import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { decode } from 'base-64';
import { getAuth, insertError } from '../Service/Api';



export default function MainComponent() {
  const[values,setValues]=useState({})
  const nav = useNavigate()
  /*PC_AD_06 "UseEffect() will be invoked with empty dependency array. Inside the useEffect()  the method called 
  checkogin() will be called."*/
  useEffect(() => {
    checkLogin()
  }, [])
  /*PC_AD_07 "checkLogin() method will be invoked. Inside the method get values from the local storage with key 
  Y2hlY2tMb2dpbg. Store it in the variable called check. Then decode it using decode() method from base-64. IF decoded version of check is equals to true then
  navigate the user to Manage project component using useNavigate() with state value userID as get value from 
  local storage." */
  async function checkLogin() {
    try {
      document.getElementById('pageLoader').style.display = 'block'
      let check = localStorage.getItem('Y2hlY2tMb2dpbg')
      /*PC_AD_08*/
      //console.log(check);
      if (check !== null && check != undefined) {
        check = check.substring(15, check.length - 10)
        if (decode(check) == "true") {
          /*PC_AD_09*/
          nav('/manageOrganization', {
            state: {
              jwt: decode(localStorage.getItem('dG9rZW4').substring(15, localStorage.getItem('dG9rZW4').length - 10)),
              userId: decode(localStorage.getItem('dXNlcklk').substring(15, localStorage.getItem('dXNlcklk').length - 10)),
              userName: decode(localStorage.getItem('eGahaTfsa').substring(15, localStorage.getItem('eGahaTfsa').length - 10)),
              accessToken: decode(localStorage.getItem('fcyhs').substring(15, localStorage.getItem('fcyhs').length - 10)),
              userMailId: decode(localStorage.getItem('shfhs').substring(15, localStorage.getItem('shfhs').length - 10)),
              adRole: Number(decode(localStorage.getItem('wewrgrb').substring(15, localStorage.getItem('wewrgrb').length - 10))),
              domain: decode(localStorage.getItem('adcrgsthj').substring(15, localStorage.getItem('adcrgsthj').length - 10)),
              adUserId: decode(localStorage.getItem('hgfjhabd').substring(15, localStorage.getItem('hgfjhabd').length - 10))

            }
          })
        }
      }else{
        let res= await getAuth({})
        // console.log(res);
        setValues({tenantId:res.tenantID,clientId:res.clientID})
      }
      /*PC_AD_30*/
      document.getElementById('pageLoader').style.display = 'none'
    }
    /*PC_AD_26*/
    catch (error) {
      //console.log(error);
      /*PC_AD_27*/
      insertError({
        "errorMessage": error.message,
        "serviceName": "frontend",
        "module": "MainComponent",
        "functionName": "checkLogin",
        "userId": ''
      })
    }
  }
  /*PC_AD_13*/
  const userLogin = () => {
    /*PC_AD_14 "userLogin() method will be invoked. Inside the method navigate the user to the microsoft login url using 
window.location.href. The url has set of query parameters. like client_id as values from contrains.clientId
response_type ass code and redirect_uri as /auth/cd component and scope as openid and reponse_mode as
query and state as random value."
User choose the microsoft account associated with avasoft.*/
    window.location.href = `https://login.microsoftonline.com/${values.tenantId}/oauth2/v2.0/authorize?
        client_id=${values.clientId}
        &response_type=code
        &redirect_uri=${window.location.origin}/auth/cb
        &scope=openid
        &response_mode=query
        &state=12345`
  }

  return (
    <div className="container-fluid">
      <div class="overlay" id='pageLoader' style={{ display: 'none' }}>
        <div class="position-absolute top-50 start-50  translate-middle">
          <div class="d-flex align-items-center loader-bg">
            <div class="spinner-border Loader text-dark align-center " role="status">
              <span class="visually-hidden"></span>
            </div>
            <span class="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
          </div>
        </div>
      </div>
      <div className="row ">
        {/* DS cg left container starts here */}
        <div className=" col-xl-6 d-xl-block d-none p-0">
          <div className="bg-gradient-primary  bg-gradient-height  text-center position-relative my-0 rounded-0">
            <h5 className="font-regular font-24 text-white pt-5">Welcome to <span className="font-bold">GenETL</span></h5>
            <h6 className="font-regular font-14 text-primary-grad mt-4">Seamless Data Integration Made Simple</h6>
            <p className="font-regular font-14 text-primary-grad">A More Approachable and Efficient Way to Connect Your Data</p>
            <span className="pos-login-img mt-5">
              <img src="/images/login-image.svg" alt="login-img" className="login-img-style" />
            </span>
          </div>
        </div>
        {/* DS cg left container ends here */}
        {/* DS cg right container starts here */}
        <div className="  col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
          <div className="form-signup">
            <div className="row justify-content-center">
              <div className="col-md-7 col-sm-12 mb-3 text-center">
                <span>
                  <img src="/images/codegen-logo.svg" alt="logo" className="logo-style my-5" />
                </span>
                <h5 className="font-regular font-22 color-black mb-5">Hi Team, Welcome to <span className="font-bold">GenETL</span></h5>
                {/*PC_AD_12*/}
                <button className="btn w-100 cust-primary-btn font-medium font-semibold" onClick={() => userLogin()} >Login</button>
              </div>
            </div>
          </div>
        </div>
        {/* DS cg right container ends here */}
      </div>
    </div>
  )
}