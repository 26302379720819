// PS_CD_1.0
import React, { useEffect, useState } from 'react';
// PS_CD_1.1
import { useLocation, useNavigate } from 'react-router-dom';
// PS_CD_1.2
import { getProjects, isActiveProjects, insertError } from '../Service/Api.js';
import { HeaderCom } from './Header.jsx';
import moment from 'moment';
import { logDOM } from '@testing-library/react';
// PS_CD_1.6
// PS_CD_1.7
const ManageProjects = () => {
    const [projectList, setProjectList] = useState([]);
    // PS_CD_1.46
    const [filterCriteria, setFilterCriteria] = useState({
        startDate: '',
        endDate: '',
        // status: 'Select',
        cloudProviderName: 'Select',
        futureStateName: 'Select',
        phase: 'Select',
        projectType: 'Select',
    });
    const [organizationDetails, setOrganizationDetails] = useState({
        orgName: '',
        orgLogo: '',
    });
    // PS_CD_1.45
    const [filteredRecords, setFilteredRecords] = useState([]);
    // PS_CD_1.35
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();
    const [dataPresent, setdataPresent] = useState(false)
    const [isFilteredApplied, setIsFilteredApplied] = useState(false)
    if (location.state == null || location.state?.orgId == undefined) {
        window.location.href = '/'
    }
    const orgId = location.state; // Retrieve orgId from the state
    // PS_CD_1.63
    const [sortOrder, setSortOrder] = useState({
        column: 'createdDate',
        ascending: false,
    }); //console.log(orgId);

    const [isActive, setIsActive] = useState([]);
    const [editHTML, seteditHTML] = useState(true)
    const [showFilter, setShowFilter] = useState(false);
    //PS_CD_1.3
    const navigate = useNavigate();
    let state = location.state

  

    // PS_CD_1.8
    useEffect(() => {
        console.log("state----", state);
        if (orgId) {
            fetchProjects(orgId);
        }
        // PS_CD_1.9
    }, [orgId]);

    const fetchProjects = async (orgId) => {
        //  
        try {
            document.getElementById('pageLoader').style.display = 'block';
            const response = await getProjects(orgId.orgId, orgId.userId, orgId.jwt);
            document.getElementById('pageLoader').style.display = 'none';

            if (response.statusCode === 200 && response.data && Array.isArray(response.data)) {
                const sortedProjects = response.data[1].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                setProjectList(sortedProjects);
                setFilteredRecords(sortedProjects);
              
                const initialIsActive = sortedProjects.map(({ isActive }) => isActive);
                setIsActive(initialIsActive || []);

                if (response.data[0][0]?.orgName && response.data[0][0]?.orgLogo) {
                    setOrganizationDetails({
                        orgName: response.data[0][0].orgName,
                        orgLogo: response.data[0][0].orgLogo,
                    });
                }
                
                setdataPresent(true);
            } else {
                console.error('Error fetching project data:', response);
                setdataPresent(false);
            }
        } catch (error) {
            console.error('Error fetching project data:', error);
            insertError({
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageProjects',
                functionName: 'getProjects',
                userId: state.userId,
            });
        }
    };









    // PS_CD_1.77-1.86
    /**
     * The function `handleProjectStatus` is an asynchronous function that updates the active status of
     * a project and updates the project list and active status array accordingly.
     * @param projectId - The `projectId` parameter is the unique identifier of a project. It is used to
     * identify the specific project that needs to be updated.
     * @param index - The `index` parameter is used to specify the position of the project in the
     * `projectList` array that needs to be updated. It is used to access and modify the corresponding
     * elements in the `projectList` and `isActive` arrays.
     */
    const handleProjectStatus = async (projectId, index) => {
        try {
            document.getElementById('pageLoader').style.display = 'block'
            //console.log(orgId)
            const updatedProject = await isActiveProjects(projectId, isActive[index], orgId.userId, orgId.jwt);
            /* The above code is creating a new array called `updatedProjectList` that is a copy of the
           `projectList` array. The spread operator (`...`) is used to create a shallow copy of the
           array. */
            //console.log('---->',updatedProject);
            let updatedProjectList = [...projectList];
            updatedProjectList = updatedProjectList.map((data) => {
                if (data.projectId == projectId) {
                    return { ...data, isActive: !data.isActive }
                }
                else {
                    return data
                }
            })

            /* The above code is setting the value of the variable "updatedProjectList" to the function
            "setProjectList". */
            //console.log(updatedProjectList);
            setProjectList(updatedProjectList);

            let filteredData = [...filteredRecords]
            filteredData = filteredData.map((data) => { return { ...data, isActive: data.projectId == projectId ? !data.isActive : data.isActive } })
            //console.log(filteredData);
            setFilteredRecords(filteredData)
            setIsActive(filteredData.map(({ isActive }) => isActive));
            document.getElementById('pageLoader').style.display = 'none'
        } catch (error) {
            // PS_CD_1.86
            insertError({
                "errorMessage": error.message,
                "serviceName": "frontend",
                "module": "ManageProjects",
                "functionName": "isActiveProject",
                "userId": state.userId
            })
        }
    };
    // PS_CD_1.65
    /**
     * The function `handleSort` is used to sort a list of records based on a specified column and update
     * the state with the sorted list.
     * @param column - The `column` parameter represents the column by which the records should be sorted.
     * It is a string value that corresponds to the name of the column in the records.
     */
    const handleSort = (column) => {
         
        console.log("in sort orderrr");
        // PS_CD_1.66
        const isSameColumn = sortOrder.column === column;
        // PS_CD_1.67
        // PS_CD_1.68
        const newOrder = isSameColumn ? !sortOrder.ascending : true;
        // PS_CD_1.69
        console.log("newOrder", newOrder, "isSameColumn", isSameColumn);

        setSortOrder({
            column,
            ascending: newOrder,
        });
        // PS_CD_1.70
        // PS_CD_1.71
        // PS_CD_1.72
        const sortedList = filteredRecords.slice().sort((a, b) => {
            // PS_CD_1.73
            if (column === 'createdDate') {
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return newOrder ? dateB - dateA : dateA - dateB;
            } else if (column === 'projectName') {
                /* The above code is using the `localeCompare` method to compare the values of two
                elements `a[column]` and `b[column]`. The comparison is done based on the
                locale-specific sort order and options provided. In this case, the comparison is
                done with numeric sorting enabled and base sensitivity level. The result of the
                comparison will be stored in the `compareValue` variable. */
                const compareValue = a[column].localeCompare(b[column], undefined, { numeric: true, sensitivity: 'base' });
                return newOrder ? compareValue : -compareValue;
            } else {
                const compareValue = a[column].localeCompare(b[column], undefined, { numeric: true, sensitivity: 'base' });
                return newOrder ? compareValue : -compareValue;
            }
        });
        // PS_CD_1.74
        setFilteredRecords(sortedList);
        setIsActive(sortedList.map(({ isActive }) => isActive))
    };


    //console.log("Current Filter Criteria:", filterCriteria);
    //console.log('Filtered List:', filteredRecords);
    {/* // PS_CD_1.50 */ }
    /**
     * The function `
     * yFilter` filters a list of projects based on the provided filter criteria
     * and sets the filtered list to a separate state variable.
     * @returns The function `handleApplyFilter` returns nothing (`undefined`).
     */
    const handleApplyFilter = () => {
         
        console.log("filtercriteria", filterCriteria)
        {/* // PS_CD_1.51 */ }
        const { startDate, endDate, status, cloudProviderName, phase, projectType, futureStateName } = filterCriteria;
        if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
            //console.log('')
            console.error("End date cannot be earlier than start date");
            // You can also display an error message to the user
            alert("End date cannot be earlier than start date");

            return;
        }
        //console.log(projectList);
        // Filter the projectList based on criteria
        /* // PS_CD_1.51
        {/* // PS_CD_1.52 */
        const filteredList = projectList.filter((record) => {
            console.log("recordsssssss in filter", record, "projectList", projectList);
            {/* // PS_CD_1.51 */ }
            const startDateMatch = !startDate || new Date(record.createdDate) >= new Date(startDate);
            const endDateMatch = !endDate || new Date(record.createdDate) <= new Date(endDate);
            // const statusMatch = status === 'Select' || status == 'active' && record.isActive == true || status == 'inactive' && record.isActive == false;
            const cloudProviderMatch = cloudProviderName === 'Select' || record.cloudProviderName.toLowerCase() === cloudProviderName.toLowerCase();
            const phaseMatch = !phase || phase === 'Select' || record.phase.toLowerCase() === phase.toLowerCase();
            const futureStateMatch = futureStateName === 'Select' || record.futureStateName.toLowerCase() === futureStateName.toLowerCase();
            const projectTypeMatch = !projectType || projectType === 'Select' || record.projectTypeName.toLowerCase() === projectType.toLowerCase();

            return startDateMatch && endDateMatch && cloudProviderMatch && phaseMatch && futureStateMatch && projectTypeMatch;
        });
        // PS_CD_1.53
        // Set the filtered projectList to a separate state variable
        console.log("filteredList", filteredList);
        setFilteredRecords(filteredList);
        setIsActive(filteredList.map(({ isActive }) => isActive))
        setIsFilteredApplied(() => true)
        setShowFilter(false)
    };

    // PS_CD_1.58
    /**
     * The function `handleClearFilter` resets the filter criteria and the filtered project list to their
     * initial values.
     */
    const handleClearFilter = () => {
        // // PS_CD_1.59
        // Reset filter criteria to their initial values
        setFilterCriteria({
            startDate: '',
            endDate: '',
            futureStateName: "Select",
            phase: "Select",
            projectType: "Select",
            cloudProviderName: 'Select',
        });
        // PS_CD_1.60
        // Reset filtered projectList to Select projectList
        setFilteredRecords(projectList);
        setIsActive(projectList.map(({ isActive }) => isActive));
    };

    /**
     * The function `handleSearch` filters a list of projects based on a search term and updates the
     * filtered records.
     * @param term - The term parameter is a string that represents the search term entered by the user.
     * It is used to filter the projectList based on the projectName and cloudProvider properties of each
     * record.
     */
    const handleSearch = (term) => {
        if (term.length < 3) {
            // If the search term is empty, reset to all projectList
            setFilteredRecords(projectList.filter(filterByAppliedFilters));
            setIsActive(projectList.map(({ isActive }) => isActive));
        } else {
            // Filter the projectList based on projectName and cloudProvider
            const filteredData = projectList.filter(
                (record) =>
                    (record.projectName && record.projectName.toLowerCase().includes(term.toLowerCase())) ||
                    (record.phase && record.phase.toLowerCase().includes(term.toLowerCase()))
            ).filter(filterByAppliedFilters);

            setFilteredRecords(filteredData);
            setIsActive(filteredData.map(({ isActive }) => isActive));
        }
    };

    /**
     * The function `filterByAppliedFilters` filters the projects based on the applied filter criteria.
     * It checks if each project matches the filter criteria set by the user.
     * @param project - The project parameter represents a single project object from the projectList.
     * @returns A boolean value indicating whether the project matches the applied filter criteria.
     */
    const filterByAppliedFilters = (project) => {
        const { startDate, endDate, status, cloudProviderName, phase, projectType, futureStateName } = filterCriteria;
        const startDateMatch = !startDate || new Date(project.createdDate) >= new Date(startDate);
        const endDateMatch = !endDate || new Date(project.createdDate) <= new Date(endDate);
        const cloudProviderMatch = cloudProviderName === 'Select' || project.cloudProviderName.toLowerCase() === cloudProviderName.toLowerCase();
        const phaseMatch = !phase || phase === 'Select' || project.phase.toLowerCase() === phase.toLowerCase();
        const futureStateMatch = futureStateName === 'Select' || project.futureStateName.toLowerCase() === futureStateName.toLowerCase();
        const projectTypeMatch = !projectType || projectType === 'Select' || project.projectTypeName.toLowerCase() === projectType.toLowerCase();

        return startDateMatch && endDateMatch && cloudProviderMatch && phaseMatch && futureStateMatch && projectTypeMatch
    };





    // Update the useEffect to trigger the search when the searchTerm changes
    useEffect(() => {
        {/* // PS_CD_1.38 */ }
        handleSearch(searchTerm);
    }, [searchTerm]);


    //PS_CD_1.28
    /**
     * The function `navigateToConnectionInfo` navigates to the `/connectionInfo` page with specific
     * state parameters.
     * @param projectId - The `projectId` parameter is the unique identifier for a project. It is used
     * to identify the project for which the connection information is being accessed or edited.
     */
    const navigateToProjectInfo = (projectId) => {
        //console.log('clicked')
        //PS_CD_1.29
        navigate('/projectInfo', {
            state: {...state, projectId, isEdit: true, orgId: orgId.orgId, jwt: orgId.jwt, userId: orgId.userId, userName: orgId.userName, orgLogo: organizationDetails.orgLogo, orgName: organizationDetails.orgName },
        });
    };


    //PS_CD_1.32
    /**
     * The function `handleNewProject` navigates to the `/connectionInfo` page with some state data.
     */
    const handleNewProject = () => {
        console.log("in new prohetbtn");
         
        //PS_CD_1.33
        navigate(`/projectInfo`, { state: { ...state, isEdit: false, orgId: orgId.orgId, jwt: state?.jwt, userId: state?.userId, userName: state?.userName, orgLogo: organizationDetails.orgLogo, orgName: organizationDetails.orgName } });
    };
    //console.log(filteredRecords.length, "*********", searchTerm, "******", dataPresent)
    {/* //PS_CD_1.27 */ }
    return (
        <div className="container-fluid">
            <div style={editHTML === false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <HeaderCom value='1' />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="row p-4">
                        <div className="d-xl-flex d-lg-block mb-5 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 mb-3 ">
                                <a className="d-flex" style={{ cursor: 'pointer' }} ><img src="images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/manageOrganization', { state: orgId })} /></a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">Manage Projects</h2>
                            </div>
                            <div className="d-lg-flex d-md-block">
                                <div className="d-flex mb-md-3 mb-sm-3">
                                    <div className="cust-input-sty font-14 rounded-2 w-50 font-regular text-grey px-3 me-3 d-flex align-items-center">
                                        <span className="me-1 text-nowrap">Viewing Projects of</span>
                                        <span>
                                            {organizationDetails.orgLogo && (
                                                <img src={organizationDetails.orgLogo} alt="org-logo" className="org-logo" />
                                            )}
                                        </span>
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" title={organizationDetails.orgName} className="me-2 text-nowrap text3dot ms-1"> {organizationDetails.orgName}</span>
                                    </div>
                                    <div className="input-group position-relative w-50 search-wid me-3">
                                        <input type="search" className="form-control cust-input-sty font-14 rounded-2 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                        <span className="search-icon-sty"><a ><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="btn-group me-3">
                                        <button className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center" type="button" onClick={() => setShowFilter(true)}>
                                            <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon" />
                                        </button>
                                        <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3 ${showFilter == true ? "show" : ""}`}>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowFilter(false)} />
                                                </div>
                                                <div className="row">
                                                    <h5 className="font-16 font-bold text-black mt-3">Created Date</h5>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="StartDate" className="form-label text-black font-14 font-medium">Start Date</label>
                                                            <input type="date" className="form-control cust-input-sty font-14 font-regular" id="startDate"
                                                                value={filterCriteria.startDate}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, startDate: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="endDate" className="form-label text-black font-14 font-medium">End Date</label>
                                                            <input type="date" className="form-control cust-input-sty font-14 font-regular" id="endDate"
                                                                value={filterCriteria.endDate}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, endDate: e.target.value })} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Cloud Provider</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="cloudProviderName"
                                                                aria-label="Default select example"
                                                                value={filterCriteria.cloudProviderName}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, cloudProviderName: e.target.value })}
                                                            >
                                                                <option value='Select'>Select</option>
                                                                <option value='AWS'>AWS</option>
                                                                <option value='Azure'>Azure</option>
                                                                <option value='GCP'>GCP</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Future State Technology</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="futurestate"
                                                                aria-label="Default select example"
                                                                value={filterCriteria.futureStateName}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, futureStateName: e.target.value })}
                                                            >
                                                                <option value='Select'>Select</option>
                                                                <option value='Databricks'>Databricks</option>
                                                                <option value='ADF'>ADF</option>
                                                                <option value='Synapse'>Synapse</option>
                                                                <option value='AWSglue'>AWSglue</option>
                                                                <option value='Redshift'>Redshift</option>
                                                                <option value='Snowflake'>Snowflake</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Phase</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="phase"
                                                                aria-label="Default select example"
                                                                value={filterCriteria.phase}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, phase: e.target.value })}
                                                            >
                                                                <option value='Select'>Select</option>
                                                                <option value='Yet to start'>Yet to Start</option>
                                                                <option value='define'>Define</option>
                                                                <option value='design'>Design</option>
                                                                <option value='"development"'>Development</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Project Type</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="project Type"
                                                                aria-label="Default select example"
                                                                value={filterCriteria.projectType}
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, projectType: e.target.value })}
                                                            >
                                                                <option value='Select'>Select</option>
                                                                <option value='Data warehouse Implementation'>Implementation</option>
                                                                <option value='Data warehouse Migration'>Migrartion</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2">
                                                    <button type="button" className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3" onClick={handleClearFilter}>
                                                        Clear
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium px-4" onClick={handleApplyFilter}>
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(state?.adRole == 1 || state?.adRole == 2 || state?.role == "Project Lead") ? (
                                        <button type="button" className="btn cust-primary-btn font-14 font-medium px-3 text-nowrap" onClick={() => handleNewProject()}>
                                            <span><img src="images/plus-icon-white.svg" alt="plus-icon-white" className="me-2" /></span>
                                            New Project
                                        </button>
                                    ) : (<></>)}
                                </div>
                            </div>
                        </div>
                        {filteredRecords.length === 0 && dataPresent ? (
                            <div className="col-md-12">
                                <div className="text-center pt-5">
                                    <span><img src="images/no-records.svg" alt="grid-no-rec-icon" className="no-rec-icon mt-4" /></span>
                                    {searchTerm !== "" || isFilteredApplied === true ? (
                                        <p className="font-24 font-medium text-black mt-5">No projects found!</p>
                                    ) : (
                                        <>
                                            <p className="font-24 font-medium text-black mt-5">No projects created so far!</p>
                                            <p className="font-18 font-regular text-grey-v3 mt-2">Start creating your first project.</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="table-responsive">
                                {filteredRecords.length == 0 ? (
                                    <p></p>
                                ) : (
                                    <table className="table w-100 table-borderless rounded custom-grid">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th onClick={() => handleSort('projectName')}>
                                                    Project{' '}
                                                    {sortOrder.column === 'projectName' ? (
                                                        <span className="cursor-pointer">
                                                            {sortOrder.ascending ? (
                                                                <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                                            ) : (
                                                                <img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />
                                                            )}
                                                        </span>
                                                    ) : <span className="cursor-pointer">
                                                        <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                                    </span>}
                                                </th>
                                                <th className="text-center">Cloud Provider</th>
                                                <th>Project Type</th>
                                                <th>Future State Technology</th>
                                                <th>Phase</th>
                                                <th onClick={() => handleSort('createdDate')}>
                                                    Created Date and Time{' '}
                                                    {sortOrder.column === 'createdDate' ? (
                                                        <span className="cursor-pointer">
                                                            {sortOrder.ascending ? (
                                                                <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                                            ) : (
                                                                <img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />
                                                            )}
                                                        </span>
                                                    ) : <span className="cursor-pointer">
                                                        <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                                    </span>}
                                                </th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {//console.log(filteredRecords)} */}
                                            {filteredRecords.map((record, index) => (
                                                <tr key={index} style={{ 'opacity': `${isActive[index] === true ? 1 : 0.5}` }}>
                                                    <td>
                                                        <a
                                                            className="link-clr text-decoration-none"
                                                            style={{ cursor: isActive[index] ? 'pointer' : 'default' }}
                                                            onClick={() => { if (isActive[index] === true) navigateToProjectInfo(record.projectId) }}
                                                        >
                                                            {record.projectName}
                                                        </a>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={record?.cloudProviderName?.toLowerCase() === "aws" || record?.cloudProviderName?.toLowerCase() === "azure" ? `images/${record?.cloudProviderName?.toLowerCase()}-logo.svg` : 'images/gcp-logo.png'} style={{ height: '30px', width: '30px' }} alt={`${record.cloudProviderName}-logo`} />
                                                    </td>
                                                    <td> {record.projectTypeName}</td>
                                                    <td> {record.futureStateName}</td>
                                                    <td> {record.phase}</td>
                                                    <td>{moment(record.createdDate).format('lll')}</td>
                                                    <td className="text-center">
                                                        <div className="form-switch text-center">
                                                            <input
                                                                className="form-check-input custom-check-input"
                                                                type="checkbox"
                                                                checked={record.isActive}
                                                                onClick={() => handleProjectStatus(record.projectId, index)}
                                                                style={{ cursor: "pointer" }}
                                                                disabled={state.role == "Developer"}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                        {filteredRecords.length !== 0 ? (
                             <p className="font-regular font-14 text-grey">
                                    # of Records:
                                    <span className="text-black font-medium">{filteredRecords.length} out of {filteredRecords.length}</span>
                                </p> 
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ManageProjects