import { useEffect, useState } from "react";

/*PS_CG_1.1*/
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { insertError, postDefineDetails,getValidated } from "../Service/Api.js";
import * as XLSX from "xlsx";
import moment from "moment/moment.js";
import * as FileSaver from "file-saver";

function DefinePop(props) {
    const { Buffer } = require('buffer');
    const [selectedFileName, setSelectedFileName] = useState("");
    const [editHTML, seteditHTML] = useState(true);
    const [defineData, setDefineData] = useState({});
    const [validationErrors, setValidationErrors] = useState([]);
    const [futureState, setFutureState] = useState(""); 
    const [defineDetails, setDefineDetails] = useState({});
    const [show, setShow] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [enable, setEnable] = useState(true)
    const [fileUploaded, setFileUploaded] = useState(false);

    const { state } = useLocation();
    if (state == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        setShow(() => true)
        if (props.fileName != '') {
            setFormValid(true);
            setDefineData({ fileName: props.fileName, })
        }
        console.log('defineDetails', defineDetails)
    }, []);

    console.log(props);
    // PS_CD_1.14-PS_CD_1.205
   /**
    * The `handleFileUpload` function in JavaScript React handles the validation and processing of an
    * uploaded Excel file, checking for specific headers and field values in different sheets.
    * @returns The `handleFileUpload` function is returning a Promise. Within the Promise, it is
    * processing the uploaded file, performing validations on the file content, and resolving with the
    * JSON data converted from the Excel file if there are no errors. If there are any errors during
    * the processing or validation, it will reject the Promise with an error message.
    */
   function convertExcelToJSON(workbook) {

    let updatedDefineDetails = { ...defineDetails }; // Make a copy of the current state

    workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const sheetData = [];

        jsonData.forEach((row) => {
            const rowData = {};

            Object.keys(row).forEach((cell) => {
                const header = cell.trim();
                const value = row[cell];
                rowData[header] = value;
            });

            sheetData.push(rowData);
        });

        // Remove duplicates from sheetData
        const uniqueSheetData = sheetData.filter(
            (item, index, self) => index === self.findIndex((t) => (
                JSON.stringify(t) === JSON.stringify(item)
            ))
        );

        if (sheetName.includes("SourceSystemDetails")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                sourceSystemDetails: [
                    ...(updatedDefineDetails.sourceSystemDetails || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("DWHSubjectArea")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                dwhSubjectArea: [
                    ...(updatedDefineDetails.dwhSubjectArea || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("Extraction")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                extraction: [
                    ...(updatedDefineDetails.extraction || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("SilverZoneTransformation")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                silverZoneTransformation: [
                    ...(updatedDefineDetails.silverZoneTransformation || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("GoldZoneTransformation")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                goldZoneTransformation: [
                    ...(updatedDefineDetails.goldZoneTransformation || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("GenericRequirements")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                genericRequirements: [
                    ...(updatedDefineDetails.genericRequirements || []),
                    ...uniqueSheetData,
                ],
            };
        } else if (sheetName.includes("TestStrategy")) {
            updatedDefineDetails = {
                ...updatedDefineDetails,
                testStrategy: [
                    ...(updatedDefineDetails.testStrategy || []),
                    ...uniqueSheetData,
                ],
            };
        }
    });

    console.log(updatedDefineDetails);

    setDefineDetails(updatedDefineDetails);
    return updatedDefineDetails
    // console.log("formValid", formValid);
}
const handleFileUpload = async (event) => {
    setFileUploaded(false);
    setValidationErrors([]);
    setFormValid(false);

    try {
        document.getElementById('pageLoader').style.display = "block";
        const selectedFile = event.target.files[0];
        if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
            setValidationErrors([
                "Invalid file format. Please upload a .xlsx file.",
            ]);
            return;
        }

        const fileName = selectedFile.name;
        setSelectedFileName(fileName);
        setDefineData({ ...defineData, fileName: fileName });

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });

                await convertExcelToJSON(workbook);
                let validatedResponse = await getValidated({SheetData:workbook,userId: state?.userId}, state?.jwt);
                                console.log(validatedResponse,"valifatttteeeeeee redspoosdhcdjfvehfvejrfvekrjf")

                setFormValid(validatedResponse.data.formValid);
                setValidationErrors(validatedResponse.data.validationErrors);

                if (validatedResponse.status === 200 && validatedResponse.data.formValid) {
                    setFileUploaded(true);
                }
            } catch (error) {
                console.error("Error reading the Excel file:", error);
                setValidationErrors(["Error processing the file."]);
            } finally {
                document.getElementById('pageLoader').style.display = "none";
            }
        };
        reader.readAsBinaryString(selectedFile);
    } catch (error) {
        console.error("Error processing the file:", error);
        setValidationErrors(["Error processing the file."]);
    }
};

    // PS_CD_1.206-PS_CD_1.266
    /**
     * The function `convertExcelToJSON` processes Excel data into JSON format and updates specific
     * sections of a state object based on sheet names.
     */
 
// PS_CD_1.285-PS_CD_1.297
  /**
   * The function `submitDetails` is an asynchronous function that handles form submission by sending
   * data to a server and updating the UI based on the response.
   */
    const submitDetails = async () => {
        console.log("entered submitDetails");
        try {
            setShow(() => false)
            document.getElementById("pageLoader").style.display = "block";
            //   const definerecord = defineDetails;
            const body = {
                frdName: defineData.fileName,
                defineDetails: defineDetails,
                userId: state.userId,
                projectId: state.projectId
                // userId:state.userId
            };
            console.log("body", body);
            console.log('state.jwt',state.jwt)
            const responseData = await postDefineDetails(
                btoa(unescape(encodeURIComponent(JSON.stringify(body)))),
                state.jwt
            );
            setShow(() => false);
            document.getElementById("pageLoader").style.display = "none";
            console.log("responseData", responseData);
             
            if (responseData.statusCode == 200) {
                props.onChange({
                    data: responseData,
                    status: 200,
                    filename: defineData.fileName
                });
            } else {
                props.onChange({ status: 400 });
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "DefinePopup",
                functionName: "postDefineDetails",
                userId: "",
            });
            console.error(error);
        }
    };

    /**
     * The function `exportToExcel` generates Excel sheets from JSON data and saves them as a file.
     * @returns The `exportToExcel` function is returning the Excel file data in the form of an array
     * buffer. This array buffer contains the Excel file content generated from the JSON data provided
     * in the function.
     */
    // PS_CD_1.268-PS_CD_1.273
    const exportToExcel = async () => {
        try {
            //  
            console.log(futureState);
            let SourceSystemDetails = [];
            let Extraction = [];
            let DWHSubjectArea = [];
            let SilverZoneTransformation = [];
            let GoldZoneTransformation = [];
            let GenericRequirements = [];
            let TestStrategy = [];
            let ws1,
                ws2,
                ws3,
                ws4,
                ws5,
                ws6,
                ws7;
            let wb;

            SourceSystemDetails =[
                {
                    "SNo": 3.1,
                    "SourceSystemName": "TrackVia",
                    "External/Internal": "External",
                    "Description": "TrackVia is a third-party cloud-based platform that Danella uses in maintaining and viewing all their data on timesheets, JHAs, vehicle and equipment-related data, training records, and small tool lists used by the foremen. Multiple workflows are maintained to track all this information.\r\n",
                    "SourceSystemType": "Application",
                    "DataFormat": "API\r\n",
                    "Comments": "NA"
                },
                {
                    "SNo": 3.1,
                    "SourceSystemName": "Samsara",
                    "External/Internal": "External",
                    "Description": "Samsara is a third-party application that danella uses to track the vehicles. It is like the overall vehicle tracking, how many fuel is present, at what speed the vehicle is travelling, what is the idling time of the vehicle and so on.",
                    "SourceSystemType": "Application",
                    "DataFormat": "API",
                    "Comments": "NA"
                }
            ]
            DWHSubjectArea = [
                {
                    "RequirementID": 5.1,
                    "SubjectArea": "Job",
                    "KeyMetrics": "Subject Area Description",
                    "RequirementDescription": "This subject area consists of information on jobs and production.\r\n\r\nJobs are the services provided by Danella, organizing information on material purchases, capturing dates, vendor details, quantities, and costs. It streamlines the tracking of purchase orders, ensuring accuracy, and timely documentation to enhance overall project management.\r\n\r\nProduction consolidates job-specific production details, including items, quantities, and production summaries. It streamlines tracking, approval, and updates for efficient management of construction project production data."
                },
                {
                    "RequirementID": 5.2,
                    "SubjectArea": "Customer",
                    "KeyMetrics": "Subject Area Description",
                    "RequirementDescription": "This subject area consists of information on Danella's customers/clients and contracts.\r\n\r\nCustomers are the people who provide business to Danella. The crucial customer details that are being captured are names, statuses, and job-related information."
                },
                {
                    "RequirementID": 5.3,
                    "SubjectArea": "Employee",
                    "KeyMetrics": "Subject Area Description",
                    "RequirementDescription": "This subject area consists of information on Danella's employees and timesheets.\r\n\r\nEmployees are the people who work for Danella. They consist of both field workers as well as office workers. Data ranging from personal information like names, addresses, and contact details to professional details such as division assignment, position, and licensing are maintained.\r\nTimesheets are records used to document and track the hours worked by the field workers. They are updated both before the start and after the end of a work shift and are usually done by the foreman."
                }
            ]

            Extraction = [
                {
                    "RequirementID": "RQ_ID: 5.1",
                    "SourceSystems": "Source System -TrackVia",
                    "RequirementType": "Extraction Scope",
                    "SubjectArea": "Job",
                    "TableName": "DCCPA_Jobs",
                    "Transformation": "JSON",
                    "RequirementDescription": "This entity will be in JSON format in Source (TrackVia) and it will be fetched and stored as a delta table in the Bronze."
                },
                {
                    "RequirementID": "RQ_ID: 5.2",
                    "SourceSystems": "Source System -TrackVia",
                    "RequirementType": "Extraction - Primary Key",
                    "SubjectArea": "Job",
                    "TableName": "DCCPA_Jobs",
                    "Transformation": "NA",
                    "RequirementDescription": "Primary Key is not specified."
                },
                {
                    "RequirementID": "RQ_ID: 5.3",
                    "SourceSystems": "Source System -TrackVia",
                    "RequirementType": "Extraction - Historical Load",
                    "SubjectArea": "Job",
                    "TableName": "DCCPA_Jobs",
                    "Transformation": "Filter",
                    "RequirementDescription": "Historical load is not applicable for this table."
                }
            ];

            SilverZoneTransformation = [
                {
                    "RequirementID": "1.1.1",
                    "SubjectArea": "Job",
                    "SourceSystem": "TrackVia",
                    "SourceTableName": "DCCPA_Jobs",
                    "SourceColumnName": "Record_ID        ",
                    "SourceDataType": "VARCHAR(255)",
                    "TargetTableName": "DCCPA_Jobs",
                    "TargetColumnName": "Job_ID",
                    "BusinessKey": "N",
                    "SurrogateKey": "Y",
                    "TargetDataType": "VARCHAR(255)",
                    "Constraints": "PK",
                    "TransformationRules": "TypeCasting ",
                    "TransformationRulesDescription": "TypeCasting - Changing the data type",
                    "AdditionalComments": "NA"
                },
                {
                    "RequirementID": "1.1.1",
                    "SubjectArea": "Job",
                    "SourceSystem": "TrackVia",
                    "SourceTableName": "DCCPA_Jobs",
                    "SourceColumnName": "Client_PA              ",
                    "SourceDataType": "VARCHAR(255)",
                    "TargetTableName": "DCCPA_Jobs",
                    "TargetColumnName": "Client_ID",
                    "BusinessKey": "N",
                    "SurrogateKey": "N",
                    "TargetDataType": "VARCHAR(255)",
                    "Constraints": "NULL",
                    "TransformationRules": "TypeCasting ",
                    "TransformationRulesDescription": "TypeCasting - Changing the data type",
                    "AdditionalComments": "NA"
                }
            ];

            GoldZoneTransformation = [
                {
                    "RequirementID": "1.1.1",
                    "SubjectArea": "Job",
                    "SourceSystem": "TrackVia",
                    "SourceTableName": "NA",
                    "SourceColumnName": "NA",
                    "SourceDataType": "BIGINT",
                    "TargetTableName": "Dim_Job",
                    "TargetColumnName": "DW_Job_ID",
                    "BusinessKey": "N",
                    "SurrogateKey": "Y",
                    "TargetDataType": "BIGINT",
                    "Constraints": "PK",
                    "TransformationRules": "Surrogate key ",
                    "TransformationRulesDescription": "Surrogate key formed using identity in datawarehouse",
                    "AdditionalComments": "NA"
                },
                {
                    "RequirementID": "1.1.1",
                    "SubjectArea": "Job",
                    "SourceSystem": "TrackVia",
                    "SourceTableName": "NA",
                    "SourceColumnName": "NA",
                    "SourceDataType": "VARCHAR(255)",
                    "TargetTableName": "Dim_Job",
                    "TargetColumnName": "Job_ID",
                    "BusinessKey": "N",
                    "SurrogateKey": "N",
                    "TargetDataType": "VARCHAR(255)",
                    "Constraints": "NOT NULL",
                    "TransformationRules": "NA",
                    "TransformationRulesDescription": "Direct Mapping and union with DCCPAPECO_JOBS",
                    "AdditionalComments": "NA"
                },
            ];
            GenericRequirements = [
                {
                    "RequirementID": 8.1,
                    "Requirements": "Logger",
                    "Description": "Require a notebook that needs to be able to read the log for execution in Databricks and to save the log in the provided location.",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.2,
                    "Requirements": "null",
                    "Description": "To handle the common functionalities by creating a functions in this Utility notebook. This can be called in other notebooks to reuse the functions.\r\n\r\nRequire a following custom function in this notebook.\r\n\r\n1)GetMetadata() \r\nInput : TableID\r\nOutput : TableName,MergeKey,LastLoadDate\r\nDescription : To fetch the columns like TableName,MergeKey,Primarykey from the metadata table for each TableID. \r\n\r\n2)UpdateMetadata()\r\nInput: TableID & Zone\r\nOutput : Update the PipelineStatus column as success  in the Metadata table .\r\nDescription: This function is to update the metadata against each tableid once the load for the table is completed\r\n",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.3,
                    "Requirements": "Tranformation notebooks",
                    "Description": " Each notebook should call the logger and utility notebooks to log the errors and to process the metadata as defined in the utility notebook",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.4,
                    "Requirements": "Extraction Notebook",
                    "Description": "Notebook should be the metadata based extraction code. Need to resuse the same code to extract tables from source and to load it in bronze and the pseudocode should by generated for Pyspark code",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.5,
                    "Requirements": "LinkedService-ADLS",
                    "Description": "ADLS Linked service needs to be created to connect and load data from Azure Data lake storage Gen 2..\r\nUse the following information \r\nIntegration runtime : Autoresolveintegration runtime\r\nAccount URL :  https://avaeussynapsepoc.dfs.core.windows.net/\r\nUserName: testaccount\r\nPassword: 0u089708-\r\n",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.5,
                    "Requirements": "LinkedService-Snowflake",
                    "Description": "Snowflake service needs to be created to connect and load data from Azure Data lake storage Gen 2..\r\nUse the following information \r\nIntegration runtime : Autoresolveintegration runtime\r\nAccount URL :  https://avaeussynapsepoc.dfs.core.windows.net/\r\nUserName: testaccount\r\nPassword: 0u089708-\r\n",
                    "AdditionalComments": 'NA'
                },
                {
                    "RequirementID": 8.6,
                    "Requirements": "Dataset-ADLS",
                    "Description": "Dataset needs to be created for ADLS to load or write data from ADLS.",
                    "AdditionalComments": 'NA'
                }
            ];
            TestStrategy = [
                {
                    "Level": "Level 0",
                    "Load": "NA",
                    "TestScenarios": "Data mapping sheet verification using a check list ",
                    "Description": "Verify  whether the source to target mapping is correct as per the requirement."
                },
                {
                    "Level": "Level 0",
                    "Load": "NA",
                    "TestScenarios": "Data mapping sheet verification using a check list ",
                    "Description": "Verify whether the naming convention of target tables and fields are proper as per the requirement"
                },
                {
                    "Level": "Level 0",
                    "Load": "NA",
                    "TestScenarios": "Data mapping sheet verification using a check list ",
                    "Description": "Verify  whether the target field maintains the same data type, length and constraints in the target as per the requirement."
                },
                {
                    "Level": "Level 0",
                    "Load": "NA",
                    "TestScenarios": "Structure Validation",
                    "Description": "Verify whether the table structure is maintained as per the data mapping sheet."
                }
            ];

            ws1 = XLSX.utils.json_to_sheet(SourceSystemDetails);
            ws2 = XLSX.utils.json_to_sheet(DWHSubjectArea);
            ws3 = XLSX.utils.json_to_sheet(Extraction);
            ws4 = XLSX.utils.json_to_sheet(SilverZoneTransformation);
            ws5 = XLSX.utils.json_to_sheet(GoldZoneTransformation);
            ws6 = XLSX.utils.json_to_sheet(GenericRequirements);
            ws7 = XLSX.utils.json_to_sheet(TestStrategy);


            wb = {
                Sheets: {
                    "SourceSystemDetails": ws1,
                    "DWHSubjectArea": ws2,
                    "Extraction": ws3,
                    "SilverZoneTransformation": ws4,
                    "GoldZoneTransformation": ws5,
                    "GenericRequirements": ws6,
                    "TestStrategy": ws7,
                },
                SheetNames: [
                    "SourceSystemDetails",
                    "DWHSubjectArea",
                    "Extraction",
                    "SilverZoneTransformation",
                    "GoldZoneTransformation",
                    "GenericRequirements",
                    "TestStrategy",
                ],
            };

            // Check if XLSX object and its writeFile method are defined
            if (XLSX && XLSX.writeFile) {
                // Generate Excel file from sheet object
                XLSX.writeFile(wb, "SampleData.xlsx");

                // Save the Excel file using FileSaver
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                /* The code is creating a new Blob object using the `Blob` constructor in JavaScript. The
                `excelBuffer` variable is being passed as the data parameter to the Blob constructor. This
                code snippet is likely part of a larger application that is working with binary data, such as
                creating a Blob object to store Excel data. */
                const data = new Blob([excelBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                // FileSaver.saveAs(data, 'AllData.xlsx');
                return excelBuffer;
            } else {
                throw new Error("XLSX object or its writeFile method is undefined.");
            }
        } catch (error) {
            console.error("Error exporting to Excel:", error);
            // Handle the error gracefully (e.g., show an error message to the user)
        }
    };

  /**
   * The handleTimeZoneChange function updates the timeZone property in the defineData state based on
   * the selected time zone from the event target.
   */
//   PS_CD_1.279-PS_CD_1.283
    // const handleTimeZoneChange = (e) => {
    //     const selectedTimeZone = e.target.innerText;
    //     setDefineData({
    //         ...defineData,
    //         timeZone: selectedTimeZone
    //     });
    // };




    // Assuming defineDetails.dwhSubjectArea is an array of subjects
    
    
    // const handleDwhSubjectAreaClick = (subject) => {
    //     const newSelectedDwhSubjectArea = [...selectedDwhSubjectArea];
    
    //     // Check if the subject is already selected
    //     const index = newSelectedDwhSubjectArea.indexOf(subject);
    
    //     if (index > -1) {
    //         // If the subject is already selected, remove it
    //         newSelectedDwhSubjectArea.splice(index, 1);
    //     } else {
    //         // If the subject is not selected, add it
    //         newSelectedDwhSubjectArea.push(subject);
    //     }
    
    //     setSelectedDwhSubjectArea(newSelectedDwhSubjectArea);
    //     console.log('selectedOptions', newSelectedDwhSubjectArea);
    //     setDefineData({
    //         ...defineData,
    //         orchestrationRequirements: newSelectedDwhSubjectArea
    //     });
    // };
    








    return (
        <div className="container-fluid">
            <div style={editHTML == false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Confrm" hidden>
                    Define Document Details
                </button>
                <div className={`modal fade ${show ? "show" : ""}`} id="Confrm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true" style={show ? { 'display': "block", "paddingLeft": '0px' } : {}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Define Document Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close(); setShow(() => false) }}></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row mt-3">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label htmlFor className="form-label text-black font-14 font-medium">Upload Define Documentations</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block">
                                                {!defineData.fileName ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                            Use this template to add info -{" "}
                                                            <a onClick={() => { exportToExcel(); }} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                Download Template
                                                            </a>{" "}
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{defineData.fileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100" }}
                                                            onClick={() => {
                                                                setDefineData({});
                                                                setValidationErrors([]);
                                                                setFormValid(false);
                                                                setFileUploaded(false);
                                                                document.getElementById('definedocumentid').value = '';
                                                            }}
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>
                                                    </button>
                                                )}
                                                <input
                                                    type="file"
                                                    name="myfile"
                                                    className="cursor-pointer cust-file"
                                                    accept=".xlsx"
                                                    id="definedocumentid"
                                                    value={defineData.file}
                                                    onChange={(event) => {
                                                        handleFileUpload(event);
                                                        setEnable(false);
                                                    }}
                                                    title={defineData.fileName}
                                                />
                                                
                                            </span>
                                            {validationErrors.length > 0 ? (
                                                <div className="code-doc-error">
                                                    {validationErrors.map((data, index) => (
                                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                            <span className="ms-2">{data}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setShow(() => false);
                                        props.close();
                                        setDefineData({});
                                        setValidationErrors([]);
                                        setEnable(true);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
    type="button"
    data-bs-dismiss="modal"
    disabled={!fileUploaded || validationErrors.length > 0}
    className="btn cust-primary-btn font-14 font-medium"
    onClick={() => { submitDetails(); }}
>
    <span>Save Details</span>
</button>
                            </div>
                        </div>
                    </div>
                </div>
                {show && <div className="modal-backdrop show"></div>}
            </div>
        </div>
    );

}
export default DefinePop;