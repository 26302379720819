// PS_01: Importing necessary libraries and components
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchExploreGridData, generationFunction, getProjectName } from '../Service/Api';
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx';
import { HeaderCom } from './Header';
import JSZip from 'jszip';
import { fetchJsonFromURL, fetchTextFromURL, putUsecaseJsonToBlob } from './new/BlobStorageService';


// PS_01: Initialize all state variables for the FormAndGridPage component
const FormAndGridPage = () => {
    // PS_11: Use "useLocation" to retrieve the state passed from the previous route
    const { state } = useLocation();

    // PS_12: Extract "item" from the state
    const item = state.item;
    console.log(item, state, 'item and state');

    const [formData, setFormData] = useState({});
    const [gridData, setGridData] = useState([]);
    const [filteredGridData, setFilteredGridData] = useState([]);
    const [isFileUploadPopupOpen, setIsFileUploadPopupOpen] = useState(false);
    const [isRegeneratePopupOpen, setIsRegeneratePopupOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedField, setSelectedField] = useState(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
    const [error, setError] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [regenComments, setRegenComments] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [verticalIndustry, setVerticalIndustry] = useState('');
    const [subjectAreaInput, setSubjectAreaInput] = useState('');
    const [subjectAreas, setSubjectAreas] = useState([]);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [projectName, setprojectName] = useState([]);
    const [projectError, setProjectError] = useState(false)
    const [verticalIndustryShow, setVerticalIndustryShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showViewInstructions, setShowViewInstructions] = useState(false)
    const [sortOrder, setSortOrder] = useState({ order: 'asc', column: 'generatedAt' })
    const [fileList, setFileList] = useState([]);
    const [xlsxContent, setXlsxContent] = useState({});
    const [rowData, setRowData] = useState({});
    const allowedExtensions = ['.py', '.dbc', '.ipynb', '.txt', '.xlsx'];



    const navigate = useNavigate()

    // PS_02: Set default form values based on item.inputFields
    const initializeFormData = () => {
        const defaultData = {};

        item.inputFields.forEach(field => {
            if (field.inputName === 'subjectArea' || field.inputName === 'verticalIndustry') {
                // Skip adding these fields to defaultData if promptId is 1
                if (defaultData.promptId === 1) {
                    return; // Skip this iteration
                }
            }

            if (field.inputName.includes('Upload')) {
                // Append fileURLs for inputNames that include "upload"
                defaultData["fileURLs"] = [];
            } else {
                // Initialize other fields with empty string
                defaultData[field.inputName] = '';
            }
        });
        setFormData(defaultData);
    };
    // PS_02: Set default form values based on item.inputFields
    useEffect(() => {
        initializeFormData();
        fetchData();

    }, []);


    // PS_09, PS_10, PS_11, PS_12: Fetching grid data for the current user session
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const object = {
                type: 'fetch',
                adRole: state.adRole,
                access_token: state.accessToken,
                emailId: state.userMailId,
                adUserId: state.adUserId,
                userId: state.userId,
                jwt_token: state.jwt,
                promptId: item.promptId
            };
            
            console.log('Fetch Data Request Payload:', object);
    
            const response = await fetchExploreGridData(object, state.jwt);
            
            console.log('Fetch Data Response:', response);
    
            if (response.status === 403) {
                sessionExpired();
                return;
            } else if (response.status !== 200) {
                console.error('Error: Non-200 status code received', response);
    
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            } else {
                setGridData(response.data);
                setFilteredGridData(response.data);
                
                console.log('Grid Data Set:', response.data);
            }
        } catch (error) {
            console.error('Error fetching grid data', error);
        } finally {
            uniqueProjectName();
            setIsLoading(false);
        }
    };
    
   

    useEffect(() => {
        console.log(formData, " statee-- formmmmm");
    }, [formData]);

    // Placeholder for PS_18, handleFilterApply and search logic
    const handleSearch = (e) => {
        console.log(e, 'wdsfs');

        let filteredData = gridData;
        if (e.length >= 3) {
            filteredData = filteredData.filter(row =>
                row.projectName.toLowerCase().includes(e.toLowerCase()) ||
                row.userName.toLowerCase().includes(e.toLowerCase())
            );
        }
        setFilteredGridData(filteredData);

    }

    // Placeholder for PS_17, handleFilterIconClick logic    // Placeholder for PS_21, handleFilterClear logic
    // Placeholder for PS_18, handleFilterApply logic
    const applyFilters = () => {
        setIsLoading(true)
        let filteredData = gridData;

        // handleSearch(searchTerm);

        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            filteredData = filteredData.filter(row => {
                const generatedOn = new Date(row.generatedAt);
                return generatedOn >= start && generatedOn <= end;
            });
        }

        if (status) {
            filteredData = filteredData.filter(row => row.status === Number(status));
        }

        setFilteredGridData(filteredData);
        if (isFilterOpen) {
            setIsFilterOpen(false)
        }

        setIsLoading(false)

    };

    const clearFilter = async () => {
        setIsFilterOpen(false)
        setSearchTerm('');
        setStartDate('');
        setEndDate('');
        setStatus('');
        setFilteredGridData(gridData)
    }

    // PS_22: HandleInputChange to update formData
    const handleInputChange = (name, value) => {
        setFormData(prevFormData => {
            if (name === 'fileURLs') {
                return { ...prevFormData, [name]: [...prevFormData.fileURLs, ...value] };
            } else {
                return { ...prevFormData, [name]: value };
            }
        });
    };


    // Placeholder for PS_34, handleFileChange  logic
    const handleFileChange = async (e, fields) => {
        console.log(e.target.files[0], 'e.target.files[0]e.target.files[0]e.target.files[0]');

        const selectedFile = e.target.files[0];
        const uploadField = fields.inputFields.find(e => e.inputType === 'upload');

        if (item.promptId === 6) {
            if (selectedFile && selectedFile.type === 'application/x-zip-compressed' && selectedFile.name.endsWith('.zip')) {
                setFile(selectedFile);
                setFileName(selectedFile.name);
                setSelectedField(uploadField);
                const isValid = await validateZipFile(selectedFile);
                if (isValid) {
                    await processZipFile(selectedFile);
                }
            } else {
                
                setFile(null);
                setErrorMessages(['Invalid file format. Please upload a .zip file.']);
                setFileName('');
                setIsFileUploadPopupOpen(true);
                setSelectedField(null);
                return;
            }
        } else {
            if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && selectedFile.name.endsWith('.xlsx')) {
                setFile(selectedFile);
                setFileName(selectedFile.name);
                setSelectedField(uploadField);
                await validateFile(selectedFile, uploadField);
            } else {
                
                setFile(null);
                setErrorMessages(['Invalid file format. Please upload a .xlsx file.']);
                setFileName('');
                setIsFileUploadPopupOpen(true);
                setSelectedField(null);
                return;
            }
        }
    };
    // Placeholder for PS_33, validateZipFile logic
    const validateZipFile = async (file) => {
        const errors = [];
        try {
            const zip = await JSZip.loadAsync(file);
            const invalidFiles = [];

            const zipFiles = Object.keys(zip.files);
            if (zipFiles.length === 0) {
                errors.push('The zip file is empty');
            }

            zipFiles.forEach(fileName => {
                const fileExt = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
                if (!allowedExtensions.includes(fileExt)) {
                    invalidFiles.push(fileName);
                }
            });

            if (invalidFiles.length > 0) {
                errors.push(`The zip file contains invalid files: ${invalidFiles.join(', ')}. Allowed extensions are: ${allowedExtensions.join(', ')}.`);
            }
        } catch (error) {
            errors.push('Error reading zip file: ' + error.message);
        }

        setErrorMessages(errors);
        return errors.length === 0;
    };

    // Placeholder for PS_33, validateFile logic
    const validateFile = async (file, uploadField) => {
        const errors = [];
        let validationColumns;
        if (item.promptId === 1) {
            if (formData?.generateCheckbox.length === 1 && formData?.generateCheckbox.includes('Subject Area')) {
                validationColumns = uploadField.subjectAreaValidations;
            } else if (formData?.generateCheckbox.length === 1 && formData?.generateCheckbox.includes('Description')) {
                validationColumns = uploadField.descriptionValidations;

            } else {
                validationColumns = uploadField.uploadValidations;
            }
        } else {
            validationColumns = uploadField.uploadValidations;
        }
        console.log(validationColumns, 'validationColumnsvalidationColumnsvalidationColumnsvalidationColumns');


        const allowedExtensions = uploadField.allowedFileTypes;

        try {
            // Assuming readExcelFile is a function that reads the Excel file and returns a data object
            const data = await readExcelFile(file);

            console.log(data, 'datadatadata');

            const requiredSheets = Object.keys(validationColumns);
            const missingSheets = requiredSheets.filter(sheet => !data[sheet]);

            if (!file.name.endsWith('.xlsx')) {
                errors.push(`Invalid file type: Allowed types are ${allowedExtensions.join(', ')}`);
            }

            if (missingSheets.length > 0) {
                errors.push(`Missing mandatory sheets: ${missingSheets.join(', ')}`);
            }

            requiredSheets.forEach(sheetName => {
                if (data[sheetName]) {
                    const sheetData = data[sheetName];
                    const sheetValidations = validationColumns[sheetName];

                    // Extract header row
                    let headerRow = sheetData[0];
                    console.log(headerRow, 'beforeheaderRowdatadatadata');

                    // Convert headerRow to array if it's an object
                    if (!Array.isArray(headerRow)) {
                        headerRow = Object.keys(headerRow); // Store only the keys
                    }
                    console.log(headerRow, 'headerRowdatadatadata');

                    // Validation for headers
                    sheetValidations.forEach((validation, index) => {
                        const normalizedHeader = normalizeHeader(headerRow[index]);
                        const expectedHeader = normalizeHeader(validation.columnHeader);
                        console.log(normalizedHeader !== expectedHeader, normalizedHeader, expectedHeader, 'datadatadata');

                        if (normalizedHeader !== expectedHeader) {
                            errors.push(`Header Field '${validation.columnHeader}' is missing or incorrect in cell ${validation.columnCell} of ${sheetName}`);
                        }
                    });

                    // Validation for data rows
                    sheetData.forEach((row, rowIndex) => {
                        sheetValidations.forEach((validation, columnIndex) => {
                            const arrayValues = Object.values(row)
                            const cellValue1 = arrayValues[columnIndex];
                            const cellValue = cellValue1?.toLowerCase();

                            console.log(cellValue1, cellValue, 'cellValuecellValue');

                            if (validation.isNull === 1 && validation.NA === 1 && validation.isEmpty === 1) {
                                if (!cellValue || cellValue.toString().trim() === '' || cellValue === 'na' || cellValue === 'null' || cellValue === undefined) {
                                    errors.push(`${validation.columnHeader} value should not be empty, NA, or null in ${sheetName}, row ${rowIndex + 2}`);
                                }
                            } else if (validation.isNull === 1 && (cellValue === null || cellValue === 'null' || cellValue === undefined)) {
                                errors.push(`${validation.columnHeader} value should not be null in ${sheetName}, row ${rowIndex + 2}`);
                            } else if (validation.NA === 1 && cellValue === 'na') {
                                errors.push(`${validation.columnHeader} value should not be NA in ${sheetName}, row ${rowIndex + 2}`);
                            } else if (validation.isEmpty === 1 && cellValue && cellValue.toString().trim() === '' || cellValue === undefined) {
                                errors.push(`${validation.columnHeader} value should not be empty in ${sheetName}, row ${rowIndex + 2}`);
                            }
                            
                            if (validation.valueValidation) {
                                const validValues = Array.isArray(validation.valueValidation) ? validation.valueValidation : [validation.valueValidation];
                                const normalizedCellValue = cellValue ? cellValue.toString().trim().toLowerCase() : '';
                                const isValidValue = validValues.map(v => v.toLowerCase()).some(v => normalizedCellValue.startsWith(v));

                                if (!isValidValue) {
                                    errors.push(`${validation.columnHeader} value should start with one of ${validValues.join(', ')} in ${sheetName}, row ${rowIndex + 2}`);
                                }
                            }
                        });
                    });
                } else {
                    console.error(`Sheet ${sheetName} is missing.`);
                }
            });

            const assignDataToXlsxContent = (data, promptId) =>
                promptId === 1 || promptId === 2 || promptId === 5 ? data.DataDictionary :
                    promptId === 3 || promptId === 4 ? data.DDLScripts :
                        [];
            // Assuming setXlsxContent is a function that sets the content of a specific sheet
            const processedData = assignDataToXlsxContent(data, item.promptId);
            setXlsxContent(processedData);
        } catch (error) {
            console.error('Error:', error);
            errors.push('Error reading file: ' + error.message);
        }

        // Assuming setErrorMessages is a function that sets error messages for UI display
        setErrorMessages(errors);
        // Assuming setFileList is a function that maintains the list of uploaded files
        setFileList(prev => [...prev, file]);

        return errors.length === 0;
    };

    const normalizeHeader = header => (header ? header.toString().trim().toLowerCase().replace(/\s+/g, "_") : "");

    // Placeholder for PS_33, validateFile, processZipFile logic
    const processZipFile = async (file) => {
        const zip = await JSZip.loadAsync(file);
        const filesPromises = Object.keys(zip.files).map(async fileName => {
            if (!zip.files[fileName].dir) {
                const blob = await zip.files[fileName].async('blob');
                const newFile = new File([blob], fileName);
                // Check if the file is an .xlsx file
                if (fileName.endsWith('.xlsx')) {
                    return newFile; // Return the .xlsx file
                }
                return newFile;
            }
            return null;
        });
        const validFiles = await Promise.all(filesPromises);
        setFileList(prev => [...prev, ...validFiles.filter(file => file !== null)]);
    }

    // Placeholder for PS_34, handleFileUpload logic
    const handleFileUpload = async () => {
        setIsLoading(true)
        setErrorMessages([]);
        setIsFileUploadPopupOpen(false);

        try {
            let fileContent;
            console.log(fileList, 'fileListfileListfileListfileListfileList');

            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.zip')) {
                for (const file of fileList) {
                    if (file.size) {
                        const fileContent = await file.text();  // Read file content as text

                        const fileNames = `notebooks/${file.name}`;
                        const url = await putUsecaseJsonToBlob(state.userId, item.promptId, fileContent, fileNames);

                        handleInputChange('fileURLs', [{ filename: fileNames, url: url }])

                    } else {
                        console.warn(`Skipping file ${file.name} as it does not have a text method.`);
                    }
                }

                handleInputChange('inputfileName', fileName)
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xlsx')) {
                console.log(xlsxContent, ' setXlsxContentsetXlsxContentsetXlsxContentsetXlsxContent');

                const url = await putUsecaseJsonToBlob(state.userId, item.promptId, xlsxContent, `${file.name}.json`);
                console.log(url, 'urlurlurlurlurlformmm');

                handleInputChange('inputfileName', fileName)
                handleInputChange('fileURLs', [url])
            }

        } catch (err) {
            console.error('Error saving files', err);
            setErrorMessages(['Error saving files']);
        } finally {
            setIsLoading(false)
        }
    };

    // Placeholder for PS_34, handleFileUpload logic
    const readExcelFile = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const result = {};
                workbook.SheetNames.forEach(sheetName => {
                    const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                    if (rows.length > 0) {
                        const headers = rows[0];
                        const convertedData = rows.slice(1).map(row => {
                            const rowObject = {};
                            headers.forEach((header, index) => {
                                rowObject[header] = row[index];
                            });
                            return rowObject;
                        });

                        result[sheetName] = convertedData;
                    }
                });
                resolve(result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };


    // PS_23: HandleSubmit to validate and submit formData, ResetFormData
    const handleCancel = () => {
        resetFormData();
        initializeFormData();
        // setFormData(item.inputFields.reduce((acc, field) => ({ ...acc, [field.inputName]: '' }), {}));
        // setSelectedRows([]);
        // setErrorMessages([]);
        // setUploadedFileURLs([]); // Clear uploaded files
    };

    // PS_23: HandleSubmit to validate and submit formData and ResetFormData
    const resetFormData = () => {
        setFormData(prevFormData => {
            const newFormData = {};
            // Iterate over each key in the previous form data
            for (const key in prevFormData) {
                if (Array.isArray(prevFormData[key])) {
                    // If the value is an array, set it to an empty array
                    newFormData[key] = [];
                } else {
                    // For other types, set the value to an empty string
                    newFormData[key] = '';
                }
            }

            return newFormData; // Return the new form data object
        });
        setFileName('')
        setFile('')
        setFileList([])
        setSubjectAreaInput([])
        setSubjectAreas([])
        setVerticalIndustryShow(false)
    };

    // PS_23: HandleSubmit to validate and submit formData
    // Placeholder for PS_24, submitFormData logic
    const handleSubmit = async () => {
        if (validateForm()) {
            const payload = {
                ...formData, isRegenerate: false, // Include promptId, username, and userId in the default data
                promptId: item.promptId,
                userName: state.userName,
                adUserId: state.adUserId,
                userId: state.userId,
                access_token: state.accessToken,
                jwt_token: state.jwt,
                generateEndpoint: item.generateEndpoint
            };
            // const generateFunction = item[item.generateEndpoint];

            try {
                resetFormData();
                // initializeFormData();
                console.log(payload, 'payloadpayloadpayloadpayloadpayloadpayload345');



                setGridData([{"userName":payload.userName,"userId":payload.userId,"outputFileURL":"-","inputfileName":payload.inputfileName,"projectName":payload.projectName,"generatedAt":"-","status":0,"comments":payload.comments,"outputFileName":"_"},...gridData]);
                setFilteredGridData([{"userName":payload.userName,"userId":payload.userId,"outputFileURL":"-","inputfileName":payload.inputfileName,"projectName":payload.projectName,"generatedAt":"-","status":0,"comments":payload.comments,"outputFileName":"_"},...gridData]);


                const response = await generationFunction(payload);
                fetchData();
                console.log(response, 'responseresponseresponseresponseresponseresponseresponse');

                if (response.status === 403 || response.status === "403") {
                    sessionExpired();
                    return;
                }
                else if (response?.status === 200 || response.status === "200") {
                    setIsLoading(true)
                    await fetchData();
                } else if (response.status !== 200|| response.status !== "200") {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                }

              
            } catch (error) {
                console.error('Error submitting form data', error);
            } finally {
                setIsLoading(false)
            }
        }
    };

    // Placeholder for PS_41, handleRowAction, to handleDelete and handleRegenerate logic
    const handleRowAction = async (action, row) => {
        if (action === 'delete') {
            setIsLoading(true)
            try {
                let projectIds;

                if (selectedRows.length > 0) {
                    projectIds = selectedRows;
                } else {
                    projectIds = [row.projectId]
                }
                const payload = { type: 'delete', projectId: projectIds, adRole: state.adRole, jwt_token: state.jwt, userId: state.userId };
                // console.log(payload, 'payloadpayloadpayload');

                // Use row.projectId instead of row.projectId
                const response = await fetchExploreGridData(payload);
                console.log(payload, response, 'payloadpayloadpayload');
                setSelectedRows([])

                if (response.status === 403) {
                    sessionExpired();
                    return;
                } else if (response.status !== 200) {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                }
                else if (response?.status === 200) {
                    await fetchData();
                    // setGridData(prevData => prevData.filter(dataRow => dataRow.projectId !== row.projectId));
                    // setFilteredGridData(prevData => prevData.filter(dataRow => dataRow.projectId !== row.projectId));
                }

            } catch (error) {
                console.error('Error deleting data', error);
            } finally {
                setIsLoading(false)
            }
        } else if (action === 'regenerate') {
            if (selectedRows.length > 1) {
                return;
            } else {
                setRowData(row)
                setSelectedRows([row.projectId])
                setIsRegeneratePopupOpen(true);
            }


        }
    };

    // Placeholder for PS_41, handleRegenerate logic
    const handlePopupRegenerate = async (comments) => {
        setIsRegeneratePopupOpen(false)
        setSelectedRows([])
        setRegenComments('')

        try {
            const payload = {
                isRegenerate: true,
                comments: comments,
                gridData: rowData,
                adUserId: state.adUserId,
                projectId: selectedRows[0],
                adRole: state.adRole,
                jwt_token: state.jwt,
                userId: state.userId,
                generateEndpoint: item.generateEndpoint
            };
            
            // setGridData([{"userName":payload.userName,"userId":payload.userId,"outputFileURL":"-","inputfileName":payload.inputfileName,"projectName":payload.projectName,"generatedAt":"-","status":0,"comments":payload.comments,"outputFileName":" "},...gridData]);
            // setFilteredGridData([{"userName":payload.userName,"userId":payload.userId,"outputFileURL":"-","inputfileName":payload.inputfileName,"projectName":payload.projectName,"generatedAt":"-","status":0,"comments":payload.comments,"outputFileName":" "},...gridData]);

            // Use row.projectId instead of row.projectId
            const response = await generationFunction(payload);


            console.log(response,"responnnnnnnnnnnnnnnnn");
            
            fetchData();




            if (response.status === 403 || response.status === "403") {
                sessionExpired();
                return;
            }
            else if (response?.status === 200 || response.status === "200") {
                setIsLoading(true)
                await fetchData();
            } else if (response.status !== 200|| response.status !== "200") {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }

        } catch (error) {
            console.error('Error Regenrating data', error);
        } finally {
            setIsLoading(false)
        }
        // const newData = gridData.map(row => {
        //     if (selectedRows.includes(row.projectId)) {
        //         return {
        //             ...row,
        //             outputFile: '',
        //             status: 'In Progress'
        //         };
        //     }
        //     return row;
        // });
        // setGridData(newData);
        // setFilteredGridData(newData);
        setIsRegeneratePopupOpen(false);
    };

    // Placeholder for PS_43, handleSelectAll logic
    const handleSelectAll = (checked) => {
        if (checked) {
            setSelectedRows(filteredGridData.map(row => row.projectId));
        } else {
            setSelectedRows([]);
        }
    };

    // Placeholder for PS_44, handleRowSelect logic
    const handleRowSelect = (id, checked) => {
        setSelectedRows(prev =>
            checked ? [...prev, id] : prev.filter(rowId => rowId !== id)
        );
    };

    // Placeholder for PS_42, handleDownload logic
    // const downloadFile = async (fileName, fileUrl) => {
    //     const name = fileUrl.split('/').pop();
    //     const fileExtension = name.split('.').pop().toLowerCase();
    //     function removeExtension(filename) {
    //         return filename.substring(0, filename.lastIndexOf('.')) || filename;
    //     }

    //     fileName = removeExtension(fileName)

    //     if (fileExtension === 'json') {
    //         try {
    //             const parsedData = await fetchJsonFromURL(fileUrl);
    //             // Filter out index and reason fields (if present)
    //             const filteredData = parsedData.map(({ index, reason, ...rest }) => rest);

    //             // Proceed with converting parsedData to an Excel sheet
    //             const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    //             // Create a new workbook and add the single sheet
    //             const wb = XLSX.utils.book_new();
    //             const ws = XLSX.utils.json_to_sheet(filteredData);
    //             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    //             // Generate the Excel file and trigger the download
    //             const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //             const data = new Blob([excelBuffer], { type: fileType });

    //             saveAs(data, `${fileName}.xlsx`);

    //         } catch (error) {
    //             console.error('Error downloading .json file as .xlsx:', error);
    //         }
    //     } else if (fileExtension === 'py' || fileExtension === 'txt') {
    //         try {
    //             const text = await fetchTextFromURL(fileUrl);
    //             const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });

    //             saveAs(blob, `${fileName}.py`);
    //         } catch (error) {
    //             console.error('Error downloading .py file:', error);
    //         }
    //     } else if (fileExtension === 'puml') {
    //         try {
    //             const text = await fetchTextFromURL(fileUrl);
    //             const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });

    //             saveAs(blob, `${fileName}.py`);
    //         } catch (error) {
    //             console.error('Error downloading .puml file:', error);
    //         }
    //     } else {
    //         console.error('File type not supported for download');
    //     }
    // };

    const downloadFile = async (fileName, fileUrl) => {
        const name = fileUrl.split('/').pop();
        const fileExtension = name.split('.').pop().toLowerCase();

        function removeExtension(filename) {
            return filename.substring(0, filename.lastIndexOf('.')) || filename;
        }

        fileName = removeExtension(fileName);

        if (fileExtension === 'json') {
            try {
                let parsedData = await fetchJsonFromURL(fileUrl);

                // 🛠 Fix: If JSON is a string, parse it
                if (typeof parsedData === 'string') {
                    try {
                        parsedData = JSON.parse(parsedData);
                    } catch (error) {
                        console.error("❌ Invalid JSON format:", parsedData);
                        return;
                    }
                }

                console.log("✅ Processed JSON Data:", parsedData);

                let dataToProcess = [];

                // Handle different JSON structures
                if (Array.isArray(parsedData)) {
                    console.log("✅ JSON is an array");
                    dataToProcess = parsedData;
                } else if (parsedData && typeof parsedData === 'object' && Array.isArray(parsedData.data)) {
                    console.log("✅ JSON contains an array inside 'data'");
                    dataToProcess = parsedData.data;
                } else if (parsedData && parsedData.response && Array.isArray(parsedData.response.files)) {
                    console.log("✅ JSON contains an array inside 'response.files'");
                    dataToProcess = parsedData.response.files;
                } else {
                    console.error("❌ Unexpected JSON format:", JSON.stringify(parsedData, null, 2));
                    return;
                }

                if (!Array.isArray(dataToProcess) || dataToProcess.length === 0) {
                    console.error('❌ No valid data to process for Excel download.');
                    return;
                }
                const sheetName = item.promptId === 1 || item.promptId === 4 ? 'DataDictionary' :
                    item.promptId === 5 ? 'DDLScripts' :
                        item.promptId === 6 ? 'CodeSuggestions' : 'Sheets1'
                console.log(sheetName, 'sheetNamesheetNamesheetName');


                // Convert JSON to Excel
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(dataToProcess);
                XLSX.utils.book_append_sheet(wb, ws, sheetName);

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });

                saveAs(data, `${fileName}.xlsx`);
            } catch (error) {
                console.error('❌ Error downloading .json file as .xlsx:', error);
            }
        } else if (fileExtension === 'py' || fileExtension === 'txt') {
            try {
                const text = await fetchTextFromURL(fileUrl);
                const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });

                saveAs(blob, `${fileName}.py`);
            } catch (error) {
                console.error('Error downloading .py file:', error);
            }
        } else if (fileExtension === 'puml') {
            try {
                const text = await fetchTextFromURL(fileUrl);
                const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });

                saveAs(blob, `${fileName}.puml`);
            } catch (error) {
                console.error('Error downloading .puml file:', error);
            }
        } else {
            console.error('File type not supported for download');
        }
    };

    const validateForm = () => {
        return item.inputFields.every(field =>
            field.isMandatory ? formData[field.inputName] : true
        );
    };

    // PS_40: Define 'downloadTemplate' function to download template from a URL
    const downloadTemplate = async () => {
        try {

            let templateURL;
            if (item.promptId === 1) {
                if (formData?.generateCheckbox.length === 1 && formData?.generateCheckbox.includes('Subject Area')) {
                    templateURL = item.templateUrlSubGen;
                } else if (formData?.generateCheckbox.length === 1 && formData?.generateCheckbox.includes('Description')) {
                    templateURL = item.templateUrlDesGen;

                } else {
                    templateURL = item.templateUrl;
                }
            } else {
                templateURL = item.templateUrl;
            }

            console.log(templateURL, 'tempURLtempURLtempURL');

            const fetchedData = await fetchJsonFromURL(templateURL);

            let parsedData = fetchedData;
            if (typeof fetchedData === 'string') {
                try {
                    parsedData = JSON.parse(fetchedData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            }

            if (parsedData) {
                const wb = XLSX.utils.book_new();
                for (const [key, value] of Object.entries(parsedData)) {
                    const ws = XLSX.utils.json_to_sheet(value);
                    XLSX.utils.book_append_sheet(wb, ws, key);
                }

                XLSX.writeFile(wb, 'SampleData.xlsx');
            }
        } catch (error) {
            console.error("Error exporting to Excel:", error);
        }
    };

    // PS_41: Define 'handleVerticalIndustryChange' function for changes in vertical industry field
    const handleVerticalIndustryChange = (selectedValue) => {
        if (selectedValue !== '') {
            setIsEditing(true)
        } else {
            setIsEditing(false)
        }
        setVerticalIndustry(selectedValue);
        handleInputChange('verticalIndustry', selectedValue);


        const selectedField = item.inputFields.find(field => field.inputName === 'verticalIndustry');
        console.log(selectedField.dependentFields[selectedValue], 'selectedField.dependentFields[selectedValue]');

        if (selectedField && selectedField.dependentFields) {
            setSubjectAreas(selectedField.dependentFields[selectedValue] || []);
            handleInputChange('subjectArea', selectedField.dependentFields[selectedValue])
        } else {
            setSubjectAreas([]);
        }
    };

    // PS_42: Define 'addSubjectArea' function to add a subject area
    const addSubjectArea = (area) => {
        if (!subjectAreas.includes(area)) {
            const updatedSubjectAreas = [...subjectAreas, area];
            setSubjectAreas(updatedSubjectAreas);
            handleInputChange('subjectArea', updatedSubjectAreas);
        }
    };

    // PS_43: Define 'removeSubjectArea' function to remove a subject area
    const removeSubjectArea = (area) => {
        const updatedAreas = subjectAreas.filter(item => item !== area);
        setSubjectAreas(updatedAreas);
        handleInputChange('subjectArea', updatedAreas);
    };

    // PS_44: Define 'handleBlur' function to handle blur events on subject area input
    const handleBlur = () => {
        if (subjectAreaInput.trim() !== '' && !subjectAreas.includes(subjectAreaInput.trim())) {
            addSubjectArea(subjectAreaInput.trim());
        }
        setSubjectAreaInput('');  // Clear the input field after blur
        // setIsEditing(false);
    };

    // PS_45: Define 'handleCheckboxChange' function for checkbox changes
    const handleCheckboxChange = (name, value) => {

        const updatedCheckboxValues = formData[name] || [];
        if (updatedCheckboxValues.includes(value)) {
            const index = updatedCheckboxValues.indexOf(value);
            updatedCheckboxValues.splice(index, 1);
        } else {
            updatedCheckboxValues.push(value);
        }
        handleInputChange(name, updatedCheckboxValues);
        console.log(updatedCheckboxValues, 'updatedCheckboxValuesupdatedCheckboxValues');

        if (updatedCheckboxValues.includes('Subject Area')) {
            setIsEditing(false)
            setVerticalIndustryShow(true)
            setFormData(prevFormData => ({ ...prevFormData, subjectArea: '', verticalIndustry: '' }));
        } else {
            setVerticalIndustryShow(false)
            // Remove subjectArea and verticalIndustry from formData
            setFormData(prevFormData => {
                const { subjectArea, verticalIndustry, ...rest } = prevFormData; // Destructure to omit these fields
                return rest; // Return the rest of the form data without subjectArea and verticalIndustry
            });
            setSubjectAreas([])
            setVerticalIndustry('')
            setSubjectAreaInput('')
        }
    };

    // PS_46: Define 'uniqueProjectName' function to fetch unique project names
    const uniqueProjectName = async () => {
        try {

            const res = await getProjectName(state.userId, state.jwt, item.promptId, 'usecase');
            console.log("response for getproject name", res.data[0]);

            if (res.statusCode === 403) {
                sessionExpired();
                return;
            } else if (res?.statusCode !== 200) {
                document.getElementById('Something-wrong').click();
            }
            else if (res?.statusCode == 200) {
                setprojectName(res.data[0]);
                console.log('res.data.data[0]', res.data[0]) // Assuming the project name 
            }
        }
        catch (error) {
            console.error('Error fetching project name:', error);
        }
    }

    // PS_47: Define 'sessionExpired' function to handle session expiration and redirection
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    // PS_48: Define 'columnMapping' to map table headers to column names
    const columnMapping = {
        'Project Name': 'projectName',
        'User Name': 'userName',
        'File Uploaded': 'inputfileName',
        'Comments': 'comments',
        'Output File': 'outputFileName',
        'Generated On': 'generatedAt',
        'Status': 'status'
    };

    // PS_49: Define 'handleSort' function to handle sorting of grid data
    const handleSort = (header) => {
        const columnName = columnMapping[header];

        console.log(columnName, '2qw3ertgyhuj');

        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'desc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = filteredGridData.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName]?.localeCompare(b[columnName]);
            } else {
                return b[columnName]?.localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setGridData(sortedRecords);
        setFilteredGridData(sortedRecords);
    };

    // PS_50: Return the JSX markup for the 'FormAndGridPage' component with associated event handlers
    return (
        <>

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ position: "absolute", zIndex: "100000" }}
            >
                {isLoading &&
                    <div
                        className="d-flex justify-content-center align-items-center w-100"
                        style={{ position: "absolute", zIndex: "100000" }}
                    >
                        <div
                            className="overlay"
                            id="pageLoader"
                            style={{ display: "block" }}
                        >
                            <div className="position-absolute top-50 start-50  translate-middle">
                                <div className="d-flex align-items-center loader-bg">
                                    <div
                                        className="spinner-border Loader text-dark align-center"
                                        role="status"
                                    >
                                        <span className="visually-hidden"></span>
                                    </div>
                                    <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div
                    id="toastMessage"
                    className="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">
                                Session has expired.Please login Again
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    id="protoastMessageSomething"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Something went wrong. Try Again!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-md-4">
                <div className="row">
                    <HeaderCom value="4" />
                    <div className="col-md-12 pt-4 mt-5">
                        {/* Breadcrumbs starts here */}
                        <div className="mt-3 mb-3">
                            <ul className="cust-breadcrumb mb-0 px-0">
                                <li className="font-16 font-medium">
                                    <a href="#">Use Case Prompt</a>
                                </li>
                                <li className="font-16 font-medium active">
                                    {item.title}
                                </li>
                            </ul>
                        </div>
                        <h2 className="text-black font-bold font-22 mb-3">
                            <img
                                src="images/back-arrow.svg"
                                alt="Back" className="me-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const { item, ...restState } = state;
                                    navigate("/usecases", {
                                        state: { ...restState }, // Send the rest of the state without 'itm'
                                    });
                                }} />
                            {item.title}
                        </h2>
                        {/* Breadcrumbs ends here */}
                        <div className=" p-4">
                            <div className="row">

                                {item?.inputFields.map((field, index) => (
                                    <>

                                        <div className="col-md-4 col-sm-12 mb-4" key={index}>
                                            {field.inputType === 'text' && field.inputName !== 'subjectArea' && (
                                                <>
                                                    <label
                                                        htmlFor="org-Name"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        {field.inputLabel}
                                                        <span className="text-red"> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 font-regular"
                                                        id="org-Name"
                                                        name={field.inputName}
                                                        value={formData[field.inputName] || ''}
                                                        placeholder={`Enter ${field.inputLabel}`}
                                                        onChange={(e) => handleInputChange(field.inputName, e.target.value)}
                                                        required={field.isMandatory}
                                                        onBlur={() => {
                                                            if (field.inputName === 'projectName') {
                                                                const isDuplicateProj = projectName.findIndex(data => data.projectName == formData.projectName);
                                                                setProjectError(isDuplicateProj == -1 ? false : true);
                                                            }
                                                        }}
                                                    />
                                                    <p className='form-label text-black font-14 font-medium text-danger mt-1'>
                                                        &nbsp;{projectError ? 'Project name Already Exist' : null}
                                                    </p>
                                                </>
                                            )}
                                            {field.inputType === 'upload' && (
                                                <>
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Upload
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="mb-3">
                                                        <button
                                                            type="button"
                                                            className="btn cust-secondary-btn"
                                                            onClick={() => { setIsFileUploadPopupOpen(true); setSelectedField(field) }}
                                                            name={field.inputName}
                                                            disabled={item.promptId === 1 ? formData?.generateCheckbox === '' || formData?.generateCheckbox?.length === 0 : false}
                                                        >
                                                            <label htmlFor="formFile">Upload</label>
                                                        </button>
                                                        {fileName && formData.fileURLs.length !== 0 && (
                                                            <span className="link-clr">
                                                                <a className="ms-3 link-clr">
                                                                    {fileName}
                                                                    <img src="images/cross-red.svg" className="ms-2" onClick={() => {
                                                                        setFile(null);
                                                                        setErrorMessages([]);
                                                                        setFileName('');
                                                                        setFileList([])
                                                                        setFormData(prevFormData => ({
                                                                            ...prevFormData,
                                                                            fileURLs: [],
                                                                        }));
                                                                        document.getElementById('OrganizationLogo').value = ''
                                                                    }} style={{ cursor: 'pointer' }} />
                                                                </a>
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {field.inputType === 'checkbox' && (
                                                <>
                                                    <div>
                                                        <label
                                                            htmlFor="data-model-structure"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            {field.inputLabel}
                                                            <span className="text-red"> *</span>
                                                        </label>
                                                        <div className="d-flex gap-4">
                                                            {field.values.map((value, idx) => {
                                                                const checkboxId = `data-model-${idx}`;
                                                                return (
                                                                    <div className="form-check" key={idx}>
                                                                        <input
                                                                            className="form-check-input custom-checkbox"
                                                                            type="checkbox"
                                                                            id={checkboxId}
                                                                            value={value}
                                                                            onChange={() => handleCheckboxChange(field.inputName, value)}
                                                                            checked={formData[field.inputName]?.includes(value) ?? false}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={checkboxId}>
                                                                            {value}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {verticalIndustryShow && (
                                                <>
                                                    {field.inputType === 'dropdown' && field.inputName === 'verticalIndustry' && (
                                                        <>
                                                            <label className="form-label text-black font-14 font-medium">
                                                                {field.inputLabel}
                                                                {field.isMandatory && <span className="text-red">*</span>}
                                                            </label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                value={verticalIndustry}
                                                                onChange={(e) => { handleVerticalIndustryChange(e.target.value); }}
                                                                required={field.isMandatory}
                                                                disabled={isReadOnly}
                                                            >
                                                                <option value="">Select</option>
                                                                {field.values.map((industry, idx) => (
                                                                    <option key={idx} value={industry}>
                                                                        {industry}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    )}
                                                    {field.inputName === 'subjectArea' && (
                                                        <>
                                                            <label className="form-label text-black font-14 font-medium">
                                                                {field.inputLabel}
                                                                {field.isMandatory && <span className="text-red">*</span>}
                                                            </label>
                                                            <div className="position-relative">
                                                                <div className="multi-select">
                                                                    {/* Render existing subject areas as bubbles */}
                                                                    {console.log(subjectAreas, 'subjectAreassubjectAreas')}
                                                                    {Array.isArray(subjectAreas) && subjectAreas.map((area, idx) => (
                                                                        <span className="multi-select-bubble subject-area font-12 font-medium" key={idx}>
                                                                            {area}
                                                                            {!isReadOnly && (
                                                                                <img
                                                                                    className="ms-2 cursor-pointer"
                                                                                    src="images/cross-red.svg"
                                                                                    alt="remove"
                                                                                    onClick={() => removeSubjectArea(area)}
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    ))}
                                                                    {/* Render the input field */}
                                                                    {isEditing ? (
                                                                        <input
                                                                            type="text"
                                                                            className="font-14 font-regular"
                                                                            value={subjectAreaInput}
                                                                            onChange={(e) => !isReadOnly && setSubjectAreaInput(e.target.value)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter' && subjectAreaInput.trim() !== '' && !isReadOnly) {
                                                                                    addSubjectArea(subjectAreaInput.trim());
                                                                                    setSubjectAreaInput('');
                                                                                }
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            autoFocus
                                                                            readOnly={formData.verticalIndustry === ''}
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            className="font-14 font-regular"
                                                                        // onClick={() => !isReadOnly && setIsEditing(true)}
                                                                        >
                                                                            {subjectAreas.length > 0 ? subjectAreaInput : 'Enter Subject Area'}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {field.inputType === 'textarea' && (
                                            <div className="col-md-12 col-sm-12 mb-4" key={item.id + '-' + index}>
                                                <label
                                                    htmlFor="org-Name"
                                                    className="form-label text-black font-14 font-medium"
                                                >
                                                    Comments<span className="text-red"> *</span>
                                                </label>
                                                <textarea
                                                    type="text"
                                                    name={field.inputName}
                                                    rows={4}
                                                    className="form-control cust-textarea-sty font-14 font-regular"
                                                    value={formData[field.inputName] || ''}
                                                    id="org-Name"
                                                    placeholder={`Enter ${field.inputLabel}`}
                                                    onChange={(e) => handleInputChange(field.inputName, e.target.value)}
                                                    required={field.isMandatory}
                                                />
                                            </div>
                                        )}

                                    </>
                                ))
                                }


                                <div className="modal-footer border-0 mb-4 mt-4">
                                    <button
                                        type="button"
                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn cust-primary-btn font-14 font-medium"
                                        onClick={handleSubmit}
                                        disabled={
                                            item.inputFields.find(field => field.inputType === 'upload') && !validateForm() ||
                                            errorMessages.length !== 0 ||
                                            projectError ||
                                            Object.values(formData).some(value => value === '' || value === null || value.length === 0)

                                        }
                                    >
                                        <span>Generate</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="d-flex align-items-center justify-content-between p-4">
                                <div className="d-flex align-items-center">
                                    <h2 className="text-black font-bold font-22 mb-0">Generated Output</h2>
                                    <button
                                        type="button"
                                        className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
                                        onClick={() => setShowViewInstructions(true)}
                                    >
                                        <img src="images/info-icon-ETL.svg" alt="info" />
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <div class="input-group position-relative search-wid me-md-3">
                                        <input type="search" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); handleSearch(e.target.value) }} />
                                        <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                    </div>
                                    <div className="btn-group me-3">
                                        <button
                                            className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                            type="button"
                                            onClick={() => setIsFilterOpen(true)}
                                        >
                                            <img
                                                src="images/filter-icon.svg"
                                                alt="filter-icon"
                                                className="filt-icon"
                                            />
                                        </button>
                                        {isFilterOpen && (
                                            <div className="dropdown-menu custom-filter shadow-sm border-0 p-3 show">
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            onClick={() => { setIsFilterOpen(false) }}
                                                            aria-label="Close"
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <h5 className="font-16 font-bold text-black mt-3">Date Range</h5>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label
                                                                htmlFor="StartDate"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Start Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className="form-control cust-input-sty font-14 font-regular"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label
                                                                htmlFor="endDate"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                End Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className="form-control cust-input-sty font-14 font-regular"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">
                                                                Status
                                                            </label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                value={status}
                                                                onChange={(e) => setStatus(e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="0">In Progress</option>
                                                                <option value="1">Completed</option>
                                                                <option value="2">Failed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="text-end mt-4 mb-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => { clearFilter() }}
                                                            className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                        >
                                                            Clear
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => applyFilters()}
                                                            className="btn cust-primary-btn font-14 font-medium px-4"
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Table Section */}
                            <div className="table-responsive mt-3 mx-4" style={{ maxHeight: '400px' }}>
                                <table className="table table-borderless rounded custom-grid border-0 custom-metadata-table">
                                    <thead className="custom-tableHeader" style={{ position: 'sticky', top: '0', zIndex: 'auto' }}>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.length === filteredGridData.length}
                                                    onChange={(e) => handleSelectAll(e.target.checked)}
                                                />
                                            </th>
                                            {/* Table Headers */}
                                            {['Project Name', 'User Name', 'File Uploaded', 'Comments', 'Output File', 'Generated On', 'Status'].map((header, index) => (
                                                <th key={index} onClick={() => handleSort(header)} >
                                                    {header}
                                                    <span className="cursor-pointer">
                                                        <img
                                                            src={sortOrder.column == columnMapping[header] && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort"
                                                            onClick={() => handleSort(header)}
                                                        />
                                                    </span>
                                                </th>
                                            ))}
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredGridData.length > 0 ?
                                            <>
                                                {filteredGridData.map((row, index) => (
                                                    <tr key={index}>
                                                        {/* Row Data */}
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedRows.includes(row.projectId)}
                                                                onChange={(e) => handleRowSelect(row.projectId, e.target.checked)}
                                                            />
                                                        </td>
                                                        {['projectName', 'userName', 'inputfileName', 'comments', 'outputFileName', 'generatedAt', 'status'].map((key, idx) => (
                                                            key === 'outputFileName' ? (
                                                                <td key={idx}>
                                                                    <a href={row[key]} download onClick={(e) => { e.preventDefault(); downloadFile(row.outputFileName, row.outputFileURL); }}>
                                                                        {row[key]}
                                                                    </a>
                                                                </td>
                                                            ) : key === 'inputfileName' ? (
                                                                <td key={idx}>
                                                                    <span className='link-primary text-decoration-none'>
                                                                        {/* <a> */}
                                                                        {row[key]}
                                                                        {/* </a> */}
                                                                        {/* {row[key]} */}
                                                                    </span>
                                                                </td>
                                                            ) : (

                                                                key === 'status' ? (
                                                                    <td key={idx}>
                                                                        {console.log(key, row[key], idx, 'keykeykeykeykey')}

                                                                        {row[key] === "Completed" || row[key] === 1 ? (
                                                                            <>
                                                                                <span className='dot completed-status' /> Completed
                                                                            </>
                                                                        ) : row[key] === "In Progress" || row[key] === 0 ? (
                                                                            <>
                                                                                <span className='dot inprogress-status' /> In Progress
                                                                            </>
                                                                        ) : row[key] === "Falied" || row[key] === 2 && (
                                                                            <>
                                                                                <span className='dot failed-status' /> Failed
                                                                            </>)
                                                                        }
                                                                    </td>
                                                                ) : (
                                                                    // Regular text fields for other columns
                                                                    <td key={idx}>{row[key]}</td>
                                                                )
                                                            )
                                                        ))}
                                                        {/* Action Buttons */}
                                                        <td className='text-center'>
                                                            <div>
                                                                {item.promptId === 1 || item.promptId === 4 || item.promptId === 6 ?
                                                                    <span
                                                                        className="cust-cursor-pointer"
                                                                        onClick={() => handleRowAction('regenerate', row)}
                                                                        disabled={selectedRows.length > 1}
                                                                    >
                                                                        <img src="images/Refresh.svg" alt="Regenerate" className="me-2" />
                                                                    </span>
                                                                    :
                                                                    null
                                                                }
                                                                <span
                                                                    className="cust-cursor-pointer"
                                                                    onClick={() => handleRowAction('delete', row)}
                                                                >
                                                                    <img src="images/delete-icon.svg" alt="Delete" />
                                                                </span>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </>

                                            :
                                            <>
                                                <tr>
                                                    <td colSpan="30" className="text-center">
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>

                                {/* Records Count */}
                                <p className='font-regular font-14 text-grey mt-3'>
                                    {console.log(filteredGridData, 'filteredGridDatafilteredGridDatafilteredGridData')}
                                    # of Records:
                                    <span className='text-black font-medium'>{`${filteredGridData?.length} out of ${gridData?.length}`}</span> {/* Assuming totalRecords is defined */}
                                </p>

                            </div>
                        </div>
                        {
                            isFileUploadPopupOpen && (
                                <>
                                    <div className="modal-backdrop fade show"></div>
                                    <div
                                        className="modal fade show"
                                        style={{ display: "block" }}
                                    >
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content px-2">
                                                <div className="modal-header border-0">
                                                    <h5
                                                        className="modal-title d-flex align-items-center font-medium font-20"
                                                        id="SourceSysLabel"
                                                    >
                                                        Upload File
                                                    </h5>
                                                    <button
                                                        onClick={() => setIsFileUploadPopupOpen(false)}
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 mb-3">
                                                            <div className="mb-2">
                                                                <label className="form-label text-black font-14 font-medium">
                                                                    Upload Data<span className="text-red"> *</span>
                                                                </label>
                                                            </div>
                                                            {item.promptId === 6 ?
                                                                <span className="d-block font-12 info-message-section text-black font-medium">
                                                                    Kindly upload document in .zip
                                                                </span>
                                                                :
                                                                <span className="d-block font-12 info-message-section text-black font-medium">
                                                                    Kindly upload document in .xlsx
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-md-12 col-sm-12">
                                                            <span className="upload-btn-wrapper d-block ">
                                                                {!fileName ?
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className=" upload-btn-sty shadow-none cursor-pointer text-center"
                                                                        >
                                                                            <img
                                                                                src="images/upload-icon.svg"
                                                                                alt="browse"
                                                                                className="mb-2"
                                                                            />
                                                                            <span className="font-medium font-13 d-block text-grey mb-2">
                                                                                Browse or drag and drop file
                                                                            </span>
                                                                            {item.promptId !== 6 &&
                                                                                <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                                                    Use this template to add info -{" "}
                                                                                    <a onClick={downloadTemplate} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                                        Download Template
                                                                                    </a>{" "}
                                                                                </span>
                                                                            }
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                                            <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                                            <a className="font-medium font-12 d-block">{fileName}</a>
                                                                            <a
                                                                                style={{ position: "relative", zIndex: "100" }}
                                                                                onClick={() => {
                                                                                    setFile(null);
                                                                                    setErrorMessages([]);
                                                                                    setFileName('');
                                                                                    setFileList([])
                                                                                    document.getElementById('OrganizationLogo').value = ''
                                                                                    setFormData(prevFormData => ({
                                                                                        ...prevFormData,
                                                                                        fileURLs: [],
                                                                                    }));
                                                                                }}
                                                                            >
                                                                                <span className="ms-2">
                                                                                    <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                                                </span>
                                                                            </a>
                                                                        </button>

                                                                    </>}

                                                                <input
                                                                    type="file"
                                                                    name="myfile"
                                                                    className="cursor-pointer cust-file"
                                                                    id="OrganizationLogo"
                                                                    onChange={(e) => { handleFileChange(e, item) }}
                                                                    disabled={file}
                                                                    accept={item.promptId === 6 ? "application/x-zip-compressed, .zip" : ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                                                                />
                                                            </span>
                                                            {errorMessages.length > 0 && (
                                                                <div className="code-doc-error">
                                                                    {errorMessages.map((message, index) => (
                                                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                                                            <img src="images/warning-red-icon.svg" alt="warning icon" />
                                                                            <span className="ms-2">{message}</span>
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 d-flex">
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => {
                                                            setIsFileUploadPopupOpen(false)
                                                            setFile(null);
                                                            setErrorMessages([]);
                                                            setFileName('');
                                                            setFileList([])
                                                            setFormData(prevFormData => ({
                                                                ...prevFormData,
                                                                fileURLs: [],
                                                            }));
                                                            document.getElementById('OrganizationLogo').value = ''
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cust-primary-btn font-14 font-medium"
                                                        onClick={handleFileUpload} disabled={!file || errorMessages.length > 0}
                                                    >
                                                        <span>Upload</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            isRegeneratePopupOpen && (
                                <>
                                    <div className="modal-backdrop fade show"></div>
                                    <div
                                        className="modal fade show"
                                        style={{ display: "block" }}
                                    >
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content px-2">
                                                <div className="modal-header border-0">
                                                    <h5
                                                        className="modal-title d-flex align-items-center font-medium font-20"
                                                        id="SourceSysLabel"
                                                    >
                                                        Regenerate File Uploaded
                                                    </h5>
                                                    <button
                                                        onClick={() => setIsRegeneratePopupOpen(false)}
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 mb-4">
                                                            <label
                                                                htmlFor="re-comments"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Generative Comments <span className="text-red"> *</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                rows={4}
                                                                className="form-control cust-textarea-sty font-14 font-regular"
                                                                id="re-comments"
                                                                value={regenComments}
                                                                placeholder="Enter Generative Comments"
                                                                onChange={(e) => setRegenComments(e.target.value)}
                                                                required
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 d-flex">
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => { setRegenComments(''); setIsRegeneratePopupOpen(false) }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cust-primary-btn font-14 font-medium"
                                                        onClick={() => handlePopupRegenerate(regenComments)}
                                                        disabled={['', 'null', 'na'].includes(regenComments.trim().toLowerCase())}
                                                    >
                                                        <span>Regenerate</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )
                        }
                        {showViewInstructions &&
                            <>
                                <div className="modal-backdrop fade show"></div>
                                <div
                                    className="modal fade show"
                                    style={{ display: "block" }}
                                >
                                    <div className="modal-dialog view-instructions-popup modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="view-instructions-popup-left-section">
                                                            <img
                                                                src="images/view-instructions-popup-banner-img.png"
                                                                alt="view-instructions-popup-banner-img"
                                                                className="popup-banner-img"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="view-instructions-popup-right-section">
                                                            <div className="d-flex justify-content-between">
                                                                <h1 className="font-bold font-20 m-0">View Instructions</h1>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                    onClick={() => setShowViewInstructions(false)}
                                                                />
                                                            </div>
                                                            <ul className="mt-4 view-instructions-list-items">
                                                                <li>Please validate whether the generated output file data are correct.</li>
                                                                <li>
                                                                    If any changes are required, regenerate them accordingly using the 'regenerate icon' in the last column.
                                                                </li>
                                                                <li>
                                                                    If everything is fine, just click and download the generated document in the output file column.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

// PS_54: Export the FormAndGridPage component
export default FormAndGridPage;