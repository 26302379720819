import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api.js'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { HeaderCom } from './Header.jsx';
// import Editor from '@monaco-editor/react';
// import axios from 'axios'
function SilverPipeline(props) {

    // const nav = useNavigate();
    const { state } = useLocation();
    
 // declare state variables
    // pseudocode no:PC_SP_02
    const [setVal, setSetVal] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [whetherUpdated, setwhetherUpdated] = useState(false);

    const [filteredRecord, setFilteredRecord] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        // const [filteredRecords, setFilteredRecords] = useState([]);

        dataflowName: 'Select', pipelineName: 'Select', tableName: 'Select'
    });
    const [newData, setNewData] = useState({
        RequirementID: "",
        DesignID: "",
        SNo: "",
        PipelineName: "",
        TableName: "",
        DataflowName: "",
        ComponentName: "",
        ComponentType: "",
        ParameterName: "",
        Configuration: "",
        PostComponentName: "",
        PostComponentType: "",
        SinkOrder: ""
    });
    const [showFilter, setShowFilter] = useState(false)

    const [action, setAction] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' })
// PC_SP_04
    useEffect(() => {
        fetchPseudo()
    }, [])
        /**
    * Pseudocode No:PC_SP_07 TO 22
    * The function fetchPseudo() is designed to handle fetching data asynchronously. When it's called, it first displays a 
    * loading spinner on the webpage to indicate that something is happening. Then, it prepares some data and sends it to 
    * another function called fetchPseudocode() located in a file named API.js. This function waits for a response from 
    * fetchPseudocode(). When it receives a response, it checks the status code: if it's 403, indicating a session expiration, 
    * it triggers a function called sessionExpired(). Otherwise, if the status code isn't 200, it opens an error modal on the 
    * webpage. But if the status code is 200, it processes the received data by parsing it and setting some values accordingly.
    */
    async function fetchPseudo() {
         
        // document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "transformation",
            userId: state.userId,
            orgId: state.orgId,
            notebookId:props.data.notebookId
        }

        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(res, "fetchRess");
        // document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            setSetVal(JSON.parse(res.data[0].pseudoCode));
            setFilteredRecord(JSON.parse(res.data[0].pseudoCode))
        }
    };
   /**
     * Pseudocode No:// PC_SP_23 to 28
     * The sessionExpired function serves to handle actions upon the expiration of a user's session. 
     * When invoked, it promptly clears the local storage, ensuring any user-related data is removed 
     * from the browser. Subsequently, it triggers the display of a toast message on the webpage, 
     * signaling to the user that their session has expired. This visual cue fades out after three 
     * seconds, discreetly vanishing from view. Additionally, there's a commented-out line of code 
     * intended to navigate the user to the login page, although it's currently inactive.
     */
    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            // nav('/');
        }, 3000);
    };
   /**
    * Pseudocode No:PC_SP_29 to 40
    * The bindGrid function is responsible for rendering the dataset onto the webpage. It first logs the filtered records to the console for debugging purposes. 
    * Then, it checks if the filteredRecord array is not empty and is indeed an array.
    * If there are records to display, it iterates over each record using the map function. For each record, it generates a table row (<tr>) with input fields for editing 
    * if the row is editable, or displays the record's data in table cells (<td>) if it's not editable. The input fields allow users to modify the data, and there are options 
    * to save or cancel the edits.
    * If there are no records to display, it renders a single table row with a message indicating that no records were found.
    * Any errors that occur during this process are caught, logged to the console, and a null value is returned to indicate a failure in rendering the grid.
    */
    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular"  placeholder="Enter" name='SNo' value={newData?.SNo} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineName' value={newData?.PipelineName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='TableName' value={newData?.TableName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DataflowName' value={newData?.DataflowName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentName' value={newData?.ComponentName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData?.ComponentType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ParameterName' value={newData?.ParameterName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Configuration' value={newData?.Configuration} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PostComponentName' value={newData?.PostComponentName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PostComponentType' value={newData?.PostComponentType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SinkOrder' value={newData?.SinkOrder} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>

                                <td class="text-center">
                                    <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            RequirementID: "",
                                            DesignID: "",
                                            SNo: "",
                                            PipelineName: "",
                                            TableName: "",
                                            DataflowName: "",
                                            ComponentName: "",
                                            ComponentType: "",
                                            ParameterName: "",
                                            Configuration: "",
                                            PostComponentName: "",
                                            PostComponentType: "",
                                            SinkOrder: ""
                                        })

                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{item.RequirementID || "NA"}</td>
                                <td>{item.DesignID || ""}</td>
                                <td>{item.SNo || "NA"}</td>
                                <td>{item.PipelineName || "NA"}</td>
                                <td>{item.TableName || "NA"}</td>
                                <td>{item.DataflowName || "NA"}</td>
                                <td>{item.ComponentName || "NA"}</td>
                                <td>{item.ComponentType || "NA"}</td>
                                <td>{item.ParameterName || "NA"}</td>
                                <td>{JSON.stringify(item.Configuration) || "NA"}</td>
                                <td>{item.PostComponentName || "NA"}</td>
                                <td>{item.PostComponentType || "NA"}</td>
                                <td>{item.SinkOrder || "NA"}</td>


                                <td className="text-center">
                                    <td class="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData(filteredRecord[index]) }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };
// PC_SP_41 TO 49
    {/*  The handleApplyFilter function processes filter criteria to refine action logs displayed. 
    It logs the provided criteria, then filters action logs based on organization name and phase, matching 
    each record against selected criteria. It produces a filtered list of records meeting both conditions,
     updates the display accordingly, and sets a flag to indicate successful filter application.
 */}
    const handleApplyFilter = () => {
        console.log("Filter Criteria:", filterCriteria);

        const filterList = setVal.filter(record => {
            console.log("Record:", record);

            const dataFlowNameMatch = filterCriteria.dataflowName === 'select' || record.dataflowName=== filterCriteria.dataflowName;
            console.log("DataFlow Name Match:", dataFlowNameMatch);

            const pipelineNameMatch = filterCriteria.pipelineName === 'select' || record.PipelineName === filterCriteria.pipelineName;
            console.log("Pipeline Name Match:", pipelineNameMatch);

            const tableNameMatch = filterCriteria.tableName === 'select' || record.TableName.includes(filterCriteria.tableName);
            console.log("Table Name Match:", tableNameMatch);

            return dataFlowNameMatch && pipelineNameMatch && tableNameMatch;
        });

        console.log("Filtered List:", filterList);

        setFilteredRecord(filterList);
        setIsFilterApplied(true);
    };

{/* PC_SP_50 TO 53
The handleClearFilter function clears the filter criteria and resets the display to show all Data
 */}
    const handleClearFilter = () => {
        console.log("Clearing Filter");

        setFilterCriteria({ dataflowName: 'Select', pipelineName: 'Select', tableName: 'Select' });
        setFilteredRecord(setVal);
        setIsFilterApplied(false);

        console.log("Filter Cleared:", setVal);
    };

 /**PC_SP_54 TO 65
     
     * The handleSearch function is designed to facilitate the search feature within a dataset. Upon invocation, 
     * it inspects the length of the provided search term. If the term contains at least three characters, the 
     * function proceeds to filter the dataset based on whether the operation type or cell name includes the 
     * search term, irrespective of case. The filtered data is then updated accordingly. Conversely, if the 
     * search term is less than three characters or empty, the function simply resets the filtered records to match the original dataset.
     */
    const handleSearch = (term) => {
         
        if (term.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.PipelineName.toLowerCase().includes(term.toLowerCase()) ||
                item.DataflowName.toLowerCase().includes(term.toLowerCase()) ||
                item.TableName.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };


  /**
     * Pseudocode No:PC_SP_66 TO 76
     * The handleDownload function prepares and initiates the download of dataset information in Excel format. 
     * It first extracts specific attributes from the dataset, constructs an Excel sheet, and converts it into 
     * an Excel buffer. This buffer is then saved as an Excel file using FileSaver.js, enabling the user to 
     * download the file directly.
     */
    const handleDownload = () => {
        let silverArray = setVal.map(data => {
            return {
                RequirementID: data.RequirementID || "",
                DesignID: data.DesignID || "",
                SNo: data.SNo || "",
                PipelineName: data.PipelineName || "",
                TableName: data.TableName || "",
                DataflowName: data.DataflowName || "",
                ComponentName: data.ComponentName || "",
                ComponentType: data.ComponentType || "",
                ParameterName: data.ParameterName || "",
                Configuration: data.Configuration || "",
                PostComponentName: data.PostComponentName || "",
                PostComponentType: data.PostComponentType || "",
                SinkOrder: data.SinkOrder || ""
            };
        });

        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert DataFlowArray to sheet
        const ws = XLSX.utils.json_to_sheet(silverArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'silver');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'silver' + fileExtension);

        // The saved file will be shown to the user
    };


{/*" PC_SP_87 TO 91
The BindPipelineName function is designed to generate a list of unique pipeline names based on the input data provided 
in the setVal array. It first checks if the setVal array has elements. If it does, the function extracts unique pipeline 
names using the Set object to avoid duplicates. Then, it maps over the unique pipeline names, generating <option> elements 
for each name, assigning the pipeline name as both the value attribute and the displayed text within the <option> element. 
The function returns this array of <option> elements." */}
    const BindPipelineName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.PipelineName))];
            return unique.map((PipelineName, index) => (
                <option key={index} value={PipelineName}>{PipelineName}</option>
            ));
        }

    }
    // PC_SP_77 TO 81
    {/*The bindDataflowName function is responsible for generating a list of unique dataflow names based 
on the input data provided in the setVal array. It first checks if the setVal array has elements. If it does,
 the function extracts unique dataflow names using the Set object to eliminate duplicates. Then, it maps over the 
unique dataflow names, creating <option> elements for each name. Each <option> element has the dataflow name assigned as
 both the value attribute and the displayed text within the element. Finally, the function returns an array containing these <option> elements. */}
    const bindDataflowName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.DataflowName))];
            return unique.map((DataflowName, index) => (
                <option key={index} value={DataflowName}>{DataflowName}</option>
            ));
        }

    }
    // PC_SP_82 TO 86
    {/* The bindTableName function is designed to generate a list of unique table names based on the input data provided in the setVal array.
 Initially, it checks if the setVal array contains any elements. If it does, the function extracts unique table names using the Set object to 
eliminate duplicates. It then iterates over the unique table names and creates <option> elements for each name. Each <option> element has the 
table name assigned as both the value attribute and the displayed text within the element. Finally, the function returns an array containing these <option> elements.*/}
    const bindTableName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.TableName))];
            return unique.map((TableName, index) => (
                <option key={index} value={TableName}>{TableName}</option>
            ));
        }

    }
 /**
     * Pseudocode No:PC_SP_92 TO 100
     * The handleSort function facilitates sorting functionality for table columns. When triggered with a column name parameter, 
     * it first checks if the clicked column is the same as the previously sorted one. If so, it toggles the sorting order;
     * otherwise, it sets the order to ascending.
     * Next, it updates the sorting order state (sortOrder) with the new column and order. Then, it creates a copy of 
     * the filtered records and sorts them based on the selected column and order using the localeCompare method for
     * string comparison. Finally, it updates the state of filtered records with the sorted list.
     */
    const handleSort = (columnName) => {
        // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = filteredRecord.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName].localeCompare(b[columnName]);
            } else {
                return b[columnName].localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setFilteredRecord(sortedRecords);
    };

   /**
     * Pseudocode No:PC_SP_127 TO 137
     * The handleDelete function is responsible for deleting a record from the dataset. Upon invocation, it first displays a loading 
     * spinner to indicate ongoing processing. It then constructs a request body containing necessary data for the deletion operation, 
     * such as project ID, user ID, and action type.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token 
     * for authentication. Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), 
     * it triggers a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. 
     * Otherwise, upon successful deletion, it resets the data for the deleted record, clears the reason for deletion, and updates the dataset with the new data.
     */
    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "transformation",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "delete",
            "comments": reason,
            "index": deleteRow,
            notebookId:props.data.notebookId

        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            });
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].pseudoCode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].pseudoCode))
            setwhetherUpdated(true)
        }
    };
      /**
     * Pseudocode No:PC_SP_101 TO 113
     * The handleEdit function manages the editing process for a record in the dataset. Upon invocation, it displays a loading spinner to indicate 
     * ongoing processing. It then constructs a request body containing necessary data for the edit operation, such as project ID, user ID, action type, 
     * comments, updated data (newData), and the index of the editable row.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. 
     * Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers 
     * a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon 
     * successful edit, it resets the data for the edited record, clears the reason for edit, and updates the dataset with the new data.
     */
    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "transformation",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": newData,
            "index": editableRow,
            notebookId:props.data.notebookId

        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            })
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].pseudoCode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].pseudoCode))
            setwhetherUpdated(true)
        }
    };
    /**
     * Pseudocode No:PC_SP_114 TO 126
     * The handleAdd function is responsible for adding a new record to the dataset. Upon invocation, it displays a loading spinner to indicate ongoing processing. 
     * It then constructs a request body containing necessary data for the addition operation, such as project ID, user ID, action type, comments, and the new data (newData).
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. Upon receiving a response, 
     * it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers a session expiration function. 
     * If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon successful addition, it resets the data for the new record, 
     * clears the reason for addition, and updates the dataset with the new data.
     */
    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "transformation",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "add",
            "comments": reason,
            "value": newData,
            notebookId:props.data.notebookId

        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            })
            setReason("")
            console.log(res.data.recordset[0].pseudocode, "res.data.recordset[0].pseudocode")
            setSetVal(JSON.parse(res.data.recordset[0].pseudoCode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].pseudoCode))
            setwhetherUpdated(true)
        }
    };


    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
              <HeaderCom value ='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <div className="btn-group me-3">
                            <button
                                className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => setShowFilter(true)}
                            >
                                <img
                                    src="images/filter-icon.svg"
                                    alt="filter-icon"
                                    className="filt-icon"
                                />
                            </button>
                            <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3  ${showFilter == true ? 'show' : ''}`} style={{ transform: 'translate(-353px, 42px) !important;', width: '466px', float: 'right' }}>
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="font-18 font-bold text-black mb-0" >
                                            Filter
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setShowFilter(false)}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label text-black font-14 font-medium">
                                                    Pipeline Name
                                                </label>
                                                <select
                                                    className="form-select cust-input-sty font-14 font-regular"
                                                    aria-label="Default select example"
                                                    value={filterCriteria.pipelineName}
                                                    onChange={(e) => { setFilterCriteria({ ...filterCriteria, pipelineName: e.target.value }) }}>
                                                    <option value="Select">Select</option>
                                                    {BindPipelineName()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="dataflowName"
                                                    className="form-label text-black font-14 font-medium">
                                                    DataFlow Name
                                                </label>
                                                <select
                                                    className="form-select cust-input-sty font-14 font-regular"
                                                    aria-label="Default select example"
                                                    value={filterCriteria.dataflowName}
                                                    onChange={(e) => { setFilterCriteria({ ...filterCriteria, dataflowName: e.target.value }) }}>
                                                    <option value="Select">Select</option>
                                                    {bindDataflowName()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="dataflowName"
                                                    className="form-label text-black font-14 font-medium">
                                                    Table Name
                                                </label>
                                                <select
                                                    className="form-select cust-input-sty font-14 font-regular"
                                                    aria-label="Default select example"
                                                    value={filterCriteria.tableName}
                                                    onChange={(e) => { setFilterCriteria({ ...filterCriteria, tableName: e.target.value }) }}>
                                                    <option value="Select">Select</option>
                                                    {bindTableName()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={() => handleClearFilter()}
                                            >
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                disabled={filterCriteria.dataflowName === 'Select' && filterCriteria.pipelineName === 'Select' && filterCriteria.tableName === 'Select'}
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={() => handleApplyFilter()}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                   
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">RequirementID</th>
                                                <th className="text-nowrap">DesignID</th>
                                                <th className="text-nowrap" >SNo</th>
                                                <th className="text-nowrap" onClick={() => handleSort('PipelineName')}>
                                                    PipelineName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PipelineName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PipelineName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('TableName')}>
                                                    TableName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "TableName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('TableName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('DataflowName')}>
                                                    DataflowName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "DataflowName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('DataflowName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ComponentName')}>
                                                    ComponentName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ComponentName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ComponentName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ComponentType')}>
                                                    ComponentType
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ComponentType" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ComponentType')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ParameterName')}>
                                                    ParameterName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ParameterName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ParameterName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('Configuration')}>
                                                    Configuration
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "Configuration" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('Configuration')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('PostComponentName')}>
                                                    PostComponentName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PostComponentName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PostComponentName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('PostComponentType')}>
                                                    PostComponentType
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PostComponentType" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PostComponentType')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('SinkOrder')}>
                                                    SinkOrder
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "SinkOrder" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('SinkOrder')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap">Actions</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {editableRow === "empty" ? (
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter RequirementID"
                                                            name="RequirementID"
                                                            value={newData?.RequirementID}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter DesignID"
                                                            name="DesignID"
                                                            value={newData?.DesignID}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter SNo"
                                                            style={{marginRight : "50px"}}
                                                            name="SNo"
                                                            value={newData?.SNo}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PipelineName"
                                                            name="PipelineName"
                                                            value={newData?.PipelineName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter TableName"
                                                            name="TableName"
                                                            value={newData?.TableName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter DataflowName"
                                                            name="DataflowName"
                                                            value={newData?.DataflowName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ComponentName"
                                                            name="ComponentName"
                                                            value={newData?.ComponentName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ComponentType"
                                                            name="ComponentType"
                                                            value={newData?.ComponentType}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ParameterName"
                                                            name="ParameterName"
                                                            value={newData?.ParameterName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter Configuration"
                                                            name="Configuration"
                                                            value={newData?.Configuration}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PostComponentName"
                                                            name="PostComponentName"
                                                            value={newData?.PostComponentName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PostComponentType"
                                                            name="PostComponentType"
                                                            value={newData?.PostComponentType}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter SinkOrder"
                                                            name="SinkOrder"
                                                            value={newData?.SinkOrder}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <img
                                                            src="images/add-icons.svg"
                                                            alt="add-icon"
                                                            className="cust-cursor-pointer"
                                                            title="Add"
                                                            onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("add"); document.getElementById('openReasson').click() } }}
                                                        />
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredRecord.length}</span></p>
                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SilverPipeline;