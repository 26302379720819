import { useEffect, useState } from "react";
/*PS_CG_1.1*/
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { insertError, DefineDataSave } from "../Service/Api.js";
import * as XLSX from "xlsx";
import moment from "moment/moment.js";
import * as FileSaver from "file-saver";
function TableReqPop(props) {

    const [selectedFileName, setSelectedFileName] = useState("");
    const [editHTML, seteditHTML] = useState(true);
    const [tableReqData, setTableReqData] = useState({});
    const [validationErrors, setValidationErrors] = useState([]);
    const [futureState, setFutureState] = useState("");
    const [tableReqDetails, setTableReqDetails] = useState({});
    const [show, setShow] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [enable, setEnable] = useState(true)

    const { state } = useLocation();
    if (state == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        setShow(() => true)
        if (props.fileName != '') {
            setFormValid(true);
            setTableReqData({ fileName: props.fileName})
        }
        console.log('tableReqDetails', tableReqDetails)
    }, []);

    console.log(props);




    const handleFileUpload = async (event) => {
        console.log(event.target.files[0]);
        return new Promise((resolve, reject) => {
            try {
                // Reset validation errors and form validity at the start
                setValidationErrors([]);
                setFormValid(true);
                
                const selectedFile = event.target.files[0];
                if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
                    setValidationErrors([
                        "Invalid file format. Please upload a .xlsx file.",
                    ]);
                    setFormValid(false);
                    return;
                }
    
                const fileName = selectedFile.name;
                setSelectedFileName(fileName); // Update the selected file name
                setTableReqData({ ...tableReqData, fileName: fileName }); // Update the logo display state
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });
                        const visibleSheetNames = workbook.SheetNames.filter((name) => {
                            const sheet = workbook.Sheets[name];
                            return !sheet.Hidden || sheet.Hidden !== 2;
                        });
    
                        const mandatorySheets = ["TableRequirement"]; // Example array of mandatory sheet names
                        const missingSheets = mandatorySheets.filter(
                            (sheet) => !visibleSheetNames.includes(sheet)
                        );
    
                        if (missingSheets.length > 0) {
                            setValidationErrors([`Missing mandatory sheets: ${missingSheets.join(", ")}`]);
                            setFormValid(false);
                            return;
                        }
    
                        const getColumnIndex = (sheet, fieldName) => {
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            let columnIndex = -1;
    
                            for (let C = range.s.c; C <= range.e.c; ++C) {
                                const address = XLSX.utils.encode_cell({ r: range.s.r, c: C });
                                const cell = sheet[address];
    
                                if (cell && cell.v === fieldName) {
                                    columnIndex = C;
                                    break;
                                }
                            }
    
                            if (columnIndex === -1) {
                                console.log(`Column with field name '${fieldName}' not found`);
                                console.log("Sheet:", sheet);
                                console.log("Range:", range);
                            }
    
                            return columnIndex;
                        };
    
                        const displayValidationError = (
                            rowIndex,
                            columnIndex,
                            fieldName,
                            errorCode,
                            errorMessage,
                            dataType
                        ) => {
                            const errorDetails =
                                errorMessage +
                                ` (${typeof columnIndex === "number"
                                    ? String.fromCharCode(65 + columnIndex)
                                    : columnIndex
                                }${rowIndex + 1})`;
                            addValidationError(errorDetails);
                            setFormValid(false);
                        };
    
                        const addValidationError = (message) => {
                            setValidationErrors((prevErrors) => [...prevErrors, message]);
                            setFormValid(false);
                        };
    
                        console.log("workbook.SheetNames", workbook.SheetNames);
    
                        const allErrors = [];
                        for (const sheetName of workbook.SheetNames) {
                            console.log(`Checking sheet: ${sheetName}`);
                            const sheet = workbook.Sheets[sheetName];
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            const errors = [];
    
                            if (sheetName === "TableRequirement") {
                                console.log("entered TableRequirement");
                                const expectedHeaders = [
                                    "SourceSystemName",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "TableRequirements"
                                ];
    
                                const normalizeHeader = (header) => header.replace(/\s+/g, '');
                                const normalizedExpectedHeaders = expectedHeaders.map(normalizeHeader);
    
                                const range = XLSX.utils.decode_range(sheet['!ref']);
    
                                for (let i = 0; i < normalizedExpectedHeaders.length; i++) {
                                    const headerField = normalizedExpectedHeaders[i];
                                    const columnHeader = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
    
                                    if (!columnHeader || normalizeHeader(columnHeader.v) !== headerField) {
                                        setFormValid(false);
                                        errors.push(`Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`);
                                        displayValidationError(0, i, headerField, "MISSING", `Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`, "string");
                                    }
                                }
    
                                const fieldsNotToBeEmpty = [
                                    "SourceSystemName",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "TableRequirements"
                                ];
    
                                for (const fieldName of fieldsNotToBeEmpty) {
                                    const columnIndex = getColumnIndex(sheet, normalizeHeader(fieldName));
    
                                    if (columnIndex === -1) {
                                        console.log(`Column index for field '${fieldName}' not found.`);
                                        continue;
                                    }
    
                                    for (let i = 1; i <= range.e.r; i++) {
                                        const cell = sheet[XLSX.utils.encode_cell({ r: i, c: columnIndex })];
                                        const cellValue = cell !== undefined && cell.v !== undefined ? cell.v : null;
    
                                        if (cellValue == null || cellValue == undefined || (typeof cellValue === "string" && cellValue.trim() === "")) {
                                            setFormValid(false);
                                            errors.push(`${fieldName} value should not be empty in ${sheetName} in row ${i}`);
                                            displayValidationError(i, columnIndex, fieldName, "EM004", `${fieldName} value should not be empty in ${sheetName}`, "string");
                                        }
                                    }
                                }
                            }
    
                            if (errors.length > 0) {
                                console.log(`Validation errors found in sheet ${sheetName}:`, errors);
                                allErrors.push(...errors);
                                setFormValid(false);
                            }
                        }
                        console.log("formValid", formValid);
    
                        const jsonData = convertExcelToJSON(workbook); // Convert the entire workbook to JSON
                        if (allErrors.length === 0) {
                            setFormValid(true); // Set formValid to true if there are no errors
                        }
                        resolve(jsonData);
                    } catch (error) {
                        console.error("Error reading the Excel file:", error);
                        setValidationErrors(["Error reading the Excel file."]);
                    }
                };
    
                reader.readAsBinaryString(selectedFile);
            } catch (error) {
                console.error("Error processing the file:", error);
                setValidationErrors(["Error processing the file."]);
            }
            document.getElementById("definedocumentid").value = "";
        });
    };
    function convertExcelToJSON(workbook) {
        let updatedDefineDetails = { ...tableReqDetails }; // Make a copy of the current state

        workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            const sheetData = [];

            jsonData.forEach((row) => {
                const rowData = {};

                Object.keys(row).forEach((cell) => {
                    const header = cell.trim();
                    const value = row[cell];
                    rowData[header] = value;
                });

                sheetData.push(rowData);
            });

            // Remove duplicates from sheetData
            const uniqueSheetData = sheetData.filter(
                (item, index, self) => index === self.findIndex((t) => (
                    JSON.stringify(t) === JSON.stringify(item)
                ))
            );

          if (sheetName.includes("TableRequirement")) {
                updatedDefineDetails = {
                    ...updatedDefineDetails,
                    tableRequirement: [
                        ...(updatedDefineDetails.tableRequirement || []),
                        ...uniqueSheetData,
                    ],
                };
            } 
        });

        console.log(updatedDefineDetails);

        setTableReqDetails(updatedDefineDetails);
        // console.log("formValid", formValid);
    }

    const submitDetails = async () => {
        console.log("entered submitDetails");
        try {
            setShow(() => false)
            props.close()
            document.getElementById("pageLoader").style.display = "block";
            //   const definerecord = tableReqDetails;
            const body = {
                sheetName: tableReqData.fileName,
                data: tableReqDetails,
                userId: state.userId,
                projectId: state.projectId,
                type:"tableRequirement"
            };
            console.log("body", body);
            console.log("state.jwt",state.jwt)
            const responseData = await DefineDataSave(
                btoa(unescape(encodeURIComponent(JSON.stringify(body)))),
                state.jwt
            );
            setShow(() => false);
            document.getElementById("pageLoader").style.display = "none";
            console.log("responseData", responseData);
            console.log(state.jwt,"jwtttt")
             
            if (responseData.statusCode == 200) {
                props.onChange({
                    data: responseData,
                    status: 200,
                    filename: tableReqData.fileName
                   
                });
            } else {
                props.onChange({ status: 400 });
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "TableReqPop",
                functionName: "DefineDataSave",
                userId: "",
            });
            console.error(error);
        }
    };

    const exportToExcel = async () => {
        try {
            //  
            console.log(futureState);
         
            let TableRequirement = [];
          
            let ws1
              
            let wb;

            TableRequirement = [
                {
                    "SourceSystemName": "SAPS4HANA",
                    "SubjectArea": "Sales",
                    "DatabaseName": "SAP",
                    "SchemaName": "saps4ha",
                    "TableName": "KNVP",
                    "TableRequirements": "The KNVP table contains customer partner information, including customer numbers and partner numbers."
                },
                {
                    "SourceSystemName": "SAPS4HANA",
                    "SubjectArea": "Sales",
                    "DatabaseName": "SAP",
                    "SchemaName": "saps4ha",
                    "TableName": "KNVK",
                    "TableRequirements": "The KNVK table stores contact person information for customers, including names and deletion/blocking flags."
                },
                {
                    "SourceSystemName": "SAPS4HANA",
                    "SubjectArea": "Sales",
                    "DatabaseName": "SAP",
                    "SchemaName": "saps4ha",
                    "TableName": "PS_ROG_CORE",
                    "TableRequirements": "The PS_ROG_CORE table contains region information, including region IDs."
                }
            ];
         

            ws1 = XLSX.utils.json_to_sheet(TableRequirement);



            wb = {
                Sheets: {
                    "TableRequirement": ws1,
                },
                SheetNames: [                  
                    "TableRequirement",
                ],
            };

            // Check if XLSX object and its writeFile method are defined
            if (XLSX && XLSX.writeFile) {
                // Generate Excel file from sheet object
                XLSX.writeFile(wb, "TableRequirement SampleData.xlsx");

                // Save the Excel file using FileSaver
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                /* The code is creating a new Blob object using the `Blob` constructor in JavaScript. The
                `excelBuffer` variable is being passed as the data parameter to the Blob constructor. This
                code snippet is likely part of a larger application that is working with binary data, such as
                creating a Blob object to store Excel data. */
                const data = new Blob([excelBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                // FileSaver.saveAs(data, 'AllData.xlsx');
                return excelBuffer;
            } else {
                throw new Error("XLSX object or its writeFile method is undefined.");
            }
        } catch (error) {
            console.error("Error exporting to Excel:", error);
            // Handle the error gracefully (e.g., show an error message to the user)
        }
    };






    return (
        <div className="container-fluid">
            <div style={editHTML == false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Confrm" hidden>
                   TableRequirements
                </button>
                <div className={`modal fade ${show ? "show" : ""}`} id="Confrm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true" style={show ? { 'display': "block", "paddingLeft": '0px' } : {}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">TableRequirements Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close(); setShow(() => false) }}></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row mt-3">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label htmlFor className="form-label text-black font-14 font-medium">Upload TableRequirements Documentation</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block">
                                                {!tableReqData.fileName ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                            Use this template to add info -{" "}
                                                            <a onClick={() => { exportToExcel(); }} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                Download Template
                                                            </a>{" "}
                                                        </span>
                                                    </button>
                                                ) : (

                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{tableReqData.fileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100" }}
                                                            onClick={() => {
                                                                setTableReqData({}); setValidationErrors([]); setFormValid(false);  document.getElementById('definedocumentid').value = ''
                                                            }}
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>

                                                    </button>
                                                )}
                                                <input type="file" name="myfile" className="cursor-pointer cust-file" accept=".xlsx" id="definedocumentid" value={tableReqData.file} onChange={(event) => { handleFileUpload(event); setEnable(false) }} title={tableReqData.fileName} />
                                            </span>
                                            {validationErrors.length > 0 ? (
                                                <div className="code-doc-error">
                                                    {validationErrors.map((data, index) => (
                                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                            <span className="ms-2">{data}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4">
                                <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setShow(() => false)
                                        props.close();
                                        setTableReqData("");
                                        setValidationErrors([]);
                                    }}>
                                    Cancel
                                </button>
                                <button type="button" data-bs-dismiss="modal" disabled={
                    formValid == false  || validationErrors.length != 0 ? true : false
                  } className="btn cust-primary-btn font-14 font-medium"
                                    onClick={() => { submitDetails(); }}>
                                    <span>Save Details</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {show && <div class="modal-backdrop show"></div>}
            </div>
        </div>

    )

}
export default TableReqPop;