// PS_MO_02
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { postOrganization, getOrganization, isActiveOrganization, editOrganization, insertError } from '../Service/Api.js';
import { HeaderCom } from './Header.jsx';

const ManageOrganizations = (props) => {
    const navigate = useNavigate();
    const [organizationList, setOrganizationList] = useState([]);
    const [filteredOrganizationList, setFilteredOrganizationList] = useState([]);
    const [newOrg, setNewOrg] = useState({ orgName: '', clientBusiness: "", orgLogo: null, orgLogoName: "", isEdited: false });
    const [loader, setLoader] = useState(false);
    const [menuVisible, setMenuVisible] = useState([]);
    const [isActive, setIsActive] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [verticalIndustryName, setVerticalIndustryName] = useState('')
    const [orgName, setOrganizationName] = useState('');
    const [clientBusiness, setClientBusiness] = useState("");
    const [organizationId, setOrganizationId] = useState(null);
    const [editHTML, seteditHTML] = useState(true)
    const [dataPresent, setDataPresent] = useState(false)


    let { state } = useLocation()
    let defaultuser = { userName: state?.userName, userMailId: state?.userMailId, adRole: state?.role, adUserId: state?.userId, domain: state?.domain, role: "Project Lead" }


    if (state == null) {
        window.location.href = '/'
    }


    // PS_MO_03

    useEffect(() => {
        const initialize = async () => {
            // Show the loader


            try {
                document.getElementById('pageLoader').style.display = 'block';
                // Await the asynchronous operation

                await fetchOrganization(state.userId, state.adRole);

                document.getElementById('pageLoader').style.display = 'none';
            } catch (error) {
                console.error("Error fetching organization:", error);
            } finally {
                // Hide the loader
                document.getElementById('pageLoader').style.display = 'none';

            }
        };
        initialize();
    }, [state]);




    /* // PS_MO_47
    *PS_MO_27 to PS_MO_47
    This code snippet appears to be part of a JavaScript program, likely within a React or similar web development framework. It fetches organization
     data asynchronously using the getOrganization function, updates the display by toggling the visibility of a loader element, and then processes and 
     updates the application state based on the received organization data. 
    The state includes lists of organizations, filtered organizations, and an array indicating the activity status of each organization. The code employs async/await for asynchronous operations and utilizes various DOM manipulations and state-setting functions. Error handling is not explicitly shown, and the code logs relevant information to the console for debugging purposes.
    */
    const fetchOrganization = async (userId, adRole) => {
        try {
            document.getElementById('pageLoader').style.display = 'block';
            const organizationData = await getOrganization(userId, adRole, state.jwt);

            if (organizationData.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (organizationData.statusCode === 200) {
                const responseData = organizationData.data || [];

                if (responseData.length === 0) {
                    // No organizations exist
                    setOrganizationList([]);
                    setFilteredOrganizationList([]);
                    setDataPresent(false);
                    return;
                }

                let orgs = responseData.map((obj, index) => ({
                    ...obj,
                    index: index
                }));

                setOrganizationList(orgs);
                setIsActive(orgs.map(org => org.isActive));

                let filteredData;
                if (searchTerm && searchTerm.length >= 3) {
                    filteredData = orgs.filter(
                        org =>
                            org.orgName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                            org.clientBusiness?.toLowerCase().includes(searchTerm?.toLowerCase())
                    );

                    setFilteredOrganizationList(filteredData);
                    setDataPresent(filteredData.length > 0);
                } else {
                    setDataPresent(orgs.length > 0);
                    setFilteredOrganizationList(orgs);
                }
            } else {
                setDataPresent(false);
            }
            document.getElementById('pageLoader').style.display = 'none';

        } catch (error) {
            console.error(error.message);
            let data = {
                errorMessage: error.errorMessage,
                serviceName: "frontend",
                module: "Manageorg",
                functionName: "fetchOrganization",
                userId: state.userId
            };
            insertError(data);
        }
    };




    /*
  PS_MO_49 to PS_MO_51
  When the user clicks the New organization button the handleNewOrgClick() method is triggered
  Inside the function set state the NewOrg as object containing orgName as empty string,orgLogo as null 
  and isEdited as false
  */
    const handleNewOrgClick = () => {
        try {
            setNewOrg({ orgName: '', clientBusiness: '', orgLogo: null, isEdited: false, orgId: null });
            setOrganizationName(''); // Reset organizationName state as well
            setClientBusiness('')
            setVerticalIndustryName('')
            console.log("state in new org", state);
            navigate(`/newOrg`, { state: { ...state, isEdit: false, orgId: null, adUserId: state.adUserId, orgName: newOrg.orgName, orgLogo: newOrg.orgLogo, userId: state.userId, jwt: state.jwt, varticalIndustryId: null } });
        } catch (error) {
            console.log(error, "Error creating in new organization")
        }

    };

    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        /* The above code is using JavaScript to clear all data stored in the localStorage. This means
        that any key-value pairs previously stored in the localStorage will be removed. */
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"
        // PS_MO_31
        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            navigate('/');
        }, 3000);
    };
    /*PS_MO_53 to PS_MO_58
     The function handleToggleOptionsMenu takes an index and a visibility flag as parameters. 
     It creates a copy of the current menuVisible array, updates the visibility at the specified index, 
     and sets the state of menuVisible to the modified array. This is likely used to control
      the visibility of options menus for items in a list.
     */
    const handleToggleOptionsMenu = (index, isVisible) => {
        // // PS_MO_54
        const updatedMenuVisible = [...menuVisible];
        //
        updatedMenuVisible[index] = isVisible;
        //PS_MO_57
        setMenuVisible(updatedMenuVisible);
    };

    /*PS_MO_59
    The handleOrgActiveStatus function toggles the active status of an organization identified by orgId. 
    It begins by displaying a page loader, then calls the isActiveOrganization API to update the organization's 
    status using the provided parameters. After updating the local state with the new status, it triggers a fetch to 
    update the organization list for the user. Finally, it hides the page loader. If an error occurs during this process, 
    it is caught and logged. The code is structured to handle the asynchronous nature of API calls, ensuring smooth user 
    experience while managing organization state updates in a React application.
    */
    const handleOrgActiveStatus = async (orgId, index) => {

        try {
            // PS_MO_61

            document.getElementById('pageLoader').style.display = 'block'
            // PS_MO_62
            const updatedOrganization = await isActiveOrganization(orgId, isActive[index], state.userId, state.jwt);
            // PS_MO_80
            if (updatedOrganization.statusCode == 403) {
                // PS_MO_81
                sessionExpired()
                return;
            }



            //
            /* The above code is toggling the boolean value at the specified index in the `isActive`
            array and updating the corresponding value in the `updatedActiveOrganizations` array. If
            the value at the index in `isActive` is `true`, it will be set to `false` in
            `updatedActiveOrganizations`, and vice versa. */



            // PS_MO_85
            await fetchOrganization(state.userId, state.adRole)
            //
            document.getElementById('pageLoader').style.display = 'none'
        }
        //
        catch (error) {
            console.error('Error toggling active status:', error);
            let data = {
                errorMessage: error.errorMessage,
                serviceName: "frontend",
                module: "Manageorg",
                functionName: "isactiveorg",
                userId: state.userId
            }
            insertError(data)
        }
    };




    /*PS_MO_99 - PS_MO_102
    The handleEditInfo function is an asynchronous function designed for editing organization information. 
    It triggers a loading indicator, calls the editOrganization API with a specified organization ID, and 
    updates the state based on the API response.It extracts the organization name from the response, sets it 
    in the newOrg state along with the organization ID and an edit flag. Additionally, it stores the organization ID and
     name separately in the state. Finally, it hides
    the loading indicator upon completion. The function includes error handling to log and handle any encountered errors during
    the API call, ensuring robustness in the organization editing process.
    */
    const handleEditInfo = async (orgId) => {
        // PS_MO_101
        navigate(`/newOrg`, { state: { ...state, isEdit: true, orgId, orgName: newOrg.orgName, orgLogo: newOrg.orgLogo, userId: state.userId, jwt: state.jwt } });


    };



    /*PS_MO_103 to PS_MO_106
    The handleViewProject function navigates to the /manageProjects route, 
    passing relevant data such as organization ID and user information.
    */
    const handleViewProject = (orgId, role) => {

        navigate(`/manageProjects`, { state: { ...state, role: role, orgId, orgName: newOrg.orgName, orgLogo: newOrg.orgLogo, userId: state.userId, jwt: state.jwt, userName: state.userName, adRole: state.adRole } });
    };

    /*
    
The handleSearch function filters the organizationList based on a search term. 
If the term has more than three characters, it creates a case-insensitive filtered list 
using organization names that include the term. Otherwise, it sets the filtered list to the
 entire organizationList. The function also updates the search term state for reference.
    */
    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);
    /*PS_MO_107 to PS_MO_113
 
The handleSearch function filters the organizationList based on a search term. 
If the term has more than three characters, it creates a case-insensitive filtered list 
using organization names that include the term. Otherwise, it sets the filtered list to the
entire organizationList. The function also updates the search term state for reference.
 */
    const handleSearch = (term) => {
        setSearchTerm(term);
        if (searchTerm.length >= 3) {
            const filteredData = organizationList.filter(
                (org) =>
                    org.orgName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    org.clientBusiness.toLowerCase().includes(searchTerm.toLowerCase())
            );
            // PS_MO_112
            setFilteredOrganizationList(filteredData);
            setDataPresent(filteredData.length > 0); // Update dataPresent based on filtered data
        } else {
            setFilteredOrganizationList(organizationList);
            setDataPresent(true); // If term length is less than 3, assume data is present
        }
    };



    return (
        <div className="container-fluid">
            <div style={editHTML === false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                {/* <div id="toastMessage" class="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                {/* header style starts here */}
                {/* <HeaderCom value='1' /> */}
                {/* header style ends here */}
                <HeaderCom value="1" />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="row p-4">
                        <div className="d-flex mb-5 justify-content-between align-items-center">
                            <h2 className="text-black font-bold font-22 mb-0">Manage Organizations</h2>
                            <div className="d-flex">
                                {/* <div className="input-group position-relative search-wid me-3">
                                    
                                    <input
                                        type="text"
                                        style={{ paddingRight: '30px' }}
                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                    <span className="search-icon-sty">
                                        <a >
                                            <img src="images/search-icon.svg" alt="search icon" />
                                        </a>
                                    </span>
                                </div> */}
                                <div class="input-group position-relative search-wid me-md-3">
                                    <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                        placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                    <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                </div>

                                {(state.adRole == 1 || state.adRole == 2) ? (
                                    <button
                                        type="button"
                                        className="btn cust-primary-btn font-14 font-medium px-3 text-nowrap"
                                        // data-bs-toggle="modal"
                                        data-bs-target="#CreateNewOrg"
                                        onClick={() => handleNewOrgClick()}
                                    >
                                        <span>
                                            <img src="images/plus-icon-white.svg" alt="plus-icon-white" className="me-2" />
                                        </span>
                                        Add Organization
                                    </button>)
                                    : (<></>)}
                            </div>
                        </div>
                        {filteredOrganizationList.length === 0 ? (
                            <div className="col-md-12">
                                <div className="d-flex justify-content-center flex-column color-white-bg rounded-bottom pt-100 pb-400 mb-5 mt-5">
                                    <div className="d-flex justify-content-center mb-5">
                                        <img src="images/no-records.svg" alt="No-Records-Found" className="d-block mt-5" />
                                    </div>
                                    {searchTerm !== "" && searchTerm.length >= 3 ? (
                                        <p className="d-flex font-22 font-medium text-grey-v3 mb-1 justify-content-center">
                                            No organization found!
                                        </p>
                                    ) : (
                                        <>
                                            <p className="d-flex font-22 font-medium text-grey-v3 mb-1 justify-content-center">
                                                It looks like you haven't added any organizations yet. To begin,{" "}
                                            </p>
                                            <p className="d-flex font-22 font-medium text-grey-v3 justify-content-center">
                                                click on the Add Organization
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            filteredOrganizationList?.map((org, index) => (
                                <div
                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                                    key={org.orgId}
                                    onMouseEnter={() => handleToggleOptionsMenu(index, true)}
                                    onMouseLeave={() => handleToggleOptionsMenu(index, false)}
                                >
                                    <div className="card h-100 card-style cursor-pointer">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <span>
                                                    <img
                                                        src={org.orgLogo ? org.orgLogo : 'images/Gen-ETL.svg'}
                                                        alt={org.orgLogo ? "organization-logo" : "default-organization-logo"}
                                                        className="card-logo me-2"
                                                    />
                                                </span>
                                                <label className="font-18 font-bold text-black me-2">{org.orgName}</label>
                                                <span>
                                                    {org.isActive ? (
                                                        <img src="images/active-organization.svg" alt="active-organization" className="mb-1" />
                                                    ) : (
                                                        <img src="images/inactive-organization.svg" alt="inactive-organization" className="mb-1" />
                                                    )}
                                                </span>
                                            </div>
                                            {(state.adRole == 1 || org.role == "Project Lead") ? (
                                                <div className="kebab-icon">
                                                    <button
                                                        style={{ display: menuVisible[index] ? 'block' : 'none' }}
                                                        className="btn border-0 p-0 shadow-none"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img src="images/kebab-icon.svg" alt="kebab-icon" onClick={() => handleToggleOptionsMenu(index, true)} />
                                                    </button>
                                                    <ul className="dropdown-menu custom-dropdown-sty border-0 p-2">
                                                        <li className="pb-1">
                                                            <a
                                                                className="dropdown-item custom-dropdown-li py-2 px-2 font-14 font-medium"
                                                                onClick={() => handleEditInfo(org.orgId)}
                                                            >
                                                                <img src="images/dscg-edit-icon.svg" id='CreateNewOrg1' alt="Edit-Dropdown" className="me-2" />
                                                                Edit
                                                            </a>
                                                        </li>
                                                        <li className="pt-1">
                                                            <a
                                                                className="dropdown-item py-2 px-2 custom-dropdown-li font-14 font-medium"
                                                                onClick={() => handleOrgActiveStatus(org.orgId, org.index)}
                                                            >
                                                                {isActive[org.index] ? (
                                                                    <img src="images/mark-inactive-icon.svg" alt="inactive-logo" className="mb-2" />
                                                                ) : (
                                                                    <img src="images/org-active-icon.svg" alt="active-logo" className="mb-2" />
                                                                )}
                                                                {isActive[org.index]
                                                                    ? ' Mark as Inactive'
                                                                    : ' Mark as Active'}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : (<></>)}
                                            <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
                                                <label className="font-14 font-medium text-grey-v2">
                                                    Total Projects
                                                    <span className="font-18 font-bold text-black-v2 ms-2">{org.projectCount}</span>
                                                </label>
                                                <button
                                                    type="button"
                                                    className="btn cust-gray-btn font-14 font-medium px-3"
                                                    onClick={() => handleViewProject(org.orgId, org.role)}
                                                    disabled={!org.isActive}
                                                >
                                                    View Project
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}


                    </div>
                </div>
            </div>

        </div>
    )

}

export default ManageOrganizations;