// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /* table style starts here */
 .custom-grid {
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
}
.custom-grid thead tr {
  border-bottom: 0.5px solid #EAEAEA;
  background-color: #F2EEFC;
}
.custom-grid thead tr th{
  background-color: #F2EEFC;
}
.custom-grid th {
  padding: 19px 18px;
  font-size: 14px;
  font-family: 'DMSans-Regular';
  color: #625B76;
}
.custom-grid tbody td {
  padding: 19px 18px;
  font-size: 14px;
  font-family: 'DMSans-Regular';
  color: #282828;
  vertical-align: middle;
}
.custom-grid tbody tr {
  border-bottom: 0.5px solid #EAEAEA;
}
.approved-badge{
  background: #DCFFD4;
  color:#03A000;
}

.rejected-badge{
  background:#FFE8E1;
  color:#AA1400;
}
/* table style ends here */`, "",{"version":3,"sources":["webpack://./src/Styles/css/grid.css"],"names":[],"mappings":"CAAC,4BAA4B;CAC5B;EACC,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,kCAAkC;EAClC,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,6BAA6B;EAC7B,cAAc;EACd,sBAAsB;AACxB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA,0BAA0B","sourcesContent":[" /* table style starts here */\n .custom-grid {\n  white-space: nowrap;\n  background-color: #FFFFFF;\n  border-radius: 4px;\n  border: 1px solid #EAEAEA;\n}\n.custom-grid thead tr {\n  border-bottom: 0.5px solid #EAEAEA;\n  background-color: #F2EEFC;\n}\n.custom-grid thead tr th{\n  background-color: #F2EEFC;\n}\n.custom-grid th {\n  padding: 19px 18px;\n  font-size: 14px;\n  font-family: 'DMSans-Regular';\n  color: #625B76;\n}\n.custom-grid tbody td {\n  padding: 19px 18px;\n  font-size: 14px;\n  font-family: 'DMSans-Regular';\n  color: #282828;\n  vertical-align: middle;\n}\n.custom-grid tbody tr {\n  border-bottom: 0.5px solid #EAEAEA;\n}\n.approved-badge{\n  background: #DCFFD4;\n  color:#03A000;\n}\n\n.rejected-badge{\n  background:#FFE8E1;\n  color:#AA1400;\n}\n/* table style ends here */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
