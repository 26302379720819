// PS_CD_1.1
import React, { useState, useEffect, useRef } from "react";
// PS_CD_1.2
import { useLocation, useNavigate } from "react-router-dom";
import { getMetaDataValues, updateMetaValues, fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError } from "../Service/Api.js";
import { HeaderCom } from "./Header.jsx";
import * as XLSX from 'xlsx';

// PS_CM_02
function ColumnMappingRework(props) {
    const location = useLocation();
    const nav = useNavigate();
    let { state } = useLocation();
    const [bronzeJson, setBronzeJson] = useState([])
    const [silverJson, setSilverJson] = useState([])
    const [goldJson, setGoldJson] = useState([])
    const [bronzeResponse, setBronzeResponse] = useState([])
    const [silverResponse, setSilverResponse] = useState([])
    const [goldResponse, setGoldResponse] = useState([])


    const [jsonData, setJsonData] = useState([]);
    // PS_CD_1.6
    const [selectedItem, setSelectedItem] = useState({});
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)
    const [verificationStatusDropDown, setVerificationStatusDropDown] = useState(["Verified", "Not Verified"]);

    const [popupErrors, setPopupErrors] = useState({});
    const [isEditMetavalue, setisEditMetavalue] = useState(false);
    //PS_CD_1.62
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    // PS_CD_1.7
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: '' })

    const [formValid, setFormValid] = useState(false);
    const [DropDownSchemaName, setDropDownSchemaName] = useState([]);
    const [DropDownTableName, setDropDownTableName] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    // PS_CM_04
    const [bronzeEditingIndex, setBronzeEditingIndex] = useState(null);
    const [goldEditingIndex, setGoldEditingIndex] = useState(null);
    const [silverEditingIndex, setSilverEditingIndex] = useState(null);



    const [whetherUpdate, setWhetherUpdate] = useState(false);
     const [isAllVerified, setIsAllVerified] = useState(false);

    const [reason, setReason] = useState("");
    const [filterDestinationTableName, setFilterDestinationTableName] = useState([]);
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);


    const [bronze_InputValues, setBronze_InputValues] = useState({
        RequirementID: '',
        SubjectArea: 'Select',
        BronzeSchemaName: '',
        BronzeTableName: '',
        BronzeColumnName: '',
        BronzeDatatype: '',
        BronzeConstraints: '',
        SourceSystem: 'Select',
        SourceTableName: '',
        SourceColumnName: '',
        SourceDatatype: "",
        SourceConstraints: '',
        TransformationLogic: '',
        Comments: '',
        VerificationStatus: "Select"

    });
    const [silver_InputValues, setSilver_InputValues] = useState({
        RequirementID: '',
        SubjectArea: 'Select',
        SilverSchemaName: 'Select',
        SilverTableName: '',
        SilverColumnName: '',
        SilverDatatype: '',
        SilverConstraint: '',
        SourceSchemaName: '',
        SourceTableName: '',
        SourceColumnName: '',
        SourceDatatype: "",
        SourceConstraints: '',
        TransformationLogic: '',
        Comments: '',
        VerificationStatus: 'Select',


    });
    // PS_CM_03
    const [gold_InputValues, setgold_InputValues] = useState({
        RequirementID: '',
        SubjectArea: 'Select',
        GoldSchemaName: 'Select',
        GoldTableName: '',
        GoldColumnName: '',
        GoldDataType: '',
        Constraints: '',
        SourceSchemaName: '',
        SourceTableName: '',
        SourceColumnName: '',
        SourceDataType: "",
        SourceConstraints: '',
        TransformationLogic: '',
        Comments: '',
        VerificationStatus: 'Select',



    });


    const [filterGoldTableName, setFilterGoldTableName] = useState([])
    const [filterGoldSubjectArea, setFilterGoldSubjectArea] = useState([])
    const [filterSilverTableName, setFilterSilverTableName] = useState([])
    const [filterSilverSubjectArea, setFilterSilverSubjectArea] = useState([])
    const [filterBronzeTableName, setFilterBronzeTableName] = useState([])
    const [filterBronzeSubjectArea, setFilterBronzeSubjectArea] = useState([])
    const [selectedColumn, setSelectedColumn] = useState("bronze")

    const [DropDownSubjectArea, setDropDownSubjectArea] = useState([]);

    const [dropDownSourceSysName, setDropDownSourceSysName] = useState([])
    const [dropDownGoldSchemaName, setDropDownGoldSchemaName] = useState([])
    const [dropDownSilverSchemaName, setDropDownSilverSchemaName] = useState([])





    const [body, setBody] = useState({})
    const [filterCriteria, setFilterCriteria] = useState({
        BronzeTableName: "Select",
        SubjectArea: "Select",
        SilverTableName: "Select",
        GoldTableName: "Select"
    });



    let apiBody

    // PS_CM_05
    useEffect(() => {
        const fetchData = async () => {
            try {
                 
                // PS_CM_05
                const dropDownValuesPromise = fetchDropDown();
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [dropDownValues, pseudoData] = await Promise.all([dropDownValuesPromise, pseudoDataPromise]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // PS_CM_06
    const fetchDropDown = async () => {
        try {
             
            let data = { type: "column mapping", projectId: state.projectId }
            // PS_CM_07
            let dropDownValues = await fetchDropDownValues(data, state.jwt)
            console.log("resfrom fetchDropDownValues", dropDownValues.data);

            if (dropDownValues.statusCode == 200) {
                // PS_CM_19
                setDropDownSubjectArea(dropDownValues.data.subjectArea)
                setDropDownSourceSysName(dropDownValues.data.sourceSysName)
                setDropDownGoldSchemaName(dropDownValues.data.goldSchemaName)
                setDropDownSilverSchemaName(dropDownValues.data.silverSchemaName)
            }
            if (dropDownValues.status == 403) {
                // PS_CM_15
                sessionExpired()
            }

        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "columnmapping",
                functionName: "fetchdropdown",
                userId: state.userId
            });
            console.log("error in fetcgh drop down", e);
        }
    }
    // console.log("uniqueTableNames", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);
    // console.log("setSchemaName", schemaName, "setSubjectArea", subjectArea);
    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            nav('/');
        }, 3000);
    };
    // PS_CM_19
    const fetchPseudo = async () => {
        try {

            let data = { type: "columnMapping", projectId: state.projectId }
            // PS_CM_21
            document.getElementById('pageLoader').style.display = 'block';
            let pseudodata = await fetchPseudocodeData(data, state.jwt)
            document.getElementById('pageLoader').style.display = 'none';
            console.log(pseudodata, "pseudodata");

            if (pseudodata.statusCode == 200) {

                let bronze = JSON.parse(pseudodata.data.bronze[0].columnMappingBronze)
                let silver = JSON.parse(pseudodata.data.silver[0].columnMappingSilver)
                let gold = JSON.parse(pseudodata.data.gold[0].columnMappingGold)


                bronze = bronze.map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                silver = silver.map((obj, index) => ({
                    ...obj,
                    index: index
                })); gold = gold.map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                // PS_CM_34
                setBronzeJson(bronze)
                setBronzeResponse(bronze)
                setSilverJson(silver)
                setSilverResponse(silver)

                setGoldJson(gold)
                setGoldResponse(gold)

                const verifiedCount = [...bronze, ...silver, ...gold].filter(data => data?.VerificationStatus === "Verified")?.length;
                const totalCount = [...bronze, ...silver, ...gold]?.length;
                const isVerified = verifiedCount === totalCount ? true : false;
                console.log(verifiedCount,'verifiedCount')
                console.log(totalCount,'totalCount')
                console.log(isVerified,'isVerified')
               setIsAllVerified(isVerified)

                // filter options for bronze
                let uniqueBronzeSubjectAreas = [...new Set((bronze).map(item => item.SubjectArea))];
                let uniqueBronzeTableName = [...new Set((bronze).map(item => item.BronzeTableName))];
                setFilterBronzeSubjectArea(uniqueBronzeSubjectAreas)
                setFilterBronzeTableName(uniqueBronzeTableName)
                /// bronze end
                // PS_CM_36
                // filter options for silver
                let uniqueSilverSubjectAreas = [...new Set((silver).map(item => item.SubjectArea))];
                let uniqueSilverTableName = [...new Set((silver).map(item => item.SilverTableName))];
                // PS_CM_37
                setFilterSilverSubjectArea(uniqueSilverSubjectAreas)
                setFilterSilverTableName(uniqueSilverTableName)
                /// silver end
                // filter options for gold
                let uniqueGoldSubjectAreas = [...new Set((gold).map(item => item.SubjectArea))];
                let uniqueGoldTableName = [...new Set((gold).map(item => item.GoldTableName))];
                // PS_CM_38
                setFilterGoldSubjectArea(uniqueGoldSubjectAreas)
                setFilterGoldTableName(uniqueGoldTableName)
                /// gold end



            }

            // PS_CM_30

            if (pseudodata.statusCode == 403) {
                sessionExpired()
            }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "columnmapping",
                functionName: "fetchpseudo",
                userId: state.userId
            });
            console.log("error in fecth pseudo", e);
        }
    }

    const filterByAppliedFilters = async () => {
        {/* // PS_CD_1.51 */ }
        const { SubjectArea, BronzeTableName, SilverTableName, GoldTableName } = filterCriteria;
        // PS_CM_158
        if (selectedColumn == "bronze") {
            const filteredList = bronzeResponse.filter((record) => {

                const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
                const BronzeTableNameMatch = BronzeTableName === 'Select' || record.BronzeTableName.toLowerCase() === BronzeTableName.toLowerCase();

                return SubjectAreaMatch && BronzeTableNameMatch
            });
            // PS_CD_1.53
            // Set the filtered projectList to a separate state variable
            return filteredList

        }
        // PS_CM_159
        if (selectedColumn == "silver") {
            const filteredList = silverResponse.filter((record) => {

                const SilverTableNameMatch = SilverTableName === 'Select' || record.SilverTableName.toLowerCase() === SilverTableName.toLowerCase();

                return SilverTableNameMatch
            });
            // Set the filtered projectList to a separate state variable
            return filteredList

        }
        // PS_CM_160
        if (selectedColumn == "gold") {
            const filteredList = goldResponse.filter((record) => {

                const GoldTableNameMatch = GoldTableName === 'Select' || record.GoldTableName === GoldTableName;

                return GoldTableNameMatch
            });
            // PS_CD_1.53
            // Set the filtered projectList to a separate state variable
            return filteredList

        }
    };




    // PS_CM_70
    const handleSearch = async (term) => {
         
        if (searchTerm?.length >=3) {
            // If the search term is empty, reset to all 

            // Filter the based  based on search criteria  SubjectArea
            let filteredData;
            // PS_CM_74
            if (selectedColumn == "bronze") {
                filteredData = bronzeJson.filter(
                    (record) =>
                        (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.BronzeSchemaName && record?.BronzeSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.Bronzetablename && record?.Bronzetablename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.BronzeColumname && record?.BronzeColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                )

                setBronzeJson(filteredData)
            }
            if (selectedColumn == "silver") {
                filteredData = silverJson.filter(
                    (record) =>
                        (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SilverSchemaName && record?.SilverSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.Silvertablename && record?.Silvertablename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SilverColumname && record?.SilverColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                )
                setSilverJson(filteredData)


            }
            if (selectedColumn == "gold") {
                filteredData = goldJson.filter(
                    (record) =>
                        (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.GoldSchemaName && record?.GoldSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.GoldColumname && record?.GoldColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                )
                setGoldJson(filteredData)

            }


        } else {

            if (selectedColumn == "bronze") {
                let records = await filterByAppliedFilters()
                setBronzeJson(records)

            }
            if (selectedColumn == "silver") {
                let records = await filterByAppliedFilters()
                setSilverJson(records)

            }
            if (selectedColumn == "gold") {
                let records = await filterByAppliedFilters()
                setGoldJson(records)

            }
        }
    };
    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);
    // PS_CM_112
    const updatePseudocode = async (value) => {
        try {
            setWhetherUpdate(true)
            // setIsEditingData(false)
             
            // PS_CM_113
            document.getElementById('pageLoader').style.display = 'block';
            let pseudodata = await updatePseudocodeData({ ...apiBody, userId: state.userId }, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            console.log("updatePseudocodeData", pseudodata);
            if (pseudodata.statusCode == 200) {

                let bronze = pseudodata.data.bronze[0].columnMappingBronze
                let silver = pseudodata.data.silver[0].columnMappingSilver
                let gold = pseudodata.data.gold[0].columnMappingGold
                console.log("bronze", bronze, "silver", silver, "gold", gold);
                bronze = JSON.parse(bronze)?.map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                silver = JSON.parse(silver).map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                gold = JSON.parse(gold).map((obj, index) => ({
                    ...obj,
                    index: index
                }));

                setBronzeResponse(bronze)
                setSilverResponse(silver)

                setGoldResponse(gold)

                setBronzeJson(bronze)
                setSilverJson(silver)
                setGoldJson(gold)

                const verifiedCount = [...bronze, ...silver, ...gold].filter(data => data?.VerificationStatus === "Verified")?.length;
                const totalCount = [...bronze, ...silver, ...gold]?.length;
                const isVerified = verifiedCount === totalCount ? true : false;
               setIsAllVerified(isVerified)

                // PS_CM_120
                // filter options for bronze
                let uniqueBronzeSubjectAreas = [...new Set((bronze).map(item => item.SubjectArea))];
                let uniqueBronzeTableName = [...new Set((bronze).map(item => item.BronzeTableName))];
                setFilterBronzeSubjectArea(uniqueBronzeSubjectAreas)
                setFilterBronzeTableName(uniqueBronzeTableName)
                /// bronze end
                // PS_CM_122
                // filter options for silver
                let uniqueSilverSubjectAreas = [...new Set((silver).map(item => item.SubjectArea))];
                let uniqueSilverTableName = [...new Set((silver).map(item => item.SilverTableName))];
                setFilterSilverSubjectArea(uniqueSilverSubjectAreas)
                setFilterSilverTableName(uniqueSilverTableName)
                /// silver end
                // filter options for gold
                // PS_CM_122
                let uniqueGoldSubjectAreas = [...new Set((gold).map(item => item.SubjectArea))];
                let uniqueGoldTableName = [...new Set((gold).map(item => item.GoldTableName))];
                setFilterGoldSubjectArea(uniqueGoldSubjectAreas)
                setFilterGoldTableName(uniqueGoldTableName)
                /// gold end




                const { SubjectArea, BronzeTableName, SilverTableName, GoldTableName } = filterCriteria;

                let bronzeFilteredList
                let silverFilteredList
                let goldFilteredList


                // PS_CM_158
                if (selectedColumn == "bronze") {
                    silverFilteredList = silver
                    goldFilteredList = gold
                    bronzeFilteredList = bronze.filter((record) => {

                        const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
                        const BronzeTableNameMatch = BronzeTableName === 'Select' || record.BronzeTableName.toLowerCase() === BronzeTableName.toLowerCase();

                        return SubjectAreaMatch && BronzeTableNameMatch
                    });

                }

                // PS_CM_159
                if (selectedColumn == "silver") {
                    bronzeFilteredList = bronze
                    goldFilteredList = gold
                    silverFilteredList = silver.filter((record) => {

                        const SilverTableNameMatch = SilverTableName === 'Select' || record.SilverTableName.toLowerCase() === SilverTableName.toLowerCase();

                        return SilverTableNameMatch
                    });
                    // Set the filtered projectList to a separate state variable
                }
                // PS_CM_160
                if (selectedColumn == "gold") {
                    bronzeFilteredList = bronze
                    silverFilteredList = silver
                    goldFilteredList = gold.filter((record) => {

                        const GoldTableNameMatch = GoldTableName === 'Select' || record.GoldTableName === GoldTableName;

                        return GoldTableNameMatch
                    });
                    // PS_CD_1.53
                    // Set the filtered projectList to a separate state variable

                }


                if (searchTerm?.length > 3) {
                    // If the search term is empty, reset to all 

                    // Filter the based  based on search criteria  SubjectArea
                    let filteredData;
                    // PS_CM_74
                    if (selectedColumn == "bronze") {
                        filteredData = bronzeFilteredList.filter(
                            (record) =>
                                (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.BronzeSchemaName && record?.BronzeSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.Bronzetablename && record?.Bronzetablename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.BronzeColumname && record?.BronzeColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                        )

                        setBronzeJson(filteredData)
                    }
                    if (selectedColumn == "silver") {
                        filteredData = silverFilteredList.filter(
                            (record) =>
                                (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SilverSchemaName && record?.SilverSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.Silvertablename && record?.Silvertablename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SilverColumname && record?.SilverColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                        )
                        setSilverJson(filteredData)


                    }
                    if (selectedColumn == "gold") {
                        filteredData = goldFilteredList.filter(
                            (record) =>
                                (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.GoldSchemaName && record?.GoldSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.GoldColumname && record?.GoldColumname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.VerificationStatus && record?.VerificationStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (record?.SourceColumnName && record?.SourceColumnName.toLowerCase().includes(searchTerm.toLowerCase()))
                        )
                        setGoldJson(filteredData)

                    }


                } else {
                    setBronzeJson(bronzeFilteredList)
                    setSilverJson(silverFilteredList)
                    setGoldJson(goldFilteredList)
                }









                // PS_CM_123
                setBronze_InputValues({
                    RequirementID: '',
                    SubjectArea: 'Select',
                    BronzeSchemaName: '',
                    BronzeTableName: '',
                    BronzeColumnName: '',
                    BronzeDataType: '',
                    BronzeConstraints: '',
                    SourceSystem: 'Select',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: 'Select',


                })
                setSilver_InputValues({
                    RequirementID: '',
                    SubjectArea: 'Select',
                    SilverSchemaName: 'Select',
                    SilverTableName: '',
                    SilverColumnName: '',
                    SilverDataType: '',
                    SilverConstraints: '',
                    SourceSchemaName: '',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: 'Select',


                });
                setgold_InputValues({
                    RequirementID: '',
                    SubjectArea: 'Select',
                    GoldSchemaName: 'Select',
                    GoldTableName: '',
                    GoldColumnName: '',
                    GoldDataType: '',
                    Constraints: '',
                    SourceSchemaName: '',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: 'Select',


                });
            }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "columnmapping",
                functionName: "updatepseudo",
                userId: state.userId
            });
            console.log("error in update", e);
        }
    }
    // PS_CM_105
    const handleTick = async (item) => {
         
        // PS_CM_106
        setBody({
            type: "columnMapping",
            subtype: selectedColumn,
            actionType: "edit",
            index: selectedColumn == "bronze" ? bronze_InputValues.index : selectedColumn == "silver" ? silver_InputValues.index : gold_InputValues.index,
            comments: reason,
            value: selectedColumn == "bronze" ? bronze_InputValues : selectedColumn == "silver" ? silver_InputValues : gold_InputValues
        })
    }
    // PS_CM_242
    const handleCancel = async () => {
        if (selectedColumn == "bronze") {
            setBronzeJson(bronzeResponse);
            setBronzeEditingIndex(null);
            setBronze_InputValues(
                {
                    RequirementID: '',
                    SubjectArea: 'Select',
                    BronzeSchemaName: '',
                    BronzeTableName: '',
                    BronzeColumnName: '',
                    BronzeDataType: '',
                    BronzeConstraints: '',
                    SourceSystem: 'Select',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: '',

                })


            setReason("")
        }
        if (selectedColumn == "silver") {

            setSilverJson(silverResponse);
            setSilverEditingIndex(null);
            setSilver_InputValues
                ({
                    RequirementID: '',
                    SubjectArea: 'Select',
                    SilverSchemaName: '',
                    SilverTableName: '',
                    SilverColumnName: '',
                    SilverDataType: '',
                    SilverConstraints: '',
                    SourceSchemaName: 'Select',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: '',


                });


            setReason("")
        }
        if (selectedColumn == "gold") {

            setGoldJson(goldResponse);
            setGoldEditingIndex(null);
            setgold_InputValues
                ({
                    RequirementID: '',
                    SubjectArea: 'Select',
                    GoldSchemaName: 'Select',
                    GoldTableName: '',
                    GoldColumnName: '',
                    GoldDataType: '',
                    Constraints: '',
                    SourceSchemaName: '',
                    SourceTableName: '',
                    SourceColumnName: '',
                    SourceDataType: "",
                    SourceConstraints: '',
                    TransformationLogic: '',
                    Comments: '',
                    VerificationStatus: '',



                });



            setReason("")
        }
        setEditingIndex(null);

        setReason("")
    }
    // PS_CM_109
    const handleReason = async (e) => {
         
        // PS_CM_110
        setReason(e.target.value)

    }

    // PS_CM_111
    const handleSaveDetails = async () => {
         
        setBronzeEditingIndex(null)
        setSilverEditingIndex(null)
        setGoldEditingIndex(null)
        console.log("body in savedetaisl----", body);
        if (!reason || !body) {
            // If reason or body is not filled, return without performing further operations
            return;
        }
        apiBody = { ...body, comments: reason, projectId: state.projectId }
        console.log("   inside savedetails fun", reason, "apiBody", apiBody);
        // PS_CM_112
        await updatePseudocode(apiBody)
        setReason("")
        setBody({})

        setBronze_InputValues({
            RequirementID: '',
            SubjectArea: 'Select',
            BronzeSchemaName: '',
            BronzeTableName: '',
            BronzeColumnName: '',
            BronzeDataType: '',
            BronzeConstraints: '',
            SourceSystem: 'Select',
            SourceTableName: '',
            SourceColumnName: '',
            SourceDataType: "",
            SourceConstraints: '',
            TransformationLogic: '',
            Comments: '',
            VerificationStatus: '',


        });
        setSilver_InputValues({
            RequirementID: '',
            SubjectArea: 'Select',
            SilverSchemaName: 'Select',
            SilverTableName: '',
            SilverColumnName: '',
            SilverDataType: '',
            SilverConstraints: '',
            SourceSchemaName: '',
            SourceTableName: '',
            SourceColumnName: '',
            SourceDataType: "",
            SourceConstraints: '',
            TransformationLogic: '',
            Comments: '',
            VerificationStatus: '',


        });
        setgold_InputValues({
            RequirementID: '',
            SubjectArea: 'Select',
            GoldSchemaName: 'Select',
            GoldTableName: '',
            GoldColumnName: '',
            GoldDataType: '',
            Constraints: '',
            SourceSchemaName: '',
            SourceTableName: '',
            SourceColumnName: '',
            SourceDataType: "",
            SourceConstraints: '',
            TransformationLogic: '',
            Comments: '',
            VerificationStatus: '',



        });


        // invoke api call 
    }
    const handleSort = (columnName) => {
         
        // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        if (selectedColumn == "bronze") {
            let sortedRecords = bronzeJson.sort((a, b) => {
                if (newSortOrder.order === 'asc') {
                    return a[columnName]?.localeCompare(b[columnName]);
                } else {
                    return b[columnName]?.localeCompare(a[columnName]);
                }
            });
            setBronzeJson(sortedRecords)
        }
        if (selectedColumn == "silver") {
            let sortedRecords = silverJson.sort((a, b) => {
                if (newSortOrder.order === 'asc') {
                    return a[columnName]?.localeCompare(b[columnName]);
                } else {
                    return b[columnName]?.localeCompare(a[columnName]);
                }
            });
            setSilverJson(sortedRecords)
        }
        if (selectedColumn == "gold") {
            let sortedRecords = goldJson.sort((a, b) => {
                if (newSortOrder.order === 'asc') {
                    return a[columnName]?.localeCompare(b[columnName]);
                } else {
                    return b[columnName]?.localeCompare(a[columnName]);
                }
            });
            setGoldJson(sortedRecords)
        }

    };
    // PS_CM_76
    const handleDownload = async () => {

        const bronzeNewArray = bronzeResponse.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });
        const silverNewArray = silverResponse.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        }); const goldNewArray = goldResponse.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });
        // Convert JSON array to worksheet
        // PS_CM_83
        const ws1 = XLSX.utils.json_to_sheet(bronzeNewArray);
        const ws2 = XLSX.utils.json_to_sheet(silverNewArray);
        const ws3 = XLSX.utils.json_to_sheet(goldNewArray);
        // PS_CM_86
        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Column Mapping- Bronze');
        XLSX.utils.book_append_sheet(wb, ws2, 'Column Mapping- Silver');
        XLSX.utils.book_append_sheet(wb, ws3, 'Column Mapping- Gold');
        // PS_CM_88
        // Generate Excel file and download
        XLSX.writeFile(wb, 'Column Mapping.xlsx');
    }
    // PS_CM_241
    const handleClearFilter = () => {

        // Reset filter criteria to their initial values
        setFilterCriteria({
            SubjectArea: 'Select',
            BronzeTableName: 'Select',
            SilverTableName: "Select",
            GoldTableName: "Select"

        });
        setBronzeJson(bronzeResponse)
        setSilverJson(silverResponse)
        setGoldJson(goldResponse)
    };
    // PS_CM_158
    const handleApplyFilter = () => {
         
        console.log("filtercriteria", filterCriteria)
        {/* // PS_CD_1.51 */ }
        const { SubjectArea, BronzeTableName, SilverTableName, GoldTableName } = filterCriteria;
        // PS_CM_158
        if (selectedColumn == "bronze") {
            const filteredList = bronzeResponse.filter((record) => {

                const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
                const BronzeTableNameMatch = BronzeTableName === 'Select' || record.BronzeTableName.toLowerCase() === BronzeTableName.toLowerCase();

                return SubjectAreaMatch && BronzeTableNameMatch
            });
            // PS_CD_1.53
            // Set the filtered projectList to a separate state variable
            setBronzeJson(filteredList);

        }
        else {
            setBronzeJson(bronzeResponse);

        }
        // PS_CM_159
        if (selectedColumn == "silver") {
            const filteredList = silverResponse.filter((record) => {
                const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();

                const SilverTableNameMatch = SilverTableName === 'Select' || record.SilverTableName.toLowerCase() === SilverTableName.toLowerCase();

                return SilverTableNameMatch && SubjectAreaMatch
            });
            // Set the filtered projectList to a separate state variable
            setSilverJson(filteredList);

        }
        else {
            setSilverJson(silverResponse);

        }
        // PS_CM_160
        if (selectedColumn == "gold") {
            const filteredList = goldResponse.filter((record) => {
                const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();

                const GoldTableNameMatch = GoldTableName === 'Select' || record.GoldTableName === GoldTableName;

                return GoldTableNameMatch && SubjectAreaMatch
            });
            // PS_CD_1.53
            // Set the filtered projectList to a separate state variable
            setGoldJson(filteredList);

        }
        else {
            setGoldResponse(goldResponse)
        }

    };

    // PS_CM_183
    const handleNotVerified = async (record) => {
        setWhetherUpdate(true)
        console.log("in not verified funcccc", selectedItem);
         
        let record1 = { ...selectedItem, VerificationStatus: "Not Verified" }
        apiBody = {
            type: "columnMapping",
            actionType: "update",
            subtype: selectedColumn,
            value: record1,
            index: record1.index,
            projectId: state?.projectId,
            userId: state.userId
        }
        await updatePseudocode()
        return true
    };
    // PS_CM_181
    const handleVerified = async (record) => {
         
        let record1 = { ...record, VerificationStatus: "Verified" }
        apiBody = {
            type: "columnMapping",
            actionType: "update",
            subtype: selectedColumn,
            value: record1,
            index: record1.index,
            projectId: state?.projectId,
            userId: state.userId
        }
        await updatePseudocode()

    };

    // input values of a bronze form (bronze_InputValues) to the console for debugging purposes. 
    // Then, it initializes an array called defaultValues with default values commonly found in form inputs (e.g., "Select", "").
    //  It converts the input values of the bronze form into an array named inputValuesArray using Object.values(). 
    //  The function checks if any default values are present in the inputValuesArray using the some() method with defaultValues.includes(value).
    //   If any default values are found, hasDefaultValues becomes true; otherwise, it remains false. Finally, the function returns the negation of hasDefaultValues, indicating whether the bronze form is fully filled
    // PS_CM_237
    const isBronzeFormFilled = () => {
         
        console.log("input vaaluess in forfilled", bronze_InputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(bronze_InputValues); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };
    // PS_CM_238
    const isSilverFormFilled = () => {
         
        console.log("input vaaluess in forfilled", silver_InputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(silver_InputValues); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };
    // PS_CM_239
    const isGoldFormFilled = () => {
         
        // console.log("input vaaluess in forfilled", gold_InputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(gold_InputValues); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };
    // PS_CM_101
    const handleEditClick = async (index, item) => {
         
        console.log("edit item", item);
        selectedColumn == "bronze" ? setBronzeEditingIndex(index) : selectedColumn == "silver" ? setSilverEditingIndex(index) : setGoldEditingIndex(index)

        selectedColumn == "bronze" ? setBronze_InputValues({ ...item }) : selectedColumn == "silver" ? setSilver_InputValues({ ...item }) : setgold_InputValues({ ...item })
        setIsEditingData(true)
    };
    // PS_CM_124
    const handleDelete = async (index, item) => {
        setBody({
            type: "columnMapping",
            actionType: "delete",
            subtype: selectedColumn,
            index: item.index,
            comments: reason
        })

    };
    // PS_CM_101
    // function handles input changes specifically for gold-related data. It extracts the name and value from the event target (likely an input field), then updates the gold_InputValues state by merging the previous state with the new name and value.
    const handleGoldInputChange = (e) => {
        const { name, value } = e.target;
        setgold_InputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // PS_CM_101
    // function handles input changes for silver-related data. It follows the same pattern of extracting name and value from the event target and updating the Silver_InputValues state accordingly.
    const handleSilverInputChange = (e) => {
        const { name, value } = e.target;
        setSilver_InputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // PS_CM_101
    // handles input changes for bronze-related data. It extracts name and value from the event target and updates the bronze_InputValues state.
    const handleBronzeInputChange = (e) => {
        const { name, value } = e.target;
        setBronze_InputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // . It takes key and value as parameters, where key likely represents the dropdown field name, and value represents the selected value. It updates the bronze_InputValues state accordingly.
    const handleBronzeDropDownChange = (key, value) => {
        setBronze_InputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }
    // . It takes key and value as parameters, where key likely represents the dropdown field name, and value represents the selected value. It updates the silver_InputValues state accordingly.
    const handleSilverDropDownChange = (key, value) => {
        setSilver_InputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }
    // PS_CM_101
    // . It takes key and value as parameters, where key likely represents the dropdown field name, and value represents the selected value. It updates the gold_InputValues state accordingly.
    const handleGoldDropDownChange = (key, value) => {
        setgold_InputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }
    // PS_CM_139

    const handleAdd = (value) => {
         

        // console.log("input vaaluess in handleAdd", inputValues);
        console.log("add itmemmmm item", value);
        setBody({
            type: "columnMapping",
            subtype: selectedColumn,
            actionType: "add",
            comments: reason,
            value: selectedColumn == "bronze" ? bronze_InputValues : selectedColumn == "silver" ? silver_InputValues : gold_InputValues
        })
    };


    // PS_CM_240
    const handlecancelSearch = (value) => {
        setSearchTerm("");
        handleApplyFilter()

    };

    const bindGoldGrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            // console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in gold bindc", goldJson);
            // Check if jsonData is an array and has elements
            if (goldJson?.length > 0) {
                console.log("in binf golddd");



                return (

                    <>
                        {goldJson?.map((item, index) => (

                            (goldEditingIndex == index) ? (
                                <tr key={index}>
                                    <td ><input class="form-control cust-input-sty font-14 rounded-3 font-regular" type="text" name="RequirementID" value={gold_InputValues?.RequirementID} onChange={handleGoldInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {gold_InputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {DropDownSubjectArea.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleGoldDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {gold_InputValues.GoldSchemaName}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {dropDownGoldSchemaName.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleGoldDropDownChange("GoldSchemaName", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="GoldTableName" value={gold_InputValues?.GoldTableName} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="GoldColumnName" value={gold_InputValues?.GoldColumnName} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="GoldDataType" value={gold_InputValues?.GoldDataType} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="Constraints" value={gold_InputValues?.Constraints} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSchemaName" value={gold_InputValues?.SourceSchemaName} onChange={handleGoldInputChange} /></td>


                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceTableName" value={gold_InputValues?.SourceTableName} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceColumnName" value={gold_InputValues?.SourceColumnName} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceDataType" value={gold_InputValues?.SourceDataType} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceConstraints" value={gold_InputValues?.SourceConstraints} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="TransformationLogic" value={gold_InputValues?.TransformationLogic} onChange={handleGoldInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="Comments" value={gold_InputValues?.Comments} onChange={handleGoldInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {gold_InputValues.VerificationStatus}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {verificationStatusDropDown.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleGoldDropDownChange("VerificationStatus", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 `}
                                            disabled={isGoldFormFilled()}

                                            onClick={() => handleTick(gold_InputValues)}
                                            {...(isGoldFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}


                                            // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            // data-bs-target="#editRows"  // Specify the target modal
                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        />
                                    </td>
                                </tr>) : (


                                <tr key={index}>


                                    <td>{item?.RequirementID}</td>
                                    <td>{item?.SubjectArea}</td>
                                    <td>{item?.GoldSchemaName}</td>
                                    <td>{item?.GoldTableName}</td>
                                    <td>{item?.GoldColumnName}</td>
                                    <td>{item?.GoldDataType}</td>
                                    <td>{item?.Constraints}</td>
                                    <td>{item?.SourceSchemaName}</td>
                                    <td>{item?.SourceTableName}</td>
                                    <td>{item?.SourceColumnName}</td>
                                    <td>{item?.SourceDataType}</td>
                                    <td>{item?.SourceConstraints}</td>
                                    <td>{item?.TransformationLogic}</td>
                                    <td>{item?.Comments}</td>
                                    <td>{item?.VerificationStatus}</td>

                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="Delete"
                                            data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        // disabled={!reason}
                                        />
                                        {state.role == "Project Lead" ? <>
                                            {(item?.VerificationStatus == "Verified") ? (<img src="images/wrong.svg" alt="tick-icon" className="cust-cursor-pointer" title="Tick" data-bs-toggle="modal" data-bs-target="#Confrm" onClick={() => setSelectedItem(item)} />) :
                                                <img src="images/tick-icon.svg" alt="tick-icon" className="cust-cursor-pointer" title="Tick" onClick={() => handleVerified(item)} />}
                                        </> : <></>}
                                    </td>
                                </tr>
                            )))}
                    </>

                );
            }
            else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "columnMapping",
                functionName: "bindgoldgrid",
                userId: state.userId
            });
            console.error("Error in bindgrid:", error);
            return null;
        }
    };


    const bindSilverGrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            // console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in silver bindgrid", silverJson);
            // Check if jsonData is an array and has elements
            if (silverJson?.length > 0) {
                console.log("in binf func2222", silverJson);

                return (

                    <>
                        {silverJson?.map((item, index) => (

                            (silverEditingIndex == index) ? (
                                <tr key={index}>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="RequirementID" value={silver_InputValues?.RequirementID} onChange={handleSilverInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {silver_InputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {DropDownSubjectArea.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleSilverDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {silver_InputValues.SilverSchemaName}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {DropDownSubjectArea.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleSilverDropDownChange("SilverSchemaName", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SilverTableName" value={silver_InputValues?.SilverTableName} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SilverColumnName" value={silver_InputValues?.SilverColumnName} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SilverDatatype" value={silver_InputValues?.SilverDatatype} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SilverConstraint" value={silver_InputValues?.SilverConstraint} onChange={handleSilverInputChange} /></td>

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSchemaName" value={silver_InputValues?.SourceSchemaName} onChange={handleSilverInputChange} /></td>

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceTableName" value={silver_InputValues?.SourceTableName} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceColumnName" value={silver_InputValues?.SourceColumnName} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceDatatype" value={silver_InputValues?.SourceDatatype} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceConstraints" value={silver_InputValues?.SourceConstraints} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="TransformationLogic" value={silver_InputValues?.TransformationLogic} onChange={handleSilverInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="Comments" value={silver_InputValues?.Comments} onChange={handleSilverInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {silver_InputValues.VerificationStatus}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {verificationStatusDropDown.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleSilverDropDownChange("VerificationStatus", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3`}
                                            onClick={() => handleTick(silver_InputValues)}
                                            {...(isSilverFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}
                                            disabled={isSilverFormFilled()}

                                            // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            // data-bs-target="#editRows"  // Specify the target modal
                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        /></td>
                                </tr>) : (



                                <tr key={index}>


                                    <td>{item?.RequirementID}</td>
                                    <td>{item?.SubjectArea}</td>
                                    <td>{item?.SilverSchemaName}</td>
                                    <td>{item?.SilverTableName}</td>
                                    <td>{item?.SilverColumnName}</td>
                                    <td>{item?.SilverDatatype}</td>
                                    <td>{item?.SilverConstraint}</td>
                                    <td>{item?.SourceSchemaName}</td>
                                    <td>{item?.SourceTableName}</td>
                                    <td>{item?.SourceColumnName}</td>
                                    <td>{item?.SourceDatatype}</td>
                                    <td>{item?.SourceConstraints}</td>
                                    <td>{item?.TransformationLogic}</td>
                                    <td>{item?.Comments}</td>
                                    <td>{item?.VerificationStatus}</td>

                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="Delete"
                                            data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        />
                                        {state.role == "Project Lead" ? <>
                                            {(item.VerificationStatus == "Verified") ? (<img src="images/wrong.svg" alt="tick-icon" className="cust-cursor-pointer me-3" title="Tick" data-bs-toggle="modal" data-bs-target="#Confrm" onClick={() => setSelectedItem(item)} />) :
                                                <img src="images/tick-icon.svg" alt="tick-icon" className="cust-cursor-pointer me-3" title="Tick" onClick={() => handleVerified(item)} />}
                                        </> : <></>}
                                    </td>
                                </tr>
                            )))}
                    </>

                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "columnMapping",
                functionName: "bindSilvergrid",
                userId: state.userId
            });
            console.error("Error in bindgrid:", error);
            return null;
        }
    };

    const bindBronzeGrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            // console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in binf func", bronzeJson);
            // Check if jsonData is an array and has elements
            if (bronzeJson?.length > 0) {
                console.log("in binf func2222");



                return (

                    <>
                        {bronzeJson?.map((item, index) => (

                            (bronzeEditingIndex == index) ? (
                                <tr key={index}>
                                    <td><input class="form-control cust-input-sty font-14 rounded-3 font-regular " type="text" name="RequirementID" value={bronze_InputValues?.RequirementID} onChange={handleBronzeInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {bronze_InputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {DropDownSubjectArea.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleBronzeDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzeSchemaName" value={bronze_InputValues?.BronzeSchemaName} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzeTableName" value={bronze_InputValues?.BronzeTableName} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzeColumnName" value={bronze_InputValues?.BronzeColumnName} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzeDatatype" value={bronze_InputValues?.BronzeDatatype} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzeConstraints" value={bronze_InputValues?.BronzeConstraints} onChange={handleBronzeInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {bronze_InputValues.SourceSystem}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {dropDownSourceSysName.map(value => (
                                                    <li style={{ fontSize: "14px" }} key={value} onClick={() => handleBronzeDropDownChange("SourceSystem", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceTableName" value={bronze_InputValues?.SourceTableName} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceColumnName" value={bronze_InputValues?.SourceColumnName} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceDatatype" value={bronze_InputValues?.SourceDatatype} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceConstraints" value={bronze_InputValues?.SourceConstraints} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="TransformationLogic" value={bronze_InputValues?.TransformationLogic} onChange={handleBronzeInputChange} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" name="Comments" value={bronze_InputValues?.Comments} onChange={handleBronzeInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {bronze_InputValues.VerificationStatus}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {verificationStatusDropDown.map(value => (
                                                    <li key={value} onClick={() => handleBronzeDropDownChange("VerificationStatus", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>

                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 `}
                                            onClick={() => handleTick(bronze_InputValues)}
                                            {...(isBronzeFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}
                                            disabled={!isBronzeFormFilled()}

                                            // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            // data-bs-target="#editRows"  // Specify the target modal
                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        />

                                    </td>
                                </tr>) : (


                                <tr key={index}>


                                    <td>{item?.RequirementID}</td>
                                    <td>{item?.SubjectArea}</td>
                                    <td>{item?.BronzeSchemaName}</td>
                                    <td>{item?.BronzeTableName}</td>
                                    <td>{item?.BronzeColumnName}</td>
                                    <td>{item?.BronzeDatatype}</td>
                                    <td>{item?.BronzeConstraints}</td>
                                    <td>{item?.SourceSystem}</td>
                                    <td>{item?.SourceTableName}</td>
                                    <td>{item?.SourceColumnName}</td>
                                    <td>{item?.SourceDatatype}</td>
                                    <td>{item?.SourceConstraints}</td>
                                    <td>{item?.TransformationLogic}</td>
                                    <td>{item?.Comments}</td>
                                    <td>{item?.VerificationStatus}</td>

                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="Delete"
                                            data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        // disabled={!reason}
                                        />
                                        {state.role == "Project Lead" ? <>
                                            {(item.VerificationStatus == "Verified") ? (<img src="images/wrong.svg" alt="tick-icon" className="cust-cursor-pointer me-3" title="Tick" data-bs-toggle="modal" data-bs-target="#Confrm" onClick={() => setSelectedItem(item)} />) :
                                                <img src="images/tick-icon.svg" alt="tick-icon" className="cust-cursor-pointer me-3" title="Tick" onClick={() => handleVerified(item)} />}
                                        </> : <></>}
                                    </td>
                                </tr>
                            )))}
                    </>

                );
            }
            else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            // insertError({
            //     errorMessage: error.message,
            //     serviceName: "frontend",
            //     module: "columnmapping",
            //     functionName: "bindbronzegrid",
            //     userId: state.userId
            // });
            console.error("Error in bindgrid:", error);
            return null;
        }
    };




    /** // PS_CD_1.13-1.16
     * The function `handleEditClick` sets the selected item and updates the `isEditMetavalue` state.
     */






    return (

        <div className="container-fluid px-md-4">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value="1" />
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Column Mapping</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdate); props.isTotalVerified(isAllVerified) }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                Column Mapping
                            </h2>
                        </div>
                        <div className="d-flex">
                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 py-2 me-3 d-flex align-items-center">
                                <span className="border-end d-flex allign-items-center pe-3">
                                    <img
                                        src="images/verified-icon.svg"
                                        alt="verified-icon"
                                        className="verified-logo-sty me-2"
                                    />
                                    <span className=" text-nowrap font-regular">
                                        Verified - <span className="font-medium">{[...bronzeResponse, ...silverResponse, ...goldResponse].filter(record => record.VerificationStatus == "Verified")?.length}</span>{" "}
                                    </span>
                                </span>
                                <span className="border-end d-flex allign-items-center px-3">
                                    <img
                                        src="images/not-verified-icon.svg"
                                        alt="not-verified-icon"
                                        className="verified-logo-sty me-2"
                                    />
                                    <span className=" text-nowrap font-regular">
                                        Not verified - <span className="font-medium">{[...bronzeResponse, ...silverResponse, ...goldResponse].filter(record => record.VerificationStatus == "Not Verified")?.length}</span>{" "}
                                    </span>
                                </span>
                                <span className="ps-3">
                                    <span className=" text-nowrap font-regular">
                                        Total - <span className="font-medium">{[...bronzeResponse, ...silverResponse, ...goldResponse]?.length}</span>{" "}
                                    </span>
                                </span>
                            </div>
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value); handleSearch() }}
                                />
                                <span className="search-icon-sty">
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon" onClick={() => handlecancelSearch()} />
                                    </a>
                                </span>
                            </div>
                            <div className="btn-group me-3">
                                <button
                                    className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon"
                                    />
                                </button>
                                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>

                                        {(selectedColumn == "bronze") ? (
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="SubjectArea"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Subject Area
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="subjectArea"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                            value={filterCriteria.SubjectArea}
                                                        >
                                                            <option selected="">Select</option>
                                                            {filterBronzeSubjectArea.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="Destination Table Name"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Bronze Table Name
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="BronzeTableName"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, BronzeTableName: e.target.value })}
                                                            value={filterCriteria.BronzeTableName}

                                                        >
                                                            <option selected="">Select</option>
                                                            {filterBronzeTableName.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : ((selectedColumn == "silver") ? (
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="SubjectArea"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Subject Area
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="SubjectArea"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                            value={filterCriteria.SubjectArea}

                                                        >
                                                            <option selected="">Select</option>
                                                            {filterSilverSubjectArea.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="Destination Table Name"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Silver Table Name
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="SilverTableName"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, SilverTableName: e.target.value })}
                                                            value={filterCriteria.SilverTableName}

                                                        >
                                                            <option selected="">Select</option>
                                                            {filterSilverTableName.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>) : (
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="SubjectArea"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Subject Area
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="SubjectArea"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                            value={filterCriteria.SubjectArea}

                                                        >
                                                            <option selected="">Select</option>
                                                            {filterSilverSubjectArea.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="GoldTableName"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Gold Table Name
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id=" GoldTableName"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, GoldTableName: e.target.value })}
                                                            value={filterCriteria.GoldTableName}

                                                        >
                                                            <option selected="">Select</option>
                                                            {filterGoldTableName.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>))}

                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={() => handleClearFilter()}

                                            >
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={() => handleApplyFilter()}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium "
                                data-bs-dismiss="modal"
                                onClick={() => handleDownload()}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#view-instruction"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#view-instruction"
                                        className="cursor-pointer pt-1 text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If any changes are required update them accordingly using{" "}
                                                <span className="text-black-v2 font-bold">‘Actions’</span>{" "}
                                                on the last column
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’ </span>then
                                                on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="code-review-tab-width">
                                <div className="code-review-page checklists tabs">
                                    <div onClick={() => { setSelectedColumn("bronze"); }} className={`tab font-bold ${selectedColumn == "bronze" ? "active" : ''} `}>
                                        Bronze
                                    </div>
                                    <div onClick={() => { setSelectedColumn("silver"); }} className={`tab font-bold ${selectedColumn == "silver" ? "active" : ''} `}  >
                                        Silver
                                    </div>
                                    <div onClick={() => { setSelectedColumn("gold"); }} className={`tab font-bold ${selectedColumn == "gold" ? "active" : ''} `}>
                                        Gold
                                    </div>
                                </div>


                                <div id="tab1" className="code-review-tab tab-content active">
                                    <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                        {/*Metadata Table starts here*/}
                                        <div className="table-responsive rounded-3">
                                            <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                                <thead className="sticky-top-pos">
                                                    {selectedColumn == "bronze" ?

                                                        <tr>
                                                            <th>RequirementID <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "RequirementID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('RequirementID')} /></span></th>
                                                            <th>SubjectArea <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                            <th>BronzeSchemaName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzeSchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzeSchemaName')} /></span></th>
                                                            <th>BronzeTableName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzeTableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzeTableName')} /></span></th>
                                                            <th>BronzeColumnName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzeColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzeColumnName')} /></span></th>
                                                            <th>BronzeDataType <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzeDatatype" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzeDatatype')} /></span></th>
                                                            <th>BronzeConstraints <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzeConstraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzeConstraints')} /></span></th>
                                                            <th>SourceSystem <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSystem" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSystem')} /></span></th>

                                                            <th>SourceTablename <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTableName')} /></span></th>
                                                            <th>SourceColumnName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceColumnName')} /></span></th>
                                                            <th>SourceDataType <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceDatatype" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceDatatype')} /></span></th>
                                                            <th>SourceConstraints <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceConstraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceConstraints')} /></span></th>

                                                            <th>TransformationLogic <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TransformationLogic" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TransformationLogic')} /></span></th>
                                                            <th>Comments <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Comments" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Comments')} /></span></th>
                                                            <th>Verification Status <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "VerificationStatus" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('VerificationStatus')} /></span></th>


                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                        : ((selectedColumn == "silver") ? (
                                                            <tr>
                                                                <th>RequirementID <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "RequirementID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('RequirementID')} /></span></th>
                                                                <th>SubjectArea <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                                <th>SilverSchemaName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverSchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverSchemaName')} /></span></th>
                                                                <th>SilverTableName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverTableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverTableName')} /></span></th>
                                                                <th>SilverColumnName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverColumnName')} /></span></th>
                                                                <th>SilverDataType <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverDataType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverDataType')} /></span></th>
                                                                <th>SilverConstraints <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverConstraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverConstraints')} /></span></th>
                                                                <th>SourceSchemaName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSchemaName')} /></span></th>

                                                                <th>SourceTablename<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTablename" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTablename')} /></span></th>
                                                                <th>SourceColumnName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceColumnName')} /></span></th>
                                                                <th>SourceDataType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceDataType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceDataType')} /></span></th>
                                                                <th>SourceConstraints<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceConstraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceConstraints')} /></span></th>

                                                                <th>TransformationLogic<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TransformationLogic" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TransformationLogic')} /></span></th>
                                                                <th>Comments<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Comments" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Comments')} /></span></th>
                                                                <th>Verification Status<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "VerificationStatus" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('VerificationStatus')} /></span></th>

                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        ) : (<tr>
                                                            <th>RequirementID <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "RequirementID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('RequirementID')} /></span></th>
                                                            <th>SubjectArea<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                            <th>GoldSchemaName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldSchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldSchemaName')} /></span></th>
                                                            <th>GoldTableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldTableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldTableName')} /></span></th>
                                                            <th>GoldColumnName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldColumnName')} /></span></th>
                                                            <th>GoldDataType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldDataType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldDataType')} /></span></th>
                                                            <th>Constraints<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Constraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Constraints')} /></span></th>
                                                            <th>SourceSchemaName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSchemaName')} /></span></th>

                                                            <th>SourceTablename<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTableName')} /></span></th>
                                                            <th>SourceColumnName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceColumnName')} /></span></th>
                                                            <th>SourceDataType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceDataType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceDataType')} /></span></th>
                                                            <th>SourceConstraints<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceConstraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceConstraints')} /></span></th>

                                                            <th>TransformationLogic<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TransformationLogic" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TransformationLogic')} /></span></th>
                                                            <th>Comments<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Comments" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Comments')} /></span></th>
                                                            <th>Verification Status<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "VerificationStatus" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('VerificationStatus')} /></span></th>


                                                            <th className="text-center">Action</th>
                                                        </tr>))}


                                                </thead>
                                                <tbody>
                                                    {/* {editingIndex != null ?
                                                        <></>
                                                        : */}
                                                    {
                                                        ((selectedColumn == "bronze") ? (
                                                            (bronzeEditingIndex != null) ?
                                                                <></>
                                                                :
                                                                <tr>
                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="RequirementID"
                                                                        value={bronze_InputValues.RequirementID}
                                                                        onChange={(e) => handleBronzeInputChange(e)}
                                                                    /></td>
                                                                    <td><select
                                                                        className="form-select cust-input-sty font-14 font-regular"
                                                                        aria-label="Default select example"
                                                                        name="SubjectArea"
                                                                        value={bronze_InputValues.SubjectArea}
                                                                        onChange={(e) => handleBronzeDropDownChange("SubjectArea", e.target.value)}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {DropDownSubjectArea?.map(value => (
                                                                            <option key={value} value={value}>{value}</option>
                                                                        ))}
                                                                    </select></td>
                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="BronzeSchemaName"
                                                                        value={bronze_InputValues.BronzeSchemaName}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>
                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="BronzeTableName"
                                                                        value={bronze_InputValues.BronzeTableName}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td> <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="BronzeColumnName"
                                                                        value={bronze_InputValues.BronzeColumnName}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td> <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="BronzeDatatype"
                                                                        value={bronze_InputValues.BronzeDatatype}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td> <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="BronzeConstraints"
                                                                        value={bronze_InputValues.BronzeConstraints}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>




                                                                    <td><select
                                                                        className="form-select cust-input-sty font-14 font-regular"
                                                                        aria-label="Default select example"
                                                                        name="SourceSystem"
                                                                        value={bronze_InputValues.SourceSystem}
                                                                        onChange={(e) => handleBronzeDropDownChange("SourceSystem", e.target.value)}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {dropDownSourceSysName?.map(value => (
                                                                            <option key={value} value={value}>{value}</option>
                                                                        ))}
                                                                    </select></td>



                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="SourceTableName"
                                                                        value={bronze_InputValues.SourceTableName}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>

                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="SourceColumnName"
                                                                        value={bronze_InputValues.SourceColumnName}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>

                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="SourceDatatype"
                                                                        value={bronze_InputValues.SourceDatatype}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>
                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="SourceConstraints"
                                                                        value={bronze_InputValues.SourceConstraints}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>

                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="TransformationLogic"
                                                                        value={bronze_InputValues.TransformationLogic}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>
                                                                    <td><input
                                                                        type="text"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                        placeholder="Enter"
                                                                        name="Comments"
                                                                        value={bronze_InputValues.Comments}
                                                                        onChange={handleBronzeInputChange}
                                                                    /></td>


                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                style={{ zIndex: 1001 }}
                                                                                placeholder="Select"
                                                                            >
                                                                                {bronze_InputValues.VerificationStatus}
                                                                            </a>
                                                                            <ul
                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >
                                                                                {verificationStatusDropDown.map(value => (
                                                                                    <li key={value} onClick={() => handleBronzeDropDownChange("VerificationStatus", value)}>
                                                                                        <a className="dropdown-item">{value}</a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <img
                                                                            src="images/add-icons.svg"
                                                                            alt="add-icon"
                                                                            title="Add"
                                                                            className={`cust-cursor-pointer`}

                                                                            disabled={isBronzeFormFilled()}

                                                                            // aria-disabled={isFormFilled()}
                                                                            onClick={() => handleAdd(bronze_InputValues)}
                                                                            {...(isBronzeFormFilled() ? {
                                                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                                                "data-bs-target": "#editRows" // Specify the target modal
                                                                            } : {})}
                                                                        /></td>
                                                                </tr>) :
                                                            ((selectedColumn == "silver") ? (
                                                                (silverEditingIndex != null) ?
                                                                    (<></>)
                                                                    :
                                                                    <tr>
                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="RequirementID"
                                                                            value={silver_InputValues.RequirementID}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>
                                                                        <td><select
                                                                            className="form-select cust-input-sty font-14 font-regular"
                                                                            aria-label="Default select example"
                                                                            name="SubjectArea"
                                                                            value={silver_InputValues.SubjectArea}
                                                                            onChange={(e) => handleSilverDropDownChange("SubjectArea", e.target.value)}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {DropDownSubjectArea?.map(value => (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            ))}
                                                                        </select></td>
                                                                        <td><select
                                                                            className="form-select cust-input-sty font-14 font-regular"
                                                                            aria-label="Default select example"
                                                                            name="SourceSystem"
                                                                            value={silver_InputValues.SilverSchemaName}
                                                                            onChange={(e) => handleSilverDropDownChange("SilverSchemaName", e.target.value)}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {dropDownSilverSchemaName?.map(value => (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            ))}
                                                                        </select></td>
                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SilverTableName"
                                                                            value={silver_InputValues.SilverTableName}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>
                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SilverColumnName"
                                                                            value={silver_InputValues.SilverColumnName}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td> <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SilverDatatype"
                                                                            value={silver_InputValues.SilverDatatype}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td> <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SilverConstraints"
                                                                            value={silver_InputValues.SilverConstraints}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td> <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SourceSchemaName"
                                                                            value={silver_InputValues.SourceSchemaName}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>




                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SourceTableName"
                                                                            value={silver_InputValues.SourceTableName}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>

                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SourceColumnName"
                                                                            value={silver_InputValues.SourceColumnName}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>

                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SourceDatatype"
                                                                            value={silver_InputValues.SourceDatatype}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>
                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="SourceConstraints"
                                                                            value={silver_InputValues.SourceConstraints}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>

                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="TransformationLogic"
                                                                            value={silver_InputValues.TransformationLogic}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>
                                                                        <td><input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                            placeholder="Enter"
                                                                            name="Comments"
                                                                            value={silver_InputValues.Comments}
                                                                            onChange={handleSilverInputChange}
                                                                        /></td>


                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                    role="button"
                                                                                    id="dropdownMenuLink"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                    style={{ zIndex: 1001 }}
                                                                                >
                                                                                    {silver_InputValues.VerificationStatus}
                                                                                </a>
                                                                                <ul
                                                                                    className="dropdown-menu cust-dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuLink"
                                                                                >
                                                                                    {verificationStatusDropDown.map(value => (
                                                                                        <li key={value} onClick={() => handleSilverDropDownChange("VerificationStatus", value)}>
                                                                                            <a className="dropdown-item">{value}</a>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>


                                                                            <img
                                                                                src="images/add-icons.svg"
                                                                                alt="add-icon"
                                                                                title="Add"
                                                                                className={`cust-cursor-pointer `}
                                                                                onClick={() => handleAdd(silver_InputValues)}
                                                                                {...(isSilverFormFilled() ? {
                                                                                    "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                                                    "data-bs-target": "#editRows" // Specify the target modal
                                                                                } : {})}
                                                                                disabled={isSilverFormFilled()}
                                                                            /></td>
                                                                    </tr>
                                                            ) :
                                                                (goldEditingIndex != null) ?
                                                                    (<></>) : (
                                                                        //gold
                                                                        <tr>
                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="RequirementID"
                                                                                value={gold_InputValues.RequirementID}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>
                                                                            <td><select
                                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                                aria-label="Default select example"
                                                                                name="SubjectArea"
                                                                                value={gold_InputValues.SubjectArea}
                                                                                onChange={(e) => handleGoldDropDownChange("SubjectArea", e.target.value)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {DropDownSubjectArea?.map(value => (
                                                                                    <option key={value} value={value}>{value}</option>
                                                                                ))}
                                                                            </select></td>
                                                                            <td><select
                                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                                aria-label="Default select example"
                                                                                name="GoldSchemaName"
                                                                                value={gold_InputValues.GoldSchemaName}
                                                                                onChange={(e) => handleGoldDropDownChange("GoldSchemaName", e.target.value)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {dropDownGoldSchemaName?.map(value => (
                                                                                    <option key={value} value={value}>{value}</option>
                                                                                ))}
                                                                            </select></td>

                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="GoldTableName"
                                                                                value={gold_InputValues.GoldTableName}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>
                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="GoldColumnName"
                                                                                value={gold_InputValues.GoldColumnName}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td> <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="GoldDataType"
                                                                                value={gold_InputValues.GoldDataType}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td> <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="Constraints"
                                                                                value={gold_InputValues.Constraints}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td> <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="SourceSchemaName"
                                                                                value={gold_InputValues.SourceSchemaName}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>







                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="SourceTableName"
                                                                                value={gold_InputValues.SourceTableName}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>

                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="SourceColumnName"
                                                                                value={gold_InputValues.SourceColumnName}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>

                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="SourceDataType"
                                                                                value={gold_InputValues.SourceDataType}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>
                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="SourceConstraints"
                                                                                value={gold_InputValues.SourceConstraints}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>

                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="TransformationLogic"
                                                                                value={gold_InputValues.TransformationLogic}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>
                                                                            <td><input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                                                placeholder="Enter"
                                                                                name="Comments"
                                                                                value={gold_InputValues.Comments}
                                                                                onChange={handleGoldInputChange}
                                                                            /></td>


                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <a
                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                        role="button"
                                                                                        id="dropdownMenuLink"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                        style={{ zIndex: 1001 }}
                                                                                    >
                                                                                        {gold_InputValues.VerificationStatus}
                                                                                    </a>
                                                                                    <ul
                                                                                        className="dropdown-menu cust-dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuLink"
                                                                                    >
                                                                                        {verificationStatusDropDown.map(value => (
                                                                                            <li key={value} onClick={() => handleGoldDropDownChange("VerificationStatus", value)}>
                                                                                                <a className="dropdown-item">{value}</a>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <img
                                                                                    src="images/add-icons.svg"
                                                                                    alt="add-icon"
                                                                                    title="Add"
                                                                                    className={`cust-cursor-pointer `}
                                                                                    onClick={() => handleAdd(gold_InputValues)}

                                                                                    // aria-disabled={isFormFilled()}
                                                                                    {...(isGoldFormFilled() ? {
                                                                                        "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                                                        "data-bs-target": "#editRows" // Specify the target modal
                                                                                    } : {})}
                                                                                    disabled={isGoldFormFilled()}
                                                                                /></td>
                                                                        </tr>)))


                                                    }





                                                    {(selectedColumn == "bronze") ?
                                                        bindBronzeGrid() : (selectedColumn == "silver") ? bindSilverGrid() : bindGoldGrid()}



                                                </tbody>
                                            </table>




                                            {/*Metadata Table ends here*/}

                                            {/* model */}

                                            <div
                                                className="modal fade "
                                                id="editRows"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabIndex={-1}
                                                aria-labelledby="ConfrmLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0">
                                                            <h5
                                                                className="modal-title d-flex align-items-center font-medium font-20"
                                                                id="ConfrmLabel"
                                                            >
                                                                Edit Reason
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                                onClick={() => setReason("")}
                                                            />
                                                        </div>
                                                        <div className="modal-body py-0">
                                                            <div className="row" style={{ margin: "10px 0px 10px 0px" }}>
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label
                                                                        htmlFor="User-Name"
                                                                        className="form-label text-black font-14 font-medium"
                                                                    >
                                                                        Specify reason for manual edit
                                                                        <span className="text-red"> *</span>
                                                                    </label>
                                                                    <textarea
                                                                        type="text"
                                                                        className="form-control font-14 font-regular "
                                                                        id="User-Name"
                                                                        placeholder="Enter reason"
                                                                        rows={5}
                                                                        value={reason}
                                                                        onChange={(e) => handleReason(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer border-0 px-4 mt-4" >
                                                            <button
                                                                type="button"
                                                                className="btn cust-secondary-btn font-14 font-medium me-2"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => setReason("")}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn cust-primary-btn font-14 font-medium"
                                                                disabled={reason == null || reason == ""}

                                                                onClick={reason && handleSaveDetails}
                                                                data-bs-dismiss="modal"

                                                            >
                                                                <span>Save Details</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="modal fade"
                                                id="Confrm"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabIndex={-1}
                                                aria-labelledby="ConfrmLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0">
                                                            <h5
                                                                className="modal-title d-flex align-items-center font-medium font-20"
                                                                id="ConfrmLabel"
                                                            >
                                                                Confirmation
                                                            </h5>
                                                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                                        </div>
                                                        <div className="modal-body py-0">
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <p className="font-16" style={{ marginLeft: "20px" }}>
                                                                        Are you sure you want to mark this document as Not verified?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer border-0 px-4">
                                                            <button
                                                                type="button"
                                                                className="btn cust-secondary-btn font-14 font-medium me-2"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                No
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn cust-primary-btn font-14 font-medium"
                                                                onClick={() => handleNotVerified()}
                                                                data-bs-dismiss="modal"

                                                            >
                                                                <span>Yes</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                       {selectedColumn=="bronze"?<>
                                       { <p className="font-regular font-14 text-grey">
                                            # of Records:
                                            <span className="text-black font-medium">{bronzeJson.length} out of {bronzeResponse.length}</span>
                                        </p>}</>: selectedColumn=="silver"?<>{
                                            <p className="font-regular font-14 text-grey">
                                            # of Records:
                                            <span className="text-black font-medium">{silverJson.length} out of {silverResponse.length}</span>
                                        </p>
                                        }</>: <>{<p className="font-regular font-14 text-grey">
                                        # of Records:
                                        <span className="text-black font-medium">{goldJson.length} out of {goldResponse.length}</span>
                                    </p>} </>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ColumnMappingRework;
