import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";  // Import the XLSX library
import { generateSasTokenAPI, insertError, DWHActivityLogs } from "../../Service/Api.js";
import { HeaderCom } from "../Header.jsx";
import ViewInstructions from '../new/Integrations/viewInstructions.jsx';


function MappingGrid(props) {
    const { state } = useLocation(props);
    const [jsonData, setJsonData] = useState([]);
    const [availableMappings, setAvailableMappings] = useState({
        SourceToBronzeMapping: false,
        BronzeToSilverMapping: false,
        SilverToGoldMapping: false,
    });
    const [editingIndex, setEditingIndex] = useState(null);
    const [tabState, setTabState] = useState(null);
    const [sourceToBronzeInputValues, setSourceToBronzeInputValues] = useState({});
    const [bronzeToSilverInputValues, setBronzeToSilverInputValues] = useState({});
    const [silverToGoldInputValues, setSilverToGoldInputValues] = useState({});
    const [reason, setReason] = useState("");
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isReasonModalVisible, setReasonModalVisible] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState({
        sourceSchemaName: "Select",
        sourceTableName: "Select",
        bronzeSchemaName: "Select",
        bronzeTableName: "Select",
        silverSchemaName: "Select",
        silverTableName: "Select",
        goldSchemaName: "Select",
        goldTableName: "Select",
    });
    const [filteredResponse, setFilteredResponse] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pendingDeleteIndex, setPendingDeleteIndex] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    useEffect(() => {
        fetchPseudo();
    }, []);
    const [sortOrder, setSortOrder] = useState({
        order: "desc",
        column: "SourceSchemaName"
      });
      const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

  
    const fetchJsonFromBlob = async (inventoryDDURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${inventoryDDURL}?${sasToken}`;
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON from blob:", error);
            throw error;
        }
    };

    const fetchPseudo = async () => {
        document.getElementById('pageLoader').style.display = 'block';

        const projectId = state.projectId;
        const orgId = state.orgId
        const migrationType = props.migrationType;
        const accountName = "avaeusgenetlsametadev";
        const containerName = "uc-migration";
        const blobPath = `dwhmigration/${orgId}/${projectId}/development/${migrationType}/mappings.json`;
        const inventoryURL = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

        try {
            const data = await fetchJsonFromBlob(inventoryURL);
            if (data) {
                setJsonData(data);
                console.log(jsonData)
                determineAvailableMappings(data);
            } else {
                throw new Error("Failed to fetch data from Azure Blob Storage");
            }
        } catch (error) {
            handleError(error);
        }
        document.getElementById('pageLoader').style.display = 'none';
    };

    const determineAvailableMappings = (data) => {
        const mappings = {
            SourceToBronzeMapping: "SourceToBronzeMapping" in data,
            BronzeToSilverMapping: "BronzeToSilverMapping" in data,
            SilverToGoldMapping: "SilverToGoldMapping" in data,
        };
        const firstAvailableTab = Object.keys(mappings).find((key) => mappings[key]);
        setTabState(firstAvailableTab);
        setAvailableMappings(mappings);
    };

    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "DataDictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId,
        });
        console.error("Error:", e);
    };

    const handleTabChange = (tab) => {
        setEditingIndex(null);
        setTabState(tab);
        setFilteredResponse(jsonData[tab] || []);
    };

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        if (tabState === "SourceToBronzeMapping") {
            setSourceToBronzeInputValues((prevState) => ({ ...prevState, [field]: value }));
        } else if (tabState === "BronzeToSilverMapping") {
            setBronzeToSilverInputValues((prevState) => ({ ...prevState, [field]: value }));
        } else if (tabState === "SilverToGoldMapping") {
            setSilverToGoldInputValues((prevState) => ({ ...prevState, [field]: value }));
        }
    };

    const handleEditClick = (index) => {
        setEditingIndex(index); // Set the index currently being edited
    
        // Determine which mapping is currently active and populate the appropriate input state
        if (tabState === "SourceToBronzeMapping") {
            setSourceToBronzeInputValues({
                SourceSchemaName: filteredResponse[index].SourceSchemaName,
                SourceTableName: filteredResponse[index].SourceTableName,
                SourceColumnName: filteredResponse[index].SourceColumnName,
                SourceDataType: filteredResponse[index].SourceDataType,
                BronzeSchemaName: filteredResponse[index].BronzeSchemaName,
                BronzeTableName: filteredResponse[index].BronzeTableName,
                BronzeColumnName: filteredResponse[index].BronzeColumnName,
                BronzeDataType: filteredResponse[index].BronzeDataType,
            });
        } else if (tabState === "BronzeToSilverMapping") {
            setBronzeToSilverInputValues({
                BronzeSchemaName: filteredResponse[index].BronzeSchemaName,
                BronzeTableName: filteredResponse[index].BronzeTableName,
                BronzeColumnName: filteredResponse[index].BronzeColumnName,
                BronzeDataType: filteredResponse[index].BronzeDataType,
                SilverSchemaName: filteredResponse[index].SilverSchemaName,
                SilverTableName: filteredResponse[index].SilverTableName,
                SilverColumnName: filteredResponse[index].SilverColumnName,
                SilverDataType: filteredResponse[index].SilverDataType,
            });
        } else if (tabState === "SilverToGoldMapping") {
            setSilverToGoldInputValues({
                SilverSchemaName: filteredResponse[index].SilverSchemaName,
                SilverTableName: filteredResponse[index].SilverTableName,
                SilverColumnName: filteredResponse[index].SilverColumnName,
                SilverDataType: filteredResponse[index].SilverDataType,
                GoldSchemaName: filteredResponse[index].GoldSchemaName,
                GoldTableName: filteredResponse[index].GoldTableName,
                GoldColumnName: filteredResponse[index].GoldColumnName,
                GoldDataType: filteredResponse[index].GoldDataType,
            });
        }
        setCurrentOperation("edit"); // Set the current operation to "edit"
    
    };
    
    // In bindgrid, retrieve input values from current state
    const inputValues = () => {
        if (tabState === "SourceToBronzeMapping") {
            return sourceToBronzeInputValues;
        } else if (tabState === "BronzeToSilverMapping") {
            return bronzeToSilverInputValues;
        } else {
            return silverToGoldInputValues;
        }
    } // Set the index of the row being edited
      

    const handleDelete = (index) => {
        setPendingDeleteIndex(index);  // Track the index of the item to be deleted
        setReasonModalVisible(true);
        setCurrentOperation("delete");
    };

    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "x-ms-blob-type": "BlockBlob",
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log("Data updated successfully in Azure Blob");
        } catch (error) {
            console.error("Error updating JSON in blob:", error);
            throw error;
        }
    };

    const handleConfirmWithReason = async () => {
        document.getElementById('pageLoader').style.display = 'block';
        if (!currentOperation) return; // Exit if no operation is set

        const projectId = state.projectId;
        const orgId = state.orgId
        const migrationType = props.migrationType;
        const accountName = "avaeusgenetlsametadev";
        const containerName = "uc-migration";
        const blobPath = `dwhmigration/${orgId}/${projectId}/development/${migrationType}/mappings.json`;
        const blobUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

        try {
            let updatedData = [...jsonData[tabState]];

            if (currentOperation === "edit" && editingIndex !== null) {
                // Handle edit operation
                updatedData = updatedData.map((item, i) =>
                    i === editingIndex ? { ...item, ...getInputValues() } : item
                );
            } else if (currentOperation === "add") {
                // Handle add operation
                const newEntry = getInputValues();
                updatedData.push(newEntry);
            } else if (currentOperation === "delete" && pendingDeleteIndex !== null) {
                // Handle delete operation
                updatedData.splice(pendingDeleteIndex, 1);
            }
            let body={
                type: "mappingSheet",
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                comments:reason,
                actionType:currentOperation
 
            }
          let res=  await DWHActivityLogs(body,state.jwt)
          console.log(res)
            // Update the blob with the new data
            await updateJsonInBlob(blobUrl, { ...jsonData, [tabState]: updatedData });

            // Update local state
            setJsonData((prevState) => ({
                ...prevState,
                [tabState]: updatedData,
            }));
            setFilteredResponse(updatedData);

            // Clear input fields for the current tab if it's an add operation
            if (currentOperation === "add" || currentOperation === "edit") {
                if (tabState === "SourceToBronzeMapping") {
                    setSourceToBronzeInputValues({});
                } else if (tabState === "BronzeToSilverMapping") {
                    setBronzeToSilverInputValues({});
                } else if (tabState === "SilverToGoldMapping") {
                    setSilverToGoldInputValues({});
                }
            }

            // Refresh data if needed
            fetchPseudo();
        } catch (error) {
            console.error("Error updating data in blob:", error);
        } finally {
            // Reset all states
            setReason("");
            setEditingIndex(null);
            setCurrentOperation(null);
            setPendingDeleteIndex(null);
            setReasonModalVisible(false);
            document.getElementById('pageLoader').style.display = 'none';
        }
    };

    const handleAddClick = () => {
        setCurrentOperation("add");
        setReasonModalVisible(true);
    };

    const getInputValues = () => {
        switch (tabState) {
            case "SourceToBronzeMapping":
                return sourceToBronzeInputValues;
            case "BronzeToSilverMapping":
                return bronzeToSilverInputValues;
            case "SilverToGoldMapping":
                return silverToGoldInputValues;
            default:
                return {};
        }
    };

    useEffect(() => {
        const filteredData = jsonData[tabState]?.filter((record) => {
            if (!record) return false;
            
            const { schemaName1, schemaName2, tableName1, tableName2 } = getRelevantFields(tabState);
            return [
                record[schemaName1],
                record[schemaName2],
                record[tableName1],
                record[tableName2]
            ].some(value => value && value.toLowerCase().includes(searchTerm.toLowerCase()));
        });

        setFilteredResponse(filteredData || []);
    }, [searchTerm, filterCriteria, tabState, jsonData]);

    const getRelevantFields = (tab) => {
        switch (tab) {
            case "SourceToBronzeMapping":
                return {
                    schemaName1: "SourceSchemaName",
                    schemaName2: "BronzeSchemaName",
                    tableName1: "SourceTableName",
                    tableName2: "BronzeTableName"
                };
            case "BronzeToSilverMapping":
                return {
                    schemaName1: "BronzeSchemaName",
                    schemaName2: "SilverSchemaName",
                    tableName1: "BronzeTableName",
                    tableName2: "SilverTableName"
                };
            case "SilverToGoldMapping":
                return {
                    schemaName1: "SilverSchemaName",
                    schemaName2: "GoldSchemaName",
                    tableName1: "SilverTableName",
                    tableName2: "GoldTableName"
                };
            default:
                return {};
        }
    };

    const handleApplyFilter = () => {
        const filteredData = jsonData[tabState]?.filter((record) => {
            if (!record) return false;

            const { schemaName1, schemaName2, tableName1, tableName2 } = getRelevantFields(tabState);

            const filterLogic = {
                SourceToBronzeMapping: () =>
                    (filterCriteria.sourceSchemaName === "Select" || record[schemaName1]?.toLowerCase() === filterCriteria.sourceSchemaName.toLowerCase()) &&
                    (filterCriteria.sourceTableName === "Select" || record[tableName1]?.toLowerCase() === filterCriteria.sourceTableName.toLowerCase()) &&
                    (filterCriteria.bronzeSchemaName === "Select" || record[schemaName2]?.toLowerCase() === filterCriteria.bronzeSchemaName.toLowerCase()) &&
                    (filterCriteria.bronzeTableName === "Select" || record[tableName2]?.toLowerCase() === filterCriteria.bronzeTableName.toLowerCase()),
                
                BronzeToSilverMapping: () =>
                    (filterCriteria.bronzeSchemaName === "Select" || record[schemaName1]?.toLowerCase() === filterCriteria.bronzeSchemaName.toLowerCase()) &&
                    (filterCriteria.bronzeTableName === "Select" || record[tableName1]?.toLowerCase() === filterCriteria.bronzeTableName.toLowerCase()) &&
                    (filterCriteria.silverSchemaName === "Select" || record[schemaName2]?.toLowerCase() === filterCriteria.silverSchemaName.toLowerCase()) &&
                    (filterCriteria.silverTableName === "Select" || record[tableName2]?.toLowerCase() === filterCriteria.silverTableName.toLowerCase()),
                       
                SilverToGoldMapping: () =>
                    (filterCriteria.silverSchemaName === "Select" || record[schemaName1]?.toLowerCase() === filterCriteria.silverSchemaName.toLowerCase()) &&
                    (filterCriteria.silverTableName === "Select" || record[tableName1]?.toLowerCase() === filterCriteria.silverTableName.toLowerCase()) &&
                    (filterCriteria.goldSchemaName === "Select" || record[schemaName2]?.toLowerCase() === filterCriteria.goldSchemaName.toLowerCase()) &&
                    (filterCriteria.goldTableName === "Select" || record[tableName2]?.toLowerCase() === filterCriteria.goldTableName.toLowerCase()),
            };

            return filterLogic[tabState]();
        });

        setFilteredResponse(filteredData || []);
    };

    const handleClearFilter = () => {
        setFilterCriteria({
            sourceSchemaName: "Select",
            sourceTableName: "Select",
            bronzeSchemaName: "Select",
            bronzeTableName: "Select",
            silverSchemaName: "Select",
            silverTableName: "Select",
            goldSchemaName: "Select",
            goldTableName: "Select",
        });
        setFilteredResponse(jsonData[tabState] || []);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setSourceToBronzeInputValues({});
        setBronzeToSilverInputValues({});
        setSilverToGoldInputValues({});
    };
    
    
    const bindgrid = () => {
        if (!filteredResponse || !Array.isArray(filteredResponse) || !tabState) {
            return (
                <tr>
                    <td colSpan={9} className="text-center">
                        No Records Found
                    </td>
                </tr>
            );
        }
    
        return filteredResponse.map((item, index) => {
            const isEditing = editingIndex === index;
            const inputValues = isEditing ? getInputValues() : item;
    
            const renderInputFields = () => {
                if (tabState === "SourceToBronzeMapping") {
                    return (
                        <>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SourceSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceTableName'] || ""} onChange={(e) => handleInputChange(e, 'SourceTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SourceColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceDataType'] || ""} onChange={(e) => handleInputChange(e, 'SourceDataType')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeTableName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeColumnName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeDataType'] || ""} onChange={(e) => handleInputChange(e, 'BronzeDataType')} /></td>
                        </>
                    );
                } else if (tabState === "BronzeToSilverMapping") {
                    return (
                        <>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeTableName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeColumnName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeDataType'] || ""} onChange={(e) => handleInputChange(e, 'BronzeDataType')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SilverSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverTableName'] || ""} onChange={(e) => handleInputChange(e, 'SilverTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SilverColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverDataType'] || ""} onChange={(e) => handleInputChange(e, 'SilverDataType')} /></td>
                        </>
                    );
                } else if (tabState === "SilverToGoldMapping") {
                    return (
                        <>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SilverSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverTableName'] || ""} onChange={(e) => handleInputChange(e, 'SilverTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SilverColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverDataType'] || ""} onChange={(e) => handleInputChange(e, 'SilverDataType')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'GoldSchemaName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldTableName'] || ""} onChange={(e) => handleInputChange(e, 'GoldTableName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldColumnName'] || ""} onChange={(e) => handleInputChange(e, 'GoldColumnName')} /></td>
                            <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldDataType'] || ""} onChange={(e) => handleInputChange(e, 'GoldDataType')} /></td>
                        </>
                    );
                }
            };
    
            const renderStaticFields = () => {
                if (tabState === "SourceToBronzeMapping") {
                    return (
                        <>
                            <td>{item?.SourceSchemaName || "NA"}</td>
                            <td>{item?.SourceTableName || "NA"}</td>
                            <td>{item?.SourceColumnName || "NA"}</td>
                            <td>{item?.SourceDataType || "NA"}</td>
                            <td>{item?.BronzeSchemaName || "NA"}</td>
                            <td>{item?.BronzeTableName || "NA"}</td>
                            <td>{item?.BronzeColumnName || "NA"}</td>
                            <td>{item?.BronzeDataType || "NA"}</td>
                        </>
                    );
                } else if (tabState === "BronzeToSilverMapping") {
                    return (
                        <>
                            <td>{item?.BronzeSchemaName || "NA"}</td>
                            <td>{item?.BronzeTableName || "NA"}</td>
                            <td>{item?.BronzeColumnName || "NA"}</td>
                            <td>{item?.BronzeDataType || "NA"}</td>
                            <td>{item?.SilverSchemaName || "NA"}</td>
                            <td>{item?.SilverTableName || "NA"}</td>
                            <td>{item?.SilverColumnName || "NA"}</td>
                            <td>{item?.SilverDataType || "NA"}</td>
                        </>
                    );
                } else if (tabState === "SilverToGoldMapping") {
                    return (
                        <>
                            <td>{item?.SilverSchemaName || "NA"}</td>
                            <td>{item?.SilverTableName || "NA"}</td>
                            <td>{item?.SilverColumnName || "NA"}</td>
                            <td>{item?.SilverDataType || "NA"}</td>
                            <td>{item?.GoldSchemaName || "NA"}</td>
                            <td>{item?.GoldTableName || "NA"}</td>
                            <td>{item?.GoldColumnName || "NA"}</td>
                            <td>{item?.GoldDataType || "NA"}</td>
                        </>
                    );
                }
            };
    
            // Render input fields or static text based on edit mode
            const renderRowFields = () => {
                if (isEditing) {
                    return renderInputFields();
                } else {
                    return renderStaticFields();
                }
            };
    
            return (
                <tr key={index}>
                    {renderRowFields()}
                    <td className="text-center">
                        {isEditing ? (
                            <>
                                <img
                                        src="images/right.svg"
                                        alt="confirm-edit"
                                        className="cust-cursor-pointer me-3"
                                        title="Confirm"
                                        onClick={() => setReasonModalVisible(true)}
                                    />
                                    <img
                                        src="images/cross-red.svg"
                                        alt="cancel-edit"
                                        className="cust-cursor-pointer"
                                        title="Cancel"
                                        onClick={() => handleCancelEdit()}
                                    />
                            </>
                        ) : (
                            <>
                                <img
                                        src="images/edit-icon.svg"
                                        alt="edit-icon"
                                        className="cust-cursor-pointer me-3"
                                        title="Edit"
                                        onClick={() => handleEditClick(index)}
                                    />
                                    <img
                                        src="images/delete-icon.svg"
                                        alt="delete-icon"
                                        className="cust-cursor-pointer"
                                        title="Delete"
                                        onClick={() => handleDelete(index)}
                                    />
                            </>
                        )}
                    </td>
                </tr>
            );
        });
    };
    // Define header configuration for each tab
    const headerConfig = {
        SourceToBronzeMapping: [
            { key: "SourceSchemaName", label: "Source Schema Name" },
            { key: "SourceTableName", label: "Source Table Name" },
            { key: "SourceColumnName", label: "Source Column Name" },
            { key: "SourceDataType", label: "Source Data Type" },
            { key: "BronzeSchemaName", label: "Bronze Schema Name" },
            { key: "BronzeTableName", label: "Bronze Table Name" },
            { key: "BronzeColumnName", label: "Bronze Column Name" },
            { key: "BronzeDataType", label: "Bronze Data Type" }
        ],
        BronzeToSilverMapping: [
            { key: "BronzeSchemaName", label: "Bronze Schema Name" },
            { key: "BronzeTableName", label: "Bronze Table Name" },
            { key: "BronzeColumnName", label: "Bronze Column Name" },
            { key: "BronzeDataType", label: "Bronze Data Type" },
            { key: "SilverSchemaName", label: "Silver Schema Name" },
            { key: "SilverTableName", label: "Silver Table Name" },
            { key: "SilverColumnName", label: "Silver Column Name" },
            { key: "SilverDataType", label: "Silver Data Type" }
        ],
        SilverToGoldMapping: [
            { key: "SilverSchemaName", label: "Silver Schema Name" },
            { key: "SilverTableName", label: "Silver Table Name" },
            { key: "SilverColumnName", label: "Silver Column Name" },
            { key: "SilverDataType", label: "Silver Data Type" },
            { key: "GoldSchemaName", label: "Gold Schema Name" },
            { key: "GoldTableName", label: "Gold Table Name" },
            { key: "GoldColumnName", label: "Gold Column Name" },
            { key: "GoldDataType", label: "Gold Data Type" }
        ]
    };
    
    
    const renderTableHeaders = () => {
        if (!tabState) return;
        return (
            <>
                {headerConfig[tabState].map((col, index) => (
                    <th key={index}>
                        {col.label}{" "}
                        <span className="ms-2 cursor-pointer">
                            <img
                                src={
                                    sortOrder.column === col.key
                                        ? sortOrder.order === "asc"
                                            ? "images/sort-up-icon.svg"
                                            : "images/sort-down-icon.svg"
                                        : "images/sort-down-icon.svg"
                                }
                                alt="sort-arrow"
                                onClick={() => handleSort(col.key)}
                            />
                        </span>
                    </th>
                ))}
                {/* Separate rendering of the Actions column without the sort icon */}
                <th>Actions</th>
            </>
        );
    };
    // Adjust the sort handler to work with dynamic columns
    const handleSort = (columnName) => {
        const isSameColumn = columnName === sortOrder.column;
        const newSortOrder = {
            column: columnName,
            order: isSameColumn && sortOrder.order === "asc" ? "desc" : "asc",
        };
    
        setSortOrder(newSortOrder);
    
        const sortedData = [...filteredResponse].sort((a, b) => {
            if (!a[columnName] || !b[columnName]) return 0;
            if (newSortOrder.order === "asc") {
                return a[columnName]?.localeCompare(b[columnName], undefined, { numeric: true }) || 0;
            } else {
                return b[columnName]?.localeCompare(a[columnName], undefined, { numeric: true }) || 0;
            }
        });
    
        setFilteredResponse(sortedData);
    };
    
    const renderInputRow = () => {
        if (editingIndex !== null || currentOperation === "edit") return null;
    
        const inputValues = getInputValues();
    
        const addClickHandler = () => {
            if (isFormFilled()) {
                handleAddClick();
            }
        };
    
        // Determine the style for the "Add" icon based on whether the form is filled
        const addIconStyle = {
            opacity: isFormFilled() ? 1 : 0.3,
            cursor: isFormFilled() ? 'pointer' : 'not-allowed'
        };
    
        // Render the input row based on the current tab state
        if (tabState === "SourceToBronzeMapping") {
            return (
                <tr>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SourceSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceTableName'] || ""} onChange={(e) => handleInputChange(e, 'SourceTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SourceColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SourceDataType'] || ""} onChange={(e) => handleInputChange(e, 'SourceDataType')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeTableName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeColumnName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeDataType'] || ""} onChange={(e) => handleInputChange(e, 'BronzeDataType')} /></td>
                    <td>
                        <img
                            src="images/add-icons.svg"
                            alt="add-icon"
                            title="Add"
                            className="cust-cursor-pointer"
                            onClick={addClickHandler}
                            style={addIconStyle}
                        />
                    </td>
                </tr>
            );
        } else if (tabState === "BronzeToSilverMapping") {
            return (
                <tr>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeTableName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeColumnName'] || ""} onChange={(e) => handleInputChange(e, 'BronzeColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['BronzeDataType'] || ""} onChange={(e) => handleInputChange(e, 'BronzeDataType')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SilverSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverTableName'] || ""} onChange={(e) => handleInputChange(e, 'SilverTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SilverColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverDataType'] || ""} onChange={(e) => handleInputChange(e, 'SilverDataType')} /></td>
                    <td>
                        <img
                            src="images/add-icons.svg"
                            alt="add-icon"
                            title="Add"
                            className="cust-cursor-pointer"
                            onClick={addClickHandler}
                            style={addIconStyle}
                        />
                    </td>
                </tr>
            );
        } else if (tabState === "SilverToGoldMapping") {
            return (
                <tr>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'SilverSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverTableName'] || ""} onChange={(e) => handleInputChange(e, 'SilverTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverColumnName'] || ""} onChange={(e) => handleInputChange(e, 'SilverColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['SilverDataType'] || ""} onChange={(e) => handleInputChange(e, 'SilverDataType')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldSchemaName'] || ""} onChange={(e) => handleInputChange(e, 'GoldSchemaName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldTableName'] || ""} onChange={(e) => handleInputChange(e, 'GoldTableName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldColumnName'] || ""} onChange={(e) => handleInputChange(e, 'GoldColumnName')} /></td>
                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" value={inputValues['GoldDataType'] || ""} onChange={(e) => handleInputChange(e, 'GoldDataType')} /></td>
                    <td>
                        <img
                            src="images/add-icons.svg"
                            alt="add-icon"
                            title="Add"
                            className="cust-cursor-pointer"
                            onClick={addClickHandler}
                            style={addIconStyle}
                        />
                    </td>
                </tr>
            );
        }
    };
    
    const isFormFilled = () => {
        const inputValues = getInputValues(); // Assuming this function retrieves the input values
        console.log("input values in isFormFilled:", inputValues);
    
        const defaultValues = ['', 'Select', null, undefined]; // Default values to check against
    
        // Check if any of the input fields contain a value classified as "default" or empty
        const isAnyValueDefault = Object.values(inputValues).some(value => 
            defaultValues.includes(value) || (typeof value === 'string' && value.trim() === '')
        );
    
        console.log("isAnyValueDefault:", isAnyValueDefault);
    
        // Return true if none of the fields have default values, meaning the form is filled
        return !isAnyValueDefault;
    };

    const handleReason = async (e) => {
        setReason(e.target.value)
    }

    const handleDownload = () => {
        const dataToExport = filteredResponse.map((item) => {
            // Remove unnecessary fields, if any
            return { ...item };
        });

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "MappingGrid.xlsx");
    };

    return (
        <div className="container-fluid">
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                        <HeaderCom value="1" />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0">
                            <li className="font-16 font-medium">
                                <a>Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a>{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Mapping Sheet</li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-between p-4">
                            <div className="d-flex">
                                <a className="d-flex">
                                    <img
                                        src="images/back-arrow.svg"
                                        alt="back-arrow"
                                        style={{ width: "24px", height: "24px", cursor: "pointer", whiteSpace: "nowrap" }}
                                        onClick={() => { props.closeGrid() }}
                                    />
                                </a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">Mapping Sheet
                                {/* <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText} */}
                            <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button>
                        </h2>
                        {isModalOpen && <ViewInstructions notebooktype='dwhMigration' onClose={handleCloseModal} />}
                            </div>
                            <div className="d-flex justify-content-end p-4">
                                <div className="input-group position-relative search-wid me-md-3" style={{ paddingLeft: '10px' }}>
                                    <input
                                        type="search"
                                        className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                        placeholder="Search"
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                    />
                                    <span className="search-icon-sty">
                                        <a>
                                            <img src="images/search-icon.svg" alt="search icon" />
                                        </a>
                                    </span>
                                </div>
                                <div className="btn-group me-3">
                                    <button
                                        className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon" />
                                    </button>
                                    <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                                <button type="button" className="btn-close" aria-label="Close" />
                                            </div>
                                            <div className="row">
                                                {tabState === "SourceToBronzeMapping" && (
                                                    <>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Source Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, sourceSchemaName: e.target.value })}
                                                                value={filterCriteria.sourceSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SourceSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Source Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, sourceTableName: e.target.value })}
                                                                value={filterCriteria.sourceTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SourceTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Bronze Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, bronzeSchemaName: e.target.value })}
                                                                value={filterCriteria.bronzeSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.BronzeSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Bronze Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, bronzeTableName: e.target.value })}
                                                                value={filterCriteria.bronzeTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.BronzeTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </>
                                                )}
                                                {tabState === "BronzeToSilverMapping" && (
                                                    <>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Bronze Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, bronzeSchemaName: e.target.value })}
                                                                value={filterCriteria.bronzeSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.BronzeSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Bronze Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, bronzeTableName: e.target.value })}
                                                                value={filterCriteria.bronzeTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.BronzeTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Silver Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, silverSchemaName: e.target.value })}
                                                                value={filterCriteria.silverSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SilverSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Silver Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, silverTableName: e.target.value })}
                                                                value={filterCriteria.silverTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SilverTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </>
                                                )}
                                                {tabState === "SilverToGoldMapping" && (
                                                    <>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Silver Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, silverSchemaName: e.target.value })}
                                                                value={filterCriteria.silverSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SilverSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Silver Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, silverTableName: e.target.value })}
                                                                value={filterCriteria.silverTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.SilverTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Gold Schema Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, goldSchemaName: e.target.value })}
                                                                value={filterCriteria.goldSchemaName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.GoldSchemaName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label text-black font-14 font-medium">Gold Table Name</label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, goldTableName: e.target.value })}
                                                                value={filterCriteria.goldTableName}
                                                            >
                                                                <option value="Select">Select</option>
                                                                {[...new Set((jsonData[tabState] || []).map(item => item.GoldTableName))].map((name, index) => (
                                                                    <option key={index} value={name}>{name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-end mt-4 mb-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                    onClick={handleClearFilter}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn cust-primary-btn font-14 font-medium px-4"
                                                    onClick={handleApplyFilter}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <button onClick={handleDownload} type="button" className="btn cust-secondary-btn font-16 font-medium">
                <span className="me-2">
                  <img src="images/download-icon.svg" alt="add-icon" />
                </span>
                Download
              </button>
                            </div>
                        </div>
                    </div>
                    <div className="code-review-tab-width" style={{width:"95%"}}>
                        <div className="code-review-page checklists tabs">
                            <div className="tabs">
                                {Object.keys(availableMappings).map(
                                    (key) =>
                                        availableMappings[key] && (
                                            <button
                                                key={key}
                                                onClick={() => handleTabChange(key)}
                                                className={`tab font-bold ${tabState === key ? "active" : ""}`}
                                                style={{ border: "none", backgroundColor: "white" }}
                                            >
                                                {key.replace("Mapping", "").replace(/To/, " to ")}
                                            </button>
                                        )
                                )}
                            </div>
                        </div>
                        <div className="table-responsive custom-activity-logs pt-5" >
                            <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                <thead className="sticky-top-pos">
                                    <tr>{renderTableHeaders()}</tr>
                                </thead>
                                <tbody>
                                    {renderInputRow()}
                                    {bindgrid()}
                                </tbody>
                            </table>

                            {isReasonModalVisible && (
                                <>    <div
                                className="modal-backdrop fade show"
                                style={{ display: "block" }}
                            ></div>
                                <div
                                    className="modal fade show"
                                    style={{ display: "block" }}
                                    id="editRows"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="ConfrmLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header border-0">
                                                <h5
                                                    className="modal-title d-flex align-items-center font-medium font-20"
                                                    id="ConfrmLabel"
                                                >
                                                    Reason for {currentOperation}
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        setReason("");
                                                        setReasonModalVisible(false);
                                                        setPendingDeleteIndex(null);  // Reset on cancel
                                                         handleCancelEdit()
                                                    }}
                                                />
                                            </div>
                                            <div className="modal-body py-0">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <label
                                                            htmlFor="User-Name"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Specify reason for {currentOperation}
                                                            <span className="text-red"> *</span>
                                                        </label>
                                                        <textarea
                                                                type="text"
                                                                className="form-control font-14 font-regular "
                                                                id="User-Name"
                                                                placeholder="Enter reason"
                                                                rows={5}
                                                                defaultValue={null}
                                                                value={reason}
                                                                onChange={handleReason}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer border-0 px-4 mt-4">
                                                <button
                                                    type="button"
                                                    className="btn cust-secondary-btn font-14 font-medium me-2"
                                                    onClick={() => {
                                                        setReason("");
                                                        setReasonModalVisible(false);
                                                        setPendingDeleteIndex(null);  // Reset on cancel
                                                         handleCancelEdit()
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn cust-primary-btn font-14 font-medium"
                                                    onClick={handleConfirmWithReason}
                                                    disabled={!reason}
                                                >
                                                    <span>Save Details</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                            
                            )}

                            <p className="font-regular font-14 text-grey mt-3">
                                # of Records:{" "}
                                <span className="text-black font-medium">
                                    {filteredResponse.length} out of {(jsonData[tabState] || []).length}
                                </span>
                            </p>
                            <div className="d-flex justify-content-center mt-3 mb-5 pb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MappingGrid;