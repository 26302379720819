import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getManageRequets } from '../Service/Api';
import { postManageRequests } from '../Service/Api';
import { download } from '../Service/Api'
import { HeaderCom } from './Header';


import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import moment from 'moment';

export default function Requests(props) {
    // Declare the state variables
    // PC_MR_02

    const nav = useNavigate();
    const { state } = useLocation()
    const [fetchRequests, setFetchRequests] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: 'timeStamp', ascending: false });
    const [visibleItems, setVisibleItems] = useState(10);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');

    const [filterCriteria, setFilterCriteria] = useState({
        OrganizationName: 'Select',
        phase: 'Select'
    });
    const [dataPresent, setDataPresent] = useState(false);

    const { userId, jwt, orgId } = state

    // const [showModal, setShowModal] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (userId) {
            fetchRequest();
        }
    }, []);
    // const adRole = 1
    console.log('jwtTT', jwt)
    console.log("state in manage request", state)
    // Pseudocode No:PC_MR_05 TO 23
    {/*
The fetchRequest function is an asynchronous function responsible for fetching data for managing requests. It first sets the loader visibility to true to indicate loading. Then, it attempts to fetch data using the getManageRequets function. If the response status is 403, it calls the sessionExpired function. If the status is not 200, it triggers an error modal. Otherwise, 
it sorts the requests based on timeStamp, filters out null timeStamps, sets the fetched requests to state variables, and sets data presence to true. Finally, it catches any errors, logs them, and may handle them further. */}

    async function fetchRequest() {
        // document.getElementById('pageLoader').style.display = "block"

        console.log("enters fetchrequets function")
        setLoaderVisible(true);
        try {
            console.log("enters try scope of manageRequests")
            document.getElementById('pageLoader').style.display = "block"
            const response = await getManageRequets(userId, state.jwt, state.adRole, "design");
            // document.getElementById('pageLoader').style.display = "none"

            if (response.status === 403) {
                sessionExpired();
            }

            else if (response.status !== 200) {
                // Show error modal
                document.getElementById('openErrorModal').click();

            }
            else {
                console.log("enters good else in manageRequests");
                console.log("response", response);
                console.log("response from manage request", response[0]); // Remove [0] here
                var sortedRequests = response.data[0].sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
                sortedRequests = sortedRequests.filter(a => a.timeStamp != null); // Change timeStamp to timeStamp
                // setOrgName(data.data[1])
                setFetchRequests(sortedRequests);
                setDataPresent(true);
                setFilteredRecords(sortedRequests);
                console.log(sortedRequests, "sortedRequest");

            }
            document.getElementById('pageLoader').style.display = "none"

            // setFetchRequests(response.data);
            // setLoaderVisible(false);
        } catch (error) {
            const errorData = {
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRequests',
                functionName: 'fetchRequestsData',
                userId: userId
            };
            // Insert error to database and show error popup
            // insertError(errorData);
        }
    }

    // Pseudocode No:PC_MR_24 TO 29
    // The sessionExpired function handles the scenario when a user's session expires. It clears the local storage to remove any cached user data, displays a toast message indicating the session expiration, and then redirects the user to the login page after a 3-second delay. This function ensures that users are informed about the session expiration and smoothly transitioned to the login page to authenticate and continue their session.

    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
             nav('/');
        }, 3000);
    };

    // PC_MR_30 TO 39
    {/*The handleSort function is responsible for sorting records based on a specified column.
   It first determines whether the column to sort by is the same as the current sorted column.
    Then, it determines the new sorting order based on whether it's the same column or a different one.
     After updating the sorting order in the state, it sorts the filtered records using a custom comparison function. 
     If the column is 'timeStamp', it converts the values to Date objects for comparison. Otherwise, it compares the values using the localeCompare method.
 Finally, it updates the filtered records with the sorted list.*/}
    const handleSort = (column) => {
         ;
        const isSameColumn = sortOrder.column === column;
        const newOrder = isSameColumn ? !sortOrder.ascending : true;
        setSortOrder({
            column,
            ascending: newOrder,
        });
        const sortedList = filteredRecords?.slice().sort((a, b) => {
            const valueA = typeof a[column] !== 'undefined' ? a[column] : '';
            const valueB = typeof b[column] !== 'undefined' ? b[column] : '';

            if (column === 'timeStamp') {
                const dateA = new Date(valueA);
                const dateB = new Date(valueB);
                return newOrder ? dateB - dateA : dateA - dateB;
            } else {
                if (valueA !== null && valueB !== null) {
                    const compareValue = valueA.localeCompare(valueB, undefined, { numeric: false, sensitivity: 'base' });
                    return newOrder ? compareValue : -compareValue;
                }
                else {
                    // Handle null values
                    return newOrder ? (valueB === null ? -1 : 1) : (valueA === null ? -1 : 1);
                }
            }
        });
        setFilteredRecords(sortedList);
    };

    // Pseudocode No:PC_MR_54 TO 64
    {/*The handleSearch function filters records based on a search term. If the search term has a length of at least 3 
characters, it filters the fetchRequests based on whether the project name or organization name contains the search term (case-insensitive). 
If the term is less than 3 characters, it resets the filtered records to include all fetchRequests. Finally, it updates the state with the filtered records. */}
    const handleSearch = (term) => {
        console.log("handleSearch")
        if (term.length >= 3) {
            // If the search term is empty, reset to all fetchRequests
            // Filter the ActionLogs based on projectName or orgName
            const filteredData = fetchRequests.filter((record) => {
                // Check if projectName or orgName contains the search term (case-insensitive)
                return (
                    (record.ProjectName && record.ProjectName.toLowerCase().includes(term.toLowerCase())) ||
                    (record.OrganizationName && record.OrganizationName.toLowerCase().includes(term.toLowerCase()))
                );
            });

            setFilteredRecords(filteredData);
        } else {
            setFilteredRecords(fetchRequests);
        }
    };


    // PC_MR_40
    {/*The handleApplyFilter function filters fetchRequests based on the filter criteria provided. 
It checks if each record matches the selected organization name, phase, and document type. If any of these criteria are set to "Select," 
it considers all records for that criteria. The filtered list is then set as the new set of filtered records. Additionally, 
it sets a flag to indicate that a filter is applied and hides the filter interface */}
    const handleApplyFilter = () => {
        console.log("handleApplyFilter started");
        console.log("Filter Criteria:", filterCriteria);

        const { OrganizationName, phase } = filterCriteria;

        const filteredList = fetchRequests.filter((record) => {
            const organizationNameMatch = OrganizationName.toLowerCase() === 'select' || (record.OrganizationName && record.OrganizationName.toLowerCase() === OrganizationName.toLowerCase());
            const phaseMatch = phase.toLowerCase() === 'select' || (record.phase && record.phase.toLowerCase() === phase.toLowerCase());

            return phaseMatch && organizationNameMatch;
        });

        console.log("Filtered List:", filteredList.length, filteredList);

        setFilteredRecords(filteredList);
        setIsFilterApplied(true);
        setShowFilter(false);
    };
    // PC_MR_49
    {/*The handleClearFilter function resets the filter criteria to their default values:
 "Select" for document type, phase, and organization name. It then sets the filtered 
records back to the original fetchRequests array and resets the flag indicating that a filter is applied.  */}
    const handleClearFilter = () => {
        console.log("handleClearFilter");

        setFilterCriteria({

            phase: 'Select',
            OrganizationName: 'Select',
        });

        setFilteredRecords(fetchRequests);
        setIsFilterApplied(false);
    };

    // Pseudocode No:PC_MR_41 TO 45
    // The BindOrganizationName function extracts unique organization names from the fetchRequests array using a Set to filter out duplicates. It then maps each unique organization name to an <option> element with a corresponding value attribute. This function effectively generates a list of organization names for use in a dropdown select input.
    function BindOrganizationName() {
        const uniqueOrgNames = [...new Set(fetchRequests.map(val => val.OrganizationName))];
        return uniqueOrgNames.map((orgName, index) => (
            <option key={index} value={orgName}>{orgName}</option>
        ));
    }


    // Pseudocode No:PC_MR_46 TO PC_MR_48
    // The BindPhase function operates similarly to BindOrganizationName, but instead, it extracts unique phase names from the fetchRequests array. It creates a dropdown list of phase names by mapping each unique phase name to an <option> element with a corresponding value attribute. This function is useful for generating options for a phase selection dropdown input.
    function BindPhase() {
        const uniquePhases = [...new Set(fetchRequests.map(val => val.phase))];
        return uniquePhases.map((phase, index) => (
            <option key={index} value={phase}>{phase}</option>
        ));
    }


    // Pseudocode No:PC_MR_152 TO 172
    {/*The handleApprove function is responsible for approving or rejecting a request by sending a request to the server.
 Upon execution, it sets the loader to be visible, attempts to post the request, and handles potential errors. If the 
response status is 403, indicating a session expiration, it triggers a session expiration action. If the response status 
is not 200, it may display an error modal. Finally, regardless of success or failure, it hides the loader. */}
    async function handleApprove(requestId, status, projectId, phase) {
        console.log("entered handleApprove function", orgId)
         
        setLoaderVisible(true);
        try {
            document.getElementById('pageLoader').style.display = "block"
            const res = await postManageRequests(requestId, status, projectId, phase, userId, jwt, orgId,);
            document.getElementById('pageLoader').style.display = "none"
            console.log("")
            if (res.status === 403) {
                sessionExpired();
                return; // Exit function early
            }

            else if (res.status !== 200) {
                // Show error modal
                // sessionExpired();
                // return; // Exit function early
            }
            else {
                console.log("handle approve data from db", res)
                fetchRequest()
            }

        } catch (error) {
            const errorData = {
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRequests',
                functionName: 'handleApprove',
                userId: userId
            };
            // Insert error to database and show error popup
            // insertError(errorData);
        } finally {
            // Hide loader regardless of success or failure
            setLoaderVisible(false);
        }
    }


    // Pseudocode No:PC_MR_173 TO 186
    {/*The handleRejecte function is responsible for approving or rejecting a request by sending a request to the server.
 Upon execution, it sets the loader to be visible, attempts to post the request, and handles potential errors. If the 
response status is 403, indicating a session expiration, it triggers a session expiration action. If the response status 
is not 200, it may display an error modal. Finally, regardless of success or failure, it hides the loader. */}
    async function handleReject(requestId, status, projectId, phase) {
        console.log("entered rejected function")

        setLoaderVisible(true);
        try {
            document.getElementById('pageLoader').style.display = "block"
            const response = await postManageRequests(requestId, status, projectId, phase, userId, jwt, orgId,);
            if (response.status === 403) {
                sessionExpired();
                return; // Exit function early
            }

            else if (response.status !== 200) {
                // Show error modal
                document.getElementById('openErrorModal').click();
                return; // Exit function early
            }
            else {
                console.log(response, "response of handle rejected")
                fetchRequest()
            }

        } catch (error) {
            const errorData = {
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRequests',
                functionName: 'handleReject',
                userId: userId
            };
            // Insert error to database and show error popup
            // insertError(errorData);
        } finally {
            // Hide loader regardless of success or failure
            setLoaderVisible(false);
        }
    }

    useEffect(() => {
        {/* // PS_CD_1.38 */ }
        handleSearch(searchTerm);
    }, [searchTerm]);

    
    
    
// workinggggggg
    async function handleDownload(phase, futureState, methodology, type, requestId) {
        let designDocs;
    
        setLoaderVisible(true);
        try {
            document.getElementById('pageLoader').style.display = "block"
            const response = await download(userId, state.jwt, state.adRole, phase, futureState, methodology, type, requestId);
    
            if (response.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                designDocs = JSON.parse(response.document[0].document);
            }
    
            console.log('Design Docs:', designDocs);
    
            if (designDocs) {
                let arr = [];
                let sheetNameCount = {};
    
                if (phase === 'Development') {
                    if (futureState === 'Databricks' || futureState === 'DataBricks' || (futureState === 'Synapse' && methodology === 'NoteBook')) {
                        if (designDocs.loggerPseudoCode) arr.push({ name: "Logger", value: designDocs.loggerPseudoCode });
                        if (designDocs.utilitiesPseudocode) arr.push({ name: "Utilities", value: designDocs.utilitiesPseudocode });
    
                        arr.push({ name: "MetadataTable", value: designDocs.metadataTable });
                        arr.push({ name: "DataDictionarySilver", value: designDocs.dataDictionarySilver });
                        arr.push({ name: "DataDictionaryGold", value: designDocs.dataDictionaryGold });
                        arr.push({ name: "DDLPseudocodeBronze", value: designDocs.DDLPseudocodeBronze });
                        arr.push({ name: "DDLPseudocodeGold", value: designDocs.DDLPseudocodeGold });
                        arr.push({ name: "DDLPseudocodeSilver", value: designDocs.DDLPseudocodeSilver });
                        arr.push({ name: "CodeReviewCheckList", value: designDocs.CodeReviewCheckList });
                        arr.push({ name: "UnitTesting", value: designDocs.unitTestCasePseudocode });
                        arr.push({ name: "DataQuality", value: designDocs.dataQualityPseudocode });
                        arr.push({ name: "Orchestration", value:designDocs.Orchestration.pseudoCodepipeline[0].pseudoCodeOrchestration})
                    }
                   
                    if (designDocs.Bronze) {
                        designDocs.Bronze.forEach((bronze) => {
                            arr.push({ name: bronze.SheetName, value: bronze.pseudoCodebronze });
                        });
                    }
                    
                    if (designDocs.Silver) {
                        designDocs.Silver.forEach((silver) => {
                            arr.push({ name: "Transformation-Silver_"+silver.notebookNamesilver, value: silver.pseudoCodesilver });
                        });
                    }
                    
                    if (designDocs.Gold) {
                        designDocs.Gold.forEach((gold) => {
                            arr.push({ name: "Transformation-gold_"+gold.notebookNamegold, value: gold.pseudoCodegold });
                        });
                    }

                    console.log(arr,'this is my final array')

                 
                } else if (phase === 'Design' || phase === 'Define') {
                    arr.push({ name: "sourceSystemDetails", value: designDocs?.sourceSystemDetails || [] });
                    arr.push({ name: "dwhSubjectArea", value: designDocs?.dwhSubjectArea || [] });
                    arr.push({ name: "extraction", value: designDocs?.extraction || [] });
                    arr.push({ name: "silverZoneTransformation", value: designDocs?.silverZoneTransformation || [] });
                    arr.push({ name: "goldZoneTransformation", value: designDocs?.goldZoneTransformation || [] });
                    arr.push({ name: "genericRequirements", value: designDocs?.genericRequirements || [] });
                    arr.push({ name: "testStrategy", value: designDocs?.testStrategy || [] });
                }
    
                const wb = XLSX.utils.book_new();
 sheetNameCount = {};

 console.log(arr,"this is my array")
 arr.forEach(sheet => {
    let originalName = sheet.name;

    // Truncate the sheet name to a maximum of 31 characters
    let truncatedName = originalName.slice(0, 31);

    // Handle duplicates
    if (truncatedName in sheetNameCount) {
        sheetNameCount[truncatedName]++;
        const suffix = `_${sheetNameCount[truncatedName]}`;
        
        // Ensure that the total length does not exceed 31 characters
        if (truncatedName.length + suffix.length > 31) {
            truncatedName = truncatedName.slice(0, 31 - suffix.length);
        }
        truncatedName += suffix;
    } else {
        sheetNameCount[truncatedName] = 1;
    }

    let sheetData;

    // Check if sheet.value is an array of objects
    if (Array.isArray(sheet.value) && sheet.value.length > 0 && typeof sheet.value[0] === 'object') {
        const flattenedItems = sheet.value.map(item => flatten(item));
        const headers = Object.keys(flattenedItems[0]);
        sheetData = [headers, ...flattenedItems.map(item => Object.values(item))];
    } else if (typeof sheet.value === 'object' && sheet.value !== null) {
        const flattenedData = flatten(sheet.value);
        const headers = Object.keys(flattenedData);
        sheetData = [headers, Object.values(flattenedData)];
    } else if (Array.isArray(sheet.value)) {
        sheetData = [['Value'], ...sheet.value.map(item => [item])];
    } else {
        sheetData = [['Value'], [sheet.value]];
    }

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, truncatedName);
});

const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

function getDocumentName(phase) {
    if (phase === "Define") return "Define Document";
    if (phase === "Design") return "Define Document";
    if (phase === "Development") return "Design Document";
    return "Document";
}

FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${getDocumentName(phase)}.xlsx`);

document.getElementById('pageLoader').style.display = "none"
            }
        } catch (error) {
            console.error("Error downloading document:", error);
            // document.getElementById('openErrorModal').click();
        } finally {
            setLoaderVisible(false);
        }
    }
    // Utility function to flatten nested objects/arrays
    const flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
                Object.assign(acc, flatten(obj[k], pre + k));
            } else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    };
    
    
    
    //FOR OTHER TARGET SYSTEMS. 

    // else if (futureState == 'ADF' || futureState == 'Synapse' && methodology == "pipeline") {
    //     arr.push({ name: "linkedService", value: designDocs.linkedService })
    //     arr.push({ name: "Datasets", value: designDocs.Datasets })
    //     arr.push({ name: "Dataflow", value: designDocs.DataFlow })
    //     arr.push({ name: "ColumnMappingBronze", value: designDocs.columnMappingBronze })
    //     arr.push({ name: "ColumnMappingSilver", value: designDocs.columnMappingSilver })
    //     arr.push({ name: "ColumnMappingGold", value: designDocs.columnMappingGold })
    //     arr.push({ name: "TableMapping", value: designDocs.tableMapping })
    //     arr.push({ name: "MetadataTable", value: designDocs.metadataTable })
    //     arr.push({ name: "DataDictionary", value: designDocs?.dataDictionary })
    //     arr.push({ name: "DDL", value: designDocs.DDLPseudocode })
    //     arr.push({ name: "CodeReviewCheckList", value: designDocs.CodeReviewCheckList })
    //     for (let bronze of designDocs.BronzePipeline) {
    //         arr.push({ name: "PipelineDesign-Bronze", value: bronze.pseudoCodebronze })
    //     }
    //     let silverArr = []
    //     for (let silver of designDocs.SilverPipeline) {
    //         silverArr = [...silverArr, ...silver.pseudoCodesilver]
    //     }
    //     arr.push({ name: "PipelineDesign-Silver", value: silverArr })
    //     let goldArr = []
    //     for (let gold of designDocs.GoldPipeline) {
    //         goldArr = [...goldArr, ...gold.pseudoCodegold]
    //     }
    //     arr.push({ name: "PipelineDesign-Gold", value: goldArr })
    //     let orcArr = [];
    //     Object.values(designDocs.Orchestration.pseudoCodepipeline).forEach((val) => {
    //         if (Array.isArray(val)) {
    //             orcArr = [...orcArr, ...val];
    //         }
    //     });
    //     arr.push({ name: "Orchestration", value: orcArr });

    // }
    // else {


    //     arr.push({ name: "ColumnMappingBronze", value: designDocs.columnMappingBronze })
    //     arr.push({ name: "ColumnMappingSilver", value: designDocs.columnMappingSilver })
    //     arr.push({ name: "ColumnMappingGold", value: designDocs.columnMappingGold })
    //     arr.push({ name: "TableMapping", value: designDocs.tableMapping })
    //     arr.push({ name: "MetadataTable", value: designDocs.metadataTable })
    //     arr.push({ name: "DataDictionary", value: designDocs.dataDictionary })
    //     arr.push({ name: "DDL", value: designDocs.DDLPseudocode })
    //     arr.push({ name: "CodeReviewCheckList", value: designDocs.CodeReviewCheckList })

    //     for (let bronze of designDocs.Bronze) {
    //         arr.push({ name: " Transformation-Bronze", value: bronze.pseudoCodebronze })
    //     }
    //     let silverArr = []
    //     for (let silver of designDocs.Silver) {
    //         silverArr = [...silverArr, ...silver.pseudoCodesilver]
    //     }
    //     arr.push({ name: "Transformation-Silver", value: silverArr })
    //     let goldArr = []
    //     for (let gold of designDocs.Gold) {
    //         goldArr = [...goldArr, ...gold.pseudoCodegold]
    //     }
    //     arr.push({ name: "Transforamtion-Gold", value: goldArr })

    //     let orcArr = [];
    //     Object.values(designDocs.Orchestration.pseudoCodepipeline).forEach((val) => {
    //         if (Array.isArray(val)) {
    //             orcArr = [...orcArr, ...val];
    //         }
    //     });
    //     arr.push({ name: "Orchestration", value: orcArr });


    // }










    // PC_MR_190 TO 192
    {/*The loadMoreItems function increases the number of visible items
 displayed in a list when the "Load More" button is clicked. It updates the visibleItems state by 
 adding the itemsPerPage value to the previous number of visible items. This allows more 
items to be displayed incrementally as the button is clicked.*/}
    const loadMoreItems = () => {
        // Increase visibleItems by itemsPerPage when LoadMore button is clicked
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
    };

    // PC_MR_190
    //The tableData function generates table rows based on the provided filteredRecords. It first checks if filteredRecords is not empty. If it's not empty, it maps over each record and creates a table row (<tr>) with specific data fields such as user name, organization logo, project name, phase, document link, timeStamp, and status. Additionally, it provides functionality to handle document download and status approval/rejection through clickable icons. 
    const tableData = () => {
        console.log(filteredRecords, "filteredRecords");
        //  ;
        if (filteredRecords?.length !== 0) {
            return filteredRecords?.map((val, index) => {
                if (index < visibleItems) {
                    return (
                        <tr key={val.requestId} className={index % 2 === 0 ? "" : "table-alter-bg"}>
                            <td className="text-left">{val.UserName}</td>
                            <td>
                                <span className="cursor-pointer">
                                    <img
                                        src={val.OrganizationLogo}
                                        alt="sort-down-icon"
                                        className="activity-log-icon"
                                    />
                                </span>
                                {val.OrganizationName}
                            </td>
                            <td>{val.ProjectName}</td>
                            <td>{val.phase}</td>
                            <td>
                                <a
                                    className="link-clr"
                                    style={{ cursor: 'pointer' }}
                                    value={val.phase}
                                    onClick={() => handleDownload(val.phase, val.futureStateName, val.Methodology, "download", val.requestId)}
                                >
                                    {/* {val.phase === 'Design' ? 'Define Document' : 'Design Document'} */}
                                    {val.phase === 'Define'
                                        ? 'Define Document'
                                        : val.phase === 'Design'
                                            ? 'Define Document'
                                            : val.phase === 'Development'
                                                ? 'Design Document'
                                                : ''}
                                </a>
                            </td>

                            <td>{moment(val.timeStamp).format("MMM DD, YYYY hh:mm A")}</td>
                            <td>
                                {val.status === "yet to start" ? (
                                    <div>
                                        <span class="cust-cursor-pointer">
                                            <img src="images/right.svg" class="me-3" width="15" height="15" onClick={() => handleApprove(val.requestId, "Approved", val.projectId, val.phase)} />
                                        </span>
                                        <span class="cust-cursor-pointer">
                                            <img src="images/wrong.svg" width="20" height="20" onClick={() => handleReject(val.requestId, "Rejected", val.projectId, val.phase)} />
                                        </span>
                                    </div>
                                ) : (
                                    <span
                                        className={`rounded-3 py-1 px-2 font-13 font-medium ${val.status === "Approved" ? "approved-badge" : "rejected-badge"}`}
                                    >
                                        {val.status}
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                }
            });
        }
    };



    return (
        <div className="container-fluid">
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
                    <div className="d-flex  align-items-center py-1">
                        <a className="navbar-brand" href="#"><img src="images/codegen-logo.svg" alt="codegen-logo" className="logo-sty" /></a>
                        <div className="ms-5">
                            <button className={props.value == '1' ? "header-button-active fw-bold" : 'header-button'} onClick={() => nav('/manageOrganization', { state: state })}>Organization</button>
                            <button className={props.value == '2' ? "header-button-active fw-bold ms-2" : 'header-button ms-2'} onClick={() => nav('/activityLogs', { state: state })}>Activity Logs</button>
                            <button className={props.value == '3' ? "header-button-active fw-bold ms-2" : 'header-button ms-2'} onClick={() => nav('/requests', { state: state })}>Manage Requests</button>
                        </div>
                        <div className="dropdown me-lg-3 ms-auto">
                            <img src="images/user-profile.svg" alt="user-profile" className="profile-sty cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                                <li><a className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" href="#"><span className="me-2"><img src="images/logout.svg" alt="profile-icon" /></span>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <HeaderCom value='3' />

                {/* header style ends here */}

                <div className="col-md-12 pt-4 mt-5 mb-5 pb-5">
                    <div className="row p-4">
                        <div className="d-xl-flex d-lg-block mb-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 mb-3 ">
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">Manage Requests</h2>
                            </div>

                            <div className="d-flex">
                                <div className="input-group position-relative search-wid me-3">
                                    <input type="search" className="form-control cust-input-sty font-14 rounded-2 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                    <span className="search-icon-sty"><a ><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                </div>
                                <div className="btn-group me-3">
                                    <button className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon" />
                                    </button>

                                    <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="StartDate" className="form-label text-black font-14 font-medium">Organization</label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.OrganizationName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, OrganizationName: e.target.value }) }}>
                                                            <option value="Select">Select</option>
                                                            {BindOrganizationName()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="endDate" className="form-label text-black font-14 font-medium">Phase</label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.phase}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, phase: e.target.value }) }}>
                                                            <option value="Select">Select</option>
                                                            {BindPhase()}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="text-end mt-4 mb-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                    onClick={() => { handleClearFilter() }}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={filterCriteria.OrganizationName == 'Select' && filterCriteria.phase == 'Select' && filterCriteria.document == 'Select' ? true : false}
                                                    className="btn cust-primary-btn font-14 font-medium px-4"
                                                    onClick={() => { handleApplyFilter() }}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* table struc starts here */}

                    {dataPresent == true && filteredRecords?.length == 0 ? (

                        <div className="col-md-12">
                            <div className="text-center pt-5">
                                <span><img src="images/no-records.svg" alt="grid-no-rec-icon" className="no-rec-icon mt-4" /></span>
                                {searchTerm != '' || isFilterApplied == true ? (
                                    <p className="font-24 font-medium text-black mt-5">No Files found!</p>

                                ) : (

                                    <>
                                        <p className="font-24 font-medium text-black mt-5">No files created so far!</p>
                                        <p className="font-18 font-regular text-grey-v3 mt-2">Start generating your first file.</p>
                                    </>
                                )}
                            </div>
                        </div>

                    ) : (
                        <div className="table-responsive page mx-4">
                            <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table">
                                <thead className="sticky-top-pos">
                                    <tr>
                                        <th className='font-14'>
                                            Username
                                        </th>
                                        <th onClick={() => { handleSort('OrganizationName') }} className='font-14'>
                                            Organization
                                            {sortOrder.column == 'OrganizationName' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)
                                                    }
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                        <th onClick={() => { handleSort('ProjectName') }}>
                                            Project
                                            {sortOrder.column == 'ProjectName' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)
                                                    }
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>

                                        <th >Phase</th>
                                        <th >Document</th>
                                        <th onClick={() => { handleSort('timeStamp') }} className='font-14'>
                                            Time Stamp
                                            {sortOrder.column == 'timeStamp' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData()}
                                </tbody>
                            </table>

                            {filteredRecords?.length != 0 && visibleItems <= filteredRecords?.length ? <p className="font-regular font-14 text-grey">
                                # of Records:
                                <span className="text-black font-medium">{visibleItems} out of {filteredRecords?.length}</span>
                            </p> : filteredRecords?.length != 0 && visibleItems >= filteredRecords?.length ? <p className="font-regular font-14 text-grey">
                                # of Records:
                                <span className="text-black font-medium">{visibleItems - (visibleItems - filteredRecords?.length)} out of {filteredRecords?.length}</span>
                            </p> : <></>}

                            {/* table struc ends here */}
                            {visibleItems <= filteredRecords?.length ? < div className="d-flex justify-content-center mt-3">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium px-3 text-nowrap" onClick={() => loadMoreItems()}
                                >
                                    Load More
                                </button>
                            </div> : <></>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}