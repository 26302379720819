import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {  getSourceSystemConnectioninfo1 } from '../../Service/Api';
import ConnectionInfo from './ingestConnectionPopup';

// Mock data for available source systems
import { sourceType } from './contrains'; // Adjust the path as needed

const SourceInfoPopup = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialEntries = [
    ...(location.state?.data || []),
    ...(props.state || [])
  ];

  const [entries, setEntries] = useState(initialEntries);
  const [isEditing, setIsEditing] = useState(null);
  const [selectedSystemTypes, setSelectedSystemTypes] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [currentStep, setCurrentStep] = useState(1); // Step management added
  const [sourceSystemInfo, setSourceSystemInfo] = useState({
    systemName: '',
    internalExternal: 'Select',
    systemType: [],
    subjectArea: [],
    sourceSystemName: 'Select',
    sourceSystemType: 'Select',
    dataVolume: 'Select',
    description: '',
    connectionDetails: {}
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [disableSourceFields, setDisableSourceFields] = useState(false);
  
  const availableSourceSystems = sourceType[0].data;

  useEffect(() => {
    fetchDetails();
  }, [location.state, props.state]); // Change this if you have specific dependencies

  useEffect(() => {
    if (isEditing !== null) {
      const entry = entries[isEditing];
      setSourceSystemInfo(entry);
      const selectedSystem = availableSourceSystems.find(
        (system) => system.sourceSystem === entry.sourceSystemName
      );
      setSelectedSystemTypes((selectedSystem && selectedSystem.systemType) || []);
      setSelectedFields((selectedSystem && selectedSystem.fields) || []);
    }
  }, [isEditing, entries]);

  useEffect(() => {
    checkFormValidity();
  }, [sourceSystemInfo, entries]);

  const fetchDetails = async () => {
    // Assuming `location.state` or `props.state` has projectId, userId, and jwt
    let state = location.state || props.state || {};
    console.log(state);
    
    try {
      let body={
        projectId:state.projectId,
        userId:state.userId,
        jwt:state.jwt,
        sourceType:'dwhmigration'
      }
      let response = await getSourceSystemConnectioninfo1(body);
      console.debug(response, "Fetch Details Response");
      if (response.status === 403) {
        // sessionExpired(); // Handle session expiry if needed
        return;
      }
      if (response.status === 200 && response?.data[0]?.length > 0) {
        setDisableSourceFields(true);
        
        const newDetails = response?.data[0]
          ?.filter((data) => data.connectionDetails != null)
          .map((data) => ({
            ...data,
            connectionDetails: JSON.parse(data.connectionDetails),
          }));
        console.log(newDetails,"newDetails")
        
        // Update entries and source system details
        setEntries(newDetails || []);
        // if (newDetails && newDetails.length > 0) {
        //   setSourceSystemInfo(newDetails[0]);
        // }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const   checkFormValidity = () => {
    // Check if at least one complete entry exists or all fields are filled
    setIsFormValid(entries.length > 0 || allFieldsFilled());
  };

  const clearForm = () => {
    setSourceSystemInfo({
      systemName: '',
      internalExternal: 'Select',
      systemType: [],
      subjectArea: [],
      sourceSystemName: 'Select',
      sourceSystemType: 'Select',
      dataVolume: 'Select',
      description: '',
    });
    setSelectedSystemTypes([]);
    setSelectedFields([]);
    setIsEditing(null);
  };

  const handleChange = (event, key) => {
    const { value } = event.target;
    setSourceSystemInfo((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleDropdownChange = (value, key, multiple = false) => {
    if (key === 'sourceSystemName') {
      const selectedSystem = availableSourceSystems.find(
        (system) => system.sourceSystem === value
      );
      setSelectedSystemTypes(selectedSystem ? selectedSystem.systemType : []);
      setSelectedFields(selectedSystem ? selectedSystem.fields : []);
      setSourceSystemInfo((prevState) => ({
        ...prevState,
        [key]: value,
        sourceSystemType: 'Select',
      }));
    } else if (multiple) {
      const isChecked = sourceSystemInfo[key].includes(value);
      setSourceSystemInfo((prevState) => ({
        ...prevState,
        [key]: isChecked
          ? prevState[key].filter((item) => item !== value)
          : [...prevState[key], value],
      }));
    } else {
      setSourceSystemInfo((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  const handleAddOrEdit = () => {
    if (!allFieldsFilled()) {
      console.log("Please fill out all fields before adding the entry.");
      return;
    }
  
    const entryWithFields = { ...sourceSystemInfo, fields: selectedFields };
    console.log(entryWithFields,"Dattatatta");
    
    if (isEditing !== null) {
      const updatedEntries = entries.map((entry, index) =>
        index === isEditing ? entryWithFields : entry
      );
      setEntries(updatedEntries);
      setIsEditing(null);
    } else {
      setEntries([...entries, entryWithFields]);
    }
    clearForm();
  }

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleDelete = (index) => {
    const updatedEntries = entries.filter((_, idx) => idx !== index);
    setEntries(updatedEntries);
    checkFormValidity(); // Ensure we recheck form validity after deletion
  };

  const allFieldsFilled = () => {
    const keysToCheck = [
      'systemName',
      'internalExternal',
      'systemType',
      'subjectArea',
      'sourceSystemName',
      'sourceSystemType',
      'dataVolume',
      'description',
    ];
    return keysToCheck.every((key) =>
      Array.isArray(sourceSystemInfo[key])
        ? sourceSystemInfo[key].length > 0
        : sourceSystemInfo[key] !== '' && sourceSystemInfo[key] !== 'Select'
    );
  };

  const handleNext = () => {
    console.log({ data: entries }, 'this is the propData');
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = () => {
    navigate('/ConnectionInfo', { state: { data: entries } });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
              className="modal-backdrop fade show"
              style={{ display: "block" }}
          ></div>
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="ConnectionInfoLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content px-2">
              <div className="modal-header border-0">
                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConnectionInfoLabel">
                  {currentStep === 1 ? 'Source System and Connection Info' : 'Connection Details'}
                </h5>
                <button 
                  type="button" 
                  className="btn-close" 
                  onClick={() => {
                    clearForm();
                    props.closePopup();
                  }} 
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-0 my-4">
                {currentStep === 1 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-center mb-5">
                        <ul className="list-unstyled custom-stepper d-flex align-items-center">
                          <li className={`stepper-sty ${currentStep === 1 ? 'active' : ''} me-5 pe-4 d-flex align-items-center`}>
                            <span className="connect-info-icon icon-sty"></span>
                            <span className="stepper-text font-14 font-bold ms-2">Source System</span>
                          </li>
                          <li className={`stepper-sty ${currentStep === 2 ? 'active' : ''} me-5 pe-4 d-flex align-items-center position-relative`}>
                            <span className="meta-info-icon icon-sty stepper-line"></span>
                            <span className="stepper-text font-14 font-bold ms-2">Connection Info</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="table-responsive meta-table-scrl mt-3">
                        <table className="table table-borderless rounded custom-grid border-0">
                          <thead className="sticky-top-pos custom-tableHeader">
                            <tr>
                              <th>System Name</th>
                              <th>Internal/External</th>
                              <th>System Type</th>
                              <th>Subject Area</th>
                              <th>Source System Name</th>
                              <th>Source System Type</th>
                              <th>Data Volume</th>
                              <th>Description</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className="form-control cust-input-sty font-12 font-regular"
                                  placeholder="Enter Systemname"
                                  value={sourceSystemInfo.systemName}
                                  onChange={(e) => handleChange(e, 'systemName')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={['Internal', 'External']}
                                  selected={sourceSystemInfo.internalExternal}
                                  onChange={(value) => handleDropdownChange(value, 'internalExternal')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={['Operational', 'Financial', 'HR', 'Sales', 'Marketing']}
                                  selected={sourceSystemInfo.systemType}
                                  onChange={(value) => handleDropdownChange(value, 'systemType', true)}
                                  multiple
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={['Operational', 'Financial', 'HR', 'Sales', 'Marketing']}
                                  selected={sourceSystemInfo.subjectArea}
                                  onChange={(value) => handleDropdownChange(value, 'subjectArea', true)}
                                  multiple
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={availableSourceSystems.map((system) => system.sourceSystem)}
                                  selected={sourceSystemInfo.sourceSystemName}
                                  onChange={(value) => handleDropdownChange(value, 'sourceSystemName')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={selectedSystemTypes}
                                  selected={sourceSystemInfo.sourceSystemType}
                                  onChange={(value) => handleDropdownChange(value, 'sourceSystemType')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  items={['Less than 1 GB', '1 GB to 500 GB', '500 GB to 1 TB', '1 TB to 10 TB', '10 TB to 100 TB']}
                                  selected={sourceSystemInfo.dataVolume}
                                  onChange={(value) => handleDropdownChange(value, 'dataVolume')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control cust-input-sty font-14 font-regular"
                                  placeholder='Enter Description'
                                  value={sourceSystemInfo.description}
                                  onChange={(e) => handleChange(e, 'description')}
                                  disabled={disableSourceFields}
                                />
                              </td>
                              <td className="text-center">
                                <span className="cust-cursor-pointer" onClick={handleAddOrEdit}>
                                  <img src="images/add-icons.svg" alt="Add/Edit" />
                                </span>
                              </td>
                            </tr>
                            {entries.map((entry, index) => (
                              <tr key={index}>
                                <td>{entry.systemName}</td>
                                <td>{entry.InternalExternal||entry.internalExternal}</td>
                                <td>{Array.isArray(entry.systemType) ? entry.systemType.join(', ') : entry.systemType}</td>
                                <td>{Array.isArray(entry.subjectArea) ? entry.subjectArea.join(', ') : entry.subjectArea}</td>
                                <td>{entry.SourceSystemName||entry.sourceSystemName}</td>
                                <td>{entry.sourceSystemType}</td>
                                <td>{entry.dataVolume}</td>
                                <td>{entry.descriptions||entry.description}</td>
                                <td className="text-center">
                                  <div>
                                    <span className="cust-cursor-pointer" onClick={() => handleEdit(index)}>
                                      <img src="images/blue-edit-icon.svg" className="me-3" alt="Edit" />
                                    </span>
                                    <span className="cust-cursor-pointer" onClick={() => handleDelete(index)}>
                                      <img src="images/delete-icon.svg" alt="Delete" />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* Content for Step 2 */}
                    <h2>Connection Details</h2>
                    {/* Add your form fields or other components here for the second step */}
                  </div>
                )}
              </div>
              <div className="modal-footer border-0 px-3 mb-4 mt-4">
                {currentStep === 1 ? (
                  <>
                    <button
                      type="button"
                      className="btn cust-secondary-btn font-14 font-medium me-3"
                      onClick={() => {
                        clearForm();
                        props.closePopup();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn cust-primary-btn font-14 font-medium"
                      onClick={handleNext}
                      disabled={!isFormValid}
                    >
                      <span>Next</span>
                    </button>
                  </>
                ) : (
                  <ConnectionInfo state={entries} closePopup={props.closePopup} submit={props.onChange} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ items, selected, onChange, multiple = false, disabled = false }) => {
  return (
    <div className="dropdown">
      <button
        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        disabled={disabled}
      >
        {multiple 
          ? (Array.isArray(selected) && selected.length ? selected.join(', ') : 'Select')
          : selected || 'Select'}
      </button>
      <ul className="dropdown-menu cust-dropdown-menu" aria-labelledby="dropdownMenuButton">
        {items.map((item, index) => (
          <li key={index}>
            {multiple ? (
              <div className="dropdown-item form-check ps-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item}
                  checked={Array.isArray(selected) ? selected.includes(item) : false}
                  id={`flexCheck${item}`}
                  onChange={() => onChange(item, multiple)}
                />
                <label className="font-12" htmlFor={`flexCheck${item}`}>
                  {item}
                </label>
              </div>
            ) : (
              <a
                className="dropdown-item font-12"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onChange(item, multiple);
                }}
              >
                {item}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SourceInfoPopup;
