//SeqNo_1 - SeqNo_2: Import React, { useState, useEffect } from 'react', useLocation, useNavigate from 'react-router-dom', 
// and libraries XLSX, FileSaver, saveAs, PlantUML, HeaderCom
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, updatePseudocodeData } from "../Service/Api.js";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import PlantUML from "react-plantuml";
import { HeaderCom } from "./Header.jsx";
import axios from 'axios';

function SourceToExtractionER(props) {
    //SeqNo_3: Define state variables including 'whetherUpdated', 'reason', 'ERModelUML', 'gridVissible', 
   
    let { state } = useLocation();
    state = { ...state, previous: "prev" };

    const nav = useNavigate();
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [reason, setReason] = useState(null);
    const [ERModelUML, setERModelUML] = useState("");
    const [gridVissible, setGridVissible] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [isReasonOpen, setIsReasonOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [svgResponse, setSvgResponse] = useState('');

    const [gridData, setGridData] = useState([]);
    const [editableRow, setEditableRow] = useState("empty");
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'SubjectArea' })
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);
    const [filterSourceSystem, setFilterSourceSystem] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [action, setAction] = useState("")
    const [zoom, setZoom] = useState(1);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [filterCriteria, setFilterCriteria] = useState({
        SubjectArea: "",
        SourceSystem: ""
    });
    const [newData, setNewData] = useState({
        SubjectArea: "",
        SourceSystem: "",
        SourceDatabaseName: "",
        SourceSchemaName: "",
        SourceTableName: "",
        SourceColumnName: "",
        SourceDataType: "",
        BronzeSchemaName: "",
        BronzeTableName: "",
        BronzeColumnName: "",
        BronzeDatatype: "",
        Constraints: ""
    })

    //SeqNo_4 - SeqNo_5: Initial Data Fetch Invoke useEffect to perform data fetching when component mounts 
    // Define async function fetchData() to get initial UML and grid data from APIs
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        if (ERModelUML) {
            fetchKrokiDiagram(ERModelUML);
        }
    }, [ERModelUML]);

  const fetchKrokiDiagram = async (umlCode) => {
      try {
          document.getElementById('pageLoader').style.display = "block";
          const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
              headers: {
                  'Content-Type': 'text/plain',
              },
          });
          console.log(response, 'response.data from Kroki');
          
          setSvgResponse(response.data); // Set the SVG image source
          document.getElementById('pageLoader').style.display = "none";
      } catch (error) {
          console.error('Error fetching diagram from Kroki:', error);
          setSvgResponse('<div>Error generating diagram</div>');
          document.getElementById('pageLoader').style.display = "none";
      }
  };
    // SeqNo_6: Inside fetchData(), call fetchPseudo() to retrieve UML and grid data from the backend
    const fetchData = async () => {
        try {
            const pseudoDataPromise = fetchPseudo();
            // Wait for both promises to resolve
            const [pseudoData] = await Promise.all([pseudoDataPromise]);
            console.log("res from fetch pseudo", pseudoData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // SeqNo_7 - SeqNo_22: Call fetchPseudocodeData() function in API.js to get data from the backend server
    const fetchPseudo = async () => {
        try {

            let data = { type: "sourceToExtractionER", projectId: state.projectId, userId: state.userId }
            let jwt = state.jwt;
            document.getElementById('pageLoader').style.display = "block"
            //  
            const pseudodata = await fetchPseudocodeData(data, jwt)
            console.log(pseudodata, "pseudodatapseudodatapseudodata")
            //  
            document.getElementById('pageLoader').style.display = "none"
            if (pseudodata.status === 403) {
                sessionExpired();
                return;
            } else if (pseudodata.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("res from fetchPseudocodeData", pseudodata.data[0].sourceToBronzeER);
                setERModelUML(pseudodata.data[0].sourceToBronzeER);
                const gridDataforBronze = JSON.parse(pseudodata.data[0].sourceTobronzeMapping)
                console.log(gridDataforBronze, 'gridDataforBronzegridDataforBronze');

                //SeqNo_17: Update state variables with received data to reflect initial UML and grid data
                setFilteredRecord(gridDataforBronze)
                setGridData(gridDataforBronze)
                setTotalCount(gridDataforBronze?.length)
                if (Array.isArray(gridDataforBronze) && gridDataforBronze.length > 0) {
                    const subjectAreas = [...new Set(gridDataforBronze.map(item => item.SubjectArea))];
                    const sourceSystems = [...new Set(gridDataforBronze.map(item => item.SourceSystem))];

                    setFilterSubjectArea(subjectAreas);
                    setFilterSourceSystem(sourceSystems);
                }
            }
        }
        catch (e) {
            console.log("error in fetch", e);
        }
    }

    //SeqNo_23 - SeqNo_28:  Call handleZoomIn() method to increment zoom level by 0.1 units for close-up view
    const handleZoomIn = () => {
        setZoom(zoom + 0.1);
    };

    //SeqNo_29 - SeqNo_34: Call handleZoomOut() method to decrement zoom level ensuring minimum zoom of 0.2
    const handleZoomOut = () => {
        if (zoom > 0.2) {
            setZoom(zoom - 0.1);
        }
    };

    //SeqNo_17: Update state variables with received data and invoke bindGrid() to reflect initial UML and grid data
    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SubjectArea' value={newData?.SubjectArea} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceSystem' value={newData?.SourceSystem} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceDatabaseName' value={newData?.SourceDatabaseName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceSchemaName' value={newData?.SourceSchemaName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceTableName' value={newData?.SourceTableName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceColumnName' value={newData?.SourceColumnName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceDataType' value={newData?.SourceDataType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='BronzeSchemaName' value={newData?.BronzeSchemaName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='BronzeTableName' value={newData?.BronzeTableName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='BronzeColumnName' value={newData?.BronzeColumnName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Constraints' value={newData?.Constraints} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} disabled={true} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='BronzeDatatype' value={newData?.BronzeDatatype} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>

                                <td class="text-center fixed-column-cell grid-fixed-td-color-white">
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        console.log(newData, index, 'newDatanewDatanewData');
                                        if (Object.values(newData).every(value => value !== '')) { setAction("edit"); setIsReasonOpen(true); }
                                    }}><img
                                            src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            SubjectArea: "",
                                            SourceSystem: "",
                                            SourceDatabaseName: "",
                                            SourceSchemaName: "",
                                            SourceTableName: "",
                                            SourceColumnName: "",
                                            SourceDataType: "",
                                            BronzeSchemaName: "",
                                            BronzeTableName: "",
                                            BronzeColumnName: "",
                                            Constraints: "",
                                            BronzeDatatype: ""
                                        })
                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{item.SubjectArea || ""}</td>
                                <td>{item.SourceSystem || ""}</td>
                                <td>{item.SourceDatabaseName || ""}</td>
                                <td>{item.SourceSchemaName || ""}</td>
                                <td>{item.SourceTableName || ""}</td>
                                <td>{item.SourceColumnName || ""}</td>
                                <td>{item.SourceDataType || ""}</td>
                                <td>{item.BronzeSchemaName || ""}</td>
                                <td>{item.BronzeTableName || ""}</td>
                                <td>{item.BronzeColumnName || ""}</td>
                                <td>{item.Constraints || ""}</td>
                                <td>{item.BronzeDatatype || ""}</td>
                                <td className={`text-center sticky-action-column fixed-column-header ${index % 2 == 0 ? "grid-fixed-td-color-white" : "grid-fixed-td-color"}`}>
                                    <td class="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setSelectedIndex(index); console.log(newData, index, 'qwqnewDatanewDatanewData'); setEditableRow(index); setNewData(filteredRecord[index]) }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    //SeqNo_35 - SeqNo_41: Call handleSearch() method with entered search term to filter grid data dynamically
    const handleSearch = (e) => {
        console.log(e, 'wdsfs');

        let filteredData = gridData;
        if (e.length >= 3) {
            filteredData = filteredData.filter(row =>
                row.SubjectArea.toLowerCase().includes(e.toLowerCase()) ||
                row.SourceSystem.toLowerCase().includes(e.toLowerCase())
            );
        }
        setFilteredRecord(filteredData);

    }

    //SeqNo_42 - //SeqNo_48: Call handleApplyFilter() method with selected filter values from dropdowns
    const handleApplyFilter = () => {
        const { SubjectArea, SourceSystem } = filterCriteria;

        let filteredData = gridData;
        if (SubjectArea) {
            filteredData = filteredData.filter(item => item.SubjectArea === SubjectArea);
        }
        if (SourceSystem) {
            filteredData = filteredData.filter(item => item.SourceSystem === SourceSystem);
        }

        setFilteredRecord(filteredData);
        setFilterOpen(false);
    };

    //SeqNo_49 - //SeqNo_55: Call handleApplyFilter() method with selected filter values from dropdowns
    const handleClearFilter = () => {
        setFilterOpen(false)
        setFilterCriteria({
            SubjectArea: "",
            SourceSystem: ""
        });
        setFilteredRecord(gridData);
    };

    //SeqNo_56 - SeqNo_62: Call handleSort() method with columnName parameter corresponding to clicked column header 
    const handleSort = (columnName) => {
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder = {
            column: columnName,
            order: isSameColumn && sortOrder.order === 'asc' ? 'desc' : 'asc'
        };

        setSortOrder(newSortOrder);

        let sortedRecords = [...filteredRecord].sort((a, b) => {
            let aValue = a[columnName];
            let bValue = b[columnName];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return newSortOrder.order === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            } else {
                // Assume numeric for non-string values
                aValue = Number(aValue);
                bValue = Number(bValue);
                return newSortOrder.order === 'asc'
                    ? aValue - bValue
                    : bValue - aValue;
            }
        });

        setFilteredRecord(sortedRecords);
    };

    //SeqNo_63 - SeqNo_69: Capture user-provided reason from modal and call handleEdit() method with reason
    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        //Request Body:
        const body = {
            "projectId": state.projectId,
            "type": "sourceToExtractionER",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": newData,
            "index": editableRow,
            "newData": newData,
            "oldData": gridData,
        }

        //SeqNo_80: Call updatePseudocodeData() function in API.js with edited row data and reason
        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, body, "updateRessupdateRessupdateRess");
        //SeqNo_81 - SeqNo_91: Return parsed response and status to handleEdit method reflecting edit completion
        if (res.status == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            //SeqNo_94: Update grid data state with the new edited values ensuring display of accurate data
            console.log("res from fetchPseudocodeData", res.data[0], res.data[1]);
            setERModelUML(res.data[0]);
            const gridDataforBronze = JSON.parse(res.data[1])

            setFilteredRecord(gridDataforBronze)
            setGridData(gridDataforBronze)
            setTotalCount(gridDataforBronze?.length)

            setNewData({
                SubjectArea: "",
                SourceSystem: "",
                SourceDatabaseName: "",
                SourceSchemaName: "",
                SourceTableName: "",
                SourceColumnName: "",
                SourceDataType: "",
                BronzeSchemaName: "",
                BronzeTableName: "",
                BronzeColumnName: "",
                BronzeDatatype: ""
            })
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
            document.getElementById('pageLoader').style.display = "none"
        }
    };


    // SeqNo_98 - SeqNo_108: Call handleDownload() method to download the data as UML or xlsx based on the gridVissible
    const handleDownload = async () => {
        try {
            if (gridVissible) {
                let bronzeArray = gridData.map(data => {
                    return {
                        SubjectArea: data.SubjectArea,
                        SourceSystem: data.SourceSystem,
                        SourceDatabaseName: data.SourceDatabaseName,
                        SourceSchemaName: data.SourceSchemaName,
                        SourceTableName: data.SourceTableName,
                        SourceColumnName: data.SourceColumnName,
                        SourceDataType: data.SourceDataType,
                        BronzeSchemaName: data.BronzeSchemaName,
                        BronzeTableName: data.BronzeTableName,
                        BronzeColumnName: data.BronzeColumnName,
                        BronzeDatatype: data.BronzeDatatype,
                        Constraints: data.Constraints
                    };
                });
                // Declare variables for file type and extension
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.json_to_sheet(bronzeArray);
                const wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws, 'SourceToExtractionER');

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, "SourceToExtractionER_Data" + fileExtension);

            } else {

                // Get the image element
                var imgElement = document.getElementById('daimage').getElementsByTagName('img')[0];

                // Get the src attribute value
                var srcValue = imgElement.getAttribute('src');
                console.log(srcValue);
                if (srcValue.startsWith('http:')) {
                    srcValue = srcValue.replace('http:', 'https:');
                }

                // Fetch the image
                fetch(srcValue)
                    .then(resp => resp.blob())
                    .then(blob => {
                        // Use FileSaver to save the blob
                        saveAs(blob, 'SourceToExtractionER_Diagram.svg');
                    })
                    .catch(() => alert('Oh no, there was an error!'));
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //SeqNo_113 - SeqNo_118: Call sessionExpired() method for handling session timeouts and expire scenarios
    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            nav("/");
        }, 3000);
    };

    //SeqNo_136 - SeqNo_138: Render and update component view to show either UML diagram or grid based on state
    return (
        <div className="container-fluid">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value='1' />
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="col-md-12 px-4">
                        {/* Breadcrumbs starts here */}
                        <div className="mt-3 px-2">
                            <ul className="cust-breadcrumb mb-0 ">
                                <li className="font-16 font-medium">
                                    <a >Manage Projects</a>
                                </li>
                                <li className="font-16 font-medium">
                                    <a >{state.projectName}</a>
                                </li>
                                <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                            </ul>
                        </div>
                        {/* Breadcrumbs ends here */}
                        <div className="d-flex align-items-center p-4">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor: "pointer", whiteSpace: 'nowrap' }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                            </a>
                            {gridVissible ?
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">Source To Extraction Data Document</h2>
                                :
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                            }
                            {gridVissible &&
                                <>
                                    <div class="input-group position-relative search-wid me-md-3 ms-auto">
                                        <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4 ms-3"
                                            placeholder="Search" value={searchTerm} onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                        <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                    </div>
                                    <div className="btn-group me-3">
                                        <button
                                            className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                            type="button"
                                            aria-expanded={filterOpen}
                                            onClick={() => {
                                                filterOpen ? setFilterOpen(false) : setFilterOpen(true); console.log(filterOpen, "efddfdfd");
                                            }}
                                        >
                                            <img
                                                src="images/filter-icon.svg"
                                                alt="filter-icon"
                                                className="filt-icon"
                                            />
                                        </button>
                                        <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3 ${filterOpen ? "show" : ""}`}>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        onClick={() => setFilterOpen(false)}
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="SubjectArea"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Subject Area
                                                            </label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="SubjectArea"
                                                                aria-label="Default select example"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                                value={filterCriteria.SubjectArea}
                                                            >
                                                                <option value="">Select</option>
                                                                {filterSubjectArea.map(value => (
                                                                    <option key={value} value={value}>{value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="SubjectArea"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Source System
                                                            </label>
                                                            <select
                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="TableName"
                                                                aria-label="Default select example"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSystem: e.target.value })}
                                                                value={filterCriteria.SourceSystem}
                                                            >
                                                                <option value="">Select</option>
                                                                {filterSourceSystem.map(value => (
                                                                    <option key={value} value={value}>{value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                        onClick={() => handleClearFilter()}>
                                                        Clear
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cust-primary-btn font-14 font-medium px-4"
                                                        onClick={() => handleApplyFilter()}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <button
                                type="button"
                                className={`btn cust-primary-btn font-16 font-medium ${gridVissible ? "" : "ms-auto"}`}
                                onClick={() => gridVissible ? setGridVissible(false) : setGridVissible(true)}
                            >
                                {gridVissible ? "View Flow" : " View Grid"}
                            </button>

                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium ms-3"
                                onClick={() => handleDownload()}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                        <div className="col-md-12 px-4">
                            {/* Accordion starts here */}
                            <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                                <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                    <button
                                        className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#logger-notebook"
                                    >
                                        <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                        <span
                                            data-bs-toggle="collapse"
                                            data-bs-target="#logger-notebook"
                                            className="cursor-pointer text-black font-16 font-medium"
                                        >
                                            View Instructions
                                        </span>
                                    </button>
                                </p>
                                <div className="row">
                                    <div className="collapse show" id="logger-notebook">
                                        <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                            <ul className="font-16 text-grey-v4 font-medium mb-0">
                                                <li className="mb-2">
                                                    Please validate whether the data’s are correct
                                                </li>
                                                <li className="mb-2">
                                                    If things are fine just click{" "}
                                                    <span className="text-black-v2 font-bold">‘back’ </span>then on{" "}
                                                    <span className="text-black-v2 font-bold">‘Looks good’</span>{" "}
                                                    and proceed to next steps{" "}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Accordion ends here */}
                            {/* Error logger textarea starts here */}
                            {gridVissible ?
                                <>
                                    <div className="row justify-content-center mt-3">
                                        <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                            <div className="table-responsive cust-table rounded-3">
                                                <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                                    <thead className="sticky-top-pos">
                                                        <tr>
                                                            <th className="text-nowrap">
                                                                Subject Area
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SubjectArea')}>
                                                                    <img
                                                                        src={sortOrder.column === "SubjectArea" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source System
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceSystem')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceSystem" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source DatabaseName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceDatabaseName')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceDatabaseName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source SchemaName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceSchemaName')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceSchemaName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source TableName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceTableName')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceTableName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source ColumnName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceColumnName')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceColumnName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Source DataType
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('SourceDataType')}>
                                                                    <img
                                                                        src={sortOrder.column === "SourceDataType" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Bronze SchemaName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('BronzeSchemaName')}>
                                                                    <img
                                                                        src={sortOrder.column === "BronzeSchemaName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Bronze TableName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('BronzeTableName')}>
                                                                    <img
                                                                        src={sortOrder.column === "BronzeTableName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Bronze ColumnName
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('BronzeColumnName')}>
                                                                    <img
                                                                        src={sortOrder.column === "BronzeColumnName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Constraints
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('Constraints')}>
                                                                    <img
                                                                        src={sortOrder.column === "Constraints" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-nowrap">
                                                                Bronze DataType
                                                                <span className="ms-2 cursor-pointer" onClick={() => handleSort('BronzeDatatype')}>
                                                                    <img
                                                                        src={sortOrder.column === "BronzeDatatype" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                                        alt="sort-arrow"
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-center sticky-action-column fixed-column-header">Actions</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {bindGrid()}
                                                    </tbody>
                                                </table>
                                                {/*Metadata Table ends here*/}
                                            </div>
                                            <p className="font-regular font-14 text-grey mt-3">
                                                # of Records: <span className="text-black font-medium">
                                                    {filteredRecord.length} out of {totalCount}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {ERModelUML !== "" ?
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                                <div className="">
                                                    <div className="">
                                                        <textarea
                                                            className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                                            id="exampleFormControlInput1"
                                                            // defaultValue={"code"}
                                                            value={ERModelUML}
                                                            style={{ height: '700px' }}
                                                            readOnly={true}
                                                        // onChange={(e) => { setERModelUML(e.target.value); setIsEdited(true) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                                <div className="">
                                                    <div className="position-relative">
                                                        <div className="position-absolute custom-btn-position bg-white rounded-3 test-name" style={{ zIndex: 1 }}>
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-in custom-bottom-brd new-style-added"
                                                                onClick={handleZoomIn}
                                                            >
                                                                <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-out new-style-added"
                                                                onClick={handleZoomOut}
                                                            >
                                                                <img
                                                                    className=""
                                                                    src="images/zoomout-icon.svg"
                                                                    alt="zoom-out"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div style={{ transformOrigin: 'top left', overflow: 'auto', height: '710px', }}>
                                                        <div className='div2' style={{ transform: `scale(${zoom})` }}>
                                                                <div  id='daimage'  dangerouslySetInnerHTML={{ __html: svgResponse }}>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <b>No Diagram Found</b>
                                    }
                                </>
                            }

                            {isReasonOpen && (
                                <>
                                    <div className="modal-backdrop fade show"></div>
                                    <div
                                        className="modal fade show"
                                        style={{ display: "block" }}
                                    >
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5
                                                        className="modal-title d-flex align-items-center font-medium font-20"
                                                        id="ConfrmLabel"
                                                    >
                                                        Edit Reason
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        onClick={() => setIsReasonOpen(false)}
                                                    />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label
                                                                htmlFor="User-Name"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Specify reason for manual edit
                                                                <span className="text-red"> *</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control font-14 font-regular "
                                                                id="User-Name"
                                                                placeholder="Enter reason"
                                                                rows={5}
                                                                defaultValue={null}
                                                                value={reason}
                                                                onChange={(e) => { setReason(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-2"
                                                        onClick={() => {
                                                            setReason(""); setIsReasonOpen(false); console.log(newData, 'handleEdithandleEdithandleEdithandleEdithandleEdit');
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type="button"
                                                        className="btn cust-primary-btn font-14 font-medium"
                                                        disabled={reason != "" ? false : true}
                                                        onClick={() => {
                                                            setIsReasonOpen(false);
                                                            handleEdit()
                                                        }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SourceToExtractionER;