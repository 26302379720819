import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateSasTokenAPI, getCodePageDataApi, postCodePageDataApi, fetchDropDownValues, insertError } from '../../Service/Api.js';
import Editor from '@monaco-editor/react';
import { HeaderCom } from '../Header.jsx';
import { fetchJsonFromBlob, fetchJsonFromURL, putJsonToBlob, putJsonToBlob1, putPythonToBlob, putPythonToBlob1, updateJsonInBlob } from '../../Component/new/BlobStorageService.js';
import CatalogComponent from '../new/Integrations/CatalogComponent.jsx';

export default function DwhCodeViewPage(props) {
    const nav = useNavigate();
    const location = useLocation();
    const { notebookName, type } = props; // Destructuring the notebookName and type from props
    const { state } = useLocation();
    const [loggerCode, setLoggerCode] = useState('');
    const [codeEdit, setCodeEdit] = useState(false);
    const [url, setUrl] = useState(false);
    const [error, setError] = useState(null);
    


    useEffect(() => {
        getCodePageData()
    }, []);


    const saveCodeDetails = async () => {
        debugger
        try {
            console.log('Logger Code:', loggerCode); // Debugging log
    
            var data = loggerCode;
            const nametoSave = props.data.notebookName;
    console.log(url,'this is theh url')
            try{
                    await putPythonToBlob1(
                        data,
                       url
                    );
                }
                catch {
               
            }
    
            console.log('Save operation completed.');
    
            props.setShowCode();
            props.isEdit(codeEdit);
        } catch (error) {
            console.error('Error saving code details:', error);
            setError(error.message);
        }
    };
    

    async function streamToBuffer(readableStream) {
        return new Promise((resolve, reject) => {
            const chunks = [];
            readableStream.on("data", (data) => {
                chunks.push(data instanceof Buffer ? data : Buffer.from(data));
            });
            readableStream.on("end", () => {
                resolve(Buffer.concat(chunks));
            });
            readableStream.on("error", reject);
        });
    }
    
    const fetchJsonFromURL = async (URLToFetch) => {
        try {
            // Generate SAS token
            const sasToken = await generateSasTokenAPI();
            const url = `${URLToFetch}?${sasToken}`;
    
            // Fetch the URL
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Read and parse JSON from response directly
            const jsonData = await response.text();
            console.log(jsonData, 'Fetched JSON Data');
    
            // Return the parsed JSON data
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };
    const updateJsonInBlob = async (blobURL, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/GenETL KT New/New Clark/json/Sample_NotebookChanges.json?${sasToken}`;
            const url = `${blobURL}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };

    function formatCode(response) {
        try {
            const parsedData = response;

            if (Array.isArray(parsedData)) {
                // Handle array of objects; join DDL scripts into one string
                let combinedCode = parsedData.map(obj => {
                    // Check keys dynamically and combine code snippets
                    return Object.values(obj).filter(value => typeof value === 'string').join(' ');
                }).join('\n\n'); // Combine all scripts with double newlines between them

                combinedCode = combinedCode.replace(/\\n/g, `\n`)
                    .replace(/\\"/g, `"`)
                    .replace(/\\'/g, `'`);
                return combinedCode;

            } else if (typeof parsedData === 'object') {
                // Handle a single object
                const code = Object.values(parsedData).filter(value => typeof value === 'string').join(' ');

                const formattedCode = code.replace(/\\n/g, `\n`)
                    .replace(/\\"/g, `"`)
                    .replace(/\\'/g, `'`);
                return formattedCode;

            } else if (typeof parsedData === 'string') {
                // Handle string
                return parsedData.replace(/\\n/g, `\n`)
                    .replace(/\\"/g, `"`)
                    .replace(/\\'/g, `'`);
            }

            throw new Error('Unsupported data format');

        } catch (error) {
            console.error('Error parsing or formatting code:', error);
            throw error;
        }
    }


    async function getCodePageData() {
        console.log("i am inside get code page")
        var url=''
        if(props.gridData=="LoggerCode"){
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/loggerCode.py`
        }
        if(props.gridData=="UtilitiesCode"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/utilityCode.py`
        }
        if(props.gridData=="MetaDataCode"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/metadataCode.sql`
        }
        
        if(props.gridData=="DDLScriptsCode"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/DDLScripts.sql`
        }
        if(props.gridType=="Bronze" || props.gridType=="Silver" || props.gridType=="Gold"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/${props.gridData}.py`
        }
        if(props.gridData=="UnitTestCase"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/unitTestCaseCode.py`
        }
        if(props.gridData=="dataQuality"){ 
            url=`https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/dataQualityCode.py`
        }
            document.getElementById('pageLoader').style.display = "block";
            setUrl(url);
            console.log(url,props.gridData,'this is the url to fetch')
            var data = await fetchJsonFromURL(url);
            console.log(data , 'this is the fetchedDatttttttaaaaaaaaa')
            try{
                var parseddata = JSON.parse(data)
                data=parseddata
            }
            catch{
                console.log('its not json')
            }
            setLoggerCode(data); 
            document.getElementById('pageLoader').style.display = "none";
    };

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            nav('/');
        }, 3000);
    };



    function download(filename, textInput) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textInput));
        element.setAttribute('download', filename);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };


    return (
        <>
            <div className="container-fluid px-md-4">
                <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                    <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                        <div className="d-flex">
                            <div className="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                                </span>
                                <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div className="spinner-border Loader text-dark align-center " role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                    Oops! Something went wrong
                </button>
                <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body px-4 pb-0">
                                <div className="row text-center">
                                    <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                    <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                    <p className="font-14 font-regular text-black">Please try again later</p>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                                <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <HeaderCom value='1' />
                    <div className="col-md-12 pt-4 mt-5">
                        <div className="mt-3 px-2">
                            <ul className="cust-breadcrumb mb-0">
                                <li className="font-16 font-medium">
                                    <a>Manage Projects</a>
                                </li>
                                <li className="font-16 font-medium">
                                    <a>{state.projectName}</a>
                                </li>
                                <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-4">
                            <div className="d-flex align-items-center">
                                <a className="d-flex" onClick={() => { saveCodeDetails() }} style={{ 'cursor': 'pointer' }}>
                                    <img src="images/back-arrow.svg" alt="back-arrow" />
                                </a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                    {props.data.hyperlinkText}
                                </h2>
                                <button
                                    class="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
                                    data-bs-target="#exampleModalToggle"
                                    data-bs-toggle="modal"
                                >
                                    <img src="images/info-icon-ETL.svg" className="" alt="info" />
                                </button>
                                <div
                                    className="modal fade"
                                    id="exampleModalToggle"
                                    aria-hidden="false"
                                    aria-labelledby="exampleModalToggleLabel"
                                    tabIndex={-1}
                                >
                                    <div className="modal-dialog view-instructions-popup modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="view-instructions-popup-left-section">
                                                            <img
                                                                src="images/view-instructions-popup-banner-img.png"
                                                                alt="view-instructions-popup-banner-img"
                                                                className="popup-banner-img"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="view-instructions-popup-right-section">
                                                            <div className="d-flex justify-content-between">
                                                                <h1 className="font-bold font-20 m-0">View Instructions</h1>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                />
                                                            </div>
                                                            <ul className="mt-4 view-instructions-list-items">
                                                                <li>
                                                                    Please validate whether the data's are correct
                                                                </li>
                                                                <li>
                                                                    If things are fine just click 'back' then on 'Looks good' and proceed to next steps
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-16 font-medium"
                                    onClick={() => { download(`${notebookName || props.data?.hyperlinkText}.py`, loggerCode) }}
                                >
                                    <span className="me-2">
                                        <img src="images/download-icon.svg" alt="add-icon" />
                                    </span>
                                    Download
                                </button>
                            </div>
                        </div>
                        <div className="col-md-12 px-4">
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-12 col-lg-9 col-sm-12 pt-4 mb-5">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h3 className="text-black font-bold font-20 mt-3 mb-4">
                                            {props.data.hyperlinkText}
                                        </h3>
                                    </div>
                                    <div className="">
                                        <div className="form-control custom-textarea logger-textarea-scroll" id="exampleFormControlInput1">
                                        <Editor height="50.5vh" defaultLanguage="python" value={loggerCode} options={{ minimap: { enabled: false } }} onChange={(e) => { setLoggerCode(e); setCodeEdit(true); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};