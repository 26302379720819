import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCodePageDataApi, generateLSApi, postCodePageDataApi } from '../Service/Api.js'; // Importing the functions from Api.js
import Editor from '@monaco-editor/react';
import axios from 'axios'
import { HeaderCom } from './Header.jsx';

export default function OrchestrationPipeline(props) {
    const nav = useNavigate();
    const {state} = useLocation()

    const [orchJSON, setOrchJSON] = useState('[]');
    const [codeEdit, setCodeEdit] = useState(false);

    useEffect(() => {
        getOrchestraionJSONPageData()
    }, [])
    //console.log(props,"props")
    async function getOrchestraionJSONPageData() {
        document.getElementById('pageLoader').style.display = "block"
        const body = {
            projectId: state.projectId,
            type: "Orchestration",
            code: orchJSON,
            userId: state.userId,
            orgId: state.orgId,
            pipelineId:props.data.pipelineId,
            pipelineType:props.data.pipelineType
        }
        const res = await getCodePageDataApi(body, state.jwt);
        //console.log(res)
        document.getElementById('pageLoader').style.display = "none"
        if (res.status === 403) {
            sessionExpired();
        } else if (res.status !== 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setOrchJSON(res.data[0].pipelineJSON);
        }
    }


    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            nav('/');
        }, 3000);
    };


    const saveCodeDetails = async () => {
        const body = {
            code: orchJSON,
            projectId: state.projectId,
            type: 'Orchestration',
            pipelineId:props.data.pipelineId,
            pipelineType: props.data.pipelineType,
            userId:state.userId
        };
        const resData = postCodePageDataApi(body, state.jwt);
        props.setShowCode()
        props.isEdit(codeEdit);
    };

    /*
               *PC_ME_15 - 16 
               When the user clicks the download button the download() method is triggered along with the respective filename and
              code.Then decraler a varaible element and create an element 'a'(anchor) and store it in emelent variable.Then set Atrribute href 
              to the element as 'data:text/plain;charset=utf-8,' + encodeURIComponent(textInput),download as filename user setAttribute() method.
              Then append the child to the html body using appendChild() and trigger it by using the click() method and then remove the child 
              from the html body using removeChild() method
              */
    function download(filename, textInput) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textInput));
        element.setAttribute('download', filename);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    // Other code for rendering and handling user interactions

    return (
        <div className="container-fluid px-md-4">
            <div class="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" class="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{display:"none"}}>
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div class="spinner-border Loader text-dark align-center " role="status">
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
          <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Pipeline Notebook</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex align-items-center">
                        <a className="d-flex" onClick={() => { saveCodeDetails() }} style={{ 'cursor': 'pointer' }}>
                            <img src="images/back-arrow.svg" alt="back-arrow" />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">
                            Pipeline Notebook
                        </h2>
                    </div>
                    <button
                        type="button"
                        className="btn cust-secondary-btn font-16 font-medium"
                        // data-bs-dismiss="modal"
                        onClick={() => { download(`${props.data.hyperlinkText}.py`, orchJSON) }}
                    >
                        <span className="me-2">
                            <img src="images/download-icon.svg" alt="add-icon" />
                        </span>
                        Download
                    </button>
                </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#logger-notebook"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3 gen-accord"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#logger-notebook"
                                        className="cursor-pointer text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="logger-notebook">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’ </span>then
                                                on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion ends here */}
                        {/* Error logger textarea starts here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-9 col-sm-12 pt-4 mb-5">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h3 className="text-black font-bold font-20 mt-3 mb-4">
                                    </h3>
                                   
                                </div>
                                <div className="">
                                    <div className="form-control custom-textarea logger-textarea-scroll" id="exampleFormControlInput1">
                                        <Editor height="57.5vh" defaultLanguage="javascript" value={orchJSON} options={{ minimap: { enabled: false } }} onChange={(e) => { setOrchJSON(e);props.onChange(); setCodeEdit(true) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


