

import { useLocation, useNavigate } from 'react-router-dom';
//Import the nescessary modules and the packages
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import DynamicFileUpload from './dynamicFileUpload';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import PlantUML from "react-plantuml";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { encode, decode } from "base-64"
import MetadataNotebook from '../MetadataNotebook';
import { HeaderCom } from '../Header';
import TwbxPopup from './tableauPopup';
import { fetchJsonFromBlob ,updateChatHistoryToBlob ,updateJsonInBlob} from '../new/BlobStorageService';
import SourceCodeViewPage from './sourceProgressCode';


// import Groups from './AcessControl/Group';
import axios from 'axios';
import { file } from 'jszip';

import DmCodeViewPage from './dmtableview';
import RelationShipViewPage from './relationships';
import DeployPBIPCode from './deployPopUP';
import { fileStructureAPI, generateSasTokenAPI ,generateSemanticModel,getCodePageDataApi } from '../../Service/Api';
import DaxGrid from './DaxGrid';


const ChatMaintableau = () => {

var { state } = useLocation();

const navigate = useNavigate()
 const [isPopupOpen, setPopupOpen] = useState(false);
    const [isTableMappingPopupOpen, setTableMappingPopupOpen] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [validationColumns, setValidationColumns] = useState([]);
    const [uploadedData, setUploadedData] = useState(null);
    const [tableuploaddetails, settableuploaddetails] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [showLooksGood, setShowLooksGood] = useState(false)
    const [showCode, setShowCode] = useState(false);
    // const[isTableEdited,setIsTableEdited] = useState(false);
    const [sourcePhase, setSourcePhase] = useState('')
    const [codeDataValues, setCodeDataValues] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const matchesRef = useRef([]);
    const [searchText, setSearchText] = useState('');
    const [dataQualityResultFile, setDataQualityResultFile] = useState('');
    const [unitTestResultFile, setUnitTestResultFile] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [isSourceToTraget, setIsSourceToTarget] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [accessControlShow, setAccessControlShow] = useState(false);
    const [templateURL, setTemplateURL] = useState('');
    const [fileUploaded, setFileUploaded] = useState('');
    var { state } = useLocation();

    //popups
    const [filePopup, setfilePopup] = useState(false);
    const [notebookPopup, setNotebookPopup] = useState(false);
    const [dynamicFileUploadPopup, setDynamicFileUploadPopup] = useState(false);
    const [unitTestcasePopup, setUnitTestcasePopup] = useState(false);
    const [dataQualityPopup, setDataQualityPopup] = useState(false);
    const [currentChatHistory, setCurrentChatHistory] = useState(false); // Initialize with false
    const [error, setError] = useState([]);
    const [confirmedTablePopup, setConfirmedTablePopup] = useState(false);
    const [showSourceProgress, setshowSourceProgress] = useState(false);
    const [showdmdetails, setshowdmdetails] = useState(false);
    const [showrelationships, setshowrelationships] = useState(false);
    const [showDax, setShowDax] = useState(false);
    const [deployPopup, setDeployPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);   
         const [blobUrl, setBlobUrl] = useState("");
         const [blobUrls, setBlobUrls] = useState("");
         const [selectedCatalog, setSelectedCatalog] = useState("");



    
         async function streamToBuffer(readableStream) {
            return new Promise((resolve, reject) => {
              const chunks = [];
              readableStream.on("data", (data) => {
                chunks.push(data instanceof Buffer ? data : Buffer.from(data));
              });
              readableStream.on("end", () => {
                resolve(Buffer.concat(chunks));
              });
              readableStream.on("error", reject);
            });
          }

    const zipAndDownloadFolder = async () => {
       
            setIsLoading(true);
        
            try {
                document.getElementById("pageLoader").style.display = "block";
                const accountName = 'avaeusgenetlsametadev';
                const containerName = 'uc-migration';
               
                const sasToken = await generateSasTokenAPI();
                let folderPath
                var folderName = chatHistory[0].fileName.split(".")[0].replaceAll(" ","_")
                if (folderName == 'Student_Demographics_Overview_Report'){
                     folderPath='TemplateData/Student Demographics Overview Report'}
                    else{            
                        folderPath = `organizations/${state.orgId}/${state.projectId}/files/${folderName}`;
                 }
                // const folderPath = `organizations/${state.orgId}/${state.projectId}/files/${folderName}`;
                let uploadFolderPath = `organizations/${state.orgId}/${state.projectId}/files/Deploy`
          
                const blobServiceClient = new BlobServiceClient(
                  `https://${accountName}.blob.core.windows.net?${sasToken}`
                );
                const containerClient = blobServiceClient.getContainerClient(containerName);
          
                const zip = new JSZip();
                console.log("Starting to list blobs...");
          
                let blobsCount = 0;
                let blobsDownloaded = 0;
                let blobContent;
                // List all blobs in the folder
                for await (const blob of containerClient.listBlobsFlat({ prefix: folderPath })) {
                    blobsCount++;
                    console.log("Found blob:", blob.name);
                    const blobClient = containerClient.getBlobClient(blob.name);
                    
                    try {
                      const downloadResponse = await blobClient.download();
                      
                  
                      if (downloadResponse.readableStreamBody) {
                        // If it's a readable stream
                        blobContent = await streamToBuffer(downloadResponse.readableStreamBody);
                      } else {
                        // If it's already a Uint8Array or ArrayBuffer
                        blobContent = await downloadResponse.blobBody;
                      }
                      
                      // Add file to zip without compression
                      const relativePath = blob.name.slice(folderPath.length + 1);
                      zip.file(relativePath, blobContent, { binary: true });
                      
                      blobsDownloaded++;
                    } catch (error) {
                      console.error(`Error processing blob: ${blob.name}`, error);
                    }
                  
          
                  // Add file to zip without compression
                  const relativePath = blob.name.slice(folderPath.length + 1); // Ensure proper slicing
                  zip.file(relativePath, blobContent, { binary: true });
                }
          
                if (blobsCount === 0) {
                  console.error("No blobs found in the specified path:", folderPath);
                  return; // Exit the function as there is nothing to download
                }
          
                console.log(`Generating zip file with ${blobsDownloaded} files...`);
                // Generate zip file without compression
                const zipContent = await zip.generateAsync({
                  type: 'blob',
                  compression: "STORE",
                  compressionOptions: {
                    level: 0
                  }
                });
          
                // Upload the zip file back to Azure Blob Storage
                const zipFileName = `${folderPath.split('/').pop()}.zip` || 'download.zip';
                await uploadZipToBlob(uploadFolderPath, zipContent, zipFileName);
          
                // Trigger download if there are files present
                if (blobsDownloaded > 0) {
                  saveAs(zipContent, zipFileName);
                } else {
                  console.error("No files downloaded. Zip file not created.");
                }
              } catch (error) {
                console.error('Error downloading folder:', error);
              } finally {
                setIsLoading(false);
                document.getElementById("pageLoader").style.display = "none";
              }
            };
          
            const uploadZipToBlob = async (folderPath, zipContent, zipFileName) => {
              try {
                var accountName = 'avaeusgenetlsametadev';
                var containerName = 'uc-migration';
              
                const sasToken = await generateSasTokenAPI();
                const blobPath = `${folderPath}/${zipFileName}`;
                const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
          
                const response = await fetch(url, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/zip',
                    'x-ms-blob-type': 'BlockBlob'
                  },
                  body: zipContent
                });
          
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
          
                console.log('Zip file uploaded successfully to ', url);
                return url;
          
              } catch (error) {
                console.error('Error uploading zip file to blob:', error);
                throw error;
              }
          
    };


    const closePopup = () => {  
        setfilePopup(false);
    };

    async function saveFunction (fileName)  {  
        chatHistory[0]["fileName"]=fileName
        try {
                    const notebookName = "Source Connection Code";
                    const phase = "Development";
                    const referenceDocuments = ['Tableau File'];
        
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Tableau File Details" },
                        { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                        { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                        { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                    console.log(`JSON data Updated successfully to ${blobJson}`);
        
                  

                    let Postobject = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                    }
                    const object = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                        "type":"sourceConnection",
                        projectName:state.projectName
                    }
                    response = await fileStructureAPI(Postobject, state.jwt);
                    console.log(response,'file structure')
                    // setLoader(() => false);
                    if (response.status === 403) {
                        // sessionExpired()
                        return;
                    } else if (response.status !== 200) {
                        // document.getElementById("protoastMessageSomething").style.display = "block";
                        // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } else if(response.status == "200"){
                        console.log("sucesssssuhhh")
                    }

                    var response = await generateSemanticModel(object, state.jwt);
                    // setLoader(() => false);
                    if (response?.status === 403) {
                        // sessionExpired()
                        return;
                    } 
                    else if(response?.status == "200" ||response?.status == 200){
                        const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                        if (fetchedData) {
                            setChatHistory(fetchedData)
                        }
                    }
                    else if (response?.status !== 200) {
                        // document.getElementById("protoastMessageSomething").style.display = "block";
                        // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } 

        
                   

        
                } catch (error) {
                 console.log(`Error generatingmmmmmmmmmmmmmmmmmm`, error);
                }
    };


    const gridUpdated = (notebookName) => {
        // Step 1: Store the current chat history in a temporary variable
        const tempChatHistory = [...chatHistory];
    
        // Step 2: Filter the chat history for entries with the specified notebookName
        const filteredChat = tempChatHistory.filter((item) => item.notebookName === notebookName);
    
        // Step 3: Update the chat history state with the filtered entries
        setChatHistory(filteredChat);
    };


    function goDown() {
        const divElement = document.getElementById("Imdown");
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth" });
        }
    }

      const [status, setStatus] = useState('');






useEffect(() => {
        fetchChatHistory()
    }, [])
    const fetchChatHistory = async () => {
            let newPro = sessionStorage.getItem("newPro");
            if (newPro == "true") {
                sessionStorage.removeItem("newPro");
                document.getElementById("protoastMessage").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessage").style.display = "none";
                }, 3000);
            } else {
                state.isEdit = true;
            }
            document.getElementById("pageLoader").style.display = "block";
            try {
                let fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
    
                if (fetchedData) {
    
                    if (fetchedData?.filter(a => a?.flow === 'error')?.length !== 0) {
                        fetchedData = fetchedData.filter((a) => a?.flow !== 'error')
                        updateChatHistoryToBlob(state.orgId, state.projectId, fetchedData, "chatHistory.json", "projectfiles");
    
                        // document.getElementById("protoastMessageSomething").style.display = "block";
                        // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    }
                    setChatHistory(fetchedData);
    
                    const hasTableMapping = fetchedData.some(obj => obj.notebookName === "tableMapping");
                    setAccessControlShow(hasTableMapping);
    
                    const unitTestFile = fetchedData.find(obj => obj.hasOwnProperty('UnitTestResultFileName'));
    
                    if (unitTestFile) {
                        setUnitTestResultFile(unitTestFile.UnitTestResultFileName);
                    }
                    else {
                        setUnitTestResultFile('');
                        setFileUploaded('')
                    }
                    const dataQualityFile = fetchedData.find(obj => obj.hasOwnProperty('DataQualityResultFileName'));
                    if (dataQualityFile) {
                        setDataQualityResultFile(dataQualityFile.DataQualityResultFileName);
                    }
                    else {
                        setDataQualityResultFile('');
                        setFileUploaded('')
                    }
                    document.getElementById("pageLoader").style.display = "none";
    
                    setTimeout(() => { goDown() }, 1000);
                }
                // return fetchedData; // Return the fetched data if needed
            } catch (error) {
                console.error("Error fetching chat history:", error);
                // Handle the error appropriately, maybe return null or an empty array
                return null; // or return [];
            }
        };

    const savedeployment = async  (data,status,userInputs)=>{
    
        if(status==200){

            chatHistory[0].deployDetails=userInputs
            const newChat = [
                ...chatHistory,
                { flow: "ai", type: "text", text: `Congratulations! You’ve successfully deployed`}
            ];
            
    
            setChatHistory(newChat);
            console.log(newChat, 'newChat');
    
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
        }
        else{
            const newChat = [
                ...chatHistory,
                { flow: 'ai', type: 'text', text: 'Oops! Seems that you haven’t provided correct credentials' },
                { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
            ];
            setChatHistory(newChat);
            console.log(newChat, 'newChat');
    
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
        }
        }
        
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && matchCount > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchCount;
            setCurrentMatchIndex(nextIndex);
            scrollToMatch(matchesRef.current[nextIndex]);
        }
    };
    const scrollToMatch = (match) => {
        if (match && typeof match.hyperlinkText === 'string') {
            const divElement = document.getElementById("search-" + match.hyperlinkText);
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const letStart = async () => {
        try {
            chatHistory[chatHistory.length - 1].show = false;
            const referenceDocuments = ['Inventory Uploaded', 'TableType Uploaded'];
            var notebookName = "Table Summary"
            const newChat = [
                ...chatHistory,
                { flow: "user", type: "text", text: "Let's Start" },
                {
                    "flow": "ai",
                    "type": "inputPopup",
                    "show": true,
                    
                }
                
            ];
    
            setChatHistory(newChat);
            console.log(newChat, 'newChat');
    
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(blobJson, 'blobJson');
        } catch (error) {
            console.error('Error updating chat history:', error);
        }
    };



    const handleSearch = useCallback((newSearchText) => {
            setSearchText(newSearchText);
            if (newSearchText) {
                const matches = chatHistory.reduce((acc, item) => {
                    if (item?.hyperlinkText && typeof item.hyperlinkText === 'string' &&
                        item.hyperlinkText.toLowerCase().includes(newSearchText.toLowerCase())) {
                        // Check if this hyperlink text is already in the accumulator
                        if (!acc.some(match => match.hyperlinkText === item.hyperlinkText)) {
                            acc.push(item);
                        }
                    }
                    return acc;
                }, []);
    
                matchesRef.current = matches;
                setMatchCount(matches.length);
                setCurrentMatchIndex(0);
                if (matches.length > 0) {
                    scrollToMatch(matches[0]);
                }
            } else {
                matchesRef.current = [];
                setMatchCount(0);
                setCurrentMatchIndex(0);
            }
        }, [chatHistory]);

    function closeSourceCodePage(){
        setShowCode(false)
        setshowSourceProgress(false)
    }
    function closeDMDetails(){
        setShowCode(false)
        setshowdmdetails(false)
    }
    function closerelationships(){
        setShowCode(false)
        setshowrelationships(false)
    }
    function closeDax(){
        setShowCode(false)
        setShowDax(false)
    }


    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            navigate("/");
        }, 3000);
    };


    async function downloadResponse(data) {
        const accountName = 'avaeusgenetlsametadev';
const containerName = 'uc-migration';

// Function to trigger download
const triggerDownload = (content, fileName, fileType) => {
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
};

// Function to fetch JSON from Blob source
const fetchJsonFromBlobsource = async (url) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const urlWithToken = `${url}?${sasToken}`;
        const response = await fetch(urlWithToken);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};

// Function to fetch code for a selected table
const fetchCodeForSelectedTable = async (blobUrl, tableName) => {
    if (!blobUrl) return;

    try {
        const jsonData = await fetchJsonFromBlobsource(blobUrl);
        const selectedData = jsonData.find(item => item.table_name === tableName);

        if (selectedData) {
            const content = selectedData.source_connection_code;
            triggerDownload(content, `${tableName}.txt`, 'text/x-python');
        } else {
            console.error("No valid data found for the selected table name.");
        }
    } catch (error) {
        console.error("Error fetching code for selected table:", error);
    }
};

// Handle the "sourceConnectionCode" case
if (data.notebookName === "sourceConnectionCode") {
    // Function to fetch code page data and trigger downloads for each table name
    const fetchCodePageData = async () => {
        const body = {
            projectId: state.projectId,
            type: "tableauCode",
            userId: state.userId,
            orgId: state.orgId,
            jwt: state.jwt,
        };

        try {
            document.getElementById('pageLoader').style.display = "block";

            const res = await getCodePageDataApi(body, state.jwt);
            if (res.status === 403) {
                sessionExpired();
                return;
            } else if (res.status !== 200) {
                document.getElementById('openErrorModal').click();
                return;
            }

            const blobURL = res.data[0]?.powerBISourceDataUrl;
            setBlobUrl(blobURL);

            if (blobURL) {
                try {
                    const jsonData = await fetchJsonFromBlobsource(blobURL);
                    if (jsonData) {
                        const tableNames = jsonData.map(item => item.table_name);

                        if (tableNames.length > 0) {
                            for (const tableName of tableNames) {
                                await fetchCodeForSelectedTable(blobURL, tableName);
                            }
                        }
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                }
            }

            document.getElementById('pageLoader').style.display = "none";
        } catch (error) {
            console.error("Error fetching code page data:", error);
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    // Run the fetch code page data function
    await fetchCodePageData();
}else if (data.notebookName === "DataModeldetails") {

    const fetchJsonFromBlobDM= async (url) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const urlWithToken = `${url}?${sasToken}`;
            const response = await fetch(urlWithToken);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            var jsonData =  response.text()
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };


            const fetchCodeForSelectedTable = async (blobUrl, tableName) => {
                if (!blobUrl) return;
        
                try {
                    const data = await fetchJsonFromBlobDM(blobUrl);
                    const parsedData = data;
                    triggerDownload(parsedData, `${tableName}.txt`, 'text/x-python');
                } catch (error) {
                    console.error("Error fetching code for selected table:", error);
                }
            };
        
            const fetchCodePageData = async () => { 
                const body = {
                    projectId: state.projectId,
                    type: "tableauCode",
                    userId: state.userId,
                    orgId: state.orgId,
                    jwt: state.jwt,
                };
        
                try {
                    document.getElementById('pageLoader').style.display = "block";
        
                    const res = await getCodePageDataApi(body, state.jwt);
                    if (res.status === 403) {
                        sessionExpired();
                        return;
                    } else if (res.status !== 200) {
                        document.getElementById('openErrorModal').click();
                        return;
                    }
        
                    const blobUrlsString = res.data[0]?.powerBITmdlUrl;
                    if (blobUrlsString) {
                        const parsedBlobUrls = JSON.parse(blobUrlsString);
                        console.log(parsedBlobUrls,'this is the urlsss') // Parse the JSON string
                        setBlobUrls(parsedBlobUrls); // Set parsed URLs to state
        
                        const tableNames = parsedBlobUrls.map(item => item.tableName);
        
                        if (tableNames.length > 0) {
                            for (let i = 0; i < tableNames.length; i++) {
                                const blobUrl = parsedBlobUrls.find(item => item.tableName === tableNames[i]).bloburl;
                                await fetchCodeForSelectedTable(blobUrl, tableNames[i]);
                            }
                        }
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
        
                    document.getElementById('pageLoader').style.display = "none";
                } catch (error) {
                    console.error("Error fetching code page data:", error);
        
                    document.getElementById('pageLoader').style.display = "none";
                }
            };
        
            await fetchCodePageData();
        }else if (data.notebookName === "relationship") {

            const fetchJsonFromBlobsourcerelationship = async (blobURL) => {
               try {
                           const sasToken = await generateSasTokenAPI();
                           const url = `${blobURL}?${sasToken}`;
                           const response = await fetch(url);
                           if (!response.ok) {
                               throw new Error(`HTTP error! status: ${response.status}`);
                           }
                           var textData = await response.text();
                           return textData;
                       } catch (error) {
                           console.error('Error fetching JSON from blob:', error);
                           throw error;
                       }
                
            };


            const fetchCodePageData = async () => { 
                const body = {
                    projectId: state.projectId,
                    type: "tableauCode",
                    userId: state.userId,
                    orgId: state.orgId,
                    jwt: state.jwt,
                };
            
                    try {
                        setError(null);
                        const res = await getCodePageDataApi(body, state.jwt);
                        if (res.status === 403) {
                            sessionExpired();
                            return;
                        } else if (res.status !== 200) {
                            throw new Error("Failed to fetch blob URL from Server");
                        }
            
                        const blobUrl = res.data[0]?.powerBIRelationshipUrl;
                        if (blobUrl) {
                            setBlobUrl(blobUrl);
                        } else {
                            throw new Error("No blob URL found");
                        }
                    } catch (error) {
                        console.error("Error fetching code page data:", error);
                        
                    }
                };
            const fetchDataFromBlob = async () => {
                try {
                    const data = await fetchJsonFromBlobsourcerelationship(blobUrl);
                    console.log(data,'this is the data')
                    console.log(data,'this is the data')
                    triggerDownload(data, `relationship.txt`, 'text/x-python');
                } catch (error) {
                    console.error("Error fetching code from blob:", error);
                    
                }
            };
    
            await fetchCodePageData();
            await fetchDataFromBlob();
    
        } else if (data.notebookName === "DAX") {
            const fetchJsonFromBlob = async () => {
                try {
                    document.getElementById('pageLoader').style.display = "block";
                    const sasToken = await generateSasTokenAPI();
                    const blobURL = `organizations/${state.orgId}/${state.projectId}/files/Dax.json`;
                    const burl = `${blobURL}?${sasToken}`;
                    const url = `https://${accountName}.blob.core.windows.net/${containerName}/${burl}`;
                    const response = await fetch(url);
    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
    
                    const data = await response.text();
                    const jsonData = JSON.parse(data);
                    document.getElementById('pageLoader').style.display = "none";
    
                    // Convert JSON to Excel format and trigger download
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(jsonData);
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'DAX');
                    XLSX.writeFile(workbook, 'DAX.xlsx');
                } catch (error) {
                    console.error('Error fetching JSON from blob:', error);
                    document.getElementById('pageLoader').style.display = "none";
                    document.getElementById('openErrorModal').click();
                }
            };
    
            await fetchJsonFromBlob();
        }
    }
        function getCodePageDetails(data) {
            console.log(data,'this is the dataaaaaaaaaaaaaaa')
            if (data.notebookName==="sourceConnectionCode"){
                // Proper state set 
                setCurrentChatHistory(data)
                setShowCode(true)
                setshowSourceProgress(true)
                
            }
            else if (data.notebookName==="DataModeldetails"){
                // Proper state set
                setCurrentChatHistory(data) 
                setShowCode(true)
                setshowdmdetails(true)
            }
            
            else if(data.notebookName=="relationship"){
                setCurrentChatHistory(data) 
                setShowCode(true)
                setshowrelationships(true)
            }
            
            else if(data.notebookName=="DAX"){
                setCurrentChatHistory(data) 
                setShowCode(true)
                setShowDax(true)
            }
        }

        const checkLooksGood = (notebookName) => {
            if(notebookName=="sourceConnectionCode"){
                generatetmdl()
            }
            if(notebookName=="DataModeldetails"){
                generateRelationships()
            }
            if(notebookName=="relationship"){
                generateDax()
            }
            if(notebookName=="DAX"){
                generateReport()
            }
            if(notebookName=="report"){
                proceedToDeploy()
            }
        }

        async function proceedToDeploy(){

            try {
                chatHistory[chatHistory.length - 1].show = false;
                chatHistory[chatHistory.length - 1].isAction = false;
                const data = [...chatHistory]
                const newChat = [
                    ...data,
                    { flow: "user", type: "text", text: "looks Good" },
                    { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
                ];
    
                setChatHistory(newChat);
    
                const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                
            } catch (error) {
                // console.error(`Error generating ${noteName}:`, error);
            }

        }

        async function generateReport ()  {  
            try {
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "looks Good" },
                            { flow: "ai", type: "text", text: `Congratulations! You’ve successfully completed the migration`},
                            // { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' },
                            {
                                "flow": "ai",
                                "type": "report",
                                "text": "You can download the PowerBI File here",
                                "hyperlinkText": chatHistory[0].fileName.split(".")[0].replaceAll(" ","_")+'.zip',
                                "show": false,
                                "isAction":true,
                                "id": "report",
                                "notebookName": "report"
                                }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                        
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generateDax ()  {  
            try {
                        const notebookName = "Dax";
                        const phase = "Development";
                        const referenceDocuments = ['Tableau File'];
            
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards converting the Tableau Expression to Power Bi Dax `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                        console.log(`JSON data Updated successfully to ${blobJson}`);

                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            "type":"measure.tmdl",
                            projectName:state.projectName
                        }
            
                        const response = await generateSemanticModel(object, state.jwt);
                        // setLoader(() => false);
                        if (response.status === 403) {
                            // sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            // document.getElementById("protoastMessageSomething").style.display = "block";
                            // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generatetmdl ()  {  
            try {
                        const notebookName = "DataModel details";
                        const phase = "Development";
                        const referenceDocuments = ['Tableau File'];
            
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating DataModel details`, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                        console.log(`JSON data Updated successfully to ${blobJson}`);

                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            "type":"tmdlGeneration",
                            projectName:state.projectName
                        }
            
                        const response = await generateSemanticModel(object, state.jwt);
                        // setLoader(() => false);
                        if (response.status === 403) {
                            // sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            // document.getElementById("protoastMessageSomething").style.display = "block";
                            // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function deployYes(){
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Yes"},
                            { flow: 'ai', type: 'deployinfo',show:true }
                        ];
                        setChatHistory(newChat);
                        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
        }


        async function deployNo(){
            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "NO"},
                { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
            ];
            setChatHistory(newChat);
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
}
    

        async function generateRelationships ()  {  
            try {
                        const notebookName = "Relationship Code";
                        const phase = "Development";
                        const referenceDocuments = ['Tableau File'];
            
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Relationship Code`, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                        console.log(`JSON data Updated successfully to ${blobJson}`);
            
            
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            "type":"relationship",
                            projectName:state.projectName
                        }
            
                        const response = await generateSemanticModel(object, state.jwt);
                        // setLoader(() => false);
                        if (response.status === 403) {
                            // sessionExpired()
                            return;
                        }else if(response.status == "200" || response.status == 200){
                            const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                        } else if (response.status !== 200) {
                            // document.getElementById("protoastMessageSomething").style.display = "block";
                            // setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } 
            
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };


    return (
            <>
                {/* PS_20 - PS_40 bindChatHitory is performed here to bind the user, ai and center flow */}
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                    style={{ position: "absolute", zIndex: "100000" }}
                >
                    <div
                        id="toastMessage"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Session has expired.Please login Again
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        id="protoastMessage"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/check 1.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Project has been created successfully
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div
                        id="protoastMessageSomething"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Something went wrong. Try Again!
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    id="openErrorModal"
                    data-bs-target="#Something-wrong"
                    hidden
                >
                    Oops! Something went wrong
                </button>
                <div
                    className="modal fade"
                    id="Something-wrong"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body px-4 pb-0">
                                <div className="row text-center">
                                    <span>
                                        <img
                                            src="images/went-wrong-icon.svg"
                                            alt="went-wrong-icon"
                                            className="sucess-icon-sty"
                                        />
                                    </span>
                                    <h3 className="font-16 font-bold text-black mt-4">
                                        Oops! Something went wrong
                                    </h3>
                                    <p className="font-14 font-regular text-black">
                                        Please try again later{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maindiv">
                {showCode === false ?
                            (
                                    <>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {/* header style starts here */}
                                                <HeaderCom value="1" />
                                                {/* header style ends here */}
                                                <div
                                                    className="overlay"
                                                    id="pageLoader"
                                                    style={{ display: "none" }}
                                                >
                                                    <div className="position-absolute top-50 start-50  translate-middle">
                                                        <div className="d-flex align-items-center loader-bg">
                                                            <div
                                                                className="spinner-border Loader text-dark align-center"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>
                                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 pt-4 mt-5">
                                                    <div className="mt-3">
                                                        <ul className="cust-breadcrumb mb-0 ">
                                                            <li className="font-16 font-medium">
                                                                <a>Manage Projects</a>
                                                            </li>
                                                            <li className="font-16 font-medium active">
                                                                {state.isEdit
                                                                    ? state.projectName
                                                                    : state.projectName}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row p-4">
                                                        <div className="d-flex  mb-4 justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center mb-xl-0 ">
                                                                <a className="d-flex" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                                                    <img
                                                                        src="images/back-arrow.svg"
                                                                        alt="back-arrow"
                                                                        onClick={() => {
                                                                            navigate("/ProjectInfo", {
                                                                                state: { ...state, isEdit: true },
                                                                            });
                                                                        }}
                                                                    />
                                                                </a>
                                                                <h2 className="text-black font-bold font-22 mb-0 ms-3" style={{ position: "absolute", paddingLeft: "1%" }}>
                                                                    {state.isEdit === false
                                                                        ? state.projectName
                                                                        : state.projectName}
                                                                </h2>
                                                            </div>
                                                            {/* <div class="d-lg-flex d-md-block"> */}
                                                            {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                                                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center" style={{ marginLeft: '48%' }}>
                                                                <span className=" text-nowrap font-bold">Organization : </span>
                                                                <span>
                                                                    <img
                                                                        src={state.orgLogo}
                                                                        alt="hubspot-logo"
                                                                        className="figma-logo"
                                                                    />
                                                                </span>
                                                                <span className="me-2 text-nowrap ">
                                                                    {" "}{state.orgName}{" "}
                                                                </span>
                                                            </div>
                                                            <div className="input-group position-relative search-wid me-md-3" style={{ overflow: 'visible' }}>
                                                                <div style={{ position: 'relative', width: '100%' }}>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                                        placeholder="Search"
                                                                        value={searchText}
                                                                        onChange={(e) => handleSearch(e.target.value)}
                                                                        onKeyPress={handleKeyPress}
                                                                        style={{
                                                                            paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                    {matchCount > 0 && (
                                                                        <span style={{
                                                                            position: 'absolute',
                                                                            left: '8px',
                                                                            top: '50%',
                                                                            transform: 'translateY(-50%)',
                                                                            fontSize: '12px',
                                                                            color: '#fff',
                                                                            backgroundColor: '#7650E0',
                                                                            padding: '2px 6px',
                                                                            borderRadius: '10px',
                                                                            zIndex: 5,
                                                                            pointerEvents: 'none',
                                                                        }}>
                                                                            {`${currentMatchIndex + 1}/${matchCount}`}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <span className="search-icon-sty" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                    <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                                                </span>
                                                            </div>
                                                            {/* </div> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="code-review-tab-width">
                                                            <div className="code-review-page tabs nav ">
                                                                <a
                                                                    onClick={() => {
                                                                        navigate("/ProjectInfo", {
                                                                            state: { ...state, isEdit: true },
                                                                        });
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <div className="tab nav-link font-bold">
                                                                        Project Info
                                                                    </div>
                                                                </a>
    
                                                                <div
                                                                    className="tab nav-link font-bold active"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#ask-gen"
                                                                >
                                                                    Ask GenETL
                                                                </div>

                                                            </div>
                                                            <div className="tab-content" id="pills-tabContent">
                                                                <div
                                                                    id="ask-gen"
                                                                    className="tab-pane fade code-review-tab show active tab-content"
                                                                >
                                                                    <div className="chat-box px-sm-0">
                                                                        <div className="container px-0">
                                                                            <div className="row px-sm-0">
                                                                                <div
                                                                                    className="chat-container"
                                                                                    style={{ height: 430, overflowY: "auto" }}
                                                                                >
                                                                                    <div className="col-md-12">
                                                                                        {chatHistory.length > 0 ? (
                                                                                            <>
                                                                                                {chatHistory.map((data) => {
    
                                                                                                    if (data.flow === "center") {
                                                                                                        return (
                                                                                                            <div className="chat-divider d-flex justify-content-center">
                                                                                                                <span className="font-12 font-medium">{data.text}</span>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data?.flow === "ai") {
                                                                                                        if (data?.type === "welcomeMessage") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Hi <span className="text-black">
                                                                                                                            {state.userName}
                                                                                                                        </span>
                                                                                                                        , How can I help you with?
                                                                                                                        
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data?.show === true && (
                                                                                                                            <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => letStart()}
                                                                                                                            >
                                                                                                                               Let's Start 
                                                                                                                            </button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        if (data?.type === "deploy") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Would you like to deploy now?
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data?.show === true && (
                                                                                                                            <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => deployYes()}
                                                                                                                            >
                                                                                                                               Yes 
                                                                                                                            </button>
                                                                                                                            
                                                                                                                            
                                                                                                                        )}
                                                                                                                        {data?.show === true && (
                                                                                                                        <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => deployNo()}
                                                                                                                            >
                                                                                                                               No 
                                                                                                                            </button>
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        if (data?.type === "inputPopup") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Kindly{" "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => setfilePopup(true)}
                                                                                                                        >
                                                                                                                            Upload
                                                                                                                        </a>{" "}  the tableau file to proceed with the migration
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        if (data?.type === "deployinfo") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly{"  "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => setDeployPopup(true)}
                                                                                                                        >
                                                                                                                            fill out this 
                                                                                                                        </a>{" "}  to know about target system details.
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === "notebookPopup") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Let's move towards Analyzing Notebook. Kindly "
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => setNotebookPopup(true)}
                                                                                                                        >
                                                                                                                            fill out this form
                                                                                                                        </a>" to know about the existing notebooks.
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data.show === true && (
                                                                                                                            <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => setNotebookPopup(true)}
                                                                                                                            >
                                                                                                                                Fill Form
                                                                                                                            </button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        
                                                                                                        else if (data.type === "text") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        {data.text}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === 'loadShow' && data.show === true) {
    
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Your {data.docName} document would be generated
                                                                                                                        based on the below document
                                                                                                                        {data.referenceDocument.length > 1 ? (
                                                                                                                            // Render the div with the loop inside if data.referenceDocument has more than 1 item
                                                                                                                            <div className="my-3">
                                                                                                                                {/* Use map to render a div for each item in data.referenceDocument */}
                                                                                                                                {data.referenceDocument.map((document, index) => (
                                                                                                                                    <div key={index}>
                                                                                                                                        <a className="text-decoration-none text-black font-12">
                                                                                                                                            <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                            {document} {/* Render the value of each document object */}
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                ))}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            // If data.referenceDocument has only 1 item, render a single div
                                                                                                                            data.referenceDocument.length === 1 && (
                                                                                                                                <div className="my-3">
                                                                                                                                    <a className="text-decoration-none text-black font-12">
                                                                                                                                        <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                        {data.referenceDocument[0]} {/* Render the value of the single document */}
                                                                                                                                    </a>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}
    
                                                                                                                        {data.docName} document would be ready soon...
                                                                                                                  
                                                                                                                    </div>
    
                                                                                                                </div>
    
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === "codeShow") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        <b>{data?.text}</b>
                                                                                                                        <div className="mt-3" >
                                                                                                                            <a
                                                                                                                                onClick={() => { getCodePageDetails(data); }}
                                                                                                                                className="hyperlink"
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                            >  
                                                                                                                                {data?.hyperlinkText}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data.show === false ? (
    
                                                                                                                            data.isAction === true && (
                                                                                                                                <>
                                                                                                                                    <div
                                                                                                                                        className="btn-group"
                                                                                                                                        role="group"
                                                                                                                                        aria-label="Basic outlined example"
                                                                                                                                    >
                                                                                                                                        <button
    className="chat-button-secondary font-12 font-bold"
    onClick={() => checkLooksGood(data?.notebookName)}
    style={{ marginRight: "10px" }}
>
    Looks good
</button>

<button
    type="button"
    className="btn btn-outline-primary chat-action-button py-0 px-1"
    onClick={() => {
        downloadResponse(data);
    }}
>
    <img src="images/chat-action-download.svg" />
</button>
                                                                                                                                        
                                                                                                                                
                                                                                                                                        
                                                                                                                                    </div>
    
                                                                                                                                </>
                                                                                                                            )
    
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                
                                                                                                                                {data.isAction === true && (
                                                                                                                                    <>
                                                                                                                                        <div
                                                                                                                                            className="btn-group"
                                                                                                                                            role="group"
                                                                                                                                            aria-label="Basic outlined example"
                                                                                                                                        >
                                                                                                                                            
                                                                                                                                            <button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                                onClick={() => {
                                                                                                                                                    downloadResponse(data);
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                                                            </button>
                                                                                                                                            
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === "report") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        <b>{data?.text}</b>
                                                                                                                        <div className="mt-3" >
                                                                                                                            <a
                                                                                                                                onClick={() => { zipAndDownloadFolder(); }}
                                                                                                                                className="hyperlink"
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                            >  
                                                                                                                                {data?.hyperlinkText}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data.show === false ? (
    
                                                                                                                            data.isAction === true && (
                                                                                                                                <>
                                                                                                                                    <div
                                                                                                                                        className="btn-group"
                                                                                                                                        role="group"
                                                                                                                                        aria-label="Basic outlined example"
                                                                                                                                    >
                                                                                                                                        <button
    className="chat-button-secondary font-12 font-bold"
    onClick={() =>{ checkLooksGood(data?.notebookName);
        zipAndDownloadFolder();
    }}
    style={{ marginRight: "10px" }}
>
    Looks good
</button>

<button
    type="button"
    className="btn btn-outline-primary chat-action-button py-0 px-1"
    onClick={() => {
        zipAndDownloadFolder();
    }}
>
    <img src="images/chat-action-download.svg" />
</button>
                                                                                                                                        
                                                                                                                                
                                                                                                                                        
                                                                                                                                    </div>
    
                                                                                                                                </>
                                                                                                                            )
    
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                
                                                                                                                                {data.isAction === true && (
                                                                                                                                    <>
                                                                                                                                        <div
                                                                                                                                            className="btn-group"
                                                                                                                                            role="group"
                                                                                                                                            aria-label="Basic outlined example"
                                                                                                                                        >
                                                                                                                                            {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                                                onClick={() => { getCodePageDetails(data); }}
                                                                                                                                            >
                                                                                                                                                <img src="images/chat-action-edit.svg" />
                                                                                                                                            </button>
                                                                                                                                            )}
                                                                                                                                            <button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                                onClick={() => {
                                                                                                                                                    downloadResponse(data);
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                                                            </button>
                                                                                                                                            {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                            >
                                                                                                                                                <img
                                                                                                                                                    src="images/chat-action-regenerate.svg"
                                                                                                                                                    height="20px"
                                                                                                                                                />
                                                                                                                                            </button>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === "consolidateDownload") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        <b>{data?.text}</b>
                                                                                                                        <div className="mt-3" >
                                                                                                                            <a
                                                                                                                                className="hyperlink"
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                                onClick={() => {
                                                                                                                                    if (data?.phase === 'Define') {
                                                                                                                                        
                                                                                                                                    } else if (data?.phase === 'Design') {
                                                                                                                                        
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {data?.hyperlinkText}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data.show === false ? (
                                                                                                                            <></>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                {" "}
                                                                                                                                <button
                                                                                                                                    className="chat-button-secondary font-12 font-bold"
                                                                                                                                    onClick={() => checkLooksGood(data?.notebookName)}
                                                                                                                                >
                                                                                                                                    Looks good
                                                                                                                                </button>
                                                                                                                                <div
                                                                                                                                    className="btn-group"
                                                                                                                                    role="group"
                                                                                                                                    aria-label="Basic outlined example"
                                                                                                                                >
                                                                                                                                    <button
                                                                                                                                        type="button"
                                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                        onClick={() => {
                                                                                                                                            if (data?.phase === 'Define') {
                                                                                                                                                
                                                                                                                                            } else if (data?.phase === 'Design') {
                                                                                                                                                
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <img src="images/chat-action-download.svg" />
                                                                                                                                    </button>
    
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                 
                                                                                                    } else if (data.flow === "user") {
                                                                                                        if (data.type === "text") {
                                                                                                            return (
                                                                                                                <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img
                                                                                                                            src={`${localStorage?.getItem("dXNlckltYWdl1") !== null
                                                                                                                                ? decode(
                                                                                                                                    localStorage
                                                                                                                                        ?.getItem("dXNlckltYWdl1")
                                                                                                                                        ?.substring(
                                                                                                                                            15,
                                                                                                                                            localStorage.getItem("dXNlckltYWdl1")?.length - 10
                                                                                                                                        )
                                                                                                                                )
                                                                                                                                : "images/userDefault.svg"
                                                                                                                                }`}
                                                                                                                            alt="user-profile"
                                                                                                                            className="chat-user-img"
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                                        {data.text}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return <></>
                                                                                                        }
                                                                                                    }
                                                                                                })}
                                                                                            </>
                                                                                        ) : <></>
                                                                                        }
                                                                                        {showLooksGood ? (
                                                                                            <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img
                                                                                                        src={`${localStorage?.getItem(
                                                                                                            "dXNlckltYWdl1"
                                                                                                        ) !== null
                                                                                                            ? decode(localStorage?.getItem("dXNlckltYWdl1")?.substring(15,
                                                                                                                localStorage.getItem(
                                                                                                                    "dXNlckltYWdl1"
                                                                                                                )?.length - 10
                                                                                                            )
                                                                                                            )
                                                                                                            : "images/userDefault.svg"
                                                                                                            }`}
                                                                                                        alt="user-profile"
                                                                                                        className="chat-user-img"
                                                                                                    />
                                                                                                </span>
                                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                    Looks good
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {showLoader === true ? (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img
                                                                                                        src="images/GenELT-chat-logo.svg"
                                                                                                        alt="user-profile"
                                                                                                    />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    <div className="dot-typing"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        <div id='Imdown'></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            



                                        </div>
                                    </>
                                    ):
                                    (
                                        <>
                                        {showSourceProgress && (
  <SourceCodeViewPage
    data={currentChatHistory}
    onChange={() => gridUpdated("sourceConnectionCode")}
    closeSourceCodePage={()=> closeSourceCodePage()}
  />
)}
{showdmdetails && (
  <DmCodeViewPage
    data={currentChatHistory}
    onChange={() => gridUpdated("DataModeldetails")}
    closeSourceCodePage={()=> closeDMDetails()}
  />
)}
{showrelationships && (
  <RelationShipViewPage
    data={currentChatHistory}
    onChange={() => gridUpdated("relationship")}
    closeSourceCodePage={()=> closerelationships()}
    fileName ={chatHistory[0]?.fileName?.split(".")[0].replaceAll(" ","_") || ""}
  />
)}

{showDax && (
  <DaxGrid
    data={currentChatHistory}
    onChange={() => gridUpdated("DAX")}
    closeDax={()=> closeDax()}
  />
)}
                                        </>
                                    )
                                }
                                {filePopup && (
                    <>
                        <TwbxPopup 
    saveFunction={(fileName)=>saveFunction(fileName)}
    closePopup={() => setfilePopup(false)}  // Pass a function that sets `filePopup` to false
    fileUploaded={chatHistory[0]?.fileName?.split(".")[0].replaceAll(" ","_") || ""}
/>
                    </>
                )}
                {deployPopup && (
                    <>
                        <DeployPBIPCode
    onChange={(data,statuss,userInputs)=>savedeployment(data,statuss,userInputs)}
    closePopup={() => setDeployPopup(false)}  // Pass a function that sets `filePopup` to false
    fileUploaded={chatHistory[0]?.deployDetails || ""}
    filename ={chatHistory[0]?.fileName?.split(".")[0].replaceAll(" ","_") || ""}

/>
                    </>
                )}

                </div >
            </>
        );
}




export default ChatMaintableau;