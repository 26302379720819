import { Client, Clientbi } from '../Service/Client'
import { ExcelClient } from '../Service/ExcelClient'
import axios from 'axios'

export async function generateSasTokenAPI() {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/adorg/generate-sas', {
            requestType: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Send JWT token in authorization header
            },
        });
        const { data } = response
        return data;
    } catch (error) {
        console.error("Error in generateSasToken:", error);
        return { status: 500, message: "Internal server error" };
    }
}
export async function postTableMapping(body, jwt) {

    console.log(body)
    return Client('https://genetlnpapi.asami.ai/uc/postTableMapping', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export const fetchUserDetail = (body, jwt) => {
    return Client('https://genetlnpapi.asami.ai/uc/generateAccessControl', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

//AccessControl
export async function generateAccessControl(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/generateAccessControl', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}
export async function generateMetadata(body, jwt) {

    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateMetaData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateExtractionRequirementData(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateExtractionRequirementData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateGoldDevCode(body, jwt) {
    console.log(body)

    return Client('https://genetlnpapi.asami.ai/cg/generateGold', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateChecklistDesign(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateChecklistDesign', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateChecklistDev(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateCheckListDev', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateDataDictionaryPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateDataDictionary', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDDL(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDDL', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDDLPseudocode(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDDLPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateExtractionPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateExtractionPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateGoldPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateGoldPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateBronze(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateBronze', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSilverSequence(body, jwt) {

    return Client('https://genetlnpapi.asami.ai/cg/generateSilverSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateGoldSequence(body, jwt) {

    return Client('https://genetlnpapi.asami.ai/cg/generateGoldSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function genrateDQualityCode(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDataQualityCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateBronzeSequence(body, jwt) {

    return Client('https://genetlnpapi.asami.ai/cg/generateBronzeSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateDataModelGoldUML(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateGoldDataModel', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDataModelSilverUML(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateSilverDataModel', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDataQualityPseudo(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDataQualityPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateBronzeToSilverER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateBronzeToSIlver', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateLoggerPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateLoggerPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSilver(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateSilver', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUtility(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUtility', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUtilityPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUtilitiesPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateOrchestrationUMLCode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateOrchestrationUmlCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateLoggerAndPipelineJSON(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateLoggerAndPipelineCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateMetadataTablePseudo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateMetadataPseudoCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateOrchestrationDocument(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateOrchestrationDocument', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generatePipelineSummaryPseudo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generatePipelineSummary', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateTransformationPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateTransformationPseudocode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateTestCaseCode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateTestCaseCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUnitTestCasePseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUnitTestCasePseudocode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSourceToExtractionER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateSourceToExtractionER', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateSilverToGoldER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateSilverToGoldER', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////












////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//meta

export const deleteDBRecords = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/meta/deleteDbRecords",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}



export async function getNotifications(role, userId, orgId, jwt) {
    return Client("https://genetlnpapi.asami.ai/meta/fetchNotifications",
        {
            method: 'POST',
            body: { role, userId, orgId },
            headers: {
                "authorization": jwt
            }
        })
}

export async function getManageRequets(userId, JWT, adRole) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRequests', {
        method: 'POST',
        body: { userId, adRole }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": JWT
        }
    });
}


export async function download(userId, JWT, adRole, phase, futureState, methodology, type, requestId) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRequests', {
        method: 'POST',
        body: { userId, adRole, phase, futureState, methodology, type, requestId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": JWT
        }
    });
}


export async function getManageRoles(userId, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRoles', {
        method: 'POST',
        body: { userId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function getMetaDataValues(projectId, jwt) {
    return Client('https://genetlnpapi.asami.ai/meta/getMetaValues', {
        method: 'POST',
        body: projectId,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function postManageRequests(requestId, status, projectId, phase, userId, jwt, orgId,) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/postManageRequests', {
        method: 'POST',
        body: { projectId, userId, requestId, orgId, status, phase }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function putManageRoles(body, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/putManageRoles', {
        method: 'POST',
        body: body, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function updateManageRoles(userId, adUserId, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/updateManageRoles', {
        method: 'POST',
        body: { userId, adUserId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export async function updateMetaValues(projectId, TableID, selectedItem, metaDataNotebookedit, userId, jwt) {
    return Client('https://genetlnpapi.asami.ai/meta/updateMetaValues', {
        method: 'POST',
        body: { projectId, TableID, selectedItem, userId, metaDataNotebookedit },
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}
export const fetchDesignDetails = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/meta/fetchDesignDetails",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}




//////////////////////////////////////////////////////////////////////////////////////////////////////
//adorg

export const getLogin = (data) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/checkUser",
        { method: 'POST', body: data },
    )
}
export async function adUsers(userId, jwt, accessToken) {
    return Client("https://genetlnpapi.asami.ai/adorg/aduser",
        {
            method: 'POST',
            body: { userId, accessToken },
            headers: {
                "Content-Type": "application/json",

                "authorization": jwt
            }
        })
}

export async function projectsDropDown(userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/projectDropDown", {
        method: 'POST',
        body: { userId },
        headers: {
            "authorization": jwt
        }
    })
}





export const getAuth = (data) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/getClient",
        { method: 'POST', body: data },
    )

}

export async function DefineDataSave(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/adorg/defineDataSave', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function editOrganization(orgId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/editOrganization",
        {
            method: 'POST',
            body: { userId, orgId },
            headers: {
                "authorization": jwt
            }
        })
}


export async function getOrganization(userId, adRole, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/adorg/getOrganization', {
        method: 'POST',
        body: { userId, adRole }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export const getProjectInfo = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/getProjectInfo", {
        method: 'POST',
        body: data,
        headers:
        {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    },
    )
}


/**PS_CD_1.982-1.986
 * The function `postDesignDetails` sends a POST request to a specified API endpoint with provided data
 * and JWT authorization.
 * @param data - The `data` parameter in the `postDesignDetails` function is the information or payload
 * that you want to send in the POST request to the specified API endpoint. This data could be in the
 * form of an object, string, or any other format that needs to be sent to the server for processing
 * @param jwt - JWT (JSON Web Token) is a type of token used for authentication and authorization in
 * web applications. It is typically sent in the Authorization header of HTTP requests to authenticate
 * the user. In this case, the `jwt` parameter is the JWT token that will be used for authorization
 * when making a POST request
 * @returns The `postDesignDetails` function is returning the result of calling the `Client` function
 * with the specified parameters, which includes making a POST request to
 * "https://genetlnpapi.asami.ai/api/postDesignDetails" with the provided data and JWT token in the headers.
 */
export const postDesignDetails = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/postDesignDetails",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export async function getProjects(orgId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/getProjects", {
        method: 'POST',
        body: { orgId, userId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export const insertError = async (data) => {
    const config = {
        method: 'POST',
        url: `https://genetlnpapi.asami.ai/adorg/insertError`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        await axios(config);
    } catch (error) {

    }
}


export async function isActiveOrganization(orgId, isActive, userId, jwt) {
    //PS_CD_1.96
    return Client('https://genetlnpapi.asami.ai/adorg/isActiveOrganization', {
        method: 'POST',
        body: { orgId, isActive, userId },
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function isActiveProjects(projectId, isActive, userId, jwt) {
    return Client('https://genetlnpapi.asami.ai/adorg/isActiveProjects', {
        method: 'POST',
        body: { projectId, isActive, userId },
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export const postDefineDetails = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/postDefineDetails", {
        method: 'POST',
        body: data,
        headers:
        {
            "authorization": jwt
        }
    },
    )
}


export async function postOrganization(isEdited, formData, users, orgId, jwt) {
    console.log("API called")
    //PS_CD_1.108
    return Client('https://genetlnpapi.asami.ai/adorg/postOrganization', {
        method: 'POST',
        body: { isEdited, formData, users, orgId }, // <-- Remove this object wrapper
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt,
        },
    });
}


export const postProjectInfo = (data, jwt) => {
    console.log(data, 'i am in api file')
    return Client(
        "https://genetlnpapi.asami.ai/adorg/postProjectInfo", {
        method: 'POST',
        body: data,
        headers:
        {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    },
    )
}











////////////////////////////////////////////////////////////////////////////////////////////////////
//note 


export async function targetSystemValidation(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/checkDatabricksCreds', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getChatHistory(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/getChatHistory', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function getSourceSystemConnectioninfo(projectId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/note/getSourceSystemConnectioninfo",
        {
            method: 'POST',
            body: { projectId, userId },
            headers: {
                "authorization": jwt
            }
        })
}


export async function getAuditLog(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/fetchAuditLogs', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getValidated(body, userId, jwt) {
    return ExcelClient('https://genetlnpapi.asami.ai/note/defineExcelValidator', {
        requestType: 'POST',
        body: { body, userId },
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt
        },
    });
}

export async function deployNotebook(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/deployNotebooks', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getCodePageDataApi(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/fetchCodeData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const fetchDropDownValues = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/fetchDropDownValues",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export async function postSourceSystemConnectioninfo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/postSourceSystemConnectioninfo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const updatePseudocodeData = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/UpdatePseudocodeData",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export const fetchPseudocodeData = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/fetchPseudocodeData",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export async function postChatHistory(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/postChatHistory', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function postCodePageDataApi(body, jwt) {
    console.log("update", body)
    return Client('https://genetlnpapi.asami.ai/note/updateCodeData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const getHubspot = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getHubsSpot",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getMySQL = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getMySQL",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getMsSQL = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getMsSQL",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getOracle = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getOracle",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getPostgres = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getPostgres",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getRedshift = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getRedshift",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}



export const getSalesforce = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getSalesforce",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getSnowflake = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getSnowflake",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export const generateDataflow = (data, jwt) => {

}





export const generateDatasetsPseudocode = (data, jwt) => {

}

export const generateDataflowPseudocode = (data, jwt) => {

}


export const generateLinkedServicePseudocode = (data, jwt) => {

}





////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////UC API////////////////////////////////////////////////////////////////////////////////////

// api.js or the relevant module file

export async function postDWHConnectionInfo(data, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/postDWHConnectionInfo', {
        method: 'POST',
        body: data,
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export async function PostUCMigrationUploads(data, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/postUcMigrationUploads', {
        method: 'POST',
        body: data,
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}





export const frameworksAPI = () => { };

export const generateCatalogSchema = () => { };



export const generateChecklistPseudo = () => { };

export const generateDDLCode = () => { };

export const generateTraditionalCode = () => { };

export const generateCopyCloneCode = () => { };

export const generateChecklistCode = () => { };

export const generateUnitTestcaseCode = () => { };

export const generateNotebookChangesRecommend = () => { };


export const consolidateDownload = async (body) => {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/consolidateDocument', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in consolidateDownload:", error);
        return { status: 500, message: "Internal server error" };
    }
};


////////////////////////UC - Migration


export async function getConnectionInfo(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/getDWHConnectionInfo', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}





////////////////////////UC - Migration




export async function inventoryGeneration(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateInventoryDocument', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generateFrameworksAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/frameWorksApi', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generateInventoryDataModelAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateInventoryDataModel', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateCatalogAndSchemaDetailsAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateCatalogAndSchema', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generateCodeReviewCheckListAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/devCodeReviewChecklist', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}





export async function ucMigrationUploadsAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/postUcMigrationUploads', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function postSourceToTargetFlowStatus(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/postSourcetotargetFlowStatus', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}




export async function generateTableMappingAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateTableMappingSheet', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateDDLScriptsCodeAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateddlscriptsCode', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateLoggerCodeAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/frameWorksApi', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}









export async function fetchNBName(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/fetchPseudocodeData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });


}

export async function generateDataDictionaryuc(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/generateDataDictionary', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateMetadataTableuc(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/generateMetadataTable', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

// generateDDLScriptsPseudouc
export async function generateDDLScriptsPseudouc(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/generateDDLScriptsPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

// generateLoggerPseudouc
export async function generateFrameWorksAPI(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/frameWorksApi', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}



export const generateDDLPseudo = () => {

};


export async function generateApproachdiagram(body, jwt) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateApproachDiagram', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateAnalysisdocument(body, jwt) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateAnalysisDocument', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateAnalysisdocument:", error);
        return { status: 500, message: "Internal server error" };
    }
}

//PS_90 - PS_103- Define the generateTableandNotebookSummary() function, which takes in parameters body and jwt, to handle the API call for generating table and notebook summary details. This function prepares the request payload and the authentication header.
export async function tableandNotebookSummary(data, jwt) {
    return Client('https://genetlnpapi.asami.ai/uc/generateTableAndNoteBookSummary', {
        method: 'POST',
        body: data,
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export async function generateNotebookRecommendationsAPI(body) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generateNotebookRecommendation', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': body.jwt_token,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in PostDWHConnection:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generatesourceTotargetFlowAPI(body, jwt) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/generatesourceTotargetFlow', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateAnalysisdocument:", error);
        return { status: 500, message: "Internal server error" };
    }
}
export async function postSourceToTargetFlowAPI(body, jwt) {
    try {
        const response = await Client('https://genetlnpapi.asami.ai/uc/postSourcetotargetFlowStatus', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateAnalysisdocument:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function postTwbxFile(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/uc/tableau_metadata_extract', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function deployPBIPcode(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/uc/deployNoteBook', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generateSemanticModel(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/uc/generateSemanticModel', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function fileStructureAPI(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/uc/generatePBIPFolder', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


/// Implementation CR

export const fetchExploreGridData = (body, jwt) => {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/migration/fetchCardGridData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export const generationFunction = (body, jwt) => {
    console.log(body)
    return Client(`https://genetlnpapi.asami.ai/migration${body.generateEndpoint}`, {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export const tablePathSave = (body, jwt) => {
    console.log(body)
    return Client(`https://genetlnpapi.asami.ai/adorg/tablepath`, {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export const getProjectName = (userId, jwt,orgId,promptId, type) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/getProjectName", {
        method: 'POST',
        body: { userId, jwt,orgId, promptId, type },
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    })
}



// dwh migration


export async function generateMappingData(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generateMappingData', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateStaticUrls(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/insertStaticdata', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateDWHMetaCode(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generatemetadatascripts', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}



export async function generateDWHddlScripts(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generateDDLScripts', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateDWHbronze(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generateExtraction', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}



export async function generateDWHsilver(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generatesilver', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}



export async function generateDWHgold(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generategold', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}

export async function generateDWHcodereviewChecklist(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/generateCodeReviewChecklistDev', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function generateDeployDWH(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/deploynotebooks', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export async function getSourceSystemConnectioninfo1(body) {
    return Client("https://genetlnpapi.asami.ai/note/getSourceSystemConnectioninfo",
        {
            method: 'POST',
            body:body,
            headers: {
                "authorization": body.jwt
            }
        })
}


export async function DWHConnection(body, jwt) {
    try {
        const response = await Clientbi('https://genetlnpapi.asami.ai/migration/DWHConnection', {
            requestType: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'authorization': jwt,  // Send JWT token in authorization header
            },
        });

        return response;
    } catch (error) {
        console.error("Error in generateApproachDiagram:", error);
        return { status: 500, message: "Internal server error" };
    }
}


export const DWHActivityLogs = (data, jwt) => {
    return Clientbi(
        "https://genetlnpapi.asami.ai/migration/dwhupdatepseudocode",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const DWHinventorySummary = (data, jwt) => {
    return Clientbi(
        "https://genetlnpapi.asami.ai/migration/generateInventorySummary",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}