import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { read, utils as XLSXUtils } from 'xlsx';
import { putJsonToBlob1 } from '../new/BlobStorageService';
import SourceInfoPopup from './ingestSourcePopup';
import { postSourceSystemConnectioninfo } from '../../Service/Api';
import { sourceType } from './contrains';
import * as XLSX from 'xlsx';
import { generateSasTokenAPI } from '../../Service/Api';
function ConnectionInfo(props) {
  const location = useLocation();
  const [showPreviousComponent, setShowPreviousComponent] = useState(false);
  console.log(props, 'this is the prop');
  const initialEntries = (props?.state || []).map((entry) => {
    // Extract the keys from connectionDetails to create the fields array
    const fields = entry.connectionDetails &&Object.keys(entry.connectionDetails).length>0 ? Object.keys(entry.connectionDetails) : entry.fields;
    return {
      ...entry,
      fields,
      connectionDetails: entry.connectionDetails || {},
      fileName: entry.inventoryDDName || '',
      sheetData: entry.sheetData || null,
      blobUrl: entry.blobUrl || '',
      inputErrors: entry.inputErrors || {},
      error: entry.error || [],
    };
  });

  const [entries, setEntries] = useState(initialEntries);
  const {state}=useLocation()

  console.log(initialEntries, 'this is the state');
  const ProjectID = state?.projectId;
  const OrgID = state?.orgId;
  const migrationType = state?.selectedTab || 'ingest';
  const [allValid, setAllValid] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const fileInputRefs = useRef([]);
  const [isDisabled, setisDisabled] = useState(false);

  useEffect(() => {
    checkAllFieldsFilled();
  }, [entries]);

  const handleInputChange = (e, field, index) => {
    const { value } = e.target;
    setEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? {
              ...entry,
              connectionDetails: {
                ...entry.connectionDetails,
                [field]: value,
              },
              inputErrors: {
                ...entry.inputErrors,
                [field]: value ? null : 'Field is required',
              },
            }
          : entry
      )
    );
  };

  const handleFileUpload = async (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.name.endsWith('.xlsx')) {
      updateEntryError(index, ['Invalid file type']);
      return;
    }

    try {
      const data = await file.arrayBuffer();
      const workbook = read(data, { type: 'array' });
      const sheets = workbook.SheetNames;
      const requiredSheets=[
        'Summary',
        'TableInventory',
        'DataDictionaryTables',
        'SubjectAreas',
      ]
      const missingSheets = requiredSheets.filter(sheet => !workbook.Sheets[sheet]);
      // let data1=`Required sheets missing:${missingSheets}`
      if (
        ![
          'Summary',
          'TableInventory',
          'DataDictionaryTables',
          'SubjectAreas',
        ].every((sheet) => sheets.includes(sheet))
      ) {
        updateEntryError(index, [`Required sheets missing: ${missingSheets}`]);
        return;
      }

      const allData = {
        Summary: XLSXUtils.sheet_to_json(workbook.Sheets['Summary']),
        TableInventory: XLSXUtils.sheet_to_json(workbook.Sheets['TableInventory']),
        DataDictionaryTables: XLSXUtils.sheet_to_json(workbook.Sheets['DataDictionaryTables']),
        SubjectAreas: XLSXUtils.sheet_to_json(workbook.Sheets['SubjectAreas']),
      };

      const validationErrors = validateData(allData);
      if (validationErrors.length > 0) {
        updateEntryError(index, validationErrors);
        return;
      }

      setEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? { ...entry, fileName: file.name, sheetData: allData, error: [] }
            : entry
        )
      );
    } catch (err) {
      console.error('Error reading file', err);
      updateEntryError(index, ['Error reading file']);
    }
  };

  const updateEntryError = (index, errors) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, error: errors } : entry
      )
    );
  };

  const validateData = ({
    Summary,
    TableInventory,
    DataDictionaryTables,
    SubjectAreas,
  }) => {
    const errors = [];

    const checkFields = (data, fields, sheetName) => {
      const headers = Object.keys(data[0] || {});
      const requiredFieldNames = fields.map((field) => field.name);

      const missingColumns = requiredFieldNames.filter(
        (field) => !headers.includes(field)
      );
      if (missingColumns.length) {
        missingColumns.forEach((column) => {
          errors.push(`${sheetName} sheet: Missing required column '${column}'.`);
        });
      }

      return errors;
    };

    const SummaryFields = [
      { name: 'Summary', allowNA: false, allowNull: false },
      { name: 'Count', allowNA: false, allowNull: false },
    ];
    const TableInventoryFields = [
      { name: 'S.No', allowNA: false, allowNull: false },
      { name: 'Source System', allowNA: false, allowNull: false },
      { name: 'Catalog/DB Name', allowNA: false, allowNull: false },
      { name: 'SchemaName', allowNA: false, allowNull: false },
      { name: 'Table Name', allowNA: false, allowNull: false },
      { name: 'Row Count', allowNA: false, allowNull: false },
      { name: 'Volume', allowNA: true, allowNull: true },
      { name: 'InScope', allowNA: true, allowNull: true },
      { name: 'Comments', allowNA: true, allowNull: true },
    ];
    const DataDictionaryviewsFields = [
      { name: 'SourceSystem', allowNA: false, allowNull: false },
      { name: 'Subject Area', allowNA: false, allowNull: false },
      { name: 'DatabaseName', allowNA: false, allowNull: false },
      { name: 'SchemaName', allowNA: false, allowNull: false },
      { name: 'TableName', allowNA: false, allowNull: false },
      { name: 'ColumnName', allowNA: false, allowNull: true },
      { name: 'DataType', allowNA: false, allowNull: false },
      { name: 'Constraints', allowNA: false, allowNull: false },
      { name: 'Description', allowNA: false, allowNull: false },
    ];
    const DataDictionaryTablesFields = [...DataDictionaryviewsFields];
    const SubjectAreasFields = [
      { name: 'SubjectArea', allowNA: false, allowNull: false },
      { name: 'SchemaName', allowNA: false, allowNull: false },
      { name: 'TableName', allowNA: false, allowNull: false },
    ];

    checkFields(Summary, SummaryFields, 'Summary');
    checkFields(TableInventory, TableInventoryFields, 'TableInventory');
    // checkFields(DataDictionaryviews, DataDictionaryviewsFields, 'DataDictionaryviews');
    checkFields(DataDictionaryTables, DataDictionaryTablesFields, 'DataDictionaryTables');
    checkFields(SubjectAreas, SubjectAreasFields, 'SubjectAreas');

    return errors;
  };


  const fetchTemplateFromBlob = async () => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `dwhmigration/templates/inventoryTemplate.json`;
        const url = `https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/${blobPath}?${sasToken}`;
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        convertJsonToExcel(jsonData);

    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};

const convertJsonToExcel = (jsonData) => {
   
    const workbook = XLSX.utils.book_new();

    for (const [key, data] of Object.entries(jsonData)) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, key);
    }
    const fileName = 'InventoryTemplate.xlsx';
    XLSX.writeFile(workbook, fileName);
};
  const handleCancelUpload = (index) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, fileName: '', sheetData: null, blobUrl: '', error: [] } : entry
      )
    );
  };

  const handlePrevious = () => {
    setShowPreviousComponent(true);
  };

  const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

const fetchJsonFromBlob = async () => {
        try {
            // Assuming generateSasTokenAPI returns a SAS token
            const sasToken = await generateSasTokenAPI();
            const blobPath = `dwhmigration/templates/initscriptrefactor.py`; // Update the path to point to the Python script
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            const url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;
            
            console.log(url2, 'this is the URL for fetch');
            
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const pythonCode = await response.text(); 
            console.log(pythonCode, 'Fetched Python Script');
            
            // Create a blob from the fetched text
            const blob = new Blob([pythonCode], { type: 'text/x-python' });
            
            // Create a link element for downloading the file
            const link = document.createElement('a');
            const urlBlob = window.URL.createObjectURL(blob);
            link.href = urlBlob;
            link.download = 'Inventoryscript.sql';
            document.body.appendChild(link); 
    
            
            link.click();
        
            document.body.removeChild(link);
            window.URL.revokeObjectURL(urlBlob);
            
            return pythonCode;
        } catch (error) {
            console.error('Error fetching and downloading Python script from blob:', error);
            throw error;
        }
    };

  const checkAllFieldsFilled = () => {
    const allFieldsValid = entries.every((entry) => {
      const inputValid = Object.values(entry.inputErrors).every((error) => !error);
      return inputValid && entry.sheetData !== null;
    });
    setAllValid(allFieldsValid);
  };

  const handleSubmit = async () => {
    try {
      
      setisDisabled(true)
      document.getElementById("pageLoader").style.display = "block";
      if (props.onChange) {
        props.onChange();
      }
      if (props.submit) {
        props.submit();
      }
      debugger
      const updatedEntries = await Promise.all(
        entries.map(async (entry) => {
          let file_name=entry.fileName.replace('.xlsx','')
          const blobpath = `dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType}/inventory_${file_name}.json`;
          debugger
          const blobUrl = await putJsonToBlob1(entry.sheetData, blobpath);
          console.log(blobUrl,"url data")
          return { ...entry, blobUrl };
        })
      );

      

      setEntries(updatedEntries);
      console.log(updatedEntries, "Submitted Data");

      await postSourceSystemConnectioninfo({
        sourceSystemDetails: updatedEntries,
        projectId: ProjectID,
        userId: state?.userId,
        sourceType: "dwhmigration"
      }, state?.jwt);

      console.log('Data submitted successfully');
      document.getElementById("pageLoader").style.display = "none";
      setisDisabled(false)
    } catch (error) {
      console.error('Error submitting data', error);
      document.getElementById("pageLoader").style.display = "none";
    }
  };

  if (showPreviousComponent) {
    return <SourceInfoPopup state={entries} closePopup={props.closePopup} />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
      <div
        className="modal-backdrop fade show"
        style={{ display: "block" }}
    ></div>
      <div
          className="overlay"
          id="pageLoader"
          style={{ display: "none" }}
          >
          <div className="position-absolute top-50 start-50  translate-middle">
            <div className="d-flex align-items-center loader-bg">
                <div
                    className="spinner-border Loader text-dark align-center"
                    role="status"
                >
                    <span className="visually-hidden"></span>
                </div>
                <span className="ms-3 font-18 loader-text mt-2 font-medium">
                    Loading...
                </span>
            </div>
          </div>
          </div>
        <div
          className="modal fade show d-block"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="SourceSysLabel"
          aria-hidden="true"
          style={{ overflow: "scroll" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content px-2">
              <div className="modal-header border-0">
                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="SourceSysLabel">
                  Source System and Connection Info
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.closePopup}
                ></button>
              </div>
              <div className="modal-body py-0 my-4 mx-5">
                <div className="col-12">
                  <div className="d-flex justify-content-center mb-3 mb-md-3 mb-lg-5">
                    <ul className="list-unstyled custom-stepper d-flex align-items-center">
                      <li className="stepper-sty me-lg-5 pe-4 d-flex align-items-center">
                        <span className="connect-info-icon icon-sty"></span>
                        <span className="stepper-text font-14 font-bold ms-2">
                          <img src="images/check-mark.svg" alt="check mark" />
                          <span className="ms-2">Source System</span>
                        </span>
                      </li>
                      <li className="stepper-sty active me-lg-5 pe-4 d-flex align-items-center position-relative">
                        <span className="meta-info-icon icon-sty stepper-line"></span>
                        <span className="stepper-text font-14 font-bold ms-2">Connection Info</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {entries.map((entry, index) => (
                  <div
                    key={index}
                    className="rounded-3 font-20 custom-acc-border font-bold mt-2"
                  >
                    <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg mb-0 p-2 rounded-3 d-flex align-items-center">
                      <button
                        className="btn custom-accordian-btn me-3 d-inline-flex align-items-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#view-instruction-${index}`}
                      >
                        <img
                          src="images/down-acc-arow.svg"
                          alt="arrow"
                          className="me-3"
                        />
                        <span className="cursor-pointer text-black font-18 font-medium">
                          {entry.systemName}
                        </span>
                      </button>
                      
                    </p>
                    <div className="row">
                      <div
                        className="collapse show"
                        id={`view-instruction-${index}`}
                      >
                        <div className="custom-accordian-body color-grey-bg py-2 px-3">
                          <div className="row pb-4">
                          {(entry.fields || []).map((field, idx) => (
  <div className="col-12 col-lg-4 mt-3" key={idx}>
    <label
      htmlFor={`${field}-${idx}`}
      className="form-label text-black font-14 font-medium"
    >
      {field} <span className="text-red">*</span>
    </label>
    <input
      type={field.toLowerCase() === 'password' ? 'password' : 'text'}
      className="form-control cust-input-sty font-14 font-regular"
      id={`${field}-${idx}`}
      placeholder={`Enter ${field}`}
      value={entry.connectionDetails[field] || ''}
      onChange={(e) => handleInputChange(e, field, index)}
    />
    {entry.inputErrors[field] && (
      <span className="text-danger font-12">{entry.inputErrors[field]}</span>
    )}
  </div>
))}
                          </div>
                          <div className="row mt-3 mb-3">
                            <div className="col-md-12 col-sm-12 mb-3">
                              <label className="form-label text-black font-14 font-medium">
                                Upload Inventory Document<span className="text-red"> *</span>
                              </label>
                              {/* <span className="d-block font-12 info-message-section text-black font-medium">
                              Please make sure the inventory document includes the Summary, Table Inventory, Current State Data Dictionary, Subject Areas, Stored Procedures, and Future State Data Dictionary.
                              </span> */}
                            </div>
                            <div className="col-md-12 col-sm-12">
                              <div className="mb-5">
                                <div className="upload-btn-wrapper d-block">
                                  {entry.fileName ? (
                                    <button
                                      type="button"
                                      className="upload-btn-sty shadow-none cursor-pointer text-center"
                                    >
                                      <img
                                        src="images/sample-logo.svg"
                                        alt="sample-logo"
                                        className="mb-2"
                                      />
                                      <a className="font-medium font-12 d-block">
                                        {entry.fileName}
                                      </a>
                                      <a
                                        style={{
                                          position: 'relative',
                                          zIndex: '100',
                                          pointerEvents: 'auto',
                                          opacity: 1,
                                        }}
                                        onClick={() => handleCancelUpload(index)}
                                      >
                                        <span className="ms-2">
                                          <img
                                            src="images/link-close-icon.svg"
                                            alt="link-close-icon"
                                          />
                                        </span>
                                      </a>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="upload-btn-sty shadow-none cursor-pointer text-center"
                                      onClick={() =>
                                        fileInputRefs.current[index].click()
                                      }
                                    >
                                      <img
                                        src="images/upload-icon.svg"
                                        alt="browse"
                                        className="mb-2"
                                      />
                                      <span className="font-medium font-13 d-block text-grey mb-2">
                                        Browse or drag and drop file
                                      </span>
                                      <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                        Use this template to add info -
                                        <a
                                          className={`popup-anchor-color custom-tableHeader ${
                                            isDownloading ? 'disabled' : ''
                                          }`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            // exportToExcel();
                                            fetchTemplateFromBlob()
                                          }}
                                          style={{
                                            pointerEvents: isDownloading
                                              ? 'none'
                                              : 'auto',
                                            opacity: isDownloading ? 0.5 : 1,
                                          }}
                                        >
                                          {isDownloading
                                            ? 'Downloading...'
                                            : 'Download Template'}
                                        </a>
                                      </span>
                                    </button>
                                  )}
                                  <input
                                    type="file"
                                    ref={(el) => (fileInputRefs.current[index] = el)}
                                    className="cursor-pointer cust-file"
                                    onChange={(e) => handleFileUpload(e, index)}
                                    style={{ display: 'none' }}
                                  />
                                </div>
                                {entry.error.length > 0 && (
                                  <div className="code-doc-error">
                                    {entry.error.map((data, errIdx) => (
                                      <span
                                        className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section"
                                        key={errIdx}
                                      >
                                        <img
                                          src="images/warning-red-icon.svg"
                                          alt="Warning icon"
                                        />
                                        <span className="ms-2">{data}</span>
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* End of Upload Data Section */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                <div className="justify-content-start d-flex me-auto">
                  <button
                    type="button"
                    className="btn cust-secondary-btn font-14 font-medium me-3"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                </div>
                <button
                  type="button"
                  className="btn cust-secondary-btn font-14 font-medium me-3"
                  data-bs-dismiss="modal" 
                  onClick={props.closePopup}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn cust-primary-btn font-14 font-medium"
                  onClick={handleSubmit}
                  disabled={!allValid || isDisabled}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectionInfo;