import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import Editor from '@monaco-editor/react';
import axios from 'axios'
import { HeaderCom } from './Header';


function UnitTestCaseADF(props) {

    // const nav = useNavigate();
    const { state } = useLocation();
    // let state = {
    //     "projectId": "4438CFB1-4357-4159-A0A2-ADF543561A47",
    //     "orgId": "7DB5BA95-93BB-443D-925D-9B6C0623B2F5",
    //     "userId": "AC70C3D8-936F-4C02-A692-649C46DA8354",
    //     jwt: ""
    // }

    const [setVal, setSetVal] = useState([]);
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        Pipelinename: "",
        DataFlowname: "",
        "ComponentName/TableName": "",
        ComponentType: "",
        Details: "",
        SourceScripts: "",
        TargetScripts: "",
        "Success/Failure": ""
    })
    const [action, setAction] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' })

    useEffect(() => {
        fetchPseudo()
    }, [])

    async function fetchPseudo() {
        //  
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type:"unitTestPseudo",
            userId: state.userId,
            orgId: state.orgId,
        }

        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(res, "fetchRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            console.log("The Response Is 200")
            let data = JSON.parse(res.data[0].unitTestCasePseudocode)
            // let arr = []
            // for (let a of data) {
            //     arr = [...arr, ...a]
            // }
            setSetVal(data);
            setFilteredRecord(data)
            console.log(setVal, "THIS IS THE VALUE OF UNITTESTCASE")
        }
    };

    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            // nav('/');
        }, 3000);
    };
    let serialNo = 1


    const viewCodes = (index) => {
        console.log(filteredRecord[index].PipelineName, "INDEXXX")
            ;
    }

    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.Pipelinename} onChange={(e) => setNewData({ ...newData, Pipelinename: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DataFlowname} onChange={(e) => setNewData({ ...newData, DataFlowname: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.['ComponentName/TableName']} onChange={(e) => setNewData({ ...newData, "ComponentName/TableName": e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData?.ComponentType} onChange={(e) => setNewData({ ...newData, ComponentType: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Details' value={newData?.Details} onChange={(e) => setNewData({ ...newData, Details: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceScripts' value={newData?.SourceScripts} onChange={(e) => setNewData({ ...newData, SourceScripts: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='TargetScripts' value={newData?.TargetScripts} onChange={(e) => setNewData({ ...newData, TargetScripts: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Success/Failure' value={newData?.['Success/Failure']} onChange={(e) => setNewData({ ...newData, "Success/Failure": e.target.value })} /></td>

                                <td class="text-center">
                                    <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            Pipelinename: "",
                                            DataFlowname: "",
                                            "ComponentName/TableName": "",
                                            ComponentType: "",
                                            Details: "",
                                            SourceScripts: "",
                                            TargetScripts: "",
                                            "Success/Failure": ""
                                        })
                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{"TC_" + (serialNo++).toFixed(0.1)}</td>
                                <td>{item.PipelineName || "NA"}</td>
                                <td>{item.DataFlowName || "NA"}</td>
                                <td>{item["ComponentName/TableName"] || "NA"}</td>
                                <td>{item.ComponentType || "NA"}</td>
                                <td>{item.Details || "NA"}</td>
                                <td>{item.SourceScripts || "NA"}</td>
                                <td>{item.TargetScripts || "NA"}</td>
                                <td>{item["Success/Failure"] || "NA"}</td>
                                <td className="text-center">
                                    <td class="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData({ ...newData, Pipelinename: filteredRecord[index].PipelineName, DataFlowname: filteredRecord[index].DataFlowName, "ComponentName/TableName": filteredRecord[index]["ComponentName/TableName"], ComponentType: filteredRecord[index].ComponentType, Details: filteredRecord[index].DataFlowName, SourceScripts: filteredRecord[index].SourceScripts, TargetScripts: filteredRecord[index].TargetScripts, "Success/Failure": filteredRecord[index]["Success/Failure"], }) }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    const handleSearch = (term) => {
         
        if (term.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.PipelineName.toLowerCase().includes(term.toLowerCase()) ||
                item.DataFlowName.toLowerCase().includes(term.toLowerCase()) 
                // ||
                // item["ComponentName/TableName"].toLowerCase().includes(term.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };

    const handleSort = (columnName) => {
        const isSameColumn = columnName === sortOrder.column;
    
        let newSortOrder;
        if (isSameColumn) {
            newSortOrder = { column: columnName, order: sortOrder.order === 'desc' ? 'asc' : 'desc' };
        } else {
            newSortOrder = { column: columnName, order: 'desc' };
        }
    
        setSortOrder(newSortOrder);
    
        let sortedRecords = [...filteredRecord];
    
        // Special handling for RQ_ID column
        if (columnName === 'RequirementID') {
            // Create an array of objects with the original data and extracted number
            let dataWithNumbers = sortedRecords.map(record => ({
                ...record,
                extractedNumber: parseFloat(record[columnName].split('.')[1])
            }));
    
            // Sort the array based on the extracted number
            dataWithNumbers.sort((a, b) => {
                if (newSortOrder.order === 'asc') {
                    return a.extractedNumber - b.extractedNumber;
                } else {
                    return b.extractedNumber - a.extractedNumber;
                }
            });
    
            // Update sortedRecords with the sorted data (without the extractedNumber property)
            sortedRecords = dataWithNumbers.map(({ extractedNumber, ...rest }) => rest);
        } else {
            // For other columns, use the existing logic
            sortedRecords.sort((a, b) => {
                const valueA = a[columnName];
                const valueB = b[columnName];
    
                const isNumeric = !isNaN(valueA) && !isNaN(valueB);
    
                if (isNumeric) {
                    return newSortOrder.order === 'asc' ? valueA - valueB : valueB - valueA;
                } else {
                    if (newSortOrder.order === 'asc') {
                        return valueA.localeCompare(valueB);
                    } else {
                        return valueB.localeCompare(valueA);
                    }
                }
            });
        }
    
        setFilteredRecord(sortedRecords);
    };

    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            type:"unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "delete",
            "comments": reason,
            "index": deleteRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                Pipelinename: "",
                DataFlowname: "",
                "ComponentName/TableName": "",
                ComponentType: "",
                Details: "",
                SourceScripts: "",
                TargetScripts: "",
                "Success/Failure": ""
            })
            fetchPseudo()
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setwhetherUpdated(true)
        }
    };

    const handleDownload = () => {
        let UtilitiesArray = setVal.map(data => { return { PipelineName: data.PipelineName, DataFlowName: data.DataFlowName, "ComponentName/TableName" : data['ComponentName/TableName'], ComponentType: data.ComponentType, Details: data.Details, SourceScripts: data.SourceScripts, TargetScripts: data.TargetScripts, "Success/Failure": data['Success/Failure'] } });
        // Declare variables for file type and extension 

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert UtilitiesArray to sheet
        const ws = XLSX.utils.json_to_sheet(UtilitiesArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Utilities');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'UnitTestCase DataBricks and Synapse Notebook' + fileExtension);

        // The saved file will be shown to the user
    };

    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": { PipelineName: newData.Pipelinename, DataFlowName: newData.DataFlowname, "ComponentName/TableName": newData['ComponentName/TableName'], ComponentType: newData.ComponentType, Details: newData.Details, SourceScripts: newData.SourceScripts, TargetScripts: newData.TargetScripts, "Success/Failure": newData['Success/Failure'] },
            "index": editableRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                Pipelinename: "",
                DataFlowname: "",
                "ComponentName/TableName": "",
                ComponentType: "",
                Details: "",
                SourceScripts: "",
                TargetScripts: "",
                "Success/Failure": ""
            })
            fetchPseudo()
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
            // setSetVal(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            // setFilteredRecord(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
        }
    };


    console.log(newData, "NWENWEMWENMWEMNWEMWEMNWEMNMWEMWE")





    const handleInputChange = (e, index, columnName) => {
        // When user types anything in the input field for filterin
        // The function handleInputChange() is triggered.

        // Get the newValue from event object e
        const newValue = e.target.value;

        // Update stateVariable at given index and columnName
        const updatedFilteredRecords = [...filteredRecord];
        updatedFilteredRecords[index][columnName] = newValue;

        // Store the newvalue in a state variable "data" by using the function setData
        setFilteredRecord(updatedFilteredRecords);
    };


    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "add",
            "comments": reason,
            "value": { PipelineName: newData.Pipelinename, DataFlowName: newData.DataFlowname, "Component/TableName": newData['ComponentName/TableName'], ComponentType: newData.ComponentType, Details: newData.Details, SourceScripts: newData.SourceScripts, TargetScripts: newData.TargetScripts, "Success/Failure": newData['Success/Failure'] }
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                Pipelinename: "",
                DataFlowname: "",
                "ComponentName/TableName": "",
                ComponentType: "",
                Details: "",
                SourceScripts: "",
                TargetScripts: "",
                "Success/Failure": ""
            })
            fetchPseudo()
            setReason("")
            setwhetherUpdated(true)
        }
    };


    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
                <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow"  style={{ width: '24px', height: '24px' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4"></div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">SNo<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SNo')} /></span></th>
                                                <th className="text-nowrap">PipelineName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineName')} /></span></th>
                                                <th className="text-nowrap">DataFlowName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DataFlowname" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DataFlowName')} /></span></th>
                                                <th className="text-nowrap">ComponentName/TableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ComponentName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ComponentName/TableName')} /></span></th>
                                                <th className="text-nowrap">ComponentType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ComponentType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ComponentType')} /></span></th>
                                                <th className="text-nowrap">Details<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Details" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Details')} /></span></th>
                                                <th className="text-nowrap">SourceScripts<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceScripts" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceScripts')} /></span></th>
                                                <th className="text-nowrap">TargetScritps<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TargetScripts" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TargetScripts')} /></span></th>
                                                <th className="text-nowrap">Success/Failure<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Success" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Success/Failure')} /></span></th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editableRow == "empty" ? (<tr>
                                                <td></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.Pipelinename} onChange={(e) => setNewData({ ...newData, Pipelinename: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DataFlowname} onChange={(e) => setNewData({ ...newData, DataFlowname: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.['ComponentName/TableName']} onChange={(e) => setNewData({ ...newData, "ComponentName/TableName": e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData?.ComponentType} onChange={(e) => setNewData({ ...newData, ComponentType: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Details' value={newData?.Details} onChange={(e) => setNewData({ ...newData, Details: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SourceScripts' value={newData?.SourceScripts} onChange={(e) => setNewData({ ...newData, SourceScripts: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='TargetScripts' value={newData?.TargetScripts} onChange={(e) => setNewData({ ...newData, TargetScripts: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Success/Failure' value={newData?.['Success/Failure']} onChange={(e) => setNewData({ ...newData, "Success/Failure": e.target.value })} /></td>
                                                <td class="text-center">
                                                    <img src="images/add-icons.svg" alt="add-icon" class="cust-cursor-pointer" title="Add" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("add"); document.getElementById('openReasson').click() } }} />
                                                </td>
                                            </tr>) : null}
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredRecord.length}</span></p>
                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitTestCaseADF;