/**
 * Pseudocode No: 
 * Import statements
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchDropDownValues, fetchPseudocodeData, updatePseudocodeData } from '../Service/Api.js'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import Select from 'react-select';
import FileSaver from 'file-saver';
import { HeaderCom } from './Header.jsx';
function PipelineSummaryDB(props) {

    /**
    * Pseudocode No:
    * State variable declaration
    */
    const [setVal, setSetVal] = useState([]);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        PipelineID: "",
        PipelineName: "",
        NotebookName: "",
        NotebookType: "",
        TableName: [],
        "DependentPipelineID's": []
    })
    const [action, setAction] = useState("")
    const [showFilter, setShowFilter] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const [filterCriteria, setFilterCriteria] = useState({

       TableName : 'Select',
       PipelineName: 'Select',
       NotebookName : 'Select',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [tableNames, setTableNames] = useState([])
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' })
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    // const nav = useNavigate();
    const { state } = useLocation();

    /**
     * Pseudocode No:
     * Call the fetchPseudo() in pageload to get the details from the API
     */
    useEffect(() => {
        fetchPseudo()
        fetchDropDown()
    }, [])

    useEffect(() => {
        // Initial setup: set filteredRecord to all records
        setFilteredRecord(setVal);
    }, [setVal]);

    /**
    * Pseudocode No:
    * The function fetchPseudo() is designed to handle fetching data asynchronously. When it's called, it first displays a 
    * loading spinner on the webpage to indicate that something is happening. Then, it prepares some data and sends it to 
    * another function called fetchPseudocode() located in a file named API.js. This function waits for a response from 
    * fetchPseudocode(). When it receives a response, it checks the status code: if it's 403, indicating a session expiration, 
    * it triggers a function called sessionExpired(). Otherwise, if the status code isn't 200, it opens an error modal on the 
    * webpage. But if the status code is 200, it processes the received data by parsing it and setting some values accordingly.
    */
    async function fetchPseudo() {
         
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "pipelineSummary",
            userId: state.userId,
            orgId: state.orgId,
        }

        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(res, "fetchRess", JSON.parse(res.data[0].pipelineSummary));
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            setSetVal(JSON.parse(res.data[0].pipelineSummary));
            setFilteredRecord(JSON.parse(res.data[0].pipelineSummary))
        }
    };

    /**
     * Pseudocode No:
     * The fetchDropDown function is an asynchronous function that retrieves data for a dropdown menu. 
     * It begins by displaying a loading spinner on the page. Then, it constructs a request body containing various parameters 
     * such as project ID, user ID, and organization ID. After that, it awaits the result of a function called fetchDropDownValues, 
     * passing in the constructed body and a JWT token.
     * Once the data is fetched, it hides the loading spinner and checks the response status code. If the status code is 403, 
     * it triggers a function called sessionExpired. If the status code is not 200, it opens an error modal. Otherwise, it 
     * extracts table names from the response data and sets them for further use.
     */
    async function fetchDropDown() {
         
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "pipelinesummarypseudocode",
            userId: state.userId,
            orgId: state.orgId,
        }

        const res = await fetchDropDownValues(body, state.jwt);

        console.log(res, "fetchRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            setTableNames(res.data.tableName);
        }
    };

    /**
     * Pseudocode No:
     * The sessionExpired function serves to handle actions upon the expiration of a user's session. 
     * When invoked, it promptly clears the local storage, ensuring any user-related data is removed 
     * from the browser. Subsequently, it triggers the display of a toast message on the webpage, 
     * signaling to the user that their session has expired. This visual cue fades out after three 
     * seconds, discreetly vanishing from view. Additionally, there's a commented-out line of code 
     * intended to navigate the user to the login page, although it's currently inactive.
     */
    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            // nav('/');
        }, 3000);
    };

    /**
    * Pseudocode No:
    * The bindGrid function is responsible for rendering the dataset onto the webpage. It first logs the filtered records to the console for debugging purposes. 
    * Then, it checks if the filteredRecord array is not empty and is indeed an array.
    * If there are records to display, it iterates over each record using the map function. For each record, it generates a table row (<tr>) with input fields for editing 
    * if the row is editable, or displays the record's data in table cells (<td>) if it's not editable. The input fields allow users to modify the data, and there are options 
    * to save or cancel the edits.
    * If there are no records to display, it renders a single table row with a message indicating that no records were found.
    * Any errors that occur during this process are caught, logged to the console, and a null value is returned to indicate a failure in rendering the grid.
    */
    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineID' value={newData?.PipelineID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineName' value={newData?.PipelineName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookName' value={newData?.NotebookName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookType' value={newData?.NotebookType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td>
    <Select
        isMulti
        name="tableNames"
        value={newData.TableName.map(a => ({ label: a, value: a }))}
        
        // Flatten and deduplicate tableNames for options
        options={[...new Set(setVal.flatMap(setVal => setVal.TableName))]
            .map(a => ({ label: a, value: a }))}
        
        className="font-14 font-regular"
        
        // Update newData when selection changes
        onChange={(e) => setNewData({ ...newData, TableName: e.map(a => a.value) })}
        
        classNamePrefix="select"
    />
</td>                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name="DependentPipelineID's" value={newData?.["DependentPipelineID's"]} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td class="text-center">
                                    <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty");
                                        setNewData({
                                            PipelineID: "",
                                            PipelineName: "",
                                            NotebookName: "",
                                            NotebookType: "",
                                            TableName: [],
                                            DependentPipelineIDs: ""
                                        })
                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{item.PipelineID || "NA"}</td>
                                <td>{item.PipelineName || "NA"}</td>
                                <td>{item.NotebookName || "NA"}</td>
                                <td>{item.NotebookType || "NA"}</td>
                                <td>
    <p className='mb-0'>
        {(() => {
            const tableNames = Array.isArray(item.TableName)
                ? item.TableName
                : typeof item.TableName === 'string'
                ? item.TableName.split(',').map(name => name.trim())
                : [];

            return tableNames.length > 0 ? (
                <>
                    {tableNames.slice(0, 3).join(',')}
                    {tableNames.length > 3 && (
                        <span
                            className="font-12 font-regular mb-0 primary-textcolor cursor-pointer num-tooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-original-title={tableNames.slice(3).join(',')}
                            title={tableNames.slice(3).join(',')}
                        >
                            {" +" + (tableNames.length - 3)}
                        </span>
                    )}
                </>
            ) : null;
        })()}
    </p>
</td>

                                <td>{Array.isArray(item["DependentPipelineID's"])
                                    ? item["DependentPipelineID's"].join(', ')
                                    : item["DependentPipelineID's"] || ""}</td>

                                <td className="text-center">
                                    <td class="text-center">

                                    <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" 
                                    onClick={() => { 
                                        setEditableRow(index); 
                                        setNewData({
                                        ...filteredRecord[index], 
                                        TableName: Array.isArray(filteredRecord[index]['TableName']) 
                                            ? filteredRecord[index]['TableName']
                                            : typeof filteredRecord[index]['TableName'] === 'string'
                                            ? filteredRecord[index]['TableName'].split(',')
                                            : []
                                        }) 
                                    }} 
                                    /> <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    /**
     * Pseudocode No:
     * The handleSearch function is designed to facilitate the search feature within a dataset. Upon invocation, 
     * it inspects the length of the provided search term. If the term contains at least three characters, the 
     * function proceeds to filter the dataset based on whether the operation type or cell name includes the 
     * search term, irrespective of case. The filtered data is then updated accordingly. Conversely, if the 
     * search term is less than three characters or empty, the function simply resets the filtered records to match the original dataset.
     */
   
const handleSearch = (term) => {
    console.log("Search term:", term);
    const searchTerm = term.trim().toLowerCase();
    console.log("Processed search term:", searchTerm);

    if (searchTerm.length >= 3) {
        const filteredData = setVal.filter(item =>
            Object.values(item).some(value => 
                value && typeof value === 'string' && value.toLowerCase().includes(searchTerm)
            )
        );
        console.log("Filtered data:", filteredData);
        setFilteredRecord(filteredData);
    } else {
        console.log("Search term too short, showing all records");
        setFilteredRecord(setVal);
    }
};



    /**
     * Pseudocode No:
     * The handleDownload function prepares and initiates the download of dataset information in Excel format. 
     * It first extracts specific attributes from the dataset, constructs an Excel sheet, and converts it into 
     * an Excel buffer. This buffer is then saved as an Excel file using FileSaver.js, enabling the user to 
     * download the file directly.
     */
  
    const handleDownload = () => {
        let pilineArray = setVal.map(data => {
            return {
                PipelineID: data.PipelineID,
                PipelineName: data.PipelineName,
                NotebookName: data.NotebookName,
                NotebookType: data.NotebookType,
                TableName: Array.isArray(data.TableName) ? data.TableName.join(', ') : data.TableName,
                "DependentPipelineID's": Array.isArray(data["DependentPipelineID's"]) 
                ? data["DependentPipelineID's"].join(', ') 
                : data["DependentPipelineID's"]        };
        });
    
        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        // Convert pilineArray to sheet
        const ws = XLSX.utils.json_to_sheet(pilineArray);
    
        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'PipelineSummary');
    
        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Pipeline Summary' + fileExtension);
    
        // The saved file will be shown to the user
    };

    /**
     * Pseudocode No:
     * The handleSort function facilitates sorting functionality for table columns. When triggered with a column name parameter, 
     * it first checks if the clicked column is the same as the previously sorted one. If so, it toggles the sorting order;
     * otherwise, it sets the order to ascending.
     * Next, it updates the sorting order state (sortOrder) with the new column and order. Then, it creates a copy of 
     * the filtered records and sorts them based on the selected column and order using the localeCompare method for
     * string comparison. Finally, it updates the state of filtered records with the sorted list.
     */
    const handleSort = (columnName) => {
        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecord and sort it
        const sortedRecords = [...filteredRecord].sort((a, b) => {
            const aValue = a[columnName];
            const bValue = b[columnName];

            // Handle null or undefined values
            if (aValue == null) return newSortOrder.order === 'asc' ? -1 : 1;
            if (bValue == null) return newSortOrder.order === 'asc' ? 1 : -1;

            // Compare strings case-insensitively
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return newSortOrder.order === 'asc'
                    ? aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
                    : bValue.localeCompare(aValue, undefined, { sensitivity: 'base' });
            }

            // For non-string values, use simple comparison
            return newSortOrder.order === 'asc'
                ? (aValue > bValue ? 1 : -1)
                : (bValue > aValue ? 1 : -1);
        });

        // Update the filteredRecord state with the sorted list
        setFilteredRecord(sortedRecords);
    };
    /**
     * Pseudocode No:
     * The handleDelete function is responsible for deleting a record from the dataset. Upon invocation, it first displays a loading 
     * spinner to indicate ongoing processing. It then constructs a request body containing necessary data for the deletion operation, 
     * such as project ID, user ID, and action type.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token 
     * for authentication. Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), 
     * it triggers a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. 
     * Otherwise, upon successful deletion, it resets the data for the deleted record, clears the reason for deletion, and updates the dataset with the new data.
     */
    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block";

        // Find the index of the item to delete in the original setVal array
        const originalIndex = setVal.findIndex(item => item.PipelineID === filteredRecord[deleteRow]?.PipelineID);

        const body = {
            projectId: state.projectId,
            type: "pipelineSummary",
            userId: state.userId,
            orgId: state.orgId,
            actionType: "delete",
            comments: reason,
            index: originalIndex, // Use the index in the original dataset
        };

        try {
            const res = await updatePseudocodeData(body, state.jwt);

            console.log(res, "updateRess");

            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                setNewData({
                    PipelineID: "",
                    PipelineName: "",
                    NotebookName: "",
                    NotebookType: "",
                    TableName: [],
                    "DependentPipelineID's": []
                });
                setReason("");
                setEditableRow("empty");

                // Update the dataset and filtered records
                const updatedData = JSON.parse(res.data.recordset[0]?.pipelineSummary);
                setSetVal(updatedData);
                setFilteredRecord(updatedData.filter(item =>
                    item.PipelineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.NotebookName.toLowerCase().includes(searchTerm.toLowerCase())
                ));
                setwhetherUpdated(true);
            }
        } catch (error) {
            console.error("Error during delete operation:", error);
            document.getElementById('openErrorModal').click();
        } finally {
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    const handleEdit = async () => {
         
        document.getElementById('pageLoader').style.display = "block";
        
        // Find the index of the item to edit in the original setVal array
        const originalIndex = setVal.findIndex(item => item.PipelineID === filteredRecord[editableRow]?.PipelineID);

        const body = {
            projectId: state.projectId,
            type: "pipelineSummary",
            userId: state.userId,
            orgId: state.orgId,
            actionType: "edit",
            comments: reason,
            value: { ...newData, TableName: newData.TableName },
            index: originalIndex, // Use the index in the original dataset
        };

        try {
            const res = await updatePseudocodeData(body, state.jwt);

            console.log(res, "updateRess");

            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                setNewData({
                    PipelineID: "",
                    PipelineName: "",
                    NotebookName: "",
                    NotebookType: "",
                    TableName: [],
                    "DependentPipelineID's": []
                });
                setReason("");
                setEditableRow("empty");

                // Update the dataset and filtered records
                const updatedData = JSON.parse(res.data.recordset[0]?.pipelineSummary);
                setSetVal(updatedData);
                setFilteredRecord(updatedData.filter(item =>
                    item.PipelineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.NotebookName.toLowerCase().includes(searchTerm.toLowerCase())
                ));
                setwhetherUpdated(true);
            }
        } catch (error) {
            console.error("Error during edit operation:", error);
            document.getElementById('openErrorModal').click();
        } finally {
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block";

        const body = {
            projectId: state.projectId,
            type: "pipelineSummary",
            userId: state.userId,
            orgId: state.orgId,
            actionType: "add",
            comments: reason,
            value: { ...newData, TableName: newData.TableName },
        };

        try {
            const res = await updatePseudocodeData(body, state.jwt);

            console.log(res, "updateRess");

            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                setNewData({
                    PipelineID: "",
                    PipelineName: "",
                    NotebookName: "",
                    NotebookType: "",
                    TableName: [],
                    "DependentPipelineID's": []
                });
                setReason("");

                // Update the dataset and filtered records
                const updatedData = JSON.parse(res.data.recordset[0]?.pipelineSummary);
                setSetVal(updatedData);
                setFilteredRecord(updatedData.filter(item =>
                    item.PipelineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.NotebookName.toLowerCase().includes(searchTerm.toLowerCase())
                ));
                setwhetherUpdated(true);
            }
        } catch (error) {
            console.error("Error during add operation:", error);
            document.getElementById('openErrorModal').click();
        } finally {
            document.getElementById('pageLoader').style.display = "none";
        }
    };


    // const handleApplyFilter = () => {
    //     console.log("Filter Criteria:", filterCriteria);

    //     const filterList = setVal.filter(record => {
    //         console.log("Record:", record);

    //         const NotebookNameMatch = filterCriteria.NotebookName === 'Select' || record.NotebookName === filterCriteria.NotebookName;

    //         const TableNameMatch = filterCriteria.TableName === 'Select' || record.TableName === filterCriteria.TableName;

    //          const pipelineNameMatch = filterCriteria.PipelineName === 'Select' || record.PipelineName === filterCriteria.PipelineName;

    //         return NotebookNameMatch && TableNameMatch && pipelineNameMatch;
    //     });

    //     console.log("Filtered List:", filterList);

    //     setFilteredRecord(filterList);
    //     setIsFilterApplied(true);
    // };
    const handleApplyFilter = () => {
        console.log("Filter Criteria:", filterCriteria);
    
        const normalizeToArray = (value) => Array.isArray(value)
            ? value
            : typeof value === 'string'
            ? value.split(',').map(item => item.trim())
            : [];
    
        const filterList = setVal.filter(record => {
            console.log("Record being checked:", record);
    
            const NotebookNameMatch = filterCriteria.NotebookName === 'Select' || record.NotebookName === filterCriteria.NotebookName;
    
            // Normalize TableNames
            const normalizedFilterTableNames = normalizeToArray(filterCriteria.TableName);
            const normalizedRecordTableNames = normalizeToArray(record.TableName);
            
            // Check if any table name from filter criteria is present in record's table names
            const TableNameMatch = filterCriteria.TableName === 'Select' || 
                normalizedFilterTableNames.some(name => normalizedRecordTableNames.includes(name));
    
            const pipelineNameMatch = filterCriteria.PipelineName === 'Select' || record.PipelineName === filterCriteria.PipelineName;
    
            console.log(`Matches - Notebook: ${NotebookNameMatch}, Table: ${TableNameMatch}, Pipeline: ${pipelineNameMatch}`);
    
            return NotebookNameMatch && TableNameMatch && pipelineNameMatch;
        });
    
        console.log("Filtered List:", filterList);
    
        setFilteredRecord(filterList);
        setIsFilterApplied(true);
    };
    
    const handleClearFilter = () => {
        console.log("Clearing Filter");
        
        // Clear filter criteria
        setFilterCriteria({  TableName : 'Select', PipelineName : 'Select', NotebookName : 'Select' });
    
        // Reset the filtered records to the original action logs
        setFilteredRecord(setVal);
    
        // Indicate that no filter is applied
        setIsFilterApplied(false);
    
        // Close the filter dropdown
        setShowFilter(false);
    
    };

    function BindTableName() {
        if (setVal.length > 0) {
            // Use Set to filter out unique organization names
            const uniqueOrgNames = [...new Set(setVal.map(setVal => setVal.TableName))];
            return uniqueOrgNames.map((TableName, index) => (
                <option key={index} value={TableName}>{TableName}</option>
            ));
        }
    }
    function BindPipelineName() {
        if (setVal.length > 0) {
            // Use Set to filter out unique organization names
            const uniqueOrgNames = [...new Set(setVal.map(setVal => setVal.PipelineName))];
            return uniqueOrgNames.map((PipelineName, index) => (
                <option key={index} value={PipelineName}>{PipelineName}</option>
            ));
        }
    }
    function BindNoteBookName() {
        if (setVal.length > 0) {
            // Use Set to filter out unique organization names
            const uniqueOrgNames = [...new Set(setVal.map(setVal => setVal.NotebookName))];
            return uniqueOrgNames.map((NotebookName, index) => (
                <option key={index} value={NotebookName}>{NotebookName}</option>
            ));
        }
    }



    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
                <HeaderCom value='1' />
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor: "pointer", whiteSpace: 'nowrap' }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} /></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                        </div>
                        <div class="d-flex">
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const newTerm = e.target.value;
                                        setSearchTerm(newTerm);
                                        handleSearch(newTerm);
                                    }}
                                />
                                <span className="search-icon-sty">
                                    <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                </span>
                            </div>
{/* filter starts hereeeeeeeeee */}
<div className="btn-group me-3">
                                    <button
                                        className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => setShowFilter(true)}
                                    >
                                        <img
                                            src="images/filter-icon.svg"
                                            alt="filter-icon"
                                            className="filt-icon"
                                        />
                                    </button>
                                    <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3  ${showFilter == true ? 'show' : ''}`} style={{ transform: 'translate(-353px, 42px) !important;', width: '466px', float: 'right' }}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0" >
                                                    Filter
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    // data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => setShowFilter(false)}
                                                />
                                            </div>
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-14 font-medium">
                                                        Notebook Name
                                                        </label>
                                                       
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.NotebookName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, NotebookName: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor: 'pointer' }}>Select</option>
                                                            {BindNoteBookName()}
                                                        </select>
                                                        
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="pipelineName"
                                                            className="form-label text-black font-14 font-medium">
                                                            Pipeline Name
                                                        </label>
                                                       
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.PipelineName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, PipelineName: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor: 'pointer' }}>Select</option>
                                                            {BindPipelineName()}
                                                        </select>
                                                    </div>
                                                </div>
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-14 font-medium">
                                                        Table Name
                                                        </label>
                                                       
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.TableName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, TableName: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor: 'pointer' }}>Select</option>
                                                            {BindTableName()}
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                      


                                            </div>

                                            <div className="text-end mt-4 mb-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                    onClick={() => { handleClearFilter() }}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={filterCriteria.TableName == 'Select' && filterCriteria.NotebookName == 'Select' && filterCriteria.PipelineName == 'Select' ? true : false}
                                                    className="btn cust-primary-btn font-14 font-medium px-4"
                                                    onClick={() => { handleApplyFilter() }}

                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>






                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">PipelineID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineID')} /></span></th>
                                                <th className="text-nowrap">PipelineName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineName')} /></span></th>
                                                <th className="text-nowrap">NotebookName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "NotebookName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('NotebookName')} /></span></th>
                                                <th className="text-nowrap">NotebookType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "NotebookType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('NotebookType')} /></span></th>
                                                <th className="text-nowrap">TableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TableName')} /></span></th>
                                                <th className="text-nowrap">DependentPipelineIDs<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DependentPipelineID's" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort("DependentPipelineID's")} /></span></th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editableRow == "empty" ? <tr>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineID' value={newData?.PipelineID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineName' value={newData?.PipelineName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookName' value={newData?.NotebookName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookType' value={newData?.NotebookType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td>
    <Select
        isMulti
        name="tableNames"
        value={newData.TableName.map(a => ({ label: a, value: a }))}
        
        // Flatten and deduplicate tableNames for options
        options={[...new Set(setVal.flatMap(setVal => setVal.TableName))]
            .map(a => ({ label: a, value: a }))}
        
        className="font-14 font-regular"
        
        // Update newData when selection changes
        onChange={(e) => setNewData({ ...newData, TableName: e.map(a => a.value) })}
        
        classNamePrefix="select"
    />
</td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name="DependentPipelineID's" value={newData?.["DependentPipelineID's"]} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td className="text-center">
                                                    <img
                                                        src="images/add-icons.svg"
                                                        alt="add-icon"
                                                        className={`cust-cursor-pointer ${Object.values(newData).every(value => value !== '') ? '' : 'disabled'}`}
                                                        title="Add"
                                                        onClick={(e) => {
                                                            if (Object.values(newData).every(value => value !== '')) {
                                                                setAction("add");
                                                                document.getElementById('openReasson').click();
                                                            } else {
                                                                e.currentTarget.style.opacity = '0.3';
                                                                e.currentTarget.style.cursor = 'not-allowed';
                                                            }
                                                        }}
                                                        style={{
                                                            opacity: Object.values(newData).every(value => value !== '') ? 1 : 0.3,
                                                            cursor: Object.values(newData).every(value => value !== '') ? 'pointer' : 'not-allowed'
                                                        }}
                                                    />
                                                </td>
                                            </tr> : <></>}
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3">
                                    # of Records: <span className="text-black font-medium">
                                        {setVal.length} out of {setVal.length}
                                     </span></p>                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" onClick={() => setReason("")} />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal" onClick={() => setReason("")}>
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PipelineSummaryDB;