import React, { useState, useRef } from 'react';
import JSZip from 'jszip';
import { useLocation } from 'react-router-dom';
import { PostUCMigrationUploads } from '../../Service/Api';

const FileUploaderComponent = (props) => {
    const [fileList, setFileList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const fileInputRef = useRef(null);
    var { state } = useLocation()

    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

    const generateSasToken = async () => {
        const response = await fetch('http://localhost:3001/generate-sas');
        if (!response.ok) throw new Error('Failed to fetch SAS token');
        const { sasToken } = await response.json();
        console.warn('SAS token generation should be done server-side');
        return sasToken;
    };

    const putJsonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
        try {
            const sasToken = await generateSasToken();
            const blobPath = `${orgName}/${projectName}/${phase}/${fileName}`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            const returnUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob'
                },
                body: JSON.stringify(jsonData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log(`JSON data uploaded successfully to ${blobPath}`);

            return returnUrl;

        } catch (error) {
            console.error('Error putting JSON to blob:', error);
            throw error;
        }
    };

    const handleFileSelection = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        await processFiles(selectedFiles);
    };

    const processFiles = async (files) => {
        for (const file of files) {
            if (file.name.endsWith('.zip')) {
                const zip = new JSZip();
                const zipContents = await zip.loadAsync(file);
                for (const [relativePath, zipEntry] of Object.entries(zipContents.files)) {
                    if (!zipEntry.dir && validateFileExtension(zipEntry.name)) {
                        const blob = await zipEntry.async('blob');
                        const newFile = new File([blob], zipEntry.name);
                        if (validateFileSize(newFile)) {
                            setFileList(prevList => [...prevList, newFile]);
                        }
                    }
                }
            } else if (validateFile(file)) {
                setFileList(prevList => [...prevList, file]);
            }
        }
    };

    const validateFileExtension = (fileName) => {
        const validExtensions = ['.py', '.dbc', '.ipynb', '.sql'];
        return validExtensions.some(ext => fileName.endsWith(ext));
    };

    const validateFileSize = (file) => {
        const maxSize = 10 * 1024 * 1024; // 10MB
        return file.size <= maxSize;
    };

    const validateFile = (file) => {
        if (validateFileExtension(file.name) && validateFileSize(file)) {
            return true;
        } else {
            setError('Invalid file type or size');
            return false;
        }
    };

    const handleSave = async () => {
        setIsUploading(true);
        document.getElementById('pageLoader').style.display = "block";

        setError('');

        try {
            const uploadedFiles = [];

            for (const file of fileList) {
                const fileContent = await file.text();
                const fileName = `notebooks/${file.name}`;
                const url = await putJsonToBlob(state.orgId, state.projectId, fileContent, fileName, 'define');
                uploadedFiles.push({ name: file.name, url: url });
            }


            let object = {
                phase: "Define",
                notebooks: uploadedFiles,
                userId: state.userId,
                projectId: state.projectId,
                orgName: state.orgName,
                projectName: state.projectName,
                jwt_token: state.jwt,
                type: "existingNotebookUpload"
            }

            console.log(object, 'objectobjectobjectobject');

            const response = await PostUCMigrationUploads(object, state.jwt);

            console.log(response, 'responseresponseresponse');

            if (response.status !== 200) {
                alert('Files uploaded successfully');
                props.handleExistingNotebook()

            }
            props.close()
            document.getElementById('pageLoader').style.display = "none";

            console.log('Files uploaded successfully', uploadedFiles);


            // setFileList([]);
            setIsUploading(false);
        } catch (err) {
            console.error('Error saving files', err);
            setError('Error saving files: ' + err.message);
            setIsUploading(false);
        }
    };

    const handleDeleteFile = (fileToDelete) => {
        setFileList(prevList => prevList.filter(file => file !== fileToDelete));
    };

    const handleCancelUpload = () => {
        setFileList([]);
        setIsUploading(false);
        setError('');
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = Array.from(event.dataTransfer.files);
        await processFiles(droppedFiles);
    };

    const filteredFiles = fileList.filter(file =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div
            className="modal fade"
            id="Notebook"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="NotebookLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content px-2">
                    <div className="modal-header border-0 px-4 pe-5">
                        <h5 className="modal-title d-flex align-items-center font-medium font-20" id="SourceSysLabel">
                            Upload Existing Notebook</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body py-0 my-4">
                        <div className="row px-4">
                            <div className="col-md-12 col-sm-12 mb-3">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <label className="form-label text-black font-14 font-medium">Upload Data<span className="text-red">*</span></label>
                                    <button type="button" className="btn cust-secondary-btn font-16 font-medium d-flex" data-bs-dismiss="modal">
                                        <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download SOP
                                    </button>
                                </div>
                                <span className="d-block font-12 info-message-section text-black font-medium">Kindly upload document in .py, .dbc, .ipynb, .zip and .sql</span>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="mb-3">
                                    <span className="upload-btn-wrapper d-block">
                                        <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center"
                                            onClick={() => fileInputRef.current.click()}
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}>
                                            <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                            <span className="font-medium font-13 d-block text-grey">Browse or drag and drop file</span>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            className="cursor-pointer cust-file"
                                            id="OrganizationLogo"
                                            onChange={handleFileSelection}
                                            multiple
                                        />
                                    </span>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="input-group position-relative search-wid mb-3">
                                        <input
                                            type="search"
                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <span className="search-icon-sty"><a href="#"><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                    </div>
                                </div>
                                <div className="table-responsive custom-scroll file-table mb-2">
                                    <table className="table table-borderless rounded custom-grid">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-start">
                                                    <input className="form-check-input custom-checkbox mt-0 me-2" type="checkbox" value="" id="selectAll" />
                                                    <label className="font-14 font-regular" htmlFor="selectAll">File Name</label>
                                                </th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredFiles.map((file, index) => (
                                                <tr key={index}>
                                                    <td className="text-start">
                                                        <input className="form-check-input custom-checkbox mt-0 me-2" type="checkbox" value="" id={`file-${index}`} />
                                                        <label className="font-14 font-regular" htmlFor={`file-${index}`}>{file.name}</label>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" className="border-0 p-0 bg-white" onClick={() => handleDeleteFile(file)}>
                                                            <img src="images/delete-icon.svg" alt="delete-icon" className="cust-cursor-pointer" title="Delete" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <p className="font-regular font-14 text-grey">Uploaded Data: <span className="text-black font-medium">{fileList.length}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                        <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" onClick={handleCancelUpload} data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" onClick={handleSave} disabled={isUploading}>
                            <span>{isUploading ? 'Saving...' : 'Save'}</span>
                        </button>
                    </div>
                    <div
                        className="overlay"
                        id="pageLoader"
                        style={{ display: "none" }}
                    >
                        <div className="position-absolute top-50 start-50  translate-middle">
                            <div className="d-flex align-items-center loader-bg">
                                <div
                                    className="spinner-border Loader text-dark align-center"
                                    role="status"
                                >
                                    <span className="visually-hidden"></span>
                                </div>
                                <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

export default FileUploaderComponent;