import { useEffect, useState } from "react";
/*PS_CG_1.1*/
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment/moment.js";
import { saveAs } from 'file-saver';
import { cloudonprem, scripts, type1, type2, type3 } from "./Contrains.js";
import { tablePathSave } from "../Service/Api.js";
function TablePathPopup(props) {

    const { state } = useLocation();
    const nav = useNavigate();

    if (state == null) {
        window.location.href = "/projectInfo";
    }
    console.log('hiiiiiiiiiiiiiiiii');
    const [tablePathDetails, setTablePathDetails] = useState(props.pathDetails);

    useEffect(() => {
        setTablePathDetails(props?.pathDetails)
    }, [])

    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            nav("/");
        }, 3000);
    };

    const updateInputData = (name, value) => {

        setTablePathDetails({ ...tablePathDetails, [name]: value });
        console.log(tablePathDetails, '678u9i');
    }

    // PS_01 to PS_33 of the table path popup
    const defineDataSave = async (type) => {
        props.close()
        console.log(tablePathDetails, 'upcoming678u9i');


        const object = {
            userId: state.userId,
            projectId: state.projectId,
            orgId: state.orgId,
            type: type,
            jwt: state.jwt,
            basePath: tablePathDetails.basePath,
            comments: tablePathDetails.comments
        }
        console.log(object, 'objectobjectobjectobjectobject');

        try {
            let response = await tablePathSave(object, state.jwt);
            if (response.status === 403) {
                sessionExpired();
                return;
            } else if (response.statusCode !== 200) {
            } else {
                if (type === 'saveBasePath') {
                    props.onChange(tablePathDetails);
                    // props.checkPseudocodeLooksGood('tablePathPopup')
                } else {
                    // if (response.data?.defineDetails[0].basePath !== null || response.data?.defineDetails.comments !== null) {
                    //     setFetchedPath(true)
                    //     setTablePathDetails(...tablePathDetails, response.data?.defineDetails[0].basePath, response.data?.defineDetails[0].comments);
                    // } else {
                    //     setFetchedPath(false)
                    //     setTablePathDetails({
                    //         basePath: null,
                    //         comments: null
                    //     })
                    // }
                }

            }
            // if (type === 'saveBasePath') {
            //     setShowTablePathPopup(false)
            //     // checkPseudocodeLooksGood('tablePathPopup')
            // }
        } catch (error) {
            console.error(error, 'errorrrrrrrrrrrrrrrrrrrrrr');

        } finally {
            document.getElementById('pageLoader').style.display = "none"
            props.close()
        }

    }


    return (
        <div className="container-fluid">
            <div
                className="overlay"
                id="pageLoader"
                style={{ display: "none" }}
            >
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center"
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="modal-backdrop fade show"></div>
                <div className="modal fade show" style={{ display: "block", paddingLeft: 0 }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content px-2">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="SourceSysLabel"
                                >
                                    Table Path Info
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => {
                                        props.close()
                                        setTablePathDetails({
                                            ...tablePathDetails, comments: null, basePath: null
                                        });
                                    }}
                                />
                            </div>
                            <div className="modal-body py-0 my-3">
                                <div className="rounded-3  font-20  font-bold">
                                    <div className="row pb-3 mb-3 pt-3">
                                        <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                            <label
                                                htmlFor="connection-Name"
                                                className="form-label text-black font-14 font-medium"
                                            >
                                                Base Path<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                id="connection-Name"
                                                placeholder="Enter Base Path"
                                                value={tablePathDetails?.basePath}
                                                onChange={(e) => updateInputData('basePath', e.target.value)}
                                                readOnly={props?.pathDetails !== ''}
                                            />
                                            {console.log(tablePathDetails, 'tablePathDetailstablePathDetailstablePathDetails')}
                                        </div>
                                        <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                            <label
                                                htmlFor="host-Name"
                                                className="form-label text-black font-14 font-medium"
                                            >
                                                Comments<span className="text-red">*</span>
                                            </label>
                                            {console.log(props.pathDetails, 'pathDetails')}
                                            <input
                                                type="url"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                id="host-Name"
                                                placeholder="Enter comments"
                                                value={tablePathDetails?.comments}
                                                onChange={(e) => updateInputData('comments', e.target.value)}
                                                readOnly={props?.pathDetails !== ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                                    <button
                                        type="button"
                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                        onClick={() => {
                                            props.close()
                                            setTablePathDetails({
                                                ...tablePathDetails, comments: '', basePath: ''
                                            });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn cust-primary-btn font-14 font-medium"
                                        onClick={() => defineDataSave('saveBasePath')}
                                        disabled={tablePathDetails?.comments === '' || tablePathDetails?.basePath === '' || props?.pathDetails !== ''}
                                    >
                                        <span>Save Details</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )

}
export default TablePathPopup;