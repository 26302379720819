import { useEffect, useState, useCallback, useRef } from "react";
import Editor, { loader } from "@monaco-editor/react";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import PlantUML from "react-plantuml";
//importing APIs
import {
    getChatHistory,
    getSourceSystemConnectioninfo,
    postChatHistory,
    projectsDropDown,
    getCodePageDataApi,
    deployNotebook,
    fetchPseudocodeData,
    deleteDBRecords,
    fetchDesignDetails
} from "../Service/Api";
import {
    generateExtractionRequirementData,
    generateSourceToExtractionER,
    generateBronzeToSilverER,
    generateSilverToGoldER,
    generateDataModelSilverUML,
    generateDataModelGoldUML,
    generateDataDictionaryPseudocode,
    generateBronzeSequence,
    generateSilverSequence,
    generateGoldSequence,
    generateBronze,
    generateChecklistDev,
    generateDDL,
    generateGoldDevCode,
    generateLoggerAndPipelineJSON,
    generateMetadata,
    generateSilver,
    generateUtility,
    generateMetadataTablePseudo,
    generateDDLPseudocode,
    generatePipelineSummaryPseudo,
    generateOrchestrationUMLCode,
    generateLoggerPseudocode,
    generateUtilityPseudocode,
    generateExtractionPseudocode,
    generateTransformationPseudocode,
    generateGoldPseudocode,
    generateOrchestrationDocument,
    generateUnitTestCasePseudocode,
    generateDataQualityPseudo,
    generateChecklistDesign,
    generateTestCaseCode,
    genrateDQualityCode 
} from "../Service/Api";
import MetadataNotebook from "./MetadataNotebook";
import BronzeNotebook from "./BronzeNotebook";
import SilverNotebook from "./SilverNotebook";
import GoldNotebook from "./GoldNotebook";
import DDLScripts from "./DDLScripts";
import LoggerNotebook from "./LoggerNotebook";
import Utility from "./Utility";
import Orchestraion from "./OrchestrationPipeline";
import { useLocation, useNavigate } from "react-router-dom";
import DDLScriptPseudo from './DDLScriptPseudoBronze'
import LoggerGridPseudocode from "./LoggerGridPseudocode";
import Utilities from "./UtilityGridPseudocode";
import LinkedServicePseudo from "./LinkedServicePseudo";
import CodeReviewChecklistDev from "./CodeReviewChecklistDev";
import DataQualityPage from "./DataQualityPage";
import DataQualitySP from './DataQualitySP'
import DataQualityPipeline from "./DataQualityPipeline";
import DataQualityDB from "./DataQulaityDB";
import UnitTestCaseSnowFlakes from "./UnitTestCaseSnowFlakes";
import UnitTestCaseADF from "./UnitTestCaseADF";
import UnitTestCaseGridDB from "./UnitTestCaseGridDB";
import UnitTestCaseCodePage from "./UnitTestCaseCode"
import DataFlowPseudo from "./DataFlowPseudo";
import GoldStoredProcedure from "./GoldStoredProcedure";
import GoldPipeline from "./GoldPipeline";
import GoldDataBricks from "./GoldDataBricks";
import SilverStoredProcedure from "./SilverStoredProcedure";
import SilverPipeline from "./SilverPipeline";
import SilverDataBricks from "./SilverDataBricks";
import ExtractionStoredProcedure from "./ExtractionStoredProcedure";
import BronzePipeline from "./BronzePipeline";
import ExtractionDataBricks from "./ExtractionDataBricks";
import OrchestrationDiagram from "./OrchestrationDiagram";
import PipelineSummaryDB from "./PipelineSummaryDB";
import MetadataTable from "./MetadataTablePseudo";
import MetaDataGridDev from "./metaGrid";
import CodeReviewChecklistDesignDB from "./CodeReviewChecklistDesignDB";
import DataDictionarySilver from "./DataDictionarySilver";
import DataDictionaryGold from "./DataDictionaryGold";
import ExtractionDBSequence from "./ExtractionDBSequence";
import SilverDBSequence from "./SilverDBSequence";
import GoldDBSequence from "./GoldDBSequence";
import SourceToExtractionER from "./SourceToExtractionER";
import BronzeToSilverER from "./BronzeToSilverER";
import SilverToGoldER from "./SilverToGoldER";
import DataModelGold from "./DataModelGold";
import DataModelSilver from "./DataModelSilver";
import ExtractionReqGrid from "./ExtractionReqGrid"
import DDLScriptPseudoBronze from "./DDLScriptPseudoBronze"
import DDLScriptPseudoSilver from "./DDLScriptPseudoSilver"
import DDLScriptPseudoGold from "./DDLScriptPseudoGold"
import ColumnMappingRework from "./columnMapping";
import OrchestrationPseudo from "./OrchestrationPseudo";
import TableMappingRework from "./TableMappingRework";
import DatasetPseudo from "./DatasetPseudo";
import CodeReviewChecklistDesignRS from "./CodeReviewChecklistDesignRS";
import CodeReviewChecklistDesignSY from "./CodeReviewChecklistDesignSY";

import { HeaderCom } from "./Header";
import DesignPopup from "./DesignDocument";
import DefinePop from "./DefinePopup";
import TableReqPop from "./TableReqPop"
import SilverZonePopup from "./SilverZoneReqPopup"
import GoldZonePopup from "./GoldZoneReqPopup"
import MetaDataTable from "./metaGrid";
import { decode } from "base-64";
import { fetchJsonFromBlob, putJsonToBlob } from "./new/BlobStorageService";
import SourceConnectionPopup from "./chatImplementSourceAndConnectionPopup";
import DeployPopup from "./chatDeployPopup";
import TablePathPopup from "./chatTablePathPopup";

//log
//PS_CM_01 - PS_CM_1964
function ChatMaintenance() {
    const [chatHistory, setChatHistory] = useState([]);
    const [sourceSystems, setSourceSystems] = useState([]);
    const [deployStatus, setDeployStatus] = useState(true);
    const [showDesignPopup, setShowDesignPopup] = useState(false);
    const [showDefinePopup, setShowDefinePopup] = useState(false);
    const [showtableReqPopup, setShowtableReqPopup] = useState(false);
    const [showsilverERPopup, setShowsilverERPopup] = useState(false);
    const [showgoldERPopup, setShowgoldERPopup] = useState(false);
    const [codeType, setCodeType] = useState("");
    const [defineDetails, setDefineDetails] = useState({});
    const [tableReqDetails, setTableReqDetails] = useState({});
    const [silverReqDetails, setSilverReqDetails] = useState({});
    const [goldReqDetails, setGoldReqDetails] = useState({});
    const [showSourcePopup, setShowSourcePopup] = useState(false);

    const [showDeployPopup, setShowDeployPopup] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const chatContainerRef = useRef(null);

    const [showLoader, setLoader] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [sourceSystemDetails, setSourceSystemDetails] = useState([]);
    const [disableSourceFields, setDisableSourceFields] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [showLooksGood, setShowlooksGood] = useState(false);
    const [codeDataValues, setCodeDataValues] = useState({});
    const [pipelineSummaryData, setPipelineSummaryData] = useState([]);
    const [extractionData, setExtractionData] = useState([]);
    const [extractionSeqData, setExtractionSeqData] = useState([]);
    const [transformationSeqData, setTransformationSeqData] = useState([]);
    const [transformationData, setTransformationData] = useState([]);
    const [goldData, setGoldData] = useState([]);
    const [orchestrationData, setOrchestrationData] = useState([]);
    const [dataflowData, setDataflowData] = useState([]);
    const [isEditNotebook, setIsEditNotebook] = useState({});
    const nav = useNavigate();
    const [designDetails, setDesignDetails] = useState({});
    const [designObj, setDesignObj] = useState({});
    const [tablePathDetails, setTablePathDetails] = useState('')
    const [popupPhase, setPopupPhase] = useState('')
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)


    const { state } = useLocation();
    console.log(state);
    let index = 0;

    let targetInfo = {
        userName: {
            userName: "",
            comments: "",
            createdBy: "",
        },
        systemUrl: "",
        accessToken: "",
    };

    // const [targetSystemDetails, setTargetSystemDetails] = useState(targetInfo);

    const [targetSystemDetails, setTargetSystemDetails] = useState({});
    const [dataUML, setDataUML] = useState("");
    const [orchUML, setOrchUML] = useState("");
    const [downloadUml, setDownloadUml] = useState(false)
    const [downloadOrgUML, setDownloadOrgUML] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const matchesRef = useRef([]);



    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            nav("/");
        }, 3000);
    };

    const downloadImage = async (imageUrl, fileName) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };


    useEffect(() => {

        // Extract the phase from chatHistory
        const userChat = chatHistory.find(item => item.flow === 'user' && item.phase);
        if (userChat) {
            setSourcePhase(userChat.phase);
        }
        console.log(chatHistory, sourcePhase, 'chatthissssssourcePhase');
    }, [chatHistory]);

    useEffect(() => {
        if (dataUML !== "") {
            const imgElement = document.getElementById('daimage111').getElementsByTagName('img')[0];
            const srcValue = imgElement.getAttribute('src');
            console.log(srcValue, "srcValue");
            downloadImage(srcValue, `${downloadUml}.svg`); 
        }
    }, [downloadUml]);

    useEffect(() => {
        if (orchUML !== "") {
            const imgElement = document.getElementById('daorgimage').getElementsByTagName('img')[0];
            const srcValue = imgElement.getAttribute('src');
            console.log(srcValue, "srcValue");
            downloadImage(srcValue, `${downloadOrgUML}.svg`);
        }
    }, [downloadOrgUML]);

    // PS_CD_1.5  
    const [showTablePathPopup, setShowTablePathPopup] = useState(false);
    const [designData, setDesignData] = useState("");

    const [sourcePhase, setSourcePhase] = useState('')


    // let sourcePhase ;
    useEffect(() => {
       

        let newPro = sessionStorage.getItem("newPro");
        if (newPro === "true") {
            sessionStorage.removeItem("newPro");
            document.getElementById("protoastMessage").style.display = "block";
            setTimeout(() => {
                document.getElementById("protoastMessage").style.display = "none";
            }, 3000);
        } else {
            state.isEdit = true;
        }
        fetchDropdown();
    }, []);

    /**
     * PS_CD_1.13 -30
     */
    async function fetchDropdown() {
        document.getElementById("pageLoader").style.display = "block";
        let response = await projectsDropDown(state.userId, state.jwt);
        fetchChatHistory();
        if (response.status !== 200) {
            return;
        }
        console.log(response);
       
        setSourceSystems(
            response?.data[9]?.filter(
                (data) =>
                    data.sourceSystemNameId !== "850BB243-E292-4417-9F78-02E7BCF55BBB" &&
                    data.sourceSystemNameId !== "389144DD-76A0-4120-9D22-43C861875690" &&
                    data.sourceSystemNameId !== "E1F3EA06-98FB-4AC9-BAA2-A0869ACF0F68"
            )
        );
    }



    function goDown() {
        const intervalId = setInterval(() => {
            const divElement = document.getElementById("Imdown");
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
                clearInterval(intervalId); // Stop checking after the element is found and scrolled to
            }
        }, 100); // Check every 100 milliseconds, adjust the interval as needed
    }

    async function fetchChatHistory() {
        try {
            // Await the chat history response
            let response = await getChatHistory(
                { projectId: state.projectId, userId: state.userId },
                state.jwt
            );
            console.log(response, "2ws0000000000000000000000");
    
            if (response.status === 403  || response.statusCode === 403) {
                sessionExpired();
                return;
            }
    
            if (response.status === 200) {
                setDeployStatus(response.data[0][0]?.deployStatus);
                console.log("dasdasdasd", response.data[0][0]?.deployStatus);
                console.log(response.data[0]);
            } else {
                // Handle other status responses if necessary
            }
    
            // Await fetching the JSON blob
            let responseData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
            console.log(responseData, "0000000000000000000000");
    
            if (responseData) {
                let value = responseData;
                console.log(value, "valuevaluevaluevaluevalue");
    
                let designDetailsFound = false;
                let sourcePhaseFound = false;
    
                if (value.length >= 3) {
                    setSourcePhase(value[2].phase);
                    sourcePhaseFound = true;
                }
    
                value.forEach((item) => {
                    if (item.deployed === true) {
                        setTargetSystemDetails(item.targetdetails);
                    }
                    if (!designDetailsFound && value.length > 7) {
                        let arr = value.filter((a) => a.text === "Notebook Details");
                        if (arr[0]?.data !== undefined) {
                            setDesignDetails(arr[0].data);
                            designDetailsFound = true;
                        }
                    }
                    if (!sourcePhaseFound && item.phase) {
                        setSourcePhase(item.phase);
                        sourcePhaseFound = true;
                    }
    
                    if (item.flow === 'error') {
                        value = value.filter((a) => a.flow !== 'error');
                        console.log(value);
                        insertChatHistory(value);
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => {
                            document.getElementById("protoastMessageSomething").style.display = "none";
                        }, 3000);
                    }
    
                    if (item.type === 'textwait') {
                        const diffInMilliseconds = Math.abs(new Date() - new Date(item.time || '2024-03-13T10:00:00'));
                        const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
                        if (minutes > 14) {
                            value = value.filter((a, i) => i !== value.length - 1 && i !== value.length - 2);
                            value[value.length - 1].show = true;
                            insertChatHistory(value);
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => {
                                document.getElementById("protoastMessageSomething").style.display = "none";
                            }, 3000);
                        }
                    }
                    console.log(item, 'itemitemitemitemitemitem');
    
                    if (item.type === 'tablePathPopup' && item?.tablePathDetails) {
                        setTablePathDetails(item?.tablePathDetails);
                    }
                    if (item.defineData) {
                        setDefineDetails(item.defineData);
                    }
                    if (item.tableReqData) {
                        setTableReqDetails(item.tableReqData);
                    }
                    if (item.silverReqData) {
                        setSilverReqDetails(item.silverReqData);
                    }
                    if (item.goldReqData) {
                        setGoldReqDetails(item.goldReqData);
                    }
                    if (item.designData) {
                        setDesignDetails(item.designData);
                        console.log(designDetails, "design------------");
                    }
                    if (item.designObj) {
                        setDesignObj(JSON.parse(item.designObj));
                        console.log(designObj, "designObj------------");
                    }
    
                    // Await fetching Pseudocode data
                    (async () => {
                        let response = await fetchPseudocodeData({
                            projectId: state.projectId,
                            orgId: state.orgId,
                            userId: state.userId,
                            type: "pipelineSummary",
                        }, state.jwt);
    
                        // Check if the response exists and the status code is 200
                        if (response && response.statusCode === 200) {
                            // Ensure response.data is not null or undefined and has at least one item
                            if (response.data && response.data.length > 0) {
                                try {
                                    // Attempt to parse the pipeline summary data safely
                                    let pipelineSummary = JSON.parse(response.data[0].pipelineSummary);
                                    setPipelineSummaryData(pipelineSummary);
                                    console.log("this is fetched PIPLEINSUMMARY");
                                } catch (error) {
                                    console.error("Failed to parse pipeline summary data", error);
                                }
                            } else {
                                console.warn("Pipeline summary data is empty or undefined");
                            }
                        } else {
                            console.error("Failed to fetch pipeline summary data", response);
                        }
                    })();
    
                    if (item.extractionSeqData) {
                        setExtractionSeqData(JSON.parse(item.extractionSeqData));
                    }
                    if (item.transformationSeqData) {
                        setTransformationSeqData(JSON.parse(item.transformationSeqData));
                    }
                    if (item.extractionData) {
                        setExtractionData(JSON.parse(item.extractionData));
                    }
                    if (item.transformationData) {
                        setTransformationData(JSON.parse(item.transformationData));
                    }
                    if (item.goldData) {
                        setGoldData(JSON.parse(item.goldData));
                    }
                    if (item.dataflowData) {
                        setDataflowData(JSON.parse(item.dataflowData));
                    }
                    if (item.orchestrationData) {
                        setOrchestrationData(item.orchestrationData);
                    }
    
                });
    
                console.log('------------------------->>', value);
                setChatHistory(value);
            }
    
            document.getElementById("pageLoader").style.display = "none";
            setTimeout(() => {
                goDown();
            }, 1000);
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    }


    console.log(targetSystemDetails, defineDetails);


    /**
     * PS_CD_1.298 - 1.309
     */
    //added refresh

    // PS_39 - PS_65 insertchatHistory
    async function insertChatHistory(data) {
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            console.log("Inserting chat history:", {
                projectId: state.projectId,
                chatHistory: data !== undefined ? data : chatHistory,
                userId: state.userId,
            });

            const response = await putJsonToBlob(state.orgId, state.projectId, data, 'chatHistory.json', 'projectfiles')
 
        } catch (error) {
            console.error("Error inserting chat history:", error);
            // Handle errors if needed
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    async function download(type, filename, stype, id) {
        console.log(type, "===================");
        let body = {
            projectId: state.projectId,
            type: type,
            userId: state.userId,
            orgId: state.orgId,
        };
        if (stype === 'notebook') {
            body.notebookId = id
        }
        if (stype === 'pipeline') {
            body.pipelineId = id
        }
        const res = await getCodePageDataApi(body, state.jwt);
        console.log(res);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired();
        } else if (res.status !== 200) {
            document.getElementById("openErrorModal").click();
        } else {
            if (type === "checklist") {
                var wb = XLSX.utils.book_new();
                var sheetMap = {};

                res.data.forEach((sheetData) => {
                    console.log(res.data, "resssssssssssssssssssssssssssssssssssss")
                    if (!sheetMap[sheetData.NotebookName.slice(0, 30)]) {
                        sheetMap[sheetData.NotebookName.slice(0, 30)] = [
                            ["Notebook Name", "Command Purpose", "Checklist Step", "Status"],
                        ];
                    }

                    sheetMap[sheetData.NotebookName.slice(0, 30)].push([
                        sheetData.NotebookName,
                        sheetData.CommandPurpose,
                        sheetData.CheckListStep,
                        sheetData.Status
                    ]);
                });

                Object.keys(sheetMap).forEach((sheetName) => {
                    var ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                });

                XLSX.writeFile(wb, "code_review_checklist.xlsx");
            } else if (type === 'metadataTable') {
                // Extract the metadataTable string from the data array
                const metadataString = res.data[0].metadataTable;

                // Parse the metadataTable string into an array of objects
                let metadataArray;
                try {
                    metadataArray = JSON.parse(metadataString);
                } catch (error) {
                    console.error('Error parsing metadataTable:', error);
                    return; // Exit the function if parsing fails
                }

                // Check if metadataArray is an array
                if (!Array.isArray(metadataArray)) {
                    console.error('Metadata table data is not in the correct format.');
                    return; // Exit the function if metadataArray is not an array
                }

                // Proceed with converting metadataArray to an Excel sheet
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.json_to_sheet(metadataArray);
                const wb = { Sheets: { 'metadata': ws }, SheetNames: ['metadata'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, 'MetaData' + fileExtension); // Corrected file name
            }
            else {
                console.log(res)
                res.data =
                    type === "metadata"
                        ? res.data[0].metadataUpdateScript === null
                            ? res.data[0].metadataInsertScript
                            : res.data[0].metadataInsertScript +
                            "\n" +
                            res.data[0].metadataUpdateScript
                        : type === "ddl"
                            ? res.data[0].DDLScripts
                            : type.toLowerCase() === "bronze".toLowerCase() || type.toLowerCase() === "extraction".toLowerCase()
                                ? res.data[0].bronzeCode
                                : type.toLowerCase() === "dataflow"
                                    ? res.data[0].pipelineJSON
                                    : type.toLowerCase() === "dataset"
                                        ? res.data[0].pipelineJSON
                                        : type.toLowerCase() === "gold"
                                            ? res.data[0].goldCode
                                            : type.toLowerCase() === "linkedservices" || type.toLowerCase() === "orchestration"
                                                ? res.data[0].pipelineJSON
                                                : type.toLowerCase() === "logger"
                                                    ? res.data[0].loggerCode
                                                    : type.toLowerCase() === "unittestcase"
                                                        ? res.data[0].unitTestCaseCode
                                                        : type.toLowerCase() === "dataQualityCode"
                                                            ? res.data[0].dataQualityCode
                                                            : type.toLowerCase() === "silver"
                                                                ? res.data[0].silverCode
                                                                : res.data[0].utilitiesCode

                const element = document.createElement("a");
                element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8," + encodeURIComponent(res.data)
                );
                element.setAttribute("download", filename + ".py");
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        }
    }

    async function scrollMatch() {
    setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = scrollPosition;
        }
      }, 0);

    }


    async function regenerate(data) {

        let chatData = [...chatHistory];

        console.log(chatData, data, 'beforenewChatnewChatnewChatnewChat');

        chatData.pop();
        chatData[chatData.length - 1].show = true;
        chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });


        console.log(chatData, 'newChatnewChatnewChatnewChat');

        setChatHistory((a) => [...chatData]);
        // Update JSON in blob
        const responseURL = await putJsonToBlob(state.orgId, state.projectId, [...chatData], 'chatHistory.json', 'projectfiles')
        console.log(responseURL, 'responseresponseURL');

        if (data?.codeType === "metadata") {

            const res = await generateMetadata(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "meta",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {
               // // await fetchChatHistory() 
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        else if (data?.codeType === "ddl") {

            const res = await generateDDL(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "ddl",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            console.log(res);
            setLoader(() => false);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType === "Extraction" || data?.codeType === "extraction") {

            setLoader(() => true);
            const res = await generateBronze(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "Extraction",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );

            console.log(res);
            setLoader(() => false);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType === "Silver") {

            setLoader(() => true);
            const res = await generateSilver(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "silver",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            // call the generate extraction notebook api
            setLoader(() => false);
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {
                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType === "Gold") {

            setLoader(() => true);
            const res = await generateGoldDevCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "gold",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }

        else if (data?.codeType === "UnitTestCase") {

            setLoader(() => true);
            const res = await generateTestCaseCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "unitTestcase",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            setLoader(() => false);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {
                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        else if (data?.codeType === "dataQualityCode") {

            const res = await genrateDQualityCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "dataQuality",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            console.log(res);
            setLoader(() => false);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {
                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }

        }
        else if (data?.codeType === "checklist") {
            setLoader(() => true);
            const res = await generateChecklistDev(
                { projectId: state.projectId, orgId: state.orgId, userId: state.userId, Methodology: state.methodology, futureStateName: state.futureStateName, conditionType: state.layer },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        else if (data?.codeType === "logger") {


            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "logger",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            console.log("logger res", res);

            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {
                // await fetchChatHistory()
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType === "utility") {


            const res = await generateUtility(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "utilities",
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            console.log("utilities res", res);
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // // await fetchChatHistory()

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType === "Orchestration") {

            setLoader(() => true);
            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: data?.codeType,
                    pipelineId: data?.pipelineId,
                    pipelineType: data?.pipelineType,
                    sampleCode: "", conditionType: state.layer
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired()
                return;
            }
            if (res.status === 200) {

                // await fetchChatHistory()
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }


    }



    /**
     *
     */

    //PS_CM_70 - PS_CM_661
    function checkLooksGood(codeType) {


        console.log(designDetails, "desggggggg");

        if (codeType === "checklist") {
            handleDeploy();
        } else if (
            state.futureStateName === "Databricks" ||
            state.futureStateName === "Snowflake" ||
            state.futureStateName === "Redshift" ||
            state.futureStateName === "AWS Glue" ||
            (state.futureStateName === "Synapse" && state.Methodology === "notebook")
        ) {
            // Flag to indicate whether code generation is in progress
            let isProcessing = false;

            // Event listener to prevent page refresh if processing is ongoing
            window.addEventListener('beforeunload', function (e) {
                if (isProcessing) {
                    e.preventDefault();
                    e.returnValue = ''; // Display a warning message
                }
            });

            // Call the function to handle code generation
            handleCodeGeneration();

            async function handleCodeGeneration() {
                if (isProcessing) {
                    return; // Exit early if already processing
                }

                isProcessing = true; // Set flag to true to indicate processing has started

                try {
                    let sampleCode = ""

                    if (codeType === "metadataTable") {

                        setShowlooksGood(() => true);
                        if ("loggerPseudocode" in designDetails) {
                            console.log("loggg");
                            await generateLoggerCode(sampleCode); // Ensure awaiting
                        } else if ("utilitiesPseudocode" in designDetails) {

                            await generateUtilitiesCode(sampleCode); // Ensure awaiting
                        } else if ("metadata" in designDetails) {

                            await generateMetadataCode(sampleCode); // Ensure awaiting
                        } else if ("DDLScripts" in designDetails) {

                            await generateDDLScripts(sampleCode); // Ensure awaiting
                        } else if ("Extraction" in designDetails) {

                            await generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            ); // Ensure awaiting
                        } else if ("Silver" in designDetails) {

                            await generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            ); // Ensure awaiting
                        } else if ("Gold" in designDetails) {

                            await generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                            // Ensure awaiting
                        }
                        
                        else if ("unitTestCasePseudocode" in designDetails) {

                            await generateUnitTestCaseCode(sampleCode); // Ensure awaiting
                        } else if ("dataQualityPseudocode" in designDetails) {

                            await generateDataQualityCode(sampleCode); // Ensure awaiting
                        } else {
                            await generateCodeReviewChecklist(); // Ensure awaiting
                        }
                    }
                    else if (codeType === "logger") {
                        setShowlooksGood(() => true);
                        if ("utilitiesPseudocode" in designDetails) {

                            generateUtilitiesCode(sampleCode);
                        } else if ("metadata" in designDetails) {

                            generateMetadataCode(sampleCode);
                        } else if ("DDLScripts" in designDetails) {

                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {

                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            );
                        } else if ("Silver" in designDetails) {

                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            );
                        } else if ("Gold" in designDetails) {

                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                        }
                        
                        else if ("unitTestCasePseudocode" in designDetails) {

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {

                            generateDataQualityCode(sampleCode);
                        }

                        else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType === "utility") {
                        setShowlooksGood(() => true);
                        if ("metadata" in designDetails) {

                            generateMetadataCode(sampleCode);
                        } else if ("DDLScripts" in designDetails) {

                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {

                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName
                            );
                        } else if ("Silver" in designDetails) {

                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName
                            );
                        } else if ("Gold" in designDetails) {

                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName
                            );
                        }
                        
                        else if ("unitTestCasePseudocode" in designDetails) {

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {

                            generateDataQualityCode(sampleCode);
                        }



                        else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType === "metadata") {
                        setShowlooksGood(() => true);
                        if ("DDLScripts" in designDetails) {

                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {

                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName
                            );
                        } else if ("Silver" in designDetails) {

                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName
                            );
                        } else if ("Gold" in designDetails) {

                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName
                            );
                        }
                        
                        else if ("unitTestCasePseudocode" in designDetails) {

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {

                            generateDataQualityCode(sampleCode);
                        } else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType === "ddl") {
                        setShowlooksGood(() => true);
                        if ("Extraction" in designDetails) {

                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            );
                        } else if ("Silver" in designDetails) {

                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            );
                        } else if ("Gold" in designDetails) {

                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                        }
                        
                        else if ("unitTestCasePseudocode" in designDetails) {

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {

                            generateDataQualityCode(sampleCode);
                        } else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType === "Extraction" || codeType === "extraction") {

                        setShowlooksGood(() => true);
                        if (designDetails.Extraction.length > 1) {

                            for (let i = 0; i < designDetails.Extraction.length; i++) {
                                const extractionNotebook = designDetails.Extraction[i];
                                if (
                                    extractionNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Extraction.length &&
                                designDetails.Extraction[index + 1] !== undefined
                            ) {
                                generateExtractionCode(
                                    designDetails.Extraction[index + 1].notebookId,
                                    designDetails.Extraction[index + 1].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Silver" in designDetails) {

                                generateSilverCode(
                                    designDetails.Silver[0].notebookId,
                                    designDetails.Silver[0].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {

                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            } else {
                                generateCodeReviewChecklist();
                            }
                        } else {
                            if ("Silver" in designDetails) {

                                generateSilverCode(
                                    designDetails.Silver[0].notebookId,
                                    designDetails.Silver[0].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {

                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                          
                            else if ("unitTestCasePseudocode" in designDetails) {

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        }
                    } else if (codeType === "Silver") {
                        console.log("dummy")
                        setShowlooksGood(() => true);
                        if (designDetails.Silver.length > 1) {

                            for (let i = 0; i < designDetails.Silver.length; i++) {
                                const silverNotebook = designDetails.Silver[i];
                                console.log(
                                    silverNotebook.notebookName,
                                    chatHistory[chatHistory.length - 1]
                                );
                                if (
                                    silverNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Silver.length &&
                                designDetails.Silver[index + 1] !== undefined
                            ) {
                                generateSilverCode(
                                    designDetails.Silver[index + 1].notebookId,
                                    designDetails.Silver[index + 1].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {

                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                           
                            else if ("unitTestCasePseudocode" in designDetails) {

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {

                                generateDataQualityCode(sampleCode);
                            } else {
                                generateCodeReviewChecklist();
                            }
                        } else {
                            if ("Gold" in designDetails) {

                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                           
                            else if ("unitTestCasePseudocode" in designDetails) {

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        }
                    } else if (codeType === "Gold") {

                        setShowlooksGood(() => true);
                        if (designDetails.Gold.length > 1) {

                            for (let i = 0; i < designDetails.Gold.length; i++) {
                                const goldNotebook = designDetails.Gold[i];
                                if (
                                    goldNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Gold.length &&
                                designDetails.Gold[index + 1] !== undefined
                            ) {
                                generateGoldCode(
                                    designDetails.Gold[index + 1].notebookId,
                                    designDetails.Gold[index + 1].notebookName,
                                    sampleCode
                                );
                            }
                          
                            else if ("unitTestCasePseudocode" in designDetails) {

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        } else {

                            
                            if ("unitTestCasePseudocode" in designDetails) {

                                generateUnitTestCaseCode(sampleCode);
                            }

                            else if ("dataQualityPseudocode" in designDetails) {

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        }
                    } else if (codeType === "UnitTestCase") {
                        setShowlooksGood(() => true);
                        if ("dataQualityPseudocode" in designDetails) {

                            generateDataQualityCode(sampleCode);
                        }
                        else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType === "dataQualityCode") {
                        setShowlooksGood(() => true);

                        generateCodeReviewChecklist();

                    } else {
                        setShowlooksGood(() => true);
                        generateCodeReviewChecklist();
                    }
                } catch (error) {
                    console.error("Error during code generation:", error);
                } finally {
                    isProcessing = false; // Reset flag once processing is complete
                }
            }

        }
    }
    const handleTablepathPopup = () => {
        let data = [...chatHistory];

        data[data.length - 1].show = false;
        console.log(state, "state");

        data.push({
            flow: "user",
            type: "text",
            text: "Looks Good",
            phase: "Design"
        });

        data.push({
            flow: "ai",
            type: "tablePathPopup",
            text: "Kindly fill out this form to know about your Table path.",
            show: true,
            phase: "Design"
        });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

    }

    //Pseudocode looks good button functionality
    // PS_108- PS_610
    async function checkPseudocodeLooksGood(pseudocodeType) {

        let sampleCode = "";
        let data;
        let eachData;
        let gold;
        let transformation;
        let extraction;

        console.log(defineDetails, "defineDetails.......");
        let genericRequirements;
        let parsedDefineDetails = defineDetails;

        if (parsedDefineDetails.genericRequirements && typeof parsedDefineDetails.genericRequirements === 'string') {
            genericRequirements = JSON.parse(parsedDefineDetails.genericRequirements);
        } else {
            genericRequirements = parsedDefineDetails.genericRequirements; // If it's already an object, assign it directly
        }

        let isLogger = genericRequirements?.filter(item => item.Requirements.includes("Logger"));
        let isUtility = genericRequirements?.filter(item => item.Requirements.includes("Utility"));

        console.log(isLogger, "isLogger");
        console.log(isUtility, "isUtility");
        if (pseudocodeType === "extractionRequirement") {
            generateBronzeER();
        }
        else if (pseudocodeType === "sourceToExtractionER" && state.layer == "Bronze") {
            handleFRDPopup()
        }
        else if (pseudocodeType === "sourceToExtractionER") {
            handleSilverER()
        }

        else if (pseudocodeType === "BronzeToSilverER" && state.layer == "Silver") {
            handleFRDPopup()
        }

        else if (pseudocodeType === "BronzeToSilverER") {
            handleGoldER()
        }


        else if (pseudocodeType === "SilverToGoldER") {
            handleFRDPopup()
        }
        else if (pseudocodeType === "dataModelSilver" && state.layer == "Silver") {
            generateDataDictionarySilver(sampleCode);
        }
        else if (pseudocodeType === "dataModelSilver") {
            generateDataModelGold();
        }
        else if (pseudocodeType === "dataModelGold" && state.layer == "Gold") {
            generateDataDictionaryGold(sampleCode);
        }
        else if (pseudocodeType === "dataModelGold") {
            generateDataDictionarySilver(sampleCode);
        }
        else if (pseudocodeType === "dataDictionarySilver" && state.layer == "Silver") {
            handleTablepathPopup();
        }
        else if (pseudocodeType === "dataDictionarySilver") {
            generateDataDictionaryGold(sampleCode);
        }
        else if (pseudocodeType === "dataDictionaryGold" && state.layer == "Gold") {
            handleTablepathPopup();
        }
        else if (pseudocodeType === "dataDictionaryGold") {
            handleTablepathPopup();
        }
        else if (pseudocodeType === "tablePathPopup") {
            generateMetadataTable(sampleCode);
        }
        else if (pseudocodeType === "metadataTable" && state.layer == "Silver") {

            generateDDLSilverPseudo(sampleCode);
        }
        else if (pseudocodeType === "metadataTable" && state.layer == "Gold") {

            generateDDLGoldPseudo(sampleCode);
        }
        else if (pseudocodeType === "metadataTable") {

            generateDDLBronzePseudo(sampleCode);
        }
        else if (pseudocodeType === "dDLScriptsBronze" && state.layer === "Bronze") { 
            // Safeguard against issues with undefined "sourceSystemNames"
            if (chatHistory[0].sourceSystemNames) {
                chatHistory[2].extractionSeqData = JSON.stringify(chatHistory[0].sourceSystemNames);

                // Use functional update to ensure react state consistency if you're in the context of React
                setChatHistory((prevChatHistory) => {
                    const newChatHistory = [...prevChatHistory];
                    newChatHistory[2] = {
                        ...newChatHistory[2],
                        extractionSeqData: JSON.stringify(newChatHistory[0].sourceSystemNames),
                    };
                    return newChatHistory;
                });
                setExtractionSeqData(chatHistory[0].sourceSystemNames)
               
        
                // Await for state update and subsequent operations
                await insertChatHistory([...chatHistory]);
        
                if (chatHistory[0].sourceSystemNames.length > 0) {
                    await generateExtractionSequence(chatHistory[0].sourceSystemNames[0], sampleCode);
                } else {
                    console.error('sourceSystemNames is empty');
                }
            } else {
                console.error('sourceSystemNames is undefined');
            }
        }
        else if (pseudocodeType === "dDLScriptsBronze") {
            generateDDLSilverPseudo(sampleCode);
        }
        else if (pseudocodeType === "dDLScriptsSilver" && state.layer === "Silver") { 
            generatePipelineSummary(sampleCode); 
        }
        else if (pseudocodeType === "dDLScriptsSilver") {
            generateDDLGoldPseudo(sampleCode);
        }
        else if (pseudocodeType === "dDLScriptsGold") {
            generatePipelineSummary(sampleCode);
        }
        else if (pseudocodeType === "pipelineSummary") {
            generateOrchestrationUML();
        }
        //Here the generation flow splits based on the target system : Notebook flow
        else if (pseudocodeType === "orchestrationUML" && state.layer == 'Medallion') {
            // Disable refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            try {
                // To fetch pipelineSummary from Database
                let response = await fetchPseudocodeData({
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "pipelineSummary",
                }, state.jwt);
                if (response.statusCode === 200) {
                    let data1 = JSON.parse(response.data[0].pipelineSummary);
                    let data = [...chatHistory];
                    data[2].pipelineSummaryData = JSON.stringify(data1);

                    // setChatHistory((a) => [...data]);
                    // insertChatHistory([...data]);

                    let extraction = data1.filter(obj =>
                        obj.NotebookType.toLowerCase().includes("extraction") && !obj.NotebookType.toLowerCase().includes("orchestration")
                    );

                    data[2].extractionSeqData = JSON.stringify(extraction);
                    setChatHistory((a) => [...data]);
                    await insertChatHistory([...data]);

                    await generateExtractionSequence(extraction[0], sampleCode);
                } else {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                }
            } catch (error) {
                console.error("Error processing orchestration UML:", error);
            } finally {
                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }

        }
        else if (pseudocodeType === "orchestrationUML" && state.layer == 'Silver') {
            debugger    
            let response = await fetchPseudocodeData({
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "pipelineSummary",
            }, state.jwt);
            if (response.statusCode === 200) {
                let data1 = JSON.parse(response.data[0].pipelineSummary);
                setPipelineSummaryData(data1)
            
            let transformation = data1.filter(obj => {
                const notebookType = obj.NotebookType.toLowerCase();
                return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
            });
            let data = [...chatHistory];
            data[2].transformationSeqData = JSON.stringify(transformation);
            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);
            await generateTransformationSequence(transformation[0], sampleCode);
        }
        }
        else if (pseudocodeType === "orchestrationUML" && state.layer == 'Gold') {
            // Disable refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            try {
                // To fetch pipelineSummary from Database
                let response = await fetchPseudocodeData({
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "pipelineSummary",
                }, state.jwt);
                if (response.statusCode === 200) {
                    let data1 = JSON.parse(response.data[0].pipelineSummary);
                    setPipelineSummaryData(data1)
                
                let gold = data1.filter(obj => {
                    const notebookType = obj.NotebookType.toLowerCase();
                    return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                });
                let data = [...chatHistory];
                data[2].goldData = JSON.stringify(gold);
                setChatHistory(a => [...data]);
                await insertChatHistory([...data]);
                await generateTransformationGoldSequence(gold[0], sampleCode);
            }
            } catch (error) {
                console.error("Error processing orchestration UML:", error);
            } finally {
                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }

        } 
        else if (pseudocodeType === "extractionSequence") {
            debugger
            let isOperationInProgress = false; // Flag to indicate ongoing operation

            // Disable refresh/navigation
            window.onbeforeunload = function (e) {
                if (isOperationInProgress) {
                    const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                    e.returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            };

            try {
                isOperationInProgress = true; // Set the flag to true when starting the operation
                
                if (extractionSeqData.length > 1) {
                    for (let i = 0; i < extractionSeqData.length; i++) {
                        let eachData = extractionSeqData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                        }
                    }
                    if (index <= extractionSeqData.length &&
                        extractionSeqData[index + 1] !== undefined) {
                        await generateExtractionSequence(extractionSeqData[index + 1], sampleCode);
                    } else {
                        if (state.layer == "Bronze") {
                            generateLogger()
                        }
                        else {

                        let transformation = pipelineSummaryData.filter(obj => {
                            const notebookType = obj.NotebookType.toLowerCase();
                            return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
                        });

                        let data = [...chatHistory];
                        data[2].transformationSeqData = JSON.stringify(transformation);
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        await generateTransformationSequence(transformation[0], sampleCode);
                    }
                    }
                } else {

                    if (state.layer == "Bronze") {
                        generateLogger()
                    }
                    else {

                    let transformation = pipelineSummaryData.filter(obj => {
                        const notebookType = obj.NotebookType.toLowerCase();
                        return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
                    });

                    let data = [...chatHistory];
                    data[2].transformationSeqData = JSON.stringify(transformation);
                    setChatHistory(a => [...data]);
                    await insertChatHistory([...data]);
                    await generateTransformationSequence(transformation[0], sampleCode);
                }
                }
            } catch (error) {
                console.error("Error processing Extraction Sequence:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                window.onbeforeunload = null; // Re-enable refresh/navigation
            }
        }
        else if (pseudocodeType === "transformationSequence") {
            let isOperationInProgress = false; // Flag to indicate ongoing operation
            // Disable refresh/navigation
            window.onbeforeunload = function (e) {
                if (isOperationInProgress) {
                    const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                    e.returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            };

            try {
                isOperationInProgress = true; // Set the flag to true when starting the operation

                if (transformationSeqData.length > 1) {
                    for (let i = 0; i < transformationSeqData.length; i++) {
                        let eachData = transformationSeqData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                        }
                    }
                    if (index <= transformationSeqData.length &&
                        transformationSeqData[index + 1] !== undefined) {
                        await generateTransformationSequence(transformationSeqData[index + 1], sampleCode);
                    } else {
                        if (state.layer == 'Silver') {
                            debugger
                            let transformation = pipelineSummaryData.filter(obj =>
                                (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                                !obj.NotebookType.toLowerCase().includes("orchestration")
                            );
                            let data = [...chatHistory];
                            let silverId = 0;
                            data[2].transformationData = JSON.stringify(transformation);
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (transformation[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    silverId = chatHistory[i]?.notebookId;
                                }
                            }
                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);
                            setTimeout(() => {
                                console.log("Timeout completed after 50 seconds");
                            }, 50000);
                            await generateTransformationSilver(transformation[0], sampleCode, silverId);
                        }
                        else {
                        let gold = pipelineSummaryData.filter(obj => {
                            const notebookType = obj.NotebookType.toLowerCase();
                            return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                        });
                        let data = [...chatHistory];
                        data[2].goldData = JSON.stringify(gold);
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        await generateTransformationGoldSequence(gold[0], sampleCode);
                    }
                    }
                } else {

                    if (state.layer == 'Silver') {
                        let transformation = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );
                        let data = [...chatHistory];
                        let silverId = 0;
                        data[2].transformationData = JSON.stringify(transformation);
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (transformation[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                silverId = chatHistory[i]?.notebookId;
                            }
                        }
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        setTimeout(() => {
                            console.log("Timeout completed after 50 seconds");
                        }, 50000);
                        await generateTransformationSilver(transformation[0], sampleCode, silverId);
                    }
                    else {
                    let gold = pipelineSummaryData.filter(obj => {
                        const notebookType = obj.NotebookType.toLowerCase();
                        return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                    });
                    let data = [...chatHistory];
                    data[2].goldData = JSON.stringify(gold);
                    setChatHistory(a => [...data]);
                    await insertChatHistory([...data]);
                    await generateTransformationGoldSequence(gold[0], sampleCode);
                }
                }
            } catch (error) {
                console.error("Error processing transformation sequence:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                window.onbeforeunload = null; // Re-enable refresh/navigation
            }
        }

        else if (pseudocodeType === "goldSequence") {
            let isOperationInProgress = false; // Flag to indicate ongoing operation

            // Disable refresh/navigation
            window.onbeforeunload = function (e) {
                if (isOperationInProgress) {
                    const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                    e.returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            };

            try {
                isOperationInProgress = true; // Set the flag to true when starting the operation
                let index;

                if (goldData.length >= 1) {
                    for (let i = 0; i < goldData.length; i++) {
                        let eachData = goldData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                            break; // Exit the loop once index is found
                        }
                    }

                    if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
                        await generateTransformationGoldSequence(goldData[index + 1], sampleCode);
                    } else {
                        if (state.layer == 'Gold') {
                            debugger
                            let response = await fetchPseudocodeData({
                                projectId: state.projectId,
                                orgId: state.orgId,
                                userId: state.userId,
                                type: "pipelineSummary",
                            }, state.jwt);
                            if (response.statusCode === 200) {
                                let data1 = JSON.parse(response.data[0].pipelineSummary);
                                setPipelineSummaryData(data1)
                                let gold = data1.filter(obj =>
                                    (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                                    !obj.NotebookType.toLowerCase().includes("orchestration")
                                );
            
                                let goldID = 0;
                                chatHistory[2].goldData = JSON.stringify(gold);
                                for (let i = 0; i < chatHistory.length; i++) {
                                    if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                        goldID = chatHistory[i]?.notebookId;
                                    }
                                }
                                setChatHistory(a => [...chatHistory]);
                                await insertChatHistory([...chatHistory]);
                                await generateGold(gold[0], sampleCode, goldID);
                            }
                            
                        }
                        else {
                        await generateLogger(sampleCode);
                        }
                    }
                } else {
                    if (state.layer == 'Gold') {
                        debugger
                        let response = await fetchPseudocodeData({
                            projectId: state.projectId,
                            orgId: state.orgId,
                            userId: state.userId,
                            type: "pipelineSummary",
                        }, state.jwt);
                        if (response.statusCode === 200) {
                            let data1 = JSON.parse(response.data[0].pipelineSummary);
                            setPipelineSummaryData(data1)
                            let gold = data1.filter(obj =>
                                (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                                !obj.NotebookType.toLowerCase().includes("orchestration")
                            );
        
                            let goldID = 0;
                            data[2].goldData = JSON.stringify(gold);
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    goldID = chatHistory[i]?.notebookId;
                                }
                            }
                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);
                            await generateGold(gold[0], sampleCode, goldID);
                        }
                        
                    }
                    else {
                    await generateLogger(sampleCode);
                    }
                }
            } catch (error) {
                console.error("Error processing gold sequence:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                window.onbeforeunload = null; // Re-enable refresh/navigation
            }
        }

        else if ((pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") && isLogger?.length !== 0) {
            if (state.layer == "Bronze" || state.layer == "Medallion") {
            generateLogger(sampleCode);
            }
            else if (state.layer == 'Silver') {

                debugger    
                let response = await fetchPseudocodeData({
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "pipelineSummary",
                }, state.jwt);
                if (response.statusCode === 200) {
                    let data1 = JSON.parse(response.data[0].pipelineSummary);
                    setPipelineSummaryData(data1)
                
                let transformation = data1.filter(obj => {
                    const notebookType = obj.NotebookType.toLowerCase();
                    return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
                });
                let data = [...chatHistory];
                data[2].transformationSeqData = JSON.stringify(transformation);
                setChatHistory(a => [...data]);
                await insertChatHistory([...data]);
                await generateTransformationSequence(transformation[0], sampleCode);
            }
           
            }
            else if (state.layer == 'Gold') {
                
            // Disable refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            try {
                // To fetch pipelineSummary from Database
                let response = await fetchPseudocodeData({
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "pipelineSummary",
                }, state.jwt);
                if (response.statusCode === 200) {
                    let data1 = JSON.parse(response.data[0].pipelineSummary);
                    setPipelineSummaryData(data1)
                
                let gold = data1.filter(obj => {
                    const notebookType = obj.NotebookType.toLowerCase();
                    return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                });
                let data = [...chatHistory];
                data[2].goldData = JSON.stringify(gold);
                setChatHistory(a => [...data]);
                await insertChatHistory([...data]);
                await generateTransformationGoldSequence(gold[0], sampleCode);
            }
            } catch (error) {
                console.error("Error processing orchestration UML:", error);
            } finally {
                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }

        
            }
        } else if ((pseudocodeType === "loggerPseudocode" || pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") && isUtility?.length !== 0) {

            generateUtilityPseudo(sampleCode);
        }
        else if (pseudocodeType === "utilityPseudocode" && state.layer == 'Bronze') {
            debugger    
            let bronzeId = 0;
            let extraction = JSON.parse(chatHistory[2].extractionData)
            setExtractionData(extraction)
                for (let i = 0; i < chatHistory.length; i++) {
                    if (extraction[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                        bronzeId = chatHistory[i]?.notebookId;
                    }
                }
                
                // setTimeout(() => {
                //     console.log("Timeout 2nd completed after 50 seconds");
                // }, 30000);                                                               
                await generateExtraction(extraction[0], sampleCode, bronzeId);
        }
        else if (pseudocodeType === "utilityPseudocode" || pseudocodeType === "loggerPseudocode" || pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") {
            // Disable refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };
            // Fetch pipelineSummary from Database
            let response = await fetchPseudocodeData({
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "pipelineSummary",
            }, state.jwt);

            if (response.statusCode === 200) {
                let data1 = JSON.parse(response.data[0].pipelineSummary);
                let data = [...chatHistory];

                data[2].pipelineSummaryData = JSON.stringify(data1);
                setChatHistory(a => [...data]);
                await insertChatHistory([...data]);

                setTimeout(() => {
                    console.log("Timeout completed after 50 seconds");
                }, 30000);

                let extraction = data1.filter(obj =>
                    obj.NotebookType.toLowerCase().includes("extraction") && !obj.NotebookType.toLowerCase().includes("orchestration")
                );
                let bronzeId = 0;
                data[2].extractionData = JSON.stringify(extraction);


                for (let i = 0; i < chatHistory.length; i++) {
                    if (extraction[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                        bronzeId = chatHistory[i]?.notebookId;
                    }
                }
                setChatHistory(a => [...data]);
                await insertChatHistory([...data]);

                setTimeout(() => {
                    console.log("Timeout 2nd completed after 50 seconds");
                }, 30000);

                generateExtraction(extraction[0], sampleCode, bronzeId);
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }
            // Re-enable refresh once all await calls are done
            window.onbeforeunload = null;
        }

        else if (pseudocodeType === "extraction") {

            let bronzeIDD = 0;

            // Disable refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            try {
                if (extractionData.length > 1) {
                    for (let i = 0; i < extractionData.length; i++) {
                        let eachData = extractionData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                            break; // Exit the loop once index is found
                        }
                    }

                    if (index !== undefined && index <= extractionData.length - 2 && extractionData[index + 1] !== undefined) {
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (extractionData[index + 1].NotebookName === chatHistory[i]?.hyperlinkText) {
                                bronzeIDD = chatHistory[i]?.notebookId;
                            }
                        }
                        setTimeout(() => {
                            console.log("Timeout completed after 50 seconds");
                        }, 50000);

                        await generateExtraction(extractionData[index + 1], sampleCode, bronzeIDD);
                    } else {
                        if (state.layer == "Bronze") {
                        await generateUnitTestCase()
                        }
                        else {
                        let transformation = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );
                        let data = [...chatHistory];
                        let silverId = 0;
                        data[2].transformationData = JSON.stringify(transformation);
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (transformation[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                silverId = chatHistory[i]?.notebookId;
                            }
                        }
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        setTimeout(() => {
                            console.log("Timeout completed after 50 seconds");
                        }, 50000);
                        await generateTransformationSilver(transformation[0], sampleCode, silverId);
                    }
                    }
                } else {
                    if (state.layer == "Bronze") {
                        await generateUnitTestCase()
                    }
                    else {
                        let transformation = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );
                        let data = [...chatHistory];
                        let silverId = 0;
                        data[2].transformationData = JSON.stringify(transformation);
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (transformation[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                silverId = chatHistory[i]?.notebookId;
                            }
                        }
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        setTimeout(() => {
                            console.log("Timeout completed after 50 seconds");
                        }, 50000);
                        await generateTransformationSilver(transformation[0], sampleCode, silverId);
                    }


                }
            } catch (error) {
                console.error("Error processing extraction:", error);
            } finally {
                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }
        }

        else if (pseudocodeType === "transformation") {
            let data = [...chatHistory];
            let tempID = 0;
            let isOperationInProgress = false;

            // Disable refresh/navigation
            window.onbeforeunload = function () {
                if (isOperationInProgress) {
                    return "Processing is still ongoing. Are you sure you want to leave?";
                }
            };

            try {
                isOperationInProgress = true; // Set the flag to true when starting the operation

                if (transformationData.length > 1) {
                    for (let i = 0; i < transformationData.length; i++) {
                        let eachData = transformationData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                            break; // Exit the loop once index is found
                        }
                    }

                    if (index !== undefined && index <= transformationData.length - 2 && transformationData[index + 1] !== undefined) {
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (transformationData[index + 1].NotebookName === chatHistory[i]?.hyperlinkText) {
                                tempID = chatHistory[i]?.notebookId;
                            }
                        }

                        await generateTransformationSilver(transformationData[index + 1], sampleCode, tempID);
                    } else {

                        if (state.layer == 'Silver') {
                            await generateUnitTestCase()
                        }
                        else {
                        let gold = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );
    
                        let goldID = 0;
                        data[2].goldData = JSON.stringify(gold);
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                goldID = chatHistory[i]?.notebookId;
                            }
                        }
    
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
    
                        await generateGold(gold[0], sampleCode, goldID);
                        
                    }
                    }
                } else {
                    if (state.layer == 'Silver') {
                        await generateUnitTestCase()
                    }
                    else {
                    let gold = pipelineSummaryData.filter(obj =>
                        (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                        !obj.NotebookType.toLowerCase().includes("orchestration")
                    );

                    let goldID = 0;
                    data[2].goldData = JSON.stringify(gold);
                    for (let i = 0; i < chatHistory.length; i++) {
                        if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                            goldID = chatHistory[i]?.notebookId;
                        }
                    }

                    setChatHistory(a => [...data]);
                    await insertChatHistory([...data]);

                    await generateGold(gold[0], sampleCode, goldID);
                    
                    }
                }
            } catch (error) {
                console.error("Error processing transformation:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }
        }

        else if (pseudocodeType === "gold") {
            let isOperationInProgress = false; // Flag to indicate an ongoing operation

            // Block refresh/navigation
            window.onbeforeunload = function (e) {
                if (isOperationInProgress) {
                    const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                    e.returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            };

            try {

                console.log(goldData, "goldData");

                let data = [...chatHistory];
                let goldPseudoId = 0;
                isOperationInProgress = true; // Set the flag to true when starting the operation

                if (goldData.length >= 1) {
                    for (let i = 0; i < goldData.length; i++) {
                        let eachData = goldData[i];
                        if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                            index = i;
                            break; // Exit the loop once index is found
                        }
                    }

                    if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (goldData[index + 1].NotebookName === chatHistory[i]?.hyperlinkText) {
                                goldPseudoId = chatHistory[i]?.notebookId;
                            }
                        }

                        await generateGold(goldData[index + 1], sampleCode, goldPseudoId);
                    } else {

                        await generateUnitTestCase()
                    }
                } else {

                    await generateUnitTestCase()
                }
            } catch (error) {
                console.error("Error processing gold transformation:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                // Re-enable refresh/navigation after operations complete
                window.onbeforeunload = null;
            }
        }
        else if (pseudocodeType === "orchestrationDocument") {
            let isOperationInProgress = false; // Flag to indicate an ongoing operation

            // Block refresh/navigation
            window.onbeforeunload = function (e) {
                if (isOperationInProgress) {
                    const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                    e.returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            };

            try {
                isOperationInProgress = true; // Set the flag to true when starting the operation

                await generateUnitTestCase(sampleCode);
                // }
            } catch (error) {
                console.error("Error processing orchestration document:", error);
            } finally {
                isOperationInProgress = false; // Reset the flag once all await calls are done
                // Re-enable refresh/navigation after operations complete
                window.onbeforeunload = null;
            }
        }

        else if (pseudocodeType === "unitTestPseudo") {

            generateDataQuality(sampleCode);
        } else if (pseudocodeType === "dataQualityPsueudocode") {

            generateCodeReviewChecklistDesign(sampleCode);
        } else if (pseudocodeType === "codeReviewChecklist") {
            generateConsolidateDesignDoc();
        }

    }// pseudocode looks good end

    //regenerate Design
    /**
     * The function `regenerateDesign` takes in details about a pseudocode type and generates
     * corresponding sample code and documents based on the type.
     */
    //    PS_899-PS_935
    async function regenerateDesign(details) {

        let columnName = "NotebookName";
        let sampleCode = "";
        let body;
        let eachData;
        let pseudocodeType = details.pseudocodeType;

        let data = [...chatHistory];
        console.log(data, 'beforenewChatnewChatnewChatnewChat');

        if (pseudocodeType === "extractionRequirement" ||
            pseudocodeType === "sourceToExtractionER" ||
            pseudocodeType === "BronzeToSilverER" ||
            pseudocodeType === "SilverToGoldER"
        ) {
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });
            setChatHistory((a) => [...data]);
            await putJsonToBlob(state.orgId, state.projectId, [...data], 'chatHistory.json', 'projectfiles')
        } else if (pseudocodeType !== "extractionSequence" ||
            pseudocodeType !== "transformationSequence" ||
            pseudocodeType !== "goldSequence" ||
            pseudocodeType !== "extraction" ||
            pseudocodeType !== "transformation" ||
            pseudocodeType !== "gold" ||
            pseudocodeType !== "orchestrationDocument"
        ) {
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
            setChatHistory((a) => [...data]);
            await putJsonToBlob(state.orgId, state.projectId, [...data], 'chatHistory.json', 'projectfiles')
        }

        if (pseudocodeType === "extractionRequirement") {
            generateExtractionRequirementData({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'ExtractionRequirement', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "sourceToExtractionER") {
            generateSourceToExtractionER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'bronzeER', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "BronzeToSilverER") {
            generateBronzeToSilverER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'silverER', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "SilverToGoldER") {
            generateSilverToGoldER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'SilverToGoldER', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dataDictionarySilver") {
            generateDataDictionaryPseudocode({ DDType: 'silver', isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataDictionary', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dataDictionaryGold") {
            generateDataDictionaryPseudocode({ DDType: 'gold', isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataDictionary', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dataModelSilver") {
            generateDataModelSilverUML({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataModel', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dataModelGold") {
            generateDataModelGoldUML({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataModel', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "metadataTable") {
            generateMetadataTablePseudo({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'MetadataTable', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dDLScriptsBronze") {
            generateDDLPseudocode({ DDLType: "bronze", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dDLScriptsSilver") {
            generateDDLPseudocode({ DDLType: "silver", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dDLScriptsGold") {
            generateDDLPseudocode({ DDLType: "gold", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "pipelineSummary") {
            generatePipelineSummaryPseudo({ DDLType: "gold", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'PipelineSummary', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "orchestrationUML") {
            generateOrchestrationUMLCode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'OrchestrationUML', conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "loggerPseudocode") {
            generateLoggerPseudocode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'LoggerPseudocode', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "utilityPseudocode") {
            generateUtilityPseudocode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'UtilityPseudocode', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        }

        if (pseudocodeType === "extractionSequence") {

            if (extractionData.length > 1) {
                for (let i = 0; i < extractionData.length; i++) {
                    eachData = extractionData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }

            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'ExtractionSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: extractionData[index], conditionType: state.layer
            };

            if (index <= extractionData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateBronzeSequence(body, state.jwt);

            }
        }
        else if (pseudocodeType === "transformationSequence") {

            if (transformationData.length > 1) {
                for (let i = 0; i < transformationData.length; i++) {
                    eachData = transformationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'TransformationSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: transformationData[index], conditionType: state.layer
            };
            if (index <= transformationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateSilverSequence(body, state.jwt);
            }
        }
        else if (pseudocodeType === "goldSequence") {

            if (goldData.length >= 1) {
                for (let i = 0; i < goldData.length; i++) {
                    eachData = goldData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'GoldSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: goldData[index], conditionType: state.layer
            };
            if (index <= goldData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateGoldSequence(body, state.jwt);
            }
        }
        else if (pseudocodeType === "extraction") {

            if (extractionData.length > 1) {
                for (let i = 0; i < extractionData.length; i++) {
                    eachData = extractionData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }

            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Extraction',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: extractionData[index], conditionType: state.layer
            };

            if (index <= extractionData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateExtractionPseudocode(body, state.jwt);

            }
        } else if (pseudocodeType === "transformation") {

            if (transformationData.length > 1) {
                for (let i = 0; i < transformationData.length; i++) {
                    eachData = transformationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: transformationData[index], conditionType: state.layer
            };
            if (index <= transformationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateTransformationPseudocode(body, state.jwt);
            }
        }
        else if (pseudocodeType === "gold") {

            if (goldData.length >= 1) {
                for (let i = 0; i < goldData.length; i++) {
                    eachData = goldData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: goldData[index], conditionType: state.layer
            };
            if (index <= goldData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                generateGoldPseudocode(body, state.jwt);
            }
        }
        else if (pseudocodeType === "orchestrationDocument") {
            body = { isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'OrchestrationDoc', sampleCode: sampleCode, jwt: state.jwt, conditionType: state.layer };

            if (orchestrationData.length > 1) {
                for (let i = 0; i < orchestrationData.length; i++) {
                    eachData = orchestrationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'OrchestrationDoc',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: pipelineSummaryData, conditionType: state.layer
            };
            if (index <= orchestrationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
                setChatHistory(() => [...data]);
                await insertChatHistory([...data]);
                //PS_CM_1405 - PS_CM_1427
                generateOrchestrationDocument(body, state.jwt);
            }
        }
        else if (pseudocodeType === "unitTestPseudo") {
            generateUnitTestCasePseudocode({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'UnitTestCase', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "dataQualityPsueudocode") {
            generateDataQualityPseudo({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataQualityPsueudocode', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        } else if (pseudocodeType === "codeReviewChecklist") {
            generateChecklistDesign({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'CodeReviewChecklist', sampleCode: sampleCode, conditionType: state.layer }, state.jwt);
        }

    }



    //Consolidated design document download
    async function downloadConsolidateDesign() {
        setLoader(() => true);

        try {
            document.getElementById('pageLoader').style.display = "block"
            var response = await fetchDesignDetails({
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                futureStateName: state.futureStateName,
                methodology: state.Methodology
            }, state.jwt);

            console.log(response, "response consolidate");
            document.getElementById('pageLoader').style.display = "none"

            if (response.body?.statusCode === 403 || response.body?.status === 403) {
                sessionExpired();
            } else if (response.body.statusCode !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            } else {
                console.log(response.body.data.designDetails[0], 'response.body.data.designDetails[0]');
                // Populate dataUML and orchUML
                if (response.body.data.designDetails[0].dataModelSilver !== null) {
                    setDataUML(() => response.body.data.designDetails[0].dataModelSilver);
                    setDownloadUml("DataModel-Silver");
                }

                if (response.body.data.designDetails[0].dataModelGold !== null) {
                    setDataUML(() => response.body.data.designDetails[0].dataModelGold);
                    setDownloadUml("DataModel-Gold");
                }

                if (response.body.data.designDetails[0].orchestrationDiagram !== null) {
                    setOrchUML(() => response.body.data.designDetails[0].orchestrationDiagram);
                    setDownloadOrgUML("OrchestrationDiagram");
                }

                // Handle extraction sequences
                const tables = ['bronzeTable', 'silverTable', 'goldTable'];
                tables.forEach(table => {
                    if (response.body.data[table][0]?.sequence !== null) {
                        for (let i = 0; i < response.body.data[table]?.length; i++) {
                            setOrchUML(() => response.body.data[table][i].sequence);
                            setDownloadOrgUML(response.body.data[table][i].notebookName);
                        }
                    }
                });

                // Create tempArray for Excel sheets
                // changed 29/07
                let tempArray = [];
                const designDetails = response.body.data.designDetails[0];
                tempArray.push({ sheetName: "DataDictionary-Silver", sheetData: JSON.parse(designDetails.dataDictionarySilver) });
                tempArray.push({ sheetName: "DataDictionary-Gold", sheetData: JSON.parse(designDetails.dataDictionaryGold) });
                tempArray.push({ sheetName: "MetadataTable", sheetData: JSON.parse(designDetails.metadataTable) });
                tempArray.push({ sheetName: "DDLScripts-Bronze", sheetData: JSON.parse(designDetails.DDLPseudocodeBronze) });
                tempArray.push({ sheetName: "DDLScripts-Silver", sheetData: JSON.parse(designDetails.DDLPseudocodeSilver) });
                tempArray.push({ sheetName: "DDLScripts-Gold", sheetData: JSON.parse(designDetails.DDLPseudocodeGold) });
                tempArray.push({ sheetName: "PipelineSummary", sheetData: JSON.parse(designDetails.pipelineSummary) });
                tempArray.push({ sheetName: "LoggerNotebook", sheetData: JSON.parse(designDetails.loggerPseudocode) });
                tempArray.push({ sheetName: "UtilitiesNotebook", sheetData: JSON.parse(designDetails.utilitiesPseudocode) });

                tables.forEach(table => {
                    for (let i = 0; i < response.body.data[table]?.length; i++) {
                        tempArray.push({ sheetName: response.body.data[table][i].notebookName, sheetData: JSON.parse(response.body.data[table][i].pseudoCode) });
                    }
                });

                tempArray.push({ sheetName: "UnitTesting", sheetData: JSON.parse(designDetails.unitTestCasePseudocode) });
                tempArray.push({ sheetName: "DataQuality", sheetData: JSON.parse(designDetails.dataQualityPseudocode) });
                tempArray.push({ sheetName: "CodeReviewChecklist", sheetData: response.body.data.codeReviewChecklist });

                console.log(tempArray, "tempArray");

                let sheetsArray = tempArray;

                function truncateSheetName(name) {
                    return name.length > 31 ? name.substring(0, 31) : name;
                }

                // changes 29/07
                function downloadMultipleSheets(sheetsArray) {
                    try {
                        const wb = XLSX.utils.book_new();

                        if (Array.isArray(sheetsArray) && sheetsArray.length > 0) {
                            sheetsArray.forEach(sheetObj => {
                                if (sheetObj && sheetObj.sheetName && sheetObj.sheetData) {
                                    const { sheetName, sheetData } = sheetObj;
                                    const truncatedSheetName = truncateSheetName(sheetName);
                                    const ws = XLSX.utils.json_to_sheet(sheetData);
                                    XLSX.utils.book_append_sheet(wb, ws, truncatedSheetName);
                                } else {
                                    console.warn("Invalid sheet object:", sheetObj);
                                }
                            });

                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const downloadLink = document.createElement('a');
                            downloadLink.href = URL.createObjectURL(blob);
                            downloadLink.download = 'Consolidated_Design_Document.xlsx';
                            downloadLink.click();
                            URL.revokeObjectURL(downloadLink.href);
                        } else {
                            console.warn("sheetsArray is not a valid array or is empty:", sheetsArray);
                        }
                    } catch (error) {
                        console.error("Error in downloadMultipleSheets:", error);
                    } finally {
                        setLoader(() => false);
                    }
                }


                downloadMultipleSheets(sheetsArray);
            }
        } catch (error) {
            console.error("Error in downloadConsolidateDesign:", error);
        } finally {
            setLoader(() => false);
        }
    }


    //Each notebook download
    async function downloadDesign(type, fileName, id) {


        const body = {
            projectId: state.projectId,
            type: type,
            userId: state.userId,
            notebookId: id,
            orgId: state.orgId
        };

        try {
            // PC_CM_101
            const response = await fetchPseudocodeData(body, state.jwt);

            console.log("response", response);
            if (response.statusCode === 403 || response.status === 403) {
                sessionExpired();
            } else if (response.statusCode !== 200) {
                document.getElementById("openErrorModal").click();
            } else {

                console.log('downloadDesignResult', response.data[0])
                const data =
                    type === "extractionRequirement" ? response.data[0].extraction :
                        type === "sourceToExtractionER" ? response.data[0].sourceToBronzeER :
                            type === "BronzeToSilverER" ? response.data[0].bronzeToSilverER :
                                type === "SilverToGoldER" ? response.data[0].silverToGoldER :
                                    type === "dataDictionarySilver" ? response.data[0].dataDictionarySilver :
                                        type === "dataDictionaryGold" ? response.data[0].dataDictionaryGold :
                                            type === "dataModelSilver" ? response.data[0].dataModelSilver :
                                                type === "dataModelGold" ? response.data[0].dataModelGold :
                                                    type === "tableMapping" ? response.data[0].tableMapping :
                                                        type === "metadataTable" ? response.data[0].metadataTable :
                                                            type === "dDLScriptsBronze" ? response.data[0].DDlPseudocodeBronze :
                                                                type === "dDLScriptsSilver" ? response.data[0].DDlPseudocodeSilver :
                                                                    type === "dDLScriptsGold" ? response.data[0].DDlPseudocodeGold :
                                                                        type === "pipelineSummary" ? response.data[0].pipelineSummary :
                                                                            type === "orchestrationUML" ? response.data[0].orchestrationDiagram :
                                                                                type === "extractionSequence" ? response.data[0].sequence :
                                                                                    type === "transformationSequence" ? response.data[0].sequence :
                                                                                        type === "goldSequence" ? response.data[0].sequence :
                                                                                            type === "loggerPseudocode" ? response.data[0].loggerPseudocode :
                                                                                                type === "utilityPseudocode" ? response.data[0].utilitiesPseudocode :
                                                                                                    type === "extraction" ? response.data[0].pseudoCode :
                                                                                                        type === "transformation" ? response.data[0].pseudoCode :
                                                                                                            type === "gold" ? response.data[0].pseudoCode :
                                                                                                                type === "orchestrationDocument" ? response.data[1]?.pseudocode || response.data[0][0]?.pseudocode :
                                                                                                                    type === "unitTestPseudo" ? response.data[0].unitTestCasePseudocode :
                                                                                                                        type === "linkedServices" ? response.data[0].pseudocode :
                                                                                                                            type === "dataset" ? response.data[0].pseudocode :
                                                                                                                                type === "dataflowDesign" ? response.data[0].pseudocode :
                                                                                                                                    type === "dataQualityPsueudocode" ? response.data[0].dataQualityPseudocode : null;




                console.log(data, "data...........");

                //UML code download
                if (type === "orchestrationUML" || type === "extractionSequence" || type === "transformationSequence" || type === "goldSequence"
                    || type === "sourceToExtractionER" || type === "BronzeToSilverER" || type === "SilverToGoldER" || type === 'dataModelSilver' || type === 'dataModelGold') {

                    setDataUML(() => data);
                    setDownloadUml(fileName)
                }
                if (type === "codeReviewChecklist") {
                    let setVal = response.data;
                    let excelNo = 1
                    let UtilitiesArray = setVal.map(data => { return { NotebookName: data.NotebookName, DesignID: data.DesignID, CommandPurpose: data.CommandPurpose, CheckListStep: data.CheckListStep, Status: data.Status } });
                    // Declare variables for file type and extension
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';

                    // Convert UtilitiesArray to sheet
                    const ws = XLSX.utils.json_to_sheet(UtilitiesArray);

                    // Create workbook object
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Code Review Checklist');

                    // Convert workbook to Excel buffer
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                    // Save data in browser memory with FileSaver.js
                    const data = new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, 'Code Review Checklist' + fileExtension);
                }

                //other notebook downloads
                if (type !== "dataModelGold" && type !== "transformationSequence" && type !== "goldSequence" && type !== "extractionSequence" && type !== "dataModelSilver" && type !== "columnMapping" && type !== "orchestrationUML" && type !== "sourceToExtractionER" && type !== "BronzeToSilverER" && type !== "SilverToGoldER" && type !== "codeReviewChecklist") {
                    let dataArray;
                    try {
                        dataArray = JSON.parse(data);
                    } catch (error) {
                        return error;
                    }

                    if (!Array.isArray(dataArray)) return;
                    console.log(dataArray, "dataArray")


                    const ws = XLSX.utils.json_to_sheet(dataArray);

                    // Create workbook
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, fileName);

                    // Generate Excel file and download
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    // PS_612-613
    /**
     * The function `handleSilverER` updates the chat history by hiding the last message, adding a user
     * message, and displaying a popup message.
     */
    const handleSilverER = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });
        data.push({ flow: "ai", type: "getSilverERPopup", phase: "Define", show: true });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    /**PS_641-PS_642
     * The function `handleGoldER` updates the chat history by hiding the last message, adding a user
     * text message, and displaying a popup message.
     */
    const handleGoldER = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });
        data.push({ flow: "ai", type: "getGoldERPopup", phase: "Define", show: true });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    const handleFRDPopup = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });
        data.push({ flow: "ai", type: "text", text: "Congratulations! You've successfully completed define phase." });
        data.push({ flow: "center", type: "text", text: "Design", phase: "Design" })
        data.push({
            flow: "ai",
            type: "text",
            text: "Let's move towards generating design document",
            phase: "Design"
        });
        data.push({ flow: "ai", type: "definePopup", phase: "Design", show: true });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };
    /**
     * PS_CD_1.312 - PS_CD_1.316
     */
    const handleDeploy = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });
        data.push({
            flow: "ai",
            type: "text",
            text: "Development phase completed successfully.",
        });

        if (state.futureStateName === 'Databricks' || state.futureStateName === 'ADF' || (state.futureStateName === 'Synapse' && state.Methodology === 'pipeline')) {
            data.push({
                flow: 'center',
                phase: 'Deploy',
                text: 'Deploy'
            });
            data.push({
                flow: 'ai',
                type: 'deploy',
                phase: 'Deploy',
                show: true
            });
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    /**
     * PS_CD_1.317 - PS_CD_1.325
     */
    function updateValues() {
        let data = [...chatHistory];
        let temp = 0;
        let arr = [];
        for (let a of data) {
            if (temp === 0) {
                if (a.type === "deploy") {
                    a.show = true;
                    temp = 1;
                }
                arr.push(a);
            }
        }
        setChatHistory(() => [...arr]);
        insertChatHistory([...data]);
        // setIsEditNotebook(() => true)
    }
    async function dataChanged() {

        let data = [...chatHistory];

        let temp = 0;

        let arr = [];

        let arr2 = []

        for (let a of data) {

            if (temp === 0) {

                if (a === codeDataValues) {

                    a.show = true;

                    temp = 1;
                }
                arr.push(a);
            } else {
                arr2.push(a)
            }
        }


        const toDeleteChatHistoryData = arr2;
        // Filter data to be deleted from DB 
        const deleteDBRecord = toDeleteChatHistoryData.filter(obj => obj.tableName === "defineDetails" || obj.tableName === "designDetails" || obj.tableName === "bronzeTable" || obj.tableName === "silverTable" || obj.tableName === "goldTable" || obj.tableName === "pipelineDetails" || obj.tableName === "codeReviewChecklist");

        if (deleteDBRecord.length !== 0) {
            const body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                deleteDBRecord: deleteDBRecord
            };

            const response = await deleteDBRecords(body, state.jwt);


            // Validate response
            if (response.status !== 200) {
                // Show error message
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }

        }

        setChatHistory(() => [...arr]);
        insertChatHistory([...arr]);

    }

    async function popupdataChanged(deleteParam, Paramvalue) {


        let data = [...chatHistory];

        let temp = 0;

        let arr = [];

        let arr2 = []

        for (let a of data) {

            if (temp === 0) {

                if (JSON.stringify(a) === JSON.stringify(deleteParam)) {

                    // a.show = true;

                    temp = 1;
                }
                arr.push(a);
            } else {
                arr2.push(a)
            }
        }


        const toDeleteChatHistoryData = arr2;
        // Filter data to be deleted from DB 
        const deleteDBRecord = toDeleteChatHistoryData.filter(obj => obj.tableName === "defineDetails" || obj.tableName === "designDetails" || obj.tableName === "bronzeTable" || obj.tableName === "silverTable" || obj.tableName === "goldTable" || obj.tableName === "pipelineDetails" || obj.tableName === "codeReviewChecklist");

        if (deleteDBRecord.length !== 0) {
            const body = {
                deletPopupName: Paramvalue,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                deleteDBRecord: deleteDBRecord
            };

            const response = await deleteDBRecords(body, state.jwt);


            // Validate response
            if (response.status !== 200) {
                // Show error message
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }

        }
        console.log(arr, "popopopopoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo")
        // setChatHistory(() => [...arr]);
        // insertChatHistory([...arr]);
        return arr;
    }
    //total verified
    function totalVerified() {

        console.log("totalverifiedddddd");
        let data = [...chatHistory];
        const index = data.findIndex(item => item.hyperlinkText === codeDataValues.hyperlinkText);
        if (data[index + 1] === undefined) {
            data[index].show = true;
            data[index].verified = true;
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    }


    /**
     * PS_TSV_10 - PS_TSV_30
     */
    /**
     * PS_CD_1.25.1
     */
    async function deployNotebooksDatabricks(a, b) {
        let body;
        console.log(targetSystemDetails);
        if (state.futureStateName === "Databricks") {
            body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                userName: targetSystemDetails.userName?.userName,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                targetSystemName: "Databricks"
            };
        }

        let data = [...chatHistory];
        if (a === "1") {
            data[1].deployed = true;
            data[1].targetdetails = targetSystemDetails;
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: "Target system details" });
            setChatHistory(() => [...data]);
        } else if (a === 2) {
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: b });
            setChatHistory(() => [...data]);
        }
        setLoader(() => true);
        const deployResponse = await deployNotebook(body, state?.jwt);
        console.log(deployResponse);
        setLoader(() => false);
        if (deployResponse.status !== 200) {

            data.push({ flow: "ai", type: "somethingDeploy", show: true });
        } else if (deployResponse.status === 200) {
            setDeployStatus(false)
            data.push({
                flow: "ai",
                type: "text",
                text: `Congratulations! You've successfully completed deployment.`,
            });
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    }

    //PS_CM_1497 - PS_CM_1513
    async function generateLoggerCode(code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);
        data.push({ flow: "user", type: "text", text: "Looks Good" });

        data.push({ flow: "ai", type: "text", text: "Let's move towards generating Logger Notebook", phase: "Development" });
        data.push({ flow: "ai", type: "loadShow", docName: "Logger Notebook", referenceDocument: ["Design - Logger Pseudocode"], show: true, phase: "Development" });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

        const res = await generateLoggerAndPipelineJSON(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "logger",
                sampleCode: code,
            },
            state.jwt
        );
        console.log("logger res", res);

        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1514 - PS_CM_1530
    async function generateUtilitiesCode(code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Utility Notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Utility Notebook",
                referenceDocument: ["Design - Utility Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Utility Notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Utility Notebook",
                referenceDocument: ["Design - Utility Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);
        const res = await generateUtility(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "utilities",
                sampleCode: code,
            },
            state.jwt
        );
        console.log("utilities res", res);
        setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1202 - PS_CM_1217
    async function generateMetadataCode(code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Metadata Code",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Metadata Code",
                referenceDocument: ["Metadata Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Metadata code",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Metadata Code",
                referenceDocument: ["Metadata Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);
        const res = await generateMetadata(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "meta",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {
            {

                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }

        }
    }
    //PS_CM_1218 - PS_CM_1233
   
    async function generateDDLScripts(code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);
        if (state.layer === 'Medallion') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating DDL Scripts Code`, phase: 'Development' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'DDL Script Code', referenceDocument: ["Bronze DDL Scripts", "Silver DDL Scripts", "Gold DDL Scripts"], show: true, phase: 'Development' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });
        }
        if (state.layer === 'Bronze') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating DDL Scripts Code`, phase: 'Development' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'DDL Script Code', referenceDocument: ["Bronze DDL Scripts"], show: true, phase: 'Development' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });
        }
        if (state.layer === 'Silver') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating DDL Scripts Code`, phase: 'Development' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'DDL Script Code', referenceDocument: ["Silver DDL Scripts"], show: true, phase: 'Development' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });
        }
        if (state.layer === 'Gold') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good'});
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating DDL Scripts Code`, phase: 'Development' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'DDL Script Code', referenceDocument: ["Gold DDL Scripts"], show: true, phase: 'Development' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });
        }
        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

console.log(state.layer)
        const res = await generateDDL(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "ddl",
                sampleCode: code,
                conditionType: state.layer
            },
            state.jwt
        );
        console.log(res);
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {
            {

                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        }
    }
    //PS_CM_1234 - PS_CM_1249
    async function generateExtractionCode(noteId, noteName, code) {
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        setShowlooksGood(() => false);
        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Extraction Notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Extraction Notebook",
                referenceDocument: [`Extraction Pseudocode ${noteName}`],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

            // {
            //     flow: "ai",
            //     type: "textwait",
            //     text: "Your Extraction notebook are getting generated. Please wait for some time!",
            //     id: "extractionMessage",
            //     time: new Date()
            // },
        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Extraction Notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Extraction Notebook",
                referenceDocument: [`Extraction Pseudocode ${noteName}`],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);

        const res = await generateBronze(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "Extraction",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
                time: new Date()
            },
            state.jwt
        );
        // call the generate extraction notebook api
        console.log(res);
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1250 - PS_CM_1265
    async function generateSilverCode(noteId, noteName, code) {
        let data = [...chatHistory];

        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Transformation Silver notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Transformation Silver",
                referenceDocument: [`Transformation Pseudocode ${noteName}`, `SilverZoneTransformations`, 'FRD-GenericRequirements', 'Utilities', 'MetadataTable'],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Transformation Silver notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Transformation Silver",
                referenceDocument: [`Transformation Pseudocode ${noteName}`],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }
            ,
        ]);

        const res = await generateSilver(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "silver",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
            },
            state.jwt
        );
        // call the generate extraction notebook api
        console.log(res);
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1382 - PS_CM_1404
    async function generateGoldCode(noteId, noteName, code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Transformation Gold notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Transformation Gold",
                referenceDocument: [`Gold Pseudocode ${noteName}`, `GoldZoneTransformations`, 'FRD-GenericRequirements', 'Utilities', 'MetadataTable'],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

            // {
            //     flow: "ai",
            //     type: "textwait",
            //     text: "Your Gold notebook is getting generated. Please wait for some time!",
            //     id: "goldMessage",
            //     time: new Date()
            // },
        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Transformation Gold notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Transformation Gold",
                referenceDocument: [`Gold Pseudocode ${noteName}`],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);

        const res = await generateGoldDevCode(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "gold",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
            },
            state.jwt
        );
        // call the generate extraction notebook api
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //This function should be invoke for the design phase 
    async function generateCodeReviewChecklist() {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
                { flow: "ai", type: "text", text: "Let's move towards generating Code Review Checklist Notebook", phase: "Development" },
                {
                    flow: "ai", type: "loadShow", docName: "Code review Checklist",
                    referenceDocument: ['Design - Extraction Notebook', 'Design - Silver Notebook', 'Design - Gold Notebook'],
                    show: true, phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }


        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
                { flow: "ai", type: "text", text: "Let's move towards generating Code Review Checklist Notebook", phase: "Development" },
                {
                    flow: "ai", type: "loadShow", docName: "Code review Checklist",
                    referenceDocument: ['Design - Extraction Notebook'],
                    show: true, phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

        ]);

        const res = await generateChecklistDev(
            { projectId: state.projectId, orgId: state.orgId, userId: state.userId, Methodology: state.methodology, futureStateName: state.futureStateName },
            state.jwt
        );
        // call the generate extraction notebook api
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
    
            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1298 - PS_CM_1314
    async function generateUnitTestCaseCode(code) {

        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            { flow: "ai", type: "text", text: "Let's move towards generating Unit Test Case Code", phase: "Development" },
            { flow: "ai", type: "loadShow", docName: "Unit Test Case Code", referenceDocument: ["Design - Unit Test Case Pseudocode"], show: true, phase: "Development" },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }
        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks Good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Unit Test Case Code",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Unit Test Case Code",
                referenceDocument: ["Design - Unit Test Case Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' }

      
        ]);

        const res = await generateTestCaseCode(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "UnitTestCase",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        // setLoader(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }
    //PS_CM_1315 - PS_CM_1330
    async function generateDataQualityCode(code) {
        setShowlooksGood(() => false);


        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });

        data.push({ flow: "ai", type: "text", text: "Let's move towards generating Data Quality Code", phase: "Development" });
        data.push({ flow: "ai", type: "loadShow", docName: "Data Quality", referenceDocument: ["Design - Data Quality"], show: true, phase: "Development" });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Development' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        const res = await genrateDQualityCode({ projectId: state.projectId, orgId: state.orgId, userId: state.userId, type: "dataQuality", sampleCode: code, }, state.jwt);
        console.log(res);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    async function generateDataDictionarySilver(code) {

        let data = [...chatHistory];

        data[data.length - 1].show = false;
        // setLoader(() => true);

        data.push({ flow: "user", type: "text", text: "Looks Good", phase: "Design" });

        data.push({ flow: "ai", type: "text", text: "Let's move towards generating Silver Data Dictionary", phase: "Design" });
        data.push({ flow: "ai", type: "loadShow", docName: "Silver Data Dictionary ", referenceDocument: ["SilverZoneTransformation -FRD"], show: true, phase: "Design" });
        data.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Design" });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

        const res = await generateDataDictionaryPseudocode({ DDType: 'silver', isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataDictionarySilver', sampleCode: code }, state.jwt);

        // setLoader(() => false);
        // setShowlooksGood(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    async function generateDataDictionaryGold(code) {
        console.log("generateDataDictionarySilver in")

        let data = [...chatHistory];

        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good", phase: "Design" });
        data.push({ flow: "ai", type: "text", text: "Let's move towards generating Gold Data Dictionary", phase: "Design" });
        data.push({ flow: "ai", type: "loadShow", docName: "Gold Data Dictionary ", referenceDocument: ["GoldZoneTransformation -FRD"], show: true, phase: "Design" });
        data.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Design" });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);


        const res = await generateDataDictionaryPseudocode({ DDType: 'gold', isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataDictionaryGold', sampleCode: code }, state.jwt);

        console.log(res, "generateDataDictionaryGold Response")
        setLoader(() => false);
        // setShowlooksGood(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    // PS_CM_683 - PS_CM_698
    async function generateBronzeER() {
        let data = [...chatHistory];
        data[data.length - 1].show = false;

        // Add objects to the existing data array
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Define' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Source to Extraction Flow ', phase: 'Define' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'Source to Extraction Flow', referenceDocument: ['TableRequirementSheet',"Source System details","Inventory"], show: true, phase: 'Define' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

        const res = await generateSourceToExtractionER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'bronzeER' }, state.jwt);

        if (res.status === 403 || res.statusCode === 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    async function generateSilverER(code, isNeedUpdate) {
        console.log("generateSilverER in")

        let arr = [...chatHistory];
        arr[arr.length - 1].show = false;


        console.log(state, "state");
        if (isNeedUpdate === "true") {
            // Add objects to the existing data arra

            arr.push({ flow: 'user', type: 'text', text: 'Silverzone Requirements Details', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Extraction to Silver Flow', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'loadShow', docName: 'Extraction To Silver flow', referenceDocument: ['SilverZoneTransformation - FRD','Inventory'], show: true, phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });

            setChatHistory((a) => [...arr]);
            insertChatHistory([...arr]);
//
        } else if (isNeedUpdate === "false") {
            console.log(isNeedUpdate)
        }
        const res = await generateBronzeToSilverER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'silverER', sampleCode: code, conditionType: state.layer }, state.jwt);

        // setShowlooksGood(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    async function generateGoldER(code, isNeedUpdate) {
        console.log("generateGoldER in")

        let arr = [...chatHistory];
        arr[arr.length - 1].show = false;



        console.log(state, "state");
        if (isNeedUpdate === "true") {
            // Add objects to the existing data arra

            arr.push({ flow: 'user', type: 'text', text: 'Goldzone Requirements Details', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver to Gold Flow', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'loadShow', docName: 'Silver to Gold', referenceDocument: ['GoldZoneTransformation - FRD'], show: true, phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });

            setChatHistory((a) => [...arr]);
            insertChatHistory([...arr]);

        } else if (isNeedUpdate === "false") {

        }
        const res = await generateSilverToGoldER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'SilverToGoldER', sampleCode: code, conditionType: state.layer }, state.jwt);
        // setShowlooksGood(() => false);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }
        if (res.status !== 200) {


            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    async function generateDataModelSilver() {


        let data = [...chatHistory];

        data[data.length - 1].show = false;

        // Add objects to the existing data array
        data.push({ flow: 'user', type: 'text', text: 'Let\'s Start ', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver Data Model', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'silver data model', referenceDocument: ['SilverZoneTransformation-FRD'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);


        const res = await generateDataModelSilverUML({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataModelSilver', chatHistory: data }, state.jwt);


        if (res.status === 403 || res.statusCode === 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    async function generateDataModelGold() {


        let data = [...chatHistory];

        data[data.length - 1].show = false;
        // Add objects to the existing data array
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Gold Data Model', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'Gold Data Model', referenceDocument: ['GoldZoneTransformations-FRD'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

        console.log(chatHistory, "chatHistorydmodel");


        const res = await generateDataModelGoldUML({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataModelGold' }, state.jwt);

        if (res.status === 403 || res.statusCode === 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    //PS_CM_766 - PS_CM_783
    async function generateMetadataTable(code, pathData) {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        console.log(data[data.length - 1], 'data[data.length - 1]data[data.length - 1]');

        if (data && data.length > 0) {
            const lastElement = data[data.length - 1];

            if (lastElement && lastElement.type === 'tablePathPopup') {
                console.log(tablePathDetails, pathData, 'tablePathDetailstablePathDetails123');
                if (!lastElement.hasOwnProperty('tablePathDetails')) {
                    lastElement.tablePathDetails = {}; // Or null, [], etc.
                }
                lastElement.tablePathDetails = pathData;
            }
        }
        if (state.layer === 'Medallion') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Metadata Table document', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Metadata Table', referenceDocument: ['Source To Bronze Mapping', 'TableRequirement', 'SilverZoneTransformation', 'GoldZoneTransformations', 'SilverDD', 'GoldDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }
        if (state.layer === 'Bronze') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Metadata Table document', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Metadata Table', referenceDocument: ['Source To Bronze Mapping', 'TableRequirement'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }
        if (state.layer === 'Silver') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Metadata Table document', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Metadata Table', referenceDocument: ['SilverZoneTransformation', 'SilverDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }
        if (state.layer === 'Gold') {
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Metadata Table document', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Metadata Table', referenceDocument: ['GoldZoneTransformations', 'GoldDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }


        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        const res = await generateMetadataTablePseudo({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'MetadataTable', sampleCode: code, conditionType: state.layer }, state.jwt);

        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }


    async function generateDDLBronzePseudo(code) {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Bronze DDL Scripts', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'Bronze DDL Script', referenceDocument: ['Source To Bronze Mapping'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        //PS_CM_784 
        const res = await generateDDLPseudocode({ DDLType: "bronze", isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: code }, state.jwt);
        if (res.status === 403 || res.statusCode === 403) {
            sessionExpired()
            return;
        }

        if (res.status !== 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    async function generateDDLSilverPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

        try {
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver DDL Scripts', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Silver DDL Script', referenceDocument: ['SilverDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            const res = await generateDDLPseudocode({
                DDLType: "silver",
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DDLScripts',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating DDL Silver pseudocode:", error);
            // Handle error appropriately
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    async function generateDDLGoldPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

        try {
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Gold DDL Scripts', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Gold DDL Script', referenceDocument: ['GoldDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateDDLPseudocode({
                DDLType: "gold",
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DDLScripts',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating DDL Gold pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_842 - PS_CM_886

    async function generatePipelineSummary(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

        try {
       if(state.layer ==='Medallion')
            {data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Pipeline Summary', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Pipeline Summary', referenceDocument: ['GoldZoneTransformations', 'SilverZoneTransformations', 'TableRequirementSheet'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }
        if(state.layer ==='Silver')
            {data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Pipeline Summary', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Pipeline Summary', referenceDocument: ['SilverZoneTransformations'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }
        if(state.layer ==='Gold')
            {data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Pipeline Summary', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Pipeline Summary', referenceDocument: ['GoldZoneTransformations'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
        }

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generatePipelineSummaryPseudo({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'PipelineSummary',
                sampleCode: code,
                conditionType: state.layer
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }


            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating pipeline summary pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_865 - PS_CM_887
    async function generateOrchestrationUML() {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

        try {

            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Orchestration diagram', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Orchestration diagram', referenceDocument: ['Pipeline Summary'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateOrchestrationUMLCode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'OrchestrationUML',
                conditionType: state.layer
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }


            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating orchestration UML code:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    async function generateLogger(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Logger Pseudocode', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'logger notebook', referenceDocument: ['FRD - Generic Requirements'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateLoggerPseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'LoggerPseudocode',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating logger pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_983 - PS_CM_1005
    async function generateUtilityPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Utility Pseudocode', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Utility Pseudocode', referenceDocument: ['FRD - Generic Requirements'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateUtilityPseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'utilityPseudocode',
                sampleCode: code
            }, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating utility pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_662 - PS_CM_682
    async function generateExtractionRequirement(code, isNeedUpdate) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let arr = [...chatHistory];

        try {
            if (isNeedUpdate === "true") {
                arr.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Define" });
                arr.push({ flow: "ai", type: "text", text: "Let's move towards generating Extraction requirement document", phase: "Define" });
                arr.push({ flow: "ai", type: "loadShow", docName: "extraction requirement", referenceDocument: ["Table Requirement", "Source system", "Inventory"], show: true, phase: "Define" });
                arr.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Define" });

                setChatHistory([...arr]);
                await insertChatHistory([...arr]);
            }
            else if (isNeedUpdate === "false") {
                console.log(isNeedUpdate)
            }
            const res = await generateExtractionRequirementData({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'TableRequirement',
                sampleCode: code,
                jwt: state.jwt
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                let a = [...chatHistory];
                a.pop();
                a[a.length - 1].show = true;
                setChatHistory([...a]);
            }
        } catch (error) {
            console.error("Error generating extraction requirement:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }



    let isOperationInProgress = false; // Global flag to manage ongoing operations
    //PS_CM_888 - PS_CM_911
    async function generateExtractionSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };
        console.log(object, 'objectobjectobjectobject');


        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'ExtractionSequence',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });


            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Extraction Sequence - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Extraction-FRD', 'SourceSystem'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);

            const res = await generateBronzeSequence(body, state.jwt);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating Extraction Sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }
    //PS_CM_912 -  PS_CM_935
    async function generateTransformationSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation Sequence - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['SilverZoneRequirements', 'SilverDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);

            const res = await generateSilverSequence(body, state.jwt);
            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }


    async function generateTransformationGoldSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation Gold - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['GoldZoneRequirements','goldDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);

            const res = await generateGoldSequence(body, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation gold sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1007 - PS_CM_1029
    async function generateExtraction(object, code, notebookId) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Extraction',
                sampleCode: code,
                notebookId: notebookId,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Extraction- ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Extraction-FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateExtractionPseudocode(body, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating extraction:", error);
            // Handle errors if needed
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }
    //PS_CM_1358 - PS_CM_1381

    async function generateTransformationSilver(object, code, notebookId) {
        try {
            // Prevent page refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName,
                notebookId: notebookId
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['SilverZoneTransformations - FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);


            const res = await generateTransformationPseudocode(body, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation silver:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }



    //PS_CM_1054 - PS_CM_1077
    async function generateGold(object, code, notebookId) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";
            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName,
                notebookId: notebookId
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });


            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Gold -${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['GoldZoneTransformation - FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateGoldPseudocode(body, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating gold:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1078 - PS_CM_1100
    async function generateOrchestrationDoc(object, code, notebook) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const notebookName = notebook;
            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'orchestrationDocument',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Orchestration document`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Pipeline Summary', 'Source system'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);


            await new Promise(resolve => setTimeout(resolve, 5000)); // Simulate a delay
            const res = await generateOrchestrationDocument(body, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating orchestration document:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }



    //PS_CM_1101 - PS_CM_1123
    async function generateUnitTestCase(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            if(state.layer === 'Medallion')
          {  data.push({ flow: 'ai', type: 'text', text: `Let\'s move towards generating the Unit Test Case Pseudocode`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'unit testcase', referenceDocument: ['Design - Extraction Pseudocode', 'Design - Silver Pseudocode', 'Design - Gold Pseudocode'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
}
if(state.layer === 'Bronze')
    {  data.push({ flow: 'ai', type: 'text', text: `Let\'s move towards generating the Unit Test Case Pseudocode`, phase: 'Design' });
      data.push({ flow: 'ai', type: 'loadShow', docName: 'unit testcase', referenceDocument: ['Design - Extraction Pseudocode'], show: true, phase: 'Design' });
      data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
}
if(state.layer === 'Silver')
    {  data.push({ flow: 'ai', type: 'text', text: `Let\'s move towards generating the Unit Test Case Pseudocode`, phase: 'Design' });
      data.push({ flow: 'ai', type: 'loadShow', docName: 'unit testcase', referenceDocument: ['Design - Silver Pseudocode'], show: true, phase: 'Design' });
      data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
}
if(state.layer === 'Gold')
    {  data.push({ flow: 'ai', type: 'text', text: `Let\'s move towards generating the Unit Test Case Pseudocode`, phase: 'Design' });
      data.push({ flow: 'ai', type: 'loadShow', docName: 'unit testcase', referenceDocument: ['Design - Gold Pseudocode'], show: true, phase: 'Design' });
      data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });
}


            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateUnitTestCasePseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'UnitTestCase',
                sampleCode: code,
                conditionType: state.layer
            }, state.jwt);

            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating unit test case:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1124 - PS_CM_1146
    async function generateDataQuality(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });


            data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Data Quality Pseudocode", phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Data Quality Pseudocode', referenceDocument: ['FRD-Test Strategy'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateDataQualityPseudo({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DataQualityPsueudocode',
                sampleCode: code,
            }, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating data quality pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    //Should be invoked in the development phase

    //PS_CM_1147 - PS_CM_1169
    async function generateCodeReviewChecklistDesign(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });

           if(state.layer === 'Medallion')
           { data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Code Review Checklist", phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Code Review Checklist', referenceDocument: ['Design - Extraction Pseudocode', 'Design - Silver Pseudocode', 'Design - Gold Pseudocode'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });}
            
           if(state.layer === 'Bronze')
            { data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Code Review Checklist", phase: 'Design' });
             data.push({ flow: 'ai', type: 'loadShow', docName: 'Code Review Checklist', referenceDocument: ['Design - Extraction Pseudocode'], show: true, phase: 'Design' });
             data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });}
             
           if(state.layer === 'Silver')
            { data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Code Review Checklist", phase: 'Design' });
             data.push({ flow: 'ai', type: 'loadShow', docName: 'Code Review Checklist', referenceDocument: ['Design - Silver Pseudocode'], show: true, phase: 'Design' });
             data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });}
             
           if(state.layer === 'Gold')
            { data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Code Review Checklist", phase: 'Design' });
             data.push({ flow: 'ai', type: 'loadShow', docName: 'Code Review Checklist', referenceDocument: ['Design - Gold Pseudocode'], show: true, phase: 'Design' });
             data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Design' });}

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);

            const res = await generateChecklistDesign({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'CodeReviewChecklist',
                sampleCode: code,
                conditionType: state.layer
            }, state.jwt);


            if (res.status === 403 || res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating code review checklist:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    function generateConsolidateDesignDoc() {
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };
        try {
            // No async operations, so no need for unload prevention here
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks Good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: "Congratulations! You've Successfully completed the design phase.", phase: 'Design' });
            data.push({ flow: 'ai', type: 'consolidateDesign', text: 'You can download the Consolidated Design Document here:', hyperlinkText: 'Consolidated Design Document', pseudocodeType: 'ConsolidatedDesignDocument', id: 'ConsolidatedDesignDocument', show: true, phase: 'Design' });

            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);
        }
        catch (error) {
            console.error("Error generating code review checklist:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    //PS_CD_1.24
    function getCodePageDetails(data) {
        if (chatContainerRef.current) {
            setScrollPosition(chatContainerRef.current.scrollTop);
          }
        setShowCode(true);
        setCodeDataValues(data);
        console.log(data);
    }
    //PS_04-PS_10
    /**
     * The function updates a chat history array based on the type provided and adds new entries
     * accordingly.
     */
    function overallFunction(type, obj) {

        let value = [...chatHistory];
        value = value.map((data) => {
            if (data.type === "getType") {
                return { ...data, show: false };
            } else {
                return data;
            }
        });

        switch (type) {
            case "level1":
                value.push({ flow: "user", type: "text", text: "Development", phase: "Development" });
                break;
            case "level2":
                value.push({ flow: "user", type: "text", text: "Design", phase: "Design" });
                break;
            case "level3":
                value.push({ flow: "user", type: "text", text: "Define", phase: "Define" });
                break;

            default:
                break;
        }
        value.push({ flow: "ai", type: "getConnectionPopup", show: true });
        setChatHistory((a) => [...value]);
        insertChatHistory([...value]);

    }


    function saveDesignDocument(details, data) {
        
        try {
            let sampleCode = "";

            // Check the status of the details
            if (details.status !== 200) {
                return;
            }

            let arr = [...data];
            let designData = details?.data?.data ?? details;

            console.log(designData, "designData");

            let obj = {};

            // Check the phase and assign values accordingly
            if (sourcePhase === "Design" || sourcePhase === "Define" || sourcePhase === "Development") {
                if (designData?.defineDetails?.[0]?.metadataTable || designData?.metadataTable || designData?.designDetails?.[0]?.metadataTable) {
                    obj.metadata = "";
                }
                if (designData?.defineDetails?.[0]?.loggerPseudocode || designData?.loggerPseudocode || designData?.designDetails?.[0]?.loggerPseudocode) {
                    obj.loggerPseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.utilitiesPseudocode || designData?.utilitiesPseudocode || designData?.designDetails?.[0]?.utilitiesPseudocode) {
                    obj.utilitiesPseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeBronze || designData?.DDLPseudocodeBronze || designData?.designDetails?.[0]?.DDLPseudocodeBronze) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeSilver || designData?.DDLPseudocodeSilver || designData?.designDetails?.[0]?.DDLPseudocodeSilver) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeGold || designData?.DDLPseudocodeGold || designData?.designDetails?.[0]?.DDLPseudocodeGold) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.unitTestCasePseudocode || designData?.unitTestCasePseudocode || designData?.designDetails?.[0]?.unitTestCasePseudocode) {
                    obj.unitTestCasePseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.dataQualityPseudocode || designData?.dataQualityPseudocode || designData?.designDetails?.[0]?.dataQualityPseudocode) {
                    obj.dataQualityPseudocode = " ";
                }
            } 

            // Handle tables
            if (designData?.bronzeTable?.length > 0) {
                obj.Extraction = designData.bronzeTable.map(value => ({
                    notebookId: value.bronzeId,
                    notebookName: value.notebookName,
                }));
            }
            if (designData?.silverTable?.length > 0) {
                obj.Silver = designData.silverTable.map(value => ({
                    notebookId: value.silverId,
                    notebookName: value.notebookName,
                }));
            }
            if (designData?.goldTable?.length > 0) {
                obj.Gold = designData.goldTable.map(value => ({
                    notebookId: value.goldId,
                    notebookName: value.notebookName,
                }));
            }

            // Handle pipeline details
            if (["Databricks", "Snowflake", "Redshift", "AWS Glue"].includes(state.futureStateName) ||
                (state.futureStateName === "Synapse" && state.Methodology === "notebook")) {
                if (designData?.pipelineDetails?.length > 0) {
                    obj.Orchestration = designData.pipelineDetails;
                }
            }

            // Update design details
            data[1].designData = obj;
            data[1].definefileName = details.fileName;
            data[1].designData.FRDName = details.fileName;
            data[1].FRDName = details.fileName;
            

            console.log(obj, "obj");

            setDesignDetails(() => obj);

            setShowDesignPopup(false);

            // Handle chat history
            for (let i = 0; i < arr.length; i++) {
                if (arr[i]?.hyperlinkText === "Consolidated Design Document") {
                    if (arr[2]?.phase === "Design" || arr[2]?.phase === "Define") {
                        arr[data.length - 1].show = false;
                        arr.push({ flow: "user", type: "text", text: "Looks Good", phase: "Design" });
                        arr.push({ flow: "center", type: "text", text: "Development", phase: "DesignToDevelopment" });
                    }
                }
            }

            for (let i = 0; i < arr.length; i++) {
                if (arr[i]?.hyperlinkText === "Consolidated Design Document") {
                    if (arr[arr.length - 1].flow === "center" && arr[arr.length - 1].text === "Development" && arr[arr.length - 1].phase === "DesignToDevelopment") {
                        arr.push({ flow: "ai", type: "text", text: "Let's move towards generating code documentations,", phase: "Development" });
                        arr.push({
                            flow: "ai",
                            type: "codeShow",
                            text: "You can find the Metadata Table here,",
                            hyperlinkText: "Metadata Table",
                            codeShowTitle: "Metadata Table",
                            codeNotebookName: "Metadata Table",
                            codeType: "metadataTable",
                            id: "metadata",
                            show: true,
                        });
                    }
                }
            }

            if (sourcePhase === "Define" && !popupPhase === "Design") {

                generateExtractionRequirement(sampleCode, "true");
            }
            if (sourcePhase === "Development") {
                arr.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: "Development" });
                arr.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: "Development" });
            }
            setChatHistory(a => [...arr]);
            insertChatHistory([...arr]);

        } catch (error) {
            console.error("Error in saveDesignDocument:", error);
        } finally {

        }
    }


    async function letsStartwithGeneration() {

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        let sampleCode = "";
        if (state.layer == "Bronze") {
        handleTablepathPopup()
        }
        else if (state.layer == "Silver" || state.layer == "Medallion") {
        generateDataModelSilver(sampleCode);
        }
        else if (state.layer == "Gold") {
        generateDataModelGold(sampleCode);
        }
    }
    //PS_CM_40 - PS_CM_69
    async function updateChatHistory(defineDetailsData, condition_type) {
        let data = [...chatHistory];
        console.log(3, "defineDetailsData");

        const isEmpty = Object.keys(defineDetailsData).length === 0 || Object.keys(defineDetailsData.data?.data ?? {}).length === 0;
        console.log(isEmpty, "isEmpty");

        if (defineDetailsData.status !== 200 || isEmpty) {
            data = data.map(item => {
                if (["designPopup", "definePopup", "tableRequirementPopup", "getSilverERPopup", "getGoldERPopup"].includes(item.type)) {
                    item.show = true;
                    return item;
                }
                return item;
            });
            insertChatHistory([...data]);
            data.push({ flow: "ai", type: "text", text: "Something went wrong! Try again later!" });
            setChatHistory([...data]);
            return;
        }

        const phase = popupPhase; // This indicates the phase the popup was initiated from
        console.log("Current Phase:", phase);

        const popupTypes = {
            "Define": ["tableRequirementPopup", "getSilverERPopup", "getGoldERPopup", "definePopup"],
            "Design": ["tableRequirementPopup", "definePopup"],
            "Development": ["designPopup"]
        };

        const updateCommonDetails = () => {
            const commonDetails = {
                defineData: data[1].defineData, ...defineDetailsData.data.data.defineDetails[0],
                definefileName: defineDetailsData.filename,
                FRDName: defineDetailsData.filename,
                dwhSubjectAreaOptions: defineDetailsData.dwhSubjectAreaOptions,
                selectedDwhSubjectArea: defineDetailsData.selectedDwhSubjectArea,
                timeZone: defineDetailsData.timeZone,
            };
            data = data.map((item, index) => {
                if (index === 1) Object.assign(item, commonDetails);
                return item;
            });
        };

        const addApprovalChatMessages = () => {
            data.push({ flow: "user", type: "text", text: "Define document details", phase: popupPhase });
            data.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: popupPhase });
            data.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: popupPhase });
        };



        if (phase === "Define") {
            updateCommonDetails();

            const newDetails = { ...data[1].defineData, ...defineDetailsData?.data?.data?.defineDetails[0] }
            console.log(newDetails, data[1].defineData, '...newDetails');

            if (data.length > 1) {
                if (!data[1].defineData) {
                    data[1].defineData = {};
                    data[1].defineData = newDetails
                } else {
                    data[1].defineData = newDetails
                }
            }
            if (condition_type === "TableReqPop") {
                data[1].tableReqData = defineDetailsData.data.data.defineDetails[0];
                data[1].tableReqfileName = defineDetailsData.filename;

                data[1].defineData.tableReqSheetName = defineDetailsData.filename;

                data[1].defineData.silverZoneSheetName = '';
                data[1].defineData.goldZoneSheetName = '';
                data[1].defineData.FRDName = '';
                data[1].FRDName = '';


                console.log(data[1].tableReqData, 'data[1].tableReqData');
                console.log(data[1].tableReqfileName, 'data[1].tableReqfileName');

                setLoader(true);
                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                const chat_response = await popupdataChanged({ flow: 'ai', type: 'tableRequirementPopup', phase: "Define", show: false }, "TableReqPop");
                data = [...chat_response];

                data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Define" });
                data.push({ flow: "ai", type: "text", text: "Let's move towards generating Extraction requirement document", phase: "Define" });
                data.push({ flow: "ai", type: "loadShow", docName: "extraction requirement", referenceDocument: ["Table Requirement", "Source system", "Inventory"], show: true, phase: "Define" });
                data.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Define" });

                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setChatHistory([...data]);
                insertChatHistory([...data]);

                generateExtractionRequirement(" ", "false");

            } else if (condition_type === "getGoldERPopup") {
                data[1].goldReqData = defineDetailsData.data.data.defineDetails[0];
                data[1].goldReqfileName = defineDetailsData.filename;


                data[1].defineData.goldZoneSheetName = defineDetailsData.filename;
                data[1].defineData.FRDName = '';
                data[1].FRDName = '';

                console.log(data[1].goldReqData, 'data[1].goldReqData');
                console.log(data[1].goldReqfileName, 'data[1].goldReqfileName');

                setLoader(true);
                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                const chat_response = await popupdataChanged({ flow: "ai", type: "getGoldERPopup", phase: "Define", show: false }, "getGoldERPopup");
                data = [...chat_response];

                data.push({ flow: 'user', type: 'text', text: 'Goldzone Requirements details', phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver to Gold Flow', phase: 'Define' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'Silver to Gold', referenceDocument: ['GoldZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });

                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setChatHistory([...data]);
                insertChatHistory([...data]);

                generateGoldER(" ", "false");

            } else if (condition_type === "getSilverERPopup") {
                data[1].silverReqData = defineDetailsData.data.data.defineDetails[0];
                data[1].silverReqfileName = defineDetailsData.filename;

                data[1].defineData.silverZoneSheetName = defineDetailsData.filename;
                data[1].defineData.goldZoneSheetName = '';
                data[1].defineData.FRDName = '';
                data[1].FRDName = '';

                console.log(data[1].silverReqData, 'data[1].silverReqData');
                console.log(data[1].silverReqfileName, 'data[1].silverReqfileName');

                setLoader(true);
                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                const chat_response = await popupdataChanged({ flow: "ai", type: "getSilverERPopup", phase: "Define", show: false }, "getSilverERPopup");
                data = [...chat_response];

                data.push({ flow: 'user', type: 'text', text: 'Silverzone Requirements details', phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Extraction to Silver Flow', phase: 'Define' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'Extraction To Silver flow', referenceDocument: ['SilverZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified by email once the document is ready.', phase: 'Define' });

                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setChatHistory([...data]);
                insertChatHistory([...data]);

                generateSilverER(" ", "false");

            } else if (condition_type === "definePopup") {
                setLoader(true);
                data[1].defineData.FRDName = defineDetailsData.filename;
                data[1].FRDName = defineDetailsData.filename;

                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                const chat_response = await popupdataChanged({ flow: "ai", type: "definePopup", phase: "Design", show: false }, "definePopup");
                data = [...chat_response];
                addApprovalChatMessages();
                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setShowDefinePopup(false);
            }
        } else if (phase === "Design") {
            const newDetails = { ...data[1].defineData, ...defineDetailsData?.data?.data?.defineDetails[0] }
            console.log(newDetails, data[1].defineData, '...newDetails');

            if (data.length > 1) {
                if (!data[1].defineData) {
                    data[1].defineData = {};
                    data[1].defineData = newDetails
                } else {
                    data[1].defineData = newDetails
                }
            }

            if (condition_type === "definePopup") {
                setLoader(true);
                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                data[1].defineData.FRDName = defineDetailsData.filename;
                data[1].FRDName = defineDetailsData.filename;
                const chat_response = await popupdataChanged({ flow: "ai", type: "definePopup", phase: "Design", show: false }, "definePopup");
                data = [...chat_response];
                addApprovalChatMessages();
                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setShowDefinePopup(false);
            } else if (condition_type === "TableReqPop") {
                data[1].tableReqData = defineDetailsData.data.data.defineDetails[0];
                data[1].tableReqfileName = defineDetailsData.filename;


                data[1].defineData.tableReqSheetName = defineDetailsData.filename;
                data[1].defineData.FRDName = '';
                data[1].FRDName = '';

                console.log(data[1].tableReqData, 'data[1].tableReqData');
                console.log(data[1].tableReqfileName, 'data[1].tableReqfileName');

                setLoader(true);
                document.getElementById("pageLoader").style.display = "block";
                if (data[data.length - 1].show === true) {
                    data[data.length - 1].show = false
                }
                const chat_response = await popupdataChanged({ flow: 'ai', type: 'tableRequirementPopup', phase: "Design", show: false }, "TableReqPop");
                console.log(chat_response, 'chat_responsechat_responsechat_response');
                data = [...chat_response];

                data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Design" });
                data.push({ flow: "ai", type: "text", text: "Let's move towards generating design document", phase: "Design" });
                data.push({ flow: "ai", type: "definePopup", phase: "Design", show: true });

                document.getElementById("pageLoader").style.display = "none";
                setLoader(false);

                setChatHistory([...data]);
                insertChatHistory([...data]);
            }
        } else {
            let temp = 0;
            let arr = [];
            for (let a of data) {
                if (temp === 0) {
                    if (a.type === "designPopup") {
                        a.show = false;
                        temp = 1;
                    }
                    arr.push(a);
                }
            }
            data = arr;
            console.log(data, 'ingachatthissssssourcePhase');
            data.push({ flow: "user", type: "text", text: "Notebook details", phase: phase });
        }
        setChatHistory([...data]);
        await insertChatHistory([...data]);
        // Handle specific popup phase logic
        if (phase === "Define") {
            if (popupPhase === "Define") {
                if (condition_type === "getSilverERPopup") {
                    let dDetails = defineDetailsData.data.data.defineDetails[0];
                    setSilverReqDetails(dDetails);
                } else if (condition_type === "getGoldERPopup") {
                    let dDetails = defineDetailsData.data.data.defineDetails[0];
                    setGoldReqDetails(dDetails);
                } else if (condition_type === "TableReqPop") {
                    let dDetails = defineDetailsData.data.data.defineDetails[0];
                    setTableReqDetails(dDetails);
                }
            } else if (popupPhase === "Design") {
                let dDetails = defineDetailsData.data.data.defineDetails[0];
                setDefineDetails(dDetails);
            }
        } else if (phase === "Design") {
            if (popupPhase === "Design") {
                if (condition_type === "TableReqPop") {
                    let dDetails = defineDetailsData.data.data.defineDetails[0];
                    setTableReqDetails(dDetails);
                } else {
                    let dDetails = defineDetailsData.data.data.defineDetails[0];
                    setDefineDetails(dDetails);
                }
            }
        } else {
            let dDetails = defineDetailsData.data.data;
            dDetails.status = 200;
            dDetails.fileName = defineDetailsData.filename;
            setDesignDetails(() => dDetails);
            saveDesignDocument(dDetails, data);
        }


    }

    async function designToDevelopment() {

        let data = [...chatHistory];

        let body = {
            projectId: state.projectId,
            userId: state.userId,
            orgId: state.orgId,
            futureStateName: state.futureStateName,
            Methodology: state.Methodology
        }
        setLoader(() => true);

        let response = await fetchDesignDetails(body, state.jwt);
        setLoader(() => false);

        console.log(response, "designToDevelopment");
        if (response.body.statusCode === 403 || response.body.status === 403) {
            sessionExpired()
            return;
        }
        else if (response.body.statusCode === 200) {
            debugger
            response.body.data.status = 200;
            data[1].designObj = JSON.stringify(response.body.data);

            saveDesignDocument(response.body.data, data);
        } else {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }


    //to handle search 
    const handleSearch = useCallback((newSearchText) => {
        setSearchText(newSearchText);
        if (newSearchText) {
            const matches = chatHistory.reduce((acc, item) => {
                if (item?.hyperlinkText && typeof item.hyperlinkText === 'string' &&
                    item.hyperlinkText.toLowerCase().includes(newSearchText.toLowerCase())) {
                    // Check if this hyperlink text is already in the accumulator
                    if (!acc.some(match => match.hyperlinkText === item.hyperlinkText)) {
                        acc.push(item);
                    }
                }
                return acc;
            }, []);

            matchesRef.current = matches;
            setMatchCount(matches.length);
            setCurrentMatchIndex(0);
            if (matches.length > 0) {
                scrollToMatch(matches[0]);
            }
        } else {
            matchesRef.current = [];
            setMatchCount(0);
            setCurrentMatchIndex(0);
        }
    }, [chatHistory]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && matchCount > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchCount;
            setCurrentMatchIndex(nextIndex);
            scrollToMatch(matchesRef.current[nextIndex]);
        }
    };

    const scrollToMatch = (match) => {
        if (match && typeof match.hyperlinkText === 'string') {
            const divElement = document.getElementById("search-" + match.hyperlinkText);
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return (
        <>
            <div
                class="d-flex justify-content-center align-items-center w-100"
                style={{ position: "absolute", zIndex: "100000" }}
            >
                <div
                    id="toastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Session has expired.Please login Again
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    id="protoastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/check 1.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Project has been created successfully
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    id="protoastMessageSomething"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Something went wrong. Try Again!
                            </span>
                        </div>
                    </div>
                </div>
                <div className='div2' style={{ display: "none" }}>
                    <div id='daimage111'>
                        <PlantUML src={dataUML} /></div>
                    <div id='daorgimage'>
                        <PlantUML src={orchUML} /></div>
                </div>

            </div>
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                id="openErrorModal"
                data-bs-target="#Something-wrong"
                hidden
            >
                Oops! Something went wrong
            </button>
            <div
                className="modal fade"
                id="Something-wrong"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span>
                                    <img
                                        src="images/went-wrong-icon.svg"
                                        alt="went-wrong-icon"
                                        className="sucess-icon-sty"
                                    />
                                </span>
                                <h3 className="font-16 font-bold text-black mt-4">
                                    Oops! Something went wrong
                                </h3>
                                <p className="font-14 font-regular text-black">
                                    Please try again later{" "}
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maindiv">
                {showCode === false ?
                    (
                        <>
                            <div className="container-fluid">
                                <div className="row">
                                    {/* header style starts here */}
                                    <HeaderCom value="1" />
                                    {/* header style ends here */}
                                    <div
                                        className="overlay"
                                        id="pageLoader"
                                        style={{ display: "none" }}
                                    >
                                        <div className="position-absolute top-50 start-50  translate-middle">
                                            <div className="d-flex align-items-center loader-bg">
                                                <div
                                                    className="spinner-border Loader text-dark align-center"
                                                    role="status"
                                                >
                                                    <span className="visually-hidden"></span>
                                                </div>
                                                <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-4 mt-5">
                                        <div className="mt-3">
                                            <ul className="cust-breadcrumb mb-0 ">
                                                <li className="font-16 font-medium">
                                                    <a>Manage Projects</a>
                                                </li>
                                                <li className="font-16 font-medium active">
                                                    {state.isEdit
                                                        ? state.projectName
                                                        : state.projectName}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row p-4">
                                            <div className="d-flex  mb-4 justify-content-between align-items-center">
                                                <div className="d-flex align-items-center mb-xl-0 ">
                                                    <a className="d-flex" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                                        <img
                                                            src="images/back-arrow.svg"
                                                            alt="back-arrow"
                                                            onClick={() => {
                                                                nav("/ProjectInfo", {
                                                                    state: { ...state, isEdit: true },
                                                                });
                                                            }}
                                                        />
                                                    </a>
                                                    <h2 className="text-black font-bold font-22 mb-0 ms-3" style={{ position: "absolute", paddingLeft: "1%" }}>
                                                        {state.isEdit === false
                                                            ? state.projectName
                                                            : state.projectName}
                                                    </h2>
                                                </div>
                                                {/* <div class="d-lg-flex d-md-block"> */}
                                                {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                                                <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center" style={{ marginLeft: '48%' }}>
                                                    <span className=" text-nowrap font-bold">Organization : </span>
                                                    <span>
                                                        <img
                                                            src={state.orgLogo}
                                                            alt="hubspot-logo"
                                                            className="org-logo"
                                                        />
                                                    </span>
                                                    <span className="me-2 text-nowrap ">
                                                        {" "}{state.orgName}{" "}
                                                    </span>
                                                </div>
                                                <div className="input-group position-relative search-wid me-md-3" style={{ overflow: 'visible' }}>
                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                        <input
                                                            type="search"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                            placeholder="Search"
                                                            value={searchText}
                                                            onChange={(e) => handleSearch(e.target.value)}
                                                            onKeyPress={handleKeyPress}
                                                            style={{
                                                                paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                width: '100%',
                                                            }}
                                                        />
                                                        {matchCount > 0 && (
                                                            <span style={{
                                                                position: 'absolute',
                                                                left: '8px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                fontSize: '12px',
                                                                color: '#fff',
                                                                backgroundColor: '#7650E0',
                                                                padding: '2px 6px',
                                                                borderRadius: '10px',
                                                                zIndex: 5,
                                                                pointerEvents: 'none',
                                                            }}>
                                                                {`${currentMatchIndex + 1}/${matchCount}`}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <span className="search-icon-sty" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                        <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                                    </span>
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>
                                            <div className="code-review-tab-width">
                                                <div className="code-review-page tabs nav ">
                                                    <a
                                                        onClick={() => {
                                                            nav("/ProjectInfo", {
                                                                state: { ...state, isEdit: true },
                                                            });
                                                        }}
                                                    >
                                                        {" "}
                                                        <div className="tab nav-link font-bold">
                                                            Project Info
                                                        </div>
                                                    </a>
                                                    <div
                                                        className="tab nav-link font-bold active"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#ask-gen"
                                                    >
                                                        Ask GenETL
                                                    </div>
                                                </div>
                                                <div className="tab-content" id="pills-tabContent">
                                                    {/* chat page  */}
                                                    <div
                                                        id="ask-gen"
                                                        className="tab-pane fade code-review-tab show active tab-content "
                                                    >


                                                        <div className="chat-box">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div
                                                                        className="chat-container"
                                                                        style={{
                                                                            height: '430px',
                                                                            overflowY: 'auto',
                                                                        }}
                                                                        ref={chatContainerRef}
                                                                    >
                                                                        <div className="col-md-12">
                                                                            {chatHistory.map((data) => {

                                                                                if (data.flow === "center") {
                                                                                    return (
                                                                                        <div className="chat-divider d-flex justify-content-center">
                                                                                            <span className="font-12 font-medium">{data.text}</span>
                                                                                        </div>
                                                                                    );
                                                                                } else if (data?.flow === "ai") {
                                                                                    if (data?.type === "getType") {
                                                                                        {
                                                                                            /* bot response */
                                                                                        }
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" className="figma-logo" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Hi{" "}
                                                                                                    <span className="text-black">
                                                                                                        {data?.userName || state.userName}
                                                                                                    </span>
                                                                                                    , How can I help you with?
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                overallFunction("level3", { type: "define" });
                                                                                                            }}>
                                                                                                            Define
                                                                                                        </button>
                                                                                                        <button className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                overallFunction("level2", { type: "design" });
                                                                                                            }}
                                                                                                        >
                                                                                                            Design
                                                                                                        </button>
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                overallFunction("level1", { type: "deployment" });
                                                                                                            }}
                                                                                                        >
                                                                                                            Development
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "getConnectionPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    As a first step{" "}
                                                                                                    <a
                                                                                                        onClick={() => setShowSourcePopup(true)}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about source system and connection details.
                                                                                                </div>
                                                                                                {data?.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => setShowSourcePopup(true)}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "text") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative mb-4">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    {data.text}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === 'letsStart') {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    you can now proceed with generating the document
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === false ? (
                                                                                                        <></>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {" "}
                                                                                                            <button
                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                onClick={() => letsStartwithGeneration()}
                                                                                                            >
                                                                                                                Let's Start
                                                                                                            </button>

                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "requestStatus") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative mb-4">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Your {data.text} has been
                                                                                                    {data.status === "Approved" ? (
                                                                                                        <span style={{ color: "green" }}> Approved</span>
                                                                                                    ) : (
                                                                                                        <span style={{ color: "red" }}> Rejected</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === 'loadShow' && data.show === true) {

                                                                                        return (
                                                                                            <div className="bot-response position-relative mb-4">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Your {data.docName} document would be generated
                                                                                                    based on the below document
                                                                                                    {data.referenceDocument.length > 1 ? (
                                                                                                        // Render the div with the loop inside if data.referenceDocument has more than 1 item
                                                                                                        <div className="my-3">
                                                                                                            {/* Use map to render a div for each item in data.referenceDocument */}
                                                                                                            {data.referenceDocument.map((document, index) => (
                                                                                                                <div key={index}>
                                                                                                                    <a className="text-decoration-none text-black font-12">
                                                                                                                        <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                        {document} {/* Render the value of each document object */}
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        // If data.referenceDocument has only 1 item, render a single div
                                                                                                        data.referenceDocument.length === 1 && (
                                                                                                            <div className="my-3">
                                                                                                                <a className="text-decoration-none text-black font-12">
                                                                                                                    <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                    {data.referenceDocument[0]} {/* Render the value of the single document */}
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        )
                                                                                                    )}

                                                                                                    {data.docName} document would be ready soon...
                                                                                                    {/* Your {data.docName} document would be generated based on the below document", 
                                need to handle the reference document loop here */}
                                                                                                </div>

                                                                                            </div>

                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "designPopup") {

                                                                                        return (

                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    As a first step{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowDesignPopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about notebook details.
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === true ? (
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowDesignPopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "definePopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    As a first step{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowDefinePopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about notebook details.
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === true ? (
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowDefinePopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "tableRequirementPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Sure I can help you with it.Kindly{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowtableReqPopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about table requirements.
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === true ? (
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowtableReqPopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "getSilverERPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Kindly{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowsilverERPopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about silver zone requirements.
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === true ? (
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowsilverERPopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "getGoldERPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Kindly{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowgoldERPopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about gold zone requirements.
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === true ? (
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowgoldERPopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "targetSystemPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    As a first step{" "}
                                                                                                    <a
                                                                                                        onClick={() => { setShowDeployPopup(true); setPopupPhase(data.phase) }}
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about target system details.
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => { setShowDeployPopup(true); setPopupPhase(data.phase) }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "tablePathPopup") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Kindly{" "}
                                                                                                    <a
                                                                                                        className="chatbot-anchor"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() => {
                                                                                                            setShowTablePathPopup(true)
                                                                                                        }}
                                                                                                    >
                                                                                                        fill out this form
                                                                                                    </a>{" "}
                                                                                                    to know about your Table path.
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                setShowTablePathPopup(true)
                                                                                                            }}
                                                                                                        >
                                                                                                            Fill Form
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "codeShow") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    <b>{data?.text}</b>
                                                                                                    <div className="mt-3" >
                                                                                                        <a
                                                                                                            onClick={() => {

                                                                                                                console.log(data, "0----------------");
                                                                                                                if (deployStatus === true) {
                                                                                                                    getCodePageDetails(data);
                                                                                                                } else {
                                                                                                                    setIsEditNotebook(data);
                                                                                                                    setShowConfirmPopup(true)
                                                                                                                }
                                                                                                            }}
                                                                                                            className="hyperlink"
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >
                                                                                                            {data?.hyperlinkText}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === false ? (
                                                                                                        <></>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {" "}
                                                                                                            <button
                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                onClick={() => checkLooksGood(data?.codeType)}
                                                                                                            >
                                                                                                                Looks Good
                                                                                                            </button>
                                                                                                            <div
                                                                                                                className="btn-group"
                                                                                                                role="group"
                                                                                                                aria-label="Basic outlined example"
                                                                                                            >
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                    onClick={() => {

                                                                                                                        if (deployStatus === true) {
                                                                                                                            getCodePageDetails(data);
                                                                                                                        } else {
                                                                                                                            setIsEditNotebook(data);
                                                                                                                            setShowConfirmPopup(true)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-edit.svg" />
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    onClick={() => {
                                                                                                                        download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-download.svg" />
                                                                                                                </button>
                                                                                                                {(data?.codeType !== 'metadataTable') ? (
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="images/chat-action-regenerate.svg"
                                                                                                                            height="20px"
                                                                                                                            onClick={() => {
                                                                                                                                regenerate(data);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "consolidateDesign") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    <b>{data?.text}</b>
                                                                                                    <div className="mt-3" >
                                                                                                        <a
                                                                                                            className="hyperlink"
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() => {
                                                                                                                downloadConsolidateDesign();
                                                                                                                // download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                            }}
                                                                                                        >
                                                                                                            {data?.hyperlinkText}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === false ? (
                                                                                                        <></>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {" "}
                                                                                                            <button
                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                onClick={() => designToDevelopment()}
                                                                                                            >
                                                                                                                Looks Good
                                                                                                            </button>
                                                                                                            <div
                                                                                                                className="btn-group"
                                                                                                                role="group"
                                                                                                                aria-label="Basic outlined example"
                                                                                                            >
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    onClick={() => {
                                                                                                                        downloadConsolidateDesign();
                                                                                                                        // download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-download.svg" />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else if (data.type === "pseudocodeShow") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    <b>{data?.text}</b>
                                                                                                    <div className="mt-3" >
                                                                                                        <a
                                                                                                            onClick={() => {

                                                                                                                console.log(data, "0----------------");
                                                                                                                if (deployStatus === true) {
                                                                                                                    getCodePageDetails(data);
                                                                                                                } else {
                                                                                                                    setIsEditNotebook(data);
                                                                                                                    setShowConfirmPopup(true)
                                                                                                                }
                                                                                                            }}
                                                                                                            className="hyperlink"
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >
                                                                                                            {data?.hyperlinkText}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    {data.show === false ? (
                                                                                                        <>
                                                                                                            {(data.show === false && data.verified === false) ? (<>
                                                                                                                {" "}

                                                                                                                <div
                                                                                                                    className="btn-group"
                                                                                                                    role="group"
                                                                                                                    aria-label="Basic outlined example"
                                                                                                                >
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                        onClick={() => {

                                                                                                                            if (deployStatus === true) {
                                                                                                                                getCodePageDetails(data);
                                                                                                                            } else {
                                                                                                                                setIsEditNotebook(data);
                                                                                                                                setShowConfirmPopup(true)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <img src="images/chat-action-edit.svg" />
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                        onClick={() => {
                                                                                                                            downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <img src="images/chat-action-download.svg" />
                                                                                                                    </button>
                                                                                                                    {(data?.codeType !== 'metadataTable') ? (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                            onClick={() => {
                                                                                                                                regenerateDesign(data); console.log('1234567890')
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                src="images/chat-action-regenerate.svg"
                                                                                                                                height="20px"

                                                                                                                            />
                                                                                                                        </button>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </>
                                                                                                            ) : (<></>)}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {" "}
                                                                                                            <button
                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                onClick={(e) => {
                                                                                                                    checkPseudocodeLooksGood(data?.pseudocodeType)
                                                                                                                    e.currentTarget.disabled = true;
                                                                                                                    e.currentTarget.style.opacity = '0.3';
                                                                                                                    e.currentTarget.style.cursor = 'not-allowed';
                                                                                                                }
                                                                                                                }
                                                                                                            >
                                                                                                                Looks Good
                                                                                                            </button>

                                                                                                            <div
                                                                                                                className="btn-group"
                                                                                                                role="group"
                                                                                                                aria-label="Basic outlined example"
                                                                                                            >
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                    onClick={() => {

                                                                                                                        if (deployStatus === true) {
                                                                                                                            getCodePageDetails(data);
                                                                                                                        } else {
                                                                                                                            setIsEditNotebook(data);
                                                                                                                            setShowConfirmPopup(true)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-edit.svg" />
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    onClick={() => {
                                                                                                                        downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-download.svg" />
                                                                                                                </button>
                                                                                                                {(data?.codeType !== 'metadataTable') ? (
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                        onClick={() => {
                                                                                                                            regenerateDesign(data); console.log('1234567890')
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="images/chat-action-regenerate.svg"
                                                                                                                            height="20px"

                                                                                                                        />
                                                                                                                    </button>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }

                                                                                    else if (data.type === "deploy") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Would you like to deploy now?
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold"
                                                                                                            onClick={() => {
                                                                                                                let data = [...chatHistory];
                                                                                                                data[data.length - 1].show = false;
                                                                                                                if (deployStatus === true) {
                                                                                                                    data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                    data.push({
                                                                                                                        flow: "ai",
                                                                                                                        type: "text",
                                                                                                                        text: "Oops! Seems that you haven't provided target system information.",
                                                                                                                    });
                                                                                                                    data.push({
                                                                                                                        flow: "ai",
                                                                                                                        type: "targetSystemPopup",
                                                                                                                        show: true,
                                                                                                                    });
                                                                                                                    setChatHistory(() => [...data]);
                                                                                                                    insertChatHistory([...data]);
                                                                                                                } else {
                                                                                                                    deployNotebooksDatabricks("2", "Yes");
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            Yes
                                                                                                        </button>
                                                                                                        <button
                                                                                                            className="font-12 font-bold chat-no-btn"
                                                                                                            onClick={() => {
                                                                                                                let data = [...chatHistory];
                                                                                                                data[data.length - 1].show = false;
                                                                                                                data.push({ flow: "user", type: "text", text: "No" });
                                                                                                                data.push({ flow: "ai", type: "reDeploy", show: true });
                                                                                                                setChatHistory(() => [...data]);
                                                                                                            }}
                                                                                                        >
                                                                                                            No
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "reDeploy") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Thanks, You can deploy even after a while.
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                let data = [...chatHistory];
                                                                                                                data[data.length - 1].show = false;
                                                                                                                if (deployStatus === true) {
                                                                                                                    data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                    data.push({
                                                                                                                        flow: "ai",
                                                                                                                        type: "text",
                                                                                                                        text: "Oops! Seems that you haven't provided target system information.",
                                                                                                                    });
                                                                                                                    data.push({
                                                                                                                        flow: "ai",
                                                                                                                        type: "targetSystemPopup",
                                                                                                                        show: true,
                                                                                                                    });
                                                                                                                    setChatHistory((a) => [...data]);
                                                                                                                    insertChatHistory([...data]);
                                                                                                                } else {
                                                                                                                    data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                    setChatHistory(() => [...data]);
                                                                                                                    deployNotebooksDatabricks("2", "Deplop Now");
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            Deploy Now
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "somethingDeploy") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    Something went wrong. Please deploy again!
                                                                                                </div>
                                                                                                {data.show === true ? (
                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                        <button
                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                            onClick={() => {
                                                                                                                deployNotebooksDatabricks("2", "Deploy Now");
                                                                                                            }}
                                                                                                        >
                                                                                                            Deploy Now
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    } else if (data.type === "textwait") {
                                                                                        return (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    {data.text}
                                                                                                </div>
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            document.getElementById("pageLoader").style.display = "block";
                                                                                                            fetchChatHistory()
                                                                                                        }}
                                                                                                    >
                                                                                                        Refresh
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return <></>
                                                                                    }
                                                                                } else if (data.flow === "user") {
                                                                                    if (data.type === "text") {
                                                                                        return (
                                                                                            <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img
                                                                                                        src={`${localStorage?.getItem("dXNlckltYWdl1") !== null
                                                                                                            ? decode(
                                                                                                                localStorage
                                                                                                                    ?.getItem("dXNlckltYWdl1")
                                                                                                                    ?.substring(
                                                                                                                        15,
                                                                                                                        localStorage.getItem("dXNlckltYWdl1")?.length - 10
                                                                                                                    )
                                                                                                            )
                                                                                                            : "images/userDefault.svg"
                                                                                                            }`}
                                                                                                        alt="user-profile"
                                                                                                        className="chat-user-img"
                                                                                                    />
                                                                                                </span>
                                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                    {data.text}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return <></>
                                                                                    }
                                                                                } else {
                                                                                    return <></>
                                                                                }
                                                                            })}
                                                                            {showLooksGood ? (
                                                                                <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                        <img
                                                                                            src={`${localStorage?.getItem(
                                                                                                "dXNlckltYWdl1"
                                                                                            ) !== null
                                                                                                ? decode(localStorage?.getItem("dXNlckltYWdl1")?.substring(15,
                                                                                                    localStorage.getItem(
                                                                                                        "dXNlckltYWdl1"
                                                                                                    )?.length - 10
                                                                                                )
                                                                                                )
                                                                                                : "images/userDefault.svg"
                                                                                                }`}
                                                                                            alt="user-profile"
                                                                                            className="chat-user-img"
                                                                                        />
                                                                                    </span>
                                                                                    <div className="chat-content-user font-14 me-4 font-medium">
                                                                                        Looks Good
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {showLoader === true ? (
                                                                                <div className="bot-response position-relative">
                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                        <img
                                                                                            src="images/GenELT-chat-logo.svg"
                                                                                            alt="user-profile"
                                                                                        />
                                                                                    </span>
                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                        <div className="dot-typing"></div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            <div id='Imdown'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {codeDataValues?.codeType === "metadata" ? (
                                <MetadataNotebook
                                    data={codeDataValues}
                                    setShowCode={() => setShowCode(false)}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}

                                />
                            ) : codeDataValues?.codeType === "Extraction" ? (
                                <BronzeNotebook
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}

                                />
                            ) : codeDataValues?.codeType === "Silver" ? (
                                <SilverNotebook
                                    data={codeDataValues}
                                    setShowCode={() => setShowCode(false)}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "Gold" ? (
                                <GoldNotebook
                                    data={codeDataValues}
                                    setShowCode={() => setShowCode(false)}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "ddl" ? (
                                <DDLScripts
                                    data={codeDataValues}
                                    setShowCode={() => setShowCode(false)}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "logger" ? (
                                <LoggerNotebook
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "utility" ? (
                                <Utility
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "Orchestration" ? (
                                <Orchestraion
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "UnitTestCase" ? (
                                <UnitTestCaseCodePage
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "dataQualityCode" ? (
                                <DataQualityPage
                                    data={codeDataValues}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                />
                            ) : codeDataValues?.codeType === "checklist" ? (
                                <CodeReviewChecklistDev
                                    data={codeDataValues}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                />

                            ) : codeDataValues?.codeType === "metadataTable" ? (
                                <MetaDataGridDev
                                    data={codeDataValues}
                                    isEdit={(status) => { if (status === true) { dataChanged() } }}
                                    setShowCode={() => {scrollMatch();setShowCode(false)}}
                                    onChange={() => updateValues()}
                                />

                            ) ///design phase components
                                : codeDataValues?.pseudocodeType === "loggerPseudocode" ? (
                                    <LoggerGridPseudocode
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "utilityPseudocode" ? (
                                    <Utilities
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dataDictionarySilver" ? (
                                    <DataDictionarySilver
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dataDictionaryGold" ? (
                                    <DataDictionaryGold
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() =>{scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "extractionSequence" ? (
                                    <ExtractionDBSequence
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "transformationSequence" ? (
                                    <SilverDBSequence
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "goldSequence" ? (
                                    <GoldDBSequence
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "sourceToExtractionER" ? (
                                    <SourceToExtractionER
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() =>{scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "BronzeToSilverER" ? (
                                    <BronzeToSilverER
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "SilverToGoldER" ? (
                                    <SilverToGoldER
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dataModelGold" ? (
                                    <DataModelGold
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dataModelSilver" ? (
                                    <DataModelSilver
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "extractionRequirement" ? (
                                    <ExtractionReqGrid
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "tableMapping" ? (
                                    <TableMappingRework
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}

                                        isTotalVerified={(status) => { if (status === true) { totalVerified(); console.log(status, "statusssss"); } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => { }}
                                    />
                                ) : codeDataValues?.pseudocodeType === "columnMapping" ? (
                                    <ColumnMappingRework
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        isTotalVerified={(status) => { if (status === true) { totalVerified() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => { updateValues() }}
                                    />
                                ) : codeDataValues?.pseudocodeType === "metadataTable" ? (
                                    <MetadataTable
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dDLScriptsBronze" ? (
                                    <DDLScriptPseudoBronze
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dDLScriptsSilver" ? (
                                    <DDLScriptPseudoSilver
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dDLScriptsGold" ? (
                                    <DDLScriptPseudoGold
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "pipelineSummary" ? (
                                    <PipelineSummaryDB
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                ) : codeDataValues?.pseudocodeType === "orchestrationUML" ? (
                                    <OrchestrationDiagram
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "extraction" ? (
                                    <ExtractionDataBricks
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                ) : codeDataValues?.pseudocodeType === "transformation" ? (
                                    <SilverDataBricks
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                ) : codeDataValues?.pseudocodeType === "gold" ? (
                                    <GoldDataBricks
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                ) : codeDataValues?.pseudocodeType === "orchestrationDocument" ? (
                                    <OrchestrationPseudo
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "dataflowDesign" ? (
                                    <DataFlowPseudo
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />
                                ) : codeDataValues?.pseudocodeType === "unitTestPseudo" ? (
                                    <UnitTestCaseGridDB
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                ) : codeDataValues?.pseudocodeType == "dataQualityPsueudocode" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                    <DataQualityDB
                                        data={codeDataValues}
                                        isEdit={(status) => { if (status === true) { dataChanged() } }}
                                        setShowCode={() => {scrollMatch();setShowCode(false)}}
                                        onChange={() => updateValues()}
                                    />

                                )  //design 
                                    : codeDataValues?.pseudocodeType === "codeReviewChecklist" ? (
                                        <CodeReviewChecklistDesignDB
                                            data={codeDataValues}
                                            isEdit={(status) => { if (status === true) { dataChanged() } }}
                                            setShowCode={() => {scrollMatch();setShowCode(false)}}
                                            onChange={() => updateValues()}
                                        />

                                    )  //developmnt phase
                                        : codeDataValues?.codeType === "metadataTable" ? (
                                            <MetaDataGridDev deployStatus={deployStatus}
                                                data={codeDataValues}
                                                setShowCode={() => {scrollMatch();setShowCode(false)}}
                                                isEdit={(status) => { if (status === true) { dataChanged() } }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                        </>
                    )}


                {showSourcePopup && (
                    <>
                        <SourceConnectionPopup
                            deployStatus={deployStatus}
                            phase={sourcePhase}
                            layer={state.layer}
                            setLoader={setLoader}
                            setDeployStatus={setDeployStatus}
                            close={() => setShowSourcePopup(false)}
                            setChatHistory={setChatHistory}
                            chatHistory={chatHistory}
                            insertChatHistory={insertChatHistory}
                        />
                    </>
                )}

                {showTablePathPopup && (
                    <>
                        <TablePathPopup
                            pathDetails={tablePathDetails}
                            close={() => setShowTablePathPopup(false)}
                            onChange={(data) => {
                                new Promise((resolve) => {
                                    setTablePathDetails(data);
                                    resolve();
                                }).then(() => {
                                    generateMetadataTable("", data);
                                });
                            }}
                        />

                    </>
                )}

                {showDeployPopup && (
                    <>
                        <DeployPopup
                            deployStatus={deployStatus}
                            close={(status) => { console.log(status, '212'); setDeployStatus(status); setShowDeployPopup(false) }}
                            setTargetSystemDetails={setTargetSystemDetails}
                            setChatHistory={setChatHistory}
                            chatHistory={chatHistory}
                            insertChatHistory={insertChatHistory}
                        />
                    </>
                )}

                {showDesignPopup ? (
                    <DesignPopup
                        fileName={chatHistory[1]?.definefileName || ""}
                        onChange={(data) => updateChatHistory(data, "designPopup")}
                        close={() => setShowDesignPopup(false)}
                    />
                ) : (
                    <></>
                )}

                {showDefinePopup && (
                    <>
                        <DefinePop
                            fileName={chatHistory[1]?.FRDName || ""}
                            onChange={(data) => updateChatHistory(data, "definePopup")}
                            close={() => setShowDefinePopup(false)}
                            layer={state.layer}
                        />
                    </>
                )}
                {showtableReqPopup && (
                    <>
                        <TableReqPop
                            fileName={chatHistory[1]?.defineData?.tableReqSheetName || ""}
                            onChange={(data) => {
                                updateChatHistory(data, "TableReqPop");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowtableReqPopup(false)}

                        />
                    </>
                )}
                {showsilverERPopup && (
                    <>
                        <SilverZonePopup
                            fileName={chatHistory[1]?.defineData?.silverZoneSheetName || ""}
                            onChange={(data) => {
                                updateChatHistory(data, "getSilverERPopup");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowsilverERPopup(false)}
                            layer={state.layer}
                        />
                    </>
                )}
                {showgoldERPopup && (
                    <>
                        <GoldZonePopup
                            fileName={chatHistory[1]?.defineData?.goldZoneSheetName || ""}
                            onChange={(data) => {
                                updateChatHistory(data, "getGoldERPopup");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowgoldERPopup(false)}
                            layer={state.layer}
                        />
                    </>
                )}

                {showConfirmPopup &&
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal fade show"
                            id="ConnectionInfo"
                            style={{ display: "block", paddingLeft: 0, transition: "smooth" }}
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5
                                            className="modal-title d-flex align-items-center font-medium font-20"
                                            id="ConfrmLabel"
                                        >
                                            Confirmation
                                        </h5>
                                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                    </div>
                                    <div className="modal-body py-0">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                                <p className="font-16">
                                                    Updating this notebook won’t change the data in all the
                                                    sequential documentations, each notebook needs to be
                                                    updated manually. Would you like to proceed further?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 px-4">
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-14 font-medium me-2"
                                            onClick={() => setShowConfirmPopup(false)}
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn cust-primary-btn font-14 font-medium"
                                            data-bs-dismiss="modal"
                                            onClick={() => { setShowConfirmPopup(false); getCodePageDetails(isEditNotebook) }}
                                        >
                                            <span>Yes</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}
export default ChatMaintenance;
