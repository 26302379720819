import React, { useRef, useEffect, useState } from 'react';
import {
  IgrExcelXlsxModule,
  IgrExcelCoreModule,
  IgrExcelModule,
  Workbook,
  WorkbookFormat,
  WorkbookSaveOptions
} from 'igniteui-react-excel';
import { IgrSpreadsheetModule, IgrSpreadsheet } from 'igniteui-react-spreadsheet';
import { saveAs } from 'file-saver';
import { sampleJsonDatas, unitTestResultUploadValidations1 } from './excelGridDataJSON';
import PopupNotification from './excelGridDataPopup';

IgrExcelCoreModule.register();
IgrExcelModule.register();
IgrExcelXlsxModule.register();
IgrSpreadsheetModule.register();

const fixedColumns = ["S.No", "NotebookName", "TableName", "UnitTestCaseDescription", "Scripts", "Comments", "ValidationStatus"];
const MAX_COLUMN_WIDTH = 50; // Cap for maximum column width in characters

const SpreadsheetOverview = () => {
  const [sampleJsonData, setSampleJsonData] = useState(sampleJsonDatas);
  const [unitTestResultUploadValidations] = useState(unitTestResultUploadValidations1);
  const [isEditing, setIsEditing] = useState(false);
  const [workbook, setWorkbook] = useState(null);
  const [notification, setNotification] = useState(null); // State to control notification
  const [validationErrors, setValidationErrors] = useState([]); // State to track validation errors

  const spreadsheetRef = useRef(null);

  useEffect(() => {
    const initialWorkbook = convertJsonToWorkbook(sampleJsonData);
    setWorkbook(initialWorkbook);
    if (spreadsheetRef.current) {
      spreadsheetRef.current.workbook = initialWorkbook;
      spreadsheetRef.current.editModeEntered = handleCellEdit;
      console.log('Spreadsheet workbook set to:', initialWorkbook);
    }
  }, [sampleJsonData]);

  const getExtension = (format) => {
    switch (format) {
      case WorkbookFormat.StrictOpenXml:
      case WorkbookFormat.Excel2007:
        return '.xlsx';
      case WorkbookFormat.Excel2007MacroEnabled:
        return '.xlsm';
      case WorkbookFormat.Excel2007MacroEnabledTemplate:
        return '.xltm';
      case WorkbookFormat.Excel2007Template:
        return '.xltx';
      case WorkbookFormat.Excel97To2003:
        return '.xls';
      case WorkbookFormat.Excel97To2003Template:
        return '.xlt';
      default:
        return '';
    }
  };

  const save = (workbook, fileNameWithoutExtension) => {
    return new Promise((resolve, reject) => {
      const opt = new WorkbookSaveOptions();
      opt.type = 'blob';
      workbook.save(
        opt,
        (d) => {
          const fileExt = getExtension(workbook.currentFormat);
          const fileName = fileNameWithoutExtension + fileExt;
          saveAs(d, fileName);
          resolve(fileName);
        },
        (e) => reject(e)
      );
    });
  };

  const convertJsonToWorkbook = (data) => {
    const workbook = new Workbook(WorkbookFormat.Excel2007);

    for (const sheetName in data) {
      if (data.hasOwnProperty(sheetName)) {
        const sheetData = data[sheetName];
        const worksheet = workbook.worksheets().add(sheetName);

        if (sheetData.length > 0) {
          const headers = Object.keys(sheetData[0]);
          headers.forEach((header, i) => {
            worksheet.rows(0).cells(i).value = header;
            worksheet.rows(0).cells(i).cellFormat.locked = fixedColumns.includes(header);
            worksheet.rows(0).cells(i).cellFormat.wrapText = true;
          });

          sheetData.forEach((row, rowIndex) => {
            headers.forEach((header, colIndex) => {
              const cell = worksheet.rows(rowIndex + 1).cells(colIndex);
              cell.value = row[header];
              cell.cellFormat.wrapText = true;
            });
          });

          // Auto size columns based on text length
          headers.forEach((header, i) => {
            const columnWidths = [header.length + 5];
            sheetData.forEach(row => {
              const cellLength = (row[header]?.toString().length || 0) + 2; // Adding extra padding to each column width
              columnWidths.push(cellLength);
            });
            const maxColumnWidth = Math.min(MAX_COLUMN_WIDTH, Math.max(...columnWidths));
            worksheet.columns(i).width = maxColumnWidth * 256; // 256 is used to convert to character width

            if (maxColumnWidth >= MAX_COLUMN_WIDTH) {
              // Apply text wrapping if column width exceeds the cap
              worksheet.columns(i).cellFormat.wrapText = true;
            }
          });
        }
        worksheet.protect(true);
      }
    }

    return workbook;
  };

  const convertWorkbookToJson = (sampleJsonData, workbook) => {
    const sheetName = Object.keys(sampleJsonData)[0];
    const worksheet = workbook.worksheets().item(0);
    const newJsonData = [];

    for (let rowIndex = 1; rowIndex < worksheet.rows().count; rowIndex++) {
      let row = worksheet.rows(rowIndex);
      let rowData = {};
      for (let colIndex = 0; colIndex < row.cells().count; colIndex++) {
        let header = worksheet.rows(0).cells(colIndex).value;
        rowData[header] = row.cells(colIndex).value;
      }
      newJsonData.push(rowData);
    }

    return { [sheetName]: newJsonData };
  };

  const handleEdit = () => {
    setIsEditing(true);
    if (workbook) {
      for (let i = 0; i < workbook.worksheets().count; i++) {
        const sheet = workbook.worksheets().item(i);
        sheet.unprotect();
      }
      spreadsheetRef.current.workbook = workbook;
      setNotification(null);
    }
  };

  const handleCellEdit = (args) => {
    if (!args.cell) return;

    const row = args.cell.row;
    const col = args.cell.column;

    const worksheet = workbook.worksheets().item(0);
    const headers = Object.keys(sampleJsonData[Object.keys(sampleJsonData)[0]][0]);

    if (row > 0) {
      const header = headers[col];
      const rowData = worksheet.rows(row).cells().map(cell => cell.value);
      const rowHasData = rowData.some(value => value !== null && value !== undefined && value !== '');

      if (!rowHasData) {
        headers.forEach((header, index) => {
          if (index !== col) {
            worksheet.rows(row).cells(index).value = '';
          }
        });

        const newJsonData = convertWorkbookToJson(sampleJsonData, workbook);
        const errors = validateData(newJsonData, unitTestResultUploadValidations);
        setValidationErrors(errors);
        setNotification(errors.length > 0 ? 'Validation Errors' : null);
      }
    }
  };

  const validateData = (data, validationColumns) => {
    const errors = [];

    const requiredSheets = Object.keys(validationColumns);

    requiredSheets.forEach(sheetName => {
      if (data[sheetName]) {
        const sheetData = data[sheetName];
        const sheetValidations = validationColumns[sheetName];

        const headerRow = Object.keys(sheetData[0]);

        sheetValidations.forEach((validation, index) => {
          const normalizedHeader = normalizeHeader(headerRow[index]);
          const expectedHeader = normalizeHeader(validation.columnHeader);

          if (normalizedHeader !== expectedHeader) {
            errors.push(`Header Field '${validation.columnHeader}' is missing or incorrect in cell ${validation.columnCell} of ${sheetName}`);
          }
        });

        sheetData.forEach((row, rowIndex) => {
          sheetValidations.forEach((validation) => {
            const cellValue = row[validation.columnHeader];

            if (validation.isEmpty === 0 && (!cellValue || cellValue.toString().trim() === '')) {
              errors.push(`${validation.columnHeader} value should not be empty in ${sheetName}, row ${rowIndex + 2}`);
            }
            if (validation.NA === 0 && cellValue === 'NA') {
              errors.push(`${validation.columnHeader} value should not be NA in ${sheetName}, row ${rowIndex + 2}`);
            }
            if (validation.isNull === 0 && (cellValue === null || cellValue === undefined)) {
              errors.push(`${validation.columnHeader} value should not be null in ${sheetName}, row ${rowIndex + 2}`);
            }

            if (validation.valueValidation) {
              const validValues = Array.isArray(validation.valueValidation) ? validation.valueValidation : [validation.valueValidation];
              const normalizedCellValue = cellValue ? cellValue.toString().trim().toLowerCase() : '';
              const isValidValue = validValues.map(v => v.toLowerCase()).some(v => normalizedCellValue.startsWith(v));

              if (!isValidValue) {
                errors.push(`${validation.columnHeader} value should start with one of ${validValues.join(', ')} in ${sheetName}, row ${rowIndex + 2}`);
              }
            }
          });

          const hasValue = Object.values(row).some(v => v && (typeof v === 'string' ? v.trim() !== '' : true));
          if (hasValue) {
            const allColumnsFilled = Object.values(row).every(v => v && (typeof v === 'string' ? v.trim() !== '' : true));
            if (!allColumnsFilled) {
              errors.push(`In ${sheetName} at row ${rowIndex + 2}, all cells must be filled if one of the cells has a value.`);
            }
          }
        });
      }
    });

    return errors;
  };

  const normalizeHeader = header => (header ? header.toString().trim().toLowerCase().replace(/\s+/g, "_") : "");

  const handleSave = async () => {
    if (workbook) {
      const newJsonData = convertWorkbookToJson(sampleJsonData, workbook);
      console.log('New JSON Data:', newJsonData);

      const errors = validateData(newJsonData, unitTestResultUploadValidations);
      if (errors.length > 0) {
        setValidationErrors(errors);
        setNotification('Validation Errors');
        return;
      }

      const response = await saveGridData(newJsonData, "your-jwt-token");
      if (response === 200) {
        console.log('Data saved successfully');
        setNotification('Data saved successfully!');
        setValidationErrors([]);
        setIsEditing(false);
        for (let i = 0; i < workbook.worksheets().count; i++) {
          const sheet = workbook.worksheets().item(i);
          sheet.protect(true);
        }
        setSampleJsonData(newJsonData);
        spreadsheetRef.current.workbook = workbook;
      }
    }
  };

  const saveGridData = (data, jwt) => {
    console.log('Saving data to backend...', data);
    return new Promise(resolve => setTimeout(() => resolve(200), 2000));
  };

  const handleCloseNotification = () => {
    setNotification(null);
    setValidationErrors([]);
  };

  return (
    <div className="container sample">
      <div className="options horizontal">
        {!isEditing ? (
          <button onClick={handleEdit}>Edit</button>
        ) : (
          <button style={{ background: 'lightgreen' }} onClick={handleSave}>Save</button>
        )}
      </div>
      <IgrSpreadsheet
        ref={spreadsheetRef}
        height="calc(100% - 30px)"
        width="100%"
      />
      {notification && (
        <PopupNotification
          message={notification}
          errors={validationErrors}
          onClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default SpreadsheetOverview;