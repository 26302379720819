import { useEffect, useState } from "react";
/*PS_CG_1.1*/
import { useLocation, useNavigate } from "react-router-dom";
import { getSnowflake, getPostgres, getMySQL, getHubspot, getSalesforce, getRedshift, getOracle, getMsSQL, postSourceSystemConnectioninfo, getSourceSystemConnectioninfo, projectsDropDown } from "../Service/Api.js";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { cloudonprem, scripts, type1, type2, type3 } from "./Contrains.js";
function SourceConnectionPopup(props) {

    const { state } = useLocation();
    if (state === null) {
        window.location.href = "/";
    }

    const [chatHistory, setChatHistory] = useState(props.chatHistory);
    const [sourceSystems, setSourceSystems] = useState([]);
    // const [showLoader, props.setLoader] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [designData, setDesignData] = useState("");
    const [phase, setPhase] = useState(props.phase)
    const [selectedFileName, setSelectedFileName] = useState("");

    const [showSourcePopup, setShowSourcePopup] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [sourceSystemInfo, setSourceSystemInfo] = useState({
        systemName: "",
        InternalExternal: "Select",
        systemType: "Select",
        subjectArea: "Select",
        dataFormat: "Select",
        sourceSystemNameId: "Select",
        sourceSystemType: "Select",
        dataVolume: "Select",
        descriptions: "",
        newSourceSystemName: "",
        comments: "",
        connectionDetails: {},
    });
    const [sourceSystemDetails, setSourceSystemDetails] = useState([]);
    const [selectedSystemType, setSelectedSystemType] = useState([]);
    const [selectedSubjectArea, setSelectedSubjectArea] = useState([])
    const [disableSourceFields, setDisableSourceFields] = useState(false);
    const nav = useNavigate();

    const [tickButtonClick, setTickButtonClick] = useState(false);
    const [sourceSystemNameValue, setSourceSystemNameValue] = useState("");

    useEffect(() => {
        fetchDropdown()
    }, [])

    useEffect(() => {
        console.log(sourceSystemInfo, 'sourceeeeee_sourceSystemInfo');
    }, [sourceSystemInfo])
    async function fetchDropdown() {
        document.getElementById("pageLoader").style.display = "block";
        let response = await projectsDropDown(state.userId, state.jwt);
        if (response.status != 200) {
            return;
        }
        console.log(response);
        if (state.isEdit === true) {
            await fetchDetails();
        }

        setSourceSystems(
            response?.data[9]?.filter(
                (data) =>
                    data.sourceSystemNameId != "850BB243-E292-4417-9F78-02E7BCF55BBB" &&
                    data.sourceSystemNameId != "389144DD-76A0-4120-9D22-43C861875690" &&
                    data.sourceSystemNameId != "E1F3EA06-98FB-4AC9-BAA2-A0869ACF0F68"
            )
        );
        document.getElementById("pageLoader").style.display = "none";
        setIsLoading(false)

    }

    async function fetchDetails() {
        console.log(state);
        //   let  body={
        //         projectId: state.projectId, 
        //         userId: state.userId 
        //     }

        let response = await getSourceSystemConnectioninfo(
            state.projectId,
            state.userId,
            state.jwt
        );
        console.log(response, "0000000000000000000000");
        if (response.status === 403) {
            sessionExpired();
            return;
        }
        if (response.status != 200) {
        } else {
            if (response?.data[0]?.length > 0) {
                setDisableSourceFields(true);
            }
            console.log(response?.data[0])
            setSourceSystemDetails(prevDetails => {
                const newDetails = response?.data[0]
                    ?.filter((data) => data.connectionDetails != null)
                    .map((data) => ({
                        ...data,
                        connectionDetails: JSON.parse(data.connectionDetails),
                    }));
                // Reverse the array before setting it
                return newDetails ? newDetails.reverse() : [];
            });

        }
    }

    const connectionIfoType1 = type1;

    const connectionIfoType2 = type2;

    const connectionIfoType3 = type3;

    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            nav("/");
        }, 3000);
    };


    /**
  /* PS_CD_1.37 - 1.46///
     */
    {/*The handleSourceSystemInputChange function manages the input changes for source system details. It takes name and value parameters to update specific fields of the source system information
For fields like "systemType" and "subjectArea", it toggles the selection based on whether the value is already present in the array. For other fields, it directly updates the value.
Finally, it updates the state with the modified source system information. This function ensures dynamic handling of input changes for different fields within the source system information. */}
    function handleSourceSystemInputChange(name, value) {
        let selectedValues = Array.isArray(sourceSystemInfo[name]) ? [...sourceSystemInfo[name]] : [];

        if (name === 'systemType' || name === 'subjectArea') {
            if (selectedValues.includes(value)) {
                selectedValues = selectedValues.filter(item => item !== value);
            } else {
                selectedValues = [...selectedValues, value];
            }

            let updatedValue = selectedValues.length > 0 ? selectedValues : ['Select'];

            if (name === 'systemType') {
                setSelectedSystemType(updatedValue);
            } else if (name === 'subjectArea') {
                setSelectedSubjectArea(updatedValue);
            }

            setSourceSystemInfo({ ...sourceSystemInfo, [name]: selectedValues });
        } else if (name === 'dataFormat') {
            setSourceSystemInfo({ ...sourceSystemInfo, [name]: value, sourceSystemNameId: "Select", newSourceSystemName: "" });
        } else {
            setSourceSystemInfo({ ...sourceSystemInfo, [name]: value });
        }
    }
    function handleSourceSystemInputChangeDev(name, value) {
        setSourceSystemInfo({ ...sourceSystemInfo, [name]: value });
    }

    // PS_CD_1.253-1.262
    {/*asynchronous function that retrieves details of a source system based on a given system ID. It searches for a specific source system
object in an array and performs different actions based on the type of source system found. The function handles various source systems
like MSSQL, Snowflake, Postgres, Salesforce, Redshift, and Oracle. For each type of system, it constructs data in a specific format and
exports it to an Excel file. The function includes logic to handle different responses from the source systems and manages data accordingly,
ensuring proper data export based on the retrieved information. */}
    const getSourceSystemDetails = async (sourceSystemId) => {
        /* The above code is using the `find` method to search for a specific `sourceSystem` object in
        the `sourceSystems` array based on a condition. The condition being checked is whether the
        `sourceSystemNameId` property of each `sourceSystem` object is equal to the `sourceSystemId`
        variable. If a matching `sourceSystem` object is found, it is assigned to the `sourceSystem`
        variable. */
        const sourceSystem = sourceSystems.find(system => system.sourceSystemNameId === sourceSystemId)

        // console.log(sourceSystem.sourceSystemNameName, 'sourceSystemNameName')
        if (sourceSystem.sourceSystemNameName === 'MSSQL') {
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                server: sourceSystemDetails[0]?.connectionDetails?.server,
                driver: sourceSystemDetails[0]?.connectionDetails?.driver,
                projectId: state.projectId,
                userId: state.userId
            };
            console.log(body, 'msssqqqlll')

            props.setLoader(() => true);
            let response = await getMsSQL(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                setShowSourcePopup(true);
                console.log("Please check connection details")
            } else {
                console.log(response);
                if (response.status === 200 && typeof response.data === 'object' && Object.keys(response.data).length > 0) {

                    const excelData = [];
                    const schemas = Object.values(response.data); // Get an array of schema objects

                    schemas.forEach(schema => {
                        const schemaName = schema.schema_name;
                        const tables = schema.tables;

                        tables.forEach(table => {
                            const tableName = table.table_name;
                            const columns = table.columns;

                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.is_nullable ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });

                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }

        if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.Username, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                username: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                account: sourceSystemDetails[0]?.connectionDetails?.account,
                warehouse: sourceSystemDetails[0]?.connectionDetails?.warehouse,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                schema: sourceSystemDetails[0]?.connectionDetails?.schema,
                userId: state.userId,
                projectId: state.project
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getSnowflake(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);
                console.log("Please check connection details")
            } else {
                console.log(response); // Log the response object
                if (response.status === 200 && typeof response.data.schemaa === 'object') {
                    const schemaa = response.data.schemaa;
                    const excelData = [];
                    Object.keys(schemaa).forEach(schemaName => {
                        const tables = schemaa[schemaName];
                        tables.forEach(table => {
                            const tableName = table.tableName;
                            const columns = table.columns;
                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.is_nullable ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });
                    console.log(excelData, 'excelData'); // Log excelData to check its content
                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Postgres') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.hostname,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database_name,
                userId: state.userId,
                projectId: state.projectId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getPostgres(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);
                console.log("Please check connection details")

            } else {
                console.log(response);
                if (response.status === 200 && response.data && Array.isArray(response.data.schema) && response.data.schema.length > 0) {
                    const excelData = [];
                    const schemas = response.data.schema;

                    schemas.forEach(schema => {
                        const schemaName = schema.schema_name;
                        const tables = schema.tables;

                        tables.forEach(table => {
                            const tableName = table.table_name;
                            const columns = table.columns;

                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database_name || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.isNull ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });

                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Mysql') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getMySQL(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);
                console.log("Please check connection details")

            } else {
                console.log(response);
                if (response.status === 200 && response.data && typeof response.data === 'object') {
                    const schemaData = response.data;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            Object.keys(tables).forEach(tableName => {
                                const columns = tables[tableName].columns;
                                columns.forEach(column => {
                                    excelData.push({
                                        SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                        SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                        DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                        SchemaName: schemaName,
                                        TableName: tableName,
                                        ColumnName: column.column_name,
                                        DataType: column.data_type,
                                        Constraints: column.not_null === 'YES' ? 'NOT NULL' : 'NULL',
                                        Description: 'NA' // You need to replace this with the actual description
                                    });
                                });
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }
        if (sourceSystem.sourceSystemNameName === 'HubSpot') {
            console.log('entered body');
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1');
            let tableList = sourceSystemDetails[0]?.connectionDetails?.tablename || ''; // Ensure tableList is initialized as a string

            // Split the string by commas and trim each part
            tableList = tableList.split(',').map(table => table.trim());

            let body = {
                accessToken: sourceSystemDetails[0]?.connectionDetails?.accesstoken,
                tableList: tableList,
                userId: state.userId
            };

            console.log('entered body', body);
            console.log(state.jwt);

            props.setLoader(() => true);
            let response = await getHubspot(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);

                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && Array.isArray(response.data.schema)) {
                    const schemaData = response.data.schema;

                    // Check if schemaData is valid and not empty
                    if (schemaData && schemaData.length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        schemaData.forEach(table => {
                            if (Array.isArray(table.columns)) {
                                table.columns.forEach(column => {
                                    excelData.push({
                                        SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                        SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                        DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                        SchemaName: table.schema_name,
                                        TableName: table.table_name,
                                        ColumnName: column.column_name,
                                        DataType: column.data_type,
                                        Constraints: column.is_null ? 'NULL' : 'NOT NULL',
                                        Description: 'NA'
                                    });
                                });
                            }
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }


        if (sourceSystem.sourceSystemNameName === 'Salesforce') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                username: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                security_token: sourceSystemDetails[0]?.connectionDetails?.security_token,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getSalesforce(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);

                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && typeof response.data === 'object') {
                    const schemaData = response.data;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            // Check if tables is an array before attempting to iterate over it
                            if (Array.isArray(tables)) {
                                tables.forEach(table => {
                                    if (Array.isArray(table.columns)) {
                                        table.columns.forEach(column => {
                                            excelData.push({
                                                SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                                SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                                DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                                SchemaName: schemaName,
                                                TableName: table.table_name,
                                                ColumnName: column.column_name,
                                                DataType: column.data_type,
                                                Constraints: column.isNull ? 'NULL' : 'NOT NULL',
                                                Description: 'NA'    // You need to replace this with the actual description
                                            });
                                        });
                                    }
                                });
                            } else {
                                console.log('Invalid tables data for schema:', schemaName);
                            }
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'DataDictionary.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Redshift') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getRedshift(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);
                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && Array.isArray(response.data.schema)) {
                    const schemaData = response.data.schema;

                    // Check if schemaData is valid and not empty
                    if (schemaData.length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        schemaData.forEach(schema => {
                            const { schema_name, tables } = schema;
                            tables.forEach(table => {
                                if (Array.isArray(table.columns)) {
                                    table.columns.forEach(column => {
                                        excelData.push({
                                            SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                            SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                            DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                            SchemaName: schema_name,
                                            TableName: table.table_name,
                                            ColumnName: column.column_name,
                                            DataType: column.data_type,
                                            Constraints: column.not_null === 'YES' ? 'NOT NULL' : 'NULL',
                                            Description: 'NA' // You need to replace this with the actual description
                                        });
                                    });
                                }
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }
        if (sourceSystem.sourceSystemNameName === 'Oracle') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            props.setLoader(() => true);
            let response = await getOracle(body, state.jwt);
            console.log(response, "000000000000");
            props.setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSource(true);
                console.log("Please check connection details")
            } else {
                console.log(response);
                if (response.status === 200 && response.data && typeof response.data.schemaa === 'object') {
                    const schemaData = response.data.schemaa;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            tables.forEach(table => {
                                if (Array.isArray(table.columns)) {
                                    table.columns.forEach(column => {
                                        excelData.push({
                                            SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown', // Fallback to 'Unknown' if systemName is not available
                                            SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown', // Fallback to 'Unknown' if subjectArea is not available
                                            DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown', // Fallback to 'Unknown' if database name is not available
                                            SchemaName: schemaName,
                                            TableName: table.tableName,
                                            ColumnName: column.column_name,
                                            DataType: column.data_type,
                                            Constraints: column.is_nullable === 'YES' ? 'NULL' : 'NOT NULL',
                                            Description: 'NA'    // You need to replace this with the actual description
                                        });
                                    });
                                }
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }
            }
        }
    }


    const handleCheckboxChange = (value) => {
        // Toggle the checkbox value using your function
        handleSourceSystemInputChange("systemType", value);
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };


    // PS_CD_1.264-1.262-1.269
    {/*The function downloadScripts takes a sourceSystemId as input to retrieve corresponding SQL script content based on the source system's name.
It iterates through an array of source systems, finding the matching system by ID. Then, it retrieves the script content for that system from a 
separate array of scripts. If script content is found, it triggers a download of a .sql file containing the script. This function accommodates 
multiple source systems like MSSQL, Snowflake, Postgres, MySQL, and Redshift. If no script content is found for the specified source system, 
it logs a message indicating this. */}
    const downloadScripts = async (sourceSystemId) => {
        const sourceSystem = sourceSystems.find(system => system.sourceSystemNameId === sourceSystemId);

        console.log(sourceSystem.sourceSystemNameName, 'sourceSystemNameName');

        let scriptContent = '';

        if (sourceSystem.sourceSystemNameName === 'MSSQL') {
            // Find the script object for MSSQL
            const scriptObject = scripts.find(script => script.sourceSystem === 'MSSQL');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        } else if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            // Find the script object for Snowflake
            const scriptObject = scripts.find(script => script.sourceSystem === 'Snowflake');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        } else if (sourceSystem.sourceSystemNameName === 'Postgres') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Postgres');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Mysql') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Mysql');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Redshift') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Redshift');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Snowflake');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }


        // Add other conditions for different source systems as needed

        if (scriptContent) {
            // Trigger download of .sql file
            const blob = new Blob([scriptContent], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'script.py';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } else {
            console.log('No script content found for the specified source system.');
        }
    };

    /**
     * PS_CD_1.96 - PS_CD_1.99
     
     */
    {/*The handleConnectionInputChange function facilitates the modification of connection details within an array of source system details.
It accepts three parameters: key (the specific connection detail to modify), value (the new value for the connection detail), and index 
(the index of the source system detail object within the array).The function begins by creating a copy of the sourceSystemDetails array 
to avoid mutating the state directly. It then updates the connection details of the source system at the specified index by assigning a 
new object that spreads the existing connection details and updates the specified key with the provided value. Finally, the updated array 
of source system details is logged to the console for debugging purposes, and the state is updated with the modified array using setSourceSystemDetails */}
    function handleConnectionInputChange(key, value, index) {

        let updatedConnectionInfo = [...sourceSystemDetails];
        updatedConnectionInfo[index].connectionDetails = {
            ...updatedConnectionInfo[index].connectionDetails,
            [key]: value,
        };
        console.log('Before state update:', updatedConnectionInfo);
        setSourceSystemDetails(updatedConnectionInfo);
    }

    // Ensure your input field is controlled properly



    /**
     * PS_CD_1.107 - PS_CD_1.120
     */
    async function handleSave() {
        try {
            props.close();
    
            // Store inventory details
            const updatedSourceSystemDetails = sourceSystemDetails.map((system) => {
                return {
                    ...system,
                    inventoryDD: system?.inventoryDD ? system.inventoryDD : "null", // Update inventoryDD with invData
                    inventoryName: system?.inventoryName
                };
            });
    
            // Update state with the updated sourceSystemDetails
            setSourceSystemDetails(updatedSourceSystemDetails);
    
            let body = {
                sourceSystemDetails: updatedSourceSystemDetails, // Use updatedSourceSystemDetails here
                projectId: state.projectId,
                userId: state.userId,
            };
    
            // props.setLoader(() => true);
            document.getElementById("pageLoader").style.display = "block";
    
            console.log(body, "bodySourceSystem");
    
            // Function to remove non-Latin1 characters
            function replaceNonLatin1(obj) {
                if (typeof obj === 'object' && obj !== null) {
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            obj[key] = replaceNonLatin1(obj[key]);
                        }
                    }
                } else if (typeof obj === 'string') {
                    // Replace non-Latin1 characters with an empty string
                    obj = obj.replace(/[^\x00-\xFF]/g, ''); // Remove characters outside the Latin1 range
                }
                return obj;
            }
    
            // Clean the JSON array
            const cleanedJsonArray = updatedSourceSystemDetails.map(item => replaceNonLatin1(item));
    
            let response = await postSourceSystemConnectioninfo(
                {
                    sourceSystemDetails: cleanedJsonArray, // Use cleanedJsonArray here
                    projectId: state.projectId,
                    userId: state.userId,
                },
                state.jwt
            );
    
            // props.setLoader(() => false);
            document.getElementById("pageLoader").style.display = "none";
    
            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status != 200) {
                setShowSourcePopup(true);
                setChatHistory((value) => [
                    ...value,
                    {
                        flow: "ai",
                        type: "text",
                        text: "Something went wrong!!!. Please fill the details again.",
                    },
                ]);
            } else {
                let data = [...chatHistory];
                if (data.length > 0) {
                    data[data.length - 1].show = false;
                }
    
                setDisableSourceFields(true);
                data.push({
                    flow: "user",
                    type: "text",
                    text: "Source System and Connection Details",
                });
    
                if (phase === "Define") {
                    if (props.layer == "Bronze") {
                        data[0].sourceSystemNames = updatedSourceSystemDetails.map(system => ({
                            NotebookName: `NB_${system.systemName}_Extraction`,
                        }));
                        data[2].extractionSeqData = JSON.stringify(data[0].sourceSystemNames)
                        data[2].extractionData = JSON.stringify(data[0].sourceSystemNames)
                        data.push({ flow: "ai", type: "tableRequirementPopup", phase: "Define", show: true });
                    }
                    else if (props.layer == "Silver") {
                        data.push({ flow: "ai", type: "getSilverERPopup", phase: "Define", show: true });
                    }
                    else if (props.layer == "Gold") {
                        data.push({ flow: "ai", type: "getGoldERPopup", phase: "Define", show: true });
                    }
                    else {
                        data.push({ flow: "ai", type: "tableRequirementPopup", phase: "Define", show: true });
                    }
    
                } else if (phase === "Design") {
                    if (state.layer === "Bronze" || state.layer === "Medallion") {
                        data[0].sourceSystemNames = updatedSourceSystemDetails.map(system => ({
                            NotebookName: `NB_${system.systemName}_Extraction`,
                        }));
                        data[2].extractionSeqData = JSON.stringify(data[0].sourceSystemNames)
                        data[2].extractionData = JSON.stringify(data[0].sourceSystemNames)
                        data.push({ flow: "ai", type: "tableRequirementPopup", phase: "Design", show: true });
                    } else {
                        data.push({ flow: "ai", type: "definePopup", phase: "Design", show: true });
                    }
                }
                else {
                    data.push({
                        flow: "ai",
                        type: "text",
                        text: "Let's move towards generating Notebooks",
                        phase: "Development"
                    });
                    data.push({ flow: "ai", type: "designPopup", phase: "Development", show: true });
                }
    
                setChatHistory((a) => [...data]);
                props.setChatHistory([...data]);
                props.insertChatHistory([...data]);
            }
        } catch (error) {
            console.error("Error in handleSave:", error);
            setValidationErrors(["Error in handleSave"]);
        }
    }




    function convertExcelToJSON(workbook, fileName, indexValue) {
        //PS_CD_1.223
        const sheet = workbook.Sheets["DataDictionary"];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        console.log("JSON", jsonData);

        const sheetData = [];
        // PS_CD_1.225
        jsonData.forEach((row) => {
            const rowData = {};
            //   PS_CD_1.227
            Object.keys(row).forEach((cell) => {
                // PS_CD_1.228
                const header = cell.trim();
                const value = row[cell];
                rowData[header] = value;
            });
            //   PS_CD_1.231
            sheetData.push(rowData);
            console.log("sheetData", sheetData);
            // setInvData(sheetData)
            // setSourceSystemDetails({...invData,inventoryDD:invData})
        })

        let sourceDeta = [...sourceSystemDetails]
        sourceDeta[indexValue] = { ...sourceDeta[indexValue], inventoryDD: sheetData, inventoryName: fileName }
        setSourceSystemDetails(sourceDeta)
        console.log("formValid", formValid);
    }

    const areAllFilesUploadedAndValid = () => {
        return sourceSystemDetails.every((system, index) => {
            // Check if a file has been uploaded
            const fileUploaded = system.fileName || system.inventoryName || system.inventoryDDName;

            // If no file is uploaded, return false
            if (!fileUploaded) {
                return false;
            }
            else {
                return true;
            }
        });
    };

    //   PS_CD_1.142

    const handleFileUpload = async (event, indexValue) => {
        console.log("in file upload fn");
        return new Promise((resolve, reject) => {
            try {
                const selectedFile = event.target.files[0];
                setValidationErrors([])
                const setValidationErrorsForIndex = (errors) => {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        [indexValue]: errors
                    }));
                };

                if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
                    setValidationErrorsForIndex([
                        "Invalid file format. Please upload a .xlsx file.",
                    ]);
                    reject("Invalid file format.");
                    setFormValid(false);
                    return;
                }

                const fileName = selectedFile.name;
                setSelectedFileName(fileName);
                setDesignData({ ...designData, fileName: fileName });

                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });
                        const visibleSheetNames = workbook.SheetNames.filter((name) => {
                            const sheet = workbook.Sheets[name];
                            return !sheet.Hidden || sheet.Hidden !== 2;
                        });

                        const mandatorySheets = ["DataDictionary"];
                        const missingSheets = mandatorySheets.filter(
                            (sheet) => !visibleSheetNames.includes(sheet)
                        );

                        if (missingSheets?.length > 0) {
                            setValidationErrorsForIndex([
                                `Missing mandatory sheets: ${missingSheets.join(", ")}`,
                            ]);
                            // reject("Missing mandatory sheets.");
                            setFormValid(false);
                            return;
                        }

                        const errors = [];
                        visibleSheetNames.forEach((sheetName) => {
                            const sheet = workbook.Sheets[sheetName];
                            const a1Cell = sheet["A1"];

                            if (
                                !a1Cell ||
                                typeof a1Cell.v !== "string" ||
                                a1Cell.v.trim() === ""
                            ) {
                                errors.push(
                                    `Cell A1 should contain a non-empty string value in sheet ${sheetName}`
                                );
                            }

                            const a2Cell = sheet["A2"];
                            if (a2Cell === null || a2Cell === undefined || a2Cell.v === "") {
                                setFormValid(false);
                                errors.push(
                                    `Cell A2 should contain a non-empty value in sheet ${sheetName}`
                                );
                            }
                        });

                        if (errors.length > 0) {
                            setValidationErrorsForIndex(errors);
                            reject("Empty cells found.");
                            setFormValid(false);
                            return;
                        }

                        const getColumnIndex = (sheet, fieldName) => {
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            let columnIndex = -1;
                            for (let C = range.s.c; C <= range.e.c; ++C) {
                                const address = XLSX.utils.encode_cell({ r: range.s.r, c: C });
                                const cell = sheet[address];

                                if (cell && cell.v === fieldName) {
                                    columnIndex = C;
                                    break;
                                }
                            }
                            if (columnIndex === -1) {
                                console.log(`Column with field name '${fieldName}' not found`);
                                console.log("Sheet:", sheet);
                                console.log("Range:", range);
                            }
                            return columnIndex;
                        };

                        const displayValidationError = (
                            rowIndex,
                            columnIndex,
                            fieldName,
                            errorCode,
                            errorMessage,
                            dataType
                        ) => {
                            const errorDetails =
                                errorMessage +
                                ` (${typeof columnIndex === "number"
                                    ? String.fromCharCode(65 + columnIndex)
                                    : columnIndex
                                }${rowIndex + 1})`;
                            addValidationError(errorDetails);
                            setFormValid(false);
                        };

                        const addValidationError = (message) => {
                            setValidationErrors(prevErrors => ({
                                ...prevErrors,
                                [indexValue]: [...(prevErrors[indexValue] || []), message]
                            }));
                            setFormValid(false);
                        };

                        console.log("workbook.SheetNames", workbook.SheetNames);
                        const allErrors = [];
                        for (const sheetName of workbook.SheetNames) {
                            console.log(`Checking sheet: ${sheetName}`);
                            const sheet = workbook.Sheets[sheetName];
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            const errors = [];

                            if (sheetName === "DataDictionary") {
                                console.log("entered DataDictionary");
                                const expectedHeaders = [
                                    "SourceSystem",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "ColumnName",
                                    "DataType",
                                    "Constraints",
                                    "Description"
                                ];

                                for (let i = 0; i < expectedHeaders.length; i++) {
                                    const headerField = expectedHeaders[i];
                                    const columnHeader = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
                                    if (!columnHeader || columnHeader.v !== headerField) {
                                        errors.push(
                                            `Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`
                                        );
                                        displayValidationError(
                                            0,
                                            i,
                                            headerField,
                                            "MISSING",
                                            `Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`,
                                            "string"
                                        );
                                        setFormValid(false);
                                    }
                                }

                                const fieldsNotToBeEmpty = [
                                    "SourceSystem",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "ColumnName",
                                    "DataType",
                                    "Constraints",
                                    "Description"
                                ];

                                for (const fieldName of fieldsNotToBeEmpty) {
                                    const columnIndex = getColumnIndex(sheet, fieldName);

                                    if (columnIndex === -1) {
                                        console.log(`Column index for field '${fieldName}' not found.`);
                                        continue;
                                    }

                                    for (let i = 1; i <= range.e.r; i++) {
                                        const cell = sheet[XLSX.utils.encode_cell({ r: i, c: columnIndex })];
                                        const cellValue = (cell && cell.v !== undefined) ? cell.v : null;

                                        if (cellValue === null || cellValue === undefined || (typeof cellValue === "string" && cellValue.trim() === "")) {
                                            displayValidationError(i, columnIndex, fieldName, "EM004", `${fieldName} value should not be empty in ${sheetName} in row ${i + 1}`, "string");
                                        }

                                        if ((fieldName.toLowerCase() !== 'databasename' && fieldName.toLowerCase() !== 'schemaname') &&
                                            (typeof cellValue === "string" && cellValue.trim().toLowerCase() === 'na')) {
                                            displayValidationError(i, columnIndex, fieldName, "EM005", `${fieldName} value should not contain 'NA' in ${sheetName} in row ${i + 1}`, "string");
                                        }
                                    }
                                }
                            }

                            if (errors.length > 0) {
                                console.log(
                                    `Validation errors found in sheet ${sheetName}:`,
                                    errors
                                );
                                allErrors.push(...errors);
                                setFormValid(false);
                            }
                        }
                        console.log("formValid", formValid);

                        const jsonData = convertExcelToJSON(workbook, fileName, indexValue);
                        setFormValid(true);
                        resolve(jsonData);
                    } catch (error) {
                        console.error("Error reading the Excel file:", error);
                        setValidationErrorsForIndex(["Error reading the Excel file."]);
                        reject("Error reading the Excel file.");
                    }
                };
                reader.readAsBinaryString(selectedFile);
            } catch (error) {
                const setValidationErrorsForIndex = (errors) => {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        [indexValue]: errors
                    }));
                };
                console.error("Error processing the file:", error);
                setValidationErrorsForIndex(["Error processing the file."]);
                reject("Error processing the file.");
            }
            document.getElementById("meta-dataUpload-file").value = "";
        });
    };

    const exportToExcel = async () => {



        let data = 
        [
            {
                "SourceSystem": "ACME",
                "SubjectArea": "Sales",
                "DatabaseName": "SAP",
                "SchemaName": "saps4ha",
                "TableName": "KNVP",
                "ColumnName": "KUNNR",
                "DataType": "CHAR(10)",
                "Constraints": "NOT NULL",
                "Description": "Customer Number"
            },
            {
                "SourceSystem": "ACME",
                "SubjectArea": "Sales",
                "DatabaseName": "SAP",
                "SchemaName": "saps4ha",
                "TableName": "KNVK",
                "ColumnName": "NAMEV, NAME1",
                "DataType": "CHAR(35)",
                "Constraints": "NULL",
                "Description": "First Name, Last Name of the contact person"
            }
        ]

        // Convert JSON array to worksheet

        const ws = XLSX.utils.json_to_sheet(data);


        // Create workbook

        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'DataDictionary');


        // Generate Excel file and download

        XLSX.writeFile(wb, 'InventoryData.xlsx');

    }


    const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });

    const handleMouseEnter = (e, content) => {
        if (content.length > 1) {
            setTooltip({
                show: true,
                text: content,
                x: e.clientX + 10, // Adjust as needed to position tooltip slightly to the right of the cursor
                y: e.clientY + 20 // Adjust as needed to position tooltip slightly below the cursor
            });
        }
    };
    const handleMouseLeave = () => {
        setTooltip({ show: false, text: '', x: 0, y: 0 });
    };



    return (
        <>
            {!isLoading &&
                <div
                    className="modal-open"
                    style={{ overflow: "hidden", paddingRight: 0 }}
                >
                    <div className="container-fluid">
                        <div className="row">

                            <div className="modal-backdrop fade show"></div>
                            <div
                                className="modal fade show"
                                id="ConnectionInfo"
                                style={{ display: "block", paddingLeft: 0 }}
                            >
                                <div
                                    className="overlay"
                                    id="pageLoader"
                                    style={{ display: "none" }}
                                >
                                    <div className="position-absolute top-50 start-50  translate-middle">
                                        <div className="d-flex align-items-center loader-bg">
                                            <div
                                                className="spinner-border Loader text-dark align-center"
                                                role="status"
                                            >
                                                <span className="visually-hidden"></span>
                                            </div>
                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content px-2">
                                        <div className="modal-header border-0">
                                            <h5
                                                className="modal-title d-flex align-items-center font-medium font-20"
                                                id="ConnectionInfoLabel"
                                            >
                                                Source System and Connection Info
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    props.close()
                                                    setShowSourcePopup(true);
                                                    if (disableSourceFields === false) {
                                                        setSourceSystemDetails([]);
                                                        setSourceSystemInfo({
                                                            systemName: "",
                                                            InternalExternal: "Select",
                                                            systemType: "Select",
                                                            subjectArea: "Select",
                                                            dataFormat: "Select",
                                                            sourceSystemNameId: "Select",
                                                            sourceSystemType: "Select",
                                                            dataVolume: "Select",
                                                            descriptions: "",
                                                            newSourceSystemName: "",
                                                            comments: "",
                                                            connectionDetails: {},
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                        {showSourcePopup === true ? (
                                            <div className="modal-body py-0 my-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* stepper starts here */}
                                                        <div className="d-flex justify-content-center mb-5">
                                                            <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                                <li className="stepper-sty active me-5 pe-4 d-flex align-items-center">
                                                                    <span className="connect-info-icon icon-sty" />
                                                                    <span className="stepper-text font-14 font-bold ms-2">
                                                                        Source System
                                                                    </span>
                                                                </li>
                                                                <li className=" stepper-sty me-5 pe-4 d-flex align-items-center position-relative">
                                                                    <span className="meta-info-icon icon-sty stepper-line " />
                                                                    <span className="stepper-text font-14 font-bold ms-2">
                                                                        Connection Info
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* stepper ends here */}
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="table-responsive meta-table-scrl mt-3">
                                                            <table className="table table-borderless rounded custom-grid border-0">
                                                                <thead className="sticky-top-pos custom-tableHeader">
                                                                    <tr>
                                                                        <th>System Name</th>
                                                                        <th>Internal/External</th>
                                                                        <th>System Type</th>
                                                                        <th>Subject Area</th>
                                                                        {phase === "Development" ?
                                                                            <th>Data Format</th>
                                                                            :
                                                                            <th>Data Analysis</th>
                                                                        }
                                                                        <th>Source System Name</th>
                                                                        {sourceSystemInfo.length > 0 && sourceSystemInfo?.some(item => item.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3") ?
                                                                            <th>Comments</th>
                                                                            : sourceSystemInfo.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" &&
                                                                            <th>Comments</th>
                                                                        }
                                                                        <th>Source System Type</th>
                                                                        <th>Data Volume</th>
                                                                        <th>Description</th>
                                                                        {!disableSourceFields &&
                                                                            <th className="text-center">Action</th>
                                                                        }

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {sourceSystemInfo?.index === undefined &&
                                                                        disableSourceFields === false ? (
                                                                        <tr>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control cust-input-sty font-12 font-regular"
                                                                                    placeholder="Enter here"
                                                                                    value={sourceSystemInfo.systemName}
                                                                                    name="systemName"
                                                                                    onChange={(e) =>
                                                                                        handleSourceSystemInputChange(
                                                                                            e.target.name,
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <a
                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                        role="button"
                                                                                        id="dropdownMenuLink"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                        style={{ zIndex: 1001 }}
                                                                                    >
                                                                                        {sourceSystemInfo.InternalExternal}
                                                                                    </a>
                                                                                    <ul
                                                                                        className="dropdown-menu cust-dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuLink"
                                                                                    >
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "InternalExternal",
                                                                                                    "Internal"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                Internal
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "InternalExternal",
                                                                                                    "External"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                External
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                            {phase === "Development" ?
                                                                                <>
                                                                                    <td>
                                                                                        <div className="dropdown">
                                                                                            <a
                                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                role="button"
                                                                                                id="dropdownMenuLink"
                                                                                                data-bs-toggle="dropdown"
                                                                                                aria-expanded="false"
                                                                                                style={{ zIndex: 1001 }}
                                                                                            >
                                                                                                {sourceSystemInfo.systemType}
                                                                                            </a>
                                                                                            <ul
                                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                                aria-labelledby="dropdownMenuLink"
                                                                                            >
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "systemType",
                                                                                                            "Operational"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Operational
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "systemType",
                                                                                                            "Finance"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Finance
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "systemType",
                                                                                                            "HR"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        HR
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "systemType",
                                                                                                            "Sales"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Sales
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "systemType",
                                                                                                            "Marketing"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Marketing
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="dropdown">
                                                                                            <a
                                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                role="button"
                                                                                                id="dropdownMenuLink"
                                                                                                data-bs-toggle="dropdown"
                                                                                                aria-expanded="false"
                                                                                                style={{ zIndex: 1001 }}
                                                                                            >
                                                                                                {sourceSystemInfo.subjectArea}
                                                                                            </a>
                                                                                            <ul
                                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                                aria-labelledby="dropdownMenuLink"
                                                                                            >
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "subjectArea",
                                                                                                            "Operational"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Operational
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "subjectArea",
                                                                                                            "Finance"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Finance
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "subjectArea",
                                                                                                            "HR"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        HR
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "subjectArea",
                                                                                                            "Sales"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Sales
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() =>
                                                                                                        handleSourceSystemInputChangeDev(
                                                                                                            "subjectArea",
                                                                                                            "Marketing"
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Marketing
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>
                                                                                        <div className="dropdown">
                                                                                            <a
                                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                role="button"
                                                                                                id="dropdownMenuLink"
                                                                                                data-bs-toggle="dropdown"
                                                                                                aria-expanded={isOpen}
                                                                                                style={{ zIndex: 1001 }}
                                                                                                onClick={() => { handleDropdownToggle(); setIsOpen(false); }}
                                                                                            >
                                                                                                {selectedSystemType.length > 0 ? selectedSystemType.join(', ') : 'Select'}
                                                                                            </a>
                                                                                            <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink" style={{ left: '0', right: 'auto' }}>
                                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            type="checkbox"
                                                                                                            id="checkboxOperational"
                                                                                                            checked={selectedSystemType.includes("Operational")}
                                                                                                            onChange={() => handleCheckboxChange("Operational")}
                                                                                                        />
                                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                            Operational
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            type="checkbox"
                                                                                                            id="checkboxFinancial"
                                                                                                            checked={selectedSystemType.includes("Financial")}
                                                                                                            onChange={() => handleCheckboxChange("Financial")}
                                                                                                        />
                                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                            Financial
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            type="checkbox"
                                                                                                            id="checkboxHR"
                                                                                                            checked={selectedSystemType.includes("HR")}
                                                                                                            onChange={() => handleCheckboxChange("HR")}
                                                                                                        />
                                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxHR" onClick={(e) => e.stopPropagation()}>
                                                                                                            HR
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            type="checkbox"
                                                                                                            id="checkboxSales"
                                                                                                            checked={selectedSystemType.includes("Sales")}
                                                                                                            onChange={() => handleCheckboxChange("Sales")}
                                                                                                        />
                                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxSales" onClick={(e) => e.stopPropagation()}>
                                                                                                            Sales
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            type="checkbox"
                                                                                                            id="checkboxMarketing"
                                                                                                            checked={selectedSystemType.includes("Marketing")}
                                                                                                            onChange={() => handleCheckboxChange("Marketing")}
                                                                                                        />
                                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                            Marketing
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="dropdown" onClick={() => { handleDropdownToggle(); setIsOpen(false); }}>
                                                                                            <a
                                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                role="button"
                                                                                                id="dropdownMenuLink2"
                                                                                                data-bs-toggle="dropdown"
                                                                                                aria-expanded={isOpen}
                                                                                                style={{ zIndex: 1001 }}
                                                                                            >
                                                                                                {selectedSubjectArea.length > 0 ? selectedSubjectArea.join(', ') : 'Select'}
                                                                                            </a>
                                                                                            <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink2">
                                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                            type="checkbox"
                                                                                                            id="flexCheckOperational"
                                                                                                            checked={selectedSubjectArea.includes('Operational')}
                                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Operational")}
                                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                        />
                                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                            Operational
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                            type="checkbox"
                                                                                                            id="flexCheckFinancial"
                                                                                                            checked={selectedSubjectArea.includes('Financial')}
                                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Financial")}
                                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                        />
                                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                            Financial
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                            type="checkbox"
                                                                                                            id="flexCheckHR"
                                                                                                            checked={selectedSubjectArea.includes('HR')}
                                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "HR")}
                                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                        />
                                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckHR" onClick={(e) => e.stopPropagation()}>
                                                                                                            HR
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                            type="checkbox"
                                                                                                            id="flexCheckSales"
                                                                                                            checked={selectedSubjectArea.includes('Sales')}
                                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Sales")}
                                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                        />
                                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckSales" onClick={(e) => e.stopPropagation()}>
                                                                                                            Sales
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                    <div className="form-check ms-2">
                                                                                                        <input
                                                                                                            className="form-check-input cursorpointer"
                                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                            type="checkbox"
                                                                                                            id="flexCheckMarketing"
                                                                                                            checked={selectedSubjectArea.includes('Marketing')}
                                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Marketing")}
                                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                        />
                                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                            Marketing
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <a
                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3" role="button"
                                                                                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}
                                                                                    >
                                                                                        {sourceSystemInfo.dataFormat}
                                                                                    </a>
                                                                                    <ul
                                                                                        className="dropdown-menu cust-dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuLink"
                                                                                    >
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataFormat",
                                                                                                    "Automatic"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                Automatic
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataFormat",
                                                                                                    "Manual"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                Manual
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                            {sourceSystemInfo.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ?
                                                                                <>
                                                                                    <td>
                                                                                        <div className="input-group position-relative me-md-3" style={{ overflow: 'visible' }}>
                                                                                            <div style={{ position: 'relative', width: '100%' }}>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                                                                    value={sourceSystemInfo?.newSourceSystemName ? sourceSystemInfo?.newSourceSystemName : sourceSystemNameValue}
                                                                                                    placeholder="Enter"
                                                                                                    onClick={() => {
                                                                                                        if (sourceSystemInfo.newSourceSystemName !== "") {
                                                                                                            setSourceSystemInfo({ ...sourceSystemInfo, newSourceSystemName: "", sourceSystemNameId: "Select", comments: "" })
                                                                                                            setSourceSystemNameValue('')
                                                                                                            setTickButtonClick(false)
                                                                                                        }
                                                                                                    }}
                                                                                                    onChange={(e) =>
                                                                                                        setSourceSystemNameValue(e.target.value)
                                                                                                    }
                                                                                                    style={{
                                                                                                        //paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                                                        width: '100%',
                                                                                                    }}
                                                                                                />
                                                                                                {!tickButtonClick &&
                                                                                                    <>
                                                                                                        <span className="" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                                                                                            onClick={() => {
                                                                                                                if (sourceSystemNameValue.trim() !== '') {
                                                                                                                    handleSourceSystemInputChange(
                                                                                                                        "newSourceSystemName",
                                                                                                                        sourceSystemNameValue
                                                                                                                    )
                                                                                                                    setTickButtonClick(true)
                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <a> <img className="" src="images/tick-icon.svg" alt="tick-icon" /></a>
                                                                                                        </span>

                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.sourceSystemNameId !=
                                                                                                "Select"
                                                                                                ? sourceSystems.filter(
                                                                                                    (data) =>
                                                                                                        data.sourceSystemNameId ==
                                                                                                        sourceSystemInfo.sourceSystemNameId
                                                                                                )[0].sourceSystemNameName
                                                                                                : "Select"}
                                                                                        </a>
                                                                                        <ul className="dropdown-menu cust-dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                            {sourceSystems
                                                                                                .filter((data) => data.projectTypeId === state.projectTypeId)
                                                                                                .map((data) => (
                                                                                                    state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                        (data.sourceSystemNameId === "27940D09-4AEE-401D-A5D1-C5604624B804" ||
                                                                                                            data.sourceSystemNameId === "2B6C0990-0653-433D-ADC5-415BF3B28757" ||
                                                                                                            data.sourceSystemNameId === "55253BD8-9AD0-4FA8-933B-D34B85414069" ||
                                                                                                            data.sourceSystemNameId === "68C9F589-6347-4564-A3D7-4787BE3B43F4" ||
                                                                                                            data.sourceSystemNameId === "6F1E2B59-E1A7-4F15-9A42-88802D2268F2" ||
                                                                                                            data.sourceSystemNameId === "93AD6411-C531-4451-A9D4-3A03F4458287") && (
                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                <a className="dropdown-item font-12">
                                                                                                                    {data.sourceSystemNameName}
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        )
                                                                                                    ) : (
                                                                                                        state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                            (
                                                                                                                data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ||
                                                                                                                data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5") && (
                                                                                                                <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                    <a className="dropdown-item font-12">
                                                                                                                        {data.sourceSystemNameName}
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            )
                                                                                                        ) : (
                                                                                                            state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ? (
                                                                                                                <>
                                                                                                                    {state.futureStateName === "Databricks" && (
                                                                                                                        <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                            <a className="dropdown-item font-12">
                                                                                                                                Redshift
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5" ? (
                                                                                                                    <>
                                                                                                                        {state.futureStateName === "Databricks" && (
                                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                <a className="dropdown-item font-12">
                                                                                                                                    Snowflake
                                                                                                                                </a>
                                                                                                                            </li>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                        <a className="dropdown-item font-12">
                                                                                                                            {data.sourceSystemNameName}
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )

                                                                                                ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                            {sourceSystemInfo.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" &&
                                                                                <td>
                                                                                    <input
                                                                                        type="textarea"
                                                                                        value={sourceSystemInfo?.comments}
                                                                                        placeholder="Enter"
                                                                                        onChange={(e) =>
                                                                                            handleSourceSystemInputChange(
                                                                                                "comments",
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                        className="form-control cust-input-sty font-14 font-regular"
                                                                                    />
                                                                                </td>
                                                                            }
                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <a
                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                        role="button"
                                                                                        id="dropdownMenuLink"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                        style={{ zIndex: 1001 }}
                                                                                    >
                                                                                        {sourceSystemInfo.sourceSystemType}
                                                                                    </a>
                                                                                    <ul
                                                                                        className="dropdown-menu cust-dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuLink"
                                                                                    >

                                                                                        {sourceSystemInfo.sourceSystemNameId !=
                                                                                            "Select" ? (
                                                                                            <>
                                                                                                {cloudonprem.toLocaleLowerCase().replace(/ /g, "").includes(
                                                                                                    sourceSystems.filter(
                                                                                                        (a) => a.sourceSystemNameId === sourceSystemInfo.sourceSystemNameId)[0]
                                                                                                        ?.sourceSystemNameName.toLocaleLowerCase()
                                                                                                        .replace(/ /g, "")
                                                                                                ) ? (
                                                                                                    <li className="cursorpointer"
                                                                                                        onClick={() => handleSourceSystemInputChange("sourceSystemType", "Onprem")}
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Onprem
                                                                                                        </a>
                                                                                                    </li>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                                <li className="cursorpointer"
                                                                                                    onClick={() => handleSourceSystemInputChange("sourceSystemType", "Cloud")}
                                                                                                >
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        Cloud
                                                                                                    </a>
                                                                                                </li>
                                                                                            </>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <a
                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                        role="button"
                                                                                        id="dropdownMenuLink"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                        style={{ zIndex: 1001 }}
                                                                                    >
                                                                                        {sourceSystemInfo.dataVolume}
                                                                                    </a>
                                                                                    <ul
                                                                                        className="dropdown-menu cust-dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuLink"
                                                                                    >
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "Less than 1 GB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                Less than 1 GB
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "1 GB to 500 GB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                1 GB to 500 GB
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "500 GB to 1 TB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                500 GB to 1 TB
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "1 TB to 10 TB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                1 TB to 10 TB
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "10 TB to 100 TB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                10 TB to 100 TB
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() =>
                                                                                                handleSourceSystemInputChange(
                                                                                                    "dataVolume",
                                                                                                    "More than 100 TB"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                More than 100 TB
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    value={sourceSystemInfo.descriptions}
                                                                                    placeholder="Enter"
                                                                                    onChange={(e) =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "descriptions",
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                    className="form-control cust-input-sty font-14 font-regular"
                                                                                />
                                                                            </td>
                                                                            {!disableSourceFields &&
                                                                                <td className="text-center">
                                                                                    <a
                                                                                        className={
                                                                                            sourceSystemDetails.findIndex(
                                                                                                (data) => data.systemName === sourceSystemInfo.systemName
                                                                                            ) === -1 &&
                                                                                                sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                sourceSystemInfo.systemType !== "Select" &&
                                                                                                (
                                                                                                    sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                    (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                )
                                                                                                ? ""
                                                                                                : "disable-btn"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                sourceSystemDetails.findIndex(
                                                                                                    (data) => data.systemName === sourceSystemInfo.systemName
                                                                                                ) === -1 &&
                                                                                                sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                sourceSystemInfo.systemType !== "Select" &&
                                                                                                (
                                                                                                    sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                    (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                )
                                                                                            ) {
                                                                                                setSourceSystemDetails([...sourceSystemDetails, sourceSystemInfo]);
                                                                                                setSourceSystemInfo({
                                                                                                    systemName: "",
                                                                                                    InternalExternal: "Select",
                                                                                                    systemType: "Select",
                                                                                                    subjectArea: "Select",
                                                                                                    dataFormat: "Select",
                                                                                                    sourceSystemNameId: "Select",
                                                                                                    sourceSystemType: "Select",
                                                                                                    dataVolume: "Select",
                                                                                                    descriptions: "",
                                                                                                    newSourceSystemName: "",
                                                                                                    comments: "",
                                                                                                    connectionDetails: {},
                                                                                                });
                                                                                                setSelectedSystemType([]);
                                                                                                setSelectedSubjectArea([]);
                                                                                                setSourceSystemNameValue('')
                                                                                                setTickButtonClick(false)

                                                                                            }
                                                                                        }}
                                                                                        style={{
                                                                                            opacity:
                                                                                                sourceSystemDetails.findIndex(
                                                                                                    (data) => data.systemName === sourceSystemInfo.systemName
                                                                                                ) === -1 &&
                                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                    sourceSystemInfo.systemType !== "Select" &&
                                                                                                    (
                                                                                                        sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                        (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                    )
                                                                                                    ? 1
                                                                                                    : 0.5,
                                                                                            cursor:
                                                                                                sourceSystemDetails.findIndex(
                                                                                                    (data) => data.systemName === sourceSystemInfo.systemName
                                                                                                ) === -1 &&
                                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                    sourceSystemInfo.systemType !== "Select" &&
                                                                                                    (
                                                                                                        sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                        (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                    )
                                                                                                    ? "pointer"
                                                                                                    : "not-allowed",
                                                                                        }}
                                                                                    >
                                                                                        <span className="cust-cursor-pointer">
                                                                                            <img src="images/add-icons.svg" alt="Add" />
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {console.log(
                                                                        sourceSystemDetails,
                                                                        "sourceSystemDetails111"
                                                                    )}
                                                                    {sourceSystemDetails.map((data, index) => {
                                                                        return (
                                                                            <>
                                                                                {sourceSystemInfo?.index === index ? (
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control cust-input-sty font-12 font-regular"
                                                                                                placeholder="Enter here"
                                                                                                value={sourceSystemInfo.systemName}
                                                                                                name="systemName"
                                                                                                onChange={(e) =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        e.target.name,
                                                                                                        e.target.value
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="dropdown">
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded="false"
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                >
                                                                                                    {sourceSystemInfo.InternalExternal}
                                                                                                </a>
                                                                                                <ul
                                                                                                    className="dropdown-menu cust-dropdown-menu"
                                                                                                    aria-labelledby="dropdownMenuLink"
                                                                                                >
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "InternalExternal",
                                                                                                                "Internal"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Internal
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "InternalExternal",
                                                                                                                "External"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            External
                                                                                                        </a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="dropdown">
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded={isOpen}
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                    onClick={() => { handleDropdownToggle(); setIsOpen(false); }}
                                                                                                >
                                                                                                    {Array.isArray(sourceSystemInfo?.systemType) ? (sourceSystemInfo?.systemType.length > 0 ? sourceSystemInfo?.systemType.join(', ') : 'Select') : sourceSystemInfo?.systemType}
                                                                                                </a>
                                                                                                <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink" style={{ left: '0', right: 'auto' }}>
                                                                                                    <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id="checkboxOperational"
                                                                                                                checked={selectedSystemType.includes("Operational")}
                                                                                                                onChange={() => handleCheckboxChange("Operational")}
                                                                                                            />
                                                                                                            <label className="form-check-label font-12" htmlFor="checkboxOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                                Operational
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id="checkboxFinancial"
                                                                                                                checked={selectedSystemType.includes("Financial")}
                                                                                                                onChange={() => handleCheckboxChange("Financial")}
                                                                                                            />
                                                                                                            <label className="form-check-label font-12" htmlFor="checkboxFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                                Financial
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id="checkboxHR"
                                                                                                                checked={selectedSystemType.includes("HR")}
                                                                                                                onChange={() => handleCheckboxChange("HR")}
                                                                                                            />
                                                                                                            <label className="form-check-label font-12" htmlFor="checkboxHR" onClick={(e) => e.stopPropagation()}>
                                                                                                                HR
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id="checkboxSales"
                                                                                                                checked={selectedSystemType.includes("Sales")}
                                                                                                                onChange={() => handleCheckboxChange("Sales")}
                                                                                                            />
                                                                                                            <label className="form-check-label font-12" htmlFor="checkboxSales" onClick={(e) => e.stopPropagation()}>
                                                                                                                Sales
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id="checkboxMarketing"
                                                                                                                checked={selectedSystemType.includes("Marketing")}
                                                                                                                onChange={() => handleCheckboxChange("Marketing")}
                                                                                                            />
                                                                                                            <label className="form-check-label font-12" htmlFor="checkboxMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                                Marketing
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="dropdown" onClick={() => { handleDropdownToggle(); setIsOpen(false); }}>
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink2"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded={isOpen}
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                >
                                                                                                    {Array.isArray(sourceSystemInfo?.subjectArea) ? (sourceSystemInfo?.subjectArea.length > 0 ? sourceSystemInfo?.subjectArea.join(', ') : 'Select') : sourceSystemInfo?.subjectArea}
                                                                                                </a>
                                                                                                <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink2">
                                                                                                    <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                                type="checkbox"
                                                                                                                id="flexCheckOperational"
                                                                                                                checked={selectedSubjectArea.includes('Operational')}
                                                                                                                onChange={() => handleSourceSystemInputChange("subjectArea", "Operational")}
                                                                                                                onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                            />
                                                                                                            <label className="font-12" htmlFor="flexCheckOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                                Operational
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                                type="checkbox"
                                                                                                                id="flexCheckFinancial"
                                                                                                                checked={selectedSubjectArea.includes('Financial')}
                                                                                                                onChange={() => handleSourceSystemInputChange("subjectArea", "Financial")}
                                                                                                                onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                            />
                                                                                                            <label className="font-12" htmlFor="flexCheckFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                                Financial
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                                type="checkbox"
                                                                                                                id="flexCheckHR"
                                                                                                                checked={selectedSubjectArea.includes('HR')}
                                                                                                                onChange={() => handleSourceSystemInputChange("subjectArea", "HR")}
                                                                                                                onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                            />
                                                                                                            <label className="font-12" htmlFor="flexCheckHR" onClick={(e) => e.stopPropagation()}>
                                                                                                                HR
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                                type="checkbox"
                                                                                                                id="flexCheckSales"
                                                                                                                checked={selectedSubjectArea.includes('Sales')}
                                                                                                                onChange={() => handleSourceSystemInputChange("subjectArea", "Sales")}
                                                                                                                onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                            />
                                                                                                            <label className="font-12" htmlFor="flexCheckSales" onClick={(e) => e.stopPropagation()}>
                                                                                                                Sales
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                        <div className="form-check ms-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                                type="checkbox"
                                                                                                                id="flexCheckMarketing"
                                                                                                                checked={selectedSubjectArea.includes('Marketing')}
                                                                                                                onChange={() => handleSourceSystemInputChange("subjectArea", "Marketing")}
                                                                                                                onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                            />
                                                                                                            <label className="font-12" htmlFor="flexCheckMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                                Marketing
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="dropdown">
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded="false"
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                >
                                                                                                    {sourceSystemInfo.dataFormat}
                                                                                                </a>
                                                                                                <ul
                                                                                                    className="dropdown-menu cust-dropdown-menu"
                                                                                                    aria-labelledby="dropdownMenuLink"
                                                                                                >
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataFormat",
                                                                                                                "Automatic"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Automatic
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataFormat",
                                                                                                                "Manual"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Manual
                                                                                                        </a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        {sourceSystemInfo.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ?
                                                                                            <>
                                                                                                <td>
                                                                                                    <div className="input-group position-relative me-md-3" style={{ overflow: 'visible' }}>
                                                                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                                                                                value={sourceSystemInfo?.newSourceSystemName ? sourceSystemInfo?.newSourceSystemName : sourceSystemNameValue}
                                                                                                                placeholder="Enter"
                                                                                                                onClick={() => {
                                                                                                                    if (sourceSystemInfo.newSourceSystemName !== "") {
                                                                                                                        setSourceSystemInfo({ ...sourceSystemInfo, newSourceSystemName: "", sourceSystemNameId: "Select", comments: "" })
                                                                                                                        setSourceSystemNameValue('')
                                                                                                                        setTickButtonClick(false)
                                                                                                                    }
                                                                                                                }}
                                                                                                                onChange={(e) =>
                                                                                                                    setSourceSystemNameValue(e.target.value)
                                                                                                                }
                                                                                                                style={{
                                                                                                                    //paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                                                                    width: '100%',
                                                                                                                }}
                                                                                                            />
                                                                                                            {!tickButtonClick &&
                                                                                                                <>
                                                                                                                    <span className="" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                                                                                                        onClick={() => {
                                                                                                                            if (sourceSystemNameValue.trim() !== '') {
                                                                                                                                handleSourceSystemInputChange(
                                                                                                                                    "newSourceSystemName",
                                                                                                                                    sourceSystemNameValue
                                                                                                                                )
                                                                                                                                setTickButtonClick(true)
                                                                                                                            }

                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <a> <img className="" src="images/tick-icon.svg" alt="tick-icon" /></a>
                                                                                                                    </span>

                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </>
                                                                                            :
                                                                                            <td>
                                                                                                <div className="dropdown">
                                                                                                    <a
                                                                                                        className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                        role="button"
                                                                                                        id="dropdownMenuLink"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        aria-expanded="false"
                                                                                                        style={{ zIndex: 1001 }}
                                                                                                    >
                                                                                                        {sourceSystemInfo.sourceSystemNameId !=
                                                                                                            "Select"
                                                                                                            ? sourceSystems.filter(
                                                                                                                (data) =>
                                                                                                                    data.sourceSystemNameId ==
                                                                                                                    sourceSystemInfo.sourceSystemNameId
                                                                                                            )[0].sourceSystemNameName
                                                                                                            : "Select"}
                                                                                                    </a>
                                                                                                    <ul className="dropdown-menu cust-dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                                        {sourceSystems
                                                                                                            .filter((data) => data.projectTypeId === state.projectTypeId)
                                                                                                            .map((data) => (
                                                                                                                state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                                    (data.sourceSystemNameId === "27940D09-4AEE-401D-A5D1-C5604624B804" ||
                                                                                                                        data.sourceSystemNameId === "2B6C0990-0653-433D-ADC5-415BF3B28757" ||
                                                                                                                        data.sourceSystemNameId === "55253BD8-9AD0-4FA8-933B-D34B85414069" ||
                                                                                                                        data.sourceSystemNameId === "68C9F589-6347-4564-A3D7-4787BE3B43F4" ||
                                                                                                                        data.sourceSystemNameId === "6F1E2B59-E1A7-4F15-9A42-88802D2268F2" ||
                                                                                                                        data.sourceSystemNameId === "93AD6411-C531-4451-A9D4-3A03F4458287") && (
                                                                                                                        <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                            <a className="dropdown-item font-12">
                                                                                                                                {data.sourceSystemNameName}
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    )
                                                                                                                ) : (
                                                                                                                    state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                                        (
                                                                                                                            data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ||
                                                                                                                            data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5") && (
                                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                <a className="dropdown-item font-12">
                                                                                                                                    {data.sourceSystemNameName}
                                                                                                                                </a>
                                                                                                                            </li>
                                                                                                                        )
                                                                                                                    ) : (
                                                                                                                        state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ? (
                                                                                                                            <>
                                                                                                                                {state.futureStateName === "Databricks" && (
                                                                                                                                    <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                        <a className="dropdown-item font-12">
                                                                                                                                            Redshift
                                                                                                                                        </a>
                                                                                                                                    </li>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5" ? (
                                                                                                                                <>
                                                                                                                                    {state.futureStateName === "Databricks" && (
                                                                                                                                        <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                            <a className="dropdown-item font-12">
                                                                                                                                                Snowflake
                                                                                                                                            </a>
                                                                                                                                        </li>
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                    <a className="dropdown-item font-12">
                                                                                                                                        {data.sourceSystemNameName}
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            )
                                                                                                                        )
                                                                                                                    )
                                                                                                                )

                                                                                                            ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </td>
                                                                                        }
                                                                                        {sourceSystemInfo.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" &&
                                                                                            <td>
                                                                                                <input
                                                                                                    type="textarea"
                                                                                                    value={sourceSystemInfo?.comments}
                                                                                                    placeholder="Enter"
                                                                                                    onChange={(e) =>
                                                                                                        handleSourceSystemInputChange(
                                                                                                            "comments",
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    }
                                                                                                    className="form-control cust-input-sty font-14 font-regular"
                                                                                                />
                                                                                            </td>
                                                                                        }
                                                                                        <td>
                                                                                            <div className="dropdown">
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded="false"
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                >
                                                                                                    {sourceSystemInfo.sourceSystemType}
                                                                                                </a>
                                                                                                <ul
                                                                                                    className="dropdown-menu cust-dropdown-menu"
                                                                                                    aria-labelledby="dropdownMenuLink"
                                                                                                >
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "sourceSystemType",
                                                                                                                "Onprem"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Onprem
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "sourceSystemType",
                                                                                                                "Cloud"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Cloud
                                                                                                        </a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="dropdown">
                                                                                                <a
                                                                                                    className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                                    role="button"
                                                                                                    id="dropdownMenuLink"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded="false"
                                                                                                    style={{ zIndex: 1001 }}
                                                                                                >
                                                                                                    {sourceSystemInfo.dataVolume}
                                                                                                </a>
                                                                                                <ul
                                                                                                    className="dropdown-menu cust-dropdown-menu"
                                                                                                    aria-labelledby="dropdownMenuLink"
                                                                                                >
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "Less than 1 GB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            Less than 1 GB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "1 GB to 500 GB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            1 GB to 500 GB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "500 GB to 1 TB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            500 GB to 1 TB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "1 TB to 10 TB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            1 TB to 10 TB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "10 TB to 100 TB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            10 TB to 100 TB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                    <li
                                                                                                        onClick={() =>
                                                                                                            handleSourceSystemInputChange(
                                                                                                                "dataVolume",
                                                                                                                "More than 100 TB"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            More than 100 TB
                                                                                                        </a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={sourceSystemInfo.descriptions}
                                                                                                onChange={(e) =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "descriptions",
                                                                                                        e.target.value
                                                                                                    )
                                                                                                }
                                                                                                className="form-control cust-input-sty font-14 font-regular"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <a className={
                                                                                                sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                    sourceSystemInfo.systemType !== "Select" &&
                                                                                                    (
                                                                                                        sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                        (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                    )

                                                                                                    ? ""
                                                                                                    : "disable-btn"
                                                                                            }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        sourceSystemDetails.filter((data, i) => data.systemName === sourceSystemInfo.systemName && sourceSystemInfo.index != i).length === 0 &&
                                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                        sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                        sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                        sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                        sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                        sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                        sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                        sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                        sourceSystemInfo.systemType !== "Select" &&
                                                                                                        (
                                                                                                            sourceSystemInfo.sourceSystemNameId !== "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ||
                                                                                                            (sourceSystemInfo.newSourceSystemName.trim() !== "" && sourceSystemInfo.comments.trim() !== "")
                                                                                                        )
                                                                                                    ) {
                                                                                                        let arr = sourceSystemDetails.map((a, index) => {
                                                                                                            if (index === sourceSystemInfo.index) {
                                                                                                                let obj = { ...sourceSystemInfo };
                                                                                                                delete obj.index;
                                                                                                                return obj;
                                                                                                            } else {
                                                                                                                return a;
                                                                                                            }
                                                                                                        });
                                                                                                        setSourceSystemDetails(arr);
                                                                                                        setSourceSystemInfo({
                                                                                                            systemName: "",
                                                                                                            InternalExternal: "Select",
                                                                                                            systemType: "Select",
                                                                                                            subjectArea: "Select",
                                                                                                            dataFormat: "Select",
                                                                                                            sourceSystemNameId: "Select",
                                                                                                            sourceSystemType: "Select",
                                                                                                            dataVolume: "Select",
                                                                                                            descriptions: "",
                                                                                                            newSourceSystemName: "",
                                                                                                            comments: "",
                                                                                                            connectionDetails: {},
                                                                                                        });
                                                                                                        setEditClicked(false)
                                                                                                    }
                                                                                                }}>
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img
                                                                                                        src="images/right.svg"
                                                                                                        className="me-3"
                                                                                                        width={15}
                                                                                                        height={15}
                                                                                                        alt="Right Icon"
                                                                                                    />
                                                                                                </span>
                                                                                            </a>
                                                                                            <a onClick={() => {
                                                                                                setSourceSystemInfo({
                                                                                                    systemName: "",
                                                                                                    InternalExternal: "Select",
                                                                                                    systemType: "Select",
                                                                                                    subjectArea: "Select",
                                                                                                    dataFormat: "Select",
                                                                                                    sourceSystemNameId: "Select",
                                                                                                    sourceSystemType: "Select",
                                                                                                    dataVolume: "Select",
                                                                                                    descriptions: "",
                                                                                                    newSourceSystemName: "",
                                                                                                    comments: "",
                                                                                                    connectionDetails: {},
                                                                                                });
                                                                                                setEditClicked(false)
                                                                                            }}>
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img
                                                                                                        src="images/wrong.svg"
                                                                                                        width={20}
                                                                                                        height={20}
                                                                                                        alt="Wrong Icon"
                                                                                                    />
                                                                                                </span>
                                                                                            </a>
                                                                                        </td>

                                                                                    </tr>
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td>{data.systemName}</td>
                                                                                        <td>{data.InternalExternal}</td>
                                                                                        <td>{Array.isArray(data.systemType) ? data.systemType.join(', ') : data.systemType}</td>
                                                                                        <td>{Array.isArray(data.subjectArea) ? data.subjectArea.join(', ') : data.subjectArea}</td>
                                                                                        <td>{data.dataFormat}</td>
                                                                                        <td>
                                                                                            {console.log(data.newSourceSystemName, sourceSystems.filter((a) => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3", sourceSystems.filter((a) => a.sourceSystemNameId === data.sourceSystemNameId), 'wedwddwdeweewde')
                                                                                            }
                                                                                            {sourceSystems.filter((a) => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" ? data.newSourceSystemName : sourceSystems.filter((a) => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName}
                                                                                            {data.sourceSystemNameId === "F0D04D49-BA1C-47C6-B0EA-E643AE41ACD3" &&
                                                                                                <>
                                                                                                    <span
                                                                                                        className="cursor-pointer"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="tooltip"
                                                                                                        data-bs-placement="top"
                                                                                                        data-bs-custom-class="custom-tooltip"
                                                                                                        onMouseEnter={(e) => handleMouseEnter(e, data.comments)}
                                                                                                        onMouseLeave={handleMouseLeave}
                                                                                                    >
                                                                                                        <img
                                                                                                            src="images/tooltip-icon.svg"
                                                                                                            alt="view-comments"
                                                                                                            className="ms-1"
                                                                                                        />
                                                                                                    </span>
                                                                                                    {tooltip.show && (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                position: 'fixed',
                                                                                                                backgroundColor: '#333',
                                                                                                                color: '#fff',
                                                                                                                padding: '5px',
                                                                                                                borderRadius: '3px',
                                                                                                                fontSize: '12px',
                                                                                                                visibility: tooltip.show ? 'visible' : 'hidden',
                                                                                                                zIndex: 1000,
                                                                                                                top: tooltip.y,
                                                                                                                left: tooltip.x,
                                                                                                                width: '300px',
                                                                                                                height: 'auto',
                                                                                                                whiteSpace: 'normal',
                                                                                                                wordWrap: 'break-word'
                                                                                                            }}
                                                                                                        >
                                                                                                            Comments:<br />
                                                                                                            {"     "} {tooltip.text}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                        {console.log(data, 'sourceeeeee123')}

                                                                                        <td>{data.sourceSystemType}</td>
                                                                                        <td>{data.dataVolume}</td>
                                                                                        <td>{data.descriptions}</td>
                                                                                        {!disableSourceFields &&
                                                                                            <td className="text-center">
                                                                                                {disableSourceFields === false ? (
                                                                                                    <div>
                                                                                                        <a
                                                                                                            onClick={() => {
                                                                                                                setEditClicked(true)
                                                                                                                setSourceSystemInfo({
                                                                                                                    ...sourceSystemDetails.filter(
                                                                                                                        (a, i) => i === index
                                                                                                                    )[0],
                                                                                                                    index: index,
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            {" "}
                                                                                                            <span className="cust-cursor-pointer">
                                                                                                                <img
                                                                                                                    src="images/blue-edit-icon.svg"
                                                                                                                    className="me-3"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </a>
                                                                                                        <a
                                                                                                            onClick={() => {
                                                                                                                setSourceSystemDetails([
                                                                                                                    ...sourceSystemDetails.filter(
                                                                                                                        (a, i) => i != index
                                                                                                                    ),
                                                                                                                ]);
                                                                                                            }}
                                                                                                        >
                                                                                                            <span className="cust-cursor-pointer">
                                                                                                                <img src="images/delete-icon.svg" />
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </td>
                                                                                        }
                                                                                    </tr>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    })}
                                                                    {sourceSystemDetails.length === 0 ||
                                                                        (sourceSystemInfo?.index != undefined &&
                                                                            sourceSystemDetails.length === 1) ? (
                                                                        <tr style={{ borderBottom: "none" }}>
                                                                            <td> </td>
                                                                        </tr>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="modal-body py-0 my-4 mx-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* stepper starts here */}
                                                        <div className="d-flex justify-content-center mb-3 mb-md-3 mb-lg-5">
                                                            <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                                <li className="stepper-sty me-lg-5 pe-4 d-flex align-items-center">
                                                                    <span className="connect-info-icon icon-sty" />
                                                                    <span className="stepper-text font-14 font-bold ms-2">
                                                                        <img src="images/check-mark.svg" />
                                                                        <span className="ms-2">Source System</span>
                                                                    </span>
                                                                </li>
                                                                <li className=" stepper-sty active me-lg-5 pe-4 d-flex align-items-center position-relative">
                                                                    <span className="meta-info-icon icon-sty stepper-line " />
                                                                    <span className="stepper-text font-14 font-bold ms-2">
                                                                        Connection Info
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* stepper ends here */}
                                                    </div>
                                                    {console.log(sourceSystemDetails, "sourceSystemDetailssourceSystemDetailssourceSystemDetails")}
                                                    {sourceSystemDetails.map((data, index) => {
                                                        return (
                                                            <div key={index} className="rounded-3 font-20 custom-acc-border font-bold mt-2">
                                                                <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg mb-0 p-2 rounded-3 d-flex align-items-center">
                                                                    <button
                                                                        className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#view-instruction-${data.systemName}`}
                                                                    >
                                                                        <img
                                                                            src="images/down-acc-arow.svg"
                                                                            alt="arrow"
                                                                            className="me-3 gen-accord"
                                                                        />
                                                                        <span
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#view-instruction-${data.systemName}`}
                                                                            className="cursor-pointer text-black font-18 font-medium"
                                                                            style={{ whiteSpace: 'nowrap' }}
                                                                        >
                                                                            {data.systemName}
                                                                        </span>
                                                                    </button>
                                                                    {/* PS_CD_1.129-1.131 */}
                                                                    {data.dataFormat === 'Manual' && ["6B9EA278-1AAF-4818-8025-73E84D39D2C5", "6D945D84-679E-42CE-9152-3293523A9F53", "27940D09-4AEE-401D-A5D1-C5604624B804", "2B6C0990-0653-433D-ADC5-415BF3B28757", "6F1E2B59-E1A7-4F15-9A42-88802D2268F2", "93AD6411-C531-4451-A9D4-3A03F4458287"].includes(data.sourceSystemNameId) && (
                                                                        <div className="d-flex justify-content-end col-12 mt-3">
                                                                            <button
                                                                                type="button"
                                                                                className="btn cust-secondary-btn font-16 font-medium d-flex coop"
                                                                                onClick={() => downloadScripts(data.sourceSystemNameId)}
                                                                                style={{ marginRight: '170px', paddingRight: '20px' }}
                                                                            >
                                                                                <span className="me-2">
                                                                                    <img src="images/download-icon.svg" alt="add-icon" />
                                                                                </span>
                                                                                Download Script
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    <div className="col-12 mt-3">
                                                                    </div>
                                                                </p>
                                                                <div className="row">
                                                                    <div className={`collapse ${index === 0 ? "show" : ""}`} id={`view-instruction-${data.systemName}`}>
                                                                        <div className="custom-accordian-body color-grey-bg py-2 px-3">
                                                                            <div className="row pb-4">
                                                                                {data?.fields.map((field) => {
                                                                                    return (
                                                                                        <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                                                            <label htmlFor="connection-Name" className="form-label text-black font-14 font-medium">
                                                                                                {" "}
                                                                                                {field === "connectionName" ? "ConnectionName" : field.replace(/_/g, " ")}
                                                                                                <span className="text-red">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <input type="text" disabled={disableSourceFields} className="form-control cust-input-sty font-14 font-regular"
                                                                                                value={sourceSystemDetails[index]["connectionDetails"][field === "connectionName" ? "connectionName" : field.toLocaleLowerCase().replace(/ /g, "_")]}
                                                                                                placeholder="Enter value"
                                                                                                onChange={(e) => { handleConnectionInputChange(field.toLocaleLowerCase().replace(/ /g, "_"), e.target.value, index); }} />
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {phase !== "Development" &&
                                                                    <>
                                                                        <div className="col-12 mt-3">
                                                                            {data.dataFormat === 'Automatic' && (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn cust-secondary-btn font-16 font-medium d-flex"
                                                                                    onClick={() => getSourceSystemDetails(data.sourceSystemNameId)}
                                                                                >
                                                                                    <span className="me-2">
                                                                                        <img src="images/download-icon.svg" alt="add-icon" />
                                                                                    </span>
                                                                                    Download Inventory
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-sm-12 mb-3">
                                                                                <label htmlFor="" className="form-label text-black font-14 font-medium">
                                                                                    Upload Data<span className="text-red"> *</span>
                                                                                </label>
                                                                                <span className="d-block font-12 info-message-section text-black font-medium">
                                                                                    Please ensure you included the Data Dictionary
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 col-sm-12">
                                                                                <div className="mb-5">
                                                                                    <span className="upload-btn-wrapper d-block">
                                                                                        {!data?.inventoryDDName && !data?.inventoryName && !data?.fileName ? (
                                                                                            <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center" disabled={disableSourceFields}>
                                                                                                <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                                                                <span className="font-medium font-13 d-block text-grey mb-2">
                                                                                                    Browse or drag and drop file
                                                                                                </span>
                                                                                                <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                                                                    Use this template to add info -{" "}
                                                                                                    <a onClick={() => exportToExcel()}>
                                                                                                        <span className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                                                            Download Template
                                                                                                        </span>{" "}
                                                                                                    </a>{" "}
                                                                                                </span>
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center" disabled={disableSourceFields}>
                                                                                                <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                                                                <a className="font-medium font-12 d-block">{data?.inventoryName || data?.inventoryDDName || designData?.fileName}</a>
                                                                                                <a
                                                                                                    style={{ position: "relative", zIndex: "100" }}
                                                                                                    onClick={() => {
                                                                                                        let updatedSourceSystemDetails = [...sourceSystemDetails];
                                                                                                        updatedSourceSystemDetails[index] = {
                                                                                                            ...updatedSourceSystemDetails[index],
                                                                                                            inventoryDDName: '',
                                                                                                            inventoryName: '',
                                                                                                            fileName: ''
                                                                                                        };
                                                                                                        setSourceSystemDetails(updatedSourceSystemDetails);
                                                                                                        setValidationErrors(prevErrors => {
                                                                                                            const newErrors = { ...prevErrors };
                                                                                                            delete newErrors[index];
                                                                                                            return newErrors;
                                                                                                        });
                                                                                                        setDesignData('');
                                                                                                        setFormValid(false)
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="ms-2">
                                                                                                        <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                                                                    </span>
                                                                                                </a>
                                                                                            </button>
                                                                                        )}
                                                                                        <input
                                                                                            type="file"
                                                                                            name="fileName"
                                                                                            className="cursor-pointer cust-file"
                                                                                            accept=".xlsx"
                                                                                            id="meta-dataUpload-file"
                                                                                            onChange={(e) => handleFileUpload(e, index)}
                                                                                            title={data.inventoryDDName || data?.fileName || data?.inventoryName} // Use onChange event handler to handle file selection
                                                                                            disabled={disableSourceFields} // Disable the input in edit mode
                                                                                        />
                                                                                    </span>
                                                                                    {validationErrors[index] && (
                                                                                        <div className="code-doc-error">
                                                                                            {(Array.isArray(validationErrors[index]) ? validationErrors[index] : [validationErrors[index]])
                                                                                                .filter(error => error) // Filter out any null or undefined values
                                                                                                .map((error, errorIndex) => (
                                                                                                    <span key={errorIndex} className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section">
                                                                                                        <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                                                                        <span className="ms-2">{error}</span>
                                                                                                    </span>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {showSourcePopup === true ? (
                                            <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                                <button
                                                    type="button"
                                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => {
                                                        props.close()
                                                        if (disableSourceFields === false) {
                                                            setSourceSystemDetails([]);
                                                            setSourceSystemInfo({
                                                                systemName: "",
                                                                InternalExternal: "Select",
                                                                systemType: "Select",
                                                                subjectArea: "Select",
                                                                dataFormat: "Select",
                                                                sourceSystemNameId: "Select",
                                                                sourceSystemType: "Select",
                                                                dataVolume: "Select",
                                                                descriptions: "",
                                                                newSourceSystemName: "",
                                                                comments: "",
                                                                connectionDetails: {},
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn cust-primary-btn font-14 font-medium"
                                                    disabled={sourceSystemDetails.length > 0 && editClicked === false ? false : true}
                                                    onClick={() => {
                                                        setShowSourcePopup(false);


                                                        let updatedata = [...sourceSystemDetails];

                                                        for (let data of updatedata) {
                                                            let fields = []; // Declare fields variable outside the loop

                                                            // Check conditions based on projectTypeId and dataFormat
                                                            if (disableSourceFields === false) {
                                                                if (state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.dataFormat === "Automatic") {
                                                                    // Adjust the logic based on the required conditions
                                                                    let arr = connectionIfoType3.filter(item => item.future === "All");
                                                                    arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                                    fields = arr[0]?.fields || [];

                                                                    if (state.futureStateName === "Databricks" && fields.length > 0 && !fields.includes("connectionName")) {
                                                                        fields.push("connectionName");
                                                                    }
                                                                } else if (state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && data.dataFormat === "Automatic") {
                                                                    // Adjust logic for the second condition
                                                                    let arr = connectionIfoType3.filter(item => item.future === "All");
                                                                    arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                                    fields = arr[0]?.fields || [];

                                                                    if (state.futureStateName === "Databricks" && fields.length > 0) {
                                                                        if (!fields.includes("connectionName")) {
                                                                            fields.push("connectionName");
                                                                        }
                                                                    }
                                                                } else if (state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6") {
                                                                    // Adjust logic for the third condition
                                                                    let arr = state.futureStateName === "Databricks" ? connectionIfoType1 : connectionIfoType1.filter(item => item.future === "All");
                                                                    arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                                    fields = arr[0]?.fields || [];

                                                                    if (state.futureStateName === "Databricks" && fields.length > 0) {
                                                                        if (!fields.includes("connectionName")) {
                                                                            fields.push("connectionName");
                                                                        }
                                                                    }
                                                                } else if (state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1") {
                                                                    // Adjust logic for the fourth condition
                                                                    let arr = connectionIfoType2.filter(value => value.future.toLocaleLowerCase() === state.futureStateName.toLocaleLowerCase());
                                                                    if (arr.length === 0 || arr[0].data === undefined) {
                                                                        fields = [];
                                                                    } else {
                                                                        arr = arr[0].data.filter(value => value.sourceSystem.toLocaleLowerCase().replace(/ /g, "") === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                                        if (arr.length === 0 || arr[0].fields === undefined) {
                                                                            fields = [];
                                                                        } else {
                                                                            fields = arr[0]?.fields;

                                                                            if (state.futureStateName === "Databricks" && fields.length > 0 && !fields.includes("connectionName")) {
                                                                                fields.push("connectionName");
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                for (let field of fields) {
                                                                    data["connectionDetails"][
                                                                        field.toLocaleLowerCase().replace(/ /g, "_")
                                                                    ] = "";
                                                                }
                                                                data.fields = fields;
                                                                setSourceSystemDetails(updatedata);
                                                            } else {
                                                                let updatedata = [...sourceSystemDetails];
                                                                for (let data of updatedata) {
                                                                    data.fields = Object.keys(data.connectionDetails);
                                                                }
                                                                setSourceSystemDetails(updatedata);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span>Next</span>
                                                </button>

                                            </div>
                                        ) : (
                                            // PS_CD_1.2
                                            <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                                                <div className="justify-content-start d-flex me-auto">
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                                        onClick={() => {
                                                            setShowSourcePopup(true);
                                                            if (disableSourceFields === false) {
                                                                setSourceSystemDetails((data) => [
                                                                    ...data.map((a) => {
                                                                        return {
                                                                            ...a,
                                                                            fields: [],
                                                                            connectionDetails: {},
                                                                        };
                                                                    }),
                                                                ]);
                                                            }
                                                        }}
                                                    >
                                                        Previous
                                                    </button>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => {
                                                        props.close()
                                                        setShowSourcePopup(true);
                                                        if (disableSourceFields === false) {
                                                            setSourceSystemDetails([]);
                                                            setSourceSystemInfo(prevState => ({
                                                                ...prevState,
                                                                systemName: "",
                                                                InternalExternal: "Select",
                                                                systemType: "Select",
                                                                subjectArea: "Select",
                                                                dataFormat: "Select",
                                                                sourceSystemNameId: "Select",
                                                                sourceSystemType: "Select",
                                                                dataVolume: "Select",
                                                                descriptions: "",
                                                                newSourceSystemName: "",
                                                                comments: "",
                                                                connectionDetails: {},
                                                            }));
                                                        }
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    data-bs-dismiss="modal"
                                                    disabled={
                                                        disableSourceFields === false
                                                            ? !sourceSystemDetails.every(({ connectionDetails }) =>
                                                                Object.values(connectionDetails).every(
                                                                    (value) => value.trim() !== ""
                                                                )
                                                            ) || phase !== "Development" && formValid === false || phase !== "Development" && Object.values(validationErrors).some(errors => errors && errors.length > 0)
                                                            || phase !== "Development" && !areAllFilesUploadedAndValid()
                                                            : true
                                                    }
                                                    className="btn cust-primary-btn font-14 font-medium"
                                                    onClick={() => {
                                                        handleSave();
                                                    }}
                                                >
                                                    <span>Save</span>
                                                </button>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}
export default SourceConnectionPopup;