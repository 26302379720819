import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { deployPBIPcode, generateDeployDWH } from "../../Service/Api.js";

function DeployDWHCode(props) {
    const [targetSystemDetails, setTargetSystemDetails] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const { state } = useLocation();
    if (state == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        if (props.fileUploaded !== '') {
            setTargetSystemDetails(props.fileUploaded)
            setIsDisabled(true)
        }
    }, [props.fileUploaded]);

    const validateInputs = () => {
        const errors = {};
        if (!targetSystemDetails.userName) {
            errors.userName = "Please enter a valid Username";
        }
        if (!targetSystemDetails.url) {
            errors.url = "Please enter a valid URL";
        }
        if (!targetSystemDetails.createdBy) {
            errors.createdBy = "Please enter a valid Creator Name";
        }
        if (!targetSystemDetails.accessToken) {
            errors.accessToken = "Please enter a valid Access Token";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submitDetails = async () => {
        console.log("entered submitDetails");

        if (!validateInputs()) {
            return;
        }

        try {
            document.getElementById("pageLoader").style.display = "block";
            props.closePopup();
            const body = {
                data: targetSystemDetails,
                fileName: props.filename,
                userId: state.userId,
                projectId: state.projectId,
                orgId: state.orgId,
                migrationType:props.migrationType,
                migrationPattern:state.migrationPattern,
                projectName:state.projectName,
                bronzenotebookNames:props.bronzeNotebookNames,
                silvernotebookNames:props.silverNotebookNames,
                goldnotebookNames:props.goldNotebookNames
            };
            console.log("body", body);
            const responseData = await generateDeployDWH(
                body,
                state.jwt
            );

            document.getElementById("pageLoader").style.display = "none";
            console.log("responseData", responseData);

            if (responseData.status === 200) {
                props.onChange(responseData, 200, targetSystemDetails);
                props.closePopup();
            } else {
                props.onChange(responseData, 400, targetSystemDetails);
                props.closePopup();
            }
        } catch (error) {
            console.error(error);
            props.closePopup();
        }
    };

    const handleInputChange = (field, value) => {
        setTargetSystemDetails({
            ...targetSystemDetails,
            [field]: value,
        });
    };

    console.log(targetSystemDetails, "gggggggggggggggggggggggggggggggggggggggggg");

    return (
        <div className="container-fluid">
            <div className="modal-backdrop fade show"></div>
            <div className="row">
            <div
                                className="modal-backdrop fade show"
                                style={{ display: "block" }}
                            ></div>
            <div
                                                    className="overlay"
                                                    id="pageLoader"
                                                    style={{ display: "none" }}
                                                >
                                                    <div className="position-absolute top-50 start-50  translate-middle">
                                                        <div className="d-flex align-items-center loader-bg">
                                                            <div
                                                                className="spinner-border Loader text-dark align-center"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>
                                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                <div className="custom-deploy-popup" style={{ zIndex: '100000', width: "1164px", top: "88px", left: "191px" }}>
                    <div className="modal-content px-2">
                        <div className="modal-header border-0">
                            <h5 className="modal-title d-flex align-items-center font-medium font-20" id="TarSysLabel">
                                Target System Details
                            </h5>
                            <button type="button" className="btn-close" onClick={() => props.closePopup()} />
                        </div>
                        <div className="modal-body py-0 my-4">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">
                                        User Name <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="User-Name"
                                        onChange={(e) => handleInputChange("userName", e.target.value)}
                                        value={targetSystemDetails?.userName || ""}
                                        disabled={isDisabled}
                                        placeholder="Enter username"
                                    />
                                    {validationErrors.userName && <span style={{ color: "red" }}>{validationErrors.userName}</span>}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label htmlFor="URL" className="form-label text-black font-14 font-medium">
                                        URL <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="URL"
                                        onChange={(e) => handleInputChange("url", e.target.value)}
                                        value={targetSystemDetails?.url || ""}
                                        disabled={isDisabled}
                                        placeholder="Enter URL"
                                    />
                                    {validationErrors.url && <span style={{ color: "red" }}>{validationErrors.url}</span>}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label htmlFor="Created-By" className="form-label text-black font-14 font-medium">
                                        Created By <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Created-By"
                                        onChange={(e) => handleInputChange("createdBy", e.target.value)}
                                        value={targetSystemDetails?.createdBy || ""}
                                        disabled={isDisabled}
                                        placeholder="Enter Created By"
                                    />
                                    {validationErrors.createdBy && <span style={{ color: "red" }}>{validationErrors.createdBy}</span>}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label htmlFor="Access-Token" className="form-label text-black font-14 font-medium">
                                        Access Token <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Access-Token"
                                        onChange={(e) => handleInputChange("accessToken", e.target.value)}
                                        disabled={isDisabled}
                                        value={targetSystemDetails?.accessToken || ""}
                                        placeholder="Enter Access Token"
                                    />
                                    {validationErrors.accessToken && <span style={{ color: "red" }}>{validationErrors.accessToken}</span>}
                                </div>
                                <div className="col-12 mt-3">
                                    <label htmlFor="Comments" className="form-label text-black font-14 font-medium">
                                        Comments
                                    </label>
                                    <textarea
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Comments"
                                        rows="8"
                                        onChange={(e) => handleInputChange("comments", e.target.value)}
                                        value={targetSystemDetails?.comments || ""}
                                        placeholder="Enter comments"
                                        style={{ height: '100px' }} 
                                        disabled={isDisabled}
                                    />
                                </div>
                                <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                    <button
                                        type="button"
                                        className="btn cust-secondary-btn font-14 font-medium me-3"
                                        onClick={() => {
                                            setTargetSystemDetails({});
                                            props.closePopup();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        id="save"
                                        className="btn cust-primary-btn font-14 font-medium"
                                        onClick={submitDetails}
                                        disabled={isDisabled}
                                    >
                                        <span>Save & Proceed</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeployDWHCode;