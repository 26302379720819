import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getAuditLog } from '../Service/Api'; // Import  API functions here
import { HeaderCom } from './Header';
import moment from 'moment';

export default function Activitylog(props) {
    // PC_AL_02
    // Declared the state variables
    const nav = useNavigate();
    const { state } = useLocation()
    const [orgName, setOrgName] = useState('')
    const [sortOrder, setSortOrder] = useState({ column: 'TimeStamp', ascending: false });
    const [ActionLogs, setActionLogs] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({

        orgName: 'Select',
        phase: 'Select'
    });
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const itemsPerPage = 10;
    const [visibleItems, setVisibleItems] = useState(10);
    const [dataPresent, setDataPresent] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedComment, setSelectedComment] = useState('');

    useEffect(() => {
        if (state.userId) {
            fetchAuditLogs();
        }
    }, []);
    console.log("state in auditlogs", state)
    // Pseudocode No:PC_AL_05 TO 21
    // The fetchAuditLogs function is an asynchronous function responsible for retrieving audit logs data from the server. It begins by displaying a page loader, then makes an asynchronous call to fetch the audit logs using the getAuditLog function. Once the data is retrieved, it checks for various conditions: if the status is 403, it triggers a session expiration action; if the status is not 200, it opens an error modal; otherwise, it sorts the action logs by timestamp, filters out null timestamps, updates state variables with the organization name and action logs data, sets a flag to indicate data presence, and finally hides the page loader.
    const fetchAuditLogs = async () => {
        console.log("enter the fetchAuditlogs")
        document.getElementById('pageLoader').style.display = "block"
        let body = { userId: state?.userId, adRole: state?.adRole }
        const data = await getAuditLog(body, state?.jwt);
        console.log(data, "data of audit logs")
        document.getElementById('pageLoader').style.display = "none"
        if (data.status == 403) {
            sessionExpired();
        } else if (data.status != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            console.log("enters the auditlog good else")
            console.log(data[0], "the result of data[0]")
            console.log(data.data[0], "Action logs")
            var sortedActionLogs = data.data[0].sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
            sortedActionLogs = sortedActionLogs.filter(a => a.timeStamp != null)
            console.log(data.data[0], sortedActionLogs, "fetch audit")
            setActionLogs(sortedActionLogs);
            setDataPresent(true)
            setFilteredRecords(sortedActionLogs)
            console.log("actionLogsData", ActionLogs)
            console.log("orgName", orgName)
        }
    };

    // Pseudocode No:PC_AL_29 TO 34
    // The sessionExpired function handles the scenario when a user's session expires. It clears the local storage to remove any cached user data, displays a toast message indicating the session expiration, and then redirects the user to the login page after a 3-second delay. This function ensures that users are informed about the session expiration and smoothly transitioned to the login page to authenticate and continue their session.
    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            nav('/');
        }, 3000);
    };

    // Pseudocode No:PC_AL_35 TO 44
    // The handleSort function manages sorting functionality for a table based on the specified column. It toggles between ascending and descending order for the column if it's the same as the previously sorted column. It then sorts the records based on the column values, handling special cases for the 'timeStamp' column by converting values to dates for comparison. For other columns, it compares values using locale-sensitive string comparison, ensuring proper sorting. Finally, it updates the state with the sorted records, reflecting the changes in the displayed table.
    const handleSort = (column) => {

        const isSameColumn = sortOrder.column === column;
        const newOrder = isSameColumn ? !sortOrder.ascending : true;
        setSortOrder({
            column,
            ascending: newOrder,
        });
        const sortedList = filteredRecords?.slice().sort((a, b) => {
            const valueA = typeof a[column] !== 'undefined' ? a[column] : '';
            const valueB = typeof b[column] !== 'undefined' ? b[column] : '';

            if (column === 'timeStamp') {
                const dateA = new Date(valueA);
                const dateB = new Date(valueB);
                return newOrder ? dateB - dateA : dateA - dateB;
            } else {
                if (valueA !== null && valueB !== null) {
                    const compareValue = valueA.localeCompare(valueB, undefined, { numeric: false, sensitivity: 'base' });
                    return newOrder ? compareValue : -compareValue;
                }
                else {
                    // Handle null values
                    return newOrder ? (valueB === null ? -1 : 1) : (valueA === null ? -1 : 1);
                }
            }
        });
        setFilteredRecords(sortedList);
    };


    //Pseudocode No: PC_AL_45 TO 56
    // The handleSearch function manages the search functionality for filtering action logs based on a provided search term. It first checks if the search term has a minimum length of 3 characters to proceed with filtering. If the term meets the criteria, it filters the action logs based on whether the project name or organization name contains the search term (case-insensitive). The filtered data is then updated in the component state to reflect the changes in the displayed logs. If the search term doesn't meet the minimum length requirement, it resets the filtered records to display all action logs.

    const handleSearch = (term) => {
        try{console.log("handleSearch")
        if (term.length >= 3) {
            // If the search term is empty, reset to all ActionLogs
            // Filter the ActionLogs based on projectName or orgName
            const filteredData = ActionLogs.filter((record) => {
                // Check if projectName or orgName contains the search term (case-insensitive)
                return (
                    (record.projectName && record.projectName.toLowerCase().includes(term.toLowerCase())) ||
                    (record.orgName && record.orgName.toLowerCase().includes(term.toLowerCase()))
                );
            });

            setFilteredRecords(filteredData);
        } else {
            setFilteredRecords(ActionLogs);
        }
    }
    catch(error){
        console.log("Error occuring in the handleSearch",error)
    }
    };


    // Pseudocode No:
    // The handleComments function is responsible for managing the display of comments associated with a specific action log. It takes an index parameter representing the index of the action log in the ActionLogs array. Inside the function, it attempts to retrieve the comments of the selected action log using the provided index and sets the selectedComment state with the retrieved comments. Additionally, it may handle potential errors by logging them and displaying an alert message to inform the user about the issue. This function facilitates the interaction with comments in the activity logs section of the application.
    // const handleComments = (index) => {

    const handleComments = (comments) => {
        try {
            setSelectedComment(comments);
        } catch (error) {
            console.log(error, "error in activitylogs");
            // alert("Something went wrong. Please try again.");
        }
    };

    // Pseudocode No:PC_AL_23 TO 28
    // The tableData function is responsible for generating the table rows (or table data) for the activity logs section based on the filteredRecords array. It logs the entry into the function and the filtered records for debugging purposes. Inside the function, it checks if there are filtered records available. If records are found, it maps over the filtered records array and generates table rows for each record, displaying information such as organization name, project name, phase, user name, actions, and timestamp. Additionally, it includes a link to view comments associated with each record, triggering the handleComments function when clicked. The timestamp is formatted using the moment.js library to display in a readable format.
    const tableData = () => {
        console.log("enter the table data of ActivityLogs")
        console.log(filteredRecords, "filter")

        if (filteredRecords?.length !== 0) {

            return filteredRecords?.map((val, index) => {
                if (index < visibleItems) {
                    return (
                        <tr className={index % 2 == 0 ? '' : 'table-alter-bg'}>

                            <td>
                                <span className="cursor-pointer">
                                    <img
                                        src={val.orgLogo}
                                        alt="sort-down-icon"
                                        className="activity-log-icon"
                                    />
                                </span>
                                {val.orgName}
                            </td>
                            <td>{val.projectName}</td>
                            <td className="text-center" >{val.phase}</td>
                            <td className="text-center" >{val.username}</td>
                            <td className="text-center">{val.actions}</td>

                            <td>
                                {ActionLogs[index].comments === "NA" ? (
                                    "NA"
                                ) : (
                                    <a
                                        className="link-clr"
                                        onClick={() => handleComments(ActionLogs[index].comments)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#Confrm"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        View comments
                                    </a>
                                )}
                            </td>





                            <td>{moment(val.timeStamp).format('MMM DD, YYYY hh:mm A')}</td>
                        </tr>
                    )
                }
            })
        }

    }



    useEffect(() => {
        {/* // PS_CD_1.38 */ }
        handleSearch(searchTerm);
    }, [searchTerm]);

    // PC_AL_57 TO 67
    // The handleApplyFilter function processes filter criteria to refine action logs displayed. It logs the provided criteria, then filters action logs based on organization name and phase, matching each record against selected criteria. It produces a filtered list of records meeting both conditions, updates the display accordingly, and sets a flag to indicate successful filter application.
    const handleApplyFilter = () => {
        console.log("Filter Criteria:", filterCriteria);

        const filterList = ActionLogs.filter(record => {
            console.log("Record:", record);

            const organizationMatch = filterCriteria.orgName === 'Select' || record.orgName === filterCriteria.orgName;
            console.log("Organization Match:", organizationMatch);

            const phaseMatch = filterCriteria.phase === 'Select' || record.phase === filterCriteria.phase;
            console.log("Phase Match:", phaseMatch);

            return organizationMatch && phaseMatch;
        });

        console.log("Filtered List:", filterList);

        setFilteredRecords(filterList);
        setIsFilterApplied(true);
    };

    // Pseudocode No:PC_AL_67 TO 72
    // The handleClearFilter function clears the filter criteria and resets the display to show all action logs.
    //     const handleClearFilter = () => {
    //         console.log("Clearing Filter");
    //  
    //         setFilterCriteria({ orgName: 'Select', phase: 'Select' });
    //         setFilteredRecords(ActionLogs);
    //         setIsFilterApplied(false);

    //         console.log("Filter Cleared:", ActionLogs);
    //     };

   

    const handleClearFilter = () => {
        console.log("Clearing Filter");
        
        // Clear filter criteria
        setFilterCriteria({ orgName: 'Select', phase: 'Select' });
    
        // Reset the filtered records to the original action logs
        setFilteredRecords(ActionLogs);
    
        // Indicate that no filter is applied
        setIsFilterApplied(false);
    
        // Close the filter dropdown
        setShowFilter(false);
    
        console.log("Filter Cleared:", ActionLogs);
    };
    

    // Pseudocode No:PC_AL_72 TO 76
    // The BindOrganizationName function extracts unique organization names from the ActionLogs array and maps them to HTML <option> elements within a <select> dropdown, ensuring each name appears only once. It returns an array of <option> elements with value attributes set to the organization names.
    function BindOrganizationName() {
        if (ActionLogs.length > 0) {
            // Use Set to filter out unique organization names
            const uniqueOrgNames = [...new Set(ActionLogs.map(auditLog => auditLog.orgName))];
            return uniqueOrgNames.map((orgName, index) => (
                <option key={index} value={orgName}>{orgName}</option>
            ));
        }
    }


    // Pseudocode No:PC_AL_77 TO 80
    // The bindPhase function retrieves unique phase values from the ActionLogs array using a Set, then maps them to HTML <option> elements within a <select> dropdown. Each phase value appears only once, and the function returns an array of <option> elements with value attributes set to the phase values.
    const bindPhase = () => {
        if (ActionLogs.length > 0) {
            // Use Set to filter out unique phase values
            const uniquePhases = [...new Set(ActionLogs.map(auditLog => auditLog.phase))];
            return uniquePhases.map((phase, index) => (
                <option key={index} value={phase}>{phase}</option>
            ));
        }
    };


    //  Pseudocode No:PC_AL_81 TO 84
    {/*The loadMoreItems function increases the number of visible items
 displayed in a list when the "Load More" button is clicked. It updates the visibleItems state by 
 adding the itemsPerPage value to the previous number of visible items. This allows more 
items to be displayed incrementally as the button is clicked.*/}
    const loadMoreItems = () => {
        // Increase visibleItems by itemsPerPage when LoadMore button is clicked
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
    };

    return (

        <div className="container-fluid">
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value="2" />
                {/* <button className={props.value == '1' ? "header-button-active fw-bold" : 'header-button'} onClick={() => nav('/manageOrganization', { state: state })}>Organization</button>
                < button className={props.value == '2' ? "header-button-active fw-bold ms-2" : 'header-button ms-2'} onClick={() => nav('/activityLogs', { state: state })}>Activity Logs</button>
                < button className={props.value == '3' ? "header-button-active fw-bold ms-2" : 'header-button ms-2'} onClick={() => nav('/request', { state: state })}>Manage Request</button> */}
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5 mb-5 pb-5">
                    <div className="row p-4">
                        <div className="d-xl-flex d-lg-block mb-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 mb-3 ">
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                    Activity Logs
                                </h2>
                            </div>
                            <div className="d-flex">
                                <div className="input-group position-relative search-wid me-3">
                                    <input type="search" className="form-control cust-input-sty font-14 rounded-2 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                    <span className="search-icon-sty"><a ><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                </div>
                                <div className="btn-group me-3">
                                    <button
                                        className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => setShowFilter(true)}
                                    >
                                        <img
                                            src="images/filter-icon.svg"
                                            alt="filter-icon"
                                            className="filt-icon"
                                        />
                                    </button>
                                    <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3  ${showFilter == true ? 'show' : ''}`} style={{ transform: 'translate(-353px, 42px) !important;', width: '466px', float: 'right' }}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0" >
                                                    Filter
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    // data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => setShowFilter(false)}
                                                />
                                            </div>
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-14 font-medium">
                                                            Organization
                                                        </label>
                                                        {/* <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            style={{ cursor:'pointer' }}
                                                            value={filterCriteria.orgName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, orgName: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor:'pointer' }}>Select</option>
                                                            {BindOrganizationName()}
                                                        </select> */}
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.orgName}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, orgName: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor: 'pointer' }}>Select</option>
                                                            {BindOrganizationName()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="Phase"
                                                            className="form-label text-black font-14 font-medium">
                                                            Phase
                                                        </label>
                                                       
                                                        <select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            value={filterCriteria.phase}
                                                            onChange={(e) => { setFilterCriteria({ ...filterCriteria, phase: e.target.value }) }}>
                                                            <option value="Select" style={{ cursor: 'pointer' }}>Select</option>
                                                            {bindPhase()}
                                                        </select>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="text-end mt-4 mb-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                    onClick={() => { handleClearFilter() }}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={filterCriteria.phase == 'Select' && filterCriteria.orgName == 'Select' ? true : false}
                                                    className="btn cust-primary-btn font-14 font-medium px-4"
                                                    onClick={() => { handleApplyFilter() }}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* table struc starts here */}
                    {dataPresent == true && filteredRecords?.length == 0 ? (

                        <div className="col-md-12">
                            <div className="text-center pt-5">
                                <span><img src="images/no-records.svg" alt="grid-no-rec-icon" className="no-rec-icon mt-4" /></span>
                                {searchTerm != '' || isFilterApplied == true ? (
                                    <p className="font-24 font-medium text-black mt-5">No Files found!</p>

                                ) : (

                                    <>
                                        <p className="font-24 font-medium text-black mt-5">No files created so far!</p>
                                        <p className="font-18 font-regular text-grey-v3 mt-2">Start generating your first file.</p>
                                    </>
                                )}
                            </div>
                        </div>

                    ) : (
                        <div className="table-responsive custom-activity-logs page">
                            <table className="table w-100 table-borderless rounded custom-grid">
                                <thead className="sticky-top-pos">
                                    <tr>
                                        <th onClick={() => { handleSort('orgName') }} className='font-14'>
                                            Organization
                                            {sortOrder.column == 'orgName' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                        <th onClick={() => { handleSort('projectName') }}>
                                            Project
                                            {sortOrder.column == 'projectName' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />
                                                    )}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>

                                        <th className="text-center font-14 " >Phase</th>
                                        <th className="text-center">Username</th>

                                        <th className="text-center">Action</th>


                                        <th className='font-14'>
                                            Comments

                                        </th>
                                        <th onClick={() => { handleSort('timeStamp') }} className='font-14'>
                                            Time Stamp
                                            {sortOrder.column == 'timeStamp' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {tableData()}
                                </tbody>
                            </table>
                            {filteredRecords?.length != 0 && visibleItems <= filteredRecords?.length ? <p className="font-regular font-14 text-grey">
                                # of Records:
                                <span className="text-black font-medium">{visibleItems} out of {filteredRecords?.length}</span>
                            </p> : filteredRecords?.length != 0 && visibleItems >= filteredRecords?.length ? <p className="font-regular font-14 text-grey">
                                # of Records:
                                <span className="text-black font-medium">{visibleItems - (visibleItems - filteredRecords?.length)} out of {filteredRecords?.length}</span>
                            </p> : <></>}
                        </div>
                    )


                    }
                    {/* table struc ends here */}

                    {visibleItems <= filteredRecords?.length ? < div className="d-flex justify-content-center mt-3">
                        <button
                            type="button"
                            className="btn cust-secondary-btn font-14 font-medium px-3 text-nowrap" onClick={() => loadMoreItems()}
                        >
                            Load More
                        </button>
                    </div> : <></>}
                </div>
            </div>
            <div>
                <div className="modal fade" id="Confrm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Reason</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <p className="font-16">{selectedComment}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 d-flex justify-content-center">
                                <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close" >
                                    <span>ok</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    );
}