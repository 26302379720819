/**
 * Pseudocode No: 
 * Import statements
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api.js'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { HeaderCom } from "./Header.jsx";

function LoggerGridPseudocode(props) {

    /**
    * Pseudocode No:
    * State variable declaration
    */
    const [setVal, setSetVal] = useState([]);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        CellName: "",
        DesignID: "",
        ExternalReviewComments: "",
        Flow: "",
        InternalReviewComments: "",
        NotebookName: "",
        OperationDescription: "",
        OperationsType: "",
        RequirementID: "",
        Steps: ""
    })
    const [action, setAction] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' })
    const nav = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        fetchPseudo()
    }, [])

    /**
    * Pseudocode No:
    * The function fetchPseudo() is designed to handle fetching data asynchronously. When it's called, it first displays a 
    * loading spinner on the webpage to indicate that something is happening. Then, it prepares some data and sends it to 
    * another function called fetchPseudocode() located in a file named API.js. This function waits for a response from 
    * fetchPseudocode(). When it receives a response, it checks the status code: if it's 403, indicating a session expiration, 
    * it triggers a function called sessionExpired(). Otherwise, if the status code isn't 200, it opens an error modal on the 
    * webpage. But if the status code is 200, it processes the received data by parsing it and setting some values accordingly.
    */
    async function fetchPseudo() {
         
        document.getElementById('pageLoader').style.display = 'block';
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "loggerPseudocode",
            userId: state.userId,
            orgId: state.orgId,
        }
 
        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(JSON.parse(res.data[0].loggerPseudocode), "fetchRes logggeerrrrr");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            // let pseudo= JSON.parse(res.data[0].loggerPseudocode)
            // pseudo = pseudo.map((obj, index) => ({
            //     ...obj,
            //     index: index
            // }));
            let values=JSON.parse(res.data[0].loggerPseudocode)
            values = values.map((a, i) => { return { ...a, Steps: `${i + 1}` } })

            setSetVal(values);
            setFilteredRecord(values)
        }
    };

    /**
     * Pseudocode No:
     * The sessionExpired function serves to handle actions upon the expiration of a user's session. 
     * When invoked, it promptly clears the local storage, ensuring any user-related data is removed 
     * from the browser. Subsequently, it triggers the display of a toast message on the webpage, 
     * signaling to the user that their session has expired. This visual cue fades out after three 
     * seconds, discreetly vanishing from view. Additionally, there's a commented-out line of code 
     * intended to navigate the user to the login page, although it's currently inactive.
     */
    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            nav('/');
        }, 3000);
    };

    /**
    * Pseudocode No:
    * The bindGrid function is responsible for rendering the dataset onto the webpage. It first logs the filtered records to the console for debugging purposes. 
    * Then, it checks if the filteredRecord array is not empty and is indeed an array.
    * If there are records to display, it iterates over each record using the map function. For each record, it generates a table row (<tr>) with input fields for editing 
    * if the row is editable, or displays the record's data in table cells (<td>) if it's not editable. The input fields allow users to modify the data, and there are options 
    * to save or cancel the edits.
    * If there are no records to display, it renders a single table row with a message indicating that no records were found.
    * Any errors that occur during this process are caught, logged to the console, and a null value is returned to indicate a failure in rendering the grid.
    */
    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookName' value={newData?.NotebookName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.CellName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Steps' value={newData?.Steps} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Flow' value={newData?.Flow} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationsType' value={newData?.OperationsType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationDescription' value={newData?.OperationDescription} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='InternalReviewComments' value={newData?.InternalReviewComments} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ExternalReviewComments' value={newData?.ExternalReviewComments} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td class="text-center">
                                    {/* <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span> */}
                                          <span class="cust-cursor-pointer" onClick={() => {
                                        console.log("newData values:", Object.values(newData));
                                        console.log("newdattttttttttttttaaaaaaaaaaaaaa",newData) // Debug: Log values
                                        if (Object.values(newData).every(value => value !== null && value !== "" && value !== '')) {
                                            setAction("edit");
                                            document.getElementById('openReasson').click();
                                        } else {
                                            alert("Some fields are empty or contain only whitespace."); // Debug: Log issue
                                        }
                                    }}>
                                        <img src="images/right.svg" class="me-3" width="15" height="15" />
                                    </span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            CellName: "",
                                            DesignID: "",
                                            ExternalReviewComments: "",
                                            Flow: "",
                                            InternalReviewComments: "",
                                            NotebookName: "Logger",
                                            OperationDescription: "",
                                            OperationsType: "",
                                            RequirementID: "",
                                            Steps: ""
                                        })
                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : 
                            <tr key={index}>

                                <td>{item.NotebookName || "NA"}</td>
                                <td>{item.RequirementID || "NA"}</td>
                                <td>{item?.DesignID || ""}</td>
                                <td>{item.CellName || "NA"}</td>
                                <td>{item?.Steps || "NA"}</td>
                                <td>{item.Flow || "NA"}</td>
                                <td>{item.OperationsType || "NA"}</td>
                                <td>{item.OperationDescription || "NA"}</td>
                                <td>{item.InternalReviewComments || "NA"}</td>
                                <td>{item.ExternalReviewComments || "NA"}</td>
                                <td className="text-center">
                                    
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData(filteredRecord[index]) }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(item.index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);

    /**
     * Pseudocode No:
     * The handleSearch function is designed to facilitate the search feature within a dataset. Upon invocation, 
     * it inspects the length of the provided search term. If the term contains at least three characters, the 
     * function proceeds to filter the dataset based on whether the operation type or cell name includes the 
     * search term, irrespective of case. The filtered data is then updated accordingly. Conversely, if the 
     * search term is less than three characters or empty, the function simply resets the filtered records to match the original dataset.
     */
    const handleSearch = (term) => {
         
        if (searchTerm.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.OperationsType.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.CellName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };

    /**
     * Pseudocode No:
     * The handleDownload function prepares and initiates the download of dataset information in Excel format. 
     * It first extracts specific attributes from the dataset, constructs an Excel sheet, and converts it into 
     * an Excel buffer. This buffer is then saved as an Excel file using FileSaver.js, enabling the user to 
     * download the file directly.
     */
    const handleDownload = () => {
        let LoggerArray = setVal.map(data => { return { NotebookName: data.NotebookName, RequirementID: data.RequirementID, DesignID: data.DesignID, CellName: data.CellName, Steps: data.Steps, Flow: data.Flow, OperationsType: data.OperationsType, OperationDescription: data.OperationDescription, InternalReviewComments: data.InternalReviewComments, ExternalReviewComments: data.ExternalReviewComments } });
        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert LoggerArray to sheet
        const ws = XLSX.utils.json_to_sheet(LoggerArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'logger');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Logger' + fileExtension);

        // The saved file will be shown to the user
    };

    /**
     * Pseudocode No:
     * The handleSort function facilitates sorting functionality for table columns. When triggered with a column name parameter, 
     * it first checks if the clicked column is the same as the previously sorted one. If so, it toggles the sorting order;
     * otherwise, it sets the order to ascending.
     * Next, it updates the sorting order state (sortOrder) with the new column and order. Then, it creates a copy of 
     * the filtered records and sorts them based on the selected column and order using the localeCompare method for
     * string comparison. Finally, it updates the state of filtered records with the sorted list.
     */
    // const handleSort = (columnName) => {
    //     // When user clicks the sort icon
    //     // The function handleSort() is triggered along with the columnName as its parameter

    //     // Determine if the column being sorted is the same as the previously sorted column
    //     const isSameColumn = columnName === sortOrder.column;

    //     let newSortOrder;
    //     if (isSameColumn) {
    //         // If it's the same column, toggle the sorting order
    //         newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
    //     } else {
    //         // Otherwise, set the sorting order to ascending
    //         newSortOrder = { column: columnName, order: 'asc' };
    //     }

    //     // Update the sortOrder state with the new column and sorting order
    //     setSortOrder(newSortOrder);

    //     // Create a copy of the filteredRecords
    //     let sortedRecords = filteredRecord.sort((a, b) => {
    //         if (newSortOrder.order === 'asc') {
    //             return a[columnName].localeCompare(b[columnName]);
    //         } else {
    //             return b[columnName].localeCompare(a[columnName]);
    //         }
    //     });

    //     // Sort the copied records based on the selected column and order

    //     // Update the filteredRecords state with the sorted list
    //     setFilteredRecord(sortedRecords);
    // };
    
    const handleSort = (columnName) => {
        const isSameColumn = columnName === sortOrder.column;
        
        let newSortOrder = {
            column: columnName,
            order: isSameColumn && sortOrder.order === 'asc' ? 'desc' : 'asc'
        };
    
        setSortOrder(newSortOrder);
    
        let sortedRecords = [...filteredRecord].sort((a, b) => {
            let aValue = a[columnName];
            let bValue = b[columnName];
    
            if (columnName === 'RequirementID') {
                // Split the string by '.' and convert each part to a number
                let aParts = aValue.split('.').map(Number);
                let bParts = bValue.split('.').map(Number);
    
                // Compare each part
                for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                    if (aParts[i] === undefined) return newSortOrder.order === 'asc' ? -1 : 1;
                    if (bParts[i] === undefined) return newSortOrder.order === 'asc' ? 1 : -1;
                    if (aParts[i] !== bParts[i]) {
                        return newSortOrder.order === 'asc' 
                            ? aParts[i] - bParts[i]
                            : bParts[i] - aParts[i];
                    }
                }
                return 0;
            }else if (columnName === 'Steps') {
                // Split the string by '.' and convert each part to a number
                let aParts = aValue.split('.').map(Number);
                let bParts = bValue.split('.').map(Number);
    
                // Compare each part
                for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                    if (aParts[i] === undefined) return newSortOrder.order === 'asc' ? -1 : 1;
                    if (bParts[i] === undefined) return newSortOrder.order === 'asc' ? 1 : -1;
                    if (aParts[i] !== bParts[i]) {
                        return newSortOrder.order === 'asc' 
                            ? aParts[i] - bParts[i]
                            : bParts[i] - aParts[i];
                    }
                }
                return 0;
            }
            
            
            
            else if (typeof aValue === 'string' && typeof bValue === 'string') {
                return newSortOrder.order === 'asc' 
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            } else {
                // Assume numeric for non-string values
                aValue = Number(aValue);
                bValue = Number(bValue);
                return newSortOrder.order === 'asc' 
                    ? aValue - bValue
                    : bValue - aValue;
            }
        });
    
        setFilteredRecord(sortedRecords);
    };



    /**
     * Pseudocode No:
     * The handleDelete function is responsible for deleting a record from the dataset. Upon invocation, it first displays a loading 
     * spinner to indicate ongoing processing. It then constructs a request body containing necessary data for the deletion operation, 
     * such as project ID, user ID, and action type.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token 
     * for authentication. Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), 
     * it triggers a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. 
     * Otherwise, upon successful deletion, it resets the data for the deleted record, clears the reason for deletion, and updates the dataset with the new data.
     */
    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "loggerPseudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "delete",
            "comments": reason,
            "index": deleteRow
        }

        const res = await updatePseudocodeData(body, state.jwt);
let response=JSON.parse(res.data.recordset[0].loggerPseudocode);
        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            response = response.map((a, i) => { return { ...a, Steps: `${i + 1}` } })

            setSetVal(response);
            setFilteredRecord(response)
            if (searchTerm.length >= 3) {
                // If searchTerm is greater than 0
                const filteredData = response.filter(item =>
                    item.OperationsType?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item.CellName?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
                setFilteredRecord(filteredData);
            } else {
                setFilteredRecord(response);
            }
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                NotebookName: "",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
        }
    };

    /**
     * Pseudocode No:
     * The handleEdit function manages the editing process for a record in the dataset. Upon invocation, it displays a loading spinner to indicate 
     * ongoing processing. It then constructs a request body containing necessary data for the edit operation, such as project ID, user ID, action type, 
     * comments, updated data (newData), and the index of the editable row.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. 
     * Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers 
     * a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon 
     * successful edit, it resets the data for the edited record, clears the reason for edit, and updates the dataset with the new data.
     */
    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "loggerPseudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": newData,
            "index": newData.index
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            let response=JSON.parse(res.data.recordset[0].loggerPseudocode);

            response = response.map((obj, index) => ({
                ...obj,
                index: index
            }));
            response = response.map((a, i) => { return { ...a, Steps: `${i + 1}` } })

            setSetVal(response);
            setFilteredRecord(response)
            setSetVal(response);
            if (searchTerm.length >= 3) {
                // If searchTerm is greater than 0
                const filteredData = response.filter(item =>
                    item.OperationsType?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item.CellName?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
                setFilteredRecord(filteredData);
            } else {
                setFilteredRecord(response);
            }
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                NotebookName: "",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
        }
    };

    /**
     * Pseudocode No:
     * The handleAdd function is responsible for adding a new record to the dataset. Upon invocation, it displays a loading spinner to indicate ongoing processing. 
     * It then constructs a request body containing necessary data for the addition operation, such as project ID, user ID, action type, comments, and the new data (newData).
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. Upon receiving a response, 
     * it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers a session expiration function. 
     * If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon successful addition, it resets the data for the new record, 
     * clears the reason for addition, and updates the dataset with the new data.
     */
    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "loggerPseudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "add",
            "comments": reason,
            "value": newData
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            let response=JSON.parse(res.data.recordset[0].loggerPseudocode);

            response = response.map((obj, index) => ({
                ...obj,
                index: index
            }));
            response = response.map((a, i) => { return { ...a, Steps: `${i + 1}` } })

            setSetVal(response);
            setFilteredRecord(response)
            setSetVal(response);
            if (searchTerm.length >= 3) {
                // If searchTerm is greater than 0
                const filteredData = response.filter(item =>
                    item.OperationsType?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item.CellName?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
                setFilteredRecord(filteredData);
            } else {
                setFilteredRecord(response);
            }
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                NotebookName: "",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
         setwhetherUpdated(true)
        }
    };


    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
                <HeaderCom value='1' />
                {/* <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
                    <div className="d-flex  align-items-center py-1">
                        <a className="navbar-brand" ><img src="images/codegen-logo.svg" alt="codegen-logo" className="logo-sty" /></a>
                        <div className="ms-5">
                            <button className="header-button-active fw-bold">Organization</button>
                            <button className="header-button ms-2">Activity Logs</button>
                        </div>
                        <div className="dropdown me-lg-3 ms-auto">
                            <img src="images/user-profile.svg" alt="user-profile" className="profile-sty cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                                <li><a className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" ><span className="me-2"><img src="images/logout.svg" alt="profile-icon" /></span>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">Logger Notebook</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">Logger Notebook</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>  
                                                <th className="text-nowrap">NotebookName<span className="ms-2 cursor-pointer"></span></th>
                                                <th className="text-nowrap">RequirementID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "RequirementID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('RequirementID')} /></span></th>
                                                <th className="text-nowrap">DesignID<span className="ms-2 cursor-pointer"></span></th>
                                                <th className="text-nowrap">CellName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "CellName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('CellName')} /></span></th>
                                                <th className="text-nowrap">Steps<span className="ms-2 cursor-pointer"><img style={{marginRight:"15px"}} src={sortOrder.column == "Steps" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Steps')} /></span></th>
                                                <th className="text-nowrap">Flow<span className="ms-2 cursor-pointer"></span></th>
                                                <th className="text-nowrap">OperationsType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "OperationsType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('OperationsType')} /></span></th>
                                                <th className="text-nowrap">OperationDescription</th>
                                                <th className="text-nowrap">InternalReviewComments</th>
                                                <th className="text-nowrap">ExternalReviewComments</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editableRow == "empty" ? <tr>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='NotebookName' value={newData?.NotebookName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.CellName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Steps' value={newData?.Steps} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Flow' value={newData?.Flow} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationsType' value={newData?.OperationsType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationDescription' value={newData?.OperationDescription} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='InternalReviewComments' value={newData?.InternalReviewComments} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ExternalReviewComments' value={newData?.ExternalReviewComments} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td className="text-center">
                                                    <img
                                                        src="images/add-icons.svg"
                                                        alt="add-icon"
                                                        className={`cust-cursor-pointer ${Object.values(newData).every(value => value !== '') ? '' : 'disabled'}`}
                                                        title="Add"
                                                        onClick={(e) => {
                                                            if (Object.values(newData).every(value => value !== '')) {
                                                                setAction("add");
                                                                document.getElementById('openReasson').click();
                                                            } else {
                                                                e.currentTarget.style.opacity = '0.3';
                                                                e.currentTarget.style.cursor = 'not-allowed';
                                                            }
                                                        }}
                                                        style={{
                                                            opacity: Object.values(newData).every(value => value !== '') ? 1 : 0.3,
                                                            cursor: Object.values(newData).every(value => value !== '') ? 'pointer' : 'not-allowed'
                                                        }}
                                                    />
                                                </td>
                                            </tr> : <></>}
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey">
                                    # of Records:
                                    <span className="text-black font-medium">{filteredRecord.length} out of {setVal.length}</span>
                                </p>                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" onClick={() => setReason("")} />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal" onClick={() => setReason("")}>
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoggerGridPseudocode;