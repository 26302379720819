// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-style
{
    width: 150px;
    height: 50px;
}
.bg-gradient-height
{
    min-height: 100vh;
}

.login-img-style
{
    height: 500px;
}
.pos-login-img
{
    position: absolute;
    right: 0px;
}

/* @media screen and (min-width:1290px)
 {
    .pos-login-img
{
    left: 0px;
}
}
@media screen and (max-width:1289px)
 {
    .pos-login-img
{
    left: 30px;
}
} */`, "",{"version":3,"sources":["webpack://./src/Styles/css/login.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,YAAY;AAChB;AACA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,aAAa;AACjB;AACA;;IAEI,kBAAkB;IAClB,UAAU;AACd;;AAEA;;;;;;;;;;;;;GAaG","sourcesContent":[".logo-style\n{\n    width: 150px;\n    height: 50px;\n}\n.bg-gradient-height\n{\n    min-height: 100vh;\n}\n\n.login-img-style\n{\n    height: 500px;\n}\n.pos-login-img\n{\n    position: absolute;\n    right: 0px;\n}\n\n/* @media screen and (min-width:1290px)\n {\n    .pos-login-img\n{\n    left: 0px;\n}\n}\n@media screen and (max-width:1289px)\n {\n    .pos-login-img\n{\n    left: 30px;\n}\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
