// PS_CD_1.1
import React, { useState, useEffect, useRef } from "react";
// PS_CD_1.2
import { useLocation, useNavigate } from "react-router-dom";
import { getMetaDataValues, updateMetaValues, fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError } from "../Service/Api.js";
import { HeaderCom } from "./Header.jsx";
import * as XLSX from 'xlsx';

// PS_CD_1.8
function TableMappingRework(props) {
    // PS_CD_1.3
    const location = useLocation();
    // PS_CD_1.4 
    const nav = useNavigate();
    // PS_CD_1.9
    let { state } = useLocation();

    if (state == null) {
        window.location.href = '/'
    }




    const [jsonData, setJsonData] = useState([]);
    // PS_CD_1.6
    const [selectedItem, setSelectedItem] = useState({});
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)
    //PS_CD_1.62
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    // PS_CD_1.7


    const [whetherUpdate, setWhetherUpdate] = useState(false);
    // const verifiedCount = response.filter(data => data?.VerificationStatus === "Verified").length;
    // const notVerifiedCount = response.filter(data => data?.VerificationStatus === "Not Verified").length;
    // const isVerified = verifiedCount === response.length ? true : false;
    const [sortOrder, setSortOrder] = useState({ ascending: false, column: 'columnname' })

    const [isAllVerified, setIsAllVerified] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState("");
    const [filterDestinationTableName, setFilterDestinationTableName] = useState([]);
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);
    const [inputValues, setInputValues] = useState({
        TableType: 'Select',
        SubjectArea: 'Select',
        DestinationSchemaName: 'Select',
        DestinationTableName: 'Select',
        SourceSystem: '',
        SourceSchema: '',
        SourceTableName: '',
        IntermediateOperations: '',
        VerificationStatus: 'Select',

    });

    const [verificationStatusDropDown, setVerificationStatusDropDown] = useState(["Verified", "Not Verified"]);

    const [destinationTableNameDropDown, setDestinationTableNameDropDown] = useState([])
    const [destinationSchemaNameDropDown, setDestinationSchemaNameDropDown] = useState([])
    const [subjectAreaDropDown, setSubjectAreaDropDown] = useState([])
    const [tableType, setTableType] = useState(["Dim", "Fact"]);
    const [body, setBody] = useState({})
    const [filterCriteria, setFilterCriteria] = useState({
        SubjectArea: 'Select',
        DestinationTableName: 'Select',

    });

    const [IsFilteredApplied, setIsFilteredApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    const [constraints, setConstraints] = useState(["NULL", "NOTNULL", "Primary Key", "Foreign Key", "Unique"]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                 
                const dropDownValuesPromise = fetchDropDown();
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [dropDownValues, pseudoData] = await Promise.all([dropDownValuesPromise, pseudoDataPromise]);
                console.log("res from fetchDropDownValues", dropDownValues);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);



    const fetchDropDown = async () => {
        try {
             
            let data = { type: "table mapping", projectId: state.projectId }
            let dropDownValues = await fetchDropDownValues(data, state.jwt)
            console.log("resfrom fetchDropDownValues", dropDownValues);
            if (dropDownValues.data?.subjectArea) {
                setSubjectAreaDropDown(dropDownValues.data.subjectArea);
            } else {
                console.log("No subject areas found");
            }

            if (dropDownValues.data?.destinatinSchemaName) {
                setDestinationSchemaNameDropDown(dropDownValues.data.destinatinSchemaName);
            } else {
                console.log("No schema names found");
            }
            if (dropDownValues.data?.destinationTableName) {
                setDestinationTableNameDropDown(dropDownValues.data.destinationTableName);
            } else {
                console.log("No schema names found");
            }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Tablemapping",
                functionName: "fetchdropdown",
                userId: state.userId
            });
        }
    }

    const fetchPseudo = async () => {
        try {
            let data = { type: "tableMapping", projectId: state.projectId, userId: state.userId }
            document.getElementById('pageLoader').style.display = 'block';
            let pseudodata = await fetchPseudocodeData(data, state.jwt)
            document.getElementById('pageLoader').style.display = 'none';
            console.log("res from fetchPseudocodeData", pseudodata);

            if (pseudodata.statusCode == 200) {
                // Parse the JSON data received
                let pseudo = JSON.parse(pseudodata.data[0]?.tableMapping || '[]');
                console.log(pseudo, "pseudo");

                pseudo = pseudo.map((obj, index) => ({
                    ...obj,
                    index: index
                }));

                const verifiedCount = pseudo.filter(data => data?.VerificationStatus === "Verified").length;
                const notVerifiedCount = pseudo.filter(data => data?.VerificationStatus === "Not Verified").length;
                const isVerified = verifiedCount === pseudo.length ? true : false;

                setJsonData(pseudo)
                setResponse(pseudo)
                setIsAllVerified(isVerified);


                let uniqueDestinationTableNames = [...new Set(pseudo.map(item => item.DestinationTableName))];
                let uniqueSubjectAreas = [...new Set(pseudo.map(item => item.SubjectArea))];
                setFilterSubjectArea(uniqueSubjectAreas)
                setFilterDestinationTableName(uniqueDestinationTableNames)

                console.log("uniqueTableNames", uniqueDestinationTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);
            }
        } catch (e) {
            // Log the error to console for diagnosis
            console.log("Error:", e);
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Tablemapping",
                functionName: "fetchpseudo",
                userId: state.userId
            });
        }
    }


    const filterByAppliedFilters = () => {
        console.log("filtercriteria", filterCriteria);
        const { SubjectArea, DestinationTableName } = filterCriteria;

        let SubjectAreaMatch;
        let DestinationTableNameMatch;

        const filteredList = response.filter((record) => {
            SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
            DestinationTableNameMatch = DestinationTableName === 'Select' || record.DestinationTableName.toLowerCase() === DestinationTableName.toLowerCase();

            return SubjectAreaMatch && DestinationTableNameMatch;
        });

        return filteredList;
    };


    const handleSearch = (term) => {
         
        if (searchTerm == null || searchTerm.length < 3) {
            // If the search term is empty, reset to all 
            let records = filterByAppliedFilters()

            setJsonData(records);
        } else {
            // Filter the based  based on search criteria  SubjectArea

            const filteredData = jsonData.filter(
                (record) =>
                    (record?.Tabletype && record?.Tabletype.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.DestinationSchemaName && record?.DestinationSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.Sourcesystem && record?.Sourcesystem.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.SourceSchema && record?.SourceSchema.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.Verificationstatus && record?.Verificationstatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (record?.LastmodifiedBy && record?.LastmodifiedBy.toLowerCase().includes(searchTerm.toLowerCase()))
            )

            setJsonData(filteredData);
        }
    };
    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);


    const updatePseudocode = async (value) => {
        try {
            setWhetherUpdate(true)
             
            console.log("succes in updatePseudocode----", apiBody);
            document.getElementById('pageLoader').style.display = 'block';
            let data = await updatePseudocodeData({ ...apiBody, userId: state.userId }, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            console.log("backend full response", data);

            if (data.statusCode == 200) {
                let pseudo = data.data
                console.log("res from fetchPseudocodeData", pseudo);
                let parseValue = JSON.parse(pseudo.recordset[0].tableMapping)
                console.log("parseValue----", parseValue);
                pseudo = parseValue.map((obj, index) => {
                    return {
                        ...obj,
                        index: index
                    }
                });
                console.log("afer addindex", pseudo, "updated return res from db--------");
                setResponse(() => pseudo);

                const verifiedCount = pseudo.filter(data => data?.VerificationStatus === "Verified").length;
                const notVerifiedCount = pseudo.filter(data => data?.VerificationStatus === "Not Verified").length;
                const isVerified = verifiedCount === pseudo.length ? true : false;

                setJsonData(pseudo)
                setResponse(pseudo)
                setIsAllVerified(isVerified);


                const { SubjectArea = '', DestinationTableName = '' } = filterCriteria;

                const filteredList = pseudo.filter((record) => {
                    // Check if SubjectArea and DestinationTableName are defined
                    const subjectAreaDefined = record.SubjectArea !== undefined && record.SubjectArea !== null;
                    const destinationTableNameDefined = record.DestinationTableName !== undefined && record.DestinationTableName !== null;

                    // If defined, perform the filtering
                    if (subjectAreaDefined && destinationTableNameDefined) {
                        const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
                        const DestinationTableNameMatch = DestinationTableName === 'Select' || record.DestinationTableName.toLowerCase() === DestinationTableName.toLowerCase();

                        return SubjectAreaMatch && DestinationTableNameMatch;
                    } else {
                        // If not defined, exclude the record from the filtered list
                        return false;
                    }
                });

                if (searchTerm == null || searchTerm.length < 3) {
                    // If the search term is empty, reset to all 
                    setJsonData(filteredList);
                } else {
                    // Filter the based  based on search criteria  SubjectArea

                    const filteredData = filteredList.filter(
                        (record) =>
                            (record?.Tabletype && record?.Tabletype.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.Subjectarea && record?.Subjectarea.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.DestinationSchemaName && record?.DestinationSchemaName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.DestinationTableName && record?.DestinationTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.Sourcesystem && record?.Sourcesystem.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.SourceSchema && record?.SourceSchema.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.SourceTableName && record?.SourceTableName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.Verificationstatus && record?.Verificationstatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            (record?.LastmodifiedBy && record?.LastmodifiedBy.toLowerCase().includes(searchTerm.toLowerCase()))
                    )

                    setJsonData(filteredData);
                }






                let uniqueDestinationTableNames = [...new Set((pseudo).map(item => item.DestinationTableName))];
                let uniqueSubjectAreas = [...new Set((pseudo).map(item => item.SubjectArea))];
                setFilterSubjectArea(uniqueSubjectAreas)
                setFilterDestinationTableName(uniqueDestinationTableNames)

                setInputValues({
                    TableType: 'Select',
                    SubjectArea: 'Select',
                    DestinationSchemaName: 'Select',
                    DestinationTableName: 'Select',
                    SourceSystem: '',
                    SourceSchema: '',
                    SourceTableName: '',
                    IntermediateOperations: '',
                    VerificationStatus: 'Select',
                    LastModifiedBy: ''

                })
            }
            // console.log("index", index, "value", value);
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Tablemapping",
                functionName: "updatepseudo",
                userId: state.userId
            });
            console.log("error in update", e);
        }
    }
    const handleTick = async (item) => {
        console.log("in tick fun", item, "reason", reason);

        setBody({
            type: "table mapping",
            actionType: "edit",
            index: item.index,
            comments: reason,
            value: { ...item, LastModifiedBy: state.userName }
        })
    }
    const handleCancel = async () => {
        setJsonData(response);
        setEditingIndex(null);
        setInputValues({
            TableType: 'Select',
            SubjectArea: 'Select',
            DestinationSchemaName: 'Select',
            DestinationTableName: 'Select',
            SourceSystem: '',
            SourceSchema: '',
            SourceTableName: '',
            IntermediateOperations: '',
            VerificationStatus: 'Select',
            LastModifiedBy: ''

        })
        setReason("")
    }
    const handleReason = async (e) => {
        setReason(e.target.value)

    }
    let apiBody
    const handleSaveDetails = async () => {

        setWhetherUpdate(true)
        setEditingIndex(null)
        console.log("body in savedetaisl----", body);
        if (!reason || !body) {
            // If reason or body is not filled, return without performing further operations
            return;
        }
        apiBody = { ...body, comments: reason, projectId: state.projectId, type: "tableMapping" }
        console.log("   inside savedetails fun", reason, "apiBody", apiBody);
        await updatePseudocode(apiBody)
        setReason("")

        setInputValues({
            TableType: 'Select',
            SubjectArea: 'Select',
            DestinationSchemaName: 'Select',
            DestinationTableName: 'Select',
            SourceSystem: '',
            SourceSchema: '',
            SourceTableName: '',
            IntermediateOperations: '',
            VerificationStatus: 'Select',
            LastModifiedBy: ''

        })
        // invoke api call 
    }



    const handleDownload = async (response) => {
         
        let responseValue = response.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(responseValue);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Table Mapping');

        // Generate Excel file and download
        XLSX.writeFile(wb, 'Table Mapping.xlsx');
    }
    const handleClearFilter = () => {

        // Reset filter criteria to their initial values
        setFilterCriteria({
            SubjectArea: 'Select',
            DestinationTableName: 'Select',

        });
        // Reset filtered projectList to all projectList
        setJsonData(response);
    };
    const handleApplyFilter = () => {
        console.log("filtercriteria", filterCriteria);
         ;
        const { SubjectArea = '', DestinationTableName = '' } = filterCriteria;

        const filteredList = response.filter((record) => {
            // Check if SubjectArea and DestinationTableName are defined
            const subjectAreaDefined = record.SubjectArea !== undefined && record.SubjectArea !== null;
            const destinationTableNameDefined = record.DestinationTableName !== undefined && record.DestinationTableName !== null;

            // If defined, perform the filtering
            if (subjectAreaDefined && destinationTableNameDefined) {
                const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
                const DestinationTableNameMatch = DestinationTableName === 'Select' || record.DestinationTableName.toLowerCase() === DestinationTableName.toLowerCase();

                return SubjectAreaMatch && DestinationTableNameMatch;
            } else {
                // If not defined, exclude the record from the filtered list
                return false;
            }
        });

        setJsonData(filteredList);
        setIsFilteredApplied(true);
        setShowFilter(false);
    };



    const handleNotVerified = async (record) => {
        console.log("in not verified funcccc", selectedItem);
        setWhetherUpdate(true)
        let record1 = { ...selectedItem, VerificationStatus: "Not Verified", LastModifiedBy: state?.userName }
        apiBody = {
            type: "tableMapping",
            actionType: "update",
            value: record1,
            index: record1.index,
            projectId: state?.projectId,
            userId: state?.userId,
            comments: "Not verify"
        }
        await updatePseudocode()
        return true
    };
    const handleVerified = async (record) => {
        setWhetherUpdate(true)
        let record1 = { ...record, VerificationStatus: "Verified", LastModifiedBy: state?.userName }
        apiBody = {
            type: "tableMapping",
            actionType: "update",
            value: record1,
            index: record1.index,
            projectId: state?.projectId,
            userId: state?.userId,
            comments: "Verify"
        }
        await updatePseudocode()


    };

    const handleSort = (columnName) => {
        const isSameColumn = sortOrder.column === columnName;
        const newOrder = isSameColumn ? !sortOrder.ascending : false;
        const sortedData = [...jsonData].sort((a, b) => {
            const aValue = a[columnName];
            const bValue = b[columnName];
    
            // Check if values are numbers
            const aValueIsNumber = !isNaN(aValue);
            const bValueIsNumber = !isNaN(bValue);
    
            // Provide default value if property doesn't exist
            const defaultStringValue = '';
    
            // If both values are numbers, sort numerically
            if (aValueIsNumber && bValueIsNumber) {
                return (isSameColumn
                    ? newOrder
                        ? aValue - bValue
                        : bValue - aValue
                    : bValue - aValue);
            } else {
                // If one or both values are not numbers, sort as strings
                return (isSameColumn
                    ? newOrder
                        ? (aValue || defaultStringValue).localeCompare(bValue || defaultStringValue)
                        : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue)
                    : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue));
            }
        });
        setSortOrder({ column: columnName, ascending: newOrder });
        setJsonData(sortedData);
    };
    
    
    // const handleSort = (columnName) => {
    //      

    //     // When user clicks the sort icon
    //     // The function handleSort() is triggered along with the columnName as its parameter

    //     // Determine if the column being sorted is the same as the previously sorted column
    //     const isSameColumn = columnName === sortOrder.column;

    //     let newSortOrder;
    //     if (isSameColumn) {
    //         // If it's the same column, toggle the sorting order
    //         newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
    //     } else {
    //         // Otherwise, set the sorting order to ascending
    //         newSortOrder = { column: columnName, order: 'desc' };
    //     }

    //     // Update the sortOrder state with the new column and sorting order
    //     setSortOrder(newSortOrder);
    //     let sortedRecords
    //     // Create a copy of the filteredRecords
    //     if (columnName != "SNO") {
    //         sortedRecords = jsonData.sort((a, b) => {
    //             if (newSortOrder.order === 'asc') {
    //                 return a[columnName]?.localeCompare(b[columnName]);
    //             } else {
    //                 return b[columnName]?.localeCompare(a[columnName]);
    //             }
    //         });
    //     }
    //     else {
    //         sortedRecords = jsonData.slice().reverse();

    //     }

    //     // Sort the copied records based on the selected column and order

    //     // Update the filteredRecords state with the sorted list
    //     setJsonData(sortedRecords);
    // };
    const bindgrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            // console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in binf func", jsonData);
            // Check if jsonData is an array and has elements
            if (jsonData.length > 0) {
                console.log("in binf func2222", jsonData);

                return (
                    <>
                        {jsonData?.map((item, index) => (

                            (editingIndex == index) ? (
                                <tr key={index}>
                                    <td></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.TableType}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {tableType.map(value => (
                                                    <li style={{fontSize:"14px"}} key={value} onClick={() => handleDropDownChange("TableType", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}

                                            >
                                                {subjectAreaDropDown.map(value => (
                                                    <li  style={{fontSize:"14px"}} key={value} onClick={() => handleDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.DestinationSchemaName}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {destinationSchemaNameDropDown.map(value => (
                                                    <li style={{fontSize:"14px"}} key={value} onClick={() => handleDropDownChange("DestinationSchemaName", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 10000 }}
                                            >
                                                {inputValues.DestinationTableName}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                                // style={{ zIndex: 10000 }}
                                                style={{ zIndex: 10000, maxHeight: '300px', overflowY: 'auto' }}
                                            >
                                                {destinationTableNameDropDown.map(value => (
                                                    <li style={{fontSize:"14px"}} key={value} onClick={() => handleDropDownChange("DestinationTableName", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td><input type="text"className="form-control" name="SourceSystem" value={inputValues?.SourceSystem} onChange={handleInputChange} /></td>
                                    <td><input type="text"className="form-control" name="SourceSchema" value={inputValues?.SourceSchema} onChange={handleInputChange} /></td>
                                    <td><input type="text"className="form-control" name="SourceTableName" value={inputValues?.SourceTableName} onChange={handleInputChange} /></td>
                                    <td><input type="text"className="form-control" name="IntermediateOperations" value={inputValues?.IntermediateOperations} onChange={handleInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.VerificationStatus}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {verificationStatusDropDown.map(value => (
                                                    <li style={{fontSize:"14px"}} key={value} onClick={() => handleDropDownChange("VerificationStatus", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td><input type="text" className="form-control"name="LastModifiedBy" value={inputValues?.LastModifiedBy} disabled={true} /></td>

                                    <td style={{ textAlign: "center" }}>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 `}
                                            onClick={() => handleTick(inputValues)} // Only call handleAdd if form is filled
                                            {...(isFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}
                                            disabled={isFormFilled()}

                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={() => handleCancel()}
                                        />
                                    </td>
                                </tr>
                            ) : (

                                <tr key={index}>
                                    <td>{item?.index + 1}</td>

                                    <td>{item?.TableType ||"NA" }</td>
                                    <td>{item?.SubjectArea ||"NA"}</td>
                                    <td>{item?.DestinationSchemaName ||"NA" }</td>
                                    <td>{item?.DestinationTableName ||"NA" }</td>
                                    <td>{item?.SourceSystem  ||"NA"}</td>
                                    <td>{item?.SourceSchema||"NA" }</td>
                                    <td>{item?.SourceTableName||"NA" }</td>
                                    <td>{item?.IntermediateOperations ||"NA"}</td>
                                    <td>{item?.VerificationStatus||"NA" }</td>
                                    <td>{item?.LastModifiedBy ||"NA" }</td>

                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="Delete"
                                            data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        // disabled={!reason}
                                        />
                                        {state.role == "Project Lead" ? <>
                                            {(item.VerificationStatus == "Verified") ? (<img src="images/wrong.svg" alt="wrong-icon" className="cust-cursor-pointer me-3" title="Wrong" data-bs-toggle="modal" data-bs-target="#Confrm" onClick={() => setSelectedItem(item)} />) :
                                                <img src="images/tick-icon.svg" alt="tick-icon" className="cust-cursor-pointer me-3" title="Tick" onClick={() => handleVerified(item)} />}
                                        </> : <></>
                                        }
                                    </td>
                                </tr>
                            )))}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindgrid:", error);
            return null;
        }
    };


    /** // PS_CD_1.13-1.16
     * The function `handleEditClick` sets the selected item and updates the `isEditMetavalue` state.
     */
    const handleEditClick = (index, item) => {
         
        console.log("edit item", item);
        setEditingIndex(index)
        // setSelectedItem(item);
        setInputValues({ ...item }); // Update inputValues with the values from the clicked item
        setIsEditingData(true)
        // setisEditMetavalue(false);
    };
    const handleDelete = (index, item) => {
        console.log("delete itmemmmm item", item);
        setBody({
            type: "tableMapping",
            actionType: "delete",
            index: item.index,
            comments: reason
        })

    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDropDownChange = (key, value) => {
        setInputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }
    const isFormFilled = () => {
         
        console.log("input vaaluess in forfilled", inputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(inputValues); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        console.log(hasDefaultValues, "hasDefaultValues");
        return !hasDefaultValues; // Return true if no default values are present
    };

    const handleAdd = (value) => {
         

        console.log("input vaaluess in handleAdd", inputValues);
        console.log("add itmemmmm item", value);
        setInputValues({ ...inputValues, LastModifiedBy: state.userName })
        setBody({
            type: "tableMapping",
            actionType: "add",
            comments: reason,
            value: { ...inputValues, LastModifiedBy: state.userName }
        })
    };


    const handlecancelSearch = (value) => {
        setSearchTerm('');
        handleApplyFilter()
        // setJsonData(response.filter(filterByAppliedFilters()))

    };





    return (
        <div className="container-fluid px-md-4">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">

                <HeaderCom value="1" />
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Table Mapping</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }}   onClick={() => { props.setShowCode(); props.isEdit(whetherUpdate); props.isTotalVerified(isAllVerified) }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                Table Mapping
                            </h2>
                        </div>
                        <div className="d-flex">
                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 py-2 me-3 d-flex align-items-center">
                                <span className="border-end d-flex allign-items-center pe-3">
                                    <img
                                        src="images/verified-icon.svg"
                                        alt="verified-icon"
                                        className="verified-logo-sty me-2"
                                    />
                                    <span className=" text-nowrap font-regular">
                                        Verified - <span className="font-medium">{response.filter(data => data?.VerificationStatus == "Verified").length}</span>{" "}
                                    </span>
                                </span>
                                <span className="border-end d-flex allign-items-center px-3">
                                    <img
                                        src="images/not-verified-icon.svg"
                                        alt="not-verified-icon"
                                        className="verified-logo-sty me-2"
                                    />
                                    <span className=" text-nowrap font-regular">
                                        Not verified - <span className="font-medium">{response.filter(data => data?.VerificationStatus == "Not Verified").length}</span>{" "}
                                    </span>
                                </span>
                                <span className="ps-3">
                                    <span className=" text-nowrap font-regular">
                                        Total - <span className="font-medium">{response.length}</span>{" "}
                                    </span>
                                </span>
                            </div>
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    value={searchTerm}
                                    placeholder="Search" onChange={(e) => { setSearchTerm(e.target.value); handleSearch(searchTerm) }}
                                />
                                <span className="search-icon-sty">
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon" onClick={() => handlecancelSearch()} />
                                    </a>
                                </span>
                            </div>
                            <div className="btn-group me-3">
                                <button
                                    className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon"
                                    />
                                </button>
                                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="Destination Table Name"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Destination Table Name
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="DestinationTableName"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.DestinationTableName}

                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, DestinationTableName: e.target.value })}
                                                    >
                                                        <option selected="Select">Select</option>
                                                        {filterDestinationTableName.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SubjectArea"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Subject Area
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="subjectArea"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.SubjectArea}
                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                    >
                                                        <option selected="Select">Select</option>
                                                        {filterSubjectArea.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={() => handleClearFilter()}

                                            >
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={() => handleApplyFilter()}

                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium "
                                data-bs-dismiss="modal" onClick={() => handleDownload(response)}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#view-instruction"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#view-instruction"
                                        className="cursor-pointer pt-1 text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If any changes are required update them accordingly using{" "}
                                                <span className="text-black-v2 font-bold">‘Actions’</span>{" "}
                                                on the last column
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’ </span>then
                                                on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="code-review-tab-width">

                                {/* <div id="tab1" className="code-review-tab tab-content active"> */}
                                <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                    {/*Metadata Table starts here*/}
                                    <div className="table-responsive rounded-3">
                                        <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                            <thead className="sticky-top-pos">
                                                <tr>
                                                    <th>SNO<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SNo" && sortOrder.ascending? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SNo')} /></span> </th>
                                                    <th>TableType  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TableType" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TableType')} /></span></th>

                                                    <th>SubjectArea  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.ascending? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                    <th>DestinationSchemaName  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DestinationSchemaName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DestinationSchemaName')} /></span></th>
                                                    <th>DestinationTableName  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DestinationTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DestinationTableName')} /></span></th>
                                                    <th>SourceSystem  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSystem" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSystem')} /></span></th>
                                                    <th>SourceSchema  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSchema" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSchema')} /></span></th>
                                                    <th>SourceTableName  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTableName')} /></span></th>
                                                    <th>IntermediateOperations  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "IntermediateOperations" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('IntermediateOperations')} /></span></th>
                                                    <th>Verification Status  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "VerificationStatus" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('VerificationStatus')} /></span></th>
                                                    <th>Last Modified by <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LastModifiedBy" && sortOrder.ascending? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LastModifiedBy')} /></span></th>

                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(editingIndex != null) ? (
                                                    <></>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td><select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            name="TableType"
                                                            value={inputValues.TableType}
                                                            onChange={(e) => handleInputChange(e)}
                                                        >
                                                            <option value="">Select</option>
                                                            {tableType?.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select></td>

                                                        <td><select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            name="SubjectArea"
                                                            value={inputValues.SubjectArea}
                                                            onChange={(e) => handleInputChange(e)}
                                                        >
                                                            <option value="">Select</option>
                                                            {subjectAreaDropDown?.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select></td>

                                                        <td><select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            name="DestinationSchemaName"
                                                            value={inputValues.DestinationSchemaName}
                                                            onChange={(e) => handleInputChange(e)}
                                                        >
                                                            <option value="">Select</option>
                                                            {destinationSchemaNameDropDown?.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select></td>

                                                        <td><select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            name="DestinationTableName"
                                                            value={inputValues.DestinationTableName}
                                                            onChange={(e) => handleInputChange(e)}
                                                        >
                                                            <option value="">Select</option>
                                                            {destinationTableNameDropDown?.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select></td>

                                                        <td><input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter"
                                                            name="SourceSystem"
                                                            value={inputValues.SourceSystem}
                                                            onChange={(e) => handleInputChange(e)}
                                                        /></td>

                                                        <td><input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter"
                                                            name="SourceSchema"
                                                            value={inputValues.SourceSchema}
                                                            onChange={(e) => handleInputChange(e)}
                                                        /></td>

                                                        <td><input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter"
                                                            name="SourceTableName"
                                                            value={inputValues.SourceTableName}
                                                            onChange={(e) => handleInputChange(e)}
                                                        /></td>
                                                        <td><input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter"
                                                            name="IntermediateOperations"
                                                            value={inputValues.IntermediateOperations}
                                                            onChange={(e) => handleInputChange(e)}
                                                        /></td>
                                                        <td><select
                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            aria-label="Default select example"
                                                            name="VerificationStatus"
                                                            value={inputValues.VerificationStatus}
                                                            onChange={(e) => handleInputChange(e)}
                                                        >
                                                            <option value="">Select</option>
                                                            {verificationStatusDropDown?.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select></td>
                                                        <td></td>
                                                        <td style={{ textAlign: "center" }}> <img
                                                            src="images/add-icons.svg"
                                                            alt="add-icon"
                                                            title="Add"

                                                            className={`cust-cursor-pointer  `}
                                                            // aria-disabled={isFormFilled()}
                                                            {...(isFormFilled() ? {
                                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                                "data-bs-target": "#editRows" // Specify the target modal
                                                            } : {})}
                                                            disabled={!isFormFilled()}

                                                            onClick={() => handleAdd(inputValues)} // Only call handleAdd if form is filled



                                                        /></td>
                                                    </tr>

                                                )
                                                }


                                                {bindgrid()}


                                            </tbody>
                                        </table>




                                        {/*Metadata Table ends here*/}

                                        {/* model */}
                                        <div
                                            className="modal fade "
                                            id="editRows"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            aria-labelledby="ConfrmLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h5
                                                            className="modal-title d-flex align-items-center font-medium font-20"
                                                            id="ConfrmLabel"
                                                        >
                                                            Edit Reason
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        />
                                                    </div>
                                                    <div className="modal-body py-0">
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12">
                                                                <label
                                                                    htmlFor="User-Name"
                                                                    className="form-label text-black font-14 font-medium"
                                                                >
                                                                    Specify reason for manual edit
                                                                    <span className="text-red"> *</span>
                                                                </label>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control font-14 font-regular "
                                                                    id="User-Name"
                                                                    placeholder="Enter reason"
                                                                    rows={5}
                                                                    defaultValue={null}
                                                                    value={reason}
                                                                    onChange={(e) => handleReason(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer border-0 px-4 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn cust-secondary-btn font-14 font-medium me-2"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => setReason("")}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn cust-primary-btn font-14 font-medium"
                                                            onClick={handleSaveDetails}
                                                            data-bs-dismiss="modal"
                                                            disabled={!reason}

                                                        >
                                                            <span>Save Details</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="modal fade"
                                            id="Confrm"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            aria-labelledby="ConfrmLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h5
                                                            className="modal-title d-flex align-items-center font-medium font-20"
                                                            id="ConfrmLabel"
                                                        >
                                                            Confirmation
                                                        </h5>
                                                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                                    </div>
                                                    <div className="modal-body py-0">
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12">
                                                                <p className="font-16">
                                                                    Are you sure you want to mark this document as Not verified?
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer border-0 px-4">
                                                        <button
                                                            type="button"
                                                            className="btn cust-secondary-btn font-14 font-medium me-2"
                                                            data-bs-dismiss="modal"
                                                        // onClick={handleVerified}

                                                        >
                                                            No
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn cust-primary-btn font-14 font-medium"
                                                            onClick={() => handleNotVerified()}
                                                            data-bs-dismiss="modal"

                                                        >
                                                            <span>Yes</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <p className="font-regular font-14 text-grey mt-3">
                                        # of Records:{" "}
                                        <span className="text-black font-medium"> {`${jsonData.length} of ${response.length} `}</span>
                                    </p>
                                </div>
                                {/* </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableMappingRework;