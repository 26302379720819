export const exploreGridDataURL = 'https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/CRTemplates/UsecaseCardData.json'

export const templateURLs = {
    testcaseResultUplaodURl: 'https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/TemplateData/uploadTemplates/Unit_Testcase_Result_Template.json',
    dataQualityResultUploadURL: 'https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/TemplateData/uploadTemplates/DataQuality_Result_Template.json',
    tableMappingUploadURL: 'https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/TemplateData/uploadTemplates/TableMapping_Upload_Template.json',
    notebookToBeUploadURL: 'https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/TemplateData/uploadTemplates/Analysis_NotebookToBeTested_Template.json'
}
export const dataQualityUploadValidations = {
    "RowCount": [
        { columnHeader: "S.No", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SchemaName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "CheckType", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Description", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Scripts", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "QALogScreenshot", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceScreenShot", columnCell: "H1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceRowCount", columnCell: "I1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "DestinationRowCount", columnCell: "J1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Comments", columnCell: "K1", isEmpty: 1, NA: 1, isNull: 0, valueValidation: null },
        { columnHeader: "ValidationStatus", columnCell: "L1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] }
    ],
    "SampleDataCheck": [
        { columnHeader: "S.No", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SchemaName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "CheckType", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Scripts", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "DestinationScreenShot", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceScreenShot", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Comments", columnCell: "H1", isEmpty: 1, NA: 1, isNull: 0, valueValidation: null },
        { columnHeader: "ValidationStatus", columnCell: "I1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] }
    ],
    "MetadataTable": [
        { columnHeader: "SourceCatalogName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceSchemaName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceTableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceStorageType", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["EXTERNAL", "MANAGED"] },
        { columnHeader: "SourceStoragePath", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewCatalogName", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewSchemaName", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewTableName", columnCell: "H1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewStorageType", columnCell: "I1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["EXTERNAL", "MANAGED"] },
        { columnHeader: "NewStoragePath", columnCell: "J1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "VersionHistory", columnCell: "K1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Yes", "No"] },
        { columnHeader: "MigrationPattern", columnCell: "L1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Migration", "Configuration"] },
        { columnHeader: "Batch ID", columnCell: "M1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "LoadType", columnCell: "N1", isEmpty: 0, NA: 1, isNull: 0, valueValidation: ["CTAS", "Deep Clone", "Config and Sync"] },
        { columnHeader: "CreatedStatus", columnCell: "O1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] },
        { columnHeader: "StructureTestStatus", columnCell: "P1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] },
        { columnHeader: "SampleDataTestStatus", columnCell: "Q1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] },
        { columnHeader: "RowCountStatus", columnCell: "R1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] },
        { columnHeader: "PipelineStatus", columnCell: "S1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] },
        { columnHeader: "PipelineStartTime", columnCell: "T1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "PipelineEndTime", columnCell: "U1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null }
    ]
};

export const unitTestResultUploadValidations = {
    "UnitTesting": [
        { columnHeader: "S.No", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NotebookName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "UnitTestCaseDescription", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Scripts", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Comments", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "ValidationStatus", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] }
    ]
}

export const tableMappingValidations = {
    "TableMappingDetails": [
        { columnHeader: "SourceCatalogName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceSchemaName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceTableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceStorageType", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["EXTERNAL", "MANAGED"] },
        { columnHeader: "SourceStoragePath", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewCatalogName", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewSchemaName", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewTableName", columnCell: "H1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NewStorageType", columnCell: "I1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["EXTERNAL", "MANAGED", "SOURCETYPE"] },
        { columnHeader: "NewStoragePath", columnCell: "J1", isEmpty: 0, NA: 1, isNull: 0, valueValidation: null },
        { columnHeader: "VersionHistory", columnCell: "K1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Yes", "No"] },
        { columnHeader: "MigrationPattern", columnCell: "L1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Migration", "Configuration"] },
        { columnHeader: "LoadType", columnCell: "M1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["CTAS", "Deep Clone", "Config and Sync"] },
        { columnHeader: "InScopeOrOutScope", columnCell: "N1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["InScope", "OutScope"] }
    ]
};


export const notebookTestUploadValidations = {
    "MatchedTables": [
        { columnHeader: "NotebookName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableNameIncatalog", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableNameInNotebook", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableCountInNotebook", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null }
    ],
    "Changes": [
        { columnHeader: "NotebookName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "ActualCode", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "ChangedCode", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null }
    ],
    "UnmatchedTables": [
        { columnHeader: "SourceCatalogName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceSchemaName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "SourceTableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null }
    ],
    "NotebookToBeTested": [
        { columnHeader: "NotebookName", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NotebookToBeTested", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null }
    ]
};


export const type1 = [
    {
        "sourceSystem": "MSSQL with SSIS",
        "future": "All",
        "fields": [
            "Server type",
            "Server Name",
            "Authentication",
            "UserName",
            "Password"
        ]
    },
    {
        "sourceSystem": "MSSQL without SSIS",
        "future": "All",
        "fields": [
            "Provider",
            "Server Name",
            "Authentication",
            "Username",
            "Password"
        ]
    },
    {
        "sourceSystem": "Informatica",
        "future": "All",
        "fields": [
            "User Name",
            "password",
            "Runtime Environment config",
            "Access token"
        ]
    },
    {
        "sourceSystem": "Oracle with informatica",
        "future": "All",
        "fields": [
            "User name",
            "Password",
            "hostname",
            "Servername",
            "Port",
            "schema",
            "Code Page"
        ]
    },
    {
        "sourceSystem": "Oracle without informatica",
        "future": "All",
        "fields": [
            "User name",
            "Password",
            "hostname",
            "Servername",
            "Port",
            "schema",
            "Code Page"
        ]
    },
    {
        "sourceSystem": "Snowflake",
        "future": "Databricks",
        "fields": [
            "host",
            "port",
            "user",
            "password",
            "sfwarehouse",
            "database",
            "schema"
        ]
    },
    {
        "sourceSystem": "Redshift",
        "future": "Databricks",
        "fields": [
            "host",
            "port",
            "user",
            "password",
            "database",
            "tempdir",
            "forward_spark_s3_credentials"
        ]
    }
]

export const type2 = [
    {
        "future": "Databricks",
        "data": [
            {
                "sourceSystem": "Salesforce",
                "fields": [
                    "user",
                    "password",
                    "clientId",
                    "clientSecret"
                ]
            },
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "api_key"
                ]
            },
            {
                "sourceSystem": "Google Ads",
                "fields": [
                    "client_id",
                    "client_secret",
                    "developer_token",
                    "refresh_token"
                ]
            },
            {
                "sourceSystem": "Google Ad Manager",
                "fields": [
                    "client_id",
                    "client_secret",
                    "refresh_token",
                    "api_url"
                ]
            },
            {
                "sourceSystem": "Google Analytics",
                "fields": [
                    "client_id",
                    "client_secret",
                    "refresh_token"
                ]
            },
            {
                "sourceSystem": "Oracle, Coupa",
                "fields": [
                    "API key or OAuth tokens",
                    "coupa_api_url"
                ]
            },
            {
                "sourceSystem": "Dynamic 365",
                "fields": [
                    "resource_url",
                    "client_id",
                    "client_secret",
                    "tenant_id",
                    "cds_api_url"
                ]
            },
            {
                "sourceSystem": "SAP",
                "fields": [
                    "hostname",
                    "user",
                    "password",
                    "database_name"
                ]
            },
            {
                "sourceSystem": "Postgres",
                "fields": [
                    "Hostname",
                    "Port",
                    "Database name",
                    "Username",
                    "Password"
                ]
            },
            {
                "sourceSystem": "Oracle",
                "fields": [
                    "Hostname",
                    "Port",
                    "Database name",
                    "Username",
                    "Password"
                ]
            },
            {
                "sourceSystem": "mysql",
                "fields": [
                    "jdbcHostname",
                    "jdbcPort",
                    "jdbcDatabase",
                    "user",
                    "password"
                ]
            },
            {
                "sourceSystem": "Sql server",
                "fields": [
                    "hostname",
                    "Database name",
                    "Username",
                    "Password"
                ]
            },
            {
                "sourceSystem": "Snowflake",
                "fields": [
                    "snowflake_account_url",
                    "username",
                    "password",
                    "database",
                    "schema",
                    "warehouse"
                ]
            },
            {
                "sourceSystem": "ADP",
                "fields": [
                    "auth_url",
                    "token_url",
                    "client_id",
                    "client_secret"
                ]
            },
            {
                "sourceSystem": "Workday",
                "fields": [
                    "workday_api_url",
                    "api_token"
                ]
            },
            {
                "sourceSystem": "UltiPro",
                "fields": [
                    "ultipro_api_key"
                ]
            },
            {
                "sourceSystem": "MsTeams",
                "fields": [
                    "teams_api_url"
                ]
            },
            {
                "sourceSystem": "Slack",
                "fields": [
                    "api_endpoint",
                    "slack_token",
                    "channel_id"
                ]
            }
        ]
    },
    {
        "future": "Synapse",
        "data": [
            {
                "sourceSystem": "Salesforce",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Environment url",
                    "User name",
                    "Password",
                    "Security token",
                    "Salesforce API version"
                ]
            },
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Client ID",
                    "Client secret",
                    "Access token",
                    "Refresh token",
                    "Use encrypted endpoints",
                    "Use host verification",
                    "Use peer verification"
                ]
            },
            {
                "sourceSystem": "Google Ads",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Google Ads API version",
                    "Client customer ID",
                    "Login customer ID",
                    "Developer token",
                    "Authentication type",
                    "Client ID",
                    "Client secret",
                    "Refresh token"
                ]
            },
            {
                "sourceSystem": "Google Ad Manager",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Google Analytics",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Oracle, Coupa",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Dynamic 365",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Deployment type",
                    "Service Uri",
                    "Authentication type",
                    "Service principal credential type",
                    "Service principal ID",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server CertificateValidation"
                ]
            },
            {
                "sourceSystem": "SAP",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Logon type",
                    "Server name",
                    "System number",
                    "Client ID",
                    "Language",
                    "SNC mode",
                    "User name",
                    "Password"
                ]
            },
            {
                "sourceSystem": "Postgress",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Authentication type",
                    "UserName",
                    "AzureKeyVault",
                    "AKV Linked Service",
                    "Secretname",
                    "SecretVersion",
                    "Credential"
                ]
            },
            {
                "sourceSystem": "Oracle",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version"
                ]
            },
            {
                "sourceSystem": "mysql",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Authentication type",
                    "UserName",
                    "AzureKeyVault",
                    "AKV Linked Service",
                    "Secretname",
                    "SecretVersion",
                    "Credential"
                ]
            },
            {
                "sourceSystem": "Sql server",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Authentication type: "
                ]
            },
            {
                "sourceSystem": "Snowflake",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Account name",
                    "User name",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Database",
                    "Warehouse",
                    "Role: "
                ]
            },
            {
                "sourceSystem": "ADP",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Workday",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "UltiPro",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "MsTeams",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Slack",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            }
        ]
    },
    {
        "future": "ADF",
        "data": [
            {
                "sourceSystem": "Salesforce",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Environment url",
                    "User name",
                    "Password",
                    "Security token",
                    "Salesforce API version"
                ]
            },
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Client ID",
                    "Client secret",
                    "Access token",
                    "Refresh token",
                    "Use encrypted endpoints",
                    "Use host verification",
                    "Use peer verification"
                ]
            },
            {
                "sourceSystem": "Google Ads",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Google Ads API version",
                    "Client customer ID",
                    "Login customer ID",
                    "Developer token",
                    "Authentication type",
                    "Client ID",
                    "Client secret",
                    "Refresh token"
                ]
            },
            {
                "sourceSystem": "Google Ad Manager",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Google Analytics",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Oracle, Coupa",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Dynamic 365",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Deployment type",
                    "Service Uri",
                    "Authentication type",
                    "Service principal credential type",
                    "Service principal ID",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server CertificateValidation"
                ]
            },
            {
                "sourceSystem": "SAP",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Logon type",
                    "Server name",
                    "System number",
                    "Client ID",
                    "Language",
                    "SNC mode",
                    "User name",
                    "Password"
                ]
            },
            {
                "sourceSystem": "Postgress",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Authentication type",
                    "UserName",
                    "AzureKeyVault",
                    "AKV Linked Service",
                    "Secretname",
                    "SecretVersion",
                    "Credential"
                ]
            },
            {
                "sourceSystem": "Oracle",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version"
                ]
            },
            {
                "sourceSystem": "mysql",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Authentication type",
                    "UserName",
                    "AzureKeyVault",
                    "AKV Linked Service",
                    "Secretname",
                    "SecretVersion",
                    "Credential"
                ]
            },
            {
                "sourceSystem": "Sql server",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "AKV linked service",
                    "Secret name",
                    "Authentication type: "
                ]
            },
            {
                "sourceSystem": "Snowflake",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Account name",
                    "User name",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Database",
                    "Warehouse",
                    "Role: "
                ]
            },
            {
                "sourceSystem": "ADP",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Workday",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "UltiPro",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "MsTeams",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Slack",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvault",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            }
        ]
    },
    {
        "future": "Snowflake",
        "data": [
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "Database Name",
                    "Schema Name",
                    "TableName"
                ]
            },
            {
                "sourceSystem": "S3 Bucket",
                "fields": [
                    "Stage",
                    "Storage_Integration",
                    "URL",
                    "File Format"
                ]

            },
            {
                "sourceSystem": "Azure Container",
                "fields": [
                    "Stage",
                    "Storage_Integration",
                    "URL",
                    "File Format"
                ]
            },
            {
                "sourceSystem": "Google Cloud Storage",
                "fields": [
                    "Stage",
                    "Storage_Integration",
                    "URL",
                    "File Format"
                ]
            }
        ]
    },
    {
        "future": "AWS Glue",
        "data": [
            {
                "sourceSystem": "Google Ad Manager",
                "fields": [
                    "client_id",
                    "client_secret",
                    "refresh_token",
                    "network_code"
                ]
            },
            {
                "sourceSystem": "SAP",
                "fields": [
                    "driver",
                    "url",
                    "user",
                    "password"
                ]
            },
            {
                "sourceSystem": "Oracle",
                "fields": [
                    "hostname",
                    "port",
                    "databasename",
                    "username",
                    "password"
                ]
            },
            {
                "sourceSystem": "Salesforce",
                "fields": [
                    "Aws salesforce connector",
                    "Consumer Key",
                    "Consumer secret",
                    "Username",
                    "Password",
                    "Security Token"
                ]
            },
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "API Key or OAuth tokens"
                ]
            },
            {
                "sourceSystem": "Google Ads",
                "fields": [
                    "Developer Token",
                    "Client ID",
                    "Client Secret",
                    "Refresh Token"
                ]
            },
            {
                "sourceSystem": "Google Analytics",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "Access Token",
                    "Refresh Token"
                ]
            },
            {
                "sourceSystem": "Oracle, Coupa",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvalut",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Dynamic 368",
                "fields": [
                    "Access Token or API Key",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "Postgress",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "mysql",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "Sql server",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "Snowflake",
                "fields": [
                    "Account URL",
                    "Database name",
                    "Warehouse name",
                    "Schema name",
                    "Snowflake user credentials"
                ]
            },
            {
                "sourceSystem": "ADP",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "Workday",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "UltiPro",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL",
                    "Auth Endpoint URL"
                ]
            },
            {
                "sourceSystem": "MsTeams",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "Tenant ID",
                    "Teams URL"
                ]
            },
            {
                "sourceSystem": "Slack",
                "fields": [
                    "SlackAPI Credentials",
                    "Slack OAuth Token",
                    "API EndPoint"
                ]
            }
        ]
    },
    {
        "future": "Redshift",
        "data": [
            {
                "sourceSystem": "Salesforce",
                "fields": [
                    "Aws salesforce connector",
                    "Consumer Key",
                    "Consumer secret",
                    "Username",
                    "Password",
                    "Security Token"
                ]
            },
            {
                "sourceSystem": "HubSpot",
                "fields": [
                    "API Key or OAuth tokens"
                ]
            },
            {
                "sourceSystem": "Google Ads",
                "fields": [
                    "Developer Token",
                    "Client ID",
                    "Client Secret",
                    "Refresh Token"
                ]
            },
            {
                "sourceSystem": "Google Analytics",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "Access Token",
                    "Refresh Token"
                ]
            },
            {
                "sourceSystem": "Oracle, Coupa",
                "fields": [
                    "Name",
                    "Connect via integration runtime",
                    "Base URL",
                    "Authentication type",
                    "User name",
                    "Keyvalut",
                    "AKV linked service",
                    "Secret name",
                    "Secret version",
                    "Server Certificate Validation"
                ]
            },
            {
                "sourceSystem": "Dynamic 368",
                "fields": [
                    "Access Token or API Key",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "Postgress",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "mysql",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "Sql server",
                "fields": [
                    "Hostname or IP address",
                    "Port number",
                    "Database name",
                    "Table name",
                    "Database username",
                    "Database password"
                ]
            },
            {
                "sourceSystem": "Snowflake",
                "fields": [
                    "Account URL",
                    "Database name",
                    "Warehouse name",
                    "Schema name",
                    "Snowflake user credentials"
                ]
            },
            {
                "sourceSystem": "ADP",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "Workday",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL"
                ]
            },
            {
                "sourceSystem": "UltiPro",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "API Endpoint URL",
                    "Auth Endpoint URL"
                ]
            },
            {
                "sourceSystem": "MsTeams",
                "fields": [
                    "Client ID",
                    "Client Secret",
                    "Tenant ID",
                    "Teams URL"
                ]
            },
            {
                "sourceSystem": "Slack",
                "fields": [
                    "SlackAPI Credentials",
                    "Slack OAuth Token",
                    "API EndPoint"
                ]
            }
        ]
    }
]

export const cloudonprem = `
MSSQL with SSIS
MSSQL without SSIS
Informatica
Oracle with informatica
Oracle without informatica
SAP 
Postgres
Oracle
mysql
Sql server
MSSQL
MYSQL
Others

`
export const type3 = [
    {
        "sourceSystem": "MSSQL",
        "future": "All",
        "fields": [
            "Server",
            "Database",
            "Username",
            "Password",
            "Driver"
        ]
    },
    {
        "sourceSystem": "Mysql",
        "future": "All",
        "fields": [
            "Host",
            "User",
            "Password",
            "Database",
            "Charset",
            "WaitForConnections",
            "ConnectionLimit",
            "QueueLimit"
        ]
    },
    {
        "sourceSystem": "HubSpot",
        "future": "All",
        "fields": [
            "accessToken",
            "baseUrl",
            "Tablename"
        ]
    },
    {
        "sourceSystem": "Salesforce",
        "future": "All",
        "fields": [
            "username",
            "password",
            "security_token",
            "client_id",
            "client_secret",
            "loginUrl"
        ]
    },
    {
        "sourceSystem": "Postgres",
        "future": "All",
        "fields": [
            "User",
            "Host",
            "Database",
            "Password",
            "Port"
        ]
    },
    {
        "sourceSystem": "Snowflake",
        "future": "All",
        "fields": [
            "Username",
            "Password",
            "Account",
            "Warehouse",
            "Database",
            "Schema"
        ]
    },
    {
        "sourceSystem": "Oracle",
        "future": "All",
        "fields": [
            "Username",
            "Password",
            "Host",
            "Database",
            "ConnectString",
            "port"
        ]
    },
    {
        "sourceSystem": "Redshift",
        "future": "All",
        "fields": [
            "User",
            "Password",
            "Host",
            "Database",
            "port"
        ]
    }

]

//download scripts
export const scripts = [
    {
        "sourceSystem": "MSSQL",
        "scripts": `  SELECT
        SCHEMA_NAME(tbl.schema_id) AS schema_name,
        tbl.name AS table_name,
        col.name AS column_name,
        t.name AS data_type,
        col.max_length AS length,
        col.is_nullable AS is_nullable,
        CASE WHEN pk.name IS NOT NULL THEN 'Primary Key'
            WHEN fk.name IS NOT NULL THEN 'Foreign Key'
            ELSE 'None' END AS constraint_type,
        fk.ref_table AS referenced_table,
        fk.ref_column AS referenced_column
    FROM
        sys.tables tbl
    INNER JOIN
        sys.columns col ON tbl.object_id = col.object_id
    INNER JOIN
        sys.types t ON col.user_type_id = t.user_type_id
    LEFT JOIN
        (SELECT
            c.object_id,
            c.name AS column_name,
            kc.name AS name
        FROM
            sys.key_constraints kc
        JOIN
            sys.index_columns ic ON kc.parent_object_id = ic.object_id
                                AND kc.unique_index_id = ic.index_id
        JOIN
            sys.columns c ON ic.object_id = c.object_id
                        AND ic.column_id = c.column_id
        WHERE
            kc.type = 'PK') pk ON tbl.object_id = pk.object_id
                            AND col.name = pk.column_name
    LEFT JOIN
        (SELECT
            object_name(fk.parent_object_id) AS table_name,
            col.name AS column_name,
            fk.name AS name,
            SCHEMA_NAME(reftbl.schema_id) AS ref_table,
            refcol.name AS ref_column
        FROM
            sys.foreign_keys fk
        JOIN
            sys.foreign_key_columns fkc ON fk.object_id = fkc.constraint_object_id
        JOIN
            sys.columns col ON fkc.parent_object_id = col.object_id
                            AND fkc.parent_column_id = col.column_id
        JOIN
            sys.tables reftbl ON fkc.referenced_object_id = reftbl.object_id
        JOIN
            sys.columns refcol ON fkc.referenced_object_id = refcol.object_id
                                AND fkc.referenced_column_id = refcol.column_id) fk ON tbl.name = fk.table_name
                                                                            AND col.name = fk.column_name
    WHERE
        SCHEMA_NAME(tbl.schema_id) <> 'sys'
    ORDER BY
        schema_name,
        table_name,
        column_name;
`
    },
    {
        "sourceSystem": "Mysql",
        "scripts": `SELECT 
        TABLE_SCHEMA AS schema_name,
        TABLE_NAME AS table_name,
        COLUMN_NAME AS column_name,
        COLUMN_TYPE AS data_type,
        COLUMN_DEFAULT AS column_default,
        IS_NULLABLE AS not_null,
        CONSTRAINT_TYPE,
        CONSTRAINT_NAME,
        REFERENCED_TABLE_NAME AS foreign_table,
        REFERENCED_COLUMN_NAME AS foreign_column
    FROM
        INFORMATION_SCHEMA.COLUMNS
    LEFT JOIN
        INFORMATION_SCHEMA.KEY_COLUMN_USAGE USING (TABLE_SCHEMA, TABLE_NAME, COLUMN_NAME)
    LEFT JOIN
        INFORMATION_SCHEMA.TABLE_CONSTRAINTS USING (TABLE_SCHEMA, TABLE_NAME, CONSTRAINT_NAME)
    WHERE
        TABLE_SCHEMA NOT IN ('information_schema', 'mysql', 'performance_schema', 'sys')
    ORDER BY
        schema_name,
        table_name,
        column_name;`
    },
    {
        "sourceSystem": "Oracle",
        "scripts": ` SELECT
        tbl.owner AS schema_name,
        tbl.table_name,
        col.column_name,
        col.data_type,
        col.data_length AS length,
        col.nullable AS is_nullable,
        CASE WHEN cons.constraint_type = 'P' THEN 'Primary Key'
             WHEN cons.constraint_type = 'R' THEN 'Foreign Key'
             ELSE 'None' END AS constraint_type,
        cons.r_owner AS referenced_table_owner,
        cons.r_constraint_name AS referenced_constraint_name,
        cons_pk.table_name AS referenced_table,
        cons_fk.column_name AS referenced_column
    FROM
        all_tables tbl
    JOIN
        all_tab_columns col ON tbl.owner = col.owner
                           AND tbl.table_name = col.table_name
    LEFT JOIN
        (SELECT
            cons.constraint_name,
            cons.owner,
            cons.table_name,
            cons.constraint_type,
            cons.r_owner,
            cons.r_constraint_name
        FROM
            all_constraints cons
        WHERE
            cons.constraint_type IN ('P', 'R')) cons ON tbl.owner = cons.owner
                                                      AND tbl.table_name = cons.table_name
    LEFT JOIN
        all_cons_columns cons_fk ON cons.constraint_name = cons_fk.constraint_name
                                 AND cons_fk.owner = tbl.owner
    LEFT JOIN
        all_cons_columns cons_pk ON cons.r_constraint_name = cons_pk.constraint_name
                                  AND cons.r_owner = cons_pk.owner
                                  AND cons_fk.position = cons_pk.position
    WHERE
        tbl.owner NOT IN ('SYS', 'SYSTEM')
    ORDER BY
        schema_name,
        table_name,
        column_name`
    },
    {
        "sourceSystem": "Postgres",
        "scripts": `    SELECT 
        columns.table_catalog AS "DatabaseName", 
        columns.table_schema AS "schema_name", 
        columns.table_name AS "table_name", 
        columns.column_name AS "column_name", 
        columns.data_type AS "data_type", 
        columns.column_default AS "column_default",
        CASE WHEN pk.p_column_name IS NOT NULL THEN 'PRIMARY KEY' ELSE '' END AS primary_Key, 
        CASE WHEN fk.f_column_name IS NOT NULL THEN 'FOREIGN KEY' ELSE '' END AS Foreign_Key, 
        fk.f_table_name AS foreign_table,
        CASE WHEN columns.is_nullable = 'YES' THEN 'YES' ELSE 'NO' END AS is_null
    FROM 
        information_schema.columns 
    LEFT JOIN (
        SELECT 
            substring(conrelid::regclass::text FROM position('.' IN conrelid::regclass::text) + 1) AS table_name, 
            pg_attribute.attname AS p_column_name 
        FROM 
            pg_constraint 
        INNER JOIN 
            pg_attribute ON pg_constraint.conindid = pg_attribute.attrelid 
        WHERE 
            pg_constraint.contype = 'p'
    ) AS Pk ON pk.table_name = columns.table_name AND columns.column_name = pk.p_column_name 
    LEFT JOIN (
        SELECT 
            substring(conrelid::regclass::text FROM position('.' IN conrelid::regclass::text) + 1) AS table_name, 
            pg_attribute.attname AS f_column_name, 
            confrelid::regclass::text AS f_table_name 
        FROM 
            pg_constraint 
        INNER JOIN 
            pg_attribute ON pg_constraint.conindid = pg_attribute.attrelid 
        WHERE 
            pg_constraint.contype = 'f'
    ) AS fk ON fk.table_name = columns.table_name AND columns.column_name = fk.f_column_name 
    WHERE 
        columns.table_schema NOT LIKE 'pg_%' AND columns.table_schema != 'information_schema'`
    },
    {
        "sourceSystem": "Snowflake",
        "scripts": ` SELECT
        ST.TABLE_NAME,
        CT.COLUMN_NAME,
        ST.TABLE_SCHEMA,
        CT.DATA_TYPE,
        CASE
            WHEN CT.CHARACTER_MAXIMUM_LENGTH IS NOT NULL THEN CT.CHARACTER_MAXIMUM_LENGTH
            ELSE NUMERIC_PRECISION
        END AS max_length,
        CT.IS_NULLABLE
    FROM
        INFORMATION_SCHEMA.COLUMNS AS CT
    INNER JOIN
        INFORMATION_SCHEMA.TABLES AS ST ON CT.TABLE_SCHEMA = ST.TABLE_SCHEMA
        AND CT.TABLE_NAME = ST.TABLE_NAME
    WHERE
        ST.TABLE_TYPE = 'BASE TABLE'
    ORDER BY
        ST.TABLE_SCHEMA,
        ST.TABLE_NAME,
        CT.ORDINAL_POSITION;`
    },
]

export const extractionRequirement = [
    {
        "RequirementID": "5.1.1",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Azure SQL MI Database",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "-",
        "LoadType": "Incremental Load",
        "RequirementDescription": "Data will be loaded from the Azure SQL MI database (dbo schema).\r\nSource table = dbo.Activity"
    },
    {
        "RequirementID": "5.1.2",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Primary Key",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "Primary Key",
        "LoadType": "Incremental Load",
        "RequirementDescription": "Primary key for this table in source system is ID"
    },
    {
        "RequirementID": "5.1.3",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Historical Load",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "-",
        "LoadType": "Incremental Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.1.4",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Incremental Load",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "Filter",
        "LoadType": "Incremental Load",
        "RequirementDescription": "Incremental load will be performed using the ModifiedOn field. Any records with a date greater than the current ModifiedOn field in the metadata table will brought in."
    },
    {
        "RequirementID": "5.1.5",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Refresh",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "NA",
        "LoadType": "Incremental Load",
        "RequirementDescription": "Data will be refreshed 4 hours once starting at 1:00 AM EDT after the source system in refreshed."
    },
    {
        "RequirementID": "5.1.6",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Transformations",
        "SubjectArea": "Operations",
        "TableName": "Activity",
        "Transformation": "NA",
        "LoadType": "Incremental Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.2.1",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Azure SQL MI Database",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Data will be loaded from the Azure SQL MI database (dbo schema).\r\nSource table = dbo.ActivityRecip"
    },
    {
        "RequirementID": "5.2.2",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Primary Key",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "Primary Key",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Primary key for this table in source system is ID."
    },
    {
        "RequirementID": "5.2.3",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Historical Load",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.2.4",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Incremental Load",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Per Req 5.2.4,no incremental load will be performed for this table. It will be a truncate and load everyday."
    },
    {
        "RequirementID": "5.2.5",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Refresh",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Data will be refreshed 4 hours once starting at 1:00 AM EDT after the source system in refreshed."
    },
    {
        "RequirementID": "5.2.6",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Transformations",
        "SubjectArea": "Operations",
        "TableName": "ActivityRecip",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.3.1",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Azure SQL MI Database",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Data will be loaded from the Azure SQL MI database (dbo schema).\r\nSource table = dbo.ActivityType"
    },
    {
        "RequirementID": "5.3.2",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Primary Key",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "Primary Key",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Primary key for this table in source system is ID."
    },
    {
        "RequirementID": "5.3.3",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Historical Load",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.3.4",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Incremental Load",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Per Req 5.3.4,no incremental load will be performed for this table. It will be a truncate and load everyday."
    },
    {
        "RequirementID": "5.3.5",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Refresh",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Data will be refreshed 4 hours once starting at 1:00 AM EDT after the source system in refreshed."
    },
    {
        "RequirementID": "5.3.6",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction - Data Transformations",
        "SubjectArea": "Operations",
        "TableName": "ActivityType",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "NA"
    },
    {
        "RequirementID": "5.4.1",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Azure SQL MI Database",
        "SubjectArea": "Operations",
        "TableName": "Client",
        "Transformation": "NA",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Data will be loaded from the Azure SQL MI database (dbo schema).\r\nSource table = dbo.Client"
    },
    {
        "RequirementID": "5.4.2",
        "SourceSystems": "Source System - Docket",
        "RequirementType": "Extraction Scope - Primary Key",
        "SubjectArea": "Operations",
        "TableName": "Client",
        "Transformation": "Primary Key",
        "LoadType": "Truncate and Load",
        "RequirementDescription": "Primary key for this table in source system is ID."
    }
]
export const silverER = [
]
export const goldER = [
]
export const dataModelSilver = [
]
export const dataModelGold = [
]
export const silverDD = [
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "silver",
        "TableName": "Silver.Activity",
        "ColumnName": "ActivityID",
        "DataType": "UNIQUEIDENTIFIER",
        "Constraints": "PRIMARY KEY",
        "Description": "A unique identifier for an activity within the operations domain."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "silver",
        "TableName": "Silver.Activity",
        "ColumnName": "WorkPlanID",
        "DataType": "INT",
        "Constraints": "FOREIGN KEY",
        "Description": "An integer representing the identifier of the work plan associated with the activity."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "silver",
        "TableName": "Silver.Activity",
        "ColumnName": "StartsOn",
        "DataType": "DATETIME",
        "Constraints": "NOT NULL",
        "Description": "The date and time when the activity is scheduled to start."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "silver",
        "TableName": "Silver.Activity",
        "ColumnName": "EndsOn",
        "DataType": "DATETIME",
        "Constraints": "NULL",
        "Description": "The date and time when the activity is scheduled to end. This field can be null if the end time is not set."
    }]
export const goldDD = [
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "Gold",
        "TableName": "Docket",
        "ColumnName": "BillingID",
        "DataType": "INT",
        "Constraints": "PRIMARY KEY",
        "Description": "Unique identifier for each billing record in the insurance operations."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "Gold",
        "TableName": "Docket",
        "ColumnName": "EmailID",
        "DataType": "VARCHAR(255)",
        "Constraints": "NOT NULL",
        "Description": "Email address associated with the insurance client or contact."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "Gold",
        "TableName": "Docket",
        "ColumnName": "UserName",
        "DataType": "VARCHAR(255)",
        "Constraints": "NOT NULL",
        "Description": "The username of the insurance client or internal user."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "Gold",
        "TableName": "Docket",
        "ColumnName": "Matter_ID",
        "DataType": "INT",
        "Constraints": "FOREIGN KEY",
        "Description": "Identifier linking to the specific matter or case in insurance operations."
    },
    {
        "SourceSystem": "BEC Docket",
        "SubjectArea": "Operations",
        "SchemaName": "Gold",
        "TableName": "Docket",
        "ColumnName": "MatterName",
        "DataType": "VARCHAR(255)",
        "Constraints": "NULL",
        "Description": "The name of the matter or case related to insurance operations."
    }]
export const extractionseqNotebook = [{}]
export const extractionseqPipeline = [{}]
export const extractionStoredProcedureSeq = [{}]
export const transformationSeqPipeline = [{}]
export const transformationseqNotebook = [{}]
export const transformationStoreProcedureSeq = [{}]
export const goldSeqPipeline = [{}]
export const goldStoreProcedureSeq = [{}]
export const goldNotebookSeq = [{}]
export const dbMetaData = `
# Databricks notebook source
# MAGIC %md
# MAGIC ## Initializing widgets

# COMMAND ----------

dbutils.widgets.text("CatalogName", " ")
CatalogName = dbutils.widgets.get("CatalogName")

# COMMAND ----------

# MAGIC %md
# MAGIC ## Initialize Catalog

# COMMAND ----------

# DBTITLE 0,Initialize Catalog
# MAGIC %sql
# MAGIC Use CATALOG CatalogName

# COMMAND ----------

# MAGIC %md
# MAGIC ## Create Schema

# COMMAND ----------

# DBTITLE 0,Create Schema
# MAGIC %sql
# MAGIC CREATE SCHEMA metadata

# COMMAND ----------

# MAGIC %md
# MAGIC ## Create Log Table

# COMMAND ----------

# MAGIC %sql
# MAGIC create table metadata.logtable
# MAGIC (
# MAGIC   TableName STRING,
# MAGIC   RowCount BIGINT,
# MAGIC   Zone STRING,
# MAGIC   DateOfLoad TIMESTAMP
# MAGIC )
# MAGIC using delta location 'dbfs:/user/hive/warehouse/metadata/logtable'

# COMMAND ----------

# MAGIC %md
# MAGIC ## Create Metadata Table

# COMMAND ----------

# DBTITLE 0,Create Metadata Table
# MAGIC %sql
# MAGIC CREATE TABLE metadata.mastermetadata (
# MAGIC   SourceSystem VARCHAR(50),
# MAGIC   SourceSecretName VARCHAR(100),
# MAGIC   TableID INTEGER,
# MAGIC   SubjectArea VARCHAR(50),
# MAGIC   SourceDBName VARCHAR(50),
# MAGIC   SourceSchema VARCHAR(50),
# MAGIC   SourceTableName VARCHAR(100),
# MAGIC   LoadType VARCHAR(250),
# MAGIC   IsActive TINYINT,
# MAGIC   Frequency VARCHAR(50),
# MAGIC   StagePath VARCHAR(250),
# MAGIC   RawPath VARCHAR(250),
# MAGIC   CuratedPath VARCHAR(250),
# MAGIC   DWHSchemaName VARCHAR(50),
# MAGIC   DWHTableName VARCHAR(100),
# MAGIC   ErrorLogPath VARCHAR(250),
# MAGIC   LastLoadDateColumn VARCHAR(50),
# MAGIC   MergeKey VARCHAR(250),
# MAGIC   MergeKeyColumn VARCHAR(250),
# MAGIC   DependencyTableIDs VARCHAR(250),
# MAGIC   LastLoadDateValue TIMESTAMP,
# MAGIC   PipelineEndTime TIMESTAMP,
# MAGIC   PipelineRunStatus VARCHAR(20),
# MAGIC   Zone VARCHAR(20),
# MAGIC   SourceSelectQuery VARCHAR(8000),
# MAGIC   StageCreateQuery VARCHAR(8000),
# MAGIC   NotebookName VARCHAR(50))
# MAGIC USING delta
# MAGIC  LOCATION 'dbfs '  

# COMMAND ----------

# MAGIC %md
# MAGIC ## Insert Data to Metadata table

# COMMAND ----------

# DBTITLE 0,Insert Data to Metadata table
# MAGIC %sql
# MAGIC INSERT INTO metadata.mastermetadata
# MAGIC VALUES (
# MAGIC "DWH"	,
# MAGIC "Ky_Dev_DWH"	,
# MAGIC "A385"	,
# MAGIC "Finance"	,
# MAGIC Null	,
# MAGIC Null	,
# MAGIC Null	,
# MAGIC Null	,
# MAGIC 1	,
# MAGIC Null	,
# MAGIC Null	,
# MAGIC Null	,
# MAGIC "/mnt/ingest/curated-data/acct/DimChartOfAccounts"	,
# MAGIC "acct"	,
# MAGIC "DimChartOfAccounts"	,
# MAGIC "log/DWH/acct/DimChartOfAccounts"	,
# MAGIC "ETLModifiedDate"	,
# MAGIC "code",
# MAGIC '"A375,A375"',	
# MAGIC Null	,
# MAGIC Null	,
# MAGIC  Null	,
# MAGIC  Null	,
# MAGIC  "select ScheduledMaintenanceId,StartDateTime,EndDateTime,MaintenanceMessage,IsActive,CreatedDate,CreatedUser,ModifiedDate,ModifiedUser,SuppressSubmit from portfolio.ScheduledMaintenance"	
# MAGIC );
`

export const dbDdl = `# Databricks notebook source
# MAGIC %md
# MAGIC ####Initializing widgets

# COMMAND ----------

dbutils.widgets.text("CatalogName", " ")
CatalogName = dbutils.widgets.get("CatalogName")

# COMMAND ----------

# MAGIC %md
# MAGIC ####Initializing Catalog

# COMMAND ----------

# DBTITLE 0,Initialize Catalog
# MAGIC %sql
# MAGIC Use catalog CatalogName

# COMMAND ----------

# MAGIC %md
# MAGIC #### Create Stage Schema

# COMMAND ----------

# DBTITLE 0,Create Stage Schema
# MAGIC %sql
# MAGIC CREATE SCHEMA stage

# COMMAND ----------

# MAGIC %md
# MAGIC #### Create Tables

# COMMAND ----------

# MAGIC %md
# MAGIC ##### Create Table - Stage.Inventory

# COMMAND ----------

# DBTITLE 0,Create Stage.Inventory
# MAGIC %sql
# MAGIC CREATE TABLE stage.inventory (
# MAGIC   SourceSystem VARCHAR(50),
# MAGIC   ErrorLogPath VARCHAR(250),
# MAGIC   LastLoadDateColumn VARCHAR(50),
# MAGIC   MergeKey VARCHAR(250),
# MAGIC   MergeKeyColumn VARCHAR(250),
# MAGIC   DependencyTableIDs VARCHAR(250),
# MAGIC   LastLoadDateValue TIMESTAMP,
# MAGIC   PipelineEndTime TIMESTAMP,
# MAGIC   PipelineRunStatus VARCHAR(20),
# MAGIC   Zone VARCHAR(20),
# MAGIC   SourceSelectQuery VARCHAR(8000))
# MAGIC USING delta
# MAGIC  LOCATION 'dbfs:/user/hive/warehouse/default/inventory';

# COMMAND ----------

# MAGIC %md
# MAGIC ##### Create Table - Stage.Store

# COMMAND ----------

# DBTITLE 0,Create Stage.Store
# MAGIC %sql
# MAGIC CREATE TABLE stage.store (
# MAGIC   SourceSystem VARCHAR(50),
# MAGIC   ErrorLogPath VARCHAR(250),
# MAGIC   LastLoadDateColumn VARCHAR(50),
# MAGIC   MergeKey VARCHAR(250),
# MAGIC   MergeKeyColumn VARCHAR(250),
# MAGIC   DependencyTableIDs VARCHAR(250),
# MAGIC   LastLoadDateValue TIMESTAMP,
# MAGIC   PipelineEndTime TIMESTAMP,
# MAGIC   PipelineRunStatus VARCHAR(20),
# MAGIC   Zone VARCHAR(20),
# MAGIC   SourceSelectQuery VARCHAR(8000))
# MAGIC USING delta
# MAGIC  LOCATION 'dbfs:/user/hive/warehouse/default/store';
`
export const sfMetadata = `CREATE TABLE mastermetadata (
	SourceSystem VARCHAR(50),
	SourceSecretName VARCHAR(100),
	TableID INTEGER,
	SubjectArea VARCHAR(50),
	SourceDBName VARCHAR(50),
	SourceSchema VARCHAR(50),
	SourceTableName VARCHAR(100),
	LoadType VARCHAR(250),
	IsActive Int,
	Frequency VARCHAR(50),
	BronzePath VARCHAR(250),
	SilverPath VARCHAR(250),
	GoldPath VARCHAR(250),
	DWHSchemaName VARCHAR(50),
	DWHTableName VARCHAR(100),
	ErrorLogPath VARCHAR(250),
	LastLoadDateColumn VARCHAR(50),
	MergeKey VARCHAR(250),
	MergeKeyColumn VARCHAR(250),
	DependencyTableIDs VARCHAR(250),
	LastLoadDateValue TIMESTAMP,
	PipelineEndTime TIMESTAMP,
	PipelineRunStatus VARCHAR(20),
	Zone VARCHAR(20),
	SourceSelectQuery VARCHAR(8000),
	StageCreateQuery VARCHAR(8000),
	NotebookName VARCHAR(50))
	
	INSERT INTO metadata.mastermetadata
VALUES (
"DWH"	,
"Ky_Dev_DWH"	,
"A385"	,
"Finance"	,
Null	,
Null	,
Null	,
Null	,
1	,
Null	,
Null	,
Null	,
"/mnt/ingest/curated-data/acct/DimChartOfAccounts"	,
"acct"	,
"DimChartOfAccounts"	,
"log/DWH/acct/DimChartOfAccounts"	,
"ETLModifiedDate"	,
"code",
'"A375,A375"',	
Null	,
Null	,
Null	,
Null	,
"select ScheduledMaintenanceId,StartDateTime,EndDateTime,MaintenanceMessage,IsActive,CreatedDate,CreatedUser,ModifiedDate,ModifiedUser,SuppressSubmit from portfolio.ScheduledMaintenance"	
);
`

export const sfDdl = `CREATE OR REPLACE TABLE Gold.Dim_Phone (
	DW_PHONE_ID NUMBER(19,0),
	START_DATETIME TIMESTAMP NOT NULL,
	END_DATETIME TIMESTAMP,
	PHONE_NUMBER VARCHAR(20) NOT NULL,
	PHONE_EXTENSION VARCHAR(20) NOT NULL,
	NOTE VARCHAR(150),
	VALID_PHONE_FLAG NUMBER(1,0) NOT NULL,
	DNC_PHONE_FLAG NUMBER(1,0) NOT NULL,
	TEXT_CONSENT NUMBER(1,0),
	MKT_SHORT_CODE_OPTIN NUMBER(1,0),
	DW_SOURCE_ID NUMBER(19,0) NOT NULL,
	SOURCE_SYSTEM_NAME VARCHAR(50) NOT NULL,
	CREATED_DATE TIMESTAMP NOT NULL,
	CREATED_BY VARCHAR NOT NULL,
	LAST_MODIFIED_DATE TIMESTAMP NOT NULL,
	LAST_MODIFIED_BY NOT NULL,
	CONSTRAINT Dim_Phone_pkey PRIMARY KEY (DW_PHONE_ID)`

export const syMetadata = `CREATE TABLE mastermetadata (
	SourceSystem VARCHAR(50),
	SourceSecretName VARCHAR(100),
	TableID INTEGER,
	SubjectArea VARCHAR(50),
	SourceDBName VARCHAR(50),
	SourceSchema VARCHAR(50),
	SourceTableName VARCHAR(100),
	LoadType VARCHAR(250),
	IsActive Int,
	Frequency VARCHAR(50),
	BronzePath VARCHAR(250),
	SilverPath VARCHAR(250),
	GoldPath VARCHAR(250),
	DWHSchemaName VARCHAR(50),
	DWHTableName VARCHAR(100),
	ErrorLogPath VARCHAR(250),
	LastLoadDateColumn VARCHAR(50),
	MergeKey VARCHAR(250),
	MergeKeyColumn VARCHAR(250),
	DependencyTableIDs VARCHAR(250),
	LastLoadDateValue TIMESTAMP,
	PipelineEndTime TIMESTAMP,
	PipelineRunStatus VARCHAR(20),
	Zone VARCHAR(20),
	SourceSelectQuery VARCHAR(8000),
	StageCreateQuery VARCHAR(8000),
	NotebookName VARCHAR(50))
	
	INSERT INTO metadata.mastermetadata
VALUES (
"DWH"	,
"Ky_Dev_DWH"	,
"A385"	,
"Finance"	,
Null	,
Null	,
Null	,
Null	,
1	,
Null	,
Null	,
Null	,
"/mnt/ingest/curated-data/acct/DimChartOfAccounts"	,
"acct"	,
"DimChartOfAccounts"	,
"log/DWH/acct/DimChartOfAccounts"	,
"ETLModifiedDate"	,
"code",
'"A375,A375"',	
Null	,
Null	,
Null	,
Null	,
"select ScheduledMaintenanceId,StartDateTime,EndDateTime,MaintenanceMessage,IsActive,CreatedDate,CreatedUser,ModifiedDate,ModifiedUser,SuppressSubmit from portfolio.ScheduledMaintenance"	
);
`

export const syDdl = `
Create table rdp.supplier(
SupplierID	varchar(50) Not Null,
SupplierName	varchar(200) Null,
SupplierType	varchar(50) Null,
SupplierAddr1	varchar(300) Null,
SupplierAddr2	varchar(100) Null,
SupplierAddr3	varchar(100) Null,
SupplierAddr4	varchar(100) Null,
SupplierCity	varchar(50) Null,
SupplierState	varchar(50) Null,
SupplierPostal	varchar(50) Null,
SupplierCountry	varchar(50)Null,
UpdateDate	datetime2(30)Null,
SourceSystem	varchar(30)
)

`

export const adfMetadata = `CREATE TABLE mastermetadata (
	SourceSystem VARCHAR(50),
	SourceSecretName VARCHAR(100),
	TableID INTEGER,
	SubjectArea VARCHAR(50),
	SourceDBName VARCHAR(50),
	SourceSchema VARCHAR(50),
	SourceTableName VARCHAR(100),
	LoadType VARCHAR(250),
	IsActive Int,
	Frequency VARCHAR(50),
	BronzePath VARCHAR(250),
	SilverPath VARCHAR(250),
	GoldPath VARCHAR(250),
	DWHSchemaName VARCHAR(50),
	DWHTableName VARCHAR(100),
	ErrorLogPath VARCHAR(250),
	LastLoadDateColumn VARCHAR(50),
	MergeKey VARCHAR(250),
	MergeKeyColumn VARCHAR(250),
	DependencyTableIDs VARCHAR(250),
	LastLoadDateValue TIMESTAMP,
	PipelineEndTime TIMESTAMP,
	PipelineRunStatus VARCHAR(20),
	Zone VARCHAR(20),
	SourceSelectQuery VARCHAR(8000),
	StageCreateQuery VARCHAR(8000),
	NotebookName VARCHAR(50))
	
	INSERT INTO metadata.mastermetadata
VALUES (
"DWH"	,
"Ky_Dev_DWH"	,
"A385"	,
"Finance"	,
Null	,
Null	,
Null	,
Null	,
1	,
Null	,
Null	,
Null	,
"/mnt/ingest/curated-data/acct/DimChartOfAccounts"	,
"acct"	,
"DimChartOfAccounts"	,
"log/DWH/acct/DimChartOfAccounts"	,
"ETLModifiedDate"	,
"code",
'"A375,A375"',	
Null	,
Null	,
Null	,
Null	,
"select ScheduledMaintenanceId,StartDateTime,EndDateTime,MaintenanceMessage,IsActive,CreatedDate,CreatedUser,ModifiedDate,ModifiedUser,SuppressSubmit from portfolio.ScheduledMaintenance"	
);
`

export const adfDdl = `CREATE TABLE [debtblue].[DIM_CONTACTS](
	[CONTACT_ID] [NUMERIC(38,0)] Not Null,
	[START_DATETIME] [DATETIME] Not Null,
	[END_DATETIME] [DATETIME] Null,
	[SOURCE_CONTACT_ID] [NUMERIC(38,0)] Not Null,
	[COMPANY_ID] [NUMERIC(38,0)] Null,
	[ASSIGNED_TO] [NUMERIC(38,0)] Null,
	[ACCT_ID] [NUMERIC(38,0)] Null,
	[CAMPAIGN_ID] [NUMERIC(38,0)] Null,
	[ATTORNEY] [NUMERIC(38,0)] Null,
	[ATTORNEY_ID] [NUMERIC(38,0)] Null,
	[ATTORNEY_ID2] [NUMERIC(38,0)] Null,
	[FIRST_NAME] [VARCHAR(512)] Null,
	[MIDDLE_NAME] [VARCHAR(512)] Null,
	[LAST_NAME] [VARCHAR(255)] Null,
	[ADDRESS] [VARCHAR(256)] Null,
	[ADDRESS2] [VARCHAR(255)] Null,
	[ADDRESS3] [VARCHAR(255)] Null,
	[TITLE] [VARCHAR(255)] Null,
	[CITY] [VARCHAR(256)] Null,
	[STATE] [VARCHAR(255)] Null,
	[ZIP] [VARCHAR(512)] Null,
	[COUNTRY] [VARCHAR(255)] Null,
	[PHONE] [VARCHAR(512)] Null,
	[FAX] [VARCHAR(255)] Null,
	[EMAIL] [VARCHAR(512)] Null,
	[UNSUBSCRIBE] [NUMERIC(38,0)] Null,
	[LAST_ACTIVITY] [DATETIME] Null,
	[SSN] [VARCHAR(256)] Null,
	[DOB] [DATE] Null,
	[ENROLLED] [NUMERIC(38,0)] Null,
	[ENROLLED_DATE] [DATE] Null,
	[ENROLLED_BY] [NUMERIC(38,0)] Null,
	[APPROVED] [VARCHAR(10)] Null,
	[APPROVED_BY] [NUMERIC(38,0)] Null,
	[APPROVED_DATE] [DATE] Null,
	[REJECTED] [VARCHAR(10)] Null,
	[REJECT_REASON] [NUMERIC(38,0)] Null,
	[DROPPED] [NUMBER(38,0)] Null,
	[DROPPED_DATE] [DATE] Null,
	[DROPPED_REASON] [NUMBER(38,0)] Null,
	[TOTAL_DEBT] [FLOAT] Null,
	[IS_DELETED] [VARCHAR(10)] Null,
	[ISCOAPP] [NUMERIC(38,0)] Null,
	[SOURCE_SYSTEM] [VARCHAR(256)] Null,
	[IS_SOURCE_DELETED] [VARCHAR(10)] Null,
	[SOURCE_SYNCED_DATE] [DATETIME] Null,
	[SOURCE_CREATED_DATE] [DATETIME] Null,
	[SOURCE_CREATED_BY] [NUMERIC(38,0)] Null,
	[SOURCE_MODIFIED_DATE] [DATETIME] Null,
	[SOURCE_MODIFIED_BY] [NUMERIC(38,0)] Null,
	[CREATED_DATE] [DATETIME] Not Null,
	[CREATED_BY] [VARCHAR(40)] Not Null,
	[MODIFIED_DATE] [DATETIME] Not Null,
	[MODIFIED_BY] [VARCHAR(40)] Not Null,
	CONSTRAINT PK_CONTACTS PRIMARY KEY (CONTACT_ID,START_DATETIME) );`

export const awsMetadata = `CREATE TABLE metadata.mastermetadata (
	SourceSystem string(255),
	SourceSecretName string(255),
	TableID INTEGER,
	SubjectArea VARCHAR(50),
	SourceDBName VARCHAR(50),
	SourceSchema VARCHAR(50),
	SourceTableName String(100),
	LoadType String(250),
	IsActive TINYINT,
	Frequency String(50),
	BronzePath String(250),
	SilverPath String(250),
	GoldPath String(250),
	DWHSchemaName String(50),
	DWHTableName String(100),
	ErrorLogPath String(250),
	LastLoadDateColumn String(50),
	MergeKey String(250),
	MergeKeyColumn String(250),
	DependencyTableIDs String(250),
	LastLoadDateValue TIMESTAMP,
	PipelineEndTime TIMESTAMP,
	PipelineRunStatus String(20),
	Zone String(20),
	SourceSelectQuery String(8000),
	StageCreateQuery String(8000),
	NotebookName String(50))
  
	INSERT INTO metadata.mastermetadata
VALUES (
"DWH"	,
"Ky_Dev_DWH"	,
"A385"	,
"Finance"	,
Null	,
Null	,
Null	,
Null	,
1	,
Null	,
Null	,
Null	,
"/mnt/ingest/curated-data/acct/DimChartOfAccounts"	,
"acct"	,
"DimChartOfAccounts"	,
"log/DWH/acct/DimChartOfAccounts"	,
"ETLModifiedDate"	,
"code",
'"A375,A375"',	
Null	,
Null	,
Null	,
Null	,
"select ScheduledMaintenanceId,StartDateTime,EndDateTime,MaintenanceMessage,IsActive,CreatedDate,CreatedUser,ModifiedDate,ModifiedUser,SuppressSubmit from portfolio.ScheduledMaintenance"	
);

	`

export const awsDdl = `"Create Table 'deft-clarity-267016.Trends_report.Dim_Client_Deezer'(
	DSP_Licensee_Name String(255) ,
	Service_Brand String(255) ,
	Licensor_Company_Name String(255) ,
	Transaction_Date Timestamp ,
	Start_Date Date ,
	End_Date Date ,
	Distribution_Channel String(255) ,
	Distribution_Channel_Description String(255) ,
	Commercial_Model_Type String(255) ,
	Product_Service_Tier String(255) ,
	Product_Service_Tier_Description String(255) ,
	Pricing_Tier String(255) ,
	Pricing_Tier_Description String(255) ,
	Sales_By_Territory String(255) ,
	Location String(255) ,
	Use_Type String(255) ,
	User_Interface_Type String(255) ,
	Transaction_Media_Type String(255) ,
	Transaction_Media_Proprietary_Id String(255) ,
	Transaction_Media_UPC String(255) ,
	Transaction_Media_Catalog_Number String(255) ,
	Transaction_Media_GRid String(255) ,
	Transaction_Media_ISRC String(255) ,
	Transaction_Media_ISWC String(255) ,
	Transaction_Media_Title String(255) ,
	Transaction_Media_Subtitle String(255) ,
	Transaction_Media_Artist_Name String(255) ,
	Transaction_Media_Label_Name String(255) ,
	Transaction_Media_Sublabel String(255) ,
	Related_Media_Type String(255) ,
	Related_Media_Proprietary_Id String(255) ,
	Related_Media_UPC String(255) ,
	Related_Media_Catalog_Number String(255) ,
	Related_Media_GRid String(255) ,
	Related_Media_ISRC String(255) ,
	Related_Media_Title String(255) ,
	Related_Media_Subtitle String(255) ,
	Related_Media_Artist_Name String(255) ,
	Related_Media_Label_Name String(255) ,
	Related_Media_Sublabel String(255) ,
	Commercial_Transactions Int64 ,
	Currency_Of_Transaction String(255) ,
	Currency_Of_Accounting String(255) ,
	Currency_Exchange_Rate String(255) ,
	Gross_Per_Unit_Revenue Float64 ,
	Gross_Revenue Float64 , ,
	Net_Per_Unit_Revenue Float64 ,
	Net_Revenue Float64 ,
	HashKey String(255) Not Null)"`


    
    
export const sfSilver = `--pl/sql procedure to load the data into Silver zone

CREATE OR REPLACE PROCEDURE SILVER.D_PHONE_SILVER_LOAD( )
RETURNS VARCHAR NOT NULL
LANGUAGE SQL   
AS 
$$
 
BEGIN

--  Loading data from Bronze table into Silver table 

--Execute immediate truncate          
TRUNCATE TABLE SILVER.D_PHONE ;

INSERT INTO SILVER.D_PHONE
(
  PHONE_ID,
  PHONE_NUMBER,
  PHONE_NUMBER_EXTENSION ,
  DNC_FLAG ,
  VALID_FORMAT_FLAG ,
  INSERT_DATETIME ,
  INSERTED_BY ,
  LAST_UPDATE,
  LAST_UPDATED_BY ,
  DNC_DATE ,
  PAYMENT_REMINDER_OPT_IN_FLAG ,
  WEBLEAD_OPT_IN_FLAG ,
  UNDELIVERABLE_FLAG ,
  TEXT_CONSENT ,
  MKT_SHORT_CODE_OPTIN,
  SOURCE_ID
)

SELECT  
  PHONE_ID,
  PHONE_NUMBER,
  PHONE_NUMBER_EXTENSION ,
  DO_NOT_CONTACT_FLAG ,
  VALID_FORMAT_FLAG ,
  INSERT_DATETIME ,
  INSERTED_BY ,
  LAST_UPDATE,
  LAST_UPDATED_BY ,
  DNC_DATE ,
  PAYMENT_REMINDER_OPT_IN_FLAG ,
  WEBLEAD_OPT_IN_FLAG ,
  UNDELIVERABLE_FLAG ,
  TEXT_CONSENT ,
  MKT_SHORT_CODE_OPTIN,
  SOURCE_ID
  
FROM BRONZE.D_PHONE;

 COMMIT;

RETURN 'Finished calling stored procedures'; 
   
END;
$$;

`

export const sfGold = `--pl/sql procedure to load the data into Gold zone
CREATE OR REPLACE PROCEDURE GOLD.DIM_PHONE_GOLD_LOAD(  )
RETURNS VARCHAR NOT NULL
LANGUAGE SQL   
AS 
$$
 
BEGIN

--  Loading data from Silver table into Gold table with transformation

--Execute immediate truncate          
TRUNCATE TABLE GOLD.DIM_PHONE_GOLD_LOAD ;

INSERT INTO GOLD.DIM_PHONE
(
  DW_PHONE_ID,
  START_DATETIME,
  END_DATETIME,
  PHONE_NUMBER,
  PHONE_EXTENSION,
  NOTE,
  VALID_PHONE_FLAG,
  DNC_PHONE_FLAG,
  TEXT_CONSENT,
  MKT_SHORT_CODE_OPTIN,
  DW_SOURCE_ID,
  SOURCE_SYSTEM_NAME,
  CREATED_DATE,
  CREATED_BY,
  LAST_MODIFIED_DATE,
  LAST_MODIFIED_BY
)

SELECT  
  PHONE_ID ,
  Current_Timestamp(),
  Current_Timestamp()-1,
  PHONE_NUMBER,
  PHONE_NUMBER_EXTENSION ,
  null -- Defauly Null value 
  VALID_PHONE_FLAG,
  DNC_PHONE_FLAG,
  TEXT_CONSENT,
  MKT_SHORT_CODE_OPTIN,
  SOURCE_ID,
  'MARKETING',
  Current_Timestamp(),
  'GolD_Zone_Load',
  Current_Timestamp(),
  'GolD_Zone_Load'
  
FROM SILVER.D_PHONE;

 COMMIT;

RETURN 'Finished calling stored procedures'; 
   
END;
$$;`



export const dateset = `{
	"name": "DS_DPG",
	"properties": {
		"linkedServiceName": {
			"referenceName": "Snowflake_MVnet",
			"type": "LinkedServiceReference",
			"parameters": {
				"DatabaseName": {
					"value": "@dataset().DatabaseName",
					"type": "Expression"
				}
			}
		},
		"parameters": {
			"DatabaseName": {
				"type": "string"
			},
			"TableName": {
				"type": "string"
			},
			"SchemaName": {
				"type": "string"
			}
		},
		"annotations": [],
		"type": "SnowflakeTable",
		"schema": [],
		"typeProperties": {
			"schema": {
				"value": "@dataset().SchemaName",
				"type": "Expression"
			},
			"table": {
				"value": "@dataset().TableName",
				"type": "Expression"
			}
		}
	},
	"type": "Microsoft.DataFactory/factories/datasets"
}`

export const dataflow = `{
	"name": "DF_Users_Group",
	"properties": {
		"folder": {
			"name": "Companies"
		},
		"type": "MappingDataFlow",
		"typeProperties": {
			"sources": [
				{
					"dataset": {
						"referenceName": "DS_DataRepository",
						"type": "DatasetReference"
					},
					"name": "SrcDpgUsersGroups"
				},
				{
					"dataset": {
						"referenceName": "DS_DataRepository",
						"type": "DatasetReference"
					},
					"name": "DimCompanies"
				},
				{
					"dataset": {
						"referenceName": "DS_DataRepository",
						"type": "DatasetReference"
					},
					"name": "LKPUsersGroup"
				}
			],
			"sinks": [
				{
					"dataset": {
						"referenceName": "DS_DataRepository",
						"type": "DatasetReference"
					},
					"name": "LKPUsersGroupTarget"
				},
				{
					"dataset": {
						"referenceName": "DS_DataRepository",
						"type": "DatasetReference"
					},
					"name": "LKPUserGroupTable"
				}
			],
			"transformations": [
				{
					"name": "SelectUsersGroups"
				},
				{
					"name": "SelectDimCompanies"
				},
				{
					"name": "SelectLKPUsersGroupColumns"
				},
				{
					"name": "LJDpgDimCompanies"
				},
				{
					"name": "SelectSourceColumns"
				},
				{
					"name": "LkTargetSource"
				},
				{
					"name": "PK"
				},
				{
					"name": "SelectForInsertColumns"
				},
				{
					"name": "SelectForUpdateColumns"
				},
				{
					"name": "Insert"
				},
				{
					"name": "Update"
				},
				{
					"name": "DeriveNewColumn"
				},
				{
					"name": "SelectRequiredColumns"
				},
				{
					"name": "DerivedColumns"
				},
				{
					"name": "DeriveColumn"
				}
			],
			"scriptLines": [
				"source(output(",
				"          ID as decimal(38,0),",
				"          ACCTID as decimal(38,0),",
				"          COMPANY_ID as decimal(38,0),",
				"          NAME as string,",
				"          {_FIVETRAN_DELETED} as boolean,",
				"          {_FIVETRAN_SYNCED} as timestamp",
				"     ),",
				"     allowSchemaDrift: true,",
				"     validateSchema: false,",
				"     isolationLevel: 'READ_UNCOMMITTED',",
				"     format: 'table') ~> SrcDpgUsersGroups",
				"source(output(",
				"          DW_COMPANY_ID as decimal(38,0),",
				"          SOURCE_COMPANY_ID as decimal(38,0),",
				"          ACCT_ID as decimal(38,0),",
				"          PARENT_ID as decimal(38,0),",
				"          SOURCE_COMPANY_TYPE_ID as decimal(38,0),",
				"          COMPANY_TYPE as string,",
				"          COMPANY_NAME as string,",
				"          CONTACT_NAME as string,",
				"          COMPANY_CODE as string,",
				"          ADDRESS as string,",
				"          ADDRESS2 as string,",
				"          CITY as string,",
				"          STATE as string,",
				"          ZIP as string,",
				"          PHONE as string,",
				"          PHONE2 as string,",
				"          FAX as string,",
				"          EMAIL as string,",
				"          TIMEZONE as string,",
				"          EIN as string,",
				"          ESIGN as string,",
				"          DOMAIN as string,",
				"          GCS_ACCOUNT_NUM as string,",
				"          GCS_POLICY_GROUP_ID as string,",
				"          ACCOUNT_NUM as string,",
				"          ROUTING_NUM as string,",
				"          BANK_NAME as string,",
				"          ACTIVE as string,",
				"          THEME as decimal(38,0),",
				"          LOGO_URL as string,",
				"          ADDITIONAL as string,",
				"          ACCOUNT_EXEC as decimal(38,0),",
				"          DOCUSIGN_ACCT as string,",
				"          DOCUSIGN_USER as string,",
				"          DOCUSIGN_PASS as string,",
				"          ENROLL_CHARGE as decimal(11,2),",
				"          CHARGE_PAYEE as decimal(38,0),",
				"          SOURCE_SYSTEM as string,",
				"          IS_SOURCE_DELETED as string,",
				"          SOURCE_SYNCED_DATE as timestamp,",
				"          SOURCE_CREATED_DATE as timestamp,",
				"          SOURCE_CREATED_BY as string,",
				"          CREATED_DATE as timestamp,",
				"          CREATED_BY as string,",
				"          MODIFIED_DATE as timestamp,",
				"          MODIFIED_BY as string",
				"     ),",
				"     allowSchemaDrift: true,",
				"     validateSchema: false,",
				"     isolationLevel: 'READ_UNCOMMITTED',",
				"     format: 'table') ~> DimCompanies",
				"source(output(",
				"          DW_USER_GROUP_ID as decimal(38,0),",
				"          SOURCE_USER_GROUP_ID as decimal(38,0),",
				"          ACCT_ID as decimal(38,0),",
				"          DW_COMPANY_ID as decimal(38,0),",
				"          SOURCE_COMPANY_ID as decimal(38,0),",
				"          {GROUP_NAME } as string,",
				"          SOURCE_SYSTEM as string,",
				"          IS_SOURCE_DELETED as boolean,",
				"          SOURCE_SYNCED_DATE as timestamp,",
				"          CREATED_DATE as timestamp,",
				"          CREATED_BY as string,",
				"          MODIFIED_DATE as timestamp,",
				"          MODIFIED_BY as string",
				"     ),",
				"     allowSchemaDrift: true,",
				"     validateSchema: false,",
				"     isolationLevel: 'READ_UNCOMMITTED',",
				"     format: 'table') ~> LKPUsersGroup",
				"SrcDpgUsersGroups select(mapColumn(",
				"          ID,",
				"          ACCTID,",
				"          COMPANY_ID,",
				"          NAME,",
				"          {_FIVETRAN_DELETED},",
				"          {_FIVETRAN_SYNCED}",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectUsersGroups",
				"DimCompanies select(mapColumn(",
				"          DW_COMPANY_ID,",
				"          SOURCE_COMPANY_ID",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectDimCompanies",
				"LKPUsersGroup select(mapColumn(",
				"          TARGET_SOURCE_USER_GROUP_ID = SOURCE_USER_GROUP_ID",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectLKPUsersGroupColumns",
				"SelectRequiredColumns, SelectDimCompanies join(COMPANY_ID == SOURCE_COMPANY_ID,",
				"     joinType:'left',",
				"     matchType:'exact',",
				"     ignoreSpaces: false,",
				"     broadcast: 'auto')~> LJDpgDimCompanies",
				"LJDpgDimCompanies select(mapColumn(",
				"          SOURCE_USER_GROUP_ID = ID,",
				"          ACCT_ID = ACCTID,",
				"          SOURCE_COMPANY_ID = COMPANY_ID,",
				"          GROUP_NAME = NAME,",
				"          IS_SOURCE_DELETED = {_FIVETRAN_DELETED},",
				"          SOURCE_SYNCED_DATE = {_FIVETRAN_SYNCED},",
				"          DW_COMPANY_ID,",
				"          SOURCE_SYSTEM",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectSourceColumns",
				"SelectSourceColumns, SelectLKPUsersGroupColumns lookup(SOURCE_USER_GROUP_ID == TARGET_SOURCE_USER_GROUP_ID,",
				"     multiple: false,",
				"     pickup: 'any',",
				"     broadcast: 'auto')~> LkTargetSource",
				"LkTargetSource split(isNull(TARGET_SOURCE_USER_GROUP_ID),",
				"     disjoint: false) ~> PK@(PKNotMatches, PKMatches)",
				"PK@PKNotMatches select(mapColumn(",
				"          SOURCE_USER_GROUP_ID,",
				"          ACCT_ID,",
				"          SOURCE_COMPANY_ID,",
				"          GROUP_NAME,",
				"          IS_SOURCE_DELETED,",
				"          SOURCE_SYNCED_DATE,",
				"          DW_COMPANY_ID,",
				"          SOURCE_SYSTEM",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectForInsertColumns",
				"PK@PKMatches select(mapColumn(",
				"          SOURCE_USER_GROUP_ID,",
				"          ACCT_ID,",
				"          SOURCE_COMPANY_ID,",
				"          GROUP_NAME,",
				"          IS_SOURCE_DELETED,",
				"          SOURCE_SYNCED_DATE,",
				"          DW_COMPANY_ID,",
				"          SOURCE_SYSTEM",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectForUpdateColumns",
				"DerivedColumns alterRow(insertIf(true())) ~> Insert",
				"DeriveColumn alterRow(updateIf(true())) ~> Update",
				"SelectUsersGroups derive(SOURCE_SYSTEM = 'Dpg') ~> DeriveNewColumn",
				"DeriveNewColumn select(mapColumn(",
				"          ID,",
				"          ACCTID,",
				"          COMPANY_ID,",
				"          NAME,",
				"          {_FIVETRAN_DELETED},",
				"          {_FIVETRAN_SYNCED},",
				"          SOURCE_SYSTEM",
				"     ),",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true) ~> SelectRequiredColumns",
				"SelectForInsertColumns derive(CREATED_DATE = fromUTC(currentUTC(),'America/Chicago'),",
				"          CREATED_BY = 'Svc_debtblue_dataload',",
				"          MODIFIED_DATE = fromUTC(currentUTC(),'America/Chicago'),",
				"          MODIFIED_BY = 'Svc_debtblue_dataload') ~> DerivedColumns",
				"SelectForUpdateColumns derive(MODIFIED_DATE = fromUTC(currentUTC(),'America/Chicago'),",
				"          MODIFIED_BY = 'Svc_debtblue_dataload') ~> DeriveColumn",
				"Insert sink(allowSchemaDrift: true,",
				"     validateSchema: false,",
				"     deletable:false,",
				"     insertable:true,",
				"     updateable:false,",
				"     upsertable:false,",
				"     format: 'table',",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true,",
				"     errorHandlingOption: 'stopOnFirstError',",
				"     mapColumn(",
				"          SOURCE_USER_GROUP_ID,",
				"          ACCT_ID,",
				"          SOURCE_COMPANY_ID,",
				"          GROUP_NAME,",
				"          IS_SOURCE_DELETED,",
				"          SOURCE_SYNCED_DATE,",
				"          DW_COMPANY_ID,",
				"          SOURCE_SYSTEM,",
				"          CREATED_DATE,",
				"          CREATED_BY,",
				"          MODIFIED_DATE,",
				"          MODIFIED_BY",
				"     )) ~> LKPUsersGroupTarget",
				"Update sink(allowSchemaDrift: true,",
				"     validateSchema: false,",
				"     deletable:false,",
				"     insertable:false,",
				"     updateable:true,",
				"     upsertable:false,",
				"     keys:['SOURCE_USER_GROUP_ID'],",
				"     format: 'table',",
				"     skipDuplicateMapInputs: true,",
				"     skipDuplicateMapOutputs: true,",
				"     errorHandlingOption: 'stopOnFirstError',",
				"     mapColumn(",
				"          SOURCE_USER_GROUP_ID,",
				"          ACCT_ID,",
				"          SOURCE_COMPANY_ID,",
				"          GROUP_NAME,",
				"          IS_SOURCE_DELETED,",
				"          SOURCE_SYNCED_DATE,",
				"          DW_COMPANY_ID,",
				"          SOURCE_SYSTEM,",
				"          MODIFIED_DATE,",
				"          MODIFIED_BY",
				"     )) ~> LKPUserGroupTable"
			]
		}
	}
}`

export const orchestration = `{
	"name": "Master_DPG_Extraction_Report_Tables",
	"properties": {
		"activities": [
			{
				"name": "DPG_Extraction_Full_Report_Tables",
				"type": "ExecutePipeline",
				"dependsOn": [
					{
						"activity": "DPG_Extraction_Incr_Report_Tables",
						"dependencyConditions": [
							"Completed"
						]
					}
				],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "DPG_Extraction_Dataflow_Full_Load_Report_Tables",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true
				}
			},
			{
				"name": "DPG_Extraction_Incr_Report_Tables",
				"type": "ExecutePipeline",
				"dependsOn": [],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "DPG_Extraction_Dataflow_Incremental_Report_Tables",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true
				}
			},
			{
				"name": "Execute_Deletion_Handle",
				"type": "ExecutePipeline",
				"dependsOn": [
					{
						"activity": "DPG_Extraction_Full_Report_Tables",
						"dependencyConditions": [
							"Succeeded"
						]
					}
				],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "Deletion_Handle_DebtBlue",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true
				}
			},
			{
				"name": "Execute_Dependency_Update",
				"type": "ExecutePipeline",
				"dependsOn": [
					{
						"activity": "Execute_Deletion_Handle",
						"dependencyConditions": [
							"Completed"
						]
					}
				],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "Extraction_Dependency_Update_Metadata",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true
				}
			},
			{
				"name": "Extraction_Success_Email_Alert",
				"type": "ExecutePipeline",
				"dependsOn": [
					{
						"activity": "Execute_Dependency_Update",
						"dependencyConditions": [
							"Succeeded"
						]
					}
				],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "Logic_App_Email_Component",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true,
					"parameters": {
						"PipelineName": {
							"value": "@pipeline().Pipeline",
							"type": "Expression"
						},
						"PipelineID": {
							"value": "@pipeline().RunId",
							"type": "Expression"
						},
						"PipelineStatus": "Success"
					}
				}
			},
			{
				"name": "Extraction_Failure_Email_Alert",
				"type": "ExecutePipeline",
				"dependsOn": [
					{
						"activity": "Execute_Dependency_Update",
						"dependencyConditions": [
							"Failed"
						]
					}
				],
				"userProperties": [],
				"typeProperties": {
					"pipeline": {
						"referenceName": "Logic_App_Email_Component",
						"type": "PipelineReference"
					},
					"waitOnCompletion": true,
					"parameters": {
						"PipelineName": {
							"value": "@pipeline().Pipeline",
							"type": "Expression"
						},
						"PipelineID": {
							"value": "@pipeline().RunId",
							"type": "Expression"
						},
						"PipelineStatus": "Failed"
					}
				}
			}
		],
		"folder": {
			"name": "Extraction"
		},
		"annotations": [],
		"lastPublishTime": "2023-10-11T17:21:52Z"
	},
	"type": "Microsoft.DataFactory/factories/pipelines"
}`




export const syBronze = ``

export const sySilver = `#!/usr/bin/env python
# coding: utf-8

# ## delivery_s4_productuoms_Silver_NB
# 
# 
# 

# ## delivery_s4_productuoms_NB
# 
# 
# 

# ### delivery_s4_productuoms_NB
# ----

# **From Certified Storage Location -> Curated Files**

#     Modifications
#     20231221 Dharshini     initial create
# 

# ##### References

# In[74]:


pSource_name = 'saps4hana'


# In[75]:


from pyspark.sql.functions import col, desc, row_number, lit, collect_list, upper, when, regexp_replace, concat, current_date, max, regexp_extract,concat_ws,md5
from pyspark.sql import types as T
from pyspark.sql.window import Window
from datetime import datetime
from pyspark.sql.functions import col, to_date
from pyspark.sql.functions import col, substring

from delta.tables import DeltaTable


# In[76]:


get_ipython().run_line_magic('run', 'zz-Common/Classes and Functions/common_data_functions_NB')


# In[77]:


get_ipython().run_line_magic('run', 'zz-Common/Classes and Functions/common_data_cleansing_NB')


# ##### Variables / Constants

# In[78]:


return_msg = []


# In[79]:


cNotebook_name = "delivery_s4_productuoms_NB"
cTarget_table_name = "RDP_ProductUOMs"
cSrc_join_fields = "ProductID,UOMFrom,UOMTo,MetricUOMFlag,ImperialFlag"
cTgt_join_fields = "ProductID,UOMFrom,UOMTo,MetricUOMFlag,ImperialFlag"


# ##### Functions

# ###### save_data

# In[80]:


def save_data(target_table_name, source_system, cmn_class, new_df, src_join_fields = None, tgt_join_fields = None, partition_field = None):
    try:
        print(source_system)

        wmode = common_write_modes.MERGE.name

        write_opts = cmn_class.build_write_options( overwrite_schema_yn = "N", \
                                                    save_table_nm = target_table_name, \
                                                    vacuum_days = 720, \
                                                    partition_by = partition_field, \
                                                    join_fields_source = src_join_fields, \
                                                    join_fields_target = tgt_join_fields )
        
        dupe_chk = new_df.groupby(new_df.columns).count().where("count > 1")

        if dupe_chk.isEmpty() == False:
            new_df = new_df.distinct()
        
        cmn_class.write_data(write_mode= wmode, \
                            source_system= source_system, \
                            target_table_name= "Silver.Productoms", \
                            new_data_df = new_df, \
                            storage_location = None, \
                            write_options = write_opts)    

    except Exception as err:
        emsg = "%s.save_data Error %s" % (cNotebook_name,err)
        return_msg.append(("Error", emsg))

`

export const syGold = `#!/usr/bin/env python
# coding: utf-8

# ## delivery_s4_productuoms_Gold_NB
# 
# 
# 

# ## delivery_s4_productuoms_NB
# 
# 
# 

# ### delivery_s4_productuoms_NB
# ----

# **From Certified Storage Location -> Curated Files**

#     Modifications
#     20231221 Dharshini     initial create
# 

# ##### References

# In[74]:


pSource_name = 'saps4hana'


# In[75]:


from pyspark.sql.functions import col, desc, row_number, lit, collect_list, upper, when, regexp_replace, concat, current_date, max, regexp_extract,concat_ws,md5
from pyspark.sql import types as T
from pyspark.sql.window import Window
from datetime import datetime
from pyspark.sql.functions import col, to_date
from pyspark.sql.functions import col, substring

from delta.tables import DeltaTable


# In[76]:


get_ipython().run_line_magic('run', 'zz-Common/Classes and Functions/common_data_functions_NB')


# In[77]:


get_ipython().run_line_magic('run', 'zz-Common/Classes and Functions/common_data_cleansing_NB')


# ##### Variables / Constants

# In[78]:


return_msg = []


# In[79]:


cNotebook_name = "delivery_s4_productuoms_NB"
cTarget_table_name = "RDP_ProductUOMs"
cSrc_join_fields = "ProductID,UOMFrom,UOMTo,MetricUOMFlag,ImperialFlag"
cTgt_join_fields = "ProductID,UOMFrom,UOMTo,MetricUOMFlag,ImperialFlag"


# ##### Functions

# ###### save_data

# In[80]:


def save_data(target_table_name, source_system, cmn_class, new_df, src_join_fields = None, tgt_join_fields = None, partition_field = None):
    try:
        print(source_system)

        wmode = common_write_modes.MERGE.name

        write_opts = cmn_class.build_write_options( overwrite_schema_yn = "N", \
                                                    save_table_nm = target_table_name, \
                                                    vacuum_days = 720, \
                                                    partition_by = partition_field, \
                                                    join_fields_source = src_join_fields, \
                                                    join_fields_target = tgt_join_fields )
        
        dupe_chk = new_df.groupby(new_df.columns).count().where("count > 1")

        if dupe_chk.isEmpty() == False:
            new_df = new_df.distinct()
        
        cmn_class.write_data(write_mode= wmode, \
                            source_system= source_system, \
                            target_table_name= target_table_name, \
                            new_data_df = new_df, \
                            storage_location = None, \
                            write_options = write_opts)    

    except Exception as err:
        emsg = "%s.save_data Error %s" % (cNotebook_name,err)
        return_msg.append(("Error", emsg))


# ###### replace_in_columns

# In[81]:


def replace_in_columns(val_to_replace, replace_with_val, data_df, column_list=[]):
    try:
        tmpdf = data_df

        for c in column_list:
            tmpdf = tmpdf.withColumn(c, regexp_replace(col(c),val_to_replace,replace_with_val))

        return tmpdf
    except Exception as err:
        emsg = "%s.replace_in_columns Error %s" % (cNotebook_name,err)
        return_msg.append(("Error", emsg))


# ###### get_saps4hana_data

# In[82]:


def get_saps4hana_data(): 
    try:    
        src_nm = "S4 Hana"

        cdf = common_data_functions(data_stage_from= common_data_stages.CERTIFIED.name,data_stage_to= common_data_stages.CURATED.name)

        #--Get S4 Hana Source Data
        # --- MARA
        path = "MARA/"
        mara_df = cdf.read_data(source_system=src_nm, table_name=path)

        # --- MARM
        path ="MARM/"
        marm_df = cdf.read_data(source_system=src_nm, table_name=path)

        # --- MARA and MARM
        joined_df = marm_df.alias("A").join(mara_df.alias("B"),(col("A.MATNR") == col("B.MATNR")) & (col("A.MANDT") == col("B.MANDT")),'left')

        # Creating the 'result_df' DataFrame with additional columns
        result_df = joined_df.withColumn("ConversionRate", col("A.UMREZ")/col("A.UMREN")
                             ).withColumn("MetricUOMFlag", when((col("A.MEINH") == 'M2')|(col("A.MEINH") == 'KG'),'Y').otherwise('N')
                             ).withColumn("ImperialFlag", when((col("A.MEINH") == 'FT2') | (col("A.MEINH") == 'G'),'Y').otherwise('N')
                             ).withColumn("BusinessProductID", md5(col("A.MATNR").cast("string"))
                             ).withColumn("UOMTo",col("B.MEINS")
                             ).withColumn("UOMFrom",col("A.MEINH")
                             ).withColumn("ProductID",col("A.MATNR")
                             ).withColumn("UpdateDate", lit(datetime.now()))


        result_df = cdf.get_targetDF_metadataFormat(cTarget_table_name, src_nm, result_df, [])

        # set the data types based on metadata settings
        cdc = common_data_cleansing()
        result_df = cdc.set_datatypes_from_metadata(cTarget_table_name, result_df)
        
        # Save the resulting DataFrame
        save_data(target_table_name=cTarget_table_name,
                source_system=src_nm,
                cmn_class=cdf,
                new_df=result_df,
                src_join_fields=cSrc_join_fields,
                tgt_join_fields=cTgt_join_fields)
        
    except Exception as err:
        emsg = "%s.get_saps4hana_data Error %s" % (cNotebook_name,err)
        return_msg.append(("Error", emsg))
    finally:
        del cdf







# In[83]:


try:
    if pSource_name != None:
        psn = pSource_name.upper().replace(' ','')
    else:
        psn = None

    if psn in ["SAPS4HANA", None]:
        get_saps4hana_data()

    return_msg.append(("Success","")) 
    
except Exception as err:
    emsg = "%s.Main Error %s" % (cNotebook_name,err)
    return_msg.append(("Error", emsg))
    
finally:
    mssparkutils.notebook.exit(return_msg)

`

export const syUtilities = `#!/usr/bin/env python
# coding: utf-8

# ## Synapse_Utility
# 
# 
# 

# ## common_data_functions_NB
# 
# 
# 

# ### common_data_functions
# -----

#     Modifications:
#     20230901 pburke     initial create
#     20231101 pburke     updated to include target system input to accommodate new delivery folder structure of delivery-data/delta/rdp.db or ibp.db/
#     20231130 pburke     enhanced read_data function to allow for Peoplesoft setid = Roger filter to be bypassed
#     20231226 pburke     updated "get_targetDF_metadataFormat" to handle fixed values of type char()

# ##### How to use
# 
#     Example:
#         %run common/common_data_functions_NB
# 
#         cdf = common_data_functions("CERTIFIED","CURATED","rdp")
# 
#         source_df = cdf.read_data("People Soft",'abfss://raw-data@microtalkdatasessionsdfs.dfs.core.windows.net/source_data/Town_State.parquet')
#         display(source_df)
# 
#         new_data_df = ....
# 
#         wmode = common_write_modes.OVERWRITE.name
#         cdf.write_data(wmode, '/stage_data/table1', new_data_df)
# 

# In[1]:


from enum import Enum
from pyspark.sql.functions import col, collect_list, length
from pyspark.sql.types import StructField, StructType, StringType
from delta import DeltaTable


# In[ ]:


sources_dictionary = { "People Soft": 0,"AX - DeWal": 11,"MAX - Griswold": 14,"SAP - Germany": 1}


# In[ ]:


class common_data_format(Enum):
    PARQUET = 1
    DELTA = 2
    CSV = 3
    JSON = 4
    TXT = 5

class common_write_modes(Enum):
    OVERWRITE = 1
    APPEND = 2
    MERGE = 3
    UPDATE = 4

class common_write_options(Enum):
    OVERWRITE_SCHEMA = 1
    SOURCE_JOIN_FIELDS = 2
    TARGET_JOIN_FIELDS = 3
    VACUUM = 4
    PARTION_BY = 5
    SAVE_AS_TABLE = 6

class common_read_options(Enum):
    HEADER = 1
    INFER_SCHEMA = 2

class common_data_stages(Enum):
    RAW = 1
    CERTIFIED = 2
    CURATED = 3
    DELIVERY = 4

class common_data_functions():
    
    _src_fmt = "PARQUET"
    _tgt_fmt = "DELTA"
    _tgt_sys = "rdp.db/rdp/"

    _df = None
    _paths_from_df = None
    _paths_to_df = None
    _paths_reference_df = None


    # ----------
    def __init__(self, data_stage_from, data_stage_to, target_system = 'rdp'):
        try:
            # for backwards compatibility for dates
            spark.conf.set("spark.sql.legacy.parquet.int96RebaseModeInWrite","LEGACY")

            df = spark.sql("Select * From metadata.common_path_map")

            self._paths_from_df = df.filter(f"data_stage = '{data_stage_from}'")
            self._paths_to_df = df.filter(f"data_stage = '{data_stage_to}'")
            self._paths_reference_df = df.filter(f"source_name = 'reference'")
            
            self._src_fmt = self._paths_from_df.select("file_type").collect()[0][0]
            self._tgt_fmt = self._paths_to_df.select("file_type").collect()[0][0]

            self._tgt_sys = target_system.lower() + ".db/" + target_system.lower() + "/"

        except Exception as err:
            err_msg = "data_functions.__init__ Error:  %s" % (err)
            raise Exception(err_msg)
    
    # ----------
    def help(self):
        try:

            funcs = dict()

            funcs["get_metadata"] = "(target_table_name, source_name = None) -> return DataFrame"
            funcs["get_targetDF_metadataFormat"] = "(target_table_name, source_name, dataDF, exclude_col_list = []) -> return DataFrame"
            funcs["build_write_options"] = "(overwrite_schema_yn = '',save_table_nm = '',vacuum_days = 0,partition_by = None,join_fields_source = None,join_fields_target = None) -> return Set"
            funcs["read_data"] = "(source_system, table_name, filter_ps_setid_yn= 'Y', storage_location = None, options = {}) -> return DataFrame"
            funcs["write_data"] = "(write_mode, source_system, target_table_name, new_data_df, storage_location = None, write_options = {}) -> return None"
            funcs["vacuum"] = "(days, storage_location) -> return None"
            funcs["get_file_exists"] = "(storage_location) -> return Boolean"

            for x in funcs:
                print(x, funcs.get(x))
                print('')

        except:
            raise 
    # ----------
    def get_metadata(self, target_table_name, source_name = None):
        try:
            
            cmd = f"SELECT * FROM metadata.common_field_map WHERE target_table_name = '{target_table_name}'"
            df = spark.sql(cmd)

            if source_name != None:
                df = df.filter(f"source_name == '{source_name}'") 
           

            return df

        except Exception as err:
            err_msg = "data_functions.get_metadata Error:  %s" % (err)
            raise Exception(err_msg)
        
    # ----------
    def get_targetDF_metadataFormat(self,target_table_name, source_name, dataDF, exclude_col_list =[]):
        try:
            meta_df = self.get_metadata(target_table_name, source_name)

            if meta_df.head(1) != None:

                cols = meta_df.select("source_column_name","target_column_name","fixed_value", "column_type", "column_size","column_precision") 

                # select the mapped source columns
                srccols = meta_df.filter(f"source_column_name NOT IN ('')") \
                                .select(collect_list("source_column_name")).collect()[0][0]
                
                if len(exclude_col_list) > 0:
                    srccols = list(set(srccols) - set(exclude_col_list))

                # select source columns found in metadata from input DF data
                tmp_out = dataDF.select(srccols)

                # create the fixed value fields
                for fx in cols.filter("fixed_value != ''").select("target_column_name","fixed_value", "column_type").collect():
                    if fx.column_type == "varchar":
                        typ = "string"
                    elif fx.column_type == "nvarchar":
                        typ = "string"
                    else:
                        typ = fx.column_type

                    if typ == "char":
                        ln = str(fx.fixed_value).__len__()
                        tmp_out = tmp_out.withColumn(fx.target_column_name, lit(fx.fixed_value).cast(f'char({ln})'))
                    else:
                        tmp_out = tmp_out.withColumn(fx.target_column_name, lit(fx.fixed_value).cast(typ))

                
                # rename source fields to target field names
                for t in cols.select("source_column_name", "target_column_name").collect():
                    tmp_out = tmp_out.withColumnRenamed(t.source_column_name, t.target_column_name)

                # add in configured target fields that don't exist
                for tf in cols.select("target_column_name").collect():
                    if tf.target_column_name not in tmp_out.columns:
                        tmp_out = tmp_out.withColumn(tf.target_column_name, lit(None).cast("string"))

            return tmp_out

        except Exception as err:
            err_msg = "data_functions.get_targetDF_metadataFormat Error:  %s" % (err)
            raise Exception(err_msg)

    # ----------
    def build_write_options(self, overwrite_schema_yn = "",
       save_table_nm = "", 
       vacuum_days = 0, 
       partition_by = None,
       join_fields_source = None,
       join_fields_target = None):

        try:
            write_options = {}

            if overwrite_schema_yn == "Y":
                write_options[common_write_options.OVERWRITE_SCHEMA.name] = "Y"
            
            if save_table_nm != None:
                write_options[common_write_options.SAVE_AS_TABLE.name] = save_table_nm

            if vacuum_days != None:
                if vacuum_days > 0:
                    write_options[common_write_options.VACUUM.name] = vacuum_days
            
            if partition_by != None and str(partition_by).isspace() == False:
                write_options[common_write_options.PARTION_BY.name] = partition_by
            
            if join_fields_source != None:
                if len(join_fields_source) > 0:
                    write_options[common_write_options.SOURCE_JOIN_FIELDS.name] = join_fields_source
            
            if join_fields_target != None:
                if len(join_fields_target) > 0:
                    write_options[common_write_options.TARGET_JOIN_FIELDS.name] = join_fields_target

            return write_options
        except Exception as err:
            err_msg = "common_data_functions.build_write_options() Error: %s" % (err)
            raise Exception(err_msg)

    # ----------
    def read_data(self, source_system, table_name, filter_ps_setid_yn= 'Y', storage_location = None, options = {}):
        try:

            if source_system == 'reference':
                pth = self._paths_reference_df.collect()[0]
                file_typ = pth["file_type"]

                if str(pth["base_path"]).endswith("/"):
                    pth_dir = pth["directory"]
                else:
                    pth_dir = "/" + pth["directory"]

                if str(pth_dir).endswith("/"):
                    None
                else:
                    table_name = "/" + table_name

                storage_location = pth["base_path"] + pth_dir + table_name

                data = spark.read.format(file_typ).load(storage_location)

            else:
                if storage_location == None:
                    pth = self._paths_from_df.filter(f"upper(source_name) = '{source_system.upper()}'").collect()[0]
                    
                    if str(pth["base_path"]).endswith("/"):
                        pth_dir = pth["directory"]
                    else:
                        pth_dir = "/" + pth["directory"]

                    if str(pth_dir).endswith("/"):
                        None
                    else:
                        table_name = "/" + table_name

                    storage_location = pth["base_path"] + pth_dir + table_name

                if self._src_fmt == "PARQUET":
                    data = spark.read.option("inferSchema","true").parquet(storage_location)
                elif self._src_fmt == "JSON":
                    data = spark.read.json(storage_location)
                elif self._src_fmt == "CSV":
                    if options.get(common_read_options.HEADER.name).upper() == "Y":
                        hdr_option = True
                    else:
                        hdr_option = False
                            
                    data = spark.read.csv(storage_location,header=hdr_option)
                else:
                    data = spark.read.format(self._src_fmt).load(storage_location)

                if source_system != None:
                    source_system = source_system.upper()

                    if source_system == "PEOPLE SOFT" and filter_ps_setid_yn == 'Y':
                        if "SETID" in data.columns:
                            data = data.filter("SETID = 'ROGER'")

            return data 
        except Exception as err:
            err_msg = "common_data_functions_NB.read_data() Error:  %s" % (err)
            raise Exception(err_msg)
    
    # ----------
    def write_data(self, write_mode, source_system, target_table_name, new_data_df, storage_location = None, write_options = {}):
        try:
            self._df = new_data_df

            wmode_uc = write_mode.upper()
            pth = self._paths_to_df.filter(f"upper(source_name) = '{source_system.upper()}'").collect()[0]
            dbname = pth["lake_database_name"]

            
            if pth["directory"] == None:
                pth_dir = self._tgt_sys
            elif pth["directory"] == "":
                pth_dir = self._tgt_sys
            else:
                pth_dir = pth["directory"]

            if storage_location == None:                
                if str(pth["base_path"]).endswith("/"):
                    None
                else:
                    pth_dir = "/" + pth_dir

                if str(pth_dir).endswith("/"):
                    None
                else:
                    target_table_name = "/" + target_table_name

                storage_location = pth["base_path"] + pth_dir + target_table_name

            if wmode_uc == "OVERWRITE":
                self.__overwrite_data(storage_location, dbname, write_options)
            elif wmode_uc == "MERGE":
                self.__merge_data(storage_location, dbname, write_options)
            elif wmode_uc == "APPEND":
                self.__append_data(storage_location)

            vacdays = write_options.get(common_write_options.VACUUM.name)

            if vacdays != None:
                self.vacuum(vacdays, storage_location)

        except Exception as err:
            err_msg = "common_data_functions.write_data() Error:  %s" % (err)
            raise Exception(err_msg)

    # ----------
    def __overwrite_data(self, storage_location, db_name, options = {}):
        try:
            save_format = self._tgt_fmt
            
            # Look for possible write options
            #      Overwrite Schema
            #      Partition By
            #      Save as Table
            opt = options.get(common_write_options.OVERWRITE_SCHEMA.name)
            if opt != None:
                overwriteSchema_option = "true"
            else:
                overwriteSchema_option = "false"

            partition_field = options.get(common_write_options.PARTION_BY.name)

            if partition_field not in self._df.columns:
                    partition_field = None
                    
            table_name = options.get(common_write_options.SAVE_AS_TABLE.name)

            if table_name != None:
                if str(table_name).find(".") == -1:
                    table_name = db_name + "." + table_name

            # 

            if partition_field != None:
                self._df.write \
                    .format(save_format) \
                    .partitionBy(partition_field) \
                    .mode("overwrite") \
                    .option("overwriteSchema", overwriteSchema_option) \
                    .save(storage_location)
            else:
                self._df.write \
                    .format(save_format) \
                    .mode("overwrite") \
                    .option("overwriteSchema", overwriteSchema_option) \
                    .save(storage_location)
            
            if table_name != None:
                spark.sql(f"CREATE DATABASE IF NOT EXISTS {db_name}")
                spark.sql(f"CREATE TABLE IF NOT EXISTS {table_name} USING {save_format} LOCATION '{storage_location}' ")

        except Exception as err:
            err_msg = "cannot write_file_from_storage location Error:  %s" % (err)
            raise Exception(err_msg)
    
    # ----------
    def __append_data(self, storage_location, options = {}):
        try:
            save_format = _tgt_fmt.value

            new_data_df.write \
                .format("delta") \
                .partitionBy(partition_field) \
                .mode("append") \
                .option("overwriteSchema", "true") \
                .save(storage_location)
            
        except Exception as err:
            err_msg = "cannot write_file_from_storage location Error:  %s" % (err)
            raise Exception(err_msg)
    
    # ----------
    def __merge_data(self, storage_location, db_name, write_options):
        try:

            if self.get_file_exists(storage_location):
                source_join_fields = write_options.get(common_write_options.SOURCE_JOIN_FIELDS.name)
                target_join_fields = write_options.get(common_write_options.TARGET_JOIN_FIELDS.name)

                table_name = write_options.get(common_write_options.SAVE_AS_TABLE.name)

                if table_name != None:
                    if str(table_name).find(".") == -1:
                        table_name = db_name + "." + table_name

                source_join_fields = source_join_fields.split(",")
                target_join_fields = target_join_fields.split(",")
                join_cmd = ""

                for t, s in zip(target_join_fields, source_join_fields):
                    if join_cmd == "":
                        join_cmd = f"dest.{t} = source.{s}"
                    else:
                        join_cmd += f" and dest.{t} = source.{s}"

               
                # create column association 
                # e.g. { "source.column1" : "dest.column1", 
                #        "source.column2" : "dest.column2" }
                
                src_cols = []
                tgt_cols = []
                
                for c in self._df.columns:
                    snm = f"source.{c}"
                    tnm = f"dest.{c}"

                    if snm not in (src_cols):
                        src_cols.append(snm)
                    
                    if tnm not in (tgt_cols):
                        tgt_cols.append(tnm)

                col_set = dict(zip(tgt_cols, src_cols))

     
                deltaTable = DeltaTable.forPath(spark, storage_location)
                deltaTable.alias('dest') \
                    .merge(self._df.alias('source'), join_cmd) \
                    .whenMatchedUpdate( set = col_set) \
                    .whenNotMatchedInsert( values = col_set) \
                    .execute()

                if table_name != None:
                    spark.sql(f"CREATE DATABASE IF NOT EXISTS {db_name}")
                    spark.sql(f"CREATE TABLE IF NOT EXISTS {table_name} USING DELTA LOCATION '{storage_location}' ")

            else:
                self.__overwrite_data(storage_location, db_name, write_options)

        except Exception as err:
            err_msg = "cannot update_file_from_storage location Error:  %s" % (err)
            raise Exception(err_msg)
    
    # ----------
    def vacuum(self, days, storage_location):
        deltaTable = DeltaTable.forPath(spark, storage_location)
        deltaTable.vacuum(days) # vacuum files not required by versions more than 30 Days = 720 hrsfrom delta.tables import *
    
    # ----------
    def get_file_exists(self, storage_location):
        try:
            bFound = True
            tmp = mssparkutils.fs.ls(storage_location)
            
        except Exception as err:
            bFound = False
        finally:
            return bFound

`

    
export const awsUtilities = ``


//Design phase sample pseudocode data
export const dataDictionary = [
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "DW_Customer_ID",
        "DataType": "BIG INT",
        "Constraints": "PRIMARY KEY, NOT NULL",
        "Description": "Unique identifier for each record."

    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "Customer_ID",
        "DataType": "VARCHAR(255)",
        "Constraints": "UNIQUE, NOT NULL",
        "Description": "Identifies a specific record related to the customer, such as an account or service record."
    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "Customer_Name",
        "DataType": "VARCHAR(255)",
        "Constraints": "NULL",
        "Description": "The name of the customer or entity associated with the record. This column holds the descriptive name or identifier for the customer."
    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "Status",
        "DataType": "VARCHAR(255)",
        "Constraints": "NULL",
        "Description": "Indicates the status of the record, whether it is currently active or inactive. This column helps track the current state of the customer or entity."
    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "Hashkey",
        "DataType": "VARCHAR(255)",
        "Constraints": "NOT NULL",
        "Description": "\r\nA hash key is generated by applying a hash function to all the columns except Primary Key column to identify the changes over time. "
    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "DW_Created_By",
        "DataType": "VARCHAR(255)",
        "Constraints": "NOT NULL",
        "Description": "Represents the user or system entity responsible for creating the record. ",

    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "DW_Created_Date",
        "DataType": "TIMESTAMP",
        "Constraints": "NOT NULL",
        "Description": "Indicates the date and time when the record was created. "
    },
    {
        "SubjectArea": "Customer",
        "SchemaName": "Gold",
        "TableName": "Dim_Customer",
        "ColumnName": "DW_Modified_By",
        "DataType": "VARCHAR(255)",
        "Constraints": "NOT NULL",
        "Description": "Represents the user or system entity responsible for the most recent modification to the record. "
    }];
export const tableMapping = [
    {
        "SNo": 1,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "Customer",
        "SourceSystem": "Aljex",
        "SourceSchema": "dbo",
        "SourceTableName": "Customer",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 2,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "Client",
        "SourceSystem": "Aljex",
        "SourceSchema": "dbo",
        "SourceTableName": "Client",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 3,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "Contracts",
        "SourceSystem": "Aljex",
        "SourceSchema": "dbo",
        "SourceTableName": "Contracts",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 5,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "aljex_customer_profiles",
        "SourceSystem": "Aljex",
        "SourceSchema": "dbo",
        "SourceTableName": "aljex_customer_profiles",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 6,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "customers",
        "SourceSystem": "Aljex",
        "SourceSchema": "dbo",
        "SourceTableName": "customers",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 7,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "customer_profile_projection",
        "SourceSystem": "Relay",
        "SourceSchema": "dbo",
        "SourceTableName": "customer_profile_projection",
        "IntermediateOperations": "One to one mapping from source"
    },
    {
        "SNo": 8,
        "TableType": "Dim",
        "SubjectArea": "Customer",
        "DestinationSchemaName": "Bronze",
        "DestinationTableName": "cai_data",
        "SourceSystem": "Edge",
        "SourceSchema": "dbo",
        "SourceTableName": "cai_data",
        "IntermediateOperations": "One to one mapping from source"
    }];
export const columnMapping = " ";
export const sampleOutputBCM = [
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "BronzeSchemaName": "Bronze",
        "BronzeTableName": "aljex_customer_profiles",
        "BronzeColumnName": "cust_id",
        "BronzeDatatype": "VARCHAR",
        "BronzeConstraints": "NULL",
        "SourceSystem": "Aljex",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_id",
        "SourceDatatype": "Varchar",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Primary Key, customer ID"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "BronzeSchemaName": "Bronze",
        "BronzeTableName": "aljex_customer_profiles",
        "BronzeColumnName": "cust_name",
        "BronzeDatatype": "VARCHAR",
        "BronzeConstraints": "NULL",
        "SourceSystem": "Aljex",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_name",
        "SourceDatatype": "Varchar",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Customer Name"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "BronzeSchemaName": "Bronze",
        "BronzeTableName": "aljex_customer_profiles",
        "BronzeColumnName": "sales_rep",
        "BronzeDatatype": "VARCHAR",
        "BronzeConstraints": "NULL",
        "SourceSystem": "Aljex",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "sales_rep",
        "SourceDatatype": "Varchar",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Sales rep from NFI to customer, usually a three letter initial"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "BronzeSchemaName": "Bronze",
        "BronzeTableName": "aljex_customer_profiles",
        "BronzeColumnName": "cust_id",
        "BronzeDatatype": "VARCHAR",
        "BronzeConstraints": "NULL",
        "SourceSystem": "Aljex",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_id",
        "SourceDatatype": "Varchar",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Primary Key, customer ID"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "BronzeSchemaName": "Bronze",
        "BronzeTableName": "aljex_customer_profiles",
        "BronzeColumnName": "cust_name",
        "BronzeDatatype": "VARCHAR",
        "BronzeConstraints": "NULL",
        "SourceSystem": "Aljex",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_name",
        "SourceDatatype": "Varchar",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Customer Name"
    }
]
export const sampleOutputSCM = [
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "SilverSchemaName": "Silver",
        "SilverTableName": "Customer",
        "SilverColumnName": "CustomerID",
        "SilverDatatype": "VARCHAR",
        "SilverConstraints": "PRIMARY KEY",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_id",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Primary Key, customer ID"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "SilverSchemaName": "Silver",
        "SilverTableName": "Customer",
        "SilverColumnName": "CustomerName",
        "SilverDatatype": "VARCHAR",
        "SilverConstraints": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_name",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Customer Name"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "SilverSchemaName": "Silver",
        "SilverTableName": "Customer",
        "SilverColumnName": "SalesRepresentative",
        "SilverDatatype": "VARCHAR",
        "SilverConstraints": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "sales_rep",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Sales rep from NFI to customer, usually a three letter initial"
    },
    {
        "RequirementID": "9.1.1",
        "SubjectArea": "Carrier",
        "SilverSchemaName": "Silver",
        "SilverTableName": "Carrier",
        "SilverColumnName": "DW_Carrier_ID",
        "SilverDatatype": "INT",
        "SilverConstraints": "PRIMARY KEY",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "Carrier",
        "SourceColumnName": "Carrier_ID",
        "SourceDatatype": "INT",
        "SourceConstraints": "UNIQUE",
        "TransformationLogic": "Generated as a Surrogate Key",
        "Comments": "New column Unique identifier that needs to be generated in the Target"
    },
    {
        "RequirementID": "9.1.1",
        "SubjectArea": "Carrier",
        "SilverSchemaName": "Silver",
        "SilverTableName": "Carrier",
        "SilverColumnName": "Hashkey",
        "SilverDatatype": "VARCHAR",
        "SilverConstraints": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "-",
        "SourceColumnName": "-",
        "SourceDatatype": "-",
        "SourceConstraints": "-",
        "TransformationLogic": "MD5 hash of all columns except primary key for change tracking",
        "Comments": "Audit column for change tracking"
    }
]
export const sampleOutputGCM = [
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "GoldSchemaName": "Gold",
        "GoldTableName": "Customer",
        "GoldColumnName": "CustomerID",
        "GoldDatatype": "VARCHAR",
        "GoldConstraint": "PRIMARY KEY",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_id",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Primary Key, customer ID"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "GoldSchemaName": "Gold",
        "GoldTableName": "Customer",
        "GoldColumnName": "CustomerName",
        "GoldDatatype": "VARCHAR",
        "GoldConstraint": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "cust_name",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Customer Name"
    },
    {
        "RequirementID": "4.1.3",
        "SubjectArea": "Customer",
        "GoldSchemaName": "Gold",
        "GoldTableName": "Customer",
        "GoldColumnName": "SalesRepresentative",
        "GoldDatatype": "VARCHAR",
        "GoldConstraint": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "aljex_customer_profiles",
        "SourceColumnName": "sales_rep",
        "SourceDatatype": "VARCHAR",
        "SourceConstraints": "NULL",
        "TransformationLogic": "Direct mapping",
        "Comments": "Sales rep from NFI to customer, usually a three letter initial"
    },
    {
        "RequirementID": "9.1.1",
        "SubjectArea": "Carrier",
        "GoldSchemaName": "Gold",
        "GoldTableName": "Carrier",
        "GoldColumnName": "DW_Carrier_ID",
        "GoldDatatype": "INT",
        "GoldConstraint": "PRIMARY KEY",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "Carrier",
        "SourceColumnName": "Carrier_ID",
        "SourceDatatype": "INT",
        "SourceConstraints": "UNIQUE",
        "TransformationLogic": "Generated as a Surrogate Key",
        "Comments": "New column Unique identifier that needs to be generated in the Target"
    },
    {
        "RequirementID": "9.1.1",
        "SubjectArea": "Carrier",
        "GoldSchemaName": "Gold",
        "GoldTableName": "Carrier",
        "GoldColumnName": "Hashkey",
        "GoldDatatype": "VARCHAR",
        "GoldConstraint": "NULL",
        "SourceSchemaName": "Bronze",
        "SourceTableName": "-",
        "SourceColumnName": "-",
        "SourceDatatype": "-",
        "SourceConstraints": "-",
        "TransformationLogic": "MD5 hash of all columns except primary key for change tracking",
        "Comments": "Audit column for change tracking"
    }
]
export const metadataTable = [
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 1,
        "SubjectArea": "Carrier",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "equip_mode_table",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "monthly_once",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/equip_mode_table",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/equip_mode_table",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/equip_mode_table",
        "DWHSchemaName": "Public",
        "DWHTableName": "equip_mode_table",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/equip_mode_table",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select equip_mode_type from Public.equip_mode_table",
        "CreateQuery": "CREATE TABLE Bronze.equip_mode_table (\r\n  equip_mode_type STRING\r\n)\r\nUSING DELTA\r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/equip_mode_table'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Relay",
        "SourceSecretName": "Relay_key",
        "TableID": 2,
        "SubjectArea": "Customer",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "shippers",
        "LoadType": "Incremental Load",
        "IsActive": 1,
        "Frequency": "15_Minutes",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/shippers",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/shippers",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/shippers",
        "DWHSchemaName": "Public",
        "DWHTableName": "shippers",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/shippers",
        "LastLoadDateColumn": "updated_at",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "\"uuid\"",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "uuid",
        "SourceSelectQuery": "select uuid, address1, address2, city, latitude, longitude, name, phone_number, state_code, time_zone, utc_offset, \\\"validated?\\\", zip_code, created_at, inserted_at, updated_at  from Public.shippers where {0}>='{1}'",
        "CreateQuery": "CREATE TABLE Bronze.shippers (\r\n    uuid VARCHAR(255),\r\n    address1 VARCHAR(255),\r\n    address2 VARCHAR(255),\r\n    city VARCHAR(255),\r\n    latitude DOUBLE,\r\n    longitude DOUBLE,\r\n    name VARCHAR(255),\r\n    phone_number VARCHAR(255),\r\n    state_code VARCHAR(255),\r\n    time_zone VARCHAR(255),\r\n    utc_offset INT,\r\n    `validated?` BOOLEAN,\r\n    zip_code VARCHAR(255),\r\n    created_at TIMESTAMP,\r\n    inserted_at TIMESTAMP,\r\n    updated_at TIMESTAMP\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/shippers'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 3,
        "SubjectArea": "Look-up",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "aljex_mode_types",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "monthly_once",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_mode_types",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/aljex_mode_types",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/aljex_mode_types",
        "DWHSchemaName": "Public",
        "DWHTableName": "aljex_mode_types",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/aljex_mode_types",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select equipment_type,equipment_mode from Public.aljex_mode_types",
        "CreateQuery": "CREATE TABLE Bronze.aljex_mode_types (\r\n    equipment_type VARCHAR(2147483647),\r\n    equipment_mode VARCHAR(2147483647)\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_mode_types'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 4,
        "SubjectArea": "Customer",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "customers",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "24hrs",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/customers",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/customers",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/customers",
        "DWHSchemaName": "Public",
        "DWHTableName": "customers",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/customers",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "Select id, zip, web_sync_table_name, web_sync_action, type, total_exposure, status, state, sales_rep, revenue_type, phone3, phone2, phone1, passwd, old_system_id, name, fax, email4, email3, email2, email1, credit_limit, contact4, contact3, contact2, contact1, city, address2, address1 from public.customers",
        "CreateQuery": "CREATE TABLE Bronze.customers (\r\n  id STRING,\r\n  zip STRING,\r\n  web_sync_table_name STRING,\r\n  web_sync_action STRING,\r\n  type STRING,\r\n  total_exposure STRING,\r\n  status STRING,\r\n  state STRING,\r\n  sales_rep STRING,\r\n  revenue_type STRING,\r\n  phone3 STRING,\r\n  phone2 STRING,\r\n  phone1 STRING,\r\n  passwd STRING,\r\n  old_system_id STRING,\r\n  name STRING,\r\n  fax STRING,\r\n  email4 STRING,\r\n  email3 STRING,\r\n  email2 STRING,\r\n  email1 STRING,\r\n  credit_limit STRING,\r\n  contact4 STRING,\r\n  contact3 STRING,\r\n  contact2 STRING,\r\n  contact1 STRING,\r\n  city STRING,\r\n  address2 STRING,\r\n  address1 STRING\r\n)\r\nUSING DELTA\r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/customers'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 5,
        "SubjectArea": "Customer",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "aljex_customer_profiles",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "24hrs",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_customer_profiles",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/aljex_customer_profiles",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/aljex_customer_profiles",
        "DWHSchemaName": "Public",
        "DWHTableName": "aljex_customer_profiles",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/aljex_customer_profiles",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select cust_id,cust_name,sales_rep,state,status,cust_country,address_one,address_two,address_city,address_zip from Public.aljex_customer_profiles",
        "CreateQuery": "CREATE TABLE Bronze.aljex_customer_profiles (\r\n    cust_id VARCHAR(2147483647),\r\n    cust_name VARCHAR(2147483647),\r\n    sales_rep VARCHAR(2147483647),\r\n    state VARCHAR(2147483647),\r\n    status VARCHAR(2147483647),\r\n    cust_country VARCHAR(2147483647),\r\n    address_one VARCHAR(2147483647),\r\n    address_two VARCHAR(2147483647),\r\n    address_city VARCHAR(2147483647),\r\n    address_zip VARCHAR(2147483647)\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/aljex_customer_profiles'",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 6,
        "SubjectArea": "Carrier",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "aljex_dot_lawson_ref",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "24hrs",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_dot_lawson_ref",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/aljex_dot_lawson_ref",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/aljex_dot_lawson_ref",
        "DWHSchemaName": "Public",
        "DWHTableName": "aljex_dot_lawson_ref",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/aljex_dot_lawson_ref",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select aljex_carrier_id,dot_number,lawson_id from Public.aljex_dot_lawson_ref",
        "CreateQuery": "CREATE TABLE Bronze.aljex_dot_lawson_ref (\r\n    aljex_carrier_id BIGINT,\r\n    dot_number BIGINT,\r\n    lawson_id VARCHAR(2147483647)\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_dot_lawson_ref'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Aljex",
        "SourceSecretName": "Aljex_key",
        "TableID": 7,
        "SubjectArea": "Finance & Invoices",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "aljex_cred_debt",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "manually_upload_1_a_week",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_cred_debt",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/aljex_cred_debt",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/aljex_cred_debt",
        "DWHSchemaName": "Public",
        "DWHTableName": "aljex_cred_debt",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/aljex_cred_debt",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select office, pro_num, customer, type_of_ship, ship_date, revenue, expense from Public.aljex_cred_debt",
        "CreateQuery": "CREATE TABLE Bronze.aljex_cred_debt (\r\n    office VARCHAR(2147483647),\r\n    pro_num BIGINT,\r\n    customer VARCHAR(2147483647),\r\n    type_of_ship VARCHAR(2147483647),\r\n    ship_date DATE,\r\n    revenue DECIMAL(10,2),\r\n    expense DECIMAL(10,2)\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/aljex_cred_debt'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    },
    {
        "SourceSystem": "Relay",
        "SourceSecretName": "Relay_key",
        "TableID": 8,
        "SubjectArea": "Load",
        "SourceDBName": "ad_hoc_analysis",
        "SourceSchema": "Public",
        "SourceTableName": "event_name_status",
        "LoadType": "Truncate and Load",
        "IsActive": 1,
        "Frequency": "24hrs",
        "Bronze": "dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/event_name_status",
        "Silver": "dbfs:/user/hive/warehouse/raw/ad_hoc_analysis/Public/event_name_status",
        "Gold": "dbfs:/user/hive/warehouse/curated/ad_hoc_analysis/Public/event_name_status",
        "DWHSchemaName": "Public",
        "DWHTableName": "event_name_status",
        "ErrorLogPath": "dbfs:/user/hive/warehouse/log/ad_hoc_analysis/Public/event_name_status",
        "LastLoadDateColumn": "null",
        "LastLoadDateValue": "1970-01-01 00:00:00",
        "MergeKey": "null",
        "DependencyTableIDs": "null",
        "PipelineStartTime": "1970-01-01 00:00:00",
        "PipelineEndTime": "1970-01-01 00:00:00",
        "PipelineRunStatus": "Succeeded",
        "Zone": "Bronze",
        "MergeKeyColumn": "null",
        "SourceSelectQuery": "select truckload_p_status, shortcut_status from Public.event_name_status",
        "CreateQuery": "CREATE TABLE Bronze.event_name_status (\r\n    truckload_p_status VARCHAR(255),\r\n    shortcut_status VARCHAR(255)\r\n) USING DELTA \r\nLOCATION 'dbfs:/user/hive/warehouse/Bronze/ad_hoc_analysis/Public/event_name_status'\r\n",
        "NotebookName": "GenETL_Notebook_1"
    }];
export const ddlNotebook = [
    {
        "TableName": "Dim_Customer",
        "DDLScripts": "create table silver.Dim_Customer(\r\nDW_customer_id,\r\ncustomer_id  VARCHAR(255) NOT NULL,\r\nHashkey  \r\nCustomer_Name VARCHAR(255),\r\nCustomer_Phone1 VARCHAR(255),\r\nCustomer_Phone2  VARCHAR(255),\r\nCustomer_Phone3  VARCHAR(255),\r\nCustomer_Email1   VARCHAR(255),\r\nCustomer_Email2   VARCHAR(255),\r\nCustomer_Email3  VARCHAR(255),\r\nCustomer_Email4   VARCHAR(255),\r\nCust_address1   VARCHAR(300),\r\nCust_address2    VARCHAR(300),\r\nCustomer_city    VARCHAR(255),\r\nCustomer_state  VARCHAR(255),\r\nCustomer_zipcode  VARCHAR(255),\r\nInvoice_Method VARCHAR(255),\r\nInvoicing_CustomerProfile_ID VARCHAR(255),\r\nCredit_Limit  decimal(10,2),\r\nCustomer_Status   VARCHAR(255),\r\nSalesperson_Name  VARCHAR(255),\r\nSourceSytem_ID INTEGER NOT NULL,\r\nSourceSystem_Name  VARCHAR(255),\r\ncreated_by VARCHAR(255),\r\ncreated_date  Timestamp_ntz NOT NULL,\r\nLast_modified_by   VARCHAR(255),\r\nlast_modified_date  Timestamp_ntz NOT NULL,\r\nCONSTRAINT Dim_customer_pkey PRIMARY KEY (customer_id)"
    },
    {
        "TableName": "Dim_Office",
        "DDLScripts": "CREATE OR REPLACE TABLE silver.Dim_Office\r\nDW_office_id  BIGINT GENERATED ALWAYS AS IDENTITY,\r\nOffice_id  VARCHAR(255) NOT NULL ,\r\nOffice_name VARCHAR(255) ,\r\nOffice_location VRACHAR(255),\r\nSourceSytem_ID INTEGER NOT NULL,\r\nSourceSystem_Name  VARCHAR(10),\r\ncreated_by VARCHAR(15),\r\ncreated_date  Timestamp_ntz NOT NULL,\r\nLast_modified_by   VARCHAR(15),\r\nlast_modified_date  Timestamp_ntz NOT NULL,\r\nCONSTRAINT Dim_office_pkey PRIMARY KEY (Office_id)\r\n"
    },
    {
        "TableName": "Lookup_Mode",
        "DDLScripts": "CREATE OR REPLACE TABLE silver.Lookup_Mode (\r\nDW_mode_id  BIGINT GENERATED ALWAYS AS IDENTITY,\r\nHashkey VARCHAR(255),\r\nSource_TransportMode VARCHAR(255),\r\nDerived_TransportMode VARCHAR(255),\r\ncreated_by VARCHAR(15),\r\ncreated_date  Timestamp_ntz NOT NULL,\r\nLast_modified_by   VARCHAR(15),\r\nLast_modified_date  Timestamp_ntz NOT NULL,\r\nCONSTRAINT Lookup_Mode_pkey PRIMARY KEY (DW_mode_id)\r\n"
    },
    {
        "TableName": "Lookup_Equipment",
        "DDLScripts": "CREATE OR REPLACE TABLE silver.Lookup_Equipment (\r\nDW_equipment_id  BIGINT GENERATED ALWAYS AS IDENTITY,\r\nHashkey VARCHAR(255),\r\nSource_Equipment_Type VARCHAR(255),\r\nDerived_ Equipment_Type VARCHAR(255),\r\ncreated_by VARCHAR(15),\r\ncreated_date  Timestamp_ntz NOT NULL,\r\nLast_modified_by   VARCHAR(255),\r\nLast_modified_date  Timestamp_ntz NOT NULL\r\nCONSTRAINT Lookup_Equipment_pkey PRIMARY KEY (DW_equipment_id)\r\n"
    },
    {
        "TableName": "Dim_Carrier",
        "DDLScripts": "CREATE OR REPLACE TABLE \r\nDW_carrier_id  BIGINT GENERATED ALWAYS AS IDENTITY,\r\nHashkey VARCHAR(255), \r\nCarrier_ID VARCHAR(255),\r\nCarrier_Name VARCHAR(255),\r\nContact_Details VARCHAR(255),\r\nCarrier_Email1 VARCHAR(255),\r\nCarrier_Email2 VARCHAR(255),\r\nCarrier_Address1 VARCHAR(255),\r\nCarrier_Address2 VARCHAR(255),\r\nCarrier_State VARCHAR(255),\r\nCarrier_ZipCode VARCHAR(255),\r\nCarrier_City VARCHAR(255),\r\nCarrier_Type VARCHAR(255),\r\nCarrier_MCnumber VARCHAR(255),\r\nCarrier_Comments VARCHAR(255),\r\nWorkman_comp_insurer VARCHAR(255),\r\nWorkman_comp_policy VARCHAR(255),\r\nWorkman_comp_amount VARCHAR(255),\r\nWorkman_comp_deduct VARCHAR(255),\r\nCarrierApproved_At VARCHAR(255),\r\nCarrierApproved_By VARCHAR(255),\r\nDenied_at VARCHAR(255),\r\ndenied_by VARCHAR(255),\r\nProhibted_at VARCHAR(255),\r\nProhibted_by VARCHAR(255),\r\nCarrierStatus VARCHAR(255),\r\nLiab_Ins_PolicyID VARCHAR(255),\r\nCargo_Ins_PolicyID VARCHAR(255),\r\nCargoInsurancer_Name VARCHAR(255),\r\nLiab_insurer VARCHAR(255),\r\nLiab_ins_amount VARCHAR(255),\r\nCargo_ins_amount VARCHAR(255),\r\nDateOfExpiration_cargo VARCHAR(255),\r\nDateOfExpiration_liab VARCHAR(255),\r\nPreferred_Carrier VARCHAR(255),\r\nSCAC VARCHAR(255),\r\nDOT VARCHAR(255),\r\nSourcesystem_ID INTEGER NOT NULL,\r\nSourcesystem_Name VARCHAR(255),\r\ncreated_by VARCHAR(255),\r\ncreated_date TIMESTAMP_NTZ NOT NULL,\r\nLast_modified_by VARCHAR(255),\r\nlast_modified_date TIMESTAMP_NTZ NOT NULL\r\nCONSTRAINT Dim_carrier_pkey PRIMARY KEY (Carrier_ID)"
    },
    {
        "TableName": "Dim_Employee",
        "DDLScripts": "CREATE OR REPLACE TABLE (\r\nDW_employee_id BIGINT GENERATED ALWAYS AS IDENTITY,\r\nHashkey VARCHAR(255),\r\nEmployee_ID  VARCHAR(255),\r\nEmployee_Name VARCHAR(255),\r\nEmployee_Title VARCHAR(255), \r\nEmployee_Division VARCHAR(255),\r\nEmployee_Email VARCHAR(255),\r\nCompany_Name VARCHAR(255),\r\nEmployee_Department VARCHAR(255),\r\nEmployee_Status VARCHAR(255),\r\nEmployee_Creation_Date VARCHAR(255),\r\nEmployee_LastUpdated_Date VARCHAR(255),\r\nEmployment_Type VARCHAR(255),\r\nSeniority_Years VARCHAR(255),\r\nSourcesystem_Name VARCHAR(255)\r\nSourcesystem_ID INTEGER NOT NULL,\r\ncreated_by VARCHAR(255),\r\ncreated_date TIMESTAMP_NTZ NOT NULL,\r\nLast_modified_by VARCHAR(255),\r\nlast_modified_date TIMESTAMP_NTZ NOT NULL\r\nCONSTRAINT Dim_employee_pkey PRIMARY KEY (Employee_ID)"
    }
];
export const ddlPipeline = [
    {
        "TableName": "Metadata",
        "DDLScripts": "CREATE TABLE mastermetadata (\r\n  SourceSystem VARCHAR(50),\r\n  SourceSecretName VARCHAR(100),\r\n  TableID INTEGER,\r\n  SubjectArea VARCHAR(50),\r\n  SourceDBName VARCHAR(50),\r\n  SourceSchema VARCHAR(50),\r\n  SourceTableName VARCHAR(100),\r\n  LoadType VARCHAR(250),\r\n  IsActive Int,\r\n  Frequency VARCHAR(50),\r\n  BronzePath VARCHAR(250),\r\n  SilverPath VARCHAR(250),\r\n  GoldPath VARCHAR(250),\r\n  DWHSchemaName VARCHAR(50),\r\n  DWHTableName VARCHAR(100),\r\n  ErrorLogPath VARCHAR(250),\r\n  LastLoadDateColumn VARCHAR(50),\r\n  MergeKey VARCHAR(250),\r\n  MergeKeyColumn VARCHAR(250),\r\n  DependencyTableIDs VARCHAR(250),\r\n  LastLoadDateValue TIMESTAMP,\r\n  PipelineEndTime TIMESTAMP,\r\n  PipelineRunStatus VARCHAR(20),\r\n  Zone VARCHAR(20),\r\n  SourceSelectQuery VARCHAR(8000),\r\n  StageCreateQuery VARCHAR(8000),\r\n  NotebookName VARCHAR(50))"
    },
    {
        "TableName": "FactShipment",
        "DDLScripts": "CREATE TABLE FactShipment (\r\n    DWLoadID BIGINT NOT NULL,\r\n    SourceLoadID NVARCHAR(20) NOT NULL,\r\n    DWCustomerID BIGINT NOT NULL,\r\n    DWShipperID BIGINT NOT NULL,\r\n    DWConsigneeID BIGINT NOT NULL,\r\n    DWLaneID BIGINT NOT NULL,\r\n    DWModeID BIGINT NOT NULL,\r\n    DWEmployeeID BIGINT NOT NULL,\r\n    DWOfficeID BIGINT NOT NULL,\r\n    DWQuoteID BIGINT NOT NULL,\r\n    DWSourceSystemID BIGINT NOT NULL,\r\n    ShipmentType NVARCHAR(255),\r\n    ShipmentStatus NVARCHAR(255),\r\n    ActualPickupDate DATETIME2,\r\n    ActualDeliveryDate DATETIME2,\r\n    ExpectedEarliestPickupDate DATETIME2,\r\n    ExpectedLatestPickupDate DATETIME2,\r\n    ExpectedEarliestDeliveryDate DATETIME2,\r\n    ExpectedLatestDeliveryDate DATETIME2,\r\n    TransitTime NVARCHAR(10),\r\n    OnTimePickupFlag SMALLINT,\r\n    OnTimeDeliveryFlag SMALLINT,\r\n    CarrierCount SMALLINT,\r\n    StopCount SMALLINT,\r\n    Quantity BIGINT,\r\n    Weight FLOAT,\r\n    Miles FLOAT,\r\n    Distance FLOAT,\r\n    TotalCost FLOAT,\r\n    TotalCarrierCost FLOAT,\r\n    LineHaulCost FLOAT,\r\n    FuelCost FLOAT,\r\n    AccessorialCost FLOAT,\r\n    TotalVendorCost FLOAT,\r\n    Margin FLOAT,\r\n    Profit FLOAT,\r\n    Insurance FLOAT,\r\n    CreatedBy NVARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME2 NOT NULL,\r\n    ModifiedBy NVARCHAR(255),\r\n    ModifiedDate DATETIME2,\r\n    PRIMARY KEY (DWLoadID)\r\n);"
    },
    {
        "TableName": "FactLoad",
        "DDLScripts": "CREATE TABLE FactLoad (\r\n    DWLoadCarrierID BIGINT NOT NULL,\r\n    DWLoadID BIGINT NOT NULL,\r\n    DWCarrierID BIGINT NOT NULL,\r\n    DWEquipmentID BIGINT NOT NULL,\r\n    CarrierOrder SMALLINT NULL,\r\n    MoveType VARCHAR(50) NULL,\r\n    CarrierMode VARCHAR(50) NULL,\r\n    LoadType VARCHAR(50) NULL,\r\n    CarrierRate DECIMAL(10,2) NULL,\r\n    CarrierTotal DECIMAL(10,2) NULL,\r\n    Weight DECIMAL NULL,\r\n    Miles DECIMAL NULL,\r\n    TempControl SMALLINT NULL,\r\n    TempMin SMALLINT NULL,\r\n    TempMax SMALLINT NULL,\r\n    TempScale VARCHAR(20) CHECK (TempScale IN ('F','C','K')) NOT NULL,\r\n    TempType VARCHAR(20) CHECK (TempScale IN ('continuous','cycle')) NOT NULL,\r\n    TempService SMALLINT NULL,\r\n    CarrierInvoiceAmount DECIMAL(10,2) NULL,\r\n    TrailerUsed VARCHAR(255) NULL,\r\n    CarrierBillReceived DATE NULL,\r\n    HoldPay SMALLINT NULL,\r\n    CreatedBy VARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME NOT NULL,\r\n    ModifiedBy VARCHAR(255) NULL,\r\n    ModifiedDate DATETIME NULL,\r\n    PRIMARY KEY (DWLoadCarrierID)\r\n);"
    },
    {
        "TableName": "DimCustomer",
        "DDLScripts": "CREATE TABLE DimCustomer (\r\n    DWCustomerID BIGINT NOT NULL,\r\n    DWSourceSystemID BIGINT NOT NULL,\r\n    DWLeadgerAccountNumber BIGINT NOT NULL,\r\n    DWCustomerGroupID BIGINT NOT NULL,\r\n    SourceCustomerID VARCHAR(50) NOT NULL,\r\n    CustomerName VARCHAR(50) NOT NULL,\r\n    CustomerStatus VARCHAR(50) NOT NULL,\r\n    DWSalespersonID BIGINT NOT NULL,\r\n    CreditLimit BIGINT NULL,\r\n    PaymentTerms BIGINT NULL,\r\n    CustomerParent BIGINT NOT NULL,\r\n    CreatedBy VARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME NOT NULL,\r\n    ModifiedBy VARCHAR(255) NULL,\r\n    ModifiedDate DATETIME NULL,\r\n    PRIMARY KEY (DWCustomerID)\r\n);"
    },
    {
        "TableName": "DimOffice",
        "DDLScripts": "CREATE TABLE DimOffice (\r\n    DWOfficeID BIGINT NOT NULL,\r\n    OfficeName VARCHAR(50) NULL,\r\n    Address VARCHAR(50) NULL,\r\n    City VARCHAR(30) NULL,\r\n    State VARCHAR(3) NULL,\r\n    Country VARCHAR(2) NULL,\r\n    SourcesystemOfficeName VARCHAR(50) NULL,\r\n    SourceSystemOfficeID VARCHAR(50) NULL,\r\n    DWSourceSystemID BIGINT NOT NULL,\r\n    CreatedBy VARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME NOT NULL,\r\n    ModifiedBy VARCHAR(255) NULL,\r\n    ModifiedDate DATETIME NULL,\r\n    PRIMARY KEY (DWOfficeID)\r\n);"
    },
    {
        "TableName": "DimEmployee",
        "DDLScripts": "CREATE TABLE DimEmployee (\r\n    DWEmployeeID BIGINT NOT NULL,\r\n    DWOfficeID BIGINT NOT NULL,\r\n    DWSourceSystemID BIGINT NOT NULL,\r\n    EmployeeName VARCHAR(255) NOT NULL,\r\n    EmployeeDesignation VARCHAR(50) NULL,\r\n    EmployeeEmail VARCHAR(100) NOT NULL,\r\n    EmployeePhone VARCHAR(15) NULL,\r\n    EmployeeAddress VARCHAR(255) NULL,\r\n    EmployeeDepartment VARCHAR(50) NULL,\r\n    EmployeeManager VARCHAR(50) NULL,\r\n    EmployeeStatus VARCHAR(50) NOT NULL,\r\n    EmploymentType VARCHAR(50) NOT NULL,\r\n    JoiningDate DATETIME NOT NULL,\r\n    CreatedBy VARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME NOT NULL,\r\n    ModifiedBy VARCHAR(255) NULL,\r\n    ModifiedDate DATETIME NULL,\r\n    PRIMARY KEY (DWEmployeeID)\r\n);"
    },
    {
        "TableName": "DimLoadItems",
        "DDLScripts": "CREATE TABLE DimLoadItems (\r\n    DWLoadItemID BIGINT NOT NULL,\r\n    DWStopID BIGINT NOT NULL,\r\n    ItemOrder SMALLINT NULL,\r\n    DWDelivStopID BIGINT NOT NULL,\r\n    DeliveryItemOrder SMALLINT NULL,\r\n    Quantity SMALLINT NULL,\r\n    MeasurementType VARCHAR(22) NULL,\r\n    Description VARCHAR(250) NULL,\r\n    Weight SMALLINT NULL,\r\n    Length SMALLINT NULL,\r\n    Class VARCHAR(16) NULL,\r\n    NMFC VARCHAR(10) NULL,\r\n    Value SMALLINT NULL,\r\n    PallentLength SMALLINT NULL,\r\n    PalletWidth SMALLINT NULL,\r\n    PalletHeight SMALLINT NULL,\r\n    Stackable SMALLINT NULL,\r\n    Hazmat SMALLINT NULL,\r\n    Density DECIMAL(6,2) NULL,\r\n    CreatedBy VARCHAR(255) NOT NULL,\r\n    CreatedDate DATETIME NOT NULL,\r\n    ModifiedBy VARCHAR(255) NULL,\r\n    ModifiedDate DATETIME NULL,\r\n    PRIMARY KEY (DWLoadItemID)\r\n);"
    },
    {
        "TableName": "Cust_master",
        "DDLScripts": "CREATE TABLE dbo.cust_master (\r\n    id BIGINT not null primary key,\r\n    name VARCHAR(60),\r\n    status VARCHAR(12),\r\n    credit_limit INT,\r\n    payment_term VARCHAR(8),\r\n    parent VARCHAR(255),\r\n    lastmod Datetime,\r\n    lastuser VARCHAR(30),\r\n    actiuser VARCHAR(30),\r\n    actidate DATETIME,\r\n    lastsync DATETIME,\r\n    created DATETIME,\r\n    addr1 VARCHAR(30),\r\n    addr2 VARCHAR(30),\r\n    city VARCHAR(27),\r\n    state VARCHAR(3),\r\n    zip VARCHAR(10),\r\n    phone VARCHAR(14),\r\n    country VARCHAR(2)\r\n);"
    }];
export const ddlProcedure = [
    {
        "TableName": "Customer",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.CUSTOMER\r\n ( CUSTOMERID NUMBER(38,0) NOT NULL, \r\nFIRSTNAME VARCHAR(255) NOT NULL,\r\n LASTNAME VARCHAR(255) NOT NULL,\r\n COMPANY VARCHAR(255) NOT NULL,\r\n ADDRESS VARCHAR(255) NOT NULL,\r\n CITY VARCHAR(255) NOT NULL,\r\nSTATE VARCHAR(255) NOT NULL, \r\nCOUNTRY VARCHAR(255) NOT NULL, \r\nSTATUS VARCHAR(255) NOT NULL, \r\nMODIFIEDDATE TIMESTAMP_NTZ(9), \r\nLEADID NUMBER(38,0), \r\nCOMPANYID NUMBER(38,0),\r\n DOB VARCHAR(25),\r\n ENROLLED VARCHAR(150), \r\nPHONENUMBER NUMBER(38,0), \r\nEMAILADDRESS VARCHAR(150),\r\n ZIPCODE VARCHAR(150),\r\n JOINDATE VARCHAR(150),\r\n CREATEDDATE TIMESTAMP_NTZ(9), \r\nprimary key (CUSTOMERID) );"
    },
    {
        "TableName": "Customer_Expense",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.CUSTOMER_EXPENSES ( CUSTOMERID NUMBER(38,0) NOT NULL, \r\nSALARYAMOUNT NUMBER(38,0), \r\nEXPENSEAMOUNT NUMBER(38,0), \r\nDEBTAMOUNT NUMBER(38,0),\r\n CREDITSCORE NUMBER(38,0),\r\n CREATEDDATE TIMESTAMP_NTZ(9), \r\nMODIFIEDDATE TIMESTAMP_NTZ(9),\r\nprimary key (CUSTOMERID) );"
    },
    {
        "TableName": "Company",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.COMPANY ( COMPANYID NUMBER(38,0) NOT NULL, \r\nLEADID NUMBER(38,0), \r\nTYPEID NUMBER(38,0),\r\n COMPANYNAME VARCHAR(255),\r\n COMPANYEMAIL VARCHAR(255) );"
    },
    {
        "TableName": "Customer_Cards",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.CUSTOMER_CARDS ( CARDID NUMBER(38,0) NOT NULL,\r\n CUSTOMERID NUMBER(38,0),\r\n ACCOUNTNUMBER NUMBER(38,0), \r\nTYPEID NUMBER(38,0), \r\nCARDNUMBER VARCHAR(255), \r\nCVV VARCHAR(25), \r\nEXPIRYDATE TIMESTAMP_NTZ(9), \r\nPAYMENTCYCLE VARCHAR(255), \r\nBILLINGDATE TIMESTAMP_NTZ(9), \r\nDUEDATE TIMESTAMP_NTZ(9), \r\nLIMIT NUMBER(18,2), \r\nCREATEDDATE TIMESTAMP_NTZ(9), \r\nMODIFIEDDATE TIMESTAMP_NTZ(9), primary key (CARDID) );"
    },
    {
        "TableName": "Card_Transactions",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.CARD_TRANSACTIONS ( CARDTRANSID NUMBER(38,0) NOT NULL, \r\nCARDID NUMBER(38,0),\r\n TRANSACTIONID NUMBER(38,0), \r\nTRANSDATE TIMESTAMP_NTZ(9), \r\nAMOUNT NUMBER(18,2),\r\n FROMACCOUNT VARCHAR(255), \r\nTOACCOUNT VARCHAR(255), \r\nBALANCE NUMBER(18,2), \r\nPENDING NUMBER(38,0), \r\nTYPE VARCHAR(255), \r\nSTATUS VARCHAR(255),\r\n CREATEDDATE TIMESTAMP_NTZ(9),\r\nMODIFIEDDATE TIMESTAMP_NTZ(9) );"
    },
    {
        "TableName": "Loans",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.LOANS ( LOANID NUMBER(38,0) NOT NULL, \r\nCUSTOMERID NUMBER(38,0),\r\n ACCOUNTID NUMBER(38,0), \r\nLOANTYPEID NUMBER(38,0),\r\n POLICYID NUMBER(38,0), \r\nLOANAMOUNT NUMBER(18,2),\r\nPAYBACKAMOUNT NUMBER(18,2),\r\n PAYBACKFREQUENCY VARCHAR(255), \r\nTERM NUMBER(38,0), \r\nLOANDATE TIMESTAMP_NTZ(9), \r\nLOANCLOSUREDATE TIMESTAMP_NTZ(9),\r\n HOLDBACKPERCENT NUMBER(18,2), \r\nSTATUS VARCHAR(255), primary key (LOANID) );"
    },
    {
        "TableName": "LoansPayments",
        "DDLScripts": "create or replace TABLE FINTECH.PUBLIC.LOANSPAYMENTS ( LOANPAYMENTID NUMBER(38,0) NOT NULL, \r\nLOANID NUMBER(38,0), \r\nTRANSACTIONID NUMBER(38,0),\r\n PAYMENTAMOUNT NUMBER(18,2), \r\nPAYMENTDATE TIMESTAMP_NTZ(9),\r\n PENDINGAMOUNT NUMBER(18,2),\r\n DUEDATE TIMESTAMP_NTZ(9),\r\n DUEAMOUNTDATE TIMESTAMP_NTZ(9),\r\n PENALTY NUMBER(18,2), primary key (LOANPAYMENTID) );"
    }];
export const pipelineSummaryNotebook = [
    {
        "PipelineID": 1,
        "PipelineName": "Aljex_Extraction",
        "NotebookName": "Aljex_To_Bronze",
        "NotebookType": "Extraction",
        "TableName": "NA",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 2,
        "PipelineName": "Relay_Extraction",
        "NotebookName": "Relay_To_Bronze",
        "NotebookType": "Extraction",
        "TableName": "NA",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 3,
        "PipelineName": "Customer_Silver_Load",
        "NotebookName": "Customer_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.Customer",
        "DependentPipelineID's": 1
    },
    {
        "PipelineID": 3,
        "PipelineName": "Customer_Silver_Load",
        "NotebookName": "Customer_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.Client",
        "DependentPipelineID's": 2
    },
    {
        "PipelineID": 3,
        "PipelineName": "Customer_Silver_Load",
        "NotebookName": "Customer_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.Contract",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 4,
        "PipelineName": "Employee_Silver_Load",
        "NotebookName": "Employee_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.Employee",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 4,
        "PipelineName": "Employee_Silver_Load",
        "NotebookName": "Employee_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.TimeSheet",
        "DependentPipelineID's": 1
    },
    {
        "PipelineID": 4,
        "PipelineName": "Employee_Silver_Load",
        "NotebookName": "Employee_Silver_Load",
        "NotebookType": "Silver Transformation",
        "TableName": "Silver.TimeSheetLineItem",
        "DependentPipelineID's": " "
    }];
export const pipelineSummaryPipeline = [
    {
        "PipelineID": 1,
        "PipelineName": "Extraction_Postgress",
        "Dataflow/PipelineName": "NA",
        "PipelineType": "Extraction",
        "TableName": "NA",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 2,
        "PipelineName": "Customer_Silver_Dataload",
        "Dataflow/PipelineName": "NA",
        "PipelineType": "Silver Trasnformation",
        "TableName": "NA",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 3,
        "PipelineName": "Shipment_Silver_Dataload",
        "Dataflow/PipelineName": "NA",
        "PipelineType": "Silver Trasnformation",
        "TableName": "NA",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 4,
        "PipelineName": "DimCustomer_DataLoad",
        "Dataflow/PipelineName": "DF_DimCustomer",
        "PipelineType": "Gold Transformation",
        "TableName": "Dim_Customer",
        "DependentPipelineID's": 2
    },
    {
        "PipelineID": 5,
        "PipelineName": "DimOffice_DataLoad",
        "Dataflow/PipelineName": "DF_Dimoffice",
        "PipelineType": "Gold Transformation",
        "TableName": "Dim_Office",
        "DependentPipelineID's": 2
    },
    {
        "PipelineID": 6,
        "PipelineName": "DimLoadItems_DataLoad",
        "Dataflow/PipelineName": "DF_DimLoad",
        "PipelineType": "Gold Transformation",
        "TableName": "Dim_Load",
        "DependentPipelineID's": "2,3"
    },
    {
        "PipelineID": 7,
        "PipelineName": "FactShipment_DataLoad",
        "Dataflow/PipelineName": "DF_FactShipment",
        "PipelineType": "Gold Transformation",
        "TableName": "Fact_Shipment",
        "DependentPipelineID's": " "
    },
    {
        "PipelineID": 8,
        "PipelineName": "FactLoad_DataLoad",
        "Dataflow/PipelineName": "DF_FactLoad",
        "PipelineType": "Gold Transformation",
        "TableName": "Fact_Load",
        "DependentPipelineID's": 5
    }];
export const pipelineSummaryProcedure = [
    {
        "PipelineID": 1,
        "PipelineName": "Customer_Silver_Load",
        "StoredProcedure/PipelineName": "Customer_Extraction",
        "PipelineType": "Extraction",
        "TableName": "Bronze.Customer",
        "DependentPipelineID's": null
    },
    {
        "PipelineID": 2,
        "PipelineName": "Customer_Silver_Load",
        "StoredProcedure/PipelineName": "Customer_Silver_Load",
        "PipelineType": "Silver Transformation",
        "TableName": "Silver.Customer",
        "DependentPipelineID's": null
    },
    {
        "PipelineID": 3,
        "PipelineName": "Customer_Silver_Load",
        "StoredProcedure/PipelineName": "Customer_Gold_Load",
        "PipelineType": "Gold Transformation",
        "TableName": "Dim_Customer",
        "DependentPipelineID's": "1,2"
    }
];

export const orchestractionDocNotebook = [
    {
        "PipelineName": "DWH_Load",
        "SNo": 1.1,
        "SubjectArea": "Employee",
        "ComponentName": "Dim_Employee_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "Notebook path: dataplatform/sharepoint/Transformations/Dim_Employee",
        "PostComponentName": "OnSuccess: Lookup_SourceSystem_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 2.2,
        "SubjectArea": "Lookup",
        "ComponentName": "Lookup_SourceSystem_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "Notebook path: dataplatform/sharepoint/Transformations/Lookup_SourcesystemLoad",
        "PostComponentName": "OnSuccess: Dim_Customer_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.1,
        "SubjectArea": "Customer",
        "ComponentName": "Dim_Customer_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "Notebook path: dataplatform/sharepoint/Transformations/Dim_Customer",
        "PostComponentName": "OnSuccess:Dim_Customer_Contacts_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.2,
        "SubjectArea": "Customer",
        "ComponentName": "Dim_Customer_Contacts_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "Notebook path: dataplatform/sharepoint/Transformations/Dim_Customer_Contacts",
        "PostComponentName": "OnSuccess:Dim_Carrier_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.3,
        "SubjectArea": "Carrier",
        "ComponentName": "Dim_Carrier_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "\r\nSettings:\r\nNotebook path: dataplatform/sharepoint/Transformations/Dim_Carrier",
        "PostComponentName": "OnSuccess:Lookup_Mode_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.4,
        "SubjectArea": "Carrier",
        "ComponentName": "Lookup_Mode_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "\r\nSettings:\r\nNotebook path: dataplatform/sharepoint/Transformations/Lookup_Mode_Load",
        "PostComponentName": "OnSuccess:Lookup_Equipment_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.5,
        "SubjectArea": "Carrier",
        "ComponentName": "Lookup_Equipment_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "\r\nSettings:\r\nNotebook path: \r\ndataplatform/sharepoint/Transformations/Lookup_Equipment_Load",
        "PostComponentName": "OnSuccess:Fact_Load",
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    },
    {
        "PipelineName": "DWH_Load",
        "SNo": 3.6,
        "SubjectArea": "Load",
        "ComponentName": "Fact_Load",
        "ComponentType": "Notebook",
        "ParameterName": null,
        "Configuration": "Settings: \r\nNotebook path:\r\ndataplatform/sharepoint/Transformations/FactLoad",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "Comments": null
    }
];
export const dataQualityPseudoNotebook = [
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.2",
        "DesignID": "",
        "CellName": "Header Initialization",
        "Steps": 1.1,
        "Flow": "Name the Notebook as Ingest - IngestDataValidation - Raw to Curated ",
        "OperationsType": "Header initialization",
        "OperationDescription": "*Delaring the header by naming the cell",
        "InternalReviewComments": null,
        "ExternalReviewComments": null
    },
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.3",
        "DesignID": "",
        "CellName": "Importing required packages",
        "Steps": 2.1,
        "Flow": "Importing the packages for supporting the sql function, delta conversions, date conversions and creating connections",
        "OperationsType": "Package Calling",
        "OperationDescription": "Importing the packages for supporting the sql function, delta conversions, date conversions and creating connections",
        "InternalReviewComments": "* Add a clear DFD",
        "ExternalReviewComments": null
    },
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.4",
        "DesignID": "",
        "CellName": "Importing required packages",
        "Steps": 3.1,
        "Flow": "* from pyspark.sql.functions import *",
        "OperationsType": "Package Calling",
        "OperationDescription": "* from pyspark.sql.functions import *",
        "InternalReviewComments": null,
        "ExternalReviewComments": null
    },
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.5",
        "DesignID": "",
        "CellName": "Importing required packages",
        "Steps": 4.1,
        "Flow": "* import datetime",
        "OperationsType": "Package Calling",
        "OperationDescription": "* import datetime",
        "InternalReviewComments": null,
        "ExternalReviewComments": null
    },
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.6",
        "DesignID": "",
        "CellName": "Importing required packages",
        "Steps": 5.1,
        "Flow": "* from delta.tables import *",
        "OperationsType": "Package Calling",
        "OperationDescription": "* from delta.tables import *",
        "InternalReviewComments": null,
        "ExternalReviewComments": null
    },
    {
        "NotebookName": "DataValidation",
        "RequirementID": "RQ_8.7",
        "DesignID": "",
        "CellName": "Importing required packages",
        "Steps": 6.1,
        "Flow": "* from pyspark.sql import SparkSession",
        "OperationsType": "Package Calling",
        "OperationDescription": "* from pyspark.sql import SparkSession",
        "InternalReviewComments": null,
        "ExternalReviewComments": null
    }];
export const codeReviewChecklistDocNotebook = [
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 1",
        "CommandPurpose": "Importing Packages",
        "ChecklistStep": "* Check whether the required packages regards to this process has been implemented or not.",
        "Status": "Success",
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 2",
        "CommandPurpose": "Declaring Required Id and Name",
        "ChecklistStep": "* Check the Module Id and Table ID has been mentioned in the first command.",
        "Status": "Success",
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 3",
        "CommandPurpose": "Environment Declaration",
        "ChecklistStep": "* Check the Environment has been declared with Secrets.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 4",
        "CommandPurpose": "File Path",
        "ChecklistStep": "* Check the path has been declared correctly with dynamic format method.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 5",
        "CommandPurpose": "Reading Metadata",
        "ChecklistStep": "* Examine the SQL statement whether it have necessary columns selected.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 6",
        "CommandPurpose": "Truncating Stage Table",
        "ChecklistStep": "* Validate the Truncate query has been place in correct command.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": "SourceToBronze",
        "DesignID": "Step 7",
        "CommandPurpose": "        ",
        "ChecklistStep": "* Check whether the Table Id, Table Name, Schema Name has been called using For loop to load multiple tables.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": null,
        "DesignID": null,
        "CommandPurpose": null,
        "ChecklistStep": "* Check the File Stage path and Stage path has been called properly.",
        "Status": null,
        "Comments": null
    },
    {
        "NotebookName": null,
        "DesignID": null,
        "CommandPurpose": null,
        "ChecklistStep": "* Examine the overall structure of code including reading the csv/xml/json, filtering, checking and loading the data into Stage table as Delta.",
        "Status": null,
        "Comments": null
    }];
export const linkedSericePipeline = [
    {
        "DesignID": "D_1",
        "Entities": "Linked Service",
        "ComponentName": "ava-eus-synapse-ds-poc-WorkspaceDefaultStorage",
        "Description": "To Connect Synapse to ADLS",
        "ComponentType": "ADLS",
        "Configuration": "Connect via integration runtime: AutoResolveIntegrationRuntime\r\nAuthentication type: System Assigned Managed Identity\r\nAccount selection method: Enter Manually\r\nURL: https://avaeussynapsepoc.dfs.core.windows.net\r\nTest Connection : Linked Service",
        "Parameters": null
    },
    {
        "DesignID": "D_2",
        "Entities": "Linked Service",
        "ComponentName": "AzureBlobStorageMG",
        "Description": "To Connect Synapse to Azure Blob",
        "ComponentType": "Azure Blob Storage",
        "Configuration": "Connect via integration runtime: AutoResolveIntegrationRuntime\r\nAuthentication type: Account key\r\nAccount selection method: Enter manually\r\nStorage account name: avaeusmgdsstracc\r\nStorage account key: xxxxxxxxx\r\nTest connection : Linked Service",
        "Parameters": null
    },
    {
        "DesignID": null,
        "Entities": null,
        "ComponentName": ".",
        "Description": null,
        "ComponentType": null,
        "Configuration": null,
        "Parameters": null
    }
];
export const datasetPipeline = [
    {
        "DesignID": "D_1",
        "Entities": "Dataset",
        "ComponentName": "fintech_src_sqlserver",
        "Description": "Dataset to read the table",
        "ComponentType": "Azure SQL Database Managed Instance",
        "Configuration": "Linked Service : FintechSQLServer\r\nLinked service Properties \r\nDatabase Name: @dataset().DatabaseName\r\nTable :@dataset().SchemaName.@dataset().TableName\r\n",
        "Parameters": "Parameters\r\nName : DatabaseName\r\nType : String\r\nDefault value : Value\r\nName :SchemaName\r\nType : String\r\nDefault value : Value\r\nName :TableName\r\nType : String\r\nDefault value : Value"
    },
    {
        "DesignID": "D_2",
        "Entities": "Dataset",
        "ComponentName": "ADLS_Parquet",
        "Description": "Dataset to read the table",
        "ComponentType": "Azure Datalake  Storage",
        "Configuration": "Connect via integration runtime: AutoResolveIntegrationRuntime\r\nAuthentication type: System Assigned Managed Identity\r\nAccount selection method: Enter Manually\r\nURL: https://avaeussynapsepoc.dfs.core.windows.net\r\nTest Connection : Linked Service",
        "Parameters": "Parameters\r\nName : DatabaseName\r\nType : String\r\nDefault value : Value\r\nName :SchemaName\r\nType : String\r\nDefault value : Value\r\nName :TableName\r\nType : String\r\nDefault value : Value"
    }
];
export const extractionPipeline = [
    {
        "RequirementID": 6.1,
        "DesignID": "D_1.1.1",
        "SNo": 1.1,
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": null,
        "DataflowName": null,
        "ComponentName": "LK_GetMetadata_Table",
        "ComponentType": "look Up",
        "ParameterName": null,
        "Configuration": "Source dataset :FintechSqlServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: Select * from Logistic.Metadata where IsActive = 1 and TableID in ('T1','T2','T3','T4','T5','T6','T7','T8','T9','T10')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": 6.1,
        "DesignID": "D_1.1.2",
        "SNo": 1.2,
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": null,
        "DataflowName": null,
        "ComponentName": "ForEach_SourceExtraction",
        "ComponentType": "For Each",
        "ParameterName": null,
        "Configuration": "Sequential: disabled\r\nBatch count: 4\r\nItems: @activity('LK_GetMetadata_Table').output.value",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": "LK_GetDebtblue_Metadata_Table",
        "SubComponentType": "Lookup ",
        "SinkOrder": null
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.3",
        "SNo": "1.2.1",
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_GetMetadata_TableDetails_copy1",
        "ComponentType": "Look Up",
        "ParameterName": "TableName",
        "Configuration": "Source dataset :FintechSQLServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: @concat('UPDATE Logistic.Metadata SET PipelineStartTime = ''',utcNow(),''' Where SourceTableName =''', pipeline().parameters.TableName,''' and IsActive = 1;\r\nSelect * from Logistic.Metadata where SourceTableName =''',pipeline().parameters.TableName,''' and IsActive = 1;')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : ForEach_SourceExtraction",
        "PostComponentType": "For Each",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.4",
        "SNo": "1.2.2",
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "Cpy_SourcetoStage_copy1",
        "ComponentType": "Copydata",
        "ParameterName": " ",
        "Configuration": "\"General \r\nName : Cpy_SourcetoStage\r\nDescription : \r\nActivity state : Activated\r\nDeactivated\r\nTimeout : 00:10:00\r\nRetry : 1\r\nRetry interval (sec) : 30\r\nSecure output : Disable\r\nSecure input : Disable\r\nSource \r\nSource dataset: FintechSQLServer\r\nDataset properties\r\nSchemaName : @activity('LK_GetMetadata_TableDetails').output.firstRow.SourceSchema\r\nTableName : @activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName\r\nUse query : Table\r\nSink \r\nSink Dataset : ADLS_Parquet\r\nDataset properties \r\nFileSystem : ava-eus-synapse-ds-poc\r\nDirectory : @concat(activity('LK_GetMetadata_TableDetails').output.firstRow.BronzePath,'/',formatDateTime(utcnow(),'MMddyyyy'))\r\nFileName : @concat(activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName,'.parquet') \"",
        "PostComponentName": " ",
        "PostComponentType": " ",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.5",
        "SNo": "1.2.3",
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_StageSuccessLogs_copy1",
        "ComponentType": "Lookup",
        "ParameterName": " ",
        "Configuration": "Source dataset :FintechSQLServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: @concat('UPDATE Logistic.Metadata SET PipelineStartTime = ''',utcNow(),''' Where TableName = ''',item().TableName,''' and IsActive = 1;\r\nSelect * from Logistic.Metadata where TableName = ''',item().TableName,''' and IsActive = 1;')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : CPY_SourcetoStage",
        "PostComponentType": "Copydata",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.6",
        "SNo": "1.2.4",
        "PipelineName": "Extraction_to Bronze_Pipeline",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_StageFailureLogs_copy1",
        "ComponentType": "Lookup",
        "ParameterName": null,
        "Configuration": "General\r\nName :LK_StageFailureLogs\r\nSetting\r\nSource dataset : FintechSQLServer\r\nDataset Properties\r\nSchemaName : default\r\nTableName : default\r\nUse Query :Query\r\nQuery : @concat('UPDATE Logistic.Metadata  SET PipelineEndTime = ''',utcNow(),''', Zone = ''STAGE'',\r\nPipelineRunStatus = ''STAGE LOAD FAILED'', ErrorLogPath = ''',replace(activity('Cpy_SourcetoStage').Error.message, '''',''),''' \r\n where isactive=1 and\r\nSourceTableName =''',activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName,'''; SELECT 1;')",
        "PostComponentName": "On Success : LK_StageSuccessLogs\r\nOn Failure : LK_StageFailureLogs",
        "PostComponentType": "Lookup",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    }
];
export const transformationPipeline = [{

    "RequirementID": "CM_R_1.1",
    "DesignID": "CM_D_1.1",
    "SNo": 1.1,
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "LK_Dependency_Check",
    "ComponentType": "Look Up",
    "Configuration": "Source dataset : Delta\nTableName: default\nSchemaName: default\nFirst row only: Enabled\nUse query: query\nQuery:  Select case when A.successcount = 3 then 1 else 0 end as DependencyStatus\nfrom\n(Select count(PipelineRunstatus) as successcount  from dpg.metadata\nwhere TableID in  and PipelineRunStatus = 'Success'and cast(PipelineEndTime as date) = (Select cast(getdate() as date)))A\nQuery timeout: 120 \nIsolation level: None\nPartition option: None",
    "PostComponentName": "On Success : If conditon",
    "PostComponentType": "If Condition"
},
{
    "RequirementID": "CM_R_1.2",
    "DesignID": "CM_D_1.2",
    "SNo": 1.2,
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "If Condition1",
    "ComponentType": "If Condition",
    "Configuration": "Expression : @equals(activity('LK_Dependency_Check').output.firstRow.DependencyStatus, 1)",
    "PostComponentName": "NA",
    "PostComponentType": "NA",
    "SubComponentName": "DF_FactShipment",
    "SubComponentType": "Data flow"
},
{
    "RequirementID": "CM_R_1.3",
    "DesignID": "CM_D_1.3",
    "SNo": "1.2.1",
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "DF_FactShipment",
    "ComponentType": "Dataflow",
    "Configuration": "Dataflow Name : DF_FactShipment\nSrcloadsh Parameter -\n   DatabaseName: LogisticDB\n   SchemaName:dbo\n   TableName: loadsh\n\nFactShipment Parameter-\n    DatabaseName:\n    SchemaName:dwh\n    TableName:FactShipment",
    "PostComponentName": "On Success : LK_UpdateMetadata_Success",
    "PostComponentType": "Look Up"
},
{
    "RequirementID": "CM_R_1.4",
    "DesignID": "CM_D_1.4",
    "SNo": "1.2.1.1",
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "LK_UpdateMetadata_Success",
    "ComponentType": "Look Up",
    "Configuration": "Source dataset : Delta\nTableName: default\nSchemaName: default\nFirst row only: Enabled\nUse query: query\nQuery:  Update logistic.Metadata set PipelineRunStatus ='Success' ,LastloadedDate =(SELECT GETDATE())   where TableID = \nQuery timeout: 120 \nIsolation level: None\nPartition option: None",
    "PostComponentName": "NA",
    "PostComponentType": "NA"
},
{
    "RequirementID": "CM_R_1.5",
    "DesignID": "CM_D_1.5",
    "SNo": "1.2.1.2",
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "LK_UpdateMetadata_Failure",
    "ComponentType": "Look Up",
    "Configuration": "Source dataset : Delta\nTableName: default\nSchemaName: default\nFirst row only: Enabled\nUse query: query\nQuery:  Update logisitcs.Metadata set PipelineRunStatus ='Failure'  ,LastloadedDate =(SELECT GETDATE())  where TableID='''pipeline().values'''\nQuery timeout: 120 \nIsolation level: None\nPartition option: None",
    "PostComponentName": "NA",
    "PostComponentType": "NA"
},
{
    "RequirementID": "CM_R_1.6",
    "DesignID": "CM_D_1.6",
    "SNo": "1.2.2",
    "PipelineName": "FactShipment_DataLoad",
    "TableName": "FactShipment",
    "ComponentName": "LK_UpdateDependency_FailureStatus",
    "ComponentType": "Look Up",
    "Configuration": "Source dataset : Delta\nTableName: default\nSchemaName: default\nFirst row only: Enabled\nUse query: query\nQuery:  Update DPG.Metadata set PipelineRunStatus ='Failure' ,LastLoadedDateValue =(SELECT GETDATE() AS CurrentDateTime where TableID =\nQuery timeout: 120 \nIsolation level: None\nPartition option: None",
    "PostComponentName": "NA",
    "PostComponentType": "NA"
}
];
export const goldPipeline = [
    {
        "RequirementID": "CM_R_1.1",
        "DesignID": "CM_D_1.1",
        "SNo": 1.1,
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "LK_Dependency_Check",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Select case when A.successcount = 3 then 1 else 0 end as DependencyStatus\r\nfrom\r\n(Select count(PipelineRunstatus) as successcount  from dpg.metadata\r\nwhere TableID in  and PipelineRunStatus = 'Success'and cast(PipelineEndTime as date) = (Select cast(getdate() as date)))A\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : If conditon",
        "PostComponentType": "If Condition",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.2",
        "DesignID": "CM_D_1.2",
        "SNo": 1.2,
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "If Condition1",
        "ComponentType": "If Condition",
        "ParameterName": null,
        "Configuration": "Expression : @equals(activity('LK_Dependency_Check').output.firstRow.DependencyStatus, 1)",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": "DF_FactShipment",
        "SubComponentType": "Data flow",
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.3",
        "DesignID": "CM_D_1.3",
        "SNo": "1.2.1",
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "DF_FactShipment",
        "ComponentType": "Dataflow",
        "ParameterName": null,
        "Configuration": "Dataflow Name : DF_FactShipment\r\nSrcloadsh Parameter -\r\n   DatabaseName: LogisticDB\r\n   SchemaName:dbo\r\n   TableName: loadsh\r\n\r\nFactShipment Parameter-\r\n    DatabaseName:\r\n    SchemaName:dwh\r\n    TableName:FactShipment",
        "PostComponentName": "On Success : LK_UpdateMetadata_Success",
        "PostComponentType": "Look Up",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.4",
        "DesignID": "CM_D_1.4",
        "SNo": "1.2.1.1",
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "LK_UpdateMetadata_Success",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update logistic.Metadata set PipelineRunStatus ='Success' ,LastloadedDate =(SELECT GETDATE())   where TableID = \r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.5",
        "DesignID": "CM_D_1.5",
        "SNo": "1.2.1.2",
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "LK_UpdateMetadata_Failure",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update logisitcs.Metadata set PipelineRunStatus ='Failure'  ,LastloadedDate =(SELECT GETDATE())  where TableID='''pipeline().values'''\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.6",
        "DesignID": "CM_D_1.6",
        "SNo": "1.2.2",
        "PipelineName": "FactShipment_DataLoad",
        "TableName": "FactShipment",
        "DataflowName": null,
        "ComponentName": "LK_UpdateDependency_FailureStatus",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update DPG.Metadata set PipelineRunStatus ='Failure' ,LastLoadedDateValue =(SELECT GETDATE() AS CurrentDateTime where TableID =\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.1",
        "DesignID": "CM_D_1.7",
        "SNo": 2.1,
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "LK_Dependency_Check",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Select case when A.successcount = 3 then 1 else 0 end as DependencyStatus\r\nfrom\r\n(Select count(PipelineRunstatus) as successcount  from dpg.metadata\r\nwhere TableID in  and PipelineRunStatus = 'Success'and cast(PipelineEndTime as date) = (Select cast(getdate() as date)))A\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : If conditon",
        "PostComponentType": "If Condition",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.2",
        "DesignID": "CM_D_1.8",
        "SNo": 2.2,
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "If Condition1",
        "ComponentType": "If Condition",
        "ParameterName": null,
        "Configuration": "Expression : @equals(activity('LK_Dependency_Check').output.firstRow.DependencyStatus, 1)",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": "DF_FactLoad",
        "SubComponentType": "Data flow",
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.3",
        "DesignID": "CM_D_1.9",
        "SNo": "2.2.1",
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "DF_FactLoad",
        "ComponentType": "Dataflow",
        "ParameterName": null,
        "Configuration": "Dataflow Name : DF_FactLoad\r\nSrcloadsh Parameter -\r\n   DatabaseName: LogisticDB\r\n   SchemaName:dbo\r\n   TableName: lscarr\r\n\r\nFactShipment Parameter-\r\n    DatabaseName:LogisticDB\r\n    SchemaName:dwh\r\n    TableName:FactLoad",
        "PostComponentName": "On Success : LK_UpdateMetadata_Success",
        "PostComponentType": "Look Up",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.4",
        "DesignID": "CM_D_1.10",
        "SNo": "2.2.1.1",
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "LK_UpdateMetadata_Success",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update DPG.Metadata set PipelineRunStatus ='Success' ,LastloadedDate =(SELECT GETDATE())   where TableID =\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.5",
        "DesignID": "CM_D_1.11",
        "SNo": "2.2.1.2",
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "LK_UpdateMetadata_Failure",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update DPG.Metadata set PipelineRunStatus ='Failure'  ,LastloadedDate =(SELECT GETDATE())  where TableID=\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": "CM_R_1.6",
        "DesignID": "CM_D_1.12",
        "SNo": "2.2.2",
        "PipelineName": "FactLoad_DataLoad",
        "TableName": "FactLoad",
        "DataflowName": null,
        "ComponentName": "LK_UpdateDependency_FailureStatus",
        "ComponentType": "Look Up",
        "ParameterName": null,
        "Configuration": "Source dataset : Delta\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Enabled\r\nUse query: query\r\nQuery:  Update DPG.Metadata set PipelineRunStatus ='Failure' ,LastLoadedDateValue =(SELECT GETDATE() AS CurrentDateTime where TableID =\r\nQuery timeout: 120 \r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "NA",
        "PostComponentType": "NA",
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    }];
export const dataflowPipeline = [
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.1,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "SrcSnowflake",
        "componenttype": "Source",
        "ParameterName": "source",
        "Configuration": "Output stream name: SrcSnowflake\r\nSource type: Dataset\r\nSource type: DS_DPG\r\nOptions: Allow Schema Drift\r\nSampling : Disable\r\nOptions: Query\r\n\"SELECT * FROM \"+ $DF_DatabaseName +\".\" + $DF_SchemaName +\".\" + $DF_Delta_Sink + \" WHERE \" + $DF_LastLoadDateColumn + \" >= \" + \"'\" + toString($DF_LastLoadDateValue) + \"'\"",
        "SinkOrder": " "
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.2,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "SelectSourceColumns",
        "componenttype": "Select",
        "ParameterName": "source",
        "Configuration": "Output stream name: SelectSourceColumns\r\nIncoming stream :SrcSnowflake\r\nOptions: Skip Duplicate Input Columns\r\nSkip Duplicate Output Columns\r\nInput Columns:Auto Mapping",
        "SinkOrder": " "
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.3,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "SelectSourceColumns",
        "componenttype": "Select",
        "ParameterName": "source",
        "Configuration": "Output stream name: SelectSourceColumns\r\nIncoming stream :SrcSnowflake\r\nOptions: Skip Duplicate Input Columns\r\nSkip Duplicate Output Columns\r\nInput Columns:Auto Mapping",
        "SinkOrder": " "
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.4,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "UpsertSQLMI",
        "componenttype": "AlterRow",
        "ParameterName": "source",
        "Configuration": "Output stream name: UpsertSQLMI\r\nIncoming stream : SelectSourceColumns\r\nAlter row conditions: UpsertIF True()",
        "SinkOrder": " "
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.5,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "Stage",
        "componenttype": "Sink",
        "ParameterName": "source",
        "Configuration": "Output stream name: Stage\r\nIncoming stream : UpsertSQLMI\r\nSink type :  Dataset\r\nDataset Name : DS_DataRepository\r\nOptions: Allow Schema Drift\r\nUpdate Method:  Upsert\r\nTable Action: None\r\nPre SQL scripts:  List of scripts\r\nPost SQL script :  List of scripts\r\nKey Column: $DF_PK",
        "SinkOrder": 1
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.6,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "UpsertDeltaFiles",
        "componenttype": "AlterRow",
        "ParameterName": "source",
        "Configuration": "Output stream name: UpsertDeltaFiles\r\nIncoming stream : SelectSourceColumns\r\nAlter row conditions: UpsertIF True()",
        "SinkOrder": " "
    },
    {
        "RequirementID": "Rq.8.1",
        "Sno": 1.7,
        "TableName": "Extraction Tables",
        "DataflowName": "DF_Debtblue_Extraction",
        "ComponentName": "Delta",
        "componenttype": "Sink",
        "ParameterName": "source",
        "Configuration": "Output stream name:delta\r\nIncoming stream : UpsertSQLMI\r\nSink type : Inline\r\nDataset Name : Delta\r\nLinkedSerivce:  AzureDatalakeStorage1\r\nFolder path:\r\ntestcontainer\r\n$DF_Delta_Sink\r\nUpdate Method: Update, Upsert\r\nKey Column: $DF_PK",
        "SinkOrder": 2
    }
];
export const orchestractionDocPipeline = [
    {
        "PipelineName": "Logistics_Source_to_Bronze_to_Silver",
        "SNo": 1,
        "SubjectArea": "Extraction",
        "ComponentName": "Logistic_Extraction",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "SettingInvoked pipeline:Extraction_to Bronze_Pipeline\r\n",
        "PostComponentName": "On Success : Extraction_SourcetoStage",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "Extraction_SourcetoStage",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Logistics_Source_to_Bronze_to_Silver",
        "SNo": 2,
        "SubjectArea": "Silver",
        "ComponentName": "Execute_Silver",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline: Execute_Silver_Pipeline",
        "PostComponentName": "On Success : Extraction_SourcetoStage",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "Pipeline_Silver",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Logistics_Gold",
        "SNo": 3,
        "SubjectArea": "Gold",
        "ComponentName": "Customer_DataLoad",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline: DimCustomer_Dataload",
        "PostComponentName": "On Success :Execute_Silver",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "DimCustomer_Transformation",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Logistics_Gold",
        "SNo": 4,
        "SubjectArea": "Gold",
        "ComponentName": "Office_DataLoad",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline: DimOffice_Dataload",
        "PostComponentName": "On Success :Execute_Silver",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "DimOffice_Transformation",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Logistics_Gold",
        "SNo": 5,
        "SubjectArea": "Gold",
        "ComponentName": "LoadItems_DataLoad",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline: DimLoadItems_Dataload",
        "PostComponentName": "On Success :Execute_Silver",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "DimLoaditems_Transformation",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Logistics_Gold",
        "SNo": 6,
        "SubjectArea": "Gold",
        "ComponentName": "FactLoad_DataLoad",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline: FactLoad_Dataload",
        "PostComponentName": "On Success :Execute_Silver",
        "PostComponentType": "Execute pipeline",
        "SubComponentName": "FactLoad_Transformation",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Master_Pipeline_Logistics",
        "SNo": 7,
        "SubjectArea": "Master pipeline",
        "ComponentName": "Master_Pipeline_Logistics",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline:Logistics_Source_to_Bronze_to_Silver",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": "Logistics_Source_to_Bronze_to_Silver",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    },
    {
        "PipelineName": "Master_Pipeline_Logistics",
        "SNo": 8,
        "SubjectArea": "Master pipeline",
        "ComponentName": "Master_Pipeline_Logistics",
        "ComponentType": "Execute pipeline",
        "ParameterName": null,
        "Configuration": "Setting Invoked pipeline:Logistics_Gold",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": "Logistics_Gold",
        "SubComponentType": "Execute pipeline",
        "Comments": null
    }
];
export const unitTestCasePseudoPipeline = [
    {
        "SNo": "TC_1",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "LK_Dependency_Check",
        "ComponentType": "Lookup",
        "Details": "This table checks the particular dependent table ID and Checks in metadata whether the PipelineRunStatus is a success against a LastLoadDateValue",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_2",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "LK_Update_Dependency_FailureStatus",
        "ComponentType": "Lookup",
        "Details": "In case of failure of the LK_Dependency_Check lookup this will update PipelineRunStatus as Failure ",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_3",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "LK_Update_Metadata_Success",
        "ComponentType": "Lookup",
        "Details": "On the success of the Dataflow component this lookup, component will update the PipelienRunStatus as Success",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_4",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "Lk_Update_Metadata_Failure",
        "ComponentType": "Lookup",
        "Details": "On the Failure of the Dataflow component this lookup component will update the PipelineRunStatus as Failure ",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_5",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "Ljcustagent",
        "ComponentType": "Join",
        "Details": "In this Component we will be joinig both the cust_master table and cust_agent table based on the customer id ",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_6",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "Ljcustmastergroup",
        "ComponentType": "Join",
        "Details": "In this Component we will be joinig both the cust_master table and cust_master group_memeber table based on the customer id ",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_7",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "LKDimCustomer",
        "ComponentType": "Lookup",
        "Details": "In this Component, the source data is left joined with the Target data using the PK columns",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_8",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "PKExist",
        "ComponentType": "Conditional Split",
        "Details": "In this component, if the source primary key matches with the target primary key then the record is upserted in the sink.",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    },
    {
        "SNo": "TC_9",
        "PipelineName": "DimCustomer_DataLoad",
        "DataFlowName": "DF_DimCustomer",
        "ComponentName/TableName": "DeriveColumns",
        "ComponentType": "Derived Column",
        "Details": "In this Component end date will be derrived if it matches with existing record ",
        "SourceScripts": null,
        "TargetScripts": null,
        "Success/Failure": null
    }];
export const dataQualityPseudoPipeline = [
    {
        "RequirementID": 6.1,
        "DesignID": "D_1.1.1",
        "SNo": 1.1,
        "PipelineName": "Row Count Validation",
        "TableName": null,
        "DataflowName": null,
        "ComponentName": "LK_GetMetadata_Table",
        "ComponentType": "look Up",
        "ParameterName": null,
        "Configuration": "Source dataset :FintechSqlServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: Select * from Logistic.Metadata where IsActive = 1 and TableID in ('T1','T2','T3','T4','T5','T6','T7','T8','T9','T10')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": null,
        "SubComponentType": null,
        "SinkOrder": null
    },
    {
        "RequirementID": 6.1,
        "DesignID": "D_1.1.2",
        "SNo": 1.2,
        "PipelineName": "Row Count Validation",
        "TableName": null,
        "DataflowName": null,
        "ComponentName": "ForEach_SourceExtraction",
        "ComponentType": "For Each",
        "ParameterName": null,
        "Configuration": "Sequential: disabled\r\nBatch count: 4\r\nItems: @activity('LK_GetMetadata_Table').output.value",
        "PostComponentName": null,
        "PostComponentType": null,
        "SubComponentName": "LK_GetDebtblue_Metadata_Table",
        "SubComponentType": "Lookup ",
        "SinkOrder": null
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.3",
        "SNo": "1.2.1",
        "PipelineName": "Row Count Validation",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_GetMetadata_TableDetails",
        "ComponentType": "Look Up",
        "ParameterName": "TableName",
        "Configuration": "Source dataset :FintechSQLServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: @concat('UPDATE Logistic.Metadata SET PipelineStartTime = ''',utcNow(),''' Where SourceTableName =''', pipeline().parameters.TableName,''' and IsActive = 1;\r\nSelect * from Logistic.Metadata where SourceTableName =''',pipeline().parameters.TableName,''' and IsActive = 1;')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : ForEach_SourceExtraction",
        "PostComponentType": "For Each",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.4",
        "SNo": "1.2.2",
        "PipelineName": "Row Count Validation",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "Cpy_SourcetoStage",
        "ComponentType": "Copydata",
        "ParameterName": " ",
        "Configuration": "\"General \r\nName : Cpy_SourcetoStage\r\nDescription : \r\nActivity state : Activated\r\nDeactivated\r\nTimeout : 00:10:00\r\nRetry : 1\r\nRetry interval (sec) : 30\r\nSecure output : Disable\r\nSecure input : Disable\r\nSource \r\nSource dataset: FintechSQLServer\r\nDataset properties\r\nSchemaName : @activity('LK_GetMetadata_TableDetails').output.firstRow.SourceSchema\r\nTableName : @activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName\r\nUse query : Table\r\nSink \r\nSink Dataset : ADLS_Parquet\r\nDataset properties \r\nFileSystem : ava-eus-synapse-ds-poc\r\nDirectory : @concat(activity('LK_GetMetadata_TableDetails').output.firstRow.BronzePath,'/',formatDateTime(utcnow(),'MMddyyyy'))\r\nFileName : @concat(activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName,'.parquet') \"",
        "PostComponentName": " ",
        "PostComponentType": " ",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.5",
        "SNo": "1.2.3",
        "PipelineName": "Row Count Validation",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_StageSuccessLogs",
        "ComponentType": "Lookup",
        "ParameterName": " ",
        "Configuration": "Source dataset :FintechSQLServer\r\nTableName: default\r\nSchemaName: default\r\nFirst row only: Disabled\r\nUse query: query\r\nQuery: @concat('UPDATE Logistic.Metadata SET PipelineStartTime = ''',utcNow(),''' Where TableName = ''',item().TableName,''' and IsActive = 1;\r\nSelect * from Logistic.Metadata where TableName = ''',item().TableName,''' and IsActive = 1;')\r\nQuery timeout: 60\r\nIsolation level: None\r\nPartition option: None",
        "PostComponentName": "On Success : CPY_SourcetoStage",
        "PostComponentType": "Copydata",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    },
    {
        "RequirementID": 6.2,
        "DesignID": "D_1.1.6",
        "SNo": "1.2.4",
        "PipelineName": "Row Count Validation",
        "TableName": " ",
        "DataflowName": " ",
        "ComponentName": "LK_StageFailureLogs_copy1",
        "ComponentType": "Lookup",
        "ParameterName": null,
        "Configuration": "General\r\nName :LK_StageFailureLogs\r\nSetting\r\nSource dataset : FintechSQLServer\r\nDataset Properties\r\nSchemaName : default\r\nTableName : default\r\nUse Query :Query\r\nQuery : @concat('UPDATE Logistic.Metadata  SET PipelineEndTime = ''',utcNow(),''', Zone = ''STAGE'',\r\nPipelineRunStatus = ''STAGE LOAD FAILED'', ErrorLogPath = ''',replace(activity('Cpy_SourcetoStage').Error.message, '''',''),''' \r\n where isactive=1 and\r\nSourceTableName =''',activity('LK_GetMetadata_TableDetails').output.firstRow.SourceTableName,'''; SELECT 1;')",
        "PostComponentName": "On Success : LK_StageSuccessLogs\r\nOn Failure : LK_StageFailureLogs",
        "PostComponentType": "Lookup",
        "SubComponentName": " ",
        "SubComponentType": " ",
        "SinkOrder": " "
    }
];



export const dataQualityCodeDB = `
# Databricks notebook source
# MAGIC %md
# MAGIC ####DataValidation NoteBook

# COMMAND ----------

# MAGIC %md
# MAGIC ####Importing the Required Packages

# COMMAND ----------

## Import the Required packages
from pyspark.sql.functions import *
from delta.tables import *
from pyspark.sql import SparkSession
from pyspark.sql.types import *
from pyspark.sql import functions as F
from pyspark.sql.functions import col, row_number
from pyspark.sql.window import Window
import datetime
import pytz

# COMMAND ----------

# MAGIC %md
# MAGIC #### Including Utility Notebooks

# COMMAND ----------

# MAGIC %run 
# MAGIC "/dataplatform/Ingest/General/Utilities/Utilities"

# COMMAND ----------

# MAGIC %md
# MAGIC #### Including Error Logger Notebooks

# COMMAND ----------

# MAGIC %run 
# MAGIC "/dataplatform/Ingest/General/Utilities/Logger"

# COMMAND ----------

#Creating variables to store error log information

ErrorLogger = ErrorLogs("Data Quality NoteBook")
logger = ErrorLogger[0]
p_logfile = ErrorLogger[1]
p_filename = ErrorLogger[2]

# COMMAND ----------

## Mentioning the catalog used, getting the catalog name from key vault

CatalogName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Catalog-Name")
spark.sql(f"USE CATALOG {CatalogName}")

# COMMAND ----------

Auto_Skipper_Check=AutoSkipper('A800','Stage')

if Auto_Skipper_Check ==0:
    try:
        ErrorLog_Path_DataValidation=GetMetaDataDetails('A800').get('ErrorLogPath')
        logger.info("Successfully read the DataValidation path")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)
    except Exception as e:
        logger.info(f"Unable to read the required path from metadata {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md ##Count Check

# COMMAND ----------

# MAGIC %md
# MAGIC ####Connecting To Vision

# COMMAND ----------

##Collecting Secret values from the Azure keyVault and storing them in variables

username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=Vision_HKS;trustServerCertificate=true"

# COMMAND ----------

###Collecting Secret values from the Azure keyVault to connect the Synapse Workspace and storing them in variables

DWHusername = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-SynapseDWH-UserName")
DWHpassword = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-SynapseDWH-Password")
DWHServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-SynapseDWH-ServerName")
driver = "com.microsoft.sqlserver.jdbc.SQLServerDriver"
url = "jdbc:sqlserver://"+DWHServerName+";database=prodscdwsyndp01;User="+DWHusername+";Password="+DWHpassword+";Authentication=ActiveDirectoryPassword"

# COMMAND ----------

#Getting the list of vision raw tables in the dataframe that needs to be checked.
DF_Metadata = spark.sql("SELECT * FROM metadata.QA_Metadata where TestCase in ('RowCount') and sourcesystem = 'Vision'  ")


# COMMAND ----------

# MAGIC %md
# MAGIC #### Defining the Field Ftructure for Row Count

# COMMAND ----------

##Definition of DataFrame Schema for DF_Columns with corresponding column names and data types

DF_Logging_Columns = StructType([StructField('CheckID', StringType(),True),
                                 StructField('SourceResult',IntegerType(),True),
                                 StructField('StageResult',IntegerType(),True),
                                 StructField('RawResult',IntegerType(),True),
                                 StructField('CuratedResult',IntegerType(),True),
                                 StructField('SynapseResult',IntegerType(),True),
                                 StructField('SourceSystem',StringType(),True),
                                 StructField('Target',StringType(),True)])

# COMMAND ----------

##If AutoSkipper is true, Get the required inputs from the Metadata data frame and load it to a variable 

if Auto_Skipper_Check ==0:
  try:
    logger.info("Reading the Data")
    CheckList = DF_Metadata.select(col('CheckID')).collect()

    ##Create the Dataframe based on the structure

    DF_Logs = spark.createDataFrame([],DF_Logging_Columns)

    #Getting the CheckID from the data frame and iterate over them in for loop

    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Metadata.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Metadata.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Metadata.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Metadata.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Metadata.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Metadata.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Metadata.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Metadata.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Metadata.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Metadata.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Metadata.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Metadata.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Metadata.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Metadata.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Metadata.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Metadata.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Metadata.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Metadata.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Metadata.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Metadata.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Metadata.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Metadata.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Metadata.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Metadata.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate


        ##Reading data from Vision into dataframe based on the connection variables declared above

        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        
        ##Store the Count in the Dataframe SourceResult
        SourceResult = DF_Source.first()['Count']

        ##Execute the Count query by using spark.sql to get the Stage and Raw table count from the Vision Source

        StageResult = spark.sql(StageQuery).collect()[0][0]
        RawResult = spark.sql(RawQuery).collect()[0][0] 
        CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
        SynapseResult = (spark.read.format("jdbc")\
                      .option("driver", driver)
                      .option("url", url)
                      .option("Query",DWHquery)             
                      .load())
        SynapseResult = SynapseResult.first()['Count']
        Concat = SourceSchema+"."+SourceTableName
        Target = str(Concat)
        ##Create the Dataframe and Store the Source,Stage and raw count result in it.
        CurrentRow = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(SourceSystem),str(Target)]])
        ##perform the union between CurrentRow & DF_Logs
        DF_Logs = DF_Logs.union(CurrentRow)
        
  ##If the above Transformation load got failed, log the error in the error path
       
  except Exception as e:
    logger.info(f"Unable to load rowcount data {e}")
    dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ####Connecting To Portfolio

# COMMAND ----------

##Collecting Secret values from the Azure keyVault and storing them in variables

username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=Core;trustServerCertificate=true"

# COMMAND ----------

###Collecting Secret values from the Azure keyVault to connect the Synapse Workspace and storing them in variables

DF_Metadata = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('RowCount') and sourcesystem = 'Portfolio' ")

# COMMAND ----------

##If AutoSkipper is true, Get the required inputs from the (Metadata)data frame and load it to a variable
if Auto_Skipper_Check ==0:
    try:
        logger.info("Reading the Data")
        CheckList = DF_Metadata.select(col('CheckID')).collect()

        #Getting the CheckID from the data frame and iterate over them in for loop

        for CheckID in CheckList:
            CheckID = CheckID.CheckID
            TestCase = DF_Metadata.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
            Category = DF_Metadata.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
            SourceSystem = DF_Metadata.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
            SourceSchema = DF_Metadata.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
            SourceTableName = DF_Metadata.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
            SourceColumnName = DF_Metadata.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
            StageSchema = DF_Metadata.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
            StageTableName = DF_Metadata.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
            StageColumnName = DF_Metadata.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
            RawSchema = DF_Metadata.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
            RawTableName = DF_Metadata.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
            RawColumnName = DF_Metadata.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
            CuratedSchema = DF_Metadata.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
            CuratedTableName = DF_Metadata.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
            CuratedColumnName = DF_Metadata.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
            DWHSchema = DF_Metadata.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
            DWHTableName = DF_Metadata.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
            DWHColumnName = DF_Metadata.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
            SourceQuery = DF_Metadata.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
            StageQuery = DF_Metadata.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
            RawQuery = DF_Metadata.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
            CuratedQuery = DF_Metadata.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
            DWHquery = DF_Metadata.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
            LastLoadDate = DF_Metadata.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
            
            ##Reading data from Vision into dataframe based on the connection variables declared above
            DF_Source = (spark.read
                                .format("jdbc")
                                .option("url", jdbcUrl)
                                .option("Query", SourceQuery)
                                .option("user", username)
                                .option("password", password)
                                .load()
                                )
            
            ##Store the Count in the Dataframe SourceResult
            SourceResult = DF_Source.first()['Count']
            
            ##Execute the Count query by using spark.sql to get the Stage and Raw table count from the Vision Source
            StageResult = spark.sql(StageQuery).collect()[0][0]
            RawResult = spark.sql(RawQuery).collect()[0][0] 
            CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
            SynapseResult = (spark.read.format("jdbc")\
                  .option("driver", driver)
                  .option("url", url)
                  .option("Query", DWHquery)             
                  .load())
            SynapseResult= SynapseResult.first()['Count']
            Concat = SourceSchema+"."+SourceTableName
            Target = str(Concat)

            ##Create the Dataframe and Store the Source,Stage and raw count result in it.

            CurrentRow = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(SourceSystem),str(Target)]])

            ##perform the union between CurrentRow & DF_Logs
            DF_Logs = DF_Logs.union(CurrentRow)

    ##If the above Transformation load got failed, log the error in the error path      
    except Exception as e:
        logger.info(f"Unable to load rowcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ####Connecting To ProjectExplorer

# COMMAND ----------

##Collecting Secret values from the Azure keyVault and storing them in variables
username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=ProjectExplorer;trustServerCertificate=true"

# COMMAND ----------

#Getting the list of ProjectExplorer raw tables in the dataframe that needs to be checked.
DF_Metadata = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('RowCount') and sourcesystem = 'ProjectExplorer' ")

# COMMAND ----------

##If AutoSkipper is true, Get the required inputs from the Metadata data frame and load it to a variable 
if Auto_Skipper_Check ==0:
    try:
        logger.info("Reading the Data")
        CheckList = DF_Metadata.select(col('CheckID')).collect()

        #Getting the CheckID from the data frame and iterate over them in for loop
        for CheckID in CheckList:
            CheckID = CheckID.CheckID
            TestCase = DF_Metadata.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
            Category = DF_Metadata.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
            SourceSystem = DF_Metadata.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
            SourceSchema = DF_Metadata.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
            SourceTableName = DF_Metadata.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
            SourceColumnName = DF_Metadata.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
            StageSchema = DF_Metadata.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
            StageTableName = DF_Metadata.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
            StageColumnName = DF_Metadata.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
            RawSchema = DF_Metadata.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
            RawTableName = DF_Metadata.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
            RawColumnName = DF_Metadata.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
            CuratedSchema = DF_Metadata.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
            CuratedTableName = DF_Metadata.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
            CuratedColumnName = DF_Metadata.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
            DWHSchema = DF_Metadata.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
            DWHTableName = DF_Metadata.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
            DWHColumnName = DF_Metadata.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
            SourceQuery = DF_Metadata.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
            StageQuery = DF_Metadata.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
            RawQuery = DF_Metadata.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
            CuratedQuery = DF_Metadata.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
            DWHquery = DF_Metadata.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
            LastLoadDate = DF_Metadata.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
            
            ##Reading data from Vision into dataframe based on the connection variables declared above
            DF_Source = (spark.read
                                .format("jdbc")
                                .option("url", jdbcUrl)
                                .option("Query", SourceQuery)
                                .option("user", username)
                                .option("password", password)
                                .load()
                                )
            
            ##Store the Count in the Dataframe SourceResult
            SourceResult = DF_Source.first()['Count']
            
            ##Execute the Count query by using spark.sql to get the Stage and Raw table count from the Vision Source
            StageResult = spark.sql(StageQuery).collect()[0][0]
            RawResult = spark.sql(RawQuery).collect()[0][0] 
            CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
            SynapseResult = (spark.read.format("jdbc")\
                  .option("driver", driver)
                  .option("url", url)
                  .option("Query", DWHquery)             
                  .load())
            ##Create the Dataframe and Store the Source,Stage and raw count result in it.
            SynapseResult=SynapseResult.first()['Count']
            Concat = SourceSchema+"."+SourceTableName
            Target = str(Concat)
            CurrentRow = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(SourceSystem),str(Target)]])
            ##perform the union between CurrentRow & DF_Log
            DF_Logs = DF_Logs.union(CurrentRow)
            
    ##If the above Transformation load got failed, log the error in the error path      
    except Exception as e:
        logger.info(f"Unable to load rowcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ####Curated and Synapse Count Check

# COMMAND ----------

#Getting the list of DWH tables in the dataframe that needs to be checked.
DF_Metadata = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('RowCount') and sourcesystem = 'DWH' ")

# COMMAND ----------

##If AutoSkipper is true, Get the required inputs from the Metadata data frame and load it to a variable 
if Auto_Skipper_Check ==0:
    try:
        logger.info("Reading the Data")
        CheckList = DF_Metadata.select(col('CheckID')).collect()

        #Getting the CheckID from the data frame and iterate over them in for loop
        for CheckID in CheckList:
            CheckID = CheckID.CheckID
            TestCase = DF_Metadata.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
            Category = DF_Metadata.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
            SourceSystem = DF_Metadata.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
            SourceSchema = DF_Metadata.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
            SourceTableName = DF_Metadata.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
            SourceColumnName = DF_Metadata.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
            StageSchema = DF_Metadata.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
            StageTableName = DF_Metadata.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
            StageColumnName = DF_Metadata.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
            RawSchema = DF_Metadata.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
            RawTableName = DF_Metadata.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
            RawColumnName = DF_Metadata.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
            CuratedSchema = DF_Metadata.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
            CuratedTableName = DF_Metadata.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
            CuratedColumnName = DF_Metadata.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
            DWHSchema = DF_Metadata.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
            DWHTableName = DF_Metadata.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
            DWHColumnName = DF_Metadata.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
            SourceQuery = DF_Metadata.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
            StageQuery = DF_Metadata.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
            RawQuery = DF_Metadata.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
            CuratedQuery = DF_Metadata.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
            DWHquery = DF_Metadata.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
            LastLoadDate = DF_Metadata.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
            
            ##Reading data from Vision into dataframe based on the connection variables declared above
            DF_Source = (spark.read
                                .format("jdbc")
                                .option("url", jdbcUrl)
                                .option("Query", SourceQuery)
                                .option("user", username)
                                .option("password", password)
                                .load()
                                )
            
            ##Store the Count in the Dataframe SourceResult
            SourceResult = DF_Source.first()['Count']
            
            ##Execute the Count query by using spark.sql to get the Stage and Raw table count from the Vision Source
            StageResult = spark.sql(StageQuery).collect()[0][0]
            RawResult = spark.sql(RawQuery).collect()[0][0] 
            CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
            SynapseResult = (spark.read.format("jdbc")\
                  .option("driver", driver)
                  .option("url", url)
                  .option("Query", DWHquery)             
                  .load())
            SynapseResult=SynapseResult.first()['Count']
            Concat = SourceSchema+"."+SourceTableName
            Target = str(Concat)
            ##Create the Dataframe and Store the Source,Stage and raw count result in it.
            CurrentRow = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(SourceSystem),str(Target)]])
            ##perform the union between CurrentRow & DF_Logs
            DF_Logs = DF_Logs.union(CurrentRow)
            
    ##If the above Transformation load got failed, log the error in the error path     
    except Exception as e:
        logger.info(f"Unable to load rowcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md ##Null Count

# COMMAND ----------

# MAGIC %md ###Connecting to Vision

# COMMAND ----------

username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Vision-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=Vision_HKS;trustServerCertificate=true"

# COMMAND ----------

#Read the Metadata Table
DF_Metadata_NULL = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('NullCount') and sourcesystem = 'Vision' ")

# COMMAND ----------

# MAGIC %md
# MAGIC ###Performing the Required Validations for Vision Null Validation 

# COMMAND ----------

try:
    logger.info("Reading the Data")
    CheckList = DF_Metadata_NULL.select(col('CheckID')).collect()
    DF_NullLogs = spark.createDataFrame([],DF_LogNull_Columns)
    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Metadata_NULL.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Metadata_NULL.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Metadata_NULL.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Metadata_NULL.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Metadata_NULL.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Metadata_NULL.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Metadata_NULL.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Metadata_NULL.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Metadata_NULL.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Metadata_NULL.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Metadata_NULL.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Metadata_NULL.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Metadata_NULL.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Metadata_NULL.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Metadata_NULL.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Metadata_NULL.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Metadata_NULL.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Metadata_NULL.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Metadata_NULL.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Metadata_NULL.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Metadata_NULL.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Metadata_NULL.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Metadata_NULL.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Metadata_NULL.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
        
        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        SourceResult = DF_Source.first()['Count']

        StageResult = spark.sql(StageQuery).collect()[0][0]
        RawResult = spark.sql(RawQuery).collect()[0][0] 
        CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
        SynapseResult = (spark.read.format("jdbc")\
                      .option("driver", driver)
                      .option("url", url)
                      .option("Query",DWHquery)             
                      .load())
        SynapseResult = SynapseResult.first()['Count']
        
        Concat = SourceSchema+"."+SourceTableName+"."+SourceColumnName
        Target = str(Concat)
        NullDF = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(Target)]])
        DF_NullLogs = DF_NullLogs.union(NullDF)
        DF_NullLog = DF_Logs.union(DF_NullLogs)
          
except Exception as e:
        logger.info(f"Unable to load nullcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md ###Connecting to Portfolio

# COMMAND ----------

username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-Portfolio-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=Core;trustServerCertificate=true"

# COMMAND ----------

#Read the Metadata Table
DF_Metadata_NULL = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('NullCount') and sourcesystem = 'Portfolio' ")

# COMMAND ----------

# MAGIC %md
# MAGIC ###Performing the Required Validations for Portfolio Null Validation 

# COMMAND ----------

DF_LogNull_Columns = StructType([StructField('CheckID', StringType(),True),
                                 StructField('SourceResult',IntegerType(),True),
                                 StructField('StageResult',IntegerType(),True),
                                 StructField('RawResult',IntegerType(),True),
                                 StructField('CuratedResult',IntegerType(),True),
                                 StructField('SynapseResult',IntegerType(),True),
                                 StructField('Target',StringType(),True)])

# COMMAND ----------

try:
    logger.info("Reading the Data")
    CheckList = DF_Metadata_NULL.select(col('CheckID')).collect()
    DF_NullLogs = spark.createDataFrame([],DF_LogNull_Columns)
    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Metadata_NULL.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Metadata_NULL.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Metadata_NULL.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Metadata_NULL.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Metadata_NULL.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Metadata_NULL.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Metadata_NULL.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Metadata_NULL.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Metadata_NULL.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Metadata_NULL.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Metadata_NULL.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Metadata_NULL.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Metadata_NULL.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Metadata_NULL.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Metadata_NULL.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Metadata_NULL.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Metadata_NULL.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Metadata_NULL.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Metadata_NULL.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Metadata_NULL.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Metadata_NULL.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Metadata_NULL.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Metadata_NULL.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Metadata_NULL.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
        
        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        SourceResult = DF_Source.first()['Count']

        StageResult = spark.sql(StageQuery).collect()[0][0]
        RawResult = spark.sql(RawQuery).collect()[0][0] 
        CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
        SynapseResult = (spark.read.format("jdbc")\
                      .option("driver", driver)
                      .option("url", url)
                      .option("Query",DWHquery)             
                      .load())
        SynapseResult = SynapseResult.first()['Count']
        
        Concat = SourceSchema+"."+SourceTableName+"."+SourceColumnName
        Target = str(Concat)
        NullDF = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(Target)]])
        DF_NullLogs = DF_NullLogs.union(NullDF)
        DF_NullLog = DF_Logs.union(DF_NullLogs)
          
except Exception as e:
        logger.info(f"Unable to load nullcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ###Connecting To ProjectExplorer

# COMMAND ----------

username = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-UserName")
password = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-Password")
ServerName = dbutils.secrets.get(scope = "Store_AzureKeyVault", key = "secret-ProjectExplorer-ServerName")
jdbcUrl = "jdbc:sqlserver://"+ServerName+";databaseName=ProjectExplorer;trustServerCertificate=true"

# COMMAND ----------

#Read the Metadata Table
DF_Metadata_NULL = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('NullCount') and sourcesystem = 'ProjectExplorer' ")

# COMMAND ----------

# MAGIC %md
# MAGIC ###Performing the Required Validations for ProjectExplorer Null Validation 

# COMMAND ----------

DF_LogNull_Columns = StructType([StructField('CheckID', StringType(),True),
                                 StructField('SourceResult',IntegerType(),True),
                                 StructField('StageResult',IntegerType(),True),
                                 StructField('RawResult',IntegerType(),True),
                                 StructField('CuratedResult',IntegerType(),True),
                                 StructField('SynapseResult',IntegerType(),True),
                                 StructField('Target',StringType(),True)])

# COMMAND ----------

try:
    # logger.info("Reading the Data")
    CheckList = DF_Metadata_NULL.select(col('CheckID')).collect()
    DF_NullLogs = spark.createDataFrame([],DF_LogNull_Columns)
    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Metadata_NULL.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Metadata_NULL.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Metadata_NULL.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Metadata_NULL.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Metadata_NULL.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Metadata_NULL.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Metadata_NULL.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Metadata_NULL.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Metadata_NULL.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Metadata_NULL.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Metadata_NULL.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Metadata_NULL.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Metadata_NULL.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Metadata_NULL.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Metadata_NULL.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Metadata_NULL.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Metadata_NULL.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Metadata_NULL.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Metadata_NULL.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Metadata_NULL.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Metadata_NULL.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Metadata_NULL.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Metadata_NULL.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Metadata_NULL.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
        
        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        SourceResult = DF_Source.first()['Count']

        StageResult = spark.sql(StageQuery).collect()[0][0]
        RawResult = spark.sql(RawQuery).collect()[0][0] 
        CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
        SynapseResult = (spark.read.format("jdbc")\
                      .option("driver", driver)
                      .option("url", url)
                      .option("Query",DWHquery)             
                      .load())
        SynapseResult = SynapseResult.first()['Count']
        
        Concat = SourceSchema+"."+SourceTableName+"."+SourceColumnName
        Target = str(Concat)
        NullDF = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(Target)]])
        DF_NullLogs = DF_NullLogs.union(NullDF)
        DF_NullLog = DF_Logs.union(DF_NullLogs)
          
except Exception as e:
        logger.info(f"Unable to load nullcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ###Curated and Synapse Null Count Check

# COMMAND ----------

#Read the Metadata Table
DF_Metadata_NULL = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('NullCount') and sourcesystem = 'DWH' ")

# COMMAND ----------

DF_LogNull_Columns = StructType([StructField('CheckID', StringType(),True),
                                 StructField('SourceResult',IntegerType(),True),
                                 StructField('StageResult',IntegerType(),True),
                                 StructField('RawResult',IntegerType(),True),
                                 StructField('CuratedResult',IntegerType(),True),
                                 StructField('SynapseResult',IntegerType(),True),
                                 StructField('Target',StringType(),True)])

# COMMAND ----------

try:
    logger.info("Reading the Data")
    CheckList = DF_Metadata_NULL.select(col('CheckID')).collect()
    DF_NullLogs = spark.createDataFrame([],DF_LogNull_Columns)
    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Metadata_NULL.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Metadata_NULL.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Metadata_NULL.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Metadata_NULL.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Metadata_NULL.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Metadata_NULL.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Metadata_NULL.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Metadata_NULL.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Metadata_NULL.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Metadata_NULL.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Metadata_NULL.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Metadata_NULL.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Metadata_NULL.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Metadata_NULL.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Metadata_NULL.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Metadata_NULL.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Metadata_NULL.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Metadata_NULL.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Metadata_NULL.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Metadata_NULL.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Metadata_NULL.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Metadata_NULL.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Metadata_NULL.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Metadata_NULL.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
        
        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        SourceResult = DF_Source.first()['Count']

        StageResult = spark.sql(StageQuery).collect()[0][0]
        RawResult = spark.sql(RawQuery).collect()[0][0] 
        CuratedResult = spark.sql(CuratedQuery).collect()[0][0]
        SynapseResult = (spark.read.format("jdbc")\
                      .option("driver", driver)
                      .option("url", url)
                      .option("Query",DWHquery)             
                      .load())
        SynapseResult = SynapseResult.first()['Count']
        
        Concat = SourceSchema+"."+SourceTableName+"."+SourceColumnName
        Target = str(Concat)
        NullDF = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(Target)]])
        DF_NullLogs = DF_NullLogs.union(NullDF)
        DF_NullLog = DF_Logs.union(DF_NullLogs)
          
except Exception as e:
        logger.info(f"Unable to load nullcount data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md ##Sample Data

# COMMAND ----------

#Getting the list of tables in the data frame that needs to be checked for sample data.

DF_Meta_data = spark.sql("SELECT * FROM Metadata.QA_Metadata where TestCase in ('Value Comparison') ")

# COMMAND ----------

# MAGIC %md
# MAGIC ####Defining the Field Structure for Sample Data

# COMMAND ----------

##Definition of DataFrame Schema for DF_Columns with corresponding column names and data types

DF_Columns = StructType([StructField('CheckID', StringType(),True),
                                 StructField('SourceResult',IntegerType(),True),
                                 StructField('StageResult',IntegerType(),True),
                                 StructField('RawResult',IntegerType(),True),
                                 StructField('CuratedResult',IntegerType(),True),
                                 StructField('SynapseResult',IntegerType(),True),
                                 StructField('Target',StringType(),True)])

# COMMAND ----------

# MAGIC %md
# MAGIC ####Performing the Required Calculations for Sampledata

# COMMAND ----------

try:
    # logger.info("Connecting to Database")
    CheckList = DF_Meta_data.select(col('CheckID')).collect()
    DF = spark.createDataFrame([],DF_Columns)
    for CheckID in CheckList:
        CheckID = CheckID.CheckID
        TestCase = DF_Meta_data.select(col('TestCase')).where(col('CheckID') == CheckID).collect()[0].TestCase
        Category = DF_Meta_data.select(col('Category')).where(col('CheckID') == CheckID).collect()[0].Category
        SourceSystem = DF_Meta_data.select(col('SourceSystem')).where(col('CheckID') == CheckID).collect()[0].SourceSystem
        SourceSchema = DF_Meta_data.select(col('SourceSchema')).where(col('CheckID') == CheckID).collect()[0].SourceSchema
        SourceTableName = DF_Meta_data.select(col('SourceTableName')).where(col('CheckID') == CheckID).collect()[0].SourceTableName
        SourceColumnName = DF_Meta_data.select(col('SourceColumnName')).where(col('CheckID') == CheckID).collect()[0].SourceColumnName
        StageSchema = DF_Meta_data.select(col('StageSchema')).where(col('CheckID') == CheckID).collect()[0].StageSchema
        StageTableName = DF_Meta_data.select(col('StageTableName')).where(col('CheckID') == CheckID).collect()[0].StageTableName
        StageColumnName = DF_Meta_data.select(col('StageColumnName')).where(col('CheckID') == CheckID).collect()[0].StageColumnName
        RawSchema = DF_Meta_data.select(col('RawSchema')).where(col('CheckID') == CheckID).collect()[0].RawSchema
        RawTableName = DF_Meta_data.select(col('RawTableName')).where(col('CheckID') == CheckID).collect()[0].RawTableName
        RawColumnName = DF_Meta_data.select(col('RawColumnName')).where(col('CheckID') == CheckID).collect()[0].RawColumnName
        CuratedSchema = DF_Meta_data.select(col('CuratedSchema')).where(col('CheckID') == CheckID).collect()[0].CuratedSchema
        CuratedTableName = DF_Meta_data.select(col('CuratedTableName')).where(col('CheckID') == CheckID).collect()[0].CuratedTableName
        CuratedColumnName = DF_Meta_data.select(col('CuratedColumnName')).where(col('CheckID') == CheckID).collect()[0].CuratedColumnName
        DWHSchema = DF_Meta_data.select(col('DWHSchema')).where(col('CheckID') == CheckID).collect()[0].DWHSchema
        DWHTableName = DF_Meta_data.select(col('DWHTableName')).where(col('CheckID') == CheckID).collect()[0].DWHTableName
        DWHColumnName = DF_Meta_data.select(col('DWHColumnName')).where(col('CheckID') == CheckID).collect()[0].DWHColumnName
        SourceQuery = DF_Meta_data.select(col('SourceQuery')).where(col('CheckID') == CheckID).collect()[0].SourceQuery
        StageQuery = DF_Meta_data.select(col('StageQuery')).where(col('CheckID') == CheckID).collect()[0].StageQuery
        RawQuery = DF_Meta_data.select(col('RawQuery')).where(col('CheckID') == CheckID).collect()[0].RawQuery
        CuratedQuery = DF_Meta_data.select(col('CuratedQuery')).where(col('CheckID') == CheckID).collect()[0].CuratedQuery
        DWHquery = DF_Meta_data.select(col('DWHquery')).where(col('CheckID') == CheckID).collect()[0].DWHquery
        LastLoadDate = DF_Meta_data.select(col('LastLoadDate')).where(col('CheckID') == CheckID).collect()[0].LastLoadDate
        
        ##Reading data from source into dataframe based on the connection variables declared above
        DF_Source = (spark.read
                            .format("jdbc")
                            .option("url", jdbcUrl)
                            .option("Query", SourceQuery)
                            .option("user", username)
                            .option("password", password)
                            .load()
                             )
        
        ##Genereate hash value for the column and load it into a data frame.
        DF_Source = DF_Source.withColumn("SourceHashID",md5(concat_ws("",*SourceColumnName.split(",")))) 
        Source = DF_Source.createOrReplaceTempView('Source')
        
        
        DF_Stage = spark.sql(StageQuery)
        DF_Stage = DF_Stage.withColumn("StageHashID",md5(concat_ws("",*StageColumnName.split(","))))        
        Stage = DF_Stage.createOrReplaceTempView('Stage')
        DF_Raw = spark.sql(RawQuery)
        DF_Raw = DF_Raw.withColumn("RawHashID",md5(concat_ws("",*RawColumnName.split(","))))
        Raw = DF_Raw.createOrReplaceTempView('Raw')
        DF_SR_Result = spark.sql("select count(1) as Count from Source sr left join Stage st on sr.SourceHashID = st.StageHashID  where sr.SourceHashID is null ")
        DF_RA_Result = spark.sql("select count(1) as Count from Stage st left join Raw ra on st.StageHashID = ra.RawHashID where st.StageHashID is null ")
        StageResult = DF_SR_Result.first()['Count']
        RawResult = DF_RA_Result.first()['Count']
        CuratedResult = 0
        SynapseResult = 0
        Target = SourceSchema+"."+SourceTableName  
        DF_Sample = spark.createDataFrame([[str(CheckID),str(SourceResult),str(StageResult),str(RawResult),str(CuratedResult),str(SynapseResult),str(Target)]])
        DF = DF.union(DF_Sample)
        DF_Result_SRC = DF_NullLog.union(DF)
          
except Exception as e:
        logger.info(f"Unable to load Valuedata data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)

# COMMAND ----------

# MAGIC %md
# MAGIC ####Comparing the Values for ValidationStatus

# COMMAND ----------

DF_Result = DF_Logs.withColumn('SourcetoStageStatus', 
    F.when(F.col('SourceResult')==F.col('StageResult'), 'Passed')
    .otherwise('Failed'))
DF_Result = DF_Result.withColumn('StageToRawStatus', 
    F.when((F.col('StageResult')!=F.col('RawResult')) &   (DF_Result.SourceSystem!='DWH'), 'Failed')
    .otherwise('Passed')) 
DF_Result = DF_Result.withColumn('RawToCuratedStatus', 
    F.when((F.col('CuratedResult')!=F.col('RawResult')) & (DF_Result.SourceSystem.isin('DWH')), 'Failed')
    .otherwise('Passed')) 
DF_Result = DF_Result.withColumn('CuratedToSynapseStatus', 
    F.when(F.col('CuratedResult')==F.col('SynapseResult'), 'Passed')
    .otherwise('Failed')) 
DF_Result = DF_Result.withColumnRenamed('Target','Target')
# DF_Result = DF_Result.withColumn('SourceSummary',DF_Result.SourceResult)
# DF_Result = DF_Result.withColumn('DestinationSummary',lit(''))
DF_Result = DF_Result.withColumn("ValidatedDate", F.current_date())
DF_Result = DF_Result.withColumn('ValidationStatus',
when((DF_Result.SourcetoStageStatus=="Passed") 
& (DF_Result.StageToRawStatus=="Passed") &
(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'))
, 'Passed')
.when((DF_Result.RawToCuratedStatus=="Passed") 
& (DF_Result.CuratedToSynapseStatus=="Passed") &
(DF_Result.SourceSystem.isin('DWH'))
, 'Passed')
.otherwise('Failed'))
DF_Result = DF_Result.withColumn('FailureStage',
when((DF_Result.SourcetoStageStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'))
, 'Stage')
.when((DF_Result.StageToRawStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'))
, 'Raw')
.when((DF_Result.RawToCuratedStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('DWH'))
, 'Curated')
.when((DF_Result.CuratedToSynapseStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('DWH'))
, 'Synapse')
.otherwise(''))
DF_Result = DF_Result.withColumn('SourceSummary',when(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'),DF_Result.SourceResult)
.when(DF_Result.SourceSystem.isin('DWH'),DF_Result.RawResult)
.otherwise(' ')
)
DF_Result = DF_Result.withColumn('DestinationSummary',when(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'),DF_Result.RawResult)
.when(DF_Result.SourceSystem.isin('DWH'),DF_Result.SynapseResult)
.otherwise(' ')
)
DF_Result = DF_Result.withColumn('Difference',
when((DF_Result.SourcetoStageStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'))
, DF_Result.SourceResult-DF_Result.StageResult)

.when((DF_Result.StageToRawStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('Vision', 'ProjectExplorer', 'Portfolio'))
, DF_Result.StageResult-DF_Result.RawResult)

.when ((DF_Result.RawToCuratedStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('DWH'))
, DF_Result.RawResult-DF_Result.CuratedResult)

.when ((DF_Result.CuratedToSynapseStatus!="Passed") 
 &
(DF_Result.SourceSystem.isin('DWH'))
, DF_Result.CuratedResult-DF_Result.SynapseResult)
.otherwise('0')
)

# COMMAND ----------

# MAGIC %md
# MAGIC ####Reading the Metadata Table

# COMMAND ----------

##Read the Metadata to get the Tableid of each indvidual Table
DF_General = spark.sql("SELECT * FROM Metadata.MasterMetadata WHERE TableId in ('A800') Order By TableID asc")

# COMMAND ----------

# MAGIC %md
# MAGIC ####Merging the Data Into the Processlog Table

# COMMAND ----------

#Getting the TableID from the data frame and iterate them in loop

for i in range(1,10):
      try:
        TableList = DF_General.select(col('TableID')).collect()

        for TableID in TableList:
          TableID = TableID.TableID
          Count_Validation_Path = DF_General.select(col('CuratedPath')).where(col('TableID') == TableID).collect()[0].CuratedPath
##Check if the QA_ProcessLog table created in the mentioned path   
          if DeltaTable.isDeltaTable(spark,Count_Validation_Path) == True:
            Count_Validation_DeltaTable = DeltaTable.forPath(spark,Count_Validation_Path)

##Perform Merge to update already existing record based on condition and insert the new records in the QA_processLog table
            Count_Validation_DeltaTable.alias("Target").merge(
                DF_Result.alias("Source"),
                "Target.ValidatedDate == Source.ValidatedDate AND Target.CheckID == Source.CheckID ") \
              .whenMatchedUpdate(set =
                                 {
                                   "Target.Difference": "Source.Difference",
                                   "Target.Target": "Source.Target",   
                                   "Target.FailureStage": "Source.FailureStage",  
                                   "Target.SourceResult": "Source.SourceResult",
                                   "Target.StageResult": "Source.StageResult",   
                                   "Target.RawResult": "Source.RawResult",
                                   "Target.CuratedResult": "Source.CuratedResult",
                                   "Target.SynapseResult": "Source.SynapseResult",
                                   "Target.SourceSummary": "Source.SourceSummary",
                                   "Target.DestinationSummary": "Source.DestinationSummary",  
                                   "Target.ValidationStatus": "Source.ValidationStatus"
                                 })\
              .whenNotMatchedInsert(values =
                                    {
                                   "Target.CheckID": "Source.CheckID",
                                   "Target.Difference": "Source.Difference",
                                   "Target.Target": "Source.Target",   
                                   "Target.FailureStage": "Source.FailureStage",
                                   "Target.SourceResult": "Source.SourceResult",
                                   "Target.StageResult": "Source.StageResult",   
                                   "Target.RawResult": "Source.RawResult",
                                   "Target.CuratedResult": "Source.CuratedResult",
                                   "Target.SynapseResult": "Source.SynapseResult",
                                   "Target.SourceSummary": "Source.SourceSummary",
                                   "Target.DestinationSummary": "Source.DestinationSummary",  
                                   "Target.ValidationStatus": "Source.ValidationStatus",
                                   "Target.ValidatedDate": "Source.ValidatedDate"
                                    })\
                                    .execute()
          else:
            DF_Logs.write.format("delta").mode("overwrite").save(Count_Validation_Path)
            print('Inserted Successfully On Max of try...',i)

    ##If the QA_ProcessLog load got failed, log the error in the error path  
          
      except Exception as e:
        logger.info(f"Unable to load merge the data {e}")
        dbutils.fs.cp("file:" + p_logfile,ErrorLog_Path_DataValidation + p_filename)
        continue 
      break

`;
export const dataQualityCodeSY = {
    "name": "RowCountCheck_Transformed",
    "properties": {
        "activities": [
            {
                "name": "LK_ExtractMetadatadetails",
                "type": "Lookup",
                "dependsOn": [],
                "policy": {
                    "timeout": "0.02:00:00",
                    "retry": 1,
                    "retryIntervalInSeconds": 30,
                    "secureOutput": false,
                    "secureInput": false
                },
                "userProperties": [],
                "typeProperties": {
                    "source": {
                        "type": "SqlDWSource",
                        "sqlReaderQuery": {
                            "value": "Select * from dbo.QA_Metadata where CheckID= '@{ pipeline().parameters.CheckID}';",
                            "type": "Expression"
                        },
                        "queryTimeout": "02:00:00",
                        "partitionOption": "None"
                    },
                    "dataset": {
                        "referenceName": "sample_datasset",
                        "type": "DatasetReference",
                        "parameters": {
                            "TableName": "QA_Metadata",
                            "Schema": "dbo",
                            "DatabaseName": "hrcedlhsqlprod"
                        }
                    }
                }
            },

        ],
        "parameters": {
            "CheckID": {
                "type": "string",
                "defaultValue": "T22-C0-RowCount-L1-HRC-JDE"
            },
            "Synapse_DatabaseName": {
                "type": "string",
                "defaultValue": "hrcedlhsqlprod"
            }
        },
        "folder": {
            "name": "QA/Delivery"
        },
        "annotations": [],
        "lastPublishTime": "2024-02-09T15:02:39Z"
    },
    "type": "Microsoft.Synapse/workspaces/pipelines"
}

export const dataQualityCodeRS = `CREATE OR ALTER PROCEDURE DWH.UnitTest_CheckD_CUSTOMER_SILVER
AS
BEGIN
    SET NOCOUNT ON;

    -- Variables to store the test results
    DECLARE @testResults NVARCHAR(MAX) = '';

    -- Helper function to execute a query and get result
    DECLARE @queryResult NVARCHAR(MAX);

    -- Get row count and column names for Silver_Customer
    EXEC sp_executesql N'SELECT COUNT(*) FROM Silver.Silver_Customer', N'@queryResult NVARCHAR(MAX) OUTPUT', @queryResult OUTPUT;
    DECLARE @customerRowCount INT = CAST(@queryResult AS INT);

    EXEC sp_executesql N'SELECT COLUMN_NAME FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_NAME = ''Silver_Customer''', N'@queryResult NVARCHAR(MAX) OUTPUT', @queryResult OUTPUT;
    DECLARE @customerColumnNames NVARCHAR(MAX) = @queryResult;

    -- Store results for Silver_Customer
    SET @testResults = CONCAT(@testResults, '{"Silver_Customer":{"rowCount":', @customerRowCount, ',"columnNames":', @customerColumnNames, '}}');

       PRINT @testResults;
END;`


export const unitTestcaseCodeDB = `# Import necessary libraries for testing
from pyspark.sql import SparkSession
from pyspark.sql import functions as F
import unittest

# Initialize Spark session for testing
spark = SparkSession.builder.appName("UnitTest").getOrCreate()

# Define the test class
class CustomerGoldLoadUnitTest(unittest.TestCase):
    def setUp(self):
        # Set up necessary test data or configurations
        # For example, create a DataFrame with test data
        self.silver_data = [(1, "John Doe", "Active"), (2, "Jane Smith", "Inactive")]
        self.silver_schema = ["Customer_ID", "Customer_Name", "Status"]
        self.silver_df = spark.createDataFrame(self.silver_data, schema=self.silver_schema)

    def tearDown(self):
        # Clean up after each test
        # For example, drop temporary tables or DataFrames
        spark.catalog.dropTempView("silver_master_customer")

    def test_read_silver_zone_data(self):
        # Test reading data from the Silver Zone table 'Silver.MasterCustomer'
        self.silver_df.createOrReplaceTempView("silver_master_customer")
        result_df = spark.sql("SELECT * FROM silver_master_customer")

        # Assert that the read data matches the expected data
        self.assertTrue(result_df.collect() == self.silver_df.collect())

    def test_apply_scd_type_2_logic(self):
        # Test applying SCD Type 2 logic
        # For example, simulate adding a new record to the Silver Zone
        new_record = (3, "Bob Johnson", "Active")
        new_record_df = spark.createDataFrame([new_record], schema=self.silver_schema)
        updated_silver_df = self.silver_df.union(new_record_df)

        # Apply your SCD Type 2 logic (this is a simplified example)
        scd_type_2_result = updated_silver_df.withColumn("LoadDate", F.current_date())

        # Assert that the result matches the expected outcome
        self.assertTrue(scd_type_2_result.count() > self.silver_df.count())

    # Add more test cases for other functionalities like merge_operation, handle_exceptions, etc.

# Run the tests if the script is executed directly
if __name__ == '__main__':
    unittest.main()

`;
export const unitTestcaseCodeSY = `  "name": "RowCountCheck_Transformed",
"properties": {
    "activities": [
        {
            "name": "LK_ExtractMetadatadetails",
            "type": "Lookup",
            "dependsOn": [],
            "policy": {
                "timeout": "0.02:00:00",
                "retry": 1,
                "retryIntervalInSeconds": 30,
                "secureOutput": false,
                "secureInput": false
            },
            "userProperties": [],
            "typeProperties": {
                "source": {
                    "type": "SqlDWSource",
                    "sqlReaderQuery": {
                        "value": "Select * from dbo.QA_Metadata where CheckID= '@{ pipeline().parameters.CheckID}';",
                        "type": "Expression"
                    },
                    "queryTimeout": "02:00:00",
                    "partitionOption": "None"
                },
                "dataset": {
                    "referenceName": "sample_datasset",
                    "type": "DatasetReference",
                    "parameters": {
                        "TableName": "QA_Metadata",
                        "Schema": "dbo",
                        "DatabaseName": "hrcedlhsqlprod"
                    }
                }
            }
        },
       
    ],
    "parameters": {
        "CheckID": {
            "type": "string",
            "defaultValue": "T22-C0-RowCount-L1-HRC-JDE"
        },
        "Synapse_DatabaseName": {
            "type": "string",
            "defaultValue": "hrcedlhsqlprod"
        }
    },
    "folder": {
        "name": "QA/Delivery"
    },
    "annotations": [],
    "lastPublishTime": "2024-02-09T15:02:39Z"
},
"type": "Microsoft.Synapse/workspaces/pipelines"

`;
export const unitTestcaseCodeRS = `CREATE OR ALTER PROCEDURE DWH.UnitTest_CheckD_CUSTOMER_SILVER
AS
BEGIN
    SET NOCOUNT ON;

    -- Variables to store the test results
    DECLARE @testResults NVARCHAR(MAX) = '';

    -- Helper function to execute a query and get result
    DECLARE @queryResult NVARCHAR(MAX);

    -- Get row count and column names for Silver_Customer
    EXEC sp_executesql N'SELECT COUNT(*) FROM Silver.Silver_Customer', N'@queryResult NVARCHAR(MAX) OUTPUT', @queryResult OUTPUT;
    DECLARE @customerRowCount INT = CAST(@queryResult AS INT);

    EXEC sp_executesql N'SELECT COLUMN_NAME FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_NAME = ''Silver_Customer''', N'@queryResult NVARCHAR(MAX) OUTPUT', @queryResult OUTPUT;
    DECLARE @customerColumnNames NVARCHAR(MAX) = @queryResult;

    -- Store results for Silver_Customer
    SET @testResults = CONCAT(@testResults, '{"Silver_Customer":{"rowCount":', @customerRowCount, ',"columnNames":', @customerColumnNames, '}}');

       PRINT @testResults;
END;
`;
//end of Design phase sample pseudocode data declaration

export const rsMetadata = ``

export const rsDdl = ``

export const rsGold = ``

export const rsBronze = ``

export const rsSilver = ``