import React, { useState } from 'react';

const ViewInstructions = ({ onClose , notebooktype}) => {
  return (
    <div
      className="modal fade show d-block"
      tabIndex={-1}
      role="dialog"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="modal-dialog view-instructions-popup modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4">
                <div className="view-instructions-popup-left-section">
                  <img
                    src="images/view-instructions-popup-banner-img.png"
                    alt="view-instructions-popup-banner-img"
                    className="popup-banner-img"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="view-instructions-popup-right-section">
                  <div className="d-flex justify-content-between">
                    <h1 className="font-bold font-20 m-0">{notebooktype}{" "}Description</h1>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={onClose} // Handle close
                    />
                  </div>
                  {/* var referenceDocuments=["DataDictionary-Silver","DataDictionary-Gold",'DDLScripts-Bronze',"DDLScripts-Silver","DDLScripts-Bronze","MetadataTable","LoggerNotebook","UtilitiesNotebook","Extraction_Notebook","Silver_Notebook","Gold_Notebook","DataQuality","UnitTesting","CodeReviewChecklist","SourceToBronzeMapping","BronzeToSilverMapping","SilverToGoldMapping"]                     */}
                  {notebooktype === 'DataDictionary-Gold' && (
  <div>
   <p><br/></p> This document provides comprehensive details of the tables and columns within the Gold layer, including their data types, constraints, and descriptions. The main columns featured in this data dictionary are SubjectArea, TableName, ColumnName, DataType, Constraints, and Description.
  </div>
)}
  
 {notebooktype === 'DataDictionary-Silver' && (
  <div>
    <p><br/></p>This document provides comprehensive details of the tables and columns within the Silver layer, including their data types, constraints, and descriptions. Key elements covered are SubjectArea, TableName, ColumnName, DataType, Constraints, and Description.
  </div>
)}
  {
  notebooktype === 'DDLScripts-Bronze' && (
    <div>
        <p><br/></p>
      <p>DDLScripts-Bronze notebook contains Data Definition Language (DDL) scripts for creating tables in the Bronze layer.</p>
      <p>Each entry includes the table name and its corresponding DDL script.</p>
    </div>
  )
}
{
  notebooktype === 'DDLScripts-Silver' && (
    <div>
        <p><br/></p>
      This section contains the Data Definition Language (DDL) scripts for creating tables in the Silver layer of your data architecture. It includes two main columns: TableName, which specifies the name of each table, and DDLScripts, which provide the corresponding DDL command to create the table.
    </div>
  )
}
{
  notebooktype === 'DDLScripts-Gold' && (
    <div>
        <p><br/></p>
      This section contains the Data Definition Language (DDL) scripts for creating tables in the Gold layer of your data architecture. It includes two main columns: TableName, which specifies the name of each table, and DDLScripts, which provide the corresponding DDL command to create the table.
    </div>
  )
}
{
  notebooktype === 'MetadataTable' && (
    <div>
        <p><br/></p>
      <p>Metadata Table stores detailed information about datasets, including their source, load time, schema details, and associated paths for different data layers.</p>
      <p>Each entry provides comprehensive metadata such as source system, table identifiers, load frequency, and pipeline runtime statistics.</p>
    </div>
  )
}
{
  notebooktype === 'LoggerNotebook' && (
    <div>
        <p><br/></p>
      <p>Logger Notebook is designed to track and log the execution details, errors, and status of data pipelines. It captures comprehensive information about each step in the notebook, including its name, design identifiers, and operation descriptions, as well as internal and external review comments.</p>
    </div>
  )
}
{
  notebooktype === 'UtilitiesNotebook' && (
    <div>
        <p><br/></p>
      <p>Utilities Notebook contains reusable utility functions and scripts essential for common data processing tasks. It includes details about each utility, such as requirement and design identifiers, step names, operation types, descriptions, and review comments.</p>
    </div>
  )
}
{
  notebooktype === 'Extraction_Notebook' && (
    <div>
        <p><br/></p>
      <p>Extraction Notebook includes pseudocode for the extraction layer, which is converted to extraction code using defined prompts by the LLM. Each entry contains details such as notebook name, requirement and design IDs, steps, operation descriptions, and review comments.</p>
    </div>
  )
}
{
  notebooktype === 'Silver_Notebook' && (
    <div>
        <p><br/></p>
 Contains the pseudocode for transformation logic and scripts required for processing data in the Silver layer. It includes detailed information such as notebook names, requirement and design IDs, cell names, steps, flow, operation types, descriptions, and review comments.
    </div>
  )
}
{
  notebooktype === 'Gold_Notebook' && (
    <div>
        <p><br/></p>
      Contains the pseudocode for the Gold layer, including transformation logic and scripts for data processing. Details covered include notebook names, requirement and design IDs, cell names, steps, flow, operation types and descriptions, along with internal and external review comments.
    </div>
  )
}
{
  notebooktype === 'DataQuality' && (
    <div>
        <p><br/></p>
       Defines data quality checks, rules, and validation processes. It encompasses notebook names, requirement and design IDs, cell names, steps, flow, operation types, descriptions, and review comments.
    </div>
  )
}

{
  notebooktype === 'UnitTesting' && (
    <div>
      <p><br/></p> Incorporates test cases and scripts to validate the functionality of individual components. It includes details such as notebook names, requirement and design IDs, cell names, steps, flow, operation types, descriptions, and review comments.
    </div>
  )
}

{
  notebooktype === 'CodeReviewChecklist' && (
    <div>
      <p><br/></p> Provides a checklist to ensure code quality, best practices, and standards during code reviews. Details include notebook names, design IDs, command purposes, checklist steps, status, and comments. The status column will be updated based on whether each item has passed or failed during the review.
    </div>
  )
}

{
  notebooktype === 'SourceToBronzeMapping' && (
    <div>
        <p><br/></p>
      <p>SourceToBronzeMapping defines the mapping and transformation rules required to move data from source systems to the Bronze layer. It includes detailed information about the source and target schemas, transformation rules, business and surrogate keys, as well as constraints and additional comments.</p>
    </div>
  )
}

{
  notebooktype === 'BronzeToSilverMapping' && (
    <div>
        <p><br/></p>
      <p>BronzeToSilverMapping outlines the transformation logic and rules for refining data from the Bronze layer to the Silver layer. It includes comprehensive details about the mapping between the Bronze and Silver schemas, transformation rules, business and surrogate keys, constraints, and additional comments.</p>
    </div>
  )
}


{
  notebooktype === 'SilverToGoldMapping' && (
    <div>
        <p><br/></p>
      <p>SilverToGoldMapping specifies the business logic and rules for transforming data from the Silver layer to the Gold layer, preparing it for final consumption. It includes detailed mappings between Silver and Gold schemas, transformation rules, business and surrogate keys, constraints, and additional comments.</p>
    </div>
  )
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default ViewInstructions;