import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import CatalogComponent from './CatalogComponent.jsx';
import ViewInstructions from './viewInstructions.jsx';

function DataDictionary(props) {
    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobURL}?${sasToken}`;

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();

            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            document.getElementById('pageLoader').style.display = "none";
            document.getElementById('openErrorModal').click();
            throw error;
        }
    };

    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState([]);
    const [subjectArea, setSubjectArea] = useState([]);
    const [schema, setSchema] = useState([]);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [blobUrl, setBlobUrl] = useState('');
    const [selectedCatalog, setSelectedCatalog] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    // const handleReasonSave = async () => {
    //     debugger
    //     let updatedData;
    //     try {
    //         switch (currentOperation) {
    //             case 'edit':
    //                 updatedData = actualValue.map(item =>
    //                     item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
    //                 );
    //                 break;
    //             case 'add':
    //                 updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
    //                 break;
    //             case 'delete':
    //                 updatedData = actualValue.filter(item => item.index !== currentItem.index);
    //                 break;
    //             default:
    //                 throw new Error('Invalid operation');
    //         }
    //         let body = {
    //             type: "dataDictionary",
    //             projectId: state.projectId,
    //             userId: state.userId,
    //             orgId: state.orgId,
    //             comments: reason,

    //         }
    //         let res = await updatePseudocodeData(body, state.jwt)
    //         console.log(res, "11111111111111111111111111111111111111111111")
    //         await updateJsonInBlob(blobUrl, updatedData);

    //         // Update local state with original data and re-apply filter
    //         setActualValue(updatedData);
    //         setGridData(updatedData); // Optionally: apply previous filter/search to updatedData

    //         setShowReasonModal(false);
    //         setReason('');
    //         setCurrentOperation(null);
    //         setCurrentItem(null);
    //         setWhetherUpdated(true);

    //     } catch (error) {
    //         console.error('Error updating data:', error);
    //     }
    // };



    const handleReasonSave = async () => {
        try {
             let updatedData;
             switch (currentOperation) {
                case 'edit':
                    updatedData = actualValue.map(item =>
                        item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                    );
                    break;
                case 'add':
                    updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                    break;
                case 'delete':
                    updatedData = actualValue.filter(item => item.index !== currentItem.index);
                    break;
                default:
                    throw new Error('Invalid operation');
            }
    let body={
                    type: "dataDictionary", 
                    projectId: state.projectId, 
                    userId: state.userId,
                    orgId: state.orgId,
                    comments:reason,
     
                }
              let res=  await updatePseudocodeData(body,state.jwt)
                await updateJsonInBlob(blobUrl, updatedData);
            await updateJsonInBlob(blobUrl, updatedData);
     
            // Update local state with original data and re-apply filter
            setActualValue(updatedData);
            setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
            setSearchFilterTrigger(prev => prev + 1);

            setShowReasonModal(false);
            setReason('');
            setCurrentOperation(null);
            setCurrentItem(null);
            setWhetherUpdated(true);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };
    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobUrl}?${sasToken}`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            document.getElementById('openErrorModal').click();
            throw error;
        }
    };

    const [settings, setSettings] = useState({
        TableConfig: [
            props.subjectArea && { key: 'SubjectArea', header: 'SubjectArea', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'SourceSchemaName', header: 'SchemaName', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'SourceTableName', header: 'TableName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'SourceColumnName', header: 'ColumnName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'DataType', header: 'DataType', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'Constraints', header: 'Constraints', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'Description', header: 'Description', sortable: true, type: 'normal', deFaultRow: 'input' },
        ].filter(Boolean),
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: [
            ...(props.subjectArea ? [{ key: 'SubjectArea', values: [] }] : []),
            { key: 'SourceSchemaName', values: [schema] },
            { key: 'Constraints', values: ["NULL", "NOTNULL", "PRIMARY KEY", "FOREIGN KEY", "UNIQUE"] }
        ]
    });

    const searchColumns = ['SubjectArea', 'SourceTableName', 'SourceColumnName', 'SourceSchemaName'];
    const FilterColumns = props.subjectArea
        ? ['SubjectArea', 'SourceTableName']
        : ['SourceTableName'];

    useEffect(() => {
        const initializeData = async () => {
            try {
                document.getElementById('pageLoader').style.display = "block";
                const data = { type: "inventorydd", projectId: state.projectId, userId: state.userId };
                const dropDownValues = await fetchDropDownValues(data, state.jwt);

                console.log(dropDownValues, 'dropDownValuesdropDownValuesdropDownValues');

                if (dropDownValues?.data?.oldCatalogName?.length > 0) {
                    setDropDownValues(dropDownValues.data.oldCatalogName);
                    setSubjectArea(dropDownValues.data.subjectArea)

                    // Set the first catalog as default if none is selected
                    const catalogToUse = selectedCatalog || dropDownValues.data.oldCatalogName[0];
                    setSelectedCatalog(catalogToUse);

                    setSettings(prevSettings => ({
                        ...prevSettings,
                        deFaultRow: prevSettings.deFaultRow.map(row =>
                            row.key === 'SourceSchemaName'
                                ? { ...row, values: dropDownValues.data.oldSchemaName || [] }
                                : row
                        )
                    }));
                    setSettings(prevSettings => ({
                        ...prevSettings,
                        deFaultRow: prevSettings.deFaultRow.map(row =>
                            row.key === 'SubjectArea'
                                ? { ...row, values: dropDownValues.data.subjectArea || [] }
                                : row
                        )
                    }));

                    // Fetch data for the selected catalog
                    await fetchPseudoForCatalog(catalogToUse);
                }
            } catch (error) {
                handleError(error);
            } finally {
                document.getElementById('pageLoader').style.display = "none";
            }
        };

        initializeData();
    }, []); // Empty dependency array for initial load

    // Separate useEffect for catalog changes
    useEffect(() => {
        if (selectedCatalog) {
            fetchPseudoForCatalog(selectedCatalog);
        }
    }, [selectedCatalog]);

    const fetchPseudoForCatalog = async (catalogName) => {
        document.getElementById('pageLoader').style.display = "block";
        try {
            const body = {
                projectId: state.projectId,
                type: "ucdatadictionary",
                catalogName: catalogName,
                userId: state.userId,
                orgId: state.orgId,
            };

            const res = await fetchPseudocodeData(body, state.jwt);

            if (res.statusCode === 403) {
                sessionExpired();
                return;
            }

            if (res.statusCode !== 200) {
                throw new Error("Failed to fetch pseudo data");
            }

            const blobURL = res?.data[0]?.DataDictionary;
            if (!blobURL) {
                throw new Error("No blob URL found in response");
            }

            setBlobUrl(blobURL);
            const jsonData = await fetchJsonFromBlob(blobURL);

            if (jsonData) {
                const pseudo = jsonData.map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                setGridData(pseudo);
                setActualValue(pseudo);
            }
        } catch (error) {
            handleError(error);
        } finally {
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleError = (error) => {
        console.error("Error:", error);
        insertError({
            errorMessage: error.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        setError(error.message);
        document.getElementById('openErrorModal').click();
    };


    const fieldsToDownload = ['SourceCatalogName', 'SourceSchemaName', 'SourceTableName', 'SourceColumnName', 'DataType', 'Constraints', 'Description'];
    const filteredData = actualValue.map(item => {
        const filteredItem = {};
        fieldsToDownload.forEach(field => {
            if (item[field] !== undefined) {
                filteredItem[field] = item[field];
            }
        });
        return filteredItem;
    });


    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} alt="Session Icon" />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                            <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button>
                        </h2>
                        {isModalOpen && <ViewInstructions notebooktype='inventoryDocument' onClose={handleCloseModal} />}
                    </div>

                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                            searchFilterTrigger={searchFilterTrigger}
                        />
                        <CatalogComponent
                            catalogNames={dropDownValues}
                            selectedCatalog={selectedCatalog}
                            setSelectedCatalog={setSelectedCatalog}
                            
                        />
                        <FilterComponent
                            columns={FilterColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                            searchFilterTrigger={searchFilterTrigger}
                        />
                        <DownloadComponent
                            type="excel"
                            data={filteredData}
                            fileName="DataDictionary"
                        />
                    </div>

                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    newRow={newRow}
                    setNewRow={setNewRow}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
            </div>
        </div>
    );
}

export default DataDictionary;