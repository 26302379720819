import React, { useState, useEffect, } from 'react';
import { decode } from 'base-64';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { getNotifications } from '../Service/Api'; // Assuming this is the correct import path

export function HeaderCom(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("statee-------", state);
  const [AllNotifications, setAllNotifications] = useState([]);
  const [activeNotification, setActiveNotification] = useState([]);
  const [defaultNotification, setDefaultNotification] = useState([]);
  const [viewAllNotification, setViewAllNotification] = useState(false);

  useEffect(() => {
    fetchNotification();
  }, []); // Add dependencies here





  async function fetchNotification() {
    try {
      const role = state.role
      const data = await getNotifications(role, state.userId, state.orgId, state?.jwt);

      if (data.statusCode === 403 || data.status === 403) {
        sessionExpired(); // Handle session expiration
        return;
      } else if (data.status !== 200) {
        console.log("Error response for getnotification");
        // Optionally trigger an error modal
        // document.getElementById('openErrorModal').click();
      } else {
        const notificationData = data.data[0];
        setAllNotifications(notificationData);

        console.log(notificationData, "record from db of zero");

        // Filter active notifications
        let activeNotification = notificationData.filter(notif => notif.isActive === true);
        console.log(activeNotification, "active notifications record");

        // If there are already two active notifications, add one inactive notification
        if (activeNotification.length >= 2) {
          const inactiveNotification = notificationData.find(notif => notif.isActive === false);
          if (inactiveNotification) {
            activeNotification.push(inactiveNotification);
          }
        }

        // Set active notifications state
        setActiveNotification(activeNotification);

        // Set default notifications state
        const defaultNotifs = activeNotification.length > 0 ?
          (activeNotification.length <= 3 ? activeNotification : activeNotification.slice(0, 3))
          : AllNotifications.slice(0, 3);
        setDefaultNotification(defaultNotifs);

        console.log(defaultNotifs, "default notifications");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle the error, for example, display an error message
    }
  }



  // console.log("try out")
  // PC_NO_42
  // The formatTimestamp function takes a timeStamp as input and formats it into a human-readable string representing how long ago the timeStamp occurred. It calculates the time difference between the current time and the given timeStamp in minutes, hours, and days. Based on this difference, it returns a string indicating the time elapsed, such as "X days ago", "X hours ago", "X minutes ago", or "Just now" if the difference is less than a minute.


  function formatTimestamp(timeStamp) {
    ;
    const currentTime = moment(); // Get current time
    const notificationTime = moment(timeStamp); // Parse timeStamp

    // Calculate the duration between now and the notification time
    const duration = moment.duration(currentTime.diff(notificationTime));

    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    if (days >= 1) {
      return `${Math.floor(days)} day${Math.floor(days) > 1 ? 's' : ''} ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hr${Math.floor(hours) > 1 ? 's' : ''} ago`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)} min${Math.floor(minutes) > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }



  function bindDataNotification() {
    // console.log("enter bindData");

    return (
      <ul className="list-unstyled my-4 mt-0">
        {defaultNotification.length === 0 ? (
          <li className="px-3 py-3 border-bottom">
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <p className="font-12 primary-textcolor align-items-center font-regular mb-1" style={{ paddingLeft: '100px', fontSize: '15px', fontWeight: 'bold' }}>
                  No Records Found
                </p>
              </div>
            </div>
          </li>
        ) : (
          defaultNotification.map((notification, index) => (
            <li key={index} className="px-3 py-3 border-bottom">
              <div className="d-flex align-items-center">
                {notification.isActive ? <span className="purple-dot me-2" /> : ""}
                <img src="images/sample-icon.svg" alt="sample-icon" className="notification-prof" />
                <div className="ms-3">
                  <p className="font-12 primary-textcolor font-regular mb-1">
                    <span className="font-bold">{notification.message}</span>
                  </p>
                  <p className="font-12 font-regular d-flex align-items-center my-2">
                    <img src="images/solid-doc-icon.svg" alt="Document Icon" className="me-2" />
                    {notification.phase} Document
                  </p>
                  <p className="font-10 font-regular d-flex align-items-center text-grey-v4 mb-0">
                    {formatTimestamp(notification.timeStamp)}
                  </p>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    );
  }


  // console.log("out from binddata")

  const sessionExpired = () => {
    localStorage.clear();
    document.getElementById('toastMessage').style.display = "block";
    setTimeout(() => {
      document.getElementById('toastMessage').style.display = "none";
      navigate('/');
    }, 3000);
  };

  function handleViewAll() {
    setViewAllNotification(true);
    if (state.adRole === 1 || state.adRole === 2) {
      navigate('/request', { state: state });
    } else if (state.adRole === 3) {
      setDefaultNotification(AllNotifications);
    }
  }



  return (
    <div>
      <div class="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
        <div id="toastMessage" class="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
          <div class="d-flex">
            <div class="toast-body">
              <span>
                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
              </span>
              <span class="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2 ">
        <div className="d-flex align-items-center py-1">
          <a className="navbar-brand cust-cursor-pointer"><img src="images/codegen-logo.svg" onClick={() => navigate('/', { state })} alt="codegen-logo" style={{ paddingLeft: '10px' }} className="logo-sty" /></a>
          <div className="ms-5">
            <button className={props.value === '1' ? "header-button-active font-bold" : 'header-button font-bold'} onClick={() => navigate('/manageOrganization', { state })}>Organization</button>
            <button className={props.value === '2' ? "header-button-active font-bold ms-2" : 'header-button ms-2 font-bold'} onClick={() => navigate('/activityLogs', { state })}>Activity Logs</button>
            {(state.adRole === 1 || state.adRole === 2) &&
              <button className={props.value === '3' ? "header-button-active font-bold ms-2" : 'header-button ms-2 font-bold'} onClick={() => navigate('/request', { state })}>Manage Request</button>
            }
            <button className={props.value === '4' ? "header-button-active font-bold ms-2" : 'header-button ms-2 font-bold'} onClick={() => navigate('/usecases', { state })}>Use Case Prompt</button>
          </div>
          <div className="d-lg-flex d-none flex-row align-items-center ms-auto">
            <div className="btn-group me-3 cursor-pointer position-relative">
              <img data-bs-toggle="dropdown" aria-expanded="false" src="images/bell.svg" alt="notification-icon" className="header-profile position-relative" style={{ cursor: 'pointer' }} onClick={fetchNotification} />
              <span className="dropdown-menu notification-container shadow-sm border-0">
                <div className="d-flex justify-content-between align-items-center px-4 py-1">
                  <span className="width d-flex justify-content-between primary-textcolor font-bold font-16" >Notifications</span>
                  {defaultNotification.length > 0 ? (<a className="cursor-pointer font-12 font-medium secondary-textcolor text-decoration-none" onClick={handleViewAll}>View All</a>)
                    : ""
                  }
                </div>
                {bindDataNotification()}
              </span>
            </div>
            <div className="dropdown me-lg-3 ms-auto">
              <img
                src={`${localStorage?.getItem('dXNlckltYWdl1') ? decode(localStorage?.getItem('dXNlckltYWdl1').substring(15, localStorage.getItem('dXNlckltYWdl1').length - 10)) : 'images/userDefault.svg'}`}
                alt="user-profile"
                style={{ borderRadius: '20px' }}
                className="profile-sty cursor-pointer"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                {state.adRole === 1 &&
                  <li><a className="dropdown-item text-black font-16 user-dd-sty font-bold d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => navigate('/manageRoles', { state })}>Manage Roles</a></li>
                }
                <li>
                  <a
                    className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.removeItem('Y2hlY2tMb2dpbg');
                      localStorage.removeItem('dXNlcklk');
                      localStorage.removeItem('dG9rZW4');
                      localStorage.removeItem('dXNlckltYWdl1');
                      navigate('/');
                    }}

                  >
                    <span className="me-2">
                      <img src="images/logout.svg" alt="profile-icon" />
                    </span>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}