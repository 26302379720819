import React, { useState, useEffect, useRef } from "react";
// PS_CD_1.2
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, updatePseudocodeData } from "../Service/Api.js";

import { saveAs } from 'file-saver'

import PlantUML from "react-plantuml";
import { HeaderCom } from "./Header.jsx";

function GoldDBSequence(props) {

    console.log(props, "propssss");
    // PS_CD_1.3
    const location = useLocation();
    // PS_CD_1.4 
    const nav = useNavigate();
    // PS_CD_1.9
    let { state } = useLocation();

    state = { ...state, previous: "prev" };
    // PS_CD_1.5

    const [isEdited, setIsEdited] = useState(false);

    const [jsonData, setJsonData] = useState([]);
    // PS_CD_1.6
    const [selectedItem, setSelectedItem] = useState({});
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)

    const [popupErrors, setPopupErrors] = useState({});
    const [isEditMetavalue, setisEditMetavalue] = useState(false);
    //PS_CD_1.62
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    // PS_CD_1.7

    const [formValid, setFormValid] = useState(false);
    const [DropDownSubjectArea, setDropDownSubjectArea] = useState([]);
    const [DropDownSchemaName, setDropDownSchemaName] = useState([]);
    const [DropDownTableName, setDropDownTableName] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState(null);
    const [GoldSequence, setGoldSequence] = useState("");
    const [whetherUpdated, setwhetherUpdated] = useState(false);


    // const [inputValues, setInputValues] = useState({})
    const [subjectArea, setSubjectArea] = useState([])
    const [schemaName, setSchemaName] = useState([])
    const [body, setBody] = useState({})

    const [IsFilteredApplied, setIsFilteredApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [code, setCode] = useState();
    const [zoom, setZoom] = useState(1);
    const [constraints, setConstraints] = useState(["NULL", "NOTNULL", "Primary Key", "Foreign Key", "Unique"]);
    // const[DropDownSubjectArea,setDropDownSubjectArea]=useState([]);
    // PS_CD_1.8

    // manually assignment'
    let uniqueTableNames = [];
    let uniqueSubjectAreas = []
    const elementRef = useRef(null)
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                 
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [pseudoData] = await Promise.all([pseudoDataPromise]);
                console.log("res from fetch pseudo", pseudoData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleZoomIn = () => {
        setZoom(zoom + 0.1);
    };

    const handleZoomOut = () => {
        if (zoom > 0.2) {
            setZoom(zoom - 0.1);
        }
    };
    const fetchPseudo = async () => {
        try {

            let data = { type: "goldSequence", projectId: state.projectId,notebookId:props.data.notebookId,userId:state.userId}
            let jwt = state.jwt;
            document.getElementById('pageLoader').style.display = "block"
             
            let pseudodata = await fetchPseudocodeData(data, jwt)
             
            document.getElementById('pageLoader').style.display = "none"
            console.log("GoldSequence", pseudodata);
            if (pseudodata.statusCode == 200) {
                console.log("res from fetchPseudocodeData", pseudodata.data[0].sequence);
                setGoldSequence(pseudodata.data[0].sequence);
                setResponse(pseudodata.data[0].sequence);
            }
            
        }
        catch (e) {
            console.log("error in fetch", e);

        }
    }
    // const handleSaveDetails = async () => {
    //     setwhetherUpdated(true)
    //     console.log("in save details");
    //     let body = {
    //         type: "goldSequence",
    //         body: GoldSequence,
    //         notebookId:props.data.notebookId,
    //         userId: state.userId,
    //         projectId: state.projectId,
    //         orgId: state.orgId,
    //         comments: reason
    //     }
         
    //     let response = await updatePseudocodeData(body, state.jwt)
    //     console.log("updaed res", response);
    //     if (response.statusCode == 200) {
    //         let pseudo = JSON.parse(response.data.recordset[0].sequence)
    //         console.log("res from updated data", pseudo);

    //         setGoldSequence(pseudo)
    //         setResponse(pseudo)
    //         setIsEdited(false)

    //     }

    // };


    const handleCaptureClick = () => {
        try {
            
        
        const imgElement = document.getElementById('daimage').getElementsByTagName('img')[0];
        let imageUrl = imgElement.src;
       
        // Ensure we're using HTTPS
       
        if (imageUrl.startsWith('http:')) {
            imageUrl = imageUrl.replace('http://', 'https://');
        }
   
        const xhr = new XMLHttpRequest();
        xhr.open('GET', imageUrl, true);
        xhr.responseType = 'blob';
   
        xhr.onload = function() {
            if (this.status === 200) {
                const blob = this.response;
                const reader = new FileReader();
                reader.onloadend = function() {
                    const base64data = reader.result;
                   
                    // Create a temporary anchor element
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = base64data;
                    a.download = 'Gold Sequence Diagram.svg';
                   
                    // Append to the body and click to trigger download
                    document.body.appendChild(a);
                    a.click();
                   
                    // Clean up
                    document.body.removeChild(a);
                }
                reader.readAsDataURL(blob);
            } else {
                console.error('Error fetching the image');
                alert('Error: Unable to download the image');
            }
        };
   
        xhr.onerror = function() {
            console.error('Network error occurred');
            alert('Error: Network error occurred while trying to download the image');
        };
   
        xhr.send();
    } catch (error) {
        console.log(error);
            
    }
    };

    return (
        <div className="container-fluid">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
              <HeaderCom value ='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}

                    <div className="col-md-12 px-4">

                        {/* <div className="row justify-content-center mt-3"> */}
                            {/* <div className="col-md-12 col-lg-12 col-sm-12 pt-4"> */}
                                {/* <div className="col-md-12 pt-4 mt-5"> */}
                                    {/* Breadcrumbs starts here */}
                                    <div className="mt-3 px-2">
                                        <ul className="cust-breadcrumb mb-0 ">
                                            <li className="font-16 font-medium">
                                                <a >Manage Projects</a>
                                            </li>
                                            <li className="font-16 font-medium">
                                                <a >{state.projectName}</a>
                                            </li>
                                            <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                                        </ul>
                                    </div>
                                    {/* Breadcrumbs ends here */}
                                    <div className="d-flex align-items-center p-4">
                                        <a  className="d-flex">
                                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px',cursor:"pointer",whiteSpace: 'nowrap' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/>
                                        </a>
                                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-16 font-medium ms-auto"
                                            // data-bs-dismiss="modal"
                                            onClick={() => handleCaptureClick()}
                                        >
                                            <span className="me-2">
                                                <img src="images/download-icon.svg" alt="add-icon" />
                                            </span>{" "}
                                            Download
                                        </button>
                                    </div>
                                    <div className="col-md-12 px-4">
                                        {/* Accordion starts here */}
                                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                                <button
                                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#logger-notebook"
                                                >
                                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                                    <span
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#logger-notebook"
                                                        className="cursor-pointer text-black font-16 font-medium"
                                                    >
                                                        View Instructions
                                                    </span>
                                                </button>
                                            </p>
                                            <div className="row">
                                                <div className="collapse show" id="logger-notebook">
                                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                                            <li className="mb-2">
                                                                Please validate whether the data’s are correct
                                                            </li>
                                                            <li className="mb-2">
                                                                If things are fine just click{" "}
                                                                <span className="text-black-v2 font-bold">‘back’ </span>then on{" "}
                                                                <span className="text-black-v2 font-bold">‘Looks good’</span>{" "}
                                                                and proceed to next steps{" "}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Accordion ends here */}
                                        {/* Error logger textarea starts here */}
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                                <div className="">
                                                    <div className="">
                                                        <textarea
                                                            className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                                            id="exampleFormControlInput1"
                                                            // defaultValue={"code"}
                                                            value={GoldSequence}
                                                            style={{ height: '700px' }}
                                                            onChange={(e) => { setGoldSequence(e.target.value); setIsEdited(true) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                                <div className="">
                                                    <div className="position-relative">
                                                        <div className="position-absolute custom-btn-position bg-white rounded-3 test-name" style={{ zIndex: 1 }}>
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-in custom-bottom-brd new-style-added"
                                                                onClick={handleZoomIn}
                                                            >
                                                                <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-out new-style-added"
                                                                onClick={handleZoomOut}
                                                            >
                                                                <img
                                                                    className=""
                                                                    src="images/zoomout-icon.svg"
                                                                    alt="zoom-out"
                                                                />
                                                            </button>
                                                        </div>
                                                        {/* <textarea
                                                            className="form-control custom-textarea logger-textarea-scroll"
                                                            id="exampleFormControlInput1"
                                                        /> */}

                                                        <div style={{ transformOrigin: 'top left', overflow: 'auto', height: '710px', }}>
                                                            <div className='div2' style={{ transform: `scale(${zoom})` }}>
                                                                <div  id='daimage'>
                                                                    <PlantUML src={GoldSequence} /></div>
                                                            </div>
                                                        </div>
                                                       
                                                        {/* <div className="position-absolute custom-btn-position bg-white rounded-3 test-name">
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-in custom-bottom-brd new-style-added"
                                                                onClick={handleZoomIn}
                                                            >
                                                                <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-common-style btn-zoom-out new-style-added"
                                                                onClick={handleZoomOut}
                                                            >
                                                                <img
                                                                    className=""
                                                                    src="images/zoomout-icon.svg"
                                                                    alt="zoom-out"
                                                                />
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="modal fade "
                                            id="editRows"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            aria-labelledby="ConfrmLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h5
                                                            className="modal-title d-flex align-items-center font-medium font-20"
                                                            id="ConfrmLabel"
                                                        >
                                                            Edit Reason
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        />
                                                    </div>
                                                    <div className="modal-body py-0">
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12">
                                                                <label
                                                                    htmlFor="User-Name"
                                                                    className="form-label text-black font-14 font-medium"
                                                                >
                                                                    Specify reason for manual edit
                                                                    <span className="text-red"> *</span>
                                                                </label>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control font-14 font-regular "
                                                                    id="User-Name"
                                                                    placeholder="Enter reason"
                                                                    rows={5}
                                                                    defaultValue={null}
                                                                    value={reason}
                                                                    onChange={(e) => { setReason(e.target.value) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>





    )
}

export default GoldDBSequence;