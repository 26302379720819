import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Maincomponent from './Component/MainComponent'
import LoginAuthComponent from '../src/Component/LoginAuthComponent'
import ManageOrganizations from './Component/ManageOrganizations';
import ManageProjects from './Component/ManageProjects';
import ProjectInfo from './Component/projectInfo';
import ManageRoles from './Component/ManageRoles';
// import Logger from './Component/LoggerGridPseudocode'
import './Styles/css/commontheme.css'
import './Styles/css/colors.css'
import './Styles/css/codegen.css'
import './Styles/css/login.css'
import './Styles/css/fonts.css'
import './Styles/css/form.css'
import './Styles/css/grid.css'
import './Styles/css/popup.css'
import Chat from './Component/ChatMain';
import Activitylog from './Component/Activitylog';
import 'bootstrap'
import NewOrganisation from './Component/NewOrganisation';
import Requests from '../src/Component/ManageRequest';
// import 'bootstrap/js/dist/popover'
// import MetaDataTable from './Component/metaGrid';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Maincomponent />} />
        <Route path="/auth/cb" element={<LoginAuthComponent />}></Route>
        <Route path="/ProjectInfo" element={<ProjectInfo />}></Route>
        <Route exact path='/manageOrganization' element={<ManageOrganizations />}></Route>
        <Route exact path='/manageProjects' element={<ManageProjects />}></Route>
        <Route path='/chat' element={<Chat/>} />
        <Route path='/activityLogs' element={<Activitylog />} /> 
        <Route path='/newOrg' element={<NewOrganisation />} /> 
        <Route path='/request' element={<Requests/>} > </Route>
        <Route path='/manageRoles' element={<ManageRoles/>} > </Route>
        {/* <Route path='/metaGrid' element={<MetaDataTable />}></Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;