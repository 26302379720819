//Import the nescessary modules and the packages
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import DynamicFileUpload from './dynamicFileUpload';
import TableMappingPopup from './tableMappingPopup';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import PlantUML from "react-plantuml";
import { inventoryGeneration } from '../../Service/Api';
import { generateInventoryDataModelAPI } from '../../Service/Api';
import { encode, decode } from "base-64"
import { downloadSVG, fetchJsonFromBlob, fetchJsonFromURL, fetchKrokiDiagram, fetchTextFromURL, updateChatHistoryToBlob, updateJsonInBlob } from './BlobStorageService';
import { putJsonToBlob } from './BlobStorageService';
import { deleteDBRecords, getConnectionInfo } from '../../Service/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import MetadataNotebook from '../MetadataNotebook';
import * as api from '../../Service/Api';
import { HeaderCom } from '../Header';
import NotebookPopup from './NotebookPopup';
import DynamicFileUploadPopup from './DynamicFileUploadPopup';
import FileUploaderComponent from './NotebookUploadComponent';

// import Groups from './AcessControl/Group';
import UnitTescasePseudo from './Integrations/UnitTestCasePseudo';
import CodeViewPage from './CodeViewPageDynamic';
import InventoryUploadPopup from './inventoryUploadComponent';
import InventoryDocument from './Integrations/InventoryDocument';
import CatalogSchema from './Integrations/CatalogSchema';
import DataDictionary from './Integrations/DataDictionary';
import MetaTableData from './Integrations/MetaTableData';
import DDlScript from './Integrations/DDlScript';
import LoggerPseudo from './Integrations/LoggerPseudo';
import UtilityPseudo from './Integrations/UtilityPseudo';
import ConfigutrationPseudo from './Integrations/ConfigurationPseudo';
import TradionalMethod from './Integrations/MigrationPseudo-Tradition';
import CopyClone from './Integrations/MigrationPseudo-CopyClone';
import ChangesPseudo from './Integrations/ChangesPseudo';
import CodeReviewPseudo from './Integrations/CodeReviewPseudo';
import CodeReviewDev from './Integrations/CodeReviewDev';
import DataQuatityPseudo from './Integrations/DataQualityPseudo';
import DynamicUmlView from './DynamicUmlView';
import PlantUMLPreview from './PlantumlView';
import SourceToTarget from './Integrations/SourceToTarget';
import ApproachDiagramView from '../new/ApproachDiagramView';
import ConfirmationTMU from '../new/Integrations/ConfirmedTableMapping'
import NoteBookChanges from '../new/Integrations/NoteBookChanges';

import { generateTableMappingAPI } from '../../Service/Api';
import { generateDDLScriptsCodeAPI } from '../../Service/Api';
import { generateLoggerCodeAPI } from '../../Service/Api';
import { generateDDLScriptsPseudouc } from '../../Service/Api';
import { generateFrameWorksAPI, generateApproachdiagram, generateAnalysisdocument } from '../../Service/Api';

// import { generateTableMappingAPI } from '../../Service/Api';
import { generateDataDictionaryuc } from '../../Service/Api';
import { generateMetadataTableuc } from '../../Service/Api';
import TableMapping from './Integrations/TableMapping';
import AnalyseDocument from './Integrations/AnalyseDocument';
import { generateCatalogAndSchemaDetailsAPI } from '../../Service/Api';
import { generateFrameworksAPI } from '../../Service/Api';
import { generateCodeReviewCheckListAPI } from '../../Service/Api';
import { generateNotebookRecommendationsAPI } from '../../Service/Api';
import ViewInstructions from './Integrations/viewInstructions'
import { ucMigrationUploadsAPI } from '../../Service/Api';
import { dataQualityUploadValidations, notebookTestUploadValidations, tableMappingValidations, templateURLs, unitTestResultUploadValidations } from '../Contrains';
import DataQualityResult from './Integrations/DataQualityResult';
import axios from 'axios';


const ChatMainUCMigration = () => {
    //state variables initialize the state variables
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isTableMappingPopupOpen, setTableMappingPopupOpen] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [validationColumns, setValidationColumns] = useState([]);
    const [uploadedData, setUploadedData] = useState(null);
    const [tableuploaddetails, settableuploaddetails] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [showLooksGood, setShowLooksGood] = useState(false)
    const [showCode, setShowCode] = useState(false);
    // const[isTableEdited,setIsTableEdited] = useState(false);
    const [sourcePhase, setSourcePhase] = useState('')
    const [codeDataValues, setCodeDataValues] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const matchesRef = useRef([]);
    const [searchText, setSearchText] = useState('');
    const [dataQualityResultFile, setDataQualityResultFile] = useState('');
    const [unitTestResultFile, setUnitTestResultFile] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [isSourceToTraget, setIsSourceToTarget] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [accessControlShow, setAccessControlShow] = useState(false);
    const [templateURL, setTemplateURL] = useState('');
    const [fileUploaded, setFileUploaded] = useState('');
    const navigate = useNavigate()
    var { state } = useLocation();

    //popups
    const [inventoryPopup, setInventoryPopup] = useState(false);
    const [notebookPopup, setNotebookPopup] = useState(false);
    const [dynamicFileUploadPopup, setDynamicFileUploadPopup] = useState(false);
    const [unitTestcasePopup, setUnitTestcasePopup] = useState(false);
    const [dataQualityPopup, setDataQualityPopup] = useState(false);
    const [isSubjectAreaRequired, setIsSubjectAreaRequired] = useState(false); // Initialize with false
    const [error, setError] = useState([]);
    const [confirmedTablePopup, setConfirmedTablePopup] = useState(false);
    const [showViewInstructions, setShowViewInstructions] = useState(true);
    const [isSourceAllApproved, setIsSourceAllApproved] = useState(false);
    const [isAllCatalogFilled, setIsAllCatalogFilled] = useState(false);
    // Define handler functions
    const handleStatusChange = (status) => {
        bindLooksGood(codeDataValues.notebookName);
        if (status === true) {
            gridDataChanged();
        }
    };

    const handleClose = () => {
        setShowViewInstructions(false);
        closeTableMappingPopup();
    };



    const getConnection = async () => {
        try {
            const requestBody = {
                userId: state.userId,
                projectId: state.projectId,
                jwt_token: state.jwt,
            };

            const response = await getConnectionInfo(requestBody);
            console.log(response, "fetchRess");

            if (response.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                setError(['Failed to fetch connection details']);
            } else {
                const data = response.data;
                // Check if isSubjectAreaRequired is a string and equals "True"
                if (data.isSubjectAreaRequired === 'True') {
                    setIsSubjectAreaRequired(true);
                } else {
                    setIsSubjectAreaRequired(false); // Ensure it's false if not "True"
                }
            }
        } catch (error) {
            console.error('Error fetching connection details:', error);
            setError(['Error fetching connection details']);
        }
    };

    // Optional: Log the value after it has been updated
    useEffect(() => {
        console.log(isSubjectAreaRequired, 'isSubjectAreaRequiredisSubjectAreaRequiredisSubjectAreaRequired');
    }, [isSubjectAreaRequired]); // Log whenever isSubjectAreaRequired changes








    //PS_103 - 106 If the response's status equals 403, indicating an expired session, the chatMainUC.js file will invoke the sessionExpired function to display a logout toast message to the user, advising them that they need to log back in.
    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            navigate("/");
        }, 3000);
    };

    const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });

    const handleMouseEnter = (e, content) => {
        if (content.length > 1) {
            setTooltip({
                show: true,
                text: content,
                x: e.clientX - 80, // Adjust as needed to position tooltip slightly to the right of the cursor
                y: e.clientY - 60 // Adjust as needed to position tooltip slightly below the cursor
            });
        }
    };
    const handleMouseLeave = () => {
        setTooltip({ show: false, text: '', x: 0, y: 0 });
    };




    //PS_01 - PS_03 User initiates the file upload process by clicking the appropriate button in the chat history page. Set isPopupOpen to true, popupText based on the button clicked, and select appropriate validationColumns based on upload type (Unit Test, Data Quality, Table Mapping, or Notebook Test).
    const handleFileUploadButtonClick = async (type) => {
        // Reset uploaded file name and validation columns
        setUploadedFileName(''); // Clear previous file name if needed
        setValidationColumns([]); // Clear previous validations if needed
        switch (type) {
            case 'unitTest':
                setPopupText("Upload Unit Test Case Results");
                setUploadedFileName(unitTestResultFile ? unitTestResultFile : '');
                setValidationColumns(unitTestResultUploadValidations);
                setTemplateURL(templateURLs.testcaseResultUplaodURl)
                setDynamicFileUploadPopup(true);
                setFileUploaded(unitTestResultFile)

                break;
            case 'dataQuality':
                setPopupText("Upload Data Quality Sheet");
                setUploadedFileName(dataQualityResultFile ? dataQualityResultFile : '');
                setValidationColumns(dataQualityUploadValidations);
                setTemplateURL(templateURLs.dataQualityResultUploadURL)
                setDynamicFileUploadPopup(true);
                setFileUploaded(dataQualityResultFile)

                break;
            case 'tableMapping':
                setPopupText("Upload Table Mapping Sheet");
                setValidationColumns(tableMappingValidations);
                setTemplateURL(templateURLs.tableMappingUploadURL)

                break;
            case 'notebookTest':
                setPopupText("Upload Notebook Test Sheet");
                setValidationColumns(notebookTestUploadValidations);
                setTemplateURL(templateURLs.notebookToBeUploadURL)

                break;
            default:
                console.warn(`Unknown type: ${type}`); // Handle unknown types gracefully
        }
    };


    // Create textData object using the updated state values
    let textData = {
        FileName: uploadedFileName,
        popupText: popupText
    };

    // PS_08 Set isPopupOpen state to false, clear popupText state, and clear validationColumns state.
    const closePopup = () => {
        setDynamicFileUploadPopup(false)
        textData = {
            FileName: '',
            popupText: ''
        }
        setPopupOpen(false);
        setPopupText('');
        setValidationColumns([]);
    };

    // PS_05	SQ_EX_31.0 Set isTableMappingPopupOpen to false and clear uploadedData when TableMappingPopup is closed.
    const closeTableMappingPopup = () => {
        setTableMappingPopupOpen(false);
        setUploadedData(null);
    };

    //PS_02, - PS_31: The fetchChatHistory() function is invoked. This function is responsible for retrieving the chat history. It calls the fetchJsinFromBlob() method from Api.js, passing state.chatHistoryLink as a parameter. This initiates the process of fetching the chat history from the blob storage.
    const fetchChatHistory = async () => {

        let newPro = sessionStorage.getItem("newPro");
        if (newPro == "true") {
            sessionStorage.removeItem("newPro");
            document.getElementById("protoastMessage").style.display = "block";
            setTimeout(() => {
                document.getElementById("protoastMessage").style.display = "none";
            }, 3000);
        } else {
            state.isEdit = true;
        }

        document.getElementById("pageLoader").style.display = "block";
        try {
            let fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');

            if (fetchedData) {

                if (fetchedData?.filter(a => a?.flow === 'error')?.length !== 0) {
                    fetchedData = fetchedData.filter((a) => a?.flow !== 'error')
                    updateChatHistoryToBlob(state.orgId, state.projectId, fetchedData, "chatHistory.json", "projectfiles");

                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                }
                setChatHistory(fetchedData);

                const hasTableMapping = fetchedData.some(obj => obj.notebookName === "tableMapping");
                setAccessControlShow(hasTableMapping);

                const unitTestFile = fetchedData.find(obj => obj.hasOwnProperty('UnitTestResultFileName'));

                if (unitTestFile) {
                    setUnitTestResultFile(unitTestFile.UnitTestResultFileName);
                }
                else {
                    setUnitTestResultFile('');
                    setFileUploaded('')
                }
                const dataQualityFile = fetchedData.find(obj => obj.hasOwnProperty('DataQualityResultFileName'));
                if (dataQualityFile) {
                    setDataQualityResultFile(dataQualityFile.DataQualityResultFileName);
                }
                else {
                    setDataQualityResultFile('');
                    setFileUploaded('')
                }
                document.getElementById("pageLoader").style.display = "none";

                setTimeout(() => { goDown() }, 1000);
            }
            // return fetchedData; // Return the fetched data if needed
        } catch (error) {
            console.error("Error fetching chat history:", error);
            // Handle the error appropriately, maybe return null or an empty array
            return null; // or return [];
        }
    };







    //PS_02, - PS_31: The fetchChatHistory() function is invoked. This function is responsible for retrieving the chat history. It calls the fetchJsinFromBlob() method from Api.js, passing state.chatHistoryLink as a parameter. This initiates the process of fetching the chat history from the blob storage.
    useEffect(() => {
        fetchChatHistory()
        getConnection();
    }, [])


    function updateValues() {
        let data = [...chatHistory];
        let temp = 0;
        let arr = [];
        for (let a of data) {
            if (temp === 0) {
                arr.push(a);
            }
        }
        setChatHistory(() => [...arr]);
        // setIsEditNotebook(() => true)
    }

    // PS_03 - PS_05 Initiate the upload of the file to Azure Blob Storage. After successful upload, update chatHistory state with the new fileName.
    const saveFunction = async (file, text) => {
        try {
            console.log(file, text, 'filefilefilefilefilefile');


            // Read the Excel file
            var data = await readExcelFile(file);
            // Convert the data to JSON string
            const jsonString = JSON.stringify(data, null, 2);
            // If you want to parse it back into an object (not necessary here)
            data = JSON.parse(jsonString);
            console.log('Parsed Data:', data);


            // Define organization and project names (you might want to make these dynamic)
            const orgName = state.orgName;
            const projectName = state.projectName;
            setUploadedData(data);


            let fileName = file.name;


            // Save the JSON data to Azure Blob Storage
            let blobUrl;
            //make a backend call to insert or update the url into the BE

            let chatData;
            // Update chat history based on the uploaded file type

            switch (text) {
                case "Upload Unit Test Case Results":
                    blobUrl = await putJsonToBlob(state.orgId, state.projectId, data, 'UnitTestcaseUploadedResult.json', 'projectfiles');
                    console.log(`File saved to Azure Blob Storage: ${blobUrl}`);
            
                    await uploadFilesBE("unitTestCaseUpload", blobUrl, "unitTestCaseResult")
                    setDynamicFileUploadPopup(false)
                    closePopup()
                    await generateNotebookChangesRecommendations('notebookChangesRecommend', fileName);
                    console.log('File saved successfully to Azure Blob Storage');
                    break;
                case "Upload Data Quality Sheet":
                    blobUrl = await putJsonToBlob(state.orgId, state.projectId, data, 'dataQualityUploadedResult.json', 'projectfiles');
                    console.log(`File saved to Azure Blob Storage: ${blobUrl}`);
                    if (data?.MetadataTable) {
                        let metaData = data.MetadataTable;
                        console.log(metaData, 'metaDatametaDatametaDatametaData');

                        putJsonToBlob(state.orgId, state.projectId, metaData, 'MetaDataTable.json', 'design')
                    }
                
                    await uploadFilesBE("dataQualityUpload", blobUrl, "dataQualityResult")
                    setDynamicFileUploadPopup(false)
                    await generateDataQualityResults('dataQualityResults', fileName);

                    console.log('File saved successfully to Azure Blob Storage');

                    break;
                case "Upload Table Mapping Sheet":
                    setChatHistory(prev => ({ ...prev, TableMappingFileName: file.name }));
                    // blobUrl = await putJsonToBlob(orgName, projectName, data, 'tableMappingUploaded.json', 'projectfiles');
                    console.log('File saved successfully to Azure Blob Storage');
                    // navigate('/confirmedTMU', { uploadedNewData: data })
                    settableuploaddetails(data)
                    setConfirmedTablePopup(true)

                    setTableMappingPopupOpen(true);
                    closePopup();

                    break;
                case "Upload Notebook Test Sheet":
                    setChatHistory(prev => ({ ...prev, NotebookToBeTestedFileName: file.name }));
                    break;
                default:
                    console.warn("Unknown upload type:", text);
                    break;
            }
            console.log(chatHistory, 'hisss');
            closePopup()

        } catch (error) {
            console.error("Error saving file:", error);
        }
    };



    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const result = {};

                workbook.SheetNames.forEach(sheetName => {
                    const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                    if (sheet.length) {
                        const headers = sheet[0];
                        const transformedData = sheet.slice(1).map(row => {
                            const rowData = {};
                            row.forEach((cell, i) => {
                                rowData[headers[i]] = cell;
                            });
                            return rowData;
                        });
                        result[sheetName] = transformedData;
                    } else {
                        result[sheetName] = [];
                    }
                });

                console.log(result, 'resultresult');

                resolve(result);
            };

            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };
   

    function getCodePageDetails(data) {
        setShowCode(true);
        setCodeDataValues(data);
        console.log(data, 'datadatadatadatadatadatadatadatadata');
    }
    function goDown() {
        const divElement = document.getElementById("Imdown");
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleConnectionDetails = () => {
        console.log('hiiiiiiiiiiiiiiiiiii');

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Connection Details" });
        data.push({ flow: "ai", type: "notebookPopup", notebookName: "notebookPopup", show: true, phase: "Define" });

        setChatHistory(() => [...data]);
        updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", [...data], "projectfiles");
        getConnection();
    };

    const handleExistingNotebook = () => {

        generateTableAndNotebookSummary('tableSummary', 'before');
    };
    async function setNotebookUploaded() {
        chatHistory[0]['notebookUploaded'] = "true"
    }


    //PS_862 - 1522 Invoke the checkLooksGood() function, passing the newly created object as an argument. 
    //Inside checkLooksGood(), based on the notebookName, invoke the generation() function to start the generation process.

    const checkLooksGood = (notebookName) => {
        // if (data.phase === 'Define') {
        if (notebookName === 'notebookPopup') {
            generateTableAndNotebookSummary('tableSummary', 'before');
        }
        else if (notebookName === 'tableSummaryBefore') {
            generateInventoryDocument("inventory");
        }
        else if (notebookName === 'inventory') {
            generateInventoryDataModel('InventoryDataModel');
        }
        else if (notebookName === 'InventoryDataModel') {
            generateCatalogAndSchemaDetails('catalogSchema');
        }
        else if (notebookName === 'catalogSchema') {
            generateTableMapping('tableMapping');
        }
        else if (notebookName === 'tableMapping') {
            generateTableAndNotebookSummary('tableSummary', 'after');
        }
        else if (notebookName === 'tableSummaryAfter') {
            generateSourceToTargetFlow('sourceToTargetFlow');
        }
        else if (notebookName === 'sourceToTargetFlow') {
            generateApproachDiagram('approachDiagram');
        }
        else if (notebookName === 'approachDiagram') {
            generateTableAndNotebookSummary('notebookSummary', 'before');
        }
        else if (notebookName === 'notebookSummaryBefore') {
            generateAnalysisDocument('analysisDocument');
        }
        else if (notebookName === 'analysisDocument') {
            generateTableAndNotebookSummary('notebookSummary', 'after');
        }
        else if (notebookName === 'notebookSummaryAfter') {
            console.log(`Downloading Consolidated Define Document with details`);
            downloadConsolidatedDefineDoc('consolidatedDefineDocument');
        }
        else if (notebookName === 'consolidatedDefineDocument') {
            generateDataDictionary('dataDictionary');
        }
        else if (notebookName === 'dataDictionary') {
            generateMetadataTable('metadataTable');
        }
        else if (notebookName === 'metadataTable') {
            generateDDLScriptsPseudo('ddlPseudo');
        }
        else if (notebookName === 'ddlPseudo') {
            generateLoggerPseudo('loggerPseudo');
        }
        else if (notebookName === 'loggerPseudo') {
            generateUtilityPseudo('utilityPseudo');
        }
        else if (notebookName === 'utilityPseudo') {
            generateConfigurationPseudo('configurationPseudo');
        }
        else if (notebookName === 'configurationPseudo') {
            generateTraditionalPseudo('traditionalPseudo');
        }
        else if (notebookName === 'traditionalPseudo') {
            generateCopyClonePseudo('copyClonePseudo');
        }
        else if (notebookName === 'copyClonePseudo') {
            generateChangesPseudo('changesPseudo');
        }
        else if (notebookName === 'changesPseudo') {
            generateCodeReviewChecklistPseudo('checklistPseudo');
        }
        else if (notebookName === 'checklistPseudo') {
            generateUnitTestcasePseudo('unitTestcasePseudo');
        }
        else if (notebookName === 'unitTestcasePseudo') {
            generateDataQualityPseudo('dataQualityPseudo');
        }
        else if (notebookName === 'dataQualityPseudo') {
            console.log(`Downloading Consolidated Design Document with details`);
            downloadConsolidatedDefineDoc('consolidatedDesignDocument');
        }
        // } else if (data.phase === 'Development') {
        else if (notebookName === 'consolidatedDesignDocument') {
            generateDDLScriptsCode('ddlCode');
        } else if (notebookName === 'ddlCode') {
            generateLoggerCode('loggerCode');
        } else if (notebookName === 'loggerCode') {
            generateUtilityCode('utilityCode');
        } else if (notebookName === 'utilityCode') {
            generateConfigurationCode('configurationCode');
        } else if (notebookName === 'configurationCode') {
            generateTraditionalMigrationCode('traditionalCode');
        } else if (notebookName === 'traditionalCode') {
            generateCopyCloneMigrationCode('copyCloneCode');
        } else if (notebookName === 'copyCloneCode') {
            generateChangesCode('changesCode');
        } else if (notebookName === 'changesCode') {
            generateCodeReviewChecklistCode('checklistCode');
        } else if (notebookName === 'checklistCode') {
            generateUnitTestCaseCode('unitTestcaseCode');
        } else if (notebookName === 'unitTestcaseCode') {

            const phase = "Development";

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "unitTestResultPopup", phase: phase },
            ]

            setChatHistory(newChat);

            const blobJson = updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

        }
       
        else if (notebookName === 'notebookChangesRecommend') {
            // Handle Data Quality Code generation after notebook changes recommendations 
            generateDataQualityCode('dataQualityCode');
        }
        else if (notebookName === 'dataQualityCode') {
            const phase = "Development";

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "dataQualityResultPopup", phase: phase },
            ]

            setChatHistory(newChat);

            const blobJson = updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);
        }
       
        else if (notebookName === 'dataQualityResults') {

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", phase: "Development", notebookName: "Completed", text: "Congratulations! You've successfully completed development phase." },
            ]
            setChatHistory(newChat);

            const blobJson = updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


        
        }
        
    };

    // PS_81 - PS_104 Inside the checkPseudocodeLooksgood function, conditional logic dictates that if the notebookName pertains to table and notebook summary, the generateTableandNotebookSummary function will be invoked to handle the generation of the required summary documents.
    async function generateTableAndNotebookSummary(type, generationState) {
        try {
            console.log('comingbro');

            let notebookName;
            const phase = "Define";

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]

            let newChat = [];

            if (type === 'tableSummary' && generationState === 'before') {

                const referenceDocuments = ['Inventory Uploaded', 'TableType Uploaded'];
                notebookName = "Table Summary"
                newChat = [
                    ...data,
                    { flow: "user", type: "text", text: "Notebook Details" },
                    { flow: "ai", type: "text", text: `Let's move towards generating ${generationState === 'after' ? 'Final' : ''} ${notebookName}`, phase: phase },
                    { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                    { flow: 'ai', type: 'text', text: 'Summary would be ready in few minutes. You will be notified with an email once the Summary is ready', phase: phase }
                ]
            }
            else {

                const referenceDocuments = ['Inventory Uploaded', 'TableType Uploaded', 'Existing Notebooks Uploaded'];
                notebookName = type === "notebookSummary" ? 'Notebook Summary' : 'Table Summary';
                newChat = [
                    ...data,
                    { flow: "user", type: "text", text: "Looks good" },
                    { flow: "ai", type: "text", text: `Let's move towards generating ${generationState === 'after' ? 'Final' : ''} ${notebookName}`, phase: phase },
                    { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                    { flow: 'ai', type: 'text', text: 'Summary would be ready in few minutes. You will be notified with an email once the Summary is ready', phase: phase }
                ];
            }

            setChatHistory(newChat);
            console.log(newChat, 'newChatnewChatnewChatnewChatnewChat');


            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgName: state.orgName,
                projectName: state.projectName,
                orgId: state.orgId,
                projectId: state.projectId,
                generationType: type,
                generationState: generationState,
                jwt_token: state.jwt,
                chatHistoryUrl: `https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/${state.orgName}/${state.projectName}/json/chatHistory.json`
            }

            const response = await api.tableandNotebookSummary(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Table Summary:", error);
        }
    }

    //PS_115 - PS_141 Should the pseudocode check pass, chatMainUC.js proceeds to invoke the function generateInventoryDocument, initiating the process for creating the Inventory Document based on the project's requirements.
    async function generateInventoryDocument(noteName) {
        try {
            const notebookName = "Inventory Document";
            const phase = "Define";
            const referenceDocuments = ['Inventory Uploaded', 'Subject Area Entered'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                projectName: state.projectName,
                orgName: state.orgName,
                jwt_token: state.jwt
            }

            const response = await inventoryGeneration(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Inventory Document:", error);
        }
    }

    async function setInventoryUploaded() {
        chatHistory[0]['inventoryUploaded'] = "true"
    }
    async function setNotebookUploaded() {
        chatHistory[0]['notebookUploaded'] = "true"
    }

    //PS_147 - PS_172 Based on the notebookName being "InventoryDataModel", the generateInventoryDataModel() function is called to start the process of generating the inventory data model.
    async function generateInventoryDataModel(noteName) {
        try {
            const notebookName = "Inventory data model";
            const phase = "Define";
            const referenceDocuments = ['Inventory Document'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                projectName: state.projectName,
                orgName: state.orgName,
                jwt_token: state.jwt
            }

            const response = await generateInventoryDataModelAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //PS_178 - PS_185 Based on the notebookName evaluated in checkPseudocodeLooksgood, the system calls the generateTableandNotebookSummary function. This function is responsible for initiating the process of generating both table and notebook summaries.
    async function generateCatalogAndSchemaDetails(noteName) {
        try {
            const notebookName = "Catalog & Schema Details";
            const phase = "Define";
            const referenceDocuments = ['Inventory Sheet'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName,
            }

            const response = await generateCatalogAndSchemaDetailsAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //PS_186 - PS_198 Based on the notebookName evaluated in checkPseudocodeLooksgood, the system calls the generateTableMapping function. This function is responsible for initiating the process of generating both table and notebook summaries.
    async function generateTableMapping(noteName) {
        try {
            const notebookName = "Table Mapping Details";
            const phase = "Define";
            const referenceDocuments = ['Inventory Document'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName,
                new_base_path:state.newStoragePath?state.newStoragePath : state.newstoragePath
            }
 
            console.log(object,"This is my objectttttttttttttttttttttttttttttttttttttttttttttttttttttt")

            const response = await generateTableMappingAPI(object, state.jwt);
            console.log(response,"responseresponseresponseresponse")
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //234 - 250 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateSourceToTargetFlow() function to start the generation process.
    async function generateSourceToTargetFlow(noteName) {
        const notebookName = "Source To Target Flow";
        const phase = "Define";
        const referenceDocuments = ['Table Mapping Sheet'];

        chatHistory[chatHistory.length - 1].show = false;
        chatHistory[chatHistory.length - 1].isAction = false;
        const data = [...chatHistory]
        const newChat = [
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            { flow: "ai", type: "codeShow", notebookName: noteName, hyperlinkText: "Source To Target Flow", text: "Please fine the source to target flow Details here", phase: "Define", show: false, isAction: false }
        ];

        setChatHistory(newChat);

        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
        console.log(`JSON data Updated successfully to ${blobJson}`);
    }

    //251 - 270 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateApproachDiagram() function to start the generation process.

    async function generateApproachDiagram(noteName) {
        try {
            const notebookName = "Approach Diagram";
            const phase = "Define";
            const referenceDocuments = ['Inventory Document', 'Confirmed Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateApproachdiagram(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                //      // Corrected array manipulation
                //      chatHistory.splice(-4, 4); // Remove last 4 items from chatHistory
                //      chatHistory[chatHistory.length - 1].show = true; // Set show to true for the last item
                //      chatHistory[chatHistory.length - 1].isAction = true;
                //      const data = [...chatHistory]
                //      setChatHistory(data);

                //       // Update JSON in blob
                //  const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", data, 'projectfiles');
                //  console.log(`JSON data updated successfully to ${blobJson}`);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //271 - 280 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateAnalysisDocument() function to start the generation process.
    async function generateAnalysisDocument(noteName) {
        try {
            const notebookName = "Analysis Document";
            const phase = "Define";
            const referenceDocuments = ['Uploaded Notebooks', 'Confirmed Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateAnalysisdocument(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //consolidated

    //PS_387 - PS_435 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the downloadConsolidatedDefine() function to start the generation process of the documents which need to be download on clicking the hyper link or the download icon from the chat.
    async function downloadConsolidatedDefineDoc(noteName) {
        try {
            if (noteName === 'consolidatedDefineDocument') {
                chatHistory[chatHistory.length - 1].show = false;
                chatHistory[chatHistory.length - 1].isAction = false;
                const data = [...chatHistory]
                const newChat = [
                    ...data,
                    { flow: 'user', type: 'text', text: 'Looks good', phase: 'Define' },
                    { flow: 'ai', type: 'text', text: "Congratulations! You've Successfully completed the Define phase.", phase: 'Define' },
                    { flow: 'ai', type: 'consolidateDownload', text: 'You can download the consolidated define document here:', hyperlinkText: 'Consolidated Define Document', notebookName: 'consolidatedDefineDocument', id: 'ConsolidatedDefineDocument', show: true, phase: 'Define' }
                ];

                setChatHistory(newChat);

                const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
                console.log(`JSON data Updated successfully to ${blobJson}`);

                downloadConsolidatedDefine(noteName);

            } else if (noteName === 'consolidatedDesignDocument') {
                chatHistory[chatHistory.length - 1].show = false;
                chatHistory[chatHistory.length - 1].isAction = false;
                const data = [...chatHistory]
                const newChat = [
                    ...data,
                    { flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' },
                    { flow: 'ai', type: 'text', text: "Congratulations! You've Successfully completed the design phase.", phase: 'Design' },
                    { flow: 'ai', type: 'consolidateDownload', text: 'You can download the consolidated Design document here:', hyperlinkText: 'Consolidated Design Document', notebookName: 'consolidatedDesignDocument', id: 'ConsolidatedDesignDocument', show: true, phase: 'Design' }
                ];

                setChatHistory(newChat);
                const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
                console.log(`JSON data Updated successfully to ${blobJson}`);
                downloadDesignDocument(noteName);
            }


        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    const generateDiagramWithKroki = async (umlURL, fileName) => {
        try {
            const response = await axios.post('https://kroki.io/plantuml/svg', umlURL, {
                headers: { 'Content-Type': 'text/plain' },
            });

            const svgContent = response.data; // SVG content


            // Create a Blob from the SVG content
            const blob = new Blob([svgContent], { type: 'image/svg+xml' });

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}.svg`; // Specify the file name

            // Trigger the download
            link.click();

            return svgContent; // Optionally return the SVG content
        } catch (error) {
            console.error('Error generating diagram with Kroki:', error);
            return '<div>Error generating diagram</div>';
        }
    };

    async function downloadImage(plantUMLText, fileName) {
        try {
            // Encode the PlantUML text
            const encodedText = encodeURIComponent(plantUMLText);
            // Create the URL for the PlantUML server (adjust if you have a specific server)
            const url = `https://www.plantuml.com/plantuml/svg/${btoa(encodedText)}`;

            // Fetch the SVG from the URL
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');

            const svgBlob = await response.blob(); // Convert to Blob

            // Create a URL for the Blob
            const svgURL = URL.createObjectURL(svgBlob);

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = svgURL;
            link.download = fileName; // Set the file name for download

            // Append to body and trigger click
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(svgURL); // Release memory
        } catch (error) {
            console.error(`Error downloading SVG: ${error}`);
        }
    }

    //PS_387 - PS_435 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the downloadConsolidatedDefine() function to start the generation process of the documents which need to be download on clicking the hyper link or the download icon from the chat.
    async function downloadConsolidatedDefine(noteName) {
        try {
            document.getElementById('pageLoader').style.display = "block";
   
            const object = {
                phase: 'Define',
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                type: 'define',
                notebookName: 'define',
                request_type: 'define',
                projectName: state.projectName,
                orgName: state.orgName,
                jwt_token: state.jwt
            };
   
            // Fetch the blob URL from the backend
            const response = await api.consolidateDownload(object, state.jwt);
            console.log(response, "Fetched Blob URL");
   
            // Fetch JSON data from the blob URL
            if (response.status === 403) {
                sessionExpired();
            } else if (response.status === 200) {
                let jsonData = await fetchJsonFromURL(response.blobUrl);
                console.log(jsonData, "Fetched JSON Data");
   
                // Create an array to hold the sheets
                const sheetsArray = [];
                if (jsonData && typeof jsonData === 'string') {
                    jsonData = JSON.parse(jsonData);
                }
                console.log(jsonData);
   
                // Check if jsonData is an object and process accordingly
                if (jsonData && typeof jsonData === 'object') {
                    // Process UCTableMappingURL if it exists and is an array
                    if (Array.isArray(jsonData?.UCTableMappingURL)) {
                        sheetsArray.push({
                            sheetName: "TableMapping",
                            sheetContent: jsonData?.UCTableMappingURL[0]
                        });
                    }
   
                    // Process catalogAndSchemaDetailsURL if it exists and is an array
                    if (Array.isArray(jsonData?.catalogAndSchemaDetailsURL)) {
                        const filteredContent = jsonData?.catalogAndSchemaDetailsURL[0].map(({ index, reason, ...rest }) => rest);
                        sheetsArray.push({
                            sheetName: "CatalogAndSchemaDetails",
                            sheetContent: filteredContent
                        });
                    }
   
                    // Process analysisDocumentURL if it exists and is an array
                    if (Array.isArray(jsonData?.analysisDocumentURL)) {
                        sheetsArray.push({
                            sheetName: "AnalysisDocument-Matched",
                            sheetContent: jsonData?.analysisDocumentURL[0]['matched_tables']
                        });
                        sheetsArray.push({
                            sheetName: "AnalysisDocument_Unmatched",
                            sheetContent: jsonData?.analysisDocumentURL[0]['unmatched_tables']
                        });
                    }
   
                    // Download diagrammatic formats separately if they exist and are not empty arrays
                    await generateDiagramWithKroki(jsonData?.approachDiagramBeforeURL[0], 'Approach_Diagram_Before.svg');
                    await generateDiagramWithKroki(jsonData?.approachDiagramAfterURL[0], 'Approach_Diagram_After.svg');
   
                    console.log(sheetsArray, "sheetsArray");
   
                } else {
                    console.error("Unexpected jsonData format:", jsonData);
                }
   
                function downloadMultipleSheets(sheetsArray) {
                    try {
                        const MAX_CHAR_LIMIT = 32767;
                        const wb = XLSX.utils.book_new();
   
                        if (Array.isArray(sheetsArray) && sheetsArray.length > 0) {
                            sheetsArray.forEach(sheetObj => {
                                if (sheetObj && sheetObj.sheetName && Array.isArray(sheetObj.sheetContent)) {
                                    const { sheetName, sheetContent } = sheetObj;
   
                                    // Validate and truncate the sheet name if necessary
                                    const truncatedSheetName = truncateSheetName(sheetName);
   
                                    // Truncate any cell content longer than the allowed maximum
                                    const truncatedContent = sheetContent.map(row => {
                                        return Object.keys(row).reduce((acc, key) => {
                                            acc[key] = typeof row[key] === 'string' && row[key].length > MAX_CHAR_LIMIT
                                                ? row[key].substring(0, MAX_CHAR_LIMIT)
                                                : row[key];
                                            return acc;
                                        }, {});
                                    });
   
                                    // Convert JSON to sheet and validate its size
                                    const ws = XLSX.utils.json_to_sheet(truncatedContent);
   
                                    XLSX.utils.book_append_sheet(wb, ws, truncatedSheetName);
                                } else {
                                    console.warn("Invalid sheet object:", sheetObj);
                                }
                            });
   
                            if (wb.SheetNames.length > 0) {
                                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                const downloadLink = document.createElement('a');
                                downloadLink.href = URL.createObjectURL(blob);
                                downloadLink.download = 'Consolidated_Document.xlsx';
                                downloadLink.click();
                                URL.revokeObjectURL(downloadLink.href);
                            } else {
                                console.warn("No valid sheets to download.");
                            }
   
                        } else {
                            console.warn("sheetsArray is not a valid array or is empty:", sheetsArray);
                        }
   
                    } catch (error) {
                        console.error("Error in downloadMultipleSheets:", error);
                    } finally {
                        const pageLoader = document.getElementById('pageLoader');
                        if (pageLoader) {
                            pageLoader.style.display = 'none';
                        }
                    }
                }
   
                function truncateSheetName(sheetName, maxLength = 31) {
                    return sheetName.length <= maxLength ? sheetName : sheetName.substring(0, maxLength);
                }
                downloadMultipleSheets(sheetsArray);
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
   
        } catch (error) {
            console.error("Error in downloadConsolidatedDefine:", error);
        } finally {
            document.getElementById('pageLoader').style.display = "none"; // Ensure loader is hidden in case of error
        }
    }


    //PS_387 - PS_435 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the downloadDesignDocument() function to start the generation process of the documents which need to be download on clicking the hyper link or the download icon from the chat.


    async function downloadDesignDocument(noteName) {
        try {
            document.getElementById('pageLoader').style.display = "block";

            const object = {
                phase: 'Design',
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                type: "design",
                request_type: 'design',
                notebookName: noteName,
                projectName: state.projectName,
                orgName: state.orgName,
                jwt_token: state.jwt
            };
            const response = await api.consolidateDownload(object, state.jwt);
            console.log(response, "Fetched Blob URL");

            if (response.status === 403) {
                sessionExpired();
            } else if (response.status === 200) {
                let jsonData = await fetchJsonFromURL(response.blobUrl);
                console.log(jsonData, "Fetched JSON Data");
                jsonData = JSON.parse(jsonData);
                const sheetsArray = [];

                // Handle Data Dictionary Details
                if (jsonData.dataDictionaryDetails && jsonData.dataDictionaryDetails.length > 0) {
                    jsonData.dataDictionaryDetails.forEach((dictionaryDetail, index) => {
                        sheetsArray.push({
                            sheetName: `DataDictionary${index + 1}`,
                            sheetContent: dictionaryDetail.content && dictionaryDetail.content.length > 0 ? dictionaryDetail.content : [{ CatalogName: dictionaryDetail.CatalogName }]
                        });
                    });
                } else {
                    console.warn("No Data Dictionary Details found.");
                }

                // Handle DDL Details
                if (jsonData.ddlDetails && jsonData.ddlDetails.length > 0) {
                    jsonData.ddlDetails.forEach((ddlDetail, index) => {
                        sheetsArray.push({
                            sheetName: `DDLScripts${index + 1}`,
                            sheetContent: ddlDetail.content && ddlDetail.content.length > 0 ? ddlDetail.content : [{ CatalogName: ddlDetail.CatalogName }]
                        });
                    });
                } else {
                    console.warn("No DDL Details found.");
                }

                // Handle Code Review Details
                if (jsonData.codeReviewDetails && jsonData.codeReviewDetails.length > 0) {
                    sheetsArray.push({
                        sheetName: "CodeReviewChecklist",
                        sheetContent: jsonData.codeReviewDetails
                    });
                } else {
                    console.warn("No Code Review Details found.");
                }

                // Handle Pseudocode Design Details
                if (jsonData.designDetails && jsonData.designDetails.length > 0) {
                    const designDetails = jsonData.designDetails[0];
                    Object.keys(designDetails).forEach((key) => {
                        if (designDetails[key] && designDetails[key].length > 0) {
                            sheetsArray.push({
                                sheetName: key,
                                sheetContent: designDetails[key]
                            });
                        } else {
                            console.warn(`No content found for ${key}.`);
                        }
                    });
                } else {
                    console.warn("No Design Details found.");
                }

                // Metadata Table
                if (jsonData.metadataTable && jsonData.metadataTable.length > 0) {
                    sheetsArray.push({
                        sheetName: "MetadataTable",
                        sheetContent: jsonData.metadataTable
                    });
                } else {
                    console.warn("No Metadata Table found.");
                }

                // Check if sheetsArray is still empty
                if (sheetsArray.length === 0) {
                    console.error("No valid data found to generate sheets.");
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => {
                        document.getElementById("protoastMessageSomething").style.display = "none";
                    }, 3000);
                    return;
                }

                console.log(sheetsArray, 'Sheets Array Prepared');

                // Remove 'index' column header from each sheet content if present
                sheetsArray.forEach(sheet => {
                    sheet.sheetContent = removeIndex(sheet.sheetContent);
                });

                await downloadMultipleSheets(sheetsArray);
            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }
        } catch (error) {
            console.error("Error in downloadDesignDocument:", error);
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => {
                document.getElementById("protoastMessageSomething").style.display = "none";
            }, 3000);
        } finally {
            document.getElementById('pageLoader').style.display = 'none';
        }
    }

    // Function to remove 'index' column header
    function removeIndex(sheetContent) {
        return sheetContent.map(row => {
            // If the 'index' column exists, remove it
            const { index, reason, ...rest } = row;
            return rest;
        });
    }

    async function downloadMultipleSheets(sheetsArray) {
        try {
            const wb = XLSX.utils.book_new();
            const addedSheetNames = new Set();

            sheetsArray.forEach(sheetObj => {
                if (sheetObj && sheetObj.sheetName) {
                    const { sheetName, sheetContent } = sheetObj;
                    const truncatedSheetName = truncateSheetName(sheetName);

                    // Skip the sheet if content is invalid (empty arrays, empty strings, or objects with empty content)
                    if (!addedSheetNames.has(truncatedSheetName) && isValidContent(sheetContent)) {
                        addedSheetNames.add(truncatedSheetName);
                        const content = Array.isArray(sheetContent) ? sheetContent : [sheetContent];
                        const ws = XLSX.utils.json_to_sheet(content);
                        XLSX.utils.book_append_sheet(wb, ws, truncatedSheetName);
                    } else {
                        console.warn("Skipping empty or duplicate sheet name:", truncatedSheetName);
                    }
                }
            });

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'Consolidated_Design_Document.xlsx';
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
        } catch (error) {
            console.error("Error in downloadMultipleSheets:", error);
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => {
                document.getElementById("protoastMessageSomething").style.display = "none";
            }, 3000);
        }
    }
    // Utility function to check if the content is valid (not empty or just empty strings)
    function isValidContent(content) {
        if (Array.isArray(content)) {
            return content.some(item => {
                return Object.values(item).some(value => value && value !== '');
            });
        }
        return content && content !== '' && Object.keys(content).length > 0;
    }

    function truncateSheetName(name) {
        return name.length > 31 ? name.substring(0, 31) : name;
    }

    //Design Generationsssss

    // PS_438 - PS_462 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateDataDictionary() function to start the generation process.
    async function generateDataDictionary(noteName) {
        try {
            const notebookName = "Data Dictionary Document";
            const phase = "Design";
            const referenceDocuments = ['Inventory Document'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "center", type: "text", text: "Design" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName
            }

            const response = await generateDataDictionaryuc(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error Generating Data Dictionary:", error);
        }
    }


    // PS_4365 - PS_478 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateMetadataTable() function to start the generation process.
    async function generateMetadataTable(noteName) {
        try {
            const notebookName = "Metadata Table";
            const phase = "Design";
            const referenceDocuments = ['Confirmed Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateMetadataTableuc(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Metadata Table", error);
        }
    }

    // PS_494 - PS_507 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateDDLScriptsPseudo() function to start the generation process.
    async function generateDDLScriptsPseudo(noteName) {
        try {
            const notebookName = "DDL Scripts";
            const phase = "Design";
            const referenceDocuments = ['Confirmed Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName
            }

            const response = await generateDDLScriptsPseudouc(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    // PS_525 PS_539 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateLoggerPseudo() function to start the generation process.
    async function generateLoggerPseudo(noteName) {
        try {
            const notebookName = "Logger Pseudo";
            const phase = "Design";
            const referenceDocuments = ['Logger Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "LoggerPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    // PS_558 - PS_578 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateUtilityPseudo() function to start the generation process.
    async function generateUtilityPseudo(noteName) {
        try {
            const notebookName = "Utility Pseudo";
            const phase = "Design";
            const referenceDocuments = ['Utilities Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "UtilityPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    // PS_589 - PS_602 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateConfigurationPseudo() function to start the generation process.
    async function generateConfigurationPseudo(noteName) {
        try {
            const notebookName = "Configuration Pseudo";
            const phase = "Design";
            const referenceDocuments = ['Inventory Document', 'Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];
            // configurationPseudo
            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "configurationPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }
    // PS_605 - PS_620 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateTraditionalPseudo() function to start the generation process.
    async function generateTraditionalPseudo(noteName) {
        try {
            const notebookName = "Migration Pseudo - Traditional Method";
            const phase = "Design";
            const referenceDocuments = ['Inventory Document', 'Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "traditionalPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }
    // PS_621 - PS_635 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateCopyClonePseudo() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateCopyClonePseudo(noteName) {
        try {
            const notebookName = "Migration Pseudo - Copy Clone";
            const phase = "Design";
            const referenceDocuments = ['Inventory Document', 'Table Mapping Details'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "copyClonepseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }
    // PS_626 - PS_640 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateChangesPseudo() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateChangesPseudo(noteName) {
        try {
            const notebookName = "Changes Pseudocode";
            const phase = "Design";
            const referenceDocuments = ['Analysis Document', 'Uploaded Notebooks'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];
            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "ChangesPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }
    // PS_641 - PS_655 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateCodeReviewChecklistPseudo() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateCodeReviewChecklistPseudo(noteName) {

        try {
            const notebookName = "Code Review Checklist";
            const phase = "Design";
            const referenceDocuments = ['Changes Notebook Pseudo', 'Migration Notebook Pseudo'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "codeReviewCheckListDesign"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    // PS_656 - PS_670 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateUnitTestcasePseudo() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateUnitTestcasePseudo(noteName) {
        try {
            const notebookName = "Unit Test Case";
            const phase = "Design";
            const referenceDocuments = ['Design- Configuration_Pseudocode', 'Design- Migration Pseudocode_Traditional Method', 'Migration Pseudocode_Copy Clone', 'Design- Changes_Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];


            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "unitTestCasePseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }
    // PS_671 - PS_685 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateDataQualityPseudo() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateDataQualityPseudo(noteName) {
        try {
            const notebookName = "DataQualityPseudo";
            const phase = "Design";
            const referenceDocuments = ['Data Quality Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];


            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                orgName: state.orgName,
                projectName: state.projectName,
                notebookName: "DataQualityPseudo"
            }

            const response = await generateFrameWorksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }

    //consolidated design Document generationssss











    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // PS_863 - PS_880 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateDDLScriptsCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateDDLScriptsCode(noteName) {
        try {
            const notebookName = "DDL Scripts Code";
            const phase = "Development";
            const referenceDocuments = ['Design - DDL Scripts Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "center", type: "text", text: "Development" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the Document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: noteName,
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateDDLScriptsCodeAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error("Error generating Code Review Checklist:", error);
        }
    }


    // PS_895 - PS_925 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateLoggerCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateLoggerCode(noteName) {
        try {
            const notebookName = "Logger Code";
            const phase = "Development";
            const referenceDocuments = ['Design - Logger Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "loggerCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }

        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_926 - PS_956 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateUtilityCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateUtilityCode(noteName) {
        try {
            const notebookName = "Utilities Code";
            const phase = "Development";
            const referenceDocuments = ['Design - Utilities Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "utilityCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_958 - PS_1020 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateConfigurationCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateConfigurationCode(noteName) {
        try {
            const notebookName = "Configuration Code";
            const phase = "Development";
            const referenceDocuments = ['Configuration Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "configurationCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_990 - PS_1008 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateTraditionalMigrationCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateTraditionalMigrationCode(noteName) {
        try {
            const notebookName = "Migration - Traditional Method Code";
            const phase = "Development";
            const referenceDocuments = ['Design - Migration Traditional Method Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "traditionalCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_1021 - PS_138 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateCopyCloneMigrationCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateCopyCloneMigrationCode(noteName) {
        try {
            const notebookName = "Migration - Copy Clone Code";
            const phase = "Development";
            const referenceDocuments = ['Design - Migration copy clone Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "copyCloneCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_1055 - PS_1071 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateChangesCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateChangesCode(noteName) {
        try {
            const notebookName = "Changes Code";
            const phase = "Development";
            const referenceDocuments = ['Design - Changes Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "changesCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }


    // PS_863 invoke the uploadFilesBE() function which helps to upload the jsonData of the uploaded file from the dynamic file upload popup
    async function uploadFilesBE(notebookName, url, notebooktype) {
        try {


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: notebookName,
                jwt_token: state.jwt,
                jwt: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName,
                url: url,
                type: notebooktype
            }

            console.log(object, 'objectobjectobjectobjectobjectobject');


            const response = await ucMigrationUploadsAPI(object, state.jwt);
            console.log(response, 'responseresponseresponseresponseresponse');

            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${notebookName}:`, error);
        }
    }



    // PS_885 - PS_1102 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateCodeReviewChecklistCode () function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateCodeReviewChecklistCode(noteName) {
        try {
            const notebookName = "Code Review Checklist";
            const phase = "Development";
            const referenceDocuments = ['Design - Code Review Checklist', 'Design - Pseudocode', 'Generated Codes'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "code review checklist Development",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateCodeReviewCheckListAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            console.log(response, 'responseresponseresponseresponse');

            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }





    // PS_1119 - PS_1135 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateUnitTestCaseCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateUnitTestCaseCode(noteName) {
        try {
            const notebookName = "Unit Test Cases Notebook";
            const phase = "Development";
            const referenceDocuments = ['Unit Test Case Pseudocode'];
            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];
            setChatHistory(newChat);
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "unitTestcaseCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_1156 - PS_1180 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateNotebookChangesRecommendations() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateNotebookChangesRecommendations(noteName, fileName) {
        try {
            const notebookName = "Notebook Changes Recommendations";
            const phase = "Development";
            const referenceDocuments = ['Unit Test Case Results'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "file", type: "text", UnitTestResultFileName: fileName },
                { flow: "user", type: "text", text: "Unit Test Case Results Details" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            fetchChatHistory()
            console.log(`JSON data Updated successfully to ${blobJson}`);


            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "notebookRecommendations",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateNotebookRecommendationsAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }








    // PS_1180 - PS_1213 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateLoggerCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateDataQualityCode(noteName) {
        try {
            const notebookName = "Data Quality Notebook";
            const phase = "Development";
            const referenceDocuments = ['Design - Data Quality Pseudocode'];

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                { flow: "ai", type: "text", text: `Let's move towards generating ${notebookName}`, phase: phase },
                { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true, phase: phase },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready', phase: phase }
            ];

            setChatHistory(newChat);

            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);

            const object = {
                isRegenerate: false,
                userId: state.userId,
                orgId: state.orgId,
                projectId: state.projectId,
                notebookName: "dataQualityCode",
                jwt_token: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName
            }

            const response = await generateFrameworksAPI(object, state.jwt);
            // call the generation function api
            // setLoader(() => false);
            if (response.status === 403) {
                sessionExpired()
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData)
                }
            }
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
    }

    // PS_1219 - PS_1222 Inside checkPseudocodeLooksgood(), based on the notebookName, invoke the generateLoggerCode() function to start the generation process and which will be update the chatHistory as per the generations based on the notebookName.
    async function generateDataQualityResults(noteName, fileName) {
        try {

            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "file", type: "text", DataQualityResultFileName: fileName },
                { flow: 'user', type: 'text', text: 'Data Quality Results Details', phase: 'Development' },
                { flow: 'ai', type: 'codeShow', text: 'You can find the data quality results sheet here,', hyperlinkText: 'Data Quality Results Sheet', notebookName: noteName, id: 'noteName', show: false, isAction: true, phase: 'Development' }
            ];
            setChatHistory(newChat);
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            console.log(`JSON data Updated successfully to ${blobJson}`);
        } catch (error) {
            console.error(`Error generating ${noteName}:`, error);
        }
        finally {
            await fetchChatHistory()
        }
    }




    // Helper function to update chat history and fetch new data
    async function updateAndFetchChat(newChat, notebookName) {
        setChatHistory(newChat);

        const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
        console.log(`JSON data Updated successfully to ${blobJson}`);

        const object = {
            isRegenerate: false,
            userId: state.userId,
            orgId: state.orgId,
            projectId: state.projectId,
            notebookName: notebookName,
            jwt_token: state.jwt,
            projectName: state.projectName,
            orgName: state.orgName,
            jwt: state.jwt
        };

        const response = await generateFrameWorksAPI(object, state.jwt);

        if (response.statusCode == 403) {
            sessionExpired();
            return;
        } else if (response.statusCode !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        } else {
            const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
            if (fetchedData) {
                setChatHistory(fetchedData);
            }
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // PS_1227 based on the notebookName from that data, the respective generation function will be invoked  with the formed object with projectId, projectName, orgName, isregenarate as true, orgId, type as notebookName.
    async function regenerate(data) {
        try {
            const notebookName = data.notebookName;

            const defineNotebooks = [
                'inventory', 'InventoryDataModel', 'catalogSchema', 'tableMapping',
                'sourceToTargetFlow', 'approachDiagram', 'analysisDocument'
            ];

            const designNotebooks = [
                'dataDictionary', 'metadataTable', 'ddlPseudo', 'loggerPseudo',
                'utilityPseudo', 'configurationPseudo', 'traditionalPseudo',
                'copyClonePseudo', 'changesPseudo', 'checklistPseudo',
                'unitTestcasePseudo', 'dataQualityPseudo'
            ];

            const developmentNotebooks = [
                'ddlCode', 'loggerCode', 'utilityCode', 'configurationCode',
                'traditionalCode', 'copyCloneCode', 'changesCode', 'checklistCode',
                'unitTestcaseCode', 'notebookChangesRecommend', 'dataQualityCode'
            ];

            if (notebookName === 'tableSummary' || notebookName === 'notebookSummary') {
                console.log(`Regenerate not applicable for ${notebookName}`);
                return;
            }

            let notebookPhase;
            if (defineNotebooks.includes(notebookName)) {
                notebookPhase = 'Define';
            } else if (designNotebooks.includes(notebookName)) {
                notebookPhase = 'Design';
            } else if (developmentNotebooks.includes(notebookName)) {
                notebookPhase = 'Development';
            } else {
                throw new Error('Unknown notebook name or type');
            }

            let newChat = [...chatHistory]
            console.log(newChat, data, 'beforenewChatnewChatnewChatnewChat');

            // Remove the last object from chatHistory
            newChat.pop();
            // Enable loader for the last item in chatHistory
            newChat[newChat.length - 1].show = true;
            // Add new message about document preparation
            newChat.push({
                flow: 'ai',
                type: 'text',
                text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready',
                phase: notebookPhase
            });


            console.log(newChat, 'newChatnewChatnewChatnewChat');

            setChatHistory((a) => [...newChat]);
            // Update JSON in blob
            newChat = [...newChat]
            const blobJson = await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
            console.log(`JSON data Updated successfully to ${blobJson}`);


            let objectRegenerate = {
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                notebookName: notebookName,
                jwt_token: state.jwt,
                jwt: state.jwt,
                projectName: state.projectName,
                orgName: state.orgName,
                new_base_path:state.newStoragePath?state.newStoragePath :state.newstoragePath
            }
            let jwt = state.jwt;

            let response;

            // Define Phase Notebooks
            if (notebookName === 'inventory') {
                response = await inventoryGeneration(objectRegenerate, jwt);
            } else if (notebookName === 'InventoryDataModel') {
                response = await generateInventoryDataModelAPI(objectRegenerate, jwt);
            } else if (notebookName === 'catalogSchema') {
                response = await generateCatalogAndSchemaDetailsAPI(objectRegenerate, jwt);
            } else if (notebookName === 'tableMapping') {
                response = await generateTableMappingAPI(objectRegenerate, jwt);
            } else if (notebookName === 'sourceToTargetFlow') {
                response = await generateSourceToTargetFlow(objectRegenerate, jwt);
            } else if (notebookName === 'approachDiagram') {
                response = await api.generateApproachdiagram(objectRegenerate, jwt);
            } else if (notebookName === 'analysisDocument') {
                response = await generateAnalysisdocument(objectRegenerate, jwt);
            }

            // Design Phase Notebooks
            else if (notebookName === 'dataDictionary') {
                response = await generateDataDictionaryuc(objectRegenerate, jwt);
            } else if (notebookName === 'metadataTable') {
                response = await generateMetadataTableuc(objectRegenerate, jwt);
            } else if (notebookName === 'ddlPseudo') {
                response = await generateDDLScriptsPseudouc(objectRegenerate, jwt);
            } else if (notebookName === 'loggerPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'utilityPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'configurationPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'traditionalPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'copyClonePseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'changesPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'checklistPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'unitTestcasePseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'dataQualityPseudo') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            }

            // Development Phase Notebooks
            else if (notebookName === 'ddlCode') {
                response = await generateDDLScriptsCodeAPI(objectRegenerate, jwt);
            } else if (notebookName === 'loggerCode') {
                response = generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'utilityCode') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'configurationCode') {

                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'traditionalCode') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'copyCloneCode') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'changesCode') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'checklistCode') {
                response = await generateCodeReviewCheckListAPI(objectRegenerate, jwt);
            } else if (notebookName === 'unitTestcaseCode') {
                response = await generateFrameworksAPI(objectRegenerate, jwt);
            } else if (notebookName === 'notebookChangesRecommend') {
                response = await generateNotebookRecommendationsAPI(objectRegenerate, jwt);
            } else if (notebookName === 'dataQualityCode') {
                response = await generateFrameWorksAPI(objectRegenerate, jwt);
            }


            if (response.status === 403) {
                sessionExpired();
                return;
            } else if (response.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            } else {
                const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
                if (fetchedData) {
                    setChatHistory(fetchedData);
                }
            }

            // Fetch updated chat history
            const fetchedData = await fetchJsonFromBlob(state.orgId, state.projectId, "chatHistory.json", 'projectfiles');
            if (fetchedData) {
                setChatHistory(fetchedData);
            }

            // Check if response.isActions is true, then show Edit, Regenerate, and Download icons
            if (response && response.isAction) {
                setShowActions(true);
            }

        } catch (error) {
            console.error('Error in regenerate function:', error);
            // Handle the error appropriately
        }
    }

    //PS_886 - 1205 The downloadResponse function to handle individual component downloads.
    async function downloadResponse(data) {
        // debugger
        // debugger
        let type = data.notebookName;
        let filename = data.hyperlinkText;
        let phase = data.phase;

        console.log(type, phase, filename, "===================");
        if (type === 'dataDictionary') {
            type = 'ucdatadictionary'
        } else if (type === 'metadataTable') {
            type = 'ucmetadata'
        } else if (type === 'ddlPseudo') {
            type = 'ucddlscripts'
        } else if (type === 'utilityPseudo') {
            type = 'ucutilitiespseudocode'
        } else if (type === 'loggerPseudo') {
            type = 'ucloggerpseudocode'
        } else if (type === 'configurationPseudo') {
            type = 'configurationpseudocode'
        } else if (type === 'traditionalPseudo') {
            type = 'traditionalpseudocode'
        } else if (type === 'copyClonePseudo') {
            type = 'copyclonepseudocode'
        } else if (type === 'changesPseudo') {
            type = 'changespseudocode'
        } else if (type === 'checklistPseudo') {
            type = 'ucchecklist'
        } else if (type === 'dataQualityResults') {
            type = 'dqresult'
        } else if (type === 'notebookChangesRecommend') {
            type = 'notebookrecommendation'
        } else if (type === 'unitTestcasePseudo') {
            type = 'unittestcase'
        } else if (type === 'dataQualityPseudo') {
            type = 'ucdataquality'
        } else if (type === 'checklistCode') {
            type = 'ucchecklist'
        } else if (type === 'approachDiagram') {
            type = 'approach'
        } else if (type === 'analysisDocument') {
            type = 'analysisdocumenturl';
        }

        let catalogName;





        // let body = {
        //     projectId: state.projectId,
        //     projectName: state.projectName,
        //     type: type,
        //     userId: state.userId,
        //     orgId: state.orgId,
        //     orgName: state.orgName,
        //     catalogName: catalogName,
        //     CatalogName: catalogName,
        //     jwt: state.jwt,
        //     phase: phase
        // };
        let commonBody = {
            projectId: state.projectId,
            projectName: state.projectName,
            userId: state.userId,
            orgId: state.orgId,
            orgName: state.orgName,
            jwt: state.jwt,
            phase: phase
        };

        // Helper function for downloading the data dictionary
        async function downloadDDAndDDL(catalog, type1) {
            let body = { ...commonBody, type: type1, catalogName: catalog, CatalogName: catalog };
            console.log(body, 'body for data dictionary');

            if (phase === 'Define' || phase === 'Design') {
                const response = await api.fetchPseudocodeData(body, state.jwt);
                if (response.statusCode === 403) {
                    sessionExpired();
                } else if (response.statusCode !== 200) {
                    document.getElementById("openErrorModal").click();
                } else {
                    let getData;
                    if (type1 === "ucdatadictionary") {
                        getData = response.data[0]?.DataDictionary;
                    } else {
                        getData = response.data[0].DDLScriptsPseudoURL;
                    }
                    console.log(type1, "0000000000000000000");
                    console.log(getData, 'getData');

                    let DDdata = await fetchJsonFromURL(getData);

                    // Ensure DataDictionary is an array
                    if (!Array.isArray(DDdata)) {
                        DDdata = [DDdata];
                    }
                    console.log(DDdata, "Check data");

                    // Remove 'index' and 'reason' columns from each row before downloading
                    DDdata = DDdata.map(removeIndexAndReason);

                    if (DDdata.length > 0) {
                        const worksheet = XLSX.utils.json_to_sheet(DDdata);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, type1 === "ucdatadictionary" ? `DataDictionary_${catalog}` : `DDL_Scripts_${catalog}`);
                        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                        const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                        FileSaver.saveAs(fileData, type1 === "ucdatadictionary" ? `DataDictionary_${catalog}.xlsx` : `DDL_Scripts_${catalog}.xlsx`);
                    } else {
                        console.log(`No data found for catalog ${catalog}`);
                    }
                }
            } else if (phase === 'Development') {
                const response = await api.getCodePageDataApi(body, state.jwt);
                if (response.statusCode === 403) {
                    sessionExpired();
                } else if (response.statusCode !== 200) {
                    document.getElementById("openErrorModal").click();
                } else {
                    // Additional logic for data dictionary during Development phase if needed
                }
            }
        }

        // Function to remove 'index' and 'reason' columns
        function removeIndexAndReason(row) {
            const modifiedRow = { ...row }; // Create a shallow copy of the row

            // Conditionally remove 'index' and 'reason' if they exist
            if ('index' in modifiedRow) {
                delete modifiedRow.index;
            }
            if ('reason' in modifiedRow) {
                delete modifiedRow.reason;
            }

            return modifiedRow;
        }


        async function downloadDDL(catalog, type1) {
            debugger
            let body = { ...commonBody, type: type1, catalogName: catalog, CatalogName: catalog };
            console.log(body, 'body for DDLScript');
            if (phase === 'Development') {
                const response = await api.getCodePageDataApi(body, state.jwt);
                if (response.status === 403) {
                    sessionExpired();
                } else if (response.status !== 200) {
                    document.getElementById("openErrorModal").click();
                } else {
                    let getData = response.data[0].code

                    console.log(type1, "0000000000000000000");

                    console.log(getData, 'getData');
                    let PseudoData = await fetchTextFromURL(getData);
                    if (typeof PseudoData=== "string"){
                        PseudoData = JSON.parse(PseudoData)
                    }
                    const element = document.createElement("a");
                    element.setAttribute(
                        "href",
                        "data:text/plain;charset=utf-8," + encodeURIComponent(PseudoData)
                    );
                    element.setAttribute("download", `${filename}_${catalog}` + ".py");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            }
        }

        // If the type is ucdatadictionary, handle it for each catalog separately
        if (type === 'ucdatadictionary') {
            let catalogNames = [];
            // Parse oldCatalogName
            if (typeof state.oldCatalogName === 'string') {
                catalogNames = JSON.parse(state.oldCatalogName);
            } else {
                catalogNames = state.oldCatalogName;
            }

            if (catalogNames.length > 0) {
                for (let catalog of catalogNames) {
                    await downloadDDAndDDL(catalog, type);
                }
            } else {
                console.log("No catalogs available for ucdatadictionary.");
            }
        }

        if (type === 'ucddlscripts') {
            let catalogNames = [];
            // Parse oldCatalogName
            if (typeof state.newCatalogName === 'string') {
                catalogNames = JSON.parse(state.newCatalogName);
            } else {
                catalogNames = state.newCatalogName;
            }

            if (catalogNames.length > 0) {
                for (let catalog of catalogNames) {
                    await downloadDDAndDDL(catalog, type);
                }
            } else {
                console.log("No catalogs available for ucddlscripts.");
            }
        }
        if (type === 'ddlCode') {
            debugger
            let catalogNames = [];
            // Parse oldCatalogName
            if (typeof state.newCatalogName === 'string') {
                catalogNames = JSON.parse(state.newCatalogName);
            } else {
                catalogNames = state.newCatalogName;
            }

            if (catalogNames.length > 0) {
                for (let catalog of catalogNames) {
                    await downloadDDL(catalog, type);
                }
            } else {
                console.log("No catalogs available for ucddlscripts.");
            }
        }
        else {

            // Default to ALL CATALOG if catalogNames are not relevant to this type
            catalogName = "ALL CATALOG"
            let CatalogName = "ALL CATALOG"
            let body = { ...commonBody, type, catalogName, CatalogName };
            console.log(body, "Bodyyyyyy")

            if (phase === 'Define' || phase === 'Design') {
                const response = await api.fetchPseudocodeData(body, state.jwt);
                console.log(type, 'typeeee');

                console.log(response, 'ressssssssssssssssssssssssssssssssdes');

                if (response.statusCode === 403) {
                    sessionExpired();
                    return;
                } else if (response.statusCode !== 200) {
                    document.getElementById("openErrorModal").click();
                } else {
                    console.log(data.contents, 'aaaaaaaaaaaaaaaaaaaa');

                    if (type === 'tableSummaryBefore' || type === 'tableSummaryAfter') {
                        let num = 1;
                        let scopeData = [];
                        if (data.contents.TableInScope) {
                            scopeData = [
                                [8, "Number of Tables inscope", data.contents.TableInScope],
                                [9, "Number of Tables outscope", data.contents.TableOutScope]
                            ]
                        } else if (data.contents.TableOutScope || data.contents.InScopeTables) {
                            scopeData = [
                                [8, "Number of Tables inscope", data.contents.InScopeTables],
                                [9, "Number of Tables outscope", data.contents.TableOutScope]
                            ]
                        }
                        console.log(data.contents.InScopeTables, data.contents.TableInScope, 'scopeDatascopeDatascopeDatascopeData');

                        const sheetData = [
                            ["S.No", "Fields", "Count"],
                            [1, "Total Number of tables", data.contents.TotalTables],
                            [2, "Number of Managed Tables", data.contents.ManagedTables],
                            [3, "Number of External Tables", data.contents.ExternalTables],
                            [4, "Number of Tables to be Configured", data.contents.TableToBeCongfigured],
                            [5, "Number of tables to be Migrated", data.contents.TableToBeMigrated],
                            [6, "Number of Changes in the Notebooks", data.contents.NotebookInvolvedInChanges],
                            [7, "Number of Changed tables in the notebook", data.contents.changesInNoteBook],
                            // data.contents.TableInScope && [8, "Number of Tables in-scope", data.contents.TableInScope],
                            // data.contents.TableOutScope && [9, "Number of Tables out-scope", data.contents.TableOutScope],
                            // data.contents.InScopeTables && [8, "Number of Tables in-scope", data.contents.InScopeTables],
                            // data.contents.OutOfScopeTables && [9, "Number of Tables out-scope", data.contents.OutOfScopeTables],
                            scopeData[0],
                            scopeData[1],
                            data.contents.VersionHistory && [10, "Tables required Version history", data.contents.VersionHistory], // Assuming a static value for Deep Cloned
                            [],
                            ["Volume", "Description", "Count"],
                            ["Low Volume Tables", `Volume of tables between 1Mb to 10Gb is categorized as Low volume tables`, `${data.contents?.LowVolumeTables || 0}`],
                            ["Medium Volume Tables", `Volume of tables between 10Gb to 50Gb is categorized as Medium volume tables`, `${data.contents.MediumVolumeTables || 0}`],
                            ["High Volume Tables", `Volume of tables over 500Gb is categorized as High volume tables`, `${data.contents.HighVolumeTables || 0}`],
                            [],
                            ["Batch Details", "", ""]
                        ];


                        sheetData.push(["Batch ID", "No. of Tables"]);
                        console.log(data.contents.BatchDetails, 'data.BatchDetails')
                        data.contents.BatchDetails.forEach((batch, index) => {
                            sheetData.push([batch.batchId, batch.count]);
                        });

                        // Create a new workbook and add the sheet
                        const ws = XLSX.utils.aoa_to_sheet(sheetData);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'TableSummary');

                        // Generate buffer and save as file
                        XLSX.writeFile(wb, 'TableSummary.xlsx');
                    }
                    else if (type === 'notebookSummaryBefore') {
                        const sheetData = [
                            ["S.No", "Fields", "Count"],
                            [1, "Number of Notebooks", data.contents.TotalNotebooks],
                            [2, "Number of Notebooks Involved in Changes", data.contents.NoteBookInvolvedInChanges],
                            [3, "Number of Changed Tables in Notebooks", data.contents.ChangedTablesIntheNoteBook],
                        ]

                        // Create a new workbook and add the sheet
                        const ws = XLSX.utils.aoa_to_sheet(sheetData);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'NotebookSummary');

                        // Generate buffer and save as file
                        XLSX.writeFile(wb, 'NoteBookSummary-Before.xlsx');

                    }
                    else if (type === 'notebookSummaryAfter') {
                        const sheetData = [
                            ["S.No", "Fields", "Count"],
                            [1, "Number of Current Notebooks", data.contents.TotalNotebooks],
                            [2, "Number of Notebooks Involved in Changes", data.contents.NoteBookInvolvedInChanges],
                            [3, "Number of Changed Tables in Notebooks", data.contents.ChangedTablesIntheNoteBook],
                            [4, "Number of Notebooks Uploaded Initially", data.contents.NotebooksUploadedInitially],
                            [5, "Number of Notebooks To Be Tested", data.contents.NoteBookToBeTested],
                            [6, "Number of Notebooks Note To Be Tested", data.contents.NoteBookNotToBeTested],
                            [7, "Number of Matched Tables", data.contents.MatchedTables],
                            [8, "Number of Unmatched Tables", data.contents.UnmatchedTables],
                        ]
                        // Create a new workbook and add the sheet
                        const ws = XLSX.utils.aoa_to_sheet(sheetData);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'NotebookSummary');

                        // Generate buffer and save as file
                        XLSX.writeFile(wb, 'NoteBookSummary-Final.xlsx');
                    }
                    else if (type === 'inventory' ||
                        type === 'catalogSchema' ||
                        type === 'tableMapping' ||
                        type === 'ucmetadata' ||
                        type === 'ucloggerpseudocode' ||
                        type === 'ucutilitiespseudocode' ||
                        type === 'configurationpseudocode' ||
                        type === 'traditionalpseudocode' ||
                        type === 'copyclonepseudocode' ||
                        type === 'changespseudocode' ||
                        type === 'ucchecklist' ||
                        type === 'unittestcase' ||
                        type === 'ucdataquality') {

                        const urlToFetch =
                            type === "inventory" ? response.data[0].inventoryDDURL :
                                type === "catalogSchema" ? response.data[0].catalogAndSchemaDetailsURL :
                                    type === "tableMapping" ? response.data[0].UCTableMappingURL :
                                        type === "ucmetadata" ? response.data[0].metadataTableURL :
                                            type === "ucddlscripts" ? response.data[0].DDLScriptsPseudoURL :
                                                type === "ucloggerpseudocode" ? response.data[0].loggerPseudocodeURL :
                                                    type === "ucutilitiespseudocode" ? response.data[0].utilitiesPseudocodeURL :
                                                        type === "configurationpseudocode" ? response.data[0].configurationPseudocodeURL :
                                                            type === "traditionalpseudocode" ? response.data[0].TraditionalPseudoURL :
                                                                type === "copyclonepseudocode" ? response.data[0].copyClonePseudocodeURL :
                                                                    type === "changespseudocode" ? response.data[0].changesPseudocodeURL :
                                                                        type === "ucchecklist" ? response?.data :
                                                                            type === "unittestcase" ? response.data[0].unitTestCasePseudocodeURL :
                                                                                type === "ucdataquality" ? response.data[0].dataQualityPseudocodeURL :
                                                                                    '';
                        console.log(urlToFetch, 'urlToFetchurlToFetchurlToFetchurlToFetch');
                        if (type === "ucchecklist") {
                            console.log(response.data, '090909090');

                            const PseudoData = urlToFetch;

                            let parsedData;
                            if (typeof PseudoData === 'string') {
                                try {
                                    parsedData = JSON.parse(PseudoData);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    parsedData = []; // Set to an empty array to avoid further errors
                                }
                            } else {
                                parsedData = PseudoData;
                            }

                            // Create a new workbook
                            const wb = XLSX.utils.book_new();
                            const sheetMap = {}; // Object to hold sheet data

                            // Process each entry in parsedData
                            parsedData.forEach((sheetData) => {
                                console.log(parsedData, "resssssssssssssssssssssssssssssssssssss");

                                // Limit sheet name length to 30 characters
                                const sheetName = sheetData.NotebookName.slice(0, 30);

                                // Initialize the sheet if it doesn't exist
                                if (!sheetMap[sheetName]) {
                                    sheetMap[sheetName] = [
                                        ["DesignID", "Notebook Name", "Command Purpose", "Checklist Step", "Comments", "Status"], // Header row
                                    ];
                                }

                                // Add data to the corresponding sheet
                                sheetMap[sheetName].push([
                                    sheetData.DesignID, // Assuming DesignID is present in the data
                                    sheetData.NotebookName,
                                    sheetData.CommandPurpose,
                                    sheetData.CheckListStep,
                                    sheetData.comments,   // Assuming comments is present in the data
                                    sheetData.Status
                                ]);
                            });

                            // Create sheets in the workbook from the map
                            Object.keys(sheetMap).forEach((sheetName) => {
                                sheetMap[sheetName].sort((a, b) => {
                                    if (a[0] === "DesignID") return -1; // Keep header row at the top
                                    if (b[0] === "DesignID") return 1; // Keep header row at the top

                                    const stepA = parseInt(a[0].replace('Step ', ''), 10);
                                    const stepB = parseInt(b[0].replace('Step ', ''), 10);

                                    return stepA - stepB;
                                });
                                const ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
                                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                            });

                            // Write the workbook to a file
                            XLSX.writeFile(wb, "Code Review Checklist Design.xlsx");
                        } else {

                            const PseudoData = await fetchJsonFromURL(urlToFetch);

                            console.log(PseudoData, 'PseudoDataPseudoDataPseudoDataPseudoData');

                            let parsedData;
                            if (typeof PseudoData === 'string') {
                                try {
                                    parsedData = JSON.parse(PseudoData);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    parsedData = PseudoData;
                                }
                            } else {
                                parsedData = PseudoData;
                            }


                            // Check if parsedData is an array
                            if (!Array.isArray(parsedData)) {
                                console.error('Pseudocode data is not in the correct format.');
                                return; // Exit the function if parsedData is not an array
                            }

                            // Filter out index and reason fields
                            const filteredData = parsedData.map(({ index, reason, ...rest }) => rest);

                            // Proceed with converting parsedData to an Excel sheet
                            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                            const fileExtension = '.xlsx';

                            filename = await truncateSheetName(filename)

                            const ws = XLSX.utils.json_to_sheet(filteredData);
                            console.log(filteredData, 'parsedDataparsedDataparsedDataparsedDataparsedDataparsedDataparsedDataparsedDataparsedData');
                            const wb = { Sheets: { [filename]: ws }, SheetNames: [filename] };

                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: fileType });

                            FileSaver.saveAs(data, `${filename}${fileExtension}`); // Corrected file name
                        }


                    } else if (type === 'analysisdocumenturl') {
                        const sheetsArray = [];

                        const urlToFetch = response.data[0].analysisDocumentURL;
                        const PseudoData = await fetchJsonFromURL(urlToFetch);

                        console.log(PseudoData, 'PseudoData');

                        let parsedData;
                        if (typeof PseudoData === 'string') {
                            try {
                                parsedData = JSON.parse(PseudoData);
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                                parsedData = PseudoData; // Fallback to raw string
                            }
                        } else {
                            parsedData = PseudoData;
                        }

                        let analysisData = parsedData
                        // 1. Process matched tables
                        if (Array.isArray(analysisData.matched_tables)) {
                            sheetsArray.push({
                                sheetName: "Matched Tables",
                                sheetContent: analysisData?.matched_tables.map(table => ({
                                    NotebookName: table.notebookName,
                                    TableNamePathInCatalog: table.tableNameInTheCatalog,
                                    TableNamePathInNotebook: table.notebookName, // Adjust as needed
                                    InstancesTableMatchedInNotebook: table.tableCountInTheNoteBook,
                                }))
                            });
                        } else {
                            sheetsArray.push({
                                sheetName: "Matched Tables",
                                sheetContent: [] // Empty array if no matched tables
                            });
                        }
                        // 2. Process changes if they exist
                        if (Array.isArray(analysisData.changes)) {
                            sheetsArray.push({
                                sheetName: "Changes",
                                sheetContent: analysisData.changes.map(change => ({
                                    NotebookName: change.notebookName,
                                    ActualCode: change.originalCode,
                                    ChangedCode: change.updatedCode,
                                }))
                            });
                        } else {
                            sheetsArray.push({
                                sheetName: "Changes",
                                sheetContent: [] // Empty array if no changes
                            });
                        }

                        // 3. Process unmatched tables
                        if (Array.isArray(analysisData.unmatched_tables)) {
                            sheetsArray.push({
                                sheetName: "Unmatched Tables",
                                sheetContent: analysisData.unmatched_tables.map(table => ({
                                    OldCatalogName: table.sourceCatalogName,
                                    SourceSchemaName: table.sourceSchemaName,
                                    SourceTableViewName: table.sourceTableName,
                                }))
                            });
                        } else {
                            sheetsArray.push({
                                sheetName: "Unmatched Tables",
                                sheetContent: [] // Empty array if no unmatched tables
                            });
                        }

                        // 4. Process notebooks to be tested
                        if (Array.isArray(analysisData.notebooksToBeTested)) {
                            sheetsArray.push({
                                sheetName: "Notebook To Be Tested",
                                sheetContent: analysisData.notebooksToBeTested.map(notebook => ({
                                    Notebook: notebook.notebookName,
                                    NotebookToBeTested: notebook.noteBookToBetested,
                                }))
                            });
                        } else {
                            sheetsArray.push({
                                sheetName: "Notebook To Be Tested",
                                sheetContent: [] // Empty array if no notebooks to be tested
                            });
                        }



                        // Create a new workbook
                        const wb = XLSX.utils.book_new();

                        // Add each sheet to the workbook
                        sheetsArray.forEach(sheet => {
                            const ws = XLSX.utils.json_to_sheet(sheet.sheetContent);
                            XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);
                        });

                        // Generate buffer and trigger download
                        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        const fileExtension = '.xlsx';

                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: fileType });

                        FileSaver.saveAs(data, `AnalysisDocument${fileExtension}`); // 

                    } else if (type === 'InventoryDataModel') {

                        const urlToFetch = response.data[0].inventoryDMURL;

                        const PseudoData = await fetchJsonFromURL(urlToFetch)

                        // Parse the changes string into an array of objects
                        let parsedData;
                        if (typeof PseudoData === 'string') {
                            try {
                                parsedData = JSON.parse(PseudoData);
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                                parsedData = PseudoData;
                            }
                        } else {
                            parsedData = PseudoData;
                        }
                        const svgData = await fetchKrokiDiagram(parsedData);
                        if (svgData) {
                            await downloadSVG(svgData, 'Inventory_Data_Model_ALL_CATALOG')
                        }
                    }
                    else if (type === 'approach') {

                        const { approachDiagramAfterURL, approachDiagramBeforeURL } = response.data[0];

                        // Function to fetch and download SVG from a given URL
                        const fetchAndDownloadSVG = async (url, fileName) => {
                            const PseudoData = await fetchJsonFromURL(url);

                            // Parse the changes string into an array of objects
                            // let parsedData;
                            // if (typeof PseudoData === 'string') {
                            //     try {
                            //         parsedData = JSON.parse(PseudoData);
                            //     } catch (error) {
                            //         console.error('Error parsing JSON:', error);
                            //         parsedData = PseudoData; // Fallback if parsing fails
                            //     }
                            // } else {
                            //     parsedData = PseudoData;
                            // }
                            console.log(PseudoData, 'PseudoDataPseudoDataPseudoData');


                            const svgData = await fetchKrokiDiagram(PseudoData);
                            console.log(svgData, 'svgDatasvgDataPseudoDataPseudoDataPseudoData');

                            if (svgData) {
                                await downloadSVG(svgData, fileName);
                            }
                        };

                        // Download SVG for both URLs
                        await fetchAndDownloadSVG(approachDiagramBeforeURL, 'ApproachDiagram_Before');
                        await fetchAndDownloadSVG(approachDiagramAfterURL, 'ApproachDiagram_After');
                    }
                }

            }
            else if (phase === 'Development') {

                if (type === "ucchecklist" || type === 'notebookrecommendation' || type === 'dqresult') {
                    const response = await api.fetchPseudocodeData(body, state.jwt);
                    console.log(type, 'typeeee');

                    console.log(response, 'ressssssssssssssssssssssssssssssssdes');

                    if (response.statusCode === 403) {
                        sessionExpired();
                        return;
                    } else if (response.statusCode !== 200) {
                        document.getElementById("openErrorModal").click();
                    } else {


                        if (type === "ucchecklist") {
                            console.log(response.data, '090909090');

                            const PseudoData = response.data;

                            // Parse the changes string into an array of objects
                            let parsedData;
                            if (typeof PseudoData === 'string') {
                                try {
                                    parsedData = JSON.parse(PseudoData);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    parsedData = []; // Set to an empty array to avoid further errors
                                }
                            } else {
                                parsedData = PseudoData;
                            }

                            // Create a new workbook
                            const wb = XLSX.utils.book_new();
                            const sheetMap = {}; // Object to hold sheet data

                            // Process each entry in parsedData
                            parsedData.forEach((sheetData) => {
                                console.log(parsedData, "resssssssssssssssssssssssssssssssssssss");

                                // Limit sheet name length to 30 characters
                                const sheetName = sheetData.NotebookName.slice(0, 30);

                                // Initialize the sheet if it doesn't exist
                                if (!sheetMap[sheetName]) {
                                    sheetMap[sheetName] = [
                                        ["DesignID", "Notebook Name", "Command Purpose", "Checklist Step", "Comments", "Status"], // Header row
                                    ];
                                }

                                // Add data to the corresponding sheet
                                sheetMap[sheetName].push([
                                    sheetData.DesignID, // Assuming DesignID is present in the data
                                    sheetData.NotebookName,
                                    sheetData.CommandPurpose,
                                    sheetData.CheckListStep,
                                    sheetData.comments,   // Assuming comments is present in the data
                                    sheetData.Status
                                ]);
                            });

                            // Sort the rows based on DesignID (alphanumeric sorting) for each sheet
                            Object.keys(sheetMap).forEach((sheetName) => {
                                const sheetData = sheetMap[sheetName];

                                // Log DesignID values to inspect them before sorting
                                console.log(`Before sorting sheet ${sheetName}:`);
                                sheetData.slice(1).forEach(row => console.log(row[0])); // Logging DesignID values (skipping header row)

                                // Ensure DesignID is a string and sort the rows starting from the second row (index 1)
                                sheetData.slice(1).sort((a, b) => {
                                    return String(a[0]).localeCompare(String(b[0])); // Alphanumeric sort for DesignID as strings
                                });

                                // Log the sorted DesignID values for verification
                                console.log(`After sorting sheet ${sheetName}:`);
                                sheetData.slice(1).forEach(row => console.log(row[0])); // Logging sorted DesignID values (skipping header row)

                                // Create the worksheet from the sorted data
                                const ws = XLSX.utils.aoa_to_sheet(sheetData);
                                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                            });

                            // Write the workbook to a file
                            XLSX.writeFile(wb, "Code Review Checklist Dev.xlsx");
                        }



                        else if (type === 'dqresult') {
                            const wb = XLSX.utils.book_new(); // Create a new workbook

                            // Handle notebookrecommendation and dqresult types
                            const urlToFetch = response.data[0].dataQualityResultURL;

                            const PseudoData = await fetchJsonFromURL(urlToFetch);

                            // Parse the changes string into an array of objects
                            let parsedData;
                            if (typeof PseudoData === 'string') {
                                try {
                                    parsedData = JSON.parse(PseudoData);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    parsedData = PseudoData; // Fallback to raw string
                                }
                            } else {
                                parsedData = PseudoData;
                            }

                            console.log(parsedData.RowCount, 'parsedDataparsedDataparsedDataparsedDataparsedData');


                            // Check if parsedData contains RowCount
                            if (parsedData.RowCount && Array.isArray(parsedData.RowCount)) {
                                const dqResultSheetContent = parsedData.RowCount.map(row => ({
                                    DDLScripts: row.Scripts,
                                    SourceRowCount: row.SourceRowCount,
                                    DestinationRowCount: row.DestinationRowCount,
                                    Status: row.ValidationStatus // Assuming ValidationStatus corresponds to Pass/Fail
                                }));

                                // Add DataQualityResult sheet to the workbook
                                const dqResultSheetName = "DataQualityResult";
                                const dqResultSheet = XLSX.utils.json_to_sheet(dqResultSheetContent);
                                XLSX.utils.book_append_sheet(wb, dqResultSheet, dqResultSheetName);
                            }

                            // Write the workbook to a file
                            XLSX.writeFile(wb, "Data Quality Results.xlsx");
                            // If you need to handle other parts of the response, add that logic here as well.
                        } else if (type === 'notebookrecommendation') {
                            const wb = XLSX.utils.book_new(); // Create a new workbook

                            // Handle notebookrecommendation type
                            const urlToFetch = response.data[0].noteBookChangesURL;
                            const PseudoData = await fetchJsonFromURL(urlToFetch);

                            // Parse the changes string into an array of objects
                            let parsedData;
                            if (typeof PseudoData === 'string') {
                                try {
                                    parsedData = JSON.parse(PseudoData);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    parsedData = PseudoData; // Fallback to raw string
                                }
                            } else {
                                parsedData = PseudoData;
                            }

                            console.log(parsedData, 'parsedDataparsedDataparsedDataparsedDataparsedData');

                            // Prepare data for the "NotebookChangesRecommendations" sheet
                            const notebookChangesRecommendations = parsedData.map(item => ({
                                NotebookName: item.NotebookName,
                                Issues: item.Issues,
                                FailedCode: item.FailedCode,
                                suggestion: item.suggestion,
                                Action: item.Action,
                            }));

                            // Create a worksheet from the prepared data
                            const notebookWs = XLSX.utils.json_to_sheet(notebookChangesRecommendations);

                            // Append the worksheet to the workbook with the specified sheet name
                            XLSX.utils.book_append_sheet(wb, notebookWs, "NotebookChangesRecommendations");
                            XLSX.writeFile(wb, "NotebookChangesRecommendations.xlsx");

                        }


                    }
                } else {
                    if (type === 'dataQualityCode') {
                        body.type = 'ucdataQualityCode'
                    }
                    const response = await api.getCodePageDataApi(body, state.jwt);
                    console.log(response, 'ressssssssssssssssssssssssssssssss');

                    if (response.status === 403) {
                        sessionExpired();
                    } else if (response.status !== 200) {
                        document.getElementById("openErrorModal").click();
                    }
                    else {
                        if (
                            type === 'ddlCode' ||
                            type === 'loggerCode' ||
                            type === 'utilityCode' ||
                            type === 'configurationCode' ||
                            type === 'traditionalCode' ||
                            type === 'copyCloneCode' ||
                            type === 'changesCode' ||
                            type === 'unitTestcaseCode' ||
                            type === 'dataQualityCode'
                        ) {
                            // Handle .py file downloads for other types

                            const urlToFetch =
                                type === "ddlCode" ? response.data[0].code :
                                    type === "loggerCode" ? response.data[0].code :
                                        type === "utilityCode" ? response.data[0].code :
                                            type === "configurationCode" ? response.data[0].code :
                                                type === "traditionalCode" ? response.data[0].code :
                                                    type === "copyCloneCode" ? response.data[0].code :
                                                        type === "changesCode" ? response.data[0].code :
                                                            type === "unitTestcaseCode" ? response.data[0].code :
                                                                type === "dataQualityCode" ? response.data[0].code :
                                                                    '';

                            var PseudoData = await fetchTextFromURL(urlToFetch);
                        
                            if (typeof PseudoData=== "string"){
                                PseudoData = JSON.parse(PseudoData)
                            }
                            const element = document.createElement("a");
                            element.setAttribute(
                                "href",
                                "data:text/plain;charset=utf-8," + encodeURIComponent(PseudoData)
                            );
                            element.setAttribute("download", filename + ".py");
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                        } else {
                            console.error("Unknown download type:", type);
                        }
                    }
                }
            }
        }
    }

    // PS_1228 - PS_1253
    async function gridDataChanged() {
        // Step 1: Create a copy of chatHistory
        console.log(codeDataValues, 'logdaaaaaaaaaaaaaaaaaaa');

        let data = [...chatHistory];
        let temp = 0;
        let arr = [];
        let arr2 = [];

        // Step 2: Start iterating through each item 'a' in 'data'
        for (let a of data) {
            if (temp === 0) {
                // Step 3: Check if codeDataValues matches and isEdit is true
                if (a === codeDataValues) {
                    // Step 4: Proceed with deletion logic if codeDataValues matches
                    if (codeDataValues.notebookName === "tableMapping") {
                        // Step 5: Check if state.fromSourceToTarget is true
                        if (isSourceToTraget) {
                            // Step 6: Check if any object in chatHistory has notebookName as "approachDiagram"
                            const hasApproachDiagram = chatHistory.some(obj => obj.notebookName === "approachDiagram");

                            if (hasApproachDiagram) {
                                const toDeleteChatHistoryData = [...chatHistory];

                                // Step 7: Define mapping from notebookName to corresponding identifiers
                                const notebookToIdentifierMap = {
                                    dataDictionary: "DataDictionaryTable",
                                    ddlPseudo: "DDLTable",
                                    metaDataTable: "UCdesignDetails",
                                    loggerCode: "UCDevDetail",
                                    checklistPseudo: "codeReviewChecklist",
                                    sourceToTargetFlow: "sourceToTargetTable"
                                };

                                // Step 8: Filter for records to delete based on notebookName
                                const deleteDBRecord = toDeleteChatHistoryData.filter(obj =>
                                    Object.keys(notebookToIdentifierMap).includes(obj.notebookName)
                                );

                                // Step 9: If there are records to delete
                                if (deleteDBRecord.length > 0) {
                                    const body = {
                                        projectId: state.projectId,
                                        userId: state.userId,
                                        orgId: state.orgId,
                                        deleteDBRecords: deleteDBRecord
                                    };
                                    console.log(body, 'bodybodybodyinside');

                                    try {
                                        // Step 10: Call deleteDBRecords API
                                        const response = await deleteDBRecords(body, state.jwt);

                                        // Step 11: Validate response
                                        if (response.status !== 200) {
                                            document.getElementById("protoastMessageSomething").style.display = "block";
                                            setTimeout(() => {
                                                document.getElementById("protoastMessageSomething").style.display = "none";
                                            }, 3000);
                                        }
                                    } catch (error) {
                                        console.error("Error deleting records:", error);
                                        document.getElementById("protoastMessageSomething").style.display = "block";
                                        setTimeout(() => {
                                            document.getElementById("protoastMessageSomething").style.display = "none";
                                        }, 3000);
                                    }
                                }
                            }

                            // Check for notebook names in chatHistory array
                            const hasSourceToTargetFlow = chatHistory.some(obj => obj.notebookName === "sourceToTargetFlow");

                            if ((hasSourceToTargetFlow) && state.allApproved) {
                                setShowLooksGood(true);
                            }
                        }
                    }


                    // a.show = true; // Show the looks good button
                    // a.isAction = true; // Show the looks good button
                    bindLooksGood(codeDataValues.notebookName)
                    temp = 1; // Set temp to indicate we've processed this item
                }
                arr.push(a); // Add to arr for further processing
                console.log(arr, 'logdaaaaboy');

            } else {
                arr2.push(a); // Add remaining items to arr2
            }
        }

        // Step 12: Assign arr2 to toDeleteChatHistoryData for further processing
        const toDeleteChatHistoryData = arr2;

        const notebookToIdentifierMap = {
            dataDictionary: "dataDictionaryTable",
            ddlPseudo: "DDLTable",
            metaDataTable: "UCdesignDetails",
            loggerCode: "UCDevDetails",
            checklistPseudo: "codeReviewChecklist",
            sourceToTargetFlow: "sourceToTargetTable"
        };


        // Step 13: Filter data for deletion from DB again using the same mapping logic as above
        const deleteDBRecordFromArr2 = toDeleteChatHistoryData.filter(obj =>
            Object.keys(notebookToIdentifierMap).includes(obj.notebookName)
        );
        console.log(deleteDBRecordFromArr2, 'logdaaaaaaaaaaaaaaaaaaa');


        // Step 14: If there are records to delete from arr2
        if (deleteDBRecordFromArr2.length > 0) {
            const body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                deleteDBRecords: deleteDBRecordFromArr2
            };
            console.log(body, 'bodybodyOutside');

            try {
                const response = await deleteDBRecords(body, state.jwt);

                if (response.status !== 200) {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => {
                        document.getElementById("protoastMessageSomething").style.display = "none";
                    }, 3000);
                }

                const hasTableMapping = chatHistory.some(obj => obj.notebookName === "tableMapping");
                const hasAnalysisDocument = chatHistory.some(obj => obj.notebookName === "analysisDocument");

                if ((hasTableMapping) && state.allApproved) {
                    setShowLooksGood(true);
                } else if (hasAnalysisDocument && state.confirmedNotebooks) {
                    setShowLooksGood(true);
                }
            } catch (error) {
                console.error("Error deleting records:", error);
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }
        }

        // Step 15: Update chatHistory state with modified data
        setChatHistory([...arr]);

        // Step 16: Call updateChatHistory() to persist changes into the DB via Azure Blob link
        await updateChatHistoryToBlob(state.orgId, state.projectId, [...arr], "chatHistory.json", "projectfiles");
        await fetchChatHistory()
        // Final step: Show updated chat history with actions to the user
    }




    //to handle search 
    const handleSearch = useCallback((newSearchText) => {
        setSearchText(newSearchText);
        if (newSearchText) {
            const matches = chatHistory.reduce((acc, item) => {
                if (item?.hyperlinkText && typeof item.hyperlinkText === 'string' &&
                    item.hyperlinkText.toLowerCase().includes(newSearchText.toLowerCase())) {
                    // Check if this hyperlink text is already in the accumulator
                    if (!acc.some(match => match.hyperlinkText === item.hyperlinkText)) {
                        acc.push(item);
                    }
                }
                return acc;
            }, []);

            matchesRef.current = matches;
            setMatchCount(matches.length);
            setCurrentMatchIndex(0);
            if (matches.length > 0) {
                scrollToMatch(matches[0]);
            }
        } else {
            matchesRef.current = [];
            setMatchCount(0);
            setCurrentMatchIndex(0);
        }
    }, [chatHistory]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && matchCount > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchCount;
            setCurrentMatchIndex(nextIndex);
            scrollToMatch(matchesRef.current[nextIndex]);
        }
    };

    const scrollToMatch = (match) => {
        if (match && typeof match.hyperlinkText === 'string') {
            const divElement = document.getElementById("search-" + match.hyperlinkText);
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const bindLooksGood = (notebookName) => {
        const data = [...chatHistory];
        const note = data[data.length - 1].notebookName;
        if (note === notebookName) {
            if (note === 'sourceToTargetFlow') {
                if (isSourceAllApproved !== false) {
                    data[data.length - 1].show = true;
                    // data[data.length - 1].isAction = false;
                    updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", data, "projectfiles");
                }
            }
            else if (note === 'catalogSchema') {
                if (isAllCatalogFilled !== false) {
                    data[data.length - 1].show = true;
                    data[data.length - 1].isAction = true;
                    updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", data, "projectfiles");
                }
            }
            else if (note === 'dataQualityResults') {
                data[data.length - 1].show = true;
                data[data.length - 1].isAction = false;
                updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", data, "projectfiles");
            } else {
                data[data.length - 1].show = true;
                data[data.length - 1].isAction = true;
                updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", data, "projectfiles");
            }
        }

    }

    //render the  return statement with the bound data 
    return (
        <>
            {/* PS_20 - PS_40 bindChatHitory is performed here to bind the user, ai and center flow */}
            <div
                class="d-flex justify-content-center align-items-center w-100"
                style={{ position: "absolute", zIndex: "100000" }}
            >
                <div
                    id="toastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Session has expired.Please login Again
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    id="protoastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/check 1.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Project has been created successfully
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    id="protoastMessageSomething"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Something went wrong. Try Again!
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className='div2' style={{ display: "none" }}>
                    <div id='daimage111'>
                        <PlantUML src={dataUML} /></div>
                </div> */}

            </div>
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                id="openErrorModal"
                data-bs-target="#Something-wrong"
                hidden
            >
                Oops! Something went wrong
            </button>
            <div
                className="modal fade"
                id="Something-wrong"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span>
                                    <img
                                        src="images/went-wrong-icon.svg"
                                        alt="went-wrong-icon"
                                        className="sucess-icon-sty"
                                    />
                                </span>
                                <h3 className="font-16 font-bold text-black mt-4">
                                    Oops! Something went wrong
                                </h3>
                                <p className="font-14 font-regular text-black">
                                    Please try again later{" "}
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maindiv">
                {confirmedTablePopup ?
                    <>
                        <ConfirmationTMU uploadedData={tableuploaddetails} closepop={() => { setConfirmedTablePopup(false) }} />
                    </>
                    :
                    <>
                        {showCode === false ?
                            (
                                <>
                                    <div className="container-fluid">
                                        <div className="row">
                                            {/* header style starts here */}
                                            <HeaderCom value="1" />
                                            {/* header style ends here */}
                                            <div
                                                className="overlay"
                                                id="pageLoader"
                                                style={{ display: "none" }}
                                            >
                                                <div className="position-absolute top-50 start-50  translate-middle">
                                                    <div className="d-flex align-items-center loader-bg">
                                                        <div
                                                            className="spinner-border Loader text-dark align-center"
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden"></span>
                                                        </div>
                                                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                            Loading...
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pt-4 mt-5">
                                                <div className="mt-3">
                                                    <ul className="cust-breadcrumb mb-0 ">
                                                        <li className="font-16 font-medium">
                                                            <a>Manage Projects</a>
                                                        </li>
                                                        <li className="font-16 font-medium active">
                                                            {state.isEdit
                                                                ? state.projectName
                                                                : state.projectName}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="row p-4">
                                                    <div className="d-flex  mb-4 justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center mb-xl-0 ">
                                                            <a className="d-flex" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                                                <img
                                                                    src="images/back-arrow.svg"
                                                                    alt="back-arrow"
                                                                    onClick={() => {
                                                                        navigate("/ProjectInfo", {
                                                                            state: { ...state, isEdit: true },
                                                                        });
                                                                    }}
                                                                />
                                                            </a>
                                                            <h2 className="text-black font-bold font-22 mb-0 ms-3" style={{ position: "absolute", paddingLeft: "1%" }}>
                                                                {state.isEdit === false
                                                                    ? state.projectName
                                                                    : state.projectName}
                                                            </h2>
                                                        </div>
                                                        {/* <div class="d-lg-flex d-md-block"> */}
                                                        {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                                                        <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center" style={{ marginLeft: '48%' }}>
                                                            <span className=" text-nowrap font-bold">Organization : </span>
                                                            <span>
                                                                <img
                                                                    src={state.orgLogo}
                                                                    alt="hubspot-logo"
                                                                    className="figma-logo"
                                                                />
                                                            </span>
                                                            <span className="me-2 text-nowrap ">
                                                                {" "}{state.orgName}{" "}
                                                            </span>
                                                        </div>
                                                        <div className="input-group position-relative search-wid me-md-3" style={{ overflow: 'visible' }}>
                                                            <div style={{ position: 'relative', width: '100%' }}>
                                                                <input
                                                                    type="search"
                                                                    className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                                    placeholder="Search"
                                                                    value={searchText}
                                                                    onChange={(e) => handleSearch(e.target.value)}
                                                                    onKeyPress={handleKeyPress}
                                                                    style={{
                                                                        paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                                {matchCount > 0 && (
                                                                    <span style={{
                                                                        position: 'absolute',
                                                                        left: '8px',
                                                                        top: '50%',
                                                                        transform: 'translateY(-50%)',
                                                                        fontSize: '12px',
                                                                        color: '#fff',
                                                                        backgroundColor: '#7650E0',
                                                                        padding: '2px 6px',
                                                                        borderRadius: '10px',
                                                                        zIndex: 5,
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                        {`${currentMatchIndex + 1}/${matchCount}`}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <span className="search-icon-sty" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                                            </span>
                                                        </div>
                                                        {/* </div> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <div className="code-review-tab-width">
                                                        <div className="code-review-page tabs nav ">
                                                            <a
                                                                onClick={() => {
                                                                    navigate("/ProjectInfo", {
                                                                        state: { ...state, isEdit: true },
                                                                    });
                                                                }}
                                                            >
                                                                {" "}
                                                                <div className="tab nav-link font-bold">
                                                                    Project Info
                                                                </div>
                                                            </a>

                                                            <div
                                                                className="tab nav-link font-bold active"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#ask-gen"
                                                            >
                                                                Ask GenETL
                                                            </div>

                                                            {accessControlShow && (
                                                                <div
                                                                    className="tab nav-link font-bold"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#acc-control"
                                                                    onClick={() => {
                                                                        navigate("/User", {
                                                                            state: { ...state },
                                                                        });
                                                                    }}
                                                                // onClick={() => {
                                                                //     <Groups state={state} />
                                                                // }}
                                                                >
                                                                    Access Control
                                                                </div>
                                                            )}

                                                        </div>
                                                        <div className="tab-content" id="pills-tabContent">
                                                            <div
                                                                id="ask-gen"
                                                                className="tab-pane fade code-review-tab show active tab-content"
                                                            >
                                                                <div className="chat-box px-sm-0">
                                                                    <div className="container px-0">
                                                                        <div className="row px-sm-0">
                                                                            <div
                                                                                className="chat-container"
                                                                                style={{ height: 430, overflowY: "auto" }}
                                                                            >
                                                                                <div className="col-md-12">
                                                                                    {chatHistory.length > 0 ? (
                                                                                        <>
                                                                                            {chatHistory.map((data) => {

                                                                                                if (data.flow === "center") {
                                                                                                    return (
                                                                                                        <div className="chat-divider d-flex justify-content-center">
                                                                                                            <span className="font-12 font-medium">{data.text}</span>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                                else if (data?.flow === "ai") {
                                                                                                    if (data?.type === "inventoryPopup") {
                                                                                                        return (
                                                                                                            <div className="bot-response position-relative">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Hi <span className="text-black">
                                                                                                                        {state.userName}
                                                                                                                    </span>
                                                                                                                    , Kindly "
                                                                                                                    <a
                                                                                                                        className="chatbot-anchor"
                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                        onClick={() => setInventoryPopup(true)}
                                                                                                                    >
                                                                                                                        fill out this form
                                                                                                                    </a>"{" "} to know about Connection details and Inventory Document.
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data?.show === true && (
                                                                                                                        <button
                                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                                            onClick={() => setInventoryPopup(true)}
                                                                                                                        >
                                                                                                                            Fill Form
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );

                                                                                                    }
                                                                                                    else if (data.type === "notebookPopup") {
                                                                                                        return (
                                                                                                            <div className="bot-response position-relative">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Let's move towards Analyzing Notebook. Kindly "
                                                                                                                    <a
                                                                                                                        className="chatbot-anchor"
                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                        onClick={() => setNotebookPopup(true)}
                                                                                                                    >
                                                                                                                        fill out this form
                                                                                                                    </a>" to know about the existing notebooks.
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data.show === true && (
                                                                                                                        <button
                                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                                            onClick={() => setNotebookPopup(true)}
                                                                                                                        >
                                                                                                                            Fill Form
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data.type === "summary") {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {console.log(data.contents, 'contentscontentscontents')}
                                                                                                                {data.generationType === "tableSummary" && data.contents ? (
                                                                                                                    <>
                                                                                                                        <div className="w-75">
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Total-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Total #Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.TotalTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Managed-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Managed Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.ManagedTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/External-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#External Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.ExternalTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/High-Volume-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#High Volume Tables</span>
                                                                                                                                            <span
                                                                                                                                                className="cursor-pointer"
                                                                                                                                                type="button"
                                                                                                                                                data-bs-toggle="tooltip"
                                                                                                                                                data-bs-placement="top"
                                                                                                                                                data-bs-custom-class="custom-tooltip"
                                                                                                                                                data-bs-title="This top tooltip is themed via CSS variables."
                                                                                                                                                onMouseEnter={(e) => handleMouseEnter(e, "Volume of tables over 50Gb is categorized as High volume tables and they will be split up into buckets (In an interval of 100).")}
                                                                                                                                                // onMouseMove={handleMouseMove}
                                                                                                                                                onMouseLeave={handleMouseLeave}
                                                                                                                                            >
                                                                                                                                                <img
                                                                                                                                                    src="images/tooltip-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="ms-1"
                                                                                                                                                />
                                                                                                                                            </span>
                                                                                                                                            {tooltip.show && (
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        position: 'fixed',
                                                                                                                                                        backgroundColor: '#333',
                                                                                                                                                        color: '#fff',
                                                                                                                                                        padding: '5px',
                                                                                                                                                        borderRadius: '3px',
                                                                                                                                                        fontSize: '12px',
                                                                                                                                                        visibility: tooltip.show ? 'visible' : 'hidden',
                                                                                                                                                        zIndex: 1000,
                                                                                                                                                        top: tooltip.y,
                                                                                                                                                        left: tooltip.x,
                                                                                                                                                        width: "70px%",
                                                                                                                                                        height: "50px"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {tooltip.text}
                                                                                                                                                </div>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.HighVolumeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Medium-Volume-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Medium Volume Tables</span>
                                                                                                                                            <span
                                                                                                                                                className="cursor-pointer"
                                                                                                                                                type="button"
                                                                                                                                                data-bs-toggle="tooltip"
                                                                                                                                                data-bs-placement="top"
                                                                                                                                                data-bs-custom-class="custom-tooltip"
                                                                                                                                                data-bs-title="This top tooltip is themed via CSS variables."
                                                                                                                                                onMouseEnter={(e) => handleMouseEnter(e, "Volume of tables between 10Gb to 50Gb is categorized as Medium volume tables")}
                                                                                                                                                // onMouseMove={handleMouseMove}
                                                                                                                                                onMouseLeave={handleMouseLeave}
                                                                                                                                            >
                                                                                                                                                <img
                                                                                                                                                    src="images/tooltip-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="ms-1"
                                                                                                                                                />
                                                                                                                                            </span>
                                                                                                                                            {tooltip.show && (
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        position: 'fixed',
                                                                                                                                                        backgroundColor: '#333',
                                                                                                                                                        color: '#fff',
                                                                                                                                                        padding: '5px',
                                                                                                                                                        borderRadius: '3px',
                                                                                                                                                        fontSize: '12px',
                                                                                                                                                        visibility: tooltip.show ? 'visible' : 'hidden',
                                                                                                                                                        zIndex: 1000,
                                                                                                                                                        top: tooltip.y,
                                                                                                                                                        left: tooltip.x,
                                                                                                                                                        width: "70px%",
                                                                                                                                                        height: "50px"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {tooltip.text}
                                                                                                                                                </div>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.MediumVolumeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Low-Volume-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Low Volume Tables</span>
                                                                                                                                            <span
                                                                                                                                                className="cursor-pointer"
                                                                                                                                                type="button"
                                                                                                                                                data-bs-toggle="tooltip"
                                                                                                                                                data-bs-placement="top"
                                                                                                                                                data-bs-custom-class="custom-tooltip"
                                                                                                                                                data-bs-title="This top tooltip is themed via CSS variables."
                                                                                                                                                onMouseEnter={(e) => handleMouseEnter(e, "Volume of tables between 1Mb to 10Gb is categorized as Low volume tables")}
                                                                                                                                                // onMouseMove={handleMouseMove}
                                                                                                                                                onMouseLeave={handleMouseLeave}
                                                                                                                                            >
                                                                                                                                                <img
                                                                                                                                                    src="images/tooltip-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="ms-1"
                                                                                                                                                />
                                                                                                                                            </span>
                                                                                                                                            {tooltip.show && (
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        position: 'fixed',
                                                                                                                                                        backgroundColor: '#333',
                                                                                                                                                        color: '#fff',
                                                                                                                                                        padding: '5px',
                                                                                                                                                        borderRadius: '3px',
                                                                                                                                                        fontSize: '12px',
                                                                                                                                                        visibility: tooltip.show ? 'visible' : 'hidden',
                                                                                                                                                        zIndex: 1000,
                                                                                                                                                        top: tooltip.y,
                                                                                                                                                        left: tooltip.x,
                                                                                                                                                        width: "70px%",
                                                                                                                                                        height: "50px"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {tooltip.text}
                                                                                                                                                </div>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.LowVolumeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">
                                                                                                                                                #Tables to be Configured
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.TableToBeCongfigured}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-to-be-Migrated-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Tables to be Migrated</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.TableToBeMigrated}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Notebooks-Involved-in-Changes-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">
                                                                                                                                                #Notebooks Involved in Changes
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.NotebookInvolvedInChanges}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Changed-Tables-in-a-Notebook-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">
                                                                                                                                                #Changed Tables in a Notebook
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.contents.changesInNoteBook}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-In-scope-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Tables Inscope</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data?.contents?.InScopeTables || data?.contents?.TableInScope}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-Out-scope-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">#Tables Outscope</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data?.contents?.TableOutScope}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            {data.generationState === "after" && (
                                                                                                                                <div className="row m-0 table-summaries-parent">
                                                                                                                                    <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                        <div className="table-summaries-list">
                                                                                                                                            <div>
                                                                                                                                                <img
                                                                                                                                                    src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="me-2"
                                                                                                                                                />
                                                                                                                                                <span className="table-summaries-title">
                                                                                                                                                    #Tables required Version History
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                            <span className="table-summaries-count">{data.contents.VersionHistory}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">
                                                                                                                                                In Scope Percentage
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{Number(data.contents.InScopePercentage).toFixed(2)} %</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="table-summaries-buttons">
                                                                                                                                {data.generationState === "before" ? (
                                                                                                                                    <>
                                                                                                                                        {data.show === true && isSubjectAreaRequired ? (
                                                                                                                                            <button
                                                                                                                                                className="outline-btn"
                                                                                                                                                onClick={() => checkLooksGood('tableSummaryBefore')}
                                                                                                                                            >
                                                                                                                                                Proceed with Inventory Document
                                                                                                                                            </button>
                                                                                                                                        ) : data.show === true && isSubjectAreaRequired === false ? (
                                                                                                                                            <button
                                                                                                                                                className="outline-btn"
                                                                                                                                                onClick={() => checkLooksGood('inventory')}
                                                                                                                                            >
                                                                                                                                                Proceed with Inventory Data Model
                                                                                                                                            </button>
                                                                                                                                        ) : (<></>)}
                                                                                                                                    </>

                                                                                                                                ) : data.show === true && data.generationState === "after" && (
                                                                                                                                    <button className="outline-btn"
                                                                                                                                        onClick={() => checkLooksGood(data.notebookName)}
                                                                                                                                    >
                                                                                                                                        Proceed with Source to Target
                                                                                                                                    </button>
                                                                                                                                )}
                                                                                                                                {data.isAction === true && (
                                                                                                                                    <button className="download-btn"
                                                                                                                                        onClick={() => downloadResponse(data)}
                                                                                                                                    >
                                                                                                                                        <img src="images/download-icon-black.svg" alt="download-icon-black" />
                                                                                                                                    </button>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                )
                                                                                                                    : data.generationType === "notebookSummary" && data.contents && (
                                                                                                                        <>
                                                                                                                            <div className="w-75">
                                                                                                                                {data.generationState === "after" && (
                                                                                                                                    <div className="row m-0 table-summaries-parent">
                                                                                                                                        <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                            <div className="table-summaries-list">
                                                                                                                                                <div>
                                                                                                                                                    <img
                                                                                                                                                        src="images/Low-Volume-Tables-icon.svg"
                                                                                                                                                        alt="table-summaries-icons"
                                                                                                                                                        className="me-2"
                                                                                                                                                    />
                                                                                                                                                    <span className="table-summaries-title">
                                                                                                                                                        #Notebook Uploaded Initally
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <span className="table-summaries-count">{data.contents?.NotebooksUploadedInitially}</span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                                <div className="row m-0 table-summaries-parent">
                                                                                                                                    <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                        <div className="table-summaries-list">
                                                                                                                                            <div>
                                                                                                                                                <img
                                                                                                                                                    src="images/Total-Tables-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="me-2"
                                                                                                                                                />
                                                                                                                                                <span className="table-summaries-title">#Notebooks</span>
                                                                                                                                            </div>
                                                                                                                                            <span className="table-summaries-count">{data.contents?.TotalNotebooks}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                        <div className="table-summaries-list">
                                                                                                                                            <div>
                                                                                                                                                <img
                                                                                                                                                    src="images/Notebooks-Involved-in-Changes-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="me-2"
                                                                                                                                                />
                                                                                                                                                <span className="table-summaries-title">#Notebooks Involved in Changes</span>
                                                                                                                                            </div>
                                                                                                                                            <span className="table-summaries-count">{data.contents?.NoteBookInvolvedInChanges}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                        <div className="table-summaries-list">
                                                                                                                                            <div>
                                                                                                                                                <img
                                                                                                                                                    src="images/Changed-Tables-in-a-Notebook-icon.svg"
                                                                                                                                                    alt="table-summaries-icons"
                                                                                                                                                    className="me-2"
                                                                                                                                                />
                                                                                                                                                <span className="table-summaries-title">#Changed Tables in a Notebook</span>
                                                                                                                                            </div>
                                                                                                                                            <span className="table-summaries-count">{data.contents?.ChangedTablesIntheNoteBook}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                {data.generationState === "after" && (
                                                                                                                                    <>
                                                                                                                                        <div className="row m-0 table-summaries-parent">
                                                                                                                                            <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                                <div className="table-summaries-list">
                                                                                                                                                    <div>
                                                                                                                                                        <img
                                                                                                                                                            src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                            alt="table-summaries-icons"
                                                                                                                                                            className="me-2"
                                                                                                                                                        />
                                                                                                                                                        <span className="table-summaries-title">
                                                                                                                                                            #Notebooks to be Tested
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <span className="table-summaries-count">{data.contents?.NoteBookToBeTested}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                                <div className="table-summaries-list">
                                                                                                                                                    <div>
                                                                                                                                                        <img
                                                                                                                                                            src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                            alt="table-summaries-icons"
                                                                                                                                                            className="me-2"
                                                                                                                                                        />
                                                                                                                                                        <span className="table-summaries-title">
                                                                                                                                                            #Notebooks Not to be Tested
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <span className="table-summaries-count">{data.contents?.NoteBookNotToBeTested}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="row m-0 table-summaries-parent">
                                                                                                                                            <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                                <div className="table-summaries-list">
                                                                                                                                                    <div>
                                                                                                                                                        <img
                                                                                                                                                            src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                            alt="table-summaries-icons"
                                                                                                                                                            className="me-2"
                                                                                                                                                        />
                                                                                                                                                        <span className="table-summaries-title">
                                                                                                                                                            Matched Tables
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <span className="table-summaries-count">{data.contents?.MatchedTables}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-md-4 custom-table-list-spacing">
                                                                                                                                                <div className="table-summaries-list">
                                                                                                                                                    <div>
                                                                                                                                                        <img
                                                                                                                                                            src="images/Tables-to-be-Configured-icon.svg"
                                                                                                                                                            alt="table-summaries-icons"
                                                                                                                                                            className="me-2"
                                                                                                                                                        />
                                                                                                                                                        <span className="table-summaries-title">
                                                                                                                                                            Unmatched Tables
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <span className="table-summaries-count">{data.contents?.UnmatchedTables}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                    </>


                                                                                                                                )}
                                                                                                                                {data.show === true &&
                                                                                                                                    <div className="table-summaries-buttons">
                                                                                                                                        {data.generationType === "notebookSummary" && data.generationState === "before" ? (
                                                                                                                                            <button
                                                                                                                                                className="outline-btn"
                                                                                                                                                onClick={() => checkLooksGood(data?.notebookName)}
                                                                                                                                            >
                                                                                                                                                Proceed with Analysis Document
                                                                                                                                            </button>
                                                                                                                                        ) : data.generationState === "after" && (
                                                                                                                                            <button
                                                                                                                                                className="outline-btn"
                                                                                                                                                onClick={() => checkLooksGood(data?.notebookName)}
                                                                                                                                            >
                                                                                                                                                Proceed with Consolidated Define Document
                                                                                                                                            </button>
                                                                                                                                        )}
                                                                                                                                        {data.isAction === true &&
                                                                                                                                            < button className="download-btn"
                                                                                                                                                onClick={() => downloadResponse(data)}
                                                                                                                                            >
                                                                                                                                                <img src="images/download-icon-black.svg" alt="download-icon-black" />
                                                                                                                                            </button>}
                                                                                                                                    </div>
                                                                                                                                }


                                                                                                                            </div>


                                                                                                                        </>
                                                                                                                    )
                                                                                                                }

                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    else if (data.type === "text") {
                                                                                                        return (
                                                                                                            <div className="bot-response position-relative mb-4">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    {data.text}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data.type === 'loadShow' && data.show === true) {

                                                                                                        return (
                                                                                                            <div className="bot-response position-relative mb-4">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Your {data.docName} document would be generated
                                                                                                                    based on the below document
                                                                                                                    {data.referenceDocument.length > 1 ? (
                                                                                                                        // Render the div with the loop inside if data.referenceDocument has more than 1 item
                                                                                                                        <div className="my-3">
                                                                                                                            {/* Use map to render a div for each item in data.referenceDocument */}
                                                                                                                            {data.referenceDocument.map((document, index) => (
                                                                                                                                <div key={index}>
                                                                                                                                    <a className="text-decoration-none text-black font-12">
                                                                                                                                        <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                        {document} {/* Render the value of each document object */}
                                                                                                                                    </a>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        // If data.referenceDocument has only 1 item, render a single div
                                                                                                                        data.referenceDocument.length === 1 && (
                                                                                                                            <div className="my-3">
                                                                                                                                <a className="text-decoration-none text-black font-12">
                                                                                                                                    <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                    {data.referenceDocument[0]} {/* Render the value of the single document */}
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    )}

                                                                                                                    {data.docName} document would be ready soon...
                                                                                                                    {/* Your {data.docName} document would be generated based on the below document", 
        need to handle the reference document loop here */}
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        );
                                                                                                    }
                                                                                                    else if (data.type === "codeShow") {
                                                                                                        return (
                                                                                                            <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    <b>{data?.text}</b>
                                                                                                                    <div className="mt-3" >
                                                                                                                        <a
                                                                                                                            onClick={() => { getCodePageDetails(data); }}
                                                                                                                            className="hyperlink"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                        >
                                                                                                                            {data?.hyperlinkText}
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data.show === false ? (

                                                                                                                        data.isAction === true && (
                                                                                                                            <>
                                                                                                                                <div
                                                                                                                                    className="btn-group"
                                                                                                                                    role="group"
                                                                                                                                    aria-label="Basic outlined example"
                                                                                                                                >
                                                                                                                                    {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                        type="button"
                                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                                        onClick={() => { getCodePageDetails(data); }}
                                                                                                                                    >
                                                                                                                                        <img src="images/chat-action-edit.svg" />
                                                                                                                                    </button>
                                                                                                                                    )}
                                                                                                                                    <button
                                                                                                                                        type="button"
                                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                        onClick={() => {
                                                                                                                                            downloadResponse(data);
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <img src="images/chat-action-download.svg" />
                                                                                                                                    </button>
                                                                                                                                    {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                        type="button"
                                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                    >
                                                                                                                                        <img
                                                                                                                                            src="images/chat-action-regenerate.svg"
                                                                                                                                            height="20px"
                                                                                                                                            onClick={() => { regenerate(data); }}
                                                                                                                                        />
                                                                                                                                    </button>
                                                                                                                                    )}
                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        )

                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {" "}
                                                                                                                            <button
                                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                                onClick={() => checkLooksGood(data?.notebookName)}
                                                                                                                            >
                                                                                                                                Looks good
                                                                                                                            </button>
                                                                                                                            {data.isAction === true && (
                                                                                                                                <>
                                                                                                                                    <div
                                                                                                                                        className="btn-group"
                                                                                                                                        role="group"
                                                                                                                                        aria-label="Basic outlined example"
                                                                                                                                    >
                                                                                                                                        {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                            type="button"
                                                                                                                                            className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                                            onClick={() => { getCodePageDetails(data); }}
                                                                                                                                        >
                                                                                                                                            <img src="images/chat-action-edit.svg" />
                                                                                                                                        </button>
                                                                                                                                        )}
                                                                                                                                        <button
                                                                                                                                            type="button"
                                                                                                                                            className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                            onClick={() => {
                                                                                                                                                downloadResponse(data);
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <img src="images/chat-action-download.svg" />
                                                                                                                                        </button>
                                                                                                                                        {(data?.notebookName !== "tableSummaryBefore" && data?.notebookName !== "tableSummaryAfter" && data?.notebookName !== "notebookSummaryBefore" && data?.notebookName !== "notebookSummaryAfter" && data?.notebookName !== 'dataQualityResults') && (<button
                                                                                                                                            type="button"
                                                                                                                                            className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                        >
                                                                                                                                            <img
                                                                                                                                                src="images/chat-action-regenerate.svg"
                                                                                                                                                height="20px"
                                                                                                                                                onClick={() => { regenerate(data); }}
                                                                                                                                            />
                                                                                                                                        </button>
                                                                                                                                        )}
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data.type === "consolidateDownload") {
                                                                                                        return (
                                                                                                            <div className="bot-response position-relative">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    <b>{data?.text}</b>
                                                                                                                    <div className="mt-3" >
                                                                                                                        <a
                                                                                                                            className="hyperlink"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => {
                                                                                                                                if (data?.phase === 'Define') {
                                                                                                                                    downloadConsolidatedDefine(data?.notebookName);
                                                                                                                                } else if (data?.phase === 'Design') {
                                                                                                                                    downloadDesignDocument(data?.notebookName);
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {data?.hyperlinkText}
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data.show === false ? (
                                                                                                                        <></>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {" "}
                                                                                                                            <button
                                                                                                                                className="chat-button-secondary font-12 font-bold"
                                                                                                                                onClick={() => checkLooksGood(data?.notebookName)}
                                                                                                                            >
                                                                                                                                Looks good
                                                                                                                            </button>
                                                                                                                            <div
                                                                                                                                className="btn-group"
                                                                                                                                role="group"
                                                                                                                                aria-label="Basic outlined example"
                                                                                                                            >
                                                                                                                                <button
                                                                                                                                    type="button"
                                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                    onClick={() => {
                                                                                                                                        if (data?.phase === 'Define') {
                                                                                                                                            downloadConsolidatedDefine(data?.notebookName);
                                                                                                                                        } else if (data?.phase === 'Design') {
                                                                                                                                            downloadDesignDocument(data?.notebookName);
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <img src="images/chat-action-download.svg" />
                                                                                                                                </button>

                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }


                                                                                                    else if (data.type === "unitTestResultPopup") {

                                                                                                        return (

                                                                                                            <div className="bot-response position-relative">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly {" "}
                                                                                                                    <a
                                                                                                                        onClick={() => handleFileUploadButtonClick('unitTest')}
                                                                                                                        className="chatbot-anchor"
                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                    >
                                                                                                                        upload sheet
                                                                                                                    </a>{" "}
                                                                                                                    to know about <b>unit test case results</b>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data.show === true ? (
                                                                                                                        <button
                                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                                            onClick={() => handleFileUploadButtonClick('unitTest')}
                                                                                                                        >
                                                                                                                            Upload
                                                                                                                        </button>
                                                                                                                    ) : (
                                                                                                                        <></>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data.type === "dataQualityResultPopup") {

                                                                                                        return (

                                                                                                            <div className="bot-response position-relative">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                </span>
                                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly {" "}
                                                                                                                    <a
                                                                                                                        onClick={() => handleFileUploadButtonClick('dataQuality')}
                                                                                                                        className="chatbot-anchor"
                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                    >
                                                                                                                        upload sheet
                                                                                                                    </a>{" "}
                                                                                                                    to know about <b>data quality results</b>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                    {data.show === true ? (
                                                                                                                        <button
                                                                                                                            className="chat-button-primary font-12 font-bold "
                                                                                                                            onClick={() => handleFileUploadButtonClick('dataQuality')}
                                                                                                                        >
                                                                                                                            Upload
                                                                                                                        </button>
                                                                                                                    ) : (
                                                                                                                        <></>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }

                                                                                                    // else if (data.type === "pseudocodeShow") {
                                                                                                    //     return (
                                                                                                    //         <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                    //             <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    //                 <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                    //             </span>
                                                                                                    //             <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    //                 <b>{data?.text}</b>
                                                                                                    //                 <div className="mt-3" >
                                                                                                    //                     <a
                                                                                                    //                         onClick={() => {
                                                                                                    //                             
                                                                                                    //                             console.log(data, "0----------------");
                                                                                                    //                             if (deployStatus === true) {
                                                                                                    //                                 getCodePageDetails(data);
                                                                                                    //                             } else {
                                                                                                    //                                 setIsEditNotebook(data);
                                                                                                    //                                 document.getElementById("showconfirm").click();
                                                                                                    //                             }
                                                                                                    //                         }}
                                                                                                    //                         className="hyperlink"
                                                                                                    //                         style={{ cursor: "pointer" }}
                                                                                                    //                     >
                                                                                                    //                         {data?.hyperlinkText}
                                                                                                    //                     </a>
                                                                                                    //                 </div>
                                                                                                    //             </div>
                                                                                                    //             <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    //                 {data.show === false ? (
                                                                                                    //                     <>
                                                                                                    //                         {(data.show === false && data.verified === false) ? (<>
                                                                                                    //                             {" "}

                                                                                                    //                             <div
                                                                                                    //                                 className="btn-group"
                                                                                                    //                                 role="group"
                                                                                                    //                                 aria-label="Basic outlined example"
                                                                                                    //                             >
                                                                                                    //                                 <button
                                                                                                    //                                     type="button"
                                                                                                    //                                     className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                    //                                     onClick={() => {
                                                                                                    //                                         
                                                                                                    //                                         if (deployStatus === true) {
                                                                                                    //                                             getCodePageDetails(data);
                                                                                                    //                                         } else {
                                                                                                    //                                             setIsEditNotebook(data);
                                                                                                    //                                             document.getElementById("showconfirm").click();
                                                                                                    //                                         }
                                                                                                    //                                     }}
                                                                                                    //                                 >
                                                                                                    //                                     <img src="images/chat-action-edit.svg" />
                                                                                                    //                                 </button>
                                                                                                    //                                 <button
                                                                                                    //                                     type="button"
                                                                                                    //                                     className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                    //                                     onClick={() => {
                                                                                                    //                                         downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                    //                                     }}
                                                                                                    //                                 >
                                                                                                    //                                     <img src="images/chat-action-download.svg" />
                                                                                                    //                                 </button>
                                                                                                    //                                 {(data?.codeType !== 'metadataTable') ? (
                                                                                                    //                                     <button
                                                                                                    //                                         type="button"
                                                                                                    //                                         className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                    //                                         onClick={() => {
                                                                                                    //                                             regenerateDesign(data); console.log('1234567890')
                                                                                                    //                                         }}
                                                                                                    //                                     >
                                                                                                    //                                         <img
                                                                                                    //                                             src="images/chat-action-regenerate.svg"
                                                                                                    //                                             height="20px"

                                                                                                    //                                         />
                                                                                                    //                                     </button>
                                                                                                    //                                 ) : null}
                                                                                                    //                             </div>
                                                                                                    //                         </>
                                                                                                    //                         ) : (<></>)}
                                                                                                    //                     </>
                                                                                                    //                 ) : (
                                                                                                    //                     <>
                                                                                                    //                         {" "}
                                                                                                    //                         <button
                                                                                                    //                             className="chat-button-secondary font-12 font-bold"
                                                                                                    //                             onClick={(e) => {
                                                                                                    //                                 checkPseudocodeLooksGood(data?.pseudocodeType)
                                                                                                    //                                 e.currentTarget.disabled = true;
                                                                                                    //                                 e.currentTarget.style.opacity = '0.3';
                                                                                                    //                                 e.currentTarget.style.cursor = 'not-allowed';
                                                                                                    //                             }
                                                                                                    //                             }
                                                                                                    //                         >
                                                                                                    //                             Looks good
                                                                                                    //                         </button>

                                                                                                    //                         <div
                                                                                                    //                             className="btn-group"
                                                                                                    //                             role="group"
                                                                                                    //                             aria-label="Basic outlined example"
                                                                                                    //                         >
                                                                                                    //                             <button
                                                                                                    //                                 type="button"
                                                                                                    //                                 className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                    //                                 onClick={() => {
                                                                                                    //                                     
                                                                                                    //                                     if (deployStatus === true) {
                                                                                                    //                                         getCodePageDetails(data);
                                                                                                    //                                     } else {
                                                                                                    //                                         setIsEditNotebook(data);
                                                                                                    //                                         document.getElementById("showconfirm").click();
                                                                                                    //                                     }
                                                                                                    //                                 }}
                                                                                                    //                             >
                                                                                                    //                                 <img src="images/chat-action-edit.svg" />
                                                                                                    //                             </button>
                                                                                                    //                             <button
                                                                                                    //                                 type="button"
                                                                                                    //                                 className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                    //                                 onClick={() => {
                                                                                                    //                                     downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                    //                                 }}
                                                                                                    //                             >
                                                                                                    //                                 <img src="images/chat-action-download.svg" />
                                                                                                    //                             </button>
                                                                                                    //                             {(data?.codeType !== 'metadataTable') ? (
                                                                                                    //                                 <button
                                                                                                    //                                     type="button"
                                                                                                    //                                     className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                    //                                     onClick={() => {
                                                                                                    //                                         regenerateDesign(data); console.log('1234567890')
                                                                                                    //                                     }}
                                                                                                    //                                 >
                                                                                                    //                                     <img
                                                                                                    //                                         src="images/chat-action-regenerate.svg"
                                                                                                    //                                         height="20px"

                                                                                                    //                                     />
                                                                                                    //                                 </button>
                                                                                                    //                             ) : null}
                                                                                                    //                         </div>
                                                                                                    //                     </>
                                                                                                    //                 )}
                                                                                                    //             </div>
                                                                                                    //         </div>
                                                                                                    //     );
                                                                                                    // }

                                                                                                    // else if (data.type === "getConnectionPopup") {
                                                                                                    //     return (
                                                                                                    //         <div className="bot-response position-relative">
                                                                                                    //             <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    //                 <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                    //             </span>
                                                                                                    //             <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    //                 As a first step{" "}
                                                                                                    //                 <a
                                                                                                    //                     data-bs-toggle="modal"
                                                                                                    //                     data-bs-target="#SourceConnectionInfo"
                                                                                                    //                     className="chatbot-anchor"
                                                                                                    //                     style={{ cursor: "pointer" }}
                                                                                                    //                 >
                                                                                                    //                     fill out this form
                                                                                                    //                 </a>{" "}
                                                                                                    //                 to know about source system and connection details.
                                                                                                    //             </div>
                                                                                                    //             {data?.show === true ? (
                                                                                                    //                 <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    //                     <button
                                                                                                    //                         className="chat-button-primary font-12 font-bold "
                                                                                                    //                         data-bs-toggle="modal"
                                                                                                    //                         data-bs-target="#SourceConnectionInfo"
                                                                                                    //                     >
                                                                                                    //                         Fill Form
                                                                                                    //                     </button>
                                                                                                    //                 </div>
                                                                                                    //             ) : (
                                                                                                    //                 <></>
                                                                                                    //             )}
                                                                                                    //         </div>
                                                                                                    //     );
                                                                                                    // }

                                                                                                } else if (data.flow === "user") {
                                                                                                    if (data.type === "text") {
                                                                                                        return (
                                                                                                            <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                    <img
                                                                                                                        src={`${localStorage?.getItem("dXNlckltYWdl1") !== null
                                                                                                                            ? decode(
                                                                                                                                localStorage
                                                                                                                                    ?.getItem("dXNlckltYWdl1")
                                                                                                                                    ?.substring(
                                                                                                                                        15,
                                                                                                                                        localStorage.getItem("dXNlckltYWdl1")?.length - 10
                                                                                                                                    )
                                                                                                                            )
                                                                                                                            : "images/userDefault.svg"
                                                                                                                            }`}
                                                                                                                        alt="user-profile"
                                                                                                                        className="chat-user-img"
                                                                                                                    />
                                                                                                                </span>
                                                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                                    {data.text}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return <></>
                                                                                                    }
                                                                                                }
                                                                                            })}
                                                                                        </>
                                                                                    ) : <></>
                                                                                    }

                                                                                    {showLooksGood ? (
                                                                                        <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img
                                                                                                    src={`${localStorage?.getItem(
                                                                                                        "dXNlckltYWdl1"
                                                                                                    ) !== null
                                                                                                        ? decode(localStorage?.getItem("dXNlckltYWdl1")?.substring(15,
                                                                                                            localStorage.getItem(
                                                                                                                "dXNlckltYWdl1"
                                                                                                            )?.length - 10
                                                                                                        )
                                                                                                        )
                                                                                                        : "images/userDefault.svg"
                                                                                                        }`}
                                                                                                    alt="user-profile"
                                                                                                    className="chat-user-img"
                                                                                                />
                                                                                            </span>
                                                                                            <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                Looks good
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}

                                                                                    {showLoader === true ? (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img
                                                                                                    src="images/GenELT-chat-logo.svg"
                                                                                                    alt="user-profile"
                                                                                                />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                <div className="dot-typing"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                    <div id='Imdown'></div>
                                                                                    {/* center flowwwwwww */}
                                                                                    {/* <div className="chat-divider d-flex justify-content-center">
                                                                                <span className="font-12 font-medium">Development</span>
                                                                            </div> */}

                                                                                    {/* AI flowwwwwww */}
                                                                                    {/* <div className="bot-response position-relative">
                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                    <img src="images/GenELT-chat-logo.svg" className="figma-logo" alt="user-profile" />
                                                                                </span>
                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                    Hi{" "}
                                                                                    <span className="text-black">
                                                                                        Mohan
                                                                                    </span>
                                                                                    , How can I help you with?
                                                                                </div>
                                                                            </div> */}

                                                                                    {/* User flowwwwwww */}
                                                                                    {/* <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                    <img
                                                                                        src="images/userDefault.svg"
                                                                                        alt="user-profile"
                                                                                        className="chat-user-img"
                                                                                    />
                                                                                </span>
                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                    Looks Good
                                                                                </div>
                                                                            </div> */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    {codeDataValues.notebookName === "inventory" ? (
                                        <InventoryDocument
                                            data={codeDataValues}
                                            setShowCode={() => { setShowCode(false); }}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                    setIsEdit(true)
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "catalogSchema" ? (
                                        <CatalogSchema
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            setIsAllCatalogFilled={setIsAllCatalogFilled}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "dataDictionary" ? (
                                        <DataDictionary
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                            subjectArea={isSubjectAreaRequired}
                                        />
                                    ) : codeDataValues.notebookName === "metadataTable" ? (
                                        <MetaTableData
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "ddlPseudo" ? (
                                        <DDlScript
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                bindLooksGood()
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "loggerPseudo" ? (
                                        <LoggerPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "utilityPseudo" ? (
                                        <UtilityPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "configurationPseudo" ? (
                                        <ConfigutrationPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "traditionalPseudo" ? (
                                        <TradionalMethod
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "copyClonePseudo" ? (
                                        <CopyClone
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "changesPseudo" ? (
                                        <ChangesPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "checklistPseudo" ? (
                                        <CodeReviewPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "checklistCode" ? (
                                        <CodeReviewDev
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "unitTestcasePseudo" ? (
                                        <UnitTescasePseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "dataQualityPseudo" ? (
                                        <DataQuatityPseudo
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "notebookChangesRecommend" ? (
                                        <NoteBookChanges
                                            data={codeDataValues}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                            isEdit={(status) => {
                                                bindLooksGood(codeDataValues.notebookName)
                                                if (status === true) {
                                                    gridDataChanged();
                                                }
                                            }}
                                        />
                                    ) : codeDataValues.notebookName === "ddlCode" ||
                                        codeDataValues.notebookName === "loggerCode" ||
                                        codeDataValues.notebookName === "utilityCode" ||
                                        codeDataValues.notebookName === "configurationCode" ||
                                        codeDataValues.notebookName === "traditionalCode" ||
                                        codeDataValues.notebookName === "copyCloneCode" ||
                                        codeDataValues.notebookName === "changesCode" ||
                                        codeDataValues.notebookName === "unitTestcaseCode" ||
                                        codeDataValues.notebookName === "dataQualityCode"
                                        ? (
                                            <CodeViewPage
                                                data={codeDataValues}
                                                setShowCode={() => setShowCode(false)}
                                                onChange={() => updateValues()}
                                                isEdit={(status) => {
                                                    bindLooksGood(codeDataValues.notebookName)
                                                    if (status === true) {
                                                        gridDataChanged();
                                                    }
                                                }}
                                            />
                                        ) : codeDataValues.notebookName === "sourceToTargetFlow" ? (
                                            <>
                                                {showViewInstructions ? (
                                                    <ViewInstructions
                                                        notebooktype="sourceToTarget"
                                                        onClose={() => setShowViewInstructions(false)}
                                                    />
                                                ) : (
                                                    <SourceToTarget
                                                        data={codeDataValues}
                                                        setShowCode={() => setShowCode(false)}
                                                        onChange={() => updateValues()}
                                                        setIsSourceAllApproved={setIsSourceAllApproved}
                                                        isEdit={(status) => {
                                                            bindLooksGood(codeDataValues.notebookName)
                                                            if (status === true) {
                                                                gridDataChanged();
                                                            }
                                                        }}
                                                        uploadedData={uploadedData}
                                                        closePopup={closePopup}
                                                        validationColumns={tableMappingValidations}
                                                        textData={textData}
                                                        saveFunction={saveFunction}
                                                        templateURL={templateURL}
                                                        closeTableMappingPopup={handleClose}

                                                    />
                                                )}
                                            </>
                                        ) :
                                            codeDataValues.notebookName === "approachDiagram"
                                                ? (

                                                    <ApproachDiagramView
                                                        data={codeDataValues}
                                                        setShowCode={() => setShowCode(false)}
                                                        onChange={() => updateValues()}
                                                        isEdit={(status) => {
                                                            bindLooksGood(codeDataValues.notebookName)
                                                            if (status === true) {
                                                                gridDataChanged();
                                                            }
                                                        }}
                                                    />
                                                ) : codeDataValues.notebookName === "tableMapping" ? (
                                                    <>
                                                        {showViewInstructions ? (
                                                            <ViewInstructions
                                                                notebooktype='tableMapping'
                                                                onClose={() => setShowViewInstructions(false)}
                                                            />
                                                        ) : (
                                                            <TableMapping
                                                                data={codeDataValues}
                                                                setShowCode={() => setShowCode(false)}
                                                                // isTableEdited={()=>isTableEdited(false)}
                                                                onChange={updateValues}
                                                                isEdit={handleStatusChange}
                                                                uploadedData={uploadedData}
                                                                closePopup={closePopup}
                                                                validationColumns={tableMappingValidations}
                                                                textData={{
                                                                    FileName: "",
                                                                    popupText: "Upload Table Mapping Sheet"
                                                                }}
                                                                saveFunction={saveFunction}
                                                                templateURL={templateURLs.tableMappingUploadURL}
                                                                closeTableMappingPopup={handleClose}
                                                            />
                                                        )}
                                                    </>

                                                ) : codeDataValues?.notebookName === "analysisDocument" ? (
                                                    <>
                                                        {showViewInstructions ? (
                                                            <ViewInstructions
                                                                notebooktype='analysisDocument'
                                                                onClose={() => setShowViewInstructions(false)}
                                                            />
                                                        ) : (
                                                            <AnalyseDocument
                                                                data={codeDataValues}
                                                                setShowCode={() => setShowCode(false)}
                                                                onChange={() => updateValues()}
                                                                isEdit={(status) => {
                                                                    bindLooksGood(codeDataValues.notebookName)
                                                                    if (status === true) {
                                                                        gridDataChanged()
                                                                    }
                                                                }}
                                                                fileName={chatHistory.notebookName || ""}
                                                                documentUploaded={() => chatHistory[0]['notebookUploaded'] || "false"}
                                                                uploaded={setNotebookUploaded}
                                                                handleExistingNotebook={() => handleExistingNotebook()}
                                                                //onChange={(data) => updateChatHistory(data, "definePopup")}
                                                                close={() => setNotebookPopup(false)}
                                                                closeTableMappingPopup={handleClose}
                                                            />
                                                        )}
                                                    </>
                                                ) : codeDataValues?.notebookName === "InventoryDataModel" ? (
                                                    <DynamicUmlView
                                                        data={codeDataValues}
                                                        setShowCode={() => setShowCode(false)}
                                                        onChange={() => updateValues()}
                                                        isEdit={(status) => {
                                                            bindLooksGood(codeDataValues.notebookName)
                                                            if (status === true) {
                                                                gridDataChanged()
                                                            }
                                                        }}
                                                    />

                                                ) : codeDataValues.notebookName === "dataQualityResults" ? (
                                                    <DataQualityResult
                                                        data={codeDataValues}
                                                        setShowCode={() => setShowCode(false)}
                                                        onChange={() => updateValues()}
                                                        isEdit={(status) => {
                                                            bindLooksGood(codeDataValues.notebookName)
                                                            if (status === true) {
                                                                gridDataChanged()
                                                            }
                                                        }}
                                                    />
                                                ) :
                                                    (
                                                        null
                                                    )}
                                </>
                            )
                        }
                    </>
                }
          
                {inventoryPopup && (
                    <>
                        <InventoryUploadPopup
                            fileName={chatHistory.notebookName || ""}
                            documentUploaded={() => chatHistory[0]['inventoryUploaded'] || "false"}
                            uploaded={setInventoryUploaded}
                            handleConnectionDetails={handleConnectionDetails}
                            //onChange={(data) => updateChatHistory(data, "definePopup")}
                            close={() => setInventoryPopup(false)}
                            setIsSubjectAreaRequired={setIsSubjectAreaRequired}

                        />
                    </>
                )}
                {notebookPopup && (
                    <>
                        <FileUploaderComponent
                            fileName={chatHistory.notebookName || ""}
                            documentUploaded={chatHistory[0]['notebookUploaded'] || "false"}
                            uploaded={setNotebookUploaded}
                            handleExistingNotebook={() => handleExistingNotebook()}
                            fromAnalysis={"false"}
                            //onChange={(data) => updateChatHistory(data, "definePopup")}
                            close={() => setNotebookPopup(false)}
                            notebookUploaded={() => { }} />
                    </>
                )}
                {dynamicFileUploadPopup && (
                    <>
                        <DynamicFileUploadPopup
                            validationColumns={validationColumns}
                            text={textData}
                            saveFunction={saveFunction}
                            closePopup={closePopup}
                            templateURL={templateURL}
                            chatHistory={chatHistory}
                            fileUploaded={fileUploaded}
                        />
                    </>
                )}
                {isTableMappingPopupOpen && (
                    <TableMappingPopup
                        uploadedData={uploadedData}
                        closePopup={() => closeTableMappingPopup()}
                        validationColumns={tableMappingValidations}
                        text={textData}
                        templateURL={templateURL}
                    />
                )}

            </div >


        </>
    );
};

export default ChatMainUCMigration;