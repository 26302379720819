import React, { useEffect, useState } from 'react';

//PS_CD_1.1
import { useNavigate, Link, useLocation } from 'react-router-dom';
//PS_CD_1.2
import { postOrganization, getOrganization, isActiveOrganization, editOrganization, adUsers, projectsDropDown, insertError } from '../Service/Api.js';
import { HeaderCom } from './Header.jsx';
import { decode } from 'base-64';

import Select from 'react-select'



const NewOrganisation = () => {

    // let { state } = useLocation()

    const [userDetails, setuserDetails] = useState({ userName: "", userMailId: "", adRole: "", adUserId: "", domain: "", role: "" })
    const [userName, setuserName] = useState("")
    const [userMailId, setuserMailId] = useState("")
    const [selectedVerticalIndustry, setSelectedVerticalIndustry] = useState('Select');
    const [isImageUploaded, setisImageUploaded] = useState(false);
    const [newOrg, setNewOrg] = useState({ orgId: "", orgName: '', clientBusiness: "", orgLogo: null, verticalIndustryId: "Select", isEdited: false });
    //PS_CD_1.7
    const [selectedFileName, setselectedFileName] = useState("")
    const [isUserEdit, setisUserEdit] = useState(false)
    const [editingIndex, setEditingIndex] = useState(null)
    const [setpickUser, setsetpickUser] = useState({ userName: "", userMailId: "", adRole: "", adUserId: "", domain: "", role: "" })

    const [selectedClientBusiness, setselectedClientBusiness] = useState("Select");
    const [sortOrder, setSortOrder] = useState({
        column: 'sample',
        descending: true,
    }); //console.log(orgId);

    const [loader, setLoader] = useState(false);
    const [organizationList, setorganizationList] = useState([]);
    //PS_CD_1.52
    const [menuVisible, setMenuVisible] = useState([]);
    const [isActive, setIsActive] = useState([]);
    const [users, setusers] = useState([]);
    const [usersArray, setusersArray] = useState([]);
    const [userRole, setuserRole] = useState(["Project Lead", "Developer"]);

    const [adusers, setadusers] = useState([]);
    const [dropDownValues, setdropDownValues] = useState([]);
    const [userNames, setUserNames] = useState([])
    const [mailIds, setMailIds] = useState([])
    //PS_CD_1.74
    const [searchTerm, setSearchTerm] = useState('');
    const [verticalIndustryName, setVerticalIndustryName] = useState('')
    const [verticalIndustryId, setVerticalIndustryId] = useState('')
    const [verticalIndustryDropDown, setverticalIndustryDropDown] = useState([])
    const [orgName, setOrganizationName] = useState('');
    const [clientBusiness, setClientBusiness] = useState("");
    const [organizationId, setOrganizationId] = useState(null);
    // State variables for selected record and input values
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [username, setUsername] = useState('');
    const [mailId, setMailId] = useState('');


    const navigate = useNavigate();

    let { state } = useLocation()
    // userDetails
    console.log("original stateeeee", state);

    console.log("modified state", state);


    useEffect(() => {
        console.log("state in usefedt", state);
        projectDropDownValue();
        fetchAdUsersValues()
        if (state.isEdit == false) {
            console.log("in new org condition", state);
            handleNewOrgClick()
            // handleAddUser()
        }
        else {
            handleEditInfo(state.orgId)
        }
    }, []);


    const projectDropDownValue = async () => {
      try {
        document.getElementById('pageLoader').style.display = 'block';

        let response = await projectsDropDown(state?.userId, state.jwt)
        // document.getElementById('pageLoader').style.display = 'none ';

        console.log("projects values from api", response.data);
        // setdropDownValues(response.data)
        if (response.status == 403) {
            sessionExpired()
            return;
        }
        if (response.status !== 200) {
            // document.getElementById('toastMessage').style.display = "block"

            return;
        }
        if (response.data.length > 0) {
            setverticalIndustryDropDown(response.data[0]);
            console.log("setvertical industry drop down", verticalIndustryDropDown);
        }
        
      } catch (error) {
        console.log(error,"Error in projectDropDownValue ")
      } 

    }
    const fetchAdUsersValues = async () => {
        document.getElementById('pageLoader').style.display = "block"
        let response = await adUsers(state.userId, state.jwt, state.accessToken)

        // let response = await adUsers(state.userId, state.jwt, decode(localStorage.getItem('fcyhs').substring(15, localStorage.getItem('fcyhs').length - 10)))
        document.getElementById('pageLoader').style.display = "none"

        if (response.status == 403) {
            sessionExpired()
            return;
        }
        if (response.status !== 200) {
            console.log("in ! 200");
            alert('something went wrong');
            sessionExpired()

            return;
        }
        console.log("ad users values from api", response);
        // adusers = adusers.data[0]
        setadusers(response.data);
        setUserNames(response.data.map(a => { return { label: a.displayName, value: a.displayName } }))
        setMailIds(response.data.map(a => { return { label: a.mail, value: a.mail } }))

    }

    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        /* The above code is using JavaScript to clear all data stored in the localStorage. This means
        that any key-value pairs previously stored in the localStorage will be removed. */
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            navigate('/');
        }, 3000);
    };

    const handleNewOrgClick = async () => {
        document.getElementById('pageLoader').style.display = 'none';

         
        setusers([])
        setusersArray([])
        if (users.length == 0) {
            let defaultuser = { userName: state.userName, userMailId: state.userMailId, adRole: state.adRole, adUserId: state.adUserId, userId: state.userId, domain: state.domain, role: "Project Lead", index: 1 }
            console.log("in new org func  defaultuser", defaultuser);
            setusers(() => [...users, defaultuser])
            setusersArray(() => [...usersArray, defaultuser])
        }
        // setusers([...users,defaultuser])
        // const response = await editOrganization(state.orgId, state.userId, state.jwt);
        // isEdited
        console.log("in new orgggg fiunction");
        setNewOrg({ orgName: '', clientBusiness: "Select", orgLogo: null, verticalIndustryId: "", isEdited: false })


    }
    console.log("aftdrrr defaut user", users);
    const handleEditInfo = async (orgId) => {

        // document.getElementById('pageLoader').style.display = 'block';

        const response = await editOrganization(orgId, state.userId, state.jwt);
        document.getElementById('pageLoader').style.display = 'none';

        console.log('API Response: EDITORG', response);

        if (response.statusCode == 403) {
            sessionExpired()
            return;
        }
         
        if (response.statusCode == 200) {
            const orgNameFromAPI = response.data.orgData[0].orgName;
            const clientBusinessFromAPI = response.data.orgData[0].clientBusiness
            const verticalIndustryFromAPI = response.data.orgData[0].verticalIndustryName
            const userFromAPI = response.data.userData

            let usersVal = userFromAPI.map((obj, index) => ({
                ...obj,
                index: index + 1,
                userMailId: obj.emailId
            }));
            setNewOrg({
                ...newOrg,
                orgName: orgNameFromAPI,
                orgId,
                orgLogo: response.data.orgData[0].orgLogo,
                clientBusiness: clientBusinessFromAPI,
                isEdited: true,
                verticalIndustryId: response.data.orgData[0].verticalIndustryId
            });

            setOrganizationId(orgId);
            setOrganizationName(orgNameFromAPI);
            setClientBusiness(clientBusinessFromAPI);
            setVerticalIndustryName(verticalIndustryFromAPI)
            setusersArray(usersVal)
            setusers(usersVal)
            setselectedClientBusiness(clientBusinessFromAPI)
            setSelectedVerticalIndustry(verticalIndustryFromAPI)
            // document.getElementById('pageLoader').style.display = 'none';

        } else {
            console.log("Response data or orgName not found in API response:", response);
            // Handle the case where response data is not as expected
        }

    }



    function handleDeleteUser(value, index) {
        let remainUser = usersArray.splice(index, 1)
        setusers(remainUser);

    }
    function handleCancelUser() {
        setusers(usersArray);

    }
    function handleUpdateUser(value, index) {
        users[index] = value
        setusers(users);
        setusersArray(users)

    }
    /*PS_CD_1.84 - 85
      
  The handleCancelOrganization function resets the newOrg state, clearing organization name, logo,
   and edit status,effectively canceling ongoing organization changes.
      */
    const handleCancelOrganization = () => {
        //PS_CD_1.85
        //console.log('Modal is closing');
        setNewOrg({ orgName: '', clientBusiness: '', verticalIndustryId: "", orgLogo: null, isEdited: false, orgId: null });
        setOrganizationName(''); // Reset organizationName state as well
        setClientBusiness('')
        navigate('/manageOrganization', { state: { ...state, isEdit: true,orgId:newOrg.orgId, orgName: newOrg.orgName, orgLogo: newOrg.orgLogo, userId: state.userId, jwt: state.jwt } });

        //console.log('New org', newOrg);
    };

    // const handleOrganizationNameChange = (event) => {
    //     setNewOrg({ ...newOrg, orgName: event.target.value });
    // };

    const handleClientBusiness = (event) => {
         
        setClientBusiness(event)
        setselectedClientBusiness(event);
        setNewOrg({ ...newOrg, clientBusiness: event });
    };


    /*PS_CD_1.41 - 51
       It handles the saving of organization data. It determines the logo based on whether it's a new organization or an existing one, 
       constructs a form data object, and sends it to a server using the postOrganization function.
        The function also updates the display with a loader during the process, fetches the updated organization data, and handles errors 
        by logging them and displaying an alert. The asynchronous nature ensures smooth execution, and various comments denote different steps 
        in the process, such as handling logos and displaying loading indicators.
      */
    console.log("new orgggggggg", newOrg);
    const handleSaveClick = async () => {
         

        console.log("-----------in save orgggggggg");
        try {
        let logo;
        if (!newOrg.orgId) {
            logo = newOrg.orgLogo || 'images/Gen-ETL.svg';
        } else {
            logo = newOrg.orgLogo;
        }

        const formData = {
            ...newOrg,
            orgLogo: logo,
            isActive: isActive[newOrg.orgId],
            userId: state?.userId
        };
        //console.log('formData', formData);

      
            document.getElementById('pageLoader').style.display = 'block';
            console.log("finalll api data  neworgggg", newOrg, "formData", formData, "users", users, "state", state);

            let res = await postOrganization(state.isEdit, formData, users, newOrg?.orgId, state?.jwt);
            //console.log('res', res);
            // fetchOrganization(state.userId);
            document.getElementById('pageLoader').style.display = 'none';
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status !== 200) {
                alert('Error in submitting');
            }
            else if (res.status == 200) {
                navigate('/manageOrganization', { state: { ...state } });
            }
        } catch (error) {
            console.error(error);
            console.log('Error in submitting');
            // alert('Error in submitting');
           
        }
    };



    //  PS_CD_1.31


    const handleUploadLogo = (event) => {
         
        try {
        //PS_CD_1.32
        const file = event.target.files[0];

        if (!file) {
            console.log("innn image upload not a png")

            return;
        }
        //PS_CD_1.33
        if (!(file.type === 'image/png' || file.type === 'image/jpeg')) {
            console.log("innn image upload not a png")
            return;
        }

        
            //PS_CD_1.34
            const reader = new FileReader();
            //PS_CD_1.35
            reader.readAsDataURL(file);
            //PS_CD_1.36
            reader.onload = (e) => {
                const shortLogo = e.target.result
                //console.log(shortLogo);
                //PS_CD_1.37
                setNewOrg({ ...newOrg, orgLogo: shortLogo, orgLogoName: file.name });
                setisImageUploaded(true)
            };
            //PS_CD_1.38
        } catch (error) {
            console.error("error", error);
        }
    }
    async function handleAddUser() {
         

        if (userDetails.userName && !users.some(user => user.userMailId === userDetails.userMailId) && userDetails.role) {
            console.log("userdetails in add", userDetails);
            let userDetails1 = { ...userDetails, index: users.length + 1 }
            setusersArray(() => [...usersArray, userDetails1])
            setusers(() => [...users, userDetails1])


            setuserDetails({ username: "", userMailId: "", adRole: "", adUserId: "", domain: "", role: "" })
        }


    }
    const handleVerticalIndustryClick = (industryId, industryName) => {
        setSelectedVerticalIndustry(industryName);
        setNewOrg({ ...newOrg, verticalIndustryId: industryId });
    };


    const bindVerticalIndustry = () => {

        console.log("in bindVerticalIndustry", verticalIndustryDropDown);
        // Check if filterOptions array exists and has at least one element
        if (verticalIndustryDropDown && verticalIndustryDropDown.length > 0) {
            // Get the first array from filterOptions, assuming it represents vertical industries
            const verticalIndustries = verticalIndustryDropDown;

            // Check if verticalIndustries is an array before mapping
            if (Array.isArray(verticalIndustries) && verticalIndustries.length > 0) {
                // Return an array containing list items for each vertical industry
                return verticalIndustries.map((industry) => (
                    <li style={{ zIndex: 1000000 }}
                        key={industry.verticalIndustryId}
                        value={industry.verticalIndustryId}
                        className={` dropdown-item font-12 cursorpointer dropdown-item${selectedVerticalIndustry === industry.verticalIndustryId ? ' active' : ''}`}
                        onClick={() => handleVerticalIndustryClick(industry.verticalIndustryId, industry.verticalIndustryName)}

                    >
                        {industry.verticalIndustryName}
                    </li>
                ));
            } else {
                console.warn('No vertical industries found in the  array.');
            }
        } else {
            console.error(' missing or empty.');
        }

        // Return null if there are no vertical industries to render
        return null;
    };

    const handleUserName = (val) => {
         

        const user = adusers.find((item) => item.displayName == val);
        console.log("handleUserName", user);
        setuserDetails({ ...userDetails, ...user, userName: user.displayName, userMailId: user.mail, adUserId: user.id, domain: user.department, index: users.length + 1 })
    };

    const handleEmailId = (val) => {
         
        const user = adusers.find((item) => item.mail == val);
        console.log(user, "handleEmailId");
        setuserDetails({ ...userDetails, ...user, userName: user.displayName, userMailId: user.mail, adUserId: user.id, domain: user.department, index: users.length + 1 })
    };



    const handleCancel = () => {
         
        setEditingIndex(null);
        // Reset userDetails state to empty object
        setuserDetails({});
        // Reset users state to its original array
        setusers(usersArray);
        console.log("usersArray", usersArray);
    }


    const handleEditClick = (index, val, preRole, fullVal) => {
        setEditingIndex(index);
        // Find the selected user from adusers array
        let selectedItem = adusers.find((item) => item.mail === val);
        console.log("in edit function,", selectedItem);
        // Update userDetails state with selected user's details
        setuserDetails({
            userName: selectedItem.displayName,
            userMailId: selectedItem.mail,
            adUserId: selectedItem.id,
            role: preRole,
            index: fullVal.index
        });
    }

    const handleDelete = (index) => {
        console.log("in userd elete", users);
         
        let usersData = [...users]; // Create a copy of the users array
        let deleteuser = usersData.splice(index, 1);
        setusers(usersData); // Update the state with the modified users array
        setusersArray(usersData);
        console.log("deleteuser", deleteuser, "usersData", usersData);
    }



    const isFormFilled = () => {
         
        console.log("input vaaluess in forfilled", userDetails);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(userDetails); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };

    const handleInputChange = (key, value) => {
        setuserDetails({ ...userDetails, role: value })
    }

    const handleTick = (index, value) => {
         
        // const user = adusers.find((item) => item.mail == val.mail);

        let updatedUser = users[index] = userDetails

        console.log("updat,updatedUseredUser", updatedUser);

        setusers(users)
        setusersArray(users)
        setEditingIndex(null)
        setuserDetails({ userName: "", userMailId: "", adRole: "", adUserId: "", domain: "", role: "" })
        // setuserDetails({ ...userDetails, role: value })
    }

    const tableData = () => {

        // console.log(getDataRoles, "filter");

        if (users?.length !== 0) {
            console.log("getDataroles if condition", users)
            console.log()
            return (
                <>

                    {/* Rest of the rows */}
                    {users.map((val, index) => (
                        (editingIndex == index) ? (
                            <tr key={index}>
                                <td></td>
                                <td className="text-left">{userDetails?.userName}</td>
                                <td className="text-left">{userDetails?.userMailId || userDetails?.emailId}</td>
                                <td className="text-left ">
                                    <div className="dropdown ">
                                        <a
                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{ zIndex: 1001 }}
                                        >
                                            {userDetails.role}
                                        </a>
                                        <ul
                                            className="dropdown-menu cust-dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            {userRole.map(value => (
                                                <li key={value} onClick={() => handleInputChange("role", value)}>
                                                    <a className="dropdown-item">{value}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </td>
                                <td className="text-center ">
                                    <img
                                        src="images/right.svg"
                                        alt="edit-icon"
                                        className={`cust-cursor-pointer me-3 ${isFormFilled() ? '' : 'disabled'}`}
                                        onClick={(e) => handleTick(index, val)} // Only call handleAdd if form is filled

                                        disabled={isFormFilled()}


                                        // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                        // data-bs-target="#editRows"  // Specify the target modal
                                        title="update"

                                    />

                                    <img
                                        src="images/cross-red.svg"
                                        alt="cancel-icon"
                                        className="cust-cursor-pointer  me-3"
                                        title="cancel"
                                        onClick={() => handleCancel()}
                                    />
                                </td>
                            </tr>) :

                            <tr key={index} className={index % 2 === 0 ? "" : "table-alter-bg"}>
                                <td className="text-left">{val.index}</td>
                                <td className="text-left">{val.userName}</td>
                                <td className="text-left">{val?.userMailId || val?.emailId}</td>
                                <td className="text-left">{val.role}</td>
                                {state?.userMailId == (val?.userMailId || val?.emailId) ? <></> :
                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, (val?.userMailId || val.emailId), val.role, val)}
                                        />
                                        <span className="cust-cursor-pointer" >
                                            <img src="images/delete-icon.svg" onClick={(e) => handleDelete(index)} />
                                        </span>
                                    </td>
                                }
                            </tr>
                    ))}
                </>
            );
        } else {
            return null; // Return null if getDataRoles is empty
        }
    };

    const handleSort = (column) => {
        // let column;
        // if(column=="SNo"){
        //     column="index"
        // }
        // else{
        //     column=column
        // }
        column == "SNo" ? column = "index" : column = column

        const isSameColumn = sortOrder.column === column;
        const newOrder = isSameColumn ? !sortOrder.descending : true;
        const sortedData = [...users].sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            // Check if values are numbers
            const aValueIsNumber = !isNaN(aValue);
            const bValueIsNumber = !isNaN(bValue);

            // Provide default value if property doesn't exist
            const defaultStringValue = '';

            // If both values are numbers, sort numerically
            if (aValueIsNumber && bValueIsNumber) {
                return (isSameColumn
                    ? newOrder
                        ? aValue - bValue
                        : bValue - aValue
                    : aValue - bValue);
            } else {
                // If one or both values are not numbers, sort as strings
                return (isSameColumn
                    ? newOrder
                        ? (aValue || defaultStringValue).localeCompare(bValue || defaultStringValue)
                        : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue)
                    : (aValue || defaultStringValue).localeCompare(bValue || defaultStringValue));
            }
        });
        setSortOrder({
            column,
            descending: newOrder,
        });
        // setSortOrder({ column: columnName, ascending: newOrder });
        setusers(sortedData);
    };






    // const handleSort = (column) => {
    //      
    //     console.log("in sort orderrr", column);
    //     // PS_CD_1.66
    //     const isSameColumn = sortOrder.column === column;
    //     // PS_CD_1.67
    //     // PS_CD_1.68
    //     const newOrder = isSameColumn ? !sortOrder.descending : true;
    //     // PS_CD_1.69
    //     console.log("newOrder", newOrder, "isSameColumn", isSameColumn);

    //     setSortOrder({
    //         column,
    //         descending: newOrder,
    //     });
    //     // PS_CD_1.70
    //     // PS_CD_1.71
    //     // PS_CD_1.72
    //     let sortedList;
    //     if (column === 'SNo') {

    //         sortedList = users.slice().reverse();
    //     }
    //     else if (column !== 'SNo') {
    //         sortedList = users.slice().sort((a, b) => {
    //             // PS_CD_1.73
    //             /* The above code is using the `localeCompare` method to compare the values of two
    //                               elements `a[column]` and `b[column]`. The comparison is done based on the
    //                               locale-specific sort order and options provided. In this case, the comparison is
    //                               done with numeric sorting enabled and base sensitivity level. The result of the
    //                               comparison will be stored in the `compareValue` variable. */
    //             const compareValue = a[column].localeCompare(b[column], undefined, { numeric: true, sensitivity: 'base' });
    //             return newOrder ? compareValue : -compareValue;
    //             // const reversedList = filteredRecords.slice().reverse();

    //         });
    //     }
    //     // PS_CD_1.74
    //     setusersArray(sortedList);
    //     setusers(sortedList);

    //     setIsActive(sortedList.map(({ isActive }) => isActive))
    // };

    return (
        <div className="container-fluid">
            {/* <div style={editHTML === false ? { display: "none" } : { display: "block" }}></div> */}
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" class="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 pt-4 mt-5">
                <HeaderCom value="1" />

                {/* Breadcrumbs starts here */}
                <div className="mt-3 mb-3">
                    <ul className="cust-breadcrumb mb-0 px-0">
                        <li className="font-16 font-medium">
                            <a >Organization</a>
                        </li>
                        <li className="font-16 font-medium active">  {state.isEdit ? 'Edit Organization' : 'Create Organization'}  </li>
                    </ul>
                </div>
                <h2 className="text-black font-bold font-22 mb-3 cust-cursor-pointer">
                    <img src="images/back-arrow.svg" alt="Back" className="me-2" onClick={() => { navigate('/manageOrganization', { state: state }) }} />
                    {state.isEdit ? 'Edit Organization' : 'Create New Organization'}
                </h2>
                {/* Breadcrumbs ends here */}
                <div className=" p-4">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 mb-4">
                            <label
                                htmlFor="org-Name"
                                className="form-label text-black font-14 font-medium"
                            >
                                Organization Name
                                <span className="text-red"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control cust-input-sty font-14 font-regular"

                                id="org-Name"
                                placeholder="Enter Organization Name"
                                disabled=""
                                value={newOrg.orgName ? newOrg.orgName : null}
                                onChange={(e) => { setNewOrg({ ...newOrg, orgName: e.target.value }); setOrganizationName(e.target.value) }}
                            />

                        </div>
                        <div className="col-md-4 col-sm-12 mb-4">
                            <label className="form-label text-black font-14 font-medium">
                                Client Business
                            </label>
                            <span className="text-red"> *</span>
                            <div className="dropdown">
                                <a
                                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                    role="button"
                                    id="clientBusiness"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ zIndex: 1001 }}
                                    value={newOrg.clientBusiness}
                                >
                                    {selectedClientBusiness}
                                </a>
                                <ul
                                    className="dropdown-menu w-100 cust-drop-menu"
                                    aria-labelledby="dropdownMenuLink"
                                    value={newOrg.clientBusiness || "Select"}
                                >
                                    <li>
                                        <a className="dropdown-item font-12 cust-cursor-pointer" onClick={() => handleClientBusiness("Select")}>
                                            Select
                                        </a>
                                    </li>
                                    <li >
                                        <a className="dropdown-item font-12 cust-cursor-pointer" onClick={() => handleClientBusiness("B2B")}>
                                            B2B
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item font-12 cust-cursor-pointer" onClick={() => handleClientBusiness("B2C")}>
                                            B2C
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>


                        <div className="col-md-4 col-sm-12 mb-4 ">
                            <label className="form-label text-black font-14 font-medium">
                                Organization Logo
                            </label>

                            <div className="mb-3">
                                <button type="button" className="btn cust-secondary-btn">
                                    <label htmlFor="formFile">
                                        Upload Image
                                        <input
                                            className="form-control d-none"
                                            type="file"
                                            id="formFile"
                                            onChange={(e) => { handleUploadLogo(e) }}
                                        />
                                    </label>
                                </button>
                                {(isImageUploaded) ? (<span className="link-clr">
                                    <a className="ms-3 link-clr">
                                        {newOrg?.orgLogoName || ""}
                                        <img src="images/cross-blue.svg" className="ms-2" onClick={() => { setNewOrg({ ...newOrg, orgLogo: "" }); setisImageUploaded(false) }} />
                                    </a>
                                </span>) : <></>}

                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 mb-4">
                            <label className="form-label text-black font-14 font-medium">
                                Vertical Industry
                            </label>
                            <span className="text-red"> *</span>
                            <div className="dropdown">

                                <a
                                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ zIndex: 1000 }}
                                >
                                    {selectedVerticalIndustry ? selectedVerticalIndustry : 'Select'}
                                </a>
                                {/* )} */}
                                <ul
                                    className="dropdown-menu w-100 cust-drop-menu"
                                    aria-labelledby="dropdownMenuLink"
                                    style={{ zIndex: 10000 }}
                                >
                                    {bindVerticalIndustry()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="table-responsive mt-3 mx-4">
                        <h2 className="text-black font-bold font-22 mb-3">Manage Users</h2>
                        <table className="table table-borderless rounded custom-grid border-0 custom-metadata-table">
                            <thead className="sticky-top-pos custom-tableHeader" style={{ zIndex: '20' }}>
                                <tr>


                                    <th onClick={() => handleSort('SNo')}>
                                        SNo{' '}
                                        
                                    </th>



                                    {/* </th> */}
                                    <th onClick={() => handleSort('userName')}>
                                        Username{' '}
                                        {sortOrder.column === 'userName' ? (
                                            <span className="cursor-pointer">
                                                {sortOrder.descending ? (
                                                    <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />

                                                ) : (
                                                    <img src="images/sort-down-icon.svg" alt="sort-up-icon" className="sort-icon" />

                                                )}
                                            </span>
                                        ) : <span className="cursor-pointer">
                                            <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                        </span>}
                                    </th>
                                    <th onClick={() => handleSort('userMailId')}>
                                        Email{' '}
                                        {sortOrder.column === 'userMailId' ? (
                                            <span className="cursor-pointer">
                                                {sortOrder.descending ? (
                                                    <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />

                                                ) : (
                                                    <img src="images/sort-down-icon.svg" alt="sort-up-icon" className="sort-icon" />

                                                )}
                                            </span>
                                        ) : <span className="cursor-pointer">
                                            <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                        </span>}
                                    </th>
                                    <th onClick={() => handleSort('role')}>
                                        Role{' '}
                                        {sortOrder.column === 'role' ? (
                                            <span className="cursor-pointer">
                                                {sortOrder.descending ? (
                                                    <img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />
                                                ) : (
                                                    <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                                )}
                                            </span>
                                        ) : <span className="cursor-pointer">
                                            <img src="images/sort-down-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                        </span>}
                                    </th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {(editingIndex != null) ? (
                                        <></>
                                    ) : (

                                        < tr className="table-alter-bg">
                                            <td className="text-left"></td>
                                            <td className="text-left">
                                                <Select
                                                    options={userNames}
                                                    placeholder="Enter username" // Set placeholder here
                                                    className="basic-single"
                                                    value={userDetails.userName ? { label: userDetails.userName, value: userDetails.userName } : null} // Set value to null if placeholder is selected
                                                    classNamePrefix="select"
                                                    onChange={(e) => { console.log(e); handleUserName(e.value) }}
                                                />
                                            </td>
                                            <td className="text-left">
                                                <Select
                                                    options={mailIds}
                                                    placeholder="Enter email" // Set placeholder here
                                                    className="basic-single"
                                                    value={userDetails.userMailId ? { label: userDetails.userMailId, value: userDetails.userMailId } : null} // Set value to null if placeholder is selected
                                                    classNamePrefix="select"
                                                    onChange={(e) => { console.log(e); handleEmailId(e.value) }}
                                                />
                                            </td>

                                            <td><select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                aria-label="Default select example"
                                                name="role"
                                                value={userDetails.role}
                                                onChange={(e) => setuserDetails({ ...userDetails, role: e.target.value })}
                                            >
                                                <option value="">Select</option>
                                                <option value="Project Lead">Project Lead</option>
                                                <option value="Developer">Developer</option>


                                            </select></td>
                                            <td className="text-center">
                                                <span className="cust-cursor-pointer" >
                                                    <img
                                                        src="images/add-icons.svg"
                                                        onClick={() => handleAddUser()}
                                                        style={{
                                                            opacity: userDetails.userName &&
                                                                !users.some(user => user.userMailId === userDetails.userMailId) &&
                                                                userDetails.role
                                                                ? 1
                                                                : 0.5,
                                                            pointerEvents: userDetails.userName &&
                                                                !users.some(user => user.userMailId === userDetails.userMailId) &&
                                                                userDetails.role
                                                                ? 'auto'
                                                                : 'none'
                                                        }}
                                                    />                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </>



                                {tableData()}



                            </tbody>
                        </table>

                        <p className="font-regular font-14 text-grey">
                            # of Records:
                            <span className="text-black font-medium">{users.length} out of {users.length}</span>
                        </p>

                        {/* <p className="font-regular font-14 text-grey mt-3 ">
                        # of Records: <span className="text-black font-medium ">{users.length}</span>
                    </p> */}
                    </div>

                </div>
                <div className="modal-footer border-0 p-4">
                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" onClick={() => handleCancelOrganization()} data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button
                        type="button"
                        className={`btn cust-primary-btn font-14 font-medium `}
                        onClick={() => handleSaveClick()}
                        disabled={newOrg.orgName.trim() == "" || newOrg.clientBusiness == "Select" || newOrg.verticalIndustryId == "" || users.length == 0}
                        // data-bs-dismiss="modal"
                    >
                        <span className="px-2">Save Details</span>
                    </button>

                </div>
            </div>
        </div>
    )


}
export default NewOrganisation;