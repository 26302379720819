import React, { useState, useEffect, useRef } from "react";
// PS_CD_1.2
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, updatePseudocodeData } from "../Service/Api.js";

import { saveAs } from 'file-saver'
import axios from 'axios';
import PlantUML from "react-plantuml";
import { HeaderCom } from "./Header.jsx";

function GoldDataModel(props) {

    console.log(props, "propssss");
    // PS_CD_1.3
    const location = useLocation();
    // PS_CD_1.4 
    const nav = useNavigate();
    // PS_CD_1.9
    let { state } = useLocation();

    state = { ...state, previous: "prev" };
    // PS_CD_1.5

    const [isEdited, setIsEdited] = useState(false);

    const [jsonData, setJsonData] = useState([]);
    // PS_CD_1.6
    const [selectedItem, setSelectedItem] = useState({});
    const [svgResponse, setSvgResponse] = useState('');
    const [isEditingData, setIsEditingData] = useState(false)

    const [popupErrors, setPopupErrors] = useState({});
    const [isEditMetavalue, setisEditMetavalue] = useState(false);
    //PS_CD_1.62
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    // PS_CD_1.7

    const [formValid, setFormValid] = useState(false);
    const [DropDownSubjectArea, setDropDownSubjectArea] = useState([]);
    const [DropDownSchemaName, setDropDownSchemaName] = useState([]);
    const [DropDownTableName, setDropDownTableName] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState(null);
    const [GoldDataModel, setGoldDataModel] = useState("");
    const [whetherUpdated, setwhetherUpdated] = useState(false);


    // const [inputValues, setInputValues] = useState({})
    const [subjectArea, setSubjectArea] = useState([])
    const [schemaName, setSchemaName] = useState([])
    const [body, setBody] = useState({})

    const [IsFilteredApplied, setIsFilteredApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [code, setCode] = useState();
    const [zoom, setZoom] = useState(1);
    const [constraints, setConstraints] = useState(["NULL", "NOTNULL", "Primary Key", "Foreign Key", "Unique"]);
    // const[DropDownSubjectArea,setDropDownSubjectArea]=useState([]);
    // PS_CD_1.8




    useEffect(() => {
        const fetchData = async () => {
            try {
                 
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [pseudoData] = await Promise.all([pseudoDataPromise]);
                console.log("res from fetch pseudo", pseudoData);
                
                
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Watch for changes to GoldDataModel and update the diagram
    useEffect(() => {
        if (GoldDataModel) {
            fetchKrokiDiagram(GoldDataModel);
        }
    }, [GoldDataModel]);

    const fetchKrokiDiagram = async (umlCode) => {
        try {
            document.getElementById('pageLoader').style.display = "block";
            const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
                headers: {
                    'Content-Type': 'text/plain',
                },
            });
            console.log(response, 'response.data from Kroki');
            
            setSvgResponse(response.data); // Set the SVG image source
            document.getElementById('pageLoader').style.display = "none";
        } catch (error) {
            console.error('Error fetching diagram from Kroki:', error);
            setSvgResponse('<div>Error generating diagram</div>');
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    const handleZoomIn = () => {
        setZoom(zoom + 0.1);
    };

    const handleZoomOut = () => {
        if (zoom > 0.2) {
            setZoom(zoom - 0.1);
        }
    };
    
    const fetchPseudo = async () => {
        try {
            let data = { type: "dataModelGold", projectId: state.projectId, userId: state.userId }
            let jwt = state.jwt;
            document.getElementById('pageLoader').style.display = "block"
             
            let pseudodata = await fetchPseudocodeData(data, jwt)
             
            document.getElementById('pageLoader').style.display = "none"
            console.log("GoldDataModel", pseudodata);
            if (pseudodata.statusCode == 200) {
                console.log("res from fetchPseudocodeData", pseudodata.data[0].dataModelGold);
                setGoldDataModel(pseudodata.data[0].dataModelGold);
            }
        }
        catch (e) {
            console.log("error in fetch", e);
        }
    }

    const handleCaptureClick = async () => {
        try {
            // Get the SVG content
            const svgString = svgResponse;
            
            // Create a Blob from the SVG string
            const blob = new Blob([svgString], { type: 'image/svg+xml' });
            
            // Use FileSaver to save the blob
            saveAs(blob, 'Gold Data Model Diagram.svg');
        } catch(error) {
            console.log(error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}

                    <div className="col-md-12 px-4">

                        <div className="mt-3 px-2">
                            <ul className="cust-breadcrumb mb-0 ">
                                <li className="font-16 font-medium">
                                    <a>Manage Projects</a>
                                </li>
                                <li className="font-16 font-medium">
                                    <a>{state.projectName}</a>
                                </li>
                                <li className="font-16 font-medium active">Gold Data Model Diagram</li>
                            </ul>
                        </div>
                        {/* Breadcrumbs ends here */}
                        <div className="d-flex align-items-center p-4">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px',cursor:"pointer" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/>
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">Gold Data Model Diagram</h2>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium ms-auto"
                                onClick={() => handleCaptureClick()}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                        <div className="col-md-12 px-4">
                            {/* Accordion starts here */}
                            <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                                <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                    <button
                                        className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#logger-notebook"
                                    >
                                        <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                        <span
                                            data-bs-toggle="collapse"
                                            data-bs-target="#logger-notebook"
                                            className="cursor-pointer text-black font-16 font-medium"
                                        >
                                            View Instructions
                                        </span>
                                    </button>
                                </p>
                                <div className="row">
                                    <div className="collapse show" id="logger-notebook">
                                        <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                            <ul className="font-16 text-grey-v4 font-medium mb-0">
                                                <li className="mb-2">
                                                    Please validate whether the data's are correct
                                                </li>
                                                <li className="mb-2">
                                                    If things are fine just click{" "}
                                                    <span className="text-black-v2 font-bold">'back' </span>then on{" "}
                                                    <span className="text-black-v2 font-bold">'Looks good'</span>{" "}
                                                    and proceed to next steps{" "}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Accordion ends here */}
                            {/* Error logger textarea starts here */}
                            <div className="row mt-3">
                                <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                    <div className="">
                                        <div className="">
                                            <textarea
                                                className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                                id="exampleFormControlInput1"
                                                value={GoldDataModel}
                                                style={{ height: '700px' }}
                                                onChange={(e) => { 
                                                    setGoldDataModel(e.target.value); 
                                                    setIsEdited(true); 
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 pt-4 mb-5">
                                    <div className="">
                                        <div className="position-relative">
                                            <div className="position-absolute custom-btn-position bg-white rounded-3 test-name" style={{ zIndex: 1 }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-common-style btn-zoom-in custom-bottom-brd new-style-added"
                                                    onClick={handleZoomIn}
                                                >
                                                    <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-common-style btn-zoom-out new-style-added"
                                                    onClick={handleZoomOut}
                                                >
                                                    <img
                                                        className=""
                                                        src="images/zoomout-icon.svg"
                                                        alt="zoom-out"
                                                    />
                                                </button>
                                            </div>
                                            
                                            <div style={{ transformOrigin: 'top left', overflow: 'auto', height: '710px' }}>
                                                <div className='div2' style={{ transform: `scale(${zoom})` }}>
                                                    <div id='daimage' dangerouslySetInnerHTML={{ __html: svgResponse }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade "
                                id="editRows"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex={-1}
                                aria-labelledby="ConfrmLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <h5
                                                className="modal-title d-flex align-items-center font-medium font-20"
                                                id="ConfrmLabel"
                                            >
                                                Edit Reason
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="modal-body py-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label
                                                        htmlFor="User-Name"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Specify reason for manual edit
                                                        <span className="text-red"> *</span>
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control font-14 font-regular "
                                                        id="User-Name"
                                                        placeholder="Enter reason"
                                                        rows={5}
                                                        defaultValue={null}
                                                        value={reason}
                                                        onChange={(e) => { setReason(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoldDataModel;