import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { getProjectInfo, insertError, projectsDropDown, postProjectInfo, getProjectName } from '../Service/Api.js';
import { HeaderCom } from './Header.jsx';
import { logDOM } from '@testing-library/react';
import { putJsonToBlob } from './new/BlobStorageService.js';


function ProjectInfo() {
    const [projectDetails, setProjectDetails] = useState({
        projectName: "",
        cloudProviderId: "",
        projectTypeId: "",
        futureStateTechnologyId: "" || null,
        warehouseId: "" || null,
        Methodology: "" || null,
        jobTypeId: "" || null,
        languageId: "" || null,
        engineTypeId: "" || null,
        versionId: "" || null,
        oldstoragePath: "" || null,
        newstoragePath: "" || null,
        oldCatalogName: [] || null,
        newCatalogName: [] || null
    });
    const [editHTML, seteditHTML] = useState(true)
    const [selectedFutureState, setSelectedFutureState] = useState('Select');
    const [selectedWarehouseType, setSelectedWarehouseType] = useState('Select');
    const [selectedJobType, setSelectedJobType] = useState('Select');
    const [selectedEngineType, setSelectedEngineType] = useState('Select');
    const [selectedLanguage, setSelectedLanguage] = useState('Select');
    const [selectedLanguageType, setSelectedLanguageType] = useState('Select');
    const [selectedVersion, setSelectedVersion] = useState('Select');
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedProjectType, setSelectedProjectType] = useState('Select');
    const [selectedCloudProvider, setSelectedCloudProvider] = useState('Select');
    const [selectedmigrationPattern, setselectedmigrationPattern] = useState('Select');
    const [selectedMethodology, setSelectedMethodology] = useState('Select');
    const [projectName, setprojectName] = useState([]);
    const [projectError, setProjectError] = useState(false)
    const [editProjectName, setEditProjectName] = useState('')
    const [inputValue, setInputValue] = useState('');
    const [oldCatalogName, setOldCatalogName] = useState([])
    const [newCatalogName, setNewCatalogName] = useState([])
    const [inputValueOld, setInputValueOld] = useState('');
    const [inputValueNew, setInputValueNew] = useState('');
    const [oldStoragePath, setOldStoragePath] = useState('')
    const [newStoragePath, setNewStoragePath] = useState('')
    const nav = useNavigate();
    const { state } = useLocation();
    if (state == null) {
        window.location.href = '/'
    }




    useEffect(() => {
        console.log(projectDetails, 'projectDetails')
        const loadData = async () => {
            try {
                await fetchDropDown();
                await uniqueProjectName();
                if (state.isEdit || (!state.isEdit && state.previous === 'prev' && state.projectId)) {
                    await getDetails();
                }
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
            }
        };

        loadData();
    }, []);


    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            nav('/');
        }, 3000);
    };

    async function fetchDropDown() {
        try {
            document.getElementById('pageLoader').style.display = 'block';

            const response = await projectsDropDown(state.userId, state.jwt);

            if (response.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                document.getElementById('Something-wrong').click();
            }
            else if (response.status == 200) {
                const { data } = response;
                setFilterOptions(data);
            }
            document.getElementById('pageLoader').style.display = 'none';


        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "ManageProjects",
                functionName: "fetchDropDown",
                userId: state.userId
            });
        }

    }

    const uniqueProjectName = async () => {
        try {
            //     
            //console.log("came");
            document.getElementById('pageLoader').style.display = 'block';

            const res = await getProjectName(state.userId, state.jwt);
            console.log("response for getproject name", res.data[0]);


            //console.log('unique projectName', res)
            /* The above code is checking if the `statusCode` property of the `res` object is equal to 200. If
            it is, then it is setting the `projectName` variable to the value of the first element in the
            `data` array of the `res` object. */
            // 
            // 
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res?.statusCode !== 200) {
                document.getElementById('Something-wrong').click();
            }
            else if (res?.statusCode == 200) {
                setprojectName(res.data[0]);
                //console.log('res.data.data[0]', res.data[0]) // Assuming the project name 
                //console.log('res.data.data[0]', res.data[0]) // Assuming the project name 
            }
            document.getElementById('pageLoader').style.display = 'none';

        }
        catch (error) {
            console.error('Error fetching project name:', error);
        }
    }



    const getDetails = async () => {
        //console.log('entered getDetails')

        document.getElementById('pageLoader').style.display = 'block';

        try {
            const body = { projectId: state.projectId, userId: state.userId };
            /*PS_CI_08*/


            let res = await getProjectInfo(body, state.jwt)
            console.log("response for p info", res.data);
            /*PS_CI_11*/
            if (res.status !== 200) {
                /*PS_CI_12*/
                document.getElementById('Something-wrong').click();
            } else {
                let data = res.data[0][0]
                console.log('data', data);
                const oldCatalogNames = JSON.parse(data.oldCatalogName || "[]");
                const newCatalogNames = JSON.parse(data.newCatalogName || "[]");
                // Use Promise.all to wait for all state updates to complete
                await Promise.all([
                    setProjectDetails(data),
                    setEditProjectName(data.projectName),
                    setSelectedProjectType(data.projectTypeName),
                    setSelectedCloudProvider(data.cloudProviderName),
                    setselectedmigrationPattern(data.migrationPattern),
                    setSelectedFutureState(data.futureStateName),
                    setSelectedWarehouseType(data.warehouseTypeName),
                    setSelectedJobType(data.jobTypeName),
                    setSelectedLanguage(data.languageName),
                    setSelectedLanguageType(data.languageName),
                    setSelectedVersion(data.versionName),
                    setSelectedEngineType(data.engineTypeName),
                    setSelectedMethodology(data.Methodology),
                    setOldCatalogName(oldCatalogNames),
                    setNewCatalogName(newCatalogNames)
                ]);
            }
        } catch (error) {
            insertError({
                "errorMessage": error.message,
                "serviceName": "frontend",
                "module": "ConnectionInfo",
                "functionName": "getProjectDetails",
                "userId": ''
            })
        } finally {
            // Hide the loader after all operations (including state updates) are complete
            document.getElementById('pageLoader').style.display = 'none';
        }
    }



    const submitDetails = async () => {
        try {

            document.getElementById('pageLoader').style.display = 'block';
            let body = { ...projectDetails, isEdit: state.isEdit, orgId: state.orgId, userId: state.userId, userName: state.userName, projectTypeId: projectDetails?.projectTypeId, orgName: state.orgName,"selectedProjectType":selectedProjectType };
            if (state.isEdit) {
                body = { ...body, projectId: state.projectId };
            } else if (state?.previous === 'prev') {
                body = { ...body, projectId: state.projectId, isEdit: true };
            }
            console.log(body, 'bodybodybodybodybodybodybodybody');

            console.log(body, 'statestatestatestate');

            const res = await postProjectInfo(body, state.jwt);
            console.log(res, 'statestatestatestate');

            document.getElementById('pageLoader').style.display = 'none';
            if (res.status === 200) {
                if (state.isEdit == false) {
                    sessionStorage.setItem('newPro', true)
                }
                console.log("state---in navvvvv", {
                    ...state,
                    ...projectDetails,
                    jwt: state.jwt,
                    projectId: state.isEdit ? state.projectId : res?.projectId,
                    projectName: projectDetails.projectName,
                    futureStateName: selectedFutureState
                }, "projetdetails", projectDetails, "state--", state);

                if (projectDetails.projectTypeId === "915943C8-E794-48C2-847A-8C37D4683EAD") {

                    console.log('okkkkkkkkkkk');

                    nav('/uc-chat', {
                        state: {
                            ...state,
                            ...projectDetails,
                            jwt: state.jwt,
                            projectId: state.isEdit ? state.projectId : res?.projectId,
                            projectName: projectDetails.projectName,
                            userId: state.userId
                        }
                    });
                } 
               else if (selectedProjectType === 'Tableau To PowerBI Migration') {

                    console.log('okkkkkkkkkkk');

                    nav('/tableauChat', {
                        state: {
                            ...state,
                            ...projectDetails,
                            jwt: state.jwt,
                            projectId: state.isEdit ? state.projectId : res?.projectId,
                            projectName: projectDetails.projectName,
                            userId: state.userId,
                        }
                    });
                }
                else if (selectedProjectType === 'DWH Migration') {
                    

                    console.log('okkkkkkkkkkk');

                    nav('/dwhChat', {
                        state: {
                            ...state,
                            ...projectDetails,
                            jwt: state.jwt,
                            projectId: state.isEdit ? state.projectId : res?.projectId,
                            projectName: projectDetails.projectName,
                            userId: state.userId,
                            migrationPattern:selectedmigrationPattern
                        }
                    });
                }
                
                
                else {
                    nav('/chat', {
                        state: {
                            ...state,
                            ...projectDetails,
                            jwt: state.jwt,
                            projectId: state.isEdit ? state.projectId : res?.projectId,
                            projectName: projectDetails.projectName,
                            futureStateName: selectedFutureState,
                            userId: state.userId
                        }
                    });
                }

            } else {
                document.getElementById('Something-wrong').click();
            }
        } catch (error) {
            insertError({
                "errorMessage": error.message,
                "serviceName": "frontend",
                "module": "ConnectionInfo",
                "functionName": "insertProjectDetails",
                "userId": state.userId
            });
            console.error(error);
        }
    };


    let onChangeValue = (e) => {
        setProjectDetails({ ...projectDetails, [e.target.name]: e.target.value })
        setOldStoragePath({ ...projectDetails, [e.target.name]: e.target.value })
        setNewStoragePath({ ...projectDetails, [e.target.name]: e.target.value })
        console.log(projectDetails, 'setProjectDetails')
    }



    const handleProjectTypeClick = (typeId, typeName) => {
        setSelectedProjectType(typeName);
        setProjectDetails({ ...projectDetails, projectTypeId: typeId, cloudProviderId: null });
        setSelectedCloudProvider(null)
        setSelectedFutureState(null)
        setselectedmigrationPattern(null)
    };


    const handleCloudProvider = (providerId, providerName) => {
        setSelectedCloudProvider(providerName);
        setProjectDetails({
            ...projectDetails,
            cloudProviderId: providerId,
            futureStateTechnologyId: null // Set futureStateTechnologyId to null when cloud provider changes
        });
        setSelectedFutureState(null);
        setSelectedJobType(null);
        setSelectedMethodology(null);
        setSelectedWarehouseType(null);
        setSelectedJobType(null);
        setSelectedLanguage(null);
        setSelectedLanguageType(null);
        setSelectedEngineType(null);
        setSelectedVersion(null)
    };


    const handleFutureStateClick = (futureStateId, futureStateName) => {
        setSelectedFutureState(futureStateName);
        setProjectDetails({
            ...projectDetails, futureStateTechnologyId: futureStateId, Methodology: null, warehouseId: null, jobType: null,
            languageId: null, engineTypeId: null, versionId: null
        });
        setSelectedMethodology(null)
        setSelectedWarehouseType(null)
        setSelectedJobType(null);
        setSelectedLanguage(null);
        setSelectedLanguageType(null);
        setSelectedEngineType(null);
        setSelectedVersion(null)
    };


    const handleWarehouseType = (warehouseId, warehouseName) => {
        setSelectedWarehouseType(warehouseName);
        setProjectDetails({ ...projectDetails, warehouseId: warehouseId });
    };

    const handleMethodologyChange = (selectedOption) => {
        setSelectedMethodology(selectedOption);
        setProjectDetails({ ...projectDetails, Methodology: selectedOption });
    };

    const handleJobType = (jobTypeId, jobTypeName) => {
        setSelectedJobType(jobTypeName);
        setProjectDetails({ ...projectDetails, jobTypeId: jobTypeId });
        setSelectedEngineType(null)
        setSelectedVersion(null)
    };

    const handleEngineType = (engineTypeId, engineTypeName) => {
        setSelectedEngineType(engineTypeName);
        setProjectDetails({ ...projectDetails, engineTypeId: engineTypeId });
    };

    const handleLanguageType = (languageId, languageName) => {
        setSelectedLanguageType(languageName);
        setProjectDetails({ ...projectDetails, languageId: languageId });
    };

    const handleLanguage = (languageId, languageName) => {
        setSelectedLanguage(languageName);
        setProjectDetails({ ...projectDetails, languageId: languageId });
    };

    const handleVersion = (versionId, versionName) => {
        setSelectedVersion(versionName);
        setProjectDetails({ ...projectDetails, versionId: versionId });
    };






    const bindProjectType = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the first array from filterOptions, assuming it represents vertical industries
            const projectTypes = filterOptions[1];

            // Check if there are any vertical industries to render
            if (Array.isArray(projectTypes) && projectTypes.length > 0) {
                // Return an array containing list items for each vertical industry
                return projectTypes.map((type) => (
                    <li
                        key={type.projectTypeId}
                        value={type.projectTypeId}
                        className={`cursorpointer dropdown-item${selectedProjectType === type.projectTypeId ? ' active' : ''}`}
                        onClick={() => handleProjectTypeClick(type.projectTypeId, type.projectTypeName)}

                    >
                        {type.projectTypeName}
                    </li>
                ));
            }
        }

        // Return null if there are no vertical industries to render
        return null;
    };



    const bindCloudProvider = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the first array from filterOptions, assuming it represents vertical industries
            const cloudProviders = filterOptions[2];
            //console.log(filterOptions[2]);
            //console.log(filterOptions[2]);

            // Check if there are any vertical industries to render
            if (Array.isArray(cloudProviders) && cloudProviders.length > 0) {
                // Return an array containing options for each vertical industry
                return cloudProviders.map((provider) => (
                    <li
    key={provider.cloudProviderId}
    value={provider.cloudProviderId}
    className={`cursorpointer dropdown-item${selectedProjectType === provider.cloudProviderId ? ' active' : ''}`}
    onClick={() => handleCloudProvider(provider.cloudProviderId, provider.cloudProviderName)}
>
    {provider.cloudProviderName === "AZURE" ? "Azure" : provider.cloudProviderName}
</li>
                ));
            }
        }

        // Return null if there are no vertical industries to render
        return null;
    };


    

    const bindMigrationPattern = () => {
        // Check if filterOptions array exists and has at least one element
      
            // Define the migration patterns
            const migrationPatterns = [
                { migrationPatternId: 1, migrationPatternName: 'Refactor' },
                { migrationPatternId: 2, migrationPatternName: 'Lift And Shift' }
            ];
    
            // Check if there are any migration patterns to render
            if (Array.isArray(migrationPatterns) && migrationPatterns.length > 0) {
                // Return an array containing options for each migration pattern
                return migrationPatterns.map((pattern) => (
                    <li
                        key={pattern.migrationPatternId}
                        value={pattern.migrationPatternId}
                        className={`cursorpointer dropdown-item${selectedProjectType === pattern.migrationPatternId ? ' active' : ''}`}
                        onClick={() => handleMigrationPattern(pattern.migrationPatternId, pattern.migrationPatternName)}
                    >
                        {pattern.migrationPatternName}
                    </li>
                ));
            }
        
    
        // Return null if there are no migration patterns to render
        return null;
    };

    const handleMigrationPattern = (patternId, patternName) => {
        setselectedmigrationPattern(patternName)
        setProjectDetails({
            ...projectDetails,
            migrationPatternName: patternName,
            futureStateTechnologyId: null // Set futureStateTechnologyId to null when migration pattern changes
        });
    };



    const bindFutureState = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const futureStates = filterOptions[3];

            // Log the selected project type and cloud provider

            // Check if there are any futureState options to render
            if (futureStates.length > 0) {
                //  
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedProjectTypeId = filterOptions[1].find(type => type.projectTypeName === selectedProjectType)?.projectTypeId;
                const selectedCloudProviderId = filterOptions[2].find(provider => provider.cloudProviderName === selectedCloudProvider)?.cloudProviderId;

                // Filter futureState options based on selected project type and cloud provider
                const filteredFutureStates = futureStates.filter((futureState) => {
                    return futureState.projectTypeId === selectedProjectTypeId &&
                        futureState.cloudProviderId === selectedCloudProviderId;
                });

                // Log the filtered future states
                //console.log('filteredFutureStates', filteredFutureStates);
                //console.log('filteredFutureStates', filteredFutureStates);

                // Return the list of future state options if there are any filtered future states
                if (filteredFutureStates.length > 0) {
                    return filteredFutureStates.map((futureState) => (
                        <li className='cursorpointer dropdown-item'
                            key={futureState.futureStateId}
                            value={futureState.futureStateId}
                            onClick={() => handleFutureStateClick(futureState.futureStateId, futureState.futureStateName)}// Add onClick handler if needed
                        >
                            {futureState.futureStateName}
                        </li>
                    ));

                } else {
                    console.warn('No futureState options found for the selected project type and cloud provider.');
                }
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };



    const bindWarehouseType = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            //  ;
            // Get the futureState options
            const warehouseTypes = filterOptions[8];
            //console.log('warehouseTypes', warehouseTypes);
            //console.log('warehouseTypes', warehouseTypes);

            // Log the selected project type and cloud provider
            //console.log('selectedFutureState', selectedFutureState);
            //console.log('selectedFutureState', selectedFutureState);

            // Check if there are any futureState options to render
            if (warehouseTypes && warehouseTypes.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedFutureStateId = filterOptions[3].find(type => type.futureStateName === selectedFutureState)?.futureStateId;
                //console.log('selectedFutureStateId', selectedFutureStateId);
                //console.log('selectedFutureStateId', selectedFutureStateId);

                // Filter warehouseTypes based on selectedFutureStateId
                const filteredWarehouseTypes = warehouseTypes.filter(warehouseType => warehouseType.futureStateId === selectedFutureStateId);

                // Log the filtered future states
                //console.log('filteredWarehouseTypes', filteredWarehouseTypes);
                //console.log('filteredWarehouseTypes', filteredWarehouseTypes);

                // Return the list of future state options if there are any filtered future states
                if (filteredWarehouseTypes.length > 0) {
                    return filteredWarehouseTypes.map(warehouseType => (
                        <li className='cursorpointer dropdown-item'
                            key={warehouseType.warehouseTypeId}
                            value={warehouseType.warehouseTypeId}
                            onClick={() => handleWarehouseType(warehouseType.warehouseTypeId, warehouseType.warehouseTypeName)} // Add onClick handler if needed
                        >
                            {warehouseType.warehouseTypeName}
                        </li>
                    ));
                }
            } else {
                console.warn('No warehouse types found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };





    const bindJobType = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const jobTypes = filterOptions[4];
            //console.log('jobTypes', jobTypes);
            //console.log('jobTypes', jobTypes);

            // Log the selected project type and cloud provider
            //console.log('selectedWarehosue', selectedWarehouseType);
            //console.log('selectedWarehosue', selectedWarehouseType);

            // Check if there are any futureState options to render
            if (jobTypes.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedFutureStateId = filterOptions[3].find(type => type.futureStateName === selectedFutureState)?.futureStateId;
                //console.log('selectedFutureStateId', selectedFutureStateId);
                //console.log('selectedFutureStateId', selectedFutureStateId);


                const filteredJobTypes = jobTypes.filter((jobType) => {
                    return jobType.futureStateId === selectedFutureStateId
                });

                // Log the filtered future states

                // Return the list of future state options if there are any filtered future states
                if (filteredJobTypes.length > 0) {
                    return filteredJobTypes.map((jobType) => (
                        <li className='cursorpointer dropdown-item'
                            key={jobType.jobTypeId}
                            value={jobType.jobTypeId}
                            onClick={() => handleJobType(jobType.jobTypeId, jobType.jobTypeName)} // Add onClick handler if needed
                        >
                            {jobType.jobTypeName}
                        </li>
                    ));


                }
            } else {
                console.warn('No futureState options found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };


    const bindEngineType = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const engineTypes = filterOptions[5];

            // Check if there are any futureState options to render
            if (engineTypes.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedJobTypeId = filterOptions[4].find(type => type.jobTypeName === selectedJobType)?.jobTypeId;
                //console.log('selectedJobTypeId', selectedJobTypeId);
                //console.log('selectedJobTypeId', selectedJobTypeId);


                const filteredEngineTypes = engineTypes.filter((engineType) => {
                    return engineType.jobTypeId === selectedJobTypeId
                });


                // Return the list of future state options if there are any filtered future states
                if (filteredEngineTypes.length > 0) {
                    return filteredEngineTypes.map((engineType) => (
                        <li className='cursorpointer dropdown-item'
                            key={engineType.engineTypeId}
                            value={engineType.engineTypeId}
                            onClick={() => handleEngineType(engineType.engineTypeId, engineType.engineTypeName)} // Add onClick handler if needed
                        >
                            {engineType.engineTypeName}
                        </li>
                    ));


                }
            } else {
                console.warn('No futureState options found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };



    const bindLanguageType = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const languages = filterOptions[6];
            //console.log('languages', languages);
            //console.log('languages', languages);

            // Log the selected project type and cloud provider
            //console.log('selectedengine', selectedEngineType);
            //console.log('selectedengine', selectedEngineType);

            // Check if there are any futureState options to render
            if (languages.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedEngineTypeId = filterOptions[5].find(type => type.engineTypeName === selectedEngineType)?.engineTypeId;
                //console.log('selectedEngineTypeId', selectedEngineTypeId);
                //console.log('selectedEngineTypeId', selectedEngineTypeId);

                // Filter futureState options based on selected project type and cloud provider
                /* The above code is filtering an array called `warehouseTypes` based on a condition. It
                is using the `filter` method to iterate over each element in the `warehouseTypes`
                array and return a new array called `filteredWarehouseTypes` that only contains
                elements where the `futureStateId` property is equal to the `selectedFutureStateId`
                variable. */
                const filteredLanguageTypeId = languages.filter((language) => {
                    return language.engineTypeId === selectedEngineTypeId
                });

                // Log the filtered future states
                //console.log('filteredLanguageTypeId', filteredLanguageTypeId);
                //console.log('filteredLanguageTypeId', filteredLanguageTypeId);

                // Return the list of future state options if there are any filtered future states
                if (filteredLanguageTypeId.length > 0) {
                    return filteredLanguageTypeId.map((language) => (
                        <li className='cursorpointer dropdown-item'
                            key={language.languageId}
                            value={language.languageId}
                            onClick={() => handleLanguageType(language.languageId, language.languageName)} // Add onClick handler if needed
                        >
                            {language.languageName}
                        </li>
                    ));


                }
            } else {
                console.warn('No futureState options found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };




    const bindLanguage = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const languages = filterOptions[6];
            //console.log('languages', languages);
            //console.log('languages', languages);

            //  Log the selected project type and cloud provider
            //console.log('selectedjobtype', selectedJobType);
            //console.log('selectedjobtype', selectedJobType);

            // Check if there are any futureState options to render
            if (languages.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedJobTypeId = filterOptions[4].find(type => type.jobTypeName === selectedJobType)?.jobTypeId;
                //console.log('selectedJobTypeId', selectedJobTypeId);
                //console.log('selectedJobTypeId', selectedJobTypeId);


                const filteredlanguage = languages.filter((language) => {
                    return language.jobTypeId === selectedJobTypeId
                });

                // Log the filtered future states
                //console.log('filteredlanguage', filteredlanguage);
                //console.log('filteredlanguage', filteredlanguage);



                // Return the list of future state options if there are any filtered future states
                if (filteredlanguage.length > 0) {
                    return filteredlanguage.map((language) => (
                        <li className='cursorpointer dropdown-item'
                            key={language.languageId}
                            value={language.languageId}
                            onClick={() => handleLanguage(language.languageId, language.languageName)} // Add onClick handler if needed
                        >
                            {language.languageName}
                        </li>
                    ));


                }
            } else {
                console.warn('No futureState options found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };



    const bindVersion = () => {
        // Check if filterOptions array exists and has at least one element
        if (filterOptions && filterOptions.length > 0) {
            // Get the futureState options
            const versions = filterOptions[7];
            //console.log('versions', versions);
            //console.log('versions', versions);

            // Log the selected project type and cloud provider
            //console.log('selectedengine', selectedEngineType);
            //console.log('selectedengine', selectedEngineType);

            // Check if there are any futureState options to render
            if (versions.length > 0) {
                // Find the selected project type and cloud provider in the filterOptions array to get their IDs
                const selectedEngineTypeId = filterOptions[5].find(type => type.engineTypeName === selectedEngineType)?.engineTypeId;
                //console.log('selectedEngineTypeId', selectedEngineTypeId);
                //console.log('selectedEngineTypeId', selectedEngineTypeId);

                // Filter futureState options based on selected project type and cloud provider
                /* The above code is filtering an array called `warehouseTypes` based on a condition. It
                is using the `filter` method to iterate over each element in the `warehouseTypes`
                array and return a new array called `filteredWarehouseTypes` that only contains
                elements where the `futureStateId` property is equal to the `selectedFutureStateId`
                variable. */
                const filteredVersion = versions.filter((version) => {
                    return version.engineTypeId === selectedEngineTypeId
                });
                // Log the filtered future states
                //console.log('filteredVersion', filteredVersion);
                //console.log('filteredVersion', filteredVersion);



                // Return the list of future state options if there are any filtered future states
                if (filteredVersion.length > 0) {
                    return filteredVersion.map((version) => (
                        <li className='cursorpointer dropdown-item'
                            key={version.versionId}
                            value={version.versionId}
                            onClick={() => handleVersion(version.versionId, version.versionName)} // Add onClick handler if needed
                        >
                            {version.versionName}
                        </li>
                    ));


                }
            } else {
                console.warn('No futureState options found in the filterOptions array.');
            }
        } else {
            console.error('filterOptions is missing or empty.');
        }

        // Return null if there are no futureState options to render
        return null;
    };


    const handleKeyPressOld = (event) => {
        if (event.key === 'Enter' && inputValueOld.trim() !== '') {
            if (!oldCatalogName.includes(inputValueOld)) {
                const updatedOldCatalogName = [...oldCatalogName, inputValueOld];
                setOldCatalogName(updatedOldCatalogName);
                setProjectDetails({ ...projectDetails, oldCatalogName: updatedOldCatalogName });
                setInputValueOld('');
            }
        }
    };

    const handleKeyPressNew = (event) => {
        if (event.key === 'Enter' && inputValueNew.trim() !== '') {
            if (!newCatalogName.includes(inputValueNew)) {
                const updatedNewCatalogName = [...newCatalogName, inputValueNew];
                setNewCatalogName(updatedNewCatalogName);
                setProjectDetails({ ...projectDetails, newCatalogName: updatedNewCatalogName });
                setInputValueNew('');
            }
        }
    };

    const createBubbleNew = (event) => {
        if (event) {
            if (!newCatalogName.includes(event)) {
                const updatedNewCatalogName = [...newCatalogName, event];
                console.log(updatedNewCatalogName, 'updatedNewCatalogNameupdatedNewCatalogName');

                setNewCatalogName(updatedNewCatalogName);
                setProjectDetails({ ...projectDetails, newCatalogName: updatedNewCatalogName });
                setInputValueNew('');
            }
        }

    }

    const createBubbleOld = (event) => {
        if (event) {
            if (!oldCatalogName.includes(event)) {
                const updatedOldCatalogName = [...oldCatalogName, event];
                setOldCatalogName(updatedOldCatalogName);
                setProjectDetails({ ...projectDetails, oldCatalogName: updatedOldCatalogName });
                setInputValueOld('');
            }
        }

    }

    const handleRemoveOldCatalog = (index) => {
        const updatedOldCatalogName = oldCatalogName.filter((_, i) => i !== index);
        setOldCatalogName(updatedOldCatalogName);
        setProjectDetails({ ...projectDetails, oldCatalogName: updatedOldCatalogName });
    };

    const handleRemoveNewCatalog = (index) => {
        const updatedNewCatalogName = newCatalogName.filter((_, i) => i !== index);
        setNewCatalogName(updatedNewCatalogName);
        setProjectDetails({ ...projectDetails, newCatalogName: updatedNewCatalogName });
    };







    return (
        <div className="container-fluid">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={editHTML === false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value='1' />
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            {state.isEdit || state.previous == 'prev' ? <li className="font-16 font-medium active" style={{ color: "black" }}>{projectDetails?.projectName}</li> : <li className="font-16 font-medium active" style={{ color: "black" }}>New Project</li>}
                        </ul>
                    </div>

                    <div className="row p-4">
                        <div className="d-flex  mb-4 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 ">
                                <a className="d-flex" style={{ cursor: "pointer" }} onClick={() => { nav('/manageProjects', { state: state }) }}><img src="images/back-arrow.svg" alt="back-arrow" /></a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.isEdit == true || state.previous == 'prev' ? `${projectDetails.projectName}` : "New Project"} </h2>
                            </div>
                            {/* <div class="d-lg-flex d-md-block"> */}
                            {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center">
                                <span className=" text-nowrap">Organization : </span>
                                <span>
                                    <img
                                        src={state.orgLogo}
                                        alt="hubspot-logo"
                                        className="figma-logo"
                                    />

                                </span>
                                <span className="me-2 text-nowrap font-bold">{state.orgName} </span>
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        <div className="code-review-tab-width">
                            <div className="code-review-page tabs nav ">
                                <div
                                    className="tab nav-link font-bold active"
                                    data-bs-toggle="pill"
                                    data-bs-target="#project-info"
                                >
                                    Project Info
                                </div>
                                <div
                                    className="tab nav-link font-bold"
                                >
                                    Ask GenETL
                                </div>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    id="project-info"
                                    className="tab-pane fade show code-review-tab tab-content active"
                                >
                                    <p className="mt-5 font-18 font-bold ">Project Info</p>
                                    <div className="modal-body py-0 my-4">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                                <label
                                                    htmlFor="Project-Name"
                                                    className="form-label text-black font-14 font-medium"
                                                >
                                                    Project Name
                                                    <span className="text-red"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name='projectName' value={projectDetails.projectName} className="form-control cust-input-sty font-14 font-regular"
                                                    maxLength="120" id="projectName"
                                                    onBlur={() => {

                                                        // 
                                                        //console.log('projectName', projectName);
                                                        if (!state.projectId) {
                                                            const isDuplicateProj = projectName.findIndex(data => data.projectName == projectDetails.projectName);
                                                            //console.log('isDuplicateProj', isDuplicateProj);
                                                            //console.log('isDuplicateProj', isDuplicateProj);
                                                            setProjectError(isDuplicateProj == -1 ? false : true);
                                                        } else {
                                                            if (projectDetails.projectName != editProjectName) {
                                                                const isDuplicateProj = projectName.findIndex(data => data.projectName == projectDetails.projectName);

                                                                setProjectError(isDuplicateProj == -1 ? false : true);
                                                            }
                                                        }
                                                    }} placeholder="Enter Project Name"
                                                    onChange={(e) => {
                                                        onChangeValue(e)
                                                    }} />
                                                <p className='form-label text-black font-14 font-medium text-danger mt-1'>&nbsp;{projectError == true ? 'Project name Already Exist' : null}</p>
                                            </div>


                                            <div className="col-md-4 col-sm-12">
                                                <label className="form-label text-black font-14 font-medium">
                                                    Project Type
                                                </label>
                                                <span className="text-red"> *</span>
                                                <div className="dropdown">
                                                    {state.isEdit || state.previous === 'prev' ? (
                                                        <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                            {selectedProjectType}
                                                        </p>
                                                    ) : (
                                                        <a
                                                            className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                            role="button"
                                                            id="dropdownMenuLink"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            style={{ zIndex: 1001 }}
                                                        >
                                                            {selectedProjectType ? selectedProjectType : 'Select'}
                                                        </a>
                                                    )}
                                                    <ul
                                                        className="dropdown-menu w-100 cust-drop-menu"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        {bindProjectType()}
                                                    </ul>
                                                </div>
                                            </div>

                                            {selectedProjectType == 'DWH Migration'  && (
    <div className="col-md-4 col-sm-12">
        <label className="form-label text-black font-14 font-medium">
            Migration Pattern
        </label>
        <span className="text-red"> *</span>
        <div className="dropdown">
            {state.isEdit || state.previous === 'prev' ? (
                <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                    {selectedmigrationPattern}
                </p>
            ) : (
                <a
                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ zIndex: 1001 }}
                >
                    {selectedmigrationPattern ? selectedmigrationPattern : 'Select'}
                </a>
            )}
            <ul
                className="dropdown-menu w-100 cust-drop-menu"
                aria-labelledby="dropdownMenuLink"
            >
                <li>{bindMigrationPattern()}</li>
            </ul>
        </div>
    </div>
)}



                                            {selectedProjectType !== 'Tableau To PowerBI Migration' && (
    <div className="col-md-4 col-sm-12">
        <label className="form-label text-black font-14 font-medium">
            Cloud Provider
        </label>
        <span className="text-red"> *</span>
        <div className="dropdown">
            {state.isEdit || state.previous === 'prev' ? (
                <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                    {selectedCloudProvider === "AZURE" ? "Azure" : selectedCloudProvider}
                </p>
            ) : (
                <a
                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ zIndex: 1001 }}
                >
                    {selectedCloudProvider ? (selectedCloudProvider === "AZURE" ? "Azure" : selectedCloudProvider) : 'Select'}
                </a>
            )}
            <ul
                className="dropdown-menu w-100 cust-drop-menu"
                aria-labelledby="dropdownMenuLink"
            >
                <li>{bindCloudProvider()}</li>
            </ul>
        </div>
    </div>
)}
                                        </div>

                                        {(selectedProjectType === 'Unity Catalog Migration'||selectedProjectType === 'Tableau To PowerBI Migration' || selectedProjectType === 'DWH Migration'?  <></> :
                                            <div className="row mt-md-5 mt-1 pt-2">

                                                <div className="col-md-4 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Future State Technology
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        {state.isEdit || state.previous === 'prev' ? (
                                                            <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                {selectedFutureState}
                                                            </p>
                                                        ) : (
                                                            <a
                                                                className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                role="button"
                                                                id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{ zIndex: 1001 }}
                                                            >
                                                                {selectedFutureState ? selectedFutureState : 'Select'}
                                                            </a>
                                                        )}
                                                        <ul
                                                            className="dropdown-menu w-100 cust-drop-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <li>{bindFutureState()}</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                {(selectedFutureState == 'ADF' && selectedCloudProvider == 'AZURE') ||
                                                    (selectedFutureState == 'AWS Glue' && selectedCloudProvider == 'AWS') ? (
                                                    <div className="col-md-4 col-sm-12">
                                                        <label className="form-label text-black font-14 font-medium">
                                                            Warehouse Type
                                                        </label>
                                                        <span className="text-red"> *</span>
                                                        <div className="dropdown">
                                                            {state.isEdit || state.previous === 'prev' ? (
                                                                <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                    {selectedWarehouseType}
                                                                </p>
                                                            ) : (
                                                                <a
                                                                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                    role="button"
                                                                    id="dropdownMenuLink"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    style={{ zIndex: 1001 }}
                                                                >
                                                                    {selectedWarehouseType ? selectedWarehouseType : 'Select'}
                                                                </a>
                                                            )}
                                                            <ul
                                                                className="dropdown-menu w-100 cust-drop-menu"
                                                                aria-labelledby="dropdownMenuLink"
                                                            >
                                                                <li>{bindWarehouseType()}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {(selectedFutureState === 'Synapse') ? (
                                                    <div className="col-md-4 col-sm-12">
                                                        <label className="form-label text-black font-14 font-medium">
                                                            Methodology
                                                        </label>
                                                        <span className="text-red"> *</span>
                                                        {(!state.isEdit || state.previous !== 'prev') ? (
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="methodology"
                                                                        id="methodology-notebook"
                                                                        value="notebook"
                                                                        checked={selectedMethodology === "notebook"}
                                                                        onChange={() => handleMethodologyChange("notebook")}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="methodology-notebook">
                                                                        Notebook
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="methodology"
                                                                        id="methodology-pipeline"
                                                                        value="pipeline"
                                                                        checked={selectedMethodology === "pipeline"}
                                                                        onChange={() => handleMethodologyChange("pipeline")}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="methodology-pipeline">
                                                                        Pipeline
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        ) : (
                                                            <div className="dropdown">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="methodology"
                                                                        id="methodology-notebook"
                                                                        value="notebook"
                                                                        checked={selectedMethodology}
                                                                        onChange={() => handleMethodologyChange("notebook")}
                                                                        disabled
                                                                    />
                                                                    <label className="form-check-label" htmlFor="methodology-notebook">
                                                                        Notebook
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="methodology"
                                                                        id="methodology-pipeline"
                                                                        value="pipeline"
                                                                        checked={selectedMethodology}
                                                                        onChange={() => handleMethodologyChange("pipeline")}
                                                                        disabled
                                                                    />
                                                                    <label className="form-check-label" htmlFor="methodology-pipeline">
                                                                        Pipeline
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null}
                                                {(selectedFutureState === 'AWS Glue') ? (
                                                    <div className="col-md-4 col-sm-12">
                                                        <label className="form-label text-black font-14 font-medium">
                                                            Job type
                                                        </label>
                                                        <span className="text-red"> *</span>
                                                        <div className="dropdown">
                                                            {state.isEdit || state.previous === 'prev' ? (
                                                                <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                    {selectedJobType}
                                                                </p>
                                                            ) : (
                                                                <a
                                                                    className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                    role="button"
                                                                    id="dropdownMenuLink"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    style={{ zIndex: 1001 }}
                                                                >
                                                                    {selectedJobType ? selectedJobType : 'Select'}
                                                                </a>
                                                            )}
                                                            <ul
                                                                className="dropdown-menu w-100 cust-drop-menu"
                                                                aria-labelledby="dropdownMenuLink"
                                                            >
                                                                <li>{bindJobType()}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>)}

                                        <div className="row mt-md-5 mt-1 pt-2">

                                            {selectedProjectType === 'Unity Catalog Migration' ? (
                                                <>
                                                    <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
    <label className="form-label text-black font-14 font-medium">
        Old Catalog Name<span className="text-red">*</span>
    </label>
    <div className="position-relative input-container form-control d-flex align-items-center flex-wrap">
        {oldCatalogName?.map((oldCatalog, index) => (
            <div key={index} className="multi-select-bubble subject-area font-12 font-medium d-flex align-items-center">
                {oldCatalog}
                <img
                    className="ms-2 cursor-pointer"
                    src="images/cross-red.svg"
                    alt="remove"
                    onClick={() => handleRemoveOldCatalog(index)}
                />
            </div>
        ))}
        <input
            type="text"
            value={inputValueOld}
            onChange={(e) => setInputValueOld(e.target.value)}
            onBlur={(e) => createBubbleOld(e.target.value)}
            onKeyPress={handleKeyPressOld}
            className="input-field flex-grow-1"
            placeholder={oldCatalogName.length > 0 ? inputValueOld : "Enter Old Catalog Name"} // Corrected placeholder logic
            style={{ border: 'none', outline: 'none', minWidth: '150px' }}
        />
    </div>
</div>

                                                    <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                                        <label className="form-label text-black font-14 font-medium">
                                                            New Catalog Name<span className="text-red">*</span>
                                                        </label>
                                                        <div className="position-relative input-container form-control d-flex align-items-center flex-wrap">
                                                            {newCatalogName?.map((newCatalog, index) => (
                                                                <div key={index} className="multi-select-bubble subject-area font-12 font-medium">
                                                                    {newCatalog}
                                                                    <img
                                                                        className="ms-2 cursor-pointer"
                                                                        src="images/cross-red.svg"
                                                                        alt="remove"
                                                                        onClick={() => handleRemoveNewCatalog(index)}
                                                                    />
                                                                </div>
                                                            ))}
                                                            <input
                                                                type="text"
                                                                value={inputValueNew}
                                                                onChange={(e) => { setInputValueNew(e.target.value) }}
                                                                onBlur={(e) => { createBubbleNew(e.target.value) }}
                                                                onKeyPress={handleKeyPressNew}
                                                                className="input-field flex-grow-1"
                                                                placeholder={newCatalogName.length > 0 ? inputValueNew : "Enter New Catalog Name"} 
                                                                style={{ border: 'none', outline: 'none', minWidth: '150px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                                        <label

                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Old Storage Path
                                                            <span className="text-red"> *</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name='oldStoragePath' value={projectDetails.oldstoragePath
                                                            } className="form-control cust-input-sty font-14 font-regular"
                                                            maxLength="120" id="oldStoragePath"
                                                            placeholder="Enter Old Starge Path"
                                                            onChange={(e) => {
                                                                onChangeValue(e)
                                                            }} />

                                                    </div>
                                                    <div className="row mt-md-5 mt-1 pt-2">
                                                        <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                                            <label

                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                New Storage Path
                                                                <span className="text-red"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name='newStoragePath' value={projectDetails.newstoragePath} className="form-control cust-input-sty font-14 font-regular"
                                                                maxLength="120" id="newStoragePath"
                                                                placeholder="Enter New Storage Path"
                                                                onChange={(e) => {
                                                                    onChangeValue(e)
                                                                }} />

                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}

                                            {(selectedFutureState === 'AWS Glue' && selectedWarehouseType === 'Snowflake' && (selectedJobType === 'Visual ETL')) ? (
                                                <div className="col-md-4 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Language
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        {state.isEdit || state.previous === 'prev' ? (
                                                            <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                {selectedLanguage}
                                                            </p>
                                                        ) : (
                                                            <a
                                                                className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                role="button"
                                                                id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{ zIndex: 1001 }}
                                                            >
                                                                {selectedLanguage ? selectedLanguage : 'Select'}
                                                            </a>
                                                        )}
                                                        <ul
                                                            className="dropdown-menu w-100 cust-drop-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <li>{bindLanguage()}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {(selectedFutureState === 'AWS Glue' && (selectedJobType === 'Notebook' || selectedJobType === 'Script Editor')) ? (
                                                <div className="col-md-4 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Engine Type
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        {state.isEdit || state.previous === 'prev' ? (
                                                            <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                {selectedEngineType}
                                                            </p>
                                                        ) : (
                                                            <a
                                                                className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                role="button"
                                                                id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{ zIndex: 1001 }}
                                                            >
                                                                {selectedEngineType ? selectedEngineType : 'Select'}
                                                            </a>
                                                        )}
                                                        <ul
                                                            className="dropdown-menu w-100 cust-drop-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <li>{bindEngineType()}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {((selectedEngineType === 'Spark' && selectedFutureState === 'AWS Glue')) ? (
                                                <div className="col-md-4 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Language Type
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        {state.isEdit || state.previous === 'prev' ? (
                                                            <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                {selectedLanguageType}
                                                            </p>
                                                        ) : (
                                                            <a
                                                                className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                role="button"
                                                                id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{ zIndex: 1001 }}
                                                            >
                                                                {selectedLanguageType ? selectedLanguageType : 'Select'}
                                                            </a>
                                                        )}
                                                        <ul
                                                            className="dropdown-menu w-100 cust-drop-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <li>{bindLanguageType()}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {((selectedEngineType === 'Python Shell' && selectedFutureState === 'AWS Glue' && selectedWarehouseType != null)) ? (
                                                <div className="col-md-4 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Python Version
                                                    </label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        {state.isEdit || state.previous === 'prev' ? (
                                                            <p className="form-control cust-input-sty font-14 font-regular d-flex align-items-center">
                                                                {selectedVersion}
                                                            </p>
                                                        ) : (
                                                            <a
                                                                className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"

                                                                role="button"
                                                                id="dropdownMenuLink"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{ zIndex: 1001 }}
                                                            >
                                                                {selectedVersion ? selectedVersion : 'Select'}
                                                            </a>
                                                        )}
                                                        <ul
                                                            className="dropdown-menu w-100 cust-drop-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <li>{bindVersion()}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}




                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-14 font-medium me-3"
                                            onClick={() => {
                                                if (state.isEdit) {
                                                    nav('/manageProjects', { state: state });
                                                } else {
                                                    setProjectDetails({});
                                                    nav('/manageProjects', { state: state });
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>

                                        <button
    type="button"
    className="btn cust-primary-btn font-14 font-medium"
    disabled = {
        selectedProjectType === 'Tableau To PowerBI Migration' ? (
            projectDetails.projectName === "" || projectDetails.projectName === null ||
            projectDetails.projectTypeId === "" || projectDetails.projectTypeId === null
        ) : (
            projectDetails.projectName === "" || projectDetails.projectName === null ||
            projectError ||
            projectDetails.projectTypeId === "" || projectDetails.projectTypeId === null || (
                selectedProjectType === "Unity Catalog Migration" ? (
                    projectDetails.cloudProviderId === "" || projectDetails.cloudProviderId === null || 
                    projectDetails.newCatalogName === "" || projectDetails.newCatalogName === null || projectDetails.newCatalogName.length === 0 ||
                    projectDetails.oldCatalogName === "" || projectDetails.oldCatalogName === null || projectDetails.oldCatalogName.length === 0 ||
                    projectDetails.newStoragePath === "" || projectDetails.newStoragePath === null ||
                    projectDetails.oldStoragePath === "" || projectDetails.oldStoragePath === null
                ) : (
                    selectedProjectType === "DWH Migration" ? (
                        projectDetails.migrationPatternName === "" || projectDetails.migrationPatternName === null ||
                        projectDetails.projectName === "" || projectDetails.projectName === null ||
                        projectDetails.projectTypeId === "" || projectDetails.projectTypeId === null ||
                        selectedCloudProvider === "" || selectedCloudProvider === null
                    ) : (
                        projectDetails.cloudProviderId === "" || projectDetails.cloudProviderId === null ||
                        projectDetails.futureStateTechnologyId === "" || projectDetails.futureStateTechnologyId === null ||
                        ((selectedFutureState === 'ADF' && selectedCloudProvider === 'AZURE') && (projectDetails.warehouseId === "" || projectDetails.warehouseId === null)) ||
                        ((selectedFutureState === 'AWS Glue' && selectedCloudProvider === 'AWS') && (projectDetails.warehouseId === "" || projectDetails.warehouseId === null)) ||
                        ((selectedFutureState === 'AWS Glue') && (projectDetails.jobTypeId === "" || projectDetails.jobTypeId === null)) ||
                        (((selectedFutureState === 'AWS Glue' && selectedWarehouseType === 'Snowflake') && (selectedJobType === 'Visual ETL')) && (projectDetails.languageId === "" || projectDetails.languageId === null)) ||
                        ((selectedFutureState === 'AWS Glue' && selectedWarehouseType === 'Snowflake' && (selectedJobType === 'Notebook' || selectedJobType === 'Script Editor')) && (projectDetails.engineTypeId === "" || projectDetails.engineTypeId === null)) ||
                        ((selectedEngineType === 'Spark' && selectedFutureState === 'AWS Glue') && (projectDetails.languageId === "" || projectDetails.languageId === null)) ||
                        (selectedEngineType === 'Python Shell' && (projectDetails.versionId === "" || projectDetails.versionId === null)) ||
                        (selectedFutureState === 'Synapse' && (projectDetails.Methodology === "" || projectDetails.Methodology === null))
                    )
                )
            )
        )
    }
    onClick={() => submitDetails()}
>
                                            <span>Next</span>
                                        </button>

                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                                            Oops! Something went wrong
                                        </button>
                                        <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-body px-4 pb-0">
                                                        <div className="row text-center">
                                                            <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                                            <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                                            <p className="font-14 font-regular text-black">Please try again later </p>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                                                        <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                                            OK
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProjectInfo;