
import axios from 'axios';
import { encode, decode } from 'base-64';
import { insertError } from './Api';

export function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

/*
*PS_CD_1.117 - 128
*PS_CI_09,18,11,20 , PC_AD_22,20 , PC_NB_37 - 40 , PC_DDL_23 - 25 , PC_ME_26 - 28 
PS_CD_1.103-PS_CD_1.104
Client() method called with several parameters, including endpoint, requestType, body, and customConfig. Here's a breakdown of its functionality:
endpoint: The URL where the HTTP request is to be sent.
requestType: The HTTP method to be used (GET, POST, PUT, etc.).
body: The data to be sent with the request, typically in JSON format.
customConfig: Additional configuration options for the Axios request, including headers.
The function starts by setting default headers with 'Content-Type' as 'application/json; charset=UTF-8' and then merges them with any headers provided in customConfig.
If a body is provided, it is first converted to a JSON string and then encoded with Base64 using a series of random strings and additional encoding operations. The resulting encoded data is set as the data property in the request configuration.
The Axios library is used to make the HTTP request to the specified endpoint with the prepared requestConfig. If the request is successful, the response is processed.
If encrypted status is not 200, insert error api is called
*/

export async function Client(
  endpoint,
  { requestType, body, ...customConfig } = {},

) {
  //debugger
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8'
  };
  const requestConfig = {
    method: requestType,

    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };
  console.log(body,"Thisssss is my client fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee ")

  if (body) {
    let encodeBody = JSON.stringify(body)
    encodeBody = encode(random(20) + encode(random(12) + encode(encodeBody) + random(15)) + random(9))
    requestConfig.data = (encodeBody);
  }
// console.log(body,"Thisssss is my client fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")

  const url = `${endpoint}`;
  //console.log(requestConfig.data)
  try {
    var apiResponse = await axios(url, requestConfig)
    //console.log(apiResponse);
    let encryptedData = decode(apiResponse.data)
    encryptedData = decode(encryptedData.substring(20, encryptedData.length - 9))
    encryptedData = JSON.parse(decode(encryptedData.substring(12, encryptedData.length - 15)));
    if (apiResponse.status != 200) {
      insertError({
        "errorMessage": apiResponse?.data || "something went wrong",
        "serviceName": "backend",
        "module": "Client.js",
        "functionName": url,
        "userId": ''
      })
    }
    //console.log(url, encryptedData);
    return encryptedData
  } catch (error) {
    //console.log(error);
    return { status: 400 }
  }
}





















