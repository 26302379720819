// PS_CD_1.1
import React, { useState, useEffect, useRef } from "react";
// PS_CD_1.2
import { useLocation, useNavigate } from "react-router-dom";
import { getMetaDataValues, updateMetaValues,getProjectInfo, } from "../Service/Api.js";
import { HeaderCom } from "./Header.jsx";
import { metaCreateSchema } from "./Contrains.js";
import { random } from "../Service/Client";
import { encode } from "base-64";
import * as XLSX from 'xlsx';
const moment = require("moment");
// PS_CD_1.8
function MetaDataGridDev(props) {
    // PS_CD_1.3
    const [filterCriteria, setFilterCriteria] = useState({
        SubjectArea: 'Select',
        TableName: 'Select',
        SourceSystem: 'Select',
        SourceSchema: 'Select',
        SourceSecretName:"Select"

    });
    const [searchTerm, setSearchTerm] = useState("");
    const [filterTableName, setFilterTableName] = useState([]);
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);
    const [response, setResponse] = useState([])
    const [sortOrder, setSortOrder] = useState({ ascending: false, column: 'DataflowName' })

    const [filterSourceSystem, setFilterSourceSystem] = useState([]);
    const [filterSourceSchema, setFilterSourceSchema] = useState([]);
    const [filterSourceSecretName, setFilterSourceSecretName] = useState([]);
    const location = useLocation();
    // PS_CD_1.4 
    const nav = useNavigate();
    // PS_CD_1.9
    let { state } = useLocation();




    const handleSort = (columnName) => {
        const isSameColumn = sortOrder.column === columnName;
        const newOrder = isSameColumn ? !sortOrder.ascending : false;
        const sortedData = [...jsonData].sort((a, b) => {
            const aValue = a[columnName];
            const bValue = b[columnName];

            // Check if values are numbers
            const aValueIsNumber = !isNaN(aValue);
            const bValueIsNumber = !isNaN(bValue);

            // Provide default value if property doesn't exist
            const defaultStringValue = '';

            // If both values are numbers, sort numerically
            if (aValueIsNumber && bValueIsNumber) {
                return (isSameColumn
                    ? newOrder
                        ? aValue - bValue
                        : bValue - aValue
                    : aValue - bValue);
            } else {
                // If one or both values are not numbers, sort as strings
                return (isSameColumn
                    ? newOrder
                        ? (aValue || defaultStringValue).localeCompare(bValue || defaultStringValue)
                        : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue)
                    : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue));
            }
        });
        setSortOrder({ column: columnName, ascending: newOrder });
        setJsonData(sortedData);
    };

    const handleApplyFilter = () => {
         
        console.log("filtercriteria", filterCriteria)
        // PS_MD_142
        const { SubjectArea, TableName, SourceSchema, SourceSystem ,SourceSecretName} = filterCriteria;


        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea?.toLowerCase() === SubjectArea?.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record.DWHTableName?.toLowerCase() === TableName?.toLowerCase();
            const SourceSchemaMatch = SourceSchema === 'Select' || record.SourceSchema?.toLowerCase() === SourceSchema?.toLowerCase();
            const SourceSystemMatch = SourceSystem === 'Select' || record.SourceSystem?.toLowerCase() === SourceSystem?.toLowerCase();
const SourceSecretNameMatch=SourceSecretName=== 'Select' || record.SourceSecretName?.toLowerCase() === SourceSecretName?.toLowerCase();
            return SubjectAreaMatch && TableNameMatch && SourceSchemaMatch && SourceSystemMatch && SourceSecretNameMatch

        });
        // PS_CD_1.53
        // Set the filtered projectList to a separate state variable
        setJsonData(filteredList);
        console.log(filterSubjectArea,"filllllllllllllllllllllllllllllllllllllllllllllllllterrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
        
    };

    const handleClearFilter = () => {

        // Reset filter criteria to their initial values
        // PS_MD_166
        setFilterCriteria({
            SubjectArea: 'Select',
            TableName: 'Select',
            SourceSystem: 'Select',
            SourceSchema: 'Select'

        });
        // Reset filtered projectList to all projectList
        setJsonData(response);
    };





    const handlecancelSearch = (value) => {
        setSearchTerm();
        setJsonData(response.filter(filterByAppliedFilters()))

    };

    const filterByAppliedFilters = () => {
        console.log("filtercriteria", filterCriteria)
        const { SubjectArea, TableName, SourceSystem, SourceSchema } = filterCriteria;


        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record.TableName.toLowerCase() === TableName.toLowerCase();
            const SourceSchemaMatch = SourceSchema === 'Select' || record.SourceSchema.toLowerCase() === SourceSchema.toLowerCase();
            const SourceSystemMatch = SourceSystem === 'Select' || record.SourceSystem.toLowerCase() === SourceSystem.toLowerCase();

            return SubjectAreaMatch && TableNameMatch && SourceSchemaMatch && SourceSystemMatch
        });
        return filteredList
    };
    const handleSearch = (term) => {
         
        if (searchTerm.length < 3) {
            // If the search term is empty, reset to all 
            let records = filterByAppliedFilters()

            setJsonData(records);
        } else {
            // Filter the based  based on search criteria  SubjectArea
            
            const filteredData = jsonData.filter(
                (record) =>
                    (record.SourceSystem && record.SourceSystem?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SubjectArea && record.SubjectArea?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SourceDBName && record.SourceDBName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SourceTableName && record.SourceTableName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.DWHTableName && record.DWHTableName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.DWHScehmaName && record.DWHScehmaName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record?.NotebookName && record.NotebookName?.toLowerCase().includes(searchTerm?.toLowerCase())) 



            )

            setJsonData(filteredData);

            console.log(filteredData,"..........................................");

        }
    };
    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);

    if (state == null) {
        window.location.href = '/'
    }
    //   const projectId ='4442FB09-061A-4963-A6B6-DC0B90E3C6E1'
    //console.log(state);
    state = { ...state, previous: "prev" };
    // const jsonData = location.state ? location.state.responseData || [] : [];
    // PS_CD_1.5

    const [jsonData, setJsonData] = useState([]);
    // PS_CD_1.6
    const [selectedItem, setSelectedItem] = useState({});
    const [popupErrors, setPopupErrors] = useState({});
    const [isEditMetavalue, setisEditMetavalue] = useState(false);
    const [codeEdit, setCodeEdit] = useState(false);
    //PS_CD_1.62
    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    // PS_CD_1.7
    const [formValid, setFormValid] = useState(false);
    const [futureState, setFutureState] = useState("Databricks");
    const [methodology, setMethodology] = useState(null);
    // PS_CD_1.8
    useEffect(() => {
       
        // getSourceSystemCount();
        getFutureState();
      }, []);

      const getFutureState = async () => {
        try {
          document.getElementById("pageLoader").style.display = "block";
          console.log("came");
          const body = { projectId: state.projectId, userId: state.userId };
          /*PS_CI_08*/
          let res = await getProjectInfo(body, state.jwt);
          console.log("futureres", res);
          document.getElementById("pageLoader").style.display = "none";
          if (res.status == 200) {
            const data = res.data[0][0];
            setFutureState(data.futureStateName);
            setMethodology(data.Methodology);
    
            console.log(futureState);
            console.log(methodology);
          } else {
            console.log("Future State is empty.");
          }
        } catch (error) {
          console.error("Error fetching project name:", error);
        }
      };
    useEffect(() => { getMetaValues() }, [])
    const bindgrid = () => {
        //console.log('binding json', jsonData);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(jsonData) && jsonData.length > 0) {
                return (
                    <>
                        {jsonData?.map((item, index) => (
                            <tr key={index}>

                                <td>{item.SourceSystem || "NA"}</td>
                                <td>{item.SourceSecretName || "NA"}</td>
                                <td>{item.TableID || "NA"}</td>
                                <td>{item.SubjectArea || "NA"}</td>
                                <td>{item.SourceDBName || "NA"}</td>
                                <td>{item.SourceSchema || "NA"}</td>
                                <td>{item.SourceTableName || "NA"}</td>
                                <td>{item.LoadType || "NA"}</td>
                                <td>{item.IsActive || "NA"}</td>
                                <td>{item.Frequency || "NA"}</td>
                                <td>{item.BronzePath ||"NA"}</td>
                                <td>{item.SilverPath ||"NA"}</td>
                                <td>{item.GoldPath ||"NA"}</td>
                                <td>{item.DWHSchemaName ||"NA"}</td>
                                <td>{item.DWHTableName ||"NA"}</td>
                                <td>{item.ErrorLogPath ||"NA"}</td>
                                <td>{item.LastLoadDateColumn ||"NA"}</td>
                                <td>{item.LastLoadDateValue ||"NA"}</td>
                                <td>{item.MergeKey ||"NA"}</td>
                                <td>{item.DependencyTableIDs ||"NA"}</td>
                                <td>{item.PipelineEndTime ||"NA"}</td>
                                <td>{item.PipelineStartTime ||"NA"}</td>
                                <td>{item.PipelineRunStatus ||"NA"}</td>
                                <td>{item.Zone ||"NA"}</td>
                                <td>{item.MergeKeyColumn ||"NA"}</td>
                               <td>{item.SourceSelectQuery ||"NA"}</td>
                                <td>
                                    {futureState === 'Databricks' || futureState === 'synapse' && methodology == 'notebook' ? item.NotebookName :
                                        (futureState === 'ADF' || futureState === 'synapse' && methodology == 'pipeline' ? item.PipelineName : item.StoreProcedureName)
                                    }
                                </td>

                                <td className="text-center">
                                    <img
                                        src="images/edit-icon.svg"
                                        alt="edit-icon"
                                        className="cust-cursor-pointer"
                                        // data-bs-toggle="modal"
                                        data-bs-target="#Metadatainfo"
                                        onClick={() => handleEditClick(item)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindgrid:", error);
            return null;
        }
    };


    /** // PS_CD_1.13-1.16
     * The function `handleEditClick` sets the selected item and updates the `isEditMetavalue` state.
     */
    const handleEditClick = (item) => {
        setSelectedItem(item);
        setisEditMetavalue(false);
    };

    //PS_CD_1.52 - 58
    /**
     * The function `getMetaValues` fetches meta values, displays a loader, and sets the fetched data
     * if successful.
     */
    const getMetaValues = async () => {
        //console.log('Fetching meta values...');
        document.getElementById('pageLoader').style.display = 'block';
        try {
            console.log(" Iam in metadata")
            const res = await getMetaDataValues({projectId:state.projectId, userId:state.userId}, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            //console.log('Meta values response:', res);
            if (res.statusCode != 200) {
                console.log('Error fetching meta values:', res.error);
            } else {
                console.log(res.data[0].metadataTable,'Meta values fetched successfully');
                setJsonData(JSON.parse(res.data[0].metadataTable));
                setResponse(JSON.parse(res.data[0].metadataTable))
                let data=JSON.parse(res.data[0]?.metadataTable)
                // console.log(data,"dataaaaaaaaassssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss")
                let uniqueTableNames = [...new Set((data).map(item => item.DWHTableName))];
                let uniqueSubjectAreas = [...new Set((data).map(item => item.SubjectArea))];
                let uniqusourcesystem = [...new Set((data).map(item => item.SourceSystem))];
                let uniqueSourceSchema = [...new Set((data).map(item => item.SourceSchema))];

                // PS_MD_130
                setFilterSubjectArea(()=> uniqueSubjectAreas)
                setFilterTableName(()=> uniqueTableNames)
                setFilterSourceSystem(()=> uniqusourcesystem)
                setFilterSourceSchema(()=> uniqueSourceSchema)
            }
            
                
            
        } catch (error) {
            console.error('Error fetching meta values:', error);
        }
    };


    /** // PS_CD_1.17-1.26
     * The function `handleInputChange` is used to update the selected item with the new value from an
     * input field, and it also handles some specific cases for certain field names.
     */
    const handleInputChange = (e, fieldName) => {
        setCodeEdit(true)
        console.log("Entered handleInputChange");
        const value = e.target.value;
        const updatedFieldName = fieldName.replace(/\s+/g, "_");
    if (((futureState == "Databricks") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "notebook")) {
        const isNotebookNameField = updatedFieldName === "NotebookName";
        // console.log(isNotebookNameField, 'isNotebookNameField');
    
        setSelectedItem((prevSelectedItem) => {
            let updatedItem;
    
            if (updatedFieldName === "DWHTableName" && value === "") {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: null };
            } else {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: value };
            }
    
            if (isNotebookNameField && value !== prevSelectedItem.NotebookName) {
                setisEditMetavalue(true);
            }
    
            // Check if deployStatus is false and updateKeysArray should be appended
            if (props.deployStatus === false) {
                // Check if selectedItem already has an updateKeysArray, if not initialize it
                const updateKeysArray = updatedItem.updateKeysArray || [];
    
                // Find the index of the entry in updateKeysArray with the matching TableID
                const index = updateKeysArray.findIndex(entry => entry.TableID === prevSelectedItem.TableID);
    
                // If the entry exists, update its editedValues
                if (index !== -1) {
                    // Ensure editedValues is initialized before setting properties
                    if (!updateKeysArray[index].editedValues) {
                        updateKeysArray[index].editedValues = [];
                    }
                    // Add the new edited value to the first element of the editedValues array
                    updateKeysArray[index].editedValues[0][updatedFieldName] = value;
                } else {
                    // If the entry doesn't exist, create a new entry for updateKeysArray
                    const newEntry = {
                        editedValues: [{ [updatedFieldName]: value }],
                        TableID: prevSelectedItem.TableID,
                        SourceTableName: prevSelectedItem.SourceTableName,
                       
                    };
                    updateKeysArray.push(newEntry);
                    //console.log(updateKeysArray)
                }
                // Update the selectedItem with the updated updateKeysArray
                updatedItem = { ...updatedItem, updateKeysArray };
            }
    
            return updatedItem;
        });
    }
    if (((futureState == "ADF") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "pipeline")) {
        const isPipelineNameField = updatedFieldName === "PipelineName";
        console.log(isPipelineNameField, 'isPipelineNameField');
    
        setSelectedItem((prevSelectedItem) => {
            let updatedItem;
    
            if (updatedFieldName === "DWHTableName" && value === "") {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: null };
            } else {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: value };
            }
    
            if (isPipelineNameField && value !== prevSelectedItem.PipelineName) {
                setisEditMetavalue(true);
            }
    
            // Check if deployStatus is false and updateKeysArray should be appended
            if (props.deployStatus === false) {
                // Check if selectedItem already has an updateKeysArray, if not initialize it
                const updateKeysArray = updatedItem.updateKeysArray || [];
    
                // Find the index of the entry in updateKeysArray with the matching TableID
                const index = updateKeysArray.findIndex(entry => entry.TableID === prevSelectedItem.TableID);
    
                // If the entry exists, update its editedValues
                if (index !== -1) {
                    // Ensure editedValues is initialized before setting properties
                    if (!updateKeysArray[index].editedValues) {
                        updateKeysArray[index].editedValues = [];
                    }
                    // Add the new edited value to the first element of the editedValues array
                    updateKeysArray[index].editedValues[0][updatedFieldName] = value;
                } else {
                    // If the entry doesn't exist, create a new entry for updateKeysArray
                    const newEntry = {
                        editedValues: [{ [updatedFieldName]: value }],
                        TableID: prevSelectedItem.TableID,
                        SourceTableName: prevSelectedItem.SourceTableName,
                       
                    };
                    updateKeysArray.push(newEntry);
                    console.log(updateKeysArray)
                }
                // Update the selectedItem with the updated updateKeysArray
                updatedItem = { ...updatedItem, updateKeysArray };
            }
    
            return updatedItem;
        });
    }
    if (((futureState == "ADF") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "pipeline")) {
        const isPipelineNameField = updatedFieldName === "PipelineName";
        console.log(isPipelineNameField, 'isPipelineNameField');
    
        setSelectedItem((prevSelectedItem) => {
            let updatedItem;
    
            if (updatedFieldName === "DWHTableName" && value === "") {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: null };
            } else {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: value };
            }
    
            if (isPipelineNameField && value !== prevSelectedItem.PipelineName) {
                setisEditMetavalue(true);
            }
    
            // Check if deployStatus is false and updateKeysArray should be appended
            if (props.deployStatus === false) {
                // Check if selectedItem already has an updateKeysArray, if not initialize it
                const updateKeysArray = updatedItem.updateKeysArray || [];
    
                // Find the index of the entry in updateKeysArray with the matching TableID
                const index = updateKeysArray.findIndex(entry => entry.TableID === prevSelectedItem.TableID);
    
                // If the entry exists, update its editedValues
                if (index !== -1) {
                    // Ensure editedValues is initialized before setting properties
                    if (!updateKeysArray[index].editedValues) {
                        updateKeysArray[index].editedValues = [];
                    }
                    // Add the new edited value to the first element of the editedValues array
                    updateKeysArray[index].editedValues[0][updatedFieldName] = value;
                } else {
                    // If the entry doesn't exist, create a new entry for updateKeysArray
                    const newEntry = {
                        editedValues: [{ [updatedFieldName]: value }],
                        TableID: prevSelectedItem.TableID,
                        SourceTableName: prevSelectedItem.SourceTableName,
                       
                    };
                    updateKeysArray.push(newEntry);
                    console.log(updateKeysArray)
                }
                // Update the selectedItem with the updated updateKeysArray
                updatedItem = { ...updatedItem, updateKeysArray };
            }
    
            return updatedItem;
        });
    }
    if (futureState == 'SnowFlake' || futureState == 'RedShift' || futureState == 'AWS Glue') {
        const isStoreProcedureNameField = updatedFieldName === "StoreProcedureName";
        console.log(isStoreProcedureNameField, 'isStoreProcedureNameField');
    
        setSelectedItem((prevSelectedItem) => {
            let updatedItem;
    
            if (updatedFieldName === "DWHTableName" && value === "") {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: null };
            } else {
                updatedItem = { ...prevSelectedItem, [updatedFieldName]: value };
            }
    
            if (isStoreProcedureNameField && value !== prevSelectedItem.PipelineName) {
                setisEditMetavalue(true);
            }
    
            // Check if deployStatus is false and updateKeysArray should be appended
            if (props.deployStatus === false) {
                // Check if selectedItem already has an updateKeysArray, if not initialize it
                const updateKeysArray = updatedItem.updateKeysArray || [];
    
                // Find the index of the entry in updateKeysArray with the matching TableID
                const index = updateKeysArray.findIndex(entry => entry.TableID === prevSelectedItem.TableID);
    
                // If the entry exists, update its editedValues
                if (index !== -1) {
                    // Ensure editedValues is initialized before setting properties
                    if (!updateKeysArray[index].editedValues) {
                        updateKeysArray[index].editedValues = [];
                    }
                    // Add the new edited value to the first element of the editedValues array
                    updateKeysArray[index].editedValues[0][updatedFieldName] = value;
                } else {
                    // If the entry doesn't exist, create a new entry for updateKeysArray
                    const newEntry = {
                        editedValues: [{ [updatedFieldName]: value }],
                        TableID: prevSelectedItem.TableID,
                        SourceTableName: prevSelectedItem.SourceTableName,
                       
                    };
                    updateKeysArray.push(newEntry);
                    console.log(updateKeysArray)
                }
                // Update the selectedItem with the updated updateKeysArray
                updatedItem = { ...updatedItem, updateKeysArray };
            }
    
            return updatedItem;
        });
    }

    };
    
    
    


    //PS_CD_1.27-1.46
    /* The above code is a useEffect hook in a React component. It is used to check if all fields in the
      selectedItem object are filled. It does this by using the Object.values() method to get an array of
      values from the selectedItem object. Then, it uses the every() method to check if every value in the
      array is not undefined, not null, and not an empty string. If all fields are filled, the formValid
      state variable is set to true. The useEffect hook is triggered whenever the selectedItem object
      changes. */
    useEffect(() => {
        const areAllFieldsFilled = Object.values(selectedItem || {}).every(
            (value) => value !== undefined && value !== null && value !== ""
        );

        setFormValid(areAllFieldsFilled);
    }, [selectedItem]);

    //PS_CD_1.32-1.31
    /**
     * The `handleSave` function is an asynchronous function that handles the saving of data in a React
     * component.
     * @returns The function `handleSave` does not have a return statement, so it does not explicitly
     * return anything.
     */
    const handleSave = async () => {
        //console.log('entered save');
        try {
            setPopupErrors(() => {
                return {};
            });
            //console.log("Updating grid...");

            // Validate form fields
            let areAllFieldsValid = await validateAllFields();
            //console.log("areAllFieldsValid", areAllFieldsValid);
            if (!areAllFieldsValid) {
                return;
            }

            document.getElementById("closepopupmeta").click();

            // Check if it's an edit operation and if the NotebookName has changed
            //console.log(selectedItem);
            const metaDataNotebookedit = { edit: true };

            // Find the selectedTableData
            var selectedTableData = jsonData.filter(
                (item) => item.TableID === selectedItem.TableID
            )[0];

            //console.log(selectedTableData);
            //console.log(state.projectId);
            if (selectedTableData) {
                // Extract projectId and TableID
                const { TableID } = selectedTableData;
                //console.log(".........", state.projectId);
                //console.log(".........", TableID);
                //console.log(".........", selectedItem);
                //console.log(".........", metaDataNotebookedit);
                //console.log(".........", state.projectId);



                // Send data to the backend for update
                const update = await updateMetaValues(
                    state.projectId,
                    TableID,
                    selectedItem, // Send the updated selectedItem object
                    metaDataNotebookedit,
                    state.userId,
                    state.jwt
                );
                //console.log(update);


                // Update the jsonData state
                if (((futureState == "Databricks") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "notebook")) {
                setJsonData((prevJsonData) => {
                    const updatedJsonData = prevJsonData.map((item) => {
                        if (item.TableID === selectedItem.TableID) {
                            const updatedItem = {
                                ...item,
                                ...selectedItem,
                                isEdit: state.isEdit == true ? true : false,
                            };
                            return updatedItem;
                        } else if (
                            selectedTableData.NotebookName == item.NotebookName &&
                            selectedTableData.NotebookName != selectedItem.NotebookName
                        ) {
                            const updatedItem = {
                                ...item,
                                isEdit: state.isEdit == true ? true : false,
                                NotebookName: selectedItem.NotebookName,
                            };
                            return updatedItem;
                        }
                        return item;
                    });
                    return updatedJsonData;
                });
            }
            if (((futureState == "ADF") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "pipeline")) {
                setJsonData((prevJsonData) => {
                    const updatedJsonData = prevJsonData.map((item) => {
                        if (item.TableID === selectedItem.TableID) {
                            const updatedItem = {
                                ...item,
                                ...selectedItem,
                                isEdit: state.isEdit == true ? true : false,
                            };
                            return updatedItem;
                        } else if (
                            selectedTableData.PipelineName == item.PipelineName &&
                            selectedTableData.PipelineName != selectedItem.PipelineName
                        ) {
                            const updatedItem = {
                                ...item,
                                isEdit: state.isEdit == true ? true : false,
                                PipelineName: selectedItem.PipelineName,
                            };
                            return updatedItem;
                        }
                        return item;
                    });
                    return updatedJsonData;
                });
            }
            if (futureState == 'SnowFlake' || futureState == 'RedShift' || futureState == 'AWS Glue') {
                 setJsonData((prevJsonData) => {
                    const updatedJsonData = prevJsonData.map((item) => {
                        if (item.TableID === selectedItem.TableID) {
                            const updatedItem = {
                                ...item,
                                ...selectedItem,
                                isEdit: state.isEdit == true ? true : false,
                            };
                            return updatedItem;
                        } else if (
                            selectedTableData.StoreProcedureName == item.StoreProcedureName &&
                            selectedTableData.StoreProcedureName != selectedItem.StoreProcedureName
                        ) {
                            const updatedItem = {
                                ...item,
                                isEdit: state.isEdit == true ? true : false,
                                StoreProcedureName: selectedItem.StoreProcedureName,
                            };
                            return updatedItem;
                        }
                        return item;
                    });
                    return updatedJsonData;
                })}
            }


        } catch (error) {
            console.error("An error occurred while updating:", error);
        }
    };


    //console.log(popupErrors);
    useEffect(() => {
        // Check the value of the operation state and set isEditMetavalue accordingly
        if (location.state && location.state.operation) {
            setisEditMetavalue(false);
        } else {
            setisEditMetavalue(true);
        }
    }, [location.state]);



    /**
     * //PS_CD_1.79-1.80
     * The function `displayValidationError` sets an error message for a specific field in a popup.
     */
    const displayValidationError = (
        fieldName,
        errorCode,
        errorMessage,
        dataType
    ) => {
        setPopupErrors((data) => {
            return { ...data, [fieldName]: errorMessage };
        });
    };

    //
    /**
     * //PS_CD_1.47-1.51
     * The function `validateAllFields` validates multiple fields individually and returns whether all
     * fields are valid or not.
     * @returns The function `validateAllFields` returns a boolean value indicating whether all the fields
     * have passed validation.
     */
    const validateAllFields = async () => {
        let isSourceSystemValid = false,
        isSourceSecretNameValid = false,
        isTableIDValid = false,
        isSubjectArea = false,
        isSourceDBName = false,
        isSourceSchema = false,
        isSourceTableName = false,
        isLoadType = false,
        isIsActiveValid = false,
        isFrequency = false,
        isBronze = false,
        isSilver = false,
        isGold = false,
        isDWHSchemaName = false,
        isDWHTableName = false,
        isErrorLogPath = false,
        isLastLoadDateColumn = false,
        isMergeKey = false,
        isDependencyTableIDs = false,
        isLastLoadDateValue = false,
        isStageCreateQuery = false,
        isNotebookName = false,
        isPipelineName =false,
        isStoredProcedureName =false ;
            const isPipelineEndTime = await validatePipelineEndTime(selectedItem.PipelineEndTime);
            const isPipelineStartTime = await validatePipelineStartTime(selectedItem.PipelineStartTime);
            const isPipelineRunStatus = await validatePipelineRunStatus(selectedItem.PipelineRunStatus);
        const isZone = await validateZone(selectedItem.Zone);
        if (((futureState == "Databricks") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "notebook")) {
        if (selectedItem.Zone === "BronzePath") {
            isSourceSystemValid = await validateSourceSystem(selectedItem.SourceSystem);
            isSourceSecretNameValid = await validateSourceSecretName(selectedItem.SourceSecretName);
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isSourceDBName = await validateSourceDBName(selectedItem.SourceDBName);
            isSourceSchema = await validateSourceSchema(selectedItem.SourceSchema);
            isSourceTableName = await validateSourceTableName(selectedItem.SourceTableName);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isBronze = await validateBronzePath(selectedItem.BronzePath);
            isZone = await validateZone(selectedItem.Zone);
            // isGold = await validateGoldPath(selectedItem.GoldPath);
            isNotebookName = await validateNotebookName(selectedItem.NotebookName);

            const areAllFieldsValid =
            isSourceSystemValid &&
            isSourceSecretNameValid &&
            isTableIDValid &&
            isSourceDBName &&
            isSubjectArea &&
            isSourceSchema &&
            isSourceTableName &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isBronze &&
            isZone &&
            isNotebookName;
            //console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
        

        } else if (selectedItem.Zone === "SilverPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isSilver = await validateSilverPath(selectedItem.SilverPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isNotebookName = await validateNotebookName(selectedItem.NotebookName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&        
            isSilver &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isNotebookName;
            //console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
      

        } 
        else if (selectedItem.Zone === "GoldPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isGold = await validateGoldPath(selectedItem.GoldPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isNotebookName = await validateNotebookName(selectedItem.NotebookName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isGold &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isNotebookName;
            //console.log("dsadasdasdasdasdasd", areAllFieldsValid);

            return areAllFieldsValid;
       
            
        }
    }
    if (((futureState == "ADF") && (methodology == "" || methodology == null)) || (futureState == "Synapse" && methodology == "pipeline")) {
        if (selectedItem.Zone === "BronzePath") {
            isSourceSystemValid = await validateSourceSystem(selectedItem.SourceSystem);
            isSourceSecretNameValid = await validateSourceSecretName(selectedItem.SourceSecretName);
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isSourceDBName = await validateSourceDBName(selectedItem.SourceDBName);
            isSourceSchema = await validateSourceSchema(selectedItem.SourceSchema);
            isSourceTableName = await validateSourceTableName(selectedItem.SourceTableName);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isBronze = await validateBronzePath(selectedItem.BronzePath);
            isZone = await validateZone(selectedItem.Zone);
            // isGold = await validateGoldPath(selectedItem.GoldPath);
            isPipelineName = await validatePipelineName(selectedItem.PipelineName);

            const areAllFieldsValid =
            isSourceSystemValid &&
            isSourceSecretNameValid &&
            isTableIDValid &&
            isSourceDBName &&
            isSubjectArea &&
            isSourceSchema &&
            isSourceTableName &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isBronze &&
            isZone &&
            isPipelineName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
        

        } else if (selectedItem.Zone === "SilverPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isSilver = await validateSilverPath(selectedItem.SilverPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isPipelineName = await validatePipelineName(selectedItem.PipelineName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&        
            isSilver &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isPipelineName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
      

        } 
        else if (selectedItem.Zone === "GoldPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isGold = await validateGoldPath(selectedItem.GoldPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isPipelineName = await validatePipelineName(selectedItem.PipelineName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isGold &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isPipelineName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);

            return areAllFieldsValid;
       
            
        }  
    }
    if (futureState == 'SnowFlake' || futureState == 'RedShift' || futureState == 'AWS Glue') {
        if (selectedItem.Zone === "BronzePath") {
            isSourceSystemValid = await validateSourceSystem(selectedItem.SourceSystem);
            isSourceSecretNameValid = await validateSourceSecretName(selectedItem.SourceSecretName);
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isSourceDBName = await validateSourceDBName(selectedItem.SourceDBName);
            isSourceSchema = await validateSourceSchema(selectedItem.SourceSchema);
            isSourceTableName = await validateSourceTableName(selectedItem.SourceTableName);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isBronze = await validateBronzePath(selectedItem.BronzePath);
            isZone = await validateZone(selectedItem.Zone);
            // isGold = await validateGoldPath(selectedItem.GoldPath);
            isStoredProcedureName = await validateStoredProcedureName(selectedItem.StoreProcedureName);

            const areAllFieldsValid =
            isSourceSystemValid &&
            isSourceSecretNameValid &&
            isTableIDValid &&
            isSourceDBName &&
            isSubjectArea &&
            isSourceSchema &&
            isSourceTableName &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isBronze &&
            isZone &&
            isStoredProcedureName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
        

        } else if (selectedItem.Zone === "SilverPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isSilver = await validateSilverPath(selectedItem.SilverPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isStoredProcedureName = await validateStoredProcedureName(selectedItem.StoreProcedureName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&        
            isSilver &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isStoredProcedureName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);
            return areAllFieldsValid;
      

        } 
        else if (selectedItem.Zone === "GoldPath") {
            isTableIDValid = await validateTableID(selectedItem.TableID);
            isSubjectArea = await validateSubjectArea(selectedItem.SubjectArea);
            isLoadType = await validateLoadType(selectedItem.LoadType);
            isIsActiveValid = await validateIsActive(selectedItem.IsActive);
            isFrequency = await validateFrequency(selectedItem.Frequency);
            isGold = await validateGoldPath(selectedItem.GoldPath);
            isDWHSchemaName = await validateDWHSchemaName(selectedItem.DWHSchemaName);
            isDWHTableName = await validateDWHTableName(selectedItem.DWHTableName);
            isZone = await validateZone(selectedItem.Zone);
            isStoredProcedureName = await validateStoredProcedureName(selectedItem.StoreProcedureName);

            const areAllFieldsValid =
            isTableIDValid &&
            isSubjectArea &&
            isLoadType &&
            isIsActiveValid &&
            isFrequency &&
            isGold &&
            isDWHSchemaName &&
            isDWHTableName &&
            isZone &&
            isStoredProcedureName;
            console.log("dsadasdasdasdasdasd", areAllFieldsValid);

            return areAllFieldsValid;
       
            
        }
    }
    
        console.log(
            "das",
            isFrequency,
            "----",
            isSourceSystemValid,
            isSourceSecretNameValid,
            isTableIDValid,
            isSourceDBName,
            isSubjectArea,
            isSourceSchema,
            isSourceTableName,
            isLoadType,
            isIsActiveValid,
            isFrequency,
            isBronze,
            isSilver,
            isGold,
            isDWHSchemaName,
            isDWHTableName,
            isErrorLogPath,
            isLastLoadDateColumn,
            isMergeKey,
            isDependencyTableIDs,
            isLastLoadDateValue,
            isZone,
            isStageCreateQuery,
            isNotebookName,
            isStoredProcedureName,
            isPipelineName
        );
       
    };
    

    /**
     *
     * //PS_CD_1.18-1.24
     * The function `validateSourceSystem` checks if a given value is valid for the SourceSystem field,
     * and returns true if it is valid, or false if it is not.
     * @returns The function `validateSourceSystem` returns a boolean value. It returns `true` if the
     * validation passes, and `false` if any of the validation checks fail.
     */
    const validateSourceSystem = async (value) => {
        //console.log("Validating SourceSystem...");
        const fieldName = "SourceSystem";

        if (value === null || value === undefined || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Value Detected");
            displayValidationError(fieldName, "EM004", "SourceSystem value should not be empty", "string");
            return false;
        }

        // Check if the value is 'Null'
        if (value === null || (typeof value === "string" && value.toLowerCase() === "null")) {
            //console.log("Error: Null Value Detected");
            displayValidationError(fieldName, "EM010", 'SourceSystem value should not be "Null"', "string"
            );
            return false;
        }
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            //console.log("Error: Invalid Data Type");
            displayValidationError(fieldName, "EM006", "SourceSystem value should be in String datatype", "string");
            return false;
        }
        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            //console.log("Error: Space Detected");
            displayValidationError(fieldName, "EM005", "SourceSystem value should not contain space", "string");
            return false;
        }

        return true; // Validation passed
    };

    /**
     *
     * //PS_CD_1.25-1.31
     * The function `validateSourceSecretName` checks if a given value is valid for the `SourceSecretName`
     * field, and returns true if it passes all the validation checks.
     * @returns The function `validateSourceSecretName` returns a boolean value. It returns `true` if the
     * validation passes, and `false` if any of the validation checks fail.
     */
    const validateSourceSecretName = async (value) => {
        //console.log("Validating SourceSecretName...");
        const fieldName = "SourceSecretName";

        if (
            value === null ||
            value === undefined ||
            (typeof value === "string" && value.trim() === "")
        ) {
            //console.log("Error: Empty Value Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "SourceSecretName value should not be empty",
                "string"
            );
            return false;
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            //console.log("Error: Null Value Detected");
            displayValidationError(
                fieldName,
                "EM010",
                'SourceSecretName value should not be "Null"',
                "string"
            );
            return false;
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            //console.log("Error: Invalid Data Type");
            displayValidationError(
                fieldName,
                "EM006",
                "SourceSecretName value should be in String datatype",
                "string"
            );
            return false;
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            //console.log("Error: Space Detected");
            displayValidationError(
                fieldName,
                "EM005",
                "SourceSecretName value should not contain space",
                "string"
            );
            return false;
        }
        return true; // Validation passed
    };

    /**
     *  //PS_CD_1.31-1.37
     * The function `validateTableID` checks if a given value is a valid TableID by validating its data
     * type, checking for null values, and ensuring it does not contain spaces.
     * @returns The function `validateTableID` returns a boolean value. It returns `true` if the validation
     * passes, and `false` if any of the validation checks fail.
     */
    const validateTableID = async (value) => {
        //console.log("Validating TableID...");
        const fieldName = "TableID";

        // Check if the value is null or not a number
        if (value === null || isNaN(value) || !Number.isInteger(value)) {
            //console.log("Error: Invalid Data Type");
            displayValidationError(
                fieldName,
                "EM006",
                "TableID value should be in int datatype",
                "int"
            );
            return false;
        }

        // Check if the value is 'Null'
        if (value === null || value === "null") {
            //console.log("Error: Null Value Detected");
            displayValidationError(
                fieldName,
                "EM010",
                'TableID value should not be "Null"',
                "int"
            );
            return false;
        }

        // Check if the value contains space
        if (typeof value === "number" && value.toString().includes(" ")) {
            //console.log("Error: Space Detected");
            displayValidationError(
                fieldName,
                "EM005",
                "TableID value should not contain space",
                "int"
            );
            return false;
        }

        return true; // Validation passed
    };

    /**
     *  //PS_CD_1.38-1.43
     * The function `validateSubjectArea` checks if a given value is valid for the SubjectArea field and
     * returns true if it is valid, otherwise it displays an error message and returns false.
     * @returns The function `validateSubjectArea` returns a boolean value. It returns `true` if the
     * validation passes, and `false` if any of the validation checks fail.
     */
    const validateSubjectArea = async (value) => {
        //console.log("Validating SubjectArea...");
        //console.log("Received value:", value);
        const fieldName = "SubjectArea";

        // Check if the value is null or an empty string
        if (
            value === null ||
            (value.v && typeof value.v === "string" && value.v.trim() === "")
        ) {
            //console.log("Error: Empty Value Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "SubjectArea value should not be empty",
                "string"
            );
            return false;
        }

        // Check if the value is 'Null'
        if (
            value !== null &&
            value.v &&
            typeof value.v === "string" &&
            value.v.toLowerCase() === "null"
        ) {
            //console.log("Error: Null Value Detected");
            displayValidationError(
                fieldName,
                "EM010",
                'SubjectArea value should not be "Null"',
                "string"
            );
            return false;
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            //console.log("Error: Invalid Data Type");
            displayValidationError(
                fieldName,
                "EM006",
                "SubjectArea value should be in String datatype",
                "string"
            );
            return false;
        }

        //console.log("Validation passed");
        return true; // Validation passed
    };

    /**
     *  //PS_CD_1.44-1.50
     * The function `validateSourceDBName` is used to validate the value of a field called `SourceDBName`
     * and returns true if the validation passes.
     * @returns The function `validateSourceDBName` returns a boolean value. It returns `true` if the
     * validation passes, and `false` if any of the validation checks fail.
     */
    const validateSourceDBName = async (value) => {
        console.log("Validating SourceDBName...");
        const fieldName = "SourceDBName";

        if (
            value === null ||
            value === undefined ||
            (typeof value === "string" && value.trim() === "")
        ) {
            //console.log("Error: Empty Value Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "SourceDBName value should not be empty",
                "string"
            );
            return false;
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            //console.log("Error: Null Value Detected");
            displayValidationError(
                fieldName,
                "EM010",
                'SourceDBName value should not be "Null"',
                "string"
            );
            return false;
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            //console.log("Error: Invalid Data Type");
            displayValidationError(
                fieldName,
                "EM006",
                "SourceDBName value should be in String datatype",
                "string"
            );
            return false;
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            //console.log("Error: Space Detected");
            displayValidationError(
                fieldName,
                "EM005",
                "SourceDBName value should not contain space",
                "string"
            );
            return false;
        }

        return true; // Validation passed
    };

    /**
     *  //PS_CD_1.51-1.57
     * The function `validateSourceSchema` checks if a given value is valid according to certain criteria
     * and returns true if it is valid, and false otherwise.
     * @returns The function `validateSourceSchema` returns a boolean value. It returns `true` if the
     * `value` passes all the validation checks, and `false` if any of the validation checks fail.
     */
    const validateSourceSchema = async (value) => {
        //console.log("Validating SourceSchema...");
        const fieldName = "SourceSchema";
        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "SourceSchema value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            if (value === null) {
                return false;
            } else {
                displayValidationError(
                    fieldName,
                    "EM010",
                    'SourceSchema value should not be "Null"',
                    "string"
                );
                return false;
            }
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "SourceSchema value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "SourceSchema value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.58-1.64
    const validateSourceTableName = async (value) => {
        //console.log("Validating SourceTableName...");
        const fieldName = "SourceTableName";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "SourceTableName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            if (value === null) {
                return false;
            } else {
                displayValidationError(
                    fieldName,
                    "EM010",
                    'SourceTableName value should not be "Null"',
                    "string"
                );
                return false;
            }
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "SourceTableName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "SourceTableName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.65-1.71
    const validateLoadType = async (value) => {
        const fieldName = "LoadType";
        const allowedValues = [
            "Full Load",
            "Incremental Load",
            "Truncate and Load",
        ];

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "LoadType value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            if (value === null) {
                return false;
            } else {
                displayValidationError(
                    fieldName,
                    "EM010",
                    'LoadType value should not be "Null"',
                    "string"
                );
                return false;
            }
        }
        if (!allowedValues.includes(value)) {
            displayValidationError(
                fieldName,
                "EM018",
                `LoadType value should be one of: ${allowedValues.join(", ")}`,
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.72-1.75
    // Validate IsActive column
    const validateIsActive = async (value) => {
        const fieldName = "IsActive";
        if (isNaN(value) && value !== 0 && value !== 1) {
            displayValidationError(
                fieldName,
                "EM010",
                "IsActive value should contain either 0 or 1",
                "boolean"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.76-1.83
    const validateFrequency = async (value) => {
        //console.log("Validating Frequency...");
        const fieldName = "Frequency";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "Frequency value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            if (value === null) {
                return false;
            } else {
                displayValidationError(
                    fieldName,
                    "EM010",
                    'Frequency value should not be "Null"',
                    "string"
                );
                return false;
            }
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "Frequency value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "Frequency value should not contain space",
                "string"
            );
        }
        return true;
    };

    //PS_CD_1.83-1.89
    const validateBronzePath = async (value) => {
        const fieldName = "BronzePath";
        const sourceDBName = selectedItem.SourceDBName;
        const sourceSchema = selectedItem.SourceSchema;
        const sourceTableName = selectedItem.SourceTableName
    
    
        // Check if sourceDBName, sourceSchema, and sourceTableName are present in the value
        const keywordsPresent = [sourceDBName, sourceSchema, sourceTableName].every(
            (keyword) => value.toLowerCase().includes(keyword.toLowerCase())
        );
    
        if (!keywordsPresent) {
            displayValidationError(
                fieldName,
                "EM013",
                `BronzePath should contain 'SourceDBName, SourceSchema, SourceTableName' values`,
                "string"
            );
            return false;
        }
    
        // Check if the value contains any space
        if (value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "BronzePath value should not contain space",
                "string"
            );
            return false;
        }
    
        return true; // Validation passed
    };
    

    //PS_CD_1.90-1.97

    const validateSilverPath = async (value) => {
        console.log("................");
        const fieldName = "SilverPath";
        const sourceDBName = selectedItem.SourceDBName;
        const sourceSchema = selectedItem.SourceSchema;
        const sourceTableName = selectedItem.SourceTableName;
        const SilverPath = selectedItem.SilverPath;
        console.log("sourceDBName:", sourceDBName);
        console.log("sourceSchema:", sourceSchema);
        console.log("sourceTableName:", sourceTableName);
        console.log("SilverPath:", SilverPath);

        console.log("SilverPath:", SilverPath);
        // Check if the value is null or an empty string
   

        const keywordsPresent = [sourceDBName, sourceSchema, sourceTableName].every(
            (keyword) => SilverPath.toLowerCase().includes(keyword.toLowerCase())
        );

        if (!keywordsPresent) {
            displayValidationError(
                fieldName,
                "EM013",
                `SilverPath should contain 'SourceDBName, SourceSchema, SourceTableName' values`,
                "string"
            );
            return false;
        }

       
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "SilverPath value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };
    //PS_CD_1.98-1.103
    const validateGoldPath = async (value) => {
        const fieldName = "GoldPath";
        const sourceDBName = selectedItem.SourceDBName;
        const sourceSchema = selectedItem.SourceSchema;
        const sourceTableName = selectedItem.SourceTableName;
        const GoldPath = selectedItem.GoldPath;


        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "GoldPath value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.104-1.109

    const validateDWHSchemaName = async (value) => {
        //console.log("Validating DWHSchemaName...");
        const fieldName = "DWHSchemaName";
        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "DWHSchemaName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "DWHSchemaName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "DWHSchemaName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.110-1.115
    const validateDWHTableName = async (value) => {
        //console.log("Validating DWHTableName...");
        const fieldName = "DWHTableName";
        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "DWHTableName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "DWHTableName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "DWHTableName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };
    //PS_CD_1.116-1.123
    const validateErrorLogPath = async (value) => {
        //console.log("Validating ErrorLogPath...");
        const fieldName = "ErrorLogPath";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "ErrorLogPath value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is 'Null'
        if (
            value === null ||
            (typeof value === "string" && value.toLowerCase() === "null")
        ) {
            if (value === null) {
                return false;
            } else {
                displayValidationError(
                    fieldName,
                    "EM010",
                    'ErrorLogPath value should not be "Null"',
                    "string"
                );
                return false;
            }
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "ErrorLogPath value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "ErrorLogPath value should not contain space",
                "string"
            );
            return false;
        }

        if (typeof value !== "string" || !value.toLowerCase().includes("log")) {
            displayValidationError(
                fieldName,
                "EM006",
                "ErrorLogPath should contain 'log' keyword",
                "string"
            );
            return false;
        }

        return true;
    };

    //PS_CD_1.124-1.128
    const validateLastLoadDateColumn = async (value) => {
        //console.log("Validating LastLoadDateColumn...");
        const fieldName = "LastLoadDateColumn";
        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "LastLoadDateColumn value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "LastLoadDateColumn value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.129-1.134
    const validateMergeKey = async (value) => {
        //console.log("Validating MergeKey...");
        const fieldName = "MergeKey";
        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "MergeKey value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "MergeKey value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "MergeKey value should not contain space",
                "string"
            );
            return false;
        }

        return true;
    };

    //PS_CD_1.135-1.138
    const validateDependencyTableIDs = async (value) => {
        const fieldName = "DependencyTableIDs";

        if (
            value !== null &&
            value.toString().trim().split(/\s+/).length > 1 &&
            !/^(\d+,)+\d+$/.test(value)
        ) {
            displayValidationError(
                fieldName,
                "EM021",
                "DependencyTableIDs value should be separated by commas",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.139-1.143

    const validateLastLoadDateValue = async (value) => {
        const fieldName = "LastLoadDateValue";

        // Check if the value is a valid Date using moment
        const isValidDate = moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid();

        if (!isValidDate) {
            displayValidationError(
                fieldName,
                "EM014",
                "LastLoadDateValue should be in Timestamp datatype",
                "Timestamp"
            );
            return false;
        }

        // Check if the time portion of the value is in HH:mm:ss format using moment
        const isValidTimeFormat =
            moment(value, moment.ISO_8601, true).format("HH:mm:ss") !==
            "Invalid date";

        if (!isValidTimeFormat) {
            displayValidationError(
                fieldName,
                "EM015",
                "LastLoadDateValue should include time in HH:mm:ss format",
                "Timestamp"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.144-1.148
    const validatePipelineEndTime = async (value) => {
        const fieldName = "PipelineEndTime";

        // Check if the value is a valid Date using moment
        const isValidDate = moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid();

        if (!isValidDate) {
            displayValidationError(
                fieldName,
                "EM014",
                "PipelineEndTime should be in Timestamp datatype",
                "Timestamp"
            );
            return false;
        }

        // Check if the time portion of the value is in HH:mm:ss format using moment
        const isValidTimeFormat =
            moment(value, moment.ISO_8601, true).format("HH:mm:ss") !==
            "Invalid date";

        if (!isValidTimeFormat) {
            displayValidationError(
                fieldName,
                "EM015",
                "PipelineEndTime should include time in HH:mm:ss format",
                "Timestamp"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.149-1.153
    const validatePipelineStartTime = async (value) => {
        const fieldName = "PipelineStartTime";
        // Check if the value is a valid Date using moment
        const isValidDate = moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid();

        if (!isValidDate) {
            displayValidationError(
                fieldName,
                "EM014",
                "PipelineStartTime should be in Timestamp datatype",
                "Timestamp"
            );
            return false;
        }

        // Check if the time portion of the value is in HH:mm:ss format using moment
        const isValidTimeFormat =
            moment(value, moment.ISO_8601, true).format("HH:mm:ss") !==
            "Invalid date";

        if (!isValidTimeFormat) {
            displayValidationError(
                fieldName,
                "EM015",
                "PipelineStartTime should include time in HH:mm:ss format",
                "Timestamp"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.154-1.159
    const validatePipelineRunStatus = async (value) => {
        //console.log("Validating PipelineRunStatus...");
        const fieldName = "PipelineRunStatus";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "PipelineRunStatus value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "PipelineRunStatus value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "PipelineRunStatus value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.160-1.165
    const validateZone = async (value) => {
        //console.log("Validating Zone...");
        const fieldName = "Zone";

        if (value === null || (typeof value === "string" && value.trim() === "")) {
            //console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "Zone value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "Zone value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "Zone value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };


    const validateStageCreateQuery = async (value) => {
        //console.log("Validating value...", value);
        //console.log("Validating StageCreateQuery...");
        const fieldName = "StageCreateQuery";
        // Check if the cell value is null or 'null'
        if (value === null || (typeof value === "string" && value.toLowerCase() === "null")) {
            displayValidationError(fieldName, "EM023", 'StageCreateQuery svalue should not be "Null"', "string");

            return false;
        }

        // Check if the cell value does not include the word 'Create table'
        if (!value.toLowerCase().includes("create table")) {
            displayValidationError(fieldName, "EM023", "StageCreateQuery value should contain 'Create' keyword", "string");
            return false;
        }
        // Check if the cell value is an empty string
        if (value.trim() === "") {
            displayValidationError(fieldName, "EM023", "StageCreateQuery should not be empty", "string");
            return false;
        }

        // Check if the cell value includes the '*' character
        if (value.includes("*")) {
            displayValidationError(
                fieldName,
                "EM023",
                "StageCreateQuery value should not contain '*' keyword",
                "string"
            );
            return false;
        }

        // Check if the cell value does not contain the word 'location'
        if (!value.toLowerCase().includes("location")) {
            displayValidationError(
                fieldName,
                "EM023",
                "StageCreateQuery should contain Location",
                "string"
            );
            return false;
        }
        return true;
    };

    const validateNotebookName = async (value) => {
        console.log("Validating value...", value);
        console.log("Validating NotebookName...");
        const fieldName = "NotebookName";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "NotebookName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "NotebookName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "NotebookName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    //PS_CD_1.181-1.186
    const validatePipelineName = async (value) => {
        console.log("Validating value...", value);
        console.log("Validating PipelineName...");
        const fieldName = "PipelineName";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "PipelineName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "PipelineName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "PipelineName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };

    const validateStoredProcedureName = async (value) => {
        console.log("Validating value...", value);
        console.log("Validating StoredProcedureName...");
        const fieldName = "StoredProcedureName";

        // Change this part of the code in validateSourceSystem
        if (value === null || (typeof value === "string" && value.trim() === "")) {
            console.log("Error: Empty Cell Detected");
            displayValidationError(
                fieldName,
                "EM004",
                "StoredProcedureName value should not be empty",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value is not a string
        if (value !== null && (typeof value !== "string" || !isNaN(value))) {
            displayValidationError(
                fieldName,
                "EM006",
                "StoredProcedureName value should be in String datatype",
                "string"
            );
            return false; // Move to the next iteration
        }

        // Check if the value contains space
        if (value !== null && typeof value === "string" && value.includes(" ")) {
            displayValidationError(
                fieldName,
                "EM005",
                "StoredProcedureName value should not contain space",
                "string"
            );
            return false;
        }
        return true;
    };



    const handleDownload = async (response) => {
         ;
        console.log("in download");
        let responseValue = response.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(responseValue);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MetaData Table');

        // Generate Excel file and download
        XLSX.writeFile(wb, 'MetaDataTable.xlsx');
        return;
    }














    return (
        <div className="container-fluid">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
               <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Meta data Table</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-f">
                            <img src="images/back-arrow.svg" alt="back-arrow"  onClick={() => { props.setShowCode(); props.isEdit(codeEdit);}} style={{ 'cursor': 'pointer' }}/>
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">
                         Metadata Table
                        </h2>
                       </div>
                         <div className="d-flex" >
                        <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    placeholder="Search" onChange={(e) => { setSearchTerm(e.target.value); handleSearch(searchTerm) }}
                                    value={searchTerm}
                                />
                                <span className="search-icon-sty" onClick={() => handlecancelSearch} >
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon" />
                                    </a>
                                </span>
                            </div>
                            <div className="btn-group me-3">
                                <button
                                    className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon"
                                    />
                                </button>
                                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                // data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SourceSystem"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Source system
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SourceSystem"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.SourceSystem}

                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSystem: e.target.value })}
                                                    >
                                                        <option selected="">Select</option>
                                                        {filterSourceSystem.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SourceSchema"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Source Schema
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SourceSchema"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.SourceSchema}

                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSchema: e.target.value })}
                                                    >
                                                        <option selected="">Select</option>
                                                        {filterSourceSchema.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {state.futureStateName == "ADF" || state.futureStateName?.toLowerCase() == "synapse" && state.Methodology?.toLowerCase() == "pipeline" ? (
                                                <>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="SourceSecretName"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Source Secret Name
                                                            </label>
                                                            <select
                                                                value={filterCriteria.SourceSecretName}

                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="TableName"
                                                                aria-label="Default select example"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSecretName: e.target.value })}
                                                            >
                                                                <option selected="">Select</option>
                                                                {filterSourceSecretName.map(value => (
                                                                    <option key={value} value={value}>{value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                </>
                                            ) :

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="TableName"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Table Name
                                                        </label>
                                                        <select
                                                            value={filterCriteria.TableName}

                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="TableName"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, TableName: e.target.value })}
                                                        >
                                                            <option selected="">Select</option>
                                                            {filterTableName.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SubjectArea"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Subject Area
                                                    </label>
                                                    <select
                                                        value={filterCriteria.SubjectArea}

                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SubjectArea"
                                                        aria-label="Default select example"
                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}>
                                                        <option selected="">Select</option>
                                                        {filterSubjectArea.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={handleClearFilter}>
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={handleApplyFilter}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium "
                                // data-bs-dismiss="modal"
                                onClick={() => handleDownload(response)} 
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                    </div>
                     </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#view-instruction"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3 gen-accord"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#view-instruction"
                                        className="cursor-pointer pt-1 text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If any changes are required update them accordingly
                                                using{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Actions’
                                                </span>{" "}
                                                on the last column
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’ </span>
                                                then on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            {/* <tr>
                                                <th>SourceSystem </th>
                                                <th>SourceSecretName</th>
                                                <th>TableID</th>
                                                <th>SubjectArea</th>
                                                <th>SourceDBName</th>
                                                <th>SourceSchema</th>
                                                <th>SourceTableName</th>
                                                <th>LoadType</th>
                                                <th>IsActive</th>
                                                <th>Frequency</th>
                                                <th>BronzePath</th>
                                                <th>SilverPath</th>
                                                <th>GoldPath</th>
                                                <th>DWHSchemaName</th>
                                                <th>DWHTableName</th>
                                                <th>ErrorLogPath</th>
                                                <th>LastLoadDateColumn</th>
                                                <th>LastLoadDateValue</th>
                                                <th>MergeKey</th>
                                                <th>DependencyTableID</th>
                                                <th>PipelineStartTime</th>
                                                <th>PipelineEndTime</th>
                                                <th>PipelineRunStatus</th>
                                                <th>Zone</th>
                                                <th>MergeKeyColumn</th>
                                                <th>SourceSelectQuery</th>
                                                {futureState === 'Databricks' || (futureState === 'synapse' && methodology === 'notebook') ?
                                                <th>NotebookName </th>:
                                                ((futureState === 'ADF' || futureState === 'synapse') && methodology === 'pipeline') ? <th>PipelineName</th> : <th>StoreProcedureName</th>}
                                                <th className="text-center">Action</th>
                                            </tr> */}
                                            <tr>
                                                <th>SourceSystem <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSystem" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSystem')} /></span></th>
                                                <th>SourceSecretName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSecretName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSecretName')} /></span></th>
                                                <th>TableID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TableID" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TableID')} /></span></th>
                                                <th>SubjectArea<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                <th>SourceDBName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceDBName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceDBName')} /></span></th>
                                                <th>SourceSchema<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSchema" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSchema')} /></span></th>
                                                <th>SourceTableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTableName')} /></span></th>
                                                <th>LoadType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LoadType" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LoadType')} /></span></th>
                                                <th>IsActive<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "IsActive" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('IsActive')} /></span></th>
                                                <th>Frequency<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Frequency" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Frequency')} /></span></th>
                                                <th>BronzePath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzePath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzePath')} /></span></th>
                                                <th>SilverPath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverPath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverPath')} /></span></th>
                                                <th>GoldPath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldPath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldPath')} /></span></th>
                                                <th>DWHSchemaName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DWHSchemaName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DWHSchemaName')} /></span></th>
                                                <th>DWHTableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DWHTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DWHTableName')} /></span></th>
                                                <th>ErrorLogPath<span className="ms-2 cursor-pointer"></span></th>
                                                <th>LastLoadDateColumn<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LastLoadDateColumn" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LastLoadDateColumn')} /></span></th>
                                                <th>LastLoadDateValue<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LastLoadDateValue" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LastLoadDateValue')} /></span></th>
                                                <th>MergeKey<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "MergeKey" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('MergeKey')} /></span></th>
                                                <th>DependencyTableIDs<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DependencyTableIDs" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DependencyTableIDs')} /></span></th>
                                                <th>PipelineStartTime<span className="ms-2 cursor-pointer"></span></th>
                                                <th>PipelineEndTime<span className="ms-2 cursor-pointer"></span></th>
                                                <th>PipelineRunStatus<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineRunStatus" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineRunStatus')} /></span></th>
                                                <th style={{ width: '150px' }}> Zone<span className="ms-2 cursor-pointer"><img style={{ marginRight: '150px' }} src={sortOrder.ascending && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Zone')} /></span></th>
                                                <th>MergeKeyColumn<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "MergeKeyColumn" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('MergeKeyColumn')} /></span></th>
                                                <th>SourceSelectQuery<span className="ms-2 cursor-pointer"></span></th>
                                                {state.futureStateName == "Databricks" || state.futureStateName == "Synapse" && state.Methodology == "Notebook" ? (
                                                    <th>NotebookName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "NotebookName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('NotebookName')} /></span></th>
                                                )
                                                    : state.futureStateName == "ADF" || state.futureStateName == "Syanapse" && state.Methodology == "Pipeline" ? (
                                                        <th>PipelineName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineName')} /></span></th>

                                                    ) : (
                                                        <th>StoredProcedureName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "StoreProcedureName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('StoreProcedureName')} /></span></th>
                                                    )}



                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{bindgrid()}</tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3">
                                    # of Records: <span className="text-black font-medium">
                                        {jsonData.length} out of {jsonData.length}
                                    </span>
                                </p>
                                <div className="modal fade" id="Metadatainfo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="MetadatainfoLabel" aria-hidden="true">
                                    <div className="modal-dialog popup-w-800">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 px-4">
                                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="MetadatainfoLabel">Metadata Info <span className="popup-batch font-12 font-medium ms-2">Table Name : {selectedItem.SourceTableName}</span></h5>
                                                <button type="button" className="btn-close"  id='closepopupmeta' aria-label="Close" />
                                            </div>
                                            <div className="modal-body px-4 metadata-info-pp-scrl">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceSystem" className="form-label text-black font-14 font-medium">SourceSystem</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SourceSystem" placeholder="Enter SourceSystem" value={selectedItem.SourceSystem} onChange={(e) => handleInputChange(e, 'SourceSystem')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceSystem}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceSecretName" className="form-label text-black font-14 font-medium">Source Secret Name</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SourceSecretName" placeholder="Enter SourceSecretName" value={selectedItem.SourceSecretName} onChange={(e) => handleInputChange(e, 'SourceSecretName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceSecretName}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="TableID" className="form-label text-black font-14 font-medium">Table ID</label>
                                                        <input type="text" disabled={true} className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="TableID" placeholder="Enter Dependency Table ID" value={selectedItem.TableID} onChange={(e) => handleInputChange(e, 'TableID')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.TableID}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SubjectArea" className="form-label text-black font-14 font-medium">Subject Area </label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SubjectArea" placeholder="Enter Subject Area" value={selectedItem.SubjectArea} onChange={(e) => handleInputChange(e, 'SubjectArea')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SubjectArea}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceDBName" className="form-label text-black font-14 font-medium">SourceDBName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SourceDBName" placeholder="Enter SourceDBName" value={selectedItem.SourceDBName} onChange={(e) => handleInputChange(e, 'SourceDBName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceDBName}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceSchema" className="form-label text-black font-14 font-medium">SourceSchema</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SourceSchema" placeholder="Enter SourceSchema" value={selectedItem.SourceSchema} onChange={(e) => handleInputChange(e, 'SourceSchema')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceSchema}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">Source Table Name</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="SourceTableName" placeholder="Enter SourceTableName" value={selectedItem.SourceTableName} onChange={(e) => handleInputChange(e, 'SourceTableName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceTableName}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label className="form-label text-black font-14 font-medium">Load Type </label>
                                                        <select className="form-select cust-input-sty font-14 font-regular" aria-label="Default select example" value={selectedItem.LoadType} onChange={(e) => handleInputChange(e, 'LoadType')}>
                                                            <option selected>select</option>
                                                            <option value='Full Load'>Full Load</option>
                                                            <option value='Incremental Load'>Incremental Load</option>
                                                            <option value='Truncate and Load'>Truncate and Load</option>
                                                        </select>
                                                        <p class="text-danger">&nbsp;{popupErrors?.LoadType}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="IsActive" className="form-label text-black font-14 font-medium">IsActive </label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="IsActive" placeholder="Enter Active Value" value={selectedItem.IsActive} onChange={(e) => handleInputChange(e, 'IsActive')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.IsActive}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label className="form-label text-black font-14 font-medium">Frequency</label>
                                                        <select className="form-select cust-input-sty font-14 font-regular" aria-label="Default select example" value={selectedItem.Frequency} onChange={(e) => handleInputChange(e, 'Frequency')}>
                                                            <option selected>select</option>
                                                            <option value='daily'>Daily</option>
                                                            <option value='weekly'>weekly</option>
                                                            <option value='monthly'>Monthly</option>
                                                        </select>
                                                        <p class="text-danger">&nbsp;{popupErrors?.Frequency}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">BronzePath</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="BronzePath" placeholder="Enter BronzePath" value={selectedItem.BronzePath} onChange={(e) => handleInputChange(e, 'BronzePath')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.BronzePath}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">SilverPath</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="SilverPath" placeholder="Enter SilverPath" value={selectedItem.SilverPath} onChange={(e) => handleInputChange(e, 'SilverPath')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SilverPath}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">GoldPath</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="GoldPath" placeholder="Enter GoldPath" value={selectedItem.GoldPath} onChange={(e) => handleInputChange(e, 'GoldPath')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.GoldPath}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">DWHSchemaName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="DWHSchemaName" placeholder="Enter DWHSchemaName" value={selectedItem.DWHSchemaName} onChange={(e) => handleInputChange(e, 'DWHSchemaName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.DWHSchemaName}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">DWHTableName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="DWHTableName" placeholder="Enter DWHTableName" value={selectedItem.DWHTableName} onChange={(e) => handleInputChange(e, 'DWHTableName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.DWHTableName}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">ErrorLogPath</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="ErrorLogPath" placeholder="Enter ErrorLogPath" value={selectedItem.ErrorLogPath} onChange={(e) => handleInputChange(e, 'ErrorLogPath')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.ErrorLogPath}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="LastLoadDateColumn" className="form-label text-black font-14 font-medium">Last Load Date Column <span className="text-red">*</span></label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="LastLoadDateColumn" placeholder="Enter Last Load Date Column" value={selectedItem.LastLoadDateColumn} onChange={(e) => handleInputChange(e, 'LastLoadDateColumn')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.LastLoadDateColumn}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="Merge-Key" className="form-label text-black font-14 font-medium">Merge Key<span className="text-red">*</span></label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="MergeKey" placeholder="Enter Merge Key" value={selectedItem.MergeKey} onChange={(e) => handleInputChange(e, 'MergeKey')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.MergeKey}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="DependencyTableID" className="form-label text-black font-14 font-medium">Dependency Table ID<span className="text-red">*</span></label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="DependencyTableIDs" placeholder="Enter Dependency Table ID" value={selectedItem.DependencyTableIDs} onChange={(e) => handleInputChange(e, 'DependencyTableIDs')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.DependencyTableIDs}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">LastLoadDateValue</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="LastLoadDateValue" placeholder="Enter LastLoadDateValue" value={selectedItem.LastLoadDateValue} onChange={(e) => handleInputChange(e, 'LastLoadDateValue')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.LastLoadDateValue}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">PipelineEndTime</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="PipelineEndTime" placeholder="Enter PipelineEndTime" value={selectedItem.PipelineEndTime} onChange={(e) => handleInputChange(e, 'PipelineEndTime')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.PipelineEndTime}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">PipelineStartTime</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="PipelineStartTime" placeholder="Enter PipelineStartTime" value={selectedItem.PipelineStartTime} onChange={(e) => handleInputChange(e, 'PipelineStartTime')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.PipelineStartTime}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">PipelineRunStatus</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="PipelineRunStatus" placeholder="Enter PipelineRunStatus" value={selectedItem.PipelineRunStatus} onChange={(e) => handleInputChange(e, 'PipelineRunStatus')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.PipelineRunStatus}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">Zone</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="Zone" placeholder="Enter Zone" value={selectedItem.Zone} onChange={(e) => handleInputChange(e, 'Zone')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.Zone}</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="SourceTableName" className="form-label text-black font-14 font-medium">MergeKeyColumn</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="MergeKeyColumn" placeholder="Enter MergeKeyColumn" value={selectedItem.MergeKeyColumn} onChange={(e) => handleInputChange(e, 'MergeKeyColumn')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.MergeKeyColumn}</p>
                                                    </div>
                                                    {futureState === 'Databricks' || (futureState === 'synapse' && methodology === 'notebook') ?
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="NotebookName" className="form-label text-black font-14 font-medium">NotebookName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="NotebookName" placeholder="Enter NotebookName" value={selectedItem.NotebookName} onChange={(e) => handleInputChange(e, 'NotebookName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.NotebookName}</p>
                                                    </div>:  ((futureState === 'ADF' || futureState === 'synapse') && methodology === 'pipeline')?
                                                    <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="PipelineName" className="form-label text-black font-14 font-medium">PipelineName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="PipelineName" placeholder="Enter PipelineName" value={selectedItem.PipelineName} onChange={(e) => handleInputChange(e, 'PipelineName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.PipelineName}</p>
                                                    </div>: <div className="col-md-6 col-sm-12">
                                                        <label htmlFor="StoreProcedureName" className="form-label text-black font-14 font-medium">StoreProcedureName</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" maxLength="120" id="StoreProcedureName" placeholder="Enter StoreProcedureName" value={selectedItem.StoreProcedureName} onChange={(e) => handleInputChange(e, 'StoreProcedureName')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.StoreProcedureName}</p>
                                                    </div>}
                                                    <div className="col-md-12 col-sm-12 mb-3">
                                                        <label htmlFor="SourceSelectQuery" className="form-label text-black font-14 font-medium">SourceSelectQuery</label>
                                                        <textarea type="textarea" className="form-control resize-none cust-textarea-sty font-14 font-regular" id="SourceSelectQuery" rows={3} placeholder="Enter SourceSelectQuery" defaultValue={""} value={selectedItem.SourceSelectQuery} onChange={(e) => handleInputChange(e, 'SourceSelectQuery')} />
                                                        <p class="text-danger">&nbsp;{popupErrors?.SourceSelectQuery}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  // PS_CD_1.76-1.78 */}
                                            <div className="modal-footer border-0 px-4 pb-3 pt-0">
                                                <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" id='closePopup' data-bs-dismiss="modal">
                                                    Cancel
                                                </button>
                                                <button type="button" className={`btn cust-primary-btn font-14 font-medium ${formValid ? '' : 'disabled'}`} disabled={!formValid} onClick={() => { handleSave() }}>
                                                    <span className="px-2">Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MetaDataGridDev;
