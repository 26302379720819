import React, { useState,useEffect } from 'react';
import { read, utils as XLSXUtils } from 'xlsx';
import { putJsonToBlob1 } from '../new/BlobStorageService';
import { useLocation } from 'react-router-dom';
import { DWHConnection } from '../../Service/Api';
import { Satellite } from 'lucide-react';
import * as XLSX from 'xlsx';
import { generateSasTokenAPI } from '../../Service/Api';

export function InitSourceSystem(props) {
    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';
    

    const fetchTemplateFromBlob = async () => {
        try {
            const sasToken = await generateSasTokenAPI();
            const blobPath = `dwhmigration/templates/inventoryTemplate.json`;
            const url = `https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/${blobPath}?${sasToken}`;
            const response = await fetch(url);
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            convertJsonToExcel(jsonData);
    
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };
    
    const convertJsonToExcel = (jsonData) => {
       
        const workbook = XLSX.utils.book_new();
    
        for (const [key, data] of Object.entries(jsonData)) {
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, key);
        }
        const fileName = 'InventoryTemplate.xlsx';
        XLSX.writeFile(workbook, fileName);
    };


    
    const fetchJsonFromBlob = async () => {
        try {
            // Assuming generateSasTokenAPI returns a SAS token
            const sasToken = await generateSasTokenAPI();
            const blobPath = `dwhmigration/templates/snowflakeScript.sql`; // Update the path to point to the Python script
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            const url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;
            
            console.log(url2, 'this is the URL for fetch');
            
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const pythonCode = await response.text(); 
            console.log(pythonCode, 'Fetched Python Script');
            
            // Create a blob from the fetched text
            const blob = new Blob([pythonCode], { type: 'text/x-python' });
            
            // Create a link element for downloading the file
            const link = document.createElement('a');
            const urlBlob = window.URL.createObjectURL(blob);
            link.href = urlBlob;
            link.download = 'Inventoryscript.sql';
            document.body.appendChild(link); 
    
            
            link.click();
        
            document.body.removeChild(link);
            window.URL.revokeObjectURL(urlBlob);
            
            return pythonCode;
        } catch (error) {
            console.error('Error fetching and downloading Python script from blob:', error);
            throw error;
        }
    };

    

    const [isDisabled, setisDisabled] = useState(false);

    const [showSourceSystem, setShowSourceSystem] = useState(true);
    const [sourceSystemDetails, setSourceSystemDetails] = useState({
        sourceSystemName: "",
        sourceSystemType: "",
        dataVolume: "",
        SystemType: "",
        description: "",
        cloudProvider: ""
    });
    const [connectionInfo, setConnectionInfo] = useState({
        userName: "",
        host: "",
        url: "",
        warehouse: "",
        database: "",
        password: "",
        fileName: ""
    });
    const [errors, setErrors] = useState([]);
    const [disable, setDisable] = useState(false);

    const [entries, setEntries] = useState([{ fileName: "", sheetData: null, error: [] }]);
    const [isDownloading, setIsDownloading] = useState(false);
    const {state} = useLocation();


    const fetchData = async ()=>{
        try{
        let body={
            "projectId":state.projectId,
            "connectionType": "GET"
          }

          const data = await DWHConnection(body, state.jwt)
          setSourceSystemDetails(data?.data[0]?.DwhDetails)
          setConnectionInfo(data?.data[0]?.ConnectionDetails)
          if(data?.data[0]?.DwhDetails){
             setDisable(true)
          }
        }
        catch (e){
console.log(e,"fetch error");

        }
    }


    useEffect( () => {
         fetchData()
      }, []);
    const validate =  ()=>{
        return disable
    }

    const handleSourceSystemInputChange = (e) => {
        const { name, value } = e.target;
        setSourceSystemDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleConnectionInputChange = (e) => {
        const { name, value } = e.target;
        setConnectionInfo(prevState => ({ ...prevState, [name]: value }));
    };

    const handleNext = () => {
        const areAllSourceSystemFieldsFilled = (
            sourceSystemDetails.sourceSystemName &&
            sourceSystemDetails.sourceSystemType &&
            sourceSystemDetails.dataVolume
        );

        if (areAllSourceSystemFieldsFilled) {
            setShowSourceSystem(false);
            setErrors([]);
        } else {
            setErrors(['Please fill out all required Data Warehouse details.']);
        }
    };

    const handleSave = async () => {
        try {
            

            document.getElementById("pageLoader").style.display = "block";
            props.closePopup()
            handleSubmit()
          // Mock saving process
          let body={
            "projectId":state.projectId,
            "dwhDetails":sourceSystemDetails,
            "ConnectionDetails":connectionInfo,
            "connectionType": "POST"
          }

          const respon = await DWHConnection(body, state.jwt)
          console.log(respon,"postres");
          
            
        //   document.getElementById("pageLoader").style.display = "none";
           


        } catch (error) {
            console.log(error,"geeeeeeeeeeeeeeeeeeeeee");
            setErrors(['An error occurred while saving data.']);
        }
    };

    const handleCancel = () => {
        setSourceSystemDetails({
            sourceSystemName: "",
            sourceSystemType: "",
            dataVolume: "",
            SystemType: "",
            description: "",
            cloudProvider: ""
        });
        setConnectionInfo({
            userName: "",
            host: "",
            url: "",
            warehouse: "",
            database: "",
            password: "",
            fileName: ""
        });
        setErrors([]);
    };

    const isNextEnabled = () => {
        const { sourceSystemName, sourceSystemType, dataVolume, SystemType, cloudProvider } = sourceSystemDetails;
        if (sourceSystemType === "SQL") {
            if (SystemType === "OnPrem") {
                return sourceSystemName && sourceSystemType && dataVolume;
            } else {
                return sourceSystemName && sourceSystemType && dataVolume && SystemType && cloudProvider ;
            }
        } else {
            return sourceSystemName && sourceSystemType && dataVolume  && cloudProvider;
        }
    };

    const handleCancleUpload = async () => {
        setConnectionInfo(prevState => ({ ...prevState, fileName: '' }));
        setEntries([{ fileName: "", sheetData: null, error: [] }])
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file || !file.name.endsWith('.xlsx')) {
            setErrors(['Invalid file type']);
            setConnectionInfo(prevState => ({ ...prevState, fileName: '' }));
            return;
        }

        try {
            const data = await file.arrayBuffer();
            const workbook = read(data, { type: 'array' });
            const sheets = workbook.SheetNames;
            const requiredSheets=[
                'Summary',
                'TableInventory',
                'CurrentStateDataDictionary',
                'SubjectAreas',
              ]
              const missingSheets = requiredSheets.filter(sheet => !workbook.Sheets[sheet]);

            if (!['Summary', 'TableInventory', 'CurrentStateDataDictionary', 'SubjectAreas'].every(sheet => sheets.includes(sheet))) {
                setErrors([`Required sheets missing: ${missingSheets}`]);
                setConnectionInfo(prevState => ({ ...prevState, fileName: '' }));
                return;
            }

            const allData = {
                Summary: XLSXUtils.sheet_to_json(workbook.Sheets['Summary']),
                TableInventory: XLSXUtils.sheet_to_json(workbook.Sheets['TableInventory']),
                CurrentStateDataDictionary: XLSXUtils.sheet_to_json(workbook.Sheets['CurrentStateDataDictionary']),
                SubjectAreas: XLSXUtils.sheet_to_json(workbook.Sheets['SubjectAreas']),
            };

            const validationErrors = validateData(allData);
            if (validationErrors.length > 0) {
                setErrors(validationErrors);
                setConnectionInfo(prevState => ({ ...prevState, fileName: '' }));
                return;
            }

            setEntries(prevEntries => prevEntries.map(entry => ({ ...entry, fileName: file.name, sheetData: allData, error: [] })));
            setConnectionInfo(prevState => ({ ...prevState, fileName: file.name }));
            setErrors([]);
        } catch (err) {
            console.error('Error reading file', err);
            setErrors(['Error reading file']);
            setConnectionInfo(prevState => ({ ...prevState, fileName: '' }));
        }
    };

    const validateData = ({ Summary, TableInventory, CurrentStateDataDictionary, SubjectAreas }) => {
        const errors = [];
        const checkFields = (data, fields, sheetName) => {
            const headers = Object.keys(data[0] || {});
            const requiredFieldNames = fields.map(field => field.name);
            const missingColumns = requiredFieldNames.filter(field => !headers.includes(field));
            if (missingColumns.length) {
                missingColumns.forEach(column => {
                    errors.push(`${sheetName} sheet: Missing required column '${column}'.`);
                });
            }
            return errors;
        };

        const SummaryFields = [
            { name: 'Summary', allowNA: false, allowNull: false },
            { name: 'Count', allowNA: false, allowNull: false },
        ];
        const TableInventoryFields = [
            { name: 'S.No', allowNA: false, allowNull: false },
            { name: 'Source System', allowNA: false, allowNull: false },
            { name: 'Catalog/DB Name', allowNA: false, allowNull: false },
            { name: 'SchemaName', allowNA: false, allowNull: false },
            { name: 'Table Name', allowNA: false, allowNull: false },
            { name: 'Row Count', allowNA: false, allowNull: false },
            { name: 'Volume', allowNA: true, allowNull: true },
            { name: 'InScope', allowNA: true, allowNull: true },
            { name: 'Comments', allowNA: true, allowNull: true },
        ];
        const DataDictionaryviewsFields = [
            { name: 'SourceSystem', allowNA: false, allowNull: false },
            { name: 'Subject Area', allowNA: false, allowNull: false },
            { name: 'DatabaseName', allowNA: false, allowNull: false },
            { name: 'SchemaName', allowNA: false, allowNull: false },
            { name: 'TableName', allowNA: false, allowNull: false },
            { name: 'ColumnName', allowNA: false, allowNull: true },
            { name: 'DataType', allowNA: false, allowNull: false },
            { name: 'Constraints', allowNA: false, allowNull: false },
            { name: 'Description', allowNA: false, allowNull: false },
        ];
        const CurrentStateDataDictionaryFields = [...DataDictionaryviewsFields];
        const SubjectAreasFields = [
            { name: 'SubjectArea', allowNA: false, allowNull: false },
            { name: 'SchemaName', allowNA: false, allowNull: false },
            { name: 'TableName', allowNA: false, allowNull: false },
        ];

        checkFields(Summary, SummaryFields, 'Summary');
        checkFields(TableInventory, TableInventoryFields, 'TableInventory');
        // checkFields(DataDictionaryviews, DataDictionaryviewsFields, 'DataDictionaryviews');
        checkFields(CurrentStateDataDictionary, CurrentStateDataDictionaryFields, 'CurrentStateDataDictionary');
        checkFields(SubjectAreas, SubjectAreasFields, 'SubjectAreas');

        return errors;
    };

    const exportToExcel = () => {
        setIsDownloading(true);
        setTimeout(() => {
            console.log('Template downloaded');
            setIsDownloading(false);
        }, 1000);
    };

   const handleSubmit = async () => {
       try {
        setisDisabled(true)
         const updatedEntries = await Promise.all(
           entries.map(async (entry) => {
             var blobpath=`dwhmigration/${state.orgId}/${state.projectId}/uploads/init/inventory.json`
             const blobUrl = await putJsonToBlob1(entry.sheetData,blobpath);
             return { ...entry, blobUrl };
           })
         );
   
         if (props.onChange) {
           props.onChange();
       }
   
       if (props.submit) {
           props.submit();
       }
         
   
         setEntries(updatedEntries);
   
         console.log('Data submitted successfully');
       } catch (error) {
        setisDisabled(false)
       }
     };

    const isSaveEnabled = () => {
        const { userName, host, url, warehouse, database, password, fileName } = connectionInfo;
        if (validate()) {
            return true
        }else if( userName && host && url && warehouse && database && password && fileName){
            return false
        }else {
            return true
        }
    };

    

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                <div
                                className="modal-backdrop fade show"
                                style={{ display: "block" }}
                            ></div>
                <div
                                                    className="overlay"
                                                    id="pageLoader"
                                                    style={{ display: "none"}}
                                                >
                                                    <div className="position-absolute top-50 start-50  translate-middle">
                                                        <div className="d-flex align-items-center loader-bg">
                                                            <div
                                                                className="spinner-border Loader text-dark align-center"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>
                                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                    <div className="modal fade show d-block" id="ConnectionInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConnectionInfoLabel" aria-hidden="true" style={{ overflow: "scroll" }}>
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content px-2">
                                <div className="modal-header border-0">
                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConnectionInfoLabel">
                                        Data Warehouse Connection Details
                                    </h5>
                                    
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closePopup} />
                                    
                                </div>
                               
                               
                                {showSourceSystem ? (
                                    <div className="modal-body py-0 my-4">
                                         {/* <a href="#" onClick={fetchJsonFromBlob} className="popup-anchor-color custom-tableHeader">Download Template</a> */}
                                            <button
                                                                        type="button"
                                                                        className="btn cust-secondary-btn font-16 font-medium d-flex coop"
                                                                        onClick={fetchJsonFromBlob}
                                                                        style={{ marginLeft: '82%',width:"18%" }}
                                                                    >
 
 
                                                                        <span className="me-2" style={{paddingRight: '3%' }}>
                                                                            <img src="images/download-icon.svg" alt="add-icon" />
                                                                        </span>
                                                                        Download Script
                                                                    </button>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="org-Name" className="form-label text-black font-14 font-medium">Data Warehouse Name
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="org-Name" 
                                                    name="sourceSystemName" 
                                                    value={sourceSystemDetails.sourceSystemName} 
                                                    onChange={handleSourceSystemInputChange} 
                                                    placeholder="Enter Data Warehouse Name" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label className="form-label text-black font-14 font-medium">Data Warehouse Type</label>
                                                <span className="text-red"> *</span>
                                                <div className="dropdown">
                                                    <a className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center" role="button" id="clientBusiness" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}>
                                                        {sourceSystemDetails.sourceSystemType || "Select"}
                                                    </a>
                                                    <ul className="dropdown-menu w-100 cust-drop-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'sourceSystemType', value: 'SQL' } })}>SQL</a></li>
                                                        <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'sourceSystemType', value: 'Snowflake' } })}>Snowflake</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="dataVolume" className="form-label text-black font-14 font-medium">Data Volume</label>
                                                <span className="text-red"> *</span>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="dataVolume" 
                                                    name="dataVolume" 
                                                    value={sourceSystemDetails.dataVolume} 
                                                    onChange={handleSourceSystemInputChange} 
                                                    placeholder="Enter Data Volume" 
                                                />
                                            </div>
                                            {sourceSystemDetails.sourceSystemType === "SQL" && (
                                                <div className="col-md-4 col-sm-12 mb-4">
                                                    <label className="form-label text-black font-14 font-medium">System Type</label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        <a className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center" role="button" id="clientBusiness" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}>
                                                            {sourceSystemDetails.SystemType || "Select"}
                                                        </a>
                                                        <ul className="dropdown-menu w-100 cust-drop-menu" aria-labelledby="dropdownMenuLink">
                                                            <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'SystemType', value: 'Cloud' } })}>Cloud</a></li>
                                                            <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'SystemType', value: 'OnPrem' } })}>OnPrem</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            {sourceSystemDetails.sourceSystemType === "SQL" && sourceSystemDetails.SystemType === "OnPrem" ? null : (
                                                <div className="col-md-4 col-sm-12 mb-4">
                                                    <label className="form-label text-black font-14 font-medium">Cloud Provider</label>
                                                    <span className="text-red"> *</span>
                                                    <div className="dropdown">
                                                        <a className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center" role="button" id="cloudProvider" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}>
                                                            {sourceSystemDetails.cloudProvider || "Select"}
                                                        </a>
                                                        <ul className="dropdown-menu w-100 cust-drop-menu" aria-labelledby="dropdownMenuLink">
                                                            <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'cloudProvider', value: 'AWS' } })}>AWS</a></li>
                                                            <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'cloudProvider', value: 'Azure' } })}>Azure</a></li>
                                                            <li><a className="dropdown-item font-12" onClick={() => handleSourceSystemInputChange({ target: { name: 'cloudProvider', value: 'GCP' } })}>GCP</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-12 col-sm-12 mb-4">
                                                <label htmlFor="description" className="form-label text-black font-14 font-medium">Description</label>
                                                <textarea 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="description" 
                                                    name="description" 
                                                    value={sourceSystemDetails.description} 
                                                    onChange={handleSourceSystemInputChange} 
                                                    placeholder="Optional Description" 
                                                    style={{ height: '100px', resize: 'none' }} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="modal-body py-0 my-4">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="user-Name" className="form-label text-black font-14 font-medium">User Name
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="user-Name" 
                                                    name="userName" 
                                                    value={connectionInfo.userName} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter User Name" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="host" className="form-label text-black font-14 font-medium">Host
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="host" 
                                                    name="host" 
                                                    value={connectionInfo.host} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter Host" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="url" className="form-label text-black font-14 font-medium">URL
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="url" 
                                                    name="url" 
                                                    value={connectionInfo.url} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter URL" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="warehouse" className="form-label text-black font-14 font-medium">Warehouse
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="warehouse" 
                                                    name="warehouse" 
                                                    value={connectionInfo.warehouse} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter Warehouse" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="database" className="form-label text-black font-14 font-medium">Database
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="database" 
                                                    name="database" 
                                                    value={connectionInfo.database} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter Database" 
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-12 mb-4">
                                                <label htmlFor="password" className="form-label text-black font-14 font-medium">Password
                                                    <span className="text-red"> *</span></label>
                                                <input 
                                                    type="password" 
                                                    className="form-control cust-input-sty font-14 font-regular" 
                                                    id="password" 
                                                    name="password" 
                                                    value={connectionInfo.password} 
                                                    onChange={handleConnectionInputChange} 
                                                    placeholder="Enter Password" 
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 mb-3">
                                                    <label className="form-label text-black font-14 font-medium">Upload Inventory Document<span className="text-red"> *</span></label>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="mb-5">
                                                       
                                                                {connectionInfo.fileName? (
                                                                     <button
                                                                     type="button"
                                                                     className="upload-btn-sty shadow-none cursor-pointer text-center"
                                                                   >
                                                            <div className="mt-3 text-center">
                                                                <a className="font-medium font-12 d-block">{connectionInfo.fileName}</a>
                                                                <span className="ms-2" onClick={() => handleCancleUpload()}>
                                          <img
                                            src="images/link-close-icon.svg"
                                            alt="link-close-icon"
                                          />
                                        </span> </div></button>
                                                        ):(<span className="upload-btn-wrapper d-block">
                                                            <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                              <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                              <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                                              <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                                Use this template to add info -{' '}
                                                                <a onClick={fetchTemplateFromBlob} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                Download Template
                                            </a>
                                                              </span>
                                                            </button>
                                                            <input
                                                              type="file"
                                                              name="fileName"
                                                              className="cursor-pointer cust-file"
                                                              onChange={handleFileUpload}
                                                            />
                                                          </span> )}
                                                        
                                                        {/* {errors.length > 0 && (
                                                            <div><span className="text-danger font-12"><img src="images/warning-red-icon.svg" alt="Error" /> <span className="ms-2">{errors.join(', ')}</span></span></div>
                                                        )} */}
                                                         {errors.length > 0 && (
                                  <div className="code-doc-error">
                                    {errors.map((data, errIdx) => (
                                      <span
                                        className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section"
                                        key={errIdx}
                                      >
                                        <img
                                          src="images/warning-red-icon.svg"
                                          alt="Warning icon"
                                        />
                                        <span className="ms-2">{data}</span>
                                      </span>
                                    ))}
                                  </div>
                                )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" data-bs-dismiss="modal" onClick={props.closePopup}>
                                        Cancel
                                    </button>
                                    {showSourceSystem ? (
                                        <button type="button" className="btn cust-primary-btn font-14 font-medium" onClick={handleNext} disabled={!isNextEnabled()}>
                                            <span>Next</span>
                                        </button>
                                    ) : (
                                        <button type="button" className="btn cust-primary-btn font-14 font-medium" onClick={handleSave} disabled={isSaveEnabled() || isDisabled}>
                                            <span>Save</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}