import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { HeaderCom } from '../Header';
import { generateSasTokenAPI } from '../../Service/Api';

export default function CodeReviewChecklistDev(props) {
    const nav = useNavigate();
    const { state } = useLocation();
    const [checklist, setChecklist] = useState([]);
    const [tab, setTab] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        getChecklistPageData();
    }, []);

    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

    const fetchJsonFromBlob = async () => {
        try {
            const sasToken = await generateSasTokenAPI();
            const blobPath = `dwhmigration/${state.orgId}/${state.projectId}/development/${props.migrationType}/codeReviewChecklistDev.json`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            console.log(url, 'this is the url for fetch')
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    const getChecklistPageData = async () => {
        document.getElementById('pageLoader').style.display = "block";
        try {
            const res = await fetchJsonFromBlob();
            console.log(res, "result");

            if (res?.length) {
                setChecklist(res);
                // Set the initial tab to the first notebook name
                setTab(res[0]?.NotebookName || '');
            }
        } catch (error) {
            console.error('Failed to load the checklist data:', error);
        } finally {
            document.getElementById('pageLoader').style.display = "none";
        }
    };

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            nav('/');
        }, 3000);
    };

    const handleTabClick = (selectedTab, index) => {
        setSelectedIndex(index);
        setTab(selectedTab);
    };

    function ExcelDownload(data) {
        const wb = XLSX.utils.book_new();
        const sheetMap = {};

        data.forEach(sheetData => {
            if (!sheetMap[sheetData.NotebookName.slice(0, 30)]) {
                sheetMap[sheetData.NotebookName.slice(0, 30)] = [
                    ['Notebook Name', 'Command Purpose', 'Checklist Step', 'Status']
                ];
            }

            sheetMap[sheetData.NotebookName.slice(0, 30)].push([
                sheetData.NotebookName,
                sheetData.CommandPurpose,
                sheetData.CheckListStep,
                sheetData.Status
            ]);
        });

        Object.keys(sheetMap).forEach(sheetName => {
            const ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        XLSX.writeFile(wb, 'code_review_checklist.xlsx');
    }

    const totalRecords = checklist.filter(data => data.NotebookName === tab)?.length;

    return (
        <div className="container-fluid">
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* Header */}
                <HeaderCom value='1' />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a>Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a>{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Code Review Checklist</li>
                        </ul>
                    </div>
                    <div className="row p-4">
                        <div className="d-xl-flex d-lg-block mb-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 mb-3 ">
                                <a className="d-flex" style={{ 'cursor': 'pointer' }}>
                                    <img src="images/back-arrow.svg" alt="back-arrow" onClick={() => { props.closeGrid() }} />
                                </a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                    Code Review Checklists
                                </h2>
                            </div>

                            <div className="d-lg-flex d-md-block">
                                <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 py-2 me-3 d-flex align-items-center">
                                    <span className="border-end d-flex allign-items-center pe-3">
                                        <img src="images/verified-icon.svg" alt="verified-icon" className="verified-logo-sty me-2" />
                                        <span className=" text-nowrap font-regular">Pass - <span className="font-medium">
                                            {checklist?.filter(item => item.Status === 'Pass')?.length}
                                        </span> </span>
                                    </span>
                                    <span className="border-end d-flex allign-items-center px-3">
                                        <img src="images/not-verified-icon.svg" alt="not-verified-icon" className="verified-logo-sty me-2" />
                                        <span className=" text-nowrap font-regular">Fail - <span className="font-medium">
                                            {checklist?.filter(item => item.Status === 'Fail')?.length}

                                        </span> </span>
                                    </span>
                                    <span className="ps-3">
                                        <span className=" text-nowrap font-regular">Total - <span className="font-medium"></span>
                                            {checklist?.length}</span>
                                    </span>
                                </div>
                                <div className="d-flex mb-md-6 mb-sm-6">
                                    <button
                                        type="button"
                                        className="btn cust-secondary-btn font-16 font-medium "
                                        // data-bs-dismiss="modal"
                                        onClick={() => { ExcelDownload(checklist) }}
                                    >
                                        <span className="me-2">
                                            <img src="images/download-icon.svg" alt="add-icon" />
                                        </span>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* First Accordion starts here */}
                        <div className="rounded-3 mb-4 font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#logger-notebook"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3 gen-accord"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#logger-notebook"
                                        className="cursor-pointer text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="logger-notebook">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’</span>then
                                                on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="code-review-tab-width">
                        <div className="code-review-page checklists tabs" style={{ overflowX: "auto", whiteSpace: "nowrap",scrollbarWidth: "thin" }}>

                                {[...new Set(checklist.map(val => val.NotebookName))].map((notebookName, index) => (
                                    <div
                                        className={notebookName === tab ? "tab font-bold active" : "tab font-bold"}
                                        key={index}
                                    >
                                        <button onClick={() => { handleTabClick(notebookName, index) }} id={index} style={{ border: "none", backgroundColor: "white" }} data-bs-toggle="tooltip" data-bs-placement="top" title={notebookName}>
                                            {notebookName.length > 10 ? notebookName.slice(0, 10) + '...' : notebookName}
                                        </button>
                                    </div>
                                ))}

                            </div>
                            <div id="tab1" className="code-review-tab tab-content active"></div>
                            <div
                                id="tab2"
                                className="code-review-tab tab-content"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                id="tab3"
                                className="code-review-tab tab-content"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                id="tab4"
                                className="code-review-tab tab-content"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                id="tab5"
                                className="code-review-tab tab-content"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                id="tab6"
                                className="code-review-tab tab-content"
                                style={{ display: "none" }}
                            ></div>
                            <div className="table-responsive custom-activity-logs pt-5">
                                <table className="table checklists w-100 table-borderless rounded custom-grid codereview">
                                    <thead className="sticky-top-pos">
                                        <tr>
                                            <th className="text-center">S.No</th>
                                            <th className="text-center">Command Purpose</th>
                                            <th className="text-center">Checklist Step</th>
                                            <th className="text-center">Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {checklist
                                            .filter(data => data.NotebookName === tab)
                                            .map((val, index) => {
                                                const isPass = val.Status === "Pass";
                                                const isFail = val.Status === "Fail" || val.Status === null;

                                                const badgeStyle = isPass
                                                    ? { background: "#DCFFD4", color: "#03A000" }
                                                    : isFail
                                                        ? { background: "#FFE8E1", color: "#AA1400" }
                                                        : {};

                                                return (
                                                    <tr key={index} className={index % 2 !== 0 ? "table-alter-bg" : ""}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{val.CommandPurpose}</td>
                                                        <td>{val.ChecklistStep}</td>
                                                        <td className="text-center">
                                                            <span
                                                                style={badgeStyle}
                                                                className={`rounded-3 py-1 px-2 font-13 font-medium ${isPass ? "approved-badge" : "rejected-badge"}`}
                                                            >
                                                                {val.Status === null ? "Fail" : val.Status}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                <p className="font-regular font-14 text-grey mt-3">
                                    # of Records: <span className="text-black font-medium">
                                        {totalRecords} out of {totalRecords}
                                    </span>
                                </p>
                                <div className="d-flex justify-content-center mt-3 mb-5 pb-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}