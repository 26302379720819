import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Maincomponent from './Component/MainComponent'
import LoginAuthComponent from '../src/Component/LoginAuthComponent'
import ManageOrganizations from './Component/ManageOrganizations';
import ManageProjects from './Component/ManageProjects';
import ProjectInfo from './Component/projectInfo';
import ManageRoles from './Component/ManageRoles';
// import Logger from './Component/LoggerGridPseudocode' 
import './Styles/css/commontheme.css'
import './Styles/css/colors.css'
import './Styles/css/codegen.css'
import './Styles/css/login.css'
import './Styles/css/fonts.css'
import './Styles/css/form.css'
import './Styles/css/grid.css'
import './Styles/css/popup.css'
import './Styles/css/side-nav.css'
import './Styles/css/side-nav.css'
import Chat from './Component/ChatMain';
import Activitylog from './Component/Activitylog';
import 'bootstrap'
import NewOrganisation from './Component/NewOrganisation';
import Requests from '../src/Component/ManageRequest';
import ChatMainUCMigration from './Component/new/chatMainUC';
import JsonHandler from './Component/new/fetchJson';
import BlobHandler from './Component/new/fetchJson';
import AzureBlobStorage from './Component/new/fetchJson';
// import ProjectInfoUC from './Component/new/ProjectInfoUC';
import UnitTestCaseGridDB from './Component/UnitTestCaseGridDB';
import Roles from './Component/new/Roles';
import Groups from './Component/new/Group';
import GroupList from './Component/new/GroupList';
import Script from './Component/new/Script';
import UserDetailsUpload from './Component/new/User';
import Approach from './Component/new/Integrations/ApproachDiagram';
// import AddUsersToGroup from './Component/new/AcessControl/GroupList';
import TableMapping from './Component/new/Integrations/TableMapping';
import AnalyseDocument from './Component/new/Integrations/AnalyseDocument';
import Inventory from './Component/new/Integrations/InventoryDocument';
import ChatMaintableau from './Component/tableauMigration/tableauChatMain'
import ChatMaindwh from './Component/dwhMigration/dwhChatMain';
import ConnectionInfo from './Component/dwhMigration/ingestConnectionPopup';
import PlantUMLPreview from './Component/new/PlantumlView';
import ChatMaintenance from './Component/ChatMain';
import SourceInfoPopup from './Component/dwhMigration/ingestSourcePopup';
import ExploreGrids from './Component/ExploreCardGrid';
import FormAndGridPage from './Component/ExploreFormAndGrid';
import SpreadsheetOverview from './Component/excelGrid';
// import 'bootstrap/js/dist/popover'
// import MetaDataTable from './Component/metaGrid';
function App() {

  const originalConsoleLog = console.log;
  console.log = function (...logValues) {
    if (logValues.some(value => typeof value === 'string' && (value.includes('http://') || value.includes('https://') || value.includes('statee--')))) {
      originalConsoleLog.apply(console, logValues);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/excelgrid" element={<SpreadsheetOverview />} />

        <Route path="/User" element={<UserDetailsUpload />} />
        <Route path="/Roles" element={<Roles />} />
        <Route path="/Group" element={<Groups />} />
        <Route path="/GroupList" element={<GroupList />} />
        <Route path="/Script" element={<Script />} />

        <Route path="/" element={<Maincomponent />} />
        <Route path="/auth/cb" element={<LoginAuthComponent />}></Route>
        <Route path="/projectInfo" element={<ProjectInfo />}></Route>
        <Route exact path='/manageOrganization' element={<ManageOrganizations />}></Route>
        <Route exact path='/manageProjects' element={<ManageProjects />}></Route>
        <Route path='/chat' element={<ChatMaintenance />} />
        <Route path='/uc-chat' element={< ChatMainUCMigration />} />
        <Route path='/tableauChat' element={< ChatMaintableau />} />
        <Route path='/dwhChat' element={< ChatMaindwh />} />

        <Route path='/activityLogs' element={<Activitylog />} />
        <Route path='/newOrg' element={<NewOrganisation />} />
        <Route path='/request' element={<Requests />} > </Route>
        {/* <Route path='/ProjectInfoUC' element={<ProjectInfoUC />} > </Route> */}
        <Route path='/TableMapping' element={<TableMapping />} > </Route>
        <Route path='/AnalyseDocument' element={<AnalyseDocument />} > </Route>
        <Route path='/Inventory' element={<Inventory />} > </Route>

        <Route path='/puml' element={<PlantUMLPreview />} > </Route>
        <Route path='/Approach' element={<Approach />} > </Route>
        <Route path='/ConnectionInfoPopup' element={<SourceInfoPopup />} />
        <Route path='/ConnectionInfo' element={<ConnectionInfo />} />



        <Route path='/manageRoles' element={<ManageRoles />} > </Route>

        <Route path="/usecases" element={<ExploreGrids />} />
        <Route path="/form-grid" element={<FormAndGridPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;