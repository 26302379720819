// PS_SDD_01
// Import all the required packages
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError } from "../Service/Api.js";
import { HeaderCom } from "./Header";

import * as XLSX from 'xlsx';


function DataDictionarySilver(props) {
    // Declare the state variables
    // PS_SDD_01
    let { state } = useLocation();
    const [jsonData, setJsonData] = useState([]);
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'SubjectArea' })
    const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState("");
    const [filterTableName, setFilterTableName] = useState([]);
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);
    const [inputValues, setInputValues] = useState({
        SubjectArea: 'Select',
        // SchemaName: 'Select',
        TableName: '',
        ColumnName: '',
        DataType: '',
        Constraints: 'Select',
        Description: ''
    });


    // const [inputValues, setInputValues] = useState({})
    const [subjectArea, setSubjectArea] = useState([])
    // const [schemaName, setSchemaName] = useState([])
    const [body, setBody] = useState({})
    const [filterCriteria, setFilterCriteria] = useState({
        SubjectArea: 'Select',
        TableName: 'Select',

    });
    const [IsFilteredApplied, setIsFilteredApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    const [constraints, setConstraints] = useState(["NULL", "NOTNULL", "Primary Key", "Foreign Key", "Unique"]);
    let uniqueTableNames = [];
    let uniqueSubjectAreas = []


console.log("stateeee",state)

// PS_SDD_05
    useEffect(() => {
        // PS_SDD_06
        const fetchData = async () => {
            try {
                 
                const dropDownValuesPromise = fetchDropDown();
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [dropDownValues, pseudoData] = await Promise.all([dropDownValuesPromise, pseudoDataPromise]);
                console.log("res from fetchDropDownValues", dropDownValues);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);



// This function is to fetch the dropdown values from the backedAPI called fetchDropdownValeus
// PS_SDD_08 TO 25
    const fetchDropDown = async () => {
        try {
             
            let data = { type: "data dictionary", projectId: state.projectId, userId: state.userId }
            document.getElementById('pageLoader').style.display = 'block';
            let dropDownValues = await fetchDropDownValues(data, state.jwt)
            document.getElementById('pageLoader').style.display = 'none';
            console.log("resfrom fetchDropDownValues", dropDownValues);
            if (dropDownValues.data?.DDuniqueSubjectArea) {
                setSubjectArea(dropDownValues.data.DDuniqueSubjectArea);
            } else {
                console.log("No subject areas found");
            }

            // if (dropDownValues.data?.uniqueSchemaName) {
            //     setSchemaName(dropDownValues.data.uniqueSchemaName);
            // } else {
            //     console.log("No schema names found");
            // }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Datadictionary",
                functionName: "DataDictionaryTable",
                userId: state.userId
            });
            console.log("error", e);
        }
    }
    console.log("uniqueTableNames", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);
    console.log( "setSubjectArea", subjectArea);
// This function is to fetch the pseudcode from the backend API fetchPeusdocodeData API.
    // PS_SDD_26 TO 43
    const fetchPseudo = async () => {
        try {

            let data = { type: "dataDictionarySilver", projectId: state.projectId, userId:state.userId }
            document.getElementById('pageLoader').style.display = 'block';
            let pseudodata = await fetchPseudocodeData(data, state.jwt)
    
            // // console.log(pseudodata);
            // let gukkan =JSON.parse(pseudodata.data[0].dataDictionarySilver)
            // let srimathi=JSON.parse(gukkan)
            // console.log(srimathi,"--------------------------------------------------------------------------------");
            document.getElementById('pageLoader').style.display = 'none';
            if (pseudodata.statusCode == 200) {
                let pseudo = pseudodata.data[0].dataDictionarySilver
                console.log(typeof(pseudo),"00000000000000000000000000000000000000000000000000000000000000000000000000000");
                while (!Array.isArray(pseudo)) {
                    try{
                    pseudo=JSON.parse(pseudo);
                    }
                    catch{
                        console.log("parse error");
                        break;
                    }
                }
                // pseudo=JSON.parse(JSON.parse(pseudo)) 
                console.log(typeof(pseudo));
                // console.log(pseudo,"stringify pseudo")
                // console.log("res from fetchPseudocodeData", pseudo);
                pseudo = pseudo.map((obj, index) => ({
                    ...obj,
                    index: index 
                }));


                setJsonData(pseudo)
                setResponse(pseudo)

                uniqueTableNames = [...new Set((pseudo).map(item => item.TableName).filter(item => item!=null))];;
                uniqueSubjectAreas = [...new Set((pseudo).map(item => item.SubjectArea).filter(item => item!=null))];
                setFilterSubjectArea(uniqueSubjectAreas)
                setFilterTableName(uniqueTableNames)

                console.log("uniqueTableNames", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Datadictionary",
                functionName: "DataDictionaryTable",
                userId: state.userId
            });
            console.log("error", e);
        }
    }

// This function is to apply the filter for the table, if we done any updated on the grid while filter applied the filter should not be removed.
// PS_SDD_44 to 52
    const filterByAppliedFilters = (data) => {
        console.log("filtercriteria", filterCriteria)
       
        const { SubjectArea, TableName } = filterCriteria;
        let SubjectAreaMatch
        let TableNameMatch

        const filteredList = response.filter((record) => {

            SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
            TableNameMatch = TableName === 'Select' || record.TableName.toLowerCase() === TableName.toLowerCase();
            return SubjectAreaMatch && TableNameMatch

        });

        return filteredList
    };


 /**
     * Pseudocode No: PC_SDD_53 TO 64
     * The handleSearch function is designed to facilitate the search feature within a linkedServices. Upon invocation, 
     * it inspects the length of the provided search term. If the term contains at least three characters, the 
     * function proceeds to filter the linkedServices based on whether the operation type or cell name includes the 
     * search term, irrespective of case. The filtered data is then updated accordingly. Conversely, if the 
     * search term is less than three characters or empty, the function simply resets the filtered records to match the original linkedServices.
     */
 const handleSearch = (searchTerm) => {
    try {   
    debugger;
    if (!searchTerm && searchTerm.length <= 3) {
        // If the search term is empty or undefined, reset to all 
        let records = filterByAppliedFilters(null)
        setJsonData(records);
    } else {
        // Filter the based based on search criteria SubjectArea

        const filteredData = jsonData.filter(
            (record) =>
                (record.TableName && record.TableName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                (record.ColumnName && record.ColumnName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                // (record.SchemaName && record.SchemaName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                (record.SubjectArea && record.SubjectArea?.toLowerCase().includes(searchTerm?.toLowerCase()))
        )

        setJsonData(filteredData);
    }
} catch (error) {
        console.log(error);
        
}
};

useEffect(() => {
    handleSearch(searchTerm);
}, [searchTerm]);

// PS_SDD_65 TO 84
// The updatePseudocode function is for made the changes in the grid by using the backendAPI called updatePsuedocodeData.
    const updatePseudocode = async (value) => {
        try {
            // Show loader
            document.getElementById('pageLoader').style.display = 'block';


            // Update pseudocode data
            let data = await updatePseudocodeData({ ...value, userId: state.userId }, state.jwt);
            console.log(data, '1234567890')
            // Hide loader
            document.getElementById('pageLoader').style.display = 'none';

            if (data.statusCode === 200) {
                let pseudo = JSON.parse(data.data.recordset[0].dataDictionarySilver);

                console.log("res from fetchPseudocodeData", pseudo);
                pseudo = pseudo.map((obj, index) => ({
                    ...obj,
                    index: index
                }));
                // Update unique table names and subject areas for filters
                let uniqueTableNames = [...new Set(pseudo.map(item => item.TableName).filter(item => item!=null))];
                let uniqueSubjectAreas = [...new Set(pseudo.map(item => item.SubjectArea).filter(item => item!=null))];
                setFilterSubjectArea(uniqueSubjectAreas);
                setFilterTableName(uniqueTableNames);

                // Handle search and apply filter again
                //    apply filter
                // PS_CD_1.53
                // Set the filtered list to a separate state variable
                setResponse(pseudo)
                const { SubjectArea, TableName } = filterCriteria;


                const filteredList = pseudo.filter((record) => {

                    const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea?.toLowerCase() === SubjectArea?.toLowerCase();
                    const TableNameMatch = TableName === 'Select' || record?.TableName?.toLowerCase() === TableName?.toLowerCase();

                    return SubjectAreaMatch && TableNameMatch
                });

                // applysearch
               // applysearch
               if (searchTerm && searchTerm.length <= 3) {
                // If the search term is empty or undefined, reset to all 
                setJsonData(filteredList);
            } else {
                // Filter the based based on search criteria SubjectArea

                const filteredData = filteredList.filter(
                    (record) =>
                        (record.TableName && record?.TableName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                        (record.ColumnName && record?.ColumnName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                        // (record.SchemaName && record?.SchemaName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                        (record.SubjectArea && record?.SubjectArea?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                )

                setJsonData(filteredData);
            }

                // Reset input values and reason
                setInputValues({
                    SubjectArea: 'Select',
                    // SchemaName: 'Select',
                    TableName: '',
                    ColumnName: '',
                    DataType: '',
                    Constraints: 'Select',
                    Description: ''
                });
                setReason("");
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "Datadictionary",
                functionName: "DataDictionaryTable",
                userId: state.userId
            });
            console.log("Error in update:", error);
        }
    }



    // When user clicks the tick icon the handleTick() function is triggered, The body will be set here to update the edit in the grid
    //PS_SDD_85 TO 86 
    const handleTick = async (item) => {
        console.log("in tick fun", item, "reason", reason);
        // setJsonData(response);

        setBody({
            type: "dataDictionarySilver",
            actionType: "edit",
            index: item.index,
            comments: reason,
            value: item
        })
    }

    // When user cliks the cancel button in the filter. It will change the filters in the initial state.
    // PS_SDD_87 TO 96

    const handleCancel = async () => {
        const { SubjectArea, TableName } = filterCriteria;


                const filteredList = response.filter((record) => {

                    const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea?.toLowerCase() === SubjectArea?.toLowerCase();
                    const TableNameMatch = TableName === 'Select' || record?.TableName?.toLowerCase() === TableName?.toLowerCase();

                    return SubjectAreaMatch && TableNameMatch
                });

                // applysearch
                if (searchTerm && searchTerm.length >= 3) {
                    // If the search term is empty or undefined, reset to all 
                    setJsonData(filteredList);
                } else {
                    // Filter the based based on search criteria SubjectArea

                    const filteredData = filteredList.filter(
                        (record) =>
                            (record.TableName && record?.TableName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                            (record.ColumnName && record?.ColumnName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                            // (record.SchemaName && record?.SchemaName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
                            (record.SubjectArea && record?.SubjectArea?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                    )

                    setJsonData(filteredData);
                }
console.log(jsonData,"jssssoooonnnnDatttaaa")
        // setJsonData(response);
        setEditingIndex(null);
        setInputValues({
            SubjectArea: 'Select',
            // SchemaName: 'Select',
            TableName: '',
            ColumnName: '',
            DataType: '',
            Constraints: 'Select',
            Description: ''
        })
        setReason("")
    }

    // handleReason function is trigerred when the characters is typed in the reason popup
    // PS_SDD_97 TO 99
    const handleReason = async (e) => {
        setReason(e.target.value)
    }
    

   
    // The function handleSaveDetails() to save the details in the database
     // PS_SDD_100 TO 111
    let apiBody
    const handleSaveDetails = async () => {
        setwhetherUpdated(true)
        setEditingIndex(null)

        if (!reason || !body) {
            // If reason or body is not filled, return without performing further operations
            return;
        }
        apiBody = { ...body, comments: reason, projectId: state.projectId }

        document.getElementById('pageLoader').style.display = 'block';
        await updatePseudocode(apiBody)
        document.getElementById('pageLoader').style.display = 'none';


        setInputValues({
            SubjectArea: 'Select',
            // SchemaName: 'Select',
            TableName: '',
            ColumnName: '',
            DataType: '',
            Constraints: 'Select',
            Description: ''
        })
        setReason("")
        // invoke api call 
    }

// When user clicks the download button  the grid will be downloaded in excel format.
// PS_SDD_112 TO 123
    const handleDownload = async (response1) => {
         
        let res1 = response1
        let response = res1.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });


        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(response);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate Excel file and download
        XLSX.writeFile(wb, 'DataDictionarySilver.xlsx');
    }

    const handleClearFilter = () => {

        // Reset filter criteria to their initial values
        setFilterCriteria({
            SubjectArea: 'Select',
            TableName: 'Select',

        });
        // Reset filtered projectList to all projectList
        setJsonData(response);
    };


    // When user clicks the apply button in the filter popup, the handleApplyFilter() function is trigerred.
    //  PS_SDD_129 TO 137
    const handleApplyFilter = async () => {
         
        console.log("filtercriteria", filterCriteria)
        {/* // PS_CD_1.51 */ }
        const { SubjectArea, TableName } = filterCriteria;


        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record?.SubjectArea?.toString()?.toLowerCase() === SubjectArea?.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record?.TableName?.toString()?.toLowerCase() === TableName?.toLowerCase();

            return SubjectAreaMatch && TableNameMatch
        });
        // Set the filtered projectList to a separate state variable

        setJsonData(filteredList);
        setIsFilteredApplied(() => true)
        setShowFilter(false)
    };
    // When user clicks the hyperlink of the dataDictionary the grid will be visible to the user. 
    // PS_SDD_129 TO 137
    const bindgrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in binf funccccccccccccccccccccccccccccccccccccccccccccccccccc", jsonData[0]);
            // Check if jsonData is an array and has elements
            if (jsonData.length > 0) {
                console.log("in binf func no:", jsonData.length);
              

                for (let i = 0; i < jsonData.length; i++) {
                    console.log(jsonData[i],"json data for loop");
                }

                return (
                    <>
                        {jsonData?.map((item, index) => (

                            (editingIndex == index) ? (
                                <tr key={index}>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {subjectArea.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    {/* <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.SchemaName}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {schemaName.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("SchemaName", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td> */}

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="TableName" value={inputValues?.TableName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="ColumnName" value={inputValues?.ColumnName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="DataType" value={inputValues?.DataType} onChange={handleInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.Constraints}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {constraints.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("Constraints", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="Description" value={inputValues?.Description} onChange={handleInputChange} /></td>
                                    <td>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 ${isFormFilled() ? '' : 'disabled'}`}
                                            onClick={() => isFormFilled() && handleTick(inputValues)} // Only call handleAdd if form is filled
                                            {...(isFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}
                                            disabled={isFormFilled()}


                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        />
                                    </td>
                                </tr>) : (

                                <tr key={index}>

                                    <td>{item?.SubjectArea || "NA"}</td>
                                    {/* <td>{item?.SchemaName || "NA"}</td> */}
                                    <td>{item?.TableName || "NA"}</td>
                                    <td>{item?.ColumnName || "NA"}</td>
                                    <td>{item?.DataType || "NA"}</td>
                                    <td>{item?.Constraints || "NA"}</td>
                                    <td>{item?.Description || "NA"}</td>

                                    <td className="text-center">
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            // data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer"
                                            title="Delete"
                                            // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        // disabled={!reason}
                                        />

                                    </td>
                                </tr>
                            )))}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "Datadictionary",
                functionName: "DataDictionaryTable",
                userId: state.userId
            });
            console.error("Error in bindgrid:", error);
            return null;
        }
    };


    /** // PS_SDD_165 TO 168
     * The function `handleEditClick` sets the selected item and updates the `isEditMetavalue` state.
     */
    const handleEditClick = (index, item) => {
         
        console.log("edit item", item);
        setEditingIndex(index)
        // setSelectedItem(item);
        setInputValues({ ...item }); // Update inputValues with the values from the clicked item
        setIsEditingData(true)
        // setisEditMetavalue(false);
    };

    // 'When user clicks the delete icon in the action column the handleDelete() function is triggered.
    // PS_SDD_169 TO 171
    const handleDelete = (index, item) => {
        console.log("delete itmemmmm item", item);
        setBody({
            type: "dataDictionarySilver",
            actionType: "delete",
            index: item.index,
            comments: reason
        })

    };


// 'when user enters the input in the grid rows. the handleInputChange() function is triggered
// PS_SDD_172 TO 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // When user clicks the dropdown field in the grid, the handleDropDownChange() function is triggered
    // PS_SDD_174 TO 175
    const handleDropDownChange = (key, value) => {
        setInputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }

// When user enters the fields to add the new row in the grid, the isFormFilled() function is triggered.
// PS_SDD_176 TO 180
    const isFormFilled = () => {
         
        console.log("input vaaluess in forfilled", inputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(inputValues); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };


// When user clicks the add icon in the action column, the handleAdd() function is triggered.
// PS_SDD_176 TO 180
    const handleAdd = (value) => {
         

        console.log("input vaaluess in handleAdd", inputValues);
        console.log("add itmemmmm item", value);
        setBody({
            type: "dataDictionarySilver",
            actionType: "add",
            comments: reason,
            value: inputValues
        })

    };


    // PS_SDD_138 to 145
     // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
    const handleSort = (columnName) => {
         
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'desc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = jsonData.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName]?.localeCompare(b[columnName]);
            } else {
                return b[columnName]?.localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setJsonData(sortedRecords);
    };


    //PS_SDD_151 TO 157 
    {/*Defines a function "handlecancelSearch()" .Updates the state variable search to an empty string
Destructures SubjectArea and TableName from filterCriteria.Filters the response array based on SubjectArea and TableName from filteredList.Checks conditions for SubjectArea and TableName matches
Returns the filteredList. Updates the state variable jsonData with the filteredList */}
    const handlecancelSearch = () => {
        setSearchTerm("");
        const { SubjectArea, TableName } = filterCriteria;

        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record.TableName.toLowerCase() === TableName.toLowerCase();

            return SubjectAreaMatch && TableNameMatch
        });
        // const filteredData = response.filter(filterByAppliedFilters);
        setJsonData(() => filteredList);
    };

    return (
        <div className="container-fluid">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">

                <HeaderCom value="1" />

                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Silver Data Dictionary</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px',cursor:"pointer",whiteSpace:"nowrap" }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">Silver Data Dictionary</h2>
                        </div>
                        <div className="d-flex">
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    placeholder="Search" onChange={(e) => { setSearchTerm(e.target.value); handleSearch() }}
                                    value={searchTerm}
                                />
                                <span className="search-icon-sty "  >
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon"onClick={()=>handlecancelSearch()} />
                                    </a>
                                </span>
                            </div>
                            <div className="btn-group me-3">
                                <button
                                    className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon"
                                    />
                                </button>
                                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="TableName"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Table Name
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="TableName"
                                                        aria-label="Default select example"
                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, TableName: e.target.value })}
                                                        value={filterCriteria.TableName}
                                                    >
                                                        <option selected="">Select</option>
                                                        {filterTableName.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SubjectArea"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Subject Area
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SubjectArea"
                                                        aria-label="Default select example"
                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}
                                                        value={filterCriteria.SubjectArea}
                                                    >

                                                        <option selected="">Select</option>
                                                        {filterSubjectArea.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={()=>handleClearFilter()}>
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={handleApplyFilter}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => handleDownload(response)}
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium "
                                // data-bs-dismiss="modal"
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                    </div>

                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button
                                    className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#view-instruction"
                                >
                                    <img
                                        src="images/down-acc-arow.svg"
                                        alt="arrow"
                                        className="me-3 gen-accord"
                                    />
                                    <span
                                        data-bs-toggle="collapse"
                                        data-bs-target="#view-instruction"
                                        className="cursor-pointer pt-1 text-black font-16 font-medium"
                                    >
                                        View Instructions
                                    </span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">
                                                Please validate whether the data’s are correct
                                            </li>
                                            <li className="mb-2">
                                                If any changes are required update them accordingly
                                                using{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Actions’
                                                </span>{" "}
                                                on the last column
                                            </li>
                                            <li className="mb-2">
                                                If things are fine just click{" "}
                                                <span className="text-black-v2 font-bold">‘back’ </span>
                                                then on{" "}
                                                <span className="text-black-v2 font-bold">
                                                    ‘Looks good’
                                                </span>{" "}
                                                and proceed to next steps{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th>SubjectArea  <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" ? sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                {/* <th>SchemaName <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SchemaName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SchemaName')} /></span></th> */}
                                                <th>TableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TableName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TableName')} /></span></th>
                                                <th>ColumnName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ColumnName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ColumnName')} /></span></th>
                                                <th>DataType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DataType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DataType')} /></span></th>
                                                <th>Constraints<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Constraints" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Constraints')} /></span></th>
                                                <th>Description<span className="ms-2 cursor-pointer"></span></th>

                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editingIndex != null ? (
                                                <></>
                                            ) : (
                                                <tr>
                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="SubjectArea"
                                                        value={inputValues.SubjectArea}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {subjectArea?.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select></td>

                                                    {/* <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="SchemaName"
                                                        value={inputValues.SchemaName}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select</option>
                                                            <option key={"silver"} value={"silver"}>{"silver"}</option>
                                                    </select></td> */}

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="TableName"
                                                        value={inputValues.TableName}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="ColumnName"
                                                        value={inputValues.ColumnName}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="DataType"
                                                        value={inputValues.DataType}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="Constraints"
                                                        value={inputValues.Constraints}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {constraints.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="Description"
                                                        value={inputValues.Description}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                        <td>
                                                            <img
                                                                src="images/add-icons.svg"
                                                                alt="add-icon"
                                                                title="Add"
                                                                className={`cust-cursor-pointer ${isFormFilled() ? '' : 'disabled'}`}
                                                                {...(isFormFilled() ? {
                                                                    "data-bs-toggle": "modal",
                                                                    "data-bs-target": "#editRows"
                                                                } : {})}
                                                                onClick={(e) => {
                                                                    if (isFormFilled()) {
                                                                        handleAdd(inputValues);
                                                                    } else {
                                                                        e.currentTarget.style.opacity = '0.3';
                                                                        e.currentTarget.style.cursor = 'not-allowed';
                                                                    }
                                                                }}
                                                                style={{
                                                                    opacity: isFormFilled() ? 1 : 0.3,
                                                                    cursor: isFormFilled() ? 'pointer' : 'not-allowed'
                                                                }}
                                                            />
                                                        </td>
                                                </tr>

                                            )
                                            }


                                            {bindgrid()}


                                        </tbody>
                                    </table>




                                    {/*Metadata Table ends here*/}

                                    {/* model */}
                                    <div
                                        className="modal fade "
                                        id="editRows"
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabIndex={-1}
                                        aria-labelledby="ConfrmLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5
                                                        className="modal-title d-flex align-items-center font-medium font-20"
                                                        id="ConfrmLabel"
                                                    >
                                                        Edit Reason
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label
                                                                htmlFor="User-Name"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Specify reason for manual edit
                                                                <span className="text-red"> *</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control font-14 font-regular "
                                                                id="User-Name"
                                                                placeholder="Enter reason"
                                                                rows={5}
                                                                defaultValue={null}
                                                                value={reason}
                                                                onChange={handleReason}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-2"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setReason("")}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cust-primary-btn font-14 font-medium"
                                                        onClick={reason && handleSaveDetails}
                                                        data-bs-dismiss="modal"
                                                        disabled={!reason}

                                                    >
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <p className="font-regular font-14 text-grey">
                                    # of Records:
                                    <span className="text-black font-medium">{jsonData.length} out of {response.length}</span>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataDictionarySilver;