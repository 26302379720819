import axios from 'axios';
import { encode, decode } from 'base-64';
import { insertError } from './Api';

export function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

/*
*PS_CD_1.117 - 128
*PS_CI_09,18,11,20 , PC_AD_22,20 , PC_NB_37 - 40 , PC_DDL_23 - 25 , PC_ME_26 - 28 
PS_CD_1.103-PS_CD_1.104
Client() method called with several parameters, including endpoint, requestType, body, and customConfig. Here's a breakdown of its functionality:
endpoint: The URL where the HTTP request is to be sent.
requestType: The HTTP method to be used (GET, POST, PUT, etc.).
body: The data to be sent with the request, typically in JSON format.
customConfig: Additional configuration options for the Axios request, including headers.
The function starts by setting default headers with 'Content-Type' as 'application/json; charset=UTF-8' and then merges them with any headers provided in customConfig.
If a body is provided, it is first converted to a JSON string and then encoded with Base64 using a series of random strings and additional encoding operations. The resulting encoded data is set as the data property in the request configuration.
The Axios library is used to make the HTTP request to the specified endpoint with the prepared requestConfig. If the request is successful, the response is processed.
If encrypted status is not 200, insert error api is called
*/

export async function Client(
  endpoint,
  { requestType, body, ...customConfig } = {},

) {
  //debugger
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8'
  };
  const requestConfig = {
    method: requestType,

    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };
  console.log(body,"Thisssss is my client fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee ")

  if (body) {
    let encodeBody = JSON.stringify(body)
    encodeBody = encode(random(20) + encode(random(12) + encode(encodeBody) + random(15)) + random(9))
    requestConfig.data = (encodeBody);
  }
// console.log(body,"Thisssss is my client fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")

  const url = `${endpoint}`;
  console.log(requestConfig)
  try {
    var apiResponse = await axios(url, requestConfig);
    // debugger;

    // Attempt to decrypt the response data
    let encryptedData;
    try {
        encryptedData = decode(apiResponse.data);
        encryptedData = decode(encryptedData.substring(20, encryptedData.length - 9));
        encryptedData = JSON.parse(decode(encryptedData.substring(12, encryptedData.length - 15)));
    } catch (decryptionError) {
        console.error("Decryption error:", decryptionError);
        
        // Insert error handling logic for decryption failure
        insertError({
            "errorMessage": decryptionError.message || "Decryption failed",
            "serviceName": "backend",
            "module": "Client.js",
            "functionName": url,
            "userId": ''
        });

        // Return the apiResponse in case of decryption failure
        return apiResponse; // Return the original API response
    }

    console.log(apiResponse, "Dattatatta");

    if (apiResponse.status !== 200) {
        insertError({
            "errorMessage": apiResponse?.data || "Something went wrong",
            "serviceName": "backend",
            "module": "Client.js",
            "functionName": url,
            "userId": ''
        });
    }

    console.log(url, body, encryptedData, "Final data:");

    // Return the appropriate response based on the existence of encryptedData
    if (encryptedData === undefined) {
        return apiResponse; // Return the original API response if encryptedData is undefined
    } else {
        return encryptedData; // Return the decrypted data if it exists
    }
}  catch (error) {
  console.log(error, "If error is there");
  if(error?.status === 403 || error?.statusCode === 403){
    return { status: 403, statusCode: 403 }; // Return a status indicating an error occurred
  } else {
    return { status: 400 }; // Return a status indicating an error occurred
  }
}

}



export async function Clientbi(
  endpoint,
  { requestType, body, ...customConfig } = {},

) {
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8'
  };
  const requestConfig = {
    method: requestType,

    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };
  console.log(body, "This is my client file");

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  const url = `${endpoint}`;
  try {
    const apiResponse = await axios(url, requestConfig);

    console.log(apiResponse, "Dattatatta");

    if (apiResponse.status !== 200) {
      insertError({
        "errorMessage": apiResponse?.data || "Something went wrong",
        "serviceName": "backend",
        "module": "Client.js",
        "functionName": url,
        "userId": ''
      });
    }

    console.log(url, apiResponse.data, "Final data:");

    return apiResponse.data; // Return the response data directly
  } catch (error) {
    console.log(error, "If error is there");
    return { status: 400 }; // Return a status indicating an error occurred
  }
}

