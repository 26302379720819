import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import Editor from '@monaco-editor/react';
import axios from 'axios'
import { HeaderCom } from './Header';


function UnitTestCaseSnowFlakes(props) {

    // const nav = useNavigate();
    const { state } = useLocation();
    // let state = {
    //     "projectId": "4438CFB1-4357-4159-A0A2-ADF543561A47",
    //     "orgId": "7DB5BA95-93BB-443D-925D-9B6C0623B2F5",
    //     "userId": "AC70C3D8-936F-4C02-A692-649C46DA8354",
    //     jwt: ""
    // }

    const [setVal, setSetVal] = useState([]);
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        CellName: "",
        DesignID: "",
        ExternalReviewComments: "",
        Flow: "",
        InternalReviewComments: "",
        StoreProcedureName: "",
        OperationDescription: "",
        OperationsType: "",
        RequirementID: "",
        Steps: ""
    })
    const [action, setAction] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'StoredProcedureName' })

    useEffect(() => {
        fetchPseudo()
    }, [])

    async function fetchPseudo() {
        //  
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "unitTestPseudo",
            userId: state.userId,
            orgId: state.orgId,
        }

        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(res.data[0].unitTestCasePseudocode, "fetchRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        }
        else {
            console.log("The Response Is 200")
            let data = JSON.parse(res.data[0].unitTestCasePseudocode)
            // let arr = []
            // for (let a of data) {
            //     arr = [...arr, ...a]
            // }
            setSetVal(data);
            setFilteredRecord(data)
            console.log(JSON.parse(res.data[0].unitTestCasePseudocode), "THIS IS THE VALUE OF UNITTESTCASE")
        }
    };

    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            // nav('/');
        }, 3000);
    };

    console.log(newData, "THIS IS NEW DAATAA")

    const bindGrid = () => {
        // console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='StoredProcedureName' value={newData?.StoreProcedureName} onChange={(e) => setNewData({ ...newData, StoreProcedureName: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID} onChange={(e) => setNewData({ ...newData, RequirementID: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, DesignID: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.CellName} onChange={(e) => setNewData({ ...newData, CellName: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Steps' value={newData?.Steps} onChange={(e) => setNewData({ ...newData, Steps: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Flow' value={newData?.Flow} onChange={(e) => setNewData({ ...newData, Flow: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationsType' value={newData?.OperationsType} onChange={(e) => setNewData({ ...newData, OperationsType: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationDescription' value={newData?.OperationDescription} onChange={(e) => setNewData({ ...newData, OperationDescription: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='InternalReviewComments' value={newData?.InternalReviewComments} onChange={(e) => setNewData({ ...newData, InternalReviewComments: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ExternalReviewComments' value={newData?.ExternalReviewComments} onChange={(e) => setNewData({ ...newData, ExternalReviewComments: e.target.value })} /></td>
                                <td class="text-center">
                                    <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            CellName: "",
                                            DesignID: "",
                                            ExternalReviewComments: "",
                                            Flow: "",
                                            InternalReviewComments: "",
                                            StoreProcedureName: "Logger",
                                            OperationDescription: "",
                                            OperationsType: "",
                                            RequirementID: "",
                                            Steps: ""
                                        })
                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{item.StoredProcedureName || "NA"}</td>
                                <td>{item.RequirementID || "NA"}</td>
                                <td>{item.DesignID || "NA"}</td>
                                <td>{item.CellName || "NA"}</td>
                                <td>{item.Steps || "NA"}</td>
                                <td>{item.Flow || "NA"}</td>
                                <td>{item.OperationsType || "NA"}</td>
                                <td>{item.OperationDescription || "NA"}</td>
                                <td>{item.InternalReviewComments || "NA"}</td>
                                <td>{item.ExternalReviewComments || "NA"}</td>
                                <td className="text-center">
                                    <td class="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData(filteredRecord[index]) }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    const handleSearch = (term) => {
         
        if (term.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.OperationsType.toLowerCase().includes(term.toLowerCase()) ||
                item.CellName.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };

    const handleSort = (columnName) => {
        // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = filteredRecord.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName].localeCompare(b[columnName]);
            } else {
                return b[columnName].localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setFilteredRecord(sortedRecords);
    };

    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "delete",
            "comments": reason,
            "index": deleteRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                StoredProcedureName: "",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setwhetherUpdated(true)
        }
    };

    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": newData,
            "index": editableRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                StoredProcedureName: "unitTestCase",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].unitTestCasePseudocode))
            setwhetherUpdated(true)
        }
    };







    const handleInputChange = (e, index, columnName) => {
        // When user types anything in the input field for filtering
        // The function handleInputChange() is triggered.

        // Get the newValue from event object e
        const newValue = e.target.value;

        // Update stateVariable at given index and columnName
        const updatedFilteredRecords = [...filteredRecord];
        updatedFilteredRecords[index][columnName] = newValue;

        // Store the newvalue in a state variable "data" by using the function setData
        setFilteredRecord(updatedFilteredRecords);
    };


    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "unitTestPseudo",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "add",
            "comments": reason,
            "value": newData
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                CellName: "",
                DesignID: "",
                ExternalReviewComments: "",
                Flow: "",
                InternalReviewComments: "",
                StoredProcedureName: "",
                OperationDescription: "",
                OperationsType: "",
                RequirementID: "",
                Steps: ""
            })
            setReason("")
            setwhetherUpdated(true)
        }
    };

    const handleDownload = () => {
        let UtilitiesArray = setVal.map(data => { return { StoreProcedureName: data.StoredProcedureName, RequirementID: data.RequirementID, DesignID: data.DesignID, CellName: data.CellName, Steps: data.Steps, Flow: data.Flow, OperationsType: data.OperationType, OperationDescription: data.OperationDescription, InternalReviewComments: data.InternalReviewComments, ExternalReviewComments: data.ExternalReviewComments } });
        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert UtilitiesArray to sheet
        const ws = XLSX.utils.json_to_sheet(UtilitiesArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'UnitTesting');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'UnitTesting' + fileExtension);

        // The saved file will be shown to the user
    };



    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
             <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a>{state.projectName}</a></li>
                            <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow"  style={{ width: '24px', height: '24px' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4"></div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">StoreProcedureName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "StoredProcedureName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('StoredProcedureName')} /></span></th>
                                                <th className="text-nowrap">RequirementID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "RequirementID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('RequirementID')} /></span></th>
                                                <th className="text-nowrap">DesignID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DesignID" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DesignID')} /></span></th>
                                                <th className="text-nowrap">CellName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "CellName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('CellName')} /></span></th>
                                                <th className="text-nowrap">Steps<span className="ms-2 cursor-pointer"><img style={{marginRight :'40px'}} src={sortOrder.column == "Steps" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Steps')} /></span></th>
                                                <th className="text-nowrap">Flow<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Flow" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Flow')} /></span></th>
                                                <th className="text-nowrap">OperationsType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "OperationsType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('OperationsType')} /></span></th>
                                                <th className="text-nowrap">OperationDescription<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "OperationDescription" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('OperationDescription')} /></span></th>
                                                <th className="text-nowrap">InternalReviewComments<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "InternalReviewComments" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('InternalReviewComments')} /></span></th>
                                                <th className="text-nowrap">ExternalReviewComments<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ExternalReviewComments" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ExternalReviewComments')} /></span></th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='StoredProcedureName' value={newData?.StoreProcedureName} onChange={(e) => setNewData({ ...newData, StoreProcedureName: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID} onChange={(e) => setNewData({ ...newData, RequirementID: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, DesignID: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='CellName' value={newData?.CellName} onChange={(e) => setNewData({ ...newData, CellName: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Steps' value={newData?.Steps} onChange={(e) => setNewData({ ...newData, Steps: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Flow' value={newData?.Flow} onChange={(e) => setNewData({ ...newData, Flow: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationsType' value={newData?.OperationsType} onChange={(e) => setNewData({ ...newData, OperationsType: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='OperationDescription' value={newData?.OperationDescription} onChange={(e) => setNewData({ ...newData, OperationDescription: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='InternalReviewComments' value={newData?.InternalReviewComments} onChange={(e) => setNewData({ ...newData, InternalReviewComments: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ExternalReviewComments' value={newData?.ExternalReviewComments} onChange={(e) => setNewData({ ...newData, ExternalReviewComments: e.target.value })} /></td>
                                                <td class="text-center">
                                                    <img src="images/add-icons.svg" alt="add-icon" class="cust-cursor-pointer" title="Add" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("add"); document.getElementById('openReasson').click() } }} />
                                                </td>
                                            </tr>
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredRecord.length}</span></p>
                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitTestCaseSnowFlakes;