import axios from 'axios';
import { encode, decode } from 'base-64';
import { insertError } from './Api';


export async function ExcelClient(endpoint, { requestType, body, ...customConfig } = {}) {
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8'
  };
  
  const requestConfig = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    // Stringify the body and encode it
    const encodedBody = encodeURIComponent(JSON.stringify(body));
    requestConfig.data = encodedBody;
  }

  const url = `${endpoint}`;
  
  try {
    const apiResponse = await axios(url, requestConfig);
    console.log(apiResponse,"i am in client fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")

    if (apiResponse?.status !== 200) {
      insertError({
        "errorMessage": apiResponse?.data || "something went wrong",
        "serviceName": "backend",
        "module": "Client.js",
        "functionName": url,
        "userId": ''
      });
    }
    
    // Decode the response data if needed
    // const decodedData = JSON.parse(decodeURIComponent(apiResponse.data));
    console.log(apiResponse,"yeahhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    return apiResponse;
  } catch (error) {
    console.error(error,"herrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
    return { status: 400 };
  }
}