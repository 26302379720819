
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api.js'; // Adjusted import path
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import Editor from '@monaco-editor/react';
import axios from 'axios';

function DatasetPseudo(props) {
    const nav = useNavigate();
    const { state } = useLocation();

    // Declare the state variables
    const [setVal, setSetVal] = useState([]);
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        DesignID: "",
        Entities: "",
        ComponentName: "",
        Description: "",
        ComponentType: "",
        Configuration: "",
        Parameters: ""
    });

    const [action, setAction] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' });

    useEffect(() => {
        fetchPseudo();
    }, []);

    async function fetchPseudo() {
        const body = {
            projectId: state.projectId,
            type: "dataset",
            userId: state.userId,
            orgId: state.orgId,
            notebookId: props.data.notebookId
        };
        document.getElementById('pageLoader').style.display = "block"
        const res = await fetchPseudocodeData(body, state.jwt);
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode === 403) {
            sessionExpired();
        } else if (res.statusCode !== 200) {
            document.getElementById('openErrorModal').click();
        } else {
            const data = JSON.parse(res.data[0].pseudocode);
            setSetVal(data);
            setFilteredRecord(data);
        }
        
    }

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            nav('/');
        }, 3000);
    };

    const bindGrid = () => {
        try {
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                 
                return (
                    <>
                        {filteredRecord.map((item, index) => {
                            
                            return editableRow === index ? (
                                <tr key={index}>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Entities' value={newData.Entities} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentName' value={newData.ComponentName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Description' value={newData.Description} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData.ComponentType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><input type="text" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Configuration' value={newData.Configuration} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td><textarea className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Parameters' value={newData.Parameters} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                    <td className="text-center">
                                        <span className="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click(); } }}><img src="images/right.svg" className="me-3" width="15" height="15" /></span>
                                        <span className="cust-cursor-pointer" onClick={() => {
                                            setEditableRow("empty");
                                            setNewData({
                                                DesignID: "",
                                                Entities: "",
                                                ComponentName: "",
                                                Description: "",
                                                ComponentType: "",
                                                Configuration: "",
                                                Parameters: ""
                                            });
                                        }}><img src="images/wrong.svg" width="20" height="20" /></span>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index}>
                                    <td>{item.DesignID || ""}</td>
                                    <td>{item.Entities || "NA"}</td>
                                    <td>{item.ComponentName || "NA"}</td>
                                    <td>{item.Description || "NA"}</td>
                                    <td>{JSON.stringify(item.Configuration) || "NA"}</td>
                                    <td>{item.ComponentType || "NA"}</td>
                                    <td>{JSON.stringify(item.Parameters) || "NA"}</td>
                                    <td className="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" className="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData({ ...item, Configuration: JSON.stringify(item.Configuration), Parameters: JSON.stringify(item.Parameters) }); }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" className="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete'); }} />
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

      /**
     * Pseudocode No: PC_DS_41 TO 52
     * The handleSearch function is designed to facilitate the search feature within a dataset. Upon invocation, 
     * it inspects the length of the provided search term. If the term contains at least three characters, the 
     * function proceeds to filter the dataset based on whether the operation type or cell name includes the 
     * search term, irrespective of case. The filtered data is then updated accordingly. Conversely, if the 
     * search term is less than three characters or empty, the function simply resets the filtered records to match the original dataset.
     */

    const handleSearch = (term) => {
         
        if (term.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.Entities.toLowerCase().includes(term.toLowerCase()) ||
                item.ComponentName.toLowerCase().includes(term.toLowerCase()) ||
                item.ComponentType.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };


  /**
     * Pseudocode No:PC_DS_53 TO 63
     * The handleDownload function prepares and initiates the download of dataset information in Excel format. 
     * It first extracts specific attributes from the dataset, constructs an Excel sheet, and converts it into 
     * an Excel buffer. This buffer is then saved as an Excel file using FileSaver.js, enabling the user to 
     * download the file directly.
     */
    const handleDownload = () => {
        let LSArray = setVal.map(data => { 
            return { 
                DesignID: data.DesignId || "",
                Entities: data.Entities || "",
                ComponentName: data.ComponentName || "",
                Description: data.Description || "",
                ComponentType: data.ComponentType || "",
                Configuration: data.Configuration || "",
                Parameters: data.Parameters || ""
            }});
        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert UtilitiesArray to sheet
        const ws = XLSX.utils.json_to_sheet(LSArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'DatasetPseudo');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'DatasetPseudo' + fileExtension);

        // The saved file will be shown to the user
    };
  /**
     * Pseudocode No:PC_DS_64 TO 72
     * The handleSort function facilitates sorting functionality for table columns. When triggered with a column name parameter, 
     * it first checks if the clicked column is the same as the previously sorted one. If so, it toggles the sorting order;
     * otherwise, it sets the order to ascending.
     * Next, it updates the sorting order state (sortOrder) with the new column and order. Then, it creates a copy of 
     * the filtered records and sorts them based on the selected column and order using the localeCompare method for
     * string comparison. Finally, it updates the state of filtered records with the sorted list.
     */

    const handleSort = (columnName) => {
        // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = filteredRecord.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName].localeCompare(b[columnName]);
            } else {
                return b[columnName].localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setFilteredRecord(sortedRecords);
    };

  /**
     * Pseudocode No: PC_DS_98 TO 109
     * The handleDelete function is responsible for deleting a record from the dataset. Upon invocation, it first displays a loading 
     * spinner to indicate ongoing processing. It then constructs a request body containing necessary data for the deletion operation, 
     * such as project ID, user ID, and action type.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token 
     * for authentication. Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), 
     * it triggers a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. 
     * Otherwise, upon successful deletion, it resets the data for the deleted record, clears the reason for deletion, and updates the dataset with the new data.
     */
  const handleDelete = async () => {
    console.log("enters handleDelete")
    document.getElementById('pageLoader').style.display = "block"
    // Call the fetchPseudocodeData() function in API.js
    const body = {
        projectId: state.projectId,
        type: "dataset",
        userId: state.userId,
        orgId: state.orgId,
        actionType: "delete",
        comments: reason,
        value: newData,
        index: editableRow,
        notebookId: props.data.notebookId
    }

    const res = await updatePseudocodeData(body, state.jwt);

    console.log(res, "updateRess");
    document.getElementById('pageLoader').style.display = "none"
    if (res.statusCode == 403) {
        sessionExpired();
    } else if (res.statusCode != 200) {
        document.getElementById('openErrorModal').click();
    } else {
        setNewData({
            DesignID: "",
            Entities: "",
            ComponentName: "",
            Description: "",
            ComponentType: "",
            Configuration: "",
            Parameters: ""
        })
        setReason("")
        setEditableRow("empty")
        setSetVal(JSON.parse(res.data.recordset[0].pseudocode))
        setFilteredRecord(JSON.parse(res.data.recordset[0].pseudocode))
        setwhetherUpdated(true)
        // console.log("resssss", setSetVal, "in handle edit")

    }
}; /**
     * Pseudocode No:PC_DS_73 TO 85
     * The handleEdit function manages the editing process for a record in the dataset. Upon invocation, it displays a loading spinner to indicate 
     * ongoing processing. It then constructs a request body containing necessary data for the edit operation, such as project ID, user ID, action type, 
     * comments, updated data (newData), and the index of the editable row.
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. 
     * Upon receiving a response, it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers 
     * a session expiration function. If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon 
     * successful edit, it resets the data for the edited record, clears the reason for edit, and updates the dataset with the new data.
     */

    const handleEdit = async () => {
        console.log("enters handleEdit")
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "dataset",
            userId: state.userId,
            orgId: state.orgId,
            actionType: "edit",
            comments: reason,
            value: newData,
            index: editableRow,
            notebookId: props.data.notebookId
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                DesignID: "",
                Entities: "",
                ComponentName: "",
                Description: "",
                ComponentType: "",
                Configuration: "",
                Parameters: ""
            })
            setReason("")
            setEditableRow("empty")
            setSetVal(JSON.parse(res.data.recordset[0].pseudocode))
            setFilteredRecord(JSON.parse(res.data.recordset[0].pseudocode))
            setwhetherUpdated(true)
            // console.log("resssss", setSetVal, "in handle edit")

        }
    };

  /**
     * Pseudocode No:PC_DS_86 TO 97
     * The handleAdd function is responsible for adding a new record to the dataset. Upon invocation, it displays a loading spinner to indicate ongoing processing. 
     * It then constructs a request body containing necessary data for the addition operation, such as project ID, user ID, action type, comments, and the new data (newData).
     * This function calls another function updatePseudocodeData located in API.js, passing the request body and user token for authentication. Upon receiving a response, 
     * it logs the result and hides the loading spinner.
     * Depending on the response status code, different actions are taken. If the status code indicates a session expiration (403), it triggers a session expiration function. 
     * If the status code is not 200, indicating an error, it opens an error modal on the webpage. Otherwise, upon successful addition, it resets the data for the new record, 
     * clears the reason for addition, and updates the dataset with the new data.
     */

    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "dataset",
            userId: state.userId,
            orgId: state.orgId,
            actionType: "add",
            comments: reason,
            value: newData,
            index: editableRow,
            notebookId: props.data.notebookId
        }

         
        const res = await updatePseudocodeData(body, state.jwt);
 
        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                DesignID: "",
                Entities: "",
                ComponentName: "",
                Description: "",
                ComponentType: "",
                Configuration: "",
                Parameters: ""
            })
            setReason("")
            setSetVal(JSON.parse(res.data.recordset[0].pseudocode));
            setFilteredRecord(JSON.parse(res.data.recordset[0].pseudocode))
            setwhetherUpdated(true)
            console.log("resssss", setSetVal, "in handle add")
        }
    };


    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
                <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
                    <div className="d-flex  align-items-center py-1">
                        <a className="navbar-brand" ><img src="images/codegen-logo.svg" alt="codegen-logo" className="logo-sty" /></a>
                        <div className="ms-5">
                            <button className="header-button-active fw-bold">Organization</button>
                            <button className="header-button ms-2">Activity Logs</button>
                        </div>
                        <div className="dropdown me-lg-3 ms-auto">
                            <img src="images/user-profile.svg" alt="user-profile" className="profile-sty cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                                <li><a className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" ><span className="me-2"><img src="images/logout.svg" alt="profile-icon" /></span>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">DatasetPseudo Notebook</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }}  onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }}/></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">Dataset</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">DesignID<span className="ms-2 cursor-pointer"></span></th>
                                                <th className="text-nowrap">Entities<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Entities" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Entities')} /></span></th>
                                                <th className="text-nowrap">ComponentName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ComponentName" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ComponentName')} /></span></th>
                                                <th className="text-nowrap">Description<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Description" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Description')} /></span></th>
                                                <th className="text-nowrap">ComponentType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "ComponentType" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('ComponentType')} /></span></th>
                                                <th className="text-nowrap">Configurations<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Configuration" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Configuration')} /></span></th>
                                                <th className="text-nowrap">Parameters<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Parameters" && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Parameters')} /></span></th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editableRow == "empty" ? <tr>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Entities' value={newData?.Entities} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentName' value={newData?.ComponentName} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Description' value={newData?.Description} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData?.ComponentType} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Configuration' value={newData?.Configuration} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Parameters' value={newData?.Parameters} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                                <td className="text-center">
                                                        <img
                                                            src="images/add-icons.svg"
                                                            alt="add-icon"
                                                            className={`cust-cursor-pointer ${Object.values(newData).every(value => value !== '') ? '' : 'disabled'}`}
                                                            title="Add"
                                                            onClick={(e) => {
                                                                if (Object.values(newData).every(value => value !== '')) {
                                                                    setAction("add");
                                                                    document.getElementById('openReasson').click();
                                                                } else {
                                                                    e.currentTarget.style.opacity = '0.3';
                                                                    e.currentTarget.style.cursor = 'not-allowed';
                                                                }
                                                            }}
                                                            style={{
                                                                opacity: Object.values(newData).every(value => value !== '') ? 1 : 0.3,
                                                                cursor: Object.values(newData).every(value => value !== '') ? 'pointer' : 'not-allowed'
                                                            }}
                                                        />
                                                </td>
                                            </tr> : <></>}
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredRecord.length}</span></p>
                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" onClick={() => setReason("")} />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                                                    <div className="d-flex">
                                                        <div className="toast-body">
                                                            <span>
                                                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                                                            </span>
                                                            <span className="mx-3 font-14 loader-text">Session has expired.Please login Again</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal" onClick={() => setReason("")}>
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DatasetPseudo;