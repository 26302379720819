export const sampleJsonDatas = {
    "UnitTesting": [
        {
            "S.No": 1,
            "NotebookName": "Notebook A",
            "TableName": "Table 1",
            "UnitTestCaseDescription": "Test case 1 description",
            "Scripts": "Script A",
            "Comments": "All good",
            "ValidationStatus": "Pass"
        },
        {
            "S.No": 2,
            "NotebookName": "Notebook B",
            "TableName": "Table 2",
            "UnitTestCaseDescription": "Test case 2 description",
            "Scripts": "Script B",
            "Comments": "Needs review",
            "ValidationStatus": "Fail"
        },
        {
            "S.No": 3,
            "NotebookName": "Notebook C",
            "TableName": "Table 3",
            "UnitTestCaseDescription": "Test case 3 description",
            "Scripts": "Script C",
            "Comments": "Pending",
            "ValidationStatus": "Pass"
        },
        {
            "S.No": 4,
            "NotebookName": "Notebook D",
            "TableName": "Table 4",
            "UnitTestCaseDescription": "Test case 4 description",
            "Scripts": "Script D",
            "Comments": "In progress",
            "ValidationStatus": "Fail"
        },
        {
            "S.No": 5,
            "NotebookName": "Notebook E",
            "TableName": "Table 5",
            "UnitTestCaseDescription": "Test case 5 description",
            "Scripts": "Script E",
            "Comments": "Complete",
            "ValidationStatus": "Pass"
        }
    ]
};



export const unitTestResultUploadValidations1 = {
    "UnitTesting": [
        { columnHeader: "S.No", columnCell: "A1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "NotebookName", columnCell: "B1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "TableName", columnCell: "C1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "UnitTestCaseDescription", columnCell: "D1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Scripts", columnCell: "E1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "Comments", columnCell: "F1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: null },
        { columnHeader: "ValidationStatus", columnCell: "G1", isEmpty: 0, NA: 0, isNull: 0, valueValidation: ["Pass", "Fail"] }
    ]
}