import { useEffect, useState } from "react";
/*PS_CG_1.1*/
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment/moment.js";
import { saveAs } from 'file-saver';
import { cloudonprem, scripts, type1, type2, type3 } from "./Contrains.js";
import { deployNotebook, getChatHistory, targetSystemValidation } from "../Service/Api.js";
function DeployPopup(props) {


    const { state } = useLocation();
    const [deployStatus, setDeployStatus] = useState(props.deployStatus);
    const [isLoading, setIsLoading] = useState(true);
    if (state == null) {
        window.location.href = "/projectInfo";
    }
    const navigate = useNavigate()
    let chatHistory = props.chatHistory;
    useEffect(() => {

        fetchStatus()
        chatHistory.forEach((item, index) => {
            if (item.deployed === true) {
                setTargetSystemDetails(item.targetdetails);
            }
        })
    }, [])

    const fetchStatus = () => {
        setIsLoading(true)
        try {
            const response = getChatHistory(
                { projectId: state.projectId, userId: state.userId },
                state.jwt
            );
            response && console.log(response, "2ws0000000000000000000000");
            if (response.status == 403) {
                sessionExpired();
                return;
            }
            if (response.status != 200) {
            } else {
                setDeployStatus(response.data[0][0]?.deployStatus);
                console.log("dasdasdasd", response.data[0][0]?.deployStatus);
                console.log(response.data[0]);
            }
        } catch (e) {
            console.error(e, 'eeeee')
        } finally {
            setIsLoading(false)

        }
    }


    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            navigate("/");
        }, 3000);
    };

    const [targetSystemDetails, setTargetSystemDetails] = useState({});
    const [loader, setLoader] = useState(false);

    const [isValidTargetSystemDetails, setIsValidTargetSystemDetails] = useState({
        userName: true,
        accessToken: true,
        comments: true,
        systemUrl: true
    });
    let targetInfo = {
        userName: {
            userName: "",
            comments: "",
            createdBy: "",
        },
        systemUrl: "",
        accessToken: "",
    };


    //this below two functionalities are the functions which are sent as props to this component but to provide this as inside the sequence diagram I have given the functions here
    async function deployNotebooksDatabricks(a, b) {
        let body;
        console.log(targetSystemDetails);
        if (state.futureStateName === "Databricks") {
            body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                userName: targetSystemDetails.userName?.userName,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                targetSystemName: "Databricks"
            };
        }

        let data = [...props.chatHistory];
        if (a == "1") {
            data[1].deployed = true;
            data[1].targetdetails = targetSystemDetails;
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: "Target system details" });
            props.setChatHistory(() => [...data]);
        } else if (a == 2) {
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: b });
            props.setChatHistory(() => [...data]);
        }
        setLoader(() => true);
        const deployResponse = await deployNotebook(body, state?.jwt);
        console.log(deployResponse);
        setLoader(() => false);
        if (deployResponse.status !== 200) {
            props.close(true)
            props.setTargetSystemDetails(targetInfo)

            data.push({ flow: "ai", type: "somethingDeploy", show: true });
        } else if (deployResponse.status === 200) {
            setDeployStatus(false)
            props.close(false)
            props.setTargetSystemDetails(targetSystemDetails)
            data.push({
                flow: "ai",
                type: "text",
                text: `Congratulations! You've successfully completed deployment.`,
            });
        }
        props.setChatHistory(() => [...data]);
        props.insertChatHistory([...data]);
    }

    async function SaveDetails() {

        // Display loader
        console.log(targetSystemDetails);
        if (state.futureStateName == "Databricks") {
            let body = {
                userName: `/Users/${targetSystemDetails.userName?.userName}`,
                projectId: state.projectId,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                userId: state.userId,
                comments: targetSystemDetails.userName?.comments,
                createdBy: targetSystemDetails.userName?.createdBy,
                targetSystem: state.futureStateName
            };

            const response = await targetSystemValidation(body, state?.jwt);
            console.log(response);
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["accessToken"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["systemUrl"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["userName"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["comments"]: true,
            }));
            if (response.status === 200) {
                // document.getElementById("popupclose").click();
                deployNotebooksDatabricks("1");
            }
            if (response.status == 403) {
                // If status is not 200, throw an error
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["accessToken"]: false,
                }));
            }
            if (response.status == 500 && response.data == "Invalid Url") {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["systemUrl"]: false,
                }));
            }
            if (response.status == 404 && response.data == "Invalid userName") {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["userName"]: false,
                }));
            }
            if (response.status == 400) {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["comments"]: false,
                }));
            }
        }

    }


    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content px-2">
                        <div className="modal-header border-0">
                            <h5
                                className="modal-title d-flex align-items-center font-medium font-20"
                                id="TarSysLabel"
                            >
                                Target System Info - Databricks
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="popupclose"
                                onClick={() => {
                                    if (deployStatus === true) {
                                        console.log(deployStatus, 'deployStatusdeployStatus');

                                        setTargetSystemDetails(targetInfo);
                                        console.log("sdfvwrgt");

                                    }
                                    props.close(deployStatus)
                                    props.setTargetSystemDetails(targetSystemDetails)

                                }}
                            />
                        </div>
                        <div className="modal-body py-0 my-4">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label
                                        htmlFor="User-Name"
                                        className="form-label text-black font-14 font-medium"
                                    >
                                        Username
                                        <span className="text-red"> *</span>
                                    </label>
                                    {console.log(!deployStatus, deployStatus, 'deployStatusdeployStatus')}
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="User-Name"
                                        value={targetSystemDetails?.userName?.userName || ""}
                                        disabled={deployStatus === false ? true : false}
                                        placeholder="Enter username"
                                        onChange={(e) => {
                                            setTargetSystemDetails((targetSystemDetails) => ({
                                                ...targetSystemDetails,
                                                userName: {
                                                    ...targetSystemDetails.userName,
                                                    ["userName"]: e.target.value,
                                                },
                                            }));
                                        }}
                                    />
                                    {isValidTargetSystemDetails?.userName == true ? (
                                        <></>
                                    ) : (
                                        <span style={{ color: "red" }}>Invalid UserName</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label
                                        htmlFor="Access-Token"
                                        className="form-label text-black font-14 font-medium"
                                    >
                                        Access Token
                                        <span className="text-red"> *</span>
                                    </label>

                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Access-Token"
                                        value={targetSystemDetails?.accessToken || ""}
                                        placeholder="Enter access token"
                                        disabled={!deployStatus}
                                        onChange={(e) => {
                                            setTargetSystemDetails((targetSystemDetails) => ({
                                                ...targetSystemDetails,
                                                ["accessToken"]: e.target.value,
                                            }));
                                        }}
                                    />
                                    {isValidTargetSystemDetails?.accessToken == true ? (
                                        <></>
                                    ) : (
                                        <span style={{ color: "red" }}>Invalid Access Token</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label
                                        htmlFor="Target-info-URL"
                                        className="form-label text-black font-14 font-medium"
                                    >
                                        URL
                                        <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Target-info-URL"
                                        placeholder="Enter URL"
                                        disabled={!deployStatus}
                                        value={targetSystemDetails?.systemUrl || ""}
                                        onChange={(e) => {
                                            setTargetSystemDetails((targetSystemDetails) => ({
                                                ...targetSystemDetails,
                                                ["systemUrl"]: e.target.value,
                                            }));
                                        }}
                                    />
                                    {isValidTargetSystemDetails?.systemUrl == true ? (
                                        <></>
                                    ) : (
                                        <span style={{ color: "red" }}>Invalid URL</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label
                                        htmlFor="Target-info-URL"
                                        className="form-label text-black font-14 font-medium"
                                    >
                                        Created By
                                        <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Target-info-URL"
                                        placeholder="Enter Created By"
                                        disabled={!deployStatus}
                                        value={targetSystemDetails?.userName?.createdBy || ""}
                                        onChange={(e) => {
                                            setTargetSystemDetails((targetSystemDetails) => ({
                                                ...targetSystemDetails,
                                                userName: {
                                                    ...targetSystemDetails.userName,
                                                    ["createdBy"]: e.target.value,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                    <label
                                        htmlFor="Target-info-URL"
                                        className="form-label text-black font-14 font-medium"
                                    >
                                        Comments
                                        <span className="text-red"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust-input-sty font-14 font-regular"
                                        id="Target-info-URL"
                                        placeholder="Enter comments"
                                        disabled={!deployStatus}
                                        value={targetSystemDetails?.userName?.comments || ""}
                                        onChange={(e) => {
                                            setTargetSystemDetails((targetSystemDetails) => ({
                                                ...targetSystemDetails,
                                                userName: {
                                                    ...targetSystemDetails.userName,
                                                    ["comments"]: e.target.value,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            {isValidTargetSystemDetails?.comments == true ? (
                                <></>
                            ) : (
                                <span style={{ color: "red" }}>
                                    CreatedBy/Comments does ot match the provided Access Token
                                </span>
                            )}
                        </div>
                        <div className="modal-footer border-0 px-3 mb-4 mt-4">
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-14 font-medium me-3"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    if (deployStatus === true) {
                                        console.log(deployStatus, 'deployStatusdeployStatus');

                                        setTargetSystemDetails(targetInfo);
                                        console.log("sdfvwrgt");

                                    }
                                    props.close(deployStatus)
                                    props.setTargetSystemDetails(targetSystemDetails)
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                id="save"
                                className="btn cust-primary-btn font-14 font-medium"
                                onClick={() => {
                                    SaveDetails();
                                }}
                                disabled={
                                    targetSystemDetails?.accessToken == "" ||
                                        targetSystemDetails?.userName?.comments == "" ||
                                        targetSystemDetails?.userName?.createdBy == "" ||
                                        targetSystemDetails?.systemUrl == "" ||
                                        targetSystemDetails?.userName?.userName == ""
                                        ? true
                                        : false || deployStatus === false || !(
                                            targetSystemDetails?.accessToken &&
                                            targetSystemDetails?.userName?.comments &&
                                            targetSystemDetails?.userName?.createdBy &&
                                            targetSystemDetails?.systemUrl &&
                                            targetSystemDetails?.userName?.userName
                                        )
                                }
                            >
                                <span>Save &amp; Proceed</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default DeployPopup;