import { useEffect, useState, useCallback, useRef } from "react";
import Editor, { loader } from "@monaco-editor/react";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import PlantUML from "react-plantuml";
//importing APIs
import {
    getChatHistory,
    getSourceSystemConnectioninfo,
    postChatHistory,
    postSourceSystemConnectioninfo,
    projectsDropDown,
    getCodePageDataApi,
    targetSystemValidation,
    deployNotebook,
    getMetaDataValues,
    fetchPseudocodeData,
    fetchDropDownValues,
    postDefineDetails,
    deleteDBRecords,
    updatePseudocodeData,
    fetchDesignDetails,
    getSnowflake,
    getMsSQL,
    getMySQL,
    getRedshift,
    getHubspot,
    getSalesforce,
    getOracle,
    getPostgres
} from "../Service/Api";
import {
    generateExtractionRequirementData,
    generateSourceToExtractionER,
    generateBronzeToSilverER,
    generateSilverToGoldER,
    generateDataModelSilverUML,
    generateDataModelGoldUML,
    generateDataDictionaryPseudocode,
    generateBronzeSequence,
    generateSilverSequence,
    generateGoldSequence,
    generateBronze,
    generateChecklistDev,
    generateDDL,
    generateGoldDevCode,
    generateLoggerAndPipelineJSON,
    generateMetadata,
    generateSilver,
    generateUtility,
    // generateDataflow,
    generateTableMappingPseudocode,
    generateColumnMappingPseudocode,
    generateMetadataTablePseudo,
    generateDDLPseudocode,
    generatePipelineSummaryPseudo,
    generateOrchestrationUMLCode,
    generateLoggerPseudocode,
    generateUtilityPseudocode,
    generateExtractionPseudocode,
    generateTransformationPseudocode,
    generateGoldPseudocode,
    generateOrchestrationDocument,
    // generateDataflowPseudocode,
    generateUnitTestCasePseudocode,
    generateDataQualityPseudo,
    generateChecklistDesign,
    // generateLinkedServicePseudocode,
    // generateDatasetsPseudocode,
    generateTestCaseCode,
    genrateDQualityCode
} from "../Service/Api";
import MetadataNotebook from "./MetadataNotebook";
import BronzeNotebook from "./BronzeNotebook";
import SilverNotebook from "./SilverNotebook";
// import Checklist from "./checklist";
import Dataflow from "./Dataflow";
import Dataset from "./DataSet";
import GoldNotebook from "./GoldNotebook";
import DDLScripts from "./DDLScripts";
import LinkedServices from "./LinkedServices";
import LoggerNotebook from "./LoggerNotebook";
import Utility from "./Utility";
import Orchestraion from "./OrchestrationPipeline";
import { useLocation, useNavigate } from "react-router-dom";
import DDLScriptPseudo from './DDLScriptPseudoBronze'
import LoggerGridPseudocode from "../Component/LoggerGridPseudocode";
import Utilities from "./UtilityGridPseudocode";
import LinkedServicePseudo from "./LinkedServicePseudo";
import CodeReviewChecklistDev from "./CodeReviewChecklistDev";
import DataQualityPage from "./DataQualityPage";
import DataQualitySP from './DataQualitySP'
import DataQualityPipeline from "./DataQualityPipeline";
import DataQualityDB from "./DataQulaityDB";
import UnitTestCaseSnowFlakes from "./UnitTestCaseSnowFlakes";
import UnitTestCaseADF from "./UnitTestCaseADF";
import UnitTestCaseGridDB from "./UnitTestCaseGridDB";
import UnitTestCaseCodePage from "./UnitTestCaseCode"
import DataFlowPseudo from "./DataFlowPseudo";
import GoldStoredProcedure from "./GoldStoredProcedure";
import GoldPipeline from "./GoldPipeline";
import GoldDataBricks from "./GoldDataBricks";
import SilverStoredProcedure from "./SilverStoredProcedure";
import SilverPipeline from "./SilverPipeline";
import SilverDataBricks from "./SilverDataBricks";

import ExtractionStoredProcedure from "./ExtractionStoredProcedure";

import BronzePipeline from "./BronzePipeline";

import ExtractionDataBricks from "./ExtractionDataBricks";

import OrchestrationDiagram from "./OrchestrationDiagram";

import PipelineSummaryRS from "./PipelineSummaryRS";

import PipelineSummarySY from "./PipelineSummarySY";

import PipelineSummaryDB from "./PipelineSummaryDB";

import MetadataTable from "./MetadataTablePseudo";
import MetaDataGridDev from "./metaGrid";
import CodeReviewChecklistDesignDB from "./CodeReviewChecklistDesignDB";
import DataDictionarySilver from "./DataDictionarySilver";
import DataDictionaryGold from "./DataDictionaryGold";
import ExtractionDBSequence from "./ExtractionDBSequence";
import SilverDBSequence from "./SilverDBSequence";
import GoldDBSequence from "./GoldDBSequence";
import SourceToExtractionER from "./SourceToExtractionER";
import BronzeToSilverER from "./BronzeToSilverER";
import SilverToGoldER from "./SilverToGoldER";
import DataModelGold from "./DataModelGold";
import DataModelSilver from "./DataModelSilver";
import ExtractionReqGrid from "./ExtractionReqGrid"
import DDLScriptPseudoBronze from "./DDLScriptPseudoBronze"
import DDLScriptPseudoSilver from "./DDLScriptPseudoSilver"
import DDLScriptPseudoGold from "./DDLScriptPseudoGold"
import ColumnMappingRework from "./columnMapping";
import OrchestrationPseudo from "./OrchestrationPseudo";
import TableMappingRework from "./TableMappingRework";
import DatasetPseudo from "./DatasetPseudo";
import CodeReviewChecklistDesignRS from "./CodeReviewChecklistDesignRS";
import CodeReviewChecklistDesignSY from "./CodeReviewChecklistDesignSY";


import {
    awsBronze,
    awsDdl,
    awsGold,
    awsLogger,
    awsMetadata,
    awsSilver,
    awsUtilities,
    cloudonprem,
    dataflow,
    dateset,
    dbBronze,
    dbDdl,
    dbGold,
    dbLogger,
    dbMetaData,
    dbSilver,
    dbUtilities,
    linkedServices,
    orchestration,
    sfBronze,
    sfDdl,
    sfGold,
    sfMetadata,
    sfSilver,
    syBronze,
    syDdl,
    syGold,
    syLogger,
    syMetadata,
    sySilver,
    syUtilities,
    type1,
    type2,
    type3,
    scripts,
    adfMetadata,
    adfDdl,
    dataDictionary,
    tableMapping,
    sampleOutputBCM,
    sampleOutputSCM,
    sampleOutputGCM,
    metadataTable,
    ddlNotebook,
    ddlPipeline,
    ddlProcedure,
    pipelineSummaryNotebook,
    pipelineSummaryPipeline,
    pipelineSummaryProcedure,
    loggerNotebook,
    utilityNotebook,
    extractionNotebook,
    transformationNotebook,
    goldNotebook,
    orchestractionDocNotebook,
    dataQualityPseudoNotebook,
    codeReviewChecklistDocNotebook,
    linkedSericePipeline,
    datasetPipeline,
    extractionPipeline,
    transformationPipeline,
    goldPipeline,
    dataflowPipeline,
    orchestractionDocPipeline,
    unitTestCasePseudoPipeline,
    dataQualityPseudoPipeline,
    codeReviewChecklistDocPipeline,
    extractionStoredProcedure,
    transformationstoredProcedure,
    goldStoredProcedure,
    unitTestCasePseudoNotebook,
    unitTestCasePseudoStoredProcedure,
    dataQualityPseudoStoredProcedure,
    codeReviewChecklistDocStoredProcedure,
    unitTestcaseCodeDB,
    unitTestcaseCodeSY,
    unitTestcaseCodeRS,
    dataQualityCodeDB,
    dataQualityCodeSY,
    dataQualityCodeRS,
    extractionRequirement,
    silverER,
    goldER,
    dataModelSilver,
    dataModelGold,
    silverDD,
    goldDD,
    extractionseqNotebook,
    extractionseqPipeline,
    extractionStoredProcedureSeq,
    transformationSeqPipeline,
    transformationseqNotebook,
    transformationStoreProcedureSeq,
    goldSeqPipeline,
    goldStoreProcedureSeq,
    goldNotebookSeq



} from "./Contrains";
import { HeaderCom } from "./Header";
import DesignPopup from "./DesignDocument";
import DefinePop from "./DefinePopup";
import TableReqPop from "./TableReqPop"
import SilverZonePopup from "./SilverZoneReqPopup"
import GoldZonePopup from "./GoldZoneReqPopup"
import MetaDataTable from "./metaGrid";
import { decode } from "base-64";


//PS_CM_01 - PS_CM_1964
function ChatMaintenance() {
    const [chatHistory, setChatHistory] = useState([]);
    const [sourceSystems, setSourceSystems] = useState([]);
    const [showCode, setShowCode] = useState(false);
    const [deployStatus, setDeployStatus] = useState(true);
    const [showDesignPopup, setShowDesignPopup] = useState(false);
    const [showDefinePopup, setShowDefinePopup] = useState(false);
    const [showtableReqPopup, setShowtableReqPopup] = useState(false);
    const [showsilverERPopup, setShowsilverERPopup] = useState(false);
    const [showgoldERPopup, setShowgoldERPopup] = useState(false);
    const [codeType, setCodeType] = useState("");
    const [defineDetails, setDefineDetails] = useState({});
    const [tableReqDetails, setTableReqDetails] = useState({});
    const [silverReqDetails, setSilverReqDetails] = useState({});
    const [goldReqDetails, setGoldReqDetails] = useState({});
    const [showSourcePopup, setShowSourcePopup] = useState(true);
    const [showLoader, setLoader] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [sourceSystemInfo, setSourceSystemInfo] = useState({
        systemName: "",
        InternalExternal: "Select",
        systemType: "Select",
        subjectArea: "Select",
        dataFormat: "Select",
        sourceSystemNameId: "Select",
        sourceSystemType: "Select",
        dataVolume: "Select",
        descriptions: "",
        connectionDetails: {},
    });
    const [sourceSystemDetails, setSourceSystemDetails] = useState([]);
    const [selectedSystemType, setSelectedSystemType] = useState([]);
    const [selectedSubjectArea, setSelectedSubjectArea] = useState([])
    const [disableSourceFields, setDisableSourceFields] = useState(false);
    const [showLooksGood, setShowlooksGood] = useState(false);
    const [codeDataValues, setCodeDataValues] = useState({});
    const [pipelineSummaryData, setPipelineSummaryData] = useState([]);
    const [extractionData, setExtractionData] = useState([]);
    const [extractionSeqData, setExtractionSeqData] = useState([]);
    const [transformationSeqData, setTransformationSeqData] = useState([]);
    const [transformationData, setTransformationData] = useState([]);
    const [goldData, setGoldData] = useState([]);
    const [orchestrationData, setOrchestrationData] = useState([]);
    const [dataflowData, setDataflowData] = useState([]);
    const [isEditNotebook, setIsEditNotebook] = useState({});
    const nav = useNavigate();
    const [designDetails, setDesignDetails] = useState({});
    const [designObj, setDesignObj] = useState({});
    const [targetSystemDetailsDb, setTargetSystemDetailsDb] = useState('')
    const [targetSystemDetailsSy, setTargetSystemDetailsSy] = useState('')
    const [targetSystemDetailsADF, setTargetSystemDetailsADF] = useState('')
    var { state } = useLocation();
    console.log(state);
    let index = 0;

    let targetInfo = {
        userName: {
            userName: "",
            comments: "",
            createdBy: "",
        },
        systemUrl: "",
        accessToken: "",
    };
    let targetInfoADF = {
        accessToken: "",
        factoryName: "",
        subscriptionId: "",
        resourceGroupName: ""
    };

    let targetInfoSynapse = {
        systemUrl: "",
        accessToken: ""
    }
    // const [targetSystemDetails, setTargetSystemDetails] = useState(targetInfo);

    const [targetSystemDetails, setTargetSystemDetails] = useState({});

    const [isValidTargetSystemDetailsADF, setIsValidTargetSystemDetailsADF] = useState({
        subscriptionId: true,
        resourceGroupName: true,
        accessToken: true,
        factoryName: true,
    });
    const [isValidTargetSystemDetailsSynaps, setIsValidTargetSystemDetailsSynaps] = useState({
        systemUrl: true,
        accessToken: true,
    });

    const [isValidTargetSystemDetails, setIsValidTargetSystemDetails] = useState({
        userName: true,
        accessToken: true,
        comments: true,
        systemUrl: true
    });

    const [dataUML, setDataUML] = useState("");
    const [orchUML, setOrchUML] = useState("");
    const [downloadUml, setDownloadUml] = useState(false)
    const [downloadOrgUML, setDownloadOrgUML] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const matchesRef = useRef([]);



    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            nav("/");
        }, 3000);
    };

    const downloadImage = async (imageUrl, fileName) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };


    useEffect(() => {
        debugger
        // Extract the phase from chatHistory
        const userChat = chatHistory.find(item => item.flow === 'user' && item.phase);
        if (userChat) {
            setSourcePhase(userChat.phase);
        }
        console.log(chatHistory, 'chatthisssss');
    }, [chatHistory]);

    useEffect(() => {
        if (dataUML !== "") {
            const imgElement = document.getElementById('daimage111').getElementsByTagName('img')[0];
            const srcValue = imgElement.getAttribute('src');
            console.log(srcValue, "srcValue");
            downloadImage(srcValue, `${downloadUml}.svg`);
        }
    }, [downloadUml]);

    useEffect(() => {
        if (orchUML !== "") {
            const imgElement = document.getElementById('daorgimage').getElementsByTagName('img')[0];
            const srcValue = imgElement.getAttribute('src');
            console.log(srcValue, "srcValue");
            downloadImage(srcValue, 'OrchestrationUML.svg');
        }
    }, [downloadOrgUML]);


    const connectionIfoType1 = type1;

    const connectionIfoType2 = type2;

    const connectionIfoType3 = type3;



    // PS_CD_1.5  

    const [validationErrors, setValidationErrors] = useState({});

    const [formValid, setFormValid] = useState(false);

    const [designData, setDesignData] = useState("");



    const [selectedFileName, setSelectedFileName] = useState("");



    const [invData, setInvData] = useState([]);
    const [sourcePhase, setSourcePhase] = useState('')


    // let sourcePhase ;



    function convertExcelToJSON(workbook, fileName, indexValue) {

        debugger
        //PS_CD_1.223

        const sheet = workbook.Sheets["DataDictionary"];

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        console.log("JSON", jsonData);



        const sheetData = [];

        // PS_CD_1.225

        jsonData.forEach((row) => {

            const rowData = {};

            //   PS_CD_1.227



            Object.keys(row).forEach((cell) => {

                // PS_CD_1.228

                const header = cell.trim();

                const value = row[cell];

                rowData[header] = value;

            });

            //   PS_CD_1.231

            sheetData.push(rowData);

            console.log("sheetData", sheetData);

            // setInvData(sheetData)
            // setSourceSystemDetails({...invData,inventoryDD:invData})
        })

        let sourceDeta = [...sourceSystemDetails]
        sourceDeta[indexValue] = { ...sourceDeta[indexValue], inventoryDD: sheetData, inventoryName: fileName }
        setSourceSystemDetails(sourceDeta)
        console.log("formValid", formValid);

    }

    const areAllFilesUploadedAndValid = () => {
        return sourceSystemDetails.every((system, index) => {
            // Check if a file has been uploaded
            const fileUploaded = system.fileName || system.inventoryName || system.inventoryDDName;
           
            // If no file is uploaded, return false
            if (!fileUploaded) {
                return false;
            }
           
            else
            {
                return true;
            }
        });
    };

    //   PS_CD_1.142

    const handleFileUpload = async (event, indexValue) => {
        console.log("in file upload fn");
        return new Promise((resolve, reject) => {
            try {
                const selectedFile = event.target.files[0];
    
                const setValidationErrorsForIndex = (errors) => {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        [indexValue]: errors
                    }));
                };
    
                if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
                    setValidationErrorsForIndex([
                        "Invalid file format. Please upload a .xlsx file.",
                    ]);
                    reject("Invalid file format.");
                    setFormValid(false);
                    return;
                }
    
                const fileName = selectedFile.name;
                setSelectedFileName(fileName);
                setDesignData({ ...designData, fileName: fileName });
    
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });
                        const visibleSheetNames = workbook.SheetNames.filter((name) => {
                            const sheet = workbook.Sheets[name];
                            return !sheet.Hidden || sheet.Hidden !== 2;
                        });
    
                        const mandatorySheets = ["DataDictionary"];
                        const missingSheets = mandatorySheets.filter(
                            (sheet) => !visibleSheetNames.includes(sheet)
                        );
    
                        if (missingSheets?.length > 0) {
                            setValidationErrorsForIndex([
                                `Missing mandatory sheets: ${missingSheets.join(", ")}`,
                            ]);
                            reject("Missing mandatory sheets.");
                            setFormValid(false);
                            return;
                        }
    
                        const errors = [];
                        visibleSheetNames.forEach((sheetName) => {
                            const sheet = workbook.Sheets[sheetName];
                            const a1Cell = sheet["A1"];
    
                            if (
                                !a1Cell ||
                                typeof a1Cell.v !== "string" ||
                                a1Cell.v.trim() == ""
                            ) {
                                errors.push(
                                    `Cell A1 should contain a non-empty string value in sheet ${sheetName}`
                                );
                            }
    
                            const a2Cell = sheet["A2"];
                            if (a2Cell == null || a2Cell == undefined || a2Cell.v == "") {
                                setFormValid(false);
                                errors.push(
                                    `Cell A2 should contain a non-empty value in sheet ${sheetName}`
                                );
                            }
                        });
    
                        if (errors.length > 0) {
                            setValidationErrorsForIndex(errors);
                            reject("Empty cells found.");
                            setFormValid(false);
                            return;
                        }
    
                        const getColumnIndex = (sheet, fieldName) => {
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            let columnIndex = -1;
                            for (let C = range.s.c; C <= range.e.c; ++C) {
                                const address = XLSX.utils.encode_cell({ r: range.s.r, c: C });
                                const cell = sheet[address];
    
                                if (cell && cell.v == fieldName) {
                                    columnIndex = C;
                                    break;
                                }
                            }
                            if (columnIndex == -1) {
                                console.log(`Column with field name '${fieldName}' not found`);
                                console.log("Sheet:", sheet);
                                console.log("Range:", range);
                            }
                            return columnIndex;
                        };
    
                        const displayValidationError = (
                            rowIndex,
                            columnIndex,
                            fieldName,
                            errorCode,
                            errorMessage,
                            dataType
                        ) => {
                            const errorDetails =
                                errorMessage +
                                ` (${typeof columnIndex == "number"
                                    ? String.fromCharCode(65 + columnIndex)
                                    : columnIndex
                                }${rowIndex + 1})`;
                            addValidationError(errorDetails);
                            setFormValid(false);
                        };
    
                        const addValidationError = (message) => {
                            setValidationErrors(prevErrors => ({
                                ...prevErrors,
                                [indexValue]: [...(prevErrors[indexValue] || []), message]
                            }));
                            setFormValid(false);
                        };
    
                        console.log("workbook.SheetNames", workbook.SheetNames);
                        const allErrors = [];
                        for (const sheetName of workbook.SheetNames) {
                            console.log(`Checking sheet: ${sheetName}`);
                            const sheet = workbook.Sheets[sheetName];
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            const errors = [];
    
                            if (sheetName == "DataDictionary") {
                                console.log("entered DataDictionary");
                                const expectedHeaders = [
                                    "SourceSystem",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "ColumnName",
                                    "DataType",
                                    "Constraints",
                                    "Description"
                                ];
    
                                for (let i = 0; i < expectedHeaders.length; i++) {
                                    const headerField = expectedHeaders[i];
                                    const columnHeader = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
                                    if (!columnHeader || columnHeader.v !== headerField) {
                                        errors.push(
                                            `Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`
                                        );
                                        displayValidationError(
                                            0,
                                            i,
                                            headerField,
                                            "MISSING",
                                            `Header Field '${headerField}' is missing or not in cell ${String.fromCharCode(65 + i)}1 ${sheetName}`,
                                            "string"
                                        );
                                        setFormValid(false);
                                    }
                                }
    
                                const fieldsNotToBeEmpty = [
                                    "SourceSystem",
                                    "SubjectArea",
                                    "DatabaseName",
                                    "SchemaName",
                                    "TableName",
                                    "ColumnName",
                                    "DataType",
                                    "Constraints",
                                    "Description"
                                ];
    
                                for (const fieldName of fieldsNotToBeEmpty) {
                                    const columnIndex = getColumnIndex(sheet, fieldName);
    
                                    if (columnIndex === -1) {
                                        console.log(`Column index for field '${fieldName}' not found.`);
                                        continue;
                                    }
    
                                    for (let i = 1; i <= range.e.r; i++) {
                                        const cell = sheet[XLSX.utils.encode_cell({ r: i, c: columnIndex })];
                                        const cellValue = (cell && cell.v !== undefined) ? cell.v : null;
    
                                        if (cellValue === null || cellValue === undefined || (typeof cellValue === "string" && cellValue.trim() === "")) {
                                            displayValidationError(i, columnIndex, fieldName, "EM004", `${fieldName} value should not be empty in ${sheetName} in row ${i + 1}`, "string");
                                        }
    
                                        if ((fieldName.toLowerCase() !== 'databasename' && fieldName.toLowerCase() !== 'schemaname') &&
                                            (typeof cellValue === "string" && cellValue.trim().toLowerCase() === 'na')) {
                                            displayValidationError(i, columnIndex, fieldName, "EM005", `${fieldName} value should not contain 'NA' in ${sheetName} in row ${i + 1}`, "string");
                                        }
                                    }
                                }
                            }
    
                            if (errors.length > 0) {
                                console.log(
                                    `Validation errors found in sheet ${sheetName}:`,
                                    errors
                                );
                                allErrors.push(...errors);
                                setFormValid(false);
                            }
                        }
                        console.log("formValid", formValid);
    
                        const jsonData = convertExcelToJSON(workbook, fileName, indexValue);
                        setFormValid(true);
                        resolve(jsonData);
                    } catch (error) {
                        console.error("Error reading the Excel file:", error);
                        setValidationErrorsForIndex(["Error reading the Excel file."]);
                        reject("Error reading the Excel file.");
                    }
                };
                reader.readAsBinaryString(selectedFile);
            } catch (error) {
                const setValidationErrorsForIndex = (errors) => {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        [indexValue]: errors
                    }));
                };
                console.error("Error processing the file:", error);
                setValidationErrorsForIndex(["Error processing the file."]);
                reject("Error processing the file.");
            }
            document.getElementById("meta-dataUpload-file").value = "";
        });
    };

    const exportToExcel = async () => {



        let data = [{
            "SourceSystem": "Trackvia",
            "SubjectArea": "Job",
            "DatabaseName": "dbo",
            "SchemaName": "job",
            "TableName": "DCCPA_Jobs",
            "ColumnName": "Record_ID",
            "DataType": "String",
            "Constraints": "PK",
            "Description": "Identifies the job in an external system or context."
        },
        {

            "SourceSystem": "Trackvia",
            "SubjectArea": "Job",
            "DatabaseName": "dbo",
            "SchemaName": "job",
            "TableName": "DCCPA_Jobs",
            "ColumnName": "Client_PA",
            "DataType": "String",
            "Constraints": "NULL",
            "Description": "Specifies the identifier for the client associated with the job."
        },
        {

            "SourceSystem": "Trackvia",
            "SubjectArea": "Job",
            "DatabaseName": "dbo",
            "SchemaName": "job",
            "TableName": "DCCPA_Jobs",
            "ColumnName": "Job_Name",
            "DataType": "String",
            "Constraints": "NULL",
            "Description": "The name or title of the job."
        }]

        // Convert JSON array to worksheet

        const ws = XLSX.utils.json_to_sheet(data);


        // Create workbook

        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'DataDictionary');


        // Generate Excel file and download

        XLSX.writeFile(wb, 'InventoryData.xlsx');

    }

    useEffect(() => {
        debugger;
        let newPro = sessionStorage.getItem("newPro");
        if (newPro == "true") {
            sessionStorage.removeItem("newPro");
            document.getElementById("protoastMessage").style.display = "block";
            setTimeout(() => {
                document.getElementById("protoastMessage").style.display = "none";
            }, 3000);
        } else {
            state.isEdit = true;
        }
        fetchDropdown();
    }, []);

    /**
     * PS_CD_1.13 -30
     */
    async function fetchDropdown() {
        document.getElementById("pageLoader").style.display = "block";
        let response = await projectsDropDown(state.userId, state.jwt);
        fetchChatHistory();
        if (response.status != 200) {
            return;
        }
        console.log(response);
        if (state.isEdit == true) {
            fetchDetails();
        }
        setSourceSystems(
            response?.data[9]?.filter(
                (data) =>
                    data.sourceSystemNameId != "850BB243-E292-4417-9F78-02E7BCF55BBB" &&
                    data.sourceSystemNameId != "389144DD-76A0-4120-9D22-43C861875690" &&
                    data.sourceSystemNameId != "E1F3EA06-98FB-4AC9-BAA2-A0869ACF0F68"
            )
        );
    }

    /**
     * PS_CD_1.45 - 1.55
     */
    async function fetchDetails() {
        console.log(state);
        //   let  body={
        //         projectId: state.projectId, 
        //         userId: state.userId 
        //     }

        let response = await getSourceSystemConnectioninfo(
            state.projectId,
            state.userId,
            state.jwt
        );
        debugger;
        console.log(response, "0000000000000000000000");
        if (response.status == 403) {
            sessionExpired();
            return;
        }
        if (response.status != 200) {
        } else {
            if (response?.data[0]?.length > 0) {
                setDisableSourceFields(true);
            }
            console.log(response?.data[0])
            setSourceSystemDetails(prevDetails => {
                const newDetails = response?.data[0]
                    ?.filter((data) => data.connectionDetails != null)
                    .map((data) => ({
                        ...data,
                        connectionDetails: JSON.parse(data.connectionDetails),
                    }));
                // Reverse the array before setting it
                return newDetails ? newDetails.reverse() : [];
            });
            
        }
    }

    function goDown() {
        const divElement = document.getElementById("Imdown");
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth" });
        } else {
            goDown()
        }
    }
    /**
     * PS_CD_1.283 - 1.295
     */
    async function fetchChatHistory() {
        debugger        
        //PS_CM_14 - PS_CM_39
        let response = await getChatHistory(
            { projectId: state.projectId, userId:state.userId },
            state.jwt
        );
        console.log(response, "0000000000000000000000");
        if (response.status == 403) {
            sessionExpired();
            return;
        }
        if (response.status != 200) {
        } else {
            setDeployStatus(response.data[0][0]?.deployStatus);
            console.log("dasdasdasd", response.data[0][0]?.deployStatus);
            console.log(response.data[0]);
            let value = JSON.parse(response.data[0][0]?.chatHistory);
            console.log(value, "valuevaluevaluevaluevalue");
            if (value[1]?.deployed == true) {
                setTargetSystemDetails(value[1].targetdetails);
            }
            if (value.length > 7) {
                let arr = value?.filter((a) => a.text == "Notebook Details");
                if (arr[0]?.data != undefined) {
                    setDesignDetails(arr[0].data);
                }
            }
            
            if (value.length >= 3) {
                setSourcePhase(value[2].phase)
            }
            console.log(sourcePhase,'sourcePhase')

            if (value?.filter(a => a?.flow == 'error')?.length > 0) {
                value = value.filter((a) => a?.flow != 'error')
                console.log(value)
                insertChatHistory(value)
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
            if (value[value.length - 1].type == 'textwait') {
                const diffInMilliseconds = Math.abs(new Date() - new Date(value[value.length - 1]?.time || '2024-03-13T10:00:00'));
                const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
                if (minutes > 14) {
                    value = value.filter((a, i) => i != value.length - 1 && i != value.length - 2)
                    value[value.length - 1].show = true
                    insertChatHistory(value)
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                }
            }
            console.log(value[1]?.defineData);


            if (value[1]?.defineData) {
                setDefineDetails(value[1]?.defineData)
            }

            if (value[1]?.tableReqData) {
                setTableReqDetails(value[1]?.tableReqData)
            }
            if (value[1]?.silverReqData) {
                setSilverReqDetails(value[1]?.silverReqData)
            }
            if (value[1]?.goldReqData) {
                setGoldReqDetails(value[1]?.goldReqData)
            }


            if (value[1]?.designData) {

                setDesignDetails(value[1]?.designData)
                console.log(designDetails, "design------------");
            }
            if (value[1]?.designObj) {
                setDesignObj(JSON.parse(value[1]?.designObj))

                console.log(designObj, "designObj------------");
            }

            if (value[2]?.pipelineSummaryData) {
                setPipelineSummaryData(JSON.parse(value[2]?.pipelineSummaryData))
            }
            debugger
            if (value[2]?.extractionSeqData) {
                setExtractionSeqData(JSON.parse(value[2]?.extractionSeqData))
            }
            if (value[2]?.transformationSeqData) {
                setTransformationSeqData(JSON.parse(value[2]?.transformationSeqData))
            }
            if (value[2]?.extractionData) {
                setExtractionData(JSON.parse(value[2]?.extractionData))
            }
            if (value[2]?.transformationData) {
                setTransformationData(JSON.parse(value[2]?.transformationData))
            }
            if (value[2]?.goldData) {
                setGoldData(JSON.parse(value[2]?.goldData))
            }
            if (value[2]?.dataflowData) {

                setDataflowData(JSON.parse(value[2]?.dataflowData))
            }
            if (value[2]?.orchestrationData) {
                setOrchestrationData((value[2]?.orchestrationData))
            }
            console.log('------------------------->>', value)
            setChatHistory(value);
        }
        document.getElementById("pageLoader").style.display = "none";
        const goDownChat=goDown()
        // function goDown() {
        //     const divElement = document.getElementById("Imdown");
        //     if (divElement) {
        //         divElement.scrollIntoView({ behavior: "smooth" });
        //     } else {
        //         goDown()
        //     }
        // }
        setTimeout(() => { goDown() }, 1000);
    }


    console.log(targetSystemDetails, defineDetails);
    

    /**
     * PS_CD_1.298 - 1.309
     */
    //added refresh
    async function insertChatHistory(data) {
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            console.log("Inserting chat history:", {
                projectId: state.projectId,
                chatHistory: data !== undefined ? data : chatHistory,
                userId: state.userId,
            });

            // setLoader(true);
            const response = await postChatHistory(
                {
                    projectId: state.projectId,
                    chatHistory: data !== undefined ? data : chatHistory,
                    userId: state.userId,
                },
                state.jwt
            );

            console.log("Response:", response);

            // setLoader(false);
            if (response.status === 403) {
                sessionExpired();
                return;
            }

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Handle successful response here if needed
        } catch (error) {
            console.error("Error inserting chat history:", error);
            // Handle errors if needed
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }
    /**
     * PS_FDF_12 - PS_FDF_15
     */
    // async function download(type, filename, stype, id) {
    //     console.log(type, "===================");
    //     let body = {
    //         projectId: state.projectId,
    //         type: type,
    //         userId: state.userId,
    //         orgId: state.orgId,
    //     };
    //     if (stype == 'notebook') {
    //         body.notebookId = id
    //     }
    //     if (stype == 'pipeline') {
    //         body.pipelineId = id
    //     }
    //     const res = await getCodePageDataApi(body, state.jwt);
    //     console.log(res);
    //     if (res.status === 403) {
    //         sessionExpired();
    //     } else if (res.status !== 200) {
    //         document.getElementById("openErrorModal").click();
    //     } else {
    //         if (type == "checklist") {
    //             var wb = XLSX.utils.book_new();
    //             var sheetMap = {};

    //             res.data.forEach((sheetData) => {
    //                 if (!sheetMap[sheetData.notebookName.slice(0, 30)]) {
    //                     sheetMap[sheetData.notebookName.slice(0, 30)] = [
    //                         ["Notebook Name", "Command Purpose", "Checklist Step"],
    //                     ];
    //                 }

    //                 sheetMap[sheetData.notebookName.slice(0, 30)].push([
    //                     sheetData.notebookName,
    //                     sheetData.commandPurpose,
    //                     sheetData.checkListStep,
    //                 ]);
    //             });

    //             Object.keys(sheetMap).forEach((sheetName) => {
    //                 var ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
    //                 XLSX.utils.book_append_sheet(wb, ws, sheetName);
    //             });

    //             XLSX.writeFile(wb, "code_review_checklist.xlsx");
    //         } else if (type === 'metadataTable') {
    //             // Extract the metadataTable string from the data array
    //             const metadataString = res.data[0].metadataTable;

    //             // Parse the metadataTable string into an array of objects
    //             let metadataArray;
    //             try {
    //                 metadataArray = JSON.parse(metadataString);
    //             } catch (error) {
    //                 console.error('Error parsing metadataTable:', error);
    //                 return; // Exit the function if parsing fails
    //             }

    //             // Check if metadataArray is an array
    //             if (!Array.isArray(metadataArray)) {
    //                 console.error('Metadata table data is not in the correct format.');
    //                 return; // Exit the function if metadataArray is not an array
    //             }

    //             // Proceed with converting metadataArray to an Excel sheet
    //             const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //             const fileExtension = '.xlsx';

    //             const ws = XLSX.utils.json_to_sheet(metadataArray);
    //             const wb = { Sheets: { 'metadata': ws }, SheetNames: ['metadata'] };

    //             const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //             const data = new Blob([excelBuffer], { type: fileType });

    //             FileSaver.saveAs(data, 'MetaData' + fileExtension); // Corrected file name
    //         }
    //         else {
    //             console.log(res)
    //             res.data =
    //                 type == "metadata"
    //                     ? res.data[0].metadataUpdateScript == null
    //                         ? res.data[0].metadataInsertScript
    //                         : res.data[0].metadataInsertScript +
    //                         "\n" +
    //                         res.data[0].metadataUpdateScript
    //                     : type == "ddl"
    //                         ? res.data[0].DDLScripts
    //                         : type.toLowerCase() == "bronze".toLowerCase() || type.toLowerCase() == "extraction".toLowerCase()
    //                             ? res.data[0].bronzeCode
    //                             : type.toLowerCase() == "dataflow"
    //                                 ? res.data[0].pipelineJSON
    //                                 : type.toLowerCase() == "dataset"
    //                                     ? res.data[0].pipelineJSON
    //                                     : type.toLowerCase() == "gold"
    //                                         ? res.data[0].goldCode
    //                                         : type.toLowerCase() == "linkedservices" || type.toLowerCase() == "orchestration"
    //                                             ? res.data[0].pipelineJSON
    //                                             : type.toLowerCase() == "logger"
    //                                                 ? res.data[0].loggerCode
    //                                                 : type.toLowerCase() == "unittestcase"
    //                                                     ? res.data[0].unitTestCaseCode
    //                                                     : type.toLowerCase() == "dataQualityCode"
    //                                                         ? res.data[0].dataQualityCode
    //                                                         : type.toLowerCase() == "silver"
    //                                                             ? res.data[0].silverCode
    //                                                             : res.data[0].utilitiesCode

    //             const element = document.createElement("a");
    //             element.setAttribute(
    //                 "href",
    //                 "data:text/plain;charset=utf-8," + encodeURIComponent(res.data)
    //             );
    //             element.setAttribute("download", filename + ".py");
    //             document.body.appendChild(element);
    //             element.click();
    //             document.body.removeChild(element);
    //         }
    //     }
    // }

    async function download(type, filename, stype, id) {
        console.log(type, "===================");
        let body = {
            projectId: state.projectId,
            type: type,
            userId: state.userId,
            orgId: state.orgId,
        };
        if (stype == 'notebook') {
            body.notebookId = id
        }
        if (stype == 'pipeline') {
            body.pipelineId = id
        }
        const res = await getCodePageDataApi(body, state.jwt);
        console.log(res);
        if (res.status === 403) {
            sessionExpired();
        } else if (res.status !== 200) {
            document.getElementById("openErrorModal").click();
        } else {
            if (type == "checklist") {
                var wb = XLSX.utils.book_new();
                var sheetMap = {};

                res.data.forEach((sheetData) => {
                    console.log(res.data,"resssssssssssssssssssssssssssssssssssss")
                    if (!sheetMap[sheetData.NotebookName.slice(0, 30)]) {
                        sheetMap[sheetData.NotebookName.slice(0, 30)] = [
                            ["Notebook Name", "Command Purpose", "Checklist Step","Status"], 
                        ];
                    }

                    sheetMap[sheetData.NotebookName.slice(0, 30)].push([
                        sheetData.NotebookName,
                        sheetData.CommandPurpose,
                        sheetData.CheckListStep,
                        sheetData.Status
                    ]);
                });

                Object.keys(sheetMap).forEach((sheetName) => {
                    var ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                });

                XLSX.writeFile(wb, "code_review_checklist.xlsx");
            } else if (type === 'metadataTable') {
                // Extract the metadataTable string from the data array
                const metadataString = res.data[0].metadataTable;

                // Parse the metadataTable string into an array of objects
                let metadataArray;
                try {
                    metadataArray = JSON.parse(metadataString);
                } catch (error) {
                    console.error('Error parsing metadataTable:', error);
                    return; // Exit the function if parsing fails
                }

                // Check if metadataArray is an array
                if (!Array.isArray(metadataArray)) {
                    console.error('Metadata table data is not in the correct format.');
                    return; // Exit the function if metadataArray is not an array
                }

                // Proceed with converting metadataArray to an Excel sheet
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.json_to_sheet(metadataArray);
                const wb = { Sheets: { 'metadata': ws }, SheetNames: ['metadata'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, 'MetaData' + fileExtension); // Corrected file name
            }
            else {
                console.log(res)
                res.data =
                    type == "metadata"
                        ? res.data[0].metadataUpdateScript == null
                            ? res.data[0].metadataInsertScript
                            : res.data[0].metadataInsertScript +
                            "\n" +
                            res.data[0].metadataUpdateScript
                        : type == "ddl"
                            ? res.data[0].DDLScripts
                            : type.toLowerCase() == "bronze".toLowerCase() || type.toLowerCase() == "extraction".toLowerCase()
                                ? res.data[0].bronzeCode
                                : type.toLowerCase() == "dataflow"
                                    ? res.data[0].pipelineJSON
                                    : type.toLowerCase() == "dataset"
                                        ? res.data[0].pipelineJSON
                                        : type.toLowerCase() == "gold"
                                            ? res.data[0].goldCode
                                            : type.toLowerCase() == "linkedservices" || type.toLowerCase() == "orchestration"
                                                ? res.data[0].pipelineJSON
                                                : type.toLowerCase() == "logger"
                                                    ? res.data[0].loggerCode
                                                    : type.toLowerCase() == "unittestcase"
                                                        ? res.data[0].unitTestCaseCode
                                                        : type.toLowerCase() == "dataQualityCode"
                                                            ? res.data[0].dataQualityCode
                                                            : type.toLowerCase() == "silver"
                                                                ? res.data[0].silverCode
                                                                : res.data[0].utilitiesCode

                const element = document.createElement("a");
                element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8," + encodeURIComponent(res.data)
                );
                element.setAttribute("download", filename + ".py");
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        }
    }

    /**
  /* PS_CD_1.37 - 1.46///
     */
    {/*The handleSourceSystemInputChange function manages the input changes for source system details. It takes name and value parameters to update specific fields of the source system information
For fields like "systemType" and "subjectArea", it toggles the selection based on whether the value is already present in the array. For other fields, it directly updates the value.
Finally, it updates the state with the modified source system information. This function ensures dynamic handling of input changes for different fields within the source system information. */}
    function handleSourceSystemInputChange(name, value) {
        let selectedValues = Array.isArray(sourceSystemInfo[name]) ? [...sourceSystemInfo[name]] : [];

        if (name === 'systemType' || name === 'subjectArea') {
            if (selectedValues.includes(value)) {
                selectedValues = selectedValues.filter(item => item !== value);
            } else {
                selectedValues = [...selectedValues, value];
            }

            let updatedValue = selectedValues.length > 0 ? selectedValues : ['Select'];

            if (name === 'systemType') {
                setSelectedSystemType(updatedValue);
            } else if (name === 'subjectArea') {
                setSelectedSubjectArea(updatedValue);
            }

            setSourceSystemInfo({ ...sourceSystemInfo, [name]: selectedValues });
        } else {
            setSourceSystemInfo({ ...sourceSystemInfo, [name]: value });
        }
    }
    function handleSourceSystemInputChangeDev(name, value) {
        setSourceSystemInfo({ ...sourceSystemInfo, [name]: value });
    }

    // PS_CD_1.253-1.262
    {/*asynchronous function that retrieves details of a source system based on a given system ID. It searches for a specific source system
object in an array and performs different actions based on the type of source system found. The function handles various source systems
like MSSQL, Snowflake, Postgres, Salesforce, Redshift, and Oracle. For each type of system, it constructs data in a specific format and
exports it to an Excel file. The function includes logic to handle different responses from the source systems and manages data accordingly,
ensuring proper data export based on the retrieved information. */}
    const getSourceSystemDetails = async (sourceSystemId) => {
        /* The above code is using the `find` method to search for a specific `sourceSystem` object in
        the `sourceSystems` array based on a condition. The condition being checked is whether the
        `sourceSystemNameId` property of each `sourceSystem` object is equal to the `sourceSystemId`
        variable. If a matching `sourceSystem` object is found, it is assigned to the `sourceSystem`
        variable. */
        const sourceSystem = sourceSystems.find(system => system.sourceSystemNameId === sourceSystemId)

        console.log(sourceSystem.sourceSystemNameName, 'sourceSystemNameName')
        if (sourceSystem.sourceSystemNameName === 'MSSQL') {
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                server: sourceSystemDetails[0]?.connectionDetails?.server,
                driver: sourceSystemDetails[0]?.connectionDetails?.driver,
                projectId: state.projectId,
                userId: state.userId
            };
            console.log(body, 'msssqqqlll')

            setLoader(() => true);
            let response = await getMsSQL(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")
            } else {
                console.log(response);
                if (response.status === 200 && typeof response.data === 'object' && Object.keys(response.data).length > 0) {

                    const excelData = [];
                    const schemas = Object.values(response.data); // Get an array of schema objects

                    schemas.forEach(schema => {
                        const schemaName = schema.schema_name;
                        const tables = schema.tables;

                        tables.forEach(table => {
                            const tableName = table.table_name;
                            const columns = table.columns;

                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.is_nullable ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });

                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }

        if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.Username, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                username: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                account: sourceSystemDetails[0]?.connectionDetails?.account,
                warehouse: sourceSystemDetails[0]?.connectionDetails?.warehouse,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                schema: sourceSystemDetails[0]?.connectionDetails?.schema,
                userId: state.userId,
                projectId: state.project
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getSnowflake(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")
            } else {
                console.log(response); // Log the response object
                if (response.status === 200 && typeof response.data.schemaa === 'object') {
                    const schemaa = response.data.schemaa;
                    const excelData = [];
                    Object.keys(schemaa).forEach(schemaName => {
                        const tables = schemaa[schemaName];
                        tables.forEach(table => {
                            const tableName = table.tableName;
                            const columns = table.columns;
                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.is_nullable ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });
                    console.log(excelData, 'excelData'); // Log excelData to check its content
                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Postgres') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.hostname,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database_name,
                userId: state.userId,
                projectId: state.projectId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getPostgres(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")

            } else {
                console.log(response);
                if (response.status === 200 && response.data && Array.isArray(response.data.schema) && response.data.schema.length > 0) {
                    const excelData = [];
                    const schemas = response.data.schema;

                    schemas.forEach(schema => {
                        const schemaName = schema.schema_name;
                        const tables = schema.tables;

                        tables.forEach(table => {
                            const tableName = table.table_name;
                            const columns = table.columns;

                            columns.forEach(column => {
                                excelData.push({
                                    SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                    SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                    DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database_name || 'Unknown',
                                    SchemaName: schemaName,
                                    TableName: tableName,
                                    ColumnName: column.column_name,
                                    DataType: column.data_type,
                                    Constraints: column.isNull ? 'NULL' : 'NOT NULL', // Adjusted logic for Constraints
                                    Description: 'NA'
                                });
                            });
                        });
                    });

                    if (excelData.length > 0) {
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(data, 'InventoryData.xlsx');
                    } else {
                        console.log('No data to export.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Mysql') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getMySQL(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")

            } else {
                console.log(response);
                if (response.status === 200 && response.data && typeof response.data === 'object') {
                    const schemaData = response.data;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            Object.keys(tables).forEach(tableName => {
                                const columns = tables[tableName].columns;
                                columns.forEach(column => {
                                    excelData.push({
                                        SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                        SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                        DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                        SchemaName: schemaName,
                                        TableName: tableName,
                                        ColumnName: column.column_name,
                                        DataType: column.data_type,
                                        Constraints: column.not_null === 'YES' ? 'NOT NULL' : 'NULL',
                                        Description: 'NA' // You need to replace this with the actual description
                                    });
                                });
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }
        if (sourceSystem.sourceSystemNameName === 'HubSpot') {
            console.log('entered body');
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1');
            let tableList = sourceSystemDetails[0]?.connectionDetails?.tablename || ''; // Ensure tableList is initialized as a string

            // Split the string by commas and trim each part
            tableList = tableList.split(',').map(table => table.trim());

            let body = {
                accessToken: sourceSystemDetails[0]?.connectionDetails?.accesstoken,
                tableList: tableList,
                userId: state.userId
            };

            console.log('entered body', body);
            console.log(state.jwt);

            setLoader(() => true);
            let response = await getHubspot(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);

                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && Array.isArray(response.data.schema)) {
                    const schemaData = response.data.schema;

                    // Check if schemaData is valid and not empty
                    if (schemaData && schemaData.length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        schemaData.forEach(table => {
                            if (Array.isArray(table.columns)) {
                                table.columns.forEach(column => {
                                    excelData.push({
                                        SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                        SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                        DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                        SchemaName: table.schema_name,
                                        TableName: table.table_name,
                                        ColumnName: column.column_name,
                                        DataType: column.data_type,
                                        Constraints: column.is_null ? 'NULL' : 'NOT NULL',
                                        Description: 'NA'
                                    });
                                });
                            }
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }


        if (sourceSystem.sourceSystemNameName === 'Salesforce') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                username: sourceSystemDetails[0]?.connectionDetails?.username,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                security_token: sourceSystemDetails[0]?.connectionDetails?.security_token,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getSalesforce(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);

                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && typeof response.data === 'object') {
                    const schemaData = response.data;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            // Check if tables is an array before attempting to iterate over it
                            if (Array.isArray(tables)) {
                                tables.forEach(table => {
                                    if (Array.isArray(table.columns)) {
                                        table.columns.forEach(column => {
                                            excelData.push({
                                                SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                                SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                                DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                                SchemaName: schemaName,
                                                TableName: table.table_name,
                                                ColumnName: column.column_name,
                                                DataType: column.data_type,
                                                Constraints: column.isNull ? 'NULL' : 'NOT NULL',
                                                Description: 'NA'    // You need to replace this with the actual description
                                            });
                                        });
                                    }
                                });
                            } else {
                                console.log('Invalid tables data for schema:', schemaName);
                            }
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'DataDictionary.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }


            }
        }
        if (sourceSystem.sourceSystemNameName === 'Redshift') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getRedshift(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")
            } else {
                if (response.status === 200 && response.data && Array.isArray(response.data.schema)) {
                    const schemaData = response.data.schema;

                    // Check if schemaData is valid and not empty
                    if (schemaData.length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        schemaData.forEach(schema => {
                            const { schema_name, tables } = schema;
                            tables.forEach(table => {
                                if (Array.isArray(table.columns)) {
                                    table.columns.forEach(column => {
                                        excelData.push({
                                            SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown',
                                            SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown',
                                            DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown',
                                            SchemaName: schema_name,
                                            TableName: table.table_name,
                                            ColumnName: column.column_name,
                                            DataType: column.data_type,
                                            Constraints: column.not_null === 'YES' ? 'NOT NULL' : 'NULL',
                                            Description: 'NA' // You need to replace this with the actual description
                                        });
                                    });
                                }
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }

            }
        }
        if (sourceSystem.sourceSystemNameName === 'Oracle') {
            console.log('entered body')
            console.log(sourceSystemDetails[0]?.connectionDetails?.user, 'sourceSystemDetails1')
            // console.log(sourceSystemDetails[0]?.connectionDetails[0],'sourceSystemDetails1')
            let body = {
                user: sourceSystemDetails[0]?.connectionDetails?.user,
                password: sourceSystemDetails[0]?.connectionDetails?.password,
                host: sourceSystemDetails[0]?.connectionDetails?.host,
                port: sourceSystemDetails[0]?.connectionDetails?.port,
                database: sourceSystemDetails[0]?.connectionDetails?.database,
                userId: state.userId
            };
            console.log('entered body', body)
            console.log(state.jwt)

            setLoader(() => true);
            let response = await getOracle(body, state.jwt);
            console.log(response, "000000000000");
            setLoader(() => false);

            if (response.status === 403) {
                sessionExpired();
                return;
            }
            if (response.status !== 200) {
                // setShowSourcePopup(true);
                console.log("Please check connection details")
            } else {
                console.log(response);
                if (response.status === 200 && response.data && typeof response.data.schemaa === 'object') {
                    const schemaData = response.data.schemaa;

                    // Check if schemaData is valid and not empty
                    if (schemaData && Object.keys(schemaData).length > 0) {
                        // Construct the data in the desired format
                        const excelData = [];
                        Object.keys(schemaData).forEach(schemaName => {
                            const tables = schemaData[schemaName];
                            tables.forEach(table => {
                                if (Array.isArray(table.columns)) {
                                    table.columns.forEach(column => {
                                        excelData.push({
                                            SourceSystem: sourceSystemDetails[0]?.systemName || 'Unknown', // Fallback to 'Unknown' if systemName is not available
                                            SubjectArea: sourceSystemDetails[0]?.subjectArea || 'Unknown', // Fallback to 'Unknown' if subjectArea is not available
                                            DatabaseName: sourceSystemDetails?.[0]?.connectionDetails?.database || 'Unknown', // Fallback to 'Unknown' if database name is not available
                                            SchemaName: schemaName,
                                            TableName: table.tableName,
                                            ColumnName: column.column_name,
                                            DataType: column.data_type,
                                            Constraints: column.is_nullable === 'YES' ? 'NULL' : 'NOT NULL',
                                            Description: 'NA'    // You need to replace this with the actual description
                                        });
                                    });
                                }
                            });
                        });

                        // Check if excelData is populated
                        if (excelData.length > 0) {
                            // Convert the data to a workbook
                            const ws = XLSX.utils.json_to_sheet(excelData);
                            const wb = { Sheets: { 'DataDictionary': ws }, SheetNames: ['DataDictionary'] };

                            // Convert the workbook to a blob
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                            // Trigger download using FileSaver
                            saveAs(data, 'InventoryData.xlsx');
                        } else {
                            console.log('No data to export.');
                        }
                    } else {
                        console.log('Invalid schema data or schema is empty.');
                    }
                } else {
                    console.log('Invalid response or data structure.');
                }
            }
        }
    }


    const handleCheckboxChange = (value) => {
        // Toggle the checkbox value using your function
        handleSourceSystemInputChange("systemType", value);
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };


    // PS_CD_1.264-1.262-1.269
    {/*The function downloadScripts takes a sourceSystemId as input to retrieve corresponding SQL script content based on the source system's name.
It iterates through an array of source systems, finding the matching system by ID. Then, it retrieves the script content for that system from a 
separate array of scripts. If script content is found, it triggers a download of a .sql file containing the script. This function accommodates 
multiple source systems like MSSQL, Snowflake, Postgres, MySQL, and Redshift. If no script content is found for the specified source system, 
it logs a message indicating this. */}
    const downloadScripts = async (sourceSystemId) => {
        const sourceSystem = sourceSystems.find(system => system.sourceSystemNameId === sourceSystemId);

        console.log(sourceSystem.sourceSystemNameName, 'sourceSystemNameName');

        let scriptContent = '';

        if (sourceSystem.sourceSystemNameName === 'MSSQL') {
            // Find the script object for MSSQL
            const scriptObject = scripts.find(script => script.sourceSystem === 'MSSQL');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        } else if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            // Find the script object for Snowflake
            const scriptObject = scripts.find(script => script.sourceSystem === 'Snowflake');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        } else if (sourceSystem.sourceSystemNameName === 'Postgres') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Postgres');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Mysql') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Mysql');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Redshift') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Redshift');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }
        else if (sourceSystem.sourceSystemNameName === 'Snowflake') {
            // Find the script object for Postgres
            const scriptObject = scripts.find(script => script.sourceSystem === 'Snowflake');
            if (scriptObject) {
                scriptContent = scriptObject.scripts;
            }
        }


        // Add other conditions for different source systems as needed

        if (scriptContent) {
            // Trigger download of .sql file
            const blob = new Blob([scriptContent], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'script.py';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } else {
            console.log('No script content found for the specified source system.');
        }
    };

    /**
     * PS_CD_1.96 - PS_CD_1.99
     
     */
    {/*The handleConnectionInputChange function facilitates the modification of connection details within an array of source system details.
It accepts three parameters: key (the specific connection detail to modify), value (the new value for the connection detail), and index 
(the index of the source system detail object within the array).The function begins by creating a copy of the sourceSystemDetails array 
to avoid mutating the state directly. It then updates the connection details of the source system at the specified index by assigning a 
new object that spreads the existing connection details and updates the specified key with the provided value. Finally, the updated array 
of source system details is logged to the console for debugging purposes, and the state is updated with the modified array using setSourceSystemDetails */}
    function handleConnectionInputChange(key, value, index) {
        debugger;
        let updatedConnectionInfo = [...sourceSystemDetails];
        updatedConnectionInfo[index].connectionDetails = {
            ...updatedConnectionInfo[index].connectionDetails,
            [key]: value,
        };
        console.log('Before state update:', updatedConnectionInfo);
        setSourceSystemDetails(updatedConnectionInfo);
    }

    // Ensure your input field is controlled properly



    /**
     * PS_CD_1.107 - PS_CD_1.120
     */
    async function handleSave() {
        try {
            debugger
            console.log(sourceSystemDetails, "sourceeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");

            // Store inventory details
            const updatedSourceSystemDetails = sourceSystemDetails.map((system) => {
                return {
                    ...system,
                    inventoryDD: system?.inventoryDD ? system.inventoryDD:"null", // Update inventoryDD with invData
                    inventoryName: system?.inventoryName
                };
            });

            // Update state with the updated sourceSystemDetails
            setSourceSystemDetails(updatedSourceSystemDetails);

            let body = {
                sourceSystemDetails: updatedSourceSystemDetails, // Use updatedSourceSystemDetails here
                projectId: state.projectId,
                userId: state.userId,
            };

            setLoader(() => true);
            console.log(body, "bodySourceSystem");

            let response = await postSourceSystemConnectioninfo(
                { sourceSystemDetails: updatedSourceSystemDetails, // Use updatedSourceSystemDetails here
                    projectId: state.projectId,
                    userId: state.userId,
                    },
                state.jwt
            );

            setLoader(() => false);

            if (response.status == 403) {
                sessionExpired();
                return;
            }
            if (response.status != 200) {
                setShowSourcePopup(true);
                setChatHistory((value) => [
                    ...value,
                    {
                        flow: "ai",
                        type: "text",
                        text: "Something went wrong!!!. Please fill the details again.",
                    },
                ]);
            } else {
                let data = [...chatHistory];
                if (data.length > 0) {
                    data[data.length - 1].show = false;
                }
                setDisableSourceFields(true);
                data.push({
                    flow: "user",
                    type: "text",
                    text: "Source System and Connection Details",
                });

                if (data[2].phase == "Define"||data[2].phase == "Design") {
                    data.push({ flow: "ai", type: "tableRequirementPopup", show: true });
                }
                //  else if (data[2].phase == "Design") {
                //     data.push({
                //         flow: "ai",
                //         type: "text",
                //         text: "Let's move towards generating design document",
                //         phase: "Design"
                //     });
                //     data.push({ flow: "ai", type: "definePopup", show: true });
                // } 
                else {
                    data.push({
                        flow: "ai",
                        type: "text",
                        text: "Let's move towards generating Notebooks",
                        phase: "Development"
                    });
                    data.push({ flow: "ai", type: "designPopup", show: true });
                }

                setChatHistory((a) => [...data]);
                insertChatHistory([...data]);
            }
        } catch (error) {
            console.error("Error in handleSave:", error);
            setValidationErrors(["Error in handleSave"]);
        }
    }

    async function regenerate(data) {
        debugger;
        let chatData = [...chatHistory];
        if (data?.codeType == "metadata") {
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbMetaData
                    : state.futureStateName == "AWS Glue"
                        ? awsMetadata
                        : state.futureStateName == "Synapse"
                            ? syMetadata
                            : state.futureStateName == "ADF"
                                ? adfMetadata
                                : state.futureStateName == "Snowflake"
                                    ? sfMetadata
                                    : sfMetadata;
            setLoader(() => true);
            const res = await generateMetadata(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "meta",
                    sampleCode: splCode,
                },
                state.jwt
            );
            console.log(res);
            setLoader(() => false);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {
                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }

        }
        else if (data?.codeType == "ddl") {
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbDdl
                    : state.futureStateName == "AWS Glue"
                        ? awsDdl
                        : state.futureStateName == "Synapse"
                            ? syDdl
                            : state.futureStateName == "ADF"
                                ? adfDdl
                                : state.futureStateName == "Snowflake"
                                    ? sfDdl
                                    : sfDdl;
            setLoader(() => true);
            const res = await generateDDL(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "ddl",
                    sampleCode: splCode,
                },
                state.jwt
            );
            console.log(res);
            setLoader(() => false);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "Extraction" || data?.codeType == "extraction") {
            let sampleCode =
                state.futureStateName == "Databricks"
                    ? dbBronze
                    : state.futureStateName == "AWS Glue"
                        ? awsBronze
                        : state.futureStateName == "Synapse" &&
                            state.Methodology == "notebook"
                            ? syBronze
                            : state.futureStateName == "Synapse" &&
                                state.Methodology == "pipeline"
                                ? orchestration
                                : state.futureStateName == "ADF"
                                    ? orchestration
                                    : state.futureStateName == "Snowflake"
                                        ? sfBronze
                                        : sfBronze;
            setLoader(() => true);
            const res = await generateBronze(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "Extraction",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: sampleCode
                },
                state.jwt
            );

            console.log(res);
            setLoader(() => false);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "Silver") {
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbSilver
                    : state.futureStateName == "AWS Glue"
                        ? awsSilver
                        : state.futureStateName == "Synapse" &&
                            state.Methodology == "notebook"
                            ? sySilver
                            : state.futureStateName == "Synapse" &&
                                state.Methodology == "pipeline"
                                ? orchestration
                                : state.futureStateName == "ADF"
                                    ? orchestration
                                    : state.futureStateName == "Snowflake"
                                        ? sfSilver
                                        : sfSilver;
            setLoader(() => true);
            const res = await generateSilver(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "silver",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: splCode,
                },
                state.jwt
            );
            // call the generate extraction notebook api
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "Gold") {
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbGold
                    : state.futureStateName == "AWS Glue"
                        ? awsGold
                        : state.futureStateName == "Synapse" &&
                            state.Methodology == "notebook"
                            ? syGold
                            : state.futureStateName == "Synapse" &&
                                state.Methodology == "pipeline"
                                ? orchestration
                                : state.futureStateName == "ADF"
                                    ? orchestration
                                    : state.futureStateName == "Snowflake"
                                        ? sfGold
                                        : sfGold;
            setLoader(() => true);
            const res = await generateGoldDevCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "gold",
                    notebookId: data?.notebookId,
                    notebookName: data?.notebookname,
                    sampleCode: splCode,
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }

        else if (data?.codeType == "UnitTestCase") {
            let sampleCode;
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = unitTestcaseCodeDB;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = unitTestcaseCodeSY;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = unitTestcaseCodeRS;
            }
            debugger
            setLoader(() => true);
            const res = await generateTestCaseCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "unitTestcase",
                    sampleCode: sampleCode,
                },
                state.jwt
            );
            setLoader(() => false);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        else if (data?.codeType == "dataQualityCode") {

            let sampleCode;
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = dataQualityCodeDB;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = dataQualityCodeSY;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = dataQualityCodeRS;
            }

            const res = await genrateDQualityCode(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "dataQuality",
                    sampleCode: sampleCode,
                },
                state.jwt
            );
            console.log(res);
            setLoader(() => false);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }

        }
        else if (data?.codeType == "checklist") {
            setLoader(() => true);
            const res = await generateChecklistDev(
                { projectId: state.projectId, orgId: state.orgId, userId: state.userId, Methodology: state.methodology, futureStateName: state.futureStateName },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        else if (data?.codeType == "LinkedServices" || data?.codeType == "linkedServices") {
            let splCode = linkedServices;
            setLoader(() => true);
            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "linkedServices",
                    pipelineId: data?.pipelineId,
                    pipelineType: data?.pipelineType,
                    sampleCode: splCode,
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "logger") {
            debugger
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbLogger
                    : state.futureStateName == "AWS Glue"
                        ? awsLogger
                        : syLogger;
            setLoader(() => true);
            debugger
            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "logger",
                    sampleCode: splCode,
                },
                state.jwt
            );
            console.log("logger res", res);
            setLoader(() => false);
            debugger
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                debugger
                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "utility") {
            let splCode =
                state.futureStateName == "Databricks"
                    ? dbUtilities
                    : state.futureStateName == "AWS Glue"
                        ? awsUtilities
                        : syUtilities;
            setLoader(() => true);
            const res = await generateUtility(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "utilities",
                    sampleCode: splCode,
                },
                state.jwt
            );
            console.log("utilities res", res);
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });


            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        } else if (data?.codeType == "Dataset" || data?.codeType == "dataset") {
            let splCode = dataflow;
            setLoader(() => true);
            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "Dataset",
                    pipelineId: data?.pipelineId,
                    pipelineType: data?.pipelineType,
                    sampleCode: splCode,
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }
        
        // else if (data?.codeType == "DataFlow" || data?.codeType == "dataflow") {
        //     let splCode = dataflow;
        //     setLoader(() => true);
        //     const res = await generateDataflow(
        //         {
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: data?.codeType,
        //             pipelineId: data?.pipelineId,
        //             pipelineType: data?.pipelineType,
        //             sampleCode: splCode,
        //         },
        //         state.jwt
        //     );
        //     console.log("dataflow res", res);
        //     setLoader(() => false);
        //     console.log(res);
        //     if (res.status == 403) {
        //         sessionExpired()
        //         return;
        //     }
        //     if (res.status == 200) {

        //         chatData.pop();
        //         chatData[chatData.length - 1].show = true;
        //         chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

        //     } else {
        //         document.getElementById("protoastMessageSomething").style.display = "block";
        //         setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        //     }
        // } 
        
        else if (data?.codeType == "Orchestration") {
            let splCode = orchestration;
            setLoader(() => true);
            const res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: data?.codeType,
                    pipelineId: data?.pipelineId,
                    pipelineType: data?.pipelineType,
                    sampleCode: splCode,
                },
                state.jwt
            );
            setLoader(() => false);
            console.log(res);
            if (res.status == 403) {
                sessionExpired()
                return;
            }
            if (res.status == 200) {

                chatData.pop();
                chatData[chatData.length - 1].show = true;
                chatData.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' });

            } else {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
            }
        }

        setChatHistory((a) => [...chatData]);
        insertChatHistory([...chatData]);
    }



    /**
     *
     */

    //PS_CM_70 - PS_CM_661
    function checkLooksGood(codeType) {

        debugger
        console.log(designDetails, "desggggggg");
        debugger;
        if (codeType == "checklist") {
            handleDeploy();
        } else if (
            state.futureStateName == "Databricks" ||
            state.futureStateName == "Snowflake" ||
            state.futureStateName == "Redshift" ||
            state.futureStateName == "AWS Glue" ||
            (state.futureStateName == "Synapse" && state.Methodology == "notebook")
        ) {
            // Flag to indicate whether code generation is in progress
            let isProcessing = false;

            // Event listener to prevent page refresh if processing is ongoing
            window.addEventListener('beforeunload', function (e) {
                if (isProcessing) {
                    e.preventDefault();
                    e.returnValue = ''; // Display a warning message
                }
            });

            // Call the function to handle code generation
            handleCodeGeneration();

            async function handleCodeGeneration() {
                if (isProcessing) {
                    return; // Exit early if already processing
                }

                isProcessing = true; // Set flag to true to indicate processing has started

                try {
                    if (codeType === "metadataTable") {
                        debugger;
                        setShowlooksGood(() => true);

                        if ("loggerPseudocode" in designDetails) {
                            console.log("loggg");
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbLogger
                                    : state.futureStateName === "AWS Glue"
                                        ? awsLogger
                                        : syLogger;
                            await generateLoggerCode(sampleCode); // Ensure awaiting
                        } else if ("utilitiesPseudocode" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbUtilities
                                    : state.futureStateName === "AWS Glue"
                                        ? awsUtilities
                                        : syUtilities;
                            await generateUtilitiesCode(sampleCode); // Ensure awaiting
                        } else if ("metadata" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbMetaData
                                    : state.futureStateName === "AWS Glue"
                                        ? awsMetadata
                                        : state.futureStateName === "Synapse"
                                            ? syMetadata
                                            : state.futureStateName === "Snowflake"
                                                ? sfMetadata
                                                : sfMetadata;
                            await generateMetadataCode(sampleCode); // Ensure awaiting
                        } else if ("DDLScripts" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbDdl
                                    : state.futureStateName === "AWS Glue"
                                        ? awsDdl
                                        : state.futureStateName === "Synapse"
                                            ? syDdl
                                            : state.futureStateName === "Snowflake"
                                                ? sfDdl
                                                : sfDdl;
                            await generateDDLScripts(sampleCode); // Ensure awaiting
                        } else if ("Extraction" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbBronze
                                    : state.futureStateName === "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName === "Synapse"
                                            ? syBronze
                                            : state.futureStateName === "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            await generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            ); // Ensure awaiting
                        } else if ("Silver" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbSilver
                                    : state.futureStateName === "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName === "Synapse"
                                            ? sySilver
                                            : state.futureStateName === "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            await generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            ); // Ensure awaiting
                        } else if ("Gold" in designDetails) {
                            let sampleCode =
                                state.futureStateName === "Databricks"
                                    ? dbGold
                                    : state.futureStateName === "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName === "Synapse"
                                            ? syGold
                                            : state.futureStateName === "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            await generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                            // Ensure awaiting
                        } 
                        else if ("Orchestration" in designDetails) {
                            let sampleCode = orchestration;
                            await generatePipelineCode(
                                designDetails.Orchestration[0].pipelineId,
                                "Orchestration",
                                designDetails.Orchestration[0].pipelineLayer,
                                sampleCode
                            ); // Ensure awaiting
                        }
                        else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) {
                                sampleCode = unitTestcaseCodeDB;
                            } else if (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) {
                                sampleCode = unitTestcaseCodeSY;
                            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }
                            await generateUnitTestCaseCode(sampleCode); // Ensure awaiting
                        } else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) {
                                sampleCode = dataQualityCodeDB;
                            } else if (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) {
                                sampleCode = dataQualityCodeSY;
                            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }
                            await generateDataQualityCode(sampleCode); // Ensure awaiting
                        }  else {
                            await generateCodeReviewChecklist(); // Ensure awaiting
                        }
                    }
                    else if (codeType == "logger") {
                        setShowlooksGood(() => true);
                        if ("utilitiesPseudocode" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbUtilities
                                    : state.futureStateName == "AWS Glue"
                                        ? awsUtilities
                                        : syUtilities;
                            generateUtilitiesCode(sampleCode);
                        } else if ("metadata" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbMetaData
                                    : state.futureStateName == "AWS Glue"
                                        ? awsMetadata
                                        : state.futureStateName == "Synapse"
                                            ? syMetadata
                                            : state.futureStateName == "Snowflake"
                                                ? sfMetadata
                                                : sfMetadata;
                            generateMetadataCode(sampleCode);
                        } else if ("DDLScripts" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbDdl
                                    : state.futureStateName == "AWS Glue"
                                        ? awsDdl
                                        : state.futureStateName == "Synapse"
                                            ? syDdl
                                            : state.futureStateName == "Snowflake"
                                                ? sfDdl
                                                : sfDdl;
                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbBronze
                                    : state.futureStateName == "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName == "Synapse"
                                            ? syBronze
                                            : state.futureStateName == "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            );
                        } else if ("Silver" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbSilver
                                    : state.futureStateName == "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName == "Synapse"
                                            ? sySilver
                                            : state.futureStateName == "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            );
                        } else if ("Gold" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbGold
                                    : state.futureStateName == "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName == "Synapse"
                                            ? syGold
                                            : state.futureStateName == "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                        }
                        else if ("Orchestration" in designDetails) {
                            let sampleCode = orchestration;
                            generatePipelineCode(
                                designDetails.Orchestration[0].pipelineId,
                                "Orchestration",
                                designDetails.Orchestration[0].pipelineLayer,
                                sampleCode
                            );
                        }
                        else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = unitTestcaseCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = unitTestcaseCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }

                            generateDataQualityCode(sampleCode);
                        }

                        else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType == "utility") {
                        setShowlooksGood(() => true);
                        if ("metadata" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbMetaData
                                    : state.futureStateName == "AWS Glue"
                                        ? awsMetadata
                                        : state.futureStateName == "Synapse"
                                            ? syMetadata
                                            : state.futureStateName == "Snowflake"
                                                ? sfMetadata
                                                : sfMetadata;
                            generateMetadataCode(sampleCode);
                        } else if ("DDLScripts" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbDdl
                                    : state.futureStateName == "AWS Glue"
                                        ? awsDdl
                                        : state.futureStateName == "Synapse"
                                            ? syDdl
                                            : state.futureStateName == "Snowflake"
                                                ? sfDdl
                                                : sfDdl;
                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbBronze
                                    : state.futureStateName == "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName == "Synapse"
                                            ? syBronze
                                            : state.futureStateName == "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName
                            );
                        } else if ("Silver" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbSilver
                                    : state.futureStateName == "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName == "Synapse"
                                            ? sySilver
                                            : state.futureStateName == "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName
                            );
                        } else if ("Gold" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbGold
                                    : state.futureStateName == "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName == "Synapse"
                                            ? syGold
                                            : state.futureStateName == "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName
                            );
                        }
                        else if ("Orchestration" in designDetails) {
                            let sampleCode = orchestration;
                            generatePipelineCode(
                                designDetails.Orchestration[0].pipelineId,
                                "Orchestration",
                                designDetails.Orchestration[0].pipelineLayer,
                                sampleCode
                            );
                        }
                        else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = unitTestcaseCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = unitTestcaseCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }

                            generateDataQualityCode(sampleCode);
                        }



                         else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType == "metadata") {
                        setShowlooksGood(() => true);
                        if ("DDLScripts" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbDdl
                                    : state.futureStateName == "AWS Glue"
                                        ? awsDdl
                                        : state.futureStateName == "Synapse"
                                            ? syDdl
                                            : state.futureStateName == "Snowflake"
                                                ? sfDdl
                                                : sfDdl;
                            generateDDLScripts(sampleCode);
                        } else if ("Extraction" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbBronze
                                    : state.futureStateName == "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName == "Synapse"
                                            ? syBronze
                                            : state.futureStateName == "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName
                            );
                        } else if ("Silver" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbSilver
                                    : state.futureStateName == "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName == "Synapse"
                                            ? sySilver
                                            : state.futureStateName == "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName
                            );
                        } else if ("Gold" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbGold
                                    : state.futureStateName == "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName == "Synapse"
                                            ? syGold
                                            : state.futureStateName == "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName
                            );
                        }
                        else if ("Orchestration" in designDetails) {
                            let sampleCode = orchestration;
                            generatePipelineCode(
                                designDetails.Orchestration[0].pipelineId,
                                "Orchestration",
                                designDetails.Orchestration[0].pipelineLayer,
                                sampleCode
                            );
                        }
                        else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = unitTestcaseCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = unitTestcaseCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }

                            generateDataQualityCode(sampleCode);
                        }  else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType == "ddl") {
                        setShowlooksGood(() => true);
                        if ("Extraction" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbBronze
                                    : state.futureStateName == "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName == "Synapse"
                                            ? syBronze
                                            : state.futureStateName == "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            generateExtractionCode(
                                designDetails.Extraction[0].notebookId,
                                designDetails.Extraction[0].notebookName,
                                sampleCode
                            );
                        } else if ("Silver" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbSilver
                                    : state.futureStateName == "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName == "Synapse"
                                            ? sySilver
                                            : state.futureStateName == "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            generateSilverCode(
                                designDetails.Silver[0].notebookId,
                                designDetails.Silver[0].notebookName,
                                sampleCode
                            );
                        } else if ("Gold" in designDetails) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbGold
                                    : state.futureStateName == "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName == "Synapse"
                                            ? syGold
                                            : state.futureStateName == "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            generateGoldCode(
                                designDetails.Gold[0].notebookId,
                                designDetails.Gold[0].notebookName,
                                sampleCode
                            );
                        }
                        else if ("Orchestration" in designDetails) {
                            let sampleCode = orchestration;
                            generatePipelineCode(
                                designDetails.Orchestration[0].pipelineId,
                                "Orchestration",
                                designDetails.Orchestration[0].pipelineLayer,
                                sampleCode
                            );
                        }
                        else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = unitTestcaseCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = unitTestcaseCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }

                            generateDataQualityCode(sampleCode);
                        }  else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType == "Extraction" || codeType == "extraction") {
                        debugger;
                        setShowlooksGood(() => true);
                        if (designDetails.Extraction.length > 1) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbBronze
                                    : state.futureStateName == "AWS Glue"
                                        ? awsBronze
                                        : state.futureStateName == "Synapse"
                                            ? syBronze
                                            : state.futureStateName == "Snowflake"
                                                ? sfBronze
                                                : sfBronze;
                            for (let i = 0; i < designDetails.Extraction.length; i++) {
                                const extractionNotebook = designDetails.Extraction[i];
                                if (
                                    extractionNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Extraction.length &&
                                designDetails.Extraction[index + 1] != undefined
                            ) {
                                generateExtractionCode(
                                    designDetails.Extraction[index + 1].notebookId,
                                    designDetails.Extraction[index + 1].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Silver" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbSilver
                                        : state.futureStateName == "AWS Glue"
                                            ? awsSilver
                                            : state.futureStateName == "Synapse"
                                                ? sySilver
                                                : state.futureStateName == "Snowflake"
                                                    ? sfSilver
                                                    : sfSilver;
                                generateSilverCode(
                                    designDetails.Silver[0].notebookId,
                                    designDetails.Silver[0].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbGold
                                        : state.futureStateName == "AWS Glue"
                                            ? awsGold
                                            : state.futureStateName == "Synapse"
                                                ? syGold
                                                : state.futureStateName == "Snowflake"
                                                    ? sfGold
                                                    : sfGold;
                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            } else {
                                generateCodeReviewChecklist();
                            }
                        } else {
                            if ("Silver" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbSilver
                                        : state.futureStateName == "AWS Glue"
                                            ? awsSilver
                                            : state.futureStateName == "Synapse"
                                                ? sySilver
                                                : state.futureStateName == "Snowflake"
                                                    ? sfSilver
                                                    : sfSilver;
                                generateSilverCode(
                                    designDetails.Silver[0].notebookId,
                                    designDetails.Silver[0].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbGold
                                        : state.futureStateName == "AWS Glue"
                                            ? awsGold
                                            : state.futureStateName == "Synapse"
                                                ? syGold
                                                : state.futureStateName == "Snowflake"
                                                    ? sfGold
                                                    : sfGold;
                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Orchestration" in designDetails) {
                                let sampleCode = orchestration;
                                generatePipelineCode(
                                    designDetails.Orchestration[0].pipelineId,
                                    "Orchestration",
                                    designDetails.Orchestration[0].pipelineLayer,
                                    sampleCode
                                );
                            }
                            else if ("unitTestCasePseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = unitTestcaseCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = unitTestcaseCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = unitTestcaseCodeRS;
                                }

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = dataQualityCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = dataQualityCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = dataQualityCodeRS;
                                }

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        }
                    } else if (codeType == "Silver") {
                        console.log("dummy")
                        setShowlooksGood(() => true);
                        if (designDetails.Silver.length > 1) {
                            debugger;
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbSilver
                                    : state.futureStateName == "AWS Glue"
                                        ? awsSilver
                                        : state.futureStateName == "Synapse"
                                            ? sySilver
                                            : state.futureStateName == "Snowflake"
                                                ? sfSilver
                                                : sfSilver;
                            for (let i = 0; i < designDetails.Silver.length; i++) {
                                const silverNotebook = designDetails.Silver[i];
                                console.log(
                                    silverNotebook.notebookName,
                                    chatHistory[chatHistory.length - 1]
                                );
                                if (
                                    silverNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Silver.length &&
                                designDetails.Silver[index + 1] != undefined
                            ) {
                                generateSilverCode(
                                    designDetails.Silver[index + 1].notebookId,
                                    designDetails.Silver[index + 1].notebookName,
                                    sampleCode
                                );
                            } else if ("Gold" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbGold
                                        : state.futureStateName == "AWS Glue"
                                            ? awsGold
                                            : state.futureStateName == "Synapse"
                                                ? syGold
                                                : state.futureStateName == "Snowflake"
                                                    ? sfGold
                                                    : sfGold;
                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Orchestration" in designDetails) {
                                let sampleCode = orchestration;
                                generatePipelineCode(
                                    designDetails.Orchestration[0].pipelineId,
                                    "Orchestration",
                                    designDetails.Orchestration[0].pipelineLayer,
                                    sampleCode
                                );
                            } 
                            else if ("unitTestCasePseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = unitTestcaseCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = unitTestcaseCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = unitTestcaseCodeRS;
                                }

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = dataQualityCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = dataQualityCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = dataQualityCodeRS;
                                }

                                generateDataQualityCode(sampleCode);
                            } else {
                                generateCodeReviewChecklist();
                            }
                        } else {
                            if ("Gold" in designDetails) {
                                let sampleCode =
                                    state.futureStateName == "Databricks"
                                        ? dbGold
                                        : state.futureStateName == "AWS Glue"
                                            ? awsGold
                                            : state.futureStateName == "Synapse"
                                                ? syGold
                                                : state.futureStateName == "Snowflake"
                                                    ? sfGold
                                                    : sfGold;
                                generateGoldCode(
                                    designDetails.Gold[0].notebookId,
                                    designDetails.Gold[0].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Orchestration" in designDetails) {
                                let sampleCode = orchestration;
                                generatePipelineCode(
                                    designDetails.Orchestration[0].pipelineId,
                                    "Orchestration",
                                    designDetails.Orchestration[0].pipelineLayer,
                                    sampleCode
                                );
                            } 
                            else if ("unitTestCasePseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = unitTestcaseCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = unitTestcaseCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = unitTestcaseCodeRS;
                                }

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = dataQualityCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = dataQualityCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = dataQualityCodeRS;
                                }

                                generateDataQualityCode(sampleCode);
                            }
                           else {
                                generateCodeReviewChecklist();
                            }
                        }
                    } else if (codeType == "Gold") {
                        debugger;
                        setShowlooksGood(() => true);
                        if (designDetails.Gold.length > 1) {
                            let sampleCode =
                                state.futureStateName == "Databricks"
                                    ? dbGold
                                    : state.futureStateName == "AWS Glue"
                                        ? awsGold
                                        : state.futureStateName == "Synapse"
                                            ? syGold
                                            : state.futureStateName == "Snowflake"
                                                ? sfGold
                                                : sfGold;
                            for (let i = 0; i < designDetails.Gold.length; i++) {
                                const goldNotebook = designDetails.Gold[i];
                                if (
                                    goldNotebook.notebookName ===
                                    chatHistory[chatHistory.length - 1].hyperlinkText
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Gold.length &&
                                designDetails.Gold[index + 1] != undefined
                            ) {
                                generateGoldCode(
                                    designDetails.Gold[index + 1].notebookId,
                                    designDetails.Gold[index + 1].notebookName,
                                    sampleCode
                                );
                            }
                            else if ("Orchestration" in designDetails) {
                                let sampleCode = orchestration;
                                generatePipelineCode(
                                    designDetails.Orchestration[0].pipelineId,
                                    "Orchestration",
                                    designDetails.Orchestration[0].pipelineLayer,
                                    sampleCode
                                );
                            }
                            else if ("unitTestCasePseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = unitTestcaseCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = unitTestcaseCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = unitTestcaseCodeRS;
                                }

                                generateUnitTestCaseCode(sampleCode);
                            }
                            else if ("dataQualityPseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = dataQualityCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = dataQualityCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = dataQualityCodeRS;
                                }

                                generateDataQualityCode(sampleCode);
                            }
                            else {
                                generateCodeReviewChecklist();
                            }
                        } else {
                            debugger
                             if ("Orchestration" in designDetails) {
                                let sampleCode = orchestration;
                                generatePipelineCode(
                                    designDetails.Orchestration[0].pipelineId,
                                    designDetails.Orchestration[0].pipelineType,
                                    designDetails.Orchestration[0].pipelineLayer,
                                    sampleCode
                                );
                            }
                            else if ("unitTestCasePseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = unitTestcaseCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = unitTestcaseCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = unitTestcaseCodeRS;
                                }
                                debugger
                                generateUnitTestCaseCode(sampleCode);
                            }

                            else if ("dataQualityPseudocode" in designDetails) {
                                let sampleCode;
                                if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                    sampleCode = dataQualityCodeDB;
                                }
                                else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                    sampleCode = dataQualityCodeSY;
                                }
                                else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                    sampleCode = dataQualityCodeRS;
                                }

                                generateDataQualityCode(sampleCode);
                            }
                             else {
                                generateCodeReviewChecklist();
                            }
                        }
                    }
                    else if (codeType == "Orchestration") {
                        debugger;
                        setShowlooksGood(() => true);
                        if (designDetails.Orchestration.length > 1) {
                            let sampleCode = orchestration;
                            for (let i = 0; i < designDetails.Orchestration.length; i++) {
                                const orgJSON = designDetails.Orchestration[i];
                                if (
                                    orgJSON.pipelineId ===
                                    chatHistory[chatHistory.length - 1].pipelineId
                                ) {
                                    index = i;
                                }
                            }
                            if (
                                index <= designDetails.Orchestration.length &&
                                designDetails.Orchestration[index + 1] != undefined
                            ) {
                                generatePipelineCode(
                                    designDetails.Orchestration[index + 1].pipelineId,
                                    designDetails.Orchestration[index + 1].pipelineType,
                                    designDetails.Orchestration[index + 1].pipelineLayer,
                                    sampleCode
                                );
                            } 
                        }  else if ("unitTestCasePseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = unitTestcaseCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = unitTestcaseCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = unitTestcaseCodeRS;
                            }

                            generateUnitTestCaseCode(sampleCode);
                        }
                        else if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }

                            generateDataQualityCode(sampleCode);
                        }
                        else {
                            generateCodeReviewChecklist();
                        }
                    } 
                    else if (codeType == "UnitTestCase") {
                        setShowlooksGood(() => true);
                       if ("dataQualityPseudocode" in designDetails) {
                            let sampleCode;
                            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                                sampleCode = dataQualityCodeDB;
                            }
                            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                                sampleCode = dataQualityCodeSY;
                            }
                            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                                sampleCode = dataQualityCodeRS;
                            }
                            generateDataQualityCode(sampleCode);
                        }
                         else {
                            generateCodeReviewChecklist();
                        }
                    } else if (codeType == "dataQualityCode") {
                        setShowlooksGood(() => true);
                       
                            generateCodeReviewChecklist();
                        
                    }
                  else {
                        setShowlooksGood(() => true);
                        generateCodeReviewChecklist();
                    }
                } catch (error) {
                    console.error("Error during code generation:", error);
                } finally {
                    isProcessing = false; // Reset flag once processing is complete
                }
            }



        } else if (
            (state.futureStateName == "Synapse" && state.Methodology == "pipeline") ||
            state.futureStateName == "ADF"
        ) {
            console.log("iamhere");
            if (codeType == "metadataTable") {
                setShowlooksGood(() => true);
                if ("LinkedServices" in designDetails) {
                    let sampleCode = linkedServices;
                    generatePipelineCode(
                        designDetails.LinkedServices[0].pipelineId,
                        "linkedServices",
                        null,
                        sampleCode
                    );
                } else if ("DataSet" in designDetails) {
                    let sampleCode = dateset;
                    generatePipelineCode(
                        designDetails.DataSet[0].pipelineId,
                        "Dataset",
                        null,
                        sampleCode
                    );
                } else if ("Metadata" in designDetails) {
                    let sampleCode = syMetadata;
                    generateMetadataCode(sampleCode);
                } else if ("DDLPseudocode" in designDetails) {
                    let sampleCode = syDdl;
                    generateDDLScripts(sampleCode);
                } else if ("DataFlow" in designDetails) {
                    let sampleCode = dataflow;
                    generatePipelineCode(
                        designDetails.DataFlow[0].pipelineId,
                        designDetails.DataFlow[0].pipelineType,
                        designDetails.DataFlow[index + 1].pipelineLayer,
                        sampleCode
                    );
                } else if ("Extraction" in designDetails) {
                    let sampleCode = orchestration;
                    generateExtractionCode(
                        designDetails.Extraction[0].notebookId,
                        designDetails.Extraction[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Silver" in designDetails) {
                    let sampleCode = orchestration;
                    generateSilverCode(
                        designDetails.Silver[0].notebookId,
                        designDetails.Silver[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Gold" in designDetails) {
                    let sampleCode = orchestration;
                    generateGoldCode(
                        designDetails.Gold[0].notebookId,
                        designDetails.Gold[0].notebookName,
                        null,
                        sampleCode
                    );
                } 
                else if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineType,
                        sampleCode
                    );
                }
                else if ("unitTestCasePseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = unitTestcaseCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = unitTestcaseCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = unitTestcaseCodeRS;
                    }

                    generateUnitTestCaseCode(sampleCode);
                }
                else if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }

                    generateDataQualityCode(sampleCode);
                }
                 else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "linkedServices" || codeType == "LinkedServices") {
                setShowlooksGood(() => true);
                if ("DataSet" in designDetails) {
                    let sampleCode = dateset;
                    generatePipelineCode(
                        designDetails.DataSet[0].pipelineId,
                        "Dataset",
                        null,
                        sampleCode
                    );
                } else if ("metdata" in designDetails) {
                    let sampleCode = syMetadata;
                    generateMetadataCode(sampleCode);
                } else if ("DDLScripts" in designDetails) {
                    let sampleCode = syDdl;
                    generateDDLScripts(sampleCode);
                } else if ("DataFlow" in designDetails) {
                    let sampleCode = dataflow;
                    generatePipelineCode(
                        designDetails.DataFlow[0].pipelineId,
                        designDetails.DataFlow[0].pipelineType,
                        designDetails.DataFlow[index + 1].pipelineLayer,
                        sampleCode
                    );
                } else if ("Extraction" in designDetails) {
                    let sampleCode = orchestration;
                    generateExtractionCode(
                        designDetails.Extraction[0].notebookId,
                        designDetails.Extraction[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Silver" in designDetails) {
                    let sampleCode = orchestration;
                    generateSilverCode(
                        designDetails.Silver[0].notebookId,
                        designDetails.Silver[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Gold" in designDetails) {
                    let sampleCode = orchestration;
                    generateGoldCode(
                        designDetails.Gold[0].notebookId,
                        designDetails.Gold[0].notebookName,
                        null,
                        sampleCode
                    );
                }
                else if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineType,
                        sampleCode
                    );
                }
                else if ("unitTestCasePseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = unitTestcaseCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = unitTestcaseCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = unitTestcaseCodeRS;
                    }

                    generateUnitTestCaseCode(sampleCode);
                }
                else if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }

                    generateDataQualityCode(sampleCode);
                }
                 else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "Dataset" || codeType == "dataset") {
                setShowlooksGood(() => true);
                if ("metadata" in designDetails) {
                    let sampleCode = syMetadata;
                    generateMetadataCode(sampleCode);
                } else if ("DDLScripts" in designDetails) {
                    let sampleCode = syDdl;
                    generateDDLScripts(sampleCode);
                } else if ("DataFlow" in designDetails) {
                    let sampleCode = dataflow;
                    generatePipelineCode(
                        designDetails.DataFlow[0].pipelineId,
                        designDetails.DataFlow[0].pipelineType,
                        designDetails.DataFlow[index + 1].pipelineLayer,
                        sampleCode
                    );
                } else if ("Extraction" in designDetails) {
                    let sampleCode = orchestration;
                    generateExtractionCode(
                        designDetails.Extraction[0].notebookId,
                        designDetails.Extraction[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Silver" in designDetails) {
                    let sampleCode = orchestration;
                    generateSilverCode(
                        designDetails.Silver[0].notebookId,
                        designDetails.Silver[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Gold" in designDetails) {
                    let sampleCode = orchestration;
                    generateGoldCode(
                        designDetails.Gold[0].notebookId,
                        designDetails.Gold[0].notebookName,
                        null,
                        sampleCode
                    );
                }
                else if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineLayer,
                        sampleCode
                    );
                } 
                else if ("unitTestCasePseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = unitTestcaseCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = unitTestcaseCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = unitTestcaseCodeRS;
                    }

                    generateUnitTestCaseCode(sampleCode);
                }
                else if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }

                    generateDataQualityCode(sampleCode);
                } else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "metadata") {
                setShowlooksGood(() => true);
                if ("DDLScripts" in designDetails) {
                    let sampleCode = syDdl;
                    generateDDLScripts(sampleCode);
                } else if ("DataFlow" in designDetails) {
                    let sampleCode = dataflow;
                    generatePipelineCode(
                        designDetails.DataFlow[0].pipelineId,
                        designDetails.DataFlow[0].pipelineType,
                        designDetails.DataFlow[index + 1].pipelineLayer,
                        sampleCode
                    );
                } else if ("Extraction" in designDetails) {
                    let sampleCode = orchestration;
                    generateExtractionCode(
                        designDetails.Extraction[0].notebookId,
                        designDetails.Extraction[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Silver" in designDetails) {
                    let sampleCode = orchestration;
                    generateSilverCode(
                        designDetails.Silver[0].notebookId,
                        designDetails.Silver[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Gold" in designDetails) {
                    let sampleCode = orchestration;
                    generateGoldCode(
                        designDetails.Gold[0].notebookId,
                        designDetails.Gold[0].notebookName,
                        null,
                        sampleCode
                    );
                }
                else if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineLayer,
                        sampleCode
                    );
                } 
                else if ("unitTestCasePseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = unitTestcaseCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = unitTestcaseCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = unitTestcaseCodeRS;
                    }

                    generateUnitTestCaseCode(sampleCode);
                }
                else if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }

                    generateDataQualityCode(sampleCode);
                }
               else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "ddl") {
                setShowlooksGood(() => true);
                if ("DataFlow" in designDetails) {
                    let sampleCode = dataflow;
                    generatePipelineCode(
                        designDetails.DataFlow[0].pipelineId,
                        designDetails.DataFlow[0].pipelineType,
                        designDetails.DataFlow[0].pipelineLayer,
                        sampleCode
                    );
                } else if ("Extraction" in designDetails) {
                    let sampleCode = orchestration;
                    generateExtractionCode(
                        designDetails.Extraction[0].notebookId,
                        designDetails.Extraction[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Silver" in designDetails) {
                    let sampleCode = orchestration;
                    generateSilverCode(
                        designDetails.Silver[0].notebookId,
                        designDetails.Silver[0].notebookName,
                        null,
                        sampleCode
                    );
                } else if ("Gold" in designDetails) {
                    let sampleCode = orchestration;
                    generateGoldCode(
                        designDetails.Gold[0].notebookId,
                        designDetails.Gold[0].notebookName,
                        null,
                        sampleCode
                    );
                }
                else if ("Orchestration" in designDetails.Orchestration) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineLayer,
                        sampleCode
                    );
                } 
                else if ("unitTestCasePseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = unitTestcaseCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = unitTestcaseCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = unitTestcaseCodeRS;
                    }

                    generateUnitTestCaseCode(sampleCode);
                }
                else if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }

                    generateDataQualityCode(sampleCode);
                }
               else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "DataFlow" || codeType == "dataFlow") {
                setShowlooksGood(() => true);
                if (designDetails.DataFlow.length > 1) {
                    let sampleCode = dataflow;
                    for (let i = 0; i < designDetails.DataFlow.length; i++) {
                        const dataflowJSON = designDetails.DataFlow[i];
                        if (
                            dataflowJSON.pipelineId ==
                            chatHistory[chatHistory.length - 1].pipelineId
                        ) {
                            index = i;
                        }
                    }
                    if (
                        index <= designDetails.DataFlow.length &&
                        designDetails.DataFlow[index + 1] != undefined
                    ) {
                        generatePipelineCode(
                            designDetails.DataFlow[index + 1].pipelineId,
                            designDetails.DataFlow[index + 1].pipelineType,
                            designDetails.DataFlow[index + 1].pipelineLayer,
                            sampleCode
                        );
                    } else if ("Extraction" in designDetails) {
                        let sampleCode = orchestration;
                        generateExtractionCode(
                            designDetails.Extraction[0].notebookId,
                            designDetails.Extraction[0].notebookName,
                            null,
                            sampleCode
                        );
                    } else if ("Silver" in designDetails) {
                        let sampleCode = orchestration;
                        generateSilverCode(
                            designDetails.Silver[0].notebookId,
                            designDetails.Silver[0].notebookName,
                            sampleCode
                        );
                    } else if ("Gold" in designDetails) {
                        let sampleCode = orchestration;
                        generateGoldCode(
                            designDetails.Gold[0].notebookId,
                            designDetails.Gold[0].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                    else {
                        generateCodeReviewChecklist();
                    }
                }
            } else if (codeType == "Extraction" || codeType == "extraction") {
                setShowlooksGood(() => true);
                if (designDetails.Extraction.length > 1) {
                    let sampleCode = orchestration;
                    for (let i = 0; i < designDetails.Extraction.length; i++) {
                        const extractionNotebook = designDetails.Extraction[i];
                        if (
                            extractionNotebook.notebookName ===
                            chatHistory[chatHistory.length - 1].hyperlinkText
                        ) {
                            index = i;
                        }
                    }
                    if (
                        index <= designDetails.Extraction.length &&
                        designDetails.Extraction[index + 1] != undefined
                    ) {
                        generateExtractionCode(
                            designDetails.Extraction[index + 1].notebookId,
                            designDetails.Extraction[index + 1].notebookName,
                            sampleCode
                        );
                    } else if ("Silver" in designDetails) {
                        let sampleCode = orchestration;
                        generateSilverCode(
                            designDetails.Silver[0].notebookId,
                            designDetails.Silver[0].notebookName,
                            sampleCode
                        );
                    } else if ("Gold" in designDetails) {
                        let sampleCode = orchestration;
                        generateGoldCode(
                            designDetails.Gold[0].notebookId,
                            designDetails.Gold[0].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                    else {
                        generateCodeReviewChecklist();
                    }
                } else {
                    if ("Silver" in designDetails) {
                        let sampleCode = orchestration;
                        generateSilverCode(
                            designDetails.Silver[0].notebookId,
                            designDetails.Silver[0].notebookName,
                            sampleCode
                        );
                    } else if ("Gold" in designDetails) {
                        let sampleCode = orchestration;
                        generateGoldCode(
                            designDetails.Gold[0].notebookId,
                            designDetails.Gold[0].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineTypenull,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                    else {
                        generateCodeReviewChecklist();
                    }
                }
            } else if (codeType == "Silver") {
                setShowlooksGood(() => true);
                if (designDetails.Silver.length > 1) {
                    let sampleCode = orchestration;
                    for (let i = 0; i < designDetails.Silver.length; i++) {
                        const silverNotebook = designDetails.Silver[i];
                        if (
                            silverNotebook.notebookName ===
                            chatHistory[chatHistory.length - 1].hyperlinkText
                        ) {
                            index = i;
                        }
                    }
                    if (
                        index <= designDetails.Silver.length &&
                        designDetails.Silver[index + 1] != undefined
                    ) {
                        generateSilverCode(
                            designDetails.Silver[index + 1].notebookId,
                            designDetails.Silver[index + 1].notebookName,
                            sampleCode
                        );
                    } else if ("Gold" in designDetails) {
                        let sampleCode = orchestration;
                        generateGoldCode(
                            designDetails.Gold[0].notebookId,
                            designDetails.Gold[0].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                     else {
                        generateCodeReviewChecklist();
                    }
                } else {
                    if ("Gold" in designDetails) {
                        let sampleCode = orchestration;
                        generateGoldCode(
                            designDetails.Gold[0].notebookId,
                            designDetails.Gold[0].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                     else {
                        generateCodeReviewChecklist();
                    }
                }
            } else if (codeType == "Gold") {
                debugger;
                setShowlooksGood(() => true);
                if (designDetails.Gold.length > 1) {
                    let sampleCode = orchestration;
                    for (let i = 0; i < designDetails.Gold.length; i++) {
                        const GoldNotebook = designDetails.Gold[i];
                        if (
                            GoldNotebook.notebookName ===
                            chatHistory[chatHistory.length - 1].hyperlinkText
                        ) {
                            index = i;
                        }
                    }
                    if (
                        index <= designDetails.Gold.length &&
                        designDetails.Gold[index + 1] != undefined
                    ) {
                        generateGoldCode(
                            designDetails.Gold[index + 1].notebookId,
                            designDetails.Gold[index + 1].notebookName,
                            sampleCode
                        );
                    }
                    else if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    } 
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }
                    else {
                        generateCodeReviewChecklist();
                    }
                } else {
                     if ("Orchestration" in designDetails) {
                        let sampleCode = orchestration;
                        generatePipelineCode(
                            designDetails.Orchestration[0].pipelineId,
                            designDetails.Orchestration[0].pipelineType,
                            designDetails.Orchestration[0].pipelineLayer,
                            sampleCode
                        );
                    }
                    else if ("unitTestCasePseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = unitTestcaseCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = unitTestcaseCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = unitTestcaseCodeRS;
                        }

                        generateUnitTestCaseCode(sampleCode);
                    }
                    else if ("dataQualityPseudocode" in designDetails) {
                        let sampleCode;
                        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                            sampleCode = dataQualityCodeDB;
                        }
                        else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                            sampleCode = dataQualityCodeSY;
                        }
                        else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                            sampleCode = dataQualityCodeRS;
                        }

                        generateDataQualityCode(sampleCode);
                    }

                    else {
                        generateCodeReviewChecklist();
                    }
                }
            }
            else if (codeType == "UnitTestCase") {
                setShowlooksGood(() => true);
                if ("dataQualityPseudocode" in designDetails) {
                    let sampleCode;
                    if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                        sampleCode = dataQualityCodeDB;
                    }
                    else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                        sampleCode = dataQualityCodeSY;
                    }
                    else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                        sampleCode = dataQualityCodeRS;
                    }
                    generateDataQualityCode(sampleCode);
                }
                else if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineLayer,
                        sampleCode
                    );
                } else {
                    generateCodeReviewChecklist();
                }
            } else if (codeType == "dataQualityCode") {
                setShowlooksGood(() => true);
                if ("Orchestration" in designDetails) {
                    let sampleCode = orchestration;
                    generatePipelineCode(
                        designDetails.Orchestration[0].pipelineId,
                        "Orchestration",
                        designDetails.Orchestration[0].pipelineLayer,
                        sampleCode
                    );
                }
                else {
                    generateCodeReviewChecklist();
                }
            }
            else if (codeType == "Orchestration") {
                setShowlooksGood(() => true);
                if (designDetails.Orchestration.length > 1) {
                    let sampleCode = orchestration;
                    for (let i = 0; i < designDetails.Orchestration.length; i++) {
                        const orgJSON = designDetails.Orchestration[i];
                        if (
                            orgJSON.pipelineId ===
                            chatHistory[chatHistory.length - 1].pipelineId
                        ) {
                            index = i;
                        }
                    }
                    if (
                        index <= designDetails.Orchestration.length &&
                        designDetails.Orchestration[index + 1] != undefined
                    ) {
                        generatePipelineCode(
                            designDetails.Orchestration[index + 1].pipelineId,
                            designDetails.Orchestration[index + 1].pipelineType,
                            designDetails.Orchestration[index + 1].pipelineLayer,
                            sampleCode
                        );
                    } else {
                        generateCodeReviewChecklist();
                    }
                } else {
                    generateCodeReviewChecklist();
                }
            } else {
                setShowlooksGood(() => true);
                generateCodeReviewChecklist();
            }
        }
    }


    //Pseudocode looks good button functionality
    // PS_108- PS_610
    async function checkPseudocodeLooksGood(pseudocodeType) {
        debugger
        let sampleCode;
        let data;
        let eachData;
        let gold;
        let transformation;
        let extraction;

        if (pseudocodeType === "extractionRequirement") {
            generateBronzeER();
        }

        else if (pseudocodeType === "sourceToExtractionER") {
            handleSilverER()
        }
        else if (pseudocodeType === "BronzeToSilverER") {
            handleGoldER()
        }
        else if (pseudocodeType === "SilverToGoldER") {
            handleFRDPopup()
        }

        else if (pseudocodeType === "dataModelSilver") {
            generateDataModelGold();
        }
        else if (pseudocodeType === "dataModelGold") {
            sampleCode = silverDD;
            generateDataDictionarySilver(sampleCode);
        }
        else if (pseudocodeType === "dataDictionarySilver") {
            sampleCode = goldDD;
            generateDataDictionaryGold(sampleCode);
        }

        else if (pseudocodeType === "dataDictionaryGold") {
            sampleCode = metadataTable;
            generateMetadataTable(sampleCode);

        }
        else if (pseudocodeType === "metadataTable") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            generateDDLBronzePseudo(sampleCode);
        }
        else if (pseudocodeType === "dDLScriptsBronze") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            generateDDLSilverPseudo(sampleCode);
        }
        else if (pseudocodeType === "dDLScriptsSilver") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            generateDDLGoldPseudo(sampleCode);
        }

        else if (pseudocodeType === "dDLScriptsGold") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = pipelineSummaryNotebook;
            }
            else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = pipelineSummaryPipeline;
            }
            else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = pipelineSummaryProcedure;
            }
            generatePipelineSummary(sampleCode);
        }

        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "ADF" || state.futureStateName === "Synapse") {
            if (pseudocodeType == "pipelineSummary") {
                generateOrchestrationUML();
            }
        }


        //Here the generation flow splits based on the target system : Notebook flow

        if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {

            debugger
            console.log(defineDetails, "defineDetails.......");
            let genericRequirements;
            let parsedDefineDetails = defineDetails;

            if (parsedDefineDetails.genericRequirements && typeof parsedDefineDetails.genericRequirements === 'string') {
                genericRequirements = JSON.parse(parsedDefineDetails.genericRequirements);
            } else {
                genericRequirements = parsedDefineDetails.genericRequirements; // If it's already an object, assign it directly
            }

            let isLogger = genericRequirements?.filter(item => item.Requirements.includes("Logger"));
            let isUtility = genericRequirements?.filter(item => item.Requirements.includes("Utility"));

            console.log(isLogger, "isLogger");
            console.log(isUtility, "isUtility");
            if (pseudocodeType === "orchestrationUML") {
                // Disable refresh
                window.onbeforeunload = function () {
                    return "Processing is still ongoing. Are you sure you want to leave?";
                };

                try {
                    debugger
                    sampleCode = extractionseqNotebook;

                    // To fetch pipelineSummary from Database
                    let response = await fetchPseudocodeData({
                        projectId: state.projectId,
                        orgId: state.orgId,
                        userId: state.userId,
                        type: "pipelineSummary",
                    }, state.jwt);

                    if (response.statusCode === 200) {
                        let data1 = JSON.parse(response.data[0].pipelineSummary);
                        let data = [...chatHistory];
                        data[2].pipelineSummaryData = JSON.stringify(data1);

                        setChatHistory((a) => [...data]);
                        insertChatHistory([...data]);

                        let extraction = data1.filter(obj =>
                            obj.NotebookType.toLowerCase().includes("extraction") && !obj.NotebookType.toLowerCase().includes("orchestration")
                        );

                        data[2].extractionSeqData = JSON.stringify(extraction);
                        setChatHistory((a) => [...data]);
                        insertChatHistory([...data]);

                        generateExtractionSequence(extraction[0], sampleCode);
                    } else {
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    }
                } catch (error) {
                    console.error("Error processing orchestration UML:", error);
                } finally {
                    // Re-enable refresh once all await calls are done
                    window.onbeforeunload = null;
                }

            } else if (pseudocodeType === "extractionSequence") {
                let isOperationInProgress = false; // Flag to indicate ongoing operation

                // Disable refresh/navigation
                window.onbeforeunload = function (e) {
                    if (isOperationInProgress) {
                        const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                        e.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                try {
                    isOperationInProgress = true; // Set the flag to true when starting the operation
                    debugger;
                    sampleCode = extractionseqNotebook;

                    if (extractionSeqData.length > 1) {
                        for (let i = 0; i < extractionSeqData.length; i++) {
                            let eachData = extractionSeqData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                            }
                        }
                        if (index <= extractionSeqData.length &&
                            extractionSeqData[index + 1] !== undefined) {
                            await generateExtractionSequence(extractionSeqData[index + 1], sampleCode);
                        } else {
                            sampleCode = transformationseqNotebook;

                            let transformation = pipelineSummaryData.filter(obj => {
                                const notebookType = obj.NotebookType.toLowerCase();
                                return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
                            });

                            let data = [...chatHistory];
                            data[2].transformationSeqData = JSON.stringify(transformation);
                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);
                            await generateTransformationSequence(transformation[0], sampleCode);
                        }
                    } else {
                        sampleCode = transformationseqNotebook;

                        let transformation = pipelineSummaryData.filter(obj => {
                            const notebookType = obj.NotebookType.toLowerCase();
                            return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestrationuml");
                        });

                        let data = [...chatHistory];
                        data[2].transformationSeqData = JSON.stringify(transformation);
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        await generateTransformationSequence(transformation[0], sampleCode);
                    }
                } catch (error) {
                    console.error("Error processing extraction sequence:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    window.onbeforeunload = null; // Re-enable refresh/navigation
                }
            }

            else if (pseudocodeType === "transformationSequence") {
                let isOperationInProgress = false; // Flag to indicate ongoing operation

                // Disable refresh/navigation
                window.onbeforeunload = function (e) {
                    if (isOperationInProgress) {
                        const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                        e.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                try {
                    isOperationInProgress = true; // Set the flag to true when starting the operation
                    sampleCode = transformationseqNotebook;
                    if (transformationSeqData.length > 1) {
                        for (let i = 0; i < transformationSeqData.length; i++) {
                            let eachData = transformationSeqData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                            }
                        }
                        if (index <= transformationSeqData.length &&
                            transformationSeqData[index + 1] !== undefined) {
                            await generateTransformationSequence(transformationSeqData[index + 1], sampleCode);
                        } else {
                            sampleCode = goldNotebookSeq;

                            let gold = pipelineSummaryData.filter(obj => {
                                const notebookType = obj.NotebookType.toLowerCase();
                                return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                            });

                            let data = [...chatHistory];
                            data[2].goldData = JSON.stringify(gold);
                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);
                            await generateTransformationGoldSequence(gold[0], sampleCode);
                        }
                    } else {
                        sampleCode = goldNotebookSeq;

                        let gold = pipelineSummaryData.filter(obj => {
                            const notebookType = obj.NotebookType.toLowerCase();
                            return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
                        });

                        let data = [...chatHistory];
                        data[2].goldData = JSON.stringify(gold);
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);
                        await generateTransformationGoldSequence(gold[0], sampleCode);
                    }
                } catch (error) {
                    console.error("Error processing transformation sequence:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    window.onbeforeunload = null; // Re-enable refresh/navigation
                }
            }

            else if (pseudocodeType === "goldSequence") {
                let isOperationInProgress = false; // Flag to indicate ongoing operation

                // Disable refresh/navigation
                window.onbeforeunload = function (e) {
                    if (isOperationInProgress) {
                        const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                        e.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                try {
                    isOperationInProgress = true; // Set the flag to true when starting the operation
                    sampleCode = goldNotebookSeq;
                    let index;

                    if (goldData.length >= 1) {
                        for (let i = 0; i < goldData.length; i++) {
                            let eachData = goldData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                                break; // Exit the loop once index is found
                            }
                        }

                        if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
                            await generateTransformationGoldSequence(goldData[index + 1], sampleCode);
                        } else {
                            sampleCode = loggerNotebook;
                            await generateLogger(sampleCode);
                        }
                    } else {
                        sampleCode = loggerNotebook;
                        await generateLogger(sampleCode);
                    }
                } catch (error) {
                    console.error("Error processing gold sequence:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    window.onbeforeunload = null; // Re-enable refresh/navigation
                }
            }


            else if ((pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") && isLogger?.length !== 0) {
                sampleCode = loggerNotebook;
                generateLogger(sampleCode);
            } else if ((pseudocodeType === "loggerPseudocode" || pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") && isUtility?.length !== 0) {
                sampleCode = utilityNotebook;
                generateUtilityPseudo(sampleCode);
            } else if (pseudocodeType === "utilityPseudocode" || pseudocodeType === "loggerPseudocode" || pseudocodeType === "orchestrationUML" || pseudocodeType === "transformationSequence" || pseudocodeType === "extractionSequence" || pseudocodeType === "goldSequence") {
                // Disable refresh
                window.onbeforeunload = function () {
                    return "Processing is still ongoing. Are you sure you want to leave?";
                };

                sampleCode = extractionNotebook;

                // Fetch pipelineSummary from Database
                let response = await fetchPseudocodeData({
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: "pipelineSummary",
                }, state.jwt);

                if (response.statusCode === 200) {
                    let data1 = JSON.parse(response.data[0].pipelineSummary);
                    let data = [...chatHistory];

                    data[2].pipelineSummaryData = JSON.stringify(data1);
                    setChatHistory(a => [...data]);
                    await insertChatHistory([...data]);

                    setTimeout(() => {
                        console.log("Timeout completed after 50 seconds");
                    }, 30000);

                    let extraction = data1.filter(obj =>
                        obj.NotebookType.toLowerCase().includes("extraction") && !obj.NotebookType.toLowerCase().includes("orchestration")
                    );
                    let bronzeId = 0;
                    data[2].extractionData = JSON.stringify(extraction);

                    for (let i = 0; i < chatHistory.length; i++) {
                        if (extraction[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                            bronzeId = chatHistory[i]?.notebookId;
                        }
                    }
                    setChatHistory(a => [...data]);
                    await insertChatHistory([...data]);

                    setTimeout(() => {
                        console.log("Timeout 2nd completed after 50 seconds");
                    }, 30000);

                    generateExtraction(extraction[0], sampleCode, bronzeId);
                } else {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => {
                        document.getElementById("protoastMessageSomething").style.display = "none";
                    }, 3000);
                }

                // Re-enable refresh once all await calls are done
                window.onbeforeunload = null;
            }

            else if (pseudocodeType === "extraction") {
                let sampleCode = extractionNotebook;
                let bronzeIDD = 0;

                // Disable refresh
                window.onbeforeunload = function () {
                    return "Processing is still ongoing. Are you sure you want to leave?";
                };

                try {
                    if (extractionData.length > 1) {
                        for (let i = 0; i < extractionData.length; i++) {
                            let eachData = extractionData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                                break; // Exit the loop once index is found
                            }
                        }

                        if (index !== undefined && index <= extractionData.length - 2 && extractionData[index + 1] !== undefined) {
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (extractionData[index + 1].NotebookName == chatHistory[i]?.hyperlinkText) {
                                    bronzeIDD = chatHistory[i]?.notebookId;
                                }
                            }
                            setTimeout(() => {
                                console.log("Timeout completed after 50 seconds");
                            }, 50000);

                            await generateExtraction(extractionData[index + 1], sampleCode, bronzeIDD);
                        } else {
                            sampleCode = transformationNotebook;

                            let transformation = pipelineSummaryData.filter(obj =>
                                (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                                !obj.NotebookType.toLowerCase().includes("orchestration")
                            );

                            let data = [...chatHistory];
                            let silverId = 0;

                            data[2].transformationData = JSON.stringify(transformation);
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (transformation[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    silverId = chatHistory[i]?.notebookId;
                                }
                            }

                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);

                            setTimeout(() => {
                                console.log("Timeout completed after 50 seconds");
                            }, 50000);

                            await generateTransformationSilver(transformation[0], sampleCode, silverId);
                        }
                    } else {
                        sampleCode = transformationNotebook;

                        let transformation = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("silver transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );

                        let data = [...chatHistory];
                        let silverPseudoId = 0;

                        data[2].transformationData = JSON.stringify(transformation);
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);

                        setTimeout(() => {
                            console.log("Timeout completed after 50 seconds");
                        }, 50000);

                        for (let i = 0; i < data.length; i++) {
                            if (transformation[0].NotebookName == data[i]?.hyperlinkText) {
                                silverPseudoId = data[i]?.notebookId;
                            }
                        }

                        await generateTransformationSilver(transformation[0], sampleCode, silverPseudoId);
                    }
                } catch (error) {
                    console.error("Error processing extraction:", error);
                } finally {
                    // Re-enable refresh once all await calls are done
                    window.onbeforeunload = null;
                }
            }

            else if (pseudocodeType === "transformation") {
                let sampleCode = transformationNotebook;
                let data = [...chatHistory];
                let tempID = 0;
                let isOperationInProgress = false;

                // Disable refresh/navigation
                window.onbeforeunload = function () {
                    if (isOperationInProgress) {
                        return "Processing is still ongoing. Are you sure you want to leave?";
                    }
                };

                try {
                    isOperationInProgress = true; // Set the flag to true when starting the operation

                    if (transformationData.length > 1) {
                        for (let i = 0; i < transformationData.length; i++) {
                            let eachData = transformationData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                                break; // Exit the loop once index is found
                            }
                        }

                        if (index !== undefined && index <= transformationData.length - 2 && transformationData[index + 1] !== undefined) {
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (transformationData[index + 1].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    tempID = chatHistory[i]?.notebookId;
                                }
                            }

                            await generateTransformationSilver(transformationData[index + 1], sampleCode, tempID);
                        } else {
                            sampleCode = goldNotebook;

                            let gold = pipelineSummaryData.filter(obj =>
                                (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                                !obj.NotebookType.toLowerCase().includes("orchestration")
                            );

                            let goldId1 = 0;
                            data[2].goldData = JSON.stringify(gold);
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    goldId1 = chatHistory[i]?.notebookId;
                                }
                            }

                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);

                            await generateGold(gold[0], sampleCode, goldId1);
                        }
                    } else {
                        sampleCode = goldNotebook;

                        let gold = pipelineSummaryData.filter(obj =>
                            (obj.NotebookType.toLowerCase().includes("gold transformation")) &&
                            !obj.NotebookType.toLowerCase().includes("orchestration")
                        );

                        let goldID = 0;
                        data[2].goldData = JSON.stringify(gold);
                        for (let i = 0; i < chatHistory.length; i++) {
                            if (gold[0].NotebookName === chatHistory[i]?.hyperlinkText) {
                                goldID = chatHistory[i]?.notebookId;
                            }
                        }

                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);

                        await generateGold(gold[0], sampleCode, goldID);
                    }
                } catch (error) {
                    console.error("Error processing transformation:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    // Re-enable refresh once all await calls are done
                    window.onbeforeunload = null;
                }
            }


            else if (pseudocodeType === "gold") {
                let isOperationInProgress = false; // Flag to indicate an ongoing operation

                // Block refresh/navigation
                window.onbeforeunload = function (e) {
                    if (isOperationInProgress) {
                        const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                        e.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                try {
                    debugger;
                    console.log(goldData, "goldData");
                    sampleCode = goldNotebook;
                    debugger;
                    let data = [...chatHistory];
                    let goldPseudoId = 0;
                    isOperationInProgress = true; // Set the flag to true when starting the operation

                    if (goldData.length >= 1) {
                        for (let i = 0; i < goldData.length; i++) {
                            let eachData = goldData[i];
                            if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
                                index = i;
                                break; // Exit the loop once index is found
                            }
                        }

                        if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
                            for (let i = 0; i < chatHistory.length; i++) {
                                if (goldData[index + 1].NotebookName === chatHistory[i]?.hyperlinkText) {
                                    goldPseudoId = chatHistory[i]?.notebookId;
                                }
                            }
                            debugger;
                            await generateGold(goldData[index + 1], sampleCode, goldPseudoId);
                        } else {
                            sampleCode = orchestractionDocNotebook;
                            debugger;
                            let orchestrationFilter = pipelineSummaryData.filter(obj =>
                                obj.NotebookType.toLowerCase()?.includes("master_orchestration") || obj.NotebookType.toLowerCase()?.includes("master orchestration")
                            );
                            let orchestrationPipelineName = [];
                            orchestrationFilter.forEach(orchestrationObj => {
                                const pipelineName = orchestrationObj.PipelineName;
                                if (!orchestrationPipelineName.includes(pipelineName)) {
                                    orchestrationPipelineName.push(pipelineName);
                                }
                            });

                            let data = [...chatHistory];
                            data[2].orchestrationData = orchestrationPipelineName;
                            setChatHistory(a => [...data]);
                            await insertChatHistory([...data]);

                            await generateOrchestrationDoc(orchestrationFilter[0], sampleCode, "");
                        }
                    }else {
                        sampleCode = orchestractionDocNotebook;
                        debugger;
                        let orchestrationFilter = pipelineSummaryData.filter(obj =>
                            obj.NotebookType.toLowerCase()?.includes("master_orchestration") || obj.NotebookType.toLowerCase()?.includes("master orchestration")
                        );
                        let orchestrationPipelineName = [];
                        orchestrationFilter.forEach(orchestrationObj => {
                            const pipelineName = orchestrationObj.PipelineName;
                            if (!orchestrationPipelineName.includes(pipelineName)) {
                                orchestrationPipelineName.push(pipelineName);
                            }
                        });

                        let data = [...chatHistory];
                        data[2].orchestrationData = orchestrationPipelineName;
                        setChatHistory(a => [...data]);
                        await insertChatHistory([...data]);

                        await generateOrchestrationDoc(orchestrationFilter[0], sampleCode, "");
                    } 
                } catch (error) {
                    console.error("Error processing gold transformation:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    // Re-enable refresh/navigation after operations complete
                    window.onbeforeunload = null;
                }
            }
            else if (pseudocodeType === "orchestrationDocument") {
                let isOperationInProgress = false; // Flag to indicate an ongoing operation

                // Block refresh/navigation
                window.onbeforeunload = function (e) {
                    if (isOperationInProgress) {
                        const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                        e.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                try {
                    isOperationInProgress = true; // Set the flag to true when starting the operation
                    sampleCode = orchestractionDocNotebook;
                    debugger;
                    // console.log(orchestrationData, "orchestrationData");

                    // if (orchestrationData.length > 1) {
                    //     for (let i = 0; i < orchestrationData.length; i++) {
                    //         let eachData = orchestrationData[i];
                    //         if (eachData === chatHistory[chatHistory.length - 1].hyperlinkText) {
                    //             index = i;
                    //             break; // Exit the loop once index is found
                    //         }
                    //     }
                    //     debugger;
                    //     if (index !== undefined && index <= orchestrationData.length - 2 && orchestrationData[index + 1] !== undefined) {
                    //         let orchestrationFilter = pipelineSummaryData.filter(obj =>
                    //             obj.NotebookType.toLowerCase().includes("master_orchestration")
                    //         );
                    //         await generateOrchestrationDoc(orchestrationFilter[index + 1], sampleCode, orchestrationData[index + 1]);
                    //     } else {
                    //         sampleCode = unitTestCasePseudoNotebook;
                    //         await generateUnitTestCase(sampleCode);
                    //     }
                    // } else {
                        sampleCode = unitTestCasePseudoNotebook;
                        await generateUnitTestCase(sampleCode);
                    // }
                } catch (error) {
                    console.error("Error processing orchestration document:", error);
                } finally {
                    isOperationInProgress = false; // Reset the flag once all await calls are done
                    // Re-enable refresh/navigation after operations complete
                    window.onbeforeunload = null;
                }
            }

            else if (pseudocodeType === "unitTestPseudo") {
                sampleCode = dataQualityPseudoNotebook;
                generateDataQuality(sampleCode);
            } else if (pseudocodeType === "dataQualityPsueudocode") {
                sampleCode = codeReviewChecklistDocNotebook;
                generateCodeReviewChecklistDesign(sampleCode);
            } else if (pseudocodeType === "codeReviewChecklist") {
                generateConsolidateDesignDoc();
            }





        }//notebook flow end
        // if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
        //     if (pseudocodeType === "orchestrationUML" ) {
        //         debugger
        //         sampleCode = extractionseqPipeline;

        //         //To fetch pipelineSummary from Database
        //         let response = await fetchPseudocodeData({
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: "pipelineSummary",
        //         }, state.jwt
        //         );

        //         if (response.statusCode === 200) {

        //             debugger
        //             let data1 = JSON.parse(response.data[0].pipelineSummary);
        //             debugger
        //             console.log(data1, "data1");

        //             let data = [...chatHistory];
        //             data[2].pipelineSummaryData = JSON.stringify(data1);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);

        //             console.log(chatHistory, "chatHistory");
        //             debugger
        //             let extraction = data1.filter(obj =>
        //                 obj.NotebookType.toLowerCase().includes("extraction") && !obj.NotebookType.toLowerCase().includes("orchestration")
        //             );

        //             console.log(extraction, "extr");
        //             debugger
        //             data[2].extractionData = JSON.stringify(extraction);
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             debugger
        //             generateExtractionSequence(extraction[0], sampleCode)

        //         } else {
        //             document.getElementById("protoastMessageSomething").style.display = "block";
        //             setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        //         }

        //     }
        //     else if (pseudocodeType === "extractionSequence") {
        //         debugger
        //         sampleCode = extractionseqPipeline;

        //         if (extractionData.length > 1) {
        //             for (let i = 0; i < extractionData.length; i++) {
        //                 let eachData = extractionData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= extractionData.length &&
        //                 extractionData[index + 1] !== undefined) {
        //                 generateExtractionSequence(extractionData[index + 1], sampleCode)
        //             }
        //             //for textting
        //             else {
        //                 debugger
        //                 sampleCode = transformationSeqPipeline;

        //                 // let transformation = pipelineSummaryData.filter(obj => obj.NotebookType.toLowerCase().includes(("silver") || ("transformation")) && !obj.NotebookType.toLowerCase().includes("orchestration"));
        //                 let transformation = pipelineSummaryData.filter(obj => {
        //                     const notebookType = obj.NotebookType.toLowerCase();
        //                     return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestration");
        //                 });


        //                 let data = [...chatHistory];
        //                 data[2].transformationData = JSON.stringify(transformation)
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateTransformationSequence(transformation[0], sampleCode)
        //             }
        //         }
        //         else {
        //             debugger
        //             sampleCode = transformationSeqPipeline;

        //             // let transformation = pipelineSummaryData.filter(obj => obj.NotebookType.includes(("silver") || ("transformation")) && !obj.NotebookType.toLowerCase().includes("orchestration"));
        //             let transformation = pipelineSummaryData.filter(obj => {
        //                 const notebookType = obj.NotebookType.toLowerCase();
        //                 return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestration");
        //             });

        //             let data = [...chatHistory];
        //             data[2].transformationData = JSON.stringify(transformation)
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationSequence(transformation[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "transformationSequence") {
        //         console.log(transformationData, "transformationData");

        //         sampleCode = transformationSeqPipeline;
        //         if (transformationData.length > 1) {
        //             for (let i = 0; i < transformationData.length; i++) {
        //                 debugger
        //                 let eachData = transformationData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= transformationData.length &&
        //                 transformationData[index + 1] !== undefined) {
        //                 generateTransformationSequence(transformationData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = goldSeqPipeline;

        //                 let gold = pipelineSummaryData.filter(obj => {
        //                     const notebookType = obj.NotebookType.toLowerCase();
        //                     return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
        //                 });

        //                 let data = [...chatHistory];
        //                 data[2].goldData = JSON.stringify(gold);
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);

        //                 generateTransformationGoldSequence(gold[0], sampleCode)
        //             }
        //         }
        //         else {
        //             sampleCode = goldSeqPipeline;
        //             let data = [...chatHistory];
        //             data[2].goldData = JSON.stringify(gold);
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationGoldSequence(gold[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "goldSequence") {
        //         debugger;
        //         console.log(goldData, "goldData");
        //         sampleCode = goldSeqPipeline;
        //         let index;

        //         if (goldData.length > 1) {
        //             for (let i = 0; i < goldData.length; i++) {
        //                 let eachData = goldData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                     break; // Exit the loop once index is found
        //                 }
        //             }

        //             if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
        //                 debugger;
        //                 generateTransformationGoldSequence(goldData[index + 1], sampleCode);
        //             } else {

        //                 sampleCode = linkedSericePipeline;
        //                 generateLinkedServicePseudo(sampleCode);
        //             }
        //         } else {

        //             sampleCode = linkedSericePipeline;
        //             generateLinkedServicePseudo(sampleCode);
        //         }
        //     } 
        //     if (pseudocodeType === "orchestrationUML" ||pseudocodeType === "transformationSequence"||pseudocodeType === "extractionSequence"|| pseudocodeType === "goldSequence") {
        //         sampleCode = linkedSericePipeline;
        //         generateLinkedServicePseudo(sampleCode);
        //     }
        //     else if (pseudocodeType === "linkedServices") {
        //         sampleCode = datasetPipeline;
        //         generateDatasetsPseudo(sampleCode);
        //     }
        //     else if (pseudocodeType === "dataset") {
        //         sampleCode = extractionPipeline;

        //         //To fetch pipelineSummary from Database
        //         const response = await fetchPseudocodeData({
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: "pipelineSummary",
        //         }, state.jwt
        //         );

        //         if (response.statusCode === 200) {
        //             let pipelineSummaryData = JSON.parse(response.data[0].pipelineSummary);
        //             debugger


        //             let data = [...chatHistory];
        //             data[2].pipelineSummaryData = JSON.stringify(pipelineSummaryData);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);

        //             console.log(chatHistory, "chatHistory");

        //             let extraction = pipelineSummaryData.filter(obj =>
        //                 obj.PipelineType.toLowerCase().includes("extraction") && !obj.PipelineType.toLowerCase().includes("orchestration")
        //             );

        //             data[2].extractionData = JSON.stringify(extraction);
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);

        //              generateExtraction(extraction[0], sampleCode)

        //         } else {
        //             document.getElementById("protoastMessageSomething").style.display = "block";
        //             setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        //         }

        //     }
        //     else if (pseudocodeType === "extraction") {
        //         sampleCode = extractionPipeline;
        //         if (extractionData.length > 1) {
        //             for (let i = 0; i < extractionData.length; i++) {
        //                 let eachData = extractionData[i];
        //                 if (eachData["Dataflow/PipelineName"] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= extractionData.length &&
        //                 extractionData[index + 1] !== undefined) {
        //                 generateExtraction(extractionData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = transformationPipeline;

        //                 let transformation = pipelineSummaryData.filter(obj => {
        //                     const pipelineType = obj.PipelineType.toLowerCase();
        //                     return (pipelineType.includes("silver transformation")) && !pipelineType.includes("orchestration");
        //                 });
        //                 // setTransformationData(...transformation);
        //                 let data = [...chatHistory];
        //                 data[2].transformationData = JSON.stringify(transformation)
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateTransformationSilver(transformation[0], sampleCode)

        //             }
        //         }
        //         else {
        //             sampleCode = transformationPipeline;

        //             let transformation = pipelineSummaryData.filter(obj => {
        //                 const pipelineType = obj.PipelineType.toLowerCase();
        //                 return (pipelineType.includes("silver transformation")) && !pipelineType.includes("orchestration");
        //             });

        //             // setTransformationData(...transformation);
        //             let data = [...chatHistory];
        //             data[2].transformationData = JSON.stringify(transformation)
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationSilver(transformation[0], sampleCode)

        //         }
        //     }
        //     else if (pseudocodeType === "transformation") {
        //         sampleCode = transformationPipeline;
        //         if (transformationData.length > 1) {
        //             for (let i = 0; i < transformationData.length; i++) {
        //                 let eachData = transformationData[i];
        //                 if (eachData["Dataflow/PipelineName"] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= transformationData.length &&
        //                 transformationData[index + 1] !== undefined) {
        //                 generateTransformationSilver(transformationData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = goldPipeline;

        //                 let goldFiltered = pipelineSummaryData.filter(obj => obj.PipelineType.toLowerCase().includes("gold transformation") && !obj.PipelineType.toLowerCase().includes("orchestration"));
        //                 let gold = goldFiltered.map(obj => {
        //                     if (obj["Dataflow/PipelineName"]?.startsWith("DF_")) {
        //                         return { ...obj, ["Dataflow/PipelineName"]: obj["Dataflow/PipelineName"].replace(/^DF_/, "") };
        //                     }
        //                     return obj;
        //                 });


        //                 let data = [...chatHistory];
        //                 data[2].goldData = JSON.stringify(gold)
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateGold(gold[0], sampleCode)

        //             }
        //         }
        //         else {
        //             sampleCode = goldPipeline;

        //             let goldFiltered = pipelineSummaryData.filter(obj => obj.PipelineType.toLowerCase().includes("gold transformation") && !obj.PipelineType.toLowerCase().includes("orchestration"));
        //             let gold = goldFiltered.map(obj => {
        //                 if (obj["Dataflow/PipelineName"]?.startsWith("DF_")) {
        //                     return { ...obj, ["Dataflow/PipelineName"]: obj["Dataflow/PipelineName"].replace(/^DF_/, "") };
        //                 }
        //                 return obj;
        //             });

        //             let data = [...chatHistory];
        //             data[2].goldData = JSON.stringify(gold)
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateGold(gold[0], sampleCode)


        //         }
        //     }
        //     else if (pseudocodeType === "gold") {
        //         debugger

        //         sampleCode = goldPipeline;
        //         if (goldData.length > 1) {
        //             for (let i = 0; i < goldData.length; i++) {
        //                 let eachData = goldData[i];
        //                 if (eachData["Dataflow/PipelineName"] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             debugger
        //             if (index <= goldData.length &&
        //                 goldData[index + 1] !== undefined) {
        //                 generateGold(goldData[index + 1], sampleCode)
        //             }
        //             else {
        //                 debugger
        //                 sampleCode = dataflowPipeline;
        //                 let dataflowFilter = pipelineSummaryData.filter(obj => obj["Dataflow/PipelineName"].startsWith("DF_"));

        //                 let data = [...chatHistory];
        //                 data[2].dataflowData = JSON.stringify(dataflowFilter)
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 debugger
        //                 console.log(dataflowFilter, "dataflowbbbbbbbbb")
        //                 generateDataflowPseudo(dataflowFilter[0], sampleCode, "Pipeline")


        //             }
        //         }
        //         else {
        //             debugger
        //             sampleCode = dataflowPipeline;
        //             let dataflowFilter = pipelineSummaryData.filter(obj => obj["Dataflow/PipelineName"].startsWith("DF_"));

        //             let data = [...chatHistory];
        //             data[2].dataflowData = JSON.stringify(dataflowFilter)
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateDataflowPseudo(dataflowFilter[0], sampleCode, "Pipeline")

        //         }
        //     }

        //     else if (pseudocodeType === "dataflowDesign") {
        //         debugger
        //         sampleCode = dataflowPipeline;
        //         if (dataflowData.length > 1) {
        //             for (let i = 0; i < dataflowData.length; i++) {
        //                 let eachData = dataflowData[i];
        //                 if (eachData["Dataflow/PipelineName"] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= dataflowData.length &&
        //                 dataflowData[index + 1] !== undefined) {
        //                 debugger
        //                 generateDataflowPseudo(dataflowData[index + 1], sampleCode, "Pipeline")
        //             }
        //             else {
        //                 sampleCode = orchestractionDocPipeline;
        //                 let orchestrationFilter = pipelineSummaryData.filter(obj => obj["Dataflow/PipelineName"].toLowerCase().includes("orchestration"));
        //                 let orchestrationPipelineName = [];
        //                 debugger
        //                 for (let each in orchestrationFilter) {
        //                     // create a variable pipelineName as assign it with the orchestration[key].pipelineName
        //                     const pipelineName = orchestrationFilter[each].PipelineName;

        //                     // check if pipelineName doesn't include in the orchestrationPipelineName
        //                     if (!orchestrationPipelineName.includes(pipelineName)) {
        //                         // Push pipelineName into the orchestrationPipelineName array.
        //                         orchestrationPipelineName.push(pipelineName);
        //                     }
        //                 }

        //                 let data = [...chatHistory];
        //                 debugger
        //                 console.log(orchestrationPipelineName, "orchestrationPipelineName");

        //                 data[2].orchestrationData = orchestrationPipelineName;
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 debugger
        //                 generateOrchestrationDoc(pipelineSummaryData, sampleCode, orchestrationPipelineName[0]);
        //             }
        //         }
        //         else {
        //             sampleCode = orchestractionDocPipeline;
        //             let orchestrationFilter = pipelineSummaryData.filter(obj => obj["Dataflow/PipelineName"].toLowerCase().includes("orchestration"));
        //             let orchestrationPipelineName = [];

        //             debugger
        //             for (let each in orchestrationFilter) {
        //                 // create a variable pipelineName as assign it with the orchestration[key].pipelineName
        //                 const pipelineName = orchestrationFilter[each].PipelineName;

        //                 // check if pipelineName doesn't include in the orchestrationPipelineName
        //                 if (!orchestrationPipelineName.includes(pipelineName)) {
        //                     // Push pipelineName into the orchestrationPipelineName array.
        //                     orchestrationPipelineName.push(pipelineName);
        //                 }
        //             }

        //             let data = [...chatHistory];
        //             debugger
        //             console.log(orchestrationPipelineName, "orchestrationPipelineName");

        //             data[2].orchestrationData = orchestrationPipelineName;
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             debugger
        //             generateOrchestrationDoc(pipelineSummaryData, sampleCode, orchestrationPipelineName[0]);
        //         }
        //     }
        //     else if (pseudocodeType === "orchestrationDocument") {
        //         debugger
        //         sampleCode = orchestractionDocPipeline;
        //         if (orchestrationData.length > 1) {
        //             debugger
        //             for (let i = 0; i < orchestrationData.length; i++) {
        //                 let eachData = orchestrationData[i];
        //                 debugger
        //                 if (eachData === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= orchestrationData.length &&
        //                 orchestrationData[index + 1] !== undefined) {
        //                 sampleCode = orchestractionDocPipeline;
        //                 debugger
        //                 generateOrchestrationDoc(pipelineSummaryData, sampleCode, orchestrationData[index + 1])
        //             }
        //             else {
        //                 sampleCode = unitTestCasePseudoPipeline;
        //                 generateUnitTestCase(sampleCode);
        //             }
        //         }
        //         else {
        //             sampleCode = unitTestCasePseudoPipeline;
        //             debugger
        //             generateUnitTestCase(sampleCode);
        //         }

        //     }
        //     else if (pseudocodeType === "unitTestPseudo") {
        //         sampleCode = dataQualityPseudoPipeline;
        //         generateDataQuality(sampleCode);
        //     }
        //     else if (pseudocodeType === "dataQualityPsueudocode") {
        //         sampleCode = codeReviewChecklistDocPipeline;
        //         generateCodeReviewChecklistDesign(sampleCode);
        //     }
        //     else if (pseudocodeType === "codeReviewChecklist") {
        //         generateConsolidateDesignDoc();
        //     }

        // }//Pipeline flow
        // if (state.futureStateName === "Snowflake" || state.futureStateName === "Redshift") {
        //     debugger
        //     if (pseudocodeType === "pipelineSummary" ) {
        //         debugger
        //         sampleCode = extractionStoredProcedureSeq;

        //         //To fetch pipelineSummary from Database
        //         let response = await fetchPseudocodeData({
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: "pipelineSummary",
        //         }, state.jwt

        //         );

        //         console.log(response, "THIS IS RESPONSE")

        //         if (response.statusCode === 200) {
        //             let data1 = JSON.parse(response.data[0].pipelineSummary)
        //             console.log(typeof data1, "data1data1data1data1", data1);
        //             extraction = data1.filter(obj =>
        //                 obj.PipelineType && obj.PipelineType.toLowerCase().includes("extraction") && !obj.PipelineType.toLowerCase().includes("orchestration")
        //             );

        //             let data = [...chatHistory];
        //             data[2].pipelineSummaryData = JSON.stringify(response.data[0].pipelineSummary);

        //             console.log(chatHistory, "chatHistory");
        //             // setExtractionData(...extraction);
        //             data[2].extractionData = JSON.stringify(extraction);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             debugger
        //             generateExtractionSequence(extraction[0], sampleCode)
        //         } else {
        //             document.getElementById("protoastMessageSomething").style.display = "block";
        //             setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        //         }
        //     }
        //     else if (pseudocodeType === "extractionSequence") {
        //         debugger
        //         sampleCode = extractionStoredProcedureSeq;

        //         if (extractionData.length > 1) {
        //             for (let i = 0; i < extractionData.length; i++) {
        //                 let eachData = extractionData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= extractionData.length &&
        //                 extractionData[index + 1] !== undefined) {
        //                 generateExtractionSequence(extractionData[index + 1], sampleCode)
        //             }
        //             //for textting
        //             else {
        //                 debugger
        //                 sampleCode = transformationStoreProcedureSeq;

        //                 // let transformation = pipelineSummaryData.filter(obj => obj.NotebookType.toLowerCase().includes(("silver") || ("transformation")) && !obj.NotebookType.toLowerCase().includes("orchestration"));
        //                 let transformation = pipelineSummaryData.filter(obj => {
        //                     const notebookType = obj.NotebookType.toLowerCase();
        //                     return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestration");
        //                 });


        //                 let data = [...chatHistory];
        //                 data[2].transformationData = JSON.stringify(transformation)
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateTransformationSequence(transformation[0], sampleCode)
        //             }
        //         }
        //         else {
        //             debugger
        //             sampleCode = transformationStoreProcedureSeq;

        //             // let transformation = pipelineSummaryData.filter(obj => obj.NotebookType.includes(("silver") || ("transformation")) && !obj.NotebookType.toLowerCase().includes("orchestration"));
        //             let transformation = pipelineSummaryData.filter(obj => {
        //                 const notebookType = obj.NotebookType.toLowerCase();
        //                 return (notebookType.includes("silver transformation")) && !notebookType.includes("orchestration");
        //             });

        //             let data = [...chatHistory];
        //             data[2].transformationData = JSON.stringify(transformation)
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationSequence(transformation[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "transformationSequence") {
        //         console.log(transformationData, "transformationData");

        //         sampleCode = transformationStoreProcedureSeq;
        //         if (transformationData.length > 1) {
        //             for (let i = 0; i < transformationData.length; i++) {
        //                 debugger
        //                 let eachData = transformationData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }
        //             }
        //             if (index <= transformationData.length &&
        //                 transformationData[index + 1] !== undefined) {
        //                 generateTransformationSequence(transformationData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = goldStoreProcedureSeq;

        //                 let gold = pipelineSummaryData.filter(obj => {
        //                     const notebookType = obj.NotebookType.toLowerCase();
        //                     return (notebookType.includes("gold transformation")) && !notebookType.includes("orchestration");
        //                 });

        //                 let data = [...chatHistory];
        //                 data[2].goldData = JSON.stringify(gold);
        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);

        //                 generateTransformationGoldSequence(gold[0], sampleCode)
        //             }
        //         }
        //         else {
        //             sampleCode = goldStoreProcedureSeq;
        //             let data = [...chatHistory];
        //             data[2].goldData = JSON.stringify(gold);
        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationGoldSequence(gold[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "goldSequence") {
        //         debugger;
        //         console.log(goldData, "goldData");
        //         sampleCode = goldStoreProcedureSeq;
        //         let index;

        //         if (goldData.length > 1) {
        //             for (let i = 0; i < goldData.length; i++) {
        //                 let eachData = goldData[i];
        //                 if (eachData.NotebookName === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                     break; // Exit the loop once index is found
        //                 }
        //             }

        //             if (index !== undefined && index <= goldData.length - 2 && goldData[index + 1] !== undefined) {
        //                 debugger;
        //                 generateTransformationGoldSequence(goldData[index + 1], sampleCode);
        //             } else {

        //                 sampleCode = linkedSericePipeline;
        //                 generateLinkedServicePseudo(sampleCode);                    }
        //         } else {
        //                     sampleCode = linkedSericePipeline;
        //             generateLinkedServicePseudo(sampleCode);
        //         }
        //     } 
        //     if (pseudocodeType === "orchestrationUML" ||pseudocodeType === "transformationSequence"||pseudocodeType === "extractionSequence"|| pseudocodeType === "goldSequence") {
        //         sampleCode = linkedSericePipeline;
        //         generateLinkedServicePseudo(sampleCode);
        //     }
        //     if (pseudocodeType === "pipelineSummary") {
        //         debugger
        //         sampleCode = extractionStoredProcedure;

        //         //To fetch pipelineSummary from Database
        //         let response = await fetchPseudocodeData({
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: "pipelineSummary",
        //         }, state.jwt

        //         );

        //         console.log(response, "THIS IS RESPONSE")

        //         if (response.statusCode === 200) {
        //             let data1 = JSON.parse(response.data[0].pipelineSummary)
        //             console.log(typeof data1, "data1data1data1data1", data1);
        //             extraction = data1.filter(obj =>
        //                 obj.PipelineType && obj.PipelineType.toLowerCase().includes("extraction") && !obj.PipelineType.toLowerCase().includes("orchestration")
        //             );

        //             let data = [...chatHistory];
        //             data[2].pipelineSummaryData = JSON.stringify(response.data[0].pipelineSummary);

        //             console.log(chatHistory, "chatHistory");
        //             // setExtractionData(...extraction);
        //             data[2].extractionData = JSON.stringify(extraction);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             debugger
        //             generateExtraction(extraction[0], sampleCode)
        //         } else {
        //             document.getElementById("protoastMessageSomething").style.display = "block";
        //             setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        //         }
        //     }
        //     else if (pseudocodeType === "extraction") {
        //         sampleCode = extractionStoredProcedure;
        //         if (extractionData.length > 1) {
        //             for (let i = 0; i < extractionData.length; i++) {
        //                 let eachData = extractionData[i];
        //                 if (eachData['StoredProcedure/PipelineName'] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }

        //             }
        //             if (index <= extractionData.length &&
        //                 extractionData[index + 1] !== undefined) {
        //                 generateExtraction(extractionData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = transformationstoredProcedure;

        //                 console.log((pipelineSummaryData), "pipelineSummaryData")

        //                 console.log(typeof pipelineSummaryData, "PIPELINEOASDASDDSAAD")

        //                 transformation = pipelineSummaryData.filter(obj =>
        //                     obj.PipelineType &&
        //                     obj.PipelineType.toLowerCase().includes("silver transformation") &&
        //                     !obj.PipelineType.toLowerCase().includes("orchestration")
        //                 );
        //                 // setTransformationData(...transformation);
        //                 let data = [...chatHistory];
        //                 data[2].transformationData = JSON.stringify(transformation);

        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateTransformationSilver(transformation[0], sampleCode)
        //             }
        //         }
        //         else {
        //             sampleCode = transformationstoredProcedure;

        //             console.log(JSON.parse(pipelineSummaryData), "pipelineSummaryData")

        //             console.log(typeof pipelineSummaryData, "PIPELINEOASDASDDSAAD")

        //             let pipeline = JSON.parse(pipelineSummaryData)

        //             transformation = pipeline.filter(obj =>
        //                 obj.PipelineType &&
        //                 obj.PipelineType.toLowerCase().includes("silver transformation") &&
        //                 !obj.PipelineType.toLowerCase().includes("orchestration")
        //             );

        //             // setTransformationData(...transformation);
        //             let data = [...chatHistory];
        //             data[2].transformationData = JSON.stringify(transformation);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateTransformationSilver(transformation[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "transformation") {
        //         debugger
        //         sampleCode = transformationstoredProcedure;
        //         if (transformationData.length > 1) {
        //             for (let i = 0; i < transformationData.length; i++) {
        //                 let eachData = transformationData[i];
        //                 if (eachData['StoredProcedure/PipelineName'] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }

        //             }
        //             if (index <= transformationData.length &&
        //                 transformationData[index + 1] !== undefined) {
        //                 generateTransformationSilver(transformationData[index + 1], sampleCode)
        //             }
        //             else {
        //                 debugger
        //                 sampleCode = goldStoredProcedure;

        //                 let pipeline = JSON.parse(pipelineSummaryData)

        //                 gold = pipeline.filter(obj =>
        //                     obj.PipelineType &&
        //                     obj.PipelineType.toLowerCase().includes("gold transformation") &&
        //                     !obj.PipelineType.toLowerCase().includes("orchestration")
        //                 );

        //                 setChatHistory((a) => [...data]);
        //                 insertChatHistory([...data]);
        //                 generateGold(gold[0], sampleCode)
        //             }
        //         }
        //         else {
        //             debugger
        //             sampleCode = goldStoredProcedure;
        //             let pipeline = JSON.parse(pipelineSummaryData)

        //             gold = pipeline.filter(obj => obj.PipelineType.toLowerCase().includes("gold transformation") && !obj.PipelineType.toLowerCase().includes("orchestration"));
        //             let data = [...chatHistory];
        //             data[2].goldData = JSON.stringify(gold);

        //             setChatHistory((a) => [...data]);
        //             insertChatHistory([...data]);
        //             generateGold(gold[0], sampleCode)
        //         }
        //     }
        //     else if (pseudocodeType === "gold") {
        //         sampleCode = goldStoredProcedure;
        //         if (goldData.length > 1) {
        //             for (let i = 0; i < goldData.length; i++) {
        //                 let eachData = goldData[i];
        //                 if (eachData['StoredProcedure/PipelineName'] === chatHistory[chatHistory.length - 1].hyperlinkText) {
        //                     index = i;
        //                 }

        //             }
        //             if (index <= goldData.length &&
        //                 goldData[index + 1] !== undefined) {
        //                 generateGold(goldData[index + 1], sampleCode)
        //             }
        //             else {
        //                 sampleCode = unitTestCasePseudoStoredProcedure;
        //                 generateUnitTestCase(sampleCode);
        //             }
        //         }
        //         else {
        //             sampleCode = unitTestCasePseudoStoredProcedure;
        //             generateUnitTestCase(sampleCode);
        //         }
        //     }

        //     else if (pseudocodeType === "unitTestPseudo") {
        //         sampleCode = dataQualityPseudoStoredProcedure;
        //         generateDataQuality(sampleCode);
        //     }
        //     else if (pseudocodeType === "dataQualityPsueudocode") {
        //         sampleCode = codeReviewChecklistDocStoredProcedure;
        //         // generateCodeReviewChecklist(sampleCode);
        //         generateCodeReviewChecklistDesign(sampleCode);
        //     }
        //     else if (pseudocodeType === "codeReviewChecklist") {
        //         generateConsolidateDesignDoc();
        //     }
        // }// stored procedure end



    }// pseudocode looks good end
    console.log("staus", deployStatus);
    //regenerate Design
    /**
     * The function `regenerateDesign` takes in details about a pseudocode type and generates
     * corresponding sample code and documents based on the type.
     */
    //    PS_899-PS_935
    function regenerateDesign(details) {
        debugger
        let data = [...chatHistory];
        let columnName;
        let sampleCode;
        let body;
        let eachData;
        let pseudocodeType = details.pseudocodeType;

        console.log(details, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
        if (pseudocodeType === "extractionRequirement") {

            console.log(pseudocodeType, "regenerate")
            let sampleCode = extractionRequirement;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateExtractionRequirementData({isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'ExtractionRequirement', sampleCode: sampleCode }, state.jwt);
        }
        if (pseudocodeType === "sourceToExtractionER") {

            console.log(pseudocodeType, "regenerate")
            // let sampleCode = ;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateSourceToExtractionER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'bronzeER' }, state.jwt);      
          }
          if (pseudocodeType === "BronzeToSilverER") {

            console.log(pseudocodeType, "regenerate")
            // let sampleCode = ;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
           generateBronzeToSilverER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'silverER' }, state.jwt);
        }
        if (pseudocodeType === "SilverToGoldER") {

            console.log(pseudocodeType, "regenerate")
            // let sampleCode = ;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateSilverToGoldER({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'SilverToGoldER', }, state.jwt);
        }
        if (pseudocodeType === "dataDictionarySilver") {

            console.log(pseudocodeType, "regenerate")
            let sampleCode = dataDictionary;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateDataDictionaryPseudocode({ DDType: 'silver', isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataDictionary', sampleCode: sampleCode }, state.jwt);
        }
        if (pseudocodeType === "dataDictionaryGold") {

            console.log(pseudocodeType, "regenerate")
            let sampleCode = dataDictionary;

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateDataDictionaryPseudocode({ DDType: 'gold', isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataDictionary', sampleCode: sampleCode }, state.jwt);
        }

        else if (pseudocodeType === "dataModelSilver") {

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            generateDataModelSilverUML({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataModel' }, state.jwt);
        }
        else if (pseudocodeType === "dataModelGold") {

            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            generateDataModelGoldUML({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataModel' }, state.jwt);
        }
        else if (pseudocodeType === "metadataTable") {
            sampleCode = metadataTable;
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateMetadataTablePseudo({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'MetadataTable', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "dDLScriptsBronze") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateDDLPseudocode({ DDLType: "bronze", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "dDLScriptsSilver") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateDDLPseudocode({ DDLType: "silver", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "dDLScriptsGold") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = ddlNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = ddlPipeline;
            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = ddlProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateDDLPseudocode({ DDLType: "gold", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "pipelineSummary") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = pipelineSummaryNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = pipelineSummaryPipeline;
            } else if (state.futureStateName === "snowflake" || state.futureStateName === "Redshift") {
                sampleCode = pipelineSummaryProcedure;
            }
            data.pop();
            // data.show = true;
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generatePipelineSummaryPseudo({ DDLType: "gold", isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'PipelineSummary', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "orchestrationUML") {
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateOrchestrationUMLCode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'OrchestrationUML' }, state.jwt);
        } else if (pseudocodeType === "loggerPseudocode") {
            debugger
            sampleCode = loggerNotebook;
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);

            generateLoggerPseudocode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'LoggerPseudocode', sampleCode: sampleCode }, state.jwt);
        }
        else if (pseudocodeType === "utilityPseudocode") {
            sampleCode = loggerNotebook;
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
            generateUtilityPseudocode({ isRegenerate: true, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'UtilityPseudocode', sampleCode: sampleCode }, state.jwt);
        }
        // else if (pseudocodeType === "linkedServices") {
        //     sampleCode = linkedSericePipeline;
        //     data.pop();
        //     data[data.length - 1].show = true;
        //     data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

        //     generateLinkedServicePseudocode({ notebookId: data.notebookId, isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, notebookId: details.notebookId, type: 'LinkedService', sampleCode: sampleCode }, state.jwt);
        // }
        // else if (pseudocodeType === "dataset") {
        //     sampleCode = datasetPipeline;
        //     data.pop();
        //     data[data.length - 1].show = true;
        //     data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

        //     generateDatasetsPseudocode({ notebookId: details.notebookId, isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, notebookId: details.notebookId, type: 'Datasets', sampleCode: sampleCode }, state.jwt);
        // }
        // else if (pseudocodeType === "dataflowDesign") {
        //     sampleCode = dataflowPipeline;
        //     debugger
        //     if (dataflowData.length > 1) {
        //         for (let i = 0; i < dataflowData.length; i++) {
        //             eachData = dataflowData[i];
        //             if (eachData["Dataflow/PipelineName"] === details.hyperlinkText) {
        //                 index = i;
        //             }
        //         }
        //     }

        //     body = {
        //         notebookId: details.notebookId,
        //         isRegenerate: true,
        //         projectId: state.projectId,
        //         userId: state.userId,
        //         orgId: state.orgId,
        //         type: 'DataflowDesign',
        //         sampleCode: sampleCode,
        //         notebookName: details.hyperlinkText,
        //         pipelineSummary: dataflowData[index]
        //     };

        //     if (index <= dataflowData.length) {
        //         data.pop();
        //         data[data.length - 1].show = true;
        //         data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

        //         generateDataflowPseudocode(body, state.jwt);
        //     }

        // }
        else if (pseudocodeType === "extractionSequence") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = extractionNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = extractionPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = extractionStoredProcedure;
                columnName = "StoredProcedure/PipelineName";
            }
            if (extractionData.length > 1) {
                for (let i = 0; i < extractionData.length; i++) {
                    eachData = extractionData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }

            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'ExtractionSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: extractionData[index]
            };

            if (index <= extractionData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateBronzeSequence(body, state.jwt);

            }
        }
        else if (pseudocodeType === "transformationSequence") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = transformationNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = transformationPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = transformationstoredProcedure;
                columnName = "StoredProcedure/PipelineName";
            }

            if (transformationData.length > 1) {
                for (let i = 0; i < transformationData.length; i++) {
                    eachData = transformationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'TransformationSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: transformationData[index]
            };
            if (index <= transformationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateSilverSequence(body, state.jwt);
            }
        }
        else if (pseudocodeType === "goldSequence") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = goldNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = goldPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = goldStoredProcedure;
                columnName = "'StoredProcedure/PipelineName";
            }
            if (goldData.length >= 1) {
                for (let i = 0; i < goldData.length; i++) {
                    eachData = goldData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'GoldSequence',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: goldData[index]
            };
            if (index <= goldData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateGoldSequence(body, state.jwt);
            }
        }
        else if (pseudocodeType === "extraction") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = extractionNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = extractionPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = extractionStoredProcedure;
                columnName = "StoredProcedure/PipelineName";
            }
            if (extractionData.length > 1) {
                for (let i = 0; i < extractionData.length; i++) {
                    eachData = extractionData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }

            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Extraction',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: extractionData[index]
            };

            if (index <= extractionData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateExtractionPseudocode(body, state.jwt);

            }
        } else if (pseudocodeType === "transformation") {
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = transformationNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = transformationPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = transformationstoredProcedure;
                columnName = "StoredProcedure/PipelineName";
            }

            if (transformationData.length > 1) {
                for (let i = 0; i < transformationData.length; i++) {
                    eachData = transformationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: transformationData[index]
            };
            if (index <= transformationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateTransformationPseudocode(body, state.jwt);
            }
        }
        else if (pseudocodeType === "gold") {
            debugger
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = goldNotebook;
                columnName = "NotebookName";
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = goldPipeline;
                columnName = "Dataflow/PipelineName";
            } else {
                sampleCode = goldStoredProcedure;
                columnName = "'StoredProcedure/PipelineName";
            }
            if (goldData.length >= 1) {
                for (let i = 0; i < goldData.length; i++) {
                    eachData = goldData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: goldData[index]
            };
            if (index <= goldData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

                generateGoldPseudocode(body, state.jwt);
            }
        }
        else if (pseudocodeType === "orchestrationDocument") {
            body = { isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'OrchestrationDoc', sampleCode: sampleCode, jwt: state.jwt };
            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = orchestractionDocNotebook;
                columnName = "NotebookName";
            } else {
                sampleCode = orchestractionDocPipeline;
                columnName = "Dataflow/PipelineName";
            }
            if (orchestrationData.length > 1) {
                for (let i = 0; i < orchestrationData.length; i++) {
                    eachData = orchestrationData[i];
                    if (eachData[columnName] === details.hyperlinkText) {
                        index = i;
                    }
                }
            }
            body = {
                notebookId: details.notebookId,
                isRegenerate: true,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'OrchestrationDoc',
                sampleCode: sampleCode,
                notebookName: details.hyperlinkText,
                pipelineSummary: pipelineSummaryData
            };
            if (index <= orchestrationData.length) {
                data.pop();
                data[data.length - 1].show = true;
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
                //PS_CM_1405 - PS_CM_1427
                generateOrchestrationDocument(body, state.jwt);
            }

        }
        else if (pseudocodeType === "unitTestPseudo") {

            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = unitTestCasePseudoNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = unitTestCasePseudoPipeline;
            } else {
                sampleCode = unitTestCasePseudoStoredProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            generateUnitTestCasePseudocode({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'UnitTestCase', sampleCode: sampleCode }, state.jwt);
        } else if (pseudocodeType === "dataQualityPsueudocode") {

            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = dataQualityPseudoNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = dataQualityPseudoPipeline;
            } else {
                sampleCode = dataQualityPseudoStoredProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            generateDataQualityPseudo({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DataQualityPsueudocode', sampleCode: sampleCode }, state.jwt);
        } else if (pseudocodeType === "codeReviewChecklist") {

            if (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || state.futureStateName === "Synapse" && state.Methodology === "notebook") {
                sampleCode = codeReviewChecklistDocNotebook;
            } else if (state.futureStateName === "ADF" || state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
                sampleCode = codeReviewChecklistDocPipeline;
            } else {
                sampleCode = codeReviewChecklistDocStoredProcedure;
            }
            data.pop();
            data[data.length - 1].show = true;
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            generateChecklistDesign({ isRegenerate: true, futureStateName: state.futureState, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'CodeReviewChecklist', sampleCode: sampleCode }, state.jwt);
        }
        debugger
        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

    }



    //Consolidated design document download
    async function downloadConsolidateDesign() {
        setLoader(() => true);
        debugger;
        try {
            document.getElementById('pageLoader').style.display = "block"
            var response = await fetchDesignDetails({
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                futureStateName: state.futureStateName,
                methodology: state.Methodology
            }, state.jwt);

            console.log(response, "response consolidate");
            debugger;
            document.getElementById('pageLoader').style.display = "none"

            if (response.body?.statusCode == 403) {
                sessionExpired();
            } else if (response.body.statusCode !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            } else {
                console.log(response.body.data.designDetails[0], 'response.body.data.designDetails[0]');
                debugger;
                // Populate dataUML and orchUML
                if (response.body.data.designDetails[0].dataModelSilver !== null) {
                    setDataUML(() => response.body.data.designDetails[0].dataModelSilver);
                    setDownloadUml("DataModel-Silver");
                }

                if (response.body.data.designDetails[0].dataModelGold !== null) {
                    setDataUML(() => response.body.data.designDetails[0].dataModelGold);
                    setDownloadUml("DataModel-Gold");
                }

                if (response.body.data.designDetails[0].orchestrationDiagram !== null) {
                    setOrchUML(() => response.body.data.designDetails[0].orchestrationDiagram);
                    setDownloadOrgUML("OrchestrationDiagram");
                }

                // Handle extraction sequences
                const tables = ['bronzeTable', 'silverTable', 'goldTable'];
                tables.forEach(table => {
                    if (response.body.data[table][0]?.sequence !== null) {
                        for (let i = 0; i < response.body.data[table]?.length; i++) {
                            setOrchUML(() => response.body.data[table][i].sequence);
                            setDownloadOrgUML(response.body.data[table][i].notebookName);
                        }
                    }
                });

                // Create tempArray for Excel sheets
                // changed 29/07
                let tempArray = [];
                const designDetails = response.body.data.designDetails[0];
                tempArray.push({ sheetName: "DataDictionary-Silver", sheetData: JSON.parse(designDetails.dataDictionarySilver) });
                tempArray.push({ sheetName: "DataDictionary-Gold", sheetData: JSON.parse(designDetails.dataDictionaryGold) });
                tempArray.push({ sheetName: "MetadataTable", sheetData: JSON.parse(designDetails.metadataTable) });
                tempArray.push({ sheetName: "DDLScripts-Bronze", sheetData: JSON.parse(designDetails.DDLPseudocodeBronze) });
                tempArray.push({ sheetName: "DDLScripts-Silver", sheetData: JSON.parse(designDetails.DDLPseudocodeSilver) });
                tempArray.push({ sheetName: "DDLScripts-Gold", sheetData: JSON.parse(designDetails.DDLPseudocodeGold) });
                tempArray.push({ sheetName: "PipelineSummary", sheetData: JSON.parse(designDetails.pipelineSummary) });
                tempArray.push({ sheetName: "Orchestration", sheetData: JSON.parse(response.body.data.pipelineDetails[0].pseudoCode)});
                tempArray.push({ sheetName: "LoggerNotebook", sheetData: JSON.parse(designDetails.loggerPseudocode) });
                tempArray.push({ sheetName: "UtilitiesNotebook", sheetData: JSON.parse(designDetails.utilitiesPseudocode) });


                    // let orchestrationArray = [];
                    // for (let i = 0; i < response.body.data.pipelineDetails?.length; i++) {
                    //     let a = JSON.parse(response.body.data.pipelineDetails[i].pseudoCode);
                    //     orchestrationArray = [...orchestrationArray, ...a];
                    // }
                    // tempArray.push({ sheetName: "Orchestration", sheetData: orchestrationArray });

                // } else if (["ADF", "Synapse"].includes(state.futureStateName) && state.Methodology === "pipeline") {
                //     const filterByType = type => response.body.data.pipelineDetails.filter(pipeline => pipeline.pipelineType === type);
                //     const filteredLinkedService = filterByType("LinkedServices");
                //     const filteredDataset = filterByType("DataSets");
                //     const filteredDataFlow = filterByType("DataFlow");
                //     const filteredOrchestration = filterByType("orchestartion");

                //     if (filteredLinkedService.length > 0) {
                //         tempArray.push({ sheetName: "LinkedServices", sheetData: JSON.parse(filteredLinkedService[0].pseudoCode) });
                //     }

                //     if (filteredDataset.length > 0) {
                //         tempArray.push({ sheetName: "Datasets", sheetData: JSON.parse(filteredDataset[0].pseudoCode) });
                //     }

                //     let dataFlowArray = [];
                //     filteredDataFlow.forEach(flow => {
                //         let a = JSON.parse(flow.pseudoCode);
                //         dataFlowArray = [...dataFlowArray, ...a];
                //     });
                //     tempArray.push({ sheetName: "DataFlow", sheetData: dataFlowArray });

                //     let orchestrationArray = [];
                //     filteredOrchestration.forEach(orch => {
                //         let a = JSON.parse(orch.pseudoCode);
                //         orchestrationArray = [...orchestrationArray, ...a];
                //     });
                //     tempArray.push({ sheetName: "Orchestration", sheetData: orchestrationArray });
                // }

                tables.forEach(table => {
                    for (let i = 0; i < response.body.data[table]?.length; i++) {
                        tempArray.push({ sheetName: response.body.data[table][i].notebookName, sheetData: JSON.parse(response.body.data[table][i].pseudoCode) });
                    }
                });

                tempArray.push({ sheetName: "UnitTesting", sheetData: JSON.parse(designDetails.unitTestCasePseudocode) });
                tempArray.push({ sheetName: "DataQuality", sheetData: JSON.parse(designDetails.dataQualityPseudocode) });
                tempArray.push({ sheetName: "CodeReviewChecklist", sheetData: response.body.data.codeReviewChecklist });

                console.log(tempArray, "tempArray");

                let sheetsArray = tempArray;

                function truncateSheetName(name) {
                    return name.length > 31 ? name.substring(0, 31) : name;
                }

                // changes 29/07
                function downloadMultipleSheets(sheetsArray) {
                    try {
                        const wb = XLSX.utils.book_new();

                        if (Array.isArray(sheetsArray) && sheetsArray.length > 0) {
                            sheetsArray.forEach(sheetObj => {
                                if (sheetObj && sheetObj.sheetName && sheetObj.sheetData) {
                                    const { sheetName, sheetData } = sheetObj;
                                    const truncatedSheetName = truncateSheetName(sheetName);
                                    const ws = XLSX.utils.json_to_sheet(sheetData);
                                    XLSX.utils.book_append_sheet(wb, ws, truncatedSheetName);
                                } else {
                                    console.warn("Invalid sheet object:", sheetObj);
                                }
                            });

                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const downloadLink = document.createElement('a');
                            downloadLink.href = URL.createObjectURL(blob);
                            downloadLink.download = 'Consolidated_Design_Document.xlsx';
                            downloadLink.click();
                            URL.revokeObjectURL(downloadLink.href);
                        } else {
                            console.warn("sheetsArray is not a valid array or is empty:", sheetsArray);
                        }
                    } catch (error) {
                        console.error("Error in downloadMultipleSheets:", error);
                    } finally {
                        setLoader(() => false);
                    }
                }

                debugger;
                downloadMultipleSheets(sheetsArray);
            }
        } catch (error) {
            console.error("Error in downloadConsolidateDesign:", error);
        } finally {
            setLoader(() => false);
        }
    }


    //Each notebook download
    async function downloadDesign(type, fileName, id) {
        debugger

        const body = {
            projectId: state.projectId,
            type: type,
            userId: state.userId,
            notebookId: id,
            orgId: state.orgId
        };

        try {
            // PC_CM_101
            const response = await fetchPseudocodeData(body, state.jwt);

            console.log("response", response);
            if (response.statusCode === 403) {
                sessionExpired();
            } else if (response.statusCode !== 200) {
                document.getElementById("openErrorModal").click();
            } else {
                debugger
                console.log('downloadDesignResult', response.data[0])
                const data =
                    type === "extractionRequirement" ? response.data[0].extraction :
                        type === "sourceToExtractionER" ? response.data[0].sourceToBronzeER :
                            type === "BronzeToSilverER" ? response.data[0].bronzeToSilverER :
                                type === "SilverToGoldER" ? response.data[0].silverToGoldER :
                                    type === "dataDictionarySilver" ? response.data[0].dataDictionarySilver :
                                        type === "dataDictionaryGold" ? response.data[0].dataDictionaryGold :
                                            type === "dataModelSilver" ? response.data[0].dataModelSilver :
                                                type === "dataModelGold" ? response.data[0].dataModelGold :
                                                    type === "tableMapping" ? response.data[0].tableMapping :
                                                        type === "metadataTable" ? response.data[0].metadataTable :
                                                            type === "dDLScriptsBronze" ? response.data[0].DDlPseudocodeBronze :
                                                                type === "dDLScriptsSilver" ? response.data[0].DDlPseudocodeSilver :
                                                                    type === "dDLScriptsGold" ? response.data[0].DDlPseudocodeGold :
                                                                        type === "pipelineSummary" ? response.data[0].pipelineSummary :
                                                                            type === "orchestrationUML" ? response.data[0].orchestrationDiagram :
                                                                                type === "extractionSequence" ? response.data[0].sequence :
                                                                                    type === "transformationSequence" ? response.data[0].sequence :
                                                                                        type === "goldSequence" ? response.data[0].sequence :
                                                                                            type === "loggerPseudocode" ? response.data[0].loggerPseudocode :
                                                                                                type === "utilityPseudocode" ? response.data[0].utilitiesPseudocode :
                                                                                                    type === "extraction" ? response.data[0].pseudoCode :
                                                                                                        type === "transformation" ? response.data[0].pseudoCode :
                                                                                                            type === "gold" ? response.data[0].pseudoCode :
                                                                                                                type === "orchestrationDocument" ? response.data[1]?.pseudocode || response.data[0][0]?.pseudocode :
                                                                                                                    type === "unitTestPseudo" ? response.data[0].unitTestCasePseudocode :
                                                                                                                        type === "linkedServices" ? response.data[0].pseudocode :
                                                                                                                            type === "dataset" ? response.data[0].pseudocode :
                                                                                                                                type === "dataflowDesign" ? response.data[0].pseudocode :
                                                                                                                                    type === "dataQualityPsueudocode" ? response.data[0].dataQualityPseudocode : null;




                console.log(data, "data...........");

                //UML code download
                if (type === "orchestrationUML" || type === "extractionSequence" || type === "transformationSequence" || type === "goldSequence"
                    || type === "sourceToExtractionER" || type === "BronzeToSilverER" || type === "SilverToGoldER" || type === 'dataModelSilver' || type === 'dataModelGold') {
                    debugger
                    setDataUML(() => data);
                    setDownloadUml(fileName)
                }
                if (type === "codeReviewChecklist") {
                    let setVal = response.data;
                    let excelNo = 1
                    let UtilitiesArray = setVal.map(data => { return { NotebookName: data.NotebookName, DesignID:data.DesignID, CommandPurpose: data.CommandPurpose, CheckListStep: data.CheckListStep, Status: data.Status } });
                    // Declare variables for file type and extension
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';

                    // Convert UtilitiesArray to sheet
                    const ws = XLSX.utils.json_to_sheet(UtilitiesArray);

                    // Create workbook object
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Code Review Checklist');

                    // Convert workbook to Excel buffer
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                    // Save data in browser memory with FileSaver.js
                    const data = new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, 'Code Review Checklist' + fileExtension);
                }

                //other notebook downloads
                if (type !== "dataModelGold" && type !== "transformationSequence" && type !== "goldSequence" && type !== "extractionSequence" && type !== "dataModelSilver" && type !== "columnMapping" && type !== "orchestrationUML" && type !== "sourceToExtractionER" && type !== "BronzeToSilverER" && type !== "SilverToGoldER" && type !== "codeReviewChecklist") {
                    let dataArray;
                    try {
                        dataArray = JSON.parse(data);
                    } catch (error) {
                        return error;
                    }

                    if (!Array.isArray(dataArray)) return;
                    console.log(dataArray, "dataArray")


                    const ws = XLSX.utils.json_to_sheet(dataArray);

                    // Create workbook
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, fileName);

                    // Generate Excel file and download
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    // PS_612-613
    /**
     * The function `handleSilverER` updates the chat history by hiding the last message, adding a user
     * message, and displaying a popup message.
     */
    const handleSilverER = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks good" });
        data.push({ flow: "ai", type: "getSilverERPopup", show: true });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    /**PS_641-PS_642
     * The function `handleGoldER` updates the chat history by hiding the last message, adding a user
     * text message, and displaying a popup message.
     */
    const handleGoldER = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks good" });
        data.push({ flow: "ai", type: "getGoldERPopup", show: true });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    const handleFRDPopup = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks Good" });
        data.push({ flow: "ai", type: "text", text: "Congratulations! You've successfully completed define phase." });
        data.push({ flow: "center", type: "text", text: "Design", phase: "Design" })
        data.push({
            flow: "ai",
            type: "text",
            text: "Let's move towards generating design document",
            phase: "Design"
        });
        data.push({ flow: "ai", type: "definePopup", show: true, phase: "Design" });

        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };
    /**
     * PS_CD_1.312 - PS_CD_1.316
     */
    const handleDeploy = () => {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: "user", type: "text", text: "Looks good" });
        data.push({
            flow: "ai",
            type: "text",
            text: "Development phase completed successfully.",
        });

        if (state.futureStateName === 'Databricks' || state.futureStateName === 'ADF' || (state.futureStateName === 'Synapse' && state.Methodology === 'pipeline')) {
            data.push({
                flow: 'center',
                phase: 'Deploy',
                text: 'Deploy'
            });
            data.push({
                flow: 'ai',
                type: 'deploy',
                phase: 'Deploy',
                show: true
            });
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    };

    /**
     * PS_CD_1.317 - PS_CD_1.325
     */
    function updateValues() {
        let data = [...chatHistory];
        let temp = 0;
        let arr = [];
        for (let a of data) {
            if (temp == 0) {
                if (a.type == "deploy") {
                    a.show = true;
                    temp = 1;
                }
                arr.push(a);
            }
        }
        setChatHistory(() => [...arr]);
        insertChatHistory([...data]);
        // setIsEditNotebook(() => true)
    }
    async function dataChanged() {
        debugger

        let data = [...chatHistory];

        let temp = 0;

        let arr = [];

        let arr2 = []

        for (let a of data) {

            if (temp == 0) {

                if (a == codeDataValues) {
                    
                    a.show = true;

                    temp = 1;
                }
                arr.push(a);
            } else {
                arr2.push(a)
            }
        }

        debugger
        const toDeleteChatHistoryData = arr2;
        // Filter data to be deleted from DB 
        const deleteDBRecord = toDeleteChatHistoryData.filter(obj => obj.tableName === "defineDetails" || obj.tableName === "designDetails" || obj.tableName === "bronzeTable" || obj.tableName === "silverTable" || obj.tableName === "goldTable" || obj.tableName === "pipelineDetails" || obj.tableName === "codeReviewChecklist");

        if (deleteDBRecord.length !== 0) {
            const body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                deleteDBRecord: deleteDBRecord
            };
            debugger
            const response = await deleteDBRecords(body, state.jwt);
            debugger

            // Validate response
            if (response.status !== 200) {
                // Show error message
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }

        }

        setChatHistory(() => [...arr]);
        insertChatHistory([...arr]);

    }

    async function popupdataChanged(deleteParam,Paramvalue) {
        debugger

        let data = [...chatHistory];

        let temp = 0;

        let arr = [];

        let arr2 = []

        for (let a of data) {

            if (temp == 0) {

                if (JSON.stringify(a) == JSON.stringify(deleteParam)) {
                    
                    // a.show = true;

                    temp = 1;
                }
                arr.push(a);
            } else {
                arr2.push(a)
            }
        }

        debugger
        const toDeleteChatHistoryData = arr2;
        // Filter data to be deleted from DB 
        const deleteDBRecord = toDeleteChatHistoryData.filter(obj => obj.tableName === "defineDetails" || obj.tableName === "designDetails" || obj.tableName === "bronzeTable" || obj.tableName === "silverTable" || obj.tableName === "goldTable" || obj.tableName === "pipelineDetails" || obj.tableName === "codeReviewChecklist");

        if (deleteDBRecord.length !== 0) {
            const body = {
                deletPopupName:Paramvalue,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                deleteDBRecord: deleteDBRecord
            };
            debugger
            const response = await deleteDBRecords(body, state.jwt);
            debugger

            // Validate response
            if (response.status !== 200) {
                // Show error message
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessageSomething").style.display = "none";
                }, 3000);
            }

        }
     console.log(arr,"popopopopoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo")
        // setChatHistory(() => [...arr]);
        // insertChatHistory([...arr]);
        return arr;
    }
    //total verified
    function totalVerified() {
        debugger

        console.log("totalverifiedddddd");
        let data = [...chatHistory];
        const index = data.findIndex(item => item.hyperlinkText === codeDataValues.hyperlinkText);
        if (data[index + 1] == undefined) {
            data[index].show = true;
            data[index].verified = true;
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);

    }


    /**
     * PS_TSV_10 - PS_TSV_30
     */
    async function SaveDetails() {
        debugger;
        // Display loader
        console.log(targetSystemDetails);
        if (state.futureStateName == "Databricks") {
            let body = {
                userName: `/Users/${targetSystemDetails.userName?.userName}`,
                projectId: state.projectId,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                userId: state.userId,
                comments: targetSystemDetails.userName?.comments,
                createdBy: targetSystemDetails.userName?.createdBy,
                targetSystem: state.futureStateName
            };

            const response = await targetSystemValidation(body, state?.jwt);
            console.log(response);
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["accessToken"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["systemUrl"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["userName"]: true,
            }));
            setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                ...IsValidTargetSystemDetails,
                ["comments"]: true,
            }));
            if (response.status == 200) {
                document.getElementById("popupclose").click();
                deployNotebooksDatabricks("1");
            }
            if (response.status == 403) {
                // If status is not 200, throw an error
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["accessToken"]: false,
                }));
            }
            if (response.status == 500 && response.data == "Invalid Url") {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["systemUrl"]: false,
                }));
            }
            if (response.status == 404 && response.data == "Invalid userName") {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["userName"]: false,
                }));
            }
            if (response.status == 400) {
                setIsValidTargetSystemDetails((IsValidTargetSystemDetails) => ({
                    ...IsValidTargetSystemDetails,
                    ["comments"]: false,
                }));
            }
        }
        else if (state.futureStateName == "ADF") {
            let body = {
                url: `https://management.azure.com/subscriptions/${targetSystemDetails.subscriptionId}/resourceGroups/${targetSystemDetails.resourceGroupName}/providers/Microsoft.DataFactory/factories/${targetSystemDetails.factoryName}/linkedservices?api-version=2018-06-01`,
                projectId: state.projectId,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                userId: state.userId,
                targetSystem: state.futureStateName,
                resourceGroupName: targetSystemDetails.resourceGroupName,
                factoryName: targetSystemDetails.factoryName
            };

            const response = await targetSystemValidation(body, state?.jwt);
            console.log(response);
            let errorObj = {
                subscriptionId: true,
                resourceGroupName: true,
                accessToken: true,
                factoryName: true,
            }
            if (response.status == 200) {
                document.getElementById("popupclose").click();
                deployNotebooksDatabricks("1");
            }
            if (response.status == 403) {
                sessionExpired()
                return
            }
            if (response.status == 400) {
                errorObj.accessToken = false
            }
            if (response.status == 401) {
                errorObj.subscriptionId = false
            }
            if (response.status == 402) {
                errorObj.resourceGroupName = false
            }
            if (response.status == 404) {
                errorObj.factoryName = false
            }
            if (response.status == 500) {
                errorObj.subscriptionId = false
                errorObj.accessToken = false
            }
            setIsValidTargetSystemDetailsADF(errorObj)
        }
        else if (state.futureStateName == "Synapse" && state.Methodology == 'pipeline') {
            debugger;
            console.log('entered .....')
            let body = {

                projectId: state.projectId,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                userId: state.userId,
                targetSystem: state.futureStateName,
                userName: state.userName
            };

            const response = await targetSystemValidation(body, state?.jwt);
            console.log(response);
            let errorObj = {
                systemUrl: true,
                accessToken: true
            }
            if (response.status == 200) {
                document.getElementById("popupclose").click();
                deployNotebooksDatabricks("1");
            }
            if (response.status == 403) {
                sessionExpired()
                return
            }
            if (response.status == 400) {
                errorObj.accessToken = false
            }
            if (response.status == 401) {
                errorObj.systemUrl = false
            }
            if (response.status == 500) {
                errorObj.systemUrl = false
                errorObj.accessToken = false
            }
            setIsValidTargetSystemDetailsADF(errorObj)
        }
    }

    console.log(chatHistory);

    /**
     * PS_CD_1.25.1
     */
    async function deployNotebooksDatabricks(a, b) {
        let body;
        console.log(targetSystemDetails);
        if (state.futureStateName === "Databricks") {
            body = {
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                userName: targetSystemDetails.userName?.userName,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                targetSystemName: "Databricks"
            };
        }

        if (state.futureStateName === "Synapse" && state.Methodology === "pipeline") {
            debugger
            body = {
                targetSystem: "Synapse",
                projectId: state.projectId,
                targetSystemUrl: targetSystemDetails.systemUrl,
                accessToken: targetSystemDetails.accessToken,
                userId: state.userId,
                orgId: state.orgId,
                userId: state.userId,
                userName: state.userName
            };
        }

        if (state.futureStateName === "ADF") {
            body = {
                targetSystem: "ADF",
                projectId: state.projectId,
                orgId: state.orgId,
                factoryName: targetSystemDetails.factoryName,
                resourceGroupName: targetSystemDetails.resourceGroupName,
                accessToken: targetSystemDetails.accessToken,
                subscriptionId: targetSystemDetails.subscriptionId,
                userId: state.userId,
                userName: state.userName
            };
        }


        let data = [...chatHistory];
        if (a == "1") {
            data[1].deployed = true;
            data[1].targetdetails = targetSystemDetails;
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: "Target system details" });
            setChatHistory(() => [...data]);
        } else if (a == 2) {
            data[data.length - 1].show = false;
            data.push({ flow: "user", type: "text", text: b });
            setChatHistory(() => [...data]);
        }
        setLoader(() => true);
        const deployResponse = await deployNotebook(body, state?.jwt);
        console.log(deployResponse);
        setLoader(() => false);
        if (deployResponse.status != 200) {
            debugger
            data.push({ flow: "ai", type: "somethingDeploy", show: true });
        } else if (deployResponse.status == 200) {
            setDeployStatus(false)
            data.push({
                flow: "ai",
                type: "text",
                text: `Congratulations! You've successfully completed deployment.`,
            });
        }
        setChatHistory(() => [...data]);
        insertChatHistory([...data]);
    }




//PS_CM_1497 - PS_CM_1513
    async function generateLoggerCode(code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating logger notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Logger Notebook",
                referenceDocument: ["Design - Logger Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            // {
            //     flow: "ai",
            //     type: "textwait",
            //     text: "Your Logger notebook is getting generated. Please wait for some time!",
            //     id: "loggerMessage",
            //     time: new Date()
            // }
            ,
        ]);
        setChatHistory(() => [
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating logger notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Logger Notebook",
                referenceDocument: ["Design - Logger PSeudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

        ]);
        const res = await generateLoggerAndPipelineJSON(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "logger",
                sampleCode: code,
            },
            state.jwt
        );
        console.log("logger res", res);
        
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        

          
        if(res.status!=200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1514 - PS_CM_1530
    async function generateUtilitiesCode(code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating utility notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Utility Notebook",
                referenceDocument: ["Design - Utility Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating utility notebook",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Utility Notebook",
                referenceDocument: ["Design - Utility Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

        ]);
        const res = await generateUtility(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "utilities",
                sampleCode: code,
            },
            state.jwt
        );
        console.log("utilities res", res);
        setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

         if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1202 - PS_CM_1217
    async function generateMetadataCode(code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Metadata code",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Metadata Code",
                referenceDocument: ["Metadata Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

        ]);
        setChatHistory((data) => [
            ...data,
            { flow: "user", type: "text", text: "Looks good" },
            {
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Metadata code",
                phase: "Development"
            },
            {
                flow: "ai",
                type: "loadShow",
                docName: "Metadata Code",
                referenceDocument: ["Metadata Pseudocode"],
                show: true,
                phase: "Development"
            },
            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

        ]);
        const res = await generateMetadata(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "meta",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        
        if (res.status != 200) { {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }
}
    //PS_CM_1218 - PS_CM_1233
    async function generateDDLScripts(code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating DDL scripts Code",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "DDL Script Code",
                    referenceDocument: ["Bronze DDL Scripts,Silver DDL Scripts,Gold DDL Scripts"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

               
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating DDL scripts Code",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "DDL Script Code",
                    referenceDocument: ["Bronze DDL Scripts,Silver DDL Scripts,Gold DDL Scripts"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }
            ]);
        
        const res = await generateDDL(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "ddl",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
    if (res.status != 200) { {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }
}
    //PS_CM_1234 - PS_CM_1249
    async function generateExtractionCode(noteId, noteName, code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Extraction notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Extraction Notebook",
                    referenceDocument: [`Extraction Pseudocode ${noteName}`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: "Your Extraction notebook are getting generated. Please wait for some time!",
                //     id: "extractionMessage",
                //     time: new Date()
                // },
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Extraction notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Extraction Notebook",
                    referenceDocument: [`Extraction Pseudocode ${noteName}`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
        
        const res = await generateBronze(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "Extraction",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
                time: new Date()
            },
            state.jwt
        );
        // call the generate extraction notebook api
        console.log(res);
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1250 - PS_CM_1265
    async function generateSilverCode(noteId, noteName, code) {
        let data = [...chatHistory];
        debugger;
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Transformation silver notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Transformation Silver",
                    referenceDocument: [`Transformation Pseudocode ${noteName}`, `SilverZoneTransformations`, 'FRD-GenericRequirements', 'Utilities', 'MetadataTable'],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Transformation silver notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Transformation Silver",
                    referenceDocument: [`Transformation Pseudocode ${noteName}`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }
                ,
            ]);
        
        const res = await generateSilver(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "silver",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
            },
            state.jwt
        );
        // call the generate extraction notebook api
        console.log(res);
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1382 - PS_CM_1404
    async function generateGoldCode(noteId, noteName, code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Transformation gold notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Transformation Gold",
                    referenceDocument: [`Gold Pseudocode ${noteName}`, `GoldZoneTransformations`, 'FRD-GenericRequirements', 'Utilities', 'MetadataTable'],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: "Your Gold notebook is getting generated. Please wait for some time!",
                //     id: "goldMessage",
                //     time: new Date()
                // },
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Transformation gold notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Transformation Gold",
                    referenceDocument: [`Gold Pseudocode ${noteName}`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
        
        const res = await generateGoldDevCode(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "gold",
                notebookId: noteId,
                notebookName: noteName,
                sampleCode: code,
            },
            state.jwt
        );
        // call the generate extraction notebook api
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
  if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1282 - PS_CM_1297
    async function generatePipelineCode(pId, pType, pLayer = null, code) {


        console.log(pId, pType,"I am in orchestration code generationnnnnnnnnnnnnnnnnnnnn")
        debugger;
        let res;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: `Let's move towards generating ${pType} notebook`,
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: `${pType}`,
                    referenceDocument: [`Design - ${pType} document`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: `Your ${pType} is getting generated. Please wait for some time!`,
                //     id: `${pType}Message`,
                //     time: new Date()
                // },
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: `Let's move towards generating ${pType} notebook`,
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: `${pType}`,
                    referenceDocument: [`Design - ${pType} document`],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
        
        if (
            // pType == "linkedServices" ||
            // pType == "Orchestration" ||
            pType == "Orchestration"
        ) {
            res = await generateLoggerAndPipelineJSON(
                {
                    projectId: state.projectId,
                    orgId: state.orgId,
                    userId: state.userId,
                    type: pType,
                    pipelineId: pId,
                    pipelineLayer: pLayer,
                    pipelineType: pType,
                    sampleCode: code,
                },
                state.jwt
            );
            // call the generate extraction notebook api
        }
        //  else {
        //     res = await generateDataflow(
        //         {
        //             projectId: state.projectId,
        //             orgId: state.orgId,
        //             userId: state.userId,
        //             type: pType,
        //             pipelineId: pId,
        //             pipelineType: pType,
        //             pipelineLayer: pLayer,
        //             sampleCode: code,
        //         },
        //         state.jwt
        //     );
        // }
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
         if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1331 - PS_CM_1356
    //This function should be invoke for the design phase 
    async function generateCodeReviewChecklist() {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

        insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating CodeReview checklist notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Code review Checklist",
                    referenceDocument: ['Design - Extraction Notebook', 'Design - Silver Notebook', 'Design - Gold Notebook', 'Design - Unit Test Case Pseudocode', 'Design - Data Quality Pseudocode'],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: "Your Checklists are getting generated. Please wait for some time!",
                //     id: `checklistMessage`,
                //     time: new Date()
                // },
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating CodeReview checklist notebook",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Code review Checklist",
                    referenceDocument: ['Design - Extraction Notebook', 'Design - Silver Notebook', 'Design - Gold Notebook', 'Design - Unit Test Case Pseudocode', 'Design - Data Quality Pseudocode'],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
        
        const res = await generateChecklistDev(
            { projectId: state.projectId, orgId: state.orgId, userId: state.userId, Methodology: state.methodology, futureStateName: state.futureStateName },
            state.jwt
        );
        // call the generate extraction notebook api
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    //PS_CM_1298 - PS_CM_1314
    async function generateUnitTestCaseCode(code) {
        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;
        // setLoader(() => true);
        setShowlooksGood(() => false);

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Unit testing document",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Unit testing",
                    referenceDocument: ["Design - unit testing PSeudocode"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: "Your DDL Scripts are getting generated. Please wait for some time!",
                //     id: "ddlMessage",
                //     time: new Date()
                // },
            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Unit testing document",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Unit testing",
                    referenceDocument: ["Design - unit testing"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

                // {
                //     flow: "ai",
                //     type: "textwait",
                //     text: "Your DDL Scripts are getting generated. Please wait for some time!",
                //     id: "ddlMessage",
                //     time: new Date()
                // },
            ]);
        
        const res = await generateTestCaseCode(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "UnitTestCase",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        // setLoader(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }
    //PS_CM_1315 - PS_CM_1330
    async function generateDataQualityCode(code) {
        setShowlooksGood(() => false);

        debugger;
        let data = [...chatHistory];
        console.log("dasdasd", data);
        data[data.length - 1].show = false;

            insertChatHistory([
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Data Quality document",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Data Quality",
                    referenceDocument: ["Design - Data Quality"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
            setChatHistory((data) => [
                ...data,
                { flow: "user", type: "text", text: "Looks good" },
                {
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating Data Quality document",
                    phase: "Development"
                },
                {
                    flow: "ai",
                    type: "loadShow",
                    docName: "Data Quality",
                    referenceDocument: ["Design - Data Quality"],
                    show: true,
                    phase: "Development"
                },
                { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Development' }

            ]);
        
        const res = await genrateDQualityCode(
            {
                projectId: state.projectId,
                orgId: state.orgId,
                userId: state.userId,
                type: "dataQuality",
                sampleCode: code,
            },
            state.jwt
        );
        console.log(res);
        if (res.status == 403) {
            sessionExpired()
            return;
        }
        if (res.status != 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    // PS_677-PS_682
    //Design phase generation functions
    /**
     * The function `generateDataDictionarySilver` processes data to generate a data dictionary Silver
     * document and updates the chat history accordingly.
     * @returns The `generateDataDictionarySilver` function is an asynchronous function that generates
     * a data dictionary document of type 'silver' based on the provided code.
     */
    async function generateDataDictionarySilver(code) {
        debugger
        let data = [...chatHistory];

        data[data.length - 1].show = false;
        // setLoader(() => true);

            data.push({
                flow: "user",
                type: "text",
                text: "Looks Good",
                phase: "Design"
            });

            data.push({
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Silver data dictionary  document",
                phase: "Design"
            });
            data.push({
                flow: "ai",
                type: "loadShow",
                docName: "Silver Data Dictionary ",
                referenceDocument: ["SilverZoneTransformation -FRD", "Datamodel-Silver"],
                show: true,
                phase: "Design"
            });
            data.push({
                flow: "ai",
                type: "text",
                text: "Document would be ready in few minutes you would be notified with an email once the document is ready",
                phase: "Design"
            });

            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);

        
        const res = await generateDataDictionaryPseudocode({ DDType: 'silver', isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataDictionarySilver', sampleCode: code }, state.jwt);

        // setLoader(() => false);
        // setShowlooksGood(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200)  {

            debugger
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }
    // PS_683-PS_691
    /**
     * The function `generateDataDictionaryGold` generates a gold data dictionary document based on
     * provided code and updates the chat history accordingly.
     * @returns The `generateDataDictionaryGold` function returns either nothing or `undefined`
     * explicitly. This is because there is no `return` statement in the function. The function performs
     * certain operations based on the response from the `generateDataDictionaryPseudocode` function and
     * updates the `chatHistory` state accordingly.
     */
    async function generateDataDictionaryGold(code) {
        console.log("generateDataDictionarySilver in")

        let data = [...chatHistory];

        data[data.length - 1].show = false;
        console.log(state, "state");

            data.push({
                flow: "user",
                type: "text",
                text: "Looks good",
                phase: "Design"
            });

            data.push({
                flow: "ai",
                type: "text",
                text: "Let's move towards generating Gold data dictionary document",
                phase: "Design"
            });
            data.push({
                flow: "ai",
                type: "loadShow",
                docName: "Gold Data Dictionary ",
                referenceDocument: ["GoldZoneTransformation -FRD", "Datamodel-Gold"],
                show: true,
                phase: "Design"
            });
            data.push({
                flow: "ai",
                type: "text",
                text: "Document would be ready in few minutes you would be notified with an email once the document is ready",
                phase: "Design"
            });

            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);

        
        const res = await generateDataDictionaryPseudocode({ DDType: 'gold', isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataDictionaryGold', sampleCode: code }, state.jwt);

        console.log(res, "generateDataDictionaryGold Response")
        setLoader(() => false);
        // setShowlooksGood(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            debugger
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    // PS_CM_683 - PS_CM_698
    async function generateBronzeER() {
        let data = [...chatHistory];
        data[data.length - 1].show = false;

            // Add objects to the existing data array
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Define' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Source To Extraction Flow ', phase: 'Define' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'source to extraction flow', referenceDocument: ['TableRequirementSheet - FRD', "Inventory"], show: true, phase: 'Define' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });

            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);

            console.log(chatHistory, "chatHistorydmodel");


        const res = await generateSourceToExtractionER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'bronzeER' }, state.jwt);

        if (res.status == 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }
    // PS_614-PS_640
    /**
     * The function `generateSilverER` is an asynchronous function in JavaScript React that generates a
     * Silverzone Requirements Details document based on the provided code and updates the chat history
     * accordingly.
     * @returns The `generateSilverER` function is an asynchronous function that generates a Silverzone
     * Requirements Details document based on the provided code. It makes an API call to generate the
     * document and updates the chat history accordingly.
     */
    //PS_CM_699 - PS_CM_714
    async function generateSilverER(code,isNeedUpdate) {
        console.log("generateSilverER in")

        let arr = [...chatHistory];
        arr[arr.length - 1].show = false;


        console.log(state, "state");
        if ( isNeedUpdate=="true") {
            // Add objects to the existing data arra

            arr.push({ flow: 'user', type: 'text', text: 'Silverzone Requirements Details', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Extraction To Silver flow document', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'loadShow', docName: 'Extraction To Silver flow', referenceDocument: ['SilverZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });

            setChatHistory((a) => [...arr]);
            insertChatHistory([...arr]);

        }else if (isNeedUpdate=="false"){
            console.log(isNeedUpdate)
        }
        const res = await generateBronzeToSilverER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'silverER', sampleCode: code }, state.jwt);

        // setShowlooksGood(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            debugger
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }
    // PS_643-PS_670
    /**
     * The function `generateGoldER` is an asynchronous function that generates a Goldzone Requirements
     * Details document based on the provided code, updating the chat history accordingly.
     * @returns The `generateGoldER` function is returning either a session expiration message if the
     * response status is 403, or it is adding specific objects to the existing `chatHistory` array and
     * updating the state accordingly if the response status is 200.
     */
    //PS_CM_715 - PS_CM_730
    async function generateGoldER(code,isNeedUpdate) {
        console.log("generateGoldER in")

        let arr = [...chatHistory];
        arr[arr.length - 1].show = false;



        console.log(state, "state");
        if (isNeedUpdate=="true") {
            // Add objects to the existing data arra

            arr.push({ flow: 'user', type: 'text', text: 'Goldzone Requirements Details', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver To Gold flow document', phase: 'Define' });
            arr.push({ flow: 'ai', type: 'loadShow', docName: 'Silver To Gold flow', referenceDocument: ['GoldZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
            arr.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });

            setChatHistory((a) => [...arr]);
            insertChatHistory([...arr]);

        }else if (isNeedUpdate=="false"){

        }
        const res = await generateSilverToGoldER({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'SilverToGoldER', sampleCode: code }, state.jwt);


        // setShowlooksGood(() => false);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            debugger
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a.pop();
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }
    // PS_760-PS_782
    /**
     * The function `generateDataModelSilver` updates chat history data and triggers the generation of a
     * silver data model document based on certain conditions.
     * @returns This function `generateDataModelSilver` is an asynchronous function that performs
     * several tasks related to generating a data model. Here is a summary of what it does:
     */
    //PS_CM_731 - PS_CM_746
    async function generateDataModelSilver() {
        debugger

        let data = [...chatHistory];

        data[data.length - 1].show = false;

            // Add objects to the existing data array
            data.push({ flow: 'user', type: 'text', text: 'Let\'s Start ', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating silver data model  document', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'silver data model', referenceDocument: ['SilverZoneTransformation-FRD '], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            // insertChatHistory([...data]);


        const res = await generateDataModelSilverUML({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataModelSilver',chatHistory: data }, state.jwt);


        if (res.status == 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }
    // PS_783-PS_828
    /**
     * The function `generateDataModelGold` updates chat history data and triggers the generation of a
     * gold data model document.
     * @returns The `generateDataModelGold` function is an asynchronous function that generates a data
     * model for a chat application. It first creates a copy of the `chatHistory` array, then modifies
     * the last element of the array by setting its `show` property to `false`.
     */
    //PS_CM_747 - PS_CM_762
    async function generateDataModelGold() {
        debugger

        let data = [...chatHistory];

        data[data.length - 1].show = false;
            // Add objects to the existing data array
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating gold data model  document', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'gold data model', referenceDocument: ['GoldZoneTransformations-FRD'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);

        console.log(chatHistory, "chatHistorydmodel");
        
 
        const res = await generateDataModelGoldUML({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'dataModelGold' }, state.jwt);

        if (res.status == 403) {
            console.log("403 error")
            sessionExpired()
            return;
        }

        if (res.status != 200) {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }

    //PS_CM_766 - PS_CM_783
    async function generateMetadataTable(code) {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating metadata table document', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'metadata table', referenceDocument: ['Inventory', 'TableRequirement', 'SilverZoneTransformation', 'GoldZoneTransformations', 'SilverDD', 'GoldDD'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });
        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        const res = await generateMetadataTablePseudo({ isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'MetadataTable', sampleCode: code }, state.jwt);

        if (res.status == 403) {
            sessionExpired()
            return;
        }
        
        if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }
    }

    // PS_692-PS_713
    /**
     * The function `generateDDLBronzePseudo` in JavaScript React generates a DDL Script Bronze and
     * updates the chat history accordingly.
     * @returns The `generateDDLBronzePseudo` function is returning either nothing (undefined) or it is
     * returning early if the condition `res.status == 403` is met and `sessionExpired()` is called.
     */
    async function generateDDLBronzePseudo(code) {
        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Bronze DDL Script', phase: 'Design' });
        data.push({ flow: 'ai', type: 'loadShow', docName: 'Bronze DDL Script', referenceDocument: ['InventoryDD'], show: true, phase: 'Design' });
        data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Design' });

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        //PS_CM_784 
        const res = await generateDDLPseudocode({ DDLType: "bronze", isRegenerate: false, futureStateName: state.futureStateName, projectId: state.projectId, userId: state.userId, orgId: state.orgId, type: 'DDLScripts', sampleCode: code }, state.jwt);
        if (res.status == 403) {
            sessionExpired()
            return;
        }

        if (res.status != 200)  {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];

            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }
    /**
     * The function `generateDDLSilverPseudo` generates a DDL script in silver format based on the
     * provided code and updates the chat history accordingly.
     * @returns The `generateDDLSilverPseudo` function is returning either nothing or `undefined`
     * explicitly. This is because there is no `return` statement in the function. The function is
     * performing certain operations based on the response from the `generateDDLPseudocode` function
     * and updating the `chatHistory` state accordingly, but it does not have a return value specified.
     */
    // PS_CM_796 - PS_CM_807
    async function generateDDLSilverPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

        try {
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver DDL Script', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'Silver DDL Script', referenceDocument: ['InventoryDD', 'SilverDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            const res = await generateDDLPseudocode({
                DDLType: "silver",
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DDLScripts',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }

           if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating DDL Silver pseudocode:", error);
            // Handle error appropriately
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    /**
     * The function `generateDDLGoldPseudo` generates a DDL script based on provided code and updates the
     * chat history accordingly.
     * @returns The `generateDDLGoldPseudo` function returns either nothing (undefined) or it returns
     * early if the `res.status` is 403 due to the `sessionExpired()` function being called. If the
     * `res.status` is 200, it updates the `chatHistory` with new data and inserts it into the chat
     * history. If the `res.status` is not 200, it displays
     */
    //  PS_CM_820 - PS_CM_841
    async function generateDDLGoldPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

        try {
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Gold DDL Script', phase: 'Design' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'Gold DDL Script', referenceDocument: ['InventoryDD', 'GoldDD'], show: true, phase: 'Design' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

                setChatHistory((a) => [...data]);
                await insertChatHistory([...data]);
            
            const res = await generateDDLPseudocode({
                DDLType: "gold",
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DDLScripts',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating DDL Gold pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_842 - PS_CM_886

    async function generatePipelineSummary(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

        try {
         
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating pipeline summary', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'pipeline summary', referenceDocument: ['SourceSystemDetails', 'GoldZoneTransformations', 'SilverZoneTransformations', 'TableRequirementSheet'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generatePipelineSummaryPseudo({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'PipelineSummary',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }


             if (res.status !== 200)  {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating pipeline summary pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_865 - PS_CM_887
    async function generateOrchestrationUML() {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let data = [...chatHistory];
        data[data.length - 1].show = false;
        data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

        try {

            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating orchestration diagram', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'orchestration diagram', referenceDocument: ['Pipeline Summary'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
             
            const res = await generateOrchestrationUMLCode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'OrchestrationUML'
            }, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }

           
            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating orchestration UML code:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    async function generateLogger(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
         
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating design for logger notebook', phase: 'Design' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'logger notebook', referenceDocument: ['FRD - Generic Requirements'], show: true, phase: 'Design' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

                setChatHistory((a) => [...data]);
                await insertChatHistory([...data]);
            
            const res = await generateLoggerPseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'LoggerPseudocode',
                sampleCode: code
            }, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating logger pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_983 - PS_CM_1005
    async function generateUtilityPseudo(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
          
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating design for utility notebook', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'utility notebook', referenceDocument: ['FRD - Generic Requirements'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateUtilityPseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'utilityPseudocode',
                sampleCode: code
            }, state.jwt);


            if (res.status === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating utility pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_662 - PS_CM_682
    async function generateExtractionRequirement(code,isNeedUpdate) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        let arr = [...chatHistory];

        try {
            if ( isNeedUpdate=="true") {
                arr.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Define" });
                arr.push({ flow: "ai", type: "text", text: "Let's move towards generating Extraction requirement document", phase: "Define" });
                arr.push({ flow: "ai", type: "loadShow", docName: "extraction requirement", referenceDocument: ["Table Requirement", "Source system", "Inventory"], show: true, phase: "Define" });
                arr.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Define" });

                setChatHistory([...arr]);
                await insertChatHistory([...arr]);
            } 
            else if(isNeedUpdate=="false"){
                console.log(isNeedUpdate)
            }
            const res = await generateExtractionRequirementData({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'TableRequirement',
                sampleCode: code
            }, state.jwt);


            if (res.status === 403) {
                sessionExpired();
                return;
            }


            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a.pop();
                a[a.length - 1].show = true;
                setChatHistory([...a]);
            }
        } catch (error) {
            console.error("Error generating extraction requirement:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    //PS_852-PS_874 
    /**
     * The function `generateExtractionSequence` generates an extraction sequence based on provided object
     * and code, updating chat history accordingly.
     * @returns The function `generateExtractionSequence` is returning either nothing (undefined) or a
     * Promise if it is called with the `await` keyword.
     */
    let isOperationInProgress = false; // Global flag to manage ongoing operations
//PS_CM_888 - PS_CM_911
    async function generateExtractionSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'ExtractionSequence',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

           
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating extraction sequence - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Extraction-FRD', 'SourceSystem'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateBronzeSequence(body, state.jwt);
            

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200)  {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating extraction sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }
    //PS_CM_912 -  PS_CM_935
    async function generateTransformationSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

            

            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation Sequence - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['SilverZoneRequirements', 'InventoryDD', 'silverDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);
             
            const res = await generateSilverSequence(body, state.jwt);
      

            if (res.status == 403) {
                sessionExpired();
                return;
            }
            
            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }


    //PS_875-PS_898
    /**
     * The function `generateTransformationGoldSequence` is an asynchronous function in JavaScript
     * React that generates a transformation gold sequence based on provided object and code inputs.
     * @returns The `generateTransformationGoldSequence` function returns either a success message
     * indicating that the document for Transformation Gold is being generated and the user will be
     * notified via email, or an error message indicating that there was an issue with the request.
     */
    //PS_CM_936 - PS_CM_959
    async function generateTransformationGoldSequence(object, code) {
        // Set the flag to indicate that an operation is in progress
        isOperationInProgress = true;

        // Disable refresh/navigation
        window.onbeforeunload = function (e) {
            if (isOperationInProgress) {
                const confirmationMessage = "Processing is still ongoing. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        try {
            let notebookName = object?.NotebookName ||
                object['Dataflow/PipelineName'] ||
                object['StoredProcedure/PipelineName'] || "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation Gold - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['GoldZoneRequirements', 'InventoryDD', 'goldDD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);
       
            const res = await generateGoldSequence(body, state.jwt);

            if (res.status === 403) {
                sessionExpired();
                return;
            }

            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation gold sequence:", error);
        } finally {
            // Reset the flag and re-enable refresh
            isOperationInProgress = false;
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1007 - PS_CM_1029
    async function generateExtraction(object, code, notebookId) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Extraction',
                sampleCode: code,
                notebookId: notebookId,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
         
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Extraction- ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Extraction-FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateExtractionPseudocode(body, state.jwt);


            if (res.status == 403) {
                sessionExpired();
                return;
            }

            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating extraction:", error);
            // Handle errors if needed
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }
    //PS_CM_1358 - PS_CM_1381

    async function generateTransformationSilver(object, code, notebookId) {
        try {
            // Prevent page refresh
            window.onbeforeunload = function () {
                return "Processing is still ongoing. Are you sure you want to leave?";
            };

            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";

            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Transformation',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName,
                notebookId: notebookId
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
            
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Transformation - ${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['SilverZoneTransformations - FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document will be ready in a few minutes. You will be notified via email once it is ready.', phase: 'Design' });

            setChatHistory(a => [...data]);
            await insertChatHistory([...data]);
            
        
            const res = await generateTransformationPseudocode(body, state.jwt);
       

            if (res.status === 403) {
                sessionExpired();
                return;
            }


            if (res.status !== 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating transformation silver:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }



    //PS_CM_1054 - PS_CM_1077
    async function generateGold(object, code, notebookId) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let notebookName = object?.NotebookName ? object?.NotebookName :
                object['Dataflow/PipelineName'] ? object['Dataflow/PipelineName'] :
                    object['StoredProcedure/PipelineName'] ? object['StoredProcedure/PipelineName'] : "";
            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'Gold',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName,
                notebookId: notebookId
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

            
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Gold -${notebookName} notebook`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['GoldZoneTransformation - FRD'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
             
            const res = await generateGoldPseudocode(body, state.jwt);
         

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating gold:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1078 - PS_CM_1100
    async function generateOrchestrationDoc(object, code, notebook) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const notebookName = notebook;
            let body = {
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'orchestrationDocument',
                sampleCode: code,
                pipelineSummary: object,
                notebookName: notebookName
            };

            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: `Let's move towards generating Orchestration document`, phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: notebookName, referenceDocument: ['Pipeline Summary', 'Source system'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            

            await new Promise(resolve => setTimeout(resolve, 5000)); // Simulate a delay
            const res = await generateOrchestrationDocument(body, state.jwt);
          

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating orchestration document:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    
    //PS_CM_1101 - PS_CM_1123
    async function generateUnitTestCase(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            let data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
            
            data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating unit test case', phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'unit testcase', referenceDocument: ['Design - Extraction Pseudocode', 'Design - Silver Pseudocode', 'Design - Gold Pseudocode'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateUnitTestCasePseudocode({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'UnitTestCase',
                sampleCode: code,
            }, state.jwt);

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating unit test case:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }

    //PS_CM_1124 - PS_CM_1146
    async function generateDataQuality(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });

        
            data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating data quality pseudocode", phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'data quality', referenceDocument: ['FRD-Test Strategy'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateDataQualityPseudo({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'DataQualityPsueudocode',
                sampleCode: code,
            }, state.jwt);
            

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200)  {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating data quality pseudocode:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    //Should be invoked in the development phase

    //PS_CM_1147 - PS_CM_1169
    async function generateCodeReviewChecklistDesign(code) {
        // Prevent page refresh
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };

        try {
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Development' });

         
            data.push({ flow: 'ai', type: 'text', text: "Let's move towards generating Code review checklist", phase: 'Design' });
            data.push({ flow: 'ai', type: 'loadShow', docName: 'code review checklist', referenceDocument: ['Design - Extraction Pseudocode', 'Design - Silver Pseudocode', 'Design - Gold Pseudocode', 'Design - Unit Test Case Pseudocode', 'Design - Data Quality Pseudocode'], show: true, phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in a few minutes. You will be notified via email once the document is ready', phase: 'Design' });

            setChatHistory((a) => [...data]);
            await insertChatHistory([...data]);
            
            const res = await generateChecklistDesign({
                isRegenerate: false,
                futureStateName: state.futureStateName,
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                type: 'CodeReviewChecklist',
                sampleCode: code,
            }, state.jwt);
       

            if (res.status == 403) {
                sessionExpired();
                return;
            }


            if (res.status != 200) {
                document.getElementById("protoastMessageSomething").style.display = "block";
                setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

                let a = [...chatHistory];
                a[a.length - 1].show = true;
                setChatHistory(() => [...a]);
            }
        } catch (error) {
            console.error("Error generating code review checklist:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    function generateConsolidateDesignDoc() {
        window.onbeforeunload = function () {
            return "Processing is still ongoing. Are you sure you want to leave?";
        };
        try {
            // No async operations, so no need for unload prevention here
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data.push({ flow: 'user', type: 'text', text: 'Looks good', phase: 'Design' });
            data.push({ flow: 'ai', type: 'text', text: "Congratulations! You've Successfully completed the design phase.", phase: 'Design' });
            data.push({ flow: 'ai', type: 'consolidateDesign', text: 'You can download the consolidated design document here:', hyperlinkText: 'Consolidated Design Document', pseudocodeType: 'ConsolidatedDesignDocument', id: 'ConsolidatedDesignDocument', show: true, phase: 'Design' });

            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);
        }
        catch (error) {
            console.error("Error generating code review checklist:", error);
        } finally {
            // Re-enable refresh once all operations are done
            window.onbeforeunload = null;
        }
    }


    //PS_CD_1.24
    function getCodePageDetails(data) {
        setShowCode(true);
        setCodeDataValues(data);
        console.log(data);
    }
    //PS_04-PS_10
    /**
     * The function updates a chat history array based on the type provided and adds new entries
     * accordingly.
     */
    function overallFunction(type, obj) {
        debugger
        let value = [...chatHistory];
        value = value.map((data) => {
            if (data.type == "getType") {
                return { ...data, show: false };
            } else {
                return data;
            }
        });
        debugger
        switch (type) {
            case "level1":
                value.push({ flow: "user", type: "text", text: "Development", phase: "Development" });
                break;
            case "level2":
                value.push({ flow: "user", type: "text", text: "Design", phase: "Design" });
                break;
            case "level3":
                value.push({ flow: "user", type: "text", text: "Define", phase: "Define" });
                break;

            default:
                break;
        }
        value.push({ flow: "ai", type: "getConnectionPopup", show: true });
        setChatHistory((a) => [...value]);
        insertChatHistory([...value]);

    }

    /**
     * PS_CD_1.35 - PS_CD_1.42
     */
    function saveDesignDocument(details, data) {
        try {
            debugger;

            // Check the status of the details
            if (details.status !== 200) {
                return;
            }

            let arr = [...data];
            let designData = details?.data?.data ?? details;

            console.log(designData, "designData");

            let obj = {};

            // Check the phase and assign values accordingly
            if (arr[2]?.phase === "Design") {
                if (designData?.defineDetails?.[0]?.metadataTable || designData?.metadataTable) {
                    obj.metadata = "";
                }
                if (designData?.defineDetails?.[0]?.loggerPseudocode || designData?.loggerPseudocode) {
                    obj.loggerPseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.utilitiesPseudocode || designData?.utilitiesPseudocode) {
                    obj.utilitiesPseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeBronze ||designData?.DDLPseudocodeBronze ) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeSilver  || designData?.DDLPseudocodeSilver) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.DDLPseudocodeGold ||designData?.DDLPseudocodeGold ) {
                    obj.DDLScripts = " ";
                }
                if (designData?.defineDetails?.[0]?.unitTestCasePseudocode || designData?.unitTestCasePseudocode) {
                    obj.unitTestCasePseudocode = " ";
                }
                if (designData?.defineDetails?.[0]?.dataQualityPseudocode || designData?.dataQualityPseudocode) {
                    obj.dataQualityPseudocode = " ";
                }
            } else if (arr[2]?.phase === "Define" || arr[28]?.phase === "Design" ||arr[2]?.phase==="Development") {
                if (designData?.designDetails?.[0]?.metadataTable ) {
                    obj.metadata = " ";
                }
                if (designData?.designDetails?.[0]?.loggerPseudocode ) {
                    obj.loggerPseudocode = " ";
                }
                if (designData?.designDetails?.[0]?.utilitiesPseudocode ) {
                    obj.utilitiesPseudocode = " ";
                }
                if (designData?.designDetails?.[0]?.DDLPseudocodeBronze ) {
                    obj.DDLScripts = " ";
                }
                if (designData?.designDetails?.[0]?.DDLPseudocodeSilver) {
                    obj.DDLScripts = " ";
                }
                if (designData?.designDetails?.[0]?.DDLPseudocodeGold) {
                    obj.DDLScripts = " ";
                }
                if (designData?.designDetails?.[0]?.unitTestCasePseudocode) {
                    obj.unitTestCasePseudocode = " ";
                }
                if (designData?.designDetails?.[0]?.dataQualityPseudocode) {
                    obj.dataQualityPseudocode = " ";
                }
            }

            // Handle tables
            if (designData?.bronzeTable?.length > 0) {
                obj.Extraction = designData.bronzeTable.map(value => ({
                    notebookId: value.bronzeId,
                    notebookName: value.notebookName,
                }));
            }
            if (designData?.silverTable?.length > 0) {
                obj.Silver = designData.silverTable.map(value => ({
                    notebookId: value.silverId,
                    notebookName: value.notebookName,
                }));
            }
            if (designData?.goldTable?.length > 0) {
                obj.Gold = designData.goldTable.map(value => ({
                    notebookId: value.goldId,
                    notebookName: value.notebookName,
                }));
            }

            // Handle pipeline details
            if (["Databricks", "Snowflake", "Redshift", "AWS Glue"].includes(state.futureStateName) ||
                (state.futureStateName === "Synapse" && state.Methodology === "notebook")) {
                if (designData?.pipelineDetails?.length > 0) {
                    obj.Orchestration = designData.pipelineDetails;
                }
            } else if ((state.futureStateName === "Synapse" && state.Methodology === "pipeline") || state.futureStateName === "ADF") {
                let linkedServices = designData.pipelineDetails.filter(a => a.pipelineType === "LinkedServices");
                let dataset = designData.pipelineDetails.filter(a => a.pipelineType === "DataSets");
                let dataflow = designData.pipelineDetails.filter(a => a.pipelineType === "DataFlow");
                let orga = designData.pipelineDetails.filter(a => a.pipelineType === "Osrchestration");

                if (linkedServices.length > 0 && linkedServices[0].pipelineId !== undefined) {
                    obj.LinkedServices = linkedServices;
                }
                if (dataset.length > 0 && dataset[0].pipelineId !== undefined) {
                    obj.DataSet = dataset;
                }
                if (dataflow.length > 0 && dataflow[0].pipelineId !== undefined) {
                    obj.DataFlow = dataflow;
                }
                if (orga.length > 0 && orga[0].pipelineId !== undefined) {
                    obj.Orchestration = orga;
                }
            }

            // Update design details
            data[1].designData = obj;
            data[1].definefileName = details.fileName;

            console.log(obj, "obj");

            setDesignDetails(() => obj);

            setShowDesignPopup(false);

            // Handle chat history
            for (let i = 0; i < arr.length; i++) {
                if (arr[i]?.hyperlinkText === "Consolidated Design Document") {
                    if (arr[2]?.phase === "Design" || arr[2]?.phase === "Define") {
                        arr[data.length - 1].show = false;
                        arr.push({ flow: "user", type: "text", text: "Looks good", phase: "Design" });
                        arr.push({ flow: "center", type: "text", text: "Development", phase: "DesignToDevelopment" });
                    }
                }
            }
            debugger
            for (let i = 0; i < arr.length; i++) {
                if (arr[i]?.hyperlinkText === "Consolidated Design Document") {
                    if (arr[arr.length - 1].flow === "center" && arr[arr.length - 1].text === "Development" && arr[arr.length - 1].phase === "DesignToDevelopment") {
                        arr.push({
                            flow: "ai",
                            type: "text",
                            text: "Let's move towards generating code documentations,",
                            phase: "Development"
                        });
                        arr.push({
                            flow: "ai",
                            type: "codeShow",
                            text: "You can find the Metadata table details here,",
                            hyperlinkText: "Metadata Table",
                            codeShowTitle: "Metadata Table",
                            codeNotebookName: "Metadata Table",
                            codeType: "metadataTable",
                            id: "metadata",
                            show: true,
                        });
                    }
                }
            }

            if (arr[2]?.phase === "Define" && !arr[28].phase === "Design") {
                let sampleCode = extractionRequirement;
                generateExtractionRequirement(sampleCode,"true");
            }
            if (arr[2]?.phase === "Development") {
                arr.push({
                    flow: "ai",
                    type: "text",
                    text: "We require your lead's approval to proceed further. Awaiting approval",
                    phase: "Development"
                });
                arr.push({
                    flow: "ai",
                    type: "text",
                    text: "Still waiting for the approval",
                    phase: "Development"
                });
            }
            setChatHistory(a => [...arr]);
            insertChatHistory([...arr]);

        } catch (error) {
            console.error("Error in saveDesignDocument:", error);
        } finally {
            debugger;
        }
    }

    // PS_671-PS_676
    /**
     * The function `letsStartwithGeneration` updates the chat history, sets the last item to not show,
     * updates the chat history state, inserts the updated chat history, and generates a data model
     * using a sample code.
     */
    async function letsStartwithGeneration() {

        debugger;
        let data = [...chatHistory];
        data[data.length - 1].show = false;

        setChatHistory((a) => [...data]);
        insertChatHistory([...data]);
        debugger
        let sampleCode = dataModelSilver;
        generateDataModelSilver(sampleCode);

    }
    //PS_CM_40 - PS_CM_69
    async function updateChatHistory(defineDetailsData,condition_type) {
        debugger;
        let data = [...chatHistory];
        console.log(defineDetailsData, "defineDetailsDataaaaaa");

        const isEmpty = Object.keys(defineDetailsData).length === 0 || Object.keys(defineDetailsData.data.data).length === 0;
        console.log(isEmpty, "isEmptyy"); // true

        if (defineDetailsData.status !== 200 || isEmpty) {
            debugger;
            let temp = 0;
            let arr = [];
            for (let a of data) {
                if (temp === 0) {
                    if (a.type === "designPopup" || a.type === "definePopup" || a.type === "tableRequirementPopup" || a.type === "getSilverERPopup" || a.type === "getGoldERPopup") {
                        a.show = true;
                        temp = 1;
                    }
                }
                arr.push(a);
            }
            data = arr;
            insertChatHistory([...data]);
            data.push({ flow: "ai", type: "text", text: "Something went wrong! Try again later!" });
            setChatHistory((a) => [...data]);
            return;
        }

        debugger;
        let phase;
        if (data[28]?.phase === "Design") {
            phase = "Design";
        } else if (data[2]?.phase === "Define") {
            phase = "Define";
        } else if (data[2]?.phase === "Design") {
            phase = "Design";
        } else {
            phase = "Development";
        }
        console.log("Current Phase:", phase);

        debugger;
        if (data[28]?.phase === "Design") {
            // if(data?.length>28 && condition_type === "DesignPopup"){
            //     debugger;
            //     let chat_response = await popupdataChanged({ flow: "ai", type: "definePopup", show: false },"DesignPopup")
            //     data=[...chat_response]
               
            // }
            let temp = 0;
            let arr = [];
            for (let a of data) {
                if (temp === 0) {
                    if (a.type === "definePopup") {
                        a.show = false;
                        temp = 1;
                    }
                }
                arr.push(a);
            }

            data = arr;
            data[1].defineData = defineDetailsData.data.data.defineDetails[0];
            data[1].definefileName = defineDetailsData.filename;
            data[1].dwhSubjectAreaOptions = defineDetailsData.dwhSubjectAreaOptions;
            data[1].selectedDwhSubjectArea = defineDetailsData.selectedDwhSubjectArea;
            data[1].timeZone = defineDetailsData.timeZone;

            data.push({ flow: "user", type: "text", text: "Define document details", phase: phase });
            data.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: phase });
            data.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: phase });
            setShowDefinePopup(false);
            if(data?.length>5 && condition_type === "TableReqPop"){
                debugger;

                setLoader(() => true);
                document.getElementById("pageLoader").style.display = "block";

                let chat_response =await popupdataChanged({ flow: 'ai', type: 'tableRequirementPopup', show: false },"TableReqPop")
                data=[...chat_response]
                data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Define" });
                data.push({ flow: "ai", type: "text", text: "Let's move towards generating Extraction requirement document", phase: "Define" });
                data.push({ flow: "ai", type: "loadShow", docName: "extraction requirement", referenceDocument: ["Table Requirement", "Source system", "Inventory"], show: true, phase: "Define" });
                data.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Define" });
                generateExtractionRequirement(" ","false")
               
                document.getElementById("pageLoader").style.display = "none";
                setLoader(() => false);

               }else  if(data?.length>21 && condition_type === "getGoldERPopup"){
                debugger;

                setLoader(() => true);
                document.getElementById("pageLoader").style.display = "block";

                let chat_response =await popupdataChanged({ flow: "ai", type: "getGoldERPopup", show: false },"getGoldERPopup")
                data=[...chat_response]
                data.push({ flow: 'user', type: 'text', text: 'Goldzone Requirements Details', phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver To Gold flow document', phase: 'Define' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'Silver To Gold flow', referenceDocument: ['GoldZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });
                
                document.getElementById("pageLoader").style.display = "none";
                setLoader(() => false);

                generateGoldER(" ","false")
                // setShowgoldERPopup(false);
               }else if(data?.length>15 && condition_type === "getSilverERPopup"){
                debugger;
                setLoader(() => true);
                document.getElementById("pageLoader").style.display = "block";

                let chat_response =await popupdataChanged({ flow: "ai", type: "getSilverERPopup", show: false },"getSilverERPopup")
                data=[...chat_response]
                data.push({ flow: 'user', type: 'text', text: 'Silverzone Requirements Details', phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Extraction To Silver flow document', phase: 'Define' });
                data.push({ flow: 'ai', type: 'loadShow', docName: 'Extraction To Silver flow', referenceDocument: ['SilverZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });
                
                document.getElementById("pageLoader").style.display = "none";
                setLoader(() => false);

                generateSilverER(" ","false")
                // setShowsilverERPopup(false);
               }else if(data?.length>8){
                debugger;

                setLoader(() => true);
                document.getElementById("pageLoader").style.display = "block";

                let chat_response = await popupdataChanged({ flow: "ai", type: "definePopup", show: false, phase: "Design" },"definePopup")
                data=[...chat_response]
                data.push({ flow: "user", type: "text", text: "Define document details", phase: phase });
                data.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: phase });
                data.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: phase });

                document.getElementById("pageLoader").style.display = "none";
                setLoader(() => false);


                setShowDefinePopup(false)
            }
               
        } else if (data[2]?.phase === "Define") {
            debugger;
            let temp = 0;
            let arr = [];
            for (let a of data) {
                if (temp === 0) {
                    if (a.type === "tableRequirementPopup" || a.type === "getSilverERPopup" || a.type === "getGoldERPopup") {
                        a.show = false;
                        temp = 1;
                    }
                }
                arr.push(a);
            }

            data = arr;
            console.log("defineDetailsData.type:", defineDetailsData.type);
            console.log("data.type:", data.type);
           
            debugger;

            if (data[21]?.type === 'getGoldERPopup') {
                if(data?.length>21 && condition_type === "getGoldERPopup"){
                    debugger;

                    setLoader(() => true);
                    document.getElementById("pageLoader").style.display = "block";

                    let chat_response =await popupdataChanged({ flow: "ai", type: "getGoldERPopup", show: false },"getGoldERPopup")
                    data=[...chat_response]
                    data.push({ flow: 'user', type: 'text', text: 'Goldzone Requirements Details', phase: 'Define' });
                    data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Silver To Gold flow document', phase: 'Define' });
                    data.push({ flow: 'ai', type: 'loadShow', docName: 'Silver To Gold flow', referenceDocument: ['GoldZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                    data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });
                    
                    document.getElementById("pageLoader").style.display = "none";
                    setLoader(() => false);
    
                    
                    generateGoldER(" ","false")
                    setShowgoldERPopup(false);
                   }else{
                    console.log("Handling getGoldERPopup");
                    data[1].goldReqData = defineDetailsData.data.data.defineDetails[0];
                    data[1].goldReqfileName = defineDetailsData.filename;
                    console.log(data[1].goldReqData, 'data[1].goldReqData');
                    console.log(data[1].goldReqfileName, 'data[1].goldReqfileName');

                    let sampleCode = silverER;
                    generateGoldER(sampleCode,"true");
                    setShowgoldERPopup(false);
                   }
            } else if (data[15]?.type === 'getSilverERPopup') {
                if(data?.length>15 && condition_type === "getSilverERPopup"){
                    debugger;

                    setLoader(() => true);
                    document.getElementById("pageLoader").style.display = "block";

                    let chat_response =await popupdataChanged({ flow: "ai", type: "getSilverERPopup", show: false },"getSilverERPopup")
                    data=[...chat_response]
                    data.push({ flow: 'user', type: 'text', text: 'Silverzone Requirements Details', phase: 'Define' });
                    data.push({ flow: 'ai', type: 'text', text: 'Let\'s move towards generating Extraction To Silver flow document', phase: 'Define' });
                    data.push({ flow: 'ai', type: 'loadShow', docName: 'Extraction To Silver flow', referenceDocument: ['SilverZoneTransformation - FRD', 'Inventory'], show: true, phase: 'Define' });
                    data.push({ flow: 'ai', type: 'text', text: 'Document would be ready in few minutes you would be notified with an email once the document is ready', phase: 'Define' });
                    
                    document.getElementById("pageLoader").style.display = "none";
                    setLoader(() => false);
    
                    
                    generateSilverER(" ","false")
                    setShowsilverERPopup(false);
                   }else{
                    console.log("Handling getSilverERPopup");
                    data[1].silverReqData = defineDetailsData.data.data.defineDetails[0];
                    data[1].silverReqfileName = defineDetailsData.filename;
                    console.log(data[1].silverReqData, 'data[1].silverReqData');
                    console.log(data[1].silverReqfileName, 'data[1].silverReqfileName');

                    let sampleCode = silverER;
                    generateSilverER(sampleCode,"true");
                    setShowsilverERPopup(false);

                   }
            } else if (data[5]?.type === 'tableRequirementPopup') {
                if(data?.length>5){
                    debugger;

                    setLoader(() => true);
                    document.getElementById("pageLoader").style.display = "block";

                    let chat_response =await popupdataChanged({ flow: 'ai', type: 'tableRequirementPopup', show: false },"TableReqPop")
                    data=[...chat_response]
                    data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Define" });
                    data.push({ flow: "ai", type: "text", text: "Let's move towards generating Extraction requirement document", phase: "Define" });
                    data.push({ flow: "ai", type: "loadShow", docName: "extraction requirement", referenceDocument: ["Table Requirement", "Source system", "Inventory"], show: true, phase: "Define" });
                    data.push({ flow: "ai", type: "text", text: "Document would be ready in a few minutes. You will be notified by email once the document is ready.", phase: "Define" });
                    
                    document.getElementById("pageLoader").style.display = "none";
                    setLoader(() => false);
    
                    
                    generateExtractionRequirement(" ","false")
                    setShowDefinePopup(false);
                    }else {
                    console.log("Handling tableRequirementPopup");
                    data[1].tableReqData = defineDetailsData.data.data.defineDetails[0];
                    data[1].tableReqfileName = defineDetailsData.filename;
                    console.log(data[1].tableReqData, 'data[1].tableReqData');
                    console.log(data[1].tableReqfileName, 'data[1].tableReqfileName');

                    let sampleCode = extractionRequirement;
                    generateExtractionRequirement(sampleCode,"true");
                    setShowDefinePopup(false);
                    }
                    
            }
        } else if (data[2]?.phase === "Design") {
            let temp = 0;
            let arr = [];
            for (let a of data) {
                if (temp === 0) {
                    if (a.type === "tableRequirementPopup" || a.type === "definePopup") {
                        a.show = false;
                        temp = 1;
                    }
                }
                arr.push(a);
            }
             if (data[8]?.type === 'definePopup') {
                if(data?.length>8){
                    debugger;

                    setLoader(() => true);
                    document.getElementById("pageLoader").style.display = "block";

                    let chat_response = await popupdataChanged({ flow: "ai", type: "definePopup", show: false, phase: "Design" },"definePopup")
                    data=[...chat_response]
                    data.push({ flow: "user", type: "text", text: "Define document details", phase: phase });
                    data.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: phase });
                    data.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: phase });

                    document.getElementById("pageLoader").style.display = "none";
                    setLoader(() => false);
    

                    setShowDefinePopup(false)
                }else{
                data = arr;
                data[1].defineData = defineDetailsData.data.data.defineDetails[0];
                data[1].definefileName = defineDetailsData.filename;
                data[1].dwhSubjectAreaOptions = defineDetailsData.dwhSubjectAreaOptions;
                data[1].selectedDwhSubjectArea = defineDetailsData.selectedDwhSubjectArea;
                data[1].timeZone = defineDetailsData.timeZone;
                // data.push({ flow: "ai", type: "definePopup", show: false });
                data.push({ flow: "user", type: "text", text: "Define document details", phase: phase });
                data.push({ flow: "ai", type: "text", text: "We require your lead's approval to proceed further. Awaiting approval", phase: phase });
                data.push({ flow: "ai", type: "text", text: "Still waiting for the approval", phase: phase });
                setShowDefinePopup(false);
                }
              
            }
            else if (data[5]?.type === 'tableRequirementPopup') {
                
                data[1].tableReqData = defineDetailsData.data.data.defineDetails[0];
                data[1].tableReqfileName = defineDetailsData.filename; // Set only tableReqfileName
                console.log(data[1].tableReqData, 'data[1].tableReqData');
                console.log(data[1].tableReqfileName, 'data[1].tableReqfileName');
                data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Design" });
                data.push({
                    flow: "ai",
                    type: "text",
                    text: "Let's move towards generating design document",
                    phase: "Design"
                });
                data.push({ flow: "ai", type: "definePopup", show: true });
                if(data?.length>5){
                    debugger;

                    setLoader(() => true);
                    document.getElementById("pageLoader").style.display = "block";

                    let chat_response = await popupdataChanged({ flow: 'ai', type: 'tableRequirementPopup', show: false },"TableReqPop")
                    data=[...chat_response]
                    data.push({ flow: "user", type: "text", text: "Table Requirements details", phase: "Design" });
                    data.push({
                        flow: "ai",
                        type: "text",
                        text: "Let's move towards generating design document",
                        phase: "Design"
                    });
                    data.push({ flow: "ai", type: "definePopup", show: true });
                   
                    document.getElementById("pageLoader").style.display = "none";
                    setLoader(() => false);
                    }
               

            }
           
        }
        
        else {
            let temp = 0
            let arr = []
            for (let a of data) {
                if (temp == 0) {
                    if (a.type == "designPopup") {
                        a.show = false;
                        temp = 1;
                    }
                    arr.push(a);
                }
            }
            data = arr
            data.push({ flow: "user", type: "text", text: "Notebook details", phase: phase });
        }

        debugger;
        if (data[28]?.phase === "Design") {
            let dDetails = defineDetailsData.data.data.defineDetails[0];
            setDefineDetails(dDetails);
            setChatHistory((a) => [...data]);
            insertChatHistory([...data]);
            console.log(dDetails, "dDetails");
        } else if (data[2]?.phase === "Define") {
            debugger;
            if (data[15]?.type === 'getSilverERPopup') {
                let dDetails = defineDetailsData.data.data.defineDetails[0];
                setSilverReqDetails(dDetails);
                setChatHistory((a) => [...data]);
                insertChatHistory([...data]);
                console.log(dDetails, "dDetails");
            } else if (data[5]?.type === 'tableRequirementPopup') {
                let dDetails = defineDetailsData.data.data.defineDetails[0];
                setTableReqDetails(dDetails);
                setChatHistory((a) => [...data]);
                insertChatHistory([...data]);
                console.log(dDetails, "dDetails");
            }
        }
        else if (data[2]?.phase === "Design") {
            debugger;
         if (data[5]?.type === 'tableRequirementPopup') {
                let dDetails = defineDetailsData.data.data.defineDetails[0];
                setTableReqDetails(dDetails);
                setChatHistory((a) => [...data]);
                insertChatHistory([...data]);
                console.log(dDetails, "dDetails");
            }
        }
         else {
            debugger;
            let dDetails = defineDetailsData.data.data;
            dDetails.status = 200;
            dDetails.fileName = defineDetailsData.filename;
            setDesignDetails(() => dDetails);
            console.log(dDetails, "dDetailsssssss");
            saveDesignDocument(dDetails, data);
        }
    }


    async function designToDevelopment() {
        debugger
        let data = [...chatHistory];

        let body = {
            projectId: state.projectId,
            userId: state.userId,
            orgId: state.orgId,
            futureStateName: state.futureStateName,
            Methodology: state.Methodology
        }
        setLoader(() => true);
        debugger
        let response = await fetchDesignDetails(body, state.jwt);
        setLoader(() => false);
        debugger
        console.log(response, "designToDevelopment");
        if (response.body.statusCode == 403) {
            sessionExpired()
            return;
        }
        else if (response.body.statusCode == 200) {
            debugger

            response.body.data.status = 200;
            data[1].designObj = JSON.stringify(response.body.data);

            debugger
            saveDesignDocument(response.body.data, data);



        } else {

            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);

            let a = [...chatHistory];
            a[a.length - 1].show = true;
            setChatHistory(() => [...a]);
        }

    }



    //to handle search 
    const handleSearch = useCallback((newSearchText) => {
        setSearchText(newSearchText);
        if (newSearchText) {
            const matches = chatHistory.reduce((acc, item) => {
                if (item?.hyperlinkText && typeof item.hyperlinkText === 'string' &&
                    item.hyperlinkText.toLowerCase().includes(newSearchText.toLowerCase())) {
                    // Check if this hyperlink text is already in the accumulator
                    if (!acc.some(match => match.hyperlinkText === item.hyperlinkText)) {
                        acc.push(item);
                    }
                }
                return acc;
            }, []);

            matchesRef.current = matches;
            setMatchCount(matches.length);
            setCurrentMatchIndex(0);
            if (matches.length > 0) {
                scrollToMatch(matches[0]);
            }
        } else {
            matchesRef.current = [];
            setMatchCount(0);
            setCurrentMatchIndex(0);
        }
    }, [chatHistory]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && matchCount > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchCount;
            setCurrentMatchIndex(nextIndex);
            scrollToMatch(matchesRef.current[nextIndex]);
        }
    };

    const scrollToMatch = (match) => {
        if (match && typeof match.hyperlinkText === 'string') {
            const divElement = document.getElementById("search-" + match.hyperlinkText);
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };




    return (
        <>
            <div
                class="d-flex justify-content-center align-items-center w-100"
                style={{ position: "absolute", zIndex: "100000" }}
            >
                <div
                    id="toastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Session has expired.Please login Again
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    id="protoastMessage"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/check 1.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Project has been created successfully
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    id="protoastMessageSomething"
                    class="toast custom-toast align-items-center mt-5"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-delay="3000"
                    style={{ display: "none" }}
                >
                    <div class="d-flex">
                        <div class="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                            </span>
                            <span class="mx-3 font-14 loader-text">
                                Something went wrong. Try Again!
                            </span>
                        </div>
                    </div>
                </div>
                <div className='div2' style={{ display: "none" }}>
                    <div id='daimage111'>
                        <PlantUML src={dataUML} /></div>
                    <div id='daorgimage'>
                        <PlantUML src={orchUML} /></div>
                </div>

            </div>
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                id="openErrorModal"
                data-bs-target="#Something-wrong"
                hidden
            >
                Oops! Something went wrong
            </button>
            <div
                className="modal fade"
                id="Something-wrong"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span>
                                    <img
                                        src="images/went-wrong-icon.svg"
                                        alt="went-wrong-icon"
                                        className="sucess-icon-sty"
                                    />
                                </span>
                                <h3 className="font-16 font-bold text-black mt-4">
                                    Oops! Something went wrong
                                </h3>
                                <p className="font-14 font-regular text-black">
                                    Please try again later{" "}
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maindiv">
                {showCode == false ? (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                {/* header style starts here */}
                                <HeaderCom value="1" />
                                {/* header style ends here */}
                                <div
                                    className="overlay"
                                    id="pageLoader"
                                    style={{ display: "none" }}
                                >
                                    <div className="position-absolute top-50 start-50  translate-middle">
                                        <div className="d-flex align-items-center loader-bg">
                                            <div
                                                className="spinner-border Loader text-dark align-center"
                                                role="status"
                                            >
                                                <span className="visually-hidden"></span>
                                            </div>
                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 pt-4 mt-5">
                                    <div className="mt-3">
                                        <ul className="cust-breadcrumb mb-0 ">
                                            <li className="font-16 font-medium">
                                                <a>Manage Projects</a>
                                            </li>
                                            <li className="font-16 font-medium active">
                                                {state.isEdit
                                                    ? state.projectName
                                                    : state.projectName}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row p-4">
                                        <div className="d-flex  mb-4 justify-content-between align-items-center">
                                            <div className="d-flex align-items-center mb-xl-0 ">
                                                <a className="d-flex" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                                    <img
                                                        src="images/back-arrow.svg"
                                                        alt="back-arrow"
                                                        onClick={() => {
                                                            nav("/ProjectInfo", {
                                                                state: { ...state, isEdit: true },
                                                            });
                                                        }}
                                                    />
                                                </a>
                                                <h2 className="text-black font-bold font-22 mb-0 ms-3" style={{ position: "absolute", paddingLeft: "1%" }}>
                                                    {state.isEdit == false
                                                        ? state.projectName
                                                        : state.projectName}
                                                </h2>
                                            </div>
                                            {/* <div class="d-lg-flex d-md-block"> */}
                                            {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center" style={{ marginLeft: '48%' }}>
                                                <span className=" text-nowrap">Organization : </span>
                                                <span>
                                                    <img
                                                        src={state.orgLogo}
                                                        alt="hubspot-logo"
                                                        className="figma-logo"
                                                    />
                                                </span>
                                                <span className="me-2 text-nowrap font-bold">
                                                    {" "}{state.orgName}{" "}
                                                </span>
                                            </div>
                                            <div className="input-group position-relative search-wid me-md-3" style={{ overflow: 'visible' }}>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <input
                                                        type="search"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                        placeholder="Search"
                                                        value={searchText}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                        style={{
                                                            paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                            width: '100%',
                                                        }}
                                                    />
                                                    {matchCount > 0 && (
                                                        <span style={{
                                                            position: 'absolute',
                                                            left: '8px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            fontSize: '12px',
                                                            color: '#fff',
                                                            backgroundColor: '#7650E0',
                                                            padding: '2px 6px',
                                                            borderRadius: '10px',
                                                            zIndex: 5,
                                                            pointerEvents: 'none',
                                                        }}>
                                                            {`${currentMatchIndex + 1}/${matchCount}`}
                                                        </span>
                                                    )}
                                                </div>
                                                <span className="search-icon-sty" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                    <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                                </span>
                                            </div>
                                            {/* </div> */}
                                            {/* </div> */}
                                        </div>
                                        <div className="code-review-tab-width">
                                            <div className="code-review-page tabs nav ">
                                                <a
                                                    onClick={() => {
                                                        nav("/ProjectInfo", {
                                                            state: { ...state, isEdit: true },
                                                        });
                                                    }}
                                                >
                                                    {" "}
                                                    <div className="tab nav-link font-bold">
                                                        Project Info
                                                    </div>
                                                </a>
                                                <div
                                                    className="tab nav-link font-bold active"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#ask-gen"
                                                >
                                                    Ask GenETL
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pills-tabContent">
                                                {/* chat page  */}
                                                <div
                                                    id="ask-gen"
                                                    className="tab-pane fade code-review-tab show active tab-content "
                                                >


                                                    <div className="chat-box px-sm-0">
                                                        <div className="container px-0">
                                                            <div className="row px-sm-0">
                                                                <div
                                                                    className="chat-container"
                                                                    style={{
                                                                        height: '430px',
                                                                        overflowY: 'auto',
                                                                    }}
                                                                >
                                                                    <div className="col-md-12">
                                                                        {chatHistory.map((data) => {

                                                                            if (data.flow == "center") {
                                                                                return (
                                                                                    <div className="chat-divider d-flex justify-content-center">
                                                                                        <span className="font-12 font-medium">{data.text}</span>
                                                                                    </div>
                                                                                );
                                                                            } else if (data?.flow == "ai") {
                                                                                if (data?.type == "getType") {
                                                                                    {
                                                                                        /* bot response */
                                                                                    }
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" className="figma-logo" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Hi{" "}
                                                                                                <span className="text-black">
                                                                                                    {data?.userName || state.userName}
                                                                                                </span>
                                                                                                , How can I help you with?
                                                                                            </div>
                                                                                            {data.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            overallFunction("level3", { type: "define" });
                                                                                                        }}>
                                                                                                        Define
                                                                                                    </button>
                                                                                                    <button className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            overallFunction("level2", { type: "design" });
                                                                                                        }}
                                                                                                    >
                                                                                                        Design
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            overallFunction("level1", { type: "deployment" });
                                                                                                        }}
                                                                                                    >
                                                                                                        Development
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "getConnectionPopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                As a first step{" "}
                                                                                                <a
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target="#SourceConnectionInfo"
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about source system and connection details.
                                                                                            </div>
                                                                                            {data?.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        data-bs-toggle="modal"
                                                                                                        data-bs-target="#SourceConnectionInfo"
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "text") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative mb-4">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                {data.text}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type === 'letsStart') {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                you can now proceed with generating the document
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == false ? (
                                                                                                    <></>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {" "}
                                                                                                        <button
                                                                                                            className="chat-button-secondary font-12 font-bold"
                                                                                                            onClick={() => letsStartwithGeneration()}
                                                                                                        >
                                                                                                            Let's Start
                                                                                                        </button>

                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "requestStatus") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative mb-4">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Your {data.text} has been
                                                                                                {data.status === "Approved" ? (
                                                                                                    <span style={{ color: "green" }}> Approved</span>
                                                                                                ) : (
                                                                                                    <span style={{ color: "red" }}> Rejected</span>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type === 'loadShow' && data.show === true) {

                                                                                    return (
                                                                                        <div className="bot-response position-relative mb-4">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Your {data.docName} document would be generated
                                                                                                based on the below document
                                                                                                {data.referenceDocument.length > 1 ? (
                                                                                                    // Render the div with the loop inside if data.referenceDocument has more than 1 item
                                                                                                    <div className="my-3">
                                                                                                        {/* Use map to render a div for each item in data.referenceDocument */}
                                                                                                        {data.referenceDocument.map((document, index) => (
                                                                                                            <div key={index}>
                                                                                                                <a className="text-decoration-none text-black font-12">
                                                                                                                    <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                    {document} {/* Render the value of each document object */}
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    // If data.referenceDocument has only 1 item, render a single div
                                                                                                    data.referenceDocument.length === 1 && (
                                                                                                        <div className="my-3">
                                                                                                            <a className="text-decoration-none text-black font-12">
                                                                                                                <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                {data.referenceDocument[0]} {/* Render the value of the single document */}
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}

                                                                                                {data.docName} document would be ready soon...
                                                                                                {/* Your {data.docName} document would be generated based on the below document", 
                                need to handle the reference document loop here */}
                                                                                            </div>

                                                                                        </div>

                                                                                    );
                                                                                }
                                                                                else if (data.type == "designPopup") {
                                                                                    debugger
                                                                                    return (

                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                As a first step{" "}
                                                                                                <a
                                                                                                    onClick={() => setShowDesignPopup(true)}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about notebook details.
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == true ? (
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => setShowDesignPopup(true)}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "definePopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                As a first step{" "}
                                                                                                <a
                                                                                                    onClick={() => setShowDefinePopup(true)}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about notebook details.
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == true ? (
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => setShowDefinePopup(true)}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "tableRequirementPopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Sure I can help you with it.Kindly{" "}
                                                                                                <a
                                                                                                    onClick={() => setShowtableReqPopup(true)}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about table requirements.
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == true ? (
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => setShowtableReqPopup(true)}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "getSilverERPopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Kindly{" "}
                                                                                                <a
                                                                                                    onClick={() => setShowsilverERPopup(true)}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about silver zone requirements.
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == true ? (
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => setShowsilverERPopup(true)}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "getGoldERPopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Kindly{" "}
                                                                                                <a
                                                                                                    onClick={() => setShowgoldERPopup(true)}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about gold zone requirements.
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == true ? (
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => setShowgoldERPopup(true)}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "targetSystemPopup") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                As a first step{" "}
                                                                                                <a
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target={state.futureStateName == "Databricks" ? `#TarSys` : state.futureStateName == "ADF" ? "#TarSysADF" : (state.futureStateName == "Synapse" && state.Methodology == "pipeline") ? "#TarSysSynapse" : ""}
                                                                                                    className="chatbot-anchor"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                >
                                                                                                    fill out this form
                                                                                                </a>{" "}
                                                                                                to know about target system details.
                                                                                            </div>
                                                                                            {data.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        data-bs-toggle="modal"
                                                                                                        data-bs-target={state.futureStateName == "Databricks" ? `#TarSys` : state.futureStateName == "ADF" ? "#TarSysADF" : (state.futureStateName == "Synapse" && state.Methodology == "pipeline") ? "#TarSysSynapse" : ""}
                                                                                                    >
                                                                                                        Fill Form
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "codeShow") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                <b>{data?.text}</b>
                                                                                                <div className="mt-3" >
                                                                                                    <a
                                                                                                        onClick={() => {
                                                                                                            debugger;
                                                                                                            console.log(data, "0----------------");
                                                                                                            if (deployStatus == true) {
                                                                                                                getCodePageDetails(data);
                                                                                                            } else {
                                                                                                                setIsEditNotebook(data);
                                                                                                                document.getElementById("showconfirm").click();
                                                                                                            }
                                                                                                        }}
                                                                                                        className="hyperlink"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        {data?.hyperlinkText}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == false ? (
                                                                                                    <></>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {" "}
                                                                                                        <button
                                                                                                            className="chat-button-secondary font-12 font-bold"
                                                                                                            onClick={() => checkLooksGood(data?.codeType)}
                                                                                                        >
                                                                                                            Looks good
                                                                                                        </button>
                                                                                                        <div
                                                                                                            className="btn-group"
                                                                                                            role="group"
                                                                                                            aria-label="Basic outlined example"
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                onClick={() => {
                                                                                                                    debugger;
                                                                                                                    if (deployStatus == true) {
                                                                                                                        getCodePageDetails(data);
                                                                                                                    } else {
                                                                                                                        setIsEditNotebook(data);
                                                                                                                        document.getElementById("showconfirm").click();
                                                                                                                    }
                                                                                                                }}
                                                                                                            >
                                                                                                                <img src="images/chat-action-edit.svg" />
                                                                                                            </button>
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                onClick={() => {
                                                                                                                    download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                                }}
                                                                                                            >
                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                            </button>
                                                                                                            {(data?.codeType != 'metadataTable') ? (
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="images/chat-action-regenerate.svg"
                                                                                                                        height="20px"
                                                                                                                        onClick={() => {
                                                                                                                            regenerate(data);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </button>
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "consolidateDesign") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                <b>{data?.text}</b>
                                                                                                <div className="mt-3" >
                                                                                                    <a
                                                                                                        className="hyperlink"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() => {
                                                                                                            downloadConsolidateDesign();
                                                                                                            // download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                        }}
                                                                                                    >
                                                                                                        {data?.hyperlinkText}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == false ? (
                                                                                                    <></>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {" "}
                                                                                                        <button
                                                                                                            className="chat-button-secondary font-12 font-bold"
                                                                                                            onClick={() => designToDevelopment()}
                                                                                                        >
                                                                                                            Looks good
                                                                                                        </button>
                                                                                                        <div
                                                                                                            className="btn-group"
                                                                                                            role="group"
                                                                                                            aria-label="Basic outlined example"
                                                                                                        >
                                                                                                            {/* <button
                                                type="button"
                                                className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                onClick={() => {
                                                    debugger;
                                                    if (deployStatus == true) {
                                                        getCodePageDetails(data);
                                                    } else {
                                                        setIsEditNotebook(data);
                                                        document.getElementById("showconfirm").click();
                                                    }
                                                }}
                                            >
                                                <img src="images/chat-action-edit.svg" />
                                            </button> */}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                onClick={() => {
                                                                                                                    downloadConsolidateDesign();
                                                                                                                    // download(data?.codeType, data?.hyperlinkText, data?.notebookId ? "notebook" : data?.pipelineId ? 'pipeline' : 'none', data?.notebookId ? data?.notebookId : data?.pipelineId ? data?.pipelineId : '');
                                                                                                                }}
                                                                                                            >
                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                            </button>
                                                                                                            {/* {(data?.codeType != 'metadataTable') ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                >
                                                    <img
                                                        src="images/chat-action-regenerate.svg"
                                                        height="20px"
                                                        onClick={() => {
                                                            regenerate(data);
                                                        }}
                                                    />
                                                </button>
                                            ) : null} */}
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else if (data.type == "pseudocodeShow") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                <b>{data?.text}</b>
                                                                                                <div className="mt-3" >
                                                                                                    <a
                                                                                                        onClick={() => {
                                                                                                            debugger;
                                                                                                            console.log(data, "0----------------");
                                                                                                            if (deployStatus == true) {
                                                                                                                getCodePageDetails(data);
                                                                                                            } else {
                                                                                                                setIsEditNotebook(data);
                                                                                                                document.getElementById("showconfirm").click();
                                                                                                            }
                                                                                                        }}
                                                                                                        className="hyperlink"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                    >
                                                                                                        {data?.hyperlinkText}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                {data.show == false ? (
                                                                                                    <>
                                                                                                        {(data.show == false && data.verified == false) ? (<>
                                                                                                            {" "}

                                                                                                            <div
                                                                                                                className="btn-group"
                                                                                                                role="group"
                                                                                                                aria-label="Basic outlined example"
                                                                                                            >
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                    onClick={() => {
                                                                                                                        debugger;
                                                                                                                        if (deployStatus == true) {
                                                                                                                            getCodePageDetails(data);
                                                                                                                        } else {
                                                                                                                            setIsEditNotebook(data);
                                                                                                                            document.getElementById("showconfirm").click();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-edit.svg" />
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    onClick={() => {
                                                                                                                        downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img src="images/chat-action-download.svg" />
                                                                                                                </button>
                                                                                                                {(data?.codeType != 'metadataTable') ? (
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                        onClick={() => {
                                                                                                                            regenerateDesign(data); console.log('1234567890')
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="images/chat-action-regenerate.svg"
                                                                                                                            height="20px"

                                                                                                                        />
                                                                                                                    </button>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </>
                                                                                                        ) : (<></>)}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {" "}
                                                                                                        <button
                                                                                                            className="chat-button-secondary font-12 font-bold"
                                                                                                            onClick={(e) => {
                                                                                                                checkPseudocodeLooksGood(data?.pseudocodeType)
                                                                                                                e.currentTarget.disabled = true;
                                                                                                                e.currentTarget.style.opacity = '0.3';
                                                                                                                e.currentTarget.style.cursor = 'not-allowed';
                                                                                                            }
                                                                                                            }
                                                                                                        >
                                                                                                            Looks good
                                                                                                        </button>

                                                                                                        <div
                                                                                                            className="btn-group"
                                                                                                            role="group"
                                                                                                            aria-label="Basic outlined example"
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-2 d-flex align-items-center"
                                                                                                                onClick={() => {
                                                                                                                    debugger;
                                                                                                                    if (deployStatus == true) {
                                                                                                                        getCodePageDetails(data);
                                                                                                                    } else {
                                                                                                                        setIsEditNotebook(data);
                                                                                                                        document.getElementById("showconfirm").click();
                                                                                                                    }
                                                                                                                }}
                                                                                                            >
                                                                                                                <img src="images/chat-action-edit.svg" />
                                                                                                            </button>
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                onClick={() => {
                                                                                                                    downloadDesign(data?.pseudocodeType, data?.hyperlinkText, data?.notebookId ? data?.notebookId : '');
                                                                                                                }}
                                                                                                            >
                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                            </button>
                                                                                                            {(data?.codeType != 'metadataTable') ? (
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                    onClick={() => {
                                                                                                                        regenerateDesign(data); console.log('1234567890')
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="images/chat-action-regenerate.svg"
                                                                                                                        height="20px"

                                                                                                                    />
                                                                                                                </button>
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }

                                                                                else if (data.type == "deploy") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Would you like to deploy now?
                                                                                            </div>
                                                                                            {data.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold"
                                                                                                        onClick={() => {
                                                                                                            let data = [...chatHistory];
                                                                                                            data[data.length - 1].show = false;
                                                                                                            if (deployStatus == true) {
                                                                                                                data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                data.push({
                                                                                                                    flow: "ai",
                                                                                                                    type: "text",
                                                                                                                    text: "Oops! Seems that you haven't provided target system information.",
                                                                                                                });
                                                                                                                data.push({
                                                                                                                    flow: "ai",
                                                                                                                    type: "targetSystemPopup",
                                                                                                                    show: true,
                                                                                                                });
                                                                                                                setChatHistory(() => [...data]);
                                                                                                                insertChatHistory([...data]);
                                                                                                            } else {
                                                                                                                deployNotebooksDatabricks("2", "Yes");
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        Yes
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="font-12 font-bold chat-no-btn"
                                                                                                        onClick={() => {
                                                                                                            let data = [...chatHistory];
                                                                                                            data[data.length - 1].show = false;
                                                                                                            data.push({ flow: "user", type: "text", text: "No" });
                                                                                                            data.push({ flow: "ai", type: "reDeploy", show: true });
                                                                                                            setChatHistory(() => [...data]);
                                                                                                        }}
                                                                                                    >
                                                                                                        No
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "reDeploy") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Thanks, You can deploy even after a while.
                                                                                            </div>
                                                                                            {data.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            let data = [...chatHistory];
                                                                                                            data[data.length - 1].show = false;
                                                                                                            if (deployStatus == true) {
                                                                                                                data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                data.push({
                                                                                                                    flow: "ai",
                                                                                                                    type: "text",
                                                                                                                    text: "Oops! Seems that you haven't provided target system information.",
                                                                                                                });
                                                                                                                data.push({
                                                                                                                    flow: "ai",
                                                                                                                    type: "targetSystemPopup",
                                                                                                                    show: true,
                                                                                                                });
                                                                                                                setChatHistory((a) => [...data]);
                                                                                                                insertChatHistory([...data]);
                                                                                                            } else {
                                                                                                                data.push({ flow: "user", type: "text", text: "Yes" });
                                                                                                                setChatHistory(() => [...data]);
                                                                                                                deployNotebooksDatabricks("2", "Deplop Now");
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        Deploy Now
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "somethingDeploy") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                Something went wrong. Please deploy again!
                                                                                            </div>
                                                                                            {data.show == true ? (
                                                                                                <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                    <button
                                                                                                        className="chat-button-primary font-12 font-bold "
                                                                                                        onClick={() => {
                                                                                                            deployNotebooksDatabricks("2", "Deploy Now");
                                                                                                        }}
                                                                                                    >
                                                                                                        Deploy Now
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                } else if (data.type == "textwait") {
                                                                                    return (
                                                                                        <div className="bot-response position-relative">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                            </span>
                                                                                            <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                {data.text}
                                                                                            </div>
                                                                                            <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                <button
                                                                                                    className="chat-button-primary font-12 font-bold "
                                                                                                    onClick={() => {
                                                                                                        document.getElementById("pageLoader").style.display = "block";
                                                                                                        fetchChatHistory()
                                                                                                    }}
                                                                                                >
                                                                                                    Refresh
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                } else {
                                                                                    return <></>
                                                                                }
                                                                            } else if (data.flow == "user") {
                                                                                if (data.type == "text") {
                                                                                    return (
                                                                                        <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                            <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                <img
                                                                                                    src={`${localStorage?.getItem("dXNlckltYWdl1") != null
                                                                                                        ? decode(
                                                                                                            localStorage
                                                                                                                ?.getItem("dXNlckltYWdl1")
                                                                                                                ?.substring(
                                                                                                                    15,
                                                                                                                    localStorage.getItem("dXNlckltYWdl1")?.length - 10
                                                                                                                )
                                                                                                        )
                                                                                                        : "images/userDefault.svg"
                                                                                                        }`}
                                                                                                    alt="user-profile"
                                                                                                    className="chat-user-img"
                                                                                                />
                                                                                            </span>
                                                                                            <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                {data.text}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                } else {
                                                                                    return <></>
                                                                                }
                                                                            } else {
                                                                                return <></>
                                                                            }
                                                                        })}
                                                                        {showLooksGood ? (
                                                                            <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                    <img
                                                                                        src={`${localStorage?.getItem(
                                                                                            "dXNlckltYWdl1"
                                                                                        ) != null
                                                                                            ? decode(localStorage?.getItem("dXNlckltYWdl1")?.substring(15,
                                                                                                localStorage.getItem(
                                                                                                    "dXNlckltYWdl1"
                                                                                                )?.length - 10
                                                                                            )
                                                                                            )
                                                                                            : "images/userDefault.svg"
                                                                                            }`}
                                                                                        alt="user-profile"
                                                                                        className="chat-user-img"
                                                                                    />
                                                                                </span>
                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                    Looks good
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {showLoader == true ? (
                                                                            <div className="bot-response position-relative">
                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                    <img
                                                                                        src="images/GenELT-chat-logo.svg"
                                                                                        alt="user-profile"
                                                                                    />
                                                                                </span>
                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                    <div className="dot-typing"></div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        <div id='Imdown'></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {codeDataValues?.codeType == "metadata" ? (
                            <MetadataNotebook
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}

                            />
                        ) : codeDataValues?.codeType == "Extraction" ? (
                            <BronzeNotebook
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "Silver" ? (
                            <SilverNotebook
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "DataFlow" ? (
                            <Dataflow
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "Dataset" ? (
                            <Dataset
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "Gold" ? (
                            <GoldNotebook
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "ddl" ? (
                            <DDLScripts
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "linkedServices" ? (
                            <LinkedServices
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "logger" ? (
                            <LoggerNotebook
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "utility" ? (
                            <Utility
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        ) : codeDataValues?.codeType == "Orchestration" ? (
                            <Orchestraion
                                data={codeDataValues}
                                setShowCode={() => setShowCode(false)}
                                onChange={() => updateValues()}
                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                            />
                        )
                            : codeDataValues?.codeType == "UnitTestCase" ? (
                                <UnitTestCaseCodePage
                                    data={codeDataValues}
                                    setShowCode={() => setShowCode(false)}
                                    onChange={() => updateValues()}
                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                />
                            )
                                : codeDataValues?.codeType == "dataQualityCode" ? (
                                    <DataQualityPage
                                        data={codeDataValues}
                                        setShowCode={() => setShowCode(false)}
                                        onChange={() => updateValues()}
                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                    />
                                )
                                    : codeDataValues?.codeType == "checklist" ? (
                                        <CodeReviewChecklistDev
                                            data={codeDataValues}
                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                            setShowCode={() => setShowCode(false)}
                                            onChange={() => updateValues()}
                                        />

                                    )
                                        : codeDataValues?.codeType == "metadataTable" ? (
                                            <MetaDataGridDev
                                                data={codeDataValues}
                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                setShowCode={() => setShowCode(false)}
                                                onChange={() => updateValues()}
                                            />

                                        )
                                            ///design phase components
                                            : codeDataValues?.pseudocodeType == "loggerPseudocode" ? (
                                                <LoggerGridPseudocode
                                                    data={codeDataValues}
                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                    setShowCode={() => setShowCode(false)}
                                                    onChange={() => updateValues()}
                                                />
                                            )
                                                : codeDataValues?.pseudocodeType == "utilityPseudocode" ? (
                                                    <Utilities
                                                        data={codeDataValues}
                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                        setShowCode={() => setShowCode(false)}
                                                        onChange={() => updateValues()}
                                                    />
                                                )
                                                    : codeDataValues?.pseudocodeType == "dataDictionarySilver" ? (
                                                        <DataDictionarySilver
                                                            data={codeDataValues}
                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                            setShowCode={() => setShowCode(false)}
                                                            onChange={() => updateValues()}
                                                        />
                                                    )
                                                        : codeDataValues?.pseudocodeType == "dataDictionaryGold" ? (
                                                            <DataDictionaryGold
                                                                data={codeDataValues}
                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                setShowCode={() => setShowCode(false)}
                                                                onChange={() => updateValues()}
                                                            />
                                                        )
                                                            : codeDataValues?.pseudocodeType == "extractionSequence" ? (
                                                                <ExtractionDBSequence
                                                                    data={codeDataValues}
                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                    setShowCode={() => setShowCode(false)}
                                                                    onChange={() => updateValues()}
                                                                />
                                                            )
                                                                : codeDataValues?.pseudocodeType == "transformationSequence" ? (
                                                                    <SilverDBSequence
                                                                        data={codeDataValues}
                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                        setShowCode={() => setShowCode(false)}
                                                                        onChange={() => updateValues()}
                                                                    />
                                                                )
                                                                    : codeDataValues?.pseudocodeType == "goldSequence" ? (
                                                                        <GoldDBSequence
                                                                            data={codeDataValues}
                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                            setShowCode={() => setShowCode(false)}
                                                                            onChange={() => updateValues()}
                                                                        />
                                                                    )
                                                                        : codeDataValues?.pseudocodeType == "sourceToExtractionER" ? (
                                                                            <SourceToExtractionER
                                                                                data={codeDataValues}
                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                setShowCode={() => setShowCode(false)}
                                                                                onChange={() => updateValues()}
                                                                            />
                                                                        )
                                                                            : codeDataValues?.pseudocodeType == "BronzeToSilverER" ? (
                                                                                <BronzeToSilverER
                                                                                    data={codeDataValues}
                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                    setShowCode={() => setShowCode(false)}
                                                                                    onChange={() => updateValues()}
                                                                                />
                                                                            )
                                                                                : codeDataValues?.pseudocodeType == "SilverToGoldER" ? (
                                                                                    <SilverToGoldER
                                                                                        data={codeDataValues}
                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                        setShowCode={() => setShowCode(false)}
                                                                                        onChange={() => updateValues()}
                                                                                    />
                                                                                )
                                                                                    : codeDataValues?.pseudocodeType == "dataModelGold" ? (
                                                                                        <DataModelGold
                                                                                            data={codeDataValues}
                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                            setShowCode={() => setShowCode(false)}
                                                                                            onChange={() => updateValues()}
                                                                                        />
                                                                                    )
                                                                                        : codeDataValues?.pseudocodeType == "dataModelSilver" ? (
                                                                                            <DataModelSilver
                                                                                                data={codeDataValues}
                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                onChange={() => updateValues()}
                                                                                            />
                                                                                        )
                                                                                            : codeDataValues?.pseudocodeType == "extractionRequirement" ? (
                                                                                                <ExtractionReqGrid
                                                                                                    data={codeDataValues}
                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                    onChange={() => updateValues()}
                                                                                                />
                                                                                            )
                                                                                                : codeDataValues?.pseudocodeType == "tableMapping" ? (
                                                                                                    <TableMappingRework
                                                                                                        data={codeDataValues}
                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}

                                                                                                        isTotalVerified={(status) => { if (status == true) { totalVerified(); console.log(status, "statusssss"); } }}
                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                        onChange={() => { }}
                                                                                                    />
                                                                                                )
                                                                                                    : codeDataValues?.pseudocodeType == "columnMapping" ? (
                                                                                                        <ColumnMappingRework
                                                                                                            data={codeDataValues}
                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                            isTotalVerified={(status) => { if (status == true) { totalVerified() } }}
                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                            onChange={() => { updateValues() }}
                                                                                                        />
                                                                                                    )
                                                                                                        : codeDataValues?.pseudocodeType == "metadataTable" ? (
                                                                                                            <MetadataTable
                                                                                                                data={codeDataValues}
                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                onChange={() => updateValues()}
                                                                                                            />
                                                                                                        )
                                                                                                            : codeDataValues?.pseudocodeType == "dDLScriptsBronze" ? (
                                                                                                                <DDLScriptPseudoBronze
                                                                                                                    data={codeDataValues}
                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                    onChange={() => updateValues()}
                                                                                                                />
                                                                                                            )
                                                                                                                : codeDataValues?.pseudocodeType == "dDLScriptsSilver" ? (
                                                                                                                    <DDLScriptPseudoSilver
                                                                                                                        data={codeDataValues}
                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                        onChange={() => updateValues()}
                                                                                                                    />
                                                                                                                )
                                                                                                                    : codeDataValues?.pseudocodeType == "dDLScriptsGold" ? (
                                                                                                                        <DDLScriptPseudoGold
                                                                                                                            data={codeDataValues}
                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                            onChange={() => updateValues()}
                                                                                                                        />
                                                                                                                    )
                                                                                                                        : codeDataValues?.pseudocodeType == "pipelineSummary" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                            <PipelineSummaryDB
                                                                                                                                data={codeDataValues}
                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                onChange={() => updateValues()}
                                                                                                                            />

                                                                                                                        )
                                                                                                                            : codeDataValues?.pseudocodeType == "pipelineSummary" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                <PipelineSummarySY
                                                                                                                                    data={codeDataValues}
                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                />
                                                                                                                            )
                                                                                                                                : codeDataValues?.pseudocodeType == "pipelineSummary" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift")) ? (
                                                                                                                                    <PipelineSummaryRS
                                                                                                                                        data={codeDataValues}
                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                    />
                                                                                                                                )
                                                                                                                                    : codeDataValues?.pseudocodeType == "orchestrationUML" ? (
                                                                                                                                        <OrchestrationDiagram
                                                                                                                                            data={codeDataValues}
                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                        />
                                                                                                                                    )
                                                                                                                                        : codeDataValues?.pseudocodeType == "extraction" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                            <ExtractionDataBricks
                                                                                                                                                data={codeDataValues}
                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                            />

                                                                                                                                        )
                                                                                                                                            : codeDataValues?.pseudocodeType == "extraction" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                <BronzePipeline
                                                                                                                                                    data={codeDataValues}
                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                />
                                                                                                                                            )
                                                                                                                                                : codeDataValues?.pseudocodeType == "extraction" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift")) ? (
                                                                                                                                                    <ExtractionStoredProcedure
                                                                                                                                                        data={codeDataValues}
                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                                    />
                                                                                                                                                )
                                                                                                                                                    : codeDataValues?.pseudocodeType == "transformation" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                                        <SilverDataBricks
                                                                                                                                                            data={codeDataValues}
                                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                                        />

                                                                                                                                                    )
                                                                                                                                                        : codeDataValues?.pseudocodeType == "transformation" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                            <SilverPipeline
                                                                                                                                                                data={codeDataValues}
                                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                                            />
                                                                                                                                                        )
                                                                                                                                                            : codeDataValues?.pseudocodeType == "transformation" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift")) ? (
                                                                                                                                                                <SilverStoredProcedure
                                                                                                                                                                    data={codeDataValues}
                                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                                />
                                                                                                                                                            )
                                                                                                                                                                : codeDataValues?.pseudocodeType == "gold" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                                                    <GoldDataBricks
                                                                                                                                                                        data={codeDataValues}
                                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                                                    />

                                                                                                                                                                )
                                                                                                                                                                    : codeDataValues?.pseudocodeType == "gold" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                                        <GoldPipeline
                                                                                                                                                                            data={codeDataValues}
                                                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                                                        />
                                                                                                                                                                    )
                                                                                                                                                                        : (codeDataValues?.pseudocodeType == "gold" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift"))) ? (
                                                                                                                                                                            <GoldStoredProcedure
                                                                                                                                                                                data={codeDataValues}
                                                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                                                            />
                                                                                                                                                                        )
                                                                                                                                                                            : codeDataValues?.pseudocodeType == "orchestrationDocument" ? (
                                                                                                                                                                                <OrchestrationPseudo
                                                                                                                                                                                    data={codeDataValues}
                                                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                                                />
                                                                                                                                                                            )
                                                                                                                                                                                : codeDataValues?.pseudocodeType == "dataflowDesign" ? (
                                                                                                                                                                                    <DataFlowPseudo
                                                                                                                                                                                        data={codeDataValues}
                                                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                                                                    />
                                                                                                                                                                                )
                                                                                                                                                                                    : codeDataValues?.pseudocodeType == "unitTestPseudo" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                                                                        <UnitTestCaseGridDB
                                                                                                                                                                                            data={codeDataValues}
                                                                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                                                                        />

                                                                                                                                                                                    )
                                                                                                                                                                                        : codeDataValues?.pseudocodeType == "unitTestPseudo" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                                                            <UnitTestCaseADF
                                                                                                                                                                                                data={codeDataValues}
                                                                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                                                                            />
                                                                                                                                                                                        )
                                                                                                                                                                                            : (codeDataValues?.pseudocodeType == "unitTestPseudo" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift"))) ? (
                                                                                                                                                                                                <UnitTestCaseSnowFlakes
                                                                                                                                                                                                    data={codeDataValues}
                                                                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                                                                />
                                                                                                                                                                                            )
                                                                                                                                                                                                : codeDataValues?.pseudocodeType == "dataQualityPsueudocode" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                                                                                    <DataQualityDB
                                                                                                                                                                                                        data={codeDataValues}
                                                                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                                                                                    />

                                                                                                                                                                                                )
                                                                                                                                                                                                    : codeDataValues?.pseudocodeType == "dataQualityPsueudocode" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                                                                        <DataQualityPipeline
                                                                                                                                                                                                            data={codeDataValues}
                                                                                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                                                                                        />
                                                                                                                                                                                                    )
                                                                                                                                                                                                        : codeDataValues?.pseudocodeType == "dataQualityPsueudocode" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift")) ? (
                                                                                                                                                                                                            <DataQualitySP
                                                                                                                                                                                                                data={codeDataValues}
                                                                                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                                                                                            />
                                                                                                                                                                                                        )

                                                                                                                                                                                                            //design 
                                                                                                                                                                                                            : codeDataValues?.pseudocodeType == "codeReviewChecklist" && (state.futureStateName === "Databricks" || state.futureStateName === "AWS Glue" || (state.futureStateName === "Synapse" && state.Methodology === "notebook")) ? (
                                                                                                                                                                                                                <CodeReviewChecklistDesignDB
                                                                                                                                                                                                                    data={codeDataValues}
                                                                                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                                                                                />

                                                                                                                                                                                                            )
                                                                                                                                                                                                                : codeDataValues?.pseudocodeType == "codeReviewChecklist" && (state.futureStateName === "ADF" || (state.futureStateName === "Synapse" && state.Methodology === "pipeline")) ? (
                                                                                                                                                                                                                    <CodeReviewChecklistDesignSY
                                                                                                                                                                                                                        data={codeDataValues}
                                                                                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                        onChange={() => updateValues()}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                )
                                                                                                                                                                                                                    : codeDataValues?.pseudocodeType == "codeReviewChecklist" && (state.futureStateName === "Snowflake" || (state.futureStateName === "Redshift")) ? (
                                                                                                                                                                                                                        <CodeReviewChecklistDesignRS
                                                                                                                                                                                                                            data={codeDataValues}
                                                                                                                                                                                                                            isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                            setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                            onChange={() => updateValues()}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                        : codeDataValues?.pseudocodeType == "linkedServices" ? (
                                                                                                                                                                                                                            <LinkedServicePseudo
                                                                                                                                                                                                                                data={codeDataValues}
                                                                                                                                                                                                                                isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                                setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                                onChange={() => updateValues()}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                        )
                                                                                                                                                                                                                            : codeDataValues?.pseudocodeType == "dataset" ? (
                                                                                                                                                                                                                                <DatasetPseudo
                                                                                                                                                                                                                                    data={codeDataValues}
                                                                                                                                                                                                                                    isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                                    setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                                    onChange={() => updateValues()}
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                // : codeDataValues?.pseudocodeType == "Datasets" ? (
                                                                                                                                                                                                                                //     <DatasetPseudo
                                                                                                                                                                                                                                //         data={codeDataValues}
                                                                                                                                                                                                                                //         isEdit={() => setIsEdit(true)}
                                                                                                                                                                                                                                //         setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                                //         onChange={() => updateValues()}
                                                                                                                                                                                                                                //     />
                                                                                                                                                                                                                                // )
                                                                                                                                                                                                                                // : codeDataValues?.pseudocodeType == "Datasets" ? (
                                                                                                                                                                                                                                //     <DatasetPseudo
                                                                                                                                                                                                                                //         data={codeDataValues}
                                                                                                                                                                                                                                //         isEdit={() => setIsEdit(true)}
                                                                                                                                                                                                                                //         setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                                //         onChange={() => updateValues()}
                                                                                                                                                                                                                                //     />
                                                                                                                                                                                                                                // )
                                                                                                                                                                                                                                //developmnt phase
                                                                                                                                                                                                                                : codeDataValues?.codeType == "metadataTable" ? (
                                                                                                                                                                                                                                    <MetaDataGridDev deployStatus={deployStatus}
                                                                                                                                                                                                                                        data={codeDataValues}
                                                                                                                                                                                                                                        setShowCode={() => setShowCode(false)}
                                                                                                                                                                                                                                        isEdit={(status) => { if (status == true) { dataChanged() } }}
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                ) :
                                                                                                                                                                                                                                    (
                                                                                                                                                                                                                                        <></>
                                                                                                                                                                                                                                    )}
                    </>
                )}

                {/* Source Syatem Modal */}
                {sourcePhase === 'Design' || sourcePhase === 'Define' ?
                    <div
                        className="modal fade"
                        id="SourceConnectionInfo"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="ConnectionInfoLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content px-2">
                                <div className="modal-header border-0">
                                    <h5
                                        className="modal-title d-flex align-items-center font-medium font-20"
                                        id="ConnectionInfoLabel"
                                    >
                                        Source System and Connection Info
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setShowSourcePopup(true);
                                            if (disableSourceFields == false) {
                                                setSourceSystemDetails([]);
                                                setSourceSystemInfo({
                                                    systemName: "",
                                                    InternalExternal: "Select",
                                                    systemType: "Select",
                                                    subjectArea: "Select",
                                                    dataFormat: "Select",
                                                    sourceSystemNameId: "Select",
                                                    sourceSystemType: "Select",
                                                    dataVolume: "Select",
                                                    descriptions: "",
                                                    connectionDetails: {},
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                {showSourcePopup == true ? (
                                    <div className="modal-body py-0 my-4">
                                        <div className="row">
                                            <div className="col-12">
                                                {/* stepper starts here */}
                                                <div className="d-flex justify-content-center mb-5">
                                                    <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                        <li className="stepper-sty active me-5 pe-4 d-flex align-items-center">
                                                            <span className="connect-info-icon icon-sty" />
                                                            <span className="stepper-text font-14 font-bold ms-2">
                                                                Source System
                                                            </span>
                                                        </li>
                                                        <li className=" stepper-sty me-5 pe-4 d-flex align-items-center position-relative">
                                                            <span className="meta-info-icon icon-sty stepper-line " />
                                                            <span className="stepper-text font-14 font-bold ms-2">
                                                                Connection Info
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* stepper ends here */}
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="table-responsive meta-table-scrl mt-3">
                                                    <table className="table table-borderless rounded custom-grid border-0">
                                                        <thead className="sticky-top-pos custom-tableHeader">
                                                            <tr>
                                                                <th>System Name</th>
                                                                <th>Internal/External</th>
                                                                <th>System Type</th>
                                                                <th>Subject Area</th>
                                                                <th>Data Analysis</th>
                                                                <th>Source System Name</th>
                                                                <th>Source System Type</th>
                                                                <th>Data Volume</th>
                                                                <th>Description</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sourceSystemInfo?.index == undefined &&
                                                                disableSourceFields == false ? (
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control cust-input-sty font-12 font-regular"
                                                                            placeholder="Enter here"
                                                                            value={sourceSystemInfo.systemName}
                                                                            name="systemName"
                                                                            onChange={(e) =>
                                                                                handleSourceSystemInputChange(
                                                                                    e.target.name,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                style={{ zIndex: 1001 }}
                                                                            >
                                                                                {sourceSystemInfo.InternalExternal}
                                                                            </a>
                                                                            <ul
                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "InternalExternal",
                                                                                            "Internal"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        Internal
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "InternalExternal",
                                                                                            "External"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        External
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded={isOpen}
                                                                                style={{ zIndex: 1001 }}
                                                                                onClick={() => { handleDropdownToggle(); setIsOpen(false); }}
                                                                            >
                                                                                {selectedSystemType.length > 0 ? selectedSystemType.join(', ') : 'Select'}
                                                                            </a>
                                                                            <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink" style={{ left: '0', right: 'auto' }}>
                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            type="checkbox"
                                                                                            id="checkboxOperational"
                                                                                            checked={selectedSystemType.includes("Operational")}
                                                                                            onChange={() => handleCheckboxChange("Operational")}
                                                                                        />
                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxOperational" onClick={(e) => e.stopPropagation()}>
                                                                                            Operational
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            type="checkbox"
                                                                                            id="checkboxFinancial"
                                                                                            checked={selectedSystemType.includes("Financial")}
                                                                                            onChange={() => handleCheckboxChange("Financial")}
                                                                                        />
                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                            Financial
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            type="checkbox"
                                                                                            id="checkboxHR"
                                                                                            checked={selectedSystemType.includes("HR")}
                                                                                            onChange={() => handleCheckboxChange("HR")}
                                                                                        />
                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxHR" onClick={(e) => e.stopPropagation()}>
                                                                                            HR
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            type="checkbox"
                                                                                            id="checkboxSales"
                                                                                            checked={selectedSystemType.includes("Sales")}
                                                                                            onChange={() => handleCheckboxChange("Sales")}
                                                                                        />
                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxSales" onClick={(e) => e.stopPropagation()}>
                                                                                            Sales
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            type="checkbox"
                                                                                            id="checkboxMarketing"
                                                                                            checked={selectedSystemType.includes("Marketing")}
                                                                                            onChange={() => handleCheckboxChange("Marketing")}
                                                                                        />
                                                                                        <label className="form-check-label font-12 cursorpointer" htmlFor="checkboxMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                            Marketing
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown" onClick={() => { handleDropdownToggle(); setIsOpen(false); }}>
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink2"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded={isOpen}
                                                                                style={{ zIndex: 1001 }}
                                                                            >
                                                                                {selectedSubjectArea.length > 0 ? selectedSubjectArea.join(', ') : 'Select'}
                                                                            </a>
                                                                            <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink2">
                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                            type="checkbox"
                                                                                            id="flexCheckOperational"
                                                                                            checked={selectedSubjectArea.includes('Operational')}
                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Operational")}
                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                        />
                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckOperational" onClick={(e) => e.stopPropagation()}>
                                                                                            Operational
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                            type="checkbox"
                                                                                            id="flexCheckFinancial"
                                                                                            checked={selectedSubjectArea.includes('Financial')}
                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Financial")}
                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                        />
                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                            Financial
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                            type="checkbox"
                                                                                            id="flexCheckHR"
                                                                                            checked={selectedSubjectArea.includes('HR')}
                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "HR")}
                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                        />
                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckHR" onClick={(e) => e.stopPropagation()}>
                                                                                            HR
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                            type="checkbox"
                                                                                            id="flexCheckSales"
                                                                                            checked={selectedSubjectArea.includes('Sales')}
                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Sales")}
                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                        />
                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckSales" onClick={(e) => e.stopPropagation()}>
                                                                                            Sales
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className="dropdown-item form-check psss cursorpointer" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input cursorpointer"
                                                                                            style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                            type="checkbox"
                                                                                            id="flexCheckMarketing"
                                                                                            checked={selectedSubjectArea.includes('Marketing')}
                                                                                            onChange={() => handleSourceSystemInputChange("subjectArea", "Marketing")}
                                                                                            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                        />
                                                                                        <label className="font-12 cursorpointer" htmlFor="flexCheckMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                            Marketing
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3" role="button"
                                                                                id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}
                                                                            >
                                                                                {sourceSystemInfo.dataFormat}
                                                                            </a>
                                                                            <ul
                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataFormat",
                                                                                            "Automatic"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        Automatic
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataFormat",
                                                                                            "Manual"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        Manual
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                style={{ zIndex: 1001 }}
                                                                            >
                                                                                {sourceSystemInfo.sourceSystemNameId !=
                                                                                    "Select"
                                                                                    ? sourceSystems.filter(
                                                                                        (data) =>
                                                                                            data.sourceSystemNameId ==
                                                                                            sourceSystemInfo.sourceSystemNameId
                                                                                    )[0].sourceSystemNameName
                                                                                    : "Select"}
                                                                            </a>
                                                                            <ul className="dropdown-menu cust-dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                {sourceSystems
                                                                                    .filter((data) => data.projectTypeId === state.projectTypeId)
                                                                                    .map((data) => (
                                                                                        state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                            (data.sourceSystemNameId === "27940D09-4AEE-401D-A5D1-C5604624B804" ||
                                                                                                data.sourceSystemNameId === "2B6C0990-0653-433D-ADC5-415BF3B28757" ||
                                                                                                data.sourceSystemNameId === "55253BD8-9AD0-4FA8-933B-D34B85414069" ||
                                                                                                data.sourceSystemNameId === "68C9F589-6347-4564-A3D7-4787BE3B43F4" ||
                                                                                                data.sourceSystemNameId === "6F1E2B59-E1A7-4F15-9A42-88802D2268F2" ||
                                                                                                data.sourceSystemNameId === "93AD6411-C531-4451-A9D4-3A03F4458287") && (
                                                                                                <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                    <a className="dropdown-item font-12">
                                                                                                        {data.sourceSystemNameName}
                                                                                                    </a>
                                                                                                </li>
                                                                                            )
                                                                                        ) : (
                                                                                            state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                (
                                                                                                    data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ||
                                                                                                    data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5") && (
                                                                                                    <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                        <a className="dropdown-item font-12">
                                                                                                            {data.sourceSystemNameName}
                                                                                                        </a>
                                                                                                    </li>
                                                                                                )
                                                                                            ) : (
                                                                                                state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ? (
                                                                                                    <>
                                                                                                        {state.futureStateName === "Databricks" && (
                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                <a className="dropdown-item font-12">
                                                                                                                    Redshift
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5" ? (
                                                                                                        <>
                                                                                                            {state.futureStateName === "Databricks" && (
                                                                                                                <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                    <a className="dropdown-item font-12">
                                                                                                                        Snowflake
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                            <a className="dropdown-item font-12">
                                                                                                                {data.sourceSystemNameName}
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )

                                                                                    ))}
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                style={{ zIndex: 1001 }}
                                                                            >
                                                                                {sourceSystemInfo.sourceSystemType}
                                                                            </a>
                                                                            <ul
                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >

                                                                                {sourceSystemInfo.sourceSystemNameId !=
                                                                                    "Select" ? (
                                                                                    <>
                                                                                        {cloudonprem.toLocaleLowerCase().replace(/ /g, "").includes(
                                                                                            sourceSystems.filter(
                                                                                                (a) => a.sourceSystemNameId == sourceSystemInfo.sourceSystemNameId)[0]
                                                                                                ?.sourceSystemNameName.toLocaleLowerCase()
                                                                                                .replace(/ /g, "")
                                                                                        ) ? (
                                                                                            <li className="cursorpointer"
                                                                                                onClick={() => handleSourceSystemInputChange("sourceSystemType", "Onprem")}
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Onprem
                                                                                                </a>
                                                                                            </li>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        <li className="cursorpointer"
                                                                                            onClick={() => handleSourceSystemInputChange("sourceSystemType", "Cloud")}
                                                                                        >
                                                                                            <a className="dropdown-item font-12">
                                                                                                Cloud
                                                                                            </a>
                                                                                        </li>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                style={{ zIndex: 1001 }}
                                                                            >
                                                                                {sourceSystemInfo.dataVolume}
                                                                            </a>
                                                                            <ul
                                                                                className="dropdown-menu cust-dropdown-menu"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "Less than 1 GB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        Less than 1 GB
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "1 GB to 500 GB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        1 GB to 500 GB
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "500 GB to 1 TB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        500 GB to 1 TB
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "1 TB to 10 TB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        1 TB to 10 TB
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "10 TB to 100 TB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        10 TB to 100 TB
                                                                                    </a>
                                                                                </li>
                                                                                <li className="cursorpointer"
                                                                                    onClick={() =>
                                                                                        handleSourceSystemInputChange(
                                                                                            "dataVolume",
                                                                                            "More than 100 TB"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <a className="dropdown-item font-12">
                                                                                        More than 100 TB
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={sourceSystemInfo.descriptions}
                                                                            placeholder="Enter"
                                                                            onChange={(e) =>
                                                                                handleSourceSystemInputChange(
                                                                                    "descriptions",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            className="form-control cust-input-sty font-14 font-regular"
                                                                        />
                                                                    </td>
                                                                    {/*PS_CD_1.73-1.79  */}
                                                                    <td className="text-center">
                                                                        <a
                                                                            className={
                                                                                sourceSystemDetails.findIndex(
                                                                                    (data) => data.systemName === sourceSystemInfo.systemName
                                                                                ) === -1 &&
                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                    sourceSystemInfo.systemType !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== ""
                                                                                    ? ""
                                                                                    : "disable-btn"
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                    sourceSystemInfo.systemType !== "Select"
                                                                                ) {
                                                                                    setSourceSystemDetails([...sourceSystemDetails, sourceSystemInfo]);
                                                                                    setSourceSystemInfo({
                                                                                        systemName: "",
                                                                                        InternalExternal: "Select",
                                                                                        systemType: "Select",
                                                                                        subjectArea: "Select",
                                                                                        dataFormat: "Select",
                                                                                        sourceSystemNameId: "Select",
                                                                                        sourceSystemType: "Select",
                                                                                        dataVolume: "Select",
                                                                                        descriptions: "",
                                                                                        connectionDetails: {},
                                                                                    });
                                                                                    setSelectedSystemType([]);
                                                                                    setSelectedSubjectArea([]);
                                                                                }
                                                                            }}
                                                                            style={{
                                                                                opacity:
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                        sourceSystemInfo.dataFormat !== "Select" &&
                                                                                        sourceSystemInfo.dataVolume !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                        sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                        sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                        sourceSystemInfo.subjectArea !== "Select" &&
                                                                                        sourceSystemInfo.systemName.trim() !== "" &&
                                                                                        sourceSystemInfo.systemType !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== ""
                                                                                        ? 1
                                                                                        : 0.5,
                                                                                cursor:
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                        sourceSystemInfo.dataFormat !== "Select" &&
                                                                                        sourceSystemInfo.dataVolume !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                        sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                        sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                        sourceSystemInfo.subjectArea !== "Select" &&
                                                                                        sourceSystemInfo.systemName.trim() !== "" &&
                                                                                        sourceSystemInfo.systemType !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== ""
                                                                                        ? "pointer"
                                                                                        : "not-allowed",
                                                                            }}
                                                                        >
                                                                            <span className="cust-cursor-pointer">
                                                                                <img src="images/add-icons.svg" alt="Add" />
                                                                            </span>
                                                                        </a>
                                                                    </td>

                                                                </tr>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {console.log(
                                                                sourceSystemDetails,
                                                                "sourceSystemDetails"
                                                            )}
                                                            {sourceSystemDetails.map((data, index) => {
                                                                return (
                                                                    <>
                                                                        {sourceSystemInfo?.index == index ? (
                                                                            <tr>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control cust-input-sty font-12 font-regular"
                                                                                        placeholder="Enter here"
                                                                                        value={sourceSystemInfo.systemName}
                                                                                        name="systemName"
                                                                                        onChange={(e) =>
                                                                                            handleSourceSystemInputChange(
                                                                                                e.target.name,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.InternalExternal}
                                                                                        </a>
                                                                                        <ul
                                                                                            className="dropdown-menu cust-dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuLink"
                                                                                        >
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "InternalExternal",
                                                                                                        "Internal"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Internal
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "InternalExternal",
                                                                                                        "External"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    External
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded={isOpen}
                                                                                            style={{ zIndex: 1001 }}
                                                                                            onClick={() => { handleDropdownToggle(); setIsOpen(false); }}
                                                                                        >
                                                                                            {sourceSystemInfo.systemType.length > 0 ? sourceSystemInfo.systemType.join(', ') : 'Select'}
                                                                                        </a>
                                                                                        <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink" style={{ left: '0', right: 'auto' }}>
                                                                                            <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="checkboxOperational"
                                                                                                        checked={selectedSystemType.includes("Operational")}
                                                                                                        onChange={() => handleCheckboxChange("Operational")}
                                                                                                    />
                                                                                                    <label className="form-check-label font-12" htmlFor="checkboxOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                        Operational
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="checkboxFinancial"
                                                                                                        checked={selectedSystemType.includes("Financial")}
                                                                                                        onChange={() => handleCheckboxChange("Financial")}
                                                                                                    />
                                                                                                    <label className="form-check-label font-12" htmlFor="checkboxFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                        Financial
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="checkboxHR"
                                                                                                        checked={selectedSystemType.includes("HR")}
                                                                                                        onChange={() => handleCheckboxChange("HR")}
                                                                                                    />
                                                                                                    <label className="form-check-label font-12" htmlFor="checkboxHR" onClick={(e) => e.stopPropagation()}>
                                                                                                        HR
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="checkboxSales"
                                                                                                        checked={selectedSystemType.includes("Sales")}
                                                                                                        onChange={() => handleCheckboxChange("Sales")}
                                                                                                    />
                                                                                                    <label className="form-check-label font-12" htmlFor="checkboxSales" onClick={(e) => e.stopPropagation()}>
                                                                                                        Sales
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="checkboxMarketing"
                                                                                                        checked={selectedSystemType.includes("Marketing")}
                                                                                                        onChange={() => handleCheckboxChange("Marketing")}
                                                                                                    />
                                                                                                    <label className="form-check-label font-12" htmlFor="checkboxMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                        Marketing
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>



                                                                                <td>
                                                                                    <div className="dropdown" onClick={() => { handleDropdownToggle(); setIsOpen(false); }}>
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink2"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded={isOpen}
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                           {sourceSystemInfo.subjectArea.length > 0 ? sourceSystemInfo.subjectArea.join(', ') : 'Select'}
                                                                                        </a>
                                                                                        <ul className={`dropdown-menu cust-dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuLink2">
                                                                                            <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                        type="checkbox"
                                                                                                        id="flexCheckOperational"
                                                                                                        checked={selectedSubjectArea.includes('Operational')}
                                                                                                        onChange={() => handleSourceSystemInputChange("subjectArea", "Operational")}
                                                                                                        onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                    />
                                                                                                    <label className="font-12" htmlFor="flexCheckOperational" onClick={(e) => e.stopPropagation()}>
                                                                                                        Operational
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                        type="checkbox"
                                                                                                        id="flexCheckFinancial"
                                                                                                        checked={selectedSubjectArea.includes('Financial')}
                                                                                                        onChange={() => handleSourceSystemInputChange("subjectArea", "Financial")}
                                                                                                        onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                    />
                                                                                                    <label className="font-12" htmlFor="flexCheckFinancial" onClick={(e) => e.stopPropagation()}>
                                                                                                        Financial
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                        type="checkbox"
                                                                                                        id="flexCheckHR"
                                                                                                        checked={selectedSubjectArea.includes('HR')}
                                                                                                        onChange={() => handleSourceSystemInputChange("subjectArea", "HR")}
                                                                                                        onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                    />
                                                                                                    <label className="font-12" htmlFor="flexCheckHR" onClick={(e) => e.stopPropagation()}>
                                                                                                        HR
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                        type="checkbox"
                                                                                                        id="flexCheckSales"
                                                                                                        checked={selectedSubjectArea.includes('Sales')}
                                                                                                        onChange={() => handleSourceSystemInputChange("subjectArea", "Sales")}
                                                                                                        onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                    />
                                                                                                    <label className="font-12" htmlFor="flexCheckSales" onClick={(e) => e.stopPropagation()}>
                                                                                                        Sales
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="dropdown-item form-check psss" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        style={{ paddingLeft: '0' }} // Set paddingLeft to 0 for the input
                                                                                                        type="checkbox"
                                                                                                        id="flexCheckMarketing"
                                                                                                        checked={selectedSubjectArea.includes('Marketing')}
                                                                                                        onChange={() => handleSourceSystemInputChange("subjectArea", "Marketing")}
                                                                                                        onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on checkbox click
                                                                                                    />
                                                                                                    <label className="font-12" htmlFor="flexCheckMarketing" onClick={(e) => e.stopPropagation()}>
                                                                                                        Marketing
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.dataFormat}
                                                                                        </a>
                                                                                        <ul
                                                                                            className="dropdown-menu cust-dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuLink"
                                                                                        >
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataFormat",
                                                                                                        "Automatic"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Automatic
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataFormat",
                                                                                                        "Manual"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Manual
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.sourceSystemNameId !=
                                                                                                "Select"
                                                                                                ? sourceSystems.filter(
                                                                                                    (data) =>
                                                                                                        data.sourceSystemNameId ==
                                                                                                        sourceSystemInfo.sourceSystemNameId
                                                                                                )[0].sourceSystemNameName
                                                                                                : "Select"}
                                                                                        </a>
                                                                                        <ul className="dropdown-menu cust-dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                            {sourceSystems
                                                                                                .filter((data) => data.projectTypeId === state.projectTypeId)
                                                                                                .map((data) => (
                                                                                                    state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                        (data.sourceSystemNameId === "27940D09-4AEE-401D-A5D1-C5604624B804" ||
                                                                                                            data.sourceSystemNameId === "2B6C0990-0653-433D-ADC5-415BF3B28757" ||
                                                                                                            data.sourceSystemNameId === "55253BD8-9AD0-4FA8-933B-D34B85414069" ||
                                                                                                            data.sourceSystemNameId === "68C9F589-6347-4564-A3D7-4787BE3B43F4" ||
                                                                                                            data.sourceSystemNameId === "6F1E2B59-E1A7-4F15-9A42-88802D2268F2" ||
                                                                                                            data.sourceSystemNameId === "93AD6411-C531-4451-A9D4-3A03F4458287") && (
                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                <a className="dropdown-item font-12">
                                                                                                                    {data.sourceSystemNameName}
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        )
                                                                                                    ) : (
                                                                                                        state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && sourceSystemInfo.dataFormat === "Automatic" ? (
                                                                                                            (
                                                                                                                data.sourceSystemNameId === "FBAF4943-E0D9-4532-A291-A43213A5555E" ||
                                                                                                                data.sourceSystemNameId === "E6B658B4-E5D3-41F0-8F84-C97C28ED2CDC") && (
                                                                                                                <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                    <a className="dropdown-item font-12">
                                                                                                                        {data.sourceSystemNameName}
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            )
                                                                                                        ) : (
                                                                                                            state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6D945D84-679E-42CE-9152-3293523A9F53" ? (
                                                                                                                <>
                                                                                                                    {state.futureStateName === "Databricks" && (
                                                                                                                        <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                            <a className="dropdown-item font-12">
                                                                                                                                Redshift
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.sourceSystemNameId === "6B9EA278-1AAF-4818-8025-73E84D39D2C5" ? (
                                                                                                                    <>
                                                                                                                        {state.futureStateName === "Databricks" && (
                                                                                                                            <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                                <a className="dropdown-item font-12">
                                                                                                                                    Snowflake
                                                                                                                                </a>
                                                                                                                            </li>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <li key={data.sourceSystemNameId} onClick={() => handleSourceSystemInputChange("sourceSystemNameId", data.sourceSystemNameId)}>
                                                                                                                        <a className="dropdown-item font-12">
                                                                                                                            {data.sourceSystemNameName}
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )

                                                                                                ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.sourceSystemType}
                                                                                        </a>
                                                                                        <ul
                                                                                            className="dropdown-menu cust-dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuLink"
                                                                                        >
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "sourceSystemType",
                                                                                                        "Onprem"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Onprem
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "sourceSystemType",
                                                                                                        "Cloud"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Cloud
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dropdown">
                                                                                        <a
                                                                                            className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            style={{ zIndex: 1001 }}
                                                                                        >
                                                                                            {sourceSystemInfo.dataVolume}
                                                                                        </a>
                                                                                        <ul
                                                                                            className="dropdown-menu cust-dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuLink"
                                                                                        >
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "Less than 1 GB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    Less than 1 GB
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "1 GB to 500 GB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    1 GB to 500 GB
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "500 GB to 1 TB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    500 GB to 1 TB
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "1 TB to 10 TB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    1 TB to 10 TB
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "10 TB to 100 TB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    10 TB to 100 TB
                                                                                                </a>
                                                                                            </li>
                                                                                            <li
                                                                                                onClick={() =>
                                                                                                    handleSourceSystemInputChange(
                                                                                                        "dataVolume",
                                                                                                        "More than 100 TB"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <a className="dropdown-item font-12">
                                                                                                    More than 100 TB
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={sourceSystemInfo.descriptions}
                                                                                        onChange={(e) =>
                                                                                            handleSourceSystemInputChange(
                                                                                                "descriptions",
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                        className="form-control cust-input-sty font-14 font-regular"
                                                                                    />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <a className={
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                            sourceSystemInfo.dataFormat !== "Select" &&
                                                                                            sourceSystemInfo.dataVolume !== "Select" &&
                                                                                            sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                            sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                            sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                            sourceSystemInfo.subjectArea !== "Select" &&
                                                                                            sourceSystemInfo.systemName.trim() !== "" &&
                                                                                            sourceSystemInfo.systemType !== "Select"
                                                                                            ? ""
                                                                                            : "disable-btn"
                                                                                    }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                sourceSystemDetails.filter((data, i) => data.systemName == sourceSystemInfo.systemName && sourceSystemInfo.index != i).length == 0 &&
                                                                                                sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                sourceSystemInfo.systemType !== "Select"
                                                                                            ) {
                                                                                                let arr = sourceSystemDetails.map((a, index) => {
                                                                                                    if (index === sourceSystemInfo.index) {
                                                                                                        let obj = { ...sourceSystemInfo };
                                                                                                        delete obj.index;
                                                                                                        return obj;
                                                                                                    } else {
                                                                                                        return a;
                                                                                                    }
                                                                                                });
                                                                                                setSourceSystemDetails(arr);
                                                                                                setSourceSystemInfo({
                                                                                                    systemName: "",
                                                                                                    InternalExternal: "Select",
                                                                                                    systemType: "Select",
                                                                                                    subjectArea: "Select",
                                                                                                    dataFormat: "Select",
                                                                                                    sourceSystemNameId: "Select",
                                                                                                    sourceSystemType: "Select",
                                                                                                    dataVolume: "Select",
                                                                                                    descriptions: "",
                                                                                                    connectionDetails: {},
                                                                                                });
                                                                                            }
                                                                                        }}>
                                                                                        <span className="cust-cursor-pointer">
                                                                                            <img
                                                                                                src="images/right.svg"
                                                                                                className="me-3"
                                                                                                width={15}
                                                                                                height={15}
                                                                                                alt="Right Icon"
                                                                                            />
                                                                                        </span>
                                                                                    </a>
                                                                                    <a onClick={() => {
                                                                                        setSourceSystemInfo({
                                                                                            systemName: "",
                                                                                            InternalExternal: "Select",
                                                                                            systemType: "Select",
                                                                                            subjectArea: "Select",
                                                                                            dataFormat: "Select",
                                                                                            sourceSystemNameId: "Select",
                                                                                            sourceSystemType: "Select",
                                                                                            dataVolume: "Select",
                                                                                            descriptions: "",
                                                                                            connectionDetails: {},
                                                                                        });
                                                                                    }}>
                                                                                        <span className="cust-cursor-pointer">
                                                                                            <img
                                                                                                src="images/wrong.svg"
                                                                                                width={20}
                                                                                                height={20}
                                                                                                alt="Wrong Icon"
                                                                                            />
                                                                                        </span>
                                                                                    </a>
                                                                                </td>

                                                                            </tr>
                                                                        ) : (
                                                                            <tr>
                                                                                <td>{data.systemName}</td>
                                                                                <td>{data.InternalExternal}</td>
                                                                                <td>{Array.isArray(data.systemType) ? data.systemType.join(', ') : data.systemType}</td>
                                                                                <td>{Array.isArray(data.subjectArea) ? data.subjectArea.join(', ') : data.subjectArea}</td>
                                                                                <td>{data.dataFormat}</td>
                                                                                <td>
                                                                                    {sourceSystems.filter((a) => a.sourceSystemNameId == data.sourceSystemNameId)[0]?.sourceSystemNameName}
                                                                                </td>
                                                                                <td>{data.sourceSystemType}</td>
                                                                                <td>{data.dataVolume}</td>
                                                                                <td>{data.descriptions}</td>
                                                                                {/* PS_CD_1.81-PS_CD_1.93*/}
                                                                                <td className="text-center">
                                                                                    {disableSourceFields == false ? (
                                                                                        <div>
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    setSourceSystemInfo({
                                                                                                        ...sourceSystemDetails.filter(
                                                                                                            (a, i) => i == index
                                                                                                        )[0],
                                                                                                        index: index,
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img
                                                                                                        src="images/blue-edit-icon.svg"
                                                                                                        className="me-3"
                                                                                                    />
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    setSourceSystemDetails([
                                                                                                        ...sourceSystemDetails.filter(
                                                                                                            (a, i) => i != index
                                                                                                        ),
                                                                                                    ]);
                                                                                                }}
                                                                                            >
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img src="images/delete-icon.svg" />
                                                                                                </span>
                                                                                            </a>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            {sourceSystemDetails.length == 0 ||
                                                                (sourceSystemInfo?.index != undefined &&
                                                                    sourceSystemDetails.length == 1) ? (
                                                                <tr style={{ borderBottom: "none" }}>
                                                                    <td> </td>
                                                                </tr>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="modal-body py-0 my-4 mx-5">
                                        <div className="row">
                                            <div className="col-12">
                                                {/* stepper starts here */}
                                                <div className="d-flex justify-content-center mb-3 mb-md-3 mb-lg-5">
                                                    <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                        <li className="stepper-sty me-lg-5 pe-4 d-flex align-items-center">
                                                            <span className="connect-info-icon icon-sty" />
                                                            <span className="stepper-text font-14 font-bold ms-2">
                                                                <img src="images/check-mark.svg" />
                                                                <span className="ms-2">Source System</span>
                                                            </span>
                                                        </li>
                                                        <li className=" stepper-sty active me-lg-5 pe-4 d-flex align-items-center position-relative">
                                                            <span className="meta-info-icon icon-sty stepper-line " />
                                                            <span className="stepper-text font-14 font-bold ms-2">
                                                                Connection Info
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* stepper ends here */}
                                            </div>
                                            {console.log(sourceSystemDetails, "sourceSystemDetails")}
                                            {sourceSystemDetails.map((data, index) => {
                                                return (
                                                    <div key={index} className="rounded-3 font-20 custom-acc-border font-bold mt-2">
                                                        <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg mb-0 p-2 rounded-3 d-flex align-items-center">
                                                            <button
                                                                className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#view-instruction-${data.systemName}`}
                                                            >
                                                                <img
                                                                    src="images/down-acc-arow.svg"
                                                                    alt="arrow"
                                                                    className="me-3 gen-accord"
                                                                />
                                                                <span
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#view-instruction-${data.systemName}`}
                                                                    className="cursor-pointer text-black font-18 font-medium"
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                >
                                                                    {data.systemName}
                                                                </span>
                                                            </button>
                                                            {/* PS_CD_1.129-1.131 */}
                                                            {data.dataFormat === 'Manual' && ["6B9EA278-1AAF-4818-8025-73E84D39D2C5", "6D945D84-679E-42CE-9152-3293523A9F53", "27940D09-4AEE-401D-A5D1-C5604624B804", "2B6C0990-0653-433D-ADC5-415BF3B28757", "6F1E2B59-E1A7-4F15-9A42-88802D2268F2", "93AD6411-C531-4451-A9D4-3A03F4458287"].includes(data.sourceSystemNameId) && (
                                                                <div className="d-flex justify-content-end col-12 mt-3">
                                                                    <button
                                                                        type="button"
                                                                        className="btn cust-secondary-btn font-16 font-medium d-flex coop"
                                                                        onClick={() => downloadScripts(data.sourceSystemNameId)}
                                                                        style={{ marginRight: '125px', paddingRight: '100px' }}
                                                                    >


                                                                        <span className="me-2">
                                                                            <img src="images/download-icon.svg" alt="add-icon" />
                                                                        </span>
                                                                        Download Script
                                                                    </button>
                                                                </div>
                                                            )}
                                                            <div className="col-12 mt-3">
                                                                {/* melchi change */}
                                                                {/* <div className="text-end" style={{ transform: 'translateX(67%)' }}>
  {data.dataFormat === 'Automatic' && ( 
    <button
      type="button"
      className="btn cust-secondary-btn font-16 font-medium d-flex msss"
      onClick={() => getSourceSystemDetails(data.sourceSystemNameId)}
    >
      <span className="me-2">
        <img src="images/download-icon.svg" alt="add-icon" />
      </span>
      Download Scripts
    </button>
  )}
</div> */}

                                                            </div>
                                                        </p>
                                                        <div className="row">
                                                            <div className={`collapse ${index === 0 ? "show" : ""}`} id={`view-instruction-${data.systemName}`}>
                                                                <div className="custom-accordian-body color-grey-bg py-2 px-3">
                                                                    <div className="row pb-4">
                                                                        {data?.fields.map((field) => {
                                                                            return (
                                                                                <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                                                    <label htmlFor="connection-Name" className="form-label text-black font-14 font-medium">
                                                                                        {" "}
                                                                                        {field == "connectionName" ? "ConnectionName" : field.replace(/_/g, " ")}
                                                                                        <span className="text-red">
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                    <input type="text" disabled={disableSourceFields} className="form-control cust-input-sty font-14 font-regular"
                                                                                        value={sourceSystemDetails[index]["connectionDetails"][field == "connectionName" ? "connectionName" : field.toLocaleLowerCase().replace(/ /g, "_")]}
                                                                                        placeholder="Enter value"
                                                                                        onChange={(e) => { handleConnectionInputChange(field.toLocaleLowerCase().replace(/ /g, "_"), e.target.value, index); }} />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            {data.dataFormat === 'Automatic' && (
                                                                <button
                                                                    type="button"
                                                                    className="btn cust-secondary-btn font-16 font-medium d-flex"
                                                                    onClick={() => getSourceSystemDetails(data.sourceSystemNameId)}
                                                                >
                                                                    <span className="me-2">
                                                                        <img src="images/download-icon.svg" alt="add-icon" />
                                                                    </span>
                                                                    Download Inventory
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12 mb-3">
                                                                <label htmlFor="" className="form-label text-black font-14 font-medium">
                                                                    Upload Data<span className="text-red"> *</span>
                                                                </label>
                                                                <span className="d-block font-12 info-message-section text-black font-medium">
                                                                    Please ensure you included the Data Dictionary
                                                                </span>
                                                            </div>
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="mb-5">
                                                                    <span className="upload-btn-wrapper d-block">
                                                                        {!data?.inventoryDDName && !data?.inventoryName && !data?.fileName ? (
                                                                            <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center" disabled={disableSourceFields}>
                                                                                <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                                                <span className="font-medium font-13 d-block text-grey mb-2">
                                                                                    Browse or drag and drop file
                                                                                </span>
                                                                                <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                                                    Use this template to add info -{" "}
                                                                                    <a onClick={() => exportToExcel()}>
                                                                                        <span className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                                            Download Template
                                                                                        </span>{" "}
                                                                                    </a>{" "}
                                                                                </span>
                                                                            </button>
                                                                        ) : (
                                                                            <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center" disabled={disableSourceFields}>
                                                                                <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                                                <a className="font-medium font-12 d-block">{data?.inventoryName || data?.inventoryDDName || designData?.fileName}</a>
                                                                                <a
                                                                                    style={{ position: "relative", zIndex: "100" }}
                                                                                    onClick={() => {
                                                                                        let updatedSourceSystemDetails = [...sourceSystemDetails];
                                                                                        updatedSourceSystemDetails[index] = {
                                                                                            ...updatedSourceSystemDetails[index],
                                                                                            inventoryDDName: '',
                                                                                            inventoryName: '',
                                                                                            fileName: ''
                                                                                        };
                                                                                        setSourceSystemDetails(updatedSourceSystemDetails);
                                                                                        setValidationErrors(prevErrors => {
                                                                                            const newErrors = {...prevErrors};
                                                                                            delete newErrors[index];
                                                                                            return newErrors;
                                                                                        });
                                                                                        setDesignData('');
                                                                                        setFormValid(false)
                                                                                    }}
                                                                                >
                                                                                    <span className="ms-2">
                                                                                        <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                                                    </span>
                                                                                </a>
                                                                            </button>
                                                                        )}
                                                                        <input
                                                                            type="file"
                                                                            name="fileName"
                                                                            className="cursor-pointer cust-file"
                                                                            accept=".xlsx"
                                                                            id="meta-dataUpload-file"
                                                                            onChange={(e) => handleFileUpload(e, index)}
                                                                            title={data.inventoryDDName || data?.fileName || data?.inventoryName} // Use onChange event handler to handle file selection
                                                                            disabled={disableSourceFields} // Disable the input in edit mode
                                                                        />
                                                                    </span>
                                                                    {validationErrors[index] && (
                                                                        <div className="code-doc-error">
                                                                            {(Array.isArray(validationErrors[index]) ? validationErrors[index] : [validationErrors[index]])
                                                                                .filter(error => error) // Filter out any null or undefined values
                                                                                .map((error, errorIndex) => (
                                                                                    <span key={errorIndex} className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section">
                                                                                        <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                                                        <span className="ms-2">{error}</span>
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </div>
                                )}
                                {showSourcePopup === true ? (
                                    <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-14 font-medium me-3"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                if (disableSourceFields === false) {
                                                    setSourceSystemDetails([]);
                                                    setSourceSystemInfo({
                                                        systemName: "",
                                                        InternalExternal: "Select",
                                                        systemType: "Select",
                                                        subjectArea: "Select",
                                                        dataFormat: "Select",
                                                        sourceSystemNameId: "Select",
                                                        sourceSystemType: "Select",
                                                        dataVolume: "Select",
                                                        descriptions: "",
                                                        connectionDetails: {},
                                                    });
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn cust-primary-btn font-14 font-medium"
                                            disabled={sourceSystemDetails.length > 0 ? false : true}
                                            onClick={() => {
                                                setShowSourcePopup(false);
                                                debugger;

                                                let updatedata = [...sourceSystemDetails];

                                                for (let data of updatedata) {
                                                    let fields = []; // Declare fields variable outside the loop

                                                    // Check conditions based on projectTypeId and dataFormat
                                                    if (disableSourceFields === false) {
                                                        if (state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" && data.dataFormat === "Automatic") {
                                                            // Adjust the logic based on the required conditions
                                                            let arr = connectionIfoType3.filter(item => item.future === "All");
                                                            arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                            fields = arr[0]?.fields || [];

                                                            if (state.futureStateName === "Databricks" && fields.length > 0 && !fields.includes("connectionName")) {
                                                                fields.push("connectionName");
                                                            }
                                                        } else if (state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1" && data.dataFormat === "Automatic") {
                                                            // Adjust logic for the second condition
                                                            let arr = connectionIfoType3.filter(item => item.future === "All");
                                                            arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                            fields = arr[0]?.fields || [];

                                                            if (state.futureStateName == "Databricks" && fields.length > 0) {
                                                                if (!fields.includes("connectionName")) {
                                                                    fields.push("connectionName");
                                                                }
                                                            }
                                                        } else if (state.projectTypeId === "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6") {
                                                            // Adjust logic for the third condition
                                                            let arr = state.futureStateName === "Databricks" ? connectionIfoType1 : connectionIfoType1.filter(item => item.future === "All");
                                                            arr = arr.filter(value => value.sourceSystem.replace(/ /g, "").toLocaleLowerCase() === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                            fields = arr[0]?.fields || [];

                                                            if (state.futureStateName == "Databricks" && fields.length > 0) {
                                                                if (!fields.includes("connectionName")) {
                                                                    fields.push("connectionName");
                                                                }
                                                            }
                                                        } else if (state.projectTypeId === "3CC51447-03C9-47E0-9D4A-2D596EED4EA1") {
                                                            // Adjust logic for the fourth condition
                                                            let arr = connectionIfoType2.filter(value => value.future.toLocaleLowerCase() === state.futureStateName.toLocaleLowerCase());
                                                            if (arr.length === 0 || arr[0].data === undefined) {
                                                                fields = [];
                                                            } else {
                                                                arr = arr[0].data.filter(value => value.sourceSystem.toLocaleLowerCase().replace(/ /g, "") === sourceSystems.filter(a => a.sourceSystemNameId === data.sourceSystemNameId)[0]?.sourceSystemNameName.toLocaleLowerCase().replace(/ /g, ""));

                                                                if (arr.length === 0 || arr[0].fields === undefined) {
                                                                    fields = [];
                                                                } else {
                                                                    fields = arr[0]?.fields;

                                                                    if (state.futureStateName === "Databricks" && fields.length > 0 && !fields.includes("connectionName")) {
                                                                        fields.push("connectionName");
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        for (let field of fields) {
                                                            data["connectionDetails"][
                                                                field.toLocaleLowerCase().replace(/ /g, "_")
                                                            ] = "";
                                                        }
                                                        data.fields = fields;
                                                        setSourceSystemDetails(updatedata);
                                                    } else {
                                                        let updatedata = [...sourceSystemDetails];
                                                        for (let data of updatedata) {
                                                            data.fields = Object.keys(data.connectionDetails);
                                                        }
                                                        setSourceSystemDetails(updatedata);
                                                    }
                                                }
                                            }}
                                        >
                                            <span>Next</span>
                                        </button>

                                    </div>
                                ) : (
                                    // PS_CD_1.2
                                    <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                                        <div className="justify-content-start d-flex me-auto">
                                            <button
                                                type="button"
                                                className="btn cust-secondary-btn font-14 font-medium me-3"
                                                onClick={() => {
                                                    setShowSourcePopup(true);
                                                    if (disableSourceFields === false) {
                                                        setSourceSystemDetails((data) => [
                                                            ...data.map((a) => {
                                                                return {
                                                                    ...a,
                                                                    fields: [],
                                                                    connectionDetails: {},
                                                                };
                                                            }),
                                                        ]);
                                                    }
                                                }}
                                            >
                                                Previous
                                            </button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-14 font-medium me-3"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setShowSourcePopup(true);
                                                if (disableSourceFields === false) {
                                                    setSourceSystemDetails([]);
                                                    setSourceSystemInfo(prevState => ({
                                                        ...prevState,
                                                        systemName: "",
                                                        InternalExternal: "Select",
                                                        systemType: "Select",
                                                        subjectArea: "Select",
                                                        dataFormat: "Select",
                                                        sourceSystemNameId: "Select",
                                                        sourceSystemType: "Select",
                                                        dataVolume: "Select",
                                                        descriptions: "",
                                                        connectionDetails: {},
                                                      }));
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            data-bs-dismiss="modal"
                                            disabled={
                                                disableSourceFields === false
                                                    ? !sourceSystemDetails.every(({ connectionDetails }) =>
                                                        Object.values(connectionDetails).every(
                                                            (value) => value.trim() !== ""
                                                        )
                                                    ) || formValid === false || Object.values(validationErrors).some(errors => errors && errors.length > 0)
                                                    || !areAllFilesUploadedAndValid()
                                                    : true
                                            }
                                            className="btn cust-primary-btn font-14 font-medium"
                                            onClick={() => {
                                                handleSave();
                                            }}
                                        >
                                            <span>Save</span>
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div> :<></>}
                    {sourcePhase === 'Development' ? 
                   <div
                   className="modal fade"
                   id="SourceConnectionInfo"
                   data-bs-backdrop="static"
                   data-bs-keyboard="false"
                   tabIndex={-1}
                   aria-labelledby="ConnectionInfoLabel"
                   aria-hidden="true"
               >
                   <div className="modal-dialog modal-xl">
                       <div className="modal-content px-2">
                           <div className="modal-header border-0">
                               <h5
                                   className="modal-title d-flex align-items-center font-medium font-20"
                                   id="ConnectionInfoLabel"
                               >
                                   Source System and Connection Info
                               </h5>
                               <button
                                   type="button"
                                   className="btn-close"
                                   data-bs-dismiss="modal"
                                   aria-label="Close"
                                   onClick={() => {
                                       setShowSourcePopup(true);
                                       if (disableSourceFields == false) {
                                           setSourceSystemDetails([]);
                                           setSourceSystemInfo({
                                               systemName: "",
                                               InternalExternal: "Select",
                                               systemType: "Select",
                                               subjectArea: "Select",
                                               dataFormat: "Select",
                                               sourceSystemNameId: "Select",
                                               sourceSystemType: "Select",
                                               dataVolume: "Select",
                                               descriptions: "",
                                               connectionDetails: {},
                                           });
                                       }
                                   }}
                               />
                           </div>
                           {showSourcePopup == true ? (
                               <div className="modal-body py-0 my-4">
                                   <div className="row">
                                       <div className="col-12">
                                           {/* stepper starts here */}
                                           <div className="d-flex justify-content-center mb-5">
                                               <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                   <li className="stepper-sty active me-5 pe-4 d-flex align-items-center">
                                                       <span className="connect-info-icon icon-sty" />
                                                       <span className="stepper-text font-14 font-bold ms-2">
                                                           Source System
                                                       </span>
                                                   </li>
                                                   <li className=" stepper-sty me-5 pe-4 d-flex align-items-center position-relative">
                                                       <span className="meta-info-icon icon-sty stepper-line " />
                                                       <span className="stepper-text font-14 font-bold ms-2">
                                                           Connection Info
                                                       </span>
                                                   </li>
                                               </ul>
                                           </div>
                                           {/* stepper ends here */}
                                       </div>
                                       <div className="col-md-12 col-sm-12">
                                           <div className="table-responsive meta-table-scrl mt-3">
                                               <table className="table table-borderless rounded custom-grid border-0">
                                                   <thead className="sticky-top-pos custom-tableHeader">
                                                       <tr>
                                                           <th>System Name</th>
                                                           <th>Internal/External</th>
                                                           <th>System Type</th>
                                                           <th>Subject Area</th>
                                                           <th>Data Format</th>
                                                           <th>Source System Name</th>
                                                           <th>Source System Type</th>
                                                           <th>Data Volume</th>
                                                           <th>Description</th>
                                                           <th className="text-center">Action</th>
                                                       </tr>
                                                   </thead>
                                                   <tbody>
                                                       {sourceSystemInfo?.index == undefined &&
                                                           disableSourceFields == false ? (
                                                           <tr>
                                                               <td>
                                                                   <input
                                                                       type="text"
                                                                       className="form-control cust-input-sty font-12 font-regular"
                                                                       placeholder="Enter here"
                                                                       value={sourceSystemInfo.systemName}
                                                                       name="systemName"
                                                                       onChange={(e) =>
                                                                           handleSourceSystemInputChange(
                                                                               e.target.name,
                                                                               e.target.value
                                                                           )
                                                                       }
                                                                   />
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.InternalExternal}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "InternalExternal",
                                                                                       "Internal"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Internal
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "InternalExternal",
                                                                                       "External"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   External
                                                                               </a>
                                                                           </li>
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.systemType}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "systemType",
                                                                                       "Operational"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Operational
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "systemType",
                                                                                       "Finance"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Finance
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "systemType",
                                                                                       "HR"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   HR
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "systemType",
                                                                                       "Sales"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Sales
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "systemType",
                                                                                       "Marketing"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Marketing
                                                                               </a>
                                                                           </li>
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.subjectArea}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "subjectArea",
                                                                                       "Operational"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Operational
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "subjectArea",
                                                                                       "Finance"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Finance
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "subjectArea",
                                                                                       "HR"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   HR
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "subjectArea",
                                                                                       "Sales"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Sales
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChangeDev(
                                                                                       "subjectArea",
                                                                                       "Marketing"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Marketing
                                                                               </a>
                                                                           </li>
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.dataFormat}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataFormat",
                                                                                       "Automatic"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Automatic
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataFormat",
                                                                                       "Manual"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Manual
                                                                               </a>
                                                                           </li>
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.sourceSystemNameId !=
                                                                               "Select"
                                                                               ? sourceSystems.filter(
                                                                                   (data) =>
                                                                                       data.sourceSystemNameId ==
                                                                                       sourceSystemInfo.sourceSystemNameId
                                                                               )[0].sourceSystemNameName
                                                                               : "Select"}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           {sourceSystems
                                                                               .filter(
                                                                                   (data) =>
                                                                                       data.projectTypeId ==
                                                                                       state.projectTypeId
                                                                               )
                                                                               .map((data) => {
                                                                                   if (
                                                                                       state.projectTypeId ==
                                                                                       "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" &&
                                                                                       data.sourceSystemNameId ==
                                                                                       "6D945D84-679E-42CE-9152-3293523A9F53"
                                                                                   ) {
                                                                                       return (
                                                                                           <>
                                                                                               {" "}
                                                                                               {state.futureStateName ==
                                                                                                   "Databricks" ? (
                                                                                                   <li className="cursorpointer"
                                                                                                       onClick={() =>
                                                                                                           handleSourceSystemInputChange(
                                                                                                               "sourceSystemNameId",
                                                                                                               data.sourceSystemNameId
                                                                                                           )
                                                                                                       }
                                                                                                   >
                                                                                                       <a className="dropdown-item font-12">
                                                                                                           Redshift
                                                                                                       </a>
                                                                                                   </li>
                                                                                               ) : (
                                                                                                   <></>
                                                                                               )}
                                                                                           </>
                                                                                       );
                                                                                   } else if (
                                                                                       state.projectTypeId ==
                                                                                       "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" &&
                                                                                       data.sourceSystemNameId ==
                                                                                       "6B9EA278-1AAF-4818-8025-73E84D39D2C5"
                                                                                   ) {
                                                                                       return (
                                                                                           <>
                                                                                               {" "}
                                                                                               {state.futureStateName ==
                                                                                                   "Databricks" ? (
                                                                                                   <li className="cursorpointer"
                                                                                                       onClick={() =>
                                                                                                           handleSourceSystemInputChange(
                                                                                                               "sourceSystemNameId",
                                                                                                               data.sourceSystemNameId
                                                                                                           )
                                                                                                       }
                                                                                                   >
                                                                                                       <a className="dropdown-item font-12">
                                                                                                           Snowflake
                                                                                                       </a>
                                                                                                   </li>
                                                                                               ) : (
                                                                                                   <></>
                                                                                               )}
                                                                                           </>
                                                                                       );
                                                                                   } else {
                                                                                       return (
                                                                                           <li className="cursorpointer"
                                                                                               onClick={() =>
                                                                                                   handleSourceSystemInputChange(
                                                                                                       "sourceSystemNameId",
                                                                                                       data.sourceSystemNameId
                                                                                                   )
                                                                                               }
                                                                                           >
                                                                                               <a className="dropdown-item font-12">
                                                                                                   {data.sourceSystemNameName}
                                                                                               </a>
                                                                                           </li>
                                                                                       );
                                                                                   }
                                                                               })}
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.sourceSystemType}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           {sourceSystemInfo.sourceSystemNameId !=
                                                                               "Select" ? (
                                                                               <>
                                                                                   {cloudonprem
                                                                                       .toLocaleLowerCase()
                                                                                       .replace(/ /g, "")
                                                                                       .includes(
                                                                                           sourceSystems
                                                                                               .filter(
                                                                                                   (a) =>
                                                                                                       a.sourceSystemNameId ==
                                                                                                       sourceSystemInfo.sourceSystemNameId
                                                                                               )[0]
                                                                                               ?.sourceSystemNameName.toLocaleLowerCase()
                                                                                               .replace(/ /g, "")
                                                                                       ) ? (
                                                                                       <li className="cursorpointer"
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "sourceSystemType",
                                                                                                   "Onprem"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Onprem
                                                                                           </a>
                                                                                       </li>
                                                                                   ) : (
                                                                                       <></>
                                                                                   )}
                                                                                   <li className="cursorpointer"
                                                                                       onClick={() =>
                                                                                           handleSourceSystemInputChange(
                                                                                               "sourceSystemType",
                                                                                               "Cloud"
                                                                                           )
                                                                                       }
                                                                                   >
                                                                                       <a className="dropdown-item font-12">
                                                                                           Cloud
                                                                                       </a>
                                                                                   </li>
                                                                               </>
                                                                           ) : (
                                                                               <></>
                                                                           )}
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <div className="dropdown">
                                                                       <a
                                                                           className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                           role="button"
                                                                           id="dropdownMenuLink"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"
                                                                           style={{ zIndex: 1001 }}
                                                                       >
                                                                           {sourceSystemInfo.dataVolume}
                                                                       </a>
                                                                       <ul
                                                                           className="dropdown-menu cust-dropdown-menu"
                                                                           aria-labelledby="dropdownMenuLink"
                                                                       >
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "Less than 1 GB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   Less than 1 GB
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "1 GB to 500 GB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   1 GB to 500 GB
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "500 GB to 1 TB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   500 GB to 1 TB
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "1 TB to 10 TB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   1 TB to 10 TB
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "10 TB to 100 TB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   10 TB to 100 TB
                                                                               </a>
                                                                           </li>
                                                                           <li className="cursorpointer"
                                                                               onClick={() =>
                                                                                   handleSourceSystemInputChange(
                                                                                       "dataVolume",
                                                                                       "More than 100 TB"
                                                                                   )
                                                                               }
                                                                           >
                                                                               <a className="dropdown-item font-12">
                                                                                   More than 100 TB
                                                                               </a>
                                                                           </li>
                                                                       </ul>
                                                                   </div>
                                                               </td>
                                                               <td>
                                                                   <input
                                                                       type="text"
                                                                       value={sourceSystemInfo.descriptions}
                                                                       onChange={(e) =>
                                                                           handleSourceSystemInputChange(
                                                                               "descriptions",
                                                                               e.target.value
                                                                           )
     
                                                                       }
                                                                       placeholder='Enter'
                                                                       className="form-control cust-input-sty font-14 font-regular"
                                                                   />
                                                               </td>
                                                               <td className="text-center">
                                                               <a
                                                                            className={
                                                                                sourceSystemDetails.findIndex(
                                                                                    (data) => data.systemName === sourceSystemInfo.systemName
                                                                                ) === -1 &&
                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                    sourceSystemInfo.systemType !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== ""
                                                                                    ? ""
                                                                                    : "disable-btn"
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                    sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                    sourceSystemInfo.dataFormat !== "Select" &&
                                                                                    sourceSystemInfo.dataVolume !== "Select" &&
                                                                                    sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                    sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                    sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                    sourceSystemInfo.subjectArea !== "Select" &&
                                                                                    sourceSystemInfo.systemName.trim() !== "" &&
                                                                                    sourceSystemInfo.systemType !== "Select"
                                                                                ) {
                                                                                    setSourceSystemDetails([...sourceSystemDetails, sourceSystemInfo]);
                                                                                    setSourceSystemInfo({
                                                                                        systemName: "",
                                                                                        InternalExternal: "Select",
                                                                                        systemType: "Select",
                                                                                        subjectArea: "Select",
                                                                                        dataFormat: "Select",
                                                                                        sourceSystemNameId: "Select",
                                                                                        sourceSystemType: "Select",
                                                                                        dataVolume: "Select",
                                                                                        descriptions: "",
                                                                                        connectionDetails: {},
                                                                                    });
                                                                                    setSelectedSystemType([]);
                                                                                    setSelectedSubjectArea([]);
                                                                                }
                                                                            }}
                                                                            style={{
                                                                                opacity:
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                        sourceSystemInfo.dataFormat !== "Select" &&
                                                                                        sourceSystemInfo.dataVolume !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                        sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                        sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                        sourceSystemInfo.subjectArea !== "Select" &&
                                                                                        sourceSystemInfo.systemName.trim() !== "" &&
                                                                                        sourceSystemInfo.systemType !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== ""
                                                                                        ? 1
                                                                                        : 0.5,
                                                                                cursor:
                                                                                    sourceSystemDetails.findIndex(
                                                                                        (data) => data.systemName === sourceSystemInfo.systemName
                                                                                    ) === -1 &&
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                        sourceSystemInfo.dataFormat !== "Select" &&
                                                                                        sourceSystemInfo.dataVolume !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                        sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                        sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                        sourceSystemInfo.subjectArea !== "Select" &&
                                                                                        sourceSystemInfo.systemName.trim() !== "" &&
                                                                                        sourceSystemInfo.systemType !== "Select" &&
                                                                                        sourceSystemInfo.descriptions.trim() !== ""
                                                                                        ? "pointer"
                                                                                        : "not-allowed",
                                                                            }}
                                                                        >
                                                                            <span className="cust-cursor-pointer">
                                                                                <img src="images/add-icons.svg" alt="Add" />
                                                                            </span>
                                                                        </a>
                                                               </td>
                                                           </tr>
                                                       ) : (
                                                           <></>
                                                       )}
                                                       {console.log(
                                                           sourceSystemDetails,
                                                           "sourceSystemDetails"
                                                       )}
                                                       {sourceSystemDetails.map((data, index) => {
                                                           return (
                                                               <>
                                                                   {sourceSystemInfo?.index == index ? (
                                                                       <tr>
                                                                           <td>
                                                                               <input
                                                                                   type="text"
                                                                                   className="form-control cust-input-sty font-12 font-regular"
                                                                                   placeholder="Enter here"
                                                                                   value={sourceSystemInfo.systemName}
                                                                                   name="systemName"
                                                                                   onChange={(e) =>
                                                                                       handleSourceSystemInputChange(
                                                                                           e.target.name,
                                                                                           e.target.value
                                                                                       )
                                                                                   }
                                                                               />
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.InternalExternal}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "InternalExternal",
                                                                                                   "Internal"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Internal
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "InternalExternal",
                                                                                                   "External"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               External
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.systemType}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "systemType",
                                                                                                   "Operational"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Operational
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "systemType",
                                                                                                   "Finance"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Finance
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "systemType",
                                                                                                   "HR"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               HR
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "systemType",
                                                                                                   "Sales"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Sales
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "systemType",
                                                                                                   "Marketing"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Marketing
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.subjectArea}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "subjectArea",
                                                                                                   "Operational"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Operational
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "subjectArea",
                                                                                                   "Finance"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Finance
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "subjectArea",
                                                                                                   "HR"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               HR
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "subjectArea",
                                                                                                   "Sales"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Sales
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "subjectArea",
                                                                                                   "Marketing"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Marketing
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.dataFormat}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataFormat",
                                                                                                   "Automatic"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Automatic
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataFormat",
                                                                                                   "Manual"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Manual
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.sourceSystemNameId !=
                                                                                           "Select"
                                                                                           ? sourceSystems.filter(
                                                                                               (data) =>
                                                                                                   data.sourceSystemNameId ==
                                                                                                   sourceSystemInfo.sourceSystemNameId
                                                                                           )[0].sourceSystemNameName
                                                                                           : "Select"}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       {sourceSystems
                                                                                           .filter(
                                                                                               (data) =>
                                                                                                   data.projectTypeId ==
                                                                                                   state.projectTypeId
                                                                                           )
                                                                                           .map((data) => {
                                                                                               if (
                                                                                                   state.projectTypeId ==
                                                                                                   "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" &&
                                                                                                   data.sourceSystemNameId ==
                                                                                                   "6D945D84-679E-42CE-9152-3293523A9F53"
                                                                                               ) {
                                                                                                   return (
                                                                                                       <>
                                                                                                           {" "}
                                                                                                           {state.futureStateName ==
                                                                                                               "Databricks" ? (
                                                                                                               <li
                                                                                                                   onClick={() =>
                                                                                                                       handleSourceSystemInputChange(
                                                                                                                           "sourceSystemNameId",
                                                                                                                           data.sourceSystemNameId
                                                                                                                       )
                                                                                                                   }
                                                                                                               >
                                                                                                                   <a className="dropdown-item font-12">
                                                                                                                       Redshift
                                                                                                                   </a>
                                                                                                               </li>
                                                                                                           ) : (
                                                                                                               <></>
                                                                                                           )}
                                                                                                       </>
                                                                                                   );
                                                                                               } else if (
                                                                                                   state.projectTypeId ==
                                                                                                   "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6" &&
                                                                                                   data.sourceSystemNameId ==
                                                                                                   "6B9EA278-1AAF-4818-8025-73E84D39D2C5"
                                                                                               ) {
                                                                                                   return (
                                                                                                       <>
                                                                                                           {" "}
                                                                                                           {state.futureStateName ==
                                                                                                               "Databricks" ? (
                                                                                                               <li
                                                                                                                   onClick={() =>
                                                                                                                       handleSourceSystemInputChange(
                                                                                                                           "sourceSystemNameId",
                                                                                                                           data.sourceSystemNameId
                                                                                                                       )
                                                                                                                   }
                                                                                                               >
                                                                                                                   <a className="dropdown-item font-12">
                                                                                                                       Snowflake
                                                                                                                   </a>
                                                                                                               </li>
                                                                                                           ) : (
                                                                                                               <></>
                                                                                                           )}
                                                                                                       </>
                                                                                                   );
                                                                                               } else {
                                                                                                   return (
                                                                                                       <li
                                                                                                           onClick={() =>
                                                                                                               handleSourceSystemInputChange(
                                                                                                                   "sourceSystemNameId",
                                                                                                                   data.sourceSystemNameId
                                                                                                               )
                                                                                                           }
                                                                                                       >
                                                                                                           <a className="dropdown-item font-12">
                                                                                                               {
                                                                                                                   data.sourceSystemNameName
                                                                                                               }
                                                                                                           </a>
                                                                                                       </li>
                                                                                                   );
                                                                                               }
                                                                                           })}
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.sourceSystemType}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "sourceSystemType",
                                                                                                   "Onprem"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Onprem
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "sourceSystemType",
                                                                                                   "Cloud"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Cloud
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <div className="dropdown">
                                                                                   <a
                                                                                       className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                                                       role="button"
                                                                                       id="dropdownMenuLink"
                                                                                       data-bs-toggle="dropdown"
                                                                                       aria-expanded="false"
                                                                                       style={{ zIndex: 1001 }}
                                                                                   >
                                                                                       {sourceSystemInfo.dataVolume}
                                                                                   </a>
                                                                                   <ul
                                                                                       className="dropdown-menu cust-dropdown-menu"
                                                                                       aria-labelledby="dropdownMenuLink"
                                                                                   >
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "Less than 1 GB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               Less than 1 GB
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "1 GB to 500 GB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               1 GB to 500 GB
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "500 GB to 1 TB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               500 GB to 1 TB
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "1 TB to 10 TB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               1 TB to 10 TB
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "10 TB to 100 TB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               10 TB to 100 TB
                                                                                           </a>
                                                                                       </li>
                                                                                       <li
                                                                                           onClick={() =>
                                                                                               handleSourceSystemInputChange(
                                                                                                   "dataVolume",
                                                                                                   "More than 100 TB"
                                                                                               )
                                                                                           }
                                                                                       >
                                                                                           <a className="dropdown-item font-12">
                                                                                               More than 100 TB
                                                                                           </a>
                                                                                       </li>
                                                                                   </ul>
                                                                               </div>
                                                                           </td>
                                                                           <td>
                                                                               <input
                                                                                   type="text"
                                                                                   value={sourceSystemInfo.descriptions}
                                                                                   onChange={(e) =>
                                                                                       handleSourceSystemInputChange(
                                                                                           "descriptions",
                                                                                           e.target.value
                                                                                       )
                                                                                   }
                                                                                   className="form-control cust-input-sty font-14 font-regular"
                                                                                   placeholder="Enter"
                                                                               />
                                                                           </td>
                                                                           <td className="text-center">
                                                                           <a className={
                                                                                        sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                            sourceSystemInfo.dataFormat !== "Select" &&
                                                                                            sourceSystemInfo.dataVolume !== "Select" &&
                                                                                            sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                            sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                            sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                            sourceSystemInfo.subjectArea !== "Select" &&
                                                                                            sourceSystemInfo.systemName.trim() !== "" &&
                                                                                            sourceSystemInfo.systemType !== "Select"
                                                                                            ? ""
                                                                                            : "disable-btn"
                                                                                    }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                sourceSystemDetails.filter((data, i) => data.systemName == sourceSystemInfo.systemName && sourceSystemInfo.index != i).length == 0 &&
                                                                                                sourceSystemInfo.InternalExternal !== "Select" &&
                                                                                                sourceSystemInfo.dataFormat !== "Select" &&
                                                                                                sourceSystemInfo.dataVolume !== "Select" &&
                                                                                                sourceSystemInfo.descriptions.trim() !== "" &&
                                                                                                sourceSystemInfo.sourceSystemNameId !== "Select" &&
                                                                                                sourceSystemInfo.sourceSystemType !== "Select" &&
                                                                                                sourceSystemInfo.subjectArea !== "Select" &&
                                                                                                sourceSystemInfo.systemName.trim() !== "" &&
                                                                                                sourceSystemInfo.systemType !== "Select"
                                                                                            ) {
                                                                                                let arr = sourceSystemDetails.map((a, index) => {
                                                                                                    if (index === sourceSystemInfo.index) {
                                                                                                        let obj = { ...sourceSystemInfo };
                                                                                                        delete obj.index;
                                                                                                        return obj;
                                                                                                    } else {
                                                                                                        return a;
                                                                                                    }
                                                                                                });
                                                                                                setSourceSystemDetails(arr);
                                                                                                setSourceSystemInfo({
                                                                                                    systemName: "",
                                                                                                    InternalExternal: "Select",
                                                                                                    systemType: "Select",
                                                                                                    subjectArea: "Select",
                                                                                                    dataFormat: "Select",
                                                                                                    sourceSystemNameId: "Select",
                                                                                                    sourceSystemType: "Select",
                                                                                                    dataVolume: "Select",
                                                                                                    descriptions: "",
                                                                                                    connectionDetails: {},
                                                                                                });
                                                                                            }
                                                                                        }}>
                                                                                        <span className="cust-cursor-pointer">
                                                                                            <img
                                                                                                src="images/right.svg"
                                                                                                className="me-3"
                                                                                                width={15}
                                                                                                height={15}
                                                                                                alt="Right Icon"
                                                                                            />
                                                                                        </span>
                                                                                    </a>
                                                                                    <a onClick={() => {
                                                                                        setSourceSystemInfo({
                                                                                            systemName: "",
                                                                                            InternalExternal: "Select",
                                                                                            systemType: "Select",
                                                                                            subjectArea: "Select",
                                                                                            dataFormat: "Select",
                                                                                            sourceSystemNameId: "Select",
                                                                                            sourceSystemType: "Select",
                                                                                            dataVolume: "Select",
                                                                                            descriptions: "",
                                                                                            connectionDetails: {},
                                                                                        });
                                                                                    }}>
                                                                                        <span className="cust-cursor-pointer">
                                                                                            <img
                                                                                                src="images/wrong.svg"
                                                                                                width={20}
                                                                                                height={20}
                                                                                                alt="Wrong Icon"
                                                                                            />
                                                                                        </span>
                                                                                    </a>
                                                                           </td>

                                                                       </tr>
                                                                   ) : (
                                                                       <tr>
                                                                           <td>{data.systemName}</td>
                                                                           <td>{data.InternalExternal}</td>
                                                                           <td>{data.systemType}</td>
                                                                           <td>{data.subjectArea}</td>
                                                                           <td>{data.dataFormat}</td>
                                                                           <td>
                                                                               {
                                                                                   sourceSystems.filter(
                                                                                       (a) =>
                                                                                           a.sourceSystemNameId ==
                                                                                           data.sourceSystemNameId
                                                                                   )[0]?.sourceSystemNameName
                                                                               }
                                                                           </td>
                                                                           <td>{data.sourceSystemType}</td>
                                                                           <td>{data.dataVolume}</td>
                                                                           <td>{data.descriptions}</td>
                                                                           <td className="text-center">
                                                                               {disableSourceFields == false ? (
                                                                                   <div>
                                                                                       <a
                                                                                                onClick={() => {
                                                                                                    setSourceSystemInfo({
                                                                                                        ...sourceSystemDetails.filter(
                                                                                                            (a, i) => i == index
                                                                                                        )[0],
                                                                                                        index: index,
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img
                                                                                                        src="images/blue-edit-icon.svg"
                                                                                                        className="me-3"
                                                                                                    />
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    setSourceSystemDetails([
                                                                                                        ...sourceSystemDetails.filter(
                                                                                                            (a, i) => i != index
                                                                                                        ),
                                                                                                    ]);
                                                                                                }}
                                                                                            >
                                                                                                <span className="cust-cursor-pointer">
                                                                                                    <img src="images/delete-icon.svg" />
                                                                                                </span>
                                                                                            </a>
                                                                                   </div>
                                                                               ) : (
                                                                                   <></>
                                                                               )}
                                                                           </td>
                                                                       </tr>
                                                                   )}
                                                               </>
                                                           );
                                                       })}
                                                       {sourceSystemDetails.length == 0 ||
                                                           (sourceSystemInfo?.index != undefined &&
                                                               sourceSystemDetails.length == 1) ? (
                                                           <tr style={{ borderBottom: "none" }}>
                                                               <td> </td>
                                                           </tr>
                                                       ) : (
                                                           <></>
                                                       )}
                                                   </tbody>
                                               </table>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           ) : (
                               <div className="modal-body py-0 my-4 mx-5">
                                   <div className="row">
                                       <div className="col-12">
                                           {/* stepper starts here */}
                                           <div className="d-flex justify-content-center mb-3 mb-md-3 mb-lg-5">
                                               <ul className="list-unstyled custom-stepper d-flex align-items-center">
                                                   <li className="stepper-sty me-lg-5 pe-4 d-flex align-items-center">
                                                       <span className="connect-info-icon icon-sty" />
                                                       <span className="stepper-text font-14 font-bold ms-2">
                                                           <img src="images/check-mark.svg" />
                                                           <span className="ms-2">Source System</span>
                                                       </span>
                                                   </li>
                                                   <li className=" stepper-sty active me-lg-5 pe-4 d-flex align-items-center position-relative">
                                                       <span className="meta-info-icon icon-sty stepper-line " />
                                                       <span className="stepper-text font-14 font-bold ms-2">
                                                           Connection Info
                                                       </span>
                                                   </li>
                                               </ul>
                                           </div>
                                           {/* stepper ends here */}
                                       </div>
                                       {console.log(sourceSystemDetails, "sourceSystemDetails")}
                                       {sourceSystemDetails.map((data, index) => {
                                           return (
                                               <>
                                                   {data?.fields?.length > 0 ? (
                                                       <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                                                           <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                                               <button
                                                                   className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100"
                                                                   type="button"
                                                                   data-bs-toggle="collapse"
                                                                   data-bs-target={`#view-instruction-${data.systemName}`}
                                                               >
                                                                   <img
                                                                       src="images/down-acc-arow.svg"
                                                                       alt="arrow"
                                                                       className="me-3 gen-accord"
                                                                   />
                                                                   <span
                                                                       data-bs-toggle="collapse"
                                                                       data-bs-target={`#view-instruction-${data.systemName}`}
                                                                       className="cursor-pointer text-black font-18 font-medium"
                                                                   >
                                                                       {data.systemName}
                                                                   </span>
                                                               </button>
                                                           </p>
                                                           <div className="row">
                                                               <div
                                                                   className={`collapse ${index == 0 ? "show" : ""
                                                                       }`}
                                                                   id={`view-instruction-${data.systemName}`}
                                                               >
                                                                   <div className="custom-accordian-body color-grey-bg py-2 px-3">
                                                                       <div className="row pb-4">
                                                                           {data?.fields.map((field) => {
                                                                               return (
                                                                                   <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                                                       <label htmlFor="connection-Name" className="form-label text-black font-14 font-medium">
                                                                                           {" "}
                                                                                           {field == "connectionName" ? "ConnectionName" : field.replace(/_/g, " ")}
                                                                                           <span className="text-red">
                                                                                               *
                                                                                           </span>
                                                                                       </label>
                                                                                       <input type="text" disabled={disableSourceFields} className="form-control cust-input-sty font-14 font-regular"
                                                                                           value={sourceSystemDetails[index]["connectionDetails"][field == "connectionName" ? "connectionName" : field.toLocaleLowerCase().replace(/ /g, "_")]}
                                                                                           placeholder="Enter value"
                                                                                           onChange={(e) => { handleConnectionInputChange(field.toLocaleLowerCase().replace(/ /g, "_"), e.target.value, index); }} />
                                                                                   </div>
                                                                               );
                                                                           })}
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   ) : (
                                                       <></>
                                                   )}
                                               </>
                                           );
                                       })}
                                   </div>
                               </div>
                           )}
                           {showSourcePopup == true ? (
                               <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                   <button
                                       type="button"
                                       className="btn cust-secondary-btn font-14 font-medium me-3"
                                       data-bs-dismiss="modal"
                                       onClick={() => {
                                           if (disableSourceFields == false) {
                                               setSourceSystemDetails([]);
                                               setSourceSystemInfo(prevState => ({
                                                ...prevState,
                                                systemName: "",
                                                InternalExternal: "Select",
                                                systemType: "Select",
                                                subjectArea: "Select",
                                                dataFormat: "Select",
                                                sourceSystemNameId: "Select",
                                                sourceSystemType: "Select",
                                                dataVolume: "Select",
                                                descriptions: "",
                                                connectionDetails: {},
                                              }));
                                           }
                                       }}
                                   >
                                       Cancel
                                   </button>
                                   <button
                                       type="button"
                                       className="btn cust-primary-btn font-14 font-medium"
                                       disabled={sourceSystemDetails.length > 0 ? false : true}
                                       onClick={() => {
                                           setShowSourcePopup(false);
                                           debugger;
                                           if (disableSourceFields == false) {
                                               let updatedata = [...sourceSystemDetails];
                                               let i;
                                               for (let data of updatedata) {
                                                   let fields = [];
                                                   if (
                                                       state.projectTypeId ==
                                                       "EB7538EA-E9FD-41C0-811F-7179C6D9EEC6"
                                                   ) {
                                                       let arr =
                                                           state.futureStateName == "Databricks"
                                                               ? connectionIfoType1
                                                               : connectionIfoType1.filter(
                                                                   (data) => data.future == "All"
                                                               );
                                                       arr = arr.filter(
                                                           (value) =>
                                                               value.sourceSystem
                                                                   .replace(/ /g, "")
                                                                   .toLocaleLowerCase() ==
                                                               sourceSystems
                                                                   .filter(
                                                                       (a) =>
                                                                           a.sourceSystemNameId ==
                                                                           data.sourceSystemNameId
                                                                   )[0]
                                                                   ?.sourceSystemNameName.toLocaleLowerCase()
                                                                   .replace(/ /g, "")
                                                       );
                                                       fields = arr[0]?.fields || [];
                                                       if (state.futureStateName == "Databricks" && fields.length > 0) {
                                                           if (!fields.includes("connectionName")) {
                                                               fields.push("connectionName");
                                                           }
                                                       }
                                                   } else if (
                                                       state.projectTypeId ==
                                                       "3CC51447-03C9-47E0-9D4A-2D596EED4EA1"
                                                   ) {
                                                       let arr = connectionIfoType2.filter(
                                                           (value) =>
                                                               value.future.toLocaleLowerCase() ==
                                                               state.futureStateName.toLocaleLowerCase()
                                                       );
                                                       if (arr.length == 0 || arr[0].data == undefined) {
                                                           fields = [];
                                                       } else {
                                                           arr = arr[0].data.filter(
                                                               (value) =>
                                                                   value.sourceSystem
                                                                       .toLocaleLowerCase()
                                                                       .replace(/ /g, "") ==
                                                                   sourceSystems
                                                                       .filter(
                                                                           (a) =>
                                                                               a.sourceSystemNameId ==
                                                                               data.sourceSystemNameId
                                                                       )[0]
                                                                       ?.sourceSystemNameName.toLocaleLowerCase()
                                                                       .replace(/ /g, "")
                                                           );
                                                           if (
                                                               arr.length == 0 ||
                                                               arr[0].fields == undefined
                                                           ) {
                                                               fields = [];
                                                           } else {
                                                               fields = arr[0]?.fields;
                                                               if (state.futureStateName == "Databricks" && fields.length > 0) {
                                                                   if (!fields.includes("connectionName")) {
                                                                       fields.push("connectionName");
                                                                   }
                                                               }
                                                           }
                                                       }
                                                   }
                                                   for (let field of fields) {
                                                       data["connectionDetails"][
                                                           field.toLocaleLowerCase().replace(/ /g, "_")
                                                       ] = "";
                                                   }
                                                   data.fields = fields;
                                               }
                                               setSourceSystemDetails(updatedata);
                                           } else {
                                               let updatedata = [...sourceSystemDetails];
                                               for (let data of updatedata) {
                                                   data.fields = Object.keys(data.connectionDetails);
                                               }
                                               setSourceSystemDetails(updatedata);
                                           }
                                       }}
                                   >
                                       <span>Next</span>
                                   </button>
                               </div>
                           ) : (
                               <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                                   <div className="justify-content-start d-flex me-auto">
                                       <button
                                           type="button"
                                           className="btn cust-secondary-btn font-14 font-medium me-3"
                                           onClick={() => {
                                               setShowSourcePopup(true);
                                               if (disableSourceFields == false) {
                                                   setSourceSystemDetails((data) => [
                                                       ...data.map((a) => {
                                                           return {
                                                               ...a,
                                                               fields: [],
                                                               connectionDetails: {},
                                                           };
                                                       }),
                                                   ]);
                                               }
                                           }}
                                       >
                                           Previous
                                       </button>
                                   </div>
                                   <button
                                       type="button"
                                       className="btn cust-secondary-btn font-14 font-medium me-3"
                                       data-bs-dismiss="modal"
                                       onClick={() => {
                                           setShowSourcePopup(true);
                                           if (disableSourceFields == false) {
                                               setSourceSystemDetails([]);
                                               setSourceSystemInfo(prevState => ({
                                                ...prevState,
                                                systemName: "",
                                                InternalExternal: "Select",
                                                systemType: "Select",
                                                subjectArea: "Select",
                                                dataFormat: "Select",
                                                sourceSystemNameId: "Select",
                                                sourceSystemType: "Select",
                                                dataVolume: "Select",
                                                descriptions: "",
                                                connectionDetails: {},
                                              }));
                                           }
                                       }}
                                   >
                                       Cancel
                                   </button>
                                   <button
                                       type="button"
                                       data-bs-dismiss="modal"
                                       disabled={
                                           disableSourceFields == false
                                               ? !sourceSystemDetails.every(({ connectionDetails }) =>
                                                   Object.values(connectionDetails).every(
                                                       (value) => value.trim() !== ""
                                                   )
                                               )
                                               : true
                                       }
                                       className="btn cust-primary-btn font-14 font-medium"
                                       onClick={() => {
                                           handleSave();
                                       }}
                                   >
                                       <span>Save</span>
                                   </button>
                               </div>
                           )}
                       </div>
                   </div>
               </div>:<></>}
                {/*Design details Modal */}
                <div
                    className="modal fade"
                    id="designDetails"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="CodeDocLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header border-0 px-4">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="CodeDocLabel"
                                >
                                    Code Documentations
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body px-4">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label
                                            htmlFor
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Upload Design Documentations
                                            <span className="text-red"> *</span>
                                        </label>
                                        <span className="d-block font-12 info-message-section text-black font-medium">
                                            Please ensure you've also added Metadata, DDL scripts,
                                            Column mapping, Orchestration diagram and Pseudocode
                                        </span>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block ">
                                                <button
                                                    type="button"
                                                    className=" upload-btn-sty shadow-none cursor-pointer text-center"
                                                >
                                                    <img
                                                        src="images/upload-icon.svg"
                                                        alt="browse"
                                                        className="mb-2"
                                                    />
                                                    <span className="font-medium font-13 d-block text-grey mb-2">
                                                        Browse or drag and drop file
                                                    </span>
                                                    <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                        Use this template to add info -{" "}
                                                        <a

                                                            className="popup-anchor-color custom-tableHeader"
                                                        >
                                                            Download Template
                                                        </a>
                                                    </span>
                                                </button>
                                                <input
                                                    type="file"
                                                    name="myfile"
                                                    className="cursor-pointer cust-file"
                                                    id="OrganizationLogo"
                                                />
                                            </span>
                                            <div className="code-doc-error">
                                                <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section">
                                                    <img src="images/warning-red-icon.svg" />{" "}
                                                    <span className="ms-2">
                                                        Field sourceSystem is missing
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* file attached html starts here */}
                                    {/* <div class="col-md-12 col-sm-12">
            <div class="mb-3">
              <label for="OrganizationLogo" class="form-label text-black font-14 font-medium">Organization Logo</label>
              <span class="upload-btn-wrapper me-2 d-block ">
                <button type="button" class=" upload-btn-sty shadow-none cursor-pointer text-center">
                  <img src="images/sample-logo.svg" alt="sample-logo" class="mb-2">
                  <a  class="font-medium font-12 d-block">Sonar-logo. svg
                    <span class="ms-2"><img src="images/link-close-icon.svg" alt="link-close-icon"></span>
                  </a>
                </button>
                <input type="file" name="myfile" class="cursor-pointer cust-file" id="OrganizationLogo">
              </span>
            </div>
          </div> */}
                                    {/* file attached html ends here */}
                                </div>
                            </div>
                            <div className="modal-footer border-0 p-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    onClick={() => {
                                        // saveDesignDocument();
                                    }}
                                    data-bs-dismiss="modal"
                                >
                                    <span className="px-2">Save &amp; Proceed</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Button trigger modal */}

                <div
                    className="modal fade"
                    id="TarSys"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="TarSysLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content px-2">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="TarSysLabel"
                                >
                                    Target System Info - Databricks
                                </h5>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    hidden
                                    id="popupclose"
                                    onClick={() => { }}
                                /> */}
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="popupclose"
                                    onClick={() => {
                                        deployStatus === false ? console.log() : setTargetSystemDetails(targetInfo);
                                        console.log("sdfvwrgt");
                                    }}
                                />
                            </div>
                            <div className="modal-body py-0 my-4">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="User-Name"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Username
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="User-Name"
                                            value={targetSystemDetails?.userName?.userName || ""}
                                            disabled={!deployStatus}
                                            placeholder="Enter username"
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    userName: {
                                                        ...targetSystemDetails.userName,
                                                        ["userName"]: e.target.value,
                                                    },
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetails?.userName == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Invalid UserName</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Access-Token"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Access Token
                                            <span className="text-red"> *</span>
                                        </label>

                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Access-Token"
                                            value={targetSystemDetails?.accessToken || ""}
                                            placeholder="Enter access token"
                                            disabled={!deployStatus}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    ["accessToken"]: e.target.value,
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetails?.accessToken == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Invalid Access Token</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Target-info-URL"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            URL
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Target-info-URL"
                                            placeholder="Enter URL"
                                            disabled={!deployStatus}
                                            value={targetSystemDetails?.systemUrl || ""}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    ["systemUrl"]: e.target.value,
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetails?.systemUrl == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Invalid URL</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Target-info-URL"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Created By
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Target-info-URL"
                                            placeholder="Enter Created By"
                                            disabled={!deployStatus}
                                            value={targetSystemDetails?.userName?.createdBy || ""}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    userName: {
                                                        ...targetSystemDetails.userName,
                                                        ["createdBy"]: e.target.value,
                                                    },
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Target-info-URL"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Comments
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Target-info-URL"
                                            placeholder="Enter comments"
                                            disabled={!deployStatus}
                                            value={targetSystemDetails?.userName?.comments || ""}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    userName: {
                                                        ...targetSystemDetails.userName,
                                                        ["comments"]: e.target.value,
                                                    },
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                {isValidTargetSystemDetails?.comments == true ? (
                                    <></>
                                ) : (
                                    <span style={{ color: "red" }}>
                                        CreatedBy/Comments does ot match the provided Access Token
                                    </span>
                                )}
                            </div>
                            <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        deployStatus === false ? console.log() : setTargetSystemDetails(targetInfo);
                                        console.log("sdfvwrgt");
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    id="save"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    onClick={() => {
                                        SaveDetails();
                                    }}
                                    disabled={
                                        targetSystemDetails?.accessToken == "" ||
                                            targetSystemDetails?.userName?.comments == "" ||
                                            targetSystemDetails?.userName?.createdBy == "" ||
                                            targetSystemDetails?.systemUrl == "" ||
                                            targetSystemDetails?.userName?.userName == ""
                                            ? true
                                            : false || deployStatus === false ||  !(
                                                targetSystemDetails?.accessToken &&
                                                targetSystemDetails?.userName?.comments &&
                                                targetSystemDetails?.userName?.createdBy &&
                                                targetSystemDetails?.systemUrl &&
                                                targetSystemDetails?.userName?.userName
                                            )
                                    }
                                >
                                    <span>Save &amp; Proceed</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className="modal fade"
                    id="TarSysADF"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="TarSysADFLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content px-2">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="TarSysADFLabel"
                                >
                                    Target System Info - ADF
                                </h5>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    hidden
                                    id="popupclose"
                                    onClick={() => { }}
                                /> */}
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="popupclose"
                                    onClick={() => {
                                        deployStatus === true ? console.log() : setTargetSystemDetails(targetInfoADF);
                                        console.log("sdfvwrgt");
                                    }}
                                />
                            </div>
                            <div className="modal-body py-0 my-4">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="User-Name"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Subscription ID
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="User-Name"
                                            value={targetSystemDetails?.subscriptionId || ""}
                                            disabled={!deployStatus}
                                            placeholder="Enter subscriptionId"
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    subscriptionId: e.target.value
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetailsADF?.subscriptionId == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Please Enter Valid Subscription ID</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Access-Token"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Resource Group Name
                                            <span className="text-red"> *</span>
                                        </label>

                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Access-Token"
                                            value={targetSystemDetails?.resourceGroupName || ""}
                                            placeholder="Enter resourceGroupName"
                                            disabled={!deployStatus}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    ["resourceGroupName"]: e.target.value,
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetailsADF?.resourceGroupName == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Please Enter Valid Resource Group Name</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Target-info-URL"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Factory Name
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Target-info-URL"
                                            placeholder="Enter factoryName"
                                            disabled={!deployStatus}
                                            value={targetSystemDetails?.factoryName || ""}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    ["factoryName"]: e.target.value,
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetailsADF?.factoryName == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Please Enter Valid Factory Name</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Target-info-URL"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Access Token
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Target-info-URL"
                                            placeholder="Enter accessToken"
                                            disabled={!deployStatus}
                                            value={targetSystemDetails?.accessToken || ""}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    accessToken: e.target.value
                                                }));
                                            }}
                                        />
                                    </div>

                                </div>
                                {isValidTargetSystemDetailsADF?.accessToken == true ? (
                                    <></>
                                ) : (
                                    <span style={{ color: "red" }}>
                                        Please Enter the Valid Access Token
                                    </span>
                                )}
                            </div>
                            <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {

                                        deployStatus === true ? console.log() : setTargetSystemDetails(targetInfoADF);
                                        console.log("sdfvwrgt");

                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    id="save"
                                    data-bs-dismiss='modal'
                                    className="btn cust-primary-btn font-14 font-medium"
                                    onClick={() => {
                                        SaveDetails();
                                    }}
                                    disabled={
                                        targetSystemDetails?.accessToken == "" ||
                                            targetSystemDetails?.subscriptionId == "" ||
                                            targetSystemDetails?.factoryName == "" ||
                                            targetSystemDetails?.resourceGroupName == ""
                                            ? true
                                            : false 
                                    }
                                >
                                    <span>Save &amp; Proceed</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="TarSysSynapse"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="TarSysSynapseLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content px-2">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="TarSysSynapseLabel"
                                >
                                    Target System Info - Synapse and pipeline
                                </h5>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    hidden
                                    id="popupclose"
                                    onClick={() => { }}
                                /> */}
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="popupclose"
                                    onClick={() => {
                                        deployStatus === true ? console.log() : setTargetSystemDetails(targetInfoSynapse);
                                        console.log("sdfvwrgt");
                                    }}
                                />
                            </div>
                            <div className="modal-body py-0 my-4">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="User-Name"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Access Token
                                            <span className="text-red"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="User-Name"
                                            value={targetSystemDetails?.accessToken || ""}
                                            disabled={!deployStatus}
                                            placeholder="Enter username"
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    accessToken: e.target.value
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetailsSynaps?.accessToken == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Please Enter Valid Access Token</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                                        <label
                                            htmlFor="Access-Token"
                                            className="form-label text-black font-14 font-medium"
                                        >
                                            Synapse URL
                                            <span className="text-red"> *</span>
                                        </label>

                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            id="Access-Token"
                                            value={targetSystemDetails?.systemUrl || ""}
                                            placeholder="Enter systemUrl"
                                            disabled={!deployStatus}
                                            onChange={(e) => {
                                                setTargetSystemDetails((targetSystemDetails) => ({
                                                    ...targetSystemDetails,
                                                    ["systemUrl"]: e.target.value,
                                                }));
                                            }}
                                        />
                                        {isValidTargetSystemDetailsSynaps?.systemUrl == true ? (
                                            <></>
                                        ) : (
                                            <span style={{ color: "red" }}>Please Enter Valid systemUrl</span>
                                        )}
                                    </div>


                                </div>

                            </div>
                            <div className="modal-footer border-0 px-3 mb-4 mt-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        deployStatus === true ? console.log() : setTargetSystemDetails(targetInfoSynapse);
                                        console.log("sdfvwrgt");
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    id="save"
                                    data-bs-dismiss="modal"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    onClick={() => {
                                        SaveDetails();
                                    }}
                                    disabled={
                                        targetSystemDetails?.accessToken == "" ||
                                            targetSystemDetails?.systemUrl == ""
                                            ? true
                                            : false
                                    }
                                >
                                    <span>Save &amp; Proceed</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {showDesignPopup ? (
                    <DesignPopup
                        fileName={chatHistory[1]?.definefileName || ""}
                        onChange={(data) => updateChatHistory(data,"DesignPopup")}
                        close={() => setShowDesignPopup(false)}
                    />
                ) : (
                    <></>
                )}

                {showDefinePopup && (
                    <>
                        {() => { debugger }} {/* Debugger statement */}
                        <DefinePop
                            fileName={chatHistory[1]?.defineData?.FRDName || ""}
                            onChange={(data) => updateChatHistory(data,"definePopup")}
                            close={() => setShowDefinePopup(false)}
                        />
                    </>
                )}
                {showtableReqPopup && (
                    <>
                        {/* Debugger statement */}
                        {() => { debugger }} {/* This debugger statement is unusual in JSX, consider if it's needed */}

                        <TableReqPop
                            fileName={chatHistory[1]?.defineData?.tableReqSheetName || ""}
                            onChange={(data) => {
                                updateChatHistory(data,"TableReqPop");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowtableReqPopup(false)}

                        />
                    </>
                )}
                {showsilverERPopup && (
                    <>
                        {/* Debugger statement */}
                        {() => { debugger }} {/* This debugger statement is unusual in JSX, consider if it's needed */}

                        <SilverZonePopup
                            fileName={chatHistory[1]?.defineData?.silverZoneSheetName || ""}
                            onChange={(data) => {
                                updateChatHistory(data,"getSilverERPopup");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowsilverERPopup(false)}
                        />
                    </>
                )}
                {showgoldERPopup && (
                    <>
                        {/* Debugger statement */}
                        {() => { debugger }} {/* This debugger statement is unusual in JSX, consider if it's needed */}

                        <GoldZonePopup
                            fileName={chatHistory[1]?.defineData?.goldZoneSheetName  || ""}
                            onChange={(data) => {
                                updateChatHistory(data,"getGoldERPopup");
                                console.log(data); // Moved console.log inside the onChange function
                            }}
                            close={() => setShowgoldERPopup(false)}
                        />
                    </>
                )}


                {/* Button trigger modal */}
                <button
                    type="button"
                    className="btn btn-primary"
                    id="showconfirm"
                    data-bs-toggle="modal"
                    data-bs-target="#Confrm"
                    hidden
                >
                    Confirmation Popup
                </button>
                {/* Modal */}
                <div
                    className="modal fade"
                    id="Confrm"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="ConfrmLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="ConfrmLabel"
                                >
                                    Confirmation
                                </h5>
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <p className="font-16">
                                            Updating this notebook won’t change the data in all the
                                            sequential documentations, each notebook needs to be
                                            updated manually. Would you like to proceed further?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-2"
                                    data-bs-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    data-bs-dismiss="modal"
                                    onClick={() => getCodePageDetails(isEditNotebook)}
                                >
                                    <span>Yes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default ChatMaintenance;
