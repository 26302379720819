import { useEffect } from 'react'
import { adUsers, getLogin } from '../Service/Api';
import { useNavigate } from 'react-router-dom';
import { random } from '../Service/Client';
import { encode } from 'base-64';
export default function LoginAuthComponent() {

    const nav = useNavigate()
    /*PC_AD_15 UseEffect() will be invoked with empty dependency array. Inside the useEffect()  the method called 
    getDetails() will be called.*/
    useEffect(() => {
        getDetails()
    }, [])

    /*PC_AD_16 "getDetils() method will be invoked. Inside the method get search values from window.location.search store it in
    the variable called search. Then get the query param code values from search using URLSearchParams() 
    constructor. Store the result in the variable called code. the getLogin() will be called then call the client method with /checkUser and body*/
    const getDetails = async () => {
        document.getElementById('pageLoader').style.display = 'block'
        let search = window.location.search;
        let code = new URLSearchParams(search).get("code")
        /*PC_AD_17*/
        let data = {
            code: code
        }
        //console.log(data);
        /*PC_AD_18*/
        let res = await getLogin(data)
        // 

        document.getElementById('pageLoader').style.display = 'none'
        //console.log("res", res);

        if (res.status != 200) {
            nav('/')
            return
        }
        //console.log(res);
        /*PC_AD_23*/
        if (res.status == 200) {
            localStorage.setItem('Y2hlY2tMb2dpbg', random(15) + encode(true) + random(10))
            localStorage.setItem('dXNlcklk', random(15) + encode(res.data.userId) + random(10))
            localStorage.setItem('dG9rZW4', random(15) + encode(res.token) + random(10))
            localStorage.setItem('dXNlckltYWdl1', random(15) + encode(res.data.imageURL) + random(10))
            localStorage.setItem('eGahaTfsa', random(15) + encode(res.data.userName) + random(10))
            localStorage.setItem('wewrgrb', random(15) + encode(res.data.adRole) + random(10))
            localStorage.setItem('fcyhs', random(15) + encode(res.accessToken) + random(10))
            localStorage.setItem('shfhs', random(15) + encode(res.data.emailId) + random(10))
            localStorage.setItem('adcrgsthj', random(15) + encode(res.data.domain) + random(10))
            localStorage.setItem('hgfjhabd', random(15) + encode(res.data.adUserId) + random(10))

            nav('/manageOrganization', { state: { jwt: res.token, userId: res.data.userId, userName: res.data.userName, accessToken: res.accessToken, userMailId: res.data.emailId, adRole: res.data.adRole, domain: res.data.domain ,adUserId:res.data.adUserId} })
        }
    }

    return (
        <div className="container-fluid">
            <div class="overlay" id='pageLoader' style={{ display: 'block' }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div class="spinner-border Loader text-dark align-center " role="status">
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row ">
                {/* DS cg left container starts here */}
                <div className=" col-xl-6 d-xl-block d-none p-0">
                    <div className="bg-gradient-primary  bg-gradient-height  text-center position-relative my-0 rounded-0">
                        <h5 className="font-regular font-24 text-white pt-5">Welcome to <span className="font-bold">GenETL</span></h5>
                        <h6 className="font-regular font-14 text-primary-grad mt-4">Seamless Data Integration Made Simple</h6>
                        <p className="font-regular font-14 text-primary-grad">A More Approachable and Efficient Way to Connect Your Data</p>
                        <span className="pos-login-img mt-5">
                            <img src="/images/login-image.svg" alt="login-img" className="login-img-style" />
                        </span>
                    </div>
                </div>
                {/* DS cg left container ends here */}
                {/* DS cg right container starts here */}
                <div className="  col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
                    <div className="form-signup">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-sm-12 mb-3 text-center">
                                <span>
                                    <img src="/images/codegen-logo.svg" alt="logo" className="logo-style my-5" />
                                </span>
                                <h5 className="font-regular font-22 color-black mb-5">Hi Team, Welcome to <span className="font-bold">GenETL</span></h5>
                                {/*PC_AD_12*/}
                                <button className="btn w-100 cust-primary-btn font-medium font-semibold" >Login</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* DS cg right container ends here */}
            </div>
        </div>
    )
}