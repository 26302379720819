// PS_02: Import necessary React hooks (React, useState, useEffect) and routing hook (useNavigate) from 'react-router-dom' library
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// PS_03: Import the custom fetchJSONfromURL function from the local './API.js' file
// import { fetchJsonFromURL } from './API'; // Ensure the path is correct
import FormAndGridPage from './ExploreFormAndGrid'; // Included as per original file, although it is not used in this excerpt
import { HeaderCom } from './Header'; // This also seems to be external
import { exploreGridDataURL } from './Contrains';
import { fetchJsonFromURL } from './new/BlobStorageService';

// PS_01: User navigates to the ExploreGrid page, initiating the component's lifecycle
const ExploreGrids = () => {

    // PS_05: Initialize state variables using useState hook: jsonData (empty array), searchResults (empty array), and selectedItem (null)
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log(state, 'statestatestate');

    // PS_06: Define a useEffect hook with an empty dependency array to run once when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                // PS_07: Inside useEffect, call fetchJSONfromURL function with the blob URL from environment variables
                const URLToFetch = exploreGridDataURL;
                const jsonData = await fetchJsonFromURL(URLToFetch);
                // const jsonData = cardsData;

                // PS_17: Update the jsonData state with the parsed JSON data using setJsonData
                setData(jsonData);

                // PS_18: Initialize the searchResults state with the same parsed JSON data using setSearchResults
                setFilteredData(jsonData);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // PS_22: The input change in SearchBar triggers the search function
    useEffect(() => {
        // PS_23: Extract the current query value from the event object (event.target.value)
        if (searchTerm.length >= 3) {
            // PS_24: If query length > 3, filter the jsonData array based on the query, matching item titles (case-insensitive)
            const filtered = data.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
            // PS_25: Update searchResults state with the filtered results using setSearchResults
            setFilteredData(filtered);
        } else {
            // PS_26: If query length <= 3, reset searchResults to the full jsonData array using setSearchResults
            setFilteredData(data);
        }
    }, [searchTerm, data]);

    if (error) return <div>Error: {error.message}</div>;

    // PS_29: The click event triggers the handleViewClick function, passing the clicked item as a parameter
    const handleViewClick = (item) => {
        // PS_30: Update the selectedItem state with the clicked item data using setSelectedItem
        // PS_32: Use the navigate function to redirect to '/form-grid' route, passing the clicked item data in the location state
        navigate('/form-grid', { state: { ...state, item } });
    };

    return (
        <>
            {isLoading &&
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ position: "absolute", zIndex: "100000" }}
                >
                    <div
                        className="overlay"
                        id="pageLoader"
                        style={{ display: "block" }}
                    >
                        <div className="position-absolute top-50 start-50  translate-middle">
                            <div className="d-flex align-items-center loader-bg">
                                <div
                                    className="spinner-border Loader text-dark align-center"
                                    role="status"
                                >
                                    <span className="visually-hidden"></span>
                                </div>
                                <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="container-fluid">
                <div className="row">
                    <HeaderCom value="4" />
                    <div className="col-md-12 pt-4 mt-5">
                        <div className="p-md-4 p-0">
                            <div className="d-flex mb-5 justify-content-between align-items-center cust-manage-org-sm">
                                <h2 className="text-black font-bold font-22 mb-md-0 mb-3 mt-md-0 mt-3">
                                    Use Case Prompt
                                </h2>
                                <div className="d-flex cust-manage-org-sm">
                                    <div class="input-group position-relative search-wid me-md-3">
                                        <input type="search" className="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                        <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {filteredData?.length > 0 ?
                                    <>
                                        {filteredData.map((item, index) => (
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                                                <div className="card h-100 card-style cursor-pointer">
                                                    <div className="card-body">
                                                        <img
                                                            src={item.iconURL || 'images/Organization_logo.svg'} // Assuming item.logo contains the logo URL
                                                            alt={`${item.title}-logo`}
                                                            className="card-logo me-2"
                                                        />
                                                        {item.title.includes(' to ') ?
                                                            <>
                                                                <p className="mt-3 font-18 font-bold text-black me-2">
                                                                    {item.title.split(' to ').map((part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                            {index < item.title.split('to').length - 1 && (
                                                                                <span>
                                                                                    <img
                                                                                        src="images/rightarrow.svg"
                                                                                        alt="right-arrow"
                                                                                        className="mx-3"
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </p>
                                                            </>
                                                            :
                                                            <>
                                                                <p className="mt-3 font-18 font-bold text-black me-2">
                                                                    {item.title}
                                                                </p>
                                                            </>
                                                        }
                                                        <div className="text-end mb-2 mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn cust-gray-btn font-14 font-medium px-3"
                                                                onClick={() => handleViewClick(item)} // PS_28: User interacts with the UI by clicking the "View" button on a specific card within the Grid
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-center flex-column color-white-bg rounded-bottom pt-100 pb-400 mb-5 mt-5">
                                                <div className="d-flex justify-content-center mb-5">
                                                    <img
                                                        src="images/no-records.svg"
                                                        alt="No-Records-Found"
                                                        className="d-block mt-5"
                                                    />
                                                </div>
                                                <p className="d-flex font-22 font-medium text-grey-v3 mb-1 justify-content-center">
                                                    No Records found
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExploreGrids;