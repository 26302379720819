import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderCom } from "../Header.jsx";
import { DWHActivityLogs, generateSasTokenAPI,insertError } from "../../Service/Api.js";
import ViewInstructions from '../new/Integrations/viewInstructions.jsx';



import * as XLSX from 'xlsx';
function MetaDataTable(props) {
    const location = useLocation();
    const nav = useNavigate();
    let { state } = useLocation();





    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';


    const [dataUpdate, setDataUpdate] = useState([])
    const [jsonData, setJsonData] = useState([]);
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)
    const [dbData, setDBData] = useState(false)
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    //PS_CD_1.62
    const [searchTerm, setSearchTerm] = useState("");
    const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState("");
    const [filterTableName, setFilterTableName] = useState([]);
    const [filterSubjectArea, setFilterSubjectArea] = useState([]);
    const [needAlter, setneddalter] = useState(false);
    const [filterSourceSystem, setFilterSourceSystem] = useState([]);
    const [filterSourceSchema, setFilterSourceSchema] = useState([]);
    const [filterSourceSecretName, setFilterSourceSecretName] = useState([]);


    const [sortOrder, setSortOrder] = useState({ ascending: false, column: 'DataflowName' })

    const [loadType, setLoadType] = useState(["Truncated", "Incremental", "Full Load"]);
    const [frequency, setFrequency] = useState(["Weekly", "Daily", "Monthly", "Hourly"]);
    const [Zone, setZone] = useState(["BronzePath", "SilverPath", "GoldPath"]);

    const [inputValues, setInputValues] = useState({
        SourceSystem: '',
        SourceSecretName: '',
        TableID: '',
        SubjectArea: 'Select',
        SourceDBName: '',
        SourceSchema: '',
        SourceTableName: '',
        LoadType: 'Select',
        IsActive: '',
        Frequency: 'Select',
        BronzePath: '',
        SilverPath: '',
        GoldPath: '',
        DWHSchemaName: '',
        DWHTableName: '',
        ErrorLogPath: '',
        LastLoadDateColumn: '',
        LastLoadDateValue: '',
        MergeKey: '',
        DependencyTableIDs: '',
        PipelineStartTime: '',
        PipelineEndTime: '',
        PipelineRunStatus: '',
        Zone: 'Select',
        MergeKeyColumn: '',
        SourceSelectQuery: '',
        NotebookName: ''
    });


    // const [inputValues, setInputValues] = useState({})
    const [subjectArea, setSubjectArea] = useState([])
    const [body, setBody] = useState({})
    const [filterCriteria, setFilterCriteria] = useState({
        SubjectArea: 'Select',
        TableName: 'Select',
        SourceSystem: 'Select',
        SourceSchema: 'Select',
        SourceSecretName:"Select"

    });
     const [isModalOpen, setModalOpen] = useState(false);
     
          const handleOpenModal = () => {
            setModalOpen(true);
        };
    
        const handleCloseModal = () => {
            setModalOpen(false);
        };
    // PS_CD_1.8

    let uniqueTableNames = [];
    let uniqueSubjectAreas = []

 const putJsonToBlob = async (jsonData) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `dwhmigration/${state.orgId}/${state.projectId}/uploads/${props.migrationType}/metadata.json`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url1 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url1;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};


    const fetchJsonFromBlob = async () => {
        try {
            const sasToken = await generateSasTokenAPI();
            const blobPath = `dwhmigration/${state.orgId}/${state.projectId}/uploads/${props.migrationType}/metadata.json`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
            console.log(url2, 'this is the url for fetch')
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };
    // PS_MD_03 

    useEffect(() => {
        const fetchData = async () => {
            try {
                 
                const dropDownValuesPromise = fetchDropDown();
                const pseudoDataPromise = fetchPseudo();
                // Wait for both promises to resolve
                const [dropDownValues, pseudoData] = await Promise.all([dropDownValuesPromise, pseudoDataPromise]);
                console.log("res from fetchDropDownValues", dropDownValues);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        // PS_MD_04
        fetchData();
    }, []);

    // PS_MD_05
    const fetchDropDown = () => {
        try {
            // Assuming jsonData is already set with actual data either from previous logic or component state
            if (jsonData && jsonData.length > 0) {
                // Extract unique values for each filter category
                const uniqueSubjectAreas = [...new Set(jsonData.map(item => item.SubjectArea))];
                const uniqueSourceSystems = [...new Set(jsonData.map(item => item.SourceSystem))];
                const uniqueSourceSchemas = [...new Set(jsonData.map(item => item.SourceSchema))];
                const uniqueTableNames = [...new Set(jsonData.map(item => item.DWHTableName).filter(a => a != null))];
                const uniqueSourceSecretNames = [...new Set(jsonData.map(item => item.SourceSecretName).filter(a => a != null))]; 
    
                // Set these unique values to state for dropdown menus
                setSubjectArea(uniqueSubjectAreas);
                setFilterSourceSystem(uniqueSourceSystems);
                setFilterSourceSchema(uniqueSourceSchemas);
                setFilterTableName(uniqueTableNames);
                setFilterSourceSecretName(uniqueSourceSecretNames);
    
                console.log("Derived filter values from jsonData");
            } else {
                console.log("No data available to derive filter values");
            }
        } catch (e) {
            console.error("Error in catch block:", e);
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "fetchDropDown",
                userId: state.userId
            });
        }
    };
    
    // Call this function once the component is mounted or whenever 'jsonData' changes
    useEffect(() => {
        fetchDropDown();
    }, [jsonData]); 


    const fetchPseudo = async () => {
        try {
        
            document.getElementById('pageLoader').style.display = 'block';
    
            // Fetch jsonData from blob
            const pseudodata = await fetchJsonFromBlob();
            console.log("Fetched pseudo data:", pseudodata);
    
            // Map to add an index if needed
            const pseudo = pseudodata.map((obj, index) => ({
                ...obj,
                index: index
            }));
    
            setJsonData(pseudo);
            setResponse(pseudo);
            setDBData(pseudo)
    
            uniqueTableNames = [...new Set((pseudo).map(item => item.DWHTableName).filter(a => a != null))];
            uniqueSubjectAreas = [...new Set((pseudo).map(item => item.SubjectArea))];
            let uniqueSourceSystem = [...new Set((pseudo).map(item => item.SourceSystem))]; 
            let uniqueSourceSchema = [...new Set((pseudo).map(item => item.SourceSchema))];
            let sourceSecretName = [...new Set((pseudo).map(item => item.SourceSecretName).filter(a => a != null))];
    
            setFilterSubjectArea(uniqueSubjectAreas);
            setFilterTableName(uniqueTableNames);
            setFilterSourceSystem(uniqueSourceSystem);
            setFilterSourceSchema(uniqueSourceSchema);
            setFilterSourceSecretName(sourceSecretName);
    
            document.getElementById('pageLoader').style.display = 'none';
    
        } catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "fetchPseudo",
                userId: state.userId
            });
            console.log("error fetching pseudo data", e);
        }
    };

    // PS_MD_130
    const filterByAppliedFilters = () => {
        console.log("filtercriteria", filterCriteria)
        const { SubjectArea, TableName, SourceSystem, SourceSchema } = filterCriteria;


        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea.toLowerCase() === SubjectArea.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record.TableName.toLowerCase() === TableName.toLowerCase();
            const SourceSchemaMatch = SourceSchema === 'Select' || record.SourceSchema.toLowerCase() === SourceSchema.toLowerCase();
            const SourceSystemMatch = SourceSystem === 'Select' || record.SourceSystem.toLowerCase() === SourceSystem.toLowerCase();

            return SubjectAreaMatch && TableNameMatch && SourceSchemaMatch && SourceSystemMatch
        });
        return filteredList
    };

    const handleSearch = (term) => {
         
        if (searchTerm.length < 3) {
            // If the search term is empty, reset to all 
            let records = filterByAppliedFilters()

            setJsonData(records);
        } else {
            // Filter the based  based on search criteria  SubjectArea

            const filteredData = jsonData.filter(
                (record) =>
                    (record.SourceSystem && record.SourceSystem?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SubjectArea && record.SubjectArea?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SourceDBName && record.SourceDBName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.SourceTableName && record.SourceTableName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.DWHTableName && record.DWHTableName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record.DWHScehmaName && record.DWHScehmaName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record?.NotebookName && record.NotebookName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record?.PipelineName && record?.PipelineName?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    (record?.StoreProcedureName && record?.StoreProcedureName?.toLowerCase().includes(searchTerm?.toLowerCase()))



            )

            setJsonData(filteredData);
        }
    };
    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm]);


    
    const updatePseudocode = async (updatedItem) => {
        try {
            document.getElementById('pageLoader').style.display = 'block';

            const updatedData = dbData.map(item => 
                item.TableID === updatedItem.value.TableID && item.SourceSystem === updatedItem.value.SourceSystem
                ? { ...item, ...updatedItem.value }
                : item
            );

            await putJsonToBlob(updatedData);

            setResponse(updatedData);
            setDataUpdate(updatedData)
            setJsonData(updatedData);
            setDBData(updatedData)    
            document.getElementById('pageLoader').style.display = 'none';
            return false

        } catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "updatePseudocode",
                userId: state.userId
            });
            console.error("Error updating data in blob:", e);
            document.getElementById('pageLoader').style.display = 'none';
        }
    };
    // PS_MD_89
    const handleTick = async (item) => {
         
        console.log("in tick fun", item, "reason", reason);
        // setJsonData(response);
        // PS_MD_90
        setBody({
            type: "metadataTable",
            actionType: "edit",
            index: item.index,
            comments: reason,
            value: item
        })
    }
    // PS_MD_167
    const handleCancel = async () => {
        // PS_MD_168
        // setJsonData(response);
        handleApplyFilter()
        setEditingIndex(null);
        setInputValues({
            SourceSystem: '',
            SourceSecretName: '',
            TableID: '',
            SubjectArea: 'Select',
            SourceDBName: '',
            SourceSchema: '',
            SourceTableName: '',
            LoadType: 'Select',
            IsActive: '',
            Frequency: 'Select',
            BronzePath: '',
            SilverPath: '',
            GoldPath: '',
            DWHSchemaName: '',
            DWHTableName: '',
            ErrorLogPath: '',
            LastLoadDateColumn: '',
            LastLoadDateValue: '',
            MergeKey: '',
            DependencyTableIDs: '',
            PipelineStartTime: '',
            PipelineEndTime: '',
            PipelineRunStatus: '',
            Zone: 'Select',
            MergeKeyColumn: '',
            SourceSelectQuery: '',
            NotebookName: ''
        })
        setReason("")
    }

    const handleReason = async (e) => {
        setReason(e.target.value)

    }
    let apiBody
    // PS_MD_96
    const handleSaveDetails = async () => {
        setEditingIndex(null)
        setwhetherUpdated(true)
        if (!reason || !body) {

            return;
        }
        apiBody = { ...body, comments: reason, projectId: state.projectId }
        // PS_MD_98
        
        
       let  resd =  await updatePseudocode(apiBody)

        document.getElementById('pageLoader').style.display = 'block';
        


        let pobody={
            type: "metadata", 
            projectId: state.projectId, 
            userId: state.userId,
            orgId: state.orgId,
            comments:reason,
            actionType:"edit"

        }
      
      let res =  await DWHActivityLogs(pobody,state.jwt)
    //   await putJsonToBlob(dataUpdate)
      console.log(res,"active response");


      console.log(resd,"resdddddddddddddddddddd");
      
      if(needAlter){
        await putJsonToBlob(dataUpdate)
        setJsonData(dataUpdate)
        setDBData(dataUpdate)
        setneddalter(false)
      }
       

        setReason("")

        setInputValues({
            SourceSystem: '',
            SourceSecretName: '',
            TableID: '',
            SubjectArea: 'Select',
            SourceDBName: '',
            SourceSchema: '',
            SourceTableName: '',
            LoadType: 'Select',
            IsActive: '',
            Frequency: 'Select',
            BronzePath: '',
            SilverPath: '',
            GoldPath: '',
            DWHSchemaName: '',
            DWHTableName: '',
            ErrorLogPath: '',
            LastLoadDateColumn: '',
            LastLoadDateValue: '',
            MergeKey: '',
            DependencyTableIDs: '',
            PipelineStartTime: '',
            PipelineEndTime: '',
            PipelineRunStatus: '',
            Zone: 'Select',
            MergeKeyColumn: '',
            SourceSelectQuery: '',
            NotebookName: ''
        })
        document.getElementById('pageLoader').style.display = 'none';
    }

    // PS_MD_63
    const handleDownload = async (response) => {
         ;
        console.log("in download");
        let responseValue = response.map(obj => {
            const { ["index"]: removedKey, ...rest } = obj; // Use object destructuring to remove the specified key
            return rest; // Return the object without the removed key
        });

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(responseValue);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MetaData Table');

        // Generate Excel file and download
        XLSX.writeFile(wb, 'MetaDataTable.xlsx');
        return;
    }

    // PS_MD_165
    const handleClearFilter = () => {

        // Reset filter criteria to their initial values
        // PS_MD_166
        setFilterCriteria({
            SubjectArea: 'Select',
            TableName: 'Select',
            SourceSystem: 'Select',
            SourceSchema: 'Select'

        });
        // Reset filtered projectList to all projectList
        setJsonData(response);
    };
    // PS_MD_140

    const handleApplyFilter = () => {
         
        console.log("filtercriteria", filterCriteria)
        // PS_MD_142
        const { SubjectArea, TableName, SourceSchema, SourceSystem ,SourceSecretName} = filterCriteria;


        const filteredList = response.filter((record) => {

            const SubjectAreaMatch = SubjectArea === 'Select' || record.SubjectArea?.toLowerCase() === SubjectArea?.toLowerCase();
            const TableNameMatch = TableName === 'Select' || record.DWHTableName?.toLowerCase() === TableName?.toLowerCase();
            const SourceSchemaMatch = SourceSchema === 'Select' || record.SourceSchema?.toLowerCase() === SourceSchema?.toLowerCase();
            const SourceSystemMatch = SourceSystem === 'Select' || record.SourceSystem?.toLowerCase() === SourceSystem?.toLowerCase();
const SourceSecretNameMatch=SourceSecretName=== 'Select' || record.SourceSecretName?.toLowerCase() === SourceSecretName?.toLowerCase();
            return SubjectAreaMatch && TableNameMatch && SourceSchemaMatch && SourceSystemMatch && SourceSecretNameMatch

        });
        // PS_CD_1.53
        // Set the filtered projectList to a separate state variable
        setJsonData(filteredList);
    };
    const bindgrid = () => {
        //console.log('binding json', jsonData);
        try {
             
            console.log("uniqueTableName-----------s", uniqueTableNames, "uniqueSubjectAreas", uniqueSubjectAreas);

            console.log("in binf func", jsonData);
            // Check if jsonData is an array and has elements
            if (jsonData.length > 0) {
                console.log("in binf func2222", jsonData);

                return (
                    <>
                        {jsonData?.map((item, index) => (

                            (editingIndex == index) ? (
                                <tr key={index}>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSystem" value={inputValues?.SourceSystem} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSecretName" value={inputValues?.SourceSecretName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="TableID" value={inputValues?.TableID} onChange={handleInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.SubjectArea}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {subjectArea.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("SubjectArea", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceDBName" value={inputValues?.SourceDBName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSchema" value={inputValues?.SourceSchema} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceTableName" value={inputValues?.SourceTableName} onChange={handleInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.LoadType}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {loadType.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("LoadType", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>

                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="IsActive" value={inputValues?.IsActive} onChange={handleInputChange} /></td>

                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                                value={inputValues.Frequency}
                                            >
                                                {inputValues.Frequency}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {frequency.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("Frequency", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>




                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="BronzePath" value={inputValues?.BronzePath} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SilverPath" value={inputValues?.SilverPath} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="GoldPath" value={inputValues?.GoldPath} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="DWHSchemaName" value={inputValues?.DWHSchemaName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="DWHTableName" value={inputValues?.DWHTableName} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="ErrorLogPath" value={inputValues?.ErrorLogPath} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="LastLoadDateColumn" value={inputValues?.LastLoadDateColumn} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="LastLoadDateValue" value={inputValues?.LastLoadDateValue} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="MergeKey" value={inputValues?.MergeKey} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="DependencyTableIDs" value={inputValues?.DependencyTableIDs} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="PipelineStartTime" value={inputValues?.PipelineStartTime} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="PipelineEndTime" value={inputValues?.PipelineEndTime} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="PipelineRunStatus" value={inputValues?.PipelineRunStatus} onChange={handleInputChange} /></td>
                                    <td>
                                        <div className="dropdown">
                                            <a
                                                className="btn custom-dropdown-toggle form-control cust-input-sty font-14 font-regular d-flex align-items-center me-3"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ zIndex: 1001 }}
                                            >
                                                {inputValues.Zone}
                                            </a>
                                            <ul
                                                className="dropdown-menu cust-dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                {Zone.map(value => (
                                                    <li key={value} onClick={() => handleDropDownChange("Zone", value)}>
                                                        <a className="dropdown-item">{value}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>


                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="MergeKeyColumn" value={inputValues?.MergeKeyColumn} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="SourceSelectQuery" value={inputValues?.SourceSelectQuery} onChange={handleInputChange} /></td>
                                    <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" name="NotebookName" value={inputValues?.NotebookName} onChange={handleInputChange} /></td>

                                    <td style={{ textAlign: "center" }}>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 `}
                                            onClick={() => handleTick(inputValues)} // Only call handleAdd if form is filled
                                            {...(isFormFilled() ? {
                                                "data-bs-toggle": "modal", // Add this attribute to trigger the modal
                                                "data-bs-target": "#editRows" // Specify the target modal
                                            } : {})}
                                            disabled={!isFormFilled()}


                                            // data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            // data-bs-target="#editRows"  // Specify the target modal
                                            title="update"

                                        />

                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer  me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        />
                                    </td>
                                </tr>) : (

                                <tr key={index}>

                                    <td>{item?.SourceSystem||"NA"}</td>
                                    <td>{item?.SourceSecretName ||"NA"}</td>
                                    <td>{item?.TableID ||"NA"}</td>
                                    <td>{item?.SubjectArea ||"NA"}</td>
                                    <td>{item?.SourceDBName ||"NA"}</td>
                                    <td>{item?.SourceSchema ||"NA"}</td>
                                    <td>{item?.SourceTableName ||"NA"}</td>

                                    <td>{item?.LoadType ||"NA"}</td>
                                    <td>{(item?.IsActive ||"NA")}</td>
                                    <td>{item?.Frequency ||"NA"}</td>
                                    <td>{item?.BronzePath ||"NA"}</td>
                                    <td>{item?.SilverPath||"NA"}</td>
                                    <td>{item?.GoldPath ||"NA"}</td>
                                    <td>{item?.DWHSchemaName ||"NA"}</td>
                                    <td>{item?.DWHTableName ||"NA"}</td>
                                    <td>{item?.ErrorLogPath||"NA"}</td>
                                    <td>{item?.LastLoadDateColumn ||"NA"}</td>
                                    <td>{item?.LastLoadDateValue ||"NA"}</td>
                                    <td>{item?.MergeKey ||"NA"}</td>
                                    <td>{item?.DependencyTableIDs ||"NA"}</td>
                                    <td>{item?.PipelineStartTime ||"NA"}</td>
                                    <td>{item?.PipelineEndTime ||"NA"}</td>
                                    <td>{item?.PipelineRunStatus ||"NA"}</td>
                                    <td>{item?.Zone ||"NA"}</td>
                                    <td>{item?.MergeKeyColumn ||"NA"}</td>
                                    <td>{item?.SourceSelectQuery ||"NA"}</td>
                                    <td>{item?.NotebookName || item.PipelineName || item.StoreProcedureName ||"NA"}</td>

                                    <td className="text-center" style={{ textAlign: "center" }}>
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            data-bs-toggle="modal"
                                            title="Edit"
                                            onClick={() => handleEditClick(index, item)}
                                        />



                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer"
                                            title="Delete"
                                            data-bs-toggle="modal"  // Add this attribute to trigger the modal
                                            data-bs-target="#editRows"  // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        />

                                    </td>
                                </tr>
                            )))}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "bindgrid",
                userId: state.userId
            });
            console.error("Error in bindgrid:", error);
            return null;
        }
    };


    /** // \PS_MD_82
     * The function `handleEditClick` sets the selected item and updates the `isEditMetavalue` state.
     */
    const handleEditClick = (index, item) => {
         
        // PS_MD_83
        setEditingIndex(index)
        setInputValues({ ...inputValues, ...item, DependencyTableIDs: item?.DependencyTableIDs || item["DependencyTableID's"], NotebookName: (item.NotebookName || item.StoreProcedureName || item.PipelineName), StoreProcedureName: (item.NotebookName || item.StoreProcedureName || item.PipelineName), PipelineName: (item.NotebookName || item.StoreProcedureName || item.PipelineName) }); // Update inputValues with the values from the clicked item
        setIsEditingData(true)
    };
    // PS_MD_107


   


    const handleDelete = async (index, updatedItem) => {

        try {
            
            document.getElementById('pageLoader').style.display = 'block';

            const updatedData = dbData.filter(item => 
        !(item.TableID === updatedItem.TableID && item.SourceSystem === updatedItem.SourceSystem)
    );

            setDataUpdate(updatedData);
            setneddalter(true)
            setResponse(updatedData);
            // setJsonData(updatedData);
            setDBData(updatedData)
    
            document.getElementById('pageLoader').style.display = 'none';
        } catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "updatePseudocode",
                userId: state.userId
            });
            console.error("Error updating data in blob:", e);
            document.getElementById('pageLoader').style.display = 'none';
        }

    };


    // PS_MD_85
    const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));


        console.log(inputValues);
        
    };

    const handleDropDownChange = (key, value) => {
        setInputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }
    const handleSort = (columnName) => {
        const isSameColumn = sortOrder.column === columnName;
        const newOrder = isSameColumn ? !sortOrder.ascending : false;
        const sortedData = [...jsonData].sort((a, b) => {
            const aValue = a[columnName];
            const bValue = b[columnName];

            // Check if values are numbers
            const aValueIsNumber = !isNaN(aValue);
            const bValueIsNumber = !isNaN(bValue);

            // Provide default value if property doesn't exist
            const defaultStringValue = '';

            // If both values are numbers, sort numerically
            if (aValueIsNumber && bValueIsNumber) {
                return (isSameColumn
                    ? newOrder
                        ? aValue - bValue
                        : bValue - aValue
                    : aValue - bValue);
            } else {
                // If one or both values are not numbers, sort as strings
                return (isSameColumn
                    ? newOrder
                        ? (aValue || defaultStringValue).localeCompare(bValue || defaultStringValue)
                        : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue)
                    : (bValue || defaultStringValue).localeCompare(aValue || defaultStringValue));
            }
        });
        setSortOrder({ column: columnName, ascending: newOrder });
        setJsonData(sortedData);
    };




    // PS_MD_170
    const isFormFilled = () => {
         
        console.log("input values in isFormFilled:", inputValues);
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(inputValues).map(value => {
            if (typeof value === 'string' || !value || value === "") {
                return typeof value === 'string' ? value.trim() : value; // Trim the string value if it's a string
            } else {
                return value; // Return non-string value as is
            }
        }); // Get an array of input values
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };




    // PS_MD_119
    const handleAdd = async (value) => {
         

        try {
            document.getElementById('pageLoader').style.display = 'block';
            console.log(value,"adddign");
            
            
            const updatedData = [value,...dbData]

            setDataUpdate(updatedData);
            
            setneddalter(true)
            setResponse(updatedData);
            // setJsonData(updatedData);
            setDBData(updatedData)
            setInputValues({
                SourceSystem: '',
                SourceSecretName: '',
                TableID: '',
                SubjectArea: 'Select',
                SourceDBName: '',
                SourceSchema: '',
                SourceTableName: '',
                LoadType: 'Select',
                IsActive: '',
                Frequency: 'Select',
                BronzePath: '',
                SilverPath: '',
                GoldPath: '',
                DWHSchemaName: '',
                DWHTableName: '',
                ErrorLogPath: '',
                LastLoadDateColumn: '',
                LastLoadDateValue: '',
                MergeKey: '',
                DependencyTableIDs: '',
                PipelineStartTime: '',
                PipelineEndTime: '',
                PipelineRunStatus: '',
                Zone: 'Select',
                MergeKeyColumn: '',
                SourceSelectQuery: '',
                NotebookName: ''
            })
    
            document.getElementById('pageLoader').style.display = 'none';
        } catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "metadatatable",
                functionName: "updatePseudocode",
                userId: state.userId
            });
            console.error("Error updating data in blob:", e);
            document.getElementById('pageLoader').style.display = 'none';
        }
    };
    

    // PS_MD_172
    const handlecancelSearch = (value) => {
        setSearchTerm();
        setJsonData(response.filter(filterByAppliedFilters()))

    };

    return (
        <div className="container-fluid">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* header style starts here */}
                <HeaderCom value='1' />

                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium">
                                <a >Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a >{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Metadata Table</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor:"pointer",whiteSpace:"nowrap"  }} onClick={() => { props.closeGrid() }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">Metadata Table
                                {/* <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText} */}
                            <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button></h2>
                            {isModalOpen && <ViewInstructions notebooktype='dwhMigration' onClose={handleCloseModal} />}
                        </div>
                        <div className="d-flex">
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    placeholder="Search" onChange={(e) => { setSearchTerm(e.target.value); handleSearch(searchTerm) }}
                                    value={searchTerm}
                                />
                                <span className="search-icon-sty" onClick={() => handlecancelSearch} >
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon" />
                                    </a>
                                </span>
                            </div>
                            <div className="btn-group me-3">
                                <button
                                    className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon"
                                    />
                                </button>
                                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SourceSystem"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Source system
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SourceSystem"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.SourceSystem}

                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSystem: e.target.value })}
                                                    >
                                                        <option selected="">Select</option>
                                                        {filterSourceSystem.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SourceSchema"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Source Schema
                                                    </label>
                                                    <select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SourceSchema"
                                                        aria-label="Default select example"
                                                        value={filterCriteria.SourceSchema}

                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSchema: e.target.value })}
                                                    >
                                                        <option selected="">Select</option>
                                                        {filterSourceSchema.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {state.futureStateName == "ADF" || state.futureStateName?.toLowerCase() == "synapse" && state.Methodology?.toLowerCase() == "pipeline" ? (
                                                <>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="SourceSecretName"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Source Secret Name
                                                            </label>
                                                            <select
                                                                value={filterCriteria.SourceSecretName}

                                                                className="form-select cust-input-sty font-14 font-regular"
                                                                id="TableName"
                                                                aria-label="Default select example"
                                                                onChange={(e) => setFilterCriteria({ ...filterCriteria, SourceSecretName: e.target.value })}
                                                            >
                                                                <option selected="">Select</option>
                                                                {filterSourceSecretName.map(value => (
                                                                    <option key={value} value={value}>{value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                </>
                                            ) :

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="TableName"
                                                            className="form-label text-black font-14 font-medium"
                                                        >
                                                            Table Name
                                                        </label>
                                                        <select
                                                            value={filterCriteria.TableName}

                                                            className="form-select cust-input-sty font-14 font-regular"
                                                            id="TableName"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setFilterCriteria({ ...filterCriteria, TableName: e.target.value })}
                                                        >
                                                            <option selected="">Select</option>
                                                            {filterTableName.map(value => (
                                                                <option key={value} value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="SubjectArea"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Subject Area
                                                    </label>
                                                    <select
                                                        value={filterCriteria.SubjectArea}

                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        id="SubjectArea"
                                                        aria-label="Default select example"
                                                        onChange={(e) => setFilterCriteria({ ...filterCriteria, SubjectArea: e.target.value })}>
                                                        <option selected="">Select</option>
                                                        {filterSubjectArea.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                                onClick={handleClearFilter}>
                                                Clear
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium px-4"
                                                onClick={handleApplyFilter}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium "
                                // data-bs-dismiss="modal"
                                onClick={() => handleDownload(response)}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon"  />
                                </span>{" "}
                                Download
                            </button>
                        </div>
                    </div>

                    <div className="col-md-12 px-4">
                     
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th>SourceSystem <span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSystem" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSystem')} /></span></th>
                                                <th>SourceSecretName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSecretName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSecretName')} /></span></th>
                                                <th>TableID<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "TableID" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('TableID')} /></span></th>
                                                <th>SubjectArea<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SubjectArea" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SubjectArea')} /></span></th>
                                                <th>SourceDBName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceDBName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceDBName')} /></span></th>
                                                <th>SourceSchema<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceSchema" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceSchema')} /></span></th>
                                                <th>SourceTableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SourceTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SourceTableName')} /></span></th>
                                                <th>LoadType<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LoadType" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LoadType')} /></span></th>
                                                <th>IsActive<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "IsActive" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('IsActive')} /></span></th>
                                                <th>Frequency<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "Frequency" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Frequency')} /></span></th>
                                                <th>BronzePath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "BronzePath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('BronzePath')} /></span></th>
                                                <th>SilverPath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "SilverPath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('SilverPath')} /></span></th>
                                                <th>GoldPath<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "GoldPath" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('GoldPath')} /></span></th>
                                                <th>DWHSchemaName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DWHSchemaName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DWHSchemaName')} /></span></th>
                                                <th>DWHTableName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DWHTableName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DWHTableName')} /></span></th>
                                                <th>ErrorLogPath<span className="ms-2 cursor-pointer"></span></th>
                                                <th>LastLoadDateColumn<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LastLoadDateColumn" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LastLoadDateColumn')} /></span></th>
                                                <th>LastLoadDateValue<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "LastLoadDateValue" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('LastLoadDateValue')} /></span></th>
                                                <th>MergeKey<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "MergeKey" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('MergeKey')} /></span></th>
                                                <th>DependencyTableIDs<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "DependencyTableIDs" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('DependencyTableIDs')} /></span></th>
                                                <th>PipelineStartTime<span className="ms-2 cursor-pointer"></span></th>
                                                <th>PipelineEndTime<span className="ms-2 cursor-pointer"></span></th>
                                                <th>PipelineRunStatus<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineRunStatus" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineRunStatus')} /></span></th>
                                                <th style={{ width: '150px' }}> Zone<span className="ms-2 cursor-pointer"><img style={{ marginRight: '150px' }} src={sortOrder.ascending && sortOrder.order == "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('Zone')} /></span></th>
                                                <th>MergeKeyColumn<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "MergeKeyColumn" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('MergeKeyColumn')} /></span></th>
                                                <th>SourceSelectQuery<span className="ms-2 cursor-pointer"></span></th>
                                                {state.futureStateName == "Databricks" || state.futureStateName == "Synapse" && state.Methodology == "Notebook" ? (
                                                    <th>NotebookName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "NotebookName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('NotebookName')} /></span></th>
                                                )
                                                    : state.futureStateName == "ADF" || state.futureStateName == "Syanapse" && state.Methodology == "Pipeline" ? (
                                                        <th>PipelineName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "PipelineName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('PipelineName')} /></span></th>

                                                    ) : (
                                                        <th>StoredProcedureName<span className="ms-2 cursor-pointer"><img src={sortOrder.column == "StoreProcedureName" && sortOrder.ascending ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"} alt="sort-arrow-down" onClick={() => handleSort('StoreProcedureName')} /></span></th>
                                                    )}



                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(editingIndex != null) ? (
                                                <></>
                                            ) : (
                                                <tr>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceSystem"
                                                        value={inputValues.SourceSystem}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceSecretName"
                                                        value={inputValues.SourceSecretName}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="TableID"
                                                        value={inputValues.TableID}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="SubjectArea"
                                                        value={inputValues.SubjectArea}
                                                        onChange={(e) => handleDropDownChange("SubjectArea", e.target.value)}
                                                    >
                                                        <option value="Select">Select</option>
                                                        {subjectArea?.map(value => (
                                                            <option key={value} value={value}>{value}</option  >
                                                        ))}
                                                    </select></td>


                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceDBName"
                                                        value={inputValues.SourceDBName}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceSchema"
                                                        value={inputValues.SourceSchema}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceTableName"
                                                        value={inputValues.SourceTableName}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="LoadType"
                                                        value={inputValues.LoadType}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {loadType.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="IsActive"
                                                        value={inputValues.IsActive}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="Frequency"
                                                        value={inputValues.Frequency}
                                                        onChange={(e) => handleDropDownChange("Frequency", e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        {frequency.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="BronzePath"
                                                        value={inputValues.BronzePath}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SilverPath"
                                                        value={inputValues.SilverPath}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="GoldPath"
                                                        value={inputValues.GoldPath}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="DWHSchemaName"
                                                        value={inputValues.DWHSchemaName}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="DWHTableName"
                                                        value={inputValues.DWHTableName}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="ErrorLogPath"
                                                        value={inputValues.ErrorLogPath}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="LastLoadDateColumn"
                                                        value={inputValues.LastLoadDateColumn}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="LastLoadDateValue"
                                                        value={inputValues.LastLoadDateValue}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="MergeKey"
                                                        value={inputValues.MergeKey}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="DependencyTableIDs"
                                                        value={inputValues.DependencyTableIDs}
                                                        onChange={handleInputChange}
                                                    /></td>  <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="PipelineStartTime"
                                                        value={inputValues.PipelineStartTime}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="PipelineEndTime"
                                                        value={inputValues.PipelineEndTime}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="PipelineRunStatus"
                                                        value={inputValues.PipelineRunStatus}
                                                        onChange={handleInputChange}
                                                    /></td>

                                                    <td><select
                                                        className="form-select cust-input-sty font-14 font-regular"
                                                        aria-label="Default select example"
                                                        name="Zone"
                                                        value={inputValues.Zone}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {Zone.map(value => (
                                                            <option key={value} value={value}>{value}</option>
                                                        ))}
                                                    </select></td>

                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="MergeKeyColumn"
                                                        value={inputValues.MergeKeyColumn}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="SourceSelectQuery"
                                                        value={inputValues.SourceSelectQuery}
                                                        onChange={handleInputChange}
                                                    /></td>
                                                    <td><input
                                                        type="text"
                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                        placeholder="Enter"
                                                        name="NotebookName"
                                                        value={inputValues.NotebookName}
                                                        onChange={handleInputChange}
                                                    /></td>


<td>
                                                            <img
                                                                src="images/add-icons.svg"
                                                                alt="add-icon"
                                                                title="Add"
                                                                className={`cust-cursor-pointer ${isFormFilled() ? '' : 'disabled'}`}
                                                                {...(isFormFilled() ? {
                                                                    "data-bs-toggle": "modal",
                                                                    "data-bs-target": "#editRows"
                                                                } : {})}
                                                                onClick={(e) => {
                                                                    if (isFormFilled()) {
                                                                        handleAdd(inputValues);
                                                                    } else {
                                                                        e.currentTarget.style.opacity = '0.3';
                                                                        e.currentTarget.style.cursor = 'not-allowed';
                                                                    }
                                                                }}
                                                                style={{
                                                                    opacity: isFormFilled() ? 1 : 0.3,
                                                                    cursor: isFormFilled() ? 'pointer' : 'not-allowed'
                                                                }}
                                                            />
                                                        </td>
                                                </tr>

                                            )
                                            }


                                            {bindgrid()}


                                        </tbody>
                                    </table>




                                    {/*Metadata Table ends here*/}

                                    {/* model */}
                                    <div
                                        className="modal fade "
                                        id="editRows"
                                        // data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabIndex={-1}
                                        aria-labelledby="ConfrmLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5
                                                        className="modal-title d-flex align-items-center font-medium font-20"
                                                        id="ConfrmLabel"
                                                    >
                                                        Edit Reason
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label
                                                                htmlFor="User-Name"
                                                                className="form-label text-black font-14 font-medium"
                                                            >
                                                                Specify reason for manual edit
                                                                <span className="text-red"> *</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control font-14 font-regular "
                                                                id="User-Name"
                                                                placeholder="Enter reason"
                                                                rows={5}
                                                                defaultValue={null}
                                                                value={reason}
                                                                onChange={handleReason}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn font-14 font-medium me-2"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setReason("")}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cust-primary-btn font-14 font-medium"
                                                        onClick={reason && handleSaveDetails}
                                                        data-bs-dismiss="modal"
                                                        disabled={!reason}

                                                    >
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <p className="font-regular font-14 text-grey">
                                    # of Records:
                                    <span className="text-black font-medium">{jsonData.length} out of {response.length}</span>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MetaDataTable;