import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData } from '../Service/Api.js'; // Assuming API.js is in the same directory
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { HeaderCom } from './Header.jsx';
// import Editor from '@monaco-editor/react';
// import axios from 'axios'
function DataQualityPipeline(props) {

    // const nav = useNavigate();
    const { state } = useLocation();
    // let state = {
    //     "projectId": "4438CFB1-4357-4159-A0A2-ADF543561A47",
    //     "orgId": "7E7F8D66-316A-43AE-837E-9E7DCB1DF036",
    //     "userId": "3C862558-ED9A-4091-929A-07D31E09A304",
    //     jwt: "",
    //     projectName: "ADFTEST"
    // }

    const [setVal, setSetVal] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const [showFilter, setShowFilter] = useState(false)

    // const [filteredRecord, setFilteredRecord] = useState([]);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({

        dataflowName: 'Select', pipelineName: 'Select', tableName: 'Select'
    });
    const [newData, setNewData] = useState({
        RequirementID: "",
        DesignID: "",
        SNo: "",
        PipelineName: "",
        TableName: "",
        DataflowName: "",
        ComponentName: "",
        ComponentType: "",
        ParameterName: "",
        Configuration: "",
        PostComponentName: "",
        PostComponentType: "",
        SinkOrder: ""
    });

    const [action, setAction] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [editableRow, setEditableRow] = useState("empty");
    const [deleteRow, setDeleteRow] = useState("empty");
    const [reason, setReason] = useState('');
    const [sortOrder, setSortOrder] = useState({ order: 'desc', column: 'NotebookName' })

    useEffect(() => {
        fetchPseudo()
    }, [])

    async function fetchPseudo() {
         
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            projectId: state.projectId,
            type: "dataQualityPsueudocode",
            userId: state.userId,
            orgId: state.orgId,
        }

        const res = await fetchPseudocodeData(body, state.jwt);

        console.log(res, "fetchRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            // document.getElementById('openErrorModal').click();
        }
        else {
            setSetVal(JSON.parse(res.data[0].dataQualityPseudocode));
            setFilteredRecord(JSON.parse(res.data[0].dataQualityPseudocode))
        }
    };

    const sessionExpired = () => {
        localStorage.clear()
        // document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            // document.getElementById('toastMessage').style.display = "none"
            // nav('/');
        }, 3000);
    };

    const bindGrid = () => {
        console.log('binding json', filteredRecord);
        try {
            // Check if jsonData is an array and has elements
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord?.map((item, index) => {
                            return editableRow == index ? <tr>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='RequirementID' value={newData?.RequirementID || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DesignID' value={newData?.DesignID} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SNo' value={newData?.SNo || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PipelineName' value={newData?.PipelineName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='TableName' value={newData?.TableName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='DataflowName' value={newData?.DataflowName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentName' value={newData?.ComponentName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ComponentType' value={newData?.ComponentType || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='ParameterName' value={newData?.ParameterName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='Configuration' value={newData?.Configuration || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PostComponentName' value={newData?.PostComponentName || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='PostComponentType' value={newData?.PostComponentType || "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>
                                <td><input type="text" class="form-control cust-input-sty font-14 rounded-3 font-regular" placeholder="Enter" name='SinkOrder' value={newData?.SinkOrder|| "NA"} onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })} /></td>

                                <td class="text-center">
                                    <span class="cust-cursor-pointer" onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("edit"); document.getElementById('openReasson').click() } }}><img
                                        src="images/right.svg" class="me-3" width="15" height="15" /></span>
                                    <span class="cust-cursor-pointer" onClick={() => {
                                        setEditableRow("empty"); setNewData({
                                            RequirementID: "",
                                            DesignID: "",
                                            SNo: "",
                                            PipelineName: "",
                                            TableName: "",
                                            DataflowName: "",
                                            ComponentName: "",
                                            ComponentType: "",
                                            ParameterName: "",
                                            Configuration: "",
                                            PostComponentName: "",
                                            PostComponentType: "",
                                            SinkOrder: ""
                                        })

                                    }}><img
                                            src="images/wrong.svg" width="20" height="20" /></span>
                                </td>
                            </tr> : <tr key={index}>

                                <td>{item?.RequirementID || "NA"}</td>
                                <td>{item?.DesignID || ""}</td>
                                <td>{item?.SNo || "NA"}</td>
                                <td>{item?.PipelineName || "NA"}</td>
                                <td>{item?.TableName || "NA"}</td>
                                <td>{item?.DataflowName || "NA"}</td>
                                <td>{item?.ComponentName || "NA"}</td>
                                <td>{item?.ComponentType || "NA"}</td>
                                <td>{item?.ParameterName || "NA"}</td>
                                <td>{JSON.stringify(item?.Configuration) || "NA"}</td>
                                <td>{item?.PostComponentName || "NA"}</td>
                                <td>{item?.PostComponentType || "NA"}</td>
                                <td>{item?.SinkOrder || "NA"}</td>


                                <td className="text-center">
                                    <td class="text-center">
                                        <img src="images/blue-edit-icon.svg" alt="edit-icon" class="cust-cursor-pointer me-3" title="Edit" onClick={() => { setEditableRow(index); setNewData(filteredRecord[index]) }} />
                                        <img src="images/delete-icon.svg" alt="delete-icon" class="cust-cursor-pointer" title="Delete" onClick={() => { setDeleteRow(index); document.getElementById('openReasson').click(); setAction('delete') }} />
                                    </td>
                                </td>
                            </tr>
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    const handleApplyFilter = () => {
        console.log("Filter Criteria:", filterCriteria);

        const filterList = setVal.filter(record => {
            console.log("Record:", record);

            const dataFlowNameMatch = filterCriteria.dataflowName === 'Select' || record.DataflowName === filterCriteria.dataflowName;
            console.log("DataFlow Name Match:", dataFlowNameMatch);

            const pipelineNameMatch = filterCriteria.pipelineName === 'Select' || record.PipelineName === filterCriteria.pipelineName;
            console.log("Pipeline Name Match:", pipelineNameMatch);

            const tableNameMatch = filterCriteria.tableName === 'Select' || record.TableName === filterCriteria.tableName;
            console.log("Table Name Match:", tableNameMatch);

            return dataFlowNameMatch && pipelineNameMatch && tableNameMatch;
        });

        console.log("Filtered List:", filterList);

        setFilteredRecord(filterList);
        setIsFilterApplied(true);
    };

    const handleClearFilter = () => {
        console.log("Clearing Filter");

        setFilterCriteria({ dataflowName: 'Select', pipelineName: 'Select', tableName: 'Select' });
        setFilteredRecord(setVal);
        setIsFilterApplied(false);

        console.log("Filter Cleared:", setVal);
    };


    const handleSearch = (term) => {
         
        if (term.length >= 3) {
            // If searchTerm is greater than 0
            const filteredData = setVal.filter(item =>
                item.PipelineName.toLowerCase().includes(term.toLowerCase()) ||
                item.DataflowName.toLowerCase().includes(term.toLowerCase()) ||
                item.TableName.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredRecord(filteredData);
        } else {
            setFilteredRecord(setVal);
        }
    };



    const handleDownload = () => {
        let goldArray = setVal.map(data => {
            return {
                RequirementID: data.RequirementID || "",
                DesignID: data.DesignID || "",
                SNo: data.SNo || "",
                PipelineName: data.PipelineName || "",
                TableName: data.TableName || "",
                DataflowName: data.DataflowName || "",
                ComponentName: data.ComponentName || "",
                ComponentType: data.ComponentType || "",
                ParameterName: data.ParameterName || "",
                Configuration: data.Configuration || "",
                PostComponentName: data.PostComponentName || "",
                PostComponentType: data.PostComponentType || "",
                SinkOrder: data.SinkOrder || ""
            };
        });

        // Declare variables for file type and extension
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Convert DataFlowArray to sheet
        const ws = XLSX.utils.json_to_sheet(goldArray);

        // Create workbook object
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'gold');

        // Convert workbook to Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save data in browser memory with FileSaver.js
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'gold' + fileExtension);

        // The saved file will be shown to the user
    };



    const BindPipelineName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.PipelineName))];
            return unique.map((PipelineName, index) => (
                <option key={index} value={PipelineName}>{PipelineName}</option>
            ));
        }

    }
    const bindDataflowName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.DataflowName))];
            return unique.map((DataflowName, index) => (
                <option key={index} value={DataflowName}>{DataflowName}</option>
            ));
        }

    }
    const bindTableName = () => {
        if (setVal.length > 0) {
            const unique = [...new Set(setVal.map(setVal => setVal.TableName))];
            return unique.map((TableName, index) => (
                <option key={index} value={TableName}>{TableName}</option>
            ));
        }

    }

    const handleSort = (columnName) => {
        // When user clicks the sort icon
        // The function handleSort() is triggered along with the columnName as its parameter

        // Determine if the column being sorted is the same as the previously sorted column
        const isSameColumn = columnName === sortOrder.column;

        let newSortOrder;
        if (isSameColumn) {
            // If it's the same column, toggle the sorting order
            newSortOrder = { column: columnName, order: sortOrder.order === 'asc' ? 'desc' : 'asc' };
        } else {
            // Otherwise, set the sorting order to ascending
            newSortOrder = { column: columnName, order: 'asc' };
        }

        // Update the sortOrder state with the new column and sorting order
        setSortOrder(newSortOrder);

        // Create a copy of the filteredRecords
        let sortedRecords = filteredRecord.sort((a, b) => {
            if (newSortOrder.order === 'asc') {
                return a[columnName].localeCompare(b[columnName]);
            } else {
                return b[columnName].localeCompare(a[columnName]);
            }
        });

        // Sort the copied records based on the selected column and order

        // Update the filteredRecords state with the sorted list
        setFilteredRecord(sortedRecords);
    };


    const handleDelete = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "dataQualityPsueudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "delete",
            "comments": reason,
            "index": deleteRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            });
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
            setSetVal(JSON.parse(res.data.recordset[0].dataQualityPseudocode));
            setFilteredRecord(JSON.parse(res.data.recordset[0].dataQualityPseudocode))
        }
    };

    const handleEdit = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "dataQualityPsueudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "edit",
            "comments": reason,
            "value": newData,
            "index": editableRow
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            })
            setReason("")
            setEditableRow("empty")
            setwhetherUpdated(true)
            setSetVal(JSON.parse(res.data.recordset[0].dataQualityPseudocode));
            setFilteredRecord(JSON.parse(res.data.recordset[0].dataQualityPseudocode))
        }
    };

    const handleAdd = async () => {
        document.getElementById('pageLoader').style.display = "block"
        // Call the fetchPseudocodeData() function in API.js
        const body = {
            "projectId": state.projectId,
            "type": "dataQualityPsueudocode",
            "userId": state.userId,
            "orgId": state.orgId,
            "actionType": "add",
            "comments": reason,
            "value": newData
        }

        const res = await updatePseudocodeData(body, state.jwt);

        console.log(res, "updateRess");
        document.getElementById('pageLoader').style.display = "none"
        if (res.statusCode == 403) {
            sessionExpired();
        } else if (res.statusCode != 200) {
            document.getElementById('openErrorModal').click();
        } else {
            setNewData({
                RequirementID: "",
                DesignID: "",
                SNo: "",
                PipelineName: "",
                TableName: "",
                DataflowName: "",
                ComponentName: "",
                ComponentType: "",
                ParameterName: "",
                Configuration: "",
                PostComponentName: "",
                PostComponentType: "",
                SinkOrder: ""
            })
            setReason("")
            setwhetherUpdated(true)
            setSetVal(JSON.parse(res.data.recordset[0].dataQualityPseudocode));
            setFilteredRecord(JSON.parse(res.data.recordset[0].dataQualityPseudocode))
        }
    };


    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
                {/* header style starts here */}
             <HeaderCom value='1'/>
                {/* header style ends here */}
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a >{state.projectName}</a></li>
                            <li className="font-16 font-medium active">DataQuality pipeline</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div class="d-flex">
                            <a class="d-flex"><img src="images/back-arrow.svg" style={{ width: '24px', height: '24px' }}  alt="back-arrow" onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} /></a>
                            <h2 class="text-black font-bold font-22 mb-0 ms-3">DataQuality pipeline</h2>
                        </div>
                        <div class="d-flex">
                            <div class="input-group position-relative search-wid me-md-3">
                                <input type="search" class="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                    placeholder="Search" onChange={(e) => { handleSearch(e.target.value); setSearchTerm(e.target.value) }} />
                                <span class="search-icon-sty"><a><img src="images/search-icon.svg" alt="search icon" /></a></span>
                            </div>
                            <div className="btn-group me-3">
                            <button
                                className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => setShowFilter(true)}
                            >
                                <img
                                    src="images/filter-icon.svg"
                                    alt="filter-icon"
                                    className="filt-icon"
                                />
                            </button>
                            <div className={`dropdown-menu custom-filter shadow-sm border-0 p-3  ${showFilter == true ? 'show' : ''}`} style={{ transform: 'translate(-353px, 42px) !important;', width: '466px', float: 'right' }}>
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="font-18 font-bold text-black mb-0" >
                                            Filter
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setShowFilter(false)}
                                        />
                                    </div>
                                    <div className="row">



                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label text-black font-14 font-medium">
                                                    Pipeline Name
                                                </label>
                                                <select
                                                    className="form-select cust-input-sty font-14 font-regular"
                                                    aria-label="Default select example"
                                                    value={filterCriteria.pipelineName}
                                                    onChange={(e) => { setFilterCriteria({ ...filterCriteria, pipelineName: e.target.value }) }}>
                                                    <option value="Select">Select</option>
                                                    {BindPipelineName()}
                                                </select>
                                            </div>
                                        </div>
                                        
                                    <div className="col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="dataflowName"
                                                className="form-label text-black font-14 font-medium">
                                                DataFlow Name
                                            </label>
                                            <select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                aria-label="Default select example"
                                                value={filterCriteria.dataflowName}
                                                onChange={(e) => { setFilterCriteria({ ...filterCriteria, dataflowName: e.target.value }) }}>
                                                <option value="Select">Select</option>
                                                {bindDataflowName()}
                                            </select>
                                        </div>
                                    </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="dataflowName"
                                                className="form-label text-black font-14 font-medium">
                                                Table Name
                                            </label>
                                            <select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                aria-label="Default select example"
                                                value={filterCriteria.tableName}
                                                onChange={(e) => { setFilterCriteria({ ...filterCriteria, tableName: e.target.value }) }}>
                                                <option value="Select">Select</option>
                                                {bindTableName()}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="text-end mt-4 mb-2">
                                        <button
                                            type="button"
                                            className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                            onClick={() => handleClearFilter()}
                                        >
                                            Clear
                                        </button>
                                        <button
                                            type="button"
                                            disabled={filterCriteria.dataflowName === 'Select' && filterCriteria.pipelineName === 'Select' && filterCriteria.tableName === 'Select'}
                                            className="btn cust-primary-btn font-14 font-medium px-4"
                                            onClick={() => handleApplyFilter()}
                                        >
                                            Apply
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                            <button type="button" class="btn cust-secondary-btn font-16 font-medium " onClick={() => handleDownload()}>
                                <span class="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                       
                        {/* Accordion starts here */}
                        <div className="rounded-3  font-20 custom-acc-border font-bold mt-2">
                            <p className="d-flex justify-content-between color-white custom-accordion color-grey-bg  mb-0 p-2 rounded-3 d-flex align-items-center">
                                <button className="btn custom-accordian-btn me-3 d-inline-flex align-items-center w-100" type="button" data-bs-toggle="collapse" data-bs-target="#view-instruction">
                                    <img src="images/down-acc-arow.svg" alt="arrow" className="me-3" />
                                    <span data-bs-toggle="collapse" data-bs-target="#view-instruction" className="cursor-pointer pt-1 text-black font-16 font-medium">View Instructions</span>
                                </button>
                            </p>
                            <div className="row">
                                <div className="collapse show" id="view-instruction">
                                    <div className="custom-accordian-body color-grey-bg py-2 ms-5">
                                        <ul className="font-16 text-grey-v4 font-medium mb-0">
                                            <li className="mb-2">Please validate whether the data’s are correct</li>
                                            <li className="mb-2">If any changes are required update them accordingly using <span className="text-black-v2 font-bold">‘Actions’</span> on the last column</li>
                                            <li className="mb-2">If things are fine just click <span className="text-black-v2 font-bold">‘back’
                                            </span>then on <span className="text-black-v2 font-bold">‘Looks good’</span> and proceed to next steps
                                            </li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion sends here */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                {/*Metadata Table starts here*/}
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">RequirementID</th>
                                                <th className="text-nowrap">DesignID</th>
                                                <th className="text-nowrap">SNo</th>
                                                <th className="text-nowrap" onClick={() => handleSort('PipelineName')}>
                                                    PipelineName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PipelineName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PipelineName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('TableName')}>
                                                    TableName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "TableName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('TableName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('DataflowName')}>
                                                    DataflowName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "DataflowName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('DataflowName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ComponentName')}>
                                                    ComponentName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ComponentName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ComponentName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ComponentType')}>
                                                    ComponentType
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ComponentType" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ComponentType')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('ParameterName')}>
                                                    ParameterName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "ParameterName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('ParameterName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('Configuration')}>
                                                    Configuration
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "Configuration" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('Configuration')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('PostComponentName')}>
                                                    PostComponentName
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PostComponentName" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PostComponentName')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('PostComponentType')}>
                                                    PostComponentType
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "PostComponentType" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('PostComponentType')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" onClick={() => handleSort('SinkOrder')}>
                                                    SinkOrder
                                                    <span className="ms-2 cursor-pointer">
                                                        <img
                                                            src={sortOrder.column === "SinkOrder" && sortOrder.order === "asc" ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg"}
                                                            alt="sort-arrow-down"
                                                            onClick={() => handleSort('SinkOrder')}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-nowrap">Actions</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {editableRow === "empty" ? (
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter RequirementID"
                                                            name="RequirementID"
                                                            value={newData?.RequirementID}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter DesignID"
                                                            name="DesignID"
                                                            value={newData?.DesignID}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter SNo"
                                                            style={{marginRight:'65px'}}
                                                            name="SNo"
                                                            value={newData?.SNo}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PipelineName"
                                                            name="PipelineName"
                                                            value={newData?.PipelineName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter TableName"
                                                            name="TableName"
                                                            value={newData?.TableName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter DataflowName"
                                                            name="DataflowName"
                                                            value={newData?.DataflowName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ComponentName"
                                                            name="ComponentName"
                                                            value={newData?.ComponentName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ComponentType"
                                                            name="ComponentType"
                                                            value={newData?.ComponentType}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter ParameterName"
                                                            name="ParameterName"
                                                            value={newData?.ParameterName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter Configuration"
                                                            name="Configuration"
                                                            value={newData?.Configuration}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PostComponentName"
                                                            name="PostComponentName"
                                                            value={newData?.PostComponentName}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter PostComponentType"
                                                            name="PostComponentType"
                                                            value={newData?.PostComponentType}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                                            placeholder="Enter SinkOrder"
                                                            name="SinkOrder"
                                                            value={newData?.SinkOrder}
                                                            onChange={(e) => setNewData({ ...newData, [e.target.name]: e.target.value })}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <img
                                                            src="images/add-icons.svg"
                                                            alt="add-icon"
                                                            className="cust-cursor-pointer"
                                                            title="Add"
                                                            onClick={() => { if (Object.values(newData).every(value => value !== '')) { setAction("add"); document.getElementById('openReasson').click() } }}
                                                        />
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                    {/*Metadata Table ends here*/}
                                </div>
                                <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredRecord.length}</span></p>
                                <div>
                                    {/* Button trigger modal */}
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" id='openReasson' data-bs-target="#editRows" hidden>
                                        Confirmation Popup
                                    </button>
                                    {/* Modal */}
                                    <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Edit Reason</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="editpopupclose" />
                                                </div>
                                                <div className="modal-body py-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">Specify reason for manual edit
                                                                <span className="text-red"> *</span></label>
                                                            {/* <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5} defaultValue={""} value={reason} onChange={(e) => setReason(e.target.value)} /> */}
                                                            <textarea type="text" className="form-control font-14 font-regular " id="User-Name" placeholder="Enter reason" rows={5}  value={reason} onChange={(e) => setReason(e.target.value)} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 px-4 mt-4">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" disabled={reason != "" ? false : true} onClick={() => { if (action == "add") { handleAdd() } else if (action == "edit") { handleEdit() } else if (action == "delete") { handleDelete() } }}>
                                                        <span>Save Details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataQualityPipeline;