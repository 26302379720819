import { Client } from '../Service/Client'
import {ExcelClient} from '../Service/ExcelClient'
import axios from 'axios'



export async function generateMetadata(body, jwt) {

    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateMetaData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateExtractionRequirementData(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateExtractionRequirementData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateGoldDevCode(body, jwt) {
    console.log(body)
    
    return Client('https://genetlnpapi.asami.ai/cg/generateGold', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateChecklistDesign (body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateChecklistDesign', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateChecklistDev(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateCheckListDev', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateDataDictionaryPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateDataDictionary', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDDL(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDDL', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDDLPseudocode(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDDLPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateExtractionPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateExtractionPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateGoldPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateGoldPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateBronze(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateBronze', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSilverSequence(body, jwt) {
    
    return Client('https://genetlnpapi.asami.ai/cg/generateSilverSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateGoldSequence(body, jwt) {
    
    return Client('https://genetlnpapi.asami.ai/cg/generateGoldSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function genrateDQualityCode(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDataQualityCode', { 
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}
 
export async function generateBronzeSequence(body, jwt) {
    
    return Client('https://genetlnpapi.asami.ai/cg/generateBronzeSequence', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateDataModelGoldUML(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateGoldDataModel', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDataModelSilverUML(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateSilverDataModel', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateDataQualityPseudo(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/cg/generateDataQualityPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateBronzeToSilverER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateBronzeToSIlver', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateLoggerPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateLoggerPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSilver(body, jwt) {   
    return Client('https://genetlnpapi.asami.ai/cg/generateSilver', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUtility(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUtility', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUtilityPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUtilitiesPseudo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateOrchestrationUMLCode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateOrchestrationUmlCode', {       
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateLoggerAndPipelineJSON(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateLoggerAndPipelineCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateMetadataTablePseudo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateMetadataPseudoCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateOrchestrationDocument(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateOrchestrationDocument', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generatePipelineSummaryPseudo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generatePipelineSummary', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateTransformationPseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateTransformationPseudocode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateTestCaseCode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateTestCaseCode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateUnitTestCasePseudocode(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateUnitTestCasePseudocode', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function generateSourceToExtractionER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateSourceToExtractionER', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function generateSilverToGoldER(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/cg/generateSilverToGoldER', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////












////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//meta

export const deleteDBRecords = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/meta/deleteDbRecords",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}



export async function getNotifications(role,userId,orgId, jwt) {
    return Client("https://genetlnpapi.asami.ai/meta/fetchNotifications",
        {
            method: 'POST',
            body: { role,userId ,orgId},
            headers: {
                "authorization": jwt
            }
        })
}

export async function getManageRequets(userId, JWT, adRole) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRequests', {
        method: 'POST',
        body: { userId, adRole }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": JWT
        }
    });
}


export async function download(userId, JWT, adRole,phase, futureState, methodology, type, requestId) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRequests', {
        method: 'POST',
        body: { userId, adRole,phase, futureState, methodology, type, requestId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": JWT
        }
    });
}


export async function getManageRoles(userId, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/getManageRoles', {
        method: 'POST',
        body: { userId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function getMetaDataValues(projectId, jwt) {
    return Client('https://genetlnpapi.asami.ai/meta/getMetaValues', {
        method: 'POST',
        body:  projectId ,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function postManageRequests(requestId,status,projectId, phase,userId,  jwt, orgId, ) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/postManageRequests', {
        method: 'POST',
        body: { projectId, userId, requestId, orgId, status, phase }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function putManageRoles(body, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/putManageRoles', {
        method: 'POST',
        body:  body , //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function updateManageRoles(userId,adUserId, jwt) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/meta/updateManageRoles', {
        method: 'POST',
        body:  {userId,adUserId}, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export async function updateMetaValues(projectId, TableID, selectedItem, metaDataNotebookedit, userId, jwt) {
    return Client('https://genetlnpapi.asami.ai/meta/updateMetaValues', {
        method: 'POST',
        body: { projectId, TableID, selectedItem, userId, metaDataNotebookedit },
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}
export const fetchDesignDetails = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/meta/fetchDesignDetails",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}




//////////////////////////////////////////////////////////////////////////////////////////////////////
//adorg

export const getLogin = (data) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/checkUser",
        { method: 'POST', body: data },
    )

}
export async function adUsers(userId, jwt, accessToken) {
    return Client("https://genetlnpapi.asami.ai/adorg/aduser",
        {
            method: 'POST',
            body: { userId, accessToken },
            headers: {
                "Content-Type": "application/json",

                "authorization": jwt
            }
        })
}

export async function projectsDropDown(userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/projectDropDown",        {
            method: 'POST',
            body: { userId },
            headers: {
                "authorization": jwt
            }
        })
}





export const getAuth = (data) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/getClient",
        { method: 'POST', body: data },
    )

}

export async function DefineDataSave(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/adorg/defineDataSave', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function editOrganization(orgId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/editOrganization",
        {
            method: 'POST',
            body: { userId, orgId },
            headers: {
                "authorization": jwt
            }
        })
}


export async function getOrganization(userId, adRole, jwt,body) {
    //PS_CD_1.104
    return Client('https://genetlnpapi.asami.ai/adorg/getOrganization', {
        method: 'POST',
        body: { userId, adRole,body }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export const getProjectInfo = (data, jwt) => {
    return Client(
       "https://genetlnpapi.asami.ai/adorg/getProjectInfo",        {
            method: 'POST',
            body: data,
            headers:
            {
                "Content-Type": "application/json",
                "authorization": jwt
            }
        },
    )
}

export const getProjectName = (userId,jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/getProjectName" ,       {
            method: 'POST',
            body:{userId},
            headers: {
                "authorization": jwt
            }
        }
    )
}

/**PS_CD_1.982-1.986
 * The function `postDesignDetails` sends a POST request to a specified API endpoint with provided data
 * and JWT authorization.
 * @param data - The `data` parameter in the `postDesignDetails` function is the information or payload
 * that you want to send in the POST request to the specified API endpoint. This data could be in the
 * form of an object, string, or any other format that needs to be sent to the server for processing
 * @param jwt - JWT (JSON Web Token) is a type of token used for authentication and authorization in
 * web applications. It is typically sent in the Authorization header of HTTP requests to authenticate
 * the user. In this case, the `jwt` parameter is the JWT token that will be used for authorization
 * when making a POST request
 * @returns The `postDesignDetails` function is returning the result of calling the `Client` function
 * with the specified parameters, which includes making a POST request to
 * "https://genetlnpapi.asami.ai/api/postDesignDetails" with the provided data and JWT token in the headers.
 */
export const postDesignDetails = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/postDesignDetails",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export async function getProjects(orgId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/adorg/getProjects", {
        method: 'POST',
        body: { orgId, userId }, //
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}


export const insertError = async (data) => {
    const config = {
        method: 'POST',
        url: `https://genetlnpapi.asami.ai/adorg/insertError`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        await axios(config);
    } catch (error) {

    }
}


export async function isActiveOrganization(orgId, isActive, userId, jwt) {
    //PS_CD_1.96
    return Client('https://genetlnpapi.asami.ai/adorg/isActiveOrganization', {
        method: 'POST',
        body: { orgId, isActive, userId },
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export async function isActiveProjects(projectId, isActive, userId, jwt) {
    return Client('https://genetlnpapi.asami.ai/adorg/isActiveProjects', {
        method: 'POST',
        body: { projectId, isActive, userId },
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt
        }
    });
}

export const postDefineDetails = (data, jwt) => {
    return Client(
"https://genetlnpapi.asami.ai/adorg/postDefineDetails", {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export async function postOrganization(isEdited, formData, users, orgId, jwt) {
    console.log("API called")
    //PS_CD_1.108
    return Client('https://genetlnpapi.asami.ai/adorg/postOrganization', {
        method: 'POST',
        body: { isEdited, formData, users, orgId }, // <-- Remove this object wrapper
        headers: {
            "Content-Type": "application/json",
            "authorization": jwt,
        },
    });
}


export const postProjectInfo = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/adorg/postProjectInfo"   ,     {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}











////////////////////////////////////////////////////////////////////////////////////////////////////
//note 


export async function targetSystemValidation(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/checkDatabricksCreds', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getChatHistory(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/getChatHistory', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}

export async function getSourceSystemConnectioninfo(projectId, userId, jwt) {
    return Client("https://genetlnpapi.asami.ai/note/getSourceSystemConnectioninfo",
        {
            method: 'POST',
            body: { projectId, userId },
            headers: {
                "authorization": jwt
            }
        })
}


export async function getAuditLog(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/fetchAuditLogs', {
        method: 'POST',
        body:  body ,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getValidated(body, userId, jwt) {
    return ExcelClient('https://genetlnpapi.asami.ai/note/defineExcelValidator', {
        requestType: 'POST',
        body: { body, userId },
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt
        },
    });
}

export async function deployNotebook(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/deployNotebooks', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function getCodePageDataApi(body, jwt) {
    console.log(body)
    return Client('https://genetlnpapi.asami.ai/note/fetchCodeData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const fetchDropDownValues = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/fetchDropDownValues",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export async function postSourceSystemConnectioninfo(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/postSourceSystemConnectioninfo', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const updatePseudocodeData = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/UpdatePseudocodeData",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export const fetchPseudocodeData = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/fetchPseudocodeData",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export async function postChatHistory(body, jwt) {
    return Client('https://genetlnpapi.asami.ai/note/postChatHistory', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export async function postCodePageDataApi(body, jwt) {
    console.log("update", body)
    return Client('https://genetlnpapi.asami.ai/note/updateCodeData', {
        method: 'POST',
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'authorization': jwt,
        },
    });
}


export const getHubspot = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getHubsSpot",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getMySQL = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getMySQL",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getMsSQL = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getMsSQL",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getOracle = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getOracle",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getPostgres = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getPostgres",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getRedshift = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getRedshift",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}



export const getSalesforce = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getSalesforce",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}


export const getSnowflake = (data, jwt) => {
    return Client(
        "https://genetlnpapi.asami.ai/note/getSnowflake",
        {
            method: 'POST',
            body: data,
            headers:
            {
                "authorization": jwt
            }
        },
    )
}

export const generateDataflow = (data, jwt) => {
   
}
 
 
 
 
 
export const generateDatasetsPseudocode = (data, jwt) => {
   
}
 
export const generateDataflowPseudocode = (data, jwt) => {
   
}
 
 
export const generateLinkedServicePseudocode = (data, jwt) => {
   
}