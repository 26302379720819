import { useLocation, useNavigate } from 'react-router-dom';
//Import the nescessary modules and the packages
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import DynamicFileUpload from './dynamicFileUpload';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { encode, decode } from "base-64"
import { HeaderCom } from '../Header';
import { dwhfetchJsonFromBlob ,dwhupdateChatHistoryToBlob ,dwhupdateJsonInBlob , putJsonToBlob1} from '../new/BlobStorageService';
import { DWHinventorySummary, fileStructureAPI, generateDWHbronze, generateDWHcodereviewChecklist, generateDWHddlScripts, generateDWHgold, generateDWHMetaCode, generateDWHsilver, generateMappingData, generateSasTokenAPI ,generateSemanticModel,generateStaticUrls,getCodePageDataApi } from '../../Service/Api';
import { InitSourceSystem } from './initSourceystem';
import SourceInfoPopup from './ingestSourcePopup';
import { DesignValidation ,DesignValidation2} from './contrains';
import DynamicFileUploadPopup from '../new/DynamicFileUploadPopup';
import MetaDataTable from './metadatagrid';
import CodeViewPage from '../new/CodeViewPageDynamic';
import DwhCodeViewPage from './dwhCodeviewPage';
import MappingGrid from './mappings';
import CodeReviewChecklistDev from './dwhCodeReviewCheckList';
import DeployDWHCode from './deployPopUp';
import InventoryGrid from './dwhInventory';
import { templateURLs } from './contrains';
import ViewInstructions from './viewDesignInfo';
const ChatMaindwh = () => {

var { state } = useLocation();

const navigate = useNavigate()
 const [isPopupOpen, setPopupOpen] = useState(false);
    const [isTableMappingPopupOpen, setTableMappingPopupOpen] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [validationColumns, setValidationColumns] = useState([]);
    const [uploadedData, setUploadedData] = useState(null);
    const [tableuploaddetails, settableuploaddetails] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [showLooksGood, setShowLooksGood] = useState(false)
    const [showCode, setShowCode] = useState(false);
    // const[isTableEdited,setIsTableEdited] = useState(false);
    const [sourcePhase, setSourcePhase] = useState('')
    const [codeDataValues, setCodeDataValues] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const matchesRef = useRef([]);
    const [searchText, setSearchText] = useState('');
    const [dataQualityResultFile, setDataQualityResultFile] = useState('');
    const [unitTestResultFile, setUnitTestResultFile] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [isSourceToTraget, setIsSourceToTarget] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [accessControlShow, setAccessControlShow] = useState(false);
    const [templateURL, setTemplateURL] = useState('');
    const [fileUploaded, setFileUploaded] = useState('');
    
    const [documentName, setDocumentName] = useState('');
    const [gridName, setGridName] = useState('');
    var { state } = useLocation();
    
    const [popUpShow, setpopUpShow] = useState(false);
    const [popupName, setPopupName] = useState(false);
    //popups
    const [filePopup, setfilePopup] = useState(false);
    const [notebookPopup, setNotebookPopup] = useState(false);
    const [dynamicFileUploadPopup, setDynamicFileUploadPopup] = useState(false);
    const [unitTestcasePopup, setUnitTestcasePopup] = useState(false);
    const [dataQualityPopup, setDataQualityPopup] = useState(false);
    const [currentChatHistory, setCurrentChatHistory] = useState(false); // Initialize with false
    const [error, setError] = useState([]);
    const [confirmedTablePopup, setConfirmedTablePopup] = useState(false);
    const [showSourceProgress, setshowSourceProgress] = useState(false);
    const [showdmdetails, setshowdmdetails] = useState(false);
    const [showrelationships, setshowrelationships] = useState(false);
    const [initTabDisabled, setinitTabDisabled] = useState(false);
    const [ingestTabDisabled, setingestTabDisabled] = useState(false);
    const [showDax, setShowDax] = useState(false);
    const [deployPopup, setDeployPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);   
         const [blobUrl, setBlobUrl] = useState("");
         const [blobUrls, setBlobUrls] = useState("");
         const [selectedCatalog, setSelectedCatalog] = useState("");
         
         const [datawarehousePopup, setDatawarehousePopup] = useState(false);
         const [selectedTab, setSelectedTab] = useState("init");


         const closePopup = () => {
            setpopUpShow(false);
        };

        const closeGrid = () => {
            setShowCode(false);
        };



        const gridUpdated = (notebookName) => {
            // Create a duplicate of the current chatHistory to manipulate
            let tempChatHistory = [...chatHistory];
        
            // Find the index of the edited notebook
            const index = tempChatHistory.findIndex((item) => item.notebookName === notebookName);
        
            // If an entry is found, remove all subsequent entries
            if (index !== -1) {
                tempChatHistory = tempChatHistory.slice(0, index + 1);
            }

            tempChatHistory[tempChatHistory.length - 1].show = false;
            tempChatHistory[tempChatHistory.length - 1].isAction = true;
            setChatHistory(tempChatHistory)
            // Update the chatHistory state with the truncated list
            // setChatHistory(tempChatHistory);
        
            // Don't forget to ensure the edited notebook and any preceding states are not removed unintentionally
            dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab + "/chatHistory.json", tempChatHistory, "projectfiles");
        };

    const bindLooksGood = async (notebookName , type ) => {
            const data = [...chatHistory];
            data[data.length - 1].show = false;
            data[data.length - 1].isAction = true;
            setChatHistory(data)
            dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", data, "projectfiles");
        }



    const savedeployment = async  (data,status,userInputs)=>{
        
            if(status==200){
    
                chatHistory[0].deployDetails=userInputs
                const newChat = [
                    ...chatHistory,
                    { flow: "user", type: "text", text: "Target System Details" },
                    { flow: "ai", type: "text", text: `Congratulations! You’ve successfully deployed`}
                ];
                
        
                setChatHistory(newChat);
                console.log(newChat, 'newChat');
        
                const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            }
            else{
                const newChat = [
                    ...chatHistory,
                    { flow: 'ai', type: 'text', text: 'Oops! Seems that you haven’t provided correct credentials' },
                    { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
                ];
                setChatHistory(newChat);
                console.log(newChat, 'newChat');
        
                const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, 'projectfiles');
            }
            }



  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const result = {};

            workbook.SheetNames.forEach(sheetName => {
                const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                if (sheet.length) {
                    const headers = sheet[0];
                    const transformedData = sheet.slice(1).map(row => {
                        const rowData = {};
                        row.forEach((cell, i) => {
                            rowData[headers[i]] = cell;
                        });
                        return rowData;
                    });
                    result[sheetName] = transformedData;
                } else {
                    result[sheetName] = [];
                }
            });

            console.log(result, 'resultresult');

            resolve(result);
        };

        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}


const saveFunction = async (file, text) => {
    try {
        document.getElementById("pageLoader").style.display = "block";
        closePopup()
        console.log(file, text, 'filefilefilefilefilefile');
        // Read the Excel file
        var data = await readExcelFile(file);
        // Convert the data to JSON string
        const jsonString = JSON.stringify(data, null, 2);
        // If you want to parse it back into an object (not necessary here)
        data = JSON.parse(jsonString);
        console.log('Parsed Data:', data);

        switch (text) {
           
            case "Please upload Design Document":
                const combinedData = {
                    "DDLScripts-Bronze": [],
                    "DDLScripts-Silver": [],
                    "DDLScripts-Gold": []
                };
                const combinedData1 = {
                    "SourceToBronzeMapping": [],
                    "BronzeToSilverMapping": [],
                    "SilverToGoldMapping": []
                };
                let notebookies={
                    "bronze": [],
                    "silver": [],
                    "gold": []
                    }

                // if(selectedTab == 'init'){
                //     initchatHistory[0]["bronzeNotebooks"]=
                // }
                let blobpath=''  
                let OrgID=state.orgId
                let ProjectID=state.projectId
                let migrationType1=selectedTab
                let blobpath2=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/DDLScripts.json`
                let blobpath3=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/mappings.json`


                for (const [sheetName, sheetData] of Object.entries(data)) {
                    
                    // Combine specific sheets into one JSON object
                    if (sheetName === "DDLScripts-Bronze" || sheetName === "DDLScripts-Silver" || sheetName === "DDLScripts-Gold") {
                        const keyName = sheetName
                        combinedData[keyName] = sheetData;
                        
                    }
                    if (sheetName === "SilverToGoldMapping" || sheetName === "BronzeToSilverMapping" || sheetName === "SourceToBronzeMapping") {
                        const keyName = sheetName
                        combinedData1[keyName] = sheetData;
                        
                    }
                        else if(sheetName=="DataDictionary-Silver") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/DataDictionarySilver.json`
                        await putJsonToBlob1(sheetData,blobpath);
                    }
                    else if(sheetName=="DataDictionary-Gold") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/DataDictionaryGold.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="Extraction_Notebook") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/bronzeNoteBooks.json`
                        notebookies.bronze = [...new Set(sheetData.map(item => item.NotebookName))];
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                    else if(sheetName=="Silver_Notebook") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/silverNoteBooks.json`
                        notebookies.silver = [...new Set(sheetData.map(item => item.NotebookName))];
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="Gold_Notebook") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/goldNoteBooks.json`
                        notebookies.gold = [...new Set(sheetData.map(item => item.NotebookName))];
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="MetadataTable") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/metadata.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="UtilitiesNotebook") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/utility.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="LoggerNotebook") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/logger.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="UnitTesting") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/unitTestCase.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="DataQuality") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/dataQuality.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }
                        else if(sheetName=="CodeReviewChecklist") {
                        blobpath=`dwhmigration/${OrgID}/${ProjectID}/uploads/${migrationType1}/codeReviewChecklist.json`
                        await putJsonToBlob1(sheetData,blobpath);
                        }

                }
                    // Save each individual sheet under its own path
                chatHistory[0]['bronzeNotebookNames']=notebookies.bronze || []
                chatHistory[0]['silverNotebookNames']=notebookies.silver || []
                chatHistory[0]['goldNotebookNames']=notebookies.gold || []
                chatHistory[0]["fileName"]=file.name
                
                setChatHistory(chatHistory)
                const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", chatHistory, "projectfiles");


                await putJsonToBlob1(combinedData,blobpath2);
                await putJsonToBlob1(combinedData1,blobpath3);
                document.getElementById("pageLoader").style.display = "none";
          
                await generatemetagrid()
    
                   
                break;
                
            
            
            default:
                console.warn("Unknown upload type:", text);
                break;
        }

        
        

    } catch (error) {
        console.error("Error saving file:", error);
    }
};

    


    function goDown() {
        const divElement = document.getElementById("Imdown");
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth" });
        }
    }


useEffect(() => {
        fetchChatHistory()
    }, [selectedTab])




    const fetchChatHistory = async () => {
            let newPro = sessionStorage.getItem("newPro");
            if (newPro == "true") {
                sessionStorage.removeItem("newPro");
                document.getElementById("protoastMessage").style.display = "block";
                setTimeout(() => {
                    document.getElementById("protoastMessage").style.display = "none";
                }, 3000);
            } else {
                state.isEdit = true;
            }
            document.getElementById("pageLoader").style.display = "block";
            try {
                let fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
    
                if (fetchedData) {
    
                    if (fetchedData?.filter(a => a?.flow === 'error')?.length !== 0) {
                        fetchedData = fetchedData.filter((a) => a?.flow !== 'error')
                        dwhupdateChatHistoryToBlob(state.orgId, state.projectId, fetchedData, selectedTab+"/chatHistory.json", "projectfiles");
    
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    }
                    setChatHistory(fetchedData);
    
                    const hasTableMapping = fetchedData.some(obj => obj.notebookName === "tableMapping");
                    setAccessControlShow(hasTableMapping);
    
                    const unitTestFile = fetchedData.find(obj => obj.hasOwnProperty('UnitTestResultFileName'));
    
                    if (unitTestFile) {
                        setUnitTestResultFile(unitTestFile.UnitTestResultFileName);
                    }
                    else {
                        setUnitTestResultFile('');
                        setFileUploaded('')
                    }
                    const dataQualityFile = fetchedData.find(obj => obj.hasOwnProperty('DataQualityResultFileName'));
                    if (dataQualityFile) {
                        setDataQualityResultFile(dataQualityFile.DataQualityResultFileName);
                    }
                    else {
                        setDataQualityResultFile('');
                        setFileUploaded('')
                    }
                    document.getElementById("pageLoader").style.display = "none";
    
                    setTimeout(() => { goDown() }, 1000);
                }
                // return fetchedData; // Return the fetched data if needed
            } catch (error) {
                console.error("Error fetching chat history:", error);
                // Handle the error appropriately, maybe return null or an empty array
                return null; // or return [];
            }
        };

    
        
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && matchCount > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchCount;
            setCurrentMatchIndex(nextIndex);
            scrollToMatch(matchesRef.current[nextIndex]);
        }
    };
    const scrollToMatch = (match) => {
        if (match && typeof match.hyperlinkText === 'string') {
            const divElement = document.getElementById("search-" + match.hyperlinkText);
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const letStart = async () => {
        try {
            var notebookName=""
            var referenceDocuments=["Summary","Table Inventory",'Current State Data Dictionary',"Subject Areas","Future State Data Dictionary"]                    
            if(selectedTab=='init'){
            chatHistory[chatHistory.length - 1].show = false;
 
            const newChat = [
                ...chatHistory,
                { flow: "user", type: "text", text: "Let's start" },
                { flow: "ai", type: "inventoryDatawarehouseInstructions", docName: notebookName, referenceDocument: referenceDocuments, show: true },                
                { flow: 'ai', type: 'datawarehouseDetails',show:true , selectedTab:"init"}
            ];
            setChatHistory(newChat);
            console.log(newChat, 'newChat');
            const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, 'projectfiles');
            console.log(blobJson, 'blobJson');
        }
        else{
            var referenceDocuments=["Summary","Table Inventory",'Current State Data Dictionary',"Subject Areas","Stored Procedures","Future State Data Dictionary"]  
                chatHistory[chatHistory.length - 1].show = false;
                const newChat = [
                    ...chatHistory,
                    { flow: "user", type: "text", text: "Let's start" },
                    { flow: "ai", type: "inventorySourceInstructions", docName: notebookName, referenceDocument: referenceDocuments, show: true }, 
                    { flow: 'ai', type: 'sourceDetails',show:true , selectedTab:"ingest"}
                ];
                setChatHistory(newChat);
                console.log(newChat, 'newChat');
                const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, 'projectfiles');
                console.log(blobJson, 'blobJson');
        }
        } catch (error) {
            console.error('Error updating chat history:', error);
        }
    };



    const handleSearch = useCallback((newSearchText) => {
            setSearchText(newSearchText);
            if (newSearchText) {
                const matches = chatHistory.reduce((acc, item) => {
                    if (item?.hyperlinkText && typeof item.hyperlinkText === 'string' &&
                        item.hyperlinkText.toLowerCase().includes(newSearchText.toLowerCase())) {
                        // Check if this hyperlink text is already in the accumulator
                        if (!acc.some(match => match.hyperlinkText === item.hyperlinkText)) {
                            acc.push(item);
                        }
                    }
                    return acc;
                }, []);
    
                matchesRef.current = matches;
                setMatchCount(matches.length);
                setCurrentMatchIndex(0);
                if (matches.length > 0) {
                    scrollToMatch(matches[0]);
                }
            } else {
                matchesRef.current = [];
                setMatchCount(0);
                setCurrentMatchIndex(0);
            }
        }, [chatHistory]);

   


    async function proceedToDeploy(){
    
                try {
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Looks Good" },
                        { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", newChat, "projectfiles");
                    
                } catch (error) {
                    // console.error(`Error generating ${noteName}:`, error);
                }
    
            }


    const sessionExpired = () => {
        // Display Session expired toast message (Assuming you have a toast notification component)
        // toast.error('The session has expired.Please login again', { autoClose: 3000 });
        localStorage.clear();
        document.getElementById("toastMessage").style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById("toastMessage").style.display = "none";
            navigate("/");
        }, 3000);
    };


    const accountName = 'avaeusgenetlsametadev';
const containerName = 'uc-migration';

// Function to trigger download
const triggerDownload = (content, fileName, fileType) => {
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
};

// Function to fetch JSON from Blob source
const dwhfetchJsonFromBlobsource = async (url) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const urlWithToken = `${url}?${sasToken}`;
        const response = await fetch(urlWithToken);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let jsonData;
        try {
            jsonData = await response.json();
        } catch (jsonError) {
            console.warn('Failed to parse JSON, falling back to text', jsonError);
            const textData = await response.text();
            jsonData = textData;
        }
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};


const downloadResponse = async (data) => {

    const fetchJsonFromURL = async (URLToFetch) => {
        try {
            // Generate SAS token
            const sasToken = await generateSasTokenAPI();
            const url = `${URLToFetch}?${sasToken}`;
    
            // Fetch the URL
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Read and parse JSON from response directly
            const jsonData = await response.text();
            console.log(jsonData, 'Fetched JSON Data');
    
            // Return the parsed JSON data
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    const { notebookName, migrationType, notebookType } = data;
    const orgId = state.orgId;
    const projectId = state.projectId;
    let url = '';
debugger
    const baseUrl = `https://${accountName}.blob.core.windows.net/${containerName}/dwhmigration/${orgId}/${projectId}`;

    if (migrationType === 'init') {
        switch (notebookName) {
            case 'MappingDocument':
                url = `${baseUrl}/development/init/mappings.json`
                break;
            case 'InventoryDocument':
                url = `${baseUrl}/uploads/init/inventory.json`;
                break;
            case 'inventorySummary':
                url = `${baseUrl}/development/init/inventorySummary.json`;
                break;
            case 'DDLScriptsCode':
                url = `${baseUrl}/development/init/DDLScripts.sql`;
                break;
            case 'metadata':
                url = `${baseUrl}/development/init/metadata.json`;
                break;
            case 'UtilitiesCode':
                url = `${baseUrl}/development/init/utilityCode.py`;
                break;
            case 'LoggerCode':
                url = `${baseUrl}/development/init/loggerCode.py`;
                break;
            case 'UnitTestCase':
                url = `${baseUrl}/development/init/unitTestCaseCode.py`;
                break;
            case 'dataQuality':
                url = `${baseUrl}/development/init/dataQualityCode.py`;
                break;
            case 'MetadataDocument':
                url = `${baseUrl}/development/init/metadata.json`;
                break;
            case 'MetaDataCode':
                url = `${baseUrl}/development/init/metadataCode.sql`;
                break;
            case 'UtilitiesCode':
                url = `${baseUrl}/development/init/utilityCode.py`;
                break;
            case 'CodeReviewchecklist':
                url = `${baseUrl}/development/init/codeReviewChecklist.json`;
                break;
            default:
                if (notebookType === 'Bronze') {
                    url = `${baseUrl}/development/init/${notebookName}.py`;
                } else if (notebookType === 'Silver') {
                    url = `${baseUrl}/development/init/${notebookName}.py`;
                } else if (notebookType === 'Gold') {
                    url = `${baseUrl}/development/init/${notebookName}.py`;
                } else {
                    url = `${baseUrl}/development/init/${notebookName}.py`;
                }
                break;
        }
    } else if (migrationType === 'ingest') {
        switch (notebookName) {
            case 'MappingDocument':
                url = `${baseUrl}/development/ingest/mappings.json`;
                break;
            case 'DDLScriptsCode':
                url = `${baseUrl}/development/ingest/DDLScripts.sql`;
                break;
            case 'metadata':
                url = `${baseUrl}/development/ingest/metadata.json`;
                break;
            case 'UtilitiesCode':
                url = `${baseUrl}/development/ingest/utilityCode.py`;
                break;
            case 'LoggerCode':
                url = `${baseUrl}/development/ingest/loggerCode.py`;
                break;
            case 'UnitTestCase':
                url = `${baseUrl}/development/ingest/unitTestCaseCode.py`;
                break;
            case 'dataQuality':
                url = `${baseUrl}/development/ingest/dataQualityCode.py`;
                break;
            case 'CodeReviewchecklist':
                url = `${baseUrl}/development/ingest/codeReviewChecklist.json`;
                break;
            case 'MetadataDocument':
                url = `${baseUrl}/development/init/metadata.json`;
                break;
            default:
                if (notebookType === 'bronze') {
                    url = `${baseUrl}/development/ingest/${notebookName}.py`;
                } else if (notebookType === 'Silver') {
                    url = `${baseUrl}/development/ingest/${notebookName}.py`;
                } else if (notebookType === 'Gold') {
                    url = `${baseUrl}/development/ingest/${notebookName}.py`;
                } else {
                    url = `${baseUrl}/development/ingest/${notebookName}.py`;
                }
                break;
        }
    }

    if (url) {
        try {
            if(!["MappingDocument","InventoryDocument" ,"MetadataDocument" ,"inventorySummary" ,"CodeReviewchecklist"].includes(notebookName))
                {
            const jsonData = await fetchJsonFromURL(url);
            const fileType = (notebookName === 'mappings' || notebookName === 'metadata') ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/python';
            triggerDownload(jsonData, `${notebookName}.${fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'py'}`, fileType);
        }
        else{
            const fetchJsonFromBlob = async (inventoryDDURL) => {
                try {
                  const sasToken = await generateSasTokenAPI();
                  const url = `${inventoryDDURL}?${sasToken}`;
                  const response = await fetch(url);
                  const jsonData = await response.json();
                  return jsonData;
                } catch (error) {
                  console.error("Error fetching JSON from blob:", error);
                  throw error;
                }
              };
            if(notebookName=="InventoryDocument"){
                
                  var gridData=await fetchJsonFromBlob(url)
                  console.log(gridData,)
                  const createAndDownloadExcel = (data, filename = "inventoryGrid.xlsx") => {
                    const worksheet = XLSX.utils.json_to_sheet(data);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "inventory");
                    XLSX.writeFile(workbook, filename);
                };
            
                var download = createAndDownloadExcel(gridData.TableInventory);
            }
            else if (notebookName=="inventorySummary"){
                // var gridData=await fetchJsonFromBlob(url)
                console.log(gridData,)
                const createAndDownloadExcel = (data, filename = "inventorySummary.xlsx") => {
                  const worksheet = XLSX.utils.json_to_sheet(data);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "inventorySummary");
                  XLSX.writeFile(workbook, filename);
                }
                var download = createAndDownloadExcel([data.summary]);
            }
            if(notebookName=="MetadataDocument"){

                const fetchJsonFromBlob = async () => {
                        try {
                            const sasToken = await generateSasTokenAPI();
                            const blobPath = `dwhmigration/${state.orgId}/${state.projectId}/uploads/${selectedTab}/metadata.json`;
                            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
                            var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
                            console.log(url2, 'this is the url for fetch')
                            const response = await fetch(url);
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                    
                            const jsonData = await response.json();
                            console.log(jsonData, 'Fetched JSON Data');
                            return jsonData;
                        } catch (error) {
                            console.error('Error fetching JSON from blob:', error);
                            throw error;
                        }
                    };
                
                var gridData=await fetchJsonFromBlob(url)
                console.log(gridData,)
                const createAndDownloadExcel = (data, filename = "metadatagrid.xlsx") => {
                  const worksheet = XLSX.utils.json_to_sheet(data);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "inventory");
                  XLSX.writeFile(workbook, filename);
              };
              
          
              var download = createAndDownloadExcel(gridData);
          }
          if (notebookName == "MappingDocument") {
            const fetchJsonFromBlob = async (url) => {
                try {
                    const sasToken = await generateSasTokenAPI();
                    const urlWithToken = `${url}?${sasToken}`;
                    const response = await fetch(urlWithToken);
                    const jsonData = await response.json();
                    return jsonData;
                } catch (error) {
                    console.error("Error fetching JSON from blob:", error);
                    throw error;
                }
            };
        
            var gridData = await fetchJsonFromBlob(url);
            console.log(gridData);
        
            const createAndDownloadExcel = (data, filename = "mapping.xlsx") => {
                const workbook = XLSX.utils.book_new();
        
                Object.keys(data).forEach(sheetName => {
                    const worksheet = XLSX.utils.json_to_sheet(data[sheetName]);
                    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
                });
        
                XLSX.writeFile(workbook, filename);
            };
        
            createAndDownloadExcel(gridData);
        }
        if(notebookName=="CodeReviewchecklist"){
            function ExcelDownload(data) {
                const wb = XLSX.utils.book_new();
                const sheetMap = {};
        
                data.forEach(sheetData => {
                    if (!sheetMap[sheetData.NotebookName.slice(0, 30)]) {
                        sheetMap[sheetData.NotebookName.slice(0, 30)] = [
                            ['Notebook Name', 'Command Purpose', 'Checklist Step', 'Status']
                        ];
                    }
        
                    sheetMap[sheetData.NotebookName.slice(0, 30)].push([
                        sheetData.NotebookName,
                        sheetData.CommandPurpose,
                        sheetData.CheckListStep,
                        sheetData.Status
                    ]);
                });
        
                Object.keys(sheetMap).forEach(sheetName => {
                    const ws = XLSX.utils.aoa_to_sheet(sheetMap[sheetName]);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                });
        
                XLSX.writeFile(wb, 'code_review_checklist.xlsx');
            }
        
            const getChecklistPageData = async () => {
                document.getElementById('pageLoader').style.display = "block";
                try {
                    const res = await fetchJsonFromBlob();
                    console.log(res, "result");
        
                    if (res?.length) {
                        ExcelDownload(res);
                        // Set the initial tab to the first notebook name
                    }
                } catch (error) {
                    console.error('Failed to load the checklist data:', error);
                } finally {
                    document.getElementById('pageLoader').style.display = "none";
                }
            };
        const fetchJsonFromBlob = async () => {
                try {
                    const sasToken = await generateSasTokenAPI();
                    const blobPath = `dwhmigration/${state.orgId}/${state.projectId}/development/${selectedTab}/codeReviewChecklistDev.json`;
                    const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
                    console.log(url, 'this is the url for fetch')
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
        
                    const jsonData = await response.json();
                    console.log(jsonData, 'Fetched JSON Data');
                    return jsonData;
                } catch (error) {
                    console.error('Error fetching JSON from blob:', error);
                    throw error;
                }
            };
            var download=getChecklistPageData()
          }
        }
    } catch (error) {
            console.error('Error in downloading the response:', error);
        }
    }
};



    const generateBronze = async(name) => {
        try {
            if(selectedTab=="init"){
                setingestTabDisabled(true)
            }
            else if(selectedTab=="ingest"){
setinitTabDisabled(true)
            }
            const notebookName = name;
            const phase = "Development";
            const referenceDocuments = ['Design Documents'];
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Looks Good" },
                        { flow: "ai", type: "text", text: `Let’s move towards generating ${notebookName}`, phase: phase },
                        { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                        { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                    
                    const object = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                        notebookName:notebookName,
                        projectName:state.projectName,
                        migrationPattern:state.migrationPattern,
                        migrationType:selectedTab
                    }
        
                    const response = await generateDWHbronze(object, state.jwt);
                    
                    // setLoader(() => false);
                    if (response.status === 403) {
                        sessionExpired()
                        return;
                    } else if (response.status !== 200) {
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } else if(response.status == "200" || response.status == 200){
                        const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                        if (fetchedData) {
                            setChatHistory(fetchedData)
                        }
                        setinitTabDisabled(false)
                        setingestTabDisabled(false)
                    }
                } catch (error) {
                    // console.error(`Error generating ${noteName}:`, error);
                }
        
      };
      
      const generateSilver = async(name) => {
        try {
            if(selectedTab=="init"){
                setingestTabDisabled(true)
            }
            else if(selectedTab=="ingest"){
setinitTabDisabled(true)
            }
            debugger
            const notebookName = name;
            const phase = "Development";
            const referenceDocuments = ['Design Documents'];
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Looks Good" },
                        { flow: "ai", type: "text", text: `Let’s move towards generating ${notebookName}`, phase: phase },
                        { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                        { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                    
                    const object = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                        notebookName:notebookName,
                        projectName:state.projectName,
                        migrationPattern:state.migrationPattern,
                        migrationType:selectedTab
                    }
        
                    const response = await generateDWHsilver(object, state.jwt);
                    
                    // setLoader(() => false);
                    if (response.status === 403) {
                        sessionExpired()
                        return;
                    } else if (response.status !== 200) {
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } else if(response.status == "200" || response.status == 200){
                        const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                        if (fetchedData) {
                            setChatHistory(fetchedData)
                        }
                        setinitTabDisabled(false)
                        setingestTabDisabled(false)
                    }
                } catch (error) {
                    // console.error(`Error generating ${noteName}:`, error);
                }
      };
      
      const generateGold = async(name) => {
        try {
            if(selectedTab=="init"){
                setingestTabDisabled(true)
            }
            else if(selectedTab=="ingest"){
setinitTabDisabled(true)
            }
            const notebookName = name;
            const phase = "Development";
            const referenceDocuments = ['Design Documents'];
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Looks Good" },
                        { flow: "ai", type: "text", text: `Let’s move towards generating ${notebookName}`, phase: phase },
                        { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                        { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                    
                    const object = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                        notebookName:notebookName,
                        projectName:state.projectName,
                        migrationPattern:state.migrationPattern,
                        migrationType:selectedTab
                    }
        
                    const response = await generateDWHgold(object, state.jwt);
                    
                    // setLoader(() => false);
                    if (response.status === 403) {
                        sessionExpired()
                        return;
                    } else if (response.status !== 200) {
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } else if(response.status == "200" || response.status == 200){
                        const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                        if (fetchedData) {
                            setChatHistory(fetchedData)
                        }
                        setinitTabDisabled(false)
                        setingestTabDisabled(false)
                    }
                } catch (error) {
                    // console.error(`Error generating ${noteName}:`, error);
                }
      };
      
      const generateUnittestcase = async() => {
        try {
            if(selectedTab=="init"){
                setingestTabDisabled(true)
            }
            else if(selectedTab=="ingest"){
setinitTabDisabled(true)
            }
            const notebookName = "unittestcase";
            const phase = "Development";
            const referenceDocuments = ['Design Documents'];
                    chatHistory[chatHistory.length - 1].show = false;
                    chatHistory[chatHistory.length - 1].isAction = false;
                    const data = [...chatHistory]
                    const newChat = [
                        ...data,
                        { flow: "user", type: "text", text: "Looks Good" },
                        { flow: "ai", type: "text", text: `Let’s move towards generating ${notebookName}`, phase: phase },
                        { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                        { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                    ];
        
                    setChatHistory(newChat);
        
                    const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                    
                    const object = {
                        userId: state.userId,
                        orgId: state.orgId,
                        projectId: state.projectId,
                        notebookName:"unitTestcase",  
                        projectName:state.projectName,
                        migrationPattern:state.migrationPattern,
                        migrationType:selectedTab
                    }
        
                    const response = await generateStaticUrls(object, state.jwt);
                    
                    // setLoader(() => false);
                    if (response.status === 403) {
                        sessionExpired()
                        return;
                    } else if (response.status !== 200) {
                        document.getElementById("protoastMessageSomething").style.display = "block";
                        setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                    } else if(response.status == "200" || response.status == 200){
                        const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                        if (fetchedData) {
                            setChatHistory(fetchedData)
                        }
                        setinitTabDisabled(false)
                        setingestTabDisabled(false)  
                    }
                } catch (error) {
                    // console.error(`Error generating ${noteName}:`, error);
                }
      };



        function getCodePageDetails(data) {
            console.log(data,'this is the dataaaaaaaaaaaaaaa')
            if (data.notebookName==="MetadataDocument"){
                // Proper state set 
                setCurrentChatHistory(data)
                setShowCode(true)
                setGridName("MetadataDocument")
}
            else if (data.notebookName==="LoggerCode"){
                // Proper state set
                setCurrentChatHistory(data) 
                setShowCode(true)
                setGridName("LoggerCode")
            }
            
            else if(data.notebookName=="UtilitiesCode"){
                setCurrentChatHistory(data) 
                setShowCode(true)
                setGridName("UtilitiesCode")
            }
            else{ 
                setCurrentChatHistory(data) 
                setShowCode(true)
                setGridName(data.notebookName)
            }
        }

        const handleNextNotebook = (list, currentName, currentFunction, nextFunction, nextNameListType) => {
            const currentIndex = list.indexOf(currentName);
            if (currentIndex === -1) return;
        
            if (currentIndex < list.length - 1) {
              currentFunction(list[currentIndex + 1]);
            } else {
              const nextList = chatHistory[0]?.[nextNameListType];
              console.log(nextList,'this is the list')
              if (nextList && nextList.length > 0) {
                nextFunction(nextList[0]);
              } 
              else if(nextNameListType=="silverNotebookNames"){
                const nextList = chatHistory[0]?.["goldNotebookNames"];
                console.log(nextList,'this is the list')
              if (nextList && nextList.length > 0) {
                generateGold(nextList[0]);
              } 
              }else {
                generateUnittestcase();
              }
            }
        };

        const checkLooksGood = (notebookName , chat) => {
            debugger
            if(notebookName=="InventoryDocument"){
                generateInventorySummary()
            }
            if(notebookName=="inventorySummary" && chat.migrationType=="init" ){
                designUploadchat()
            } 
            // if(notebookName=="MappingDocument" && chat.migrationType=="init" ){   
            //     designUploadchat()
            // }
            // if(notebookName=="MappingDocument" && chat.migrationType=="ingest"){   
            //     generatemetagrid()
            // }
            if(notebookName=="MetadataDocument"){
                generateLogger()
            }

            if(notebookName=="LoggerCode"){
                generateUtility()
            }

            if(notebookName=="UtilitiesCode"){
                generateMetacode()
            }

            if(notebookName=="MetaDataCode"){
                generateDDLScripts()
            }
            if (notebookName === 'DDLScriptsCode') {
                const checkNext = (list, nextFunction) => {
                  if (list && list.length > 0) {
                    nextFunction(list[0]);
                  } else {
                    return false;
                  }
                  return true;
                };
          
                const processed = 
                  checkNext(chatHistory[0]?.bronzeNotebookNames, generateBronze) ||
                  checkNext(chatHistory[0]?.SilverNotebookNames, generateSilver) ||
                  checkNext(chatHistory[0]?.GoldNotebookNames, generateGold);
                  
                if (!processed) {
                  generateUnittestcase();
                }
              } else if (chat.notebookType === 'Bronze' && chatHistory[0]?.bronzeNotebookNames?.includes(chat.notebookName)) {
                handleNextNotebook(chatHistory[0].bronzeNotebookNames, chat.notebookName, generateBronze, generateSilver, 'silverNotebookNames');
              } else if (chat.notebookType === 'Silver' && chatHistory[0]?.silverNotebookNames?.includes(chat.notebookName)) {
                handleNextNotebook(chatHistory[0].silverNotebookNames, chat.notebookName, generateSilver, generateGold, 'goldNotebookNames');
              } else if (chat.notebookType === 'Gold' && chatHistory[0]?.goldNotebookNames?.includes(chat.notebookName)) {
                handleNextNotebook(chatHistory[0].goldNotebookNames, chat.notebookName, generateGold, generateUnittestcase, null);
              }

              else if (chat.notebookName === 'UnitTestCase' ) {
                generateDataQuality()
              }

              else if (chat.notebookName === 'dataQuality' ) {
                generateCodeReviewCheckList()
              }
              else if (chat.notebookName === 'CodeReviewchecklist' ) {
                proceedToDeploy()
              }








            if(notebookName=="report"){
                // proceedToDeploy()
            }
        }

        

        async function generateInventory ()  { 

            try {

                const notebookName = "Inventory";
                const phase = "Development";
                const referenceDocuments = ['Inventory Document'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Data Warehouse & Connection Details" },
                            {
                                "flow": "ai",
                                "type": "codeShow",
                                "text": "You can find the Inventory Document here",
                                "hyperlinkText": "Inventory Document",
                                "show": true,
                                "isAction":true,
                                "id": "InventoryDocument",
                                "migrationType": selectedTab,
                                "migrationPattern": state.migrationPattern,
                                'notebookName': "InventoryDocument"
                            }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };
        

    
        async function generateDataQuality ()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "Data Quality Notebook";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Data Quality`, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:"dataquality",  
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            

                        const response = await generateStaticUrls(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generateCodeReviewCheckList ()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "CodeReviewCheckList";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Code Review Checklist `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:"codeReviewCheckList",
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await generateDWHcodereviewChecklist(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generatemetagrid ()  {  
            try {
                const notebookName = "Metadata Grid";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Metadata details`, phase: phase },
                            {
                                "flow": "ai",
                                "type": "codeShow",
                                "text": "You can find the Metadata details here",
                                "hyperlinkText": "Metadata Document",
                                "show": true,
                                "isAction":true,
                                "id": "MetadataDocument",
                                "migrationType": selectedTab,
                                "migrationPattern": state.migrationPattern,
                                'notebookName': "MetadataDocument"
                            }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                       
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generateDDLScripts ()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "DDlScripts";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating DDL Scripts `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:"DDLScripts",
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await generateDWHddlScripts(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generateLogger()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "LoggerNotebook";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Logger Code`, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                          notebookName:'logger',
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await generateStaticUrls(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function regenerate(data){
            debugger
            var newChat=chatHistory
            // Remove the last object from chatHistory
newChat.pop();
// Enable loader for the last item in chatHistory
newChat[newChat.length - 1].show = true;
// Add new message about document preparation
newChat.push({
    flow: 'ai',
    type: 'text',
    text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready',
    phase: data.notebookType
});
setChatHistory((a) => [...newChat]);

const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", chatHistory, "projectfiles");

            if(data.notebookType=="Bronze"){
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const object = {
                    userId: state.userId,
                    orgId: state.orgId,
                    projectId: state.projectId,
                    notebookName:data.notebookName,
                    projectName:state.projectName,
                    migrationPattern:state.migrationPattern,
                    migrationType:selectedTab
                }
    
                const response = await generateDWHbronze(object, state.jwt);
               
                if (response.status === 403) {
                    sessionExpired()
                    return;
                } else if (response.status !== 200) {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                } else if(response.status == "200" || response.status == 200){
                    const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                    if (fetchedData) {
                        setChatHistory(fetchedData)
                    }
                    setinitTabDisabled(false)
                        setingestTabDisabled(false)
                }
            }
            if(data.notebookType=="Silver"){
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const object = {
                    userId: state.userId,
                    orgId: state.orgId,
                    projectId: state.projectId,
                    notebookName:data.notebookName,
                    projectName:state.projectName,
                    migrationPattern:state.migrationPattern,
                    migrationType:selectedTab
                }
    
                const response = await generateDWHsilver(object, state.jwt);
                
                if (response.status === 403) {
                    sessionExpired()
                    return;
                } else if (response.status !== 200) {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                } else if(response.status == "200" || response.status == 200){
                    const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                    if (fetchedData) {
                        setChatHistory(fetchedData)
                    }
                    setinitTabDisabled(false)
                        setingestTabDisabled(false)
                }

            }
            if(data.notebookType=="Gold"){
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const object = {
                    userId: state.userId,
                    orgId: state.orgId,
                    projectId: state.projectId,
                    notebookName:data.notebookName,
                    projectName:state.projectName,
                    migrationPattern:state.migrationPattern,
                    migrationType:selectedTab
                }
    
                const response = await generateDWHgold(object, state.jwt);
               
                if (response.status === 403) {
                    sessionExpired()
                    return;
                } else if (response.status !== 200) {
                    document.getElementById("protoastMessageSomething").style.display = "block";
                    setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                } else if(response.status == "200" || response.status == 200){
                    const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                    if (fetchedData) {
                        setChatHistory(fetchedData)
                    }
                    setinitTabDisabled(false)
                        setingestTabDisabled(false)
                }
            }
        }

        async function generateUtility()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "Utilities";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Utilities Code `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:'utility',
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await generateStaticUrls(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };

        async function generateMetacode()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                const notebookName = "metaData code";
                const phase = "Development";
                const referenceDocuments = ['Design Documents'];
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        var newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating Metadata Code `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:'metaDataCode',
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await generateDWHMetaCode(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };











        async function generateInventorySummary ()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                        const notebookName = "inventorySummary";
                        const phase = "Development";
                        const referenceDocuments = ['Inventory Document'];
            
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating the Inventory Summary `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        console.log(`JSON data Updated successfully to ${blobJson}`);

                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:"inventorySummary",
                            projectName:state.projectName,
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab
                        }
            
                        const response = await DWHinventorySummary(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };


        async function generateMapping ()  {  
            try {
                if(selectedTab=="init"){
                    setingestTabDisabled(true)
                }
                else if(selectedTab=="ingest"){
setinitTabDisabled(true)
                }
                        const notebookName = "Mapping";
                        const phase = "Development";
                        const referenceDocuments = ['Inventory Document'];
            
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "looks Good" },
                            { flow: "ai", type: "text", text: `Let’s move towards generating the Mapping document `, phase: phase },
                            { flow: "ai", type: "loadShow", docName: notebookName, referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'text', text: 'Document would be ready in few minutes. You will be notified with an email once the document is ready' }
                        ];
            
                        setChatHistory(newChat);
            
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        console.log(`JSON data Updated successfully to ${blobJson}`);

                        const object = {
                            userId: state.userId,
                            orgId: state.orgId,
                            projectId: state.projectId,
                            notebookName:"Mapping",
                            migrationPattern:state.migrationPattern,
                            migrationType:selectedTab,
                            projectName:state.projectName
                        }
            
                        const response = await generateMappingData(object, state.jwt);
                        
                        // setLoader(() => false);
                        if (response.status === 403) {
                            sessionExpired()
                            return;
                        } else if (response.status !== 200) {
                            document.getElementById("protoastMessageSomething").style.display = "block";
                            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
                        } else if(response.status == "200" || response.status == 200){
                            const fetchedData = await dwhfetchJsonFromBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", 'projectfiles');
                            if (fetchedData) {
                                setChatHistory(fetchedData)
                            }
                            setinitTabDisabled(false)
                        setingestTabDisabled(false)
                        }
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };




       
        
        
        async function designUploadchat ()  {  
            try {
                        
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        
                        
                        var newChat   
                       if(selectedTab=='ingest'){ 
                        var referenceDocuments=["DataDictionary-Silver","DataDictionary-Gold",'DDLScripts-Bronze',"DDLScripts-Silver","DDLScripts-Gold","MetadataTable","LoggerNotebook","UtilitiesNotebook","Extraction_Notebook","Silver_Notebook","Gold_Notebook","DataQuality","UnitTesting","CodeReviewChecklist"]                    
                        newChat= [
                            ...data,
                            { flow: "user", type: "text", text: "Source and Connection Info"},
                            { flow: "ai", type: "designInstructionShow", referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'designUpload', text: 'Kindly upload the design document to proceed with the migration' }
                        ];
                    }
                    else{
                        var referenceDocuments=["DataDictionary-Gold",'DDLScripts-Bronze','DDLScripts-Bronze',"MetadataTable","LoggerNotebook","UtilitiesNotebook","Extraction_Notebook","Gold_Notebook","DataQuality","UnitTesting","CodeReviewChecklist"]
                        newChat= [
                            ...data,
                            { flow: "user", type: "text", text: "Datawarehouse and Connection Info"},
                            { flow: "ai", type: "designInstructionShow", referenceDocument: referenceDocuments, show: true },
                            { flow: 'ai', type: 'designUpload', text: 'Kindly upload the design document to proceed with the migration' }
                        ];
                    }
                        setChatHistory(newChat);
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
                        
                    } catch (error) {
                        // console.error(`Error generating ${noteName}:`, error);
                    }
        };




        async function deployYes(){
                        chatHistory[chatHistory.length - 1].show = false;
                        chatHistory[chatHistory.length - 1].isAction = false;
                        const data = [...chatHistory]
                        const newChat = [
                            ...data,
                            { flow: "user", type: "text", text: "Yes"},
                            { flow: 'ai', type: 'deployinfo',show:true }
                        ];
                        setChatHistory(newChat);
                        const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
        }


        async function deployNo(){
            chatHistory[chatHistory.length - 1].show = false;
            chatHistory[chatHistory.length - 1].isAction = false;
            const data = [...chatHistory]
            const newChat = [
                ...data,
                { flow: "user", type: "text", text: "NO"},
                { flow: "ai", type: "deploy", text: `would you like to deploy now`,show:true}
            ];
            setChatHistory(newChat);
            const blobJson = await dwhupdateJsonInBlob(state.orgId, state.projectId, selectedTab+"/chatHistory.json", newChat, "projectfiles");
}
    

    return (
            <>
                {/* PS_20 - PS_40 bindChatHitory is performed here to bind the user, ai and center flow */}
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                    style={{ position: "absolute", zIndex: "100000" }}
                >
                    <div
                        id="toastMessage"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Session has expired.Please login Again
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        id="protoastMessage"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/check 1.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Project has been created successfully
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div
                        id="protoastMessageSomething"
                        class="toast custom-toast align-items-center mt-5"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        data-bs-delay="3000"
                        style={{ display: "none" }}
                    >
                        <div class="d-flex">
                            <div class="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: "22px" }} />
                                </span>
                                <span class="mx-3 font-14 loader-text">
                                    Something went wrong. Try Again!
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    id="openErrorModal"
                    data-bs-target="#Something-wrong"
                    hidden
                >
                    Oops! Something went wrong
                </button>
                <div
                    className="modal fade"
                    id="Something-wrong"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body px-4 pb-0">
                                <div className="row text-center">
                                    <span>
                                        <img
                                            src="images/went-wrong-icon.svg"
                                            alt="went-wrong-icon"
                                            className="sucess-icon-sty"
                                        />
                                    </span>
                                    <h3 className="font-16 font-bold text-black mt-4">
                                        Oops! Something went wrong
                                    </h3>
                                    <p className="font-14 font-regular text-black">
                                        Please try again later{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maindiv">
                {showCode === false ?
                            (
                                    <>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {/* header style starts here */}
                                                <HeaderCom value="1" />
                                                {/* header style ends here */}
                                                <div
                                                    className="overlay"
                                                    id="pageLoader"
                                                    style={{ display: "none" }}
                                                >
                                                    <div className="position-absolute top-50 start-50  translate-middle">
                                                        <div className="d-flex align-items-center loader-bg">
                                                            <div
                                                                className="spinner-border Loader text-dark align-center"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>
                                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 pt-4 mt-5">
                                                    <div className="mt-3">
                                                        <ul className="cust-breadcrumb mb-0 ">
                                                            <li className="font-16 font-medium">
                                                                <a>Manage Projects</a>
                                                            </li>
                                                            <li className="font-16 font-medium active">
                                                                {state.isEdit
                                                                    ? state.projectName
                                                                    : state.projectName}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row p-4">
                                                        <div className="d-flex  mb-4 justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center mb-xl-0 ">
                                                                <a className="d-flex" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                                                    <img
                                                                        src="images/back-arrow.svg"
                                                                        alt="back-arrow"
                                                                        onClick={() => {
                                                                            navigate("/ProjectInfo", {
                                                                                state: { ...state, isEdit: true },
                                                                            });
                                                                        }}
                                                                    />
                                                                </a>
                                                                <h2 className="text-black font-bold font-22 mb-0 ms-3" style={{ position: "absolute", paddingLeft: "1%" }}>
                                                                    {state.isEdit === false
                                                                        ? state.projectName
                                                                        : state.projectName}
                                                                </h2>
                                                            </div>
                                                            {/* <div class="d-lg-flex d-md-block"> */}
                                                            {/* <div class="d-flex mb-md-6 mb-sm-6"> */}
                                                            <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 me-3 d-flex align-items-center" style={{ marginLeft: '48%' }}>
                                                                <span className=" text-nowrap font-bold">Organization : </span>
                                                                <span>
                                                                    <img
                                                                        src={state.orgLogo}
                                                                        alt="hubspot-logo"
                                                                        className="figma-logo"
                                                                    />
                                                                </span>
                                                                <span className="me-2 text-nowrap ">
                                                                    {" "}{state.orgName}{" "}
                                                                </span>
                                                            </div>
                                                            <div className="input-group position-relative search-wid me-md-3" style={{ overflow: 'visible' }}>
                                                                <div style={{ position: 'relative', width: '100%' }}>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                                                                        placeholder="Search"
                                                                        value={searchText}
                                                                        onChange={(e) => handleSearch(e.target.value)}
                                                                        onKeyPress={handleKeyPress}
                                                                        style={{
                                                                            paddingLeft: matchCount > 0 ? '60px' : '12px',
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                    {matchCount > 0 && (
                                                                        <span style={{
                                                                            position: 'absolute',
                                                                            left: '8px',
                                                                            top: '50%',
                                                                            transform: 'translateY(-50%)',
                                                                            fontSize: '12px',
                                                                            color: '#fff',
                                                                            backgroundColor: '#7650E0',
                                                                            padding: '2px 6px',
                                                                            borderRadius: '10px',
                                                                            zIndex: 5,
                                                                            pointerEvents: 'none',
                                                                        }}>
                                                                            {`${currentMatchIndex + 1}/${matchCount}`}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <span className="search-icon-sty" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                    <a><img src="images/search-icon.svg" alt="search icon" /></a>
                                                                </span>
                                                            </div>
                                                            {/* </div> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="code-review-tab-width">
                                                            <div className="code-review-page tabs nav ">
                                                                <a
                                                                    onClick={() => {
                                                                        navigate("/ProjectInfo", {
                                                                            state: { ...state, isEdit: true },
                                                                        });
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <div className="tab nav-link font-bold">
                                                                        Project Info
                                                                    </div>
                                                                </a>
    
                                                                <div
    className={`tab nav-link font-bold ${selectedTab === 'init' ? 'active' : ''}`}
    data-bs-toggle="tooltip"
    data-bs-target="#ask-gen"
    onClick={() => {
      if (!initTabDisabled) {
        setSelectedTab('init');
      }
    }}
    title={initTabDisabled ? "Wait till the generation is completed" : ""}
    style={initTabDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
  >
    Init
</div>

<div
    className={`tab nav-link font-bold ${selectedTab === 'ingest' ? 'active' : ''}`}
    data-bs-toggle="tooltip"
    data-bs-target="#ask-gen"
    onClick={() => {
      if (!ingestTabDisabled) {
        setSelectedTab('ingest');
      }
    }}
    title={ingestTabDisabled ? "Wait till the generation is completed" : ""}
    style={ingestTabDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
  >
    Ingest
</div>

                                                            </div>
                                                            <div className="tab-content" id="pills-tabContent">
                                                                <div
                                                                    id="ask-gen"
                                                                    className="tab-pane fade code-review-tab show active tab-content"
                                                                >
                                                                    <div className="chat-box px-sm-0">
                                                                        <div className="container px-0">
                                                                            <div className="row px-sm-0">
                                                                                <div
                                                                                    className="chat-container"
                                                                                    style={{ height: 430, overflowY: "auto" }}
                                                                                >
                                                                                    <div className="col-md-12">
                                                                                        {chatHistory.length > 0 ? (
                                                                                            <>
                                                                                                {chatHistory.map((data) => {
    
                                                                                                    if (data.flow === "center") {
                                                                                                        return (
                                                                                                            <div className="chat-divider d-flex justify-content-center">
                                                                                                                <span className="font-12 font-medium">{data.text}</span>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (data?.flow === "ai") {
                                                                                                        if (data?.type === "welcomeMessage") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Hi <span className="text-black">
                                                                                                                            {state.userName}
                                                                                                                        </span>
                                                                                                                        , How can I help you with?
                                                                                                                        
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data?.show === true && (
                                                                                                                            <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => letStart()}
                                                                                                                            >
                                                                                                                               Let's Start
                                                                                                                            </button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        if (data?.type === "deploy") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Would you like to deploy now?
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data?.show === true && (
                                                                                                                            <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => deployYes()}
                                                                                                                            >
                                                                                                                               Yes 
                                                                                                                            </button>
                                                                                                                            
                                                                                                                            
                                                                                                                        )}
                                                                                                                        {data?.show === true && (
                                                                                                                        <button
                                                                                                                                className="chat-button-primary font-12 font-bold "
                                                                                                                                onClick={() => deployNo()}
                                                                                                                            >
                                                                                                                               No 
                                                                                                                            </button>
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }

                                                                                                        if (data?.type === "summary") {
                                                                                                            return (
                                                                                                                <div className="w-75">
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Total-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Total Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.totalTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-In-scope-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Tables In-scope</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.inScopeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-Out-scope-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Tables Out-scope</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.outScopeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                             
                                                                                                                             
                                                                                                                            </div>
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Medium-Volume-Tables-icon.svg"
                                                                                                                                                alt="High-Volume-Tables-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">
                                                                                                                                            Database
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.dataBaseCount}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/High-Volume-Tables-icon.svg"
                                                                                                                                                alt="Medium-Volume-Tables-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Subject Area</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.subjectAreaCount}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Low-Volume-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Schema</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.schemaCount}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Changed-Tables-in-a-Notebook-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Views</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.viewCount}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row m-0 table-summaries-parent">
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Tables-Out-scope-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">High Volume Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.highVolumeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Total-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Medium Volume Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.mediumVolumeTables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-md-3 custom-table-list-spacing">
                                                                                                                                    <div className="table-summaries-list">
                                                                                                                                        <div>
                                                                                                                                            <img
                                                                                                                                                src="images/Medium-Volume-Tables-icon.svg"
                                                                                                                                                alt="table-summaries-icons"
                                                                                                                                                className="me-2"
                                                                                                                                            />
                                                                                                                                            <span className="table-summaries-title">Low Volume Tables</span>
                                                                                                                                        </div>
                                                                                                                                        <span className="table-summaries-count">{data.summary.lowVolumetables}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                             
                                                                                                                             
                                                                                                                            </div>
                                                                                                    
                                                                                                                          

                                                                                                                            <div className="table-summaries-buttons">
                                                                                                                            {data.isAction === true && (
                                                                                                                                            <button
                                                                                                                                                className="outline-btn"
                                                                                                                                                onClick={() => checkLooksGood('inventorySummary',data)}
                                                                                                                                            >
                                                                                                                                                Proceed To Upload Document
                                                                                                                                            </button>
                                                                                                                                       )}

                                                                                                                              
                                                                                                                                {data.isAction === true && (
                                                                                                                                    <button className="download-btn"
                                                                                                                                        onClick={() => downloadResponse(data)}
                                                                                                                                    >
                                                                                                                                        <img src="images/download-icon-black.svg" alt="download-icon-black" />
                                                                                                                                    </button>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                            );
                                                                                                        }
                                                                                                        
                                                                                                        if (data?.type === "deployinfo") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly{"  "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => {setDeployPopup(true)
                                                                                                                                setPopupName('dwhdeploy')
                                                                                                                                setpopUpShow(true);
                                                                                                                            }
                                                                                                                            }
                                                                                                                        >
                                                                                                                            fill out this 
                                                                                                                        </a>{" "}  to know about target system details.
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                                 
                                                                                                        if (data?.type === "datawarehouseDetails" ) {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Sure I can help you with it. Kindly{"  "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => 
                                                                                                                                {setPopupName("datawarehouse");
                                                                                                                                setpopUpShow(true);}
                                                                                                                            }
                                                                                                                        >
                                                                                                                            fill out this 
                                                                                                                        </a>{" "}  to know about Data Warehouse and Connection Details.
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }

                                                                                                        if (data?.type === "sourceDetails" ) {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Sure I can help you with it. Kindly{"  "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => 
                                                                                                                                {setPopupName("sourceDetails");
                                                                                                                                setpopUpShow(true);}
                                                                                                                            }
                                                                                                                        >
                                                                                                                            fill out this 
                                                                                                                        </a>{" "}  to know about Source System and Connection Details.
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                       
                                                                                                        if (data.type === 'designInstructionShow' && data.show === true) {
    
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly find the details to be included in the following inventory document upload.
                                                                                                                    The inventory document should contain the following sheets:
                                                                                                                    {data.referenceDocument.length > 1 ? (
            // Render the div with the loop inside if data.referenceDocument has more than 1 item
            <div className="my-3">
                {/* Use map to render a div for each item in data.referenceDocument */}
                {data.referenceDocument.map((document, index) => (
                    <div key={index}>
                        <a
                            className="text-decoration-none text-black font-12"
                            onClick={() => {setDocumentName(document);setPopupName("designInstructions");setpopUpShow(true);}}
                            style={{ cursor: "pointer" }}
                        >
                            <img src="images/solid-doc-icon.svg" className="me-2" />
                            {document} {/* Render the value of each document object */}
                        </a>
                    </div>
                ))}
            </div>
        ) : (
            // If data.referenceDocument has only 1 item, render a single div
            data.referenceDocument.length === 1 && (
                <div className="my-3">
                    <a
                        className="text-decoration-none text-black font-12"
                        onClick={() => {setDocumentName(data.referenceDocument[0]);setPopupName("designInstructions");setpopUpShow(true);}}
                        style={{ cursor: "pointer" }}
                    >
                        <img src="images/solid-doc-icon.svg" className="me-2" />
                        {data.referenceDocument[0]} {/* Render the value of the single document */}
                    </a>
                </div>
            )
        )}
    
                                                                                                                       
                                                                                                                    </div>
    
                                                                                                                </div>
    
                                                                                                            );
                                                                                                        }

                                                                                                        if (data?.type === "designUpload") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                    Kindly{"  "}
                                                                                                                        <a
                                                                                                                            className="chatbot-anchor"
                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                            onClick={() => 
                                                                                                                                {setPopupName("designUpload");
                                                                                                                                setpopUpShow(true);
                                                                                                                            }
                                                                                                                            }
                                                                                                                        >
                                                                                                                             upload the design document
                                                                                                                        </a>{" "}  to proceed with the migration .
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }

                                                                                                        else if (data.type === "text") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        {data.text}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === 'inventorySourceInstructions' && data.show === true) {
    
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium" style={{ maxWidth: '7000px' }}>
                                                                                                                   Steps for extracting inventory from a Source System:       
                                                                                                                        <br/>
                                                                                                                  
                                                                                                                   1. Open the below pop-up and click on download scripts
                                                                                                                        <br/>
                                                                                                                      

                                                                                                                      2. After downloading, execute each script in the respective source system to extract the inventory data.
                                                                                                                        <br/>
                                                                                                                       
                                                                                                                      3. Once the data is extracted, convert it into the provided template format which is available in the below popUp and upload it back to GenETL.
                                                                                                                    </div>
                                                                                                                </div>
    
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === 'inventoryDatawarehouseInstructions' && data.show === true) {
    
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium" style={{ maxWidth: '7000px' }}>
                                                                                                                   Steps for extracting inventory from a Data Warehouse:       
                                                                                                                        <br/>
                                                                                                                  
                                                                                                                   1. Open the below pop-up and click on download scripts
                                                                                                                        <br/>
                                                                                                                      

                                                                                                                      2. After downloading, execute each script in the respective data warehouse to extract the inventory data.
                                                                                                                        <br/>
                                                                                                                       
                                                                                                                      3. Once the data is extracted, convert it into the provided template format which is available in the below pop-up and upload it back to GenETL.
                                                                                                                    </div>
                                                                                                                </div>
    
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === 'loadShow' && data.show === true) {
    
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative mb-4">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        Your {data.docName} document would be generated
                                                                                                                        based on the below document
                                                                                                                        {data.referenceDocument.length > 1 ? (
                                                                                                                            // Render the div with the loop inside if data.referenceDocument has more than 1 item
                                                                                                                            <div className="my-3">
                                                                                                                                {/* Use map to render a div for each item in data.referenceDocument */}
                                                                                                                                {data.referenceDocument.map((document, index) => (
                                                                                                                                    <div key={index}>
                                                                                                                                        <a className="text-decoration-none text-black font-12">
                                                                                                                                            <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                            {document} {/* Render the value of each document object */}
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                ))}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            // If data.referenceDocument has only 1 item, render a single div
                                                                                                                            data.referenceDocument.length === 1 && (
                                                                                                                                <div className="my-3">
                                                                                                                                    <a className="text-decoration-none text-black font-12">
                                                                                                                                        <img src="images/solid-doc-icon.svg" className="me-2" />
                                                                                                                                        {data.referenceDocument[0]} {/* Render the value of the single document */}
                                                                                                                                    </a>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}
    
                                                                                                                        {data.docName} document would be ready soon...
                                                                                                                  
                                                                                                                    </div>
    
                                                                                                                </div>
    
                                                                                                            );
                                                                                                        }
                                                                                                        else if (data.type === "codeShow") {
                                                                                                            return (
                                                                                                                <div className="bot-response position-relative" id={`search-${data.hyperlinkText}`}>
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img src="images/GenELT-chat-logo.svg" alt="user-profile" />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                                        <b>{data?.text}</b>
                                                                                                                        <div className="mt-3" >
                                                                                                                            <a
                                                                                                                                onClick = {() => { getCodePageDetails(data); bindLooksGood()}}
                                                                                                                                className="hyperlink"
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                            >  
                                                                                                                                {data?.hyperlinkText}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2 ms-4 position-absolute">
                                                                                                                        {data.show === false ? (
    
                                                                                                                            data.isAction === true && (
                                                                                                                                <>
                                                                                                                                    <div
                                                                                                                                        className="btn-group"
                                                                                                                                        role="group"
                                                                                                                                        aria-label="Basic outlined example"
                                                                                                                                    >
                                                                                                                                        <button
    className="chat-button-secondary font-12 font-bold"
    onClick={() => checkLooksGood(data?.notebookName , data )}
    style={{ marginRight: "10px" }}
>
    Looks good
</button>

<button
    type="button"
    className="btn btn-outline-primary chat-action-button py-0 px-1"
    onClick={() => {
        downloadResponse(data);
    }}
>
    <img src="images/chat-action-download.svg" />
</button>
{data.notebookType && ['Gold', 'Silver', 'Bronze'].includes(data.notebookType) && (
  <button
    type="button"
    className="btn btn-outline-primary chat-action-button py-0 px-1"
  >
    <img
      src="images/chat-action-regenerate.svg"
      height="20px"
      onClick={() => { regenerate(data) }}
    />
  </button>
)}
                                                                                                                                        
                                                                                                                                
                                                                                                                                        
                                                                                                                                    </div>
    
                                                                                                                                </>
                                                                                                                            )
    
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                
                                                                                                                                {data.isAction === true && (
                                                                                                                                    <>
                                                                                                                                        <div
                                                                                                                                            className="btn-group"
                                                                                                                                            role="group"
                                                                                                                                            aria-label="Basic outlined example"
                                                                                                                                        >
                                                                                                                                            
                                                                                                                                            <button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-outline-primary chat-action-button py-0 px-1"
                                                                                                                                                onClick={() => {
                                                                                                                                                    downloadResponse(data);
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <img src="images/chat-action-download.svg" />
                                                                                                                                            </button>
                                                                                                                                            {data.notebookType && ['Gold', 'Silver', 'Bronze'].includes(data.notebookType) && (
  <button
    type="button"
    className="btn btn-outline-primary chat-action-button py-0 px-1"
  >
    <img
      src="images/chat-action-regenerate.svg"
      height="20px"
      onClick={() => { regenerate(data) }}
    />
  </button>
)}
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        
                                                                                                       
                        
                                                                                                    } else if (data.flow === "user") {
                                                                                                        if (data.type === "text") {
                                                                                                            return (
                                                                                                                <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                                    <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                                        <img
                                                                                                                            src={`${localStorage?.getItem("dXNlckltYWdl1") !== null
                                                                                                                                ? decode(
                                                                                                                                    localStorage
                                                                                                                                        ?.getItem("dXNlckltYWdl1")
                                                                                                                                        ?.substring(
                                                                                                                                            15,
                                                                                                                                            localStorage.getItem("dXNlckltYWdl1")?.length - 10
                                                                                                                                        )
                                                                                                                                )
                                                                                                                                : "images/userDefault.svg"
                                                                                                                                }`}
                                                                                                                            alt="user-profile"
                                                                                                                            className="chat-user-img"
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                    <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                                        {data.text}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return <></>
                                                                                                        }
                                                                                                    }
                                                                                                })}
                                                                                            </>
                                                                                        ) : <></>
                                                                                        }
                                                                                        {showLooksGood ? (
                                                                                            <div className="user-chat-input position-relative d-flex justify-content-end">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img
                                                                                                        src={`${localStorage?.getItem(
                                                                                                            "dXNlckltYWdl1"
                                                                                                        ) !== null
                                                                                                            ? decode(localStorage?.getItem("dXNlckltYWdl1")?.substring(15,
                                                                                                                localStorage.getItem(
                                                                                                                    "dXNlckltYWdl1"
                                                                                                                )?.length - 10
                                                                                                            )
                                                                                                            )
                                                                                                            : "images/userDefault.svg"
                                                                                                            }`}
                                                                                                        alt="user-profile"
                                                                                                        className="chat-user-img"
                                                                                                    />
                                                                                                </span>
                                                                                                <div className="chat-content-user font-14 me-4 font-medium">
                                                                                                    Looks good
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {showLoader === true ? (
                                                                                            <div className="bot-response position-relative">
                                                                                                <span className="bot-chat-img d-flex align-items-center justify-content-center d-inline-block position-absolute">
                                                                                                    <img
                                                                                                        src="images/GenELT-chat-logo.svg"
                                                                                                        alt="user-profile"
                                                                                                    />
                                                                                                </span>
                                                                                                <div className="chat-content font-14 ms-4 mb-3 font-medium">
                                                                                                    <div className="dot-typing"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        <div id='Imdown'></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            



                                        </div>
                                    </>
                                    ):
                                    (
                                        <>
                                        {showCode && (
    <>
        {gridName === "MetadataDocument" ? (
            <MetaDataTable
            data={currentChatHistory}
            onChange={() => gridUpdated(currentChatHistory.notebookName)}
                closeGrid={closeGrid}
                migrationPattern={state.migrationPattern}
                migrationType={selectedTab}
            />
        ) : gridName === "MappingDocument" ? (
            <MappingGrid 
            data={currentChatHistory}
            onChange={() => gridUpdated(currentChatHistory.notebookName)}
                closeGrid={closeGrid}
                migrationPattern={state.migrationPattern}
                migrationType={selectedTab}
            />
        ) : gridName === "CodeReviewchecklist" ? (
            <CodeReviewChecklistDev 
            data={currentChatHistory}
            onChange={() => gridUpdated(currentChatHistory.notebookName)}
                closeGrid={closeGrid}
                migrationPattern={state.migrationPattern}
                migrationType={selectedTab}
            />
        ) : gridName === "InventoryDocument" ? (
            <InventoryGrid
            data={currentChatHistory}
            onChange={() => gridUpdated(currentChatHistory.notebookName)}
                closeGrid={closeGrid}
                migrationPattern={state.migrationPattern}
                migrationType={selectedTab}
            />
        ) : (
            <DwhCodeViewPage
                data={currentChatHistory}
            // onChange={() => gridUpdated(currentChatHistory.notebookName)}
                setShowCode={() => setShowCode(false)}
                isEdit={(status) => {
                    console.log(isEdit)
                    if (status === true) {
                        gridUpdated(currentChatHistory.notebookName);
                    }
                }}
                gridData={gridName}
                migrationType={selectedTab}
                gridType={currentChatHistory.notebookType || ""}
            />
        )}
    </>
)}
                                        </>
                                    )
                                }
                                {/* popUPS */}
                                {popUpShow && (
    <>
        {popupName === "sourceDetails" ? (
            <SourceInfoPopup
                onChange={() => {
                    closePopup();
                    designUploadchat();
                }}
                closePopup={closePopup}
            />
        ) : popupName === "datawarehouse" ? (
            <InitSourceSystem
                onChange={() => {
                    generateInventory()
                }}
                closePopup={closePopup}
            />
        ) : popupName === "designUpload"  ? (
            selectedTab === "ingest" ? (
                <DynamicFileUploadPopup
                    validationColumns={DesignValidation}
                    text={{
                        FileName: chatHistory[0]?.fileName || '',
                        popupText: "Please upload Design Document"
                    }}
                    saveFunction={saveFunction}
                    closePopup={closePopup}
                    templateURL={templateURLs.designTemplates}
                    chatHistory={chatHistory}
                    fileUploaded={chatHistory[0]?.fileName || ''} 
                    migrationType={selectedTab}
                />
            ) : selectedTab === "init" ? (
                <DynamicFileUploadPopup
                    validationColumns={DesignValidation2}
                    text={{
                        FileName: chatHistory[0]?.fileName || '',
                        popupText: "Please upload Design Document"
                    }}
                    saveFunction={saveFunction}
                    closePopup={closePopup}
                    templateURL={templateURLs.designTemplates}
                    chatHistory={chatHistory}
                    fileUploaded={chatHistory[0]?.fileName || ''} 
                    migrationType={selectedTab}
                />
            ) : null
        ) : popupName === "dwhdeploy" ? (  // Corrected this popupName to be different
            <DeployDWHCode
                onChange={(data, status, userInputs) => savedeployment(data, status, userInputs)}
                closePopup={closePopup}  // Pass a function that sets `setDeployPopup` to false
                fileUploaded={chatHistory[0]?.deployDetails || ""}
                filename={chatHistory[0]?.fileName?.split(".")[0]?.replaceAll(" ", "_") || ""}
                migrationType={selectedTab}
                bronzeNotebookNames={chatHistory[0]['bronzeNotebookNames']}
                silverNotebookNames={chatHistory[0]['silverNotebookNames']}
                goldNotebookNames={chatHistory[0]['goldNotebookNames']}
            />
        ) : popupName === "designInstructions" ? (  // Corrected this popupName to be different
            <ViewInstructions
            notebooktype={documentName}
            onClose={closePopup}
          />
        ): null }
    </>
)}
                </div >
            </>
        );
}




export default ChatMaindwh;