import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import ViewInstructions from './viewInstructions.jsx'

function MetaTableData(props) {
    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            let url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [blobUrl, setblobUrl] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);


    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

//     const handleReasonSave = async () => {
//         let updatedData;
//         try {
//             switch (currentOperation) {
//                 case 'edit':
//                     updatedData = actualValue.map(item =>
//                         item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
//                     );
//                     break;
//                 case 'add':
//                     updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
//                     break;
//                 case 'delete':
//                     updatedData = actualValue.filter(item => item.index !== currentItem.index);
//                     break;
//                 default:
//                     throw new Error('Invalid operation');
//             }
// let body={
//                 type: "metaDataTable", 
//                 projectId: state.projectId, 
//                 userId: state.userId,
//                 orgId: state.orgId,
//                 comments:reason,

//             }
//           let res=  await updatePseudocodeData(body,state.jwt)
//             await updateJsonInBlob(blobUrl, updatedData);
//             setActualValue(updatedData);
//             setGridData(updatedData);
//             setShowReasonModal(false);
//             setReason('');
//             setCurrentOperation(null);
//             setCurrentItem(null);
//             setWhetherUpdated(true);
//         } catch (error) {
//             console.error('Error updating data:', error);
//         }
//     };



const handleReasonSave = async () => {
       try {
            let updatedData;
            switch (currentOperation) {
               case 'edit':
                   updatedData = actualValue.map(item =>
                       item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                   );
                   break;
               case 'add':
                   updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                   break;
               case 'delete':
                   updatedData = actualValue.filter(item => item.index !== currentItem.index);
                   break;
               default:
                   throw new Error('Invalid operation');
           }
   let body={
                   type: "metaDataTable", 
                   projectId: state.projectId, 
                   userId: state.userId,
                   orgId: state.orgId,
                   comments:reason,
   
   
   
               }
             let res=  await updatePseudocodeData(body,state.jwt)
               await updateJsonInBlob(blobUrl, updatedData);
           await updateJsonInBlob(blobUrl, updatedData);
   
   
   
           // Update local state with original data and re-apply filter
           setActualValue(updatedData);
           setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
    setSearchFilterTrigger(prev => prev + 1);
   
   
           setShowReasonModal(false);
           setReason('');
           setCurrentOperation(null);
           setCurrentItem(null);
           setWhetherUpdated(true);
       } catch (error) {
           console.error('Error updating data:', error);
       }
   };


    const updateJsonInBlob = async (blobURL, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobURL}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };



    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'SourceCatalogName', header: 'Source Catalog Name', sortable: true, deFaultRow: 'input' },
            { key: 'SourceSchemaName', header: 'Source Schema Name', sortable: true, deFaultRow: 'input' },
            { key: 'SourceTableName', header: 'Source Table Name', sortable: true, deFaultRow: 'input' },
            { key: 'SourceStorageType', header: 'Source Storage Type', sortable: true, deFaultRow: 'dropdown', options: ['MANAGED', 'EXTERNAL'] },
            { key: 'SourceStoragePath', header: 'Source Storage Path', sortable: true, deFaultRow: 'input' },
            { key: 'NewCatalogName', header: 'New Catalog Name', sortable: true, deFaultRow: 'input' },
            { key: 'NewSchemaName', header: 'New Schema Name', sortable: true, deFaultRow: 'input' },
            { key: 'NewTableName', header: 'New Table Name', sortable: true, deFaultRow: 'input' },
            { key: 'NewStorageType', header: 'New Storage Type', sortable: true, deFaultRow: 'dropdown', options: ['MANAGED', 'EXTERNAL','SOURCETYPE'] },

            // Editable only when both SourceStorageType and NewStorageType are "EXTERNAL".
    
            {
                key: 'NewStoragePath',
                header: 'New Storage Path',
                sortable: true,
                deFaultRow: 'input',
                // CSS class added for styling
            },

            // Dropdowns with options
            { key: 'VersionHistory', header: 'Version History', sortable: true, deFaultRow: 'dropdown', options: ['Yes', 'No'] },
            { key: 'MigrationPattern', header: 'Migration Pattern', sortable: true, deFaultRow: 'dropdown', options: ['Migration', 'Configuration'] },
            { key: 'BatchID', header: 'Batch ID', sortable: true, deFaultRow: 'input' }, // Calculated field
            { key: 'LoadType', header: 'Load Type', sortable: true, deFaultRow: 'input' }, // To be determined based on conditions
            { key: 'CreatedStatus', header: 'Created Status', sortable: true, deFaultRow: 'dropdown' },
            { key: 'StructureTestStatus', header: 'Structure Test Status', sortable: true, deFaultRow: 'dropdown' },
            { key: 'SampleDataTestStatus', header: 'Sample Data Test Status', sortable: true, deFaultRow: 'dropdown' },
            { key: 'RowCountStatus', header: 'Row Count Status', sortable: true, deFaultRow: 'dropdown' },
            { key: 'PipelineStatus', header: 'Pipeline Status', sortable: true, deFaultRow: 'dropdown' },
            { key: 'PipelineStartTime', header: 'Pipeline Start Time', sortable: true, deFaultRow: 'datetime' },
            { key: 'PipelineEndTime', header: 'Pipeline End Time', sortable: true, deFaultRow: 'datetime' }
        ],
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: [
            // Default values for dropdowns and inputs.
            { key: 'SourceStorageType', values: ['MANAGED', 'EXTERNAL'] },
            { key: 'NewStorageType', values: ['MANAGED', 'EXTERNAL','SOURCETYPE'] },
            { key: 'NewStoragePath', values: '-'},
            { key: 'VersionHistory', values: ['Yes', 'No'] },
            { key: 'MigrationPattern', values: ['Migration', 'Configuration'] },
            { key: 'CreatedStatus', values: ['-'] }, // Default value
            { key: 'StructureTestStatus', values: ['-'] }, // Default value
            { key: 'SampleDataTestStatus', values: ['-'] }, // Default value
            { key: 'RowCountStatus', values: ['-'] }, // Default value
            { key: 'PipelineStatus', values: ['-'] }, // Default value
            { key: 'PipelineStartTime', values: ['-'] }, // Default value
            { key: 'PipelineEndTime', values: ['-'] } // Default value
        ]
        
    });



    const searchColumns = ['SourceCatalogName', 'SourceSchemaName', 'NewCatalogName', 'NewSchemaName'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchPseudo()]); 
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } 
        };   
        fetchData();   
    }, []);

    const fetchPseudo = async () => {
        document.getElementById('pageLoader').style.display = "block";
        const body = {
            projectId: state.projectId,
            type: "ucmetadata",
            userId: state.userId,
            orgId: state.orgId,
            jwt: state.jwt
        };

        try {
            const res = await fetchPseudocodeData(body, state.jwt);
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                if (res.data && res.data.length > 0) {
                    let MetaDataUrl = res.data[0].metadataTableURL;
                    setblobUrl(MetaDataUrl);
                    try {
                        const jsonData = await fetchJsonFromBlob(MetaDataUrl);
                        document.getElementById('pageLoader').style.display = "none";

                        if (jsonData) {
                            let pseudo = jsonData.map((obj, index) => ({
                                ...obj,
                                index: index
                            }));
                            setGridData(pseudo);
                            setActualValue(pseudo);
                        } else {
                            throw new Error("Failed to fetch data from Azure Blob Storage");
                        }
                    } catch (e) {
                        console.error('Error fetching JSON from blob:', e);
                        handleError(e);
                    }
                } else {
                    throw new Error("No data found in response");
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    };

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                            <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button>
                        </h2> {isModalOpen && <ViewInstructions notebooktype='metaDataTable' onClose={handleCloseModal} />}
                    </div>
                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                            searchFilterTrigger={searchFilterTrigger}
                        />
                        <DownloadComponent
                            type="excel"
                            data={actualValue}
                            fileName="MetaTableData"
                        />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    newRow={newRow}
                    setNewRow={setNewRow}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
            </div>
        </div>
    );
}

export default MetaTableData;