// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text3dot {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px !important;
}

.org-logo {
  width: 30px !important;
  height: 30px;
}


.hyperlink {
  color: #0d6efd !important;
  text-decoration: underline !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;;AAGA;EACE,yBAAyB;EACzB,qCAAqC;AACvC","sourcesContent":[".text3dot {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 70px !important;\n}\n\n.org-logo {\n  width: 30px !important;\n  height: 30px;\n}\n\n\n.hyperlink {\n  color: #0d6efd !important;\n  text-decoration: underline !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
