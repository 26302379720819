import React from 'react';

const popupStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    container: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        maxHeight: '500px', // Limit the height to 500px
        overflowY: 'auto' // Make content scrollable
    },
    button: {
        marginTop: '10px',
        padding: '10px 20px'
    },
    ul: {
        listStyleType: 'none',
        paddingLeft: 0,
    },
    li: {
        textAlign: 'left',
    }
};

const PopupNotification = ({ message, errors, onClose }) => {
    return (
        <div style={popupStyles.overlay}>
            <div style={popupStyles.container}>
                <p><b>{message}</b></p>
                {errors && errors.length > 0 && (
                    <ul style={popupStyles.ul}>
                        {errors.map((error, index) => (
                            <li style={popupStyles.li} key={index}>{error}</li>
                        ))}
                    </ul>
                )}
                <button style={popupStyles.button} onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default PopupNotification;