import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { generateSasTokenAPI, insertError } from "../../Service/Api.js";
import { HeaderCom } from "../Header.jsx";
import ViewInstructions from '../new/Integrations/viewInstructions.jsx';

function InventoryGrid(props) {
  const { state } = useLocation();
  const [data, setData] = useState(null);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    order: "desc",
    column: "DatabaseName"
  });
  const [filterCriteria, setFilterCriteria] = useState({
    SchemaName: "Select",
    TableName: "Select"
  });
  const [isLoading, setIsLoading] = useState(true);
  const [filteredResponse, setFilteredResponse] = useState([]);
  const [inScopeCount, setInScopeCount] = useState(0);
  const [outScopeCount, setOutScopeCount] = useState(0);

       const [isModalOpen, setModalOpen] = useState(false);
       
            const handleOpenModal = () => {
              setModalOpen(true);
          };
      
          const handleCloseModal = () => {
              setModalOpen(false);
          };

  const fetchJsonFromBlob = async (inventoryDDURL) => {
    try {
      const sasToken = await generateSasTokenAPI();
      const url = `${inventoryDDURL}?${sasToken}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching JSON from blob:", error);
      throw error;
    }
  };

  const fetchPseudo = async () => {
    const projectId = state.projectId;
    const orgId =state.orgId;
    const migrationType = props.migrationType;
    const accountName = "avaeusgenetlsametadev";
    const containerName = "uc-migration";
    const blobPath = `dwhmigration/${orgId}/${projectId}/uploads/${migrationType}/inventory.json`;
    const inventoryURL = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

    try {
      const jsonData = await fetchJsonFromBlob(inventoryURL);
      if (jsonData) {
        setData(jsonData);
        mergeAndBindData(jsonData);
      } else {
        throw new Error("Failed to fetch data from Azure Blob Storage");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const trimAndLowercase = (str) => (str ? str.trim().toLowerCase() : '');

  const mergeAndBindData = (jsonData) => {
    const { TableInventory } = jsonData;

    const mergedData = TableInventory.map((item) => {
      const databaseName = trimAndLowercase(item["Catalog/DB Name"]);
      const schemaName = trimAndLowercase(item["SchemaName"]);
      const tableName = trimAndLowercase(item["Table Name"]);

      const volumeInMB = item["Volume"] ? parseFloat(item["Volume"]) : 0;
      let volumeCategory = "Low";

      if (volumeInMB !== "NA") {
        if (volumeInMB == 0 || volumeInMB < 1) {
          volumeCategory = "Low";
        } else if (volumeInMB >= 1 && volumeInMB <= 20) {
          volumeCategory = "Medium";
        } else {
          volumeCategory = "High";
        }
      }      

      return {
        DatabaseName: databaseName,
        SchemaName: schemaName,
        TableName: tableName,
        RowCount: item["Row Count"] || 0,
        VolumeinGB: volumeInMB,
        VolumeCategory: volumeCategory || 'Low',
        InScope: item["InScope"] === "Yes",
      };
    });

    const inScope = mergedData.filter((item) => item.InScope).length;
    const outScope = mergedData.length - inScope;

    setInScopeCount(inScope);
    setOutScopeCount(outScope);
    setResponse(mergedData);
    setFilteredResponse(mergedData);
  };

  const handleError = (e) => {
    insertError({
      errorMessage: e.message,
      serviceName: "frontend",
      module: "Datadictionary",
      functionName: "DataDictionaryTable",
      userId: state.userId,
    });
    console.error("Error: ", e);
  };

  useEffect(() => {
    fetchPseudo();
  }, []);

  const handleSort = (columnName) => {
    const isSameColumn = columnName === sortOrder.column;
    const newSortOrder = {
      column: columnName,
      order: isSameColumn && sortOrder.order === "asc" ? "desc" : "asc",
    };

    setSortOrder(newSortOrder);

    const sortedData = [...filteredResponse].sort((a, b) => {
      if (!a[columnName] || !b[columnName]) return 0;
      if (newSortOrder.order === "asc") {
        return a[columnName]?.localeCompare(b[columnName], undefined, { numeric: true }) || 0;
      } else {
        return b[columnName]?.localeCompare(a[columnName], undefined, { numeric: true }) || 0;
      }
    });

    setFilteredResponse(sortedData);
  };

  const handleDownload = () => {
    const dataToExport = filteredResponse.map((item) => {
      const { index, ...rest } = item;
      return rest;
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "InventoryGrid.xlsx");
  };

  const handleClearFilter = () => {
    setFilterCriteria({
      SchemaName: "Select",
      TableName: "Select",
    });
    setFilteredResponse(response);
  };

  const handleApplyFilter = () => {
    const { SchemaName, TableName } = filterCriteria;

    const filteredData = response.filter((record) => {
      const subjectMatch =
        SchemaName === "Select" ||
        (record.SchemaName && trimAndLowercase(record.SchemaName) === SchemaName.toLowerCase());
      const tableMatch =
        TableName === "Select" ||
        (record.TableName && trimAndLowercase(record.TableName) === TableName.toLowerCase());

      return subjectMatch && tableMatch;
    });

    setFilteredResponse(filteredData);
  };

  useEffect(() => {
    const filteredData = response.filter((record) => {
      const searchMatch =
        (record.TableName && trimAndLowercase(record.TableName).includes(searchTerm.toLowerCase())) ||
        (record.SchemaName && trimAndLowercase(record.SchemaName).includes(searchTerm.toLowerCase()));

      return searchMatch;
    });

    setFilteredResponse(filteredData);
  }, [searchTerm, filterCriteria]);

  const handleCheckboxChange = async (index) => {
    const updatedResponse = [...filteredResponse];
    updatedResponse[index].InScope = !updatedResponse[index].InScope;

    // Update the in-scope and out-scope count
    const inScope = updatedResponse.filter((item) => item.InScope).length;
    const outScope = updatedResponse.length - inScope;

    setInScopeCount(inScope);
    setOutScopeCount(outScope);
    setFilteredResponse(updatedResponse);

    // Prepare the data to be updated in the blob
    const jsonData = {
      ...data,
      TableInventory: data.TableInventory.map((inventory) =>
        trimAndLowercase(inventory["SchemaName"]) === updatedResponse[index].SchemaName &&
        trimAndLowercase(inventory["Table Name"]) === updatedResponse[index].TableName
          ? { ...inventory, InScope: updatedResponse[index].InScope ? "Yes" : "No" }
          : inventory
      )
    };

    // Update Azure Blob Storage
    const projectId = state.projectId;
    const orgId =state.orgId;
    const migrationType = props.migrationType;
    const accountName = "avaeusgenetlsametadev";
    const containerName = "uc-migration";
    const blobPath = `dwhmigration/${orgId}/${projectId}/uploads/${migrationType}/inventory.json`;
    const inventoryURL = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

    try {
      await updateJsonInBlob(inventoryURL, jsonData);
      fetchPseudo(); // Refresh data by fetching again after update
    } catch (error) {
      console.error('Failed to update blob.', error);
    }
  };

  // Ensure the updateJsonInBlob function is properly sending the updated data
  const updateJsonInBlob = async (blobUrl, updatedData) => {
    try {
      const sasToken = await generateSasTokenAPI();
      const url = `${blobUrl}?${sasToken}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-ms-blob-type': 'BlockBlob',
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Data updated successfully in Azure Blob');
    } catch (error) {
      console.error('Error updating JSON in blob:', error);
      throw error;
    }
  };

  const bindGrid = () => {
    if (filteredResponse.length === 0) {
      return (
        <tr>
          <td colSpan="7" className="text-center">
            No Records Found
          </td>
        </tr>
      );
    }

    return filteredResponse.map((item, index) => (
      <tr key={index}>
        <td>{item.DatabaseName || "NA"}</td>
        <td>{item.SchemaName || "NA"}</td>
        <td>{item.TableName || "NA"}</td>
        <td>{item.RowCount || "NA"}</td>
        <td>{item.VolumeinGB || 0}</td>
        <td>{item.VolumeCategory || "Low"}</td>
        <td>
          <input
           className="form-check-input"
            type="checkbox"
            checked={item.InScope}
            onChange={() => handleCheckboxChange(index)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid">
      <div className="overlay" id="pageLoader" style={{ display: isLoading ? "flex" : "none" }}>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className="d-flex align-items-center loader-bg">
            <div className="spinner-border Loader text-dark align-center" role="status">
              <span className="visually-hidden"></span>
            </div>
            <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
          </div>
        </div>
      </div>
      <div className="row">
        <HeaderCom value="1" />
        <div className="col-md-12 pt-4 mt-5">
          <div className="mt-3 px-2">
            <ul className="cust-breadcrumb mb-0">
              <li className="font-16 font-medium">
                <a>Manage Projects</a>
              </li>
              <li className="font-16 font-medium">
                <a>{state.projectName}</a>
              </li>
              <li className="font-16 font-medium active">Inventory Table</li>
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-between p-4">
            <div className="d-flex">
              <a className="d-flex">
                <img
                  src="images/back-arrow.svg"
                  alt="back-arrow"
                  style={{ width: "24px", height: "24px", cursor: "pointer", whiteSpace: "nowrap" }}
                  onClick={() => { props.closeGrid() }}
                />
              </a>
              <h2 className="text-black font-bold font-22 mb-0 ms-3">Inventory Table
                                {/* <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText} */}
                            <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button>
                        </h2>
                        {isModalOpen && <ViewInstructions notebooktype='dwhMigration' onClose={handleCloseModal} />}
            </div>
            <div className="d-flex justify-content-end p-4" >
              <p className="font-regular font-14 text-grey me-3"style={{padding :'7px',backgroundColor:'#00ff013d'}}>
                In-Scope - <span className="text-black font-medium">{inScopeCount}</span>
              </p>
              <p className="font-regular font-14 text-grey me-3" style={{padding :'7px',backgroundColor:'rgb(255 0 0 / 16%)'}}>
                Out-of-Scope - <span className="text-black font-medium">{outScopeCount}</span>
              </p>
              <p className="font-regular font-14 text-grey" style={{padding :'7px',backgroundColor:'#00ff013d'}}>
                Total - <span className="text-black font-medium">{filteredResponse.length}</span>
              </p>
              <div className="input-group position-relative search-wid me-md-3" style={{paddingLeft :'10px'}}>
                <input
                  type="search"
                  className="form-control cust-input-sty font-14 rounded-3 font-regular pe-4"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                <span className="search-icon-sty">
                  <a>
                    <img src="images/search-icon.svg" alt="search icon" />
                  </a>
                </span>
              </div>
              <div className="btn-group me-3">
                <button
                  className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  
                >
                  <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon" />
                </button>
                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                      <button type="button" className="btn-close" aria-label="Close" />
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label htmlFor="TableName" className="form-label text-black font-14 font-medium">
                            Table Name
                          </label>
                          <select
                            className="form-select cust-input-sty font-14 font-regular"
                            id="TableName"
                            aria-label="Default select example"
                            onChange={(e) => setFilterCriteria({ ...filterCriteria, TableName: e.target.value })}
                            value={filterCriteria.TableName}
                          >
                            <option value="Select">Select</option>
                            {[...new Set(response.map((item) => item.TableName))].map((tableName, index) => (
                              <option key={index} value={tableName}>
                                {tableName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label htmlFor="SchemaName" className="form-label text-black font-14 font-medium">
                            Schema Name
                          </label>
                          <select
                            className="form-select cust-input-sty font-14 font-regular"
                            id="SchemaName"
                            aria-label="Default select example"
                            onChange={(e) => setFilterCriteria({ ...filterCriteria, SchemaName: e.target.value })}
                            value={filterCriteria.SchemaName}
                          >
                            <option value="Select">Select</option>
                            {[...new Set(response.map((item) => item["SchemaName"]?.trim()))].map((schemaName, index) => (
                              <option key={index} value={schemaName}>
                                {schemaName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-4 mb-2">
                      <button
                        type="button"
                        className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                        onClick={handleClearFilter}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="btn cust-primary-btn font-14 font-medium px-4"
                        onClick={handleApplyFilter}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button onClick={handleDownload} type="button" className="btn cust-secondary-btn font-16 font-medium">
                <span className="me-2">
                  <img src="images/download-icon.svg" alt="add-icon" />
                </span>
                Download
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 px-4">
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
            <div className="table-responsive rounded-3">
              <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                <thead className="sticky-top-pos">
                  <tr>
                    <th>
                      Catalog/DB Name{" "}
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "DatabaseName"
                              ? sortOrder.order === "asc"
                                ? "images/sort-up-icon.svg"
                                : "images/sort-down-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("DatabaseName")}
                        />
                      </span>
                    </th>
                    <th>
                      Schema Name{" "}
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "SchemaName" && sortOrder.order === "asc"
                              ? "images/sort-up-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("SchemaName")}
                        />
                      </span>
                    </th>
                    <th>
                      Table Name
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "TableName" && sortOrder.order === "asc"
                              ? "images/sort-up-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("TableName")}
                        />
                      </span>
                    </th>
                    <th>
                      Row Count
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "RowCount" && sortOrder.order === "asc"
                              ? "images/sort-up-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("RowCount")}
                        />
                      </span>
                    </th>
                    <th>
                      Volume in GB
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "VolumeinGB" && sortOrder.order === "asc"
                              ? "images/sort-up-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("VolumeinGB")}
                        />
                      </span>
                    </th>
                    <th>
                      Volume Category
                    </th>
                    <th>
                      In Scope
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            sortOrder.column === "InScope" && sortOrder.order === "asc"
                              ? "images/sort-up-icon.svg"
                              : "images/sort-down-icon.svg"
                          }
                          alt="sort-arrow"
                          onClick={() => handleSort("InScope")}
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>{bindGrid()}</tbody>
              </table>
            </div>
            <p className="font-regular font-14 text-grey">
              # of Records:
              <span className="text-black font-medium">
                {filteredResponse.length} out of {response.length}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryGrid;