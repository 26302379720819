import React, { useState, useEffect } from 'react';
import { HeaderCom } from './Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { putManageRoles, updateManageRoles, getManageRoles, adUsers, insertError } from '../Service/Api';
import Select from 'react-select';

export function ManageRoles() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [getDataRoles, setGetDataRoles] = useState([]);
    const [visibleItems, setVisibleItems] = useState(10);
    const [newUser, setNewUser] = useState({ userName: "", emailId: "", adUserId: "" });
    const [sortOrder, setSortOrder] = useState({ column: 'SNo', ascending: false });
    const [adData, setAdData] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [mailIds, setMailIds] = useState([]);

    useEffect(() => {
        fetchRoles();
        fetchadUser();
    }, []);

    const userId = state.userId;
    const JWT = state.jwt;

    const fetchRoles = async () => {
        try {
            console.log("enters fetch roles function");
            document.getElementById('pageLoader').style.display = 'block';
            const response = await getManageRoles(state.userId, state?.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            if (response.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                // Handle other errors here
            } else {
                let values = response.data;
                values = values.map((a, i) => ({ ...a, SNo: `${i + 1}` }));
                setGetDataRoles(values);
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    const fetchadUser = async () => {
        try {
            console.log("enters aduserData api");
            document.getElementById('pageLoader').style.display = 'block';
            const response = await adUsers(state.userId, state?.jwt, state?.accessToken);
            document.getElementById('pageLoader').style.display = 'none';
            if (response.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                // Handle other errors here
            } else {
                setAdData(response.data);
                setUserNames(response.data.map(a => ({ label: a.displayName, value: a.displayName })));
                setMailIds(response.data.map(a => ({ label: a.mail, value: a.mail })));
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRoles',
                functionName: 'fetchadUser',
                userId: state.userId,
            });
        }
    };

    const handleUserName = (val) => {
        const user = adData.find((item) => item.displayName === val);
        setNewUser({ ...user, userName: user.displayName, emailId: user.mail, adUserId: user.id });
    };

    const handleEmailId = (val) => {
        const user = adData.find((item) => item.mail === val);
        setNewUser({ ...user, userName: user.displayName, emailId: user.mail, adUserId: user.id });
    };

    const handleSort = (columnName) => {
        const isSameColumn = sortOrder.column === columnName;
        const newOrder = isSameColumn ? !sortOrder.ascending : true;

        const sortedData = [...getDataRoles].sort((a, b) => {
            if (columnName === 'SNo') {
                return newOrder ? a.SNo - b.SNo : b.SNo - a.SNo;
            } else {
                const valueA = a[columnName].toLowerCase();
                const valueB = b[columnName].toLowerCase();
                return newOrder
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }
        });

        setSortOrder({ column: columnName, ascending: newOrder });
        setGetDataRoles(sortedData);
    };

    useEffect(() => {
        handleSort('SNo');
    }, []);

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = 'block';
        setTimeout(() => {
            navigate('/');
        }, 3000);
    };

    const deleteUser = async (userID) => {
        try {
            document.getElementById('pageLoader').style.display = 'block';
            const data = await updateManageRoles(userId, userID, state?.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            if (data.statusCode === 403) {
                sessionExpired();
            } else if (data.statusCode !== 200) {
                // Handle other errors here
            } else {
                let values = data.data;
                values = values.map((a, i) => ({ ...a, SNo: `${i + 1}` }));
                setGetDataRoles(() => values);
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRoles',
                functionName: 'deleteUser',
                userId: state.userId,
            });
        }
    };

    // const addUser = async () => {
    //     try {
    //         const newUserData = newUser;
    //         let status = !getDataRoles.some(user => user.emailId === newUser.emailId);

    //         let body = {
    //             newUserData,
    //             userId: state.userId,
    //             accessToken: state?.accessToken,
    //         };

    //         if (status) {
    //             document.getElementById('pageLoader').style.display = 'block';
    //             const data = await putManageRoles(body, JWT);
    //             document.getElementById('pageLoader').style.display = 'none';

    //             if (data.status === 403) {
    //                 sessionExpired();
    //             } else if (data.status !== 200) {
    //                 // Handle other errors here
    //             } else {
    //                 let values = data.data;
    //                 values = values.map((a, i) => ({ ...a, SNo: `${i + 1}` }));
    //                 setNewUser("");
    //                 setGetDataRoles(values);
    //             }
    //         }
    //     } catch (error) {
    //         insertError({
    //             errorMessage: error.message,
    //             serviceName: 'frontend',
    //             module: 'ManageRoles',
    //             functionName: 'addUser',
    //             userId: state.userId,
    //         });
    //     }
    // };


    const addUser = async () => {
        try {
            const newUserData = newUser;
            // Check if the user already exists
            let userExists = getDataRoles.some(user => user.emailId === newUser.emailId);

            if (userExists) {
                // Clear the fields if the user exists
                setNewUser({ userName: "", emailId: "" });
                return; // Exit the function early if the user already exists
            }

            let body = {
                newUserData,
                userId: state.userId,
                accessToken: state?.accessToken,
            };

            document.getElementById('pageLoader').style.display = 'block';
            const data = await putManageRoles(body, JWT);
            document.getElementById('pageLoader').style.display = 'none';

            if (data.status === 403) {
                sessionExpired();
            } else if (data.status !== 200) {
                // Handle other errors here
            } else {
                let values = data.data;
                values = values.map((a, i) => ({ ...a, SNo: `${i + 1}` }));
                setNewUser({ userName: "", emailId: "" });
                setGetDataRoles(values);
            }
        } catch (error) {
            insertError({
                errorMessage: error.message,
                serviceName: 'frontend',
                module: 'ManageRoles',
                functionName: 'addUser',
                userId: state.userId,
            });
        }
    };

    const tableData = () => {
        if (getDataRoles.length !== 0) {
            return (
                <>
                    <tr className="table-alter-bg">
                        <td className="text-center"></td>
                        <td className="text-center">
                            <Select
                                options={userNames}
                                className="basic-single"
                                placeholder='Enter username'
                                value={{ label: newUser.userName, value: newUser.userName }}
                                classNamePrefix="select"
                                onChange={(e) => handleUserName(e.value)}
                            />
                        </td>
                        <td className="text-center">
                            <Select
                                options={mailIds}
                                className="basic-single"
                                placeholder='Enter email'
                                value={{ label: newUser.emailId, value: newUser.emailId }}
                                classNamePrefix="select"
                                onChange={(e) => handleEmailId(e.value)}
                            />
                        </td>
                        <td className="text-center">
                            <span className="cust-cursor-pointer">
                                <img
                                    src="images/add-icons.svg"
                                    onClick={newUser.userName !== "" && newUser.emailId !== "" ? () => addUser() : null}
                                    alt="Add User"
                                    style={{
                                        cursor: newUser.userName !== "" && newUser.emailId !== "" ? "pointer" : "not-allowed",
                                        opacity: newUser.userName !== "" && newUser.emailId !== "" ? 1 : 0.5
                                    }}
                                />
                            </span>
                        </td>
                    </tr>
                    {getDataRoles.slice(0, visibleItems).map((val, index) => (
                        <tr key={index} className={index % 2 === 0 ? "" : "table-alter-bg"}>
                            <td className="text-center">{val.SNo}</td>
                            <td className="text-left">{val.username}</td>
                            <td className="text-left">{val.emailId}</td>
                            <td className="text-center">
                                <span className="cust-cursor-pointer">
                                    <img src="images/delete-icon.svg" onClick={() => deleteUser(val.userId)} alt="Delete User" />
                                </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return null;
        }
    };

    const loadMoreItems = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
    };






    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                {/* header style starts here */}
                <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
                    <div className="d-flex align-items-center py-1">
                        <a className="navbar-brand" href="#"><img src="images/codegen-logo.svg" alt="codegen-logo" className="logo-sty" /></a>
                        <div className="ms-5">
                            <HeaderCom value='4' />
                        </div>
                        <div className="dropdown me-lg-3 ms-auto">
                            <img src="images/user-profile.svg" alt="user-profile" className="profile-sty cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                                <li><a className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" href="#"><span className="me-2"><img src="images/logout.svg" alt="profile-icon" /></span>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* header style ends here */}
                {/* <div className="col-md-12 pt-4 mt-5" style={{ position: "sticky",top: "0",z-index:" 999"}}> */}
                <div
                    className="col-md-12 pt-4 mt-5"
                    style={{
                        position: "sticky",
                        top: "0",
                        zIndex: "999"
                    }}
                >
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a href="#">User Profile</a></li>
                            <li className="font-16 font-medium active">Manage Role</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center p-4">
                        <h2 className="text-black font-bold font-22 mb-0">Manage Role</h2>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="table-responsive mt-3">
                            {/* Loader */}
                            {/* <div className="overlay" id='pageLoader' style={{ display: 'none', zIndex: "10000" }}>
                                <div className="position-absolute top-50 start-50  translate-middle">
                                    <div className="d-flex align-items-center loader-bg">
                                        <div className="spinner-border Loader text-dark align-center " role="status">
                                            <span className="visually-hidden"></span>
                                        </div>
                                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                                    </div>
                                </div>
                            </div> */}
                            <div className="overlay" id='pageLoader' style={{ display: 'none', zIndex: "10000", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <div className="d-flex align-items-center loader-bg">
                                        <div className="spinner-border Loader text-dark" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                                    </div>
                                </div>
                            </div>


                            {/* Table content */}
                            <table className="table table-borderless rounded custom-grid border-0 custom-metadata-table" style={{ overflow: "hidden" }} >
                                {/* Table headers */}
                                <thead className="sticky-top-pos custom-tableHeader">
                                    <tr>
                                        <th onClick={() => { handleSort('SNo') }} className='font-14'>
                                            SNo
                                            {/* {sortOrder.column == 'SNo' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>} */}
                                        </th>
                                        <th onClick={() => { handleSort('username') }} className='font-14'>
                                            Username
                                            {sortOrder.column == 'username' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                        <th onClick={() => { handleSort('emailId') }} className='font-14'>
                                            Email
                                            {sortOrder.column == 'emailId' ? <>
                                                <span className="cursor-pointer">
                                                    {sortOrder.ascending ? (
                                                        <img
                                                            src="images/sort-down-icon.svg"
                                                            alt="sort-down-icon"
                                                            className="sort-icon"
                                                        />) : (<img src="images/sort-up-icon.svg" alt="sort-up-icon" className="sort-icon" />)}
                                                </span>
                                            </> : <span className="cursor-pointer">
                                                <img src="images/sort-up-icon.svg" alt="sort-down-icon" className="sort-icon" />
                                            </span>}
                                        </th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                {/* Table body */}
                                <tbody>
                                    {tableData()}
                                </tbody>
                            </table>
                            {getDataRoles.length > visibleItems && (
                                <>
                                    <p className="font-regular font-14 text-grey">
                                        # of Records:
                                        <span className="text-black font-medium">{visibleItems} out of {getDataRoles.length}</span>
                                    </p>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button
                                            type="button"
                                            className="btn cust-secondary-btn font-14 font-medium px-3 text-nowrap"
                                            onClick={loadMoreItems}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageRoles;