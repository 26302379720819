import React from 'react';
import { saveAs } from 'file-saver';
import { generateSasTokenAPI } from '../../Service/Api';
import axios from 'axios';


const accountName = 'avaeusgenetlsametadev';
const containerName = 'uc-migration';


export const fetchJsonFromBlob = async (orgId, projectId, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `organizations/${orgId}/${projectId}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        console.log(url2, 'this is the url for fetch')
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};





export const dwhfetchJsonFromBlob = async (orgId, projectId, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `dwhmigration/${orgId}/${projectId}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        console.log(url2, 'this is the url for fetch')
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};






export const fetchTextFromURL = async (URLToFetch) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const url = `${URLToFetch}?${sasToken}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const textData = await response.text(); // Use text() instead of json()
        console.log(textData, 'Fetched Text Data');
        return textData;

    } catch (error) {
        console.error('Error fetching text from URL:', error);
        throw error;
    }
};

export const fetchJsonFromURL = async (URLToFetch) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const url = `${URLToFetch}?${sasToken}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;

    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};

export const putJsonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `organizations/${orgName}/${projectName}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url1 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url1;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

export const updateChatHistoryToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `organizations/${orgName}/${projectName}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        console.log(url2, 'this is the update chatHistoryurl')

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

export const dwhupdateChatHistoryToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `dwhmigration/${orgName}/${projectName}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url2 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        console.log(url2, 'this is the update chatHistoryurl')

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

export const putJsonToBlob1 = async (jsonData, blobpath) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = blobpath
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url1 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });
 
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
 
        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url1;
 
    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

export const putPythonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `organizations/${orgName}/${projectName}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: jsonData
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

export const putPythonToBlob1 = async (jsonData, blobpath) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const url = `${blobpath}?${sasToken}`;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: jsonData
        });
 
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
 
        console.log('JSON data uploaded successfully to ', blobpath);
        return blobpath;
 
    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};
export const updateJsonInBlob = async (orgName, projectName, fileName, updateData, phase) => {
    try {
        // First, fetch the existing data
        // const existingData = await fetchJsonFromBlob(orgName, projectName, fileName);

        // // Then, update it with the new data
        // const updatedData = [...existingData, ...updateData];

        // Finally, upload the updated data
        await putJsonToBlob(orgName, projectName, updateData, fileName, phase);

        console.log(updateData, 'Updated Data');
        return updateData;
    } catch (error) {
        console.error('Error updating JSON in blob:', error);
        throw error;
    }
};


export const dwhputJsonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `dwhmigration/${orgName}/${projectName}/${phase}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url1 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url1;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};


export const dwhupdateJsonInBlob = async (orgName, projectName, fileName, updateData, phase) => {
    try {
        // First, fetch the existing data
        // const existingData = await fetchJsonFromBlob(orgName, projectName, fileName);

        // // Then, update it with the new data
        // const updatedData = [...existingData, ...updateData];

        // Finally, upload the updated data
        await dwhputJsonToBlob(orgName, projectName, updateData, fileName, phase);

        console.log(updateData, 'Updated Data');
        return updateData;
    } catch (error) {
        console.error('Error updating JSON in blob:', error);
        throw error;
    }
};


export const fetchKrokiDiagram = async (umlCode) => {
    try {
        const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });

        return response.data; // Set the SVG image source
    } catch (error) {
        console.error('Error fetching diagram from Kroki:', error);
        return ('<div>Error generating diagram</div>');
    }
};

export const downloadSVG = async (umlValue, fileName) => {
    try {

        let srcValue = umlValue;
        if (srcValue.startsWith('http:')) {
            srcValue = srcValue.replace('http:', 'https:');
            
            const response = await fetch(srcValue);
            const blob = await response.blob();
            saveAs(blob, `${fileName}.svg`);
        } else {
            // Create a Blob from the SVG content
            const blob = new Blob([srcValue], { type: 'image/svg+xml' });
            saveAs(blob, `${fileName}.svg`);
        }

    } catch (error) {
        console.log('Error downloading the image:', error);
    }
};


export const putUsecaseJsonToBlob = async (userId, promptId, jsonData, fileName) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `usecasefiles/${userId}/${promptId}/${fileName}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        var url1 = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: JSON.stringify(jsonData)
        });

        console.log(response, 'responseresponseresponse');

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url1;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};
